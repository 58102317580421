<template>
  <div>
    <div class="table-wrapper">
      <table class="common_table">
        <thead class="common_thead">
          <tr class="common_thead_row">
            <th class="common_thead_item">フラググループ</th>
            <th class="common_thead_item">フラグ名</th>
          </tr>
        </thead>
        <tbody class="common_tbody">
          <tr
            v-for="group in Object.values(tagGroups.data)"
            :key="group.id"
            class="common_tbody_row"
          >
            <td class="common_tbody_item flag_group_td">
              <p class="tag_group_name">
                {{ group.name }}
              </p>
              <p
                v-if="isEdit"
                class="tag_group_cancel"
                @click="onClickCancelFlg({ group })"
              >
                追加をキャンセル
              </p>
            </td>
            <td class="common_tbody_item flag_td">
              <div>
                <div>
                  <ul
                    class="flexbox flexbox-middle flag_list position_relative"
                  >
                    <template v-if="group.tags.length > 0">
                      <li v-for="tag in group.tags" :key="tag.id">
                        <span class="flag">{{ tag.name }}</span>
                      </li>
                    </template>
                    <li v-if="isEdit">
                      <div
                        v-if="group.tags.length > 0"
                        class="btn-add-flag"
                        @click="onClickAddFlg({ group })"
                      >
                        <p>フラグを選択</p>
                      </div>
                      <div
                        v-else
                        class="btn-add-flag"
                        @click="onClickAddFlg({ group })"
                      />
                      <tag-context-menu
                        v-if="
                          isVisibleAddFlag === true &&
                          group.id === selectedTagGroupId
                        "
                        class="tag_context_menu_comp"
                        :placeholder="'フラグを新規登録'"
                        :list="getTagsForContextMenu"
                        @close="isVisibleAddFlag = false"
                        @onChangeSelect="onChangeSelectTag"
                        @onClickNewItem="onClickNewItemTag(group.id, $event)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btn-add-flag-group position_relative">
      <div
        v-if="isEdit"
        class="btn-add-flag"
        @click="isVisibleAddFlagGroup = true"
      >
        <p>フラググループを追加する</p>
      </div>
      <tag-context-menu
        v-if="isEdit === true && isVisibleAddFlagGroup === true"
        class="tag_context_menu_comp"
        :placeholder="'フラググループを新規登録'"
        :list="getTagGroupsForContext"
        @close="isVisibleAddFlagGroup = false"
        @onChangeSelect="onChangeSelectFlagGroup"
        @onClickNewItem="onClickNewItemFlagGroup"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import tagsService from '@/services/tags';
import TagContextMenu from '@/components/ui/menus/components/TagContextMenu';

export default defineComponent({
  name: 'UpdateApplicantFlag',
  components: { TagContextMenu },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    onChanged: {
      type: Function,
      required: true,
    },
    defaultTagGroups: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const store = useStore();
    const isVisibleAddFlagGroup = ref(false);
    const isVisibleAddFlag = ref(false);
    const tagGroups = reactive({ data: props.defaultTagGroups });
    const selectedTagGroupId = ref(-1);

    // computed
    const getTagGroupsForContext = computed(() => {
      const result = [];
      for (const group of store.getters['company/companyTagGroups']) {
        result.push({
          id: group.id,
          name: group.name,
          selected: tagGroups.data[group.id] !== undefined,
        });
      }
      return result;
    });
    const getTagsForContextMenu = computed(() => {
      const filteredCompanyTagGroups = store.getters[
        'company/companyTagGroups'
      ].filter(group => group.id === parseInt(selectedTagGroupId.value, 10));
      if (
        filteredCompanyTagGroups.length > 0 &&
        filteredCompanyTagGroups[0].tags
      ) {
        const selectedTags = tagGroups.data[
          parseInt(selectedTagGroupId.value, 10)
        ].tags.reduce(
          (accumulator, curr) =>
            Object.assign({}, accumulator, { [curr.id]: true }),
          {},
        );
        return filteredCompanyTagGroups[0].tags.map(tag =>
          Object.assign({}, tag, { selected: !!selectedTags[tag.id] }),
        );
      }
      return [];
    });

    // methods
    const onClickAddFlg = event => {
      isVisibleAddFlag.value = true;
      selectedTagGroupId.value = event.group.id;
    };
    const onClickCancelFlg = event => {
      const newTagGroups = Object.assign({}, tagGroups.data);
      delete newTagGroups[event.group.id];
      tagGroups.data = newTagGroups;
      if (props.onChanged) props.onChanged(newTagGroups);
    };
    const onChangeSelectFlagGroup = event => {
      let newTagGroups = Object.assign({}, tagGroups.data);
      if (event.selected) {
        newTagGroups = Object.assign({}, newTagGroups, {
          [event.id]: Object.assign({}, event, { tags: [] }),
        });
        isVisibleAddFlagGroup.value = false;
      } else {
        delete newTagGroups[event.id];
      }
      tagGroups.data = newTagGroups;
    };
    const onClickNewItemFlagGroup = async ev => {
      await store.dispatch('company/ADD_COMPANY_TAG_GROUP', {
        id: store.getters['company/company'].id,
        name: ev.name,
      });
    };
    const onChangeSelectTag = async event => {
      if (selectedTagGroupId.value === -1) return;
      const newTagGroups = Object.assign({}, tagGroups.data);
      if (event.selected) {
        newTagGroups[selectedTagGroupId.value].tags.push(event);
      } else {
        let newTags = Object.assign(
          [],
          newTagGroups[selectedTagGroupId.value].tags,
        );
        newTags = newTags.filter(el => el.id !== event.id);
        newTagGroups[selectedTagGroupId.value].tags = newTags;
      }
      tagGroups.data = newTagGroups;
      if (props.onChanged) props.onChanged(newTagGroups);
    };
    const onClickNewItemTag = async (tagGroupId, event) => {
      const res = await tagsService.createTag(tagGroupId, event.name);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
      await onChangeSelectTag(Object.assign({}, event, { selected: false }));
    };

    // lifecycle
    onMounted(async () => {
      await store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
    });

    return {
      isVisibleAddFlagGroup,
      isVisibleAddFlag,
      tagGroups,
      selectedTagGroupId,
      getTagGroupsForContext,
      getTagsForContextMenu,
      onClickAddFlg,
      onClickCancelFlg,
      onChangeSelectFlagGroup,
      onClickNewItemFlagGroup,
      onChangeSelectTag,
      onClickNewItemTag,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';

@include common_table_style(150px, 100%);
.common_table {
  .common_thead {
    .common_thead_row {
      .common_thead_item:nth-of-type(2) {
        text-align: left;
      }
    }
  }
  .common_tbody {
    .common_tbody_row {
      .flag_group_td {
        .tag_group_name {
          text-align: left;
          font-weight: bold !important;
          margin: 10px 0 20px 0;
        }
        .tag_group_cancel {
          cursor: pointer;
          text-align: left;
          color: red !important;
        }
      }
      .flag_td {
        display: flex;
        white-space: normal;
        padding: 1rem 1rem 1rem 1rem;
        .flag_list {
          flex-wrap: wrap;
          li {
            margin-right: 10px;
            margin-bottom: 10px;
            span {
              padding: 10px;
              background: #efefef;
              border-radius: 3px;
              font-weight: bold;
              line-height: 1.3;
            }
          }
        }
      }
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
.position_relative {
  position: relative;
  .tag_context_menu_comp {
    position: absolute;
    bottom: 40px;
    font-size: 1.3rem;
    & :deep(.btn-toggle) {
      .accodion {
        display: none;
      }
      .check {
        display: inline-block;
      }
    }
  }
}
.btn-add-flag-group {
  display: flex;
  align-items: center;
  height: 4rem;
  margin-left: 2rem;
}
.btn-add-flag {
  cursor: pointer;
  margin-top: 2px;
  padding: 10px 30px 10px 0px;
  background-image: url('../../../../../assets/img/add_circle_gray.svg');
  background-position: right 4px center;
  background-size: 20px;
}
</style>
