import httpClient from '@/http-client';

const StaffSettingsManager = function _StaffSettingsManager() {
  const manager = Object.create(StaffSettingsManager.prototype);
  return manager;
};

StaffSettingsManager.prototype = {
  async patchIsOpenCalendarNav(isOpenCalendarNav) {
    const res = await httpClient.patch('/staff_settings', {
      is_open_calendar_nav: isOpenCalendarNav,
    });
    return res;
  },
};

export default StaffSettingsManager();
