/**
 * ページ単位の情報
 * 画面切り替え時にリセットします。
 */
import store from '@/stores';

export default {
  namespaced: true,
  state: {
    isLoaded: false,
    applicationError: {
      error: null,
      title: '',
      message: '',
    },
  },
  getters: {
    isPageLoaded: currentState => currentState.isLoaded,
    hasApplicationError: currentState => currentState.applicationError.error,
    applicationErrorInfo: currentState => ({
      title: currentState.applicationError.title,
      message: currentState.applicationError.message,
    }),
  },
  mutations: {
    SET_LOADED(state) {
      Object.assign(state, { isLoaded: true });
    },
    RESET_ALL(state) {
      Object.assign(state, { isLoaded: false });
    },
    SET_APPLICATION_ERROR(state, payload) {
      Object.assign(state, { applicationError: payload });
    },
    CLEAR_APPLICATION_ERROR(state) {
      Object.assign(state, {
        applicationError: {
          error: null,
          title: '',
          message: '',
        },
      });
    },
  },
  actions: {
    /**
     * ページ内でローディング完了時に任意に呼び出します。
     * @param commit
     * @constructor
     */
    async SET_LOADED({ commit }) {
      commit('SET_LOADED');
      await store.dispatch('company/UPDATE_LINE_CONSUMPTION');
    },
    async SET_LOADED_FOR_EXT({ commit }) {
      commit('SET_LOADED');
    },
    /**
     * routerで画面切り替え時に自動的にリセットします。
     * @param commit
     * @constructor
     */
    RESET_PAGE({ commit }) {
      commit('RESET_ALL');
    },
    SET_APPLICATION_ERROR({ commit }, payload) {
      commit('SET_APPLICATION_ERROR', payload);
    },
    CLEAR_APPLICATION_ERROR({ commit }) {
      commit('CLEAR_APPLICATION_ERROR');
    },
  },
};
