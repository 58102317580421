import tagsService from '@/services/tags';
import companyService from '@/services/company';
import roleService from '@/services/role';

export default {
  namespaced: true,
  state: {
    company: null,
    companyInfo: '',
    tagGroups: [],
    roles: [],
    lineConsumption: null,
  },
  getters: {
    company: currentState => currentState.company,
    companyInfo: currentState => currentState.companyInfo,
    companyTagGroups: currentState => currentState.tagGroups,
    roles: currentState => currentState.roles,
    allowFileManagiment: currentState => currentState.company.fileupload,
    lineConsumption: currentState => currentState.lineConsumption,
    isGuided: currentState => currentState.company.guide,
  },
  mutations: {
    SET_COMPANY(state, company) {
      Object.assign(state, { company });
    },
    CLEAR_COMPANY(state) {
      Object.assign(state, { company: null });
    },
    SET_TAG_GROUPS(state, tagGroups) {
      Object.assign(state, { tagGroups });
    },
    ADD_TAG_GROUP(state, tagGroup) {
      state.tagGroups.push(tagGroup);
    },
    CLEAR_TAG_GROUPS(state) {
      Object.assign(state, { tagGroups: [] });
    },
    SET_COMPANY_INFO(state, companyInfo) {
      Object.assign(state, { companyInfo });
    },
    SET_COMPANY_ROLES(state, roles) {
      Object.assign(state, { roles });
    },
    SET_LINE_CONSUMPTION(state, lineConsumption) {
      Object.assign(state, { lineConsumption });
    },
  },
  actions: {
    async SET_COMPANY({ commit }, company) {
      commit('SET_COMPANY', company);
    },
    async CLEAR_COMPANY({ commit }) {
      commit('CLEAR_COMPANY');
    },
    async FETCH_COMPANY_TAG_GROUP({ commit }) {
      const tagGroups = await tagsService.fetchTagGroups();
      commit('SET_TAG_GROUPS', tagGroups);
    },
    async ADD_COMPANY_TAG_GROUP({ commit }, newItem) {
      const res = await tagsService.createTagGroup(newItem.id, newItem.name);
      if (res.success) {
        commit('ADD_TAG_GROUP', res.tag_group);
      } else {
        this.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
      return res.tag_group;
    },
    async CLEAR_COMPANY_TAG_GROUPS({ commit }) {
      commit('CLEAR_TAG_GROUPS');
    },
    async FETCH_COMPANY_INFO({ commit }) {
      const companyInfo = await companyService.fetchCompany();
      commit('SET_COMPANY_INFO', companyInfo);
    },
    async FETCH_COMPANY_ROLES({ commit }) {
      const roles = await roleService.fetchRoleTypes();
      commit('SET_COMPANY_ROLES', roles);
    },
    async UPDATE_LINE_CONSUMPTION({ commit }) {
      const lineConsumption = await companyService.getLineConsumption();
      commit('SET_LINE_CONSUMPTION', lineConsumption.consumption);
    },
  },
};
