import httpClient from '@/http-client';

const VenueManager = function _VenueManager() {
  const manager = Object.create(VenueManager.prototype);
  return manager;
};

VenueManager.prototype = {
  async fetchVenues(
    graduatedId,
    startDate,
    endDate,
    isShowExceptMySelection = true,
    isShowMySelection = true,
    isShowGoogleEvent = true,
    isOpenCalendarNav = true,
    isUpdatedSetting = false,
    staffs = null,
    googleResourceCalendars = null,
  ) {
    const response = await httpClient.post('/venues', {
      graduated_id: graduatedId,
      start_date: startDate,
      end_date: endDate,
      is_show_except_my_selection: isShowExceptMySelection,
      is_show_my_selection: isShowMySelection,
      is_show_google_event: isShowGoogleEvent,
      is_open_calendar_nav: isOpenCalendarNav,
      is_updated_setting: isUpdatedSetting,
      staffs,
      google_resource_calendars: googleResourceCalendars,
    });
    return response.data;
  },
  async fetchVenuesDay(
    graduatedId,
    startDate,
    endDate,
    selectedStaffIds = [],
    googleResourceCalendars = [],
  ) {
    const payload = {
      graduated_id: graduatedId,
      start_date: startDate,
      end_date: endDate,
      selected_staff_ids: selectedStaffIds,
      google_resource_calendar_ids: googleResourceCalendars,
    };
    const res = await httpClient.post('/venues/day', payload);
    return res.data;
  },
};

export default VenueManager();
