<template>
  <main class="input_otp">
    <form-policy v-if="isPolicy" @backFromPolicy="backFromPolicy" />
    <template v-else>
      <p class="input_otp_title">ワンタイムパスワードの確認</p>
      <p class="input_otp_caption">
        ご入力いただいたメールアドレスではエントリーフォームの入力の必要はありません。
        <br />
        ご入力いただいたメールアドレス宛に認証メールを送信しましたので、メール内に記載の6桁の数字を下のフォームに入力すれば登録完了となります。
      </p>
      <div class="input_wrapper">
        <p class="input_head">メールアドレス</p>
        <p class="email_display">{{ email }}</p>
        <div class="input_head_wrapper">
          <p class="input_head">ワンタイムパスワード</p>
          <!-- <a @click.prevent="$emit('resendOtp')">パスワード再送信</a> -->
        </div>
        <input
          v-model="otp"
          :class="{ input: true, 'is-danger': !errors.otp.isValid }"
          type="password"
          class="one_time_input"
          placeholder="ワンタイムパスワード"
          maxlength="6"
        />
        <span v-show="!errors.otp.isValid" class="fa fa-warning font-s">
          {{ errors.otp.message }}
        </span>
      </div>
      <check-policy
        v-if="isRequiredEmail !== true"
        v-model="checkedPolicy"
        @changeCheckPolicy="changeCheckPolicy"
        @toPolicy="toPolicy"
      />
      <div class="input_footer">
        <button
          class="submit_button"
          :class="{
            'is-disabled': isRequiredEmail !== true && checkedPolicy !== true,
          }"
          @click.prevent="verifyOtp"
        >
          送信する
        </button>
        <button class="cancel_button" @click.prevent="$emit('back')">
          前のページに戻る
        </button>
      </div>
    </template>
  </main>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import useValidation from '@/composables/useValidation';
import FormPolicy from '@/components/features/policy/components/FormPolicy';
import CheckPolicy from '@/components/page/line_entry/components/CheckPolicy';

export default defineComponent({
  name: 'InputOtp',
  components: { FormPolicy, CheckPolicy },
  props: {
    email: {
      type: String,
      required: true,
    },
    isRequiredEmail: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['verifyOtp', 'back'],
  setup(props, context) {
    const store = useStore();
    const otp = ref('');
    const checkedPolicy = ref(false);
    const isPolicy = ref(false);
    const { errors, resetErrors, validateRequire, validateOtp } = useValidation(
      ['otp'],
    );

    // methods
    const validate = () => {
      resetErrors();
      let isValid = true;
      isValid = validateOtp('otp', otp.value);
      isValid = validateRequire('otp', otp.value);
      if (isValid === false) {
        return {
          success: false,
          message: '正しい認証コードを入力してください',
        };
      }
      return { success: true, message: '' };
    };
    const verifyOtp = () => {
      if (props.isRequiredEmail !== true && checkedPolicy.value !== true) {
        return;
      }

      const valid = validate();
      if (valid.success === true) {
        context.emit('verifyOtp', otp.value);
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: valid.message,
          type: false,
        });
      }
    };
    const changeCheckPolicy = val => {
      checkedPolicy.value = val;
    };
    const toPolicy = () => {
      isPolicy.value = true;
    };
    const backFromPolicy = () => {
      isPolicy.value = false;
    };

    return {
      otp,
      checkedPolicy,
      isPolicy,
      errors,
      // methods
      verifyOtp,
      changeCheckPolicy,
      toPolicy,
      backFromPolicy,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.input_otp {
  display: flex;
  min-height: calc(100vh - 15px - 130px);
  flex-flow: column;
  font-size: 14px;
}

.input_otp_title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.input_otp_caption {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 4px solid #f0f0f0;
}

.input_wrapper {
  margin: 0 0 auto;
}

.input_head_wrapper {
  display: flex;
}

.input_head {
  margin: 0 auto 3px 0;
  font-weight: bold;
}

.email_display {
  padding-bottom: 20px;
  margin-bottom: 20px;
  line-height: 1.6;
  word-break: break-all;
  border-bottom: 1px solid #f0f0f0;
}

.input {
  width: 100%;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  margin-bottom: 30px;
  font-size: 16px;
  & + .fa-warning {
    display: block;
    margin-top: -30px;
    margin-bottom: 30px;
  }
}

.input_footer {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #f0f0f0;
}

.submit_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  background: #545454;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &.is-disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.cancel_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 10px;
  font-weight: normal;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  cursor: pointer;
}
</style>
