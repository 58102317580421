<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">評価の一括変更</p>
        </div>
        <div class="body">
          <div class="message">
            <p>一覧画面にて選択した応募者の評価を一括で変更します。</p>
            <p class="caption">
              ※一括操作により、自動アクションが適用される可能性があるのでご注意ください
            </p>
          </div>
          <div
            v-if="$store.getters['staff/isOperator'] === true"
            class="area-finished-status"
          >
            <textarea
              v-model="form.data.operator.comment"
              data-vv-as="評価"
              name="comment"
              class="area-operatosssr-body-txt"
              placeholder="担当者の方は評価を入力してください"
              maxlength="1000"
            />
            <div v-if="validateAll() !== true" class="area-err">
              評価を入力してください。
            </div>
          </div>
          <div
            v-else-if="$store.getters['staff/isManager'] === true"
            class="area-finished-status"
          >
            <textarea
              v-model="form.data.manager.comment"
              data-vv-as="評価"
              name="comment"
              class="area-operatosssr-body-txt"
              placeholder="人事の方は評価を入力してください"
              maxlength="1000"
            />
            <button-progress-status
              :progress-state-name="form.data.manager.statusName"
              :is-editing="true"
              @onChangeProgressState="
                form.data.manager.statusName = $event.progressStateName
              "
            />
            <div v-if="validateAll() !== true" class="area-err">
              評価を入力してください。
            </div>
          </div>
          <div
            v-if="
              $store.getters['staff/isOperator'] === true ||
              $store.getters['staff/isManager'] === true
            "
          >
            <div
              v-if="availableCommentLength >= 0"
              class="area-available-comment"
            >
              {{ availableCommentLength }}文字入力可能です。
            </div>
            <div v-else class="area-err">
              {{ availableCommentLength * -1 }}文字オーバーしています。
            </div>
          </div>
          <div class="area-applicant-list allow-announced">
            <p class="title">一括評価対象の応募者</p>
            <selectable-applicant-table
              class="change-list"
              :applicants="allowApplicants"
              :invisible-columns="invisibleColumns"
              :is-sortable="false"
            />
            <div v-if="allowApplicants.length === 0" class="area-err">
              対象の応募者がいません。
            </div>
          </div>
          <div class="area-applicant-list deny-announced">
            <p v-if="$store.getters['staff/isOperator'] === true" class="title">
              以下の応募者は【評価通知済み】、【選考が未評価ではない】、もしくは【選考未割り当て】のため
              <span class="alert_red">評価の変更が出来ません。</span>
            </p>
            <p v-if="$store.getters['staff/isManager'] === true" class="title">
              以下の応募者は【評価通知済み】、もしくは【選考未割り当て】のため
              <span class="alert_red">評価の変更が出来ません。</span>
            </p>
            <selectable-applicant-table
              class="change-list"
              :applicants="denyApplicants"
              :invisible-columns="invisibleColumns"
              :is-sortable="false"
            />
          </div>
        </div>
        <div class="btn-col2">
          <div class="btn btn-unavailable" @click="onClickCancel">
            キャンセル
          </div>
          <div
            class="btn btn-availability"
            @click="
              onClickSettle($event);
              postPottosEvent(26);
            "
          >
            評価を変更する
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { postPottosEvent } from '@/utils/pottos';

import { onResizeModal } from '@/utils/modal-resize';
import applicantService from '@/services/applicants';
import Modal from '@/components/ui/modals/components/Modal';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';
import ButtonProgressStatus from '@/components/ui/buttons/components/ButtonProgressStatus';

export default defineComponent({
  name: 'ModalBulkUpdateEvaluation',
  components: { Modal, SelectableApplicantTable, ButtonProgressStatus },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    applicants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCancel', 'onClickSettle'],
  setup(props, context) {
    const MAX_SELECTION_COMMENT_LENGTH = 1000;
    const store = useStore();
    const invisibleColumns = ref([]);
    const form = reactive({ data: {} });
    const api = reactive({ data: {} });

    // computed
    const allowApplicants = computed(() =>
      props.applicants.filter(v => isAllowApplicant(v)),
    );
    const denyApplicants = computed(() =>
      props.applicants.filter(v => !isAllowApplicant(v)),
    );
    const availableCommentLength = computed(() => {
      let formCommentLength = 0;
      let comments = [];
      const progressComments = allowApplicants.value
        .map(v =>
          api.data.allCurrentProgress.find(x => x.applicant_id === v.id),
        )
        .map(v => ({
          comment: (v.progress.comment || '').length,
          evaluation: (v.progress.evaluation || '').length,
        }));
      if (store.getters['staff/isManager'] === true) {
        formCommentLength = form.data.manager.comment.length;
        comments = progressComments.map(v => v.evaluation);
      } else {
        formCommentLength = form.data.operator.comment.length;
        comments = progressComments.map(v => v.comment);
      }
      return (
        MAX_SELECTION_COMMENT_LENGTH -
        formCommentLength -
        comments.reduce(
          (accumulator, cur) => (cur > accumulator ? cur : accumulator),
          0,
        )
      );
    });

    // watch
    watch(
      () => props.isVisible,
      async v => {
        if (v === true) {
          const res = await applicantService.fetchCurrentProgressApplicants(
            props.applicants.map(v => v.id),
          );
          api.data.allCurrentProgress = res.current_progresses.filter(
            v => v !== null && v !== undefined,
          );
          nextTick(() => localResizeModal());
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );

    // methods
    const resetInit = () => {
      form.data = {
        operator: { comment: '' },
        manager: { comment: '', statusName: '' },
      };
      api.data = { allCurrentProgress: [] };
    };
    const onClickCancel = () => {
      context.emit('onClickCancel');
      resetInit();
    };
    const onClickSettle = () => {
      if (validateAll() !== true || allowApplicants.value.length === 0) return;
      const payload = {
        applicantIds: allowApplicants.value.map(a => a.id),
        isOperator: store.getters['staff/isOperator'],
        isManager: store.getters['staff/isManager'],
        comment: '',
        statusName: '',
      };
      if (store.getters['staff/isOperator'] === true) {
        payload.comment = form.data.operator.comment;
      } else if (store.getters['staff/isManager'] === true) {
        payload.comment = form.data.manager.comment;
        payload.statusName = form.data.manager.statusName;
      }
      context.emit('onClickSettle', payload);
    };
    const validateAll = () => {
      if (store.getters['staff/isOperator'] === true) {
        return form.data.operator.comment && availableCommentLength.value >= 0;
      } else if (store.getters['staff/isManager'] === true) {
        return (
          form.data.manager.comment &&
          form.data.manager.statusName &&
          availableCommentLength.value >= 0
        );
      }
      return false;
    };
    const isAllowApplicant = applicant => {
      const foundApplicant = api.data.allCurrentProgress.find(
        v => v.applicant_id === applicant.id,
      );
      if (!foundApplicant) return false;
      if (
        foundApplicant.progress &&
        foundApplicant.progress.finished_progress &&
        foundApplicant.progress.finished_progress.announced_at
      ) {
        return false;
      }
      if (
        store.getters['staff/isOperator'] === true &&
        foundApplicant.progress.progress_status_id !== 4
      ) {
        return false;
      }
      if (foundApplicant.progress) return true;
      return false;
    };
    const localResizeModal = () =>
      onResizeModal(props.isVisible, {
        updateInvisibleColumns: v => (invisibleColumns.value = v),
      });

    resetInit();

    return {
      invisibleColumns,
      form,
      api,
      allowApplicants,
      denyApplicants,
      availableCommentLength,
      resetInit,
      onClickCancel,
      onClickSettle,
      validateAll,
      isAllowApplicant,
      postPottosEvent,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  overflow-y: auto;
  // width: 79rem;
  .header {
    padding: 2rem 0;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    font-size: 1.2rem;
    padding: 0rem 20px;
    margin-bottom: 20px;
    .message {
      padding: 40px 0 32px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      p {
        line-height: 1.8;
      }
      .caption {
        color: $mochica_color_red;
        font-size: $font_12;
        font-weight: bold;
      }
    }
    .area-finished-status {
      .area-operatosssr-body {
        margin-bottom: 1.2rem;
        .view-blank-text {
          min-height: 5rem;
          background: white;
          border: 1px solid $border_color;
          border-radius: 0.4rem;
          min-height: 10rem;
          padding: 1.2rem;
          font-size: 1.2rem;
          line-height: normal;
          word-wrap: break-word;
        }
        &-txt {
          letter-spacing: 0.05em;
          border: 1px solid $border_color;
          border-radius: 2px 2px 0 0;
          padding: 1.2rem;
          font-size: 1.2rem;
          min-height: 5rem;
          height: 5rem;
          background: #fff;
        }
      }
    }
    .area-applicant-list {
      .title {
        text-align: left;
        font-weight: bold;
        margin: 2em 0 0.5em;
      }
      span.alert_red {
        color: #c31e1e;
      }
      &.allow-announced {
      }
      &.deny-announced {
        .title {
          /*color: red;*/
        }
      }
    }
    .area-table {
      &.change-list {
        width: 100%;
        height: 18vh;
        margin: 0 0 0 0;
        border: 1px solid $border_color;
        border-top: none;
        text-align: left;

        tr {
          border-bottom: 1px solid $border_color;
        }
      }
    }
    .change-status_btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      &_item {
        width: 49.5%;
        height: 3.6rem;
        line-height: 3.6rem;
        background: $mochica_color;
        border-radius: 5rem;
        color: #fff;
        font-weight: bold;
        &.back_btn {
          background: #fff;
          border: 1px solid $border_color;
          color: #333;
          font-weight: normal;
        }
      }
    }
    .area-err {
      color: #c31e1e;
      margin: 10px 0;
      font-size: 1.2rem;
      text-align: left;
    }
    .area-available-comment {
      text-align: left;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    .body {
      text-align: left;
      .area-applicant-list {
        &.deny-announced {
          .title {
            line-height: 1.4;
          }
        }
      }
    }
  }
}
</style>
