<script setup>
import ConfirmEmail from '@/components/ui/confirm/ConfirmEmail.vue';
import ConfirmLine from '@/components/ui/confirm/ConfirmLine.vue';
import FileList from '@/components/ui/files/FileList.vue';

defineProps({
  notice: {
    type: Object,
    default: () => ({
      email: {
        subject: '',
        content: '',
      },
      line: {
        content: '',
      },
      isSendForceEmail: false,
      isConfirmMessage: false,
      files: [],
    }),
  },
});
const emit = defineEmits(['download-file']);

const handleDownloadFile = file => {
  emit('download-file', file);
};
</script>

<template>
  <div class="action-inner">
    <p class="selected-action">連絡事項を通知</p>
    <div class="action-contents auto-action">
      <div class="result-contents-inner">
        <ul class="result-options">
          <li class="result-option">
            <div class="title">全員にメールで送信</div>
            <div class="contents">
              <div class="result-option-check">
                全員にメールで送信{{
                  notice.isSendForceEmail ? 'する' : 'しない'
                }}
              </div>
            </div>
          </li>
          <li class="result-option">
            <div class="title">メッセージ確認</div>
            <div class="contents">
              <div class="result-option-check">
                <div>
                  {{ notice.isConfirmMessage ? 'あり' : 'なし' }}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="action-forms">
          <div class="action-form">
            <div class="action-form-title notice">
              連絡事項のメール・LINEの文面
            </div>
            <div>
              <div class="action-form-contents">
                <div class="form-wrapper">
                  <confirm-email :confirm-values="notice.email">
                    <template #attachment>
                      <file-list
                        :files="notice.files"
                        :delete-enabled="false"
                        @download-file="handleDownloadFile($event)"
                      />
                    </template>
                  </confirm-email>
                </div>
                <div class="form-wrapper">
                  <confirm-line
                    :confirm-values="{
                      content: notice.line.content,
                      isSendForceEmail: notice.isSendForceEmail,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;

.result-contents-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.result-options {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 20px;
  background: #fafafa;
  border: 1px solid #ededed;
  border-radius: 4px;
  font-size: $font_14;
}

.result-option {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &:not(:last-of-type) {
    padding: 0 0 24px;
    border-bottom: 1px solid #ededed;
  }
  .title {
    color: $mochica_color;
    font-weight: bold;
  }
  .contents {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.result-option-check {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: $font_16;
}

.result-option-capions {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #777;
  font-size: $font_12;
}
</style>
