<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content add-selection-modal">
        <section class="modal-header">
          <div class="modal-header_cancell" @click="$emit('onClickCancel')">
            <img
              slot="icon"
              class="icon"
              src="@/assets/img/close_black.svg"
              alt
            />
          </div>
        </section>
        <section class="modal-body">
          <div class="si-block">
            <div class="headline">
              {{ announceBasicInfoTitle }}
            </div>
            <div class="inner">
              <div class="edit-block">
                <div class="title">選考名</div>
                <div class="value">
                  {{
                    selection && selection.event ? selection.event.title : ''
                  }}
                </div>
              </div>
              <div class="edit-block">
                <div class="title">
                  管理用メモ（このメモは応募者には送信されません）
                </div>
                <div class="value">
                  {{
                    selection && selection.description
                      ? selection.description
                      : ''
                  }}
                </div>
              </div>
              <div class="edit-block">
                <div class="title">連絡事項</div>
                <div class="value">
                  {{ selection && selection.notice ? selection.notice : '' }}
                </div>
              </div>
            </div>
          </div>
          <section v-if="isConfirm === true" class="si-block">
            <div class="headline">{{ announceVenueTitle }}</div>
            <div class="inner">
              <ul class="venue-list">
                <selections-venue
                  v-for="(venue, iVenue) in selection.venues"
                  :key="iVenue"
                  :i-venue="iVenue"
                  :is-modal-selection-mode="true"
                  :is-announce-modal="true"
                  :selection-venues="selection.venues"
                />
              </ul>
            </div>
          </section>
          <section class="si-block">
            <div class="headline">
              {{ announceApplicantListTitle }}
            </div>
            <div class="inner">
              <div class="switch_inner">
                <switch-tab-applicant
                  :disp-applicants="dispApplicants"
                  :is-disp-line-friends="isDispLineFriends"
                  @input="onSwitchTab"
                />
                <div class="applicant_counter">
                  <div v-if="modalType === 'venue-announce'">
                    <span class="title_text">案内済：</span>
                    <span class="count_text">
                      {{ announcedApplicantsCount }}名
                    </span>
                    <span class="title_text">未案内：</span>
                    <span class="count_text">
                      {{ notAnnouncedApplicantsCount }}名
                    </span>
                    <span class="title_text">選択されている人数：</span>
                    <span class="count_text">
                      {{ selectedAllApplicants.length }} / {{ sendLimit }}名
                    </span>
                  </div>
                  <div v-if="modalType === 'result-announce'">
                    <span class="title_text">未通知：</span>
                    <span class="count_text">
                      {{ notAnnouncedResultCount }}名
                    </span>
                    <span class="title_text">選択されている人数：</span>
                    <span class="count_text">
                      {{ selectedAllApplicants.length }} / {{ sendLimit }}名
                    </span>
                  </div>
                </div>
              </div>
              <!-- 応募者 -->
              <selectable-applicant-table
                v-show="dispApplicants === 'regist_applicants'"
                :is-sortable="false"
                :is-scrollable="true"
                :is-selectable="isConfirm !== true"
                :is-initial-select-all="true"
                :init-selected-applicants="selectedRegisterApplicants"
                :applicants="registerApplicantsList"
                :can-move-detail="false"
                :applicants-tags="{}"
                :header-additional="additionalHeader"
                :invisible-columns="invisibleColumns"
                @onChangeCurrentSelect="onChangeCurrentSelectRegisterApplicants"
              />
              <!-- LINE友達 -->
              <selectable-applicant-table
                v-show="dispApplicants === 'line_friends' && isDispLineFriends"
                :is-line-applicants="true"
                :is-sortable="false"
                :is-scrollable="true"
                :is-selectable="isConfirm !== true"
                :init-selected-applicants="selectedLineFriendApplicants"
                :applicants="lineFriendApplicantsList"
                :can-move-detail="false"
                :applicants-tags="{}"
                :header-additional="additionalHeader"
                :is-initial-select-all="true"
                :invisible-columns="invisibleColumns"
                @onChangeCurrentSelect="onChangeCurrentSelectLineFriends"
              />
            </div>
          </section>
        </section>
        <div class="btn-col2 mb_120">
          <div class="btn btn-unavailable" @click="$emit('onClickBack')">
            {{ backTitle }}
          </div>
          <template v-if="modalType === 'bulk-selection' || isConfirm === true">
            <button-base
              class="btn submit-button"
              :button-text="okTitle"
              :is-loading="loadingSend"
              :is-disabled="!isSelectedApplicant"
              :rejected-admin-ids="[2]"
              @click="onClickAdd"
            />
          </template>
          <button-base
            v-else
            class="btn submit-button"
            button-text="確認画面に進む"
            :is-loading="loadingSend"
            :is-disabled="!isSelectedApplicant"
            :rejected-admin-ids="[2]"
            @click="onClickAdd"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { postPottosEvent } from '@/utils/pottos';
import Modal from '@/components/ui/modals/components/Modal';
import SwitchTabApplicant from '@/components/ui/switchTabApplicant/components/SwitchTabApplicant';
import SelectionsVenue from '@/components/features/selectionDetailBody/components/SelectionsVenue';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';

export default defineComponent({
  name: 'ModalSelectableApplicantWithSelection',
  components: {
    Modal,
    SelectableApplicantTable,
    SelectionsVenue,
    SwitchTabApplicant,
  },
  props: {
    modalType: {
      type: String,
      default: '',
    }, // 'venue-announce'|'result-announce'|'bulk-selection'
    selection: {
      type: Object,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    applicants: {
      type: Array,
      default: () => [],
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
    initSelectedApplicants: {
      type: Array,
      default: () => [],
    },
    isDispLineFriends: {
      type: Boolean,
      default: false,
    },
    loadingSend: {
      type: Boolean,
      required: true,
    },
    additionalHeader: {
      type: Array,
      default: () => [],
    },
    invisibleColumns: {
      type: Array,
      default: () => [],
    },
    okTitle: {
      type: String,
      required: true,
    },
    backTitle: {
      type: String,
      default: '戻る',
    },
    announceBasicInfoTitle: {
      type: String,
      default: '',
    },
    announceVenueTitle: {
      type: String,
      default: '',
    },
    announceApplicantListTitle: {
      type: String,
      default: '',
    },
  },
  emits: [
    'onClickAdd',
    'onClickSendWithAnnounce',
    'onChangeCurrentSelectRegisterApplicants',
    'onChangeCurrentSelectLineFriends',
    'onSwitchTab',
  ],
  setup(props, context) {
    const SEND_LIMIT = 1000;
    const store = useStore();
    const selectedApplicants = ref([]);
    const selectedRegisterApplicants = ref([]);
    const selectedLineFriendApplicants = ref([]);
    const dispApplicants = ref('regist_applicants'); // 'regist_applicants' | 'line_friends'

    // computed
    const isSelectedApplicant = computed(
      () =>
        (selectedAllApplicants.value &&
          selectedAllApplicants.value.length >= 1) ||
        props.isConfirm === true,
    );
    const registerApplicantsList = computed(() =>
      props.applicants.filter(
        v =>
          v.contactTypeId === 1 ||
          (v.contactTypeId === 2 && v.activatedAt !== null),
      ),
    );
    const registerApplicantIds = computed(() =>
      registerApplicantsList.value.map(v => v.id),
    );
    const lineFriendApplicantsList = computed(() =>
      props.applicants.filter(
        v =>
          v.contactTypeId !== 1 &&
          v.contactTypeId === 2 &&
          v.activatedAt === null,
      ),
    );
    const lineFriendApplicantIds = computed(() =>
      lineFriendApplicantsList.value.map(applicant => applicant.id),
    );
    const selectedAllApplicants = computed(() =>
      props.modalType === 'result-announce'
        ? [...selectedRegisterApplicants.value]
        : [
            ...selectedRegisterApplicants.value,
            ...selectedLineFriendApplicants.value,
          ],
    );
    const announcedApplicantsCount = computed(
      () =>
        props.applicants.filter(v => v.status.progress.name === 'announced')
          .length,
    );
    const notAnnouncedApplicantsCount = computed(
      () =>
        props.applicants.filter(v => v.status.progress.name === 'not_announced')
          .length,
    );
    const notAnnouncedResultCount = computed(
      () =>
        props.applicants
          .filter(
            v =>
              v.contactTypeId === 1 ||
              (v.contactTypeId === 2 && v.activatedAt !== null),
          )
          .filter(app => app.not_announe_lamp === true).length,
    );

    // watch
    watch(
      () => props.isVisible,
      val => {
        dispApplicants.value = 'regist_applicants';
        if (props.modalType === 'venue-announce') {
          // 選考通知の場合
          // 未案内の先頭から100人を抽出する
          const initApplicantsIds100 = props.applicants.filter(
            v => v.status.progress.name === 'not_announced',
          );
          const filteredApplicant = initApplicantsIds100.filter(
            v =>
              v.contactTypeId === 1 ||
              (v.contactTypeId === 2 && v.activatedAt !== null),
          );
          const filteredLineFriendApplicant = initApplicantsIds100.filter(
            v =>
              v.contactTypeId !== 1 &&
              v.contactTypeId === 2 &&
              v.activatedAt === null,
          );
          let setSelectedRegisterApplicantsIds = [];
          let setSelectedRegisterLineFriendsIds = [];
          if (filteredApplicant.length > 0) {
            setSelectedRegisterApplicantsIds =
              filteredApplicant.length < SEND_LIMIT
                ? filteredApplicant
                : filteredApplicant.slice(0, SEND_LIMIT);
          } else {
            setSelectedRegisterLineFriendsIds =
              filteredLineFriendApplicant.length < SEND_LIMIT
                ? filteredLineFriendApplicant
                : filteredLineFriendApplicant.slice(0, SEND_LIMIT);
          }
          selectedRegisterApplicants.value =
            setSelectedRegisterApplicantsIds.map(v => v.id);
          if (props.isDispLineFriends) {
            selectedLineFriendApplicants.value =
              setSelectedRegisterLineFriendsIds.map(v => v.id);
          }
        } else if (props.modalType === 'result-announce') {
          // 結果通知の場合
          // 未案内の先頭から100人を抽出する
          const initApplicantsIds100 = props.applicants.filter(
            v => v.not_announe_lamp === true,
          );
          const filteredApplicants = initApplicantsIds100.filter(
            v =>
              v.contactTypeId === 1 ||
              (v.contactTypeId === 2 && v.activatedAt !== null),
          );
          let setSelectedRegisterApplicantsIds = [];
          if (filteredApplicants.length > 0) {
            setSelectedRegisterApplicantsIds =
              filteredApplicants.length < SEND_LIMIT
                ? filteredApplicants
                : filteredApplicants.slice(0, SEND_LIMIT);
          }
          selectedRegisterApplicants.value =
            setSelectedRegisterApplicantsIds.map(v => v.id);
        } else {
          selectedRegisterApplicants.value = registerApplicantIds.value;
          // LINE友達を表示させない時は初期値(全選択)を入れない
          if (props.isDispLineFriends) {
            selectedLineFriendApplicants.value = lineFriendApplicantIds.value;
          }
        }
      },
    );

    // methods
    const onClickAdd = () => {
      if (
        selectedAllApplicants.value.length > SEND_LIMIT &&
        props.modalType !== 'bulk-selection'
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `通知する人数は${SEND_LIMIT}名以内に設定してください`,
          type: false,
        });
      } else if (isSelectedApplicant.value) {
        if (props.isConfirm === true) {
          if (props.modalType === 'result-announce') {
            postPottosEvent(30);
          } else if (props.modalType === 'venue-announce') {
            postPottosEvent(24);
          }
        }
        context.emit('onClickAdd', selectedAllApplicants.value);
      }
    };
    const onClickSendWithAnnounce = () => {
      if (
        selectedAllApplicants.value.length > SEND_LIMIT &&
        props.modalType !== 'bulk-selection'
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `通知する人数は${SEND_LIMIT}名以内に設定してください`,
          type: false,
        });
      } else if (isSelectedApplicant.value) {
        if (props.isConfirm === true) {
          if (props.modalType === 'result-announce') {
            postPottosEvent(30);
          } else if (props.modalType === 'venue-announce') {
            postPottosEvent(24);
          }
        }
        context.emit('onClickSendWithAnnounce', selectedAllApplicants.value);
      }
    };
    const onChangeCurrentSelectRegisterApplicants = payload => {
      selectedRegisterApplicants.value = payload.selectedApplicants;
    };
    const onChangeCurrentSelectLineFriends = payload => {
      selectedLineFriendApplicants.value = payload.selectedApplicants;
    };
    const onSwitchTab = value => {
      dispApplicants.value = value;
    };

    return {
      sendLimit: SEND_LIMIT,
      selectedApplicants,
      selectedRegisterApplicants,
      selectedLineFriendApplicants,
      dispApplicants,
      isSelectedApplicant,
      lineFriendApplicantsList,
      selectedAllApplicants,
      announcedApplicantsCount,
      notAnnouncedApplicantsCount,
      notAnnouncedResultCount,
      registerApplicantsList,
      onClickAdd,
      onClickSendWithAnnounce,
      onChangeCurrentSelectRegisterApplicants,
      onChangeCurrentSelectLineFriends,
      onSwitchTab,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
  overflow-y: auto;
  text-align: left;
}

.si-block {
  margin-bottom: 30px;
  background-color: #fff;
  > .headline {
    padding: 15px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  .inner {
    padding: 30px;
    .switch_inner {
      margin-bottom: 20px;
      // display: flex;
      // justify-content: space-between;
    }
    .applicant_counter {
      .title_text {
        font-weight: bold;
      }
      .count_text {
        font-size: 1.8rem;
        margin-right: 20px;
      }
    }
  }
}

.edit-block {
  + .edit-block {
    margin-top: 30px;
  }
  .title {
    margin-bottom: 5px;
    line-height: 1.4;
    font-size: 1.2rem;
  }
  .value {
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
    line-height: 1.2;
    font-size: 1.6rem;
  }
}

.btn-col2 {
  width: 90%;
  margin: 30px auto;
  .submit-button {
    padding: 15px 10px;
  }
}
.mb_120 {
  margin-bottom: 120px;
}

@media (max-width: ($media_query_tablet)) {
  .si-block {
    > .headline {
      line-height: 1.4;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .si-block {
    .inner {
      padding: 20px;
    }
  }
}
</style>
