<template>
  <div>
    <div class="evaluation-block">
      <p class="headline">
        担当者評価
        <span v-if="comment">
          ：{{ comment.staff_lastname }} {{ comment.staff_firstname }}
        </span>
      </p>
      <div v-if="isEditOperator === true">
        <textarea
          v-model="commentMessage"
          class="comment_area"
          placeholder="担当者の方は評価を入力してください"
          maxlength="1000"
        />
      </div>
      <textarea v-else readonly :value="commentMessage" class="comment_area" />
      <div
        v-if="canEditOperatorEvaluation === true"
        class="btns-area btn-centerArea"
      >
        <button-base
          v-if="isEditOperator !== true && isPreview !== true"
          button-text="担当者評価を入力"
          icon-file-name="pen_white"
          :rejected-admin-ids="[2]"
          @click="isEditOperator = true"
        />
        <div
          v-if="isEditOperator === true && isPreview !== true"
          class="evaluation-btn-wrapper"
        >
          <button class="btn btn-unavailable" @click="cancelUpdateComment()">
            キャンセル
          </button>
          <button-base
            button-text="評価を確定"
            button-type="success"
            icon-file-name="check_white"
            :is-loading="isLoading"
            :is-disabled="isPreview"
            :rejected-admin-ids="[2]"
            @click="
              updateComment();
              postPottosEvent(25);
            "
          />
        </div>
      </div>
    </div>
    <div class="evaluation-block">
      <p class="headline">
        人事評価
        <span v-if="evaluation">
          ：{{ evaluation.staff_lastname }} {{ evaluation.staff_firstname }}
        </span>
      </p>
      <div :class="{ edit: isEditManager }">
        <div v-if="isEditManager === true">
          <textarea
            v-model="evaluationMessage"
            class="comment_area"
            :class="{
              'is-danger': errors.evaluationMessage.isValid !== true,
            }"
            placeholder="人事の方は評価を入力してください"
            name="manager-comment"
            maxlength="1000"
          />
          <p
            v-if="errors.evaluationMessage.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.evaluationMessage.message }}
          </p>
        </div>
        <textarea
          v-else
          readonly
          :value="evaluationMessage"
          class="comment_area"
        />
      </div>
      <button-progress-status
        v-show="!progress.status.announced && isEditManager === true"
        :progress-state-name="progressStateName"
        :is-editing="isEditManager"
        @onChangeProgressState="onChangeProgressState"
      />

      <div
        v-if="isEvaluatableStatus === true && !progress.status.announced"
        class="btns-area btn-centerArea"
      >
        <button-base
          v-if="!isEditManager && !isPreview"
          button-text="人事評価を入力"
          icon-file-name="pen_white"
          :rejected-role-ids="[3]"
          :rejected-admin-ids="[2]"
          @click="isEditManager = true"
        />
        <div
          v-if="isEditManager === true && isPreview !== true"
          class="evaluation-btn-wrapper"
        >
          <button class="btn btn-unavailable" @click="cancelUpdateStatus()">
            キャンセル
          </button>
          <button-base
            button-text="評価を確定"
            button-type="success"
            icon-file-name="check_white"
            :is-loading="isLoading"
            :is-disabled="isPreview"
            :rejected-role-ids="[3]"
            :rejected-admin-ids="[2]"
            @click="
              updateStatus();
              postPottosEvent(25);
            "
          />
        </div>
      </div>
    </div>
    <div v-if="isModeEvaluation === true" class="now-status">
      <div class="status" :class="getStatusClass(progressStatusName)">
        <p>{{ statusName }}</p>
      </div>
      <div
        v-if="
          isCommitted === true &&
          isCurrentProgress === true &&
          isNotAnnounced !== true &&
          isPreview !== true
        "
        class="reStatus"
      >
        <button
          class="btn btn-reStatus"
          :disabled="isLoading"
          @click.prevent="revertProgress"
        >
          <img class="icon" src="@/assets/img/loading_blue.svg" />
          通知前の状態に戻す
        </button>
      </div>
      <div
        v-if="
          progress.status.announced &&
          progressStatus.offered === progressStatusName &&
          !isPreview
        "
      >
        <button-base
          class="btn-offer_canceled btn offered_choice"
          button-text="内定辞退"
          :is-loading="isLoading"
          :rejected-role-ids="[3]"
          :rejected-admin-ids="[2]"
          @click="progressAfterOffered(progressStatus.offer_canceled)"
        />
        <button-base
          class="btn-hired btn offered_choice"
          button-text="採用"
          :is-loading="isLoading"
          :rejected-role-ids="[3]"
          :rejected-admin-ids="[2]"
          @click="progressAfterOffered(progressStatus.hired)"
        />
      </div>
    </div>
    <matched-actions-modal
      :is-view="isViewMatchAutoActionsModal"
      :actions="matchActions"
      :target-item="{
        type: 'status',
        target:
          PROGRESS_STATUSES_LABEL[
            progressStatusNameToId(execPayload.targetStatus)?.progress_status_id
          ] ??
          FINISHED_PROGRESS_STATUSES_LABEL[
            progressStatusNameToId(execPayload.targetStatus)
              ?.finished_progress_status_id
          ],
      }"
      @submit="execOperation"
      @close="isViewMatchAutoActionsModal = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import applicantService from '@/services/applicants';
import useValidation from '@/composables/useValidation';
import ButtonProgressStatus from '@/components/ui/buttons/components/ButtonProgressStatus';
import { postPottosEvent } from '@/utils/pottos';
import MatchedActionsModal from '@/components/features/autoActions/modals/MatchedActionsModal';

import autoActionsService from '@/services/autoActions.js';
import {
  PROGRESS_STATUSES,
  FINISHED_PROGRESS_STATUSES,
  PROGRESS_STATUSES_LABEL,
  FINISHED_PROGRESS_STATUSES_LABEL,
} from '@/defines/progresses';

export default defineComponent({
  name: 'ApplicantEvaluation',
  components: { ButtonProgressStatus, MatchedActionsModal },
  props: {
    isCommitted: {
      type: Boolean,
      default: false,
    },
    isCurrentProgress: {
      type: Boolean,
      default: false,
    },
    isNotAnnounced: {
      type: Boolean,
      default: false,
    },
    isOperator: {
      type: Boolean,
      default: false,
    },
    isManager: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    progressStatusName: {
      type: String,
      default: '',
    },
    progressId: {
      type: Number,
      default: null,
    },
    comment: {
      type: Object,
      default: null,
    },
    evaluation: {
      type: Object,
      default: null,
    },
    progress: {
      type: Object,
      default: null,
    },
    applicantGraduatedId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'determineOperatorEvaluation',
    'determineManagerEvaluation',
    'undoManagerEvaluation',
  ],
  setup(props, context) {
    const progressStatus = {
      not_set_selection: 'not_set_selection', // 選考未割当て
      not_announced: 'not_announced', // 未案内
      announced: 'announced', // 案内済
      not_evaluated: 'not_evaluated', // 未評価
      pending: 'pending', // 人事待ち
      passed: 'passed', // 通過
      offered: 'offered', // 内定
      dropped: 'dropped', // 不採用
      hired: 'hired', // 採用
      canceled: 'canceled', // 辞退
      offer_canceled: 'offer_canceled', // 内定辞退
    };
    const { errors, resetErrors, validateRequire } = useValidation([
      'evaluationMessage',
    ]);
    const store = useStore();
    const isLoading = ref(false);
    const isEditOperator = ref(false);
    const isEditManager = ref(false);
    const commentMessage = ref(props.comment ? props.comment.message : '');
    const evaluationMessage = ref(
      props.evaluation ? props.evaluation.message : '',
    );
    const commentStaffFirstname = ref(
      props.comment ? props.comment.staff_firstname : '',
    );
    const commentStaffLastname = ref(
      props.comment ? props.comment.staff_lastname : '',
    );
    const evaluationStaffFirstname = ref(
      props.evaluation ? props.evaluation.staff_firstname : '',
    );
    const evaluationStaffLastname = ref(
      props.evaluation ? props.evaluation.staff_lastname : '',
    );
    const progressStateName = ref(props.progress.status.name || '');

    const isViewMatchAutoActionsModal = ref(false);
    const matchActions = ref([]);
    const execPayload = ref({});

    // computed
    const applicant = computed(() => store.getters['applicant/applicant']);
    const canEditOperatorEvaluation = computed(() => {
      // 結果通知済の場合は評価を編集できない
      if (props.progress.status.announced) return false;
      // 管理者／人事権限は無条件で編集可能
      if (props.isManager) return true;
      // 応募者の担当が担当者の場合
      if (props.isOperator) {
        if (
          // 担当者は '未案内', '案内済', '未評価', '人事待ち' の応募者のみ編集可
          props.progressStatusName === 'not_announced' ||
          props.progressStatusName === 'announced' ||
          props.progressStatusName === 'not_evaluated' ||
          props.progressStatusName === 'pending'
        ) {
          return true;
        }
      }
      return false;
    });
    const statusName = computed(() => {
      switch (props.progressStatusName) {
        case progressStatus.passed:
          return '現在の評価は「通過」';
        case progressStatus.canceled:
          return '現在の評価は「辞退」';
        case progressStatus.dropped:
          return '現在の評価は「不採用」';
        case progressStatus.offered:
          return '現在の評価は「内定」';
        case progressStatus.hired:
          return '現在の評価は「採用」';
        case progressStatus.offer_canceled:
          return '現在の評価は「内定辞退」';
        default:
          return '';
      }
    });
    const isEvaluatableStatus = computed(
      () =>
        props.progressStatusName === progressStatus.pending ||
        props.progressStatusName === progressStatus.not_set_selection ||
        props.progressStatusName === progressStatus.not_announced ||
        props.progressStatusName === progressStatus.passed ||
        props.progressStatusName === progressStatus.offered ||
        props.progressStatusName === progressStatus.dropped ||
        props.progressStatusName === progressStatus.canceled ||
        props.progressStatusName === progressStatus.hired ||
        props.progressStatusName === progressStatus.offer_canceled,
    );
    const isModeEvaluation = computed(
      () =>
        props.progressStatusName === progressStatus.passed ||
        props.progressStatusName === progressStatus.canceled ||
        props.progressStatusName === progressStatus.dropped ||
        props.progressStatusName === progressStatus.offered ||
        props.progressStatusName === progressStatus.hired ||
        props.progressStatusName === progressStatus.offer_canceled,
    );

    // methods
    const getStatusClass = currentState => {
      const cssClass = [];
      if (currentState === progressStatus.passed) {
        cssClass.push('btn-passed');
        return cssClass;
      }
      if (currentState === progressStatus.canceled) {
        cssClass.push('btn-canceled');
        return cssClass;
      }
      if (currentState === progressStatus.dropped) {
        cssClass.push('btn-dropped');
        return cssClass;
      }
      if (currentState === progressStatus.offered) {
        cssClass.push('btn-offered');
        return cssClass;
      }
      if (currentState === progressStatus.hired) {
        cssClass.push('btn-hired');
        return cssClass;
      }
      if (currentState === progressStatus.offer_canceled) {
        cssClass.push('btn-offer_canceled');
        return cssClass;
      }
      return '';
    };
    // 評価を変更
    const onChangeProgressState = payload => {
      if (isEditManager.value !== true) return;
      progressStateName.value = payload.progressStateName;
    };
    // 担当者評価送信
    const updateComment = async () => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      execPayload.value = {
        emit: 'determineOperatorEvaluation',
        targetStatus: progressStatus.pending,
        progressStatus: progressStateName.value,
        evaluation: commentMessage.value,
        isOperator: true,
        isManager: false,
        progressId: props.progressId,
        statusType: progressStatus,
        closeOperatorEvaluationForm: () => {
          isEditOperator.value = false;
        },
        finishLoading: () => {
          isLoading.value = false;
        },
      };
      if (!isModeEvaluation.value) {
        await checkMatchActions(execPayload.value.targetStatus);
      } else {
        isLoading.value = false;
        context.emit(execPayload.value.emit, execPayload.value);
      }
    };
    // 担当者評価キャンセル
    const cancelUpdateComment = () => {
      commentMessage.value = props.comment ? props.comment.message : '';
      isEditOperator.value = false;
    };
    // 人事評価送信
    const updateStatus = async () => {
      resetErrors();
      if (
        validateRequire('evaluationMessage', evaluationMessage.value) !== true
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '人事評価を入力し、選考結果を選択してください',
          type: false,
        });
        return;
      }
      if (
        progressStateName.value !== progressStatus.not_set_selection &&
        progressStateName.value !== progressStatus.not_announced &&
        progressStateName.value !== progressStatus.announced &&
        progressStateName.value !== progressStatus.not_evaluated &&
        progressStateName.value !== progressStatus.pending &&
        progressStateName.value !== undefined
      ) {
        if (isLoading.value === true) return;
        isLoading.value = true;
        execPayload.value = {
          emit: 'determineManagerEvaluation',
          targetStatus: progressStateName.value,
          progressStatus: progressStateName.value,
          evaluation: evaluationMessage.value,
          isOperator: false,
          isManager: true,
          progressId: props.progressId,
          statusType: progressStatus,
          closeManagerEvaluationForm: () => {
            isEditManager.value = false;
          },
          finishLoading: () => {
            isLoading.value = false;
          },
        };
        await checkMatchActions(progressStateName.value);
        return;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '選考結果を選択してください。',
        type: false,
      });
    };
    // 人事評価キャンセル
    const cancelUpdateStatus = () => {
      evaluationMessage.value = props.evaluation
        ? props.evaluation.message
        : '';
      progressStateName.value = props.progress.status.name || '';
      isEditManager.value = false;
    };
    // 通知前の状態に戻す
    const revertProgress = async () => {
      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: 'この応募者の選考を結果通知前の状態に戻します',
        message:
          'この応募者の選考を結果通知前の状態に戻します。人事評価の再入力ができるようになります。',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          if (payload.choice === 2) {
            isLoading.value = true;
            const res = await applicantService.currentProgressRevert(
              applicant.value.id,
            );
            isLoading.value = false;
            store.dispatch('notification/VISIBLE_NOTIFICATION', {
              message: res.message,
              type: res.success,
            });
            if (res.success === true) context.emit('applicantFetchAllApi');
          }
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        onclickOuter: () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    // 内定辞退・採用送信
    const progressAfterOffered = async val => {
      if (
        progressStateName.value !== progressStatus.not_set_selection &&
        progressStateName.value !== progressStatus.not_announced &&
        progressStateName.value !== progressStatus.announced &&
        progressStateName.value !== progressStatus.not_evaluated &&
        progressStateName.value !== progressStatus.pending &&
        progressStateName.value !== undefined
      ) {
        if (isLoading.value === true) return;
        isLoading.value = true;
        progressStateName.value = val;
        isEditOperator.value = false;
        execPayload.value = {
          emit: 'undoManagerEvaluation',
          targetStatus: progressStateName.value,
          progressStatus: progressStateName.value,
          evaluation: evaluationMessage.value,
          isOperator: false,
          isManager: true,
          progressId: props.progressId,
          statusType: progressStatus,
          closeManagerEvaluationForm: () => {
            isEditManager.value = false;
          },
          finishLoading: () => {
            isLoading.value = false;
          },
        };
        await checkMatchActions(progressStateName.value);
        return;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: 'この選考のステータスは変更できません',
        type: false,
      });
    };

    const checkMatchActions = async payloadStatus => {
      const { data } = await autoActionsService.checkMatchAction(
        applicant.value.id,
        {
          graduated_id: props.applicantGraduatedId,
          selection_id: applicant.value.status.selectionId,
          status: progressStatusNameToId(payloadStatus),
        },
      );
      matchActions.value = data.actions;
      if (data.actions.length > 0) {
        isViewMatchAutoActionsModal.value = true;
        isLoading.value = false;
      } else {
        context.emit(execPayload.value.emit, execPayload.value);
      }
    };

    const execOperation = () => {
      isLoading.value = true;
      context.emit(execPayload.value.emit, execPayload.value);
      isViewMatchAutoActionsModal.value = false;
    };

    const progressStatusNameToId = statusName => {
      const progressStatusNameMap = {
        [progressStatus.not_set_selection]: 0,
        [progressStatus.not_announced]: 1,
        [progressStatus.announced]: 2,
        [progressStatus.not_evaluated]: 4,
        [progressStatus.pending]: 5,
      };

      const finishedProgressStatusNameMap = {
        [progressStatus.hired]: 1,
        [progressStatus.offered]: 2,
        [progressStatus.dropped]: 3,
        [progressStatus.canceled]: 4,
        [progressStatus.offer_canceled]: 5,
        [progressStatus.passed]: 6,
      };

      const progressStatusId = progressStatusNameMap[statusName];
      const finishedProgressStatusId =
        finishedProgressStatusNameMap[statusName];
      return {
        progress_status_id: progressStatusId ?? null,
        finished_progress_status_id: finishedProgressStatusId ?? null,
      };
    };

    return {
      errors,
      isLoading,
      isEditOperator,
      isEditManager,
      commentMessage,
      commentStaffFirstname,
      commentStaffLastname,
      evaluationMessage,
      evaluationStaffFirstname,
      evaluationStaffLastname,
      progressStateName,
      applicant,
      progressStatus,
      statusName,
      canEditOperatorEvaluation,
      isEvaluatableStatus,
      isModeEvaluation,
      matchActions,
      execPayload,
      isViewMatchAutoActionsModal,
      getStatusClass,
      updateComment,
      cancelUpdateComment,
      updateStatus,
      cancelUpdateStatus,
      onChangeProgressState,
      progressAfterOffered,
      revertProgress,
      postPottosEvent,
      execOperation,
      checkMatchActions,
      progressStatusNameToId,
      PROGRESS_STATUSES,
      FINISHED_PROGRESS_STATUSES,
      PROGRESS_STATUSES_LABEL,
      FINISHED_PROGRESS_STATUSES_LABEL,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@include validation_effects();

.evaluation-block {
  margin: 20px;
  textarea {
    resize: vertical;
  }
  .headline {
    margin-bottom: 10px;
    font-weight: bold;
  }
  .comment_area {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.btn-centerArea {
  margin-top: 20px;
}

.evaluation-btn-wrapper {
  justify-content: center;
}
.now-status {
  padding: 20px;
  background-color: #efefef;
  border-radius: 0 0 4px 4px;
  > .status {
    padding: 10px;
    text-align: center;
  }
}
.btn-passed {
  background-color: $passed_bg;
  color: #fff;
  font-weight: bold;
}
.btn-canceled {
  background-color: $canceled_bg;
  color: #fff;
  font-weight: bold;
}
.btn-dropped {
  background-color: $dropped_bg;
  color: #fff;
  font-weight: bold;
}
.btn-offered {
  background-color: $offered_bg;
  color: #fff;
  font-weight: bold;
}
.btn-offer_canceled {
  display: inline-block;
  margin-right: 2%;
  font-weight: bold;
  background-color: $offer_canceled_bg !important;
  color: #fff;
  opacity: 1;
  width: 100%;
  &.offered_choice {
    width: 49%;
  }
}
.btn-hired {
  display: inline-block;
  font-weight: bold;
  background-color: $hired_bg !important;
  color: #fff;
  opacity: 1;
  width: 100%;
  &.offered_choice {
    width: 49%;
  }
}
.reStatus {
  margin: 20px 0 5px;
  text-align: center;
}
.btn-reStatus {
  padding: 0;
  color: #16b2d9 !important;
  margin-bottom: 1rem;
}

.error_message {
  margin-bottom: 15px;
}

@media (max-width: ($media_query_sp)) {
  .btn {
    width: 100%;
  }
  .btn + .btn {
    margin: 10px 0;
  }

  .evaluation-btn-wrapper {
    flex-direction: column;
  }
}
</style>
