<template>
  <div>
    <div class="btn_back">
      <p @click="onClickCancelLinkGoogleCalendar">システム連携に戻る</p>
    </div>
    <template v-if="isConnectedZoom !== true">
      <dl class="setting_list">
        <dd class="setting_text">
          <p class="setting_title">Zoom連携</p>
          <p>
            Zoomの管理画面で設定したクライアントIDとクライアントシークレットを入力後、
            <br />
            連携ボタンをクリックして下さい。
          </p>
          <!-- <p class="link_box">
            クライアントIDとクライアントシークレットは
            <a href="" target="_blank">こちらの記事</a>
            を参考にしてご用意ください。
          </p> -->
        </dd>
      </dl>
      <dl class="setting_list">
        <dt>
          クライアントID
          <span>必須</span>
        </dt>
        <dd>
          <input v-model="clientId" type="text" class="zoom_input" />
        </dd>
      </dl>
      <dl class="setting_list">
        <dt>
          クライアントシークレット
          <span>必須</span>
        </dt>
        <dd>
          <input v-model="clientSecret" type="text" class="zoom_input" />
        </dd>
      </dl>
      <dl class="setting_list">
        <dd>
          <button-base
            class="btn btn-send"
            button-text="連携"
            button-type="success"
            :is-loading="isLoading"
            :rejected-role-ids="[2, 3]"
            :rejected-admin-ids="[2]"
            @click="linkZoom"
          />
        </dd>
      </dl>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import ZoomService from '@/services/zoom';

export default defineComponent({
  name: 'LinkZoomLinkClient',
  props: {
    isConnectedZoom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeMode'],
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const clientId = ref('');
    const clientSecret = ref('');

    // methods
    const onClickCancelLinkGoogleCalendar = () => {
      context.emit('changeMode', 'home');
    };
    const linkZoom = async () => {
      if (validate() !== true) return;
      isLoading.value = true;
      const res = await ZoomService.linkZoom({
        client_id: clientId.value,
        client_secret: clientSecret.value,
      });
      if (res.success === true) {
        // 成功時のみZoomログイン画面にリダイレクト
        window.location.href = res.url;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: false,
          message: res.message,
        });
        isLoading.value = false;
      }
    };
    const validate = () => {
      if (clientId.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'クライアントIDを入力してください',
          type: false,
        });
        return false;
      }
      if (clientSecret.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'クライアントシークレットを入力してください',
          type: false,
        });
        return false;
      }
      return true;
    };

    return {
      clientId,
      clientSecret,
      isLoading,
      onClickCancelLinkGoogleCalendar,
      linkZoom,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.zoom_link {
  padding-top: 50px;
  margin-top: 50px;
  border-top: 1px solid #e5e5e5;
}

.setting_title {
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #5896cc;
  border-left: 6px solid #5896cc;
}

.setting_text {
  margin-bottom: 24px;
  line-height: 1.75;
  > span {
    display: block;
    margin-top: 5px;
  }
}

.setting_caption {
  color: #777;
  font-size: 12px;
}

.setting_list {
  border-top: 1px solid #e5e5e5;
  padding-top: 24px;
  margin-bottom: 24px;
  > dt {
    margin-bottom: 18px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: bold;
    > span {
      display: inline-block;
      width: 44px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      background-color: #bb1b1c;
      border-radius: 4px;
      text-align: center;
    }
  }
  > dd {
    margin-bottom: 18px;

    > p {
      margin-bottom: 10px;
    }
  }
}

.zoom_input {
  width: 100%;
  max-width: 380px;
}

.btn-availability {
  width: 189px;
  height: 40px;
  &.btn-long {
    margin-top: 15px;
    width: 260px;
  }
  &:disabled {
    color: #333 !important;
    background-color: #999;
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.btn-unavailable {
  width: 189px;
  height: 40px;
}

@media (max-width: ($media_query_sp)) {
  .resource_box {
    width: 100%;
  }
}

.setting_title {
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #5896cc;
  border-left: 6px solid #5896cc;
}

.setting_text {
  margin-bottom: 24px;
  line-height: 1.75;
  > span {
    display: block;
    margin-top: 5px;
  }
}

.setting_list {
  border-top: 1px solid #e5e5e5;
  padding-top: 24px;
  margin-bottom: 24px;
  > dt {
    margin-bottom: 18px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: bold;
    > span {
      display: inline-block;
      width: 44px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      background-color: #bb1b1c;
      border-radius: 4px;
      text-align: center;
    }
  }
  > dd {
    margin-bottom: 18px;

    > p {
      margin-bottom: 10px;
    }

    > .input-file_wrapper {
      margin-bottom: 20px;
      > label {
        display: inline-block;
        width: 126px;
        height: 40px;
        margin-top: 18px;
        margin-right: 10px;
        line-height: 40px;
        text-align: center;
        background-color: #efefef;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        cursor: pointer;
      }
      .file_name {
        color: #777;
        vertical-align: 2px;
        line-height: 1;
      }
    }
  }
}

.btn_back {
  margin-bottom: 27px;
  > p {
    padding-left: 15px;
    line-height: 1;
    background-image: url('../../../../../assets/img/icon-back.svg');
    background-size: auto 12px;
    background-position: 0 1.5px;
    background-repeat: no-repeat;
    color: #5896cc;
    cursor: pointer;
  }
}

.link_box {
  padding: 10px 16px;
  margin-top: 16px;
  border: 1px solid #d7e5ee;
  line-height: 20px;
  border-radius: 4px;
  background: #f3f9fd;
  > a {
    font-weight: bold;
    padding: 0 17px 2px 0;
    color: #1899d6;
    text-decoration: underline;
    background-image: url('../../../../../assets/img/icon-external-link.svg');
    background-size: 14px 14px;
    background-position: right 1px;
    background-repeat: no-repeat;
  }
}

.input-file {
  display: none; /* アップロードボタンのスタイルを無効にする */
}

.btn-send {
  width: 100%;
  height: 54px;
  background-color: #12b312;
  &.btn-long {
    margin-top: 15px;
    width: 260px;
  }
  &:disabled {
    color: #333 !important;
    background-color: #999;
    opacity: 0.3;
    cursor: not-allowed;
  }
}
</style>
