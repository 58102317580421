<template>
  <transition name="fade">
    <div v-if="isView" ref="refBalloonMenu" class="balloon-box" :style="styles">
      <div class="box-container">
        <slot name="content" />
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

export default defineComponent({
  name: 'BalloonMenu',
  props: {
    zIndex: {
      type: Number,
      default: 10,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: null,
    },
    left: {
      type: String,
      default: null,
    },
    right: {
      type: String,
      default: null,
    },
    bottom: {
      type: String,
      default: null,
    },
    suspendCloseEvent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, context) {
    const refBalloonMenu = ref(null);
    const styles = () => {
      let style = {};
      if (props.top) style = Object.assign({}, style, { top: props.top });
      if (props.left) style = Object.assign({}, style, { left: props.left });
      if (props.right) style = Object.assign({}, style, { right: props.right });
      if (props.bottom) {
        style = Object.assign({}, style, { bottom: props.bottom });
      }
      if (props.zIndex) {
        style = Object.assign({}, style, { 'z-index': props.zIndex });
      }
      return style;
    };
    const onClickOuter = ev => {
      if (
        props.suspendCloseEvent === true ||
        props.isView !== true ||
        (refBalloonMenu.value && refBalloonMenu.value.contains(ev.target))
      ) {
        // 自分のコンポーネント内の要素をクリックした場合は閉じない
        return;
      }
      context.emit('close');
    };

    onMounted(() => {
      window.addEventListener('click', onClickOuter, true);
    });
    onUnmounted(() => {
      window.removeEventListener('click', onClickOuter, true);
    });

    return { refBalloonMenu, styles };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.balloon-box {
  width: max-content;
  position: absolute;
  z-index: 20;
  top: 40px;
  left: 10px;
  border: 2px solid $mochica_color;
  border-radius: 4px;
  background-color: #fff;
}
@media (max-width: ($media_query_sp)) {
  .balloon-box {
    left: 0;
  }
}
.balloon-box {
  & :deep(.flag-add-btn-svg) {
    width: 2rem;
    height: 2rem;
  }
}
.balloon-box:before,
.balloon-box:after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  width: 0;
  height: 0;
  margin: 0 0 0 10px;
}
.balloon-box:before {
  top: -12px;
  border-style: solid;
  border-color: transparent transparent $mochica_color transparent;
  border-width: 0 9px 10px 9px;
}
.balloon-box:after {
  top: -9px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  border-width: 0 9px 10px 9px;
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
