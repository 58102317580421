<template>
  <div class="flag_survey_question">
    <p class="question_subject">以下のアンケートにお答えください</p>
    <ul>
      <li v-for="(question, index) in questions" :key="`question${index}`">
        <div class="question_text">
          質問 {{ index + 1 }}
          <span class="required">*</span>
        </div>
        <div class="question_title">
          {{
            question.questionTitle
              ? question.questionTitle
              : question.question_title
          }}
          <b v-if="question.multi">（複数回答可）</b>
        </div>
        <ul v-if="question.answers" class="options">
          <li
            v-for="(answer, answerIndex) in question.answers"
            :key="`answer${answerIndex}`"
          >
            <div
              class="option"
              :class="answer.isSelected ? 'is-active' : ''"
              @click.prevent="$emit('on-select-answer', index, answerIndex)"
            >
              <p class="dummy_checklayout">
                <span
                  v-if="question.multi"
                  class="dummy_checkbox"
                  :class="{ checked: answer.isSelected }"
                />
                <span
                  v-else
                  class="dummy_radio"
                  :class="{ checked: answer.isSelected }"
                />
              </p>
              <p class="flag_name">{{ answer.flag_name }}</p>
            </div>
          </li>
        </ul>
        <ul
          v-if="
            question.tagGroups &&
            question.tagGroups.find(tagGroup => tagGroup.isSelected)
          "
        >
          <li
            v-for="(answer, answerIndex) in question.tagGroups.find(
              tagGroup => tagGroup.isSelected,
            ).tags"
            :key="`answer${answerIndex}`"
          >
            <div
              class="option"
              :class="answer.isSelected ? 'is-active' : ''"
              @click.prevent="$emit('on-select-answer', index, answerIndex)"
            >
              <span class="flag_name">{{ answer.name }}</span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FlagsurveyQuestionsSelector',
  props: {
    questions: {
      type: Array,
      default: () => {},
    },
  },
  emits: ['on-select-answer'],
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.dummy_checklayout {
  width: 50px;
  display: flex;
  padding-top: 17px;
  justify-content: center;
  align-items: flex-start;
  .dummy_checkbox {
    position: relative;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    cursor: pointer;
    &.checked {
      border: 1px solid #20a8e9;
      &::after {
        content: url(../../../../assets/img/check.svg);
        height: 1.1rem;
        width: 1.5rem;
        margin: -5px 0 0 1px;
        position: absolute;
        bottom: 6px;
        left: 0;
      }
    }
  }
  .dummy_radio {
    position: relative;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #bcbcbc;
    border-radius: 18px;
    cursor: pointer;
    &.checked {
      border: 1px solid #20a8e9;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        border: 1px solid #20a8e9;
        border-radius: 6px;
        background-color: #20a8e9;
      }
    }
  }
}
.flag_name {
  width: 100%;
  padding: 13px 13px 13px 0px;
  word-break: break-all;
  white-space: pre-wrap;
  text-align: left;
}

.flag_survey_question {
  padding-top: 20px;
  border-top: 4px solid #f0f0f0;
}

.question_subject {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 18px;
}

.question_text {
  color: #777777;
  text-align: left;
  font-weight: bold;
}

.question_title {
  margin-bottom: 10px;
  text-align: left;
  word-break: break-all;
  white-space: pre-wrap;
  font-weight: bold;
}

.options {
  margin-bottom: 30px;
}
.required {
  color: #ee3030;
  font-weight: bold;
  font-size: 14px;
}
.option {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  background-color: #fafafa;
  transition: all ease 0.3s;
  cursor: pointer;

  &.is-active {
    font-weight: bold;
    border: 1px solid #20a8e9;
    color: #20a8e9;
    background-color: #e9f7fd;
  }
}
</style>
