<template>
  <div>
    <div class="breadcrumb">
      <span class="chip" @click="$emit('onHomeClick')">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">トップ</span>
      </span>
      <span class="chip" @click="changeMode('default')">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">登録情報の確認</span>
      </span>
      <span
        v-if="mode === 'editing' || mode === 'confirming'"
        class="chip"
        @click="changeMode('editing')"
      >
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">登録情報の変更</span>
      </span>
      <span v-if="mode === 'confirming'" class="chip">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">変更内容の確認</span>
      </span>
    </div>

    <div class="title-header">
      <div class="bg-bold">{{ generateTitleDescription(mode).title }}</div>
      <div class="desc">{{ generateTitleDescription(mode).description }}</div>
    </div>
    <div class="gray-bg" />
    <div class="user-info-body">
      <div v-if="mode !== 'completed'">
        <p class="bold-thin-black top-space1 bottom-space1">登録情報</p>
        <p class="border-bottom-space-md" />
      </div>
      <div>
        <div v-if="mode === 'default'">
          <ul class="applicant-info-list">
            <li>
              <p class="title">氏名（変更不可）</p>
              <p class="value">{{ applicantName }}</p>
            </li>
            <li>
              <p class="title">連絡方法（変更不可）</p>
              <p class="value">
                {{ contactTypes[applicantObj.contact_type_id] }}
              </p>
            </li>
            <li>
              <p class="title">メールアドレス（変更不可）</p>
              <p class="value">{{ applicantObj.email }}</p>
            </li>
            <li>
              <p class="title">性別</p>
              <p class="value">
                {{
                  genderOptions[applicantObj.gender_id - 1]
                    ? genderOptions[applicantObj.gender_id - 1].description
                    : '-'
                }}
              </p>
            </li>
            <li>
              <p class="title">学校名</p>
              <p class="value">
                {{ applicantObj.school ? applicantObj.school : '-' }}
              </p>
            </li>
            <li>
              <p class="title">学部</p>
              <p class="value">
                {{ applicantObj.faculty ? applicantObj.faculty : '-' }}
              </p>
            </li>
            <li>
              <p class="title">学科</p>
              <p class="value">
                {{ applicantObj.department ? applicantObj.department : '-' }}
              </p>
            </li>
            <li>
              <p class="title">文理</p>
              <p class="value">
                {{
                  departmentCategories[applicantObj.department_category_id - 1]
                    ? departmentCategories[
                        applicantObj.department_category_id - 1
                      ].description
                    : '-'
                }}
              </p>
            </li>
            <li>
              <p class="title">電話番号</p>
              <p class="value">
                {{ applicantObj.tel ? applicantObj.tel : '-' }}
              </p>
            </li>
            <li>
              <p class="title">郵便番号</p>
              <p class="value">
                〒{{ applicantObj.zipcode ? applicantObj.zipcode : '-' }}
              </p>
            </li>
            <li>
              <p class="title">住所</p>
              <p class="value">
                {{ applicantObj.address ? applicantObj.address : '-' }}
              </p>
            </li>
            <li>
              <p class="title">生年月日</p>
              <p class="value">{{ showBirthDate(applicantObj.birth) }}</p>
            </li>
          </ul>
          <div>
            <button-round
              class="btn-round"
              button-text="登録情報を変更"
              :is-outlined="true"
              @click="changeMode('editing')"
            />
          </div>
        </div>
        <div v-else-if="mode === 'editing'">
          <ul class="applicant-info-list">
            <li>
              <p class="title">氏名（変更不可）</p>
              <p class="value">{{ applicantName }}</p>
            </li>
            <li>
              <p class="title">連絡方法（変更不可）</p>
              <p class="value">
                {{ contactTypes[applicantObj.contact_type_id] }}
              </p>
            </li>
            <li>
              <p class="title">メールアドレス（変更不可）</p>
              <p class="value">{{ applicantObj.email }}</p>
            </li>
            <li>
              <p class="title">性別</p>
              <radio-group
                :checked-idx="genderIdx"
                :on-change="changeGender"
                :options="genderOptions"
              />
            </li>
            <li>
              <p class="title">学校名</p>
              <div class="value">
                <input
                  v-model="applicantObj.school"
                  type="text"
                  name="school"
                  placeholder="学校名を入力してください"
                  maxlength="50"
                  class="edited-input"
                  data-vv-as="学校名"
                />
              </div>
            </li>
            <li>
              <p class="title">学部</p>
              <div class="value">
                <input
                  v-model="applicantObj.faculty"
                  type="text"
                  placeholder="学部名を入力してください"
                  name="faculty"
                  class="edited-input"
                  maxlength="50"
                  data-vv-as="学部"
                />
              </div>
            </li>
            <li>
              <p class="title">学科</p>
              <div class="value">
                <input
                  v-model="applicantObj.department"
                  type="text"
                  placeholder="学科名を入力してください"
                  name="department"
                  class="edited-input"
                  maxlength="50"
                  data-vv-as="学科"
                />
              </div>
            </li>
            <li>
              <p class="title">文理</p>
              <radio-group
                :checked-idx="departmentCategoryIdx"
                :on-change="changeDepartment"
                :options="departmentCategories"
              />
            </li>
            <li>
              <p class="title">電話番号</p>
              <div class="value">
                <input
                  v-model="applicantObj.tel"
                  type="text"
                  minlength="10"
                  maxlength="11"
                  class="edited-input"
                  placeholder="電話番号を入力してください"
                  name="tel"
                  data-vv-as="電話番号"
                  :class="{ 'is-danger': !errors.tel.isValid }"
                  @input="
                    validateOnInput('tel', applicantObj.tel, validateTel, false)
                  "
                />
              </div>
              <p v-if="!errors.tel.isValid" class="txt-warning">
                {{ errors.tel.message }}
              </p>
            </li>
            <li>
              <p class="title">郵便番号</p>
              <div class="value">
                <input
                  v-model="applicantObj.zipcode"
                  type="text"
                  minlength="7"
                  maxlength="7"
                  placeholder="郵便番号を入力してください"
                  name="zipcode"
                  class="edited-input"
                  data-vv-as="郵便番号"
                  :class="{ 'is-danger': !errors.zipcode.isValid }"
                  @input="
                    validateOnInput(
                      'zipcode',
                      applicantObj.zipcode,
                      validateZipcode,
                      false,
                    )
                  "
                />
              </div>
              <p v-if="!errors.zipcode.isValid" class="txt-warning">
                {{ errors.zipcode.message }}
              </p>
            </li>
            <li>
              <p class="title">住所</p>
              <div class="value">
                <input
                  v-model="applicantObj.address"
                  type="text"
                  name="address"
                  maxlength="50"
                  class="edited-input"
                  placeholder="住所を入力してください"
                  data-vv-as="住所"
                />
              </div>
            </li>
            <li>
              <p class="title">生年月日</p>
              <div class="date-of-birth value">
                <div>
                  <select
                    name="year"
                    @change="
                      changeBirthDate('year', $event.currentTarget.value)
                    "
                  >
                    <option
                      v-for="year in ['-', ...getYearOptions()]"
                      :key="year"
                      :selected="year === birthDate.year"
                    >
                      {{ year }}
                    </option>
                  </select>
                  <span>年</span>
                </div>
                <div>
                  <select
                    name="month"
                    @change="
                      changeBirthDate('month', $event.currentTarget.value)
                    "
                  >
                    <option
                      v-for="month in ['-', ...getMonthOptions()]"
                      :key="month"
                      :selected="month === birthDate.month"
                    >
                      {{ month }}
                    </option>
                  </select>
                  <span>月</span>
                </div>
                <div>
                  <select
                    name="day"
                    @change="changeBirthDate('day', $event.currentTarget.value)"
                  >
                    <option
                      v-for="day in ['-', ...getDayOptions()]"
                      :key="day"
                      :selected="day === birthDate.day"
                    >
                      {{ day }}
                    </option>
                  </select>
                  <span>日</span>
                </div>
              </div>
            </li>
          </ul>
          <div>
            <button-round
              class="btn-round"
              button-text="変更内容を確認"
              :has-arrow="true"
              @click="confirmApplicant()"
            />
          </div>
        </div>
        <div v-else-if="mode === 'confirming'">
          <ul class="applicant-info-list">
            <li>
              <p class="title">氏名（変更不可）</p>
              <p class="value">{{ applicantName }}</p>
            </li>
            <li>
              <p class="title">連絡方法（変更不可）</p>
              <p class="value">
                {{ contactTypes[applicant.contact_type_id - 1] }}
              </p>
            </li>
            <li>
              <p class="title">メールアドレス（変更不可）</p>
              <p class="value">{{ applicant.email }}</p>
            </li>
            <li>
              <p class="title">性別</p>
              <p class="value">
                {{
                  genderOptions[applicant.gender_id - 1]
                    ? genderOptions[applicant.gender_id - 1].description
                    : '-'
                }}
              </p>
            </li>
            <li>
              <p class="title">学校名</p>
              <p class="value">
                {{ applicant.school ? applicant.school : '-' }}
              </p>
            </li>
            <li>
              <p class="title">学部</p>
              <p class="value">
                {{ applicant.faculty ? applicant.faculty : '-' }}
              </p>
            </li>
            <li>
              <p class="title">学科</p>
              <p class="value">
                {{ applicant.department ? applicant.department : '-' }}
              </p>
            </li>
            <li>
              <p class="title">文理</p>
              <p class="value">
                {{
                  departmentCategories[applicant.department_category_id - 1]
                    ? departmentCategories[applicant.department_category_id - 1]
                        .description
                    : '-'
                }}
              </p>
            </li>
            <li>
              <p class="title">電話番号</p>
              <p class="value">{{ applicant.tel ? applicant.tel : '-' }}</p>
            </li>
            <li>
              <p class="title">郵便番号</p>
              <p class="value">
                〒{{ applicant.zipcode ? applicant.zipcode : '-' }}
              </p>
            </li>
            <li>
              <p class="title">住所</p>
              <p class="value">
                {{ applicant.address ? applicant.address : '-' }}
              </p>
            </li>
            <li>
              <p class="title">生年月日</p>
              <p class="value">{{ showBirthDate(applicant.birth) }}</p>
            </li>
          </ul>
          <div>
            <button-round
              class="btn-round btn-commit"
              button-text="登録情報を修正"
              button-type="secondary"
              @click="changeMode('editing')"
            />
            <button-round
              class="btn-round btn-commit"
              button-text="登録情報を保存"
              :has-arrow="true"
              :is-disabled="isLoading === true"
              @click="updateApplicant()"
            />
          </div>
        </div>
        <div v-else-if="mode === 'completed'">
          <p class="sm-bold-blue">登録情報の変更が完了しました。</p>
          <p class="desc">
            「登録情報に戻る」のボタンを押して変更された内容を確認してください。
          </p>
          <p class="big-spacer" />
          <button-round
            class="btn-round"
            button-text="登録情報に戻る"
            :is-outlined="true"
            @click="changeMode('default')"
          />
        </div>
      </div>
    </div>
    <local-page-loader v-if="isLoading" :is-full-size="true" />
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import useValidation from '@/composables/useValidation';
import moment from 'moment';

import {
  genders,
  departmentCategories,
  contactTypes,
} from '@/defines/applicant';
import MyPageService from '@/services/mypage';
import RadioGroup from '@/components/page/mypage/components/RadioGroup';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import ButtonRound from '@/components/page/mypage/components/ButtonRound.vue';

export default defineComponent({
  name: 'Profile',
  components: { RadioGroup, LocalPageLoader, ButtonRound },
  props: {
    applicant: {
      type: Object,
      required: true,
    },
    applicantName: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  emits: ['getApplicantData', 'onHomeClick'],
  setup(props, context) {
    const { errors, validateTel, validateZipcode, validateOnInput } =
      useValidation(['tel', 'zipcode']);
    const store = useStore();
    const mode = ref('default'); // 'default' | 'editing' | 'confirming' | 'completed'
    const isLoading = ref(false);
    const departmentCategoryIdx = ref(null);
    const genderIdx = ref(null);
    const genderOptions = ref(genders.slice(0, 3));
    const birthDate = ref({
      year: '-',
      month: '-',
      day: '-',
    });
    const applicantObj = ref(props.applicant);

    onBeforeMount(() => {
      departmentCategoryIdx.value = props.applicant.department_category_id;
      genderIdx.value = props.applicant.gender_id;
      if (props.applicant.birth) {
        setBirthDate();
      }
    });

    const setBirthDate = () => {
      birthDate.value.year = applicantObj.value.birth.slice(0, 4);
      birthDate.value.month = applicantObj.value.birth.slice(5, 7);
      birthDate.value.day = applicantObj.value.birth.slice(8, 10);
    };

    const resetBirthDate = () => {
      birthDate.value.year = '-';
      birthDate.value.month = '-';
      birthDate.value.day = '-';
    };

    const changeDepartment = id => {
      departmentCategoryIdx.value = id;
      applicantObj.value.department_category_id = id;
    };

    const showBirthDate = value => {
      if (value) {
        const parsedBirthDate = new Date(value).toISOString();
        return moment(parsedBirthDate).format('YYYY/MM/DD');
      }
      return '-';
    };

    const getYearOptions = () => {
      const currentYear = new Date().getFullYear();
      const min = currentYear - 60;
      const years = [];
      for (let i = min; i <= currentYear - 12; i += 1) {
        years.push(String(i));
      }
      return years;
    };

    const getMonthOptions = () => {
      return Array(12)
        .fill(null)
        .map((_, i) => {
          if (i < 9) return `0${i + 1}`;
          return String(i + 1);
        });
    };

    const getDayOptions = () => {
      return Array(31)
        .fill(null)
        .map((_, i) => {
          if (i < 9) return `0${i + 1}`;
          return String(i + 1);
        });
    };

    const changeBirthDate = (fieldName, newValue) => {
      if (fieldName === 'month') {
        birthDate.value.month = newValue;
      } else if (fieldName === 'day') {
        birthDate.value.day = newValue;
      } else if (fieldName === 'year') {
        birthDate.value.year = newValue;
      }
    };

    const changeGender = id => {
      genderIdx.value = id;
      applicantObj.value.gender_id = id;
    };

    const generateTitleDescription = modeName => {
      const returnText = {
        title: '登録情報の確認',
        description:
          '登録した氏名などの情報の確認や、学部学科情報などを確認、変更できます。',
      };
      switch (modeName) {
        case 'default':
          break;
        case 'editing':
          returnText.title = '登録情報の変更';
          returnText.description =
            '登録した氏名などの情報の確認や、学部学科情報などを変更できます。';
          break;
        case 'confirming':
          returnText.title = '変更内容の確認';
          returnText.description =
            '登録情報を以下の内容に変更します。内容をご確認ください。';
          break;
        case 'completed':
          returnText.title = '登録情報の変更';
          returnText.description = '';
          break;
        default:
          break;
      }
      return returnText;
    };

    const validateAll = () => {
      let isValid = true;
      if (
        !validateTel('tel', applicantObj.value.tel, false) ||
        !validateZipcode('zipcode', applicantObj.value.zipcode, false)
      ) {
        isValid = false;
      }
      return isValid;
    };

    const confirmApplicant = () => {
      if (!validateAll()) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '変更内容を確認してください',
          type: false,
        });
        return;
      }

      changeMode('confirming');
      if (
        birthDate.value.year !== '-' &&
        birthDate.value.month !== '-' &&
        birthDate.value.day !== '-'
      ) {
        applicantObj.value.birth = `${birthDate.value.year}-${birthDate.value.month}-${birthDate.value.day}`;
        return;
      }
      applicantObj.value.birth = null;
    };

    const updateApplicant = async () => {
      changeMode('completed');
      if (isLoading.value === true) return;
      isLoading.value = true;
      const data = await MyPageService.updateApplicant({
        token: props.token,
        applicant: applicantObj.value,
      });
      birthDate.value = {};
      isLoading.value = false;
      if (data.success === false) {
        await store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '個人情報の更新に失敗しました',
          type: false,
        });
        return;
      }
      context.emit('getApplicantData', props.token);
      if (applicantObj.value.birth !== null) {
        setBirthDate();
      } else {
        resetBirthDate();
      }
      await store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '個人情報の更新が完了しました',
        type: true,
      });
    };

    const changeMode = async modeName => {
      mode.value = modeName;
      window.scrollTo(0, 0);
    };

    return {
      mode,
      isLoading,
      departmentCategoryIdx,
      departmentCategories,
      genderIdx,
      genderOptions,
      contactTypes,
      birthDate,
      applicantObj,
      errors,
      onBeforeMount,
      setBirthDate,
      resetBirthDate,
      changeDepartment,
      showBirthDate,
      getYearOptions,
      getMonthOptions,
      getDayOptions,
      changeBirthDate,
      changeGender,
      changeMode,
      generateTitleDescription,
      confirmApplicant,
      updateApplicant,
      validateTel,
      validateZipcode,
      validateOnInput,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

@include validation_effects();
.breadcrumb {
  padding: 1.5rem 0 1.2rem;
  margin: 0 1.5rem;
  border-bottom: 1px solid $border_color;
  .chip {
    padding: 0.5rem 0;
    cursor: pointer;
    > span {
      padding: 0 0.32rem;
    }
    .chip_title {
      font-size: 1rem;
    }
    .link {
      color: $mochica_color;
      font-weight: bold;
    }
    .arrow-icon {
      width: 15px;
      height: 15px;
      padding-right: 0.5rem;
    }
  }
}

.btn-round.btn-commit {
  margin-bottom: 1.5rem;
}
.applicant-info-list {
  > li {
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid $border_color;
  }
  > li > .title {
    font-weight: bold;
    padding: 0.5rem 0;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
  > li > .value {
    font-size: 1.4rem;
    word-wrap: break-word;
    margin-bottom: 1.5rem;
  }
}
.bold-thin-black {
  color: $dropped_color;
  font-weight: bold;
}

.border-bottom-space-sm {
  padding-bottom: 1rem;
  border-top: 1px solid #ccc;
}
.border-bottom-space-md {
  padding-bottom: 1.5rem;
  border-top: 1px solid #ccc;
}

.top-space1 {
  padding-top: 1rem;
}
.bottom-space1 {
  padding-bottom: 1rem;
}
.bg-bold {
  white-space: pre-line;
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 1rem;
}
.title-header {
  padding: 1rem 1.5rem;
}
.gray-bg {
  height: 15px;
  background-color: $mochica_light_gray_bg;
  border-top: 1px solid $border_color;
  border-bottom: 1px solid $border_color;
}
.user-info-body {
  padding: 1.5rem;
}
.desc {
  padding: 0.5rem 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.big-spacer {
  padding-top: 7rem;
  border-bottom: 1px solid $border_color;
  margin-bottom: 3rem;
}
.sm-bold-blue {
  padding: 1rem 0;
  font-size: 1.6rem;
  color: $mochica_color;
  font-weight: bold;
}
.edited-input {
  &::placeholder {
    color: $border_color;
  }
  width: 100%;
  padding: 2rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  border: 1px solid $border_color;
}
.date-of-birth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  select {
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid $border_color;
    margin-right: 0.5rem;
    @media only screen and (max-width: 400px) {
      padding: 1rem;
      margin: 0;
    }
  }
  span {
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    justify-self: center;
  }
}
// radio button css should be refactored
.radio-block {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 5rem;
  input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }
  input[type='radio']:checked ~ .check {
    border: 2px solid $mochica_color;
  }

  input[type='radio']:checked ~ .check::before {
    background: $mochica_color;
  }

  label {
    display: block;
    position: relative;
    font-size: 1.2rem;
    padding: 25px 25px 25px 35px;
    margin: 1rem auto;
    height: 25px;
    z-index: 9;
    cursor: pointer;
  }
  .check {
    display: block;
    position: absolute;
    border: 2px solid #aaaaaa;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 30px;
  }
  .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 2.5px;
    left: 3px;
    margin: auto;
  }
}
</style>
