<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content modal-window -typeA">
        <div class="header">
          <p class="title">Googleフォーム新規登録</p>
        </div>
        <transition name="fade">
          <div class="body">
            <div class="body-inner">
              <section>
                <div v-if="!isConnected" class="survey_form">
                  <dl>
                    <dt>
                      <label>Googleフォーム名</label>
                    </dt>
                    <dd>
                      <input
                        v-model="sheetName"
                        type="text"
                        maxlength="30"
                        data-vv-as="Googleフォーム名"
                        :class="{ 'is-danger': !errors.surveyName.isValid }"
                        placeholder="Googleフォーム名を入力してください"
                        @input="
                          validateOnInput(
                            'surveyName',
                            sheetName,
                            validateRequire,
                          )
                        "
                      />
                      <span
                        v-show="!errors.surveyName.isValid"
                        class="fa fa-warning font-s"
                      >
                        {{ errors.surveyName.message }}
                      </span>
                    </dd>
                    <dt>
                      <label>スプレッドシートアドレス</label>
                    </dt>
                    <dt>
                      <span class="url-txt">
                        https://docs.google.com/spreadsheets/...&nbsp;で始まるアドレスを全て入力してください
                      </span>
                    </dt>
                    <dd>
                      <input
                        v-model="url"
                        name="survey_url"
                        type="text"
                        :class="{ 'is-danger': !errors.surveyUrl.isValid }"
                        placeholder=""
                        @input="
                          validateOnInput('surveyUrl', url, validateRequire)
                        "
                      />
                      <span
                        v-show="!errors.surveyUrl.isValid"
                        class="fa fa-warning font-s"
                      >
                        {{ errors.surveyUrl.message }}
                      </span>
                    </dd>
                    <dt>
                      <label>スプレッドシートUID</label>
                    </dt>
                    <dd>
                      <span>{{ spreadSheetUid.spreadUid }}</span>
                    </dd>
                    <dt>
                      <label>スプレッドシートGID</label>
                    </dt>
                    <dd>
                      <span>{{ spreadSheetUid.spreadGid }}</span>
                    </dd>
                  </dl>
                </div>
                <div v-else class="survey_conected">
                  <page-loader />
                  <p v-if="!isReceived">
                    Google にリクエストを送信しています。
                  </p>
                  <p v-else>
                    スプレッドシートから {{ size }} kb のデータを受信中です。
                  </p>
                </div>
              </section>
            </div>
          </div>
        </transition>
        <div class="btn-col2">
          <button
            class="btn btn-unavailable"
            :class="{ disabled: isDisabled }"
            :disabled="isDisabled"
            @click="$emit('onClickCancel', $event)"
          >
            キャンセル
          </button>
          <button
            class="btn btn-availability"
            :class="{ disabled: isDisabled }"
            :disabled="isDisabled"
            @click="onClickToConfirm"
          >
            Googleフォームを登録する
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';
import { useStore } from 'vuex';

import { onResizeModal } from '@/utils/modal-resize';
import useValidation from '@/composables/useValidation';
import Modal from '@/components/ui/modals/components/Modal';
import PageLoader from '@/components/ui/loaders/components/PageLoader';

export default defineComponent({
  name: 'ModalAddSurvey',
  components: { Modal, PageLoader },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: null,
    },
    forced: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClickCancel', 'surveyCreate'],
  setup(props, context) {
    const SPREADSHEET_UID_LENGTH = 44;
    const {
      errors,
      resetErrors,
      updateErrors,
      validateOnInput,
      validateRequire,
    } = useValidation(['surveyName', 'surveyUrl']);
    const store = useStore();
    const sheetName = ref(null);
    const url = ref('');
    const isDisabled = ref(false);
    const isConnected = ref(false);
    const isReceived = ref(false);

    // computed
    const spreadSheetUid = computed(() => {
      let uid = null;
      let gid = null;
      if (url.value && url.value.length > 0) {
        try {
          const urls = url.value
            .slice(url.value.search(/spreadsheets/) + 15)
            .split('/');
          gid = urls[1].slice(urls[1].search(/#gid=/) + 5);
          uid = urls[0];
        } catch (e) {
          // gid,uidがURLから取得できない場合は警告文を出す
          updateErrors(
            'surveyUrl',
            'スプレッドシートアドレスが正しくありません',
          );
          return { spreadUid: uid, spreadGid: gid };
        }
      }
      if (validateUid(uid) !== true) {
        updateErrors('surveyUrl', 'スプレッドシートアドレスが正しくありません');
      }
      return { spreadUid: uid, spreadGid: gid };
    });

    // watch
    watch(
      () => props.size,
      val => {
        props.size = val;
        if (val) isReceived.value = true;
      },
    );

    // methods
    const validateUid = uid => {
      return uid && uid.length === SPREADSHEET_UID_LENGTH;
    };
    const onClickToConfirm = async () => {
      resetErrors();
      const isValidSheetName = validateRequire('surveyName', sheetName.value);
      const isValidSheetUrl = validateRequire('surveyUrl', url.value);
      const isValidUid = validateUid(spreadSheetUid.value.spreadUid);
      if (
        isValidSheetName !== true ||
        isValidSheetUrl !== true ||
        isValidUid !== true
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容をご確認ください',
          type: false,
        });
        return;
      }
      isDisabled.value = true;
      isConnected.value = true;
      context.emit('surveyCreate', {
        sheet_name: sheetName.value,
        sheet_uid: spreadSheetUid.value.spreadUid,
        sheet_gid: spreadSheetUid.value.spreadGid,
      });
    };
    const surveyCreateResponse = () => {
      isConnected.value = false;
      isDisabled.value = false;
      isReceived.value = false;
    };
    const localResizeModal = () =>
      onResizeModal(props.isView, { shouldResizeModalWidth: false });

    // lifecycle
    onBeforeMount(() => {
      if (props.forced === true) {
        isDisabled.value = true;
        isConnected.value = true;
      }
    });
    onMounted(() => {
      window.addEventListener('resize', localResizeModal);
      localResizeModal();
    });
    onUnmounted(() => window.removeEventListener('resize', localResizeModal));

    return {
      errors,
      sheetName,
      url,
      isDisabled,
      isConnected,
      isReceived,
      spreadSheetUid,
      onClickToConfirm,
      surveyCreateResponse,
      validateOnInput,
      validateRequire,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.content {
  border-radius: 1rem;
  .header {
    padding: 2rem;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    font-size: 1.2rem;
    padding: 2rem;
    .body-inner {
      overflow-y: auto;
    }
    section {
      text-align: left;
      .survey_conected {
        p {
          text-align: right;
        }
      }
      .survey_form {
        dl {
          dt {
            margin-bottom: 1rem;
            label {
              font-weight: bold;
            }
          }
          dd {
            height: 5.8rem;
            margin-bottom: 1rem;
            input[type='text'] {
              width: 100%;
              border: solid 1px #808080;
              border-radius: 2px;
              padding: 0.8rem;
              font-size: 1.4rem;
              transition: 0.3s ease;
              border-radius: 0.3rem;
              height: 3em;
            }
            input[name='survey_url'] {
              width: 100%;
            }
            &:nth-child(7) {
              height: 2rem;
            }
            &:last-child {
              height: 2rem;
            }
          }
        }
      }
    }
    .area_btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      .item {
        width: 49.5%;
        height: 3.6rem;
        line-height: 3.6rem;
        background: $mochica_color;
        border-radius: 0.3rem;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        &.back_btn {
          background: #fff;
          border: 1px solid $border_color;
          color: #333;
          font-weight: normal;
        }
        &.disabled {
          background-color: #ccc;
          color: #333;
          font-weight: 100;
        }
      }
    }
  }
}

.pageloader {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 254px;
}
input:focus,
select:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid !important;
}
.fa-warning {
  color: #c31e1e;
  padding-top: 4px;
}
.fa {
  display: block;
}
// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: ($media_query_sp)) {
  .url-txt {
    line-height: 1.4;
  }
}
</style>
