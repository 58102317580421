<template>
  <div>
    <div class="btn_back">
      <p @click="onClickCancelLinkGoogleCalendar">システム連携に戻る</p>
    </div>
    <div>
      <dl class="setting_list">
        <dd class="setting_text">
          <p class="setting_title">Googleカレンダー連携</p>
          <p>
            GoogleのワークスペースカスタマーIDを入力いただき、連携ボタンをクリックしてください。
            連携後、会議室選択画面に移動します。
          </p>
          <!-- <p class="link_box">
              ワークスペースカスタマーIDは
              <a href="" target="_blank">こちらの記事</a>
              を参考にしてご用意ください。
            </p> -->
        </dd>
      </dl>
      <dl class="setting_list">
        <dt>ワークスペースカスタマーID</dt>
        <dd>
          <p>入力すると会議室情報を取得できるようになります。</p>
          <input
            v-model="googleWorkspaceCustomerId"
            type="text"
            class="input-workspace_customer_id"
            placeholder="ワークスペースカスタマーID"
          />
        </dd>
      </dl>
      <dl class="setting_list">
        <dd>
          <button-base
            class="btn btn-send"
            button-text="連携"
            button-type="success"
            :is-loading="isLoading"
            :is-disabled="!googleWorkspaceCustomerId"
            :rejected-role-ids="[2, 3]"
            :rejected-admin-ids="[2]"
            @click="sendWorkspaceCustomerId"
          />
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import GoogleCalendarService from '@/services/google-calendar';

export default defineComponent({
  name: 'LinkGoogleCalendarLinkWorkspaceCustomerId',
  emits: ['changeMode', 'changeIsConnectedGoogleWorkspaceCustomerId'],
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const googleWorkspaceCustomerId = ref('');

    // methods
    const sendWorkspaceCustomerId = async () => {
      isLoading.value = true;
      const res =
        await GoogleCalendarService.linkGoogleWorkspaceCustomerIdCalendar({
          google_workspace_customer_id: googleWorkspaceCustomerId.value,
        });
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        type: res.success,
        message: res.message,
      });
      if (res.success === true) {
        googleWorkspaceCustomerId.value = '';
        context.emit('changeMode', 'home');
        context.emit('changeIsConnectedGoogleWorkspaceCustomerId', true);
      }
    };
    const onClickCancelLinkGoogleCalendar = () => {
      googleWorkspaceCustomerId.value = '';
      context.emit('changeMode', 'home');
    };

    return {
      googleWorkspaceCustomerId,
      isLoading,
      onClickCancelLinkGoogleCalendar,
      sendWorkspaceCustomerId,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.setting_title {
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #5896cc;
  border-left: 6px solid #5896cc;
}

.setting_text {
  margin-bottom: 24px;
  line-height: 1.75;
  > span {
    display: block;
    margin-top: 5px;
  }
}

.setting_list {
  border-top: 1px solid #e5e5e5;
  padding-top: 24px;
  margin-bottom: 24px;
  > dt {
    margin-bottom: 18px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: bold;
    > span {
      display: inline-block;
      width: 44px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      background-color: #bb1b1c;
      border-radius: 4px;
      text-align: center;
    }
  }
  > dd {
    margin-bottom: 18px;

    > .input-workspace_customer_id {
      width: 100%;
      margin-top: 15px;
      margin-right: 20px;
    }

    > p {
      margin-bottom: 10px;
    }
  }
}

.btn_back {
  margin-bottom: 27px;
  > p {
    padding-left: 15px;
    line-height: 1;
    background-image: url('../../../../../assets/img/icon-back.svg');
    background-size: auto 12px;
    background-position: 0 1.5px;
    background-repeat: no-repeat;
    color: #5896cc;
    cursor: pointer;
  }
}

.link_box {
  padding: 10px 16px;
  margin-top: 16px;
  border: 1px solid #d7e5ee;
  line-height: 20px;
  border-radius: 4px;
  background: #f3f9fd;
  > a {
    font-weight: bold;
    padding: 0 17px 2px 0;
    color: #1899d6;
    text-decoration: underline;
    background-image: url('../../../../../assets/img/icon-external-link.svg');
    background-size: 14px 14px;
    background-position: right 1px;
    background-repeat: no-repeat;
  }
}

.btn-send {
  width: 100%;
  height: 54px;
  background-color: #12b312;
  &.btn-long {
    margin-top: 15px;
    width: 260px;
  }
  &:disabled {
    color: #333 !important;
    background-color: #999;
    opacity: 0.3;
    cursor: not-allowed;
  }
}
</style>
