/**
 * ファイル名から拡張子を分離して取得
 * @param {Number} strong - ユニーク強度
 * @return {String} ユニークID
 */
const generateUniqueId = (strong = 1000) => {
  return (
    new Date().getTime().toString(16) +
    Math.floor(strong * Math.random()).toString(16)
  );
};

export { generateUniqueId };
