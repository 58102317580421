<template>
  <div
    v-if="isCommitted"
    :class="{
      'not-announced': flowItemCssClass,
      selection_done: isCommitted,
      selection_current: isCurrentProgress,
    }"
    class="flow-item-wrapper"
  >
    <section
      class="item-flow committed committed-item"
      :class="progressCssClass"
    >
      <div
        :class="{ 'pointer-default': isCurrentProgress }"
        class="header header-committed"
        @click="toggleBodyExpand()"
      >
        <span class="title">{{ progress.title }}</span>
        <span class="prgress-message">{{ progress.status.description }}</span>
        <div
          class="btn-toggle"
          :class="{ on: isBodyExpand, off: !isBodyExpand }"
        >
          <img
            class="icon accordion"
            src="@/assets/img/arrow_down_white.svg"
            alt
          />
        </div>
      </div>
      <transition name="ease">
        <div v-show="isBodyExpand" :class="expandCssClass">
          <div class="body body-committed">
            <div class="area-selection-info">
              <div v-if="progress.venue" class="area-section-info-detail">
                <div class="detail-row">
                  <div class="area-selection-item venue-name">
                    <p class="title">会場名</p>
                    <p class="value">
                      <span>{{ progress.venue.name }}</span>
                    </p>
                  </div>
                  <div class="area-selection-item selection-date">
                    <p class="title">開催日</p>
                    <p class="value">
                      <span>
                        {{
                          $utils.parseDateTime(
                            progress.venue.date,
                            'YYYY/MM/DD',
                          )
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="area-selection-item selection-time">
                    <p class="title">開催時間</p>
                    <p class="value">
                      <span
                        v-if="
                          progress.venue.timetables &&
                          progress.venue.timetables.length
                        "
                      >
                        {{
                          $utils.parseTime(progress.venue.timetables[0].start)
                        }}
                        〜
                        {{
                          $utils.parseTime(progress.venue.timetables[0].last)
                        }}
                      </span>
                    </p>
                  </div>
                  <div
                    v-if="
                      progress.venue.timetables &&
                      progress.venue.timetables.length
                    "
                    class="area-selection-item selection-reminder"
                  />
                </div>
                <div class="detail-row">
                  <div class="area-selection-item venue-address">
                    <p class="title">住所</p>
                    <p class="value">
                      <span>
                        {{ progress.venue.pref }}{{ progress.venue.address }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="area-form">
              <applicant-evaluation
                :is-committed="isCommitted"
                :is-current-progress="isCurrentProgress"
                :is-not-announced="isNotAnnounce"
                :is-operator="
                  $store.getters['applicant/applicant'].staff.id ===
                  $store.getters['staff/staff'].id
                "
                :is-manager="$store.getters['staff/isManager']"
                :is-preview="true"
                :comment="progress.comment"
                :evaluation="progress.evaluation"
                :progress-id="progress.id"
                :progress="progress"
                :progress-status-name="progress.status.name"
                @applicantSettleOperator="
                  $emit('applicantSettleOperator', $event)
                "
                @applicantFetchAllApi="$emit('applicantFetchAllApi', $event)"
              />
            </div>
          </div>
        </div>
      </transition>
    </section>
  </div>
  <div
    v-else
    :class="{
      'not-announced': flowItemCssClass,
      selection_done: isCommitted,
      selection_current: isCurrentProgress,
    }"
    class="flow-item-wrapper"
  >
    <section :class="progressCssClass" class="item-flow not-committed">
      <div class="header header-not-committed" @click="toggleBodyExpand()">
        <span class="title">{{ progress.title }}</span>
        <span class="prgress-message">{{ progress.status.description }}</span>
        <div
          class="btn-toggle"
          :class="{ on: isBodyExpand, off: !isBodyExpand }"
        >
          <img
            class="icon accordion"
            src="@/assets/img/arrow_down_white.svg"
            alt
          />
        </div>
      </div>
      <transition name="ease">
        <div v-show="isBodyExpand" :class="expandCssClass">
          <div class="body body-not-committed">
            <div v-if="isCurrentProgress" class="link-selection-next-box">
              <applicant-evaluation
                :is-commited="isCommitted"
                :is-operator="
                  $store.getters['applicant/applicant'].staff.id ===
                  $store.getters['staff/staff'].id
                "
                :is-manager="$store.getters['staff/isManager']"
                :is-preview="true"
                :operator-evaluation="progress.operatorEvaluation"
                :manager-evaluation="progress.managerEvaluation"
                :progress-id="progress.id"
                :progress="progress"
                :progress-status-name="progress.status.name"
                @applicantSettleOperator="
                  $emit('applicantSettleOperator', $event)
                "
                @applicantFetchAllApi="$emit('applicantFetchAllApi', $event)"
              />
            </div>
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';

import ApplicantEvaluation from '@/components/page/applicants/components/ApplicantEvaluation';

export default defineComponent({
  name: 'SelectionFlowPreview',
  components: { ApplicantEvaluation },
  props: {
    isCommitted: {
      type: Boolean,
      default: false,
    },
    isCurrentProgress: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Object,
      default: null,
    },
    isLineFriend: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['applicantSettleOperator', 'applicantFetchAllApi'],
  setup(props, context) {
    const isBodyExpand = ref(true);
    const isNotAnnounce = ref(true);

    const flowItemCssClass = computed(() => {
      let cssClass = false;
      if (
        props.progress.status.announced !== undefined &&
        props.progress.status.announced === false &&
        props.progress.status.name !== 'committed' &&
        props.progress.status.name !== 'hired' &&
        props.progress.status.name !== 'offer_canceled'
      ) {
        isNotAnnounce.value = true;
        cssClass = true;
      } else {
        isNotAnnounce.value = false;
        cssClass = false;
      }
      return cssClass;
    });
    const progressCssClass = computed(() => {
      const cssClass = [];
      cssClass.push(`progress-status-${props.progress.status.id}`);
      if (props.isCurrentProgress) cssClass.push('current');
      return cssClass;
    });
    const isExpandFlowBody = computed(() => {
      const classes = [];
      classes.push(isBodyExpand.value ? 'open' : 'close');
      return classes;
    });

    // methods
    const toggleBodyExpand = () => {
      isBodyExpand.value = !isBodyExpand.value;
    };
    const expandCssClass = () => {
      const classes = [];
      classes.push(isBodyExpand.value ? 'open' : 'close');
      return classes;
    };

    // lifecycle
    onMounted(() => {
      isBodyExpand.value = true;
    });

    return {
      isBodyExpand,
      isNotAnnounce,
      flowItemCssClass,
      progressCssClass,
      isExpandFlowBody,
      toggleBodyExpand,
      expandCssClass,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

// .not-announced .item-flow .not-announce::before
.btns-area + .flow-item-wrapper {
  &.not-announced .not-announce::before {
    display: none;
  }
}

.flow-item-wrapper {
  position: relative;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0.2rem;
    height: calc(100% + 2rem);
    background-color: $mochica_color_gray2;
    left: 11px;
    top: 22px;
  }
  .header {
    &::before {
      width: 2.4rem;
      height: 2.4rem;
      border: 2px solid $mochica_color_gray2;
      background-size: 1.3rem 1.3rem;
      background: $white;
      top: 14px;
      left: -3.8rem;
      border-radius: 2.4rem;
      z-index: 1;
    }
  }
  &.selection_done {
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 2px;
      height: calc(100% + 1.5rem);
      background-color: $mochica_color;
      left: 11px;
      top: 28px;
    }
    .header {
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        border: 2px solid $mochica_color;
        top: 5px;
        background: $white;
        border-radius: 2.4rem;
        z-index: 1;
      }
    }
  }
  &.selection_current {
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 0.2rem;
      background-color: $mochica_color_gray2;
      left: 11px;
    }
    .header {
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        background: $white;
        border: 2px solid $mochica_color !important;
        top: 10px;
        left: -3.8rem;
        border-radius: 50%;
        z-index: 2 !important;
      }
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        background: $mochica_color !important;
        top: 15px;
        left: -40.8px;
        border-radius: 50%;
        z-index: 3 !important;
      }
    }
  }
  &.not-announced {
    .item-flow {
      position: relative;
      top: -10px;
      .header {
        display: flex;
      }
      .header-committed {
        &::before {
          width: 2.4rem;
          height: 2.4rem;
          border: 2px solid $mochica_color_gray2;
          background-size: 1.3rem 1.3rem;
          top: 10px;
          // left: -3.6rem;
          border-radius: 50%;
          z-index: 1;
        }
      }
      .not-announce {
        display: flex;
        justify-content: space-between;
        background: #c31e1e;
        padding: 1rem;
        color: $white;
        font-weight: bold;
        border-radius: 4px 4px 0 0;
        & + .header {
          border-radius: 0 0 0 0;
        }
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 0.2rem;
          height: calc(8% + 1rem);
          background-color: $mochica_color;
          left: -25px;
          top: 0;
          z-index: 1;
        }
        .dashed {
          padding-bottom: 0.2rem;
          border-bottom: 1px dashed $white;
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
          .commit_svg {
            display: inline-block;
            width: 1rem;
            fill: $white;
            stroke: $white;
            margin-top: -1px;
            margin-right: 0.4rem;
          }
        }
      }
    }
  }
  &:first-of-type.not-announced {
    .item-flow {
      .not-announce {
        &::before {
          display: none;
        }
      }
    }
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
}
.item-flow {
  border-radius: 4px;
  background: $white;
  &::before {
    width: 2.4rem;
    height: 2.4rem;
    border: 2px solid $mochica_color;
    background-size: 1.3rem 1.3rem;
    top: 14px;
    left: -3.8rem;
    border-radius: 50%;
    z-index: 1;
  }
  &.not-committed {
    margin-left: 3.6rem;
    .header {
      background: #e3e3e3;
      border-radius: 0.5rem;
      .title {
        position: static;
        padding-right: 1rem;
        &::before {
          display: none;
        }
      }
      .prgress-message {
        background: $white;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  &.committed-item {
    margin-left: 3.6rem;
    .header {
      background: #808080;
      border-radius: 0.5rem;
      .title {
        font-weight: bold;
        color: $white;
        padding-right: 1rem;
      }
      .prgress-message {
        background: $white;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  &.current {
    margin-left: 3.6rem;
    .header {
      background: $mochica_color;
      border-radius: 0.5rem 0.5rem 0 0;
      .title {
        color: $white;
        font-weight: bold;
        padding-right: 1rem;
      }
    }
    .body {
      border: 2px solid $mochica_color;
      border-radius: 0 0 0.5rem 0.5rem;
      background: $white;
    }
  }
  > * {
    &::before {
      position: absolute;
      content: '';
      display: inline-block;
    }
    &::after {
      position: absolute;
      content: '';
      display: inline-block;
    }
  }
  .btn-toggle {
    margin-left: auto;
    .accordion {
      width: 12px;
      height: 7.5px;
      transition: all ease 0.3s;
      margin: 0 1rem;
    }
  }
  .btn-toggle.on {
    .accordion {
      transform: rotate(180deg);
    }
  }

  // .header.pointer-default {
  //   cursor: default;
  // }

  .header {
    display: flex;
    padding: 1rem;
    cursor: pointer;
    align-items: center;
    font-size: 1.2rem;
  }
  .header-not-committed {
    position: relative;
  }
  .header-committed {
    position: relative;
    padding: 1rem;
    .title {
      font-size: 1.2rem;
    }
    .prgress-message {
      font-size: 1.2rem;
    }
    .link-delete {
      position: absolute;
      right: 20px;
      .btn {
        color: #c31e1e;
        border-bottom: 1px dashed #c31e1e;
        padding-bottom: 0.2rem;
        transition: all ease 0.3s;
        .delete_svg {
          width: 10px;
          margin-right: 0.2rem;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .body {
    border: 2px solid #808080;
    border-radius: 0 0 0.3rem 0.3rem;
    margin: -0.4rem 0 0;
    .area-selection-info {
      position: relative;
      background-color: #e9f3f8;
      &::before {
        background: $mochica_color;
        position: absolute;
        display: block;
        width: 1px;
        height: 100%;
        top: 0;
        left: -2.4rem;
      }
      .area-section-info-detail {
        padding: 1.6rem;
        .detail-row {
          display: flex;
          padding-bottom: 1.2rem;
          margin-bottom: 1.2rem;
          border-bottom: solid 1px #e3e3e3;
          .area-selection-item {
            margin-right: 2rem;
            padding-right: 1.2rem;
            .title {
              min-width: 13rem;
              margin-bottom: 0.8rem;
              font-size: 1rem;
            }
            .cover_reminder {
              cursor: pointer;
              position: absolute;
              height: 20px;
              width: 170px;
              z-index: 1;
            }
            .value {
              min-width: 13rem;
              &.reminder {
                cursor: pointer;
                input[name='is_reminder'] {
                  position: relative;
                  top: 2px;
                  width: 1.4rem;
                  height: 1.4rem;
                  margin: 0 5px 0 0;
                  border-radius: 0.4rem;
                  border: 2px solid #9d9d9d;
                  background: $white;
                  appearance: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  cursor: pointer;
                  &:checked {
                    &::after {
                      content: url(../../../../assets/img/check.svg);
                      height: 1.1rem;
                      width: 1.5rem;
                      margin: -5px 0 0 1px;
                      position: absolute;
                      bottom: 3px;
                      left: 0;
                    }
                  }
                }
                label {
                  cursor: pointer;
                }
              }
            }
          }
          &:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
            border: none;
          }
        }
      }
      .venue-address {
        .ttl {
          color: #9d9d9d;
          font-size: 1.1rem;
          line-height: 1.8rem;
          min-width: 13rem;
        }
        .value {
          font-size: 1.2rem;
          min-width: 13rem;
        }
      }
    }
    .area-form {
      textarea {
        width: 100%;
        height: 15rem;
      }
    }
  }
  .body-not-committed {
    background-color: $white;
    border: 2px solid $mochica_color_gray2;
    margin-bottom: 2rem;
    .link-selection {
      margin: 1.6rem;
      height: 3.2rem;
      line-height: 3rem;
      border-radius: 5px;
      text-align: center;
      background-color: $mochica_color;
      border: 1px solid #027db7;
      color: $white;
      font-weight: bold;
      transition: all ease 0.3s;
      &:hover {
        opacity: 0.6;
      }
      a {
        color: #5f5f5f;
        font-weight: 500;
        font-size: 1.2rem;
      }
      & + .link-selection-next-box {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: 1px solid $border_color;
      }
    }
    .operate-attendance {
      margin: 1.6rem;
      height: 3.2rem;
      line-height: 3rem;
      border-radius: 5px;
      text-align: center;
      background-color: #e3e3e3;
      border: 1px solid #cfcfcf;
      color: #5f5f5f;
      transition: all ease 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .body-committed {
    position: relative;
  }

  .selection-delete-block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    &:empty {
      margin-top: 0;
    }
    .link-delete {
      padding: 0.8rem 6rem;
      border: 1px solid red;
      border-radius: 3px;
      .btn {
        color: #c31e1e;
        transition: all ease 0.3s;
        .delete_svg {
          width: 10px;
          margin-right: 0.2rem;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .body-not-committed {
    padding: 20px 0 20px;
    text-align: center;
  }
}

.progress-status-7,
.progress-status-8,
.progress-status-9,
.progress-status-10,
.progress-status-11,
.progress-status-12 {
  .body-committed {
    background-color: $white;
    & :deep(.area-operatosssr-body) {
      margin-bottom: 0;
    }
    .link-selection {
      width: 100%;
      height: 3.2rem;
      line-height: 3rem;
      border-radius: 5px;
      text-align: center;
      background-color: $mochica_color;
      border: 1px solid #027db7;
      color: $white;
      font-weight: bold;
      transition: all ease 0.3s;
      &:hover {
        opacity: 0.6;
      }
      a {
        color: #5f5f5f;
        font-weight: 500;
        font-size: 1.2rem;
      }
      & + .link-selection-next-box {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: 1px solid $border_color;
      }
    }
    &::before {
      display: none;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .item-flow {
    .body {
      .area-selection-info {
        .area-section-info-detail {
          .detail-row {
            .area-selection-item {
              margin-right: 0;
              padding-right: 15px;
              .title {
                min-width: initial;
              }
              .value {
                min-width: initial;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .flow-item-wrapper {
    margin-bottom: 20px;
    &::after {
      display: none;
    }
    .header {
      &::before {
        display: none;
      }
    }
    &.selection_done {
      &::after {
        display: none;
      }
      .header {
        &::before {
          display: none;
        }
      }
    }
    &.selection_current {
      &::after {
        display: none;
      }
      .header {
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
      &.not-announced {
        .item-flow {
          .header {
            display: block;
            padding: 10px;
          }
          .not-announce {
            display: block;
            padding: 12px 15px 15px;
            &::before {
              display: none;
            }
            .notice-left {
              margin-bottom: 15px;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }

  .item-flow {
    &.committed-item {
      margin-left: 0;
      .header {
        cursor: pointer;
        display: block;
        text-align: center;
        .title {
          display: block;
          margin-bottom: 10px;
        }
        .prgress-message {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    &.not-committed {
      margin-left: 0;
      .header {
        cursor: pointer;
        display: block;
        text-align: center;
        .title {
          display: block;
          margin-bottom: 10px;
        }
        .prgress-message {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    .header {
      cursor: pointer;
      padding: 10px;
      text-align: center;
      .title {
        display: block;
        margin-bottom: 10px;
      }
      .prgress-message {
        display: block;
      }
    }
    .body {
      .area-selection-info {
        .area-section-info-detail {
          .detail-row {
            display: block;
            .area-selection-item {
              padding-right: 0;
            }
            .area-selection-item + .area-selection-item {
              margin-top: 20px;
            }
          }
        }
      }
      .btn-box {
        padding: 0 20px;
        .btn {
          width: 100%;
        }
        .btn + .btn {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
