import router from '@/router';
import httpClient from '@/http-client';
import store from '@/stores';
import staffModel from '@/models/staff';
import widget from '@/utils/widget';
import staffsService from '@/services/staffs';

const initSetting = async data => {
  // widgetを初期化
  widget.init(data.company.id, data.staff.id);
  data.plan.unread_all_count = data.unread_all_count;
  await store.dispatch(
    'staff/SET_STAFF',
    staffModel.createFromJSON(data.staff),
  );
  await store.dispatch('company/SET_COMPANY', data.company);
  await store.dispatch('plan/SET_PLAN', data.plan);
  await store.dispatch('graduateds/FETCH');
};

const state = {
  isLogin: false,
};

const mutations = {
  LOGIN(oldState) {
    Object.assign(oldState, { isLogin: true });
  },
  LOGOUT(oldState) {
    Object.assign(oldState, { isLogin: false });
  },
};

const getters = {
  isLogin: currentState => currentState.isLogin,
};

const actions = {
  async LOGIN({ commit }, payload) {
    const response = await httpClient.post('/login', {
      login: {
        email: payload.email,
        password: payload.password,
        company_id: payload.company_id ? payload.company_id : null,
      },
    });
    if (response.data.success) {
      await initSetting(response.data);
      await commit('LOGIN');
      // admin === 1 のみadminに飛ばす
      if (response.data.staff.admin === 1) {
        router.replace('/admin');
      } else {
        if (response.data.staff.role.id === 1 && !response.data.company.guide) {
          router.replace('/guide');
        } else {
          router.replace('/');
        }
      }
    }
    return response.data;
  },
  async MASTER_LOGIN({ commit }, payload) {
    const response = await httpClient.post('/login/master', {
      login: {
        email: payload.email,
        password: payload.password,
        company_id: payload.company_id ? payload.company_id : null,
      },
    });
    if (response.data.success) {
      await initSetting(response.data);
      await commit('LOGIN');
      router.replace('/');
    }
    return response.data;
  },
  async LOGOUT({ commit }) {
    const response = await httpClient.post('/logout');
    if (response.status === 200) {
      // await store.dispatch('staff/CLEAR_STAFF');
      // await store.dispatch('company/CLEAR_COMPANY');
      store.dispatch('page/CLEAR_APPLICATION_ERROR');
      await commit('LOGOUT');
    }
    // chatbotを非表示にしてLoginページへ飛ばす
    widget.hideChatbot();
    router.push('/login');
    return response.success;
  },
  async INIT({ commit }) {
    // ページリロード時に一度だけ呼ばれる
    const response = await staffsService.loginInit();
    if (response.data.success) {
      await initSetting(response.data);
      await commit('LOGIN');
    }
    return response.data.success;
  },
  async CHECK_LOGIN() {
    const response = await staffsService.loginCheck();
    if (response.data.success) {
      response.data.plan.unread_all_count = response.data.unread_all_count;
      await store.dispatch('plan/SET_PLAN', response.data.plan);
    }
    return response.data.success;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
