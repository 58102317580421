<script setup>
import Modal from '@/components/ui/modals/components/Modal';

const { isView } = defineProps({
  isView: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close-modal']);
const closeModal = () => {
  emit('close-modal', false);
};
</script>

<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content">
        <div class="body">
          <div class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                <slot name="header" />
              </div>
              <button
                type="button"
                class="modal-header_cancel"
                @click="closeModal"
              >
                <img slot="icon" src="@/assets/img/close_black.svg" alt />
              </button>
            </div>
          </div>
          <div class="modal-body">
            <slot name="body" />
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.content {
  width: 100vw;
  height: 100vh;
  background: $mochica_color_gray2;
  overflow-y: auto;
  .modal-header {
    @include full_modal_before();
    position: fixed;
    top: 0;
    left: 0;
    z-index: 21;
    width: 100%;
    background: $mochica_light_color;
    border-bottom: 1px solid #b6bfde;

    &_inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      font-size: $font_14;
      padding: 18px 32px;
      @media (max-width: ($media_query_sp)) {
        padding: 12px 22px;
      }
    }

    &_ttl {
      display: flex;
      align-items: center;
      @media (max-width: ($media_query_sp)) {
        font-size: $font_12;
      }
    }
    &_cancel {
      width: 22px;
      height: 22px;
      background: transparent;
      transition: all ease 0.3s;
      &:hover {
        opacity: 0.4;
      }
      svg {
        fill: #333333;
      }
      cursor: pointer;
      @media (max-width: ($media_query_sp)) {
        right: 10px;
      }
    }
  }
  .modal-body {
    padding: 90px 0 0;
    width: 90%;
    margin: 0 auto;
    @media (max-width: ($media_query_sp)) {
      padding: 70px 0 0;
    }
  }
}
</style>
