import env from '@/utils/env';

export default {
  init(companyId, staffId) {
    /* eslint-disable */
    // 新規外部チャットボット
    !(function () {
      var b = document.getElementsByTagName('body')[0];
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://ws1.sinclo.jp/client/616413456cd81.js';
      s.charset = 'UTF-8';
      b.appendChild(s);
    })();
    // pottos
    const currentEnv = env.getEnv();
    if (currentEnv === 'production') {
      !(function (e, n, t, c, s, a, o) {
        (e[s] =
          e[s] ||
          function () {
            (e[s].q = e[s].q || []).push(arguments);
          }),
          (e[s].l = 1 * new Date()),
          (a = n.createElement(t)),
          (o = n.getElementsByTagName(t)[0]),
          (a.async = 1),
          (a.src = 'https://embed.pottos.jp/embed.js'),
          o.parentNode.insertBefore(a, o);
      })(window, document, 'script', 0, 'pottos');
      pottos(
        'init',
        'laeqBvzRhAWaWXGo8l5fjJYxeuQXfMZ7',
        `${companyId}`,
        `${staffId}`,
      );
    }
    /* eslint-enable */
  },
  initPage() {
    // pottos
    const currentEnv = env.getEnv();
    if (currentEnv === 'production') {
      window.pottos('spaInit');
    }
  },
  showChatbot() {
    const chatbotEl = document.querySelector('sinclo[id="sincloBox"]');
    if (chatbotEl) chatbotEl.style.display = 'block';
  },
  hideChatbot() {
    const chatbotEl = document.querySelector('sinclo[id="sincloBox"]');
    if (chatbotEl) chatbotEl.style.display = 'none';
  },
};
