import httpClient from '@/http-client';

type PostContactSwitchParams = {
  token: string;
};

type PostContactSwitchResponse = {
  data: {
    success: boolean;
    message: string;
  };
};

type ContactSwitchManagerType = {
  contactSwitch: (
    params: PostContactSwitchParams,
  ) => Promise<PostContactSwitchResponse>;
};

const ContactSwitchManager =
  function _ContactSwitchManager(): ContactSwitchManagerType {
    const manager = Object.create(ContactSwitchManager.prototype);
    return manager;
  };

ContactSwitchManager.prototype = {
  async contactSwitch(params: PostContactSwitchParams) {
    const res = await httpClient.post<PostContactSwitchResponse>(
      `/contact_switch`,
      params,
    );
    return res;
  },
};

export default ContactSwitchManager();
