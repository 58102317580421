import httpClient from '@/http-client';

const UpdateEmailExtManager = function _UpdateEmailExtManager() {
  const manager = Object.create(UpdateEmailExtManager.prototype);
  return manager;
};

UpdateEmailExtManager.prototype = {
  async updateEmailExt(payload) {
    const response = await httpClient.post('/update_email_ext', payload);
    return response.data;
  },
};

export default UpdateEmailExtManager();
