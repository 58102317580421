<script setup>
defineOptions({ inheritAttrs: false });
defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const updateModelValue = event => {
  emit('update:modelValue', event.target.value);
};
</script>

<template>
  <input
    v-bind="$attrs"
    :value="modelValue"
    :class="{ error: isError }"
    class="base-input"
    @input="updateModelValue"
  />
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.base-input {
  width: 100%;
  height: 40px;
  padding: 8px 16px 6px;
  border: 1px solid $mochica_color_gray;
  border-radius: 4px;
  font-size: $font_14;
  transition:
    color 0.3s ease,
    border-color 0.3s ease;
  &::placeholder {
    color: #777777;
  }
  &:disabled {
    background-color: #f9f9f9;
    color: $mochica_color_gray;
  }
  &:focus {
    background: $white;
    border-color: $mochica-color;
    transition:
      color 0.3s ease,
      border-color 0.3s ease;
  }
  &.error {
    border: 1px $mochica_color_red solid;
  }
}
</style>
