<template>
  <div class="form__wrapper">
    <div class="form__inner">
      <div class="form__inner__contents">
        <div class="smartphone">
          <div class="mochica_desc">
            <input-selection-form
              v-if="mode === 'inputSelectionForm'"
              :selections="selections"
              :company-name="companyName"
              :is-loading="isLoading"
              @postSelectionTime="postSelectionTime"
            />
            <reserve-result
              v-else-if="mode === 'reserveResult'"
              :company-name="companyName"
              :reserve-result-data="reserveResultData"
            />
            <div v-else-if="mode === 'errorResult'" class="error_contents">
              {{ errorMessage }}
            </div>
            <notification />
          </div>
        </div>
      </div>
      <!-- form__inner__contents -->
    </div>
    <!-- form__inner -->
  </div>
  <!-- form__wrapper -->
</template>

<script>
import { defineComponent, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import qs from 'qs';
import selectionReserveService from '@/services/selection_reserve';
import Notification from '@/components/ui/notification/components/Notification';
import ReserveResult from '@/components/page/forms/selection_reserve_ext/components/ReserveResult';
import InputSelectionForm from '@/components/page/forms/selection_reserve_ext/components/InputSelectionForm';

const ReserveMode = {
  generateOtp: 'generateOtp',
  associateMail: 'associateMail',
  askOtp: 'askOtp',
  inputForm: 'inputForm',
  errorResult: 'errorResult',
  inputSelectionForm: 'inputSelectionForm',
  reserveResult: 'reserveResult',
  reserveTop: 'reserveTop',
};

export default defineComponent({
  name: 'SelectionReserveExt',
  components: {
    Notification,
    ReserveResult,
    InputSelectionForm,
  },
  setup(props, context) {
    const store = useStore();
    const token = ref(null);
    const selections = ref([]);
    const companyName = ref(null);
    const reserveResultData = ref(null);
    const mode = ref('');
    const errorMessage = ref('このURLは表示できません。');
    const isLoading = ref(false);

    // methods
    const init = async token => {
      // put token as a param in init
      const res = await selectionReserveService.selectionListRequest({
        token,
      });
      if (res.status_code === 1) {
        // incorrect token
        mode.value = ReserveMode.errorResult;
        return;
      }
      if (res.status_code === 2) {
        // 予約済だった場合
        errorMessage.value = res.message
          ? res.message
          : 'すでに選考予約済みのため登録できません。';
        mode.value = ReserveMode.errorResult;
        return;
      }
      res.selections.forEach(selection => {
        selection.venues.sort((a, b) => new Date(a.date) - new Date(b.date));
      });
      selections.value = res.selections;
      companyName.value = res.company.name;
      mode.value = ReserveMode.inputSelectionForm;
    };

    const postSelectionTime = async payload => {
      // payload param for this func
      isLoading.value = true;
      const res = await selectionReserveService.selectionReserveRequest({
        token: token.value,
        timetable_id: payload.timetableId,
      });
      isLoading.value = false;
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        return;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '送信完了しました。',
        type: res.success,
      });
      reserveResultData.value = res;
      mode.value = ReserveMode.reserveResult;
    };

    // lifecycle
    onBeforeMount(async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (!query.token) {
        mode.value = ReserveMode.errorResult;
        return;
      }
      token.value = query.token;
      await init(token.value);
    });

    return {
      selections,
      companyName,
      reserveResultData,
      mode,
      errorMessage,
      isLoading,
      postSelectionTime,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@include form_wrapper_css();

.form__inner__contents {
  align-items: unset;
  padding: 1% 0;
  .mochica_desc {
    text-align: center;
    padding: 2rem 0;
    .error_contents {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
}
.smartphone {
  width: 100%;
}
footer {
  width: 90%;
  margin: 0 auto;
}
</style>
