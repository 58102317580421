import httpClient from '@/http-client';
import { download } from '@/utils/file';

const MyPageManager = function _MyPageManager() {
  const manager = Object.create(MyPageManager.prototype);
  return manager;
};

MyPageManager.prototype = {
  async getApplicant(payload) {
    const response = await httpClient.post('/mypage/get_applicant', payload);
    return response.data;
  },
  async updateApplicant(payload) {
    const response = await httpClient.post('/mypage/update_applicant', payload);
    return response.data;
  },
  async getSelections(payload) {
    const response = await httpClient.post(
      '/selection_reserve_ext/get_list',
      payload,
    );
    return response.data;
  },
  async getFilelist(payload) {
    const response = await httpClient.post('/mypage/get_filelist', payload);
    return response.data;
  },
  async uploadFile(payload) {
    const formData = new FormData();
    formData.append('file', payload.file);
    const response = await httpClient.post(
      `/mypage/upload_file/${payload.token}`,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
    return response.data;
  },
  async deleteFile(payload) {
    const response = await httpClient.delete(
      `/mypage/delete_file/${payload.token}`,
      { data: payload.data },
    );
    return response.data;
  },
  async downloadFile(payload) {
    const response = await httpClient.get(
      `/mypage/file/${payload.uploadId}/download?token=${payload.token}`,
      { responseType: 'blob' },
    );
    if (response.status === 200 && response.data) {
      download(response.data, payload.originalFilename);
      return response.data;
    }
    return response.data;
  },
  async getCurrentSelection(payload) {
    const response = await httpClient.get(
      `/mypage/selection/current?token=${payload.token}`,
    );
    return response.data;
  },
  async getSelectionReservationList(payload) {
    const response = await httpClient.get(
      `/mypage/selection/reserve/${payload.token}`,
    );
    return response.data;
  },
  async postSelectionReservation(payload) {
    const response = await httpClient.post(
      `/mypage/selection/reserve/${payload.timetable_id}/${payload.token}`,
    );
    return response.data;
  },
  async postSelectionCancel(payload) {
    const response = await httpClient.post(
      `/mypage/selection/cancel/${payload.token}`,
      {
        progress_id: payload.progress_id,
        venue_id: payload.venue_id,
        time_id: payload.time_id,
      },
    );
    return response.data;
  },
};

export default MyPageManager();
