<template>
  <div v-if="modeEdit" class="edit-text">
    <h2 class="personal_setting_list_item_ttl">
      <span class="list_title">{{ title }}</span>
    </h2>

    <p class="pw_ttl">現在のパスワード</p>
    <div class="row">
      <input
        v-model="currentPassword"
        :class="{ 'is-danger': !errors.currentPassword.isValid }"
        name="pass"
        type="password"
        class="password"
      />
      <span v-show="!errors.currentPassword.isValid" class="fa fa-warning">
        {{ errors.currentPassword.message }}
      </span>
      <span v-if="modePass">
        <img
          slot="icon"
          class="icon"
          src="@/assets/img/check_white.svg"
          alt=""
        />
      </span>
    </div>

    <p class="pw_ttl">
      新しいパスワード（半角英数字・8〜64文字・大文字を1文字以上含んだ形式）
    </p>
    <div class="row">
      <input
        v-model="password"
        :class="{ 'is-danger': !errors.password.isValid }"
        name="new_pass"
        type="password"
        class="password"
        @blur="validatePassword('password', password)"
      />
      <span v-show="!errors.password.isValid" class="fa fa-warning">
        {{ errors.password.message }}
      </span>
    </div>

    <p class="pw_ttl">新しいパスワード（確認用）</p>
    <div class="row">
      <input
        v-model="confirmPassword"
        :class="{ 'is-danger': !errors.confirmPassword.isValid }"
        name="new_pass_conf"
        type="password"
        class="password"
      />
      <span v-show="!errors.confirmPassword.isValid" class="fa fa-warning">
        {{ errors.confirmPassword.message }}
      </span>
    </div>

    <div class="btn-box">
      <button-base
        class="btn"
        button-text="キャンセル"
        button-type="secondary"
        @click="reset()"
      />
      <button-base
        class="btn"
        button-text="パスワードを変更"
        button-type="success"
        icon-file-name="check_white"
        :rejected-admin-ids="[2]"
        @click="commit()"
      />
    </div>
  </div>

  <div v-else class="edit-text">
    <div class="item-title">
      <h2 class="personal_setting_list_item_ttl">
        <span class="list_title">{{ title }}</span>
      </h2>
      <button-base
        button-text="編集"
        icon-file-name="pen_white"
        :rejected-admin-ids="[2]"
        @click="modeEdit = true"
      />
    </div>
    <p>●●●●●●●●</p>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'EditPass',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    id: {
      type: Number,
      default: null,
    },
    current: {
      type: String,
      default: '',
    },
    new: {
      type: String,
      default: '',
    },
    confirm: {
      type: String,
      default: '',
    },
  },
  emits: ['commit'],
  setup(props, context) {
    const store = useStore();
    const {
      errors,
      resetErrors,
      validatePassword,
      validateComparisonPassword,
    } = useValidation(['currentPassword', 'password', 'confirmPassword']);
    const currentPassword = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const modeEdit = ref(false);
    const modePass = ref(false);

    const commit = () => {
      const validRes = validateAll();
      if (validRes.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: validRes.message,
          type: false,
        });
        return;
      }
      context.emit('commit', {
        id: props.id,
        current: currentPassword.value,
        new: password.value,
        confirm: confirmPassword.value,
      });
      reset();
    };
    const reset = () => {
      currentPassword.value = '';
      password.value = '';
      confirmPassword.value = '';
      modeEdit.value = false;
    };
    const validateAll = () => {
      resetErrors();
      const isValidPassword = validatePassword('password', password.value);
      if (
        !isValidPassword ||
        !validateComparisonPassword(password.value, confirmPassword.value)
      ) {
        return {
          success: false,
          message: '入力内容を確認して下さい',
        };
      }
      return { success: true, message: '' };
    };

    return {
      currentPassword,
      password,
      confirmPassword,
      modeEdit,
      modePass,
      errors,
      validatePassword,
      commit,
      reset,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects2();

.row {
  margin-bottom: 20px;
}
.pw_ttl {
  margin-bottom: 5px;
}
.password {
  width: 240px;
  padding: 7px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
}
.btn-box {
  .btn + .btn {
    margin-left: 7px;
  }
}
.btn-availability {
  > .icon {
    margin-right: 3px;
  }
}

.item-title {
  display: flex;
  justify-content: space-between;
  width: 580px;
  margin-bottom: 10px;
  margin-bottom: 20px;

  h2 {
    padding-top: 10px;
    margin-bottom: 0;
  }
}

@media (max-width: ($media_query_tablet)) {
  .item-title {
    width: 100%;
  }
}

@media (max-width: ($media_query_sp)) {
  .password {
    width: 100%;
  }
}
</style>
