<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content">
        <div class="body">
          <div class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl"></div>
            </div>
            <div
              class="modal-header_cancell"
              @click="$emit('onClickCancel', $event)"
            >
              <img src="@/assets/img/close_black.svg" alt />
            </div>
          </div>
          <div class="modal-body">
            <div class="sec-search-condition">
              <modal-selectable-applicants
                :send-limit="sendLimit"
                :initi-selected-regist-applicants="initSelectedRegistApplicants"
                :initi-selected-line-friends="initSelectedLineFriends"
                :is-disp-line-friend="isDispLineFriend"
                @onChangeCurrentSelect="onChangeCurrentSelect"
              />
            </div>
          </div>
          <div class="btn-col2">
            <div
              class="btn btn-unavailable"
              @click="$emit('onClickCancel', $event)"
            >
              キャンセル
            </div>
            <div class="btn btn-availability" @click="onClickSettle">
              応募者の選択を確定する
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import Modal from '@/components/ui/modals/components/Modal';
import ModalSelectableApplicants from '@/components/features/modalSelectableApplicants/components/ModalSelectableApplicants';

export default defineComponent({
  name: 'ModalNoticeSelectableApplicants',
  components: { Modal, ModalSelectableApplicants },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    initSelectedRegistApplicants: {
      type: Array,
      default: () => [],
    },
    initSelectedLineFriends: {
      type: Array,
      default: () => [],
    },
    sendLimit: {
      type: Number,
      default: 100,
    },
    isDispLineFriend: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onClickCancel', 'onChangeCurrentSelect', 'onClickSettle'],
  setup(props, context) {
    const store = useStore();
    const selectedAllApplicantsLength = ref([]);
    const onChangeCurrentSelect = payload => {
      selectedAllApplicantsLength.value = payload.selectedApplicantIds.length;
      context.emit('onChangeCurrentSelect', payload);
    };
    const onClickSettle = () => {
      if (selectedAllApplicantsLength.value > props.sendLimit) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `応募者の選択は${props.sendLimit}名以下に設定してください`,
          type: false,
        });
        return;
      }
      context.emit('onClickSettle');
    };
    return { onChangeCurrentSelect, onClickSettle };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
}
.sec-search-condition {
  font-size: 1.2rem;
  background: #fff;
  padding: 2rem;
}

.modal-body {
  text-align: start;
}
img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}
.btn-col2 {
  width: 90%;
  margin: 30px auto;
}
</style>
