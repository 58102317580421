import csv from '@/assets/csv/pottos.csv';
import env from '@/utils/env';

const parsePottosCsv = tmpCsv => {
  let parsedCsv = [...tmpCsv];
  parsedCsv.shift();
  parsedCsv = parsedCsv.map(x => ({
    id: parseInt(x[0], 10),
    eventKey: x[1],
    pageName: x[2],
    buttonName: x[3],
  }));
  return parsedCsv;
};
const pottosEventList = parsePottosCsv(csv);

export const postPottosEvent = eventId => {
  const currentEnv = env.getEnv();
  if (currentEnv !== 'production') return;
  if (window.pottos && eventId) {
    const targetEvent = pottosEventList.find(
      x => x.id === parseInt(eventId, 10),
    );
    if (targetEvent) {
      window.pottos(
        targetEvent.eventKey,
        targetEvent.pageName,
        targetEvent.buttonName,
      );
    }
  }
};
