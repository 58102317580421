<template>
  <li :id="`venue_id_${venue.id}`" class="venue-lists">
    <div class="venue-list-item">
      <selections-venue-basic-info
        ref="refSelectionsVenueBasicInfo"
        :ref-selections-timetables="{ [venue.id]: refSelectionsTimetable }"
        :i-venue="iVenue"
        :is-announce-modal="isAnnounceModal"
        :is-connected-google-calendar="isConnectedGoogleCalendar"
        :is-registration-selection="isRegistrationSelection"
        :is-edit-selection="isEditSelection"
        :selection-venues="selectionVenues"
        :create-venue="createVenue"
        :update-venue="updateVenue"
        :update-timetable="updateTimetable"
        :update-venue-data="updateVenueData"
        :update-venue-data-by-key="updateVenueDataByKey"
        :update-active-venue="updateActiveVenue"
        :validate-venue-and-timetables="validateVenueAndTimetables"
        @onAddVenue="
          isEditTimetable = false;
          $emit('onAddVenue', $event);
        "
        @fetchAll="$emit('fetchAll', $event)"
      />
      <div :class="{ edit: isEditTimetable === true }" class="timetable">
        <div class="timetable-header">
          <div>
            <span class="title">開催時間一覧</span>
            <span class="value">合計参加人数 {{ venue.actual }}</span>
          </div>
        </div>
        <ul class="timetable-time-list">
          <template v-for="(timetable, iTimetable) in venue.timetables">
            <!-- 開催時間編集画面項目（Google登録済みを外部スタッフが編集時のみ、編集できないので表示画面を表示） -->
            <selections-venue-timetable-edit
              v-if="
                isEditTimetable === true &&
                getIsEditableTimetable(timetable) === true
              "
              ref="refSelectionsTimetable"
              :key="`venue_${venue.id}_timetable_${iTimetable}`"
              :i-venue="iVenue"
              :i-timetable="iTimetable"
              :google-resources="googleResources"
              :enable-zoom="enableZoom"
              :staffs="staffs"
              :selection-venues="selectionVenues"
              :is-connected-google-calendar="isConnectedGoogleCalendar"
              :is-edit-selection="isEditSelection"
              :update-venue-data-by-key="updateVenueDataByKey"
              :delete-timetable="deleteTimetable"
              :update-timetable-data-by-index-in-venue-data="
                updateTimetableDataByIndexInVenueData
              "
              :delete-zoom-meeting="deleteZoomMeeting"
              @fetchAll="$emit('fetchAll', $event)"
            />
            <!-- 開催時間表示画面項目 -->
            <selections-venue-timetable
              v-else
              ref="refSelectionsTimetable"
              :key="`venue_${venue.id}_timetable_${iTimetable}`"
              :i-venue="iVenue"
              :i-timetable="iTimetable"
              :google-resources="googleResources"
              :enable-zoom="enableZoom"
              :is-edit-timetable="isEditTimetable"
              :staffs="staffs"
              :selection-venues="selectionVenues"
              :is-connected-google-calendar="isConnectedGoogleCalendar"
              :is-edit-selection="isEditSelection"
              :send-assignment-reminder="sendAssignmentReminder"
              :update-google-event="updateGoogleEvent"
              :update-zoom-meeting="updateZoomMeeting"
              @fetchAll="$emit('fetchAll', $event)"
            />
          </template>
        </ul>
        <div class="new_add_timetable">
          <button-base
            v-if="isEditTimetable === true"
            :button-text="'新規開催時間の追加'"
            :icon-file-name="'plus_circle'"
            :rejected-role-ids="rejectedRoleIds"
            :rejected-admin-ids="rejectedAdminIds"
            @click="addTimeTable"
          />
          <!-- 新規登録画面|Google未連携|Google連携済かつ社内スタッフ の場合一括割当て可能 -->
          <div
            v-if="isModalSelectionMode !== true && isEditTimetable === true"
            class="bulk_assign_area"
          >
            <button-base
              class="btn btn-bulk_assign"
              button-text="開催時間の一括操作"
              @click="isVisibleBulkEditTimetable = !isVisibleBulkEditTimetable"
            />
            <balloon-menu
              :is-view="isVisibleBulkEditTimetable"
              @close="isVisibleBulkEditTimetable = false"
            >
              <template #content>
                <div>
                  <div
                    v-if="isVisibleBulkAssignmentStaff === true"
                    class="applicants-edit-menu"
                    @click="isVisibleModalBulkAssignmentStaff = true"
                  >
                    <span>選考担当者の一括割当て</span>
                  </div>
                  <div
                    class="applicants-edit-menu"
                    @click="isVisibleBulkAssignmentPassword = true"
                  >
                    <span>出席確認パスワードの一括割当て</span>
                  </div>
                  <div
                    v-if="enableZoom === true"
                    class="applicants-edit-menu"
                    @click="isVisibleBulkAssignmentZoomHost = true"
                  >
                    <span>Zoomのホストの一括割当て</span>
                  </div>
                </div>
              </template>
            </balloon-menu>
          </div>
        </div>
        <div
          v-if="isEditSelection === true && venue.id !== null"
          class="edit_timetable btns-area"
        >
          <button-base
            v-if="$store.getters['staff/isOperator'] !== true"
            :button-text="
              isEditTimetable === true ? '詳細内容を保存' : '詳細内容を編集'
            "
            :button-type="isEditTimetable === true ? 'success' : 'primary'"
            :icon-file-name="isEditTimetable === true ? 'check' : 'pen'"
            :is-loading="isLoading === true && isEditTimetable === true"
            :rejected-role-ids="rejectedRoleIds"
            :rejected-admin-ids="rejectedAdminIds"
            @click="
              isEditTimetable === true ? updateVenueLocal() : onClickEditVenue()
            "
          />
          <button-base
            v-if="venue.actual !== 0 && isEditTimetable !== true"
            :button-text="'応募者のCSVをダウンロード'"
            :icon-file-name="'download'"
            :rejected-role-ids="rejectedRoleIds"
            :rejected-admin-ids="rejectedAdminIds"
            @click="downloadApplicantCsv({ venueId: venue.id })"
          />
        </div>
      </div>
      <modal-dialog
        :is-view="isVisibleModalCopy"
        :accent-color="'#1899D6'"
        :title="'会場名 ' + modalCopyName + ' を複製します。'"
        :message="'登録されている全ての開催日・人数をも併せて複製します。'"
        :button-text1="'キャンセル'"
        :button-text2="'会場を複製'"
        @onSelected="copyVenue"
        @onclickOuter="isVisibleModalCopy = $event"
      />
      <modal-dialog
        :is-view="isVisibleModalDelete"
        :accent-color="'#C31E1E'"
        :title="'会場名 ' + modalDeleteName + 'を削除します。'"
        :message="'登録されている全ての開催日・人数をも併せて削除されます。この操作は取り消すことが出来ません。'"
        :button-text1="'キャンセル'"
        :button-text2="'会場を削除'"
        :aleart-flag="true"
        @onSelected="deleteVenueLocal"
        @onclickOuter="isModalDelete = $event"
      />
    </div>
    <!-- Google未連携|Google連携済みかつ社内スタッフ の場合複製・削除可能 -->
    <div
      v-if="
        isModalSelectionMode !== true &&
        isMultipleVenues === true &&
        $store.getters['staff/isOperator'] !== true
      "
      class="btns-area venue-list-item-action"
    >
      <button-base
        :button-text="`選考会場${iVenue + 1}を複製`"
        :icon-file-name="'copy'"
        :rejected-role-ids="rejectedRoleIds"
        :rejected-admin-ids="rejectedAdminIds"
        @click="onClickVenueCopy"
      />
      <button-base
        v-if="
          isDeletable === true &&
          venue.actual === 0 &&
          $store.getters['staff/isOperator'] !== true
        "
        :button-text="`選考会場${iVenue + 1}を削除`"
        :button-type="'secondary'"
        :icon-file-name="'trash'"
        :rejected-role-ids="rejectedRoleIds"
        :rejected-admin-ids="rejectedAdminIds"
        @click="onClickVenueDelete"
      />
    </div>
    <modal-bulk-assign-staff
      :is-view="isVisibleModalBulkAssignmentStaff"
      :staffs="staffs"
      :selected-staffs="staffs.filter(v => v.selected)"
      :validate-staffs="validateStaffs"
      @selectStaff="$emit('selectStaff', $event)"
      @setStaffs="setStaffs"
      @refetchStaffs="refetchStaffs"
      @onClickCancel="closeBulkAssignStaffModal"
    />
    <modal-bulk-assign-password
      :is-view="isVisibleBulkAssignmentPassword"
      @setPassword="setPassword"
      @onClickCancel="isVisibleBulkAssignmentPassword = false"
    />
    <modal-bulk-assign-zoom-host
      :is-view="isVisibleBulkAssignmentZoomHost"
      :zoom-users="zoomUsers"
      @setZoomHost="setZoomHost"
      @onClickCancel="isVisibleBulkAssignmentZoomHost = false"
    />
  </li>
</template>

<script>
import { computed, defineComponent, ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import assignment from '@/components/features/selectionDetailBody/defines/assignment';
import TimeTableModel from '@/models/timetable';
import BalloonMenu from '@/components/ui/menus/components/BalloonMenu';
import ModalDialog from '@/components/ui/modals/components/ModalDialog';
import SelectionsVenueBasicInfo from '@/components/features/selectionDetailBody/components/SelectionsVenueBasicInfo';
import SelectionsVenueTimetable from '@/components/features/selectionDetailBody/components/SelectionsVenueTimetable';
import SelectionsVenueTimetableEdit from '@/components/features/selectionDetailBody/components/SelectionsVenueTimetableEdit';
import ModalBulkAssignStaff from '@/components/features/selectionDetailBody/components/ModalBulkAssignStaff';
import ModalBulkAssignPassword from '@/components/features/selectionDetailBody/components/ModalBulkAssignPassword';
import ModalBulkAssignZoomHost from '@/components/features/selectionDetailBody/components/ModalBulkAssignZoomHost';

export default defineComponent({
  name: 'SelectionsVenue',
  components: {
    BalloonMenu,
    ModalDialog,
    SelectionsVenueBasicInfo,
    SelectionsVenueTimetable,
    SelectionsVenueTimetableEdit,
    ModalBulkAssignStaff,
    ModalBulkAssignPassword,
    ModalBulkAssignZoomHost,
  },
  props: {
    maxVenueLength: {
      type: Number,
      default: null,
    },
    maxTimetableLength: {
      type: Number,
      default: null,
    },
    iVenue: {
      type: Number,
      default: null,
    },
    selectionId: {
      type: Number,
      default: null,
    },
    isRegistrationSelection: {
      type: Boolean,
      default: false,
    },
    isEditSelection: {
      type: Boolean,
      default: false,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    isModalSelectionMode: {
      type: Boolean,
      default: false,
    },
    isAnnounceModal: {
      type: Boolean,
      default: false,
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    googleResources: {
      type: Array,
      default: () => [],
    },
    isConnectedGoogleCalendar: {
      type: Boolean,
      default: false,
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    zoomUsers: {
      type: Array,
      default: () => [],
    },
    selectionVenues: {
      type: Array,
      default: () => [],
    },
    createVenue: {
      type: Function,
      default: () => {},
    },
    updateVenue: {
      type: Function,
      default: () => {},
    },
    deleteVenue: {
      type: Function,
      default: () => {},
    },
    updateTimetable: {
      type: Function,
      default: () => {},
    },
    updateVenueData: {
      type: Function,
      default: () => {},
    },
    updateVenueDataByKey: {
      type: Function,
      default: () => {},
    },
    deleteTimetable: {
      type: Function,
      default: () => {},
    },
    updateActiveVenue: {
      type: Function,
      default: () => {},
    },
    sendAssignmentReminder: {
      type: Function,
      default: () => {},
    },
    updateTimetableDataByIndexInVenueData: {
      type: Function,
      default: () => {},
    },
    addTimetableInVenueData: { type: Function, default: () => {} },
    updateGoogleEvent: { type: Function, default: () => {} },
    updateZoomMeeting: { type: Function, default: () => {} },
    deleteZoomMeeting: { type: Function, default: () => {} },
    downloadApplicantCsv: { type: Function, default: () => {} },
    resetStaffsSelected: { type: Function, default: () => {} },
  },
  emits: [
    'onAddVenue',
    'fetchAll',
    'selectStaff',
    'setStaffs',
    'refetchStaffs',
  ],
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const refSelectionsVenueBasicInfo = ref(null);
    const refSelectionsTimetable = ref(null);
    const modalCopyName = ref('');
    const modalDeleteName = ref('');
    const isLoading = ref(false);
    // 選考新規作成及び選考編集時は編集状態が初期状態
    const isEditTimetable = ref(
      props.isRegistrationSelection === true ||
        (props.isEditSelection === true &&
          !props.selectionVenues[props.iVenue].id),
    );
    const isVisibleBulkEditTimetable = ref(false);
    const isVisibleModalCopy = ref(false);
    const isVisibleModalDelete = ref(false);
    const isVisibleModalBulkAssignmentStaff = ref(false);
    const isVisibleBulkAssignmentPassword = ref(false);
    const isVisibleBulkAssignmentZoomHost = ref(false);

    // computed
    const isGoogleMember = computed(() => {
      return store.getters['staff/staff']
        ? store.getters['staff/staff'].is_google_member
        : false;
    });
    // 担当者一括アサインボタン表示条件
    const isVisibleBulkAssignmentStaff = computed(() => {
      // 選考新規作成画面であれば編集可能
      if (props.isRegistrationSelection === true) return true;
      // Google未連携であれば編集可能
      if (props.isConnectedGoogleCalendar !== true) return true;
      // Google連携済みかつ社内メンバーであれば編集可能
      if (isGoogleMember.value === true) return true;
      return false;
    });
    // 会場複製・削除条件
    const isMultipleVenues = computed(() => {
      // 選考新規作成画面では複製・削除不可能
      if (props.isRegistrationSelection === true) return false;
      // Google未連携であれば複製・削除可能
      if (props.isConnectedGoogleCalendar !== true) return true;
      // Google連携済みかつ社内メンバーであれば複製・削除可能
      if (isGoogleMember.value === true) return true;
      return false;
    });
    const venue = computed(() => props.selectionVenues[props.iVenue]);

    // methods
    // 開催時間の更新
    const updateVenueLocal = async () => {
      if (isEditTimetable.value !== true) return;
      const refTimetables = refSelectionsTimetable.value;
      if (refTimetables && refTimetables.length > 0) {
        // 全開催時間のバリデーション
        refTimetables.map(v => v.validateTimetable());
      }
      isLoading.value = true;
      const res = await props.updateTimetable(
        props.iVenue,
        props.isGoogleMember,
        store,
      );
      isLoading.value = false;
      if (res.success !== true) return;
      isEditTimetable.value = false;
      if (res.success === true) context.emit('fetchAll');
    };
    // 会場の削除
    const deleteVenueLocal = async ({ choice }) => {
      isVisibleModalDelete.value = false;
      if (choice === 1) return;
      await props.deleteVenue(venue.value.id, props.iVenue, store);
    };
    // 開催時間の追加（ローカル処理）
    const addTimeTable = () => {
      if (venue.value.timetables.length >= props.maxTimetableLength) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `1つの会場に作成できる開催時間は${props.maxTimetableLength}個までです`,
          type: false,
        });
        return;
      }
      // Google連携済みかつGoogle WorkSpaceスタッフの場合、isCreateGoogleCalendarはデフォルトtrue
      const newTimetable =
        props.isConnectedGoogleCalendar === true &&
        props.isGoogleMember === true
          ? new TimeTableModel(
              null,
              null,
              null,
              0,
              0,
              false,
              '',
              [],
              [],
              [],
              '',
              false,
              [],
              true,
            )
          : new TimeTableModel();
      // selectStaffsはmodelで作成されないので手動で付与
      newTimetable.selectStaffs = cloneDeep(props.staffs);
      // selectResourcesはmodelで作成されないので手動で付与
      newTimetable.selectResources = cloneDeep(props.googleResources);
      // selectZoomHostsはmodelで作成されないので手動で付与
      newTimetable.selectZoomHosts = cloneDeep(props.zoomUsers);
      props.addTimetableInVenueData(props.iVenue, newTimetable);
    };
    // 会場の複製（ローカル処理）
    const copyVenue = event => {
      isVisibleModalCopy.value = false;
      if (event.choice !== 1) context.emit('onCopyVenue', venue.value);
    };
    // 会場の複製モーダル開く
    const onClickVenueCopy = () => {
      // 編集時は会場登録は300個まで
      if (
        props.selectionId &&
        props.selectionVenues.length >= props.maxVenueLength
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `選考会場の登録数の上限は${props.maxVenueLength}です`,
          type: false,
        });
        return;
      }
      // 新規作成時のみ会場登録は1つまで
      if (!props.selectionId && props.selectionVenues.length >= 1) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '選考新規作成時の選考会場の登録数の上限は1です',
          type: false,
        });
        return;
      }
      isVisibleModalCopy.value = true;
      modalCopyName.value = venue.value.name;
    };
    // 会場の削除モーダル開く
    const onClickVenueDelete = () => {
      if (props.selectionId && venue.value.timetables.length > 1) {
        // 編集時のみ複数開催時間がある会場は削除できない
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '開催時間が複数ある会場は削除できません',
          type: false,
        });
        return;
      }
      isVisibleModalDelete.value = true;
      modalDeleteName.value = venue.value.name;
    };
    // 開催時間の編集開始
    const onClickEditVenue = () => {
      isLoading.value = false;
      isEditTimetable.value = !isEditTimetable.value;
    };
    // 開催時間を編集できる条件
    const getIsEditableTimetable = tmpTimetable => {
      // Google未連携の場合は常に編集可能
      if (props.isConnectedGoogleCalendar !== true) return true;
      // Google連携時、Google登録できるスタッフの場合は常に編集可能
      if (isGoogleMember.value === true) return true;
      // Google連携時、社外スタッフはGoogle未登録の開催時間の場合に編集可能
      if (
        tmpTimetable.hasGoogleEventId !== true &&
        tmpTimetable.isGoogleSyncError !== true
      ) {
        return true;
      }
      return false;
    };
    const validateVenueAndTimetables = () => {
      // 親のコンポーネントで呼び出されるので注意
      const isValidVenue = refSelectionsVenueBasicInfo.value.validateVenue();
      const isValidTimetables = refSelectionsTimetable.value
        .map(refTimetable => refTimetable.validateTimetable())
        .every(v => v === true);
      return isValidVenue === true && isValidTimetables === true;
    };
    // 担当者の人数チェック
    const validateStaffs = selectCount => {
      if (selectCount > assignment.ASSIGNMENT_LIMIT_COUNT) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `${assignment.ASSIGNMENT_LIMIT_COUNT}名以上の担当者は選択できません`,
          type: false,
        });
        return { success: false, selectedStaffIds: [] };
      }
      const selectedStaffIds = props.staffs
        .filter(tmpStaff => tmpStaff.selected)
        .map(tmpStaff => tmpStaff.id);
      // 現在選択中のスタッフを全て取得
      const allStaffIds = [];
      venue.value.timetables.forEach(tmpTimetable => {
        allStaffIds.push(
          tmpTimetable.selectStaffs.filter(v => v.selected).map(v => v.id),
        );
      });
      // 最大人数を超えたtimetableを算出
      const overLimitStaffCounts = allStaffIds
        .map(v => v.length)
        .filter(
          v => v > assignment.ASSIGNMENT_LIMIT_COUNT - selectedStaffIds.length,
        );
      if (overLimitStaffCounts.length === 0) {
        return { success: true, selectedStaffIds };
      }
      // 人数の最大値を算出
      const maxAssignmentCount = overLimitStaffCounts.reduce((a, b) =>
        Math.max(a, b),
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: `担当者が${maxAssignmentCount}人割当てられている開催時間があるためこれ以上選択できません`,
        type: false,
      });
      return { success: false, selectedStaffIds };
    };
    // 担当者一括割当て
    const setStaffs = selectCount => {
      // ローカルの選考担当者を1名も選択していない場合のみ一括更新
      const resultValidateStaffs = validateStaffs(selectCount);
      if (resultValidateStaffs.success !== true) return;
      // ローカルの開催時間の担当者を更新
      setSelectedStaffAll(resultValidateStaffs.selectedStaffIds);
      closeBulkAssignStaffModal();
    };
    // 開催時間へ担当者を一括割当てモーダルで、確定押下後の確定時の処理（ローカル）
    const setSelectedStaffAll = selectedStaffIds => {
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((timetable, i) => {
          timetable.selectStaffs = timetable.selectStaffs.map(tmpStaff => {
            if (selectedStaffIds.includes(tmpStaff.id))
              tmpStaff.selected = true;
            return tmpStaff;
          });
          return timetable;
        }),
      );
    };
    // 担当者一括割当て後スタッフ再取得
    const refetchStaffs = async () => {
      closeBulkAssignStaffModal();
      // 詳細表示・編集時では、担当者情報をリロード
      context.emit('fetchSelectionDetail', router);
    };
    // 担当者一括割当てモーダルをリセットして閉じる
    const closeBulkAssignStaffModal = () => {
      props.resetStaffsSelected();
      isVisibleModalBulkAssignmentStaff.value = false;
    };
    // パスワード一括割当て(ローカル)
    const setPassword = password => {
      if (venue.value.timetables.every(v => v.reminderCheck === true)) {
        // 全ての開催時間で出席確認のチェックボックスが入力済みの場合
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '既にパスワードが設定されているため一括入力できません',
          type: false,
        });
        return;
      }
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((timetable, i) => {
          // パスワード未設定の開催時間のみ割当て
          if (timetable.reminderCheck !== true) {
            timetable.reminderCheck = true;
            timetable.reminderPassword = password;
          }
          return timetable;
        }),
      );
      isVisibleBulkAssignmentPassword.value = false;
    };
    // Zoomホスト一括割当て（ローカル）
    const setZoomHost = selectZoomHosts => {
      if (
        !venue.value.timetables.find(
          v =>
            !(
              v.isCreateZoomUrl === true &&
              v.selectZoomHosts.find(v => v.selected)
            ) && !v.zoomId,
        )
      ) {
        // 全ての開催時間でZoomホストが選択済みの場合
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '既にZoomのホストが選択されているため一括入力できません',
          type: false,
        });
        return;
      }
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((timetable, i) => {
          // Zoomホスト未選択の開催時間のみ割当て
          if (
            !(
              timetable.isCreateZoomUrl === true &&
              timetable.selectZoomHosts.find(v => v.selected)
            ) &&
            !timetable.zoomId
          ) {
            timetable.isCreateZoomUrl = true;
            timetable.selectZoomHosts = cloneDeep(selectZoomHosts);
          }
          return timetable;
        }),
      );
      nextTick(() => {
        const refTimetables = refSelectionsTimetable.value;
        // 全ての開催時間でZoomホストのバリデーション
        if (refTimetables && refTimetables.length > 0) {
          refTimetables.map(v => v.validateZoomHost());
        }
      });
      isVisibleBulkAssignmentZoomHost.value = false;
    };

    return {
      refSelectionsVenueBasicInfo,
      refSelectionsTimetable,
      rejectedRoleIds: [3],
      rejectedAdminIds: [2],
      modalCopyName,
      modalDeleteName,
      isLoading,
      isEditTimetable,
      isVisibleBulkEditTimetable,
      isVisibleModalCopy,
      isVisibleModalDelete,
      isVisibleModalBulkAssignmentStaff,
      isVisibleBulkAssignmentPassword,
      isVisibleBulkAssignmentZoomHost,
      isVisibleBulkAssignmentStaff,
      isMultipleVenues,
      venue,
      updateVenueLocal,
      deleteVenueLocal,
      addTimeTable,
      copyVenue,
      onClickEditVenue,
      onClickVenueCopy,
      onClickVenueDelete,
      getIsEditableTimetable,
      setZoomHost,
      validateVenueAndTimetables,
      validateStaffs,
      setStaffs,
      refetchStaffs,
      closeBulkAssignStaffModal,
      setPassword,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.venue-lists {
  margin-top: 30px;
}

.venue-list-item {
  border-radius: 4px 4px 0 0 / 4px 4px 0 0;
  border: 2px solid #808080;
}

.timetable-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  position: relative;
  background-color: #e2e2e2;
  .title,
  .value {
    margin-right: 20px;
  }
}

.new_add_timetable {
  margin: 20px 16px;
  &:empty {
    margin: 0;
  }
  .icon {
    margin-right: 5px;
  }
}

.bulk_assign_area {
  display: inline-block;
  position: relative;
  .btn-bulk_assign {
    width: 180px;
    padding: 10px 10px 10px 0;
    margin-left: 8px;
    background-image: url('../../../../assets/img/rect_arrow_down_white.svg');
    background-position: right 10px center;
    background-size: 10px;
    background-repeat: no-repeat;
  }
}

.edit_timetable {
  margin: 20px;
  text-align: center;
}

.venue-list-item-action {
  margin: 20px 0;
  text-align: center;
}

.applicants-edit-menu {
  padding: 15px 10px;
  border-bottom: dashed 1px #eee;
  cursor: pointer;
}

.applicants-edit-menu:last-child {
  border-bottom: none;
}

.btns-area {
  button {
    margin: 10px 6px 0 0;
  }
}

@media (max-width: ($media_query_sp)) {
  .btns-area {
    text-align: left;
  }

  .timetable-header {
    display: block;
    flex-direction: column;
    .title,
    .value {
      margin-bottom: 10px;
      display: block;
      flex-direction: column;
    }
  }

  .bulk_assign_area {
    .btn-bulk_assign {
      margin: 15px 0;
    }
  }
}
</style>
