<template>
  <main class="entry_form">
    <div>
      <p class="entry_form_title">入力内容の確認</p>
      <p class="entry_form_caption">
        エントリー内容をご確認の上、問題なければ登録ボタンを押してください。
      </p>
      <template v-if="entryForm.email">
        <p class="input_head">メールアドレス</p>
        <p class="email_display">{{ entryForm.email }}</p>
      </template>
      <p class="input_head">卒業年数・登録種別</p>
      <p v-if="entryForm.graduated < 9900" class="input_display">
        {{ entryForm.graduated }}年3月卒業予定
      </p>
      <p v-else class="input_display">
        {{ graduatedYearToLabelName(entryForm.graduated) }}
      </p>
      <p class="input_head">姓名</p>
      <p class="input_display">
        {{ entryForm.lastname }}{{ entryForm.firstname }}
      </p>
      <p class="input_head">セイメイ</p>
      <p class="input_display">
        {{ entryForm.lastnameKana }}{{ entryForm.firstnameKana }}
      </p>
      <template v-if="entryForm.birth">
        <p class="input_head">生年月日</p>
        <p class="input_display">
          {{ $utils.parseDateTime(entryForm.birth, 'YYYY年M月D日') }}
        </p>
      </template>
      <template v-if="entryForm.genderId">
        <p class="input_head">性別</p>
        <p class="input_display">
          {{
            entryForm.genderId === '1'
              ? '男性'
              : entryForm.genderId === '2'
                ? '女性'
                : '回答しない'
          }}
        </p>
      </template>
      <template v-if="entryForm.tel">
        <p class="input_head">電話番号</p>
        <p class="input_display">
          {{ entryForm.tel }}
        </p>
      </template>
      <template v-if="entryForm.zipcode">
        <p class="input_head">郵便番号</p>
        <p class="input_display">
          {{ entryForm.zipcode }}
        </p>
      </template>
      <template v-if="entryForm.address">
        <p class="input_head">住所</p>
        <p class="input_display">
          {{ entryForm.address }}
        </p>
      </template>
      <template v-if="entryForm.school">
        <p class="input_head">学校名</p>
        <p class="input_display">
          {{ entryForm.school }}
        </p>
      </template>
      <template v-if="entryForm.faculty">
        <p class="input_head">学部</p>
        <p class="input_display">
          {{ entryForm.faculty }}
        </p>
      </template>
      <template v-if="entryForm.department">
        <p class="input_head">学科</p>
        <p class="input_display">
          {{ entryForm.department }}
        </p>
      </template>
      <template v-if="entryForm.departmentCategoryId">
        <p class="input_head">文理</p>
        <p class="input_display">
          {{
            entryForm.departmentCategoryId === '1'
              ? '文'
              : entryForm.departmentCategoryId === '2'
                ? '理'
                : '回答しない'
          }}
        </p>
      </template>
      <template v-if="questions && questions.length > 0">
        <p class="question_subject">アンケートの回答内容</p>
        <div v-for="(question, i) in questions" :key="`question_${i}`">
          <div class="question_text">質問 {{ i + 1 }}</div>
          <p class="input_head question_title">{{ question.question_title }}</p>
          <p class="input_display">
            <span
              v-for="(answer, index) in question.answers.filter(
                x => x.isSelected,
              )"
              :key="`answer_${index}`"
            >
              {{ answer.flag_name }}
            </span>
          </p>
        </div>
      </template>
      <div class="input_footer">
        <button class="submit_button" @click.prevent="$emit('register')">
          上記の内容で登録
        </button>
        <button class="cancel_button" @click.prevent="$emit('backPage')">
          前のページに戻る
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent } from 'vue';

import GraduatedDefines from '@/defines/graduated';

export default defineComponent({
  name: 'LineEntryPreview',
  props: {
    entryForm: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['register', 'backPage'],
  setup(props, context) {
    const graduatedYearToLabelName = year => {
      const res = GraduatedDefines.find(
        defineTitle => defineTitle.year === year,
      );
      if (res !== undefined) return res.name_entry;
      return `${year}`;
    };

    return { graduatedYearToLabelName };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.entry_form {
  font-size: 14px;
}

.entry_form_title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.entry_form_caption {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 4px solid #f0f0f0;
  letter-spacing: 0;
}

.input_head {
  margin: 0 auto 3px 0;
  font-weight: bold;
  line-height: 1.3;
  word-break: break-all;
  white-space: pre-wrap;
}

.email_display {
  padding-bottom: 20px;
  margin-bottom: 20px;
  line-height: 1.6;
  border-bottom: 1px solid #f0f0f0;
  line-height: 1.3;
  word-break: break-all;
  white-space: pre-wrap;
}

.input_display {
  margin-bottom: 20px;
  line-height: 1.6;
  word-break: break-all;

  > span {
    display: block;
  }
}

.question_subject {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 18px;
  padding-top: 28px;
  border-top: solid 1px #e0e0e0;
}

.question_text {
  margin-bottom: 4px;
  color: #777777;
  text-align: left;
  font-weight: bold;
}

.question_title {
  margin-bottom: 10px;
  text-align: left;
  word-break: break-all;
  white-space: pre-wrap;
  font-weight: bold;
}

.input_footer {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #f0f0f0;
}

.submit_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  background: #545454;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &.is-disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.cancel_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 10px;
  font-weight: normal;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  cursor: pointer;
}
</style>
