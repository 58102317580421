import { computed, reactive, ref } from 'vue';
import cloneDeep from 'lodash/cloneDeep';

import selectionsService from '@/services/selections';
import progressesService from '@/services/progresses';
import eventsService from '@/services/events';
import staffsService from '@/services/staffs';
import applicantService from '@/services/applicants';
import googleCalendarService from '@/services/google-calendar';
import zoomService from '@/services/zoom';
import SelectionModel from '@/models/selection';

export function useSelection({
  initSelectionId = null,
  initSelectionDetailMode = null,
  initSelection = {},
}) {
  const SELECTION_DETAIL_MODES = Object.freeze({
    VIEW: 1,
    REGISTRATION: 2,
    EDIT: 3,
  });
  const MAX_VENUE_LENGTH = 300;
  const MAX_ACTIVE_VENUE_LENGTH = 30;
  const MAX_TIMETABLE_LENGTH = 30;
  const selectionId = ref(initSelectionId);
  const selectionDetailMode = ref(
    initSelectionDetailMode
      ? initSelectionDetailMode
      : SELECTION_DETAIL_MODES.VIEW,
  );
  const selection = reactive({ data: initSelection });
  const staffs = ref([]);
  const events = ref([]);
  const isConnectedGoogleCalendar = ref(false);
  const applicants = ref([]);
  const applicantSearch = reactive({
    body: {},
    sort: {},
    pager: { page: 0, count: 0, pageCount: 0 },
  });
  const googleResources = ref([]);
  const enableZoom = ref(false);
  const zoomUsers = ref([]);
  const sortDate = ref(1);

  // computed
  const selectionVenues = computed(() => selection.data.venues);
  const isLocalStateLoaded = computed(
    () => Object.keys(selection.data).length >= 1 && events.value.length >= 1,
  );
  const isRegistrationSelection = computed(
    () => selectionDetailMode.value === SELECTION_DETAIL_MODES.REGISTRATION,
  );
  const isEditSelection = computed(
    () => selectionDetailMode.value === SELECTION_DETAIL_MODES.EDIT,
  );

  // methods
  /**
   * 選考取得
   * @param { Object } router ルーターオブジェクト
   */
  const fetchSelectionDetail = async router => {
    const res = await selectionsService.fetchSelection(
      selectionId.value,
      sortDate.value,
    );
    // Googleカレンダー連携が使えるかどうか
    isConnectedGoogleCalendar.value = res.isConnectedGoogleCalendar === true;
    // Googleカレンダー会議室が使えるかどうか
    googleResources.value =
      isConnectedGoogleCalendar.value === true &&
      res.isConnectedGoogleWorkspaceCustomerId === true &&
      res.calendarResources.length > 0
        ? res.calendarResources
        : [];
    // Zoom連携が使えるかどうか
    enableZoom.value = res.enableZoom === true;
    zoomUsers.value = enableZoom.value === true ? res.zoomUsers : [];
    if (!res.selection) router.push({ path: '/selections' });
    res.selection.venues = res.selection.venues.map(venue => {
      venue.timetables = venue.timetables.map(timetable => {
        // selectStaffsは手動で付与
        timetable.selectStaffs = cloneDeep(staffs.value).map(tmpStaff => {
          tmpStaff.selected = timetable.staffs
            .map(x => x.id)
            .includes(tmpStaff.id);
          return tmpStaff;
        });
        // selectResourcesは手動で付与
        timetable.selectResources = cloneDeep(googleResources.value).map(
          resource => {
            resource.selected = timetable.selectedCalendarResources
              .map(x => x.id)
              .includes(resource.id);
            return resource;
          },
        );
        // selectZoomHostsは手動で付与
        timetable.selectZoomHosts = cloneDeep(zoomUsers.value).map(host => {
          host.selected = timetable.selectedZoomHost === host.id;
          return host;
        });
        return timetable;
      });
      return venue;
    });
    selection.data = res.selection;
  };
  /**
   * スタッフ取得
   */
  const fetchStaffs = async () => {
    // 削除済みスタッフを除く一覧を取得
    staffs.value = await staffsService.fetchStaffs(true);
    staffs.value = staffs.value.map(tmpStaff => ({
      id: tmpStaff.id,
      name: `${tmpStaff.lastname}${tmpStaff.firstname}`,
      selected: false,
    }));
  };
  /**
   * イベント取得
   * @param { number } graduatedId 卒年ID
   */
  const fetchEvents = async graduatedId => {
    const res = await eventsService.fetchVisibleEvents(graduatedId, 1);
    events.value = res;
  };
  /**
   * Googleカレンダー連携情報を取得（選考作成画面のみ）
   * @param { Object } store ストアオブジェクト
   */
  const checkLinkGoogleCalendar = async store => {
    const res = await googleCalendarService.checkLinkGoogleCalendar();
    if (res.success !== true) {
      // Googleカレンダーが利用できない場合にもエラーが返ってくるので、その場合エラーメッセージを表示しない
      if (res.message !== 'Googleカレンダー連携は利用できません') {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
      isConnectedGoogleCalendar.value = false;
      googleResources.value = [];
    } else {
      isConnectedGoogleCalendar.value =
        res.is_connected_google_calendar === true;
      googleResources.value =
        res.is_connected_google_workspace_customer_id === true &&
        res.calendar_resources.length > 0
          ? res.calendar_resources
          : [];
    }
  };
  /**
   * Zoom連携情報を取得（選考作成画面のみ）
   * @param { Object } store ストアオブジェクト
   */
  const checkLinkZoom = async store => {
    const res = await zoomService.checkLinkZoom();
    if (res.success !== true) {
      //Zoomが利用できない場合にもエラーが返ってくるので、その場合エラーメッセージを表示しない
      if (res.message !== 'Zoom連携は利用できません') {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
      enableZoom.value = false;
      zoomUsers.value = [];
    } else {
      enableZoom.value = res.enable_zoom === true;
      zoomUsers.value = res.zoom_users ? res.zoom_users : [];
    }
  };
  /**
   * 応募者の取得
   * @param { number } page ページ番号
   * @param { Object } store ストアオブジェクト
   */
  const fetchApplicants = async (page = 1, store) => {
    const graduatedYear =
      await store.getters['graduateds/selectedGraduatedYear'];
    if (graduatedYear) {
      const result = await applicantService.fetchApplicantsSearch(
        graduatedYear.year,
        page,
        10,
        applicantSearch.body,
        {
          or_flag: false,
          // 応募者(LINE+メール) + LINE友達対象
          is_all_applicants: true,
          include_selection_id: selectionId.value,
        },
        applicantSearch.sort,
      );
      applicants.value = result.applicants;
      applicantSearch.pager.page = result.page;
      applicantSearch.pager.count = result.count;
      applicantSearch.pager.pageCount = result.pageCount;
    }
  };
  /**
   * イベントの新規作成
   * @param { string } eventName イベント名
   * @param { number } graduatedId 卒年ID
   * @return { Object } res.data
   */
  const createEvent = async (eventName, graduatedId) => {
    const res = await eventsService.createEvent(eventName, graduatedId);
    // TODO: イベント作成APIのエラーハンドリングはしなくて良いのか？
    const newEvent = { id: res.id, title: res.title, createdAt: null };
    const tmpEvents = Object.assign([], events.value);
    tmpEvents.push(newEvent);
    events.value = tmpEvents;
    selection.data.event = newEvent;
    return res;
  };
  /**
   * 選考の新規作成
   * @param { Object } store ストアオブジェクト
   * @param { Object } router ルーターオブジェクト
   */
  const cerateSelection = async (store, router) => {
    // venueとtimetable毎に選択したスタッフIDの配列を作成
    const selectedStaffIds = selection.data.venues.map(venue =>
      venue.timetables.map(timetable =>
        timetable.selectStaffs.filter(x => x.selected).map(x => x.id),
      ),
    );
    const selectedResourceIds = selection.data.venues.map(venue =>
      venue.timetables.map(timetable =>
        timetable.selectResources.filter(x => x.selected).map(x => x.id),
      ),
    );
    const selectedZoomHostIds = selection.data.venues.map(venue =>
      venue.timetables.map(timetable =>
        timetable.selectZoomHosts.find(x => x.selected)
          ? timetable.selectZoomHosts.find(x => x.selected).id
          : null,
      ),
    );
    const res = await selectionsService.createSelection({
      selection: selection.data.jsonObjCreateSelection(
        selectedStaffIds,
        selectedResourceIds,
        selectedZoomHostIds,
      ),
    });
    if (res.success !== true) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      return;
    }
    if (selection.data.venues.length > MAX_ACTIVE_VENUE_LENGTH) {
      // 選考会場の上限エラー
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: `応募者を募集できる選考会場の上限は${MAX_ACTIVE_VENUE_LENGTH}会場です。`,
        type: false,
      });
      return;
    }
    // 成功の場合
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? res.zoom_error_messages[0]
          : res.message,
      type:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? false
          : res.success,
    });
    // 選考編集画面に遷移
    router.push({ name: 'SelectionDetail', params: { id: res.selection.id } });
  };
  /**
   * 選考の削除
   * @param { Object } store ストアオブジェクト
   * @return { Object } res.data
   */
  const deleteSelection = async store => {
    const res = await selectionsService.deleteSelection(selectionId.value);
    if (res.success !== true) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      return res;
    }
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? res.zoom_error_messages[0]
          : res.message,
      type:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? false
          : res.success,
    });
    return res;
  };
  /**
   * 選考情報の更新（選考編集画面）
   * @param { Object } tmpSelection 選考オブジェクト
   * @return { Object } res.data
   */
  const updateSelectionBasic = async tmpSelection => {
    // 選考編集時
    const res = await selectionsService.updateSelection(
      selectionId.value,
      tmpSelection.eventId,
      tmpSelection.notice,
      tmpSelection.description,
      tmpSelection.reserve,
      tmpSelection.updateFlag,
      tmpSelection.reminderCheck,
    );
    return res;
  };
  /**
   * 会場の新規作成
   * @param { Object } tmpVenue 会場オブジェクト
   * @param { number } index 会場index
   * @param { Object } store ストアオブジェクト
   * @return { Object } res.data
   */
  const createVenue = async (tmpVenue, store) => {
    const res = await selectionsService.createVenue(
      selectionId.value,
      tmpVenue.jsonObjCreateVenue(
        tmpVenue.timetables.map(x =>
          x.selectStaffs.filter(y => y.selected).map(y => y.id),
        ),
        tmpVenue.timetables.map(x =>
          x.selectResources
            ? x.selectResources.filter(x => x.selected).map(x => x.id)
            : [],
        ),
        tmpVenue.timetables.map(x =>
          x.selectZoomHosts && x.selectZoomHosts.find(x => x.selected)
            ? x.selectZoomHosts.find(x => x.selected).id
            : null,
        ),
      ),
    );
    if (res.success !== true) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      return res;
    }
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? res.zoom_error_messages[0]
          : res.message,
      type:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? false
          : res.success,
    });
    return res;
  };
  /**
   * 会場の更新
   * @param { Object } tmpVenue 会場オブジェクト
   * @param { number } index 会場index
   * @param { Object } store ストアオブジェクト
   * @return { Object } res.data
   */
  const updateVenue = async (venue, isEditLimitDate, store) => {
    const tmpVenue = venue.jsonObjExceptTimetables();
    const res = await selectionsService.updateVenue(
      selectionId.value,
      tmpVenue.id,
      tmpVenue,
      isEditLimitDate,
    );
    if (res.success !== true) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      return res;
    }
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? res.zoom_error_messages[0]
          : res.message,
      type:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? false
          : res.success,
    });
    return res;
  };
  /**
   * 会場の削除
   * @param { number } venueId 会場ID
   * @param { number } index 会場index
   * @param { Object } store ストアオブジェクト
   */
  const deleteVenue = async (venueId, index, store) => {
    // 会場は1件は必要。一度作成した会場は応募者が紐づいた状態では削除不可となる。
    if (!selection.data.venues) return;
    if (!selection.data.venues[index].isDeletable()) return;
    if (!selection.data.venues[index].id) {
      // venue id が存在しない場合、まだ登録前なのでローカル削除
      document.querySelector('body').classList.remove('-noscroll');
      selection.data.venues.splice(index, 1);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '会場を削除しました',
        type: true,
      });
      return;
    }
    // venue id が存在する場合はAPIリクエストして削除
    const res = await selectionsService.deleteVenue(selectionId.value, venueId);
    if (res.success === true) {
      // 成功の場合
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.zoom_error_messages && res.zoom_error_messages.length > 0
            ? res.zoom_error_messages[0]
            : res.message,
        type:
          res.zoom_error_messages && res.zoom_error_messages.length > 0
            ? false
            : res.success,
      });
      selection.data.venues.splice(index, 1);
      return;
    }
    if (store.getters['staff/staff'].role.id === 3 && res.code === 1001) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      return;
    }
    if (res.code === 1001) {
      // selection がない場合 code: 1001 も成功
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '会場を削除しました',
        type: true,
      });
      selection.data.venues.splice(index, 1);
      return;
    }
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: res.message,
      type: res.success,
    });
  };
  /**
   * 開催時間の削除
   * @param { number } iVenue 会場index
   * @param { number } iTimetable 開催時間index
   * @param { Object } store ストアオブジェクト
   */
  const updateTimetable = async (iVenue, isGoogleMember, store) => {
    const timetables = { create: [], update: [] };
    selection.data.venues[iVenue].timetables.forEach(tmpTimetable => {
      const zoomHostId =
        enableZoom.value === true &&
        tmpTimetable.selectZoomHosts &&
        tmpTimetable.selectZoomHosts.find(x => x.selected === true)
          ? tmpTimetable.selectZoomHosts.find(x => x.selected === true).id
          : null;
      if (tmpTimetable.id === null) {
        timetables['create'].push(
          tmpTimetable.jsonObjCreateTimetable(
            tmpTimetable.selectStaffs.filter(x => x.selected).map(x => x.id),
            tmpTimetable.selectResources.filter(x => x.selected).map(x => x.id),
            zoomHostId,
          ),
        );
      } else {
        if (
          isConnectedGoogleCalendar.value === true &&
          isGoogleMember === false
        ) {
          // Googleカレンダー連携済みかつ社外スタッフの場合
          if (
            tmpTimetable.hasGoogleEventId !== true &&
            tmpTimetable.isGoogleSyncError !== true
          ) {
            // Googleカレンダー登録済み・登録エラーがない場合のみ送信対象（社内施設が含まれていても削除して上書き）
            timetables['update'].push(
              tmpTimetable.jsonObjCreateTimetable(
                tmpTimetable.selectStaffs
                  .filter(x => x.selected)
                  .map(x => x.id),
                [],
                zoomHostId,
              ),
            );
          }
        } else {
          // Google連携済みかつ社内スタッフ、Google未連携時
          timetables['update'].push(
            tmpTimetable.jsonObjCreateTimetable(
              tmpTimetable.selectStaffs.filter(x => x.selected).map(x => x.id),
              tmpTimetable.selectResources
                ? tmpTimetable.selectResources
                    .filter(x => x.selected)
                    .map(x => x.id)
                : [],
              zoomHostId,
            ),
          );
        }
      }
    });
    const res = await selectionsService.updateTimetables(
      selectionId.value,
      selection.data.venues[iVenue].id,
      timetables,
    );
    if (res.success !== true) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      return res;
    }
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? res.zoom_error_messages[0]
          : res.message,
      type:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? false
          : res.success,
    });
    return res;
  };
  /**
   * 開催時間の削除
   * @param { number } iVenue 会場index
   * @param { number } iTimetable 開催時間index
   * @param { Object } store ストアオブジェクト
   */
  const deleteTimetable = async (iVenue, iTimetable, store) => {
    const res = await selectionsService.deleteTimetable(
      selectionId.value,
      selection.data.venues[iVenue].id,
      selection.data.venues[iVenue].timetables[iTimetable].id,
    );
    if (res.success !== true) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      return;
    }
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? res.zoom_error_messages[0]
          : res.message,
      type:
        res.zoom_error_messages && res.zoom_error_messages.length > 0
          ? false
          : res.success,
    });
    const timetables = Object.assign(
      [],
      selection.data.venues[iVenue].timetables,
    );
    timetables.splice(iTimetable, 1);
    updateVenueDataByKey(iVenue, 'timetables', timetables);
  };
  /**
   * 会場の応募者募集ステータスの変更
   * @param { Object } venueData 会場データ
   * @return { Object } res.data
   */
  const updateActiveVenue = async venueData => {
    const res = await selectionsService.updateActiveVenue(
      selectionId.value,
      venueData.id,
      venueData,
    );
    return res;
  };
  /**
   * 日付のソート
   * @param { number } val ソートID
   * @param { Object } router ルーターオブジェクト
   */
  const onClickSortDate = async (val, router) => {
    sortDate.value = val;
    const res = await selectionsService.fetchSelectionSortDate(
      selectionId.value,
      sortDate.value,
    );
    if (!res) router.push({ path: '/selections' });
    res.venues = res.venues.map(venue => {
      venue.timetables = venue.timetables.map(timetable => {
        // selectStaffsは手動で付与
        timetable.selectStaffs = cloneDeep(staffs.value).map(tmpStaff => {
          tmpStaff.selected = timetable.staffs
            .map(x => x.id)
            .includes(tmpStaff.id);
          return tmpStaff;
        });
        // selectResourcesは手動で付与
        timetable.selectResources = cloneDeep(googleResources.value).map(
          resource => {
            resource.selected = timetable.selectedCalendarResources
              .map(x => x.id)
              .includes(resource.id);
            return resource;
          },
        );
        // selectZoomHostsは手動で付与
        timetable.selectZoomHosts = cloneDeep(zoomUsers.value).map(host => {
          host.selected = timetable.selectedZoomHost === host.id;
          return host;
        });
        return timetable;
      });
      return venue;
    });
    selection.data = res;
  };
  /**
   * 応募者登録
   * @param { number[] } selectedApplicants 応募者ID配列
   * @param { Object } store ストアオブジェクト
   * @param { Object } refModalSelectionsApplicant refオブジェクト
   */
  const registerApplicant = async (
    selectedApplicants,
    store,
    refModalSelectionsApplicant,
  ) => {
    if (selectedApplicants.length > 200) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '200名以上の応募者は選択できません',
        type: false,
      });
      refModalSelectionsApplicant.onRegisterCallback();
      return;
    }
    if (selectedApplicants === undefined || selectedApplicants.length === 0) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '応募者が選択されていません',
        type: false,
      });
      refModalSelectionsApplicant.onRegisterCallback();
      return;
    }
    const result = await progressesService.createProgress(
      selectionId.value,
      selectedApplicants,
      false,
    );
    refModalSelectionsApplicant.onRegisterCallback();
    if (result.success === false) {
      if (result.code !== undefined && result.code === 1900) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者を追加する対象の選考日程を確認してください',
          type: false,
        });
        return;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: result.message,
        type: result.success,
      });
      return;
    }
    if (result.error_applicants) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: 'この選考に登録できない応募者が含まれています',
        type: false,
      });
      return;
    }
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: '選択された応募者を選考に追加しました',
      type: true,
    });
    refModalSelectionsApplicant.clearSelectedApplicants();
    const res = await selectionsService.fetchSelection(
      selectionId.value,
      sortDate.value,
    );
    selection.data = res.selection;
  };
  /**
   * Googleカレンダーイベント更新エラー時のリトライ処理
   * @param { number } venueId 会場ID
   * @param { number } timetableId 開催時間ID
   */
  const updateGoogleEvent = async (venueId, timetableId) => {
    const res = await selectionsService.updateGoogleEvent(
      selection.data.id,
      venueId,
      timetableId,
    );
    return res;
  };
  /**
   * Zoom更新エラー時のリトライ処理
   * @param { number } venueId 会場ID
   * @param { number } timetableId 開催時間ID
   */
  const updateZoomMeeting = async (venueId, timetableId) => {
    const res = await selectionsService.updateZoomMeeting(
      selection.data.id,
      venueId,
      timetableId,
    );
    return res;
  };
  /**
   * Zoom登録削除処理
   * @param { number } venueId 会場ID
   * @param { number } timetableId 開催時間ID
   */
  const deleteZoomMeeting = async (venueId, timetableId) => {
    const res = await selectionsService.deleteZoomMeeting(
      selection.data.id,
      venueId,
      timetableId,
    );
    return res;
  };
  /**
   * 応募者CSVのダウンロード
   * @param {{ selectionId, venueId }} payload 選考ID or 会場ID
   */
  const downloadApplicantCsv = async ({ selectionId, venueId }) => {
    let response = {};
    let csvName = '';
    if (selectionId) {
      response = await applicantService.downloadSelectionCsv(selectionId);
      csvName = 'mochicaSelection.csv';
    } else if (venueId) {
      response = await applicantService.downloadVenueCsv(venueId);
      csvName = 'mochicaVenue.csv';
    } else {
      return;
    }
    let blob;
    const csv = [response.data];
    const type = { type: 'text/csv; charset=Shift-JIS' };
    const link = document.createElement('a');
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    if (window.navigator.msSaveOrOpenBlob) {
      // for ie
      blob = new Blob([bom, csv], type);
      window.navigator.msSaveOrOpenBlob(blob, csvName);
    } else if (window.webkitURL && window.webkitURL.createObjectURL) {
      // for chrome (and safari)
      blob = new Blob([bom, csv], type);
      link.setAttribute('download', csvName);
      link.setAttribute('href', window.webkitURL.createObjectURL(blob));
      link.click();
      window.URL.revokeObjectURL(link.href);
    } else if (window.URL && window.URL.createObjectURL) {
      // for firefox
      blob = new Blob([bom, csv], type);
      link.setAttribute('download', csvName);
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  };
  const sendAssignmentReminder = async (iVenue, iTimetable, store) => {
    const res = await selectionsService.sendAssignmentReminder(
      selectionId.value,
      selection.data.venues[iVenue].id,
      selection.data.venues[iVenue].timetables[iTimetable].id,
    );
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: res.message,
      type: res.success,
    });
  };
  /**
   * ローカル選考情報の更新（選考作成画面）
   */
  const updateSelectionBasicInRegistration = tmpSelection => {
    const targetEvent = events.value.find(
      event => event.id === tmpSelection.eventId,
    );
    if (targetEvent) selection.data.event = targetEvent;
    selection.data.notice = tmpSelection.notice;
    selection.data.description = tmpSelection.description;
    selection.data.reserve = tmpSelection.reserve;
    selection.reminder_check = tmpSelection.reminderCheck;
  };
  /**
   * 選考初期化（選考作成画面）
   */
  const resetSelectionLocal = () => {
    selection.data = new SelectionModel();
    selection.data.addVenue({ isCreateGoogleCalendar: true });
    // selectStaffsはmodelで作成されないので手動で付与
    selection.data.venues[0].timetables[0].selectStaffs = cloneDeep(
      staffs.value,
    );
    // selectResourcesはmodelで作成されないので手動で付与
    selection.data.venues[0].timetables[0].selectResources = cloneDeep(
      googleResources.value,
    );
    // selectZoomHostsはmodelで作成されないので手動で付与
    selection.data.venues[0].timetables[0].selectZoomHosts = cloneDeep(
      zoomUsers.value,
    );
  };
  /**
   * 任意のselectionのイベントIDの上書き
   * @param { any } val 値
   */
  const updateSelectionEvent = val => {
    selection.data.event.id = val.id;
    selection.data.event.title = val.name;
  };
  /**
   * 任意のselection.venues[]のデータの全差し替え
   * @param { number } iVenue venueのindex
   * @param { any } val 値
   */
  const updateVenueData = (iVenue, val) => {
    selection.data.venues[iVenue] = val;
  };
  /**
   * selection.venuesに新規開催時間を追加
   * @param { Object } val 開催時間オブジェクト
   */
  const addVenueData = val => {
    selection.data.venues.push(val);
  };
  /**
   * 任意のselection.venues[]の任意の値のみを更新
   * @param { number } iVenue venueのindex
   * @param { string } key キー
   * @param { any } val 値
   */
  const updateVenueDataByKey = (iVenue, key, val) => {
    selection.data.venues[iVenue][key] = val;
  };
  /**
   * 任意のselection.venues[].timetablesにtimetableを追加
   * @param { number } iVenue venueのindex
   * @param { any } val 値
   */
  const addTimetableInVenueData = (iVenue, val) => {
    selection.data.venues[iVenue].timetables.push(val);
  };
  /**
   * 任意のselection.venues[].timetables内の任意のtimetableの任意の値を更新
   * @param { number } iVenue venueのindex
   * @param { number } iTimetable timetableのindex
   * @param { string } key キー
   * @param { any } val 値
   */
  const updateTimetableDataByIndexInVenueData = (
    iVenue,
    iTimetable,
    key,
    val,
  ) => {
    selection.data.venues[iVenue].timetables[iTimetable][key] = val;
  };
  /**
   * 担当者のセット
   * @param { number } id スタッフID
   */
  const selectStaff = id => {
    staffs.value = staffs.value.map(tmpStaff => {
      if (tmpStaff.id === id) tmpStaff.selected = !tmpStaff.selected;
      return tmpStaff;
    });
  };
  /**
   * 担当者のリセット
   */
  const resetStaffsSelected = () => {
    staffs.value = staffs.value.map(tmpStaff => {
      tmpStaff.selected = false;
      return tmpStaff;
    });
  };
  /**
   * 応募者のソート変更
   * @param { Object } sort ソートオブジェクト
   * @param { Object } store ストアオブジェクト
   */
  const onApplicantsChangeSort = (payload, store) => {
    if (payload === undefined) return;
    applicantSearch.sort = payload;
    fetchApplicants(1, store);
  };

  return {
    MAX_VENUE_LENGTH,
    MAX_ACTIVE_VENUE_LENGTH,
    MAX_TIMETABLE_LENGTH,
    selection,
    staffs,
    events,
    applicants,
    applicantSearch,
    isConnectedGoogleCalendar,
    googleResources,
    enableZoom,
    zoomUsers,
    sortDate,
    selectionVenues,
    isLocalStateLoaded,
    isRegistrationSelection,
    isEditSelection,
    checkLinkGoogleCalendar,
    checkLinkZoom,
    fetchSelectionDetail,
    fetchStaffs,
    fetchEvents,
    fetchApplicants,
    createEvent,
    cerateSelection,
    deleteSelection,
    updateSelectionBasic,
    createVenue,
    updateVenue,
    deleteVenue,
    updateTimetable,
    deleteTimetable,
    updateActiveVenue,
    onClickSortDate,
    registerApplicant,
    updateGoogleEvent,
    updateZoomMeeting,
    deleteZoomMeeting,
    downloadApplicantCsv,
    sendAssignmentReminder,
    updateSelectionBasicInRegistration,
    resetSelectionLocal,
    updateSelectionEvent,
    updateVenueData,
    addVenueData,
    updateVenueDataByKey,
    addTimetableInVenueData,
    updateTimetableDataByIndexInVenueData,
    selectStaff,
    resetStaffsSelected,
    onApplicantsChangeSort,
  };
}
