<template>
  <div class="content">
    <div class="title">登録種別応募者数</div>
    <ul v-if="aggregate.length > 0">
      <li v-for="kind in aggregate" :key="kind.graduated">
        <span class="kind">{{ getGraduatedName(kind.graduated) }}</span>
        <span class="count">{{ kind.count }}名</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount } from 'vue';
import applicantService from '@/services/applicants';
import GraduatedDefines from '@/defines/graduated';

export default defineComponent({
  name: 'DashboardKind',
  setup(props, context) {
    const aggregate = ref([]);
    const fetchAll = async () => {
      const res = await applicantService.getAggregateApplicant();
      aggregate.value = res.aggregate;
    };
    const getGraduatedName = year => {
      const res = GraduatedDefines.find(v => v.year === year);
      if (res !== undefined) return res.name_jp;
      return `${year}年卒業`;
    };

    onBeforeMount(async () => await fetchAll());

    return { aggregate, getGraduatedName };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.content {
  padding: 20px;
  min-height: 600px;
  background-color: #fff;
  .title {
    margin-bottom: 20px;
    padding: 15px 20px;
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  ul {
    padding: 20px;
    li {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e3e3e3;
      .kind {
        padding-right: 20px;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: ($media_query_tablet)) {
}

@media (max-width: ($media_query_sp)) {
}
</style>
