<template>
  <div class="login-form-wrapper">
    <div class="login_block">
      <div>
        <img
          class="login-form__logo-title"
          width="300"
          viewBox="0 0 394.2 41"
          src="@/assets/img/mochica_login.svg"
          alt
        />
      </div>
      <div v-if="isLoading">
        <local-page-loader class="loader" />
      </div>
      <div v-else>
        <login-form
          v-if="mode === 'loginLogin'"
          @pathChange="$router.replace('/policy?privacy_policy=1')"
          @changeMode="changeMode"
          @submitLogin="login"
        />
        <to-issue v-if="mode === 'loginToIssue'" @changeMode="changeMode" />
      </div>
    </div>
    <div class="content_block" :style="styles">
      <div class="info_block">
        <div v-if="info.data.title" class="title">{{ info.data.title }}</div>
        <div v-if="info.data.sub_title" class="sub_title">
          {{ info.data.sub_title }}
        </div>
        <div v-if="info.data.content" class="content">
          {{ info.data.content }}
        </div>
        <button v-if="info.data.link" class="btn info_btn" @click="openLink">
          {{ info.data.btn_title }}
        </button>
      </div>
    </div>
    <notification />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import Notification from '@/components/ui/notification/components/Notification';
import LoginForm from '@/components/features/loginForm/components/LoginForm';
import ToIssue from '@/components/page/login/components/ToIssue';
import loginService from '@/services/login';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';

export default defineComponent({
  name: 'Login',
  components: {
    Notification,
    LoginForm,
    ToIssue,
    LocalPageLoader,
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const mode = ref('loginLogin'); // loginLogin, loginToIssue
    const info = reactive({ data: {} });
    const styles = ref(null);

    // methods
    const getLoginContents = async () => {
      const res = await loginService.getLoginContents();
      info.data = res.info;
    };
    const openLink = () => {
      window.open(info.data.link, '_blank');
    };
    const login = async payload => {
      isLoading.value = true;
      const res = await store.dispatch('auth/LOGIN', {
        email: payload.email,
        password: payload.password,
        company_id: payload.company_id,
      });
      if (res.success === false) {
        isLoading.value = false;
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `${res.message}`,
          type: res.success,
        });
      }
    };
    const changeMode = key => {
      mode.value = key;
    };
    const setBackgroundImg = () => {
      let style = {};
      if (!info.data.img_url) {
        style = Object.assign({}, style, { background: '#1899d6' });
      } else {
        style = Object.assign({}, style, {
          background: `url("${info.data.img_url}") center no-repeat`,
        });
        style = Object.assign({}, style, { backgroundSize: 'cover' });
      }
      styles.value = style;
    };

    // lifecycle
    onBeforeMount(async () => {
      await getLoginContents();
      setBackgroundImg();
    });

    return {
      isLoading,
      mode,
      info,
      styles,
      getLoginContents,
      openLink,
      login,
      changeMode,
      setBackgroundImg,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.loader {
  min-width: 300px;
  min-height: 375px;
}

.support-message {
  line-height: 18px;
  width: 40rem;
  letter-spacing: 1px;
  background: #eee;
  border: 1px solid #808080;
  padding: 2rem;
  margin: 0px 16px 20px;
}
.br-block {
  display: block;
  text-align: left;
}

.title-block {
  text-align: center;
}
.upper-title {
  text-align: left;
  margin-bottom: 1rem;
}
.under-contents {
  text-align: left;
  .contact_line {
    border-bottom: solid 1px;
    padding: 1rem 0 0 0;
    margin-bottom: 1rem;
  }
  .contact_text {
    margin-bottom: 0.5rem;
  }
}
.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.login-form-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
  .login_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 100vh;
    background-color: #fff;
    .logo-title {
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;
      fill: #88c6ed;
    }
  }
  .content_block {
    width: 65%;
    height: 100vh;
    padding: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .info_block {
      text-align: center;
      color: #fff;
      .title {
        font-weight: bold;
        font-size: 2.8rem;
        line-height: 3.8rem;
        white-space: break-spaces;
        margin-bottom: 20px;
      }
      .sub_title {
        font-size: 2.2rem;
        line-height: 3.2rem;
        white-space: break-spaces;
        margin-bottom: 40px;
      }
      .content {
        font-size: 1.4rem;
        line-height: 2.4rem;
        white-space: break-spaces;
        margin-bottom: 40px;
      }
      button {
        background-color: #fff;
        color: #1999d6 !important;
        transition: all ease 0.6s;
        cursor: pointer;
        &:hover {
          opacity: 0.4;
        }
      }
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .login-form-wrapper {
    height: auto;
    // padding: 0 math_size(10)
    background-color: #fff;
    .login_block {
      width: 100vw;
      height: 100%;
      padding-top: 40px;
    }
    .content_block {
      width: 100vw;
      height: auto;
      padding: 80px 20px;
      .title {
        white-space: pre-wrap !important;
      }
      .sub_title {
        white-space: pre-wrap !important;
      }
      .content {
        white-space: pre-wrap !important;
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .login-form-wrapper {
    height: auto;
    // padding: 0 math_size(10);
    background-color: #fff;
    .login_block {
      width: 100vw;
      height: 100%;
      padding-top: 40px;
    }
    .content_block {
      width: 100vw;
      height: auto;
      padding: 80px 20px;
      .title {
        white-space: pre-wrap !important;
      }
      .sub_title {
        white-space: pre-wrap !important;
      }
      .content {
        white-space: pre-wrap !important;
      }
    }
  }
}
</style>
