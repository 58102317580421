import httpClient from '@/http-client';
import { isOwner, isManager, isOperator } from '@/defines/staff';

const RoleManager = function RoleManager() {
  const manager = Object.create(RoleManager.prototype);
  return manager;
};

RoleManager.prototype = {
  async fetchRoleTypes() {
    const response = await httpClient.get('/roles');
    return response.data.roles.map(staffRole => ({
      ...staffRole,
      isOwner: () => isOwner(staffRole.name),
      isManager: () => isManager(staffRole.name),
      isOperator: () => isOperator(staffRole.name),
    }));
  },
};

export default RoleManager();
