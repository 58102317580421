<template>
  <transition name="tr-notification">
    <div
      v-if="$store.getters['notification/isNotificationVisible']"
      class="global-notification"
      :class="{ success: $store.getters['notification/notificationType'] }"
    >
      <div class="contents">
        <p class="message">
          {{ $store.getters['notification/notificationMessage'] }}
        </p>
        <div
          class="close"
          @click="$store.dispatch('notification/INVISIBLE_NOTIFICATION')"
        >
          <img class="icon" src="@/assets/img/close_white.svg" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Notification',
  setup(props, context) {},
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.global-notification {
  @include flex_center($justify: center);
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  height: 4.2rem;
  background-color: #bb1b1c;
  color: white;
  transition: all 0.3s ease;
  font-weight: bold;
  border-radius: 0.4rem 0.4rem 0 0;
  font-size: 1.2rem;
  letter-spacing: 0.05em;

  .contents {
    width: 100%;
    position: relative;

    .message {
      text-align: center;
      line-height: 6rem;
      font-size: 1.4rem;
      letter-spacing: 0.05em;
    }
    .close {
      display: block;
      width: 2rem;
      height: 2rem;
      position: absolute;
      right: 2rem;
      top: 0;
      bottom: 0;
      right: 2rem;
      margin: auto;
      cursor: pointer;
      display: block;
    }
  }
  &.success {
    background-color: #8fb60b;
  }
}

.tr-notification-enter-from {
  opacity: 0;
}
.tr-notification-leave-active {
  opacity: 0;
}
.tr-notification-enter-from .global-notification,
.tr-notification-leave-active .global-notification {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: ($media_query_tablet)) {
  .global-notification .contents .message {
    line-height: 1.2;
    font-size: 1.1rem;
    padding: 0 45px 0 5px;
  }
}
</style>
