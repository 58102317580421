<template>
  <div class="ar-detailFilters">
    <div v-if="searchDetailFilters.length > 0">
      <table class="common_table">
        <thead class="common_thead">
          <tr class="common_thead_row align-left">
            <th class="common_thead_item"><span>項目名</span></th>
            <th class="common_thead_item"><span>条件</span></th>
            <th class="common_thead_item"><span>検索内容</span></th>
            <th class="common_thead_item"><span></span></th>
          </tr>
        </thead>
        <tbody
          v-for="(searchDetailFilter, index) in searchDetailFilters"
          :key="index"
          class="common_tbody"
        >
          <tr>
            <td class="common_tbody_item" aria-label="項目名">
              <dl class="item ar-inputElement">
                <dd>
                  <drop-down
                    :placeholder="'項目名を選択してください'"
                    :selected-id="searchDetailFilter.id"
                    :list="getTitleLists"
                    :is-view="
                      visibleTitleIds[index] &&
                      getTitleLists.findIndex(tg => !tg.disabled) !== -1
                    "
                    @onOpen="openDropDownTitleId(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="onSelectedListId($event, index)"
                  />
                </dd>
              </dl>
            </td>
            <td class="common_tbody_item" aria-label="条件">
              <dl class="item ar-inputElement">
                <drop-down
                  :placeholder="'条件'"
                  :selected-id="searchDetailFilter.selectOption"
                  :list="
                    getSelectOptions(searchDetailFilter.id).map(option =>
                      Object.assign({}, option, {
                        selected:
                          searchDetailFilter &&
                          option.id === searchDetailFilter.selectOption,
                      }),
                    )
                  "
                  :is-view="visibleSorts[index]"
                  @onOpen="openDropDownSorts(index)"
                  @onClose="closeAllDropDown"
                  @onSelected="onSelectedOption($event, index)"
                />
              </dl>
            </td>

            <td class="common_tbody_item" aria-label="検索内容">
              <dl
                v-if="getTitleLists[0].disabled && searchDetailFilter.id === 0"
                class="item ar-inputElement"
              >
                <dd class="selection_name_wrapper">
                  <drop-down
                    placeholder="選考名"
                    :selected-id="searchDetailFilter.data"
                    :list="getEventIds"
                    :is-view="searchDetailFilter.isView"
                    :disabled="getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="onSelectedEventId(index, $event)"
                  />
                </dd>
              </dl>
              <dl
                v-if="getTitleLists[1].disabled && searchDetailFilter.id === 1"
                class="item ar-inputElement"
              >
                <dt class="status_wrap">
                  <div
                    class="status_wrapper"
                    :class="{
                      'is-empty': searchDetailFilter.data.length === 0,
                      'is-disabled': getDisabledBySelectOption(index),
                    }"
                    @click="
                      !getDisabledBySelectOption(index)
                        ? openDropDownVisible(index)
                        : () => {}
                    "
                  >
                    {{
                      getProgressStatusIdLabels === ''
                        ? 'ステータス'
                        : getProgressStatusIdLabels
                    }}
                  </div>
                  <float-context-menu
                    class="status_multiselector"
                    :is-view="searchDetailFilter.isView"
                    :list="getProgressStatuses"
                    :is-multiple-select="true"
                    @onClose="closeAllDropDown"
                    @onSelected="onSelectedProgressStatus(index, $event)"
                  />
                </dt>
              </dl>
              <dl
                v-if="getTitleLists[2].disabled && searchDetailFilter.id === 2"
                class="item ar-inputElement"
              >
                <dd>
                  <drop-down
                    placeholder="登録経路"
                    :selected-id="searchDetailFilter.data"
                    :list="getChannels"
                    :is-view="searchDetailFilter.isView"
                    :disabled="getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="
                      onSelectedFilterItem(
                        searchDetailFilter.keyName,
                        index,
                        $event,
                      )
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="getTitleLists[3].disabled && searchDetailFilter.id === 3"
                class="item ar-inputElement"
              >
                <dd>
                  <drop-down
                    placeholder="担当者"
                    :selected-id="searchDetailFilter.data"
                    :list="getStaffs"
                    :is-view="searchDetailFilter.isView"
                    :disabled="getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="
                      onSelectedFilterItem(
                        searchDetailFilter.keyName,
                        index,
                        $event,
                      )
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="getTitleLists[4].disabled && searchDetailFilter.id === 4"
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="名前"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="getTitleLists[5].disabled && searchDetailFilter.id === 5"
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="フリガナ"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="getTitleLists[6].disabled && searchDetailFilter.id === 6"
                class="item ar-inputElement"
              >
                <dd class="category_dd">
                  <drop-down
                    :placeholder="'性別'"
                    :selected-id="searchDetailFilter.data"
                    :list="getGenderIds"
                    :is-view="searchDetailFilter.isView"
                    :disabled="getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="
                      onSelectedFilterItem(
                        searchDetailFilter.keyName,
                        index,
                        $event,
                      )
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="getTitleLists[7].disabled && searchDetailFilter.id === 7"
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="メール"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="getTitleLists[8].disabled && searchDetailFilter.id === 8"
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="電話番号"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="getTitleLists[9].disabled && searchDetailFilter.id === 9"
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="住所"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[10].disabled && searchDetailFilter.id === 10
                "
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="学校名"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[11].disabled && searchDetailFilter.id === 11
                "
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="学部"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[12].disabled && searchDetailFilter.id === 12
                "
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="学科"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[13].disabled && searchDetailFilter.id === 13
                "
                class="item ar-inputElement"
              >
                <dd class="category_dd">
                  <drop-down
                    :placeholder="'文理'"
                    :selected-id="searchDetailFilter.data"
                    :list="getDepartmentCategories"
                    :is-view="searchDetailFilter.isView"
                    :disabled="getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="
                      onSelectedFilterItem(
                        searchDetailFilter.keyName,
                        index,
                        $event,
                      )
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[14].disabled && searchDetailFilter.id === 14
                "
                class="item ar-inputElement"
              >
                <dd>
                  <el-date-picker
                    id="el-input__inner"
                    type="date"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                    placeholder="登録日"
                    name="createdAt"
                    :model-value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @update:model-value="
                      updateSearchDetailFilterData(index, { data: $event })
                    "
                    @focus="
                      updateSearchDetailFilterData(index, { isView: true })
                    "
                    @blur="
                      updateSearchDetailFilterData(index, { isView: false })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[15].disabled && searchDetailFilter.id === 15
                "
                class="item ar-inputElement"
              >
                <dd>
                  <el-date-picker
                    id="el-input__inner--entry"
                    type="date"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                    placeholder="LINEエントリー日"
                    name="activatedAt"
                    :model-value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @update:model-value="
                      updateSearchDetailFilterData(index, { data: $event })
                    "
                    @focus="
                      updateSearchDetailFilterData(index, { isView: true })
                    "
                    @blur="
                      updateSearchDetailFilterData(index, { isView: false })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[16].disabled && searchDetailFilter.id === 16
                "
                class="item ar-inputElement"
              >
                <dd class="category_dd">
                  <drop-down
                    :placeholder="'ブロック状況'"
                    :selected-id="searchDetailFilter.data"
                    :list="getLineBlock"
                    :is-view="searchDetailFilter.isView"
                    :disabled="getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="
                      onSelectedFilterItem(
                        searchDetailFilter.keyName,
                        index,
                        $event,
                      )
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[17].disabled && searchDetailFilter.id === 17
                "
                class="item ar-inputElement"
              >
                <dd>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="応募者ID"
                    :value="searchDetailFilter.data"
                    :disabled="getDisabledBySelectOption(index)"
                    @input="
                      updateSearchDetailFilterData(index, {
                        data: $event.target.value,
                      })
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[18].disabled && searchDetailFilter.id === 18
                "
                class="item ar-inputElement"
              >
                <dt>
                  <span v-if="!hasEventId">（先に選考名をお選び下さい）</span>
                </dt>
                <dd>
                  <drop-down
                    :placeholder="'参加日'"
                    :selected-id="attendanceId"
                    :list="hasEventId ? getAttendances : []"
                    :is-view="searchDetailFilter.isView"
                    :disabled="!hasEventId || getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="onSelectedAttendanceId(index, $event)"
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[19].disabled && searchDetailFilter.id === 19
                "
                class="item ar-inputElement"
              >
                <dt>
                  <span v-if="!hasEventId">（先に選考名をお選び下さい）</span>
                </dt>
                <dd>
                  <drop-down
                    placeholder="参加確認"
                    :selected-id="searchDetailFilter.data"
                    :list="hasEventId ? getReminderChecks : []"
                    :is-view="searchDetailFilter.isView"
                    :disabled="!hasEventId || getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="
                      onSelectedFilterItem(
                        searchDetailFilter.keyName,
                        index,
                        $event,
                      )
                    "
                  />
                </dd>
              </dl>
              <dl
                v-if="
                  getTitleLists[20].disabled && searchDetailFilter.id === 20
                "
                class="item ar-inputElement"
              >
                <dd>
                  <drop-down
                    placeholder="連絡方法"
                    :selected-id="searchDetailFilter.data"
                    :list="getContactTypeIds"
                    :is-view="searchDetailFilter.isView"
                    :disabled="getDisabledBySelectOption(index)"
                    @onOpen="openDropDownVisible(index)"
                    @onClose="closeAllDropDown"
                    @onSelected="
                      onSelectedFilterItem(
                        searchDetailFilter.keyName,
                        index,
                        $event,
                      )
                    "
                  />
                </dd>
              </dl>
            </td>
            <td align="center" class="common_tbody_btn">
              <button-base
                button-text="削除"
                button-type="secondary"
                icon-file-name="delete_white"
                @click="deleteTitleListRow(index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="canAddFilter"
      class="btn btn-availability is-active"
      @click="addTitleListRow"
    >
      <button>
        <img slot="icon" class="icon" src="@/assets/img/add_white.svg" alt />
        検索条件を追加
      </button>
    </div>
    <div v-else>
      <span class="btn-add-title_description">
        全ての項目名が選択されています。
      </span>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import ProgressStatuses from '@/components/features/searchApplicants/defines/progress-statuses';
import {
  channels,
  genders,
  departmentCategories,
  lineBlocks,
  contactTypes,
} from '@/defines/applicant';
import eventsService from '@/services/events';
import DropDown from '@/components/ui/menus/components/DropDown';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';

export default defineComponent({
  name: 'SearchApplicantsDetailFilters',
  components: { DropDown, FloatContextMenu },
  props: {
    searchDetailFilters: {
      type: Array,
      default: () => [],
    },
    selectableSearchDetailFilters: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    attendanceId: {
      type: Number,
      default: null,
    },
    updateProgressStatusIds: {
      type: Function,
      default: () => {},
    },
    updateAttendanceId: {
      type: Function,
      default: () => {},
    },
    addSearchDetailFilter: {
      type: Function,
      default: () => {},
    },
    removeSearchDetailFilter: {
      type: Function,
      default: () => {},
    },
    updateSearchDetailFilterData: {
      type: Function,
      default: () => {},
    },
    updateSearchDetailFilterOption: {
      type: Function,
      default: () => {},
    },
    getDisabledBySelectOption: {
      type: Function,
      default: () => {},
    },
    displayActivatedAt: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const selectedGraduatedId = store.getters['graduateds/selectedGraduatedId'];
    const progressStatuses = ProgressStatuses.map(x => ({ ...x }));
    const attendances = ref([]);
    const visibleTitleIds = ref([]);
    const visibleSorts = ref([]);

    // computed
    const getSelectOptions = computed(() => id => {
      const exceptionSearchIds = [1, 2, 3, 4, 14, 20];
      if (exceptionSearchIds.includes(id) === true) {
        return [
          { id: 1, name: '含む' },
          { id: 0, name: '含まない' },
        ];
      } else {
        return [
          { id: 1, name: '含む' },
          { id: 0, name: '含まない' },
          { id: 3, name: '存在する' },
          { id: 2, name: '存在しない' },
        ];
      }
    });
    const getGenderIds = computed(() => {
      return genders.map(gid => {
        const target = props.searchDetailFilters.find(
          x => x.keyName === 'genderId',
        );
        const category = genders.find(item => item.id === gid.id);
        return {
          id: gid.id,
          name: category.description,
          selected: target && gid.id === target.data,
        };
      });
    });
    const getLineBlock = computed(() => {
      return lineBlocks.map(blockObj => {
        const target = props.searchDetailFilters.find(
          x => x.keyName === 'lineBlock',
        );
        const category = lineBlocks.find(item => item.id === blockObj.id);
        return {
          id: blockObj.id,
          name: category.description,
          selected: target && blockObj.id === target.data,
        };
      });
    });
    const getDepartmentCategories = computed(() => {
      const target = props.searchDetailFilters.find(
        x => x.keyName === 'departmentCategoryId',
      );
      return departmentCategories.map(dc => {
        const category = departmentCategories.find(item => item.id === dc.id);
        return {
          id: dc.id,
          name: category.description,
          selected: target && dc.id === target.data,
        };
      });
    });
    const getChannels = computed(() => {
      return Object.keys(channels).map(key => {
        const target = props.searchDetailFilters.find(
          x => x.keyName === 'channelId',
        );
        const intKey = parseInt(key, 10);
        const list = {
          id: intKey,
          name: channels[intKey],
          selected: target && intKey === target.data,
        };
        return list;
      });
    });
    const getProgressStatuses = computed(() => {
      return progressStatuses.map(status => {
        let selected = false;
        const progressStatusIdsFilter = props.searchDetailFilters.find(
          x => x.keyName === 'progressStatusIds',
        );
        if (progressStatusIdsFilter) {
          selected = progressStatusIdsFilter.data.includes(status.id);
        }
        const list = Object.assign({}, status, { selected });
        return list;
      });
    });
    const getEventIds = computed(() => {
      return props.events.map(event => {
        const target = props.searchDetailFilters.find(
          x => x.keyName === 'eventId',
        );
        const list = Object.assign({}, event, {
          name: event.title,
          selected: target && event.id === target.data,
        });
        return list;
      });
    });
    const getStaffs = computed(() => {
      return props.staffs.map(staff => {
        const target = props.searchDetailFilters.find(
          x => x.keyName === 'staffId',
        );
        const list = Object.assign({}, staff, {
          name: `${staff.lastname}${staff.firstname}`,
          selected: target && staff.id === target.data,
        });
        return list;
      });
    });
    const getAttendances = computed(() => {
      if (!attendances.value) return [];
      return attendances.value.map((date, index) => {
        const target = props.searchDetailFilters.find(
          x => x.keyName === 'attendanceDate',
        );
        return {
          name: date,
          title: date,
          id: index,
          selected: target && index === attendances.value.indexOf(target.data),
        };
      });
    });
    const getReminderChecks = computed(() => {
      // 参加日が存在しない場合は選択肢は0
      const target = props.searchDetailFilters.find(
        x => x.keyName === 'eventId',
      );
      if (!target || !target.data || getAttendances.value.length === 0)
        return [];
      const targetReminderCheck = props.searchDetailFilters.find(
        x => x.keyName === 'reminderCheck',
      );
      return [
        {
          id: 1,
          name: 'あり',
          selected: targetReminderCheck && targetReminderCheck.data === 1,
        },
        {
          id: 0,
          name: 'なし',
          selected: targetReminderCheck && targetReminderCheck.data === 0,
        },
      ];
    });
    const getTitleLists = computed(() => {
      const resTitleLists = props.selectableSearchDetailFilters.map(filter => {
        const newFilter = Object.assign({}, filter, {
          id: filter.id,
          title: filter.title,
        });
        if (
          (filter.keyName === 'attendanceDate' ||
            filter.keyName === 'reminderCheck') &&
          props.searchDetailFilters.filter(x => x.id === 0).length === 0
        ) {
          // attendanceDate. reminderCheckは選考名(id=0)選択前はdisabled
          newFilter.disabled = true;
        }
        if (store.getters['staff/staff'].admin !== 2 && filter.id === 17) {
          newFilter.disabled = true;
        } else {
          // 選択済みの項目はdisabled
          newFilter.disabled =
            props.searchDetailFilters.filter(x => x.id === filter.id).length >
            0;
        }
        return newFilter;
      });
      return resTitleLists;
    });
    const getProgressStatusIdLabels = computed(() => {
      const progressStatusIdsFilter = props.searchDetailFilters.find(
        x => x.keyName === 'progressStatusIds',
      );
      if (
        progressStatusIdsFilter === undefined ||
        !progressStatusIdsFilter.data
      ) {
        return '';
      }
      return progressStatusIdsFilter.data
        .map(progressStatusId => {
          return progressStatuses.find(x => x.id === progressStatusId).name;
        })
        .join(', ');
    });
    const getContactTypeIds = computed(() => {
      return Object.keys(contactTypes).map(key => {
        const target = props.searchDetailFilters.find(
          x => x.keyName === 'contactTypeId',
        );
        const intKey = parseInt(key, 10);
        const list = {
          id: intKey,
          name: contactTypes[intKey],
          selected: target && intKey === target.data,
        };
        return list;
      });
    });
    const canAddFilter = computed(
      () => getTitleLists.value.filter(x => !x.disabled).length > 0,
    );
    const hasEventId = computed(() => {
      const eventIdFilter = props.searchDetailFilters.find(
        x => x.keyName === 'eventId',
      );
      // 選考名が選択されている場合true
      return eventIdFilter && eventIdFilter.data;
    });

    // methods
    const openDropDownTitleId = key => {
      closeAllDropDown();
      visibleTitleIds.value[key] = true;
    };
    const openDropDownSorts = key => {
      closeAllDropDown();
      visibleSorts.value[key] = true;
    };
    const openDropDownVisible = i => {
      closeAllDropDown();
      props.updateSearchDetailFilterData(i, { isView: true });
    };
    const closeAllDropDown = () => {
      props.searchDetailFilters.forEach((x, i) => {
        props.updateSearchDetailFilterData(i, { isView: false });
      });
      visibleTitleIds.value = visibleTitleIds.value.map(x => false);
      visibleSorts.value = visibleSorts.value.map(x => false);
    };
    const addTitleListRow = () => {
      if (
        canAddFilter.value &&
        props.searchDetailFilters.filter(tg => tg.id === null).length === 0
      ) {
        props.addSearchDetailFilter();
        visibleTitleIds.value.push(false);
      }
    };
    const deleteTitleListRow = index => {
      const target = props.searchDetailFilters[index];
      props.removeSearchDetailFilter(index, target.keyName);
      visibleTitleIds.value.splice(index, 1);
    };
    const onSelectedListId = (payload, i) => {
      // dataが空の詳細検索フィルター追加
      props.updateSearchDetailFilterData(i, {
        id: payload.item.id,
        disabled: payload.item.disabled,
        keyName: payload.item.keyName,
        name: payload.item.name,
        data: payload.item.keyName === 'progressStatusIds' ? [] : null,
      });
      // selectOptionをリセット
      const targetFilterIndex = props.searchDetailFilters.findIndex(
        x => x.keyName === payload.item.keyName,
      );
      if (targetFilterIndex !== -1) {
        props.updateSearchDetailFilterOption(1, targetFilterIndex);
      }
      // ステータスが存在しない場合、ステータスのvalueをリセット
      if (
        !props.searchDetailFilters.find(
          searchDetailFilter =>
            searchDetailFilter.keyName !== 'progressStatusIds',
        )
      ) {
        props.updateProgressStatusIds(i, null);
      }
      // nextTickが効かない
      setTimeout(() => closeAllDropDown(), 100);
    };
    const onSelectedOption = (payload, i) => {
      props.updateSearchDetailFilterOption(payload.item.id, i);
    };
    const onSelectedFilterItem = (key, i, payload) => {
      props.updateSearchDetailFilterData(i, { data: payload.item.id });
      // nextTickが効かない
      setTimeout(() => closeAllDropDown(), 100);
    };
    const onSelectedProgressStatus = (i, payload) => {
      props.updateProgressStatusIds(i, payload.item.id);
      // nextTickが効かない
      setTimeout(() => {
        closeAllDropDown();
        // 複数選択なので閉じさせない
        props.updateSearchDetailFilterData(i, { isView: true });
      }, 100);
    };
    const onSelectedEventId = async (i, payload) => {
      props.searchDetailFilters.forEach((filter, index) => {
        if (
          filter.keyName === 'attendanceId' ||
          filter.keyName === 'attendanceDate' ||
          filter.keyName === 'reminderCheck'
        ) {
          props.updateSearchDetailFilterData(index, { data: null });
        }
        return filter;
      });
      props.updateSearchDetailFilterData(i, { data: payload.item.id });
      await setAttendances();
      // nextTickが効かない
      setTimeout(() => closeAllDropDown(), 100);
    };
    const onSelectedAttendanceId = (i, payload) => {
      props.updateSearchDetailFilterData(i, { data: payload.item.name });
      props.updateAttendanceId(payload.item.id);
      // nextTickが効かない
      setTimeout(() => closeAllDropDown(), 100);
    };
    const setAttendances = async (targetEventId = null) => {
      let eventId = targetEventId;
      if (!eventId) {
        const target = props.searchDetailFilters.find(
          x => x.keyName === 'eventId',
        );
        eventId = target ? target.data : null;
      }
      if (eventId) {
        const res = await eventsService.fetchAttendance({
          event_id: eventId,
          graduated_id: selectedGraduatedId,
        });
        attendances.value = res.event_dates;
      } else {
        attendances.value = [];
      }
      return attendances.value;
    };

    // life cycles
    onMounted(async () => {
      await setAttendances();
    });

    return {
      visibleTitleIds,
      visibleSorts,
      getSelectOptions,
      getGenderIds,
      getLineBlock,
      getDepartmentCategories,
      getChannels,
      getProgressStatuses,
      getEventIds,
      getStaffs,
      getAttendances,
      getReminderChecks,
      getTitleLists,
      getProgressStatusIdLabels,
      getContactTypeIds,
      canAddFilter,
      hasEventId,
      openDropDownTitleId,
      openDropDownSorts,
      openDropDownVisible,
      closeAllDropDown,
      addTitleListRow,
      deleteTitleListRow,
      onSelectedListId,
      onSelectedOption,
      onSelectedFilterItem,
      onSelectedProgressStatus,
      onSelectedEventId,
      onSelectedAttendanceId,
      setAttendances,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
@import '@/assets/datepicker.scss';
@include common_table_style(
  $col_1: 35px,
  $col_1_ta: left,
  $col_2: 15px,
  $col_2_ta: left,
  $col_3: 40px,
  $col_3_ta: left,
  $col_4: 8px,
  $col_4_ta: left
);

.ar-detailFilters {
  border-top: 1px solid #ccc;
  padding: 20px 0px 0px 0px;
}
.common_table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0px;
  .common_thead {
    .common_thead_row {
      .common_thead_item {
        padding: 12px 12px 12px 0px;
      }
      :first-of-type {
        padding-left: 10px;
        border-radius: 4px 0 0 4px;
      }
      :last-of-type {
        border-radius: 0 4px 4px 0;
        padding-right: 0px;
        padding-left: 0px;
        width: 12px;
        min-width: 12px;
      }
    }
  }
  .common_tbody {
    td {
      padding: 2rem 1rem 0 0;

      &.common_tbody_item {
        padding: 2rem 1rem 0 0;
      }

      &.common_tbody_btn {
        padding-right: 0;
        text-align: right;
      }
    }

    @media (max-width: ($media_query_sp)) {
      td {
        padding: 1rem 0;

        &.common_tbody_item {
          padding: 1rem 0;
        }

        &.common_tbody_btn {
          padding-right: 0;
          text-align: left;
        }
      }
    }
  }
  margin-bottom: 2rem;
}

.ar-inputElement {
  position: relative;
  dt {
    position: absolute;
    top: -17px;

    @media (max-width: ($media_query_sp)) {
      top: -25px;
      right: 0;
    }
  }

  > dd > input[type='text'] {
    width: 100%;
    &:disabled {
      opacity: 0.6;
      background-color: #ededed;
    }
  }
  > dd :deep(.el-date-editor.el-input) {
    width: 100%;
  }
}

.btn-availability {
  > button {
    cursor: pointer;
  }
}

.btn-add-title_description {
  display: block;
  padding-top: 20px;
}

.status_wrap {
  width: 100%;
}

.status_wrapper {
  height: 36px;
  padding: 10px 25px 10px 10px;
  line-height: 1;
  border: solid 1px #adadad;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-image: url('../../../../assets/img/arrow_down_blue.svg');
  background-size: 10px auto;
  background-position: right 10px center;
  background-repeat: no-repeat;
  cursor: pointer;

  &.is-empty {
    color: #aaa;
  }

  &.is-disabled {
    background-color: #ededed;
    background-image: none;
    opacity: 0.6;
    cursor: default;
  }
}

.selection_name_wrapper {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:deep(.button_content .wrap_selected_content) {
  min-height: 15.6px;
}

@media (max-width: ($media_query_sp)) {
  @include common_table_style(
    $col_1: 100%,
    $col_1_ta: left,
    $col_2: 100%,
    $col_2_ta: left,
    $col_3: 100%,
    $col_3_ta: left,
    $col_4: 100%,
    $col_4_ta: left
  );

  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
    width: 100%;
  }
  .common_thead_row {
    display: none;
  }
  td::before {
    content: attr(aria-label);
    display: inline-block;
    padding: 10px 0;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
  }
  td:last-of-type {
    padding-bottom: 12px;
  }
}
</style>
