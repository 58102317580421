<template>
  <div v-if="fetchData.data && graphData.data" class="content">
    <div class="aggregate-basic-content">
      <div v-if="fetchData.data.activated" class="chart-section">
        <div class="chart-ttl">応募者率</div>
        <div class="chart-text-wrapper">
          <div class="chart-text activated">
            <span class="title">応募者数</span>
            <span class="value">
              {{ fetchData.data.activated.activated.count }}名
            </span>
          </div>
          <div class="chart-text">
            <span class="title">LINE友達数</span>
            <span class="value">
              {{ fetchData.data.activated.notActivated.count }}名
            </span>
          </div>
        </div>
        <Doughnut
          :data="
            getChartData(
              graphData.data.activated,
              ['応募者数', 'LINE友達数'],
              ['#1899D6', '#EAEAEA'],
            )
          "
          :options="
            getChartOptions(['応募者数', 'LINE友達数'], ['#FFFFFF', '#707070'])
          "
        />
      </div>
      <div v-if="fetchData.data.contactType" class="chart-section">
        <div class="chart-ttl">連絡手段</div>
        <div class="chart-text-wrapper">
          <div class="chart-text line">
            <span class="title">LINE</span>
            <span class="value">
              {{ fetchData.data.contactType.line.count }}名
            </span>
          </div>
          <div class="chart-text">
            <span class="title">メール</span>
            <span class="value">
              {{ fetchData.data.contactType.mail.count }}名
            </span>
          </div>
        </div>
        <Doughnut
          :data="
            getChartData(
              graphData.data.contactType,
              ['LINE', 'メール'],
              ['#22D396', '#EAEAEA'],
            )
          "
          :options="getChartOptions(['LINE', 'メール'], ['#FFFFFF', '#707070'])"
        />
      </div>
      <div v-if="fetchData.data.gender" class="chart-section">
        <div class="chart-ttl">
          性別
          <span>（全統計の割合を表示中）</span>
        </div>
        <div class="chart-text-wrapper">
          <div class="chart-text male">
            <span class="title">男</span>
            <span class="value">{{ fetchData.data.gender.male.count }}名</span>
          </div>
          <div class="chart-text female">
            <span class="title">女</span>
            <span class="value">
              {{ fetchData.data.gender.female.count }}名
            </span>
          </div>
          <div class="chart-text other">
            <span class="title">その他</span>
            <span class="value">{{ fetchData.data.gender.other.count }}名</span>
          </div>
          <div class="chart-text noanswer">
            <span class="title">回答しない</span>
            <span class="value">
              {{ fetchData.data.gender.noanswer.count }}名
            </span>
          </div>
        </div>
        <Doughnut
          :data="
            getChartData(
              graphData.data.gender,
              ['男', '女', 'その他', '回答しない'],
              ['#1899D6', '#FF98C6', '#FFA439', '#EAEAEA'],
            )
          "
          :options="
            getChartOptions(
              ['男', '女', 'その他', '回答しない'],
              ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#707070'],
            )
          "
        />
      </div>
      <div v-if="fetchData.data.departmentCategory" class="chart-section">
        <div class="chart-ttl">
          文理
          <span>（全統計の割合を表示中）</span>
        </div>
        <div class="chart-text-wrapper">
          <div class="chart-text humanitie">
            <span class="title">文系</span>
            <span class="value">
              {{ fetchData.data.departmentCategory.humanities.count }}名
            </span>
          </div>
          <div class="chart-text sciences">
            <span class="title">理系</span>
            <span class="value">
              {{ fetchData.data.departmentCategory.science.count }}名
            </span>
          </div>
          <div class="chart-text">
            <span class="title">その他</span>
            <span class="value">
              {{ fetchData.data.departmentCategory.other.count }}名
            </span>
          </div>
        </div>
        <Doughnut
          :data="
            getChartData(
              graphData.data.departmentCategory,
              ['文系', '理系', 'その他'],
              ['#FFA439', '#22D396', '#EAEAEA'],
            )
          "
          :options="
            getChartOptions(
              ['文系', '理系', 'その他'],
              ['#FFFFFF', '#FFFFFF', '#707070'],
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onBeforeMount, onMounted } from 'vue';
import { useStore } from 'vuex';
import {
  Chart as ChartJS,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Doughnut } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import DashboardsService from '@/services/dashboards';

export default defineComponent({
  name: 'DashboardBasic',
  components: { Doughnut },
  setup(props, context) {
    const store = useStore();
    const graphData = reactive({ data: {} });
    const fetchData = reactive({
      data: {
        activated: { activated: { count: 0 }, notActivated: { count: 0 } },
        contactType: { line: { count: 0 }, mail: { count: 0 } },
        gender: {
          male: { count: 0 },
          female: { count: 0 },
          other: { count: 0 },
          noanswer: { count: 0 },
        },
        departmentCategory: {
          humanities: { count: 0 },
          science: { count: 0 },
          other: { count: 0 },
        },
      },
    });

    // methods
    const fetchAllData = async () => {
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      const payload = {
        companyId: store.getters['company/company'].id,
        graduatedYear: graduatedYear.year,
        selectedGenderOption: 0,
        selectedDepartmentOption: 0,
      };
      const resAll = await Promise.all([
        DashboardsService.fetchBasicContactTypes(payload),
        DashboardsService.fetchBasicActivated(payload),
        DashboardsService.fetchBasicGender(payload),
        DashboardsService.fetchBasicDepartmentCategory(payload),
      ]);
      setData(resAll);
    };
    const setData = resAll => {
      const contactType =
        resAll[0].success === true && resAll[0].data ? resAll[0].data : {};
      const activated =
        resAll[1].success === true && resAll[1].data ? resAll[1].data : {};
      const gender =
        resAll[2].success === true && resAll[2].data ? resAll[2].data : {};
      const departmentCategory =
        resAll[3].success === true && resAll[3].data ? resAll[3].data : {};
      fetchData.data = {
        contactType,
        activated,
        gender,
        departmentCategory,
      };
      graphData.data.contactType = [
        contactType.line.percent,
        contactType.mail.percent,
      ];
      graphData.data.activated = [
        activated.activated.percent,
        activated.notActivated.percent,
      ];
      graphData.data.gender = [
        gender.male.percent,
        gender.female.percent,
        gender.other.percent,
        gender.noanswer.percent,
      ];
      graphData.data.departmentCategory = [
        departmentCategory.humanities.percent,
        departmentCategory.science.percent,
        departmentCategory.other.percent,
      ];
    };
    const resetGraph = () => {
      graphData.data = {
        activated: [0, 0],
        contactType: [0, 0],
        gender: [0, 0, 0, 0],
        departmentCategory: [0, 0, 0],
      };
    };
    const getChartData = (data, labels, colors) => {
      return {
        labels,
        datasets: [{ data, backgroundColor: colors, borderWidth: 0 }],
      };
    };
    const getChartOptions = (labels, colors) => {
      return {
        cutout: 35,
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
        hover: { mode: null },
        plugins: {
          legend: { position: 'bottom', display: false },
          color: colors,
          datalabels: {
            textAlign: 'center',
            color: context => {
              return colors[context.dataIndex];
            },
            formatter: (value, context) => {
              if (value === 0) return '';
              return `${labels[context.dataIndex]}\n${value}%`;
            },
          },
        },
        animation: { tension: { duration: 1500 } },
      };
    };

    ChartJS.register(
      Legend,
      ArcElement,
      CategoryScale,
      LinearScale,
      ChartDataLabels,
    );

    // lifecycle
    onBeforeMount(() => resetGraph());
    onMounted(async () => await fetchAllData());

    return {
      graphData,
      fetchData,
      getChartData,
      getChartOptions,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.content {
  padding: 40px 0px;
  background: #fff;
  .aggregate-basic-content {
    display: flex;
  }
  .chart-section {
    width: 25%;
    padding: 0 30px;
    border-right: solid 1px #ededed;
    &:last-child {
      border-right: none;
    }
    .chart-ttl {
      width: 100%;
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: 0.05em;
      span {
        display: inline-block;
        font-size: 1.1rem;
      }
    }
    .chart-text-wrapper {
      margin: 20px 0;
      min-height: 100px;
      .chart-text {
        color: #707070;
        font-size: 1.3rem;
        margin-bottom: 10px;
        position: relative;
        margin-left: 1.3em;
        .title {
          display: inline-block;
          &:after {
            display: inline-block;
            content: ':';
            padding: 0 4px;
          }
        }
        .value {
          display: inline-block;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          display: inline-block;
          content: '';
          width: 12px;
          height: 12px;
          background: #eaeaea;
          position: absolute;
          top: 1px;
          left: -1.3em;
        }
        &.activated {
          &::before {
            background: #1899d6;
          }
        }
        &.line {
          &::before {
            background: #22d396;
          }
        }
        &.male {
          &::before {
            background: #1899d6;
          }
        }
        &.female {
          &::before {
            background: #ff98c6;
          }
        }
        &.other {
          &::before {
            background: #ffa439;
          }
        }
        &.noanswer {
          &::before {
            background: #eaeaea;
          }
        }
        &.humanitie {
          &::before {
            background: #ffa439;
          }
        }
        &.sciences {
          &::before {
            background: #22d396;
          }
        }
      }
    }
    .area-select {
      margin-top: 20px;
      .label-select {
        position: relative;
        select {
          width: 100%;
          height: 3rem;
          padding-left: 1rem;
          border: solid 1px #e3e3e3;
          border-radius: 4px;
          &::-ms-expand {
            display: none;
          }
        }
        &::after {
          content: '';
          width: 6px;
          height: 6px;
          border: 0px;
          border-bottom: solid 2px #178fd0;
          border-right: solid 2px #178fd0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          right: 10px;
          margin-top: -4px;
        }
      }
    }
  }

  @media (max-width: ($media_query_tablet)) {
    .aggregate-basic-content {
      flex-wrap: wrap;
      margin-bottom: -40px;
    }
    .chart-section {
      width: 50%;
      border-right: none;
      .chart-text-wrapper {
        min-height: auto;
      }
    }
    .chart-section:nth-child(even) {
      padding: 0 40px 40px 20px;
    }
    .chart-section:nth-child(odd) {
      padding: 0 20px 40px 40px;
    }
  }

  @media (max-width: ($media_query_sp)) {
    .aggregate-basic-content {
      display: block;
    }
    .chart-section {
      width: 100%;
    }
    .chart-section:nth-child(even) {
      padding: 0 math_size(20) 40px;
    }
    .chart-section:nth-child(odd) {
      padding: 0 math_size(20) 40px;
    }
  }
}
</style>
