<template>
  <button
    v-if="withIcon === true"
    class="copy-btn_with_icon"
    @click="copyToClipboard(text)"
  >
    <img class src="@/assets/img/icon_copy.svg" />
  </button>
  <button
    v-else-if="blueButtonText"
    class="btn btn-availability"
    @click="copyToClipboard(text)"
  >
    <span :class="{ 'is-blue-icon': isBlueIcon }">{{ blueButtonText }}</span>
  </button>
  <button v-else class="copy-btn" @click="copyToClipboard(text)">コピー</button>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

import BrowserUtil from '@/utils/browser';

export default defineComponent({
  name: 'ButtonClipboardCopy',
  props: {
    text: {
      type: String,
      default: '',
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    blueButtonText: {
      type: String,
      default: '',
    },
    isBlueIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = useStore();
    const copyToClipboard = tmpText => {
      const res = BrowserUtil.copyToClipboard(tmpText);
      if (res) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'クリップボードにコピーしました',
          type: true,
        });
      }
    };
    return { copyToClipboard };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.copy-btn_with_icon {
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  padding: 2px 0px 2px 0px;
  background-color: inherit;
}

.copy-btn {
  border-radius: 4px;
  color: $white;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  background-color: #999;
  margin-left: 10px;
}

.copy-btn:hover {
  background-color: #666 !important;
}

.btn-availability {
  span::before {
    content: '';
    mask-image: url(../../../../assets/img/icon_copy.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    display: inline-block;
    position: relative;
    background-color: #ffffff;
    height: 16px;
    width: 16px;
    top: 2px;
    margin-right: 3px;
  }

  span.is-blue-icon::before {
    content: '';
    mask-image: url(../../../../assets/img/icon_copy.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    display: inline-block;
    position: relative;
    background-color: #0085fa;
    height: 16px;
    width: 16px;
    top: 2px;
    margin-right: 3px;
  }
}
</style>
