<template>
  <main class="flexbox width-sp-limit">
    <div class="contents">
      <div class="form-wrapper contents_wrapper">
        <div class="form_intro">
          <div class="mochica_desc">
            <div class="company_name">{{ companyName }}</div>
            <div class="reserve_title">ご案内</div>
          </div>
          <p class="form_intro_desc">
            参加を希望する、日時、会場、時間を選択し、送信ボタンを押してください。
          </p>
        </div>
        <div class="form_body">
          <h2 class="selection_name">
            <span class="selection_name_inner">
              {{ selectionForm.selection.event.title }}
            </span>
            で参加を希望する、日時・会場を選択してください。
          </h2>
          <div class="selection_notice">
            <b>連絡事項：</b>
            <p class="selection_notice_content">
              {{ selectionForm.selection.notice }}
            </p>
          </div>
          <form class="selection_wrapper">
            <ul v-if="isVisibleVenue" class="selection_venue">
              <li
                v-for="venue in selectionForm.selection.venues"
                :key="venue.id"
                :class="{ venue_active: selectedVenue.data.id === venue.id }"
                class="selection_venue_item"
                @click="selectVenue(venue)"
              >
                <div class="selection_venue_item-date">{{ venue.date }}</div>
                <div class="selection_venue_item-name">{{ venue.name }}</div>
                <div class="selection_venue_item-address">
                  {{ venue.address }}
                </div>
              </li>
            </ul>

            <div v-if="isVisibleTime" class="selection_time">
              <h2 class="selection_time_desc">
                選択した会場で参加を希望する時間をお選びください。
              </h2>
              <ul class="selection_time_item_wrapper">
                <li
                  v-for="time in selectedVenueTimetableFormatted"
                  :key="time.id"
                  :class="{ time_active: selectedTime.data.id === time.id }"
                  class="selection_time_item"
                  @click="selectTime(time)"
                >
                  {{ time.start }}~{{ time.last }}
                </li>
              </ul>
            </div>
            <div v-if="isVisibleCheck" class="check_selection">
              <h2 class="check_selection_desc">
                以下の内容で日程を決定します。よろしければ送信ボタンをクリックしてください。
              </h2>
              <ul class="check_selection_wrapper">
                <li class="check_selection_venue check_selection_item">
                  <dl>
                    <dt class="check_selection_venue_company">会社名:</dt>
                    <dd class="dt check_selection_venue_company">
                      {{ companyName }}
                    </dd>
                  </dl>
                  <dl>
                    <dt class="check_selection_venue_selection">イベント名:</dt>
                    <dd class="dt check_selection_venue_selection">
                      {{ selectionForm.selection.event.title }}
                    </dd>
                  </dl>
                  <dl>
                    <dt class="check_selection_venue_date">日付:</dt>
                    <dd class="dt check_selection_venue_date">
                      {{ selectedVenue.data.date }}
                    </dd>
                  </dl>
                  <dl>
                    <dt class="check_selection_time_ttl">時間:</dt>
                    <dd class="dt check_selection_time_value">
                      {{ selectedTime.data.start }}〜{{
                        selectedTime.data.last
                      }}
                    </dd>
                  </dl>
                  <dl>
                    <dt class="check_selection_venue_name">会場名:</dt>
                    <dd class="dt check_selection_venue_name">
                      {{ selectedVenue.data.name }}
                    </dd>
                  </dl>
                  <dl>
                    <dt class="check_selection_venue_address">住所:</dt>
                    <dd class="dt check_selection_venue_address">
                      {{ selectedVenue.data.address }}
                    </dd>
                  </dl>
                  <dl>
                    <dt class="check_selection_venue_room">部屋名:</dt>
                    <dd class="dt check_selection_venue_room">
                      {{ selectedVenue.data.room }}
                    </dd>
                  </dl>
                </li>
              </ul>
              <button-base
                class="regist_btn"
                button-text="上記の内容で送信"
                :is-loading="isLoading"
                @click="postSelectionTime"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, computed, reactive } from 'vue';

export default defineComponent({
  name: 'InputSelectionForm',
  props: {
    selectionForm: {
      type: Object,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['postSelectionTime'],
  setup(props, context) {
    const isVisibleVenue = ref(true);
    const isVisibleTime = ref(false);
    const isVisibleCheck = ref(false);
    const selectedVenue = reactive({ data: {} });
    const selectedTime = reactive({ data: {} });

    // computed
    const selectedVenueTimetableFormatted = computed(() => {
      const venues = props.selectionForm.selection.venues.find(
        venue => venue.id === selectedVenue.data.id,
      );
      if (venues) {
        return venues.timetables.map(timetable => ({
          id: timetable.id,
          limit: timetable.limit,
          start: timetable.start.slice(0, -3),
          last: timetable.last.slice(0, -3),
        }));
      }
      return null;
    });

    // methods
    const postSelectionTime = () => {
      if (selectedVenue.data.id !== null && selectedTime.data.id !== null) {
        context.emit('postSelectionTime', {
          timetableId: selectedTime.data.id,
        });
      }
    };
    const selectVenue = venue => {
      if (venue) {
        selectedVenue.data.id = parseInt(venue.id, 10);
        selectedVenue.data.date = venue.date;
        selectedVenue.data.name = venue.name;
        selectedVenue.data.pref = venue.pref;
        selectedVenue.data.address = venue.address;
        selectedVenue.data.room = venue.room_name;
        selectedVenue.data.notice = venue.notice;
        selectedTime.data.venueId = parseInt(venue.id, 10);
        isVisibleTime.value = true;
        isVisibleCheck.value = false;
        selectedTime.data.id = null;
        selectedTime.data.start = '';
        selectedTime.data.last = '';
      }
    };
    const selectTime = time => {
      if (time) {
        selectedTime.data.id = parseInt(time.id, 10);
        selectedTime.data.start = time.start;
        selectedTime.data.last = time.last;
        isVisibleCheck.value = true;
      }
    };

    return {
      isVisibleVenue,
      isVisibleTime,
      isVisibleCheck,
      selectedVenue,
      selectedTime,
      selectedVenueTimetableFormatted,
      postSelectionTime,
      selectVenue,
      selectTime,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.mochica_desc {
  text-align: center;
  padding: 3rem 0 3rem;
  .company_name {
    font-size: 1.8rem;
    font-weight: bold;
  }
  .reserve_title {
    font-size: 1.4rem;
    font-weight: bold;
  }
}
.regist_btn {
  width: 100%;
  border: none;
  background-color: #656565 !important;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem !important;
  padding: 10px 0 !important;
  border-radius: 20px !important;
  cursor: pointer;
}

.contents {
  width: 100%;
}
.form-wrapper {
  width: 90%;
  max-width: 96rem;
  margin: 0 auto;
}
.form_intro_desc {
  font-size: 1.4rem;
}
.form_body {
  padding: 3rem 0;
  .selection_notice {
    margin-bottom: 1rem;
    .selection_notice_content {
      white-space: pre-wrap;
      word-wrap: break-word;
      line-height: 1.2;
    }
  }
  .selection_name {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    &::before {
      content: '1';
      @include flex_center_center;
      height: 3rem;
      width: 3rem;
      border-radius: 1.5rem;
      border: 2px solid #656565;
      color: #656565;
      font-weight: bold;
      font-size: 1.6rem;
      margin-right: 1rem;
    }
    .selection_name_inner {
      color: #656565;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
  .selection_wrapper {
    .selection_venue {
      border: 1px solid $border_color;
      padding: 2rem 2rem 0.6rem;
      border-radius: 0.6rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 1.2rem;
      .selection_venue_item {
        width: 48%;
        margin-bottom: 2%;
        padding-top: 1%;
        padding-bottom: 2%;
        cursor: pointer;
        transition: all ease 0.2s;
        &::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          background: $border_color;
          position: absolute;
          bottom: -9%;
          left: 0;
          opacity: 0.4;
        }
        position: relative;
        .selection_venue_item-date {
          color: #656565;
          font-weight: bold;
          font-size: 1.4rem;
          letter-spacing: 0.08em;
        }
        .selection_venue_item-name {
          background: #656565;
          color: #fff;
          font-weight: bold;
          padding: 0 0.5rem;
        }
        .selection_venue_item-address {
          margin-top: -0.1rem;
          border: 1px solid $border_color;
          padding: 0 0.5rem;
        }

        &:nth-of-type(2n) {
          margin-left: 2%;
          &::after {
            content: '';
            display: inline-block;
            height: 100%;
            width: 1px;
            background: $border_color;
            position: absolute;
            left: -4%;
            top: 0;
            opacity: 0.4;
          }
        }
        &:last-of-type,
        &:nth-last-of-type(2) {
          margin-bottom: 0;
          &::before {
            display: none;
          }
        }

        &:hover {
          opacity: 0.6;
        }
        &.active {
        }
      }
    }
    .venue_active {
      .selection_venue_item-name {
        background-color: #1ecd01 !important;
      }
      .selection_venue_item-address {
        border-color: #1ecd01 !important;
      }
    }
    .time_active {
      border-color: #1ecd01 !important;
      color: #1ecd01 !important;
    }
    .selection_time {
      .selection_time_desc {
        display: flex;
        align-items: center;
        padding-top: 3rem;
        margin: 3rem 0 1rem;
        border-top: 1px solid $border_color;
        &::before {
          content: '2';
          @include flex_center_center;
          height: 3rem;
          width: 3rem;
          border-radius: 1.5rem;
          border: 2px solid #656565;
          color: #656565;
          font-weight: bold;
          font-size: 1.6rem;
          margin-right: 1rem;
        }
      }
      .selection_time_item {
        cursor: pointer;
        border: 1px solid $border_color;
        width: 24%;
        text-align: center;
        height: 3rem;
        line-height: 3rem;
        margin-left: 1%;
        margin-bottom: 1%;
        font-weight: bold;
        border-radius: 0.2rem;
        transition: all ease 0.3s;
        &:hover {
          border: 1px solid #656565;
          color: #656565;
        }
        &.active {
          background: #1ecd01;
          color: #fff;
          border-color: #1ecd01;
        }
      }
      .selection_time_item_wrapper {
        border: 1px solid $border_color;
        padding: 2rem 2rem 1rem;
        border-radius: 0.6rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 1.2rem;
      }
    }
    .check_selection {
      .check_selection_desc {
        padding-top: 3rem;
        margin: 3rem 0 1rem;
        border-top: 1px solid $border_color;
        display: flex;
        align-items: center;
        &::before {
          content: '3';
          @include flex_center_center;
          height: 3rem;
          width: 3rem;
          border-radius: 1.5rem;
          border: 2px solid #656565;
          color: #656565;
          font-weight: bold;
          font-size: 1.6rem;
          margin-right: 1rem;
        }
      }
      .check_selection_item {
        dl {
          margin-right: 1rem;
          margin-bottom: 1rem;
          dt {
            font-weight: bold;
            color: #656565;
            display: inline;
            margin-right: 1rem;
          }
          dd {
            display: inline-block;
          }
        }
      }
      .regist_btn {
        margin-top: 3rem;
        transition: all ease 0.3s;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contents {
    width: 100%;
  }
  .form-wrapper {
    width: 100%;
    max-width: unset;
    margin: 2rem auto;
  }
  .form_body {
    padding: 3rem 0;
    .selection_name {
      margin-bottom: 1rem;
      display: block;
      &::before {
        display: block;
        text-align: center;
        margin: 0 auto 1rem;
        line-height: 3rem;
      }
      .selection_name_inner {
        color: #656565;
        font-size: 1.6rem;
        font-weight: bold;
      }
    }
    .selection_wrapper {
      .selection_venue {
        border: none;
        padding: 0;
        .selection_venue_item {
          width: 100%;
          margin-bottom: 2%;
          padding-top: 2%;
          padding-bottom: 2%;
          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 1px;
            background: $border_color;
            position: absolute;
            bottom: -9%;
            left: 0;
            opacity: 0.4;
          }
          position: relative;
          .selection_venue_item-date {
            color: #656565;
            font-weight: bold;
            font-size: 1.2rem;
            letter-spacing: 0.08em;
          }
          .selection_venue_item-name {
            background: #656565;
            color: #fff;
            font-weight: bold;
            padding: 0 0.5rem;
          }
          .selection_venue_item-address {
            margin-top: -0.1rem;
            border: 1px solid $border_color;
            padding: 0 0.5rem;
          }

          &:nth-of-type(2n) {
            margin-left: 0;
            &::after {
              display: none;
            }
          }
        }
      }
      .venue_active {
        .selection_venue_item-name {
          background-color: #1ecd01 !important;
        }
        .selection_venue_item-address {
          border-color: #1ecd01 !important;
        }
      }
      .time_active {
        .selection_time_item {
          border-color: #1ecd01 !important;
          color: #1ecd01 !important;
        }
      }
      .selection_time {
        .selection_time_desc {
          margin-bottom: 1rem;
          display: block;
          &::before {
            display: block;
            text-align: center;
            margin: 0 auto 1rem;
            line-height: 3rem;
          }
        }
        .selection_time_item {
          width: 100%;
          text-align: center;
          margin: 0 0 0.6rem;
        }
        .selection_time_item_wrapper {
          border: none;
          padding: 0;
        }
      }
      .check_selection {
        .check_selection_desc {
          margin-bottom: 1rem;
          display: block;
          &::before {
            display: block;
            text-align: center;
            margin: 0 auto 1rem;
            line-height: 3rem;
          }
        }
        .check_selection_item {
          dl {
            display: block;
            margin: 0 0 1rem;
            dt {
              font-weight: bold;
              color: #656565;
              display: inline;
              margin-right: 1rem;
            }
            dd {
              display: inline-block;
            }
          }
        }
        .regist_btn {
          margin-top: 3rem;
        }
      }
    }
  }
}
</style>
