<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useField } from 'vee-validate';
import BaseCheckbox from '@/components/ui/checkbox/BaseCheckbox.vue';
import FormEmail from '@/components/ui/forms/FormEmail.vue';
import FormLine from '@/components/ui/forms/FormLine.vue';
import FormFileSelect from '@/components/ui/forms/FormFileSelect.vue';
import ModalFixedPhraseList from '@/components/features/fixedPhrase/components/ModalFixedPhraseList';

import { excludeFileExtensions } from '@/components/ui/attachmentFile/defines/exclude-file-extensions';
import { generateUniqueId } from '@/utils/unique-id';
import { download } from '@/utils/file';
import { NOTICE_COPY_TAGS } from '@/defines/autoActions';

import fixphraseService from '@/services/fixphrase';

const store = useStore();
const {
  value: emailSubject,
  errorMessage: emailSubjectErrorMessage,
  handleChange: handleChangeEmailSubject,
} = useField('notice.email.subject');
const {
  value: emailContent,
  errorMessage: emailContentErrorMessage,
  handleChange: handleChangeEmailContent,
} = useField('notice.email.content');
const {
  value: lineContent,
  errorMessage: lineContentErrorMessage,
  handleChange: handleChangeLineContent,
} = useField('notice.line.content');
const { value: isSendForceEmail } = useField('notice.isSendForceEmail');
const { value: isConfirmMessage } = useField('notice.isConfirmMessage');
const { value: files } = useField('notice.files');

const isFixPhraseBtnDisabled = ref(false);
const fixPhrases = ref([]);
const isViewModalSelectFixPhraseList = ref(false);

const fileSelectErrorMessage = ref('');

const currentFileSize = computed(() => {
  return files.value.length > 0
    ? files.value.reduce((prev, current) => {
        return prev + current.fileSize;
      }, 0)
    : 0;
});

const handleAddFile = selectedFiles => {
  if (!selectedFiles.length || !validateFile(selectedFiles)) return;
  const targetFile = {
    id: generateUniqueId(),
    file: selectedFiles[0],
    fileName: selectedFiles[0].name,
    fileSize: selectedFiles[0].size,
    blob: new Blob([selectedFiles[0]], { type: selectedFiles[0].type }),
  };
  files.value.push(targetFile);
  resetError();
};

const handleUpdateFiles = event => {
  files.value = event;
  resetError();
};

const handleDownloadFile = file => {
  download(file.blob, file.filename);
};

const resetError = () => {
  fileSelectErrorMessage.value = '';
};

const validateFile = newFiles => {
  if (newFiles.length > 1) {
    fileSelectErrorMessage.value = '1度に選択できるファイルは1つまでです';
    return false;
  }
  if (files.value.length === 3) {
    fileSelectErrorMessage.value = '添付できるファイルは３つまでです';
    return false;
  }
  if (newFiles[0].name.length > 70) {
    fileSelectErrorMessage.value = '添付できるファイル名は70文字までです';
    return false;
  }
  if (calcFileSize(newFiles[0].size) >= 3 * 1024 * 1024) {
    fileSelectErrorMessage.value = '添付できるファイルサイズは合計3MBまでです';
    return false;
  }
  if (excludeFileExtensions.includes(fileExtension(newFiles[0].name))) {
    fileSelectErrorMessage.value = '添付できないファイル形式が選択されています';
    return false;
  }
  return true;
};

const fileExtension = fileName => {
  return fileName.split('.').pop();
};

const calcFileSize = addFileSize => {
  return currentFileSize.value + addFileSize;
};

const selectFixedPhrase = payload => {
  isViewModalSelectFixPhraseList.value = false;
  emailSubject.value = payload.mail_title;
  emailContent.value = payload.mail_content;
  lineContent.value = payload.line_content;
};

const openModalFixedPhraseList = async () => {
  isFixPhraseBtnDisabled.value = true;
  const res = await fixphraseService.postFixPhraseList(
    store.getters['graduateds/selectedGraduatedId'],
  );
  if (res.success) {
    fixPhrases.value = res.fixphrase_list;
  }
  isViewModalSelectFixPhraseList.value = true;
  isFixPhraseBtnDisabled.value = false;
};
</script>

<template>
  <div class="action-inner">
    <div class="action-title">
      <h3 class="title">連絡事項を通知</h3>
      <div class="desc">
        メールとLINEを使用して、応募者に連絡事項を通知します。
      </div>
    </div>
    <div class="action-contents auto-action">
      <div class="result-contents-inner">
        <ul class="result-options">
          <li class="result-option">
            <div class="title">全員にメールで送信</div>
            <div class="contents">
              <div class="result-option-check">
                <base-checkbox v-model="isSendForceEmail" />
                <div>全員にメールで送信する（ファイル添付可）</div>
              </div>
              <ul class="result-option-capions">
                <li class="result-option-capion">
                  ※ チェックを入れいるとメールのみで送信します。
                </li>
                <li class="result-option-capion">
                  ※ 連絡方法がLINEの応募者にもメールで送信されます。
                </li>
              </ul>
            </div>
          </li>
          <li class="result-option">
            <div class="title">メッセージ確認</div>
            <div class="contents">
              <div class="result-option-check">
                <base-checkbox v-model="isConfirmMessage" />
                <div>送信したメッセージを応募者が確認したかチェックする</div>
              </div>
              <ul class="result-option-capions">
                <li class="result-option-capion">
                  ※LINEの場合は応募者に確認ボタンが送信されます。
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div class="action-forms">
          <div class="action-form">
            <div class="action-form-title notice">
              連絡事項のメール・LINEの文面
            </div>
            <div>
              <div class="message-templete-button">
                <button-base
                  :disabled="isFixPhraseBtnDisabled"
                  type="button"
                  button-text="メッセージテンプレート"
                  icon-file-name="icon_template"
                  @click="openModalFixedPhraseList"
                />
              </div>
              <div class="action-form-contents">
                <div>
                  <form-email
                    :subject="{
                      value: emailSubject,
                      errorMessage: emailSubjectErrorMessage,
                    }"
                    :content="{
                      value: emailContent,
                      errorMessage: emailContentErrorMessage,
                    }"
                    :copy-tags="NOTICE_COPY_TAGS"
                    @blur-subject="handleChangeEmailSubject"
                    @blur-content="handleChangeEmailContent"
                  >
                    <template v-if="isSendForceEmail" #addCaptions>
                      <li class="message-caption-list">
                        ※ 添付ファイルは３つまで、かつ合計3MBまで添付できます。
                      </li>
                    </template>
                    <template v-if="isSendForceEmail" #attachment>
                      <div class="form-file-wrapper">
                        <form-file-select
                          :files="files"
                          :error="{
                            isError: !!fileSelectErrorMessage,
                            message: fileSelectErrorMessage,
                          }"
                          @add-file="handleAddFile($event)"
                          @update-files="handleUpdateFiles($event)"
                          @download-file="handleDownloadFile($event)"
                        />
                      </div>
                    </template>
                  </form-email>
                </div>
                <form-line
                  :content="{
                    value: lineContent,
                    errorMessage: lineContentErrorMessage,
                  }"
                  :copy-tags="NOTICE_COPY_TAGS"
                  :is-force-send-email="isSendForceEmail"
                  @blur-content="handleChangeLineContent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-fixed-phrase-list
      :fixed-phrase-list="fixPhrases"
      :is-view="isViewModalSelectFixPhraseList"
      @openFixedPhrase="selectFixedPhrase"
      @onClickCloseModalSelectPhraseList="
        isViewModalSelectFixPhraseList = false
      "
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;

.result-contents-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.result-options {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 20px;
  background: #fafafa;
  border: 1px solid #ededed;
  border-radius: 4px;
  font-size: $font_14;
}

.result-option {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &:not(:last-of-type) {
    padding: 0 0 24px;
    border-bottom: 1px solid #ededed;
  }
  .title {
    color: $mochica_color;
    font-weight: bold;
  }
  .contents {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.result-option-check {
  display: flex;
  align-items: center;
  gap: 8px;
}

.result-option-capions {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 0 0 24px;
  color: #777;
  font-size: $font_12;
}

.message-templete-button {
  margin: 20px 0 0 28px;
}
.form-file-wrapper {
  margin-top: 16px;
}
.contactForm-wrapper .message-caption-list {
  line-height: 1.8;
}
</style>
