<template>
  <div v-if="isTab !== true" class="switch-disp-area">
    <div v-if="isDispApplicant" class="flex">
      <label
        for="applicants"
        :class="{ 'is-selected_label': dispApplicants === 'regist_applicants' }"
      >
        <input
          id="applicants"
          type="radio"
          name="disp_applicants"
          checked="checked"
          value="regist_applicants"
          @input="$emit('input', $event.target.value)"
        />
        <span
          :class="{ 'is-selected': dispApplicants === 'regist_applicants' }"
        >
          応募者
        </span>
      </label>
    </div>
    <div v-if="isDispLineFriends" class="flex">
      <label
        v-if="isDispLineFriends"
        for="line-friends"
        :class="{ 'is-selected_label': dispApplicants === 'line_friends' }"
      >
        <input
          id="line-friends"
          type="radio"
          name="disp_applicants"
          value="line_friends"
          @input="$emit('input', $event.target.value)"
        />
        <span :class="{ 'is-selected': dispApplicants === 'line_friends' }">
          LINE友達
        </span>
      </label>
    </div>
  </div>
  <div v-else class="switch-disp-area is-tab">
    <div v-if="isDispApplicant" class="flex">
      <label
        :class="{ 'is-selected_label': dispApplicants === 'regist_applicants' }"
      >
        <input
          type="radio"
          name="disp_applicants"
          checked="checked"
          value="regist_applicants"
          @input="$emit('input', $event.target.value)"
        />
        <span
          :class="{ 'is-selected': dispApplicants === 'regist_applicants' }"
        >
          応募者
        </span>
      </label>
    </div>
    <div v-if="isDispLineFriends" class="flex">
      <label
        v-if="isDispLineFriends"
        :class="{ 'is-selected_label': dispApplicants === 'line_friends' }"
      >
        <input
          type="radio"
          name="disp_applicants"
          value="line_friends"
          @input="$emit('input', $event.target.value)"
        />
        <span :class="{ 'is-selected': dispApplicants === 'line_friends' }">
          LINE友達
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SwitchTabApplicant',
  props: {
    dispApplicants: {
      type: String,
      default: '',
    },
    isDispApplicant: {
      type: Boolean,
      default: true,
    },
    isDispLineFriends: {
      type: Boolean,
      default: false,
    },
    isTab: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, context) {},
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.switch-disp-area {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding-bottom: 2rem;
  label {
    cursor: pointer;
    padding: 7px;
    span {
      font-size: 1.4rem;
      text-align: center;
      display: inline-block;
    }
    input[type='radio'] {
      margin-right: 1rem;
    }
  }
  .flex {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    &:first-child {
      span {
        padding-right: 4rem;
      }
    }
  }
  .is-selected {
    color: $mochica_color;
  }
  &.is-tab {
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 2rem;
    border-bottom: 4px solid #ccc;
    label {
      padding: 10px 0;
      margin: 0 auto;
      text-align: center;
      span {
        display: inline-block;
        width: 120px;
        padding: 0 10px;
        margin: 0 auto;
        font-size: 1.4rem;
        text-align: center;
      }
      input[type='radio'] {
        display: none;
      }
    }
    .is-selected_label {
      font-weight: bold;
      margin: 0 auto -4px;
      border-bottom: 4px solid $mochica_color;
    }
    .flex {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      &:first-child {
        span {
          padding: 0 10px;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}
</style>
