<template>
  <div class="home_content">
    <p class="home_sub_title">MOCHICAからのお知らせ</p>
    <ul class="home_news">
      <li v-for="(v, i) in news" :key="`news_${i}`">
        <a :href="v.link" target="_blank">
          <span>{{ $utils.parseDateTime(v.date, 'YYYY/MM/DD') }}</span>
          {{ v.title }}
        </a>
      </li>
      <li v-if="news.length > 0">
        <a href="https://help.mochica.jp/news/" target="_blank">
          他のお知らせを確認
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import newsService from '@/services/news';

export default defineComponent({
  name: 'HomeNews',
  setup(props, context) {
    const store = useStore();
    const news = ref([]);

    const fetchAll = async () => {
      const res = await newsService.getNews();
      if (res.success === true) {
        // 最新の2件のみ表示
        news.value = res.news.slice(0, 2);
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
      }
    };

    return { news, fetchAll };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.home_sub_title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
}

.home_content {
  min-height: 245px;
  padding: 30px 30px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
}

.home_news {
  > li {
    padding: 15px 0;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
    word-wrap: break-word;
    line-height: 1.6;
    > a {
      color: #000;
      > span {
        margin-right: 12px;
        color: #777777;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &:last-child {
      text-align: right;
      border-bottom: none;
    }
  }
}
</style>
