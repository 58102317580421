const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8MB
const MAX_FILE_NAME_LENGTH = 70;
const ignoreFileTypes = Object.freeze([
  'exe',
  'dll',
  'js',
  'sh',
  'php',
  'rb',
  'gz',
  'lzh',
  '7z',
]);

/**
 * ファイル名から拡張子を分離して取得
 * @param {String} filename - filename
 * @return {String} 拡張子
 */
const getExtName = filename => {
  const fileSplit = filename.split('.');
  return fileSplit.length >= 2 ? fileSplit[fileSplit.length - 1] : '';
};

/**
 * Fileのダウンロード
 * @param {Blob} blob - blob
 * @param {String} filename - filename
 */
const download = (blob, filename) => {
  const link = document.createElement('a');
  if (window.navigator.msSaveOrOpenBlob) {
    // for ie
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else if (window.URL && window.URL.createObjectURL) {
    // for firefox & chrome
    link.setAttribute('download', filename);
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.click();
    window.URL.revokeObjectURL(link.href);
  } else if (window.webkitURL && window.webkitURL.createObjectURL) {
    // for safari
    link.setAttribute('download', filename);
    link.setAttribute('href', window.webkitURL.createObjectURL(blob));
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
};

/**
 * ByteをMega Byteに変換
 * @param {Number} size - Byte
 * @param {Number} floatNum - 四捨五入する少数点の調整 (default: 1)
 * @return {Number} MB
 */
const conversionToMegaByte = (byte, floatNum = 1) =>
  Math.round((byte / (1024 * 1024)) * floatNum) / floatNum;

/**
 * ファイルサイズのバリデーション
 * @param {Object} fileInfo - ファイルオブジェクト
 * @param {Number} maxSize - 最大ファイルサイズ（Optional）
 * @param {Number} fileNameLength - 最大ファイル文字数（Optional）
 * @return {{success: Boolean, message: String}} バリデーション結果
 */
const validateFile = (
  fileInfo,
  maxSize = MAX_FILE_SIZE,
  fileNameLength = MAX_FILE_NAME_LENGTH,
) => {
  if (
    !fileInfo ||
    !fileInfo.name ||
    ignoreFileTypes.includes(getExtName(fileInfo.name))
  ) {
    return {
      success: false,
      message: '許可されていないファイルフォーマットです。',
    };
  }
  if (fileInfo.name.length > fileNameLength) {
    return {
      success: false,
      message: `ファイル名が${fileNameLength}文字を超えています。`,
    };
  }
  if (fileInfo.size > maxSize) {
    return {
      success: false,
      message: `ファイルサイズが${conversionToMegaByte(
        maxSize,
      )}MBを超えています。`,
    };
  }
  return { success: true };
};

/**
 * ファイルサイズのバリデーション
 * @param {Number} size - 比較するファイルサイズ
 * @param {Number} maxSize - ファイルサイズのmax（Optional）
 * @return {{success: Boolean, message: String}} バリデーション結果
 */
const validateCsvFileSize = (size, maxSize = MAX_FILE_SIZE) => {
  if (size < maxSize) return { success: true };
  return {
    success: false,
    message: `登録しようとしているCSVファイルが${conversionToMegaByte(
      maxSize,
    )}MBを超えています。`,
  };
};

/**
 * 対象の文字列から拡張子を取得
 * @param {Number} text - 対象の文字列
 * @return {string|null} 拡張子文字列
 */
const getFileExtension = text => {
  if (!text) return null;
  if (text.indexOf('画像ファイルが送信されました。') !== -1) {
    return '.jpg';
  }
  if (text.indexOf('音声ファイルが送信されました。') !== -1) {
    return '.m4a';
  }
  if (text.indexOf('PDFファイルが送信されました。') !== -1) {
    return '.pdf';
  }
  if (text.indexOf('ビデオファイルが送信されました。') !== -1) {
    return '.mp4';
  }
  if (text.indexOf('画像ファイルが送信されました。') !== -1) {
    return '';
  }
  return null;
};

export {
  download,
  conversionToMegaByte,
  validateFile,
  validateCsvFileSize,
  getFileExtension,
};
