<template>
  <div>
    <balloon-menu :is-view="applicantsEditForm" @close="$emit('close')">
      <template #content>
        <div>
          <div class="applicants-edit-group">
            <div class="applicants-edit-category">
              <span>選考関連</span>
            </div>
            <div class="applicants-edit-menu" @click="openBulkUpdateSelection">
              <span>応募者に選考を割当て</span>
            </div>
            <div class="applicants-edit-menu" @click="openBulkDeleteSelection">
              <span>応募者の指定した選考を削除</span>
            </div>
            <div
              class="applicants-edit-menu"
              @click="openBulkSendReserveSelection"
            >
              <span>応募者に選考予約画面を送信</span>
            </div>
            <div
              class="applicants-edit-menu"
              @click="openBulkUpdateFinProgress"
            >
              <span>応募者の評価を変更</span>
            </div>
          </div>
          <div class="applicants-edit-group">
            <div class="applicants-edit-category">
              <span>応募者の管理</span>
            </div>
            <div class="applicants-edit-menu" @click="openBulkUpdateMenu">
              <span>応募者の担当者を変更</span>
            </div>
            <div class="applicants-edit-menu" @click="openBulkUpdateFlag">
              <span>応募者にフラグを追加</span>
            </div>
          </div>
          <div v-if="isActivated" class="applicants-edit-group">
            <div class="applicants-edit-category">
              <span>切り替え案内</span>
            </div>
            <div
              v-if="!enabledLineNotification"
              class="applicants-edit-menu"
              @click="openBulkUpdateSendSwitchLineAnnounce({ type: 'mail' })"
            >
              <span>応募者にメールで切り替え案内を送信</span>
            </div>
            <div
              class="applicants-edit-menu"
              :class="{ disabled: !enabledLineNotification }"
              @click="openBulkUpdateSendSwitchLineAnnounce({ type: 'line' })"
            >
              <span>応募者にLINE・メールで切り替え案内を送信</span>
            </div>
          </div>
          <div v-else class="applicants-edit-group">
            <div class="applicants-edit-category">
              <span>エントリーフォームの送信</span>
            </div>
            <div class="applicants-edit-menu" @click="openSendEntryForm">
              <span>応募者にエントリーフォームを送信</span>
            </div>
          </div>
          <div v-if="!isFreePlan" class="applicants-edit-group">
            <div class="applicants-edit-category">
              <span>その他</span>
            </div>
            <div class="applicants-edit-menu" @click="openBulkDeleteApplicants">
              <span>応募者を削除</span>
            </div>
          </div>
        </div>
      </template>
    </balloon-menu>
    <!-- 一括選考割当て 選考選択モーダル -->
    <modal-selection-search
      :is-visible="isVisibleModalSelectionSearch"
      announce-selections-search-title="割当てたい選考を以下の一覧よりお選びください。"
      :is-just-select="true"
      :disabled-ids="selectionDisableIds"
      @onClickCancel="isVisibleModalSelectionSearch = false"
      @onSelected="bulkUpdateSelectionSearchForward"
    />
    <!-- 一括選考割当て 選考詳細モーダル -->
    <modal-selection-detail
      :id="selectionSelectId"
      :is-visible="isVisibleModalSelectionDetail"
      ok-button-text="選考を割当て"
      back-button-text="選考の選択に戻る"
      middle-button="開催時間まで割当て"
      :is-line-friend="!isActivated"
      @onClickSubmit="bulkUpdateSelectionDetailForward"
      @onClickMiddle="bulkAssignTimetableSearchForward"
      @onClickBack="bulkUpdateSelectionDetailBack"
    />
    <!-- 一括選考割当て 確認画面モーダル -->
    <modal-selectable-applicant-with-selection
      :modal-type="'bulk-selection'"
      :is-visible="isVisibleModalBulkUpdateSelectionApplicant"
      :applicants="getSelectedApplicants"
      :init-selected-applicants="getSelectedApplicantsIds"
      :selection="selectSelection.data"
      :is-disp-line-friends="true"
      :loading-send="isLoading"
      :invisible-columns="[
        'unread_count',
        'faculty',
        'department',
        'department_category',
        'email',
        'tel',
        'channel',
        'attendanceDate',
        'invited_status',
        'created_at',
        'activated_at',
        'invited_at',
      ]"
      ok-title="この応募者に選考を割当て"
      @onChangeCurrentSelect="$emit('updateSelectedApplicants', $event)"
      @onClickAdd="bulkUpdateSelectionApplicant"
      @onClickCancel="isVisibleModalBulkUpdateSelectionApplicant = false"
      @onClickBack="bulkUpdateSelectionApplicantBack"
    />
    <!-- 一括選考割当て 開催時間割当て応募者確認モーダル -->
    <modal-bulk-assign-timetable-applicants
      :is-visible="isVisibleModalAssignTimetableApplicants"
      :applicants="getSelectedApplicants"
      :selection-id="selectSelection.data.id"
      :selected-selection-event-title="
        selectSelection.data && selectSelection.data.event
          ? selectSelection.data.event.title
          : ''
      "
      title="開催時間の割当てが可能な応募者一覧"
      @onClickCancel="isVisibleModalAssignTimetableApplicants = false"
      @onClickSettle="bulkAssignTimetableApplicantsForward"
    />
    <!-- 一括選考割当て 開催時間割当てモーダル -->
    <modal-assign-time-table
      :is-visible="isVisibleModalAssignTimetable"
      :selection="selectSelection.data"
      @onClickCancel="bulkAssignTimetableBack"
      @onClickSettle="bulkAssignTimetable"
    />

    <!-- 一括選考削除 選考選択モーダル -->
    <modal-selection-search
      :is-visible="isVisibleModalDeleteSelectionSearch"
      :is-just-select="true"
      announce-selections-search-title="削除したい選考を以下の一覧よりお選びください。"
      @onClickCancel="isVisibleModalDeleteSelectionSearch = false"
      @onSelected="bulkDeleteSelectionSearchForward"
    />
    <!-- 一括選考削除 選考詳細モーダル -->
    <modal-selection-detail
      :id="selectionSelectId"
      :is-visible="isVisibleModalDeleteSelectionDetail"
      ok-button-text="確認画面へ"
      back-button-text="選考の選択に戻る"
      @onClickSubmit="bulkDeleteSelectionDetailForward"
      @onClickBack="bulkDeleteSelectionDetailBack"
    />
    <!-- 一括選考削除 確認画面モーダル -->
    <modal-bulk-send-selection-delete
      :is-visible="isVisibleModalBulkSendSelectionDelete"
      :applicants="getSelectedApplicants"
      :selection="selectSelection.data"
      :eligible-applicants="deleteSelectionEligibleApplicants"
      @onClickCancel="isVisibleModalBulkSendSelectionDelete = false"
      @onClickSettle="bulkDeleteSelection"
    />

    <!-- 一括選考予約送信画面送信モーダル -->
    <modal-bulk-send-selection-reserve
      :is-visible="isVisibleModalBulkSendSelectionReserve"
      :applicants="getSelectedApplicants"
      :eligible-applicants="reserveSelectionEligibleApplicants"
      @onClickCancel="isVisibleModalBulkSendSelectionReserve = false"
      @onClickSettle="bulkSendReserve"
    />

    <!-- 一括評価変更モーダル -->
    <modal-bulk-update-evaluation
      ref="refModalBulkUpdateEvaluation"
      :is-visible="isVisibleModalBulkUpdateEvaluation"
      :applicants="getSelectedApplicants"
      @onClickCancel="isVisibleModalBulkUpdateEvaluation = false"
      @onClickSettle="bulkUpdateEvaluation"
    />

    <!-- 一括担当者変更モーダル -->
    <modal-bulk-update-staff
      :is-visible="isVisibleModalBulkUpdateStaff"
      :applicants="getSelectedApplicants"
      :staffs="staffs"
      @onClickCancel="isVisibleModalBulkUpdateStaff = false"
      @onClickSettle="bulkUpdateStaff"
    />
    <!-- メールのLINE切り替え案内送信 -->
    <modal-send-switch-contact-type-mail
      :is-visible="isVisibleModalSendSwitchContactTypeMail"
      :applicants="getSelectedApplicants"
      @onClickCancel="isVisibleModalSendSwitchContactTypeMail = false"
      @onClickSettle="bulkSendSwitchLineAnnounce"
    />
    <!-- メールとLINEの切り替え案内送信 -->
    <modal-send-switch-contact-type-line
      :is-visible="isVisibleModalSendSwitchContactTypeLine"
      :applicants="getSelectedApplicants"
      @onClickCancel="isVisibleModalSendSwitchContactTypeLine = false"
      @onClickSettle="bulkSendSwitchLineAnnounce"
    />

    <!-- 一括フラグ追加モーダル -->
    <modal-bulk-update-flag
      ref="refModalBulkUpdateFlag"
      :is-visible="isVisibleModalBulkUpdateFlag"
      :applicants="getSelectedApplicants"
      @onClickCancel="isVisibleModalBulkUpdateFlag = false"
      @onClickSettle="bulkUpdateFlag"
    />

    <!-- 一括応募者削除モーダル -->
    <modal-bulk-delete-applicant
      :is-visible="isVisibleModalBulkDeleteApplicant"
      :applicants="getSelectedApplicants"
      @onClickCancel="isVisibleModalBulkDeleteApplicant = false"
      @onClickSettle="bulkDeleteApplicantsWrapper"
    />
    <modal-window
      :is-visible="isVisibleModalBulkDeleteApplicantsConfirm"
      :is-visible-close-button="true"
      :data="targetBulkDeleteApplicants"
      :is-loading="isLoading"
      :is-new-design="true"
      max-width="520px"
      modal-type="danger"
      title="選択された応募者を一括削除"
      message="削除された応募者は復元できなくなりますが、本当に削除してよろしいですか？"
      caution-note="※選考に割り当てられていた場合、削除後も目標対比の集計対象に含まれます。"
      @clickOuter="isVisibleModalBulkDeleteApplicantsConfirm = false"
      @close="isVisibleModalBulkDeleteApplicantsConfirm = false"
      @click="bulkDeleteApplicants"
    />

    <!-- 一括エントリーフォーム送信モーダル -->
    <modal-bulk-send-entry-form
      :is-visible="isVisibleModalBulkSendEntryForm"
      :applicants="getSelectedApplicants"
      @onClickCancel="isVisibleModalBulkSendEntryForm = false"
      @onClickSettle="bulkSendEntryForm"
    />

    <!-- 処理が失敗した応募者表示モーダル -->
    <modal-selection-fail-applicants
      :is-visible="isVisibleModalSelectionFailApplicant"
      :description="modalSelectionFailApplicantsDescription"
      :applicant-ids="existSelectionApplicants"
      @close="onClickBackModalSelectionFailApplicants"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { postPottosEvent } from '@/utils/pottos';

import { BUTTON_CLICK_EVENT } from '@/defines/modal';

import applicantService from '@/services/applicant';
import applicantsService from '@/services/applicants';
import applicantRegistrationService from '@/services/applicant-registration';
import selectionService from '@/services/selection';
import selectionsService from '@/services/selections';
import progressesService from '@/services/progresses';
import selectionReserveService from '@/services/selection_reserve';
import reserveService from '@/services/reserves';
import BalloonMenu from '@/components/ui/menus/components/BalloonMenu';
import ModalSelectionSearch from '@/components/page/applicants/components/ModalSelectionSearch';
import ModalSelectionDetail from '@/components/page/applicants/components/ModalSelectionDetail';
import ModalAssignTimeTable from '@/components/page/applicants/components/ModalAssignTimeTable';
import ModalSelectableApplicantWithSelection from '@/components/features/modalSelectableApplicants/components/ModalSelectableApplicantWithSelection';
import ModalBulkDeleteApplicant from '@/components/page/applicants/components/ModalBulkDeleteApplicant';
import ModalSelectionFailApplicants from '@/components/page/applicants/index/components/ModalSelectionFailApplicants';
import ModalBulkUpdateStaff from '@/components/page/applicants/index/components/ModalBulkUpdateStaff';
import ModalBulkUpdateEvaluation from '@/components/page/applicants/index/components/ModalBulkUpdateEvaluation';
import ModalBulkSendSelectionReserve from '@/components/page/applicants/index/components/ModalBulkSendSelectionReserve';
import ModalBulkSendSelectionDelete from '@/components/page/applicants/index/components/ModalBulkSendSelectionDelete';
import ModalBulkSendEntryForm from '@/components/page/applicants/index/components/ModalBulkSendEntryForm';
import ModalSendSwitchContactTypeMail from '@/components/page/applicants/index/components/ModalBulkSendSwitchLineAnnounce';
import ModalSendSwitchContactTypeLine from '@/components/features/applicants/modals/SendSwitchContactTypeLine';
import ModalBulkUpdateFlag from '@/components/page/applicants/index/components/ModalBulkUpdateFlag';
import ModalBulkAssignTimetableApplicants from '@/components/page/applicants/index/components/ModalBulkAssignTimetableApplicants';

export default defineComponent({
  name: 'BulkEditApplicants',
  components: {
    BalloonMenu,
    ModalBulkUpdateStaff,
    ModalBulkUpdateEvaluation,
    ModalBulkSendSelectionReserve,
    ModalBulkSendSelectionDelete,
    ModalBulkSendEntryForm,
    ModalSendSwitchContactTypeMail,
    ModalSendSwitchContactTypeLine,
    ModalBulkUpdateFlag,
    ModalSelectionSearch,
    ModalSelectionDetail,
    ModalSelectionFailApplicants,
    ModalBulkDeleteApplicant,
    ModalBulkAssignTimetableApplicants,
    ModalAssignTimeTable,
    ModalSelectableApplicantWithSelection,
  },
  props: {
    staffs: {
      type: Array,
      required: true,
    },
    applicantsEditForm: {
      type: Boolean,
      required: true,
    },
    selectedApplicants: {
      type: Array,
      required: true,
    },
    isActivated: {
      type: Boolean,
      required: true,
    },
    isFreePlan: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    getSelectedApplicants: {
      type: Array,
      required: true,
    },
    getSelectedApplicantsIds: {
      type: Array,
      required: true,
    },
    resetState: {
      type: Function,
      required: true,
    },
    validateSelectedApplicants: {
      type: Function,
      required: true,
    },
    fetchAndMovePage1: {
      type: Function,
      required: true,
    },
    movePage: {
      type: Function,
      required: true,
    },
    switchActivatedLocal: {
      type: Function,
      required: true,
    },
  },
  emits: ['updateSelectedApplicants'],
  setup(props, context) {
    const store = useStore();
    const refModalBulkUpdateEvaluation = ref(null);
    const refModalBulkUpdateFlag = ref(null);
    const isLoading = ref(false);
    const isVisibleModalSelectionSearch = ref(false);
    const isVisibleModalSelectionDetail = ref(false);
    const isVisibleModalBulkUpdateSelectionApplicant = ref(false);
    const isVisibleModalDeleteSelectionSearch = ref(false);
    const isVisibleModalDeleteSelectionDetail = ref(false);
    const isVisibleModalBulkSendSelectionDelete = ref(false);
    const isVisibleModalBulkSendSelectionReserve = ref(false);
    const isVisibleModalBulkUpdateEvaluation = ref(false);
    const isVisibleModalBulkUpdateStaff = ref(false);
    const isVisibleModalSendSwitchContactTypeMail = ref(false);
    const isVisibleModalSendSwitchContactTypeLine = ref(false);
    const isVisibleModalBulkUpdateFlag = ref(false);
    const isVisibleModalBulkDeleteApplicant = ref(false);
    const isVisibleModalBulkDeleteApplicantsConfirm = ref(false);
    const isVisibleModalBulkSendEntryForm = ref(false);
    const isVisibleModalAssignTimetableApplicants = ref(false);
    const isVisibleModalAssignTimetable = ref(false);
    const isVisibleModalSelectionFailApplicant = ref(false);
    const selectionSelectId = ref(null);
    const modalSelectionFailApplicantsDescription = ref('');
    const reserveSelectionEligibleApplicants = ref([]);
    const deleteSelectionEligibleApplicants = ref([]);
    const selectionDisableIds = ref([]);
    const existSelectionApplicants = ref([]);
    const targetBulkDeleteApplicants = ref([]);
    const bulkAssignTimetableApplicantIds = ref([]);
    const selectSelection = reactive({ data: {} });

    const enabledLineNotification =
      store.getters['company/company'].is_line_cooperate_user;

    // method
    // 一括選考割当て 選考選択モーダル開く
    const openBulkUpdateSelection = async () => {
      if (props.validateSelectedApplicants() === true) {
        if (isLoading.value === true) return;
        isLoading.value = true;
        const res = await selectionService.checkDisable();
        if (res.success === true) {
          selectionDisableIds.value = res.selections;
          isVisibleModalSelectionSearch.value = true;
          context.emit('close');
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.messages,
            type: res.success,
          });
        }
        isLoading.value = false;
      }
    };
    // 一括選考割当て 選考詳細モーダル開く
    const bulkUpdateSelectionSearchForward = async selection => {
      selectionSelectId.value = selection.id;
      isVisibleModalSelectionSearch.value = false;
      isVisibleModalSelectionDetail.value = true;
    };
    // 一括選考割当て 確認モーダルを開く
    const bulkUpdateSelectionDetailForward = async selection => {
      selectSelection.data = selection;
      isVisibleModalSelectionDetail.value = false;
      isVisibleModalBulkUpdateSelectionApplicant.value = true;
    };
    // 一括選考割当て 選考詳細モーダルを閉じる
    const bulkUpdateSelectionDetailBack = boolean => {
      isVisibleModalSelectionSearch.value = !boolean;
      isVisibleModalSelectionDetail.value = false;
    };
    // 一括選考割当て 選考詳細モーダルに戻る
    const bulkUpdateSelectionApplicantBack = () => {
      isVisibleModalBulkUpdateSelectionApplicant.value = false;
      isVisibleModalSelectionDetail.value = true;
    };
    // 一括選考割当てイベント
    const bulkUpdateSelectionApplicant = async payloadSelectedApplicants => {
      isLoading.value = true;
      postPottosEvent(61);
      const res = await progressesService.createProgress(
        selectSelection.data.id,
        payloadSelectedApplicants,
        true,
      );
      if (res.success === true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        isVisibleModalBulkUpdateSelectionApplicant.value = false;
        // 既に選考割当て済みだった応募者が存在すれば表示
        existSelectionApplicants.value = res.already_assigned_applicant_ids;
        if (existSelectionApplicants.value.length > 0) {
          modalSelectionFailApplicantsDescription.value =
            '以下の応募者は、既に同一の選考が割当てられていたため選考を登録できませんでした。';
          isVisibleModalSelectionFailApplicant.value = true;
        } else {
          await props.fetchAndMovePage1();
        }
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
      isLoading.value = false;
    };

    // 一括開催時間割当て 割当て応募者確認モーダルを開く
    const bulkAssignTimetableSearchForward = async selection => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const selectionWithTimetables =
        await selectionsService.fetchSelectionSortDate(selection.id, 1);
      // 当日より過去の日付の開催時間をフィルタリング
      selectionWithTimetables.venues = selectionWithTimetables.venues.filter(
        v => moment(v.date).isAfter(moment().add(-1, 'd')),
      );
      selectSelection.data = selectionWithTimetables;
      isVisibleModalAssignTimetableApplicants.value = true;
      isLoading.value = false;
    };
    // 一括開催時間割当て 開催時間割当てモーダルを開く
    const bulkAssignTimetableApplicantsForward = applicantIds => {
      isVisibleModalAssignTimetableApplicants.value = false;
      isVisibleModalAssignTimetable.value = true;
      // リクエスト用応募者を保持
      bulkAssignTimetableApplicantIds.value = applicantIds;
    };
    // 一括開催時間割当て 実行
    const bulkAssignTimetable = async payload => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const res = await reserveService.bulkReserveWithAssignProgress({
        selectionId: selectSelection.data.id,
        timetableId: payload.timetable_id,
        applicantIds: bulkAssignTimetableApplicantIds.value,
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        isVisibleModalAssignTimetable.value = false;
        existSelectionApplicants.value = res.failed_applicant_ids
          ? res.failed_applicant_ids
          : [];
        if (existSelectionApplicants.value.length > 0) {
          // 割当て失敗応募者が存在すればモーダルで表示
          isVisibleModalSelectionFailApplicant.value = true;
          modalSelectionFailApplicantsDescription.value =
            '以下の応募者は、参加上限人数を超えている、選考が削除されている、応募者の情報が変更されたため割当てできませんでした。';
        } else {
          // リクエスト応募者をリセット
          bulkAssignTimetableApplicantIds.value = [];
          await props.fetchAndMovePage1();
        }
      }
      isLoading.value = false;
    };
    // 一括開催時間割当て 割当て応募者モーダルに戻る
    const bulkAssignTimetableBack = () => {
      isVisibleModalAssignTimetable.value = false;
      isVisibleModalAssignTimetableApplicants.value = true;
    };
    // 処理が失敗した応募者表示モーダルから戻る
    const onClickBackModalSelectionFailApplicants = async () => {
      isVisibleModalSelectionFailApplicant.value = false;
      existSelectionApplicants.value = [];
      modalSelectionFailApplicantsDescription.value = '';
      await props.fetchAndMovePage1();
    };
    // 選考一括削除 選考選択モーダル開く
    const openBulkDeleteSelection = async () => {
      if (props.validateSelectedApplicants() === true) {
        const res = await selectionService.checkDisable();
        if (res.success === true) {
          selectionDisableIds.value = res.selections;
          isVisibleModalDeleteSelectionSearch.value = true;
          context.emit('close');
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.messages,
            type: res.success,
          });
        }
      }
    };
    // 選考一括削除 選考詳細モーダルを開く
    const bulkDeleteSelectionSearchForward = selection => {
      selectSelection.data = selection;
      selectionSelectId.value = selection.id;
      isVisibleModalDeleteSelectionSearch.value = false;
      isVisibleModalDeleteSelectionDetail.value = true;
    };
    // 選考一括削除 選考詳細モーダルを閉じる
    const bulkDeleteSelectionDetailBack = () => {
      isVisibleModalDeleteSelectionSearch.value = true;
      isVisibleModalDeleteSelectionDetail.value = false;
    };
    // 一括選考削除確認画面モーダルを開く
    const bulkDeleteSelectionDetailForward = async () => {
      if (props.selectedApplicants.length > 0) {
        const res = await applicantsService.fetchDeletableApplicantSelection({
          applicant_ids: props.selectedApplicants,
          selection_id: selectionSelectId.value,
        });
        deleteSelectionEligibleApplicants.value = res.applicant_ids
          ? res.applicant_ids
          : [];
        isVisibleModalBulkSendSelectionDelete.value = true;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者を選択してください。',
          type: false,
        });
      }
    };
    // 一括選考削除イベント
    const bulkDeleteSelection = async () => {
      if (deleteSelectionEligibleApplicants.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '選考削除できる応募者がいないため送信できませんでした。',
          type: false,
        });
      } else {
        const res = await applicantsService.deleteProgresses({
          applicant_ids: deleteSelectionEligibleApplicants.value,
          selection_id: selectionSelectId.value,
        });
        if (res.success === true) {
          isVisibleModalBulkSendSelectionDelete.value = false;
          store.dispatch('modalApplicants/VISIBLE_MODAL_APPLICANTS', {
            title: res.message,
            applicantIds: deleteSelectionEligibleApplicants.value,
          });
          await props.fetchAndMovePage1();
          isVisibleModalDeleteSelectionDetail.value = false;
          isVisibleModalBulkSendSelectionDelete.value = false;
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
        }
      }
    };

    // 一括選考予約画面送信モーダル開く
    const openBulkSendReserveSelection = async () => {
      if (props.validateSelectedApplicants() === true) {
        const res = await selectionReserveService.checkReserveSelection({
          applicant_ids: props.selectedApplicants,
        });
        reserveSelectionEligibleApplicants.value = res.applicant_ids;
        isVisibleModalBulkSendSelectionReserve.value = true;
        context.emit('close');
      }
    };
    // 一括選考予約画面送信イベント
    const bulkSendReserve = async ({ applicant_ids }) => {
      if (applicant_ids.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '選考予約できる応募者がいないため送信できませんでした。',
          type: false,
        });
      } else {
        const res = await selectionReserveService.sendReserveSelection({
          applicant_ids,
        });
        if (res.success === true) {
          isVisibleModalBulkSendSelectionReserve.value = false;
          store.dispatch('modalApplicants/VISIBLE_MODAL_APPLICANTS', {
            title: '選考予約通知を送信しました。',
            applicantIds: applicant_ids,
          });
          await props.fetchAndMovePage1();
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
        }
      }
    };

    // 一括評価変更 送信モーダルを開く
    const openBulkUpdateFinProgress = () => {
      if (props.validateSelectedApplicants() === true) {
        isVisibleModalBulkUpdateEvaluation.value = true;
        context.emit('close');
      }
    };
    // 一括評価変更イベント
    const bulkUpdateEvaluation = async payload => {
      let res = { success: false };
      if (payload.isManager) {
        res = await applicantService.bulkUpdateProgressesStatus(
          payload.applicantIds,
          payload.comment,
          payload.statusName,
        );
        if (res.success === true) {
          store.dispatch('modalApplicants/VISIBLE_MODAL_APPLICANTS', {
            title: '応募者の人事評価を更新しました。',
            applicantIds: payload.applicantIds,
          });
        } else if (res.message) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
        }
      } else if (payload.isOperator) {
        res = await applicantService.bulkUpdateProgressesComment(
          payload.applicantIds,
          payload.comment,
        );
        if (res.success === true) {
          store.dispatch('modalApplicants/VISIBLE_MODAL_APPLICANTS', {
            title: '応募者の担当者コメントを更新しました。',
            applicantIds: payload.applicantIds,
          });
        } else if (res.message) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
        }
      }
      if (res.success === true) {
        isVisibleModalBulkUpdateEvaluation.value = false;
        refModalBulkUpdateEvaluation.value.resetInit();
        await props.fetchAndMovePage1();
      }
    };

    // 一括担当者変更モーダルを開く
    const openBulkUpdateMenu = () => {
      if (props.validateSelectedApplicants() === true) {
        isVisibleModalBulkUpdateStaff.value = true;
        context.emit('close');
      }
    };
    // 一括担当者変更イベント
    const bulkUpdateStaff = async payload => {
      const res = await applicantService.bulkUpdateApplicantStaff(
        props.selectedApplicants,
        payload.staffId,
      );
      if (res.success === true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: true,
        });
        isVisibleModalBulkUpdateStaff.value = false;
        await props.fetchAndMovePage1();
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '担当者の変更に失敗しました。',
          type: false,
        });
      }
    };

    // 切り替え案内送信モーダルを開く
    const openBulkUpdateSendSwitchLineAnnounce = ({ type }) => {
      if (!props.selectedApplicants.length) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者を選択してください。',
          type: false,
        });
        return;
      }
      type === 'mail'
        ? (isVisibleModalSendSwitchContactTypeMail.value = true)
        : (isVisibleModalSendSwitchContactTypeLine.value = true);
    };
    // 一括LINE切り替え案内送信イベント
    const bulkSendSwitchLineAnnounce = async payload => {
      const currentModal = isVisibleModalSendSwitchContactTypeMail.value
        ? isVisibleModalSendSwitchContactTypeMail
        : isVisibleModalSendSwitchContactTypeLine;
      currentModal.value = false;

      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        title: '選択された応募者にLINE切り替え案内を送信',
        message: '選択された応募者に切り替え案内を送信します。よろしいですか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async onClickedPayload => {
          if (onClickedPayload.choice === BUTTON_CLICK_EVENT.cancel) {
            await store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            currentModal.value = true;
            return;
          }
          const res = await applicantRegistrationService.sendSwitchLineAnnounce(
            {
              invite: { applicant_ids: payload },
            },
          );
          await props.movePage(props.page);
          await props.resetState();
          await store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          await store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.success
              ? 'LINE切り替え案内を送信しました'
              : 'LINE切り替え案内の送信に失敗しました',
            type: res.success,
          });
        },
        onclickOuter: async () => {
          await store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          currentModal.value = true;
        },
        isDisabledShowModalDisplayed: true,
      });
    };

    // 一括フラグ追加モーダルを開く
    const openBulkUpdateFlag = () => {
      if (props.selectedApplicants.length > 0) {
        isVisibleModalBulkUpdateFlag.value = true;
        context.emit('close');
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者を選択してください。',
          type: false,
        });
      }
    };
    // 一括フラグ追加イベント
    const bulkUpdateFlag = async ({ applicantIds, tagIds }) => {
      isVisibleModalBulkUpdateFlag.value = false;
      postPottosEvent(63);
      const res = await applicantsService.createBulkApplicantTags(
        applicantIds,
        tagIds,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        refModalBulkUpdateFlag.value.resetAll();
        await props.fetchAndMovePage1(true); // flagReload: trueにする
      }
    };

    // 一括応募者削除モーダルを開く
    const openBulkDeleteApplicants = async () => {
      if (props.validateSelectedApplicants() === true) {
        const res = await selectionService.checkDisable();
        if (res.success === true) {
          selectionDisableIds.value = res.selections;
          isVisibleModalBulkDeleteApplicant.value = true;
          context.emit('close');
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.messages,
            type: res.success,
          });
        }
      }
    };
    // 一括応募者削除イベント
    const bulkDeleteApplicantsWrapper = async ({ applicants }) => {
      targetBulkDeleteApplicants.value = await applicants;
      isVisibleModalBulkDeleteApplicant.value = false;
      isVisibleModalBulkDeleteApplicantsConfirm.value = true;
    };
    const bulkDeleteApplicants = async ({ selected }) => {
      if (selected === 'cancel') {
        isVisibleModalBulkDeleteApplicantsConfirm.value = false;
        isVisibleModalBulkDeleteApplicant.value = true;
      }
      if (selected === 'submit') {
        isLoading.value = true;
        const deleteApplicantIds = targetBulkDeleteApplicants.value.map(
          x => x.id,
        );
        const res =
          await applicantService.bulkDeleteApplicants(deleteApplicantIds);
        isVisibleModalBulkDeleteApplicantsConfirm.value = false;
        if (res.success === true) {
          await props.switchActivatedLocal(true);
          await props.fetchAndMovePage1();
        }
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        isLoading.value = false;
      }
      targetBulkDeleteApplicants.value = [];
    };

    // 応募者にエントリーフォームを送信
    const openSendEntryForm = () => {
      if (props.validateSelectedApplicants() === true) {
        isVisibleModalBulkSendEntryForm.value = true;
        context.emit('close');
      }
    };
    // 一括エントリーフォーム送信イベント
    const bulkSendEntryForm = async payload => {
      isVisibleModalBulkSendEntryForm.value = false;
      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        title: '選択された応募者にエントリーフォームを送信',
        message: '応募者にエントリーフォームを送信します。よろしいですか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async onClickedPayload => {
          if (onClickedPayload.choice === 2) {
            const res = await applicantRegistrationService.sendEntryFormToLine({
              invite: { applicant_ids: payload },
            });
            if (res.success === true) {
              await props.movePage(props.page);
              await props.resetState();
              isVisibleModalBulkSendEntryForm.value = false;
            } else {
              isVisibleModalBulkSendEntryForm.value = true;
            }
            await store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            await store.dispatch('notification/VISIBLE_NOTIFICATION', {
              message: res.message,
              type: res.success,
            });
          } else {
            await store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            isVisibleModalBulkSendEntryForm.value = true;
          }
        },
        onclickOuter: async () => {
          await store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          isVisibleModalBulkSendEntryForm.value = true;
        },
        isDisabledShowModalDisplayed: true,
      });
    };

    return {
      refModalBulkUpdateEvaluation,
      refModalBulkUpdateFlag,
      isLoading,
      isVisibleModalSelectionSearch,
      isVisibleModalSelectionDetail,
      isVisibleModalBulkUpdateSelectionApplicant,
      isVisibleModalDeleteSelectionSearch,
      isVisibleModalDeleteSelectionDetail,
      isVisibleModalBulkSendSelectionDelete,
      isVisibleModalBulkSendSelectionReserve,
      isVisibleModalBulkUpdateEvaluation,
      isVisibleModalBulkUpdateStaff,
      isVisibleModalSendSwitchContactTypeMail,
      isVisibleModalSendSwitchContactTypeLine,
      isVisibleModalBulkUpdateFlag,
      isVisibleModalBulkDeleteApplicant,
      isVisibleModalBulkDeleteApplicantsConfirm,
      isVisibleModalBulkSendEntryForm,
      isVisibleModalAssignTimetableApplicants,
      isVisibleModalAssignTimetable,
      isVisibleModalSelectionFailApplicant,
      selectionSelectId,
      modalSelectionFailApplicantsDescription,
      reserveSelectionEligibleApplicants,
      deleteSelectionEligibleApplicants,
      selectionDisableIds,
      existSelectionApplicants,
      selectSelection,
      enabledLineNotification,
      // モーダル開始処理
      openBulkUpdateSelection,
      openBulkDeleteSelection,
      openBulkUpdateFinProgress,
      openBulkSendReserveSelection,
      openBulkUpdateMenu,
      openSendEntryForm,
      openBulkUpdateSendSwitchLineAnnounce,
      openBulkUpdateFlag,
      openBulkDeleteApplicants,
      // 一括選考割当てモーダル処理
      bulkUpdateSelectionSearchForward,
      bulkUpdateSelectionDetailForward,
      bulkUpdateSelectionDetailBack,
      bulkUpdateSelectionApplicant,
      bulkUpdateSelectionApplicantBack,
      // 一括選考削除処理
      bulkDeleteSelectionSearchForward,
      bulkDeleteSelectionDetailForward,
      bulkDeleteSelectionDetailBack,
      bulkDeleteSelection,
      // 一括選考予約処理
      bulkSendReserve,
      // 一括評価変更処理
      bulkUpdateEvaluation,
      // 一括担当者変更処理
      bulkUpdateStaff,
      // 一括LINE切り替え案内処理
      bulkSendSwitchLineAnnounce,
      // 一括フラグ追加処理
      bulkUpdateFlag,
      // 一括応募者削除処理
      bulkDeleteApplicantsWrapper,
      bulkDeleteApplicants,
      // 一括エントリーフォーム送信処理
      bulkSendEntryForm,
      // 一括開催時間割当て処理
      bulkAssignTimetableSearchForward,
      bulkAssignTimetableApplicantsForward,
      bulkAssignTimetable,
      bulkAssignTimetableBack,
      // 処理が失敗した応募者表示モーダル処理
      onClickBackModalSelectionFailApplicants,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.applicants-edit-menu {
  padding: 15px 10px;
  border-bottom: dashed 1px #eee;
  cursor: pointer;

  &.disabled {
    opacity: 0.1;
    pointer-events: none;
    cursor: auto;
  }
}

.applicants-edit-category {
  padding: 16px 10px 8px 10px;
  border-bottom: dashed 1px #eee;
  background: $mochica_light_gray_bg;
  color: #777;
  font-size: $font_12;
}

.applicants-edit-menu:last-child {
  border-bottom: none;
}

// カテゴリ表示の背景色と合わせるため
:deep .balloon-box:after {
  border-color: transparent transparent $mochica_light_gray_bg transparent;
}
</style>
