<template>
  <div class="wrapper">
    <div v-if="isEditing === true" class="edit-mode">
      <input v-model="inputText" maxlength="128" type="text" />
      <div class="buttons-wrapper">
        <button-base class="btn-flag" button-text="確定" @click="saveItem" />
        <button-base
          class="btn-flag"
          button-text="削除"
          button-type="secondary"
          @click="deleteItem"
        />
      </div>
    </div>
    <div
      v-else
      :class="['display-mode', isGroup === true ? 'flag-group' : 'flag']"
    >
      <div>{{ text }}</div>
      <button-base
        class="btn-flag"
        button-text="編集"
        @click="activateEditMode"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'FlagItemUpdateInput',
  props: {
    tagId: {
      type: Number,
      default: null,
    },
    tagGroupId: {
      type: Number,
      default: null,
    },
    text: {
      type: String,
      default: '',
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['commit', 'delete'],

  setup(props, context) {
    const store = useStore();
    const isEditing = ref(false);
    const inputText = ref('');

    const activateEditMode = () => {
      isEditing.value = true;
      inputText.value = props.text;
    };

    const saveItem = () => {
      isEditing.value = false;
      if (inputText.value === '') {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '空欄での登録はできません',
          type: false,
        });
        return;
      }
      const payload = { tagGroupId: props.tagGroupId, text: inputText.value };
      if (props.isGroup === false) {
        payload.tagId = props.tagId;
      }
      context.emit('commit', payload);
    };

    const deleteItem = () => {
      isEditing.value = false;
      const payload = { tagGroupId: props.tagGroupId, text: inputText.value };
      if (props.isGroup === false) {
        payload.tagId = props.tagId;
      }
      context.emit('delete', payload);
    };

    return {
      isEditing,
      inputText,
      activateEditMode,
      saveItem,
      deleteItem,
    };
  },
});
</script>

<style scoped="" lang="scss">
@import '@/assets/variables.scss';

.wrapper {
  button {
    display: block;
    text-align: center;
    font-size: 1.1rem;
    padding: 6px 10px;
    min-width: 47px;
  }
}

.display-mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
  margin-left: 10px;

  button {
    margin-left: 10px;
  }
}

.edit-mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      display: block;
      margin-left: 0.6rem;
      text-align: center;
      font-size: 1.1rem;
    }
  }

  input[type='text'] {
    @media (min-width: ($media_query_tablet)) {
      width: 100%;
    }
    padding: 0.3rem;
    font-size: 0.9em;
    background: $white;
  }
}

button.btn-base.btn-flag {
  padding: 6px 5px;
  font-size: 1.1rem;
}
</style>
