<template>
  <transition name="modal">
    <div v-if="isView" class="overlay" @click="outerClick">
      <div class="modal-base">
        <div class="modal-content" :style="styles">
          <button
            v-if="isViewCloseButton"
            type="button"
            class="close-button"
            @click="closeModal"
          >
            <img src="@/assets/img/close_white.svg" />
          </button>
          <div
            class="modal-header"
            :class="{ 'has-header-button': hasHeaderButton }"
          >
            <p class="title">{{ title }}</p>
            <slot v-if="hasHeaderButton" name="headerButton" />
          </div>
          <div class="modal-main"><slot name="main" /></div>
          <div class="modal-footer"><slot name="footer" /></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, watch } from 'vue';

export default defineComponent({
  name: 'ModalBase',
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    maxWidthValue: {
      type: String,
      default: '',
    },
    borderRadiusValue: {
      type: String,
      default: '6px',
    },
    isClosableClickOutside: {
      type: Boolean,
      default: true,
    },
    isViewCloseButton: {
      type: Boolean,
      default: true,
    },
    hasHeaderButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeModal'],
  setup(props, context) {
    const styles = { borderRadius: props.borderRadiusValue };
    if (props.maxWidthValue !== '') styles.maxWidth = props.maxWidthValue;
    let storedScrollTop = 0;

    // モーダルの外側でスクロールを無効・スクロール現在値の保持
    watch(
      () => props.isView,
      async val => {
        const bodyEl = document.querySelector('body');
        if (val) {
          storedScrollTop = window.pageYOffset;
          bodyEl.classList.add('-noscroll');
          bodyEl.style.top = `-${storedScrollTop}px`;
        } else {
          bodyEl.classList.remove('-noscroll');
          bodyEl.style.removeProperty('top');
          window.scrollTo(0, storedScrollTop);
        }
      },
    );

    const closeModal = () => {
      context.emit('closeModal', true);
    };

    const outerClick = () => {
      if (props.isClosableClickOutside) closeModal();
    };

    return { styles, closeModal, outerClick };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.overlay {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(112, 112, 112, 0.95);
  transition: opacity 0.3s ease;
}

.modal-base {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: opacity 0.3s ease;
  margin: 0 60px;
  @media (max-width: ($media_query_sp)) {
    margin: 0 20px;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 120px);
    width: 100%;
    background-color: $white;
    box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
    outline: 0;
    line-height: 1.5;
    @media (max-width: ($media_query_sp)) {
      max-height: calc(100vh - 80px);
    }
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px 20px;
      border-bottom: 1px solid $mochica_color;
      color: #1899d6;
      @media (max-width: ($media_query_sp)) {
        padding: 20px;
      }
      .title {
        font-size: 1.4rem;
        font-weight: bold;
      }
      &.has-header-button {
        justify-content: space-between;
        @media (max-width: ($media_query_sp)) {
          justify-content: center;
          max-height: calc(100vh - 80px);
          flex-wrap: wrap;
        }
        .title {
          font-size: 1.4rem;
          font-weight: bold;
          @media (min-width: ($media_query_sp)) {
            padding-right: 30px;
          }
        }
      }
    }
    .modal-main {
      overflow-y: auto;
      padding: 20px;
      @media (max-width: ($media_query_sp)) {
        padding: 20px;
      }
    }
    .modal-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between;
      padding: 0 20px 20px 20px;
      @media (max-width: ($media_query_sp)) {
        padding: 0 20px 20px 20px;
      }
    }
  }
}
.close-button {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -40px;
  right: 0;
  cursor: pointer;
  @media (max-width: ($media_query_sp)) {
    margin-top: -30px;
  }
}
// モーダルのフェードイン・フェードアウトのアニメーション用
.modal-enter-from {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
</style>
