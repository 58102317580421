<script setup>
import { ref } from 'vue';

import FlagSurveyDetailModal from '@/components/features/flagSurvey/FlagSurveyDetailModal.vue';

const FLAG_SURVEY_MODE = Object.freeze({
  REGIST: Symbol('flagSurveyRegist'),
  EDIT: Symbol('flagSurveyEdit'),
  DETAIL: Symbol('flagSurveyDetail'),
});

defineProps({
  flagSurvey: {
    type: Object,
    default: () => ({
      title: '',
    }),
  },
});

const isViewFlagSurveyDetailModal = ref(false);
</script>

<template>
  <div class="action-inner">
    <p class="selected-action">アンケートを通知</p>
    <div class="action-contents auto-action">
      <div class="gray-box">
        <div class="gray-box-inner">
          <ul class="items">
            <li class="item">
              <div class="title">選択したアンケート</div>
              <div class="contents">
                <div class="side-contents">
                  <div class="side-contents-name">{{ flagSurvey.title }}</div>
                  <div class="side-contents-check">
                    <button-base
                      type="button"
                      :button-text="'確認'"
                      @click="isViewFlagSurveyDetailModal = true"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <flag-survey-detail-modal
      :is-view="isViewFlagSurveyDetailModal"
      :flag-survey-id="flagSurvey.id"
      :flag-survey-detail-mode="FLAG_SURVEY_MODE.DETAIL"
      :flag-survey-mode="FLAG_SURVEY_MODE"
      @close-modal="isViewFlagSurveyDetailModal = false"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
.gray-box {
  padding: 20px 20px 16px;
}
</style>
