<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">テンプレート</p>
        </div>

        <transition name="fade">
          <div class="body">
            <div class="body-inner">
              <section>
                <div class="body_description">
                  青文字をコピーして本文中の任意の位置に挿入することで、テンプレート内で応募者名や会社名を利用することができます。
                  <div class="body_minor_description">
                    ※それぞれ青文字の部分はメール送信時に変換されます。
                  </div>
                  <span class="body_gray_area">
                    <span class="tag_blue">[company_name]</span>
                    （会社名）
                    <button-clipboard-copy
                      text="[company_name]"
                      :with-icon="true"
                    />
                  </span>
                  <span class="body_gray_area">
                    <span class="tag_blue">[applicant_name]</span>
                    （応募者名）
                    <button-clipboard-copy
                      text="[applicant_name]"
                      :with-icon="true"
                    />
                  </span>
                </div>
                <p class="body_description">
                  ※入力欄に既に文字が入力されている場合、最後尾にテンプレートが追加されます。
                </p>
                <div class="fixphrase_form">
                  <dl>
                    <dt>
                      <label>テンプレート名</label>
                    </dt>
                    <dd>
                      <input
                        v-model="fixedPhrase.name"
                        maxlength="30"
                        type="text"
                        name="fixphrase_name"
                        :readonly="!isEdit && !isCreate"
                      />
                    </dd>
                  </dl>
                  <input-line-and-email
                    v-if="
                      isCreate ||
                      isEdit ||
                      (fixedPhrase.line_content &&
                        fixedPhrase.line_content.length > 0)
                    "
                    :line-content="lineContent"
                    :email-title="emailTitle"
                    :email-content="emailContent"
                    :is-template="true"
                    @updateLineContent="updateLineContent"
                    @updateEmailTitle="updateEmailTitle"
                    @updateEmailContent="updateEmailContent"
                  />
                </div>
              </section>
            </div>
          </div>
        </transition>

        <div class="btn-col2">
          <button
            class="btn btn-unavailable"
            @click="onClickCloseModalSelectPhrase"
          >
            キャンセル
          </button>
          <button-base
            v-if="isCreate"
            class="btn submit-button"
            button-text="テンプレートを作成"
            :is-loading="isLoading"
            @click="onClickSubmitModalSelectPhrase"
          />
          <button-base
            v-else-if="isEdit"
            class="btn submit-button"
            button-text="テンプレートを更新"
            :is-loading="isLoading"
            @click="onClickSubmitModalSelectPhrase"
          />
          <button
            v-else
            class="btn btn-availability"
            @click="onClickSubmitModalSelectPhrase"
          >
            テンプレートを挿入
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref, onMounted, watch, nextTick } from 'vue';
import cloneDeep from 'lodash.clonedeep';
import { onResizeModal } from '@/utils/modal-resize';
import Modal from '@/components/ui/modals/components/Modal';
import ButtonClipboardCopy from '@/components/ui/buttons/components/ButtonClipboardCopy';
import InputLineAndEmail from '@/components/features/inputLineAndEmail/components/InputLineAndEmail';

export default defineComponent({
  name: 'ModalSelectFixedPhrase',
  components: { Modal, ButtonClipboardCopy, InputLineAndEmail },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    fixedPhrase: {
      type: Object,
      default: () => {},
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onClickCloseModalSelectPhrase',
    'onClickSubmitModalSelectPhrase',
    'updateLineContent',
    'updateEmailTitle',
    'updateEmailContent',
  ],
  setup(props, context) {
    const lineContent = ref('');
    const emailTitle = ref('');
    const emailContent = ref('');
    if (props.isEdit && props.isView) {
      lineContent.value = props.fixedPhrase.line_content;
      emailTitle.value = props.fixedPhrase.mail_title;
      emailContent.value = props.fixedPhrase.mail_content;
    }

    watch(
      () => props.isView,
      val => {
        updateLineContent(props.fixedPhrase.line_content);
        updateEmailTitle(props.fixedPhrase.mail_title);
        updateEmailContent(props.fixedPhrase.mail_content);
        if (val === true) {
          setTimeout(() => localResizeModal(), 200);
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );

    const onClickSubmitModalSelectPhrase = async () => {
      const args = cloneDeep(props.fixedPhrase);
      args.line_content = lineContent.value;
      args.mail_title = emailTitle.value;
      args.mail_content = emailContent.value;
      context.emit('onClickSubmitModalSelectPhrase', args);
    };
    const onClickCloseModalSelectPhrase = () => {
      context.emit('onClickCloseModalSelectPhrase');
    };
    const updateLineContent = val => {
      lineContent.value = val;
    };
    const updateEmailTitle = val => {
      emailTitle.value = val;
    };
    const updateEmailContent = val => {
      emailContent.value = val;
    };
    const localResizeModal = () =>
      onResizeModal(props.isView, { shouldResizeModalWidth: false });

    onMounted(() => {
      nextTick(() => localResizeModal());
    });

    return {
      lineContent,
      emailTitle,
      emailContent,
      updateLineContent,
      updateEmailTitle,
      updateEmailContent,
      onClickSubmitModalSelectPhrase,
      onClickCloseModalSelectPhrase,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.content {
  max-width: 1100px !important;
  width: calc(100% - 20px);
  max-width: 800px;
  border-radius: 1rem;
}

.header {
  padding: 2rem;
  border-bottom: solid 1px;
  color: #1899d6;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  .title {
    font-size: 1.4rem;
  }
}

.body {
  font-size: 1.2rem;
  padding: 2rem !important;

  .body-inner {
    .body_description {
      margin-bottom: 2rem;
      .tag_blue {
        color: #1899d6;
        font-weight: bold;
      }
    }
  }
  .body_minor_description {
    font-size: 1rem;
    color: #777777;
    letter-spacing: 0.05em;
    margin: 1rem 0 2rem;
  }
  .body_gray_area {
    display: inline-block;
    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
    margin-right: 0.6rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: #fafafa;
  }
  section {
    text-align: left;
  }
}

.btn-col2 {
  .submit-button {
    padding: 15px 10px;
  }
}

.survey_conected {
  p {
    text-align: right;
  }
}

.fixphrase_form {
  dl {
    dt {
      margin-bottom: 1rem;
      label {
        font-weight: bold;
      }
    }
    dd {
      height: 5.8rem;
      margin-bottom: 1rem;
      input[type='text'] {
        width: 100%;
        border: solid 1px #808080;
        border-radius: 2px;
        padding: 0.8rem;
        font-size: 1.4rem;
        transition: 0.3s ease;
        border-radius: 0.3rem;
        height: 3em;
      }
    }
  }
}

.area_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  .item {
    width: 49.5%;
    height: 3.6rem;
    line-height: 3.6rem;
    border: 1px solid #027db7;
    background: #1899d6;
    border-radius: 0.3rem;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    &.back_btn {
      background: #fff;
      border: 1px solid $border_color;
      color: #333;
      font-weight: normal;
    }
    &.disabled {
      background-color: #ccc;
      color: #333;
      font-weight: 100;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .content .body {
    padding: 20px 20px 0;
  }
  .btn-col2 {
    padding: 20px 10px 10px;
  }
  .body_gray_area:last-child {
    margin-top: 1.5rem;
  }
}
</style>
