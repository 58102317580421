<template>
  <modal :is-view="isView" @close="close">
    <template #rightTop>
      <div class="right-top" @click="close">
        <img slot="icon" class="icon" src="@/assets/img/close_white.svg" alt />
      </div>
    </template>

    <template #content>
      <div>
        <search-applicant-preset
          v-if="pageMode && pageMode !== 'searchFilter'"
          :page-mode="pageMode"
          :edit-preset-id="editPresetId"
          :edit-preset-name="editPresetName"
          :edit-preset-search-filter-object="editPresetSearchFilterObject.data"
          :staffs="staffs"
          :events="events"
          :tag-groups="tagGroups"
          :survey-sheet-id="surveySheetId"
          :surveys-titles="surveysProps"
          :search-flag-groups="searchFlagGroups"
          :search-flag-group-options="searchFlagGroupOptions"
          :search-surveys="searchSurveys"
          :search-survey-texts="searchSurveyTexts"
          :parse-to-search-filter-object="parseToSearchFilterObject"
          @openPresetEditModal="openPresetEditModalLocal"
          @openPresetDeleteDialogModal="openPresetDeleteDialogModal"
          @openPresetEditDialogModal="openPresetEditDialogModalLocal"
          @openPresetListModal="openPresetListModal"
          @openSearchFilter="openSearchFilter"
          @loadPreset="loadPreset"
          @close="openSearchFilter"
          @switchSearchFilter="switchSearchFilterLocal"
          @fetchSearchFilterPresets="$emit('fetchSearchFilterPresets', $event)"
        />
        <div v-else class="modal-window -typeB">
          <div class="header">
            <p class="title">応募者の絞り込み検索</p>
            <button class="btn-searchSwitch" @click="searchFormDetailSwitch">
              <img
                slot="icon"
                class="icon"
                src="@/assets/img/arrow_wind_double_white.svg"
                alt
              />
              詳細検索に切り替え
            </button>
          </div>

          <div class="body">
            <div class="search_filter_controllers">
              <button class="btn btn-unavailable" @click="reset">
                検索条件をクリア
              </button>
              <button
                v-if="hasPreset === true"
                class="btn btn-availability"
                @click="openPresetListModal"
              >
                プリセット一覧
              </button>
            </div>
            <search-applicants-filters
              ref="refFormFilters"
              :search-filters="searchFilters"
              :display-activated-at="displayActivatedAt"
              :events="events"
              :staffs="staffs"
              :set-search-filters="setSearchFilters"
              :set-progress-status-ids="setProgressStatusIds"
            />
            <search-flag-group
              v-if="flagMode"
              class="ar-flagComponent"
              :search-flag-groups="searchFlagGroups"
              :visible-add-flags="visibleAddFlags"
              :visible-flag-group-ids="visibleFlagGroupIds"
              :visible-flag-ids="visibleFlagIds"
              :visible-flag-group-options="visibleFlagGroupOptions"
              :selectable-search-flag-group-options-default="
                selectableSearchFlagGroupOptionsDefault
              "
              :get-flag-groups="getFlagGroups"
              :get-flags="getFlags"
              :get-flag-name-by-id="getFlagNameById"
              :update-flag-group-id="updateFlagGroupId"
              :update-flag-id="updateFlagId"
              :add-flag-group-row="addFlagGroupRow"
              :remove-flag-group-row="removeFlagGroupRow"
              :open-drop-down-flag-group-ids="openDropDownFlagGroupIds"
              :open-drop-down-flag-id="openDropDownFlagId"
              :open-drop-down-flag-group-option="openDropDownFlagGroupOption"
              :close-all-flag-groups-drop-down="closeAllFlagGroupsDropDown"
              :close-all-flag-group-options-drop-down="
                closeAllFlagGroupOptionsDropDown
              "
              :search-flag-group-options="searchFlagGroupOptions"
              :add-flag-group-option="addFlagGroupOption"
              :remove-flag-group-option="removeFlagGroupOption"
              :update-flag-group-option="updateFlagGroupOption"
            />
            <search-survey
              v-else
              class="ar-flagComponent"
              :search-surveys="searchSurveys"
              :search-survey-texts="searchSurveyTexts"
              :visible-add-text="visibleAddText"
              :visible-survey-ids="visibleSurveyIds"
              :get-surveys="getSurveys"
              :update-search-survey="updateSearchSurvey"
              :update-search-survey-texts="updateSearchSurveyTexts"
              :add-surveys-raw="addSurveysRaw"
              :remove-surveys-row="removeSurveysRow"
              :open-drop-down-survey-ids="openDropDownSurveyIds"
              :close-search-survey-drop-down="closeSearchSurveyDropDown"
            />
            <div class="ar-actionBtns">
              <div class="btn btn-availability" @click="onSearch">
                <img class="icon" src="@/assets/img/search_white.svg" alt />
                上記の条件で検索
              </div>
              <div class="btn btn-unavailable" @click="resetAndSearch">
                検索をリセット
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, onUnmounted, ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';

import { onResizeModal } from '@/utils/modal-resize';
import useSearchFlagGroups from '@/composables/useSearchFlagGroups';
import useSearchFlagGroupOptions from '@/composables/useSearchFlagGroupOptions';
import useSearchFilters from '@/components/features/searchApplicants/composables/useSearchFilters';
import useSearchSurveys from '@/components/features/searchApplicants/composables/useSearchSurveys';
import useSearchApplicantPreset from '@/components/features/searchApplicants/composables/useSearchApplicantPreset';
import Modal from '@/components/ui/modals/components/Modal';
import SearchApplicantsFilters from '@/components/features/searchApplicants/components/SearchApplicantsFilters';
import SearchFlagGroup from '@/components/features/searchApplicants/components/SearchFlagGroup';
import SearchSurvey from '@/components/features/searchApplicants/components/SearchSurvey';
import SearchApplicantPreset from '@/components/features/searchApplicants/components/SearchApplicantPreset';

export default defineComponent({
  name: 'SearchApplicants',
  components: {
    Modal,
    SearchApplicantsFilters,
    SearchFlagGroup,
    SearchSurvey,
    SearchApplicantPreset,
  },
  props: {
    isView: {
      type: Boolean,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    staffs: {
      type: Array,
      required: true,
    },
    flagMode: {
      type: Boolean,
      default: true,
    },
    displayActivatedAt: {
      type: Boolean,
      default: true,
    },
    tagGroups: {
      type: Array,
      default: () => [],
    },
    surveySheetId: {
      type: Number,
      default: null,
    },
    surveysProps: {
      type: Array,
      default: () => [],
    },
    formDefault: {
      type: Object,
      default: () => {},
    },
    hasPreset: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onClose',
    'onSearch',
    'onReset',
    'loadPresetSearchDetail',
    'openPresetEditModal',
    'openPresetDeleteDialogModal',
    'openPresetEditDialogModal',
    'openPresetListModal',
    'openSearchFilter',
    'switchSearchFilter',
  ],
  setup(props, context) {
    const store = useStore();
    const refFormFilters = ref(null);
    const orFlag = ref(false);
    const closeAllDropDown = () => {
      refFormFilters.value.closeAllDropDown();
    };
    const {
      searchFilters,
      resetSearchFilters,
      setSearchFilters,
      setProgressStatusIds,
      createSearchBody,
      parseToSearchFilterObject,
    } = useSearchFilters();
    const {
      searchFlagGroups,
      visibleAddFlags,
      visibleFlagGroupIds,
      visibleFlagIds,
      getFlagGroups,
      resetSearchFlagGroups,
      getFlags,
      getFlagNameById,
      updateFlagGroupId,
      updateFlagId,
      addFlagGroupRow,
      removeFlagGroupRow,
      openDropDownFlagGroupIds,
      openDropDownFlagId,
      closeAllFlagGroupsDropDown,
    } = useSearchFlagGroups(
      store,
      searchFilters.flagGroups.value,
      closeAllDropDown,
    );
    const {
      searchFlagGroupOptions,
      visibleFlagGroupOptions,
      selectableSearchFlagGroupOptionsDefault,
      addFlagGroupOption,
      removeFlagGroupOption,
      updateFlagGroupOption,
      resetFlagGroupOptions,
      openDropDownFlagGroupOption,
      closeAllFlagGroupOptionsDropDown,
    } = useSearchFlagGroupOptions();
    const {
      searchSurveys,
      searchSurveyTexts,
      visibleAddText,
      visibleSurveyIds,
      getSurveys,
      resetSearchSurveys,
      resetSelectableSurveys,
      updateSearchSurvey,
      updateSearchSurveyTexts,
      addSurveysRaw,
      removeSurveysRow,
      openDropDownSurveyIds,
      closeSearchSurveyDropDown,
    } = useSearchSurveys(props.surveysProps, closeAllDropDown);
    const {
      pageMode,
      editPresetId,
      editPresetName,
      editPresetSearchFilterObject,
      openPresetListModal,
      openPresetEditModal,
      openPresetDeleteDialogModal,
      openPresetEditDialogModal,
      openSearchFilter,
      openSwitchSearchFilterDialogModal,
      switchSearchFilter,
    } = useSearchApplicantPreset();

    // watch
    watch(
      () => props.isView,
      v => {
        if (v === true) {
          store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
          nextTick(() => localResizeModal());
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );
    watch(
      () => props.surveysProps,
      val => {
        resetSelectableSurveys(val);
      },
    );

    // methods
    const searchFormDetailSwitch = () => {
      openSwitchSearchFilterDialogModal();
    };
    const switchSearchFilterLocal = args => {
      switchSearchFilter({
        choice: args.choice,
        callbackFunction: () => {
          reset();
          context.emit('searchFormDetailSwitch', true);
        },
      });
    };
    const close = () => {
      openSearchFilter();
      context.emit('onClose');
    };
    const onSearch = () => {
      context.emit('onSearch', {
        searchBody: createSearchBody(
          searchFlagGroups.value,
          searchSurveys.value,
          searchSurveyTexts.value,
        ),
        orFlag: orFlag.value,
        searchFilterObject: {
          search_detail_filters: parseToSearchFilterObject(),
          search_flag_groups: searchFlagGroups.value,
          search_flag_group_options: searchFlagGroupOptions.value,
          search_surveys: searchSurveys.value,
          search_survey_texts: searchSurveyTexts.value,
          sheet_id: props.surveySheetId,
          or_flag: orFlag.value,
        },
      });
    };
    const reset = () => {
      orFlag.value = false;
      resetSearchFilters();
      resetSearchFlagGroups();
      resetFlagGroupOptions();
      resetSearchSurveys();
    };
    const resetAndSearch = () => {
      reset();
      context.emit('onReset');
      // -noscrollが残ってしまうので除去する
      const bodyEl = document.querySelector('body');
      bodyEl.classList.remove('-noscroll');
      bodyEl.style.removeProperty('top');
    };
    const setInitSearchFilters = () => {
      // 画面を開いた最初の検索で条件検索させる場合
      if (props.formDefault && props.formDefault.body) {
        // 現在progressStatusIdsのみ対応
        if (props.formDefault.body.progressStatusIds) {
          setSearchFilters(
            'progressStatusIds',
            props.formDefault.body.progressStatusIds,
          );
        }
      }
    };
    const openPresetEditModalLocal = ({
      presetId,
      presetName,
      presetSearchFilterObject,
    }) => {
      const resValidate = openPresetEditModal({
        presetId,
        presetName,
        presetSearchFilterObject:
          // プリセット新規作成時は現在の絞り込み条件、プリセット編集時は保存された検索条件を渡す
          presetSearchFilterObject !== null
            ? presetSearchFilterObject
            : {
                search_detail_filters: parseToSearchFilterObject(),
                search_flag_groups: searchFlagGroups.value,
                search_flag_group_options: searchFlagGroupOptions.value,
                search_surveys: searchSurveys.value,
                search_survey_texts: searchSurveyTexts.value,
                sheet_id: props.surveySheetId,
              },
      });
      if (resValidate.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: resValidate.message,
          type: false,
        });
      }
    };
    const openPresetEditDialogModalLocal = async ({ presetId, presetName }) => {
      // プリセット検索条件上書き確認ダイアログ表示イベント
      const resValidate = openPresetEditDialogModal({
        presetId,
        presetName,
        presetSearchFilterObject: {
          search_detail_filters: parseToSearchFilterObject(),
          search_flag_groups: searchFlagGroups.value,
          search_flag_group_options: searchFlagGroupOptions.value,
          search_surveys: searchSurveys.value,
          search_survey_texts: searchSurveyTexts.value,
        },
      });
      if (resValidate.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: resValidate.message,
          type: false,
        });
      }
    };
    const loadPreset = async ({
      searchFilterObj: {
        search_detail_filters = [],
        search_flag_groups = [],
        search_flag_group_options = [],
        search_surveys = [],
        search_survey_texts = [],
        sheet_id = null,
      },
      preset = {},
    }) => {
      reset();
      switchSearchFilterLocal({ choice: 0 });
      context.emit('loadPresetSearchDetail', {
        searchFilterObj: {
          search_detail_filters,
          search_flag_groups,
          search_flag_group_options,
          search_surveys,
          search_survey_texts,
          sheet_id,
        },
        preset,
      });
    };
    const localResizeModal = () =>
      onResizeModal(props.isView, { shouldResizeModalWidth: false });

    // searchFilter/START_SEARCHを監視する
    const unsubscribeStore = store.subscribe(async mutation => {
      if (mutation.type === 'searchFilter/START_SEARCH') {
        nextTick(() => setInitSearchFilters());
      }
    });
    setInitSearchFilters();

    // life cycles
    onUnmounted(() => {
      unsubscribeStore();
    });

    return {
      unsubscribeStore,
      refFormFilters,
      orFlag,
      searchFilters,
      setSearchFilters,
      setProgressStatusIds,
      searchFlagGroups,
      visibleAddFlags,
      visibleFlagGroupIds,
      visibleFlagIds,
      getFlagGroups,
      getFlags,
      getFlagNameById,
      updateFlagGroupId,
      updateFlagId,
      addFlagGroupRow,
      removeFlagGroupRow,
      openDropDownFlagGroupIds,
      openDropDownFlagId,
      pageMode,
      editPresetId,
      editPresetName,
      closeAllFlagGroupsDropDown,
      searchFlagGroupOptions,
      visibleFlagGroupOptions,
      selectableSearchFlagGroupOptionsDefault,
      addFlagGroupOption,
      removeFlagGroupOption,
      updateFlagGroupOption,
      openDropDownFlagGroupOption,
      closeAllFlagGroupOptionsDropDown,
      searchSurveys,
      searchSurveyTexts,
      visibleAddText,
      visibleSurveyIds,
      getSurveys,
      updateSearchSurvey,
      updateSearchSurveyTexts,
      addSurveysRaw,
      removeSurveysRow,
      openDropDownSurveyIds,
      closeSearchSurveyDropDown,
      searchFormDetailSwitch,
      close,
      onSearch,
      reset,
      resetAndSearch,
      openPresetListModal,
      editPresetSearchFilterObject,
      openPresetDeleteDialogModal,
      openPresetEditModalLocal,
      openPresetEditDialogModalLocal,
      openSearchFilter,
      switchSearchFilterLocal,
      parseToSearchFilterObject,
      loadPreset,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.header,
.body {
  max-width: 1070px;
  width: calc(100vw - 40px);
}

.toRelease {
  display: inline-block;
  color: #1899d6;
  cursor: pointer;
}

.search_filter_controllers {
  margin-bottom: 20px;
  text-align: right;
  > button:last-child {
    margin-left: 10px;
  }
}

.btn-searchSwitch {
  position: absolute;
  top: 50%;
  right: 10px;
  border: none;
  border-radius: 126px;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  background-color: #16b2d9;
  transform: translateY(-50%);
  cursor: pointer;
  > .icon {
    height: 12px;
    vertical-align: -2px;
  }
  &:hover {
    background-color: #1698d9;
  }
}

.ar-flagComponent {
  padding: 20px 0;
  margin-bottom: 40px;
  border-bottom: 1px solid #ccc;
  > .subBtn {
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
  }
}

.ar-actionBtns {
  text-align: center;
  > .btn-availability {
    display: block;
    width: 100%;
    padding: 20px;
  }
  > .btn-unavailable {
    margin: 20px 0 0;
    padding: 20px 30px;
  }
}

@media (max-width: ($media_query_sp)) {
  .btn-searchSwitch {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    margin-top: 20px;
    transform: none;
  }
}
</style>
