<template>
  <div class="contact-area privacypolicy">
    <p class="policy_ttl">MOCHICAユーザー利用規約</p>
    <div class="container">
      <user-policy />
    </div>
    <button
      class="to_login_btn"
      type="button"
      @click.prevent="$emit('backFromPolicy')"
    >
      閉じる
    </button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import UserPolicy from '@/components/features/policy/components/UserPolicy';

export default defineComponent({
  name: 'FormPolicy',
  components: { UserPolicy },
  emits: ['backFromPolicy'],
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.login-form {
  display: flex;
  align-items: center;
  flex-flow: column;
  &-toissue {
    width: 45rem;
    padding: 3rem 3.6rem;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0.4rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}
.login-form_logo-title {
  max-width: 300px;
  fill: $mochica-color;
  margin-bottom: 2vh;
  margin-top: 6vh;
}
.login-form-wrapper {
  position: fixed;
  text-align: center;
  background: url(../../../../assets/img/login_bg.jpg) no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  .logo-title {
    width: 38rem;
    fill: #88c6ed;
    margin: 0 auto 3.6rem;
  }
}
.login-form-toissue {
  height: 100%;
  max-width: 1200px;
  width: 80%;
  padding: 0;
}

.container {
  font-weight: normal;
  font-size: 1.4rem;
  color: #888;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  padding: 10px 10%;
  overflow-y: auto;
  height: 45vh;
  .privacypolicy h4 {
    font-size: 1.6rem;
    color: #1dcd00;
    margin-bottom: 50px;
    .row {
      height: 100%;
    }
  }
  .article_ttl {
    font-size: 1.6rem;
    color: #202020;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .p_2 {
    font-size: 1.4rem;
  }
  .p_3 {
    font-size: 1.2rem;
  }
}
.to_login_btn {
  width: 80%;
  height: 3.6rem;
  line-height: 3.6rem;
  background-color: #656565;
  color: #fff;
  text-align: center;
  border: none;
  font-weight: bold;
  border-radius: 0.3rem;
  cursor: pointer;
  margin: 3vh auto 0;
}
.privacypolicy {
  text-align: center;
}
.policy_ttl {
  font-size: 2rem;
  color: #656565;
  margin-bottom: 10px;
}
.privacy__text-link {
  display: inline-block;
  color: $mochica-color;
}
</style>
