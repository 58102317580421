const mochicaPlans = [
  // エントリープラン（廃止）
  {
    id: 0,
    name: 'entry',
    name_ja: 'エントリープラン',
    name_short: 'エントリー',
    applicant_limit: 100,
    file_storage: 0,
    delete_applicant: false,
    is_show: false,
    color: '#ea6f26',
  },
  {
    id: 3,
    name: 'free',
    name_ja: 'フリープラン',
    name_short: 'フリー',
    applicant_limit: 30,
    file_storage: 15,
    delete_applicant: false,
    is_show: true,
    color: '#ea6f26',
  },
  {
    id: 1,
    name: 'light',
    name_ja: 'ライトプラン',
    name_short: 'ライト',
    applicant_limit: 300,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#1899d6',
  },
  {
    id: 4,
    name: 'light500',
    name_ja: 'ライトプラン500',
    name_short: 'ライト500',
    applicant_limit: 500,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#1899d6',
  },
  {
    id: 5,
    name: 'light700',
    name_ja: 'ライトプラン700',
    name_short: 'ライト700',
    applicant_limit: 700,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#1899d6',
  },
  {
    id: 6,
    name: 'light900',
    name_ja: 'ライトプラン900',
    name_short: 'ライト900',
    applicant_limit: 900,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#1899d6',
  },
  {
    id: 2,
    name: 'standard',
    name_ja: 'スタンダードプラン',
    name_short: 'スタンダード',
    applicant_limit: 99999999,
    file_storage: 15,
    delete_applicant: true,
    is_show: true,
    color: '#43a048',
  },
];

const getPlanDataById = planId => mochicaPlans.find(plan => plan.id === planId);

export { mochicaPlans, getPlanDataById };
