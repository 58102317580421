/**
 * 応募者一覧FULL Modal module
 */
export default {
  namespaced: true,
  state: {
    isView: false,
    title: '',
    applicantIds: [],
  },
  getters: {
    modalApplicantsIsView: currentState => currentState.isView,
    modalApplicantsTitle: currentState => currentState.title,
    modalApplicantsApplicantIds: currentState => currentState.applicantIds,
    searchOptions: currentState => currentState.searchOptions,
  },
  mutations: {
    VISIBLE_MODAL_APPLICANTS(state, payload) {
      Object.assign(state, {
        isView: true,
        title: payload.title,
        applicantIds: payload.applicantIds,
        searchOptions: payload.searchOptions,
      });
    },
    INVISIBLE_MODAL_APPLICANTS(state) {
      Object.assign(state, {
        isView: false,
        title: '',
        applicantIds: [],
        searchOptions: null,
      });
    },
  },
  actions: {
    VISIBLE_MODAL_APPLICANTS({ commit }, payload) {
      commit('VISIBLE_MODAL_APPLICANTS', payload);
    },
    INVISIBLE_MODAL_APPLICANTS({ commit }) {
      commit('INVISIBLE_MODAL_APPLICANTS');
    },
  },
};
