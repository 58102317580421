export const PROGRESS_STATUSES = Object.freeze({
  notSetSelection: 0,
  notAnnounced: 1,
  announced: 2,
  notEvaluated: 4,
  pending: 5,
  finished: 6,
});
export const FINISHED_PROGRESS_STATUSES = Object.freeze({
  hired: 1,
  offered: 2,
  dropped: 3,
  canceled: 4,
  offerCanceled: 5,
  passed: 6,
});

export const PROGRESS_STATUSES_LABEL = Object.freeze({
  [PROGRESS_STATUSES.notSetSelection]: '選考未割当て',
  [PROGRESS_STATUSES.notAnnounced]: '未案内',
  [PROGRESS_STATUSES.announced]: '案内済',
  [PROGRESS_STATUSES.notEvaluated]: '未評価',
  [PROGRESS_STATUSES.pending]: '人事待',
});

export const FINISHED_PROGRESS_STATUSES_LABEL = Object.freeze({
  [FINISHED_PROGRESS_STATUSES.hired]: '採用',
  [FINISHED_PROGRESS_STATUSES.offered]: '内定',
  [FINISHED_PROGRESS_STATUSES.dropped]: '不採用',
  [FINISHED_PROGRESS_STATUSES.canceled]: '辞退',
  [FINISHED_PROGRESS_STATUSES.offerCanceled]: '内定辞退',
  [FINISHED_PROGRESS_STATUSES.passed]: '通過',
});
