<template>
  <form class="login-form">
    <div class="login-form-inner">
      <div v-if="isMaster" class="form-item company_id_inner">
        <label class="el-form-item__label">会社ID</label>
        <input
          v-model="companyId"
          class="form-item-input"
          autocomplete="off"
          type="text"
          @keyup.enter.prevent="number"
        />
      </div>
      <div class="form-item mail_inner">
        <label class="el-form-item__label">
          <img
            class="form-item-svg mail-svg"
            width="20"
            height="14"
            src="@/assets/img/mail_blue.svg"
            alt
          />
          メールアドレス
        </label>
        <input v-model="email" class="form-item-input" type="mail" />
      </div>
      <div class="form-item password_inner">
        <label class="el-form-item__label">
          <img
            class="form-item-svg key-svg"
            width="12"
            height="22"
            src="@/assets/img/password_blue.svg"
            alt
          />
          パスワード
        </label>
        <input
          v-model="password"
          class="form-item-input"
          autocomplete="off"
          type="password"
          @keyup.enter.prevent="login"
        />
      </div>
      <div class="form-item login-btn">
        <button type="button" @click.prevent="login">ログイン</button>
      </div>
      <div v-if="!isMaster" class="under_btns">
        <p class="under_btns__btn">
          <span class="pathChange_span" @click.prevent="$emit('pathChange')">
            利用規約を確認する
          </span>
        </p>
        <p class="under_btns__btn">
          <span
            class="changemode_span"
            @click.prevent="$emit('changeMode', 'loginToIssue')"
          >
            パスワードを忘れた方はこちら
          </span>
        </p>
      </div>
    </div>
    <!-- <section class="maintenance">
      <h1 class="maintenance-heading">現在メンテナンス中です</h1>
      <div class="maintenance-description">
        <div>【緊急メンテナンス期間】</div>
        <div class="maintenance-time">開始時刻：2021/06/30&nbsp;21：00</div>
        <div class="maintenance-time">終了予定時刻：2021/07/01&nbsp;06：00</div>
      </div>
    </section> -->
    <notification />
  </form>
</template>
<script>
import { defineComponent, ref } from 'vue';
import Notification from '@/components/ui/notification/components/Notification';

export default defineComponent({
  name: 'LoginForm',
  components: { Notification },
  props: {
    isMaster: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submitLogin', 'pathChange', 'changeMode'],
  setup(props, context) {
    const email = ref('');
    const password = ref('');
    const companyId = ref('');

    // methods
    const login = () => {
      context.emit('submitLogin', {
        email: email.value,
        password: password.value,
        company_id: companyId.value,
      });
    };

    return {
      email,
      password,
      companyId,
      login,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.maintenance {
  margin-bottom: 30px;
  @media (max-width: ($media_query_sp)) {
    padding: 20px;
  }
  .maintenance-heading {
    margin: 0 0 15px 0;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  }
  .maintenance-description {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid $border_color;
    font-size: 1.4rem;
    line-height: 1.55;
    .maintenance-time {
      @media (max-width: ($media_query_sp)) {
        font-size: 1.1rem;
      }
    }
  }
}
.login-form {
  &-inner {
    width: 300px;
    max-width: 350px;
    padding: 30px 0px;

    .form-item {
      margin-bottom: 1.2rem;
      &:nth-of-type(2) {
        margin-bottom: 2.4rem;
      }
    }
    label {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding-left: 2.4rem;
      margin-bottom: 0.6rem;
      line-height: unset;
      height: 2.2rem;
      font-size: 1.2rem;
      .form-item-svg {
        fill: $mochica_color;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        &.mail-svg {
          height: 1.4rem;
        }
        &.key-svg {
          height: 2rem;
          left: 0.6rem;
        }
      }
    }
    .form-item-input {
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      line-height: unset;
      border: 1px solid #e3e3e3;
      border-radius: 2px;
      padding: 0.8rem 0.8rem;
      background: #fff;
      font-size: 1.5rem;
      &:focus {
        border-color: $mochica-color;
        transition: 0.3s;
      }
    }
    .under_btns {
      margin-top: 30px;
      text-align: center;
      &__btn span {
        @include btn_hover();
        display: inline-block;
        padding-bottom: 3px;
        border-bottom: 1px dashed $mochica_color;
        color: #333;
      }
      &__btn + .under_btns__btn {
        margin-top: 10px;
      }
    }
    .company_id_inner {
      .el-form-item__label {
        padding: 0;
      }
    }
  }
  .login-btn {
    margin-bottom: 0;
    button {
      width: 100%;
      height: 3.6rem;
      line-height: 3.6rem;
      background-color: $mochica_color;
      color: #fff;
      text-align: center;
      border: none;
      font-weight: bold;
      border-radius: 0.3rem;
      cursor: pointer;
      @include btn_hover();
    }
  }
}
</style>
