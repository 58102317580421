<script setup>
defineOptions({ inheritAttrs: false });
defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const updateModelValue = event => {
  emit('update:modelValue', event.target.checked);
};
</script>

<template>
  <input
    v-bind="$attrs"
    type="checkbox"
    :checked="modelValue"
    class="base-checkbox"
    @change="updateModelValue"
  />
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
$check_svg: '@/assets/img/check.svg';

.base-checkbox {
  position: relative;
  appearance: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 2px solid $mochica_color_gray;
  border-radius: 4px;
  background: $white;
  transform: translate3d(0px, 0px, 0px);
  &:checked {
    &::after {
      content: url($check-svg);
      position: absolute;
      width: 18px;
      height: auto;
      inset: -2px 0 0 1.5px;
    }
  }
}
</style>
