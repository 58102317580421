<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  setup(props, context) {},
});
</script>

<style lang="scss" scoped>
//モーダルが開いている時の処理です。
#app {
  background-color: #e2e2e2;
  overflow-y: auto;
  //応募者詳細の選考結果の決定確認モーダルの調整
  & :deep(.list-flow .item-flow) {
    .modal-mask {
      width: 101vw;
    }
  }
}
</style>
