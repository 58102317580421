<template>
  <div class="step-bar">
    <div class="step" :class="{ active: 1 <= currentStep }">
      <span class="number">1</span>
      <span class="text">初期登録種別の設定</span>
    </div>
    <div class="step" :class="{ active: 2 <= currentStep }">
      <span class="number">2</span>
      <span class="text">未読メッセージの通知設定</span>
    </div>
    <div class="step" :class="{ active: 3 <= currentStep }">
      <span class="number">3</span>
      <span class="text">エントリーフォームの設定</span>
    </div>
    <div class="step" :class="{ active: 4 <= currentStep }">
      <span class="number">4</span>
      <span class="text">確認</span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GuideStepBar',
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },
});
</script>
<style lang="scss" scoped>
// メディアクエリの900pxは親要素のコンテンツサイズに依存している
@import '@/assets/variables.scss';

.step-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: (900px)) {
    justify-content: space-between;
    padding: 0 30px;
    &::after {
      position: absolute;
      z-index: 0;
      content: '';
      display: inline-block;
      background-color: $mochica_color_gray2;
      height: 2px;
      width: calc(100% - 60px);
    }
  }
  .step {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child)::after {
      content: '';
      display: inline-block;
      height: 2px;
      width: 100%;
      background-color: $mochica_color_gray2;
      @media (min-width: (900px)) {
        width: 50px;
        margin: 0 10px;
      }
    }
    .number {
      background-color: $mochica_color_gray2;
      border-radius: 50%;
      min-width: 32px;
      min-height: 32px;
      line-height: 32px;
      text-align: center;
      color: $mochica_color_gray;
      font-weight: bold;
    }
    .text {
      display: none;
      color: $mochica_color_gray;
      @media (min-width: (900px)) {
        display: block;
        margin-left: 10px;
      }
    }
    &.active {
      &:after:not(:last-child) {
        background-color: $mochica_color;
      }
      .number {
        background-color: $mochica_color;
        color: $white;
      }
      .text {
        color: $mochica_color;
      }
    }
  }
}
</style>
