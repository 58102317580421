export default [
  {
    id: 9901,
    year: 9901,
    name: 'parttimejob',
    name_jp: 'アルバイト',
    name_entry: 'アルバイト',
  },
  {
    id: 9902,
    year: 9902,
    name: 'midcareerjob',
    name_jp: '中途採用',
    name_entry: '中途',
  },
  {
    id: 9903,
    year: 9903,
    name: 'contactemployeejob',
    name_jp: '派遣・契約',
    name_entry: '派遣・契約',
  },
  {
    id: 9999,
    year: 9999,
    name: 'otherjob',
    name_jp: 'その他',
    name_entry: 'その他',
  },
];
