import { ref } from 'vue';
import { useStore } from 'vuex';
import autoActionsService from '@/services/autoActions';
import { formatAutoActionsDetailToState } from '@/utils/autoActions/formatAutoActionData';

export default function useFetchAutoAction() {
  const action = ref({});
  const isLoading = ref(false);
  const store = useStore();

  const fetchAutoAction = async actionId => {
    isLoading.value = true;
    const { data } = await autoActionsService.fetchAutoActionsDetail(
      { graduated_id: store.getters['graduateds/selectedGraduatedId'] },
      actionId,
    );
    action.value = formatAutoActionsDetailToState(data);
    isLoading.value = false;
  };

  return { action, isLoading, fetchAutoAction };
}
