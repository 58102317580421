import { createStore } from 'vuex';
import applicant from '@/stores/modules/applicant';
import plan from '@/stores/modules/plan';
import auth from '@/stores/modules/auth';
import graduateds from '@/stores/modules/graduateds';
import staff from '@/stores/modules/staff';
import company from '@/stores/modules/company';
import page from '@/stores/modules/page';
import notification from '@/stores/modules/notification';
import modalApplicants from '@/stores/modules/modal-applicants';
import ui from '@/stores/modules/ui/index';
import announce from '@/stores/modules/announce';
import searchFilter from '@/stores/modules/search-filter';

const store = createStore({
  modules: {
    applicant,
    plan,
    auth,
    graduateds,
    staff,
    company,
    page,
    notification,
    modalApplicants,
    ui,
    announce,
    searchFilter,
  },
});

export default store;
