<template>
  <div
    v-if="staff.role.id === 1 || !$store.getters['staff/isOwner']"
    class="value"
  >
    {{ $utils.getValueFromNestedObject(staff, relation) }}
  </div>
  <div v-else class="value">
    <div class="edit-text">
      <div v-if="isEditMode" class="staff_setting_list_item_ttl">
        <select
          v-model="localStaff.data.role.id"
          name="role_id"
          class="ui-select"
        >
          <option value="2">人事担当</option>
          <option value="3">担当者</option>
        </select>
      </div>
      <div v-else class="edit-text">
        <span class="role">
          {{ $utils.getValueFromNestedObject(localStaff.data, relation) }}
        </span>
      </div>
      <button-base
        class="edit-role-button"
        :is-loading="isLoading"
        :rejected-role-ids="[2, 3]"
        :rejected-admin-ids="[2]"
        :button-text="isEditMode === true ? '権限を確定' : '権限を変更'"
        :button-type="isEditMode === true ? 'success' : 'primary'"
        :icon-file-name="isEditMode === true ? 'check_white' : 'pen_white'"
        @click="isEditMode === true ? commit() : (isEditMode = true)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import staffsService from '@/services/staffs';

export default defineComponent({
  name: 'EditRole',
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
    relation: {
      type: String,
      default: '',
    },
  },
  emits: ['fetchStaffs'],
  setup(props, context) {
    const store = useStore();
    const localStaff = reactive({ data: props.staff });
    const isEditMode = ref(false);
    const isLoading = ref(false);
    watch(
      () => props.staff,
      () => (localStaff.data = props.staff),
    );
    const commit = async () => {
      isLoading.value = true;
      const res = await staffsService.updateStaffRole(
        props.staff.id,
        localStaff.data.role.id,
      );
      if (res.success === true) {
        context.emit('fetchStaffs');
        isEditMode.value = false;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? '権限を変更しました'
            : '権限の変更に失敗しました',
        type: res.success,
      });
      isLoading.value = false;
    };
    return { localStaff, isEditMode, isLoading, commit };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.edit-text {
  display: flex;
  align-items: center;
  @media (max-width: ($media_query_sp)) {
    flex-wrap: wrap;
  }
}
.ui-select {
  height: 30px;
  border: 1px solid #e2e2e2;
}
.role {
  @media (min-width: ($media_query_sp)) {
    width: 67px;
  }
}
.edit-role-button {
  margin-left: 20px;
  @media (max-width: ($media_query_sp)) {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
    font-size: 1rem;
  }
}
</style>
