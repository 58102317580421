<template>
  <div class="edit-text">
    <button-base
      v-if="staff.role.id !== 1"
      class="btn"
      button-text="スタッフを削除"
      button-type="danger"
      icon-file-name="delete_white"
      :rejected-admin-ids="[2]"
      @click="deleteMode = true"
    />

    <modal-window
      :is-visible="deleteMode"
      :message="`※この操作は取り消すことができません。\n\n名前：${staff.firstname} ${staff.lastname} \nメールアドレス：${staff.email}`"
      class="delete-staff-modal"
      title="以下のスタッフを削除します"
      modal-type="danger"
      button-text="スタッフを削除"
      @close="deleteMode = false"
      @click="onClickSettleDeleteStaff"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'AdminDeleteStaff',
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['onClickSettleDeleteStaff'],
  setup(props, context) {
    const deleteMode = ref(false);

    const onClickSettleDeleteStaff = ({ selected }) => {
      if (selected === 'submit') {
        context.emit('onClickSettleDeleteStaff', { staff_id: props.staff.id });
      }
      deleteMode.value = false;
    };

    return { deleteMode, onClickSettleDeleteStaff };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.edit-text {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .staffs-email_form_item {
    white-space: initial;
    input {
      width: 300px;
      height: 40px;
      padding: 0.6rem;
      border-radius: 0.4rem;
      border: 1px solid $border_color;
      font-size: 1.4rem;
      background: #fff;
    }
    span {
      &.fa-warning {
        color: #c31e1e;
        padding-top: 4px;
        font-size: 1.2rem;
      }
      &.fa {
        display: block;
      }
    }
  }
}
.ui-select {
  height: 30px;
  border: 1px solid #e2e2e2;
}
.btn {
  margin-left: 20px;
}
.delete-staff-modal {
  white-space: break-spaces;
}

@media (max-width: ($media_query_sp)) {
  .edit-text {
    font-size: 1rem;
  }
  .btn {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 150px;
    bottom: -50px;
  }
}
</style>
