<script setup>
import { ref, watch } from 'vue';
import ModalFullSize from '@/components/ui/modals/ModalFullSize.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';

import richMessageService from '@/services/richmessage';

const props = defineProps({
  isView: {
    type: Boolean,
    default: false,
  },
  richMessageId: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(['close-modal']);

const isLoading = ref(false);
const richMessageObj = ref({});

const fetchRichMessages = async () => {
  if (!props.richMessageId) return;
  isLoading.value = true;
  const res = await richMessageService.fetchRichMessages();
  if (!res.success) {
    isLoading.value = false;
    return;
  }
  richMessageObj.value = res.richmessage_list.find(
    richMessage => richMessage.id === props.richMessageId,
  );
  isLoading.value = false;
};

watch(
  () => props.isView,
  newIsView => {
    if (newIsView) {
      fetchRichMessages();
    }
  },
);
</script>

<template>
  <modal-full-size :is-view="isView" @close-modal="emit('close-modal')">
    <template #header>
      <div class="header">
        <div>送信するリッチメッセージの選択</div>
        <img src="@/assets/img/arrow_right_blue.svg" />
        <div>リッチメッセージの内容</div>
      </div>
    </template>
    <template #body>
      <div class="body-wrapper">
        <div class="body-inner">
          <div v-if="isLoading" class="page-loading">
            <LocalPageLoader />
          </div>
          <div v-else-if="Object.keys(richMessageObj).length === 0">
            <EmptyContent>
              <p>
                リッチメッセージがありません
                <br />
                あらかじめ「応募者へ通知」からリッチメッセージを
                <br />
                作成している必要があります
              </p>
            </EmptyContent>
          </div>
          <div v-else>
            <section class="rich_area">
              <div class="rich_main">
                <div class="rich_image">
                  <div
                    v-show="richMessageObj.image_type == 1"
                    class="rich_image-inner"
                  >
                    <a
                      class="rich_share_item share1_a"
                      :href="richMessageObj.link_a"
                      target="_blank"
                    >
                      <span>A</span>
                    </a>
                  </div>
                  <div
                    v-show="richMessageObj.image_type == 2"
                    class="rich_image-inner"
                  >
                    <a
                      class="rich_share_item share2_a"
                      :href="richMessageObj.link_a"
                      target="_blank"
                    >
                      <span>A</span>
                    </a>
                    <a
                      class="rich_share_item share2_b"
                      :href="richMessageObj.link_b"
                      target="_blank"
                    >
                      <span>B</span>
                    </a>
                  </div>
                  <div
                    v-show="richMessageObj.image_type == 3"
                    class="rich_image-inner"
                  >
                    <a
                      class="rich_share_item share3_a"
                      :href="richMessageObj.link_a"
                      target="_blank"
                    >
                      <span>A</span>
                    </a>
                    <a
                      class="rich_share_item share3_b"
                      :href="richMessageObj.link_b"
                      target="_blank"
                    >
                      <span>B</span>
                    </a>
                    <a
                      class="rich_share_item share3_c"
                      :href="richMessageObj.link_c"
                      target="_blank"
                    >
                      <span>C</span>
                    </a>
                  </div>
                  <div
                    v-show="richMessageObj.image_type == 4"
                    class="rich_image-inner"
                  >
                    <a
                      class="rich_share_item share4_a"
                      :href="richMessageObj.link_a"
                      target="_blank"
                    >
                      <span>A</span>
                    </a>
                    <a
                      class="rich_share_item share4_b"
                      :href="richMessageObj.link_b"
                      target="_blank"
                    >
                      <span>B</span>
                    </a>
                    <a
                      class="rich_share_item share4_c"
                      :href="richMessageObj.link_c"
                      target="_blank"
                    >
                      <span>C</span>
                    </a>
                    <a
                      class="rich_share_item share4_d"
                      :href="richMessageObj.link_d"
                      target="_blank"
                    >
                      <span>D</span>
                    </a>
                  </div>
                  <img
                    class="rich_image_bind"
                    :src="`${richMessageObj.image_url}/700`"
                    width="478"
                    height="478"
                    alt
                  />
                </div>
                <div class="rich_share">
                  <p class="rich_heading">リッチメッセージのリンク分割方法</p>
                  <ul>
                    <li
                      :class="richMessageObj.image_type === 1 ? 'checked' : ''"
                    >
                      <label for="share01">
                        <input
                          id="share01"
                          v-model="richMessageObj.image_type"
                          disabled
                          type="radio"
                          name="share"
                          value="1"
                        />
                        <img
                          src="@/components/page/richmessage/assets/img/rich_share_01.png"
                          width="60"
                          height="60"
                          alt
                        />
                        リンクを分割しない
                      </label>
                    </li>
                    <li
                      :class="richMessageObj.image_type === 2 ? 'checked' : ''"
                    >
                      <label for="share02">
                        <input
                          id="share02"
                          v-model="richMessageObj.image_type"
                          disabled
                          type="radio"
                          name="share"
                          value="2"
                        />
                        <img
                          src="@/components/page/richmessage/assets/img/rich_share_02.png"
                          width="60"
                          height="60"
                          alt
                        />
                        リンクを2つに分割する
                      </label>
                    </li>
                    <li
                      :class="richMessageObj.image_type === 3 ? 'checked' : ''"
                    >
                      <label for="share03">
                        <input
                          id="share03"
                          v-model="richMessageObj.image_type"
                          disabled
                          type="radio"
                          name="share"
                          value="3"
                        />
                        <img
                          src="@/components/page/richmessage/assets/img/rich_share_03.png"
                          width="60"
                          height="60"
                          alt
                        />
                        リンクを3つに分割する
                      </label>
                    </li>
                    <li
                      :class="richMessageObj.image_type === 4 ? 'checked' : ''"
                    >
                      <label for="share04">
                        <input
                          id="share04"
                          v-model="richMessageObj.image_type"
                          disabled
                          type="radio"
                          name="share"
                          value="4"
                        />
                        <img
                          src="@/components/page/richmessage/assets/img/rich_share_04.png"
                          width="60"
                          height="60"
                          alt
                        />
                        リンクを4つに分割する
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rich_bottom">
                <p class="rich_heading">タイトル</p>
                <div class="input_tr">
                  <input
                    v-model="richMessageObj.name"
                    readonly
                    type="text"
                    name="richmessage_name"
                    class="richmessage_name input_block"
                  />
                </div>
                <p class="rich_heading">スマートフォンロック画面メッセージ</p>
                <div class="input_tr">
                  <div class="line_alt_text-inputarea">
                    <input
                      v-model="richMessageObj.line_alt_text"
                      readonly
                      type="text"
                      name="richmessage_alt_text"
                      class="richmessage_alt_text input_block"
                    />
                  </div>
                </div>
                <p class="rich_heading">メッセージ確認</p>
                <div class="input_tr">
                  <span>
                    {{ richMessageObj.richmessage_check ? 'あり' : 'なし' }}
                  </span>
                </div>
                <p class="rich_heading">メッセージ</p>
                <div class="input_tr">
                  <textarea
                    v-model="richMessageObj.push_message"
                    readonly
                    name="richmessage_push"
                    class="richmessage_push_textarea"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <p class="rich_heading">リッチメッセージのリンク</p>
                <ul class="link-list">
                  <li class="item">
                    <div class="row">
                      <p class="link-label -linkA">リンクA</p>
                      <input
                        v-model="richMessageObj.link_a"
                        readonly
                        type="text"
                        name="input_link_a"
                        class="input_block"
                      />
                    </div>
                    <p v-if="richMessageObj.link_a" class="url">
                      <a target="_blank" :href="richMessageObj.link_a">
                        {{ richMessageObj.link_a }}
                      </a>
                    </p>
                  </li>
                  <li v-show="richMessageObj.image_type != 1" class="item">
                    <div class="row">
                      <p class="link-label -linkB">リンクB</p>
                      <input
                        v-model="richMessageObj.link_b"
                        readonly
                        type="text"
                        name="input_link_b"
                        class="input_block"
                      />
                    </div>
                    <p v-if="richMessageObj.link_b" class="url">
                      <a target="_blank" :href="richMessageObj.link_b">
                        {{ richMessageObj.link_b }}
                      </a>
                    </p>
                  </li>
                  <li
                    v-show="
                      richMessageObj.image_type != 1 &&
                      richMessageObj.image_type != 2
                    "
                    class="item"
                  >
                    <div class="row">
                      <p class="link-label -linkC">リンクC</p>
                      <input
                        v-model="richMessageObj.link_c"
                        readonly
                        type="text"
                        name="input_link_c"
                        class="input_block"
                      />
                    </div>
                    <p v-if="richMessageObj.link_c" class="url">
                      <a target="_blank" :href="richMessageObj.link_c">
                        {{ richMessageObj.link_c }}
                      </a>
                    </p>
                  </li>
                  <li v-show="richMessageObj.image_type == 4" class="item">
                    <div class="row">
                      <p class="link-label -linkD">リンクD</p>
                      <input
                        v-model="richMessageObj.link_d"
                        readonly
                        type="text"
                        name="input_link_d"
                        class="input_block"
                      />
                    </div>
                    <p v-if="richMessageObj.link_d" class="url">
                      <a target="_blank" :href="richMessageObj.link_d">
                        {{ richMessageObj.link_d }}
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="btn-col">
        <button-base
          type="button"
          class="btn-cancel"
          button-type="secondary"
          button-text="キャンセル"
          @click="emit('close-modal')"
        />
      </div>
    </template>
  </modal-full-size>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.header {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: 12px;
    height: 12px;
  }
}
.body-wrapper {
  padding: 20px;
  background-color: $white;
}
.body-inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.rich_area {
  text-align: left;
}

.rich_main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0 30px;
}
@media (max-width: ($media_query_sp)) {
  .rich_main {
    display: block;
  }
}

.rich_heading {
  font-size: 1.3rem;
  font-weight: bold;
  color: #1899d6;
  padding-bottom: 1rem;
  text-align: left;
}
.input_tr {
  margin-bottom: 30px;
  .input_block {
    display: block;
    margin-bottom: 5px;
  }
}
.input-file {
  display: none;
}

.rich_image {
  position: relative;
  width: 40%;
  height: 100%;
}
@media (max-width: ($media_query_sp)) {
  .rich_image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}
.rich_image-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rich_image img {
  width: 100%;
  height: 100%;
}

.rich_share {
  width: 58%;
  ul {
    & > li {
      text-align: left;
    }
  }
}
@media (max-width: ($media_query_sp)) {
  .rich_share {
    width: 100%;
  }
}
.rich_share_item {
  position: absolute;
  display: table;
}
.rich_share_item span {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 5rem;
  color: #757575;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: 'alpha(opacity=100)';
}
.rich_image .share1_a {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.rich_image .share2_a {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
}
.rich_image .share2_b {
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
}
.rich_image .share3_a {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
}
.rich_image .share3_b {
  width: 50%;
  height: 50%;
  bottom: 0;
  left: 0;
  border-right: 1px solid #ccc;
}
.rich_image .share3_c {
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
}
.rich_image .share4_a {
  width: 50%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.rich_image .share4_b {
  width: 50%;
  height: 50%;
  top: 0;
  right: 0;
}
.rich_image .share4_c {
  width: 50%;
  height: 50%;
  bottom: 0;
}
.rich_image .share4_d {
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.rich_share {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}
.rich_share .rich_heading {
  padding: 25px;
}
.rich_share li {
  border-top: 1px solid #f0f0f0;
}
.rich_share li.checked {
  background: #efefef;
}
.rich_share li label {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
}
.rich_share li input,
.rich_share li img {
  margin-right: 15px;
}
@media (max-width: ($media_query_sp)) {
  .rich_share li img {
    width: 30px;
    height: auto;
  }
}

.richmessage_name {
  width: 100%;
  max-width: 650px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.richmessage_alt_text {
  width: 100%;
  max-width: 650px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.richmessage_push_textarea {
  background: $white;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  line-height: 1.8;
  min-height: 150px;
}

.link-list {
  .item + .item {
    margin-top: 20px;
  }
  .row {
    display: flex;
  }
  .input_block {
    width: 550px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
  }
  .fa-warning {
    padding-left: 80px;
  }
  .url {
    padding: 10px 0 0 80px;
    word-break: break-all;
  }
}
@media (max-width: ($media_query_sp)) {
  .link-list {
    .row {
      display: block;
    }
    .input_block {
      width: 100%;
      margin-top: 10px;
    }
    .fa-warning {
      padding-left: 0;
    }
    .url {
      padding: 10px 0 0 0;
    }
  }
}

.link-list {
  .item + .item {
    margin-top: 20px;
  }
  .row {
    display: flex;
  }
  .input_block {
    width: 550px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
  }
  .fa-warning {
    padding-left: 80px;
  }
  .url {
    padding: 10px 0 0 80px;
    word-break: break-all;
  }
}
@media (max-width: ($media_query_sp)) {
  .link-list {
    .row {
      display: block;
    }
    .input_block {
      width: 100%;
      margin-top: 10px;
    }
    .fa-warning {
      padding-left: 0;
    }
    .url {
      padding: 10px 0 0 0;
    }
  }
}

.link-label {
  white-space: nowrap;
  margin-right: 20px;
  padding: 8px;
  font-weight: bold;
}
@media (max-width: ($media_query_sp)) {
  .link-label {
    margin-right: 0;
  }
}
.link-label.-linkA {
  background: #f7f7f7;
  border: 1px solid #d8d8d8;
}
.link-label.-linkB {
  background: #e1f3fa;
  border: 1px solid #90cfef;
}
.link-label.-linkC {
  background: #faf5e1;
  border: 1px solid #edc98e;
}
.link-label.-linkD {
  background: #edfae0;
  border: 1px solid #90ee94;
}

.btn-cancel {
  width: 100%;
  height: 46px;
  margin: 20px auto 0 auto;
}
</style>
