<template>
  <div>
    <div
      v-if="
        isShowOrFlagBox === true ||
        !!searchFilterText ||
        !!flagGroupSearchFilterText ||
        !!flagSurveySearchFilterText
      "
      class="filter_wrapper"
      :class="{ no_border: hasBorder !== true }"
    >
      <div class="header">
        <p class="title">検索条件</p>
        <p
          v-if="hasReset === true"
          class="reset event_text"
          @click="$emit('onReset')"
        >
          <span>検索をリセット</span>
        </p>
      </div>
      <div class="filter_box">
        <p v-if="isShowOrFlagBox === true" class="or_flag">
          OR検索を有効にする
        </p>
        <div
          v-if="
            searchFilterText.length +
              flagGroupSearchFilterText.length +
              flagSurveySearchFilterText.length >=
              MAX_TEXT_LENGTH && showSearchFilterTextAll !== true
          "
        >
          <p v-if="searchFilterText" class="filter_text">
            {{
              searchFilterText.length > MAX_TEXT_LENGTH / 2
                ? searchFilterText.slice(0, MAX_TEXT_LENGTH / 2) + '...'
                : searchFilterText
            }}
          </p>
          <p
            v-if="flagGroupSearchFilterText || flagSurveySearchFilterText"
            class="filter_text"
          >
            {{
              flagGroupSearchFilterText.length +
                flagSurveySearchFilterText.length >
              MAX_TEXT_LENGTH / 2
                ? (
                    flagGroupSearchFilterText + flagSurveySearchFilterText
                  ).slice(0, MAX_TEXT_LENGTH / 2) + '...'
                : flagGroupSearchFilterText + flagSurveySearchFilterText
            }}
          </p>
        </div>
        <div v-else>
          <p v-if="searchFilterText" class="filter_text">
            {{ searchFilterText }}
          </p>
          <p
            v-if="flagGroupSearchFilterText || flagSurveySearchFilterText"
            class="filter_text"
          >
            {{ flagGroupSearchFilterText }}
            {{ flagSurveySearchFilterText }}
          </p>
        </div>
        <template
          v-if="
            searchFilterText.length +
              flagGroupSearchFilterText.length +
              flagSurveySearchFilterText.length >=
            MAX_TEXT_LENGTH
          "
        >
          <p v-if="showSearchFilterTextAll !== true" class="event_text">
            <span @click="showSearchFilterTextAll = true">続きを見る</span>
          </p>
          <p v-else class="event_text">
            <span @click="showSearchFilterTextAll = false">元に戻す</span>
          </p>
        </template>
      </div>
    </div>
    <div
      v-else-if="isShowNoFilterMessage"
      class="filter_wrapper"
      :class="{ no_border: hasBorder !== true }"
    >
      <div class="header">
        <p class="title">検索条件</p>
        <p
          v-if="hasReset === true"
          class="reset event_text"
          @click="emit('onReset')"
        >
          <span>検索をリセット</span>
        </p>
      </div>
      <div class="filter_box">
        <div class="filter_text">※検索条件が見つかりません</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import defineProgressStatus from '@/components/features/searchApplicants/defines/progress-statuses';
import {
  channels,
  genders,
  departmentCategories,
  lineBlocks,
  contactTypes,
  reminderChecks,
} from '@/defines/applicant';
import defineDetailSearchOptions from '@/components/features/searchApplicants/defines/detail-search-options';

export default defineComponent({
  name: 'SearchFilterIndicator',
  props: {
    searchFilterDetail: {
      type: Object,
      default: () => {},
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    tagGroups: {
      type: Array,
      default: () => [],
    },
    surveyTitles: {
      type: Array,
      default: () => [],
    },
    surveyList: {
      type: Array,
      default: () => [],
    },
    hasReset: {
      type: Boolean,
      default: true,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    isShowNoFilterMessage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onReset'],
  setup(props, context) {
    const store = useStore();
    const MAX_TEXT_LENGTH = 96;
    const showSearchFilterTextAll = ref(false);
    // computed
    const isShowOrFlagBox = computed(() =>
      props.searchFilterDetail
        ? props.searchFilterDetail.or_flag === true
        : false,
    );
    const searchFilterText = computed(() => {
      if (
        !props.searchFilterDetail ||
        !props.searchFilterDetail.search_detail_filters
      ) {
        return '';
      }
      return props.searchFilterDetail.search_detail_filters
        .map(filter =>
          generateShapedText(
            filter.name,
            convertSearchValueToJapanese(filter),
            convertSearchOptionToJapanese(filter),
          ),
        )
        .filter(itemText => itemText !== '')
        .join(' / ');
    });
    const flagGroupSearchFilterText = computed(() => {
      if (
        !props.searchFilterDetail ||
        !props.searchFilterDetail.search_flag_groups
      ) {
        return '';
      }
      return props.searchFilterDetail.search_flag_groups
        .map((searchFlagGroup, i) =>
          generateShapedText(
            getJapaneseFromDict(
              [searchFlagGroup.id],
              props.tagGroups,
              'id',
              'name',
            ),
            getJapaneseFromDict(
              searchFlagGroup.flags,
              getFlags(searchFlagGroup.id, null, searchFlagGroup.flags),
              'id',
              'name',
            ),
            convertSearchOptionToJapanese(
              props.searchFilterDetail.search_flag_group_options[i],
            ),
          ),
        )
        .filter(itemText => itemText !== '')
        .join(' / ');
    });
    const flagSurveySearchFilterText = computed(() => {
      if (
        !props.searchFilterDetail ||
        !props.searchFilterDetail.search_surveys
      ) {
        return '';
      }
      return props.searchFilterDetail.search_surveys
        .map((searchSurveyList, i) =>
          generateShapedText(
            searchSurveyList.name_jp,
            props.searchFilterDetail.search_survey_texts[i],
          ),
        )
        .filter(itemText => itemText !== '')
        .join(' / ');
    });

    // methods
    /**
     * 検索条件文字列をフロント表示フォーマットに成形
     * @param {string} key
     * @param {string} value
     * @param {string|null} option
     * @return {string}
     */
    const generateShapedText = (key, value, option = null) => {
      if (key === '' || option === '') return '';
      if (option === null) return `${key}：${value}`;
      if (option === 'が存在しない' || option === 'が存在する') {
        return `${key}${option}`;
      }
      if (value === '') return '';
      return `${key}：${value}${option}`;
    };
    /**
     * idと日本語名が定義された配列から対象id配列の日本語名を取得
     * @param {[number]} ids 取得したい対象id配列
     * @param {[Object]} dict idと日本語名が定義された配列
     * @param {string} keyName idが定義されたキー名
     * @param {string} returnKey 日本語が定義されたキー名
     * @return {string}
     */
    const getJapaneseFromDict = (ids, dict, keyName, returnKey) => {
      const targetTexts = ids.map(id => {
        const targetItemArg = dict.filter(item => item[`${keyName}`] === id);
        if (targetItemArg.length === 1) {
          return targetItemArg[0][`${returnKey}`] ?? '';
        }
        return '';
      });
      const targetText = targetTexts.filter(text => text !== '').join(', ');
      return targetText;
    };
    /**
     * 検索条件の入力値を日本語に変換
     * @param {{ keyName: string, data: string|number|array }} searchFilter
     * @return {string}
     */
    const convertSearchValueToJapanese = searchFilter => {
      if (
        !searchFilter ||
        typeof searchFilter.keyName !== 'string' ||
        !searchFilter.data ||
        ['tags', 'survey_lists'].includes(searchFilter) === true
      ) {
        return '';
      } else if (searchFilter.keyName === 'progressStatusIds') {
        // ステータス
        return getJapaneseFromDict(
          searchFilter.data,
          defineProgressStatus,
          'id',
          'name',
        );
      } else if (searchFilter.keyName === 'channelId') {
        // 登録経路
        return channels[searchFilter.data];
      } else if (searchFilter.keyName === 'contactTypeId') {
        // 連絡方法
        return contactTypes[searchFilter.data];
      } else if (searchFilter.keyName === 'staffId') {
        // 担当者名
        const targetStaff = props.staffs.filter(
          staff => staff.id === searchFilter.data,
        )[0];
        return targetStaff.lastname + targetStaff.firstname ?? '';
      } else if (searchFilter.keyName === 'eventId') {
        // 選考名
        return getJapaneseFromDict(
          [searchFilter.data],
          props.events,
          'id',
          'title',
        );
      } else if (searchFilter.keyName === 'genderId') {
        // 性別
        return getJapaneseFromDict(
          [searchFilter.data],
          genders,
          'id',
          'description',
        );
      } else if (searchFilter.keyName === 'departmentCategoryId') {
        // 文理
        return getJapaneseFromDict(
          [searchFilter.data],
          departmentCategories,
          'id',
          'description',
        );
      } else if (searchFilter.keyName === 'lineBlock') {
        // ブロック状況
        return getJapaneseFromDict(
          [searchFilter.data],
          lineBlocks,
          'id',
          'description',
        );
      } else if (searchFilter.keyName === 'reminderCheck') {
        // 参加確認
        return getJapaneseFromDict(
          [searchFilter.data],
          reminderChecks,
          'id',
          'name',
        );
      }
      return searchFilter.data;
    };
    /**
     * 検索条件のオプションを日本語に変換
     * @param {{ exist: boolean, eq: boolean|number }} searchFilter
     * @return {string}
     */
    const convertSearchOptionToJapanese = searchFilter => {
      if (searchFilter === null) return '';
      const existOptionArg = defineDetailSearchOptions.filter(
        option => option.exist === searchFilter.exist,
      );
      if (existOptionArg.length === 1) return existOptionArg[0].text ?? '';
      const containOptionArg = defineDetailSearchOptions.filter(
        option => option.eq === searchFilter.eq,
      );
      if (containOptionArg.length === 1) return containOptionArg[0].text ?? '';
      if (searchFilter.eq === false || searchFilter.eq === 0) {
        return 'を含まない';
      }
      if (searchFilter.eq === true || searchFilter.eq === 1) {
        return 'を含む';
      }
      return '';
    };
    /**
     * 選択可能なFlagリストを取得
     * @param {Number} flagGroupId
     * @param {Number|null} index
     * @param {Number|null} flags
     * @return {[{ id: Number, selected: Boolean, }]} flags
     */
    const getFlags = (flagGroupId, index, flags = null) => {
      const flagGroup = store.getters['company/companyTagGroups'].find(
        g => g.id === flagGroupId,
      );
      if (!flagGroup) return [];
      return flagGroup.tags.map(flag => {
        const targetFlags =
          index !== null
            ? store.getters['company/companyTagGroups'][index].flags
            : flags;
        return {
          ...flag,
          selected:
            flag.id === targetFlags.find(id => id === flag.id) ? true : false,
        };
      });
    };

    return {
      MAX_TEXT_LENGTH,
      showSearchFilterTextAll,
      isShowOrFlagBox,
      searchFilterText,
      flagGroupSearchFilterText,
      flagSurveySearchFilterText,
    };
  },
});
</script>

<style scoped lang="scss">
.filter_wrapper {
  border-bottom: solid 1px #cccccc;
  padding-bottom: 4px;
  margin-bottom: 10px;

  &.no_border {
    border-bottom: none;
    padding-bottom: 0;
  }

  .title {
    margin-bottom: 10px;
    font-weight: 700;
    color: #777777;
  }
  .sub_title {
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 700;
    color: #777777;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.reset {
  position: relative;
  &::after {
    content: url('../../../../assets/img/batsu_blue.svg');
    width: 1rem;
    margin: 0 0 0 1px;
    position: absolute;
    top: 1px;
    left: -17px;
  }
}

.filter_box {
  background-color: #f5f5f5;
  padding: 14px 16px 10px;
  margin-bottom: 6px;
  border-radius: 4px;
}

.or_flag {
  position: relative;
  margin-bottom: 8px;
  margin-left: 20px;
  &::after {
    content: url('../../../../assets/img/check.svg');
    width: 1.3rem;
    margin: 0 0 0 1px;
    position: absolute;
    left: -22px;
  }
}

.filter_text {
  margin-bottom: 6px;
  line-height: 1.5;
  word-break: break-all;
  white-space: pre-line;
}

.event_text {
  margin-bottom: 6px;
  text-align: right;
  font-size: 14px;
  color: #5896cc;

  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
