<template>
  <div>
    <div class="breadcrumb">
      <span class="chip" @click="$emit('onHomeClick')">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">トップ</span>
      </span>
      <span class="chip" @click="setModeDefault">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">イベントの確認</span>
      </span>
      <span v-if="mode === ('reserve' || 'reserveComplete')" class="chip">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">イベントの予約</span>
      </span>
      <span
        v-if="mode === ('cancelConfirming' || 'cancelComplete')"
        class="chip"
      >
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">イベントのキャンセル</span>
      </span>
    </div>

    <div class="txt-block">
      <div class="bg-bold">{{ generateTitleDescription(mode).title }}</div>
      <div class="desc">{{ generateTitleDescription(mode).description }}</div>
    </div>
    <div class="selection-body">
      <div class="gray-thin-bg" />
      <div v-if="mode === 'default'">
        <div class="txt-block">
          <p class="bold-thin-black top-space1 bottom-space1">
            参加予定のイベント
          </p>
          <div v-if="currentProgress.event && isVisibleProgress">
            <p class="bg-bold">{{ currentProgress.event.title }}</p>
            <div class="gray-bg">
              <dl>
                <dt>会社名：</dt>
                <dd>{{ companyName }}</dd>
              </dl>
              <dl>
                <dt>イベント名：</dt>
                <dd>{{ currentProgress.event.title }}</dd>
              </dl>
              <template v-if="currentProgress.timetable">
                <dl>
                  <dt>日付：</dt>
                  <dd>
                    {{
                      $utils.parseDateTime(
                        currentProgress.timetable.venue.date,
                        'YYYY年M月D日(ddd)',
                      )
                    }}
                  </dd>
                </dl>
                <dl>
                  <dt>時間：</dt>
                  <dd>
                    {{ $utils.parseTime(currentProgress.timetable.start) }}〜{{
                      $utils.parseTime(currentProgress.timetable.last)
                    }}
                  </dd>
                </dl>
                <dl>
                  <dt>会場名：</dt>
                  <dd>{{ currentProgress.timetable.venue.name }}</dd>
                </dl>
                <dl>
                  <dt>住所：</dt>
                  <dd>
                    {{ currentProgress.timetable.venue.pref
                    }}{{ currentProgress.timetable.venue.address }}
                  </dd>
                </dl>
                <dl>
                  <dt>部屋名：</dt>
                  <dd>{{ currentProgress.timetable.venue.room_name }}</dd>
                </dl>
                <dl>
                  <dt>連絡事項：</dt>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <dd v-html="$utils.sanitize(currentProgress.notice)" />
                </dl>
              </template>
            </div>
            <div
              v-if="
                !!currentProgress.timetable &&
                currentProgress.timetable.zoom_join_url
              "
              class="web_meeting_area"
            >
              <p class="mid-spacer" />
              <p class="web_meeting-title">Web面接への参加</p>
              <p class="web_meeting-text">
                今回の選考はZoomによるWeb会議を使って行います。下のボタンから参加してください。
              </p>
              <a
                :href="currentProgress.timetable.zoom_join_url"
                target="_blank"
                rel="noopener"
              >
                <button>
                  <p>Web面接へ参加</p>
                </button>
              </a>
            </div>
            <p class="mid-spacer" />
            <div class="centered">
              <template v-if="currentProgress.timetable">
                <button
                  v-if="!isReservation && isCancel"
                  class="blue-underscore"
                  @click="changeMode('cancelConfirming')"
                >
                  イベントの参加をキャンセル
                </button>
              </template>
              <template v-else>
                <button-round
                  v-if="isReservation"
                  class="btn-round"
                  button-text="会場と時間を選択"
                  :has-arrow="true"
                  @click="getSelectionAssignedReservationList"
                />
              </template>
            </div>
          </div>
          <div v-else>
            <div class="no-data space2">参加予定のイベントはありません</div>
            <div class="centered top-space2">
              <button-round
                v-if="isReservation && isVisibleProgress"
                class="btn-round"
                button-text="イベントの予約"
                :has-arrow="true"
                @click="getSelectionReservationList"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="mode === 'reserve'">
        <div class="form_body">
          <form class="form-wrapper">
            <div class="selections">
              <div class="selections_desc">
                <span>
                  次の中から
                  <b>参加をご希望のイベント</b>
                  を1つ選択してください。
                </span>
              </div>
              <ul class="selections_wrapper">
                <li
                  v-for="selectionReservation in selectionReservationList"
                  :key="selectionReservation.id"
                  :class="{
                    selections_active:
                      selectedSelection.id === selectionReservation.id,
                  }"
                  class="selections_item"
                  @click="showVenues(selectionReservation)"
                >
                  {{ selectionReservation.event.title }}
                </li>
              </ul>
            </div>
            <div v-if="isVisibleVenue" id="id_venue">
              <div class="selection_venue_desc">
                <span>
                  <span class="bold">{{ selectedSelection.event.title }}</span>
                  での参加を希望する、日時・会場を選択してください。
                </span>
              </div>
              <div class="selection_notice">
                <b>{{ companyName }}からの連絡事項</b>
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="selection_notice_content"
                  v-html="$utils.sanitize(selectedSelection.notice)"
                />
                <!--eslint-enable-->
              </div>
              <ul class="selection_venue">
                <li
                  v-for="venue in selectedSelection.venues"
                  :key="venue.id"
                  :class="{ venue_active: selectedVenue.id === venue.id }"
                  class="selection_venue_item"
                  @click="showTimetables(venue)"
                >
                  <div class="selection_venue_item-date">
                    <b>
                      {{
                        $utils.parseDateTime(venue.date, 'YYYY年M月D日(ddd)')
                      }}
                    </b>
                  </div>
                  <div class="selection_venue_item-name">
                    <div>{{ venue.name }}</div>
                    <span v-if="selectedVenue.id === venue.id">選択中</span>
                  </div>
                  <div class="selection_venue_item-address">
                    {{ venue.address }}
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="isVisibleTime" id="id_time" class="selection_time">
              <h2 class="selection_time_desc">
                選択した会場で参加を希望する時間をお選びください。
              </h2>
              <ul class="selection_time_item_wrapper">
                <li
                  v-for="time in openVenueTimetables()"
                  :key="time.id"
                  :class="{ time_active: selectedTime.id === time.id }"
                  class="selection_time_item"
                  @click="showConfirm(time)"
                >
                  {{ time.start }}〜{{ time.last }}
                </li>
              </ul>
            </div>
            <div v-if="isVisibleConfirm" id="id_all">
              <h2 class="check_selection_desc">
                以下の内容で日程を決定します。よろしければ送信ボタンをクリックしてください。
              </h2>
              <div class="txt-block">
                <div class="gray-bg b-margin2">
                  <dl>
                    <dt>会社名：</dt>
                    <dd>{{ companyName }}</dd>
                  </dl>
                  <dl>
                    <dt>イベント名：</dt>
                    <dd>{{ selectedSelection.event.title }}</dd>
                  </dl>
                  <dl>
                    <dt>日付：</dt>
                    <dd>
                      {{
                        $utils.parseDateTime(
                          selectedVenue.date,
                          'YYYY年M月D日(ddd)',
                        )
                      }}
                    </dd>
                  </dl>
                  <dl>
                    <dt>時間：</dt>
                    <dd>
                      {{ $utils.parseTime(selectedTime.start) }}〜{{
                        $utils.parseTime(selectedTime.last)
                      }}
                    </dd>
                  </dl>
                  <dl>
                    <dt>会場名：</dt>
                    <dd>{{ selectedVenue.name }}</dd>
                  </dl>
                  <dl>
                    <dt>住所：</dt>
                    <dd>{{ selectedVenue.pref }}{{ selectedVenue.address }}</dd>
                  </dl>
                  <dl>
                    <dt>部屋名：</dt>
                    <dd>{{ selectedVenue.room_name }}</dd>
                  </dl>
                </div>
                <button-round
                  class="btn-round"
                  button-text="上記の内容で送信"
                  :has-arrow="true"
                  :is-disabled="isLoading === true"
                  @click="createReserveSelection"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-else-if="mode === 'cancelConfirming'">
        <div class="txt-block">
          <p class="bold-thin-black top-space1 bottom-space1">
            キャンセルするイベント
          </p>
          <p class="bg-bold">{{ currentProgress.event.title }}</p>
          <div class="gray-bg">
            <dl>
              <dt>会社名：</dt>
              <dd>{{ companyName }}</dd>
            </dl>
            <dl>
              <dt>イベント名：</dt>
              <dd>{{ currentProgress.event.title }}</dd>
            </dl>
            <dl>
              <dt>日付：</dt>
              <dd>
                {{
                  $utils.parseDateTime(
                    currentProgress.timetable.venue.date,
                    'YYYY年M月D日(ddd)',
                  )
                }}
              </dd>
            </dl>
            <dl>
              <dt>時間：</dt>
              <dd>
                {{ $utils.parseTime(currentProgress.timetable.start) }}〜{{
                  $utils.parseTime(currentProgress.timetable.last)
                }}
              </dd>
            </dl>
            <dl>
              <dt>会場名：</dt>
              <dd>{{ currentProgress.timetable.venue.name }}</dd>
            </dl>
            <dl>
              <dt>住所：</dt>
              <dd>
                {{ currentProgress.timetable.venue.pref
                }}{{ currentProgress.timetable.venue.address }}
              </dd>
            </dl>
            <dl>
              <dt>部屋名：</dt>
              <dd>{{ currentProgress.timetable.venue.room_name }}</dd>
            </dl>
            <dl>
              <dt>連絡事項：</dt>
              <dd v-html="$utils.sanitize(currentProgress.notice)" />
            </dl>
          </div>
          <p class="mid-spacer" />
          <p class="desc bottom-space2">
            イベントへの参加をキャンセルする場合は、「イベントへの参加をキャンセル」を押してください。
          </p>
          <button-round
            class="btn-round btn-cancel"
            button-text="イベントへの参加をキャンセル"
            button-type="danger"
            :is-disabled="isLoading === true"
            @click="postCancelCurrentEvent"
          />
          <button-round
            class="btn-round btn-cancel"
            button-text="イベントの確認に戻る"
            button-type="secondary"
            @click="setModeDefault"
          />
        </div>
      </div>
      <div v-else-if="mode === 'cancelComplete'">
        <div class="txt-block">
          <p class="sm-bold-blue">参加をキャンセルしました</p>
          <p class="desc">予約済みのイベントへの参加をキャンセルしました。</p>
          <p class="mid-spacer" />
          <button-round
            class="btn-round"
            button-text="イベントの確認に戻る"
            :is-outlined="true"
            @click="setModeDefault"
          />
        </div>
      </div>
      <div v-else-if="mode === 'reserveComplete'">
        <div class="txt-block">
          <div class="gray-bg">
            <dl>
              <dt>会社名：</dt>
              <dd>{{ companyName }}</dd>
            </dl>
            <dl>
              <dt>イベント名：</dt>
              <dd>{{ selectedSelection.event.title }}</dd>
            </dl>
            <dl>
              <dt>日付：</dt>
              <dd>
                {{
                  $utils.parseDateTime(selectedVenue.date, 'YYYY年M月D日(ddd)')
                }}
              </dd>
            </dl>
            <dl>
              <dt>時間：</dt>
              <dd>
                {{ $utils.parseTime(selectedTime.start) }}〜{{
                  $utils.parseTime(selectedTime.last)
                }}
              </dd>
            </dl>
            <dl>
              <dt>会場名：</dt>
              <dd>{{ selectedVenue.name }}</dd>
            </dl>
            <dl>
              <dt>住所：</dt>
              <dd>{{ selectedVenue.pref }}{{ selectedVenue.address }}</dd>
            </dl>
            <dl>
              <dt>部屋名：</dt>
              <dd>{{ selectedVenue.room_name }}</dd>
            </dl>
            <dl>
              <dt>連絡事項：</dt>
              <dd v-html="$utils.sanitize(selectedSelection.notice)" />
            </dl>
          </div>
          <p class="mid-spacer" />
          <button-round
            class="btn-round"
            button-text="イベントの確認に戻る"
            :is-outlined="true"
            @click="setModeDefault"
          />
        </div>
      </div>
    </div>
    <local-page-loader v-if="isLoading" :is-full-size="true" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import MyPageService from '@/services/mypage';
import ButtonRound from '@/components/page/mypage/components/ButtonRound.vue';

export default defineComponent({
  name: 'Selection',
  components: { LocalPageLoader, ButtonRound },
  props: {
    token: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
  },
  emits: ['onHomeClick'],

  setup(props, context) {
    const store = useStore();
    const mode = ref('default'); // 'default' | 'reserve' | 'reserveComplete' | 'cancelConfirming' | 'cancelComplete'
    const currentProgress = ref({});
    const selectionReservationList = ref([]);
    const selectedSelection = ref({});
    const selectedVenue = ref({});
    const selectedTime = ref({});
    const isReservation = ref(false);
    const isVisibleProgress = ref(true);
    const isCancel = ref(false);
    const isLoading = ref(false);
    const isVisibleVenue = ref(false);
    const isVisibleTime = ref(false);
    const isVisibleConfirm = ref(false);

    onMounted(async () => {
      await getCurrentSelection();
    });

    const openVenueTimetables = () => {
      if (selectedVenue.value && selectedVenue.value.timetables) {
        return selectedVenue.value.timetables.map(timetable => ({
          id: timetable.id,
          limit: timetable.limit,
          start: timetable.start.slice(0, -3),
          last: timetable.last.slice(0, -3),
        }));
      }
      return null;
    };

    const scrollDown = elementId => {
      setTimeout(() => {
        document
          .getElementById(elementId)
          .scrollIntoView({ behavior: 'smooth' });
      }, 100);
    };

    const showVenues = selectionObj => {
      if (selectionObj) {
        isVisibleVenue.value = true;
        isVisibleTime.value = false;
        isVisibleConfirm.value = false;
        selectedVenue.value = {};
        selectedTime.value = {};
        selectedSelection.value = selectionObj;
        scrollDown('id_venue');
      }
    };

    const showTimetables = venueObj => {
      if (venueObj) {
        selectedVenue.value = venueObj;
        isVisibleTime.value = true;
        isVisibleConfirm.value = false;
        selectedTime.value = {};
        scrollDown('id_time');
      }
    };

    const showConfirm = timeObj => {
      if (timeObj) {
        selectedTime.value = timeObj;
        isVisibleConfirm.value = true;
        scrollDown('id_all');
      }
    };

    const generateTitleDescription = modeName => {
      const returnText = {
        title: 'イベントの確認',
        description:
          '参加予定のイベント情報の確認・キャンセル・登録を行えます。',
      };
      switch (modeName) {
        case 'default':
          break;
        case 'reserve':
          returnText.title = 'イベントの予約';
          returnText.description =
            '参加可能なイベントがある場合予約ができます。';
          break;
        case 'reserveComplete':
          returnText.title = 'イベントの予約完了';
          returnText.description = 'イベントの参加予約が完了しました。';
          break;
        case 'cancelConfirming':
          returnText.title = 'イベントのキャンセル';
          returnText.description =
            '予約済みのイベントへの参加をキャンセルします。この操作は取り消せません。';
          break;
        case 'cancelComplete':
          returnText.title = 'イベントのキャンセル';
          returnText.description = '';
          break;
        default:
          break;
      }
      return returnText;
    };

    const changeMode = modeName => {
      mode.value = modeName;
      window.scrollTo(0, 0);
    };

    const setModeDefault = async () => {
      await getCurrentSelection();
      changeMode('default');
    };

    const getCurrentSelection = async () => {
      const res = await MyPageService.getCurrentSelection({
        token: props.token,
      });
      if (res.success === false) {
        await store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        isReservation.value = false;
        return;
      }
      currentProgress.value = res.current_progress;
      isVisibleProgress.value = res.current_progress.mypage_visible
        ? res.current_progress.mypage_visible
        : isVisibleProgress.value;
      isCancel.value = res.current_progress.is_cancel
        ? res.current_progress.is_cancel
        : isCancel.value;
      if (
        !currentProgress.value.progress_status_id ||
        currentProgress.value.progress_status_id === 0 ||
        currentProgress.value.progress_status_id === 1 ||
        currentProgress.value.progress_status_id === 2
      ) {
        isReservation.value = true;
        return;
      }
      isReservation.value = false;
    };

    const getSelectionReservationList = async () => {
      const res = await MyPageService.getSelectionReservationList({
        token: props.token,
      });
      if (res.success === false) {
        await store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      res.selections.forEach(selection => {
        selection.venues.sort((a, b) => new Date(a.date) - new Date(b.date));
      });
      selectionReservationList.value = res.selections;
      changeMode('reserve');
    };

    const getSelectionAssignedReservationList = async () => {
      const res = await MyPageService.getSelectionReservationList({
        token: props.token,
      });
      if (res.success === false) {
        await store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      res.selections.forEach(selection => {
        selection.venues.sort((a, b) => new Date(a.date) - new Date(b.date));
      });
      selectionReservationList.value = res.selections;
      changeMode('reserve');
      // イベントが確定しているので選択済みにさせる
      showVenues(res.selections[0]);
    };

    const createReserveSelection = async () => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const payload = {
        token: props.token,
        timetable_id: selectedTime.value.id,
      };
      const res = await MyPageService.postSelectionReservation(payload);
      isLoading.value = false;
      if (res.success === false) {
        await store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      changeMode('reserveComplete');
    };

    const postCancelCurrentEvent = async () => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const payload = {
        token: props.token,
        progress_id: currentProgress.value.id,
        venue_id: currentProgress.value.timetable.venue.id,
        time_id: currentProgress.value.timetable.id,
      };
      const res = await MyPageService.postSelectionCancel(payload);
      isLoading.value = false;
      if (res.success === false) {
        await store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      changeMode('cancelComplete');
    };

    return {
      mode,
      currentProgress,
      selectionReservationList,
      selectedSelection,
      selectedVenue,
      selectedTime,
      isReservation,
      isVisibleProgress,
      isCancel,
      isLoading,
      isVisibleVenue,
      isVisibleTime,
      isVisibleConfirm,
      openVenueTimetables,
      generateTitleDescription,
      scrollDown,
      showVenues,
      showTimetables,
      showConfirm,
      changeMode,
      setModeDefault,
      getCurrentSelection,
      getSelectionAssignedReservationList,
      getSelectionReservationList,
      createReserveSelection,
      postCancelCurrentEvent,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.breadcrumb {
  padding: 1.5rem 0 1.2rem;
  margin: 0 1.5rem;
  border-bottom: 1px solid $border_color;
  .chip {
    padding: 0.5rem 0;
    cursor: pointer;
    > span {
      padding: 0 0.32rem;
    }
    .chip_title {
      font-size: 1rem;
    }
    .link {
      color: $mochica_color;
      font-weight: bold;
    }
    .arrow-icon {
      width: 15px;
      height: 15px;
      padding-right: 0.5rem;
    }
  }
}

.btn-cancel {
  margin-bottom: 1.5rem;
}
.selections_wrapper {
  border: none;
  padding: 0;
  margin-top: 2rem;
  flex-wrap: wrap;
  font-size: 1.2rem;
}

.desc {
  padding: 0.5rem 0;
  font-size: 1.5rem;
  line-height: 1.8rem;
}
.sm-bold-blue {
  padding: 1rem 0;
  font-size: 1.6rem;
  color: $mochica_color;
  font-weight: bold;
}
.txt-block {
  padding: 1.5rem;
}
.centered {
  @include flex_center_center;
}
.blue-underscore {
  text-decoration: underline;
  color: $mochica_color;
  font-size: 1.4rem;
  padding: 1rem;
  cursor: pointer;
  align-self: center;
  text-align: center;
}
.gray-thin-bg {
  height: 15px;
  background-color: $mochica_light_gray_bg;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.bg-bold {
  white-space: pre-line;
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 1rem;
}
.mid-spacer {
  padding-top: 3rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 3rem;
}
.sm-spacer {
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid $border_color;
}
.bold-thin-black {
  color: $dropped_color;
  font-weight: bold;
}
.sm-bold-blue {
  padding: 1rem 0;
  font-size: 1.6rem;
  color: $mochica_color;
  font-weight: bold;
}
.top-space1 {
  padding-top: 1rem;
}
.top-space2 {
  padding-top: 2rem;
}
.space2 {
  padding: 2rem 0;
}
.b-margin2 {
  margin-bottom: 2rem;
}
.bottom-space1 {
  padding-bottom: 1rem;
}
.bottom-space2 {
  padding-bottom: 2rem;
}
.no-data {
  text-align: center;
  border-radius: 0.5rem;
  background-color: $mochica_light_gray_bg;
  padding: 4rem 5rem;
  font-size: 1.4rem;
  color: $dropped_color;
  border: 1px solid $border_color;
}
.gray-bg {
  border: 1px solid $border_color;
  border-radius: 0.5rem;
  background-color: $mochica_light_gray_bg;
  padding: 1rem;
  dl {
    padding: 1rem;
    display: flex;
    text-align: left;
    dt {
      font-weight: bold;
      min-width: 90px;
      text-align: right;
    }
    dd {
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;
      line-height: 1.2;
    }
  }
}
.form_body {
  .selections_active {
    border-color: $mochica_color !important;
    color: $mochica_color !important;
    background: $white !important;
    font-weight: bold !important;
  }
  .selections_desc {
    @include pseudo-number-stepper();
    text-align: left;
  }
  .selections_wrapper {
    margin-top: 2rem;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 1.2rem;
  }
  .selections_item {
    cursor: pointer;
    border: 1px solid $border_color;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    height: 6rem;
    border-radius: 0.2rem;
    width: 100%;
    background: $mochica_light_gray_bg;
    font-size: 1.4rem;
    transition: all ease 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border: 1px solid $border_color;
    }
  }
  .selection_notice {
    display: block;
    margin: 1.5rem;
    padding: 1.5rem;
    text-align: left;
    background: $mochica_light_gray_bg;
    border: 1px solid $border_color;
    border-radius: 0.3rem;
    .selection_notice_content {
      white-space: pre-wrap;
      word-wrap: break-word;
      line-height: 1.3;
    }
  }
  .form-wrapper {
    background-color: $white;
    .selection_venue {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 1.4rem;
      padding: 0 1.5rem;
    }
    .selection_venue_desc {
      @include pseudo-number-stepper($content-number: '2');
      text-align: left;
    }
    .selection_venue_item {
      width: 100%;
      padding: 0.5rem 0;
      cursor: pointer;
      transition: all ease 0.2s;
      position: relative;
      &:hover {
        opacity: 0.6;
      }
    }
    .selection_venue_item-date {
      color: $black;
      font-size: 1.4rem;
      padding: 1rem 0;
      text-align: left;
    }
    .selection_venue_item-name {
      background: #656565;
      color: $white;
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      border-radius: 0.4rem 0.4rem 0 0;
      span {
        font-weight: lighter;
      }
    }
    .selection_venue_item-address {
      text-align: left;
      margin-top: -0.1rem;
      border: 1px solid #656565;
      padding: 1rem;
      border-radius: 0 0 0.4rem 0.4rem;
    }
    .venue_active {
      .selection_venue_item-name {
        background-color: $mochica_color !important;
      }
      .selection_venue_item-address {
        border-color: $mochica_color !important;
      }
    }
    .time_active {
      border-color: $mochica_color !important;
      background-color: $white !important;
      color: $mochica_color !important;
      font-weight: bold !important;
    }
    .selection_time_desc {
      @include pseudo-number-stepper($content-number: '3');
    }
    .selection_time_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $border_color;
      width: 100%;
      height: 6rem;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 1.4rem;
      font-weight: lighter;
      padding: 1.5rem;
      border-radius: 0.2rem;
      transition: all ease 0.3s;
      background-color: $mochica_light_gray_bg;
      &:hover {
        border: 1px solid #656565;
        color: #656565;
      }
    }

    .selection_time_item_wrapper {
      padding: 2rem 2rem 1rem;
      border-radius: 0.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .check_selection_desc {
      @include pseudo-number-stepper($content-number: '4');
      text-align: left;
    }
    .regist_btn {
      margin-top: 3rem;
      transition: all ease 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.web_meeting_area {
  .web_meeting-title {
    font-size: 20px;
    font-weight: 700;
    color: #2a8bbc;
    margin-bottom: 16px;
  }

  .web_meeting-text {
    font-size: 18px;
    line-height: 180%;
    margin-bottom: 16px;
  }

  button {
    width: 100%;
    border-radius: 100px 100px 100px 100px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #3b9bcc;

    p {
      margin: 32px 0;
      font-size: 18px;
      color: $white;

      &::before {
        content: '';
        mask-image: url(../../../../assets/img/icon_video.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        display: inline-block;
        position: relative;
        background-color: $white;
        height: 20px;
        width: 20px;
        margin-right: 6px;
        top: 3px;
      }
    }
  }
}
</style>
