<template>
  <balloon-menu
    :is-view="isView"
    :top="'30px'"
    :suspend-close-event="isVisibleDate"
    @close="$emit('onClose')"
  >
    <template #content>
      <div class="balloon-content">
        <div class="header">
          <p>選考の登録状態で絞り込む</p>
        </div>
        <div class="balloon-body">
          <drop-down
            :placeholder="'選考名を選択'"
            :selected-id="eventId"
            :list="eventIds"
            class="selection-name"
            :is-view="isVisibleEventId"
            @onOpen="isVisibleEventId = true"
            @onClose="isVisibleEventId = false"
            @onSelected="onSelectedEventId"
          />
          <el-date-picker
            :model-value="eventDate"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            class="event-date"
            placeholder="開催日を選択"
            @update:model-value="eventDate = $event"
            @visible-change="isVisibleDate = $event"
          />
          <div
            class="btn btn-availability"
            @click="$emit('onSearch', { event_id: eventId, date: eventDate })"
          >
            <img class="icon" src="@/assets/img/search_white.svg" alt="" />
            <span>上記の条件で検索を行う</span>
          </div>
          <div class="btn btn-unavailable" @click="reset">
            <span>検索をリセットする</span>
          </div>
        </div>
      </div>
    </template>
  </balloon-menu>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

import eventsService from '@/services/events';
import BalloonMenu from '@/components/ui/menus/components/BalloonMenu';
import DropDown from '@/components/ui/menus/components/DropDown';

// 選考絞り込み検索ポップアップ
export default defineComponent({
  name: 'FloatSelectionsForm',
  components: { BalloonMenu, DropDown },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    initGraduatedId: {
      type: Number,
      default: null,
    },
  },
  emits: ['onClose', 'onSearch', 'onReset'],
  setup(props, context) {
    const store = useStore();
    const eventId = ref(null);
    const eventDate = ref(null);
    const events = ref([]);
    const isVisibleEventId = ref(false);
    const isVisibleDate = ref(false);
    // computed
    const eventIds = computed(() =>
      events.value.map(event =>
        Object.assign({}, event, {
          selected: event.id === eventId.value,
          name: event.title,
        }),
      ),
    );
    // methods
    const reset = () => {
      eventId.value = null;
      eventDate.value = null;
      context.emit('onReset');
    };
    const onSelectedEventId = payload => {
      eventId.value = payload.item.id;
      isVisibleEventId.value = false;
    };
    // lifecycle
    onBeforeMount(
      async () =>
        (events.value = await eventsService.fetchEvents(
          props.initGraduatedId !== null
            ? props.initGraduatedId
            : store.getters['graduateds/selectedGraduatedId'],
        )),
    );

    return {
      eventId,
      eventDate,
      events,
      isVisibleEventId,
      isVisibleDate,
      eventIds,
      reset,
      onSelectedEventId,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';

.balloon-content {
  padding: 20px;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  svg {
    height: 2.4rem;
    fill: $mochica_color;
    margin-right: 5px;
  }
}
.selection-name {
  width: 350px;
  margin-bottom: 10px;
}
.event-date {
  width: 350px;
  margin-bottom: 20px;
}
.event-date :deep(input) {
  height: auto;
  padding: 8px 30px 9px;
}
.btn {
  // width: 100%;
  display: block;
}
.btn-availability {
  margin-bottom: 10px;
  > .icon {
    margin-right: 6px;
  }
}

.btn-availability {
  margin-top: 10px;
}
:deep(.el-input) {
  width: 100%;
}
</style>
