export const mapFlagGroup = flagGroups => {
  return (flagGroups.tag_groups || []).map(tagGroup => ({
    id: tagGroup.tag_group_id,
    name: tagGroup.tag_group_name,
    isContain: tagGroup.is_contain,
    flags: tagGroup.tags.map(tag => ({
      id: tag.id,
      name: tag.tag_name,
    })),
  }));
};

export const mapTriggerConditions = triggerConditions => {
  const { selection, richmessage, flagsurvey, notification, result } =
    triggerConditions;
  return {
    assignProgress: selection
      ? {
          id: selection.id,
          title: selection.event_title,
          description: selection.description,
        }
      : null,
    results: result
      ? {
          passed: {
            email: {
              subject: result.passed.mail_subject,
              content: result.passed.mail_content,
            },
            line: { content: result.passed.line_content },
          },
          offered: {
            email: {
              subject: result.offered.mail_subject,
              content: result.offered.mail_content,
            },
            line: { content: result.offered.line_content },
          },
          dropped: {
            email: {
              subject: result.dropped.mail_subject,
              content: result.dropped.mail_content,
            },
            line: { content: result.dropped.line_content },
          },
        }
      : null,
    richMessage: richmessage
      ? {
          id: richmessage.id,
          title: richmessage.title,
          image_url: richmessage.image_url,
        }
      : null,
    flagSurvey: flagsurvey
      ? {
          id: flagsurvey.id,
          title: flagsurvey.title,
        }
      : null,
    notice: notification
      ? {
          email: {
            subject: notification.mail_subject,
            content: notification.mail_content,
          },
          line: { content: notification.line_content },
          isSendForceEmail: notification.force_email,
          isConfirmMessage: notification.notice_check,
          files: mapFiles(notification.upload_files),
        }
      : null,
  };
};

export const mapApplicantStatus = (
  progressStatuses,
  finishedProgressStatuses,
) => {
  return [
    ...progressStatuses.map(progress => progress.name),
    ...finishedProgressStatuses.map(progress => progress.name),
  ];
};

export const mapFiles = files => {
  return files.map(file => {
    return {
      id: file.id,
      fileName: file.filename,
      fileSize: file.size,
      fileUrl: file.image_url,
    };
  });
};

export const formatAutoActionsDetailToState = data => {
  const { selection } = data;
  const { tags, progress_status_ids, finished_progress_status_ids } =
    data.trigger_conditions;
  return {
    actionName: data.name,
    actionType: data.action_type,
    actionStatus: data.status,
    applicantStatus: mapApplicantStatus(
      progress_status_ids,
      finished_progress_status_ids,
    ),
    selection: {
      id: selection.id,
      title: selection.event_title,
      description: selection.description,
    },
    flagGroups: mapFlagGroup(tags),
    triggerDelayDay: data.trigger_delay_days,
    triggerDelayTime: data.trigger_time,
    ...mapTriggerConditions(data.action_conditions),
  };
};

export const formatAutoActionsLogsDetailToState = data => {
  return {
    executedAt: data.executed_at,
    historyTitle: data.history_title,
    totalApplicants: data.total_applicants,
    ...formatAutoActionsDetailToState(data),
  };
};
