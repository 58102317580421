import httpClient from '@/http-client';

const Admin = function _Admin() {
  const admin = Object.create(Admin.prototype);
  return admin;
};

Admin.prototype = {
  async getCompanyAdminAnalytics() {
    const res = await httpClient.get('/admin');
    return res.data;
  },
  async getInquiryList(payload) {
    const response = await httpClient.get('/admin/inquiry', payload);
    return response.data;
  },
  async updateLoginContents(payload) {
    const res = await httpClient.post('/admin/contents_update', payload, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    return res.data;
  },
  async updateInquiryContets(payload) {
    const res = await httpClient.post('/admin/inquiry_update', {
      menus: payload,
    });
    return res.data;
  },
  async updateStaffEmail(payload) {
    const res = await httpClient.post('/admin/edit_staff_email', {
      staff_id: payload.staff_id,
      email: payload.email,
      company_id: payload.company_id,
    });
    return res.data;
  },
  async updateStaffRole(payload) {
    const res = await httpClient.post('/admin/edit_staff_role', {
      staff_id: payload.staff_id,
      role: payload.role,
      company_id: payload.company_id,
    });
    return res.data;
  },
  async fetchStaffsByCompanyId(payload) {
    const res = await httpClient.post('/admin/company_staffs', {
      company_id: payload.company_id,
    });
    return res.data;
  },
  async deleteStaff(payload) {
    const res = await httpClient.post('/admin/edit_staff_delete', {
      staff_id: payload.staff_id,
      company_id: payload.company_id,
    });
    return res.data;
  },
  async getDownloadCompanyCsv() {
    const res = await httpClient.get('/admin/download');
    return res.data;
  },
  async fetchDeleToApplicantsId({ company_id, selected_plan_id }) {
    const res = await httpClient.post('/admin/delete_to_applicants_id', {
      company_id,
      selected_plan_id,
    });
    return res.data;
  },
  async downloadDeleteToApplicantsCsv({ company_id, selected_plan_id }) {
    const res = await httpClient.post('/admin/delete_to_applicants_csv', {
      company_id,
      selected_plan_id,
    });
    return res.data;
  },
  async changePlan({ company_id, selected_plan_id }) {
    const res = await httpClient.post('/admin/change_plan', {
      company_id,
      selected_plan_id,
    });
    return res.data;
  },
  async getCompanies(page, limit) {
    const res = await httpClient.get(
      `/admin/companies?page=${page}&limit=${limit}`,
    );
    return res.data;
  },
  async createCompany(
    email,
    lineAccessToken,
    name,
    comPlan,
    comTel,
    zipcode,
    address,
    lineChannelSecret,
    lineId,
    lineQr,
    lineUrl,
    staffemail,
    lastname,
    tel,
    firstname,
    fileupload,
    storagesize,
    emailFlag,
  ) {
    const res = await httpClient.post('/admin/companies', {
      company: {
        name,
        plan: comPlan,
        line_access_token: lineAccessToken,
        email,
        zipcode,
        address,
        line_channel_secret: lineChannelSecret,
        line_id: lineId,
        tel: comTel,
        qr: lineQr,
        line_url: lineUrl,
        fileupload,
        storagesize,
        email_flag: emailFlag,
      },
      staff: { email: staffemail, lastname, firstname, tel },
    });
    return res.data;
  },
  async getCompany(companyId) {
    const res = await httpClient.get(`/admin/companies/${companyId}`);
    return res.data;
  },
  async updateCompany(
    companyId,
    deactive,
    fileupload,
    issue,
    emailFlag,
    isLineCooperateUser,
    issueAt,
    storagesize,
    email,
    lineAccessToken,
    name,
    comTel,
    zipcode,
    address,
    lineChannelSecret,
    lineId,
    lineQr,
    lineUrl,
    webhook,
    lastname,
    firstname,
    tel,
    staffemail,
  ) {
    const res = await httpClient.patch(`/admin/companies/${companyId}`, {
      company: {
        deactive,
        fileupload,
        issue,
        issue_at: issueAt,
        storagesize,
        email,
        line_access_token: lineAccessToken,
        name,
        tel: comTel,
        zipcode,
        address,
        line_channel_secret: lineChannelSecret,
        line_id: lineId,
        qr: lineQr,
        line_url: lineUrl,
        webhook,
        email_flag: emailFlag,
        is_line_cooperate_user: isLineCooperateUser,
      },
      staff: { lastname, firstname, tel, email: staffemail },
    });
    return res.data;
  },
  async getCompanyCountPlan() {
    const res = await httpClient.get('/admin/count_plan');
    return res.data;
  },
  async deleteApplicants(payload) {
    const res = await httpClient.delete('/admin/applicants', {
      data: { company_id: payload.company_id },
    });
    return res.data;
  },
  async postAdminApplicantsCsvDownload(obj) {
    const res = await httpClient.post('/admin/applicants/csv/download', obj);
    return res.data;
  },
};

export default Admin();
