<script setup>
import { ref } from 'vue';
import { useField } from 'vee-validate';

import RichMessageListModal from '@/components/features/richMessage/RichMessageListModal.vue';
import RichMessageDetailModal from '@/components/features/richMessage/RichMessageDetailModal.vue';

const isViewRichMessageListModal = ref(false);
const isViewRichMessageDetailModal = ref(false);

const { value: id, errorMessage } = useField('richMessage.id');
const { value: name } = useField('richMessage.name');
const { value: img } = useField('richMessage.img');

const handleSelectRichMessage = payload => {
  id.value = payload.id;
  name.value = payload.name;
  img.value = `${payload.image_url}/240`;
  isViewRichMessageListModal.value = false;
};
</script>

<template>
  <div class="action-inner">
    <div class="action-title">
      <h3 class="title">リッチメッセージを通知</h3>
      <div class="desc">LINEのリッチメッセージを応募者に通知します。</div>
    </div>
    <div class="action-contents auto-action">
      <div class="gray-box">
        <div :class="{ error: !!errorMessage }" class="button-wrapper">
          <div class="button-inner">
            <button-base
              type="button"
              :button-text="'リッチメッセージを選択'"
              :icon-file-name="'plus_circle'"
              @click="isViewRichMessageListModal = true"
            />
          </div>
          <div v-show="!!errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
        <div v-if="id" class="gray-box-inner">
          <ul class="items">
            <li class="item">
              <div class="title">選択中のリッチメッセージ</div>
              <div class="contents">
                <div class="side-contents">
                  <img class="side-contents-img" :src="img" :alt="name" />
                  <div class="side-contents-name">{{ name }}</div>
                  <div class="side-contents-check">
                    <button-base
                      type="button"
                      :button-text="'確認'"
                      @click="isViewRichMessageDetailModal = true"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="empty">リッチメッセージを選択してください</div>
      </div>
    </div>
    <rich-message-list-modal
      :is-view="isViewRichMessageListModal"
      @close-modal="isViewRichMessageListModal = false"
      @select-rich-message="handleSelectRichMessage({ ...$event })"
    />
    <rich-message-detail-modal
      :is-view="isViewRichMessageDetailModal"
      :rich-message-id="id"
      @close-modal="isViewRichMessageDetailModal = false"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;

.rich-message-inner {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 320px;

  .rich-message-img {
    display: block;
    width: 32px;
    height: auto;
    border: 1px solid #ededed;
    border-radius: 4px;
  }
  .rich-message-check {
    width: fit-content;
    margin: 0 0 0 auto;
    .btn-base {
      padding: 4px 16px 2px;
      font-size: $font_14;
    }
  }
}
</style>
