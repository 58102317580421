<template>
  <div class="modal-window -typeB">
    <div class="header">
      <p class="title">
        {{
          presetName
            ? '応募者検索プリセット編集'
            : '応募者検索プリセット新規作成'
        }}
      </p>
    </div>
    <div class="body">
      <p class="preset_edit_description">
        プリセット名入れて保存してください。
      </p>
      <dl class="preset_edit_name">
        <dt>プリセット名</dt>
        <dd>
          <input
            v-model="localPresetName"
            type="text"
            placeholder="プリセット名"
            maxlength="30"
            :class="{
              'is-danger': errors.presetName.isValid !== true,
            }"
            @blur="validateAll"
          />
          <p v-if="errors.presetName.isValid !== true" class="fa-warning">
            {{ errors.presetName.message }}
          </p>
        </dd>
      </dl>
      <p class="preset_edit_caption">※検索条件に適切な名前をつけてください</p>
      <search-filter-indicator
        :search-filter-detail="presetSearchFilterObject"
        :events="events"
        :tag-groups="tagGroups"
        :survey-titles="surveyTitles"
        :staffs="staffs"
        :has-reset="false"
        :has-border="false"
        :is-show-no-filter-message="true"
      />
      <div class="ar-actionBtns">
        <button-base
          class="btn btn-availability"
          button-text="保存"
          :is-loading="isLoading"
          :is-disabled="errors.presetName.isValid !== true"
          :rejected-role-ids="[3]"
          :rejected-admin-ids="[2]"
          @click="save"
        />
        <button class="btn btn-unavailable" @click="$emit('close')">
          キャンセル
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import SearchFilterPresetsService from '@/services/search_filter_presets';
import useValidation from '@/composables/useValidation';
import SearchFilterIndicator from '@/components/features/searchApplicants/components/SearchFilterIndicator.vue';

export default defineComponent({
  name: 'SearchApplicantPresetEdit',
  components: { SearchFilterIndicator },
  props: {
    presetId: {
      type: Number,
      default: null,
    },
    presetName: {
      type: String,
      default: '',
    },
    presetSearchFilterObject: {
      type: Object,
      default: () => {},
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    tagGroups: {
      type: Array,
      default: () => [],
    },
    surveyTitles: {
      type: Array,
      default: () => [],
    },
    currentPresetId: {
      type: Number,
      default: null,
    },
  },
  emits: ['close', 'fetchSearchFilterPresets', 'updateCurrentPreset'],
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const localPresetName = ref(props.presetName);
    const { errors, resetErrors, validatePresetName } = useValidation([
      'presetName',
    ]);

    // methods
    const validateAll = () => {
      resetErrors();
      return validatePresetName('presetName', localPresetName.value);
    };
    const save = async () => {
      if (validateAll() !== true) return;
      if (isLoading.value === true) return;
      isLoading.value = true;
      if (props.presetId === null) {
        // プリセット名新規作成
        const res = await SearchFilterPresetsService.createSearchFilterPresets({
          name: localPresetName.value,
          search_filter_obj: props.presetSearchFilterObject,
        });
        if (res.success === true) {
          context.emit('fetchSearchFilterPresets');
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
          return;
        }
      } else {
        // プリセット名更新
        const res = await SearchFilterPresetsService.updateSearchFilterPresets(
          props.presetId,
          { name: localPresetName.value },
        );
        if (res.success === true) {
          context.emit('fetchSearchFilterPresets');
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
          return;
        }
      }
      if (errors.presetName.isValid === true) context.emit('close');
      isLoading.value = false;
      // 選択中のプリセット名が更新された場合
      if (props.currentPresetId === props.presetId) {
        context.emit('updateCurrentPreset', {
          id: props.presetId,
          name: localPresetName.value,
        });
      }
    };

    return { isLoading, localPresetName, errors, validateAll, save };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.modal-window.-typeB {
  width: calc(100vw - 20px);
  max-width: 1070px;
}
.header,
.body {
  width: 100%;
}

.preset_edit_description {
  margin-bottom: 15px;
  font-size: 16px;
}

.preset_edit_name {
  > dt {
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: bold;
  }
  > dd {
    margin-bottom: 10px;
    input {
      width: 100%;
    }
  }
}

.fa-warning {
  font-size: 12px;
}

.preset_edit_caption {
  margin-bottom: 20px;
  color: #777;
}

.ar-actionBtns {
  text-align: center;
  border-top: 1px solid #ccc;
  padding-top: 30px;
  > .btn-availability {
    display: block;
    width: 100%;
    padding: 20px;
    cursor: pointer;
    &:disabled {
      background-color: #999;
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  > .btn-unavailable {
    width: 100%;
    max-width: 320px;
    margin: 20px 0 0;
    padding: 20px 30px;
  }
}
</style>
