import httpClient from '@/http-client';

const FlagSurveyManager = function _FlagSurveyManager() {
  const manager = Object.create(FlagSurveyManager.prototype);
  return manager;
};

FlagSurveyManager.prototype = {
  async fetchFlagSurveys(payload) {
    const res = await httpClient.get(
      `/flagsurvey?page=${payload.page}&limit=${payload.limit}`,
    );
    return res.data;
  },
  async createFlagSurvey(payload) {
    const res = await httpClient.post('/flagsurvey', payload);
    return res.data;
  },
  async getFlagSurvey(payload) {
    const res = await httpClient.get(`/flagsurvey/${payload.flagsurvey_id}`);
    return res.data;
  },
  async updateFlagSurvey(payload) {
    const res = await httpClient.put(
      `/flagsurvey/${payload.flagsurvey_id}`,
      payload,
    );
    return res.data;
  },
  async deleteFlagSurvey(payload) {
    const res = await httpClient.delete(`/flagsurvey/${payload.flagsurvey_id}`);
    return res.data;
  },
  async sendFlagSurvey(payload) {
    const res = await httpClient.post(
      `/flagsurvey/${payload.flagsurvey_id}/send`,
      payload,
    );
    return res.data;
  },
  async fetchFlagsurveyQuestions(token) {
    const res = await httpClient.get(`/flagsurvey_ext?token=${token}`);
    return res.data;
  },
  async postFlagsurveyAnswers(payload) {
    const res = await httpClient.post('/flagsurvey_ext', payload);
    return res.data;
  },
};

export default FlagSurveyManager();
