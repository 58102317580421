<template>
  <main class="input_email">
    <p class="input_email_title">メールアドレスの登録</p>
    <p class="input_email_caption">
      メールアドレスを入力し、「メールアドレスを登録」ボタンを押してください。
    </p>
    <div class="input_wrapper">
      <p class="input_head">メールアドレス</p>
      <input
        v-model="email"
        :class="{ input: true, 'is-danger': !errors.email.isValid }"
        type="email"
        class="input_email"
        placeholder="メールアドレスを入力"
        maxlength="254"
      />
      <span v-show="!errors.email.isValid" class="fa fa-warning font-s">
        {{ errors.email.message }}
      </span>
    </div>
    <div class="input_footer">
      <button class="submit_button" @click.prevent="verifyEmail">
        メールアドレスを登録
      </button>
      <p v-if="isRequiredEmail === false" class="input_skip_button">
        <a @click.prevent="skipSendEmail">この入力をスキップ</a>
      </p>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'InputEmail',
  props: {
    isRequiredEmail: {
      type: Boolean,
      default: false,
    },
    isEntered: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['verifyEmail', 'skipSendEmail', 'registerEmail'],
  setup(props, context) {
    const store = useStore();
    const email = ref('');
    const { errors, resetErrors, validateRequire, validateEmail } =
      useValidation(['email']);

    // methods
    const validate = () => {
      resetErrors();
      let isValid = true;
      isValid = validateEmail('email', email.value);
      isValid = validateRequire('email', email.value);
      if (isValid === false) {
        return {
          success: false,
          message: '入力内容を確認してください',
        };
      }
      return {
        success: true,
        message: '',
      };
    };
    const verifyEmail = () => {
      const valid = validate();
      if (valid.success === true) {
        if (props.isEntered === true) {
          // エントリーフォーム入力済みの場合はEmailを登録して終了
          context.emit('registerEmail', email.value);
        } else {
          context.emit('verifyEmail', email.value);
        }
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: valid.message,
          type: false,
        });
      }
    };
    const skipSendEmail = () => {
      email.value = '';
      context.emit('skipSendEmail');
    };

    return { email, errors, verifyEmail, skipSendEmail };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.input_email {
  display: flex;
  min-height: calc(100vh - 300px - 15px);
  flex-flow: column;
  font-size: 14px;
}

.input_email_title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.input_email_caption {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 4px solid #f0f0f0;
}

.input_wrapper {
  margin: 0 0 auto;
}

.input_head {
  margin: 0 auto 3px 0;
  font-weight: bold;
}

.input {
  width: 100%;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  margin-bottom: 30px;
  font-size: 16px;
  & + .fa-warning {
    display: block;
    margin-top: -30px;
    margin-bottom: 30px;
  }
}

.input_footer {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #f0f0f0;
}

.submit_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 24px;
  font-weight: bold;
  color: #fff;
  background: #545454;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  cursor: pointer;
}

.input_skip_button {
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}
</style>
