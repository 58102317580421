<template>
  <section class="as-block">
    <div class="headline">
      <img
        class="icon"
        width="22"
        height="22"
        src="@/assets/img/send_mail_circle_white.svg"
      />
      連絡事項の送信履歴
    </div>
    <div class="inner">
      <div class="overflow">
        <table class="sendHistory-table">
          <thead>
            <tr>
              <th>
                <span>送信日</span>
              </th>
              <th>
                <span>連絡事項タイトル</span>
              </th>
              <th>
                <span>送信先</span>
              </th>
              <th>
                <span>担当者</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in noticeHistory"
              :key="item.id"
              @click="openModalNoticeDetail(item)"
            >
              <td>
                <span>
                  {{
                    $utils.parseDateTime(
                      item.applicant_notices[0].announced_at,
                      'YYYY/MM/DD HH:mm',
                    )
                  }}
                </span>
              </td>
              <td>
                <span>
                  {{ $utils.textEllipsis(defineChange(item.notice_title)) }}
                </span>
              </td>
              <td v-if="item.applicant_notices.length === 1">
                <span>
                  {{ item.applicant_notices[0].applicant.lastname }}
                  {{ item.applicant_notices[0].applicant.firstname }}
                </span>
              </td>
              <td v-else>
                <span>
                  {{ item.applicant_notices[0].applicant.lastname }}
                  {{ item.applicant_notices[0].applicant.firstname }}、 他{{
                    item.applicant_notices.length - 1
                  }}名
                </span>
              </td>
              <td>
                <span>
                  {{ item.staff === null ? '' : item.staff.lastname }}
                  {{ item.staff === null ? '' : item.staff.firstname }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-notice-detail
      :is-visible="isOpenModalNoticeDetail"
      :item="clickedItem.data"
      :is-applicant-detail-view="true"
      @onClickCancel="onClickCancel"
    />
  </section>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import NotificationService from '@/services/notifications';
import ModalNoticeDetail from '@/components/features/modalNoticeDetail/components/ModalNoticeDetail';

export default defineComponent({
  name: 'NoticeHistory',
  components: { ModalNoticeDetail },
  props: {
    applicantId: {
      type: Number,
      default: null,
    },
    applicantObj: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const store = useStore();
    const noticeHistory = ref([]);
    const isOpenModalNoticeDetail = ref(false);
    const clickedItem = reactive({
      data: {
        applicant_notices: [
          {
            announced_at: '',
            applicant: { id: null, lastname: '', firstname: '' },
          },
        ],
        staff: { lastname: '', firstname: '' },
      },
    });

    // methods
    const defineChange = item => {
      const applicantName =
        props.applicantObj.firstname == null
          ? props.applicantObj.lastname
          : `${props.applicantObj.lastname} ${props.applicantObj.firstname}`;
      const strText = item
        .replace(/\[applicant_name\]/g, applicantName)
        .replace(/\[company_name\]/g, store.getters['company/company'].name);
      return strText;
    };
    const openModalNoticeDetail = item => {
      clickedItem.data = item;
      isOpenModalNoticeDetail.value = true;
    };
    const onClickCancel = () => {
      isOpenModalNoticeDetail.value = false;
    };

    // lifecycle
    onBeforeMount(async () => {
      const res = await NotificationService.getApplicantActivityNotice(
        props.applicantId,
      );
      noticeHistory.value = res.list.filter(
        list => list.applicant_notices[0].announced_at !== null,
      );
      await store.dispatch('page/SET_LOADED');
    });

    return {
      noticeHistory,
      clickedItem,
      isOpenModalNoticeDetail,
      defineChange,
      openModalNoticeDetail,
      onClickCancel,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.as-block {
  margin-bottom: 20px;
  background-color: #fff;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  .inner {
    padding: 30px 20px;
  }
}

.overflow {
  max-height: 400px;
  overflow-x: auto;
  overflow-y: scroll;
}

.sendHistory-table {
  width: 100%;
  font-size: 1.4rem;
  white-space: nowrap;
  thead {
    background-color: #e2e2e2;
    th {
      padding: 15px;
      text-align: left;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e2e2e2;
      cursor: pointer;
    }
    tr:hover {
      background-color: #f1f1f1;
    }
    td {
      padding: 15px;
    }
  }
}
</style>
