<template>
  <div>
    <div v-if="isPageValid" class="form_wrapper">
      <div class="form_inner_contents">
        <div class="txt-block">
          <p class="sm-bold">{{ companyName }}</p>
        </div>
        <div class="name_container">
          <p class="txt-white sm-text">マイページ</p>
          <p class="bg-bold txt-white">{{ applicantName }}さん</p>
        </div>
        <div class="mypage-body">
          <div v-if="mode === 'Home'">
            <div class="txt-block">
              <p class="desc">
                マイページでは参加するイベントの確認や、履歴書などのファイル管理などを行えます。
              </p>
              <p class="border-bottom-md-space-top" />
            </div>
          </div>
          <div v-if="mode === 'Home'">
            <div class="txt-block">
              <div class="md-text bold-thin-black">
                マイページのメニュー一覧
              </div>
              <div class="btn-group">
                <button-round
                  class="btn-round"
                  button-text="イベントの確認・予約"
                  :has-arrow="true"
                  @click="mode = 'Selection'"
                />
                <button-round
                  class="btn-round"
                  button-text="ファイル管理"
                  :has-arrow="true"
                  @click="mode = 'File'"
                />
                <button-round
                  class="btn-round"
                  button-text="登録情報の確認"
                  :has-arrow="true"
                  @click="mode = 'Setting'"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <setting
              v-if="mode === 'Setting'"
              :applicant="applicant"
              :applicant-name="applicantName"
              :token="token"
              @onHomeClick="mode = 'Home'"
            />
            <my-page-file-upload
              v-if="mode === 'File'"
              :token="token"
              :applicant-name="applicantName"
              :company-name="companyName"
              @onHomeClick="mode = 'Home'"
            />
            <selection
              v-if="mode === 'Selection'"
              :token="token"
              :company-name="companyName"
              @onHomeClick="mode = 'Home'"
            />
          </div>
        </div>
        <div class="mypage-footer" />
      </div>
      <notification />
    </div>
    <div v-else-if="isLoading">
      <div class="centered">
        <div class="message-area">
          <div class="loading-message">
            {{ '読み込み中です' }}
          </div>
          <div>{{ 'ページが切り変わらない場合は、' }}</div>
          <div>{{ '再度、＜マイページ＞と入力してください。' }}</div>
        </div>
      </div>
    </div>
    <div v-else-if="!isPageValid" class="form_wrapper">
      <div class="centered">
        <div class="message-area">
          <div>
            {{
              message === 'トークンがない、または不正です'
                ? 'このURLは期限が切れています。'
                : message
            }}
          </div>
          <div>
            {{
              message === 'トークンがない、または不正です'
                ? '再度、＜マイページ＞と入力してください。'
                : message
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs';

import { defineComponent, ref, onBeforeMount } from 'vue';

import MyPageService from '@/services/mypage';
import Notification from '@/components/ui/notification/components/Notification';
import Setting from '@/components/page/mypage/components/Profile.vue';
import MyPageFileUpload from '@/components/page/mypage/components/MyPageFileUpload.vue';
import Selection from '@/components/page/mypage/components/Selection.vue';
import ButtonRound from '@/components/page/mypage/components/ButtonRound.vue';

export default defineComponent({
  name: 'MyPage',
  components: {
    Notification,
    Setting,
    MyPageFileUpload,
    Selection,
    ButtonRound,
  },

  setup() {
    const applicantName = ref('');
    const message = ref('');
    const mode = ref('Home'); // 'Home' | 'Selection' | 'File' | 'Setting'
    const token = ref('');
    const applicant = ref(null);
    const companyName = ref('');
    const isLoading = ref(true);
    const isPageValid = ref(false);

    onBeforeMount(async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      token.value = query.token;
      const res = await MyPageService.getApplicant({
        token: query.token,
      });
      if (res.success === false) {
        message.value = res.message;
        isLoading.value = false;
        return;
      }
      isPageValid.value = true;
      applicant.value = res.applicant;
      companyName.value = res.applicant.company.name;
      applicantName.value = res.applicant.full_name;
      isLoading.value = false;
    });

    const getApplicantData = async token => {
      const applicantData = await MyPageService.getApplicant({ token });
      if (applicantData.success) {
        isPageValid.value = false;
        message.value = 'トークンが不正です。';
        return;
      }
      applicant.value = applicantData.applicant;
    };

    return {
      applicantName,
      message,
      mode,
      token,
      applicant,
      companyName,
      isLoading,
      isPageValid,
      getApplicantData,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.centered {
  @include flex_center_center;
  height: 100vh;
}
.message-area {
  text-align: center;
  line-height: 1.5;
  .loading-message {
    margin-bottom: 40px;
  }
}
.form_wrapper {
  width: 100vw;
  height: 100%;
  /* min-height: 100vh; */
  max-width: 500px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: (823px)) {
    width: 100%;
    max-width: 100%;
    width: 100vw;
  }
}
.form_inner_contents {
  width: 100vw;
  min-height: 100vh;
  background: white;
  .name_container {
    background: url(../../components/page/mypage/assets/img/mypage-bg.png)
      no-repeat;
    background-size: cover;
    padding: 1.5rem;
  }

  .border-bottom-sm-space-top {
    padding-top: 1rem;
    border-bottom: 1px solid $border_color;
  }
  .border-bottom-md-space-top {
    padding-top: 1.5rem;
    border-bottom: 1px solid $border_color;
  }

  .sm-text {
    font-size: 1rem;
    padding: 0.5rem 0;
  }
  .bold-thin-black {
    color: $dropped_color;
    font-weight: bold;
  }
  .btn-group {
    padding-top: 1rem;
  }

  .btn-round {
    margin-bottom: 1rem;
  }

  .md-text {
    padding: 0.5rem 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .txt-block {
    padding: 1.5rem;
  }
  .txt-block-border-bottom {
    padding: 1.5rem 0 1.2rem;
    margin: 0 1.5rem;
    border-bottom: 1px solid $border_color;
  }
  .sm-bold {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .desc {
    padding: 0.5rem 0;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .back-home {
    padding: 0.5rem 0;
    cursor: pointer;
    display: flex;
    color: $mochica_color;
    font-size: 1.2rem;
    font-weight: bold;
    .arrow-icon {
      width: 15px;
      height: 15px;
      padding-right: 0.5rem;
    }
  }
  .bg-bold {
    white-space: pre-line;
    font-size: 2rem;
    font-weight: bold;
  }
  .txt-white {
    color: white;
  }
}
.mypage-footer {
  height: 60px;
}
</style>
