const initModalDialog = {
  isView: false,
  alertFlag: false,
  accentColor: '#1899D6',
  title: '',
  message: '',
  buttonText1: 'キャンセル',
  buttonText2: 'はい',
  onSelected: () => {},
  onClickOuter: () => {},
  // ** 既存処理でmodal表示時にapp要素にclass="modal_displayed"が設定されるのを防ぐ設定
  // modal_displayedは下階層でmodal表示するときにcssのpositionによって画面にずれが発生するために用意されたもの。
  isDisabledShowModalDisplayed: false,
};

export default {
  namespaced: true,
  state: {
    modalDialog: initModalDialog,
  },
  getters: {
    modalDialog: currentState => currentState.modalDialog,
  },
  mutations: {
    VISIBLE_DIALOG(state, payload) {
      if (!state.modalDialog.isView) {
        Object.assign(state, {
          modalDialog: {
            ...payload,
            isView: true,
          },
        });
      }
    },
    INVISIBLE_DIALOG(state) {
      Object.assign(state, {
        modalDialog: initModalDialog,
      });
    },
  },
  actions: {
    async VISIBLE_DIALOG({ commit }, payload) {
      await commit('VISIBLE_DIALOG', payload);
    },
    async INVISIBLE_DIALOG({ commit }) {
      await commit('INVISIBLE_DIALOG');
    },
  },
};
