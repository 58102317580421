<template>
  <button
    class="btn-round"
    :class="generateBtnClass(buttonType, isOutlined, hasArrow)"
    :disabled="isDisabled === true"
    @click="$emit('click')"
  >
    <span>{{ buttonText }}</span>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

const defineButtonType = ['primary', 'secondary', 'danger'];

export default defineComponent({
  name: 'ButtonRound',
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    buttonType: {
      type: String,
      default: 'primary',
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup() {
    const generateBtnClass = (btnType, isOutlined, hasArrow) => {
      let classString = defineButtonType.includes(btnType)
        ? `btn-${btnType}`
        : `btn-primary`;

      if (isOutlined === true) {
        classString = classString.concat(` btn-outlined`);
      }

      if (hasArrow === true) {
        classString = classString.concat(` btn-arrow`);
      }

      return classString;
    };

    return { generateBtnClass };
  },
});
</script>

<style scoped lang="scss">
$btnPrimaryColor: #16b2d9 !default;
$btnSecondaryColor: #999 !default;
$btnDangerColor: #bf0000 !default;
$btnOutlinedColor: #fff !default;
$btnDisabledColor: #999 !default;

$standardFontColor: #fff !default;
$disabledFontColor: #333 !default;

$fontSize: 1.4rem !default;
$btnHeight: 70px;

@mixin buttonDesign($btnColor) {
  background-color: $btnColor;
  &.btn-outlined {
    color: $btnColor;
    border: solid 1px $btnColor;
    span::after {
      background-color: $btnColor;
    }
  }
}
button.btn-round {
  display: inline-block;
  width: 100%;
  height: $btnHeight;
  border-radius: calc(#{$btnHeight} / 2);
  text-align: center;
  color: $standardFontColor;
  font-size: $fontSize;
  font-weight: bold;
  line-height: $btnHeight;
  cursor: pointer;

  &.btn-arrow {
    position: relative;

    &::after {
      content: '';
      mask-image: url('../../../../assets/img/arrow_white_r.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      display: inline-block;
      position: absolute;
      background-color: $standardFontColor;
      height: calc(#{$btnHeight} / 3.2);
      width: 20px;
      top: calc(#{$btnHeight} / 3);
      right: 5%;
    }
  }
  &.btn-primary {
    @include buttonDesign($btnPrimaryColor);
  }

  &.btn-secondary {
    @include buttonDesign($btnSecondaryColor);
  }

  &.btn-danger {
    @include buttonDesign($btnDangerColor);
  }

  &.btn-outlined {
    background-color: $btnOutlinedColor;
  }

  &:disabled {
    @include buttonDesign($btnDisabledColor);
    color: $disabledFontColor;
    border: none;
    opacity: 0.3;
    cursor: not-allowed;

    &::after {
      background-color: $disabledFontColor;
    }
  }
}
</style>
