<template>
  <div class="row">
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第1条（定義）</h5>
        <p class="p_2">
          1.「MOCHICA」とは、株式会社ネオキャリア（以下、「当社」といいます）が提供するWEBプラットフォームサービスの総称をいいます。サービス内容は、以下の各号に定めるとおりとします。
        </p>
        <br />
        <p class="p_3">
          1)
          利用企業と求職者間の採用活動または利用企業による職業紹介における相互のコミュニケーションを円滑にし、採用活動状況を効率的に管理すること
        </p>
        <br />
        <p class="p_3">
          2)
          利用企業による広報活動、マーケティング活動における利用企業と登録ユーザーとの相互コミュニケーション、ならびに利用企業の主催するイベント集客およびスケジュール調整を行うこと
        </p>
        <br />
        <p class="p_2">2.「ユーザー」とは、MOCHICAを利用する個人をいいます。</p>
        <br />
        <p class="p_2">
          3.「利用企業」とは、当社との間でMOCHICA利用に関する契約を締結した法人をいいます。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第2条（MOCHICAの利用）</h5>
        <p class="p_2">
          1.&nbsp;ユーザーは、MOCHICAの利用を開始した時点で、MOCHICA利用規約（以下、「利用規約」といいます）および当社のプライバシーポリシーの内容をすべて承諾したものとみなされます。
        </p>
        <br />
        <p class="p_2">
          2.&nbsp;当社は、利用規約を事前の予告なく変更、追加又は削除することがあります。この場合において、当社は、MOCHICA上で変更後の規約を掲示するものとし、ユーザーは、当該変更後にMOCHICAを利用した場合、変更後の本規約に同意したとみなされるものとします。
        </p>
        <br />
        <p class="p_2">
          3.&nbsp;ユーザーは、MOCHICAの利用を開始するに先立ち、LINE株式会社の提供するメッセージングサービス「LINE」又はMOCHICAにて利用するその他のメッセージングサービスの利用者でなければなりません。
        </p>
        <br />
        <p class="p_2">
          4.&nbsp;当社は「LINE」又はMOCHICAにて利用するその他のメッセージングサービスのサービス提供者とはならず、「LINE」及びMOCHICAにて利用するその他のメッセージングサービスに起因する一切の不具合やユーザーが蒙った損害を保証しないものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第3条（ユーザーの責任）</h5>
        <p class="p_2">
          1.&nbsp;ユーザーは、自らの意思および責任をもってMOCHICAを利用するものとします。
        </p>
        <br />
        <p class="p_2">
          2.&nbsp;ユーザーがMOCHICAに登録する情報は、ユーザー自らがその内容の正確性等、一切の責任を負うものとします。
        </p>
        <br />
        <p class="p_2">
          3.&nbsp;ユーザーは、利用企業との日程調整の確約、求人企業に採用されること等を当社が保証しないことを了承したうえでMOCHICAを利用するものとします。
        </p>
        <br />
        <p class="p_2">
          4.&nbsp;ユーザーがMOCHICAの利用により、利用企業または第三者に対して損害を生じさせた場合には、ユーザーの責任において解決し、当社は一切の責任を負わないものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第4条（ユーザーの禁止事項）</h5>
        <p class="p_2">
          1.&nbsp;ユーザーはMOCHICAを利用するにあたり、以下の各行為またはその恐れのある行為をしてはならないものとします。
        </p>
        <br />
        <p class="p_3">（1）犯罪行為または犯罪行為に結びつく行為</p>
        <br />
        <p class="p_3">
          （2）当社または第三者の著作権、商標権その他の知的財産権、プライバシー権、名誉等の権利を侵害する行為
        </p>
        <br />
        <p class="p_3">（3）当社または第三者を差別または誹謗中傷する行為</p>
        <br />
        <p class="p_3">（4）MOCHICAを営利または商業目的で利用する行為</p>
        <br />
        <p class="p_3">
          （5）MOCHICAの提供のためのシステムへの不正アクセス等、MOCHICAの運営を妨げる行為
        </p>
        <br />
        <p class="p_3">
          （6）MOCHICA内コンテンツの全部または一部を、当社に無断で、複製、複写、転載、転送、蓄積、販売、出版、その他ユーザー個人の私的利用の範囲を超えて利用する行為
        </p>
        <br />
        <p class="p_3">
          （7）MOCHICAの利用権を第三者に再許諾、譲渡し、または、担保に供する行為
        </p>
        <br />
        <p class="p_3">
          （8）MOCHICAのリバースエンジニアリング、逆コンパイル、逆アセンブル、その他これらに準じる行為
        </p>
        <br />
        <p class="p_3">（9）当社または第三者の信用を損なう行為</p>
        <br />
        <p class="p_3">（10）他人になりすまして、MOCHICAを利用する行為</p>
        <br />
        <p class="p_3">（11）法令、公序良俗若しくは利用規約に違反する行為</p>
        <br />
        <p class="p_3">
          （12）当社の承認した以外の方法により、MOCHICAを利用する行為
        </p>
        <br />
        <p class="p_3">（13）当社または第三者に対する迷惑行為</p>
        <br />
        <p class="p_3">（14）その他当社が不適切と判断する行為</p>
        <br />
        <p class="p_2">
          2.&nbsp;ユーザーが前項各号の1つにでも該当した場合、当社は何らの催告なく利用規約を解除し、MOCHICAの提供を停止するものとします。なお、当社のユーザーに対する損害賠償請求を妨げません。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第5条（MOCHICAの提供情報）</h5>
        <p class="p_2">
          1.&nbsp;MOCHICAにおいて提供される情報（利用企業より提供される説明会、選考日程、面談日程、選考状況、その他広報・マーケティング活動に関する情報）は、利用企業の責任で提供されるもので、ユーザーは、提供情報の真実性、最新性、合法性、安全性、有用性、適切性について当社が何ら保証しないことを承諾し、自己の責任において利用するものとします。
        </p>
        <br />
        <p class="p_2">
          2.&nbsp;当社は、MOCHICAの提供に不具合や障害が生じないこと、MOCHICAから得られる情報等が正確なものであること、MOCHICAから得られる役務および情報等がユーザーの期待を満たすものであることのいずれについても保証しないものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第6条（免責事項）</h5>
        <p class="p_2">
          1.&nbsp;当社はMOCHICAを提供するのみであって、利用企業の採用、職業紹介、または広報・マーケティングの対象当事者にはなりません。但し、当社が別途提供する職業紹介サービス等において、MOCHICAを使用することを否定するものではありません。
        </p>
        <br />
        <p class="p_2">
          2.&nbsp;当社は、MOCHICAの利用（これらに伴う当社または利用企業または第三者の情報提供等を含みます）または利用できないことから生じる損害（精神的苦痛、求職活動の中断、その他の金銭的損失を含む一切の不利益）について、一切の責任を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          3.&nbsp;当社は、MOCHICAを通じてアクセスできる利用企業またはその他の第三者等が提供するサイトおよびサービスにより生じる損害について、一切の責任を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          4.&nbsp;当社は、利用企業とユーザーとの間で行われる連絡、交渉、情報提供等のすべてのやりとりと、そのやりとりが確実に相手方に到達していることについて介入および管理を行いません。
        </p>
        <br />
        <p class="p_2">
          4.&nbsp;当社は、利用企業とユーザーとの間で行われる連絡、交渉、情報提供等のすべてのやりとりと、そのやりとりが確実に相手方に到達していることについて介入および管理を行いません。
        </p>
        <br />
        <p class="p_2">
          5.&nbsp;当社は、通常講ずるべきウィルス対策では防止できないウィルス被害、コンピュータまたは通信回線等の事故による被害、天変地異による被害、その他当社の責めによらない事由により生じた被害について、一切の責任を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          6.&nbsp;当社は、MOCHICAにおけるデータが消去・変更されないことを保証できません。ユーザーは、必要なデータを自己の責任において保存するものとします。
        </p>
        <br />
        <p class="p_2">
          7.&nbsp;当社は、MOCHICAを介し提供される新卒紹介企業および求人企業の財務内容の優良性、事業体としての存続性、事業の適法性、社会的妥当性、または利用企業の広報・マーケティングに関する情報の適正性、信頼性、合目的性について何ら保証しないものとします。
        </p>
        <br />
        <p class="p_2">
          8.&nbsp;ユーザーは、MOCHICAを利用するにあたり、MOCHICAからのダウンロードおよびその他の方法によりソフトウェア等をユーザーのコンピュータ等にインストールした際に生じた損害について一切の責任を負うものとし、その損害について当社は一切の責任を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          9.&nbsp;当社が責任を負う場合であっても、当社に故意または重過失がある場合を除き、賠償の範囲はユーザーに現実に生じた、直接かつ通常の損害に限られるものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第7条（個人情報の利用）</h5>
        <p class="p_2">
          ユーザーは、MOCHICAを利用することで、別途掲示する当社のプライバシーポリシーその他個人情報の取扱（Cookieによる情報収集を含みます）に関する条件に同意したものとみなされることを承諾します。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第8条（登録情報の利用）</h5>
        <p class="p_2">
          当社は、MOCHICAに提供されたユーザーの登録情報のうち、氏名、住所、電話番号、メールアドレス等個人を特定する情報を除いた情報を、当社が編集・発行する著作物等に二次使用することができるものとします。この場合、二次著作物の著作権は、当社に帰属します。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第9条（再委託）</h5>
        <p class="p_2">
          当社は、MOCHICAの適切な運営及び管理を行うため、必要に応じてユーザーに対するサービスの全部または一部の業務を第三者に委託できるものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第10条（MOCHICAの変更等）</h5>
        <p class="p_2">
          1.&nbsp;当社は、ユーザーに対し事前に通知することなく、MOCHICAの変更、中断および中止を行うことができるものとします。
        </p>
        <br />
        <p class="p_2">
          2.&nbsp;前項により、ユーザーに損害が発生した場合でも、当社はユーザーに対して何ら責任を負わないものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第11条（損害賠償の請求）</h5>
        <p class="p_2">
          ユーザーが利用規約に反した行為、または不正または違法にMOCHICAを利用することにより、当社に損害を与えた場合、当社は該当ユーザーに対して損害賠償の請求を行う場合があります。
        </p>
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第12条（反社会的勢力）</h5>
        <p class="p_2">
          1.&nbsp;ユーザーは、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
        </p>
        <br />
        <p class="p_3">
          (1) 暴力団員等が経営を支配していると認められる関係を有すること
        </p>
        <br />
        <p class="p_3">
          (2) 暴力団員等が経営に実質的に関与していると認められる関係を有すること
        </p>
        <br />
        <p class="p_3">
          (3)
          自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
        </p>
        <br />
        <p class="p_3">
          (4)
          暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
        </p>
        <br />
        <p class="p_3">
          (5)
          役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
        </p>
        <br />
        <p class="p_3">
          (3)
          自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
        </p>
        <br />
        <p class="p_2">
          2.&nbsp;ユーザーは、自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。
        </p>
        <br />
        <p class="p_3">(1) 暴力的な要求行為</p>
        <br />
        <p class="p_3">(2) 法的な責任を超えた不当な要求行為</p>
        <br />
        <p class="p_3">
          (3) 取引に関して、脅迫的な言動をし、または暴力を用いる行為
        </p>
        <br />
        <p class="p_3">
          (4)
          風説を流布し、偽計を用いまたは威力を用いて当社、他のユーザー、その他第三者の信用を毀損し、または当社、他のユーザー、その他第三者の業務を妨害する行為
        </p>
        <br />
        <p class="p_3">(5) その他前各号に準ずる行為</p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第13条（準拠法および管轄）</h5>
        <p class="p_2">
          利用規約の準拠法は日本法とし、利用規約に関して生じる一切の紛争については、その訴額に応じ東京地方裁判所または東京簡易裁判所を第一審の専属的管轄裁判所とします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">プライバシーポリシー</h5>
        <p class="p_2">
          株式会社ネオキャリアは一般財団法人日本情報経済社会推進協会（JIPDEC）より個人情報保護に取り組む企業を示す「プライバシーマーク」を取得しています。
        </p>
        <br />
        <p class="p_2">
          当社は、転職・就職支援、人材派遣、及びそれらに関するコンサルティング、コンテンツ制作・ソフトウェア開発企業としての責務を認識し、誠実にお客様のニーズに応え、広く社会に貢献したいという基本理念の下、利用者様からお預かりする個人情報保護の重要性を全従業者が認識し、日本工業規格「個人情報保護に関する個人情報保護マネジメントシステムの要求事項」（JISQ15001）に準拠した以下の取組みを実施し、維持します。
        </p>
        <br />
        <p class="p_3">
          1.
          求職者（利用者）、お取引先企業様、当社従業者の個人情報について、「個人情報保護管理責任者」を設置し、適切な保護を行います。
        </p>
        <br />
        <p class="p_3">
          2.
          自社採用、当社の事業である転職支援、就職支援、人材派遣、関連する各種コンテンツ・ソフトウェアの企画・制作・維持管理、就職情報誌の発行、就職イベントの企画・運営、採用コンサルティング上必要な範囲に限定し、適切な手段で、個人情報を取得、利用、提供いたします。個人情報の取得時には、利用目的、
          及び、問い合せに対する窓口を明確にします。
        </p>
        <br />
        <p class="p_3">
          3.
          個人情報は、原則として法律に基づく命令等を除いて、特定された利用目的の達成に必要な範囲を超えた個人情報の取り扱いは行いません。また、目的外の利用を行わないよう、適切かつ必要な措置を講じます。
        </p>
        <br />
        <p class="p_3">
          4.
          個人情報への不正アクセス、個人情報の紛失、破壊、改竄、滅失、毀損及び漏洩などのリスクに対しては、合理的な安全対策を講じ、予防並びに是正を行います。
        </p>
        <br />
        <p class="p_3">
          5.
          個人情報を取り扱う業務をほかの会社に委託する場合、個人情報を取得する時の承諾に基づく利用、提供、安全管理を守るように、委託先に対する適切な契約や指導・管理をします。
        </p>
        <br />
        <p class="p_3">
          6.
          当社が保有する個人情報に関する苦情及び相談の適切な対応のみならず、開示、訂正、提供範囲の変更や削除を本人から依頼された場合には、適切な本人確認をさせていただいた上で、合理的な範囲ですみやかに対応します。
        </p>
        <br />
        <p class="p_3">
          7.
          当社が取り扱う個人情報に関して適用される法令、国が定める指針その他規範を遵守するとともに、適切な運用が実施されるよう管理と必要な是正を行い、個人情報保護のマネジメントシステムを継続的に見直し、改善していきます。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">個人情報とは</h5>
        <p class="p_2">
          個人情報とはお預かりした、氏名、住所、電話番号、電子メールアドレス、社歴&nbsp;等をいい、その他の記述などによって特定の個人を識別できるものをいいます。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">個人情報の利用目的</h5>
        <p class="p_2">
          利用者（求職者、派遣登録希望者、各種Webサイト利用者等）より取得する個人情報
        </p>
        <br />
        <p class="p_3">・お問合せに対し、回答するため</p>
        <br />
        <p class="p_3">
          ・当社サービス（就職・転職支援、派遣、各種Webサイト）を提供するため
        </p>
        <br />
        <p class="p_3">
          ・当社サービス（就職・転職支援、派遣、各種Webサイト）情報を案内するため
        </p>
        <br />
        <p class="p_3">・その他、付随する業務につき本人と連絡を取るため</p>
        <br />
        <p class="p_2">当社のお取引先企業様より取得する個人情報</p>
        <br />
        <p class="p_3">
          ・当社の採用アウトソーシングサービスのご利用につき、お取引先企業様（委託元）の応募者の採用業務を代行するため
        </p>
        <br />
        <p class="p_3">
          ・当社が提供するコンテンツ・ソフトウェアサービスの保守・維持管理につき、お取引先企業様（委託元）のユーザー情報の一部を預かるため
        </p>
        <br />
        <p class="p_3">
          ・連絡、協力、交渉、契約の履行、履行請求などを行うため
        </p>
        <br />
        <p class="p_3">・商法にもとづく権利の行使、義務の履行のため</p>
        <br />
        <p class="p_2">当社の採用に応募いただく方の個人情報</p>
        <br />
        <p class="p_3">・採用検討、面接、業務連絡などを行うため</p>
        <br />
        <p class="p_2">
          当社従業者の個人情報・人事・給与、福利厚生、業務連絡などを行うため
        </p>
        <br />
        <p class="p_2">その他、当社へのお問合せにより取得する個人情報</p>
        <br />
        <p class="p_3">・お問合せに対応するため</p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">取得する個人情報の種類</h5>
        <p class="p_2">
          利用者（求職者、派遣登録希望者、各種Webサイト利用者等）より取得する個人情報
        </p>
        <br />
        <p class="p_3">
          ・氏名、性別、生年月日、電話番号、メールアドレス、住所、職歴等
        </p>
        <br />
        <p class="p_2">当社のお取引先企業様より取得する個人情報</p>
        <br />
        <p class="p_3">
          ・お取引先企業様（委託元）の採用における応募者の個人情報（氏名、性別、生年月日、電話番号、メールアドレス、住所、職歴等）
        </p>
        <br />
        <p class="p_3">・氏名、電話番号、メールアドレス等</p>
        <br />
        <p class="p_3">
          ・お取引先企業様（委託元）より当社提供コンテンツ・ソフトウェアを通して預かるユーザー情報（氏名、性別、生年月日、電話番号、メールアドレス、住所、職歴等）
        </p>
        <br />
        <p class="p_3">・氏名、電話番号、メールアドレス等</p>
        <br />
        <p class="p_2">当社の採用に応募いただく方の個人情報</p>
        <br />
        <p class="p_3">
          ・氏名、性別、生年月日、電話番号、メールアドレス、住所、学歴・職歴等
        </p>
        <br />
        <p class="p_2">当社従業者の個人情報</p>
        <br />
        <p class="p_3">
          ・氏名、性別、生年月日、電話番号、メールアドレス、住所、学歴・職歴等
        </p>
        <br />
        <p class="p_2">その他、当社へのお問合せにより取得する個人情報</p>
        <br />
        <p class="p_3">・氏名、電話番号、メールアドレス、住所等</p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">個人情報の第三者提供について</h5>
        <p class="p_2">
          本人の同意がある場合または法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">個人情報の委託</h5>
        <p class="p_2">
          取得した個人情報は、上記「個人情報の利用目的」の範囲内で委託することがあります。
        </p>
        <br />
        <p class="p_2">
          いずれの委託先も、当社と秘密保持契約を締結しています。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">個人情報の共同利用</h5>
        <p class="p_2">
          取得した個人情報は、以下内容に基づき共同利用することがあります。
        </p>
        <br />
        <p class="p_2">共同利用する個人情報の項目</p>
        <br />
        <p class="p_2">上記「取得する個人情報の種類」に同じ</p>
        <br />
        <p class="p_2">
          共同して利用する者の範囲
          ネオキャリアグループ各社：（http://www.neo-career.co.jp/profile/group.html）
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">共同利用の目的</h5>
        <p class="p_2">①求職者（利用者）より取得する個人情報</p>
        <br />
        <p class="p_3">
          ａ．当社サービス（利用者向け）が、ネオキャリアグループ全体の連携した提供であるため
        </p>
        <br />
        <p class="p_3">
          ｂ．取得した個人情報を管理するシステムが、ネオキャリアグループでの管理であるため
        </p>
        <br />
        <p class="p_2">②当社のお取引先企業様より取得する個人情報</p>
        <br />
        <p class="p_3">
          ａ．当社サービス（法人向け）が、ネオキャリアグループ全体の連携した提供であるため
        </p>
        <br />
        <p class="p_3">
          ｂ．取得した個人情報を管理するシステムが、ネオキャリアグループでの管理であるため
        </p>
        <br />
        <p class="p_2">③当社の採用に応募いただく方の個人情報</p>
        <br />
        <p class="p_3">
          ａ．ネオキャリアグループ採用とし、内定後に所属グループ会社を決めるため
        </p>
        <br />
        <p class="p_3">
          ｂ．取得した個人情報を管理するシステムが、ネオキャリアグループでの管理であるため
        </p>
        <br />
        <p class="p_2">④当社従業者の個人情報</p>
        <br />
        <p class="p_3">
          ａ．従業者の人事労務をネオキャリアグループにて管理するため
        </p>
        <br />
        <p class="p_3">
          ｂ．他グループ会社（従業者）との業務連携が必須であるため
        </p>
        <br />
        <p class="p_2">⑤その他、当社へのお問合せにより取得する個人情報</p>
        <br />
        <p class="p_3">
          ａ．当社サービスが、ネオキャリアグループ全体の連携した提供であるため
        </p>
        <br />
        <p class="p_3">
          ｂ．取得した個人情報を管理するシステムが、ネオキャリアグループでの管理であるため
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">個人情報の利用目的</h5>
        <p class="p_2">
          利用者（求職者、派遣登録希望者、各種Webサイト利用者等）より取得する個人情報
        </p>
        <br />
        <p class="p_2">共同利用における管理責任者</p>
        <br />
        <p class="p_3">株式会社ネオキャリア</p>
        <br />
        <p class="p_2">取得方法</p>
        <br />
        <p class="p_3">書面または電子データ</p>
        <br />
        <p class="p_2">開示対象個人情報の開示等及び問合わせ窓口について</p>
        <br />
        <p class="p_3">
          本人からの求めにより、次の窓口で当社が保有する開示対象個人情報の利用目的の通知・開示・内容の訂正・追加又は削除・利用停止・消去等に応じます。
        </p>
        <br />
        <p class="p_2">【個人情報に関する苦情・相談窓口】</p>
        <br />
        <p class="p_3">TEL（共通）：0120-550-150</p>
        <br />
        <p class="p_3">(土日祝・弊社休業日を除く平日午前9時～午後8時)</p>
        <br />
        <p class="p_3">FAX：03-5908-8046</p>
        <br />
        <p class="p_3">Mail：neo-customer@neo-career.co.jp</p>
        <br />
        <p class="p_2">任意性</p>
        <br />
        <p class="p_3">
          個人情報を提供されることは随意ですが、十分な個人情報が提供されない場合、当社サービスの提供やお問合せの対応に支障が生ずることがあります。
        </p>
        <br />
        <p class="p_2">本人が容易に識別できない方法による個人情報の取得</p>
        <br />
        <p class="p_3">
          当社が運営する一部のWebサイトにおいて、ユーザーのプライバシーの保護、利便性の向上、広告の配信および統計データの取得のため、Cookie（クッキー）を使用しております。
        </p>
        <br />
        <p class="p_3">
          また、CookieやJavaScript等の技術を利用して取得した情報のうち、年齢や性別、職業、居住地域など個人が特定できない属性情報（組み合わせることによっても個人が特定できないものに限られます）や端末情報、サービスサイト内におけるユーザーの行動履歴（アクセスしたURL、コンテンツ、参照順等）、及びスマートフォン等利用時のユーザーの承諾・申込みに基づく位置情報を取得することがあります。ただし、
          Cookie及び行動履歴 等には個人情報は一切含まれません。
        </p>
        <br />
        <p class="p_3">
          Cookieを無効化させる設定をされていると、一部サービスをご利用いただけない場合がございますので、あらかじめご了承ください。
        </p>
        <br />
        <p class="p_2">個人情報の安全管理措置について</p>
        <br />
        <p class="p_3">
          取得した個人情報については、漏洩、滅失または棄損の防止と是正、その他個人情報の安全管理のため必要且つ適切な措置を講じます。
        </p>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserPolicy',
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.row {
  height: 100%;
}
.article_ttl {
  font-size: 1.6rem;
  color: #202020;
  margin-bottom: 20px;
  margin-top: 20px;
}
.p_2 {
  font-size: 1.4rem;
}
.p_3 {
  font-size: 1.2rem;
}
</style>
