const channels = {
  1: 'マイナビ', // mynavi
  2: 'リクナビ', // recnavi
  3: 'LINE', // line
  4: '招待', // invite
  5: 'その他', // mochica
};

const contactTypes = {
  1: 'メール', // mail
  2: 'LINE', // line
};

const departmentCategories = [
  { id: 1, name: 'humanities', description: '文系' },
  { id: 2, name: 'science', description: '理系' },
  { id: 3, name: 'other', description: 'その他' },
];

const genders = [
  { id: 1, name: 'male', description: '男' },
  { id: 2, name: 'female', description: '女' },
  { id: 3, name: 'others', description: 'その他' },
  { id: 4, name: 'noanswer', description: '回答しない' },
];

const lineBlocks = [
  { id: 0, name: 'unblock', description: 'ブロックしていない' },
  { id: 1, name: 'block', description: 'ブロック中' },
];

const reminderChecks = [
  { id: 0, name: 'なし' },
  { id: 1, name: 'あり' },
];

const applicantListColumns = [
  {
    name: 'isSelect',
    name_jp: '選択',
    relation: [],
    activated: { disabled: false },
    notActivated: { disabled: false },
    filter: 'isSelect',
  },
  {
    name: 'event_title',
    name_jp: '選考名',
    sort_by: 'event',
    relation: ['status.eventTitle'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'unread_count',
    name_jp: '未読',
    sort_by: 'unread_count',
    relation: ['unreadCount'],
    activated: { disabled: false },
    notActivated: { disabled: false },
    selected: true,
    filter: 'count',
  },
  {
    name: 'status',
    name_jp: 'ステータス',
    sort_by: 'status',
    relation: ['status.progress.description'],
    activated: { disabled: false },
    notActivated: { disabled: false },
    filter: 'currentStatus',
  },
  {
    name: 'attendanceAt',
    name_jp: '参加日',
    sort_by: 'attendanceAt',
    relation: ['attendanceAt'],
    activated: { disabled: false },
    notActivated: { disabled: true },
    filter: 'attendanceAt',
  },
  {
    name: 'reminderCheck',
    name_jp: '参加確認',
    sort_by: 'reminderCheck',
    relation: ['reminderCheck'],
    activated: { disabled: false },
    notActivated: { disabled: true },
    filter: 'reminderCheck',
  },
  {
    name: 'not_announe_lamp',
    name_jp: '結果未通知',
    sort_by: 'announced_at',
    relation: ['not_announe_lamp'],
    activated: { disabled: false },
    notActivated: { disabled: true },
  },
  {
    name: 'name',
    name_jp: '名前',
    sort_by: 'name',
    relation: ['lastname', 'firstname'],
    activated: { disabled: false },
    notActivated: { disabled: false },
    filter: 'applicantName',
  },
  {
    name: 'name_kana',
    name_jp: 'セイメイ',
    sort_by: 'name_kana',
    relation: ['lastnameKana', 'firstnameKana'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'message_created_at',
    name_jp: '新着メッセージ日時',
    sort_by: 'message_created_at',
    relation: ['messageCreatedAt'],
    activated: { disabled: false },
    notActivated: { disabled: false },
    filter: 'datetime',
  },
  {
    name: 'contact_type_status',
    name_jp: '連絡方法',
    sort_by: 'contact_type_id',
    relation: ['contactTypeStatus'],
    activated: { disabled: false },
    notActivated: { disabled: false },
    filter: 'contactTypeStatus',
  },
  {
    name: 'gender',
    name_jp: '性別',
    sort_by: 'gender',
    relation: ['gender.description'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'school',
    name_jp: '学校名',
    sort_by: 'school',
    relation: ['school'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'faculty',
    name_jp: '学部',
    sort_by: 'faculty',
    relation: ['faculty'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'department',
    name_jp: '学科',
    sort_by: 'department',
    relation: ['department'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'department_category',
    name_jp: '文理',
    sort_by: 'department_category',
    relation: ['departmentCategory.description'],
    activated: { default: true },
    notActivated: { default: false },
  },
  {
    name: 'email',
    name_jp: 'メールアドレス',
    sort_by: 'email',
    relation: ['email'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'tel',
    name_jp: '電話番号',
    sort_by: 'tel',
    relation: ['tel'],
    activated: { disabled: false },
    notActivated: { disabled: false },
    filter: 'applicantTel',
  },
  {
    name: 'channel',
    name_jp: '登録経路',
    sort_by: 'channel',
    relation: ['channel'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'invited_status',
    name_jp: 'エントリーフォーム送信',
    sort_by: 'invite_status',
    relation: ['invitedAt'],
    activated: { disabled: true },
    notActivated: { disabled: false },
    filter: 'invitedStatus',
  },
  {
    name: 'invited_at',
    name_jp: '送信日時',
    sort_by: 'invited_at',
    relation: ['invitedAt'],
    activated: { disabled: true },
    notActivated: { disabled: false },
    filter: 'momentYmdNoSpace',
  },
  {
    name: 'created_at',
    name_jp: '登録日',
    sort_by: 'created_at',
    relation: ['createdAt'],
    activated: { disabled: false },
    notActivated: { disabled: false },
    filter: 'momentYmdNoSpace',
  },
  {
    name: 'activated_at',
    name_jp: 'LINEエントリー日',
    sort_by: 'activated_at',
    relation: ['activatedAt'],
    activated: { disabled: false },
    notActivated: { disabled: true },
    filter: 'momentYmdNoSpace',
  },
  {
    name: 'contact_switch_announced_at',
    name_jp: 'LINE通知メッセージの送信日時',
    sort_by: 'contact_switch_announced_at',
    relation: ['contactSwitchAnnouncedAt'],
    activated: { disabled: false },
    notActivated: { disabled: false },
    filter: 'datetime',
  },
  {
    name: 'staff',
    name_jp: '担当者',
    sort_by: 'staff',
    relation: ['staff.lastname', 'staff.firstname'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
];

const CONTACT_TYPE_IDS = {
  mail: 1,
  line: 2,
} as const;

export {
  channels,
  contactTypes,
  departmentCategories,
  genders,
  lineBlocks,
  reminderChecks,
  applicantListColumns,
  CONTACT_TYPE_IDS,
};
