<template>
  <balloon-menu
    :is-view="isVisible"
    :bottom="'1rem'"
    :left="'1rem'"
    @close="$emit('onClose', $event)"
  >
    <template #content>
      <div class="balloon-content">
        <div class="balloon-header">
          <p>連絡事項の絞り込み検索</p>
        </div>

        <div class="balloon-body">
          <dl class="segment">
            <dt>送信内容からの検索</dt>
            <dd>
              <input
                v-model="formWord"
                class="search_input"
                type="text"
                placeholder="文字"
              />
            </dd>
          </dl>
          <dl class="segment">
            <dt>応募者の名前</dt>
            <dd>
              <input
                v-model="formName"
                class="search_input"
                type="text"
                placeholder="応募者の名前"
              />
            </dd>
          </dl>
        </div>
        <div class="btn btn-availability" @click="onSearch">
          <img class="icon" src="@/assets/img/search_white.svg" alt="" />
          上記の条件で検索を行う
        </div>
        <div class="btn btn-unavailable" @click="reset">検索をリセットする</div>
      </div>
    </template>
  </balloon-menu>
</template>

<script>
import { defineComponent, ref } from 'vue';

import BalloonMenu from '@/components/ui/menus/components/BalloonMenu';

export default defineComponent({
  name: 'FloatNoticeHistorySearch',
  components: { BalloonMenu },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClose', 'onSearch', 'onReset'],
  setup(props, context) {
    const formWord = ref('');
    const formName = ref('');
    const onSearch = () => {
      context.emit('onSearch', { word: formWord.value, name: formName.value });
    };
    const reset = () => {
      formWord.value = '';
      formName.value = '';
      context.emit('onReset');
      onSearch();
    };
    return { formWord, formName, reset, onSearch };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.balloon-content {
  padding: 20px;
}
.balloon-header {
  margin-bottom: 20px;
  font-weight: bold;
}
.balloon-body {
  margin-bottom: 20px;
}
.segment {
  margin-bottom: 10px;
  dt {
    margin-bottom: 5px;
  }
  .search_input {
    width: 100%;
  }
}
.btn {
  width: 100%;
}
.btn-availability {
  margin-bottom: 10px;
}
</style>
