<template>
  <div class="edit-company-element">
    <h2 class="company-member_list_item_ttl">
      <span class="list_title">QRコード</span>
    </h2>
    <div>
      <ul class="qr_code_block">
        <li v-if="localCompanyInfo.data.qr !== ''">
          <img
            :src="`${localCompanyInfo.data.qr}`"
            class="qr_code_img"
            alt="QRコード画像"
          />
        </li>
        <li v-if="localCompanyInfo.data.qr !== ''">
          <button-base
            class="btn qr_code_download_btn"
            button-text="QRコードをダウンロード"
            @click="downloadImage(localCompanyInfo.data.qr)"
          />
        </li>
        <li>
          <p class="title">LINE友達追加URL</p>
          <div v-if="localCompanyInfo.data.line_url" class="line_url">
            <span class="url">{{ localCompanyInfo.data.line_url }}</span>
            <button-clipboard-copy :text="localCompanyInfo.data.line_url" />
          </div>
          <div v-else class="line_url">設定なし</div>
        </li>
        <li v-if="localCompanyInfo.data.qr !== ''">
          <p>
            ※QRコードや友達追加用URLの活用方法は
            <a
              href="https://help.mochica.jp/use/qr%e3%82%b3%e3%83%bc%e3%83%89%e3%81%a8%e5%8f%8b%e9%81%94url%e3%81%ae%e5%88%a9%e7%94%a8%e6%96%b9%e6%b3%95/"
              target="_blank"
              rel="noopener"
              class="a_link"
            >
              ヘルプページ
            </a>
            もご活用ください。
          </p>
          <p>
            ※LINEの設定の確認は
            <a
              href="https://www.linebiz.com/jp/login/"
              target="_blank"
              rel="noopener"
              class="a_link"
            >
              LINE Official Manager
            </a>
            でご確認ください。
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
import { useStore } from 'vuex';

import BrowserUtil from '@/utils/browser';
import companyService from '@/services/company';
import ButtonClipboardCopy from '@/components/ui/buttons/components/ButtonClipboardCopy.vue';

export default defineComponent({
  name: 'EditCompanyQr',
  components: { ButtonClipboardCopy },
  props: {
    companyInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const store = useStore();
    const localCompanyInfo = reactive({ data: {} });
    const copyToClipboard = text => {
      BrowserUtil.copyToClipboard(text);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: 'クリップボードにコピーしました',
        type: true,
      });
    };
    const downloadImage = async url => {
      const res = await companyService.downloadQrCode();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    watch(
      () => props.companyInfo,
      () => (localCompanyInfo.data = props.companyInfo),
    );
    return { localCompanyInfo, copyToClipboard, downloadImage };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.qr_code_block {
  li {
    margin-bottom: 20px;
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    img.qr_code_img {
      max-width: 150px;
      border: solid 1px $mochica_color_gray;
    }
    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.line_url {
  display: flex;
  min-height: 25px;
  align-items: center;
}
.a_link {
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (max-width: $media_query_sp) {
  .qr_code_download_btn {
    width: 100%;
  }
  .line_url {
    flex-direction: column;
    align-items: flex-start;
    .url {
      word-break: break-all;
    }
    .copy-btn {
      margin: 10px 0 0 0;
    }
  }
}
</style>
