<template>
  <section v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link :to="{ name: 'ApplicantsOverviewDefault' }">
            応募者一覧
          </router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">
          応募者の重複をチェックする
        </span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <div v-show="isLoading !== true" class="header-content">
        <p>{{ resultMessage }}</p>
      </div>
      <div v-show="isLoading !== true" class="btn-menu -spNone">
        <div class="button-regist" @click="isVisibleColumnSelection = true">
          表示項目
          <img
            slot="arrow"
            class="arrow"
            src="@/assets/img/arrow_down_blue.svg"
          />
        </div>
        <float-context-menu
          :is-view="isVisibleColumnSelection"
          :list="localApplicantListColumns.filter(v => v.id !== 'isSelect')"
          :is-multiple-select="true"
          @onClose="isVisibleColumnSelection = false"
          @onSelected="onSelectedColumn"
        />
      </div>
      <div v-if="isLoading === true" class="no-lists-contents">
        <local-page-loader />
      </div>
      <template v-else>
        <div
          v-for="(duplicatedApplicant, i) in duplicatedApplicants"
          :key="`listHead_${i}`"
          class="list-wrapper"
          :class="{ 'is-open': duplicatedApplicant.isOpen === true }"
        >
          <div class="list-head" @click="setApplicant(i)">
            <span
              v-if="duplicatedApplicant.full_name && duplicatedApplicant.tel"
            >
              名前「{{ duplicatedApplicant.full_name }}」・電話番号「{{
                duplicatedApplicant.tel
              }}」
            </span>
            <span v-else-if="duplicatedApplicant.full_name">
              名前「{{ duplicatedApplicant.full_name }}」
            </span>
            <span v-else-if="duplicatedApplicant.tel">
              電話番号「{{ duplicatedApplicant.tel }}」
            </span>
            が
            <span>{{ duplicatedApplicant.applicant.length }}名</span>
            重複しています
            <img
              class="arrow_icon"
              src="@/assets/img/arrow_right_white.svg"
              alt=""
            />
          </div>
          <div v-if="duplicatedApplicant.isOpen === true" class="list-table">
            <local-page-loader
              v-if="duplicatedApplicant.isLoading === true"
              class="loader"
            />
            <selectable-applicant-table
              :key="`list_${i}`"
              :applicants="duplicatedApplicantLists[i]"
              :invisible-columns="
                localApplicantListColumns
                  .filter(v => v.selected !== true)
                  .map(v => v.id)
              "
              :can-move-detail="true"
              :is-selectable="!$store.getters['plan/isFreePlan']"
              :is-sortable="false"
              :is-double-check="true"
              @onChangeCurrentSelect="onChangeCurrentSelectApplicant($event, i)"
            />
          </div>
        </div>
      </template>
      <div v-if="!$store.getters['plan/isFreePlan']" class="bottom-butto-area">
        <button
          v-show="isLoading !== true"
          :disabled="selectedDeleteApplicantIds.flat().length === 0"
          :class="{ deactive: selectedDeleteApplicantIds.flat().length === 0 }"
          class="btn delete-confirm btn-caution"
          @click="isVisibleModalBulkDeleteApplicant = true"
        >
          選択した応募者を削除する
        </button>
      </div>
    </section>
    <modal-bulk-delete-applicant
      :is-visible="isVisibleModalBulkDeleteApplicant"
      :applicants="selectedApplicantIdsSumList"
      @onClickCancel="isVisibleModalBulkDeleteApplicant = false"
      @onClickSettle="
        isVisibleModalBulkDeleteApplicant = false;
        isVisibleModalBulkDeleteApplicantsConfirm = true;
      "
    />
    <modal-window
      :is-visible="isVisibleModalBulkDeleteApplicantsConfirm"
      :is-visible-close-button="true"
      :is-loading="isLoading"
      :is-new-design="true"
      max-width="520px"
      modal-type="danger"
      title="選択された応募者を一括削除"
      message="削除された応募者は復元できなくなりますが、本当に削除してよろしいですか？"
      caution-note="※選考に割り当てられていた場合、削除後も目標対比の集計対象に含まれます。"
      @clickOuter="isVisibleModalBulkDeleteApplicantsConfirm = false"
      @close="isVisibleModalBulkDeleteApplicantsConfirm = false"
      @click="onClickBulkDeleteApplicants"
    />
  </section>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

import { applicantListColumns } from '@/defines/applicant';
import applicantService from '@/services/applicant';
import applicantsService from '@/services/applicants';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';
import ModalBulkDeleteApplicant from '@/components/page/applicants/components/ModalBulkDeleteApplicant';

export default defineComponent({
  name: 'ApplicantsDoubleCheck',
  components: {
    BreadCrumbList,
    FloatContextMenu,
    LocalPageLoader,
    SelectableApplicantTable,
    ModalBulkDeleteApplicant,
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const isVisibleColumnSelection = ref(false);
    const isVisibleModalBulkDeleteApplicant = ref(false);
    const isVisibleModalBulkDeleteApplicantsConfirm = ref(false);
    const duplicatedApplicants = ref([]);
    const duplicatedApplicantLists = ref([]);
    const localApplicantListColumns = ref([]);
    const selectedDeleteApplicantIds = ref([]);
    const resultMessage = ref('');

    // computed
    const selectedApplicantIdsSumList = computed(() => {
      // 2重配列なのでフラット化
      const applicantListBaseId = duplicatedApplicantLists.value
        .flat()
        .map(applicant => applicant.id);
      const flatAllApplicantList = duplicatedApplicantLists.value
        .flat()
        .filter((x, i) => applicantListBaseId.indexOf(x.id) === i);
      const flatSelectedDeleteApplicantList = selectedDeleteApplicantIds.value
        .flat()
        .filter((x, i, self) => self.indexOf(x) === i);
      return flatAllApplicantList.filter(applicant => {
        const findById = flatSelectedDeleteApplicantList.find(
          v => v === applicant.id,
        );
        return findById === applicant.id;
      });
    });

    // methods
    const init = async () => {
      isLoading.value = true;
      const res = await applicantsService.fetchDoubleCheckApplicantIds();
      duplicatedApplicants.value = res.double;
      duplicatedApplicantLists.value = duplicatedApplicants.value.map(() => []);
      selectedDeleteApplicantIds.value = duplicatedApplicants.value.map(
        () => [],
      );
      resultMessage.value = res.message;
      localApplicantListColumns.value = applicantListColumns.map(col => {
        // デフォルトで非表示(on,offは可)
        let defaultSelected = true;
        if (
          col.name === 'unread_count' ||
          col.name === 'attendanceAt' ||
          col.name === 'name_kana' ||
          col.name === 'gender' ||
          col.name === 'invite_status' ||
          col.name === 'invited_at' ||
          col.name === 'staff' ||
          col.name === 'not_announe_lamp'
        ) {
          defaultSelected = false;
        }
        return {
          id: col.name,
          name: col.name_jp,
          selected: defaultSelected,
          activated: col.activated,
          notActivated: col.notActivated,
        };
      });
      isLoading.value = false;
    };
    const setApplicant = async index => {
      // 既にアコーディオン開いていた場合、アコーディオンの開閉だけ実行
      if (duplicatedApplicants.value[index].isOpen !== undefined) {
        duplicatedApplicants.value = duplicatedApplicants.value.map((v, i) => {
          if (i === index) {
            return { ...v, isOpen: !duplicatedApplicants.value[index].isOpen };
          }
          return v;
        });
        return;
      }
      // 既にfetch済み・fetch中の場合なにもしない
      if (
        duplicatedApplicants.value[index].isLoading === true ||
        (duplicatedApplicantLists.value.length > 0 &&
          duplicatedApplicantLists.value[index].id)
      ) {
        return;
      }
      // アコーディオン内loading開始
      duplicatedApplicants.value = duplicatedApplicants.value.map((v, i) => {
        if (i === index) return { ...v, isOpen: true, isLoading: true };
        return v;
      });
      const res = await applicantsService.fetchApplicantsEditGetApplicants(
        duplicatedApplicants.value[index].applicant,
      );
      const applicantLogicList = res.applicants.map(applicant => ({
        ...applicant,
        // 氏名の重複フラグ
        nameColorRed:
          res.applicants
            .map(v =>
              v.firstname === null ? v.lastname : v.lastname + v.firstname,
            )
            .filter(
              (x, i, self) =>
                self.indexOf(x) === i && i !== self.lastIndexOf(x),
            )
            .indexOf(
              applicant.firstname === null
                ? applicant.lastname
                : applicant.lastname + applicant.firstname,
            ) !== -1
            ? 1
            : 0,
        // 電話番号の重複フラグ
        telColorRed:
          res.applicants
            .map(v => v.tel)
            .filter(
              (x, i, self) =>
                self.indexOf(x) === i && i !== self.lastIndexOf(x),
            )
            .indexOf(applicant.tel) !== -1
            ? 1
            : 0,
      }));
      duplicatedApplicantLists.value = duplicatedApplicantLists.value.map(
        (v, i) => {
          if (i === index) return applicantLogicList;
          return v;
        },
      );
      // アコーディオン内loading終了
      duplicatedApplicants.value = duplicatedApplicants.value.map((v, i) => {
        if (i === index) return { ...v, isLoading: false };
        return v;
      });
    };
    const bulkDeleteApplicants = async () => {
      const res = await applicantService.bulkDeleteApplicants(
        selectedDeleteApplicantIds.value.flat(),
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        duplicatedApplicantLists.value = duplicatedApplicants.value.map(
          () => [],
        );
        selectedDeleteApplicantIds.value = duplicatedApplicants.value.map(
          () => [],
        );
        await init();
      }
    };
    const onClickBulkDeleteApplicants = async ({ selected }) => {
      if (selected === 'cancel') isVisibleModalBulkDeleteApplicant.value = true;
      if (selected === 'submit') {
        isLoading.value = true;
        await bulkDeleteApplicants();
        isLoading.value = false;
      }
      isVisibleModalBulkDeleteApplicantsConfirm.value = false;
    };
    const onChangeCurrentSelectApplicant = (payload, index) => {
      if (payload === undefined) return;
      selectedDeleteApplicantIds.value = selectedDeleteApplicantIds.value.map(
        (v, i) => {
          if (i === index) return payload.selectedApplicants;
          return v;
        },
      );
    };
    const onSelectedColumn = payload => {
      // 表示項目のメニューのチェック
      const tmpApplicantListColumns = [...localApplicantListColumns.value];
      tmpApplicantListColumns[
        tmpApplicantListColumns.findIndex(col => col.id === payload.item.id)
      ].selected = !payload.item.selected;
      localApplicantListColumns.value = tmpApplicantListColumns;
    };

    // lifecycle
    onBeforeMount(async () => {
      await store.dispatch('page/SET_LOADED');
      init();
    });

    return {
      isLoading,
      isVisibleColumnSelection,
      isVisibleModalBulkDeleteApplicant,
      isVisibleModalBulkDeleteApplicantsConfirm,
      duplicatedApplicants,
      duplicatedApplicantLists,
      localApplicantListColumns,
      selectedDeleteApplicantIds,
      resultMessage,
      selectedApplicantIdsSumList,
      setApplicant,
      onClickBulkDeleteApplicants,
      onChangeCurrentSelectApplicant,
      onSelectedColumn,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

:deep(.area-table) {
  width: 100%;
}
.content {
  padding: 40px 30px;
  background-color: #fff;
}
.content-warp {
  .list-wrapper {
    position: relative;
    margin-bottom: 10px;
    background-color: #fff;

    > .list-head {
      position: relative;
      padding: 20px;
      font-size: 16px;
      color: #fff;
      background-color: #1698d9;
      cursor: Pointer;

      > span {
        font-weight: bold;
      }

      > .arrow_icon {
        position: absolute;
        right: 20px;
        top: 20px;
        height: 16px;
        transform: rotate(90deg);
        transition: transform 0.3s ease;
      }
    }

    > .list-table {
      padding: 20px;

      > .loader {
        position: absolute;
        height: 97px;
        min-height: 97px;
        max-height: 97px;
      }
    }

    &.is-open {
      > .list-head {
        > .arrow_icon {
          transform: rotate(-90deg);
        }
      }
      > .list-table {
        min-height: 137px;
      }
    }
  }
}
.header-content {
  margin-bottom: 30px;
  padding: 20px 40px;
  background: #fff;
  font-size: 1.4rem;
  font-weight: bold;
}
.no-lists-contents {
  height: 150px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline-warp {
  display: inline-block;
}
.headline-area {
  padding: 20px 0 15px;
  border-top: 1px solid #e5e5e5;
  font-size: 1.4rem;
  font-weight: bold;
}
.btn-menu {
  display: inline-block;
  margin-bottom: 10px;
}
.input-area {
  padding-bottom: 20px;
  font-size: 1.4rem;
  &.last {
    margin-bottom: 40px;
    border-bottom: 1px solid #e5e5e5;
  }
}
.required {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  vertical-align: 1px;
  color: #fff;
  font-size: 1rem;
  background-color: #b30000;
}
.table-row {
  + .table-row {
    margin-top: 15px;
  }
  td {
    vertical-align: top;
  }
  td:nth-child(n + 2) {
    padding-left: 15px;
  }
  .txt-warning {
    padding-left: 50px;
  }
}
.name-label {
  width: 50px;
  text-align: center;
}
.radio-wrapper {
  + .radio-wrapper {
    margin-left: 10px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
input,
select {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #adadad;
  vertical-align: middle;
}
.input-sizefull {
  width: 100%;
}
.input-sizehalf {
  width: 260px;
}
.select-sotsunen {
  width: 260px;
}
.select-birthday {
  width: 140px;
  margin-right: 5px;
}
.txt-warning {
  margin-top: 5px;
}
.red {
  color: #c31e1e;
}
.delete-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  padding: 15px 10px;
  background-color: $mochica_color_red;
  margin: 0 auto;
  &.deactive {
    cursor: auto;
    opacity: 0.4;
    background-color: #adadad;
  }
}
.bottom-butto-area {
}

@media (max-width: ($media_query_tablet)) {
  .content {
    padding: 40px;
  }
  .inline-warp {
    display: block;
  }
  .input-sizehalf {
    width: 100%;
    max-width: 260px;
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px math_size(20);
  }
  .table-row {
    width: 100%;
    td {
      display: block;
    }
    td:nth-child(n + 2) {
      padding-top: 10px;
      padding-left: 0;
    }
  }
}

@media (min-width: ($media_query_sp)) {
  .minWid600 {
    min-width: 600px;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
