<template>
  <div class="preview" :class="{ 'is-setting': isSetting === true }">
    <div ref="refPreviewForm" class="preview_form">
      <div class="preview_form__inner">
        <div ref="refPreviewTitle" class="preview_form__inner__title">
          <div class="company_name">{{ companyName }}</div>
          <div class="entry_title">エントリーフォーム</div>
        </div>
        <div class="preview_form__inner__contents">
          <entry-form
            ref="refEntryForm"
            :graduateds="graduateds"
            :graduated-visible="true"
            :initial-graduated-year="graduatedYear"
            :is-preview-flagsurvey="isPreviewFlagsurvey"
            :is-preview="true"
          />
        </div>
      </div>
      <!-- ハイライト表示 -->
      <div v-show="isHighlight" class="hightlight_block">
        <div
          class="highlight_mask"
          :style="{ height: `${refPreviewForm?.scrollHeight || 0}px` }"
        />
        <div
          class="preview_form__inner hightlight_contents"
          :style="highlightStyle"
        >
          <div class="preview_form__inner__title">
            <div class="company_name">{{ companyName }}</div>
            <div class="entry_title">エントリーフォーム</div>
          </div>
          <div class="preview_form__inner__contents">
            <entry-form
              ref="refEntryFormHighlight"
              :graduateds="graduateds"
              :graduated-visible="true"
              :initial-graduated-year="graduatedYear"
              :is-preview-flagsurvey="isPreviewFlagsurvey"
              :is-preview="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';

import EntryForm from '@/components/page/line_entry/components/EntryForm';

export default defineComponent({
  name: 'EntryFormSettingPreview',
  components: { EntryForm },
  props: {
    companyName: {
      type: String,
      required: true,
    },
    graduateds: {
      type: Array,
      required: true,
    },
    graduatedYear: {
      type: Number,
      required: true,
    },
    formSetting: {
      type: Object,
      required: true,
    },
    toggleSwitch: {
      type: Function,
      required: true,
    },
    isPreviewFlagsurvey: {
      type: Boolean,
      default: false,
    },
    isSetting: {
      type: Boolean,
      default: false,
    },
    highlightPreview: {
      type: Object,
      required: false,
      default: () => ({
        graduated: false,
      }),
    },
  },
  setup(props, context) {
    const isHighlight = computed(() => {
      return Object.values(props.highlightPreview).some(value => value);
    });
    const highlightStyle = computed(() => {
      const titleHeight = refPreviewTitle.value?.clientHeight || 0;
      if (props.highlightPreview.graduated) {
        return {
          'clip-path': `inset(${124 + titleHeight}px 3% ${
            284 - titleHeight
          }px 3% round 4px)`,
        };
      }
      return {};
    });
    const refPreviewForm = ref(null);
    const refPreviewTitle = ref(null);
    const refEntryForm = ref(null);
    const refEntryFormHighlight = ref(null);
    const setPreviewData = (key = null, previewQuestions = []) => {
      if (key !== null) props.toggleSwitch(key);
      // formSettingが反映するまで
      setTimeout(() => {
        // プレビューに渡す登録種別・卒年、必須項目Objectを渡す
        if (refEntryForm.value && refEntryForm.value.setPreviewData) {
          refEntryForm.value.setPreviewData(
            props.graduatedYear,
            props.formSetting,
            previewQuestions,
          );
        }
        if (
          refEntryFormHighlight.value &&
          refEntryFormHighlight.value.setPreviewData
        ) {
          refEntryFormHighlight.value.setPreviewData(
            props.graduatedYear,
            props.formSetting,
            previewQuestions,
          );
        }
      }, 100);
    };

    return {
      setPreviewData,
      refEntryForm,
      refEntryFormHighlight,
      isHighlight,
      refPreviewForm,
      refPreviewTitle,
      highlightStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@include form_wrapper_css();

.preview {
  @include entry_input_from();
}

.preview_form {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 440px;
  max-height: 440px;
  margin: 0;
  padding: 0;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  overflow: scroll;
}

.is-setting {
  .preview_form {
    min-height: 532px;
    max-height: 532px;
  }
}

.highlight_mask {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140%;
  background-color: rgba(0, 0, 0, 0.5);
}

.preview_form__inner {
  position: relative;
  width: 375px;
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: 0.05em;
  background-color: #fff;

  &.hightlight_contents {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
}
.preview_form__inner__title {
  padding: 20px 15px 60px 15px;
  text-align: center;
  background-image: url('../../../../assets/img/entryform_bg.png');
  background-size: 375px auto;
  color: #fff;
  background-repeat-y: repeat;

  > .company_name {
    font-size: 28px;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 10px;
  }

  > .entry_title {
    line-height: 1.5;
    font-weight: bold;
  }
}

.preview_form__inner__contents {
  margin-top: -35px;
  padding: 30px 20px 20px;
  font-size: 1.3rem;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
</style>
