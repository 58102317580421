import httpClient from '@/http-client';

const GraduatedsManager = function _GraduatedsManager() {
  const manager = Object.create(GraduatedsManager.prototype);
  return manager;
};

GraduatedsManager.prototype = {
  async fetchGraduateds() {
    const response = await httpClient.get('/graduateds');
    return response.data.graduateds;
  },
  async createGraduated(year) {
    const response = await httpClient.post('/graduateds', {
      graduated_year: year,
    });
    return response.data;
  },
  async deleteGraduated(year) {
    const response = await httpClient.delete(`/graduateds/${year}`);
    return response.data;
  },
  async getFormSetting(id) {
    const response = await httpClient.get(`/graduateds/${id}`);
    return response.data;
  },
  async updateFormSetting(payload) {
    const response = await httpClient.post(`/graduateds/${payload.id}`, {
      name: payload.name,
      address: payload.address,
      birth: payload.birth,
      department: payload.department,
      department_category: payload.department_category,
      faculty: payload.faculty,
      gender: payload.gender,
      school: payload.school,
      tel: payload.tel,
      zipcode: payload.zipcode,
      entry_flagsurvey_questions: payload.entry_flagsurvey_questions,
      enabled_entry_form: payload.enabled_entry_form,
    });
    return response.data;
  },
};

export default GraduatedsManager();
