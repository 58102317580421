<template>
  <div v-if="!$store.getters['staff/isOwner'] || isOwnersEmail" class="value">
    {{ staff.email }}
  </div>
  <div v-else class="value">
    <div v-if="isEditMode" class="edit-text">
      <h2 class="staff_setting_list_item_ttl">
        <div class="staffs-email_form_item">
          <input
            v-model="emailForm"
            class="mail-form"
            type="text"
            :class="{ 'is-danger': !errors.email.isValid }"
            placeholder="example@mochica.jp"
            @input="validateOnInput('email', emailForm, validateEmail)"
          />
          <span v-show="!errors.email.isValid" class="fa fa-warning font-s">
            {{ errors.email.message }}
          </span>
        </div>
      </h2>
      <button-base
        class="btn edit-email-button"
        button-text="メールアドレスを確定"
        button-type="success"
        icon-file-name="check_white"
        :rejected-role-ids="[2, 3]"
        :rejected-admin-ids="[2]"
        @click="commit"
      />
    </div>
    <div v-else class="edit-text">
      <span>{{ staff.email }}</span>
      <button-base
        v-if="!staff.email_update_pending"
        class="btn edit-email-button"
        button-text="メールアドレスを変更"
        icon-file-name="pen_white"
        :rejected-role-ids="[2, 3]"
        :rejected-admin-ids="[2]"
        @click="isEditMode = true"
      />
      <button-base
        v-else
        class="btn edit-email-button"
        button-text="変更をキャンセル"
        button-type="secondary"
        :rejected-role-ids="[2, 3]"
        :rejected-admin-ids="[2]"
        @click="isVisibleCancelModal = true"
      />
    </div>
    <modal-window
      class="edit-email-modal"
      :is-visible="isVisibleEmailUpdate"
      :is-loading="loadingEditEmail"
      title="メールアドレスを変更する"
      :message="`${emailForm}に確認メールが送信されます。`"
      button-text="確認メールを送信"
      @click="onSelectedEmailUpdatePendingDialog"
      @close="onClickOuterEmailUpdatePendingDialog"
    />
    <modal-window
      class="edit-email-modal"
      :is-visible="isVisibleCancelModal"
      :is-loading="loadingCancel"
      title="変更をキャンセル"
      :message="`メールアドレスの変更を取り消します。\n\n名前：${staff.firstname} ${staff.lastname}`"
      button-text="変更を取り消し"
      @click="onSelectedCancelDialog"
      @close="isVisibleCancelModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'EditEmail',
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
    isOwnersEmail: {
      type: Boolean,
      default: false,
    },
    loadingEditEmail: {
      type: Boolean,
      default: false,
    },
    loadingCancel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['commitedEmailChange', 'onSelectedCancelDialog'],
  setup(props, context) {
    const { errors, resetErrors, validateOnInput, validateEmail } =
      useValidation(['email']);
    const emailForm = ref(props.staff.email);
    const isEditMode = ref(false);
    const isVisibleCancelModal = ref(false);
    const isVisibleEmailUpdate = ref(false);

    // watch
    watch(
      () => props.staff,
      () => (emailForm.value = props.staff.email),
    );

    // methods
    const commit = () => {
      if (props.staff.email === emailForm.value) {
        isEditMode.value = false;
        return;
      }
      isVisibleEmailUpdate.value = true;
    };
    const onSelectedEmailUpdatePendingDialog = payload => {
      if (payload.selected === 'submit') {
        context.emit('commitedEmailChange', {
          staff_id: props.staff.id,
          staff_email: emailForm.value,
        });
      } else {
        emailForm.value = props.staff.email;
      }
      resetErrors();
      isVisibleEmailUpdate.value = false;
      isEditMode.value = false;
    };
    const onClickOuterEmailUpdatePendingDialog = () => {
      resetErrors();
      isVisibleEmailUpdate.value = false;
      isEditMode.value = false;
    };
    const onSelectedCancelDialog = payload => {
      if (payload.selected === 'submit') {
        context.emit('onSelectedCancelDialog', {
          staff_id: props.staff.id,
        });
      }
      resetErrors();
      isVisibleCancelModal.value = false;
    };

    return {
      errors,
      emailForm,
      isEditMode,
      isVisibleCancelModal,
      isVisibleEmailUpdate,
      commit,
      onSelectedEmailUpdatePendingDialog,
      onClickOuterEmailUpdatePendingDialog,
      onSelectedCancelDialog,
      validateOnInput,
      validateEmail,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.btn-unavailable:hover {
  cursor: auto;
}
.edit-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ($media_query_sp)) {
    flex-wrap: wrap;
    .btn {
      margin-top: 20px;
      margin-left: 0;
    }
  }
  .staff_setting_list_item_ttl {
    width: 100%;
  }
  .staffs-email_form_item {
    white-space: initial;
    input {
      width: 300px;
      height: 40px;
      padding: 0.6rem;
      border-radius: 0.4rem;
      border: 1px solid $border_color;
      font-size: 1.4rem;
      background: #fff;
      @media (max-width: ($media_query_sp)) {
        width: 100%;
        box-sizing: border-box;
      }
    }
    span {
      &.fa-warning {
        color: #c31e1e;
        padding-top: 4px;
        font-size: 1.2rem;
      }
      &.fa {
        display: block;
      }
    }
  }
}
.ui-select {
  height: 30px;
  border: 1px solid #e2e2e2;
}
.edit-email-button {
  margin-left: 20px;
  cursor: pointer !important;
  @media (max-width: ($media_query_sp)) {
    width: 100%;
  }
}
.edit-email-modal {
  white-space: break-spaces;
}

.pending {
  background-color: $mochica_color_gray;
  padding: 10px;
  color: $white;
  margin-right: 20px;
}
</style>
