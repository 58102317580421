import httpClient from '@/http-client';
import { snakeToCamelCaseForObject } from '@/utils/change-case';

const ProgressesManager = function _ProgressesManager() {
  const manager = Object.create(ProgressesManager.prototype);
  return manager;
};

ProgressesManager.prototype = {
  async fetchProgress(applicantId) {
    const response = await httpClient.get(
      `/applicants/${applicantId}/progresses`,
    );
    return response.status === 200
      ? {
          currentProgressId:
            response.data.progresses.current_progress_id || null,
          committed: response.data.progresses.committed
            ? response.data.progresses.committed.map(v =>
                snakeToCamelCaseForObject(v),
              )
            : null,
          notCommitted: response.data.progresses.not_committed
            ? response.data.progresses.not_committed.map(v =>
                snakeToCamelCaseForObject(v),
              )
            : null,
        }
      : null;
  },
  /**
   * 評価待から人事待に変更するAPI
   * 担当者か人事以上で叩ける
   * 評価待ち状態でしか受け付けない
   * @param applicantId
   * @param comment
   * @returns {Promise<void>}
   */
  async updateCurrentProgressComment(applicantId, comment, progressId) {
    const response = await httpClient.patch(
      `/applicants/${applicantId}/progresses/current/comment`,
      {
        progress: {
          comment,
          progress_id: progressId,
        },
      },
    );
    return response.data;
  },
  /**
   * どこからでも内定、不採用、辞退、通過に変更するAPI
   * ※辞退・不採用、採用状態からは変更できない
   * 人事以上で叩ける
   *
   * @param applicantId
   * @param comment
   * @param toStatusName  finished, offered, canceled, dropped
   * @returns {Promise<void>}
   */
  // TODO: API側で route 変更がされたので移植予定
  async updateCurrentProgressStatus(
    applicantId,
    comment,
    toStatusName,
    progressId,
  ) {
    const response = await httpClient.patch(
      `/applicants/${applicantId}/progresses/current/status`,
      {
        progress: {
          progress_id: progressId,
          comment,
          to_status: toStatusName,
        },
      },
    );
    return response.data;
  },
  // TODO: API側で route 変更がされたので移植予定
  async updateFinishedProgressStatus(applicantId, res) {
    const response = await httpClient.post(
      `/applicants/${applicantId}/progresses/current/offered-reactions?accept=${res}`,
    );
    return response.data;
  },
  async createProgress(selectionId, applicantIds, through) {
    const result = await httpClient.post('/progresses', {
      selection_id: selectionId,
      assign: { applicant_ids: applicantIds, through },
    });
    return result.data;
  },
};

export default ProgressesManager();
