<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

import BaseInput from '@/components/ui/input/BaseInput.vue';
import ModalFullSize from '@/components/ui/modals/ModalFullSize.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import Pager from '@/components/ui/pager/components/Pager.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';

import autoActionsService from '@/services/autoActions.js';

const props = defineProps({
  isView: {
    type: Boolean,
    default: false,
  },
  excludeSelectionIds: {
    type: Array,
    default: () => [],
  },
});

const initialPage = 1;

const store = useStore();

const isLoading = ref(false);
const selections = ref([]);
const selectionsMeta = ref({});
const searchKeyword = ref('');

const emit = defineEmits(['select-selection, close-modal']);

const searchSelections = async page => {
  isLoading.value = true;
  const res = await autoActionsService.fetchSelections({
    graduated_id: store.getters['graduateds/selectedGraduatedId'],
    page,
    per_page: 20,
    exclude_ids: props.excludeSelectionIds,
    title: searchKeyword.value,
  });
  isLoading.value = false;
  selections.value = res.selections;
  selectionsMeta.value = res.meta;
};

const resetSearchCondition = () => {
  searchKeyword.value = '';
  searchSelections(initialPage);
};
const onClickSelection = selection => {
  emit('select-selection', selection);
};
const movePage = ({ next }) => {
  searchSelections(next);
};

watch(
  () => props.isView,
  newIsView => {
    if (newIsView) {
      searchSelections(initialPage);
    }
  },
);
</script>

<template>
  <modal-full-size :is-view="isView" @close-modal="emit('close-modal', false)">
    <template #header>選考の選択</template>
    <template #body>
      <div class="wrapper">
        <div class="header">
          <p class="header-title">以下の選考は表示されません</p>
          <p class="header-desc">
            「適用する選考」ですでに選択済みの選考 /
            すべての開催日時が過ぎている選考
          </p>
        </div>
        <div class="body-inner">
          <div class="body-header">
            <div class="search">
              <base-input
                v-model.trim="searchKeyword"
                :disabled="isLoading"
                name="search-selection"
                type="text"
                placeholder="選考名を入力してください"
              />
              <div class="search-contole">
                <button-base
                  type="button"
                  class="search-button"
                  :is-loading="isLoading"
                  :button-text="'検索'"
                  :button-type="'primary'"
                  :icon-file-name="'search_white'"
                  @click="searchSelections(initialPage)"
                />
                <button
                  class="search-reset-button"
                  type="button"
                  @click="resetSearchCondition"
                >
                  検索をリセット
                </button>
              </div>
            </div>
            <div v-if="!isLoading" class="pager-wrapper">
              <pager
                :page="selectionsMeta.current_page"
                :count="selectionsMeta.total_count"
                :page-count="selectionsMeta.total_pages"
                @move="movePage"
              />
            </div>
          </div>
          <div v-if="isLoading" class="page-loading">
            <LocalPageLoader />
          </div>
          <div v-else-if="!isLoading && selections.length === 0">
            <EmptyContent>
              <p class="empty-text">表示できる選考がありません</p>
              <p class="empty-text-sub">
                <strong>以下の選考は表示されません</strong>
                「適用する選考」ですでに選択済みの選考
                <br />
                すべての開催日時が過ぎている選考
              </p>
            </EmptyContent>
          </div>
          <div v-else>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                  <th>選考名</th>
                  <th class="fit-center">登録会場数</th>
                  <th>開催日時</th>
                  <th>管理用メモ</th>
                </thead>
                <tbody>
                  <tr
                    v-for="selection in selections"
                    :key="selection.id"
                    @click="onClickSelection(selection)"
                  >
                    <td class="text-ellipsis">{{ selection.title }}</td>
                    <td class="fit-center">{{ selection.total_venues }}</td>
                    <td>
                      {{ selection.venues_dates.join(', ') }}
                    </td>
                    <td>{{ selection.description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="!isLoading" class="pager-wrapper">
            <pager
              :page="selectionsMeta.current_page"
              :count="selectionsMeta.total_count"
              :page-count="selectionsMeta.total_pages"
              @move="movePage"
            />
          </div>
        </div>
      </div>
      <div class="btn-col">
        <button-base
          type="button"
          class="btn-cancel"
          button-type="secondary"
          button-text="キャンセル"
          @click="emit('close-modal')"
        />
      </div>
    </template>
  </modal-full-size>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.wrapper {
  padding: 20px;
  background-color: $white;
}

//header
.header {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid $mochica_color_gray2;
  color: $system_color;
  font-size: $font_14;
  text-align: left;
}
.header-title {
  margin: 0 0 2px;
  font-weight: bold;
  line-height: 1.5;
}
.header-desc {
  line-height: 1.8;
}

//body
.body-inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.body-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

//検索 & テーブル上のページネーション
.search {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  input,
  button,
  button.btn-base {
    font-size: $font_14;
  }
  input {
    width: 100%;
    max-width: 320px;
  }
}
.search-contole {
  display: flex;
  gap: 16px;
}
.search-button {
  width: 120px;
  height: 40px;
}
.search-reset-button {
  cursor: pointer;
  color: $mochica_color;
  font-weight: normal;
  text-decoration: underline;
  white-space: nowrap;
  transition: all 0.3s ease;
  &:hover {
    color: $mochica_border_color;
  }
}
.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

//テーブル
.table-wrapper {
  overflow-x: auto;
  min-height: 400px;
}
.table {
  width: 100%;
  white-space: nowrap;
  font-size: $font_14;
  text-align: left;
  thead {
    background: $mochica_color_gray2;
  }
  th,
  td {
    min-width: 220px;
    &.fit-center {
      width: 0;
      min-width: unset;
      white-space: nowrap;
      text-align: center;
    }
    &.text-ellipsis {
      max-width: 20em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  th {
    padding: 12px 10px;
    &:first-of-type {
      padding: 12px 10px 12px 20px;
    }
    &:last-of-type {
      padding: 12px 20px 12px 10px;
    }
  }
  td {
    padding: 18px 10px;
    &:first-of-type {
      padding: 18px 10px 18px 20px;
    }
    &:last-of-type {
      padding: 18px 20px 18px 10px;
    }
  }
  tr {
    border-bottom: 1px solid #e8e8e8;
    &:hover {
      background: #f1f1f1;
    }
  }
}

//空の場合の表示
.empty-text-sub {
  display: flex;
  flex-direction: column;
  gap: 4px;
  strong {
    color: #777;
    font-size: $font_12;
    font-weight: bold;
  }
}
.btn-cancel {
  width: 100%;
  height: 46px;
  margin-top: 20px;
}

@media (max-width: ($media_query_tablet)) {
  .body-header {
    flex-wrap: wrap;
    gap: 32px;
  }
  .search {
    flex-wrap: wrap;
    gap: 12px;
    input {
      max-width: unset;
    }
  }
  .search-contole {
    width: 100%;
  }
  .search-button {
    width: 100%;
  }
  .search-reset-button {
    width: 160px;
  }
}
</style>
