<template>
  <div class="scroll-table">
    <table class="table-typeA">
      <thead class="thead">
        <tr>
          <th class="item-th">テンプレート名</th>
          <th class="item-th">作成者</th>
          <th class="item-th">設定内容</th>
          <th class="item-th">作成日</th>
          <th class="item-th">&nbsp;</th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr v-for="fixPhrase in fixPhraseList" :key="fixPhrase.id" class="row">
          <td class="item-td" data-label="テンプレート名">
            <!-- FIXME: 表示崩れ防止のため下記で修正すること -->
            <!-- <p>{{ $utils.textEllipsis(fixPhrase.name) }}</p> -->
            <p>{{ fixPhrase.name }}</p>
          </td>
          <td class="item-td" data-label="作成者">
            <p>{{ fixPhrase.staff.lastname }}{{ fixPhrase.staff.firstname }}</p>
          </td>
          <td class="item-td" data-label="設定内容">
            <p>
              <span class="line_cont">
                {{ fixPhrase.line_content !== '' ? 'LINE' : '' }}
              </span>
              <span class="mail_cont">
                {{ fixPhrase.mail_content !== '' ? 'メール' : '' }}
              </span>
            </p>
          </td>
          <td class="item-td" data-label="作成日">
            <p>
              {{
                $utils.parseDateTime(fixPhrase.created_at, 'YYYY/MM/DD HH:mm')
              }}
            </p>
          </td>
          <td class="item-td item-btns">
            <button
              class="btn btn-availability"
              @click="$emit('openModalUpdateFixPhrase', fixPhrase)"
            >
              <img class="icon" src="@/assets/img/pen_white.svg" alt="" />
              編集
            </button>
            <button
              class="btn btn-unavailable"
              @click="$emit('openModalDeleteFixPhrase', fixPhrase)"
            >
              <img class="icon" src="@/assets/img/delete_white.svg" alt="" />
              削除
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ListViewFixPhrase',
  props: {
    fixPhraseList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['openModalUpdateFixPhrase', 'openModalDeleteFixPhrase'],
  setup(props, context) {
    return {};
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
@include common_table_style(
  100%,
  150px,
  200px,
  150px,
  150px,
  $col_1_ta: left,
  $col_2_ta: left,
  $col_3_ta: center,
  $col_4_ta: left,
  $col_5_ta: left
);

.scroll-table {
  overflow-x: auto;
  white-space: nowrap;
}

.table-typeA {
  font-size: 1.4rem;
}

@media (max-width: ($media_query_sp)) {
  .scroll-table {
    overflow-x: visible;
    white-space: normal;
  }
  .table-typeA {
    font-size: 1rem;
    .tbody .item-td:not(.item-btns):before {
      width: 100px;
    }
  }
}
</style>
