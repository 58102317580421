<script setup>
import { ref } from 'vue';

const isVisible = ref(false);

const showTooltip = () => {
  isVisible.value = true;
};

const hideTooltip = () => {
  isVisible.value = false;
};
</script>

<template>
  <div
    class="tooltip-container"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    <span class="question-icon" :class="{ 'is-visible': isVisible }">
      <img src="@/assets/img/question.svg" />
    </span>
    <div v-show="isVisible" class="tooltip">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.tooltip-container {
  position: relative;
  display: inline-block;
}

.question-icon {
  display: flex;
  width: 14px;
  height: 14px;
  background: #9d9d9d;
  align-items: center;
  justify-content: center;
  border-radius: 44px;

  img {
    display: block;
    width: 8px;
    height: 8px;
  }
  &.is-visible::after {
    content: '';
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: calc(20px / 2);
    pointer-events: none;
    transition: all ease 0.1s;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    background: #333;
  }
}

.tooltip {
  position: absolute;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  padding: 8px 10px;
  transition: all ease 0.1s;
  background: #333;
  color: $white;
  border-radius: 4px;
  line-height: 1.8;
  font-size: 12px;
  pointer-events: none;
  z-index: 2;
}
</style>
