<template>
  <float-box :is-view="isView" @close="$emit('onClose')">
    <template #content>
      <div>
        <div class="context-menu">
          <ul>
            <li
              v-for="item in list.filter(item => !item.disabled)"
              :key="item.id"
              class="un-selectable"
              :class="{ selected: item.selected }"
              @click="selected(item)"
            >
              <div class="li-inner flexbox flexbox-middle">
                <p>
                  {{ item.name }}
                  <span v-if="item.suffix" class="selected_content_suffix">
                    {{ item.suffix }}
                  </span>
                </p>
                <div
                  v-if="isMultipleSelect && item.selected"
                  class="margin-left-auto"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </float-box>
</template>

<script>
import { defineComponent } from 'vue';

import FloatBox from '@/components/ui/menus/components/FloatBox';

export default defineComponent({
  name: 'FloatContextMenu',
  components: { FloatBox },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    isMultipleSelect: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onSelected', 'onClose'],
  setup(props, context) {
    const selected = _item => {
      context.emit('onSelected', {
        item: _item,
        isMultipleSelect: props.isMultipleSelect,
      });
      if (props.isMultipleSelect === false) context.emit('onClose');
    };

    return { selected };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.context-menu {
  position: absolute;
  background: white;
  min-width: 16rem;
  top: 5px;
  left: 0;
  z-index: 5;
  max-height: 25rem;
  overflow: auto;
  color: #333;
  font-weight: 400;
  ul {
    box-shadow: 0px 1px 1px rgba(97, 113, 118, 0.17);
    border-radius: 2px;
    li {
      border-bottom: dashed 1px #d9d9d94d;
      cursor: pointer;
      font-size: 1.2rem;
      padding: 0 1rem;
      &:last-child {
        border-bottom: none;
      }
      .li-inner {
        // height: 3.6rem;
        padding: 15px 0px;
        p {
          white-space: nowrap;
          margin-right: 1.2rem;
        }
        div {
          display: none;
        }
      }
    }
    li.selected {
      color: $mochica_color;
      font-weight: bold;
      background: #fafafa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      &::after {
        content: '';
        display: inline-block;
        background: url(../../../../assets/img/check.svg) no-repeat;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
}

.selected_content_suffix {
  color: $mochica_color_gray;
  font-size: 12px;
}
</style>
