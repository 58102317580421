<template>
  <modal :is-view="isVisible">
    <template v-if="hasCloseButton === true" #rightTop>
      <div class="right-top" @click="onClickCancel">
        <img src="@/assets/img/close_white.svg" />
      </div>
    </template>

    <template #content>
      <div class="modal-window">
        <div class="header">
          <div class="title">開催時間の選択</div>
        </div>

        <div class="body">
          <div class="contents">
            <p
              v-if="
                !selection || !selection.venues || selection.venues.length === 0
              "
              class="text_warning"
            >
              ※この選考の情報が変更されています。確認してください。
            </p>
            <div class="selection_venue">
              <h2 class="selection_head">
                選考名： 「{{ selection.event ? selection.event.title : '' }}
                」で手動予約する、「日時・会場」を選択してください。
              </h2>
              <div v-if="isVisibleVenue === true" class="select_box">
                <ul class="selection_venue_table_head">
                  <li>日付</li>
                  <li>会場</li>
                  <li>住所</li>
                </ul>
                <ul class="selection_venue_table_body">
                  <li
                    v-for="venue in selection.venues ? selection.venues : []"
                    :key="venue.id"
                    :class="{ 'is-active': selectVenue.id === venue.id }"
                    class="selection_venue_item"
                    @click="onClickSelectVenue(venue)"
                  >
                    <div class="selection_venue_item-date">
                      {{ $utils.parseDateTime(venue.date, 'YYYY年M月D日') }}
                    </div>
                    <div class="selection_venue_item-name">
                      {{ venue.name }}
                    </div>
                    <div class="selection_venue_item-address">
                      {{ venue.address }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="isVisibleTime === true" class="selection_time">
              <h2 class="selection_head">予約する時間を選択してください。</h2>
              <ul class="select_box">
                <li
                  v-for="time in selectedVenueTimetables"
                  :key="time.id"
                  :class="{ 'is-active': selectTime.id === time.id }"
                  class="selection_time_item"
                  @click="onClickSelectTime(time)"
                >
                  {{ time.start }} 〜 {{ time.last }}
                </li>
              </ul>
            </div>
            <div v-if="isVisibleCheck === true" class="selection_preview">
              <h2 class="selection_head">
                以下の内容で日程を決定します。よろしければ「開催時間を割当て」ボタンを押してください。
              </h2>
              <div class="selection_preview_content">
                <dl>
                  <dt>選考名:</dt>
                  <dd>{{ selection.event ? selection.event.title : '' }}</dd>
                </dl>
                <dl>
                  <dt>日付:</dt>
                  <dd>
                    {{ $utils.parseDateTime(selectVenue.date, 'YYYY年M月D日') }}
                  </dd>
                </dl>
                <dl>
                  <dt>時間:</dt>
                  <dd>{{ selectTime.start }}〜{{ selectTime.last }}</dd>
                </dl>
                <dl>
                  <dt>会場名:</dt>
                  <dd>{{ selectVenue.name }}</dd>
                </dl>
                <dl>
                  <dt>会議室名:</dt>
                  <dd>{{ selectVenue.room }}</dd>
                </dl>
                <dl>
                  <dt>住所:</dt>
                  <dd>{{ selectVenue.address }}</dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="btn-col2">
            <button class="btn btn-unavailable" @click="onClickCancel">
              キャンセル
            </button>
            <button
              class="btn btn-availability"
              @click="
                onClickSettle();
                postPottosEvent(53);
              "
            >
              開催時間を割当て
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import Modal from '@/components/ui/modals/components/Modal';
import { postPottosEvent } from '@/utils/pottos';

export default defineComponent({
  name: 'ModalAssignTimeTable',
  components: { Modal },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Object,
      required: true,
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClickSettle', 'onClickCancel'],
  setup(props, context) {
    const store = useStore();
    const isVisibleVenue = ref(true);
    const isVisibleTime = ref(false);
    const isVisibleCheck = ref(false);
    const selectVenue = reactive({
      id: null,
      date: '',
      name: '',
      pref: '',
      address: '',
      room: '',
      notice: '',
    });
    const selectTime = reactive({
      id: null,
      venueId: null,
      start: '',
      last: '',
    });

    // computed
    const selectedVenueTimetables = computed(() => {
      if (!props.selection.venues) return null;
      const venue = props.selection.venues.find(
        venue => venue.id === selectVenue.id,
      );
      if (!venue) return null;
      return venue.timetables.map(timetable => ({
        id: timetable.id,
        limit: timetable.limit,
        start: timetable.start.slice(0, -3),
        last: timetable.last.slice(0, -3),
      }));
    });

    // methods
    const reset = () => {
      isVisibleVenue.value = true;
      isVisibleTime.value = false;
      isVisibleCheck.value = false;
      selectVenue.id = null;
      selectVenue.date = '';
      selectVenue.name = '';
      selectVenue.pref = '';
      selectVenue.address = '';
      selectVenue.room = '';
      selectVenue.notice = '';
      selectTime.id = null;
      selectTime.venueId = null;
      selectTime.start = '';
      selectTime.last = '';
    };
    const onClickSelectVenue = venue => {
      if (!venue) return;
      selectVenue.id = parseInt(venue.id, 10);
      selectVenue.date = venue.date;
      selectVenue.name = venue.name;
      selectVenue.pref = venue.pref;
      selectVenue.address = venue.address;
      selectVenue.room = venue.roomName;
      selectVenue.notice = venue.notice;
      selectTime.id = null;
      selectTime.venueId = parseInt(venue.id, 10);
      selectTime.start = '';
      selectTime.last = '';
      isVisibleTime.value = true;
      isVisibleCheck.value = false;
    };
    const onClickSelectTime = time => {
      if (!time) return;
      selectTime.id = parseInt(time.id, 10);
      selectTime.start = time.start;
      selectTime.last = time.last;
      isVisibleCheck.value = true;
    };
    const onClickSettle = () => {
      if (selectVenue.id !== null && selectTime.id !== null) {
        context.emit('onClickSettle', { timetable_id: selectTime.id });
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '「日時・会場」、「時間」を選択してください',
          type: false,
        });
      }
    };
    const onClickCancel = () => {
      reset();
      context.emit('onClickCancel', false);
    };

    onMounted(() => reset());

    return {
      isVisibleVenue,
      isVisibleTime,
      isVisibleCheck,
      selectVenue,
      selectTime,
      selectedVenueTimetables,
      onClickSelectVenue,
      onClickSelectTime,
      onClickSettle,
      onClickCancel,
      postPottosEvent,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.modal-window {
  width: calc(100% - 60px - 60px);
  height: 100%;
  margin: 0 60px;
  .body {
    padding: 30px;
  }
}

.text_warning {
  margin-bottom: 30px;
  text-align: left;
  color: $error-text;
}

.selection_head {
  display: flex;
  margin-bottom: 1rem;
  line-height: 1.25;
  align-items: center;
  text-align: left;
  &::before {
    @include flex_center_center;
    content: '1';
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    font-size: 1.6rem;
    font-weight: bold;
    border: 2px solid #656565;
    border-radius: 1.5rem;
    color: #656565;
  }
}

.select_box {
  border: 1px solid $border_color;
  padding: 20px 20px 0;
  border-radius: 0.6rem;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
}

.selection_venue {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $border_color;
  > .select_box {
    display: block;
    padding: 0;
  }
}

.selection_venue_table_head {
  background-color: #e2e2e2;
  display: flex;
  width: 100%;
  > li {
    display: flex;
    padding: 20px;
    font-weight: 700;
    &:nth-child(1) {
      padding: 20px 20px 20px 40px;
      min-width: 176px;
      max-width: 176px;
    }
    &:nth-child(2) {
      min-width: 120px;
      max-width: 330px;
      width: 100%;
    }
    &:nth-child(3) {
      width: 100%;
    }
  }
}

.selection_venue_table_body {
  width: 100%;
  .selection_venue_item {
    display: flex;
    border-bottom: 1px solid $border_color;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    .selection_venue_item-date {
      min-width: 176px;
      max-width: 176px;
      padding: 20px 20px 20px 40px;
    }
    .selection_venue_item-name {
      min-width: 120px;
      max-width: 330px;
      width: 100%;
      padding: 20px;
    }
    .selection_venue_item-address {
      width: 100%;
      padding: 20px;
    }
  }
}

.selection_venue_item {
  width: 100%;
  text-align: left;
  &.is-active {
    position: relative;
    color: #1899d6;
    background-color: #e4f3ff;
    font-weight: 700;
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 20px;
      left: 14px;
      width: 15px;
      height: 13px;
      background: url('../../../../assets/img/icon_check_blue.svg') 0 0
        no-repeat;
    }
  }
}

.selection_time {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $border_color;
  > .selection_head {
    &::before {
      content: '2';
    }
  }
  > .select_box {
    padding-bottom: 10px;
    padding-left: calc(20px - 1%);
  }
}

.selection_time_item {
  width: 24%;
  height: 3rem;
  line-height: 3rem;
  margin: 0 0 1% 1%;
  border: 1px solid $border_color;
  border-radius: 0.2rem;
  text-align: center;
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #1899d6;
    border: 1px solid #027db7;
    color: #fff;
  }
  &.is-active {
    font-weight: 700;
    border-color: #1699d6;
    color: #fff;
    background-color: #1699d6;
  }
}

.selection_preview {
  margin-bottom: 20px;
  .selection_head {
    &::before {
      content: '3';
    }
  }
}

.selection_preview_content {
  padding: 20px 20px 10px;
  font-size: 1.4rem;
  text-align: left;
  dl {
    margin: 0 0 20px 0;
    dt {
      font-weight: bold;
      color: #656565;
      display: inline;
      margin-right: 1rem;
    }
    dd {
      display: inline-block;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .modal-window {
    width: calc(100% - 20px - 20px);
    margin: 0 20px;
    .body {
      padding: 20px 15px;
    }
  }
  .selection_venue {
    display: block;
  }
  .selection_venue_item {
    margin-bottom: 20px;
  }
  .selection_venue_table_head {
    display: none;
  }
  .selection_venue_table_body {
    padding: 10px;
    .selection_venue_item {
      display: block;
      margin-bottom: 20px;
      transition: all ease 0.3s;
      text-align: center;
      cursor: pointer;
      &.is-active {
        position: relative;
        color: #1899d6;
        background-color: #fff;
        font-weight: 700;
        &:before {
          display: none;
        }
      }
      .selection_venue_item-date,
      .selection_venue_item-name {
        padding: 10px;
        min-width: 100%;
        max-width: 100%;
      }
      > div {
        display: block;
        padding: 10px;
      }
      .selection_venue_item-date {
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        color: #656565;
      }
      .selection_venue_item-name {
        padding: 1rem;
        line-height: 1rem;
        font-weight: bold;
        border-radius: 5px 5px 0 0;
        background: #c3c3c3;
        color: #fff;
        transition: all ease 0.3s;
      }
      .selection_venue_item-address {
        padding: 1rem;
        line-height: 2rem;
        border: 1px solid $border_color;
        border-radius: 0 0 5px 5px;
        transition: all ease 0.3s;
      }
      &:hover,
      &.is-active {
        .selection_venue_item-name {
          background-color: #1699d6;
        }
        .selection_venue_item-address {
          border-color: #1699d6;
        }
      }
    }
  }
  .selection_time {
    > .select_box {
      padding-bottom: 0;
    }
  }
  .selection_time_item {
    width: 100%;
    margin: 0 0 20px;
  }
  .btn-col2 {
    display: block;
    > .btn {
      width: 100%;
    }
    > .btn + .btn {
      margin-top: 10px;
    }
  }
}
</style>
