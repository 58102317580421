<script setup>
import { conversionToMegaByte } from '@/utils/file';

const props = defineProps({
  files: {
    type: Array,
    default: () => [],
  },
  deleteEnabled: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['update-files', 'download-file']);
const onClickDeleteFile = file => {
  emit(
    'update-files',
    props.files.filter(_file => _file.id !== file.id),
  );
};
</script>

<template>
  <div v-show="props.files.length > 0" class="success-file-wrapper">
    <div class="success-file-title">
      <img
        class="mail_icon_svg"
        src="@/assets/img/icon_file.svg"
        alt="フォルダのアイコン"
      />
      添付ファイル
    </div>
    <ul class="success-files">
      <li v-for="file in props.files" :key="file.id" class="success-file">
        <div class="file-name" @click="$emit('download-file', file)">
          {{ file.fileName }} ({{ conversionToMegaByte(file.fileSize, 100) }}MB)
        </div>
        <template v-if="deleteEnabled">
          <button-base
            type="button"
            :button-text="'削除'"
            :button-type="'secondary'"
            :icon-file-name="'trash'"
            @click="onClickDeleteFile(file)"
          />
        </template>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include contactFormStyle(mail);

.success-file-wrapper {
  margin: 20px 0 0;
  padding: 20px 20px 4px;
  background: $white;
  border: 1px solid #ededed;
  border-radius: 4px;
}
.success-file-title {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 0 14px;
  border-bottom: 4px solid #ededed;
  color: $mochica_color;
  font-weight: bold;
  img {
    display: block;
    width: 14px;
    height: auto;
  }
}
.success-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-height: 56px;
  padding: 12px 0;
  line-height: 1.5;
  &:not(:last-of-type) {
    border-bottom: 1px solid #ededed;
  }
  button.btn-base {
    padding: 8px 12px 6px;
    font-size: $font_14;
    white-space: nowrap;
  }
}
.file-name {
  color: $mochica_color;
  font-size: $font_14;
  text-decoration: underline;
  word-break: break-all;
  text-align: left;
  line-height: 1.5;
}
@media (max-width: ($media_query_sp)) {
  .success-file {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .success-file {
    button.btn-base {
      margin: 0 0 0 auto;
    }
  }
}
</style>
