import httpClient from '@/http-client';

const Company = function _Company() {
  const company = Object.create(Company.prototype);
  return company;
};

Company.prototype = {
  async fetchCompany() {
    const result = await httpClient.get('/my_company');
    if (result.status === 200) {
      return result.data;
    }
    // TODO:エラー時の処理をどうするか
    return null;
  },
  async updateCompany(name, zipcode, address, tel) {
    const res = await httpClient.patch('/my_company', {
      name,
      zipcode,
      address,
      tel,
    });
    return res.data;
  },
  async setIpAddress(ipEnable) {
    const res = await httpClient.patch('/my_company', { ip_enable: ipEnable });
    return res.data;
  },
  async updateCompanyEntryFormEnable(entryFormEnable) {
    const res = await httpClient.patch('/my_company', {
      entryform_enable: entryFormEnable,
    });
    return res.data;
  },
  async updateDefaultGraduated(graduatedYear) {
    const res = await httpClient.patch('/my_company', {
      graduated_default: graduatedYear,
    });
    return res.data;
  },
  async updateCompanyIsRequiredEmail(isRequiredEmail) {
    const res = await httpClient.patch('/my_company', {
      is_required_email: isRequiredEmail,
    });
    return res.data;
  },
  async updateLineAlert(linealert) {
    const res = await httpClient.patch('/my_company', { linealert });
    return res.data;
  },
  async updateIpAddress(ipAddresses) {
    const res = await httpClient.post('/my_company/ip_addresses', {
      ip_address: ipAddresses,
    });
    return res.data;
  },
  async getLineConsumption() {
    const res = await httpClient.get(
      '/my_company/count_line_messages_for_this_month',
    );
    return res.data;
  },
  async downloadQrCode() {
    const res = await httpClient.get(`/my_company/download_qrcode`, {
      responseType: 'blob',
    });
    if (res.status === 200) {
      const fileName = 'qrcode.png';
      const blobData = res.data;
      const type = {
        type: 'image/png',
      };
      const link = document.createElement('a');
      const blob = new Blob([blobData], type);

      if (window.navigator.msSaveOrOpenBlob) {
        // for ie
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else if (window.webkitURL && window.webkitURL.createObjectURL) {
        // for chrome (and safari)
        link.setAttribute('download', fileName);
        link.setAttribute('href', window.webkitURL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else if (window.URL && window.URL.createObjectURL) {
        // for firefox
        link.setAttribute('download', fileName);
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
      return {
        success: true,
        message: 'QRコードの画像をダウンロードしました',
      };
    }
    return {
      success: false,
      message: 'QRコードの画像をダウンロードできませんでした',
    };
  },
  async updateCalendarResources(payload) {
    const res = await httpClient.post(
      '/my_company/calendar_resources',
      payload,
    );
    return res.data;
  },
};
export default Company();
