<template>
  <div class="talk_input_wrapper">
    <ul class="tabs_layout">
      <li
        v-if="hasLine"
        :class="{
          tab_line: true,
          'is-current': isLineInput,
          'has-only_line': !hasEmail,
        }"
        @click="currentTabId = 1"
      >
        <span :class="isLineInput ? 'icon-line-active' : 'icon-line-inactive'">
          LINE送信
        </span>
      </li>
      <li
        v-if="hasEmail"
        :class="{
          tab_email: true,
          'is-current': !isLineInput,
          'has-only_mail': !hasLine,
        }"
        @click="currentTabId = 2"
      >
        <span :class="!isLineInput ? 'icon-mail-active' : 'icon-mail-inactive'">
          メールで送信
        </span>
      </li>
    </ul>
    <div v-if="isLineInput">
      <div v-if="applicant.lineBlock" class="line_block_area">
        <p>ブロックされているため、メッセージを送信できません。</p>
      </div>
      <template v-else>
        <ul class="input_support_tools">
          <li
            v-if="!isUploadImage"
            class="emoji-picker-wrapper"
            @click="isUploadImage = false"
          >
            <emoji-picker @selected="appendEmoji" />
          </li>
          <li v-if="!isUploadImage" @click="openModalFixedPhraseList">
            <img src="@/assets/img/icon_template.svg" />
            テンプレート
          </li>
          <li v-if="!isUploadImage" @click="isUploadImage = !isUploadImage">
            <img src="@/assets/img/icon_picture.svg" />
            画像
            <span>（jpgのみ）</span>
          </li>
          <li
            v-else
            @click="
              resetImageFile();
              isUploadImage = !isUploadImage;
            "
          >
            <img src="@/assets/img/icon_text.svg" />
            テキスト
          </li>
        </ul>
        <div class="line_talk_input">
          <textarea
            v-if="!isUploadImage"
            v-model="lineContent"
            class="notice_form-textarea form-line"
            placeholder="送信メッセージを入力して下さい。"
            maxlength="1000"
          />
          <div v-else class="line_talk_upload_image">
            <attachment-file
              ref="refLineImage"
              :limit-size="8"
              :is-only-jpg="true"
              @setFile="setLineImage"
            />
          </div>
        </div>
      </template>
    </div>
    <div v-else-if="!isLineInput">
      <ul class="input_support_tools">
        <li @click="openModalFixedPhraseList">
          <img src="@/assets/img/icon_template.svg" />
          テンプレート
        </li>
      </ul>
      <div class="email_talk_input">
        <input
          v-model="emailTitle"
          name="mail_title"
          class="notice_form-input form-line"
          placeholder="件名を入力して下さい。"
          maxlength="1000"
        />
        <textarea
          v-model="emailContent"
          name="mail_content"
          class="notice_form-textarea form-line"
          placeholder="本文を入力してください。"
          maxlength="5000"
        />
      </div>
    </div>
    <div v-if="isLineInput" class="line_button_area">
      <p class="input_caption">
        <span v-if="!applicant.lineBlock">送信メッセージは1000文字まで</span>
      </p>
      <div v-if="applicant.lineBlock" class="btn btn-availability is-disabled">
        <img class="icon" src="@/assets/img/send_mail_white.svg" />
        送信確認画面へ
      </div>
      <div
        v-else
        class="btn btn-availability"
        :class="{ 'is-disabled': isSending }"
        @click="sendLineMessageConfirm"
      >
        <img class="icon" src="@/assets/img/send_mail_white.svg" />
        送信確認画面へ
      </div>
    </div>
    <div v-else-if="!isLineInput" class="email_button_area">
      <p class="email_caption">※ 末尾には設定している署名が挿入されます</p>
      <p class="email_caption">
        ※ 添付ファイルは３つまで、かつ合計3MBまで添付できます。
      </p>
      <attachment-file
        ref="refEmailFile"
        :files="emailFiles"
        @setFile="setMailFile"
        @deleteFile="deleteMailFile"
      />
      <div class="button_send_wrapper">
        <div
          class="btn btn-availability"
          :class="{ 'is-disabled': isSending }"
          @click="sendEmailMessageConfirm"
        >
          <img class="icon" src="@/assets/img/send_mail_white.svg" />
          送信確認画面へ
        </div>
      </div>
    </div>

    <modal-fixed-phrase-list
      :fixed-phrase-list="fixedPhraseList"
      :is-view="isOpenModalSelectFixedPhraseList"
      @openFixedPhrase="openModalFixedPhrase"
      @onClickCloseModalSelectPhraseList="closeModalFixedPhraseList"
    />
    <modal-select-fixed-phrase
      :fixed-phrase="fixedPhrase.data"
      :is-view="isOpenModalSelectFixedPhrase"
      @onClickSubmitModalSelectPhrase="submitModalSelectFixedPhrase"
      @onClickCloseModalSelectPhrase="closeModalSelectFixedPhrase"
    />
    <modal-send-confirm
      ref="refModalSendLine"
      :is-view="isViewSendLineConfirm"
      :line-content="lineContent"
      @onSelected="onSelectSendLineMessage"
      @onClickOuter="isViewSendLineConfirm = false"
      @onClickUnreadNeed="onClickUnreadNeed"
    />
    <modal-send-confirm
      ref="refModalSendEmail"
      :is-view="isViewSendEmailConfirm"
      :mail-title="emailTitle"
      :mail-content="emailContent"
      :mail-files="emailFiles"
      @onSelected="onSelectSendEmail"
      @onClickOuter="isViewSendEmailConfirm = false"
      @onClickUnreadNeed="onClickUnreadNeed"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import lineTalkService from '@/services/linetalk';
import sendgridSendService from '@/services/sendgrid_send';
import fixphraseService from '@/services/fixphrase';
import Applicant from '@/models/applicant';
import EmojiPicker from '@/components/ui/menus/components/EmojiPicker';
import AttachmentFile from '@/components/ui/attachmentFile/components/AttachmentFile';
import ModalFixedPhraseList from '@/components/features/fixedPhrase/components/ModalFixedPhraseList';
import ModalSelectFixedPhrase from '@/components/features/fixedPhrase/components/ModalSelectFixedPhrase';
import ModalSendConfirm from '@/components/page/applicants/_id/components/ModalSendConfirm';
import { postPottosEvent } from '@/utils/pottos';

export default defineComponent({
  name: 'TalkInput',
  components: {
    EmojiPicker,
    ModalSendConfirm,
    AttachmentFile,
    ModalFixedPhraseList,
    ModalSelectFixedPhrase,
  },
  props: {
    applicantGraduatedId: {
      type: Number,
      required: true,
    },
  },
  emits: ['fetchNewMessage'],
  setup(props, context) {
    const store = useStore();
    const refLineImage = ref(null);
    const refEmailFile = ref(null);
    const refModalSendLine = ref(null);
    const refModalSendEmail = ref(null);
    const refEmojiPicker = ref(null);
    const currentTabId = ref(1);
    const lineContent = ref('');
    const emailTitle = ref('');
    const emailContent = ref('');
    const emailFiles = ref([]);
    const fixedPhraseList = ref([]);
    const fixedPhrase = reactive({ data: {} });
    const lineFile = reactive({ file: null });
    const isUploadImage = ref(false);
    // const isUploadingImage = ref(false);
    const isUnreadNeed = ref(false);
    const isViewSendLineConfirm = ref(false);
    const isViewSendEmailConfirm = ref(false);
    const isOpenModalSelectFixedPhraseList = ref(false);
    const isOpenModalSelectFixedPhrase = ref(false);
    const isSending = ref(false);

    const applicant = computed(() => {
      return store.getters['applicant/applicant'];
    });
    const company = computed(() => {
      return store.getters['company/company'];
    });
    const isLineInput = computed(() => {
      return currentTabId.value === 1;
    });
    const hasLine = computed(() => {
      return applicant.value.contactTypeId === 2;
    });
    const hasEmail = computed(() => {
      return applicant.value.email !== null;
    });
    // テンプレート一覧モーダルを開く
    const openModalFixedPhraseList = async () => {
      const res = await fixphraseService.postFixPhraseList(
        props.applicantGraduatedId,
      );
      if (res.success) {
        fixedPhraseList.value = cloneDeep(res.fixphrase_list);
      }
      isUploadImage.value = false;
      isOpenModalSelectFixedPhraseList.value = true;
    };
    // テンプレート一覧モーダルを閉じる
    const closeModalFixedPhraseList = () => {
      isOpenModalSelectFixedPhraseList.value = false;
    };
    // テンプレート詳細モーダルを開く
    const openModalFixedPhrase = payload => {
      closeModalFixedPhraseList();
      fixedPhrase.data = cloneDeep(payload);
      isOpenModalSelectFixedPhrase.value = true;
    };
    // テンプレート詳細モーダルを閉じる
    const closeModalSelectFixedPhrase = () => {
      isOpenModalSelectFixedPhrase.value = false;
      isOpenModalSelectFixedPhraseList.value = true;
    };
    // テンプレートのTalkへの反映
    const submitModalSelectFixedPhrase = payload => {
      // テンプレートの反映
      if (isLineInput.value) {
        // LINEの場合
        const tmpLineContent = Applicant.replaceApplicantNameAndCompanyName(
          payload.line_content,
          applicant.value,
          company.value.name,
        );
        lineContent.value = lineContent.value + tmpLineContent;
      } else {
        // メールの場合
        const tmpEmailTitle = Applicant.replaceApplicantNameAndCompanyName(
          payload.mail_title,
          applicant.value,
          company.value.name,
        );
        const tmpEmailContent = Applicant.replaceApplicantNameAndCompanyName(
          payload.mail_content,
          applicant.value,
          company.value.name,
        );
        emailTitle.value = emailTitle.value + tmpEmailTitle;
        emailContent.value = emailContent.value + tmpEmailContent;
      }
      isOpenModalSelectFixedPhrase.value = false;
    };
    // 絵文字処理
    const appendEmoji = emoji => {
      const textArea = document.querySelector(
        '.notice_form-textarea.form-line',
      );
      const beforeText = textArea.value.substring(0, textArea.selectionStart);
      const afterText = textArea.value.substring(
        textArea.selectionStart,
        textArea.value.length,
      );
      lineContent.value = `${beforeText}${emoji}${afterText}`;
    };
    // メッセージ確認
    const onClickUnreadNeed = val => {
      isUnreadNeed.value = val;
      postPottosEvent(19);
    };
    // LINE画像セット
    const setLineImage = file => {
      lineFile.file = file;
    };
    // メール添付ファイルセット
    const setMailFile = file => {
      emailFiles.value.push(file);
    };
    // メール添付ファイル削除
    const deleteMailFile = fileId => {
      emailFiles.value = emailFiles.value.filter(file => file.id !== fileId);
    };
    // LINE送信確認
    const sendLineMessageConfirm = async () => {
      if (isSending.value === true) return;
      if (isUploadImage.value) {
        if (!lineFile.file) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: '画像ファイルを選択してください',
            type: false,
          });
          return;
        }
        await sendLineImageConfirm();
        return;
      }
      if (!lineContent.value && lineContent.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '送信メッセージを入力してください',
          type: false,
        });
        return;
      }
      isViewSendLineConfirm.value = true;
    };
    // LINEメッセージ送信処理
    const onSelectSendLineMessage = async payload => {
      isViewSendLineConfirm.value = false;
      // payload.choice === 1 => キャンセル, 2 => はい
      if (payload.choice === 2) {
        postPottosEvent(17);
        await sendLineMessage();
      } else {
        postPottosEvent(18);
      }
    };
    const sendLineMessage = async () => {
      isSending.value = true;
      const res = await lineTalkService.sendLineMassage(
        applicant.value.id,
        lineContent.value,
        isUnreadNeed.value,
      );
      if (res.success) {
        if (refLineImage.value) {
          refLineImage.value.resetFile();
        }
        lineContent.value = '';
        context.emit('fetchNewMessage');
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'メッセージの送信に失敗しました。',
          type: false,
        });
      }
      isUnreadNeed.value = false;
      refModalSendLine.value.resetData();
      isSending.value = false;
    };
    // LINE画像送信確認モーダル表示
    const sendLineImageConfirm = async () => {
      if (isSending.value === true) return;
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: '画像送信',
        message: '画像を送信しますか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          if (payload.choice === 2) await sendLineImage();
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    // LINE画像送信
    const sendLineImage = async uploadImageId => {
      isSending.value = true;

      // isUploadingImage.value = true;
      const formData = new FormData();
      formData.append('applicant_id', applicant.value.id);
      formData.append('file', lineFile.file);

      const res = await lineTalkService.sendLineImage(formData);
      if (res.success) {
        if (refLineImage.value) {
          refLineImage.value.resetFile();
        }
        lineContent.value = '';
        context.emit('fetchNewMessage', { hasImage: true });
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '画像メッセージの送信に失敗しました。',
          type: false,
        });
      }
      isSending.value = false;
    };
    // メール送信確認モーダル表示
    const sendEmailMessageConfirm = async () => {
      if (isSending.value === true) return;
      if (emailTitle.value.length === 0 || emailContent.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '件名と本文を入力してください',
          type: false,
        });
        return;
      }
      isViewSendEmailConfirm.value = true;
    };
    // メール送信処理
    const onSelectSendEmail = async payload => {
      isViewSendEmailConfirm.value = false;
      // payload.choice === 1 => キャンセル, 2 => はい
      if (payload.choice === 2) {
        postPottosEvent(17);
        await sendEmail();
      } else {
        postPottosEvent(18);
      }
    };
    const sendEmail = async () => {
      isSending.value = true;
      const res = await sendgridSendService.sendEmail({
        applicantId: applicant.value.id,
        title: emailTitle.value,
        content: emailContent.value,
        files: emailFiles.value,
        unreadNeed: isUnreadNeed.value,
      });
      if (res.success) {
        const args = {};
        if (emailFiles.value.length > 0) args.hasImage = true;
        emailTitle.value = '';
        emailContent.value = '';
        emailFiles.value = [];
        refEmailFile.value.resetFiles();
        context.emit('fetchNewMessage', args);
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'メッセージの送信に失敗しました。',
          type: false,
        });
      }
      isUnreadNeed.value = false;
      refModalSendEmail.value.resetData();
      isSending.value = false;
    };
    const resetImageFile = () => {
      lineFile.file = null;
    };
    onMounted(() => {
      currentTabId.value = hasLine.value ? 1 : props.applicantGraduatedId;
    });

    return {
      refLineImage,
      refEmailFile,
      refModalSendLine,
      refModalSendEmail,
      refEmojiPicker,
      currentTabId,
      lineContent,
      emailTitle,
      emailContent,
      emailFiles,
      fixedPhraseList,
      fixedPhrase,
      lineFile,
      isUploadImage,
      isUnreadNeed,
      isViewSendLineConfirm,
      isViewSendEmailConfirm,
      isOpenModalSelectFixedPhraseList,
      isOpenModalSelectFixedPhrase,
      isSending,
      applicant,
      company,
      isLineInput,
      hasLine,
      hasEmail,
      openModalFixedPhraseList,
      openModalFixedPhrase,
      closeModalFixedPhraseList,
      closeModalSelectFixedPhrase,
      submitModalSelectFixedPhrase,
      setLineImage,
      onClickUnreadNeed,
      appendEmoji,
      setMailFile,
      deleteMailFile,
      sendLineMessageConfirm,
      onSelectSendLineMessage,
      sendEmailMessageConfirm,
      onSelectSendEmail,
      resetImageFile,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.talk_input_wrapper {
  position: relative;
}

.tabs_layout {
  position: absolute;
  display: flex;
  height: 56px;
  margin-top: -55px;
  > li {
    width: 180px;
    font-size: 1.3rem;
    line-height: 26px;
    font-weight: bold;
    text-align: center;
    background-color: $tab_inactive_color;
    color: $tab_text_color;
    border-top: 1px solid $tab_border_color;
    cursor: pointer;
    &.tab_line {
      border-radius: 3px 0 0 0;
      border-left: 1px solid $tab_border_color;
      &.has-only_line {
        border-right: 1px solid $tab_border_color;
        border-radius: 3px 3px 0 0;
      }

      .icon-line-active::before {
        content: '';
        background-image: url(../../../../../assets/img/icon_line.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        position: relative;
        height: 25px;
        width: 25px;
        vertical-align: middle;
      }
      .icon-line-inactive::before {
        content: '';
        background-image: url(../../../../../assets/img/icon_line_disabled.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        position: relative;
        height: 25px;
        width: 25px;
        vertical-align: middle;
      }
    }
    &.tab_email {
      border-radius: 0 3px 0 0;
      border-right: 1px solid $tab_border_color;
      &.has-only_mail {
        border-left: 1px solid $tab_border_color;
        border-radius: 3px 3px 0 0;
      }
      // TODO: ここに記入
      .icon-mail-active::before {
        content: '';
        mask-image: url(../../../../../assets/img/icon_email.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        display: inline-block;
        position: relative;
        background-color: $email_color;
        top: 6px;
        height: 22px;
        width: 22px;
      }
      .icon-mail-inactive::before {
        content: '';
        mask-image: url(../../../../../assets/img/icon_email.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        display: inline-block;
        position: relative;
        background-color: #bbbbbb;
        top: 6px;
        height: 22px;
        width: 22px;
      }
    }
    > span {
      display: block;
      width: 100%;
      height: 50px;
      padding: 10px;
      border-top: $tab_inactive_color 3px solid;
    }
    &.is-current {
      background-color: #fff;
      > span {
        border-color: #fff;
      }
      &.tab_line > span {
        border-color: $line_color;
        color: $line_color;
      }
      &.tab_email > span {
        border-color: $email_color;
        color: $email_color;
      }
    }
  }
}

.input_support_tools {
  display: flex;
  background-color: #f6f6f6b2;
  margin-top: -1px;
  border-top: 1px solid $tab_border_color;
  border-left: 1px solid $tab_border_color;
  border-right: 1px solid $tab_border_color;
  border-radius: 0 4px 0 0;
  font-size: 1.1rem;
  padding: 16px;
  > li {
    height: 1.5rem;
    line-height: 1;
    margin-right: 1.4rem;
    cursor: pointer;
    img {
      height: 1.5rem;
      vertical-align: -0.4rem;
    }
    &:nth-child(3) > img {
      margin-right: 0.4rem;
    }
    > span {
      margin-left: -0.6rem;
      font-size: 1rem;
      color: #777;
    }
  }
}

.line_talk_input {
  .notice_form-textarea {
    display: block;
    width: 100%;
    height: 19rem;
    min-height: 19rem;
    max-height: 19rem;
    padding: 1rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6em;
    border-top: none;
    border-bottom: 1px solid $tab_border_color;
    border-left: 1px solid $tab_border_color;
    border-right: 1px solid $tab_border_color;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
  }
  .line_talk_upload_image {
    padding: 1rem;
    border-top: none !important;
    border-bottom: 1px solid $tab_border_color;
    border-left: 1px solid $tab_border_color;
    border-right: 1px solid $tab_border_color;
    border-radius: 0 0 4px 4px;
  }
}

.line_block_area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19rem + 4.15rem;
  background-color: #f9f9f9;
  border: 1px solid $tab_border_color;
  border-radius: 0 0 4px 4px;
}

.btn-availability.is-disabled {
  background-color: #bbb;
  cursor: default;
}

.email_talk_input {
  > .notice_form-input {
    display: block;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 1;
    border-top: none;
    border-left: 1px solid $tab_border_color;
    border-right: 1px solid $tab_border_color;
    border-bottom: 1px solid #e3e3e3;
    background-color: #fff;
  }
  > .notice_form-textarea {
    display: block;
    width: 100%;
    min-height: 15rem;
    height: 15rem;
    padding: 1rem;
    margin-bottom: 1rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6em;
    border-top: none;
    border-bottom: 1px solid $tab_border_color;
    border-left: 1px solid $tab_border_color;
    border-right: 1px solid $tab_border_color;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
  }
}

.input_caption {
  font-size: 1.2rem;
  margin: 1rem 0;
  color: #777;
}

.email_caption {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.line_button_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .btn-availability {
    margin-top: 0.6rem;
  }
}

.email_button_area {
  .button_send_wrapper {
    display: flex;
    margin-top: 0.6rem;
    .btn-availability {
      margin: 0 0 0 auto;
    }
  }
}

// modal
.content .body .message {
  padding: 2.4rem;
  font-size: 1.2rem;
  line-height: 1.6;
}

@media (max-width: ($media_query_sp)) {
  .line_button_area {
    display: block;
    > .btn-availability {
      width: 100%;
    }
  }
  .email_button_area {
    .button_send_wrapper {
      display: block;
      margin-left: 20px;
      margin-right: 20px;
      > .btn-availability {
        width: 100%;
      }
    }
  }

  .email_caption {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
