import qs from 'qs';

import httpClient from '@/http-client';
import Applicant from '@/models/applicant';
import { getInitSearchSurveyForRequest } from '@/models/applicant-search';
import { download } from '@/utils/file';

const ApplicantsManager = function _ApplicantsManager() {
  const manager = Object.create(ApplicantsManager.prototype);
  return manager;
};

ApplicantsManager.prototype = {
  async fetchApplicants() {
    const response = await httpClient.get('/applicants');
    return response.data.applicants.map(Applicant.createFromJSON);
  },
  async fetchApplicantsSearch(
    graduated,
    page = 1,
    load = 1,
    searchBody = {},
    searchFlags = {
      or_flag: false,
      is_all_applicants: false,
      is_only_line_applicants: false,
      is_only_applicants_of_line: false,
    },
    sort = null,
    searchSurvey = getInitSearchSurveyForRequest(),
    isExceptGraduated = false,
  ) {
    let order = '';
    if (sort && sort.sort_order != null && sort.sort_by != null) {
      order = '&';
      if (sort.sort_order != null) {
        order = `${order}sort_order=${sort.sort_order ? 'desc' : 'asc'}`;
      }
      if (sort.sort_by != null) {
        order = `${order}&sort_by=${sort.sort_by}`;
      }
    }

    const strGraduated = !isExceptGraduated ? `graduated=${graduated}&` : '';
    const path = `/applicants/search?${strGraduated}load=${load}&page=${page}${order}`;
    const response = await httpClient.post(path, {
      search: searchBody,
      search_survey: searchSurvey,
      search_flags: searchFlags,
    });
    if (response.status === 200) {
      // TODO: APIからのレスポンスをフロント都合でkey名を変更しているので修正したい
      return {
        count: response.data.count,
        load: response.data.load,
        page: response.data.page,
        sheet_title: response.data.sheet_title,
        pageCount: response.data.page_count,
        applicants: response.data.applicants
          ? response.data.applicants.map(Applicant.createFromJSON)
          : [],
        searchFilter: {
          search: searchBody,
          search_survey: searchSurvey,
          search_flags: searchFlags,
        },
      };
    }
    return {
      error: true,
      sheet_title: [],
      applicants: [],
      page: 0,
      count: 0,
      pageCount: 0,
      load: 200,
      searchFilter: {
        search: searchBody,
        search_survey: searchSurvey,
        search_flags: searchFlags,
      },
    };
  },
  async fetchApplicant(id) {
    const response = await httpClient.get(`/applicants/${id}`);
    return response.data.success
      ? Applicant.createFromJSON(response.data.applicant)
      : null;
  },
  async fetchApplicantsHaveSelection(selectionId) {
    const query = qs.stringify({ not_announced: true });
    const response = await httpClient.get(
      `/selections/${selectionId}/applicants?${query}`,
    );
    return response.data.applicants.map(Applicant.createFromJSON);
  },
  async fetchNotAnnounceApplicants(graduatedId, selectionId) {
    const res = await httpClient.post('/applicants/notannounce', {
      graduated_id: graduatedId,
      selection_id: selectionId,
    });
    return res.data.applicants.map(Applicant.createFromJSON);
  },
  async fetchAttendanceDates(payload) {
    const res = await httpClient.post(
      '/applicants/get_attendance_date',
      payload,
    );
    return res.data;
  },
  async fetchDoubleCheckApplicantIds() {
    const res = await httpClient.post('/applicants_edit/double_check', {});
    return res.data;
  },
  async fetchApplicantsEditGetApplicants(searchIds = []) {
    const path = '/applicants_edit/get_applicants';
    const response = await httpClient.post(path, { applicant_ids: searchIds });
    if (response.status === 200) {
      return {
        count: response.data.count,
        load: response.data.load,
        page: response.data.page,
        sheet_title: response.data.sheet_title,
        pageCount: response.data.page_count,
        applicants: response.data.applicants
          ? response.data.applicants.map(Applicant.createFromJSON)
          : [],
      };
    }
    return { error: true, applicants: [], count: 0 };
  },
  /**
   * @deprecated applicantsは廃止になりました。/applicants/provisionが今までの/applicantsの位置づけになっています
   * @param applicant
   * @returns {Promise<void>}
   */
  async createApplicant(applicant) {
    const response = await httpClient.post('/applicants', { applicant });
    return response.data.success;
  },
  async createApplicantProvision(applicant) {
    const response = await httpClient.post('/applicants/provision', applicant);
    if (response.status === 200) {
      return {
        isSuccess: true,
        isAlreadyRegistered: false,
        data: response.data ? response.data : null,
      };
    } else if (response.status === 409) {
      return {
        isSuccess: false,
        isAlreadyRegistered: true,
        data: response.data ? response.data : null,
      };
    }
    return {
      isSuccess: false,
      isAlreadyRegistered: false,
      message: response.data.message,
      success: response.data.success,
      data: response.data ? response.data : null,
    };
  },
  async createApplicantWithLine(
    { firstname, lastname, gender, school, graduated },
    token,
  ) {
    const response = await httpClient.post('/applicants/line', {
      applicant: { firstname, lastname, gender, school, graduated, token },
    });
    return response.data.success;
  },
  async updateApplicant(applicant) {
    const response = await httpClient.put(`/applicants/${applicant.id}`, {
      applicant,
    });
    return response.data;
  },
  async deleteApplicant(applicantId) {
    const res = await httpClient.delete(`/applicants/${applicantId}`);
    return res.data;
  },
  async deleteApplicantTagGroup(applicantId, tagGroupId) {
    const result = await httpClient.delete(
      `/applicants/${applicantId}/tag_groups/${tagGroupId}`,
    );
    return result;
  },
  async announceSkipApplicant(payload) {
    const response = await httpClient.patch(
      `/applicants/${payload.applicant_id}/progresses/current/not-announced`,
      { progress_id: payload.progress_id },
    );
    return response.data;
  },
  async announceSkipApplicants(payload) {
    const response = await httpClient.patch(
      '/applicants/progresses/current/not-announced',
      payload,
    );
    return response.data;
  },
  async deleteSelection(applicantId, progressId) {
    const response = await httpClient.delete(
      `/applicants/${applicantId}/progress/${progressId}`,
    );
    return response.data;
  },
  async downloadSelectionCsv(selectionId) {
    const response = await httpClient.post(
      '/applicants/download/csv/selection',
      { selection_id: selectionId },
    );
    return response;
  },
  async downloadVenueCsv(venueId) {
    const response = await httpClient.post('/applicants/download/csv/venue', {
      venue_id: venueId,
    });
    return response;
  },
  async fetchDownloadReserveList() {
    const res = await httpClient.get('/uploadcsv/list');
    return res.data;
  },
  async downloadUploadCsv(payload) {
    const res = await httpClient.get(`/uploadcsv/download/${payload.id}`, {
      responseType: 'blob',
    });
    if (res.data.success) {
      download(res.data, payload.filename);
      return res.data;
    }
    return res.data;
  },
  async deleteCsvReservedData(payload) {
    const res = await httpClient.post('/uploadcsv/delete', payload);
    return res.data;
  },
  async reserveUploadCsv(payload) {
    const res = await httpClient.post('/uploadcsv/reserve', payload);
    return res.data;
  },
  async fetchCurrentProgressApplicants(applicantIds) {
    const query = applicantIds.map(id => `id=${id}`).join('&');
    const response = await httpClient.get(
      `/applicants/progress/current?${query}`,
    );
    return response.data;
  },
  async currentProgressRevert(applicantId) {
    const result = await httpClient.post(
      `/applicants/${applicantId}/progress/revert`,
    );
    return result.data;
  },
  async createBulkApplicantTags(applicantIds, tagIds) {
    const response = await httpClient.post('/applicants/taglist', {
      applicants: applicantIds,
      tags: tagIds,
    });
    return response.data;
  },
  async postEntryForm(payload) {
    const res = await httpClient.post(
      '/applicants/post_bulk_entryform',
      payload,
    );
    return res.data;
  },
  async getAggregateApplicant() {
    const res = await httpClient.get('/applicants/aggregate/applicant');
    return res.data;
  },
  async fetchMypageLogs(payload) {
    const res = await httpClient.get(`/applicants/${payload.id}/mypage_log`);
    return res.data;
  },
  async fetchDeletableApplicantSelection(payload) {
    const res = await httpClient.post(
      '/applicants/selection/deletable',
      payload,
    );
    return res.data;
  },
  async deleteProgresses(payload) {
    const res = await httpClient.post('/applicants/progress', payload);
    return res.data;
  },
  async fetchApplicantFlagsurveyNotices(id) {
    const response = await httpClient.get(
      `/applicants/${id}/flagsurvey_notice`,
    );
    return response.data;
  },
  async fetchApplicantFlagsurveyNotice(id, flagSurveyNoticeId) {
    const response = await httpClient.get(
      `/applicants/${id}/flagsurvey_notice/${flagSurveyNoticeId}`,
    );
    return response.data;
  },
};

export default ApplicantsManager();
