<template>
  <div class="home_content">
    <p class="home_sub_title">3日以内の選考状況</p>
    <p class="home_caption">3日以内の状態を表示します。</p>
    <div class="home_counter">
      <dl
        class="is-selection_reserves"
        @click="$router.push({ name: 'ApplicantsSelectionReserves' })"
      >
        <dt>直近の選考予約</dt>
        <dd>{{ selectionReservesCount }}</dd>
      </dl>
    </div>
    <div class="home_counter">
      <dl
        class="is-applicants"
        @click="$router.push({ name: 'ApplicantsEnteredApplicants' })"
      >
        <dt>直近の応募者</dt>
        <dd>{{ enteredApplicantsCount }}</dd>
      </dl>
    </div>
    <div class="home_counter">
      <dl class="is-files" @click="$router.push({ name: 'ApplicantsUploads' })">
        <dt>直近のファイル</dt>
        <dd>{{ filesCount }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import applicantService from '@/services/applicant';

export default defineComponent({
  name: 'HomeLatest',
  setup(props, context) {
    const store = useStore();
    const selectionReservesCount = ref(null);
    const enteredApplicantsCount = ref(null);
    const filesCount = ref(null);

    const fetchAll = async () => {
      // 並行処理
      const res = await Promise.all([
        applicantService.getSelectionReservesSummary(),
        applicantService.getEnteredApplicantsSummary(),
        applicantService.getEnteredUploadsSummary(),
      ]);
      if (res[0].success === true) {
        selectionReservesCount.value = res[0].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[0].message,
          type: false,
        });
      }
      if (res[1].success === true) {
        enteredApplicantsCount.value = res[1].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[1].message,
          type: false,
        });
      }
      if (res[2].success === true) {
        filesCount.value = res[2].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[2].message,
          type: false,
        });
      }
    };

    return {
      selectionReservesCount,
      enteredApplicantsCount,
      filesCount,
      fetchAll,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.home_sub_title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
}

.home_caption {
  margin-bottom: 24px;
  font-size: 14px;
  color: #777777;
}

.home_content {
  padding: 30px 30px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
}

.home_counter {
  min-height: 94px;
  padding: 17px 20px;
  margin-bottom: 12px;
  border: 1px solid #d7ebf5;
  border-radius: 4px;
  background: #f3f9fc;
  cursor: pointer;
  > dl {
    line-height: 1;
    > dt {
      position: relative;
      padding-left: 22px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 16px;
        height: 15px;
        background-position: 0 0;
        background-size: auto 15px;
        background-repeat: no-repeat;
        pointer-events: none;
      }
      &:after {
        content: '';
        position: absolute;
        top: 3px;
        right: 0;
        width: 8px;
        height: 13px;
        background-image: url('../../../../assets/img/arrow_right_blue.svg');
        background-size: auto 12px;
        background-position: 0 0;
        background-repeat: no-repeat;
        pointer-events: none;
      }
    }
    > dd {
      padding-left: 22px;
      font-size: 32px;
      font-weight: 700;
      color: #1c91c8;
    }
    &.is-selection_reserves {
      > dt:before {
        top: 1px;
        background-image: url('../../../../assets/img/two_men_blue.svg');
      }
    }
    &.is-applicants {
      > dt:before {
        width: 17px;
        background-image: url('../../../../assets/img/man_blue.svg');
      }
    }
    &.is-files {
      > dt:before {
        background-image: url('../../../../assets/img/icon_file_white.svg');
      }
    }
  }
}
</style>
