import httpClient from '@/http-client';

const InitialSettings = function _InitialSettings() {
  const company = Object.create(InitialSettings.prototype);
  return company;
};

InitialSettings.prototype = {
  async initializeCompanySetting(payload) {
    const res = await httpClient.post(
      'initial_settings/initialize_company_setting',
      payload,
    );
    return res.data;
  },
};
export default InitialSettings();
