<script setup>
import { ref } from 'vue';

defineProps({
  isView: {
    type: Boolean,
    required: false,
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['open', 'close']);

const resolvePromise = ref(null);

const openModal = () => {
  emit('open');
  return new Promise((resolve, reject) => {
    resolvePromise.value = { resolve, reject };
  });
};
const confirm = () => {
  resolvePromise.value.resolve(true);
  emit('close');
};
const cancel = () => {
  resolvePromise.value.resolve(false);
  emit('close');
};

defineExpose({ openModal });
</script>

<template>
  <div class="leave-confirm-wrapper">
    <modal-window
      :is-visible="isView"
      :modal-type="'danger'"
      :title="title"
      :max-width="'700px'"
      @clickOuter="emit('close')"
      @close="cancel"
    >
      <template #content>
        <div class="modal-contents">
          <div class="modal-contents-text">
            <div>
              {{ message }}
            </div>
          </div>
          <div class="modal-contents-bottom">
            <button-base
              :button-text="'キャンセル'"
              button-type="secondary"
              @click="cancel"
            />
            <button-base
              :button-text="'画面を閉じる'"
              :button-type="'danger'"
              @click="confirm"
            />
          </div>
        </div>
      </template>
    </modal-window>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
.leave-confirm-wrapper {
  .modal-contents {
    padding: 24px;
    &-text {
      white-space: pre-line;
    }
    &-bottom {
      display: flex;
      gap: 8px;
      margin-top: 40px;
      button {
        height: 52px;
        width: 100%;
      }
    }
  }
}
</style>
