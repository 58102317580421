<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useField } from 'vee-validate';
import { useRouter } from 'vue-router';

import BaseInput from '@/components/ui/input/BaseInput.vue';
import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import Highlight from '@/components/ui/text/Highlight.vue';
import AutoActionsTemplate from '@/components/features/autoActions/templates/AutoActionsTemplate.vue';
import FlagGroupSelect from '@/components/features/flagGroup/FlagGroupSelect.vue';
import SelectionsSearchModal from '@/components/features/autoActions/modals/SelectionsSearchModal.vue';

import {
  PROGRESS_STATUSES,
  FINISHED_PROGRESS_STATUSES,
  PROGRESS_STATUSES_LABEL,
  FINISHED_PROGRESS_STATUSES_LABEL,
} from '@/defines/progresses';
import { ACTION_TYPES, FORM_COMPONENTS } from '@/defines/autoActions';

const ACTION_TYPE_SELECT_OPTIONS = Object.freeze({
  assignProgress: {
    label: '新しく選考を割り当てる',
    value: ACTION_TYPES.assignProgress,
  },
  announce: {
    label: '選考の案内を通知',
    value: ACTION_TYPES.announce,
  },
  results: {
    label: '選考結果を通知',
    value: ACTION_TYPES.results,
  },
  richMessage: {
    label: 'リッチメッセージを送信',
    value: ACTION_TYPES.richMessage,
  },
  flagSurvey: {
    label: 'アンケートを送信',
    value: ACTION_TYPES.flagSurvey,
  },
  notice: {
    label: '連絡事項を送信',
    value: ACTION_TYPES.notice,
  },
});

const NOT_SELECTED = 0;

defineProps({
  formValues: {
    type: Object,
    default: () => ({}),
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['select-action-type', 'submit']);

const store = useStore();
const router = useRouter();

const {
  value: actionName,
  handleChange: handleChangeActionName,
  errorMessage: actionNameError,
} = useField('actionName');
const { value: selectionId, errorMessage: selectionError } =
  useField('selection.id');
const { value: selectionTitle } = useField('selection.title');
const { value: selectionDescription } = useField('selection.description');
const { value: actionType, errorMessage: actionTypeError } =
  useField('actionType');
const { value: applicantStatus } = useField('applicantStatus');
const { value: triggerDelayDay } = useField('triggerDelayDay');
const { value: triggerHour } = useField('triggerHour');
const { value: triggerMin } = useField('triggerMin');

const isViewSelectionSearchModal = ref(false);
const isViewConfirmActionType = ref(false);
const pendingActionType = ref(0);

const progressStatusOptions = _actionType => {
  const mapStatusLabels = (statusLabels, isFinished) =>
    Object.keys(statusLabels).map(key => ({
      key,
      label: isFinished
        ? FINISHED_PROGRESS_STATUSES_LABEL[statusLabels[key]]
        : PROGRESS_STATUSES_LABEL[statusLabels[key]],
    }));
  const tmpOptions = [
    ...mapStatusLabels(PROGRESS_STATUSES, false),
    ...mapStatusLabels(FINISHED_PROGRESS_STATUSES, true),
  ];
  const baseOptions = tmpOptions.filter(({ key }) => {
    return key !== 'finished' && key !== 'notSetSelection';
  });
  if (_actionType === ACTION_TYPES.assignProgress) {
    applicantStatus.value = 'passed';
    return baseOptions.filter(({ key }) => {
      return key === 'passed';
    });
  } else if (_actionType === ACTION_TYPES.announce) {
    return baseOptions.filter(({ key }) => {
      return key === 'notAnnounced' || key === 'announced';
    });
  } else if (_actionType === ACTION_TYPES.results) {
    applicantStatus.value = 'passed offered dropped';
    return [
      {
        key: 'passed offered dropped',
        label: `${
          FINISHED_PROGRESS_STATUSES_LABEL[FINISHED_PROGRESS_STATUSES.passed]
        }・${
          FINISHED_PROGRESS_STATUSES_LABEL[FINISHED_PROGRESS_STATUSES.offered]
        }・${
          FINISHED_PROGRESS_STATUSES_LABEL[FINISHED_PROGRESS_STATUSES.dropped]
        }`,
      },
    ];
  } else {
    return baseOptions;
  }
};

const handleSelectSelection = ({ id, title, description }) => {
  selectionId.value = id;
  selectionTitle.value = title;
  selectionDescription.value = description;
  isViewSelectionSearchModal.value = false;
};

const handleChangeActionType = event => {
  const numberTargetValue = Number(event.target.value);
  if (actionType.value !== NOT_SELECTED) {
    isViewConfirmActionType.value = true;
    pendingActionType.value = numberTargetValue;
    event.target.value = actionType.value;
    return;
  }
  if (numberTargetValue === ACTION_TYPES.assignProgress) {
    triggerDelayDay.value = 0;
  }
  actionType.value = numberTargetValue;
  emit('select-action-type', numberTargetValue);
};

const changeActionTypeConfirm = () => {
  actionType.value = pendingActionType.value;
  emit('select-action-type', pendingActionType.value);
  isViewConfirmActionType.value = false;
};

onMounted(() => {
  store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
  store.dispatch('page/SET_LOADED');
});
</script>

<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <form @submit.prevent="emit('submit')">
        <auto-actions-template
          :header-title="'自動アクション登録'"
          :back-page-title="'自動アクション一覧に戻る'"
          :on-back-page="() => router.push({ name: 'AutoActions' })"
        >
          <template #titleDescription>
            作成したい自動アクションの内容を入力し、保存を押してください。
          </template>
          <template #body>
            <div class="section-body-inner">
              <section class="action-wrapper">
                <div class="action-outer">
                  <div class="action-inner">
                    <div class="action-title">
                      <h3 class="title">自動アクション名</h3>
                      <p class="desc">
                        作成する自動アクションの名前を入力してください。
                      </p>
                    </div>
                    <div class="action-contents">
                      <div class="action-create-parts">
                        <base-input
                          name="actionName"
                          :model-value="actionName"
                          :is-error="!!actionNameError"
                          @blur="handleChangeActionName"
                        />
                        <div v-show="!!actionNameError" class="error-message">
                          {{ actionNameError }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="action-wrapper">
                <highlight class="section-title" :h-level="2">
                  自動アクションさせる選考と実行するアクション
                </highlight>
                <div class="action-outer">
                  <div class="action-inner">
                    <div class="action-title">
                      <h3 class="title">自動アクションさせる選考</h3>
                      <p class="desc">
                        自動アクションをさせる選考を選択してください。
                      </p>
                    </div>
                    <div class="action-contents">
                      <div class="seletct-selection">
                        <div class="action-create-parts">
                          <div
                            class="button-wrapper"
                            :class="{ error: !!selectionError }"
                          >
                            <div class="button-inner">
                              <button-base
                                type="button"
                                :button-text="'選考を選択'"
                                :icon-file-name="'plus_circle'"
                                @click="isViewSelectionSearchModal = true"
                              />
                            </div>
                          </div>
                          <div v-show="!!selectionError" class="error-message">
                            {{ selectionError }}
                          </div>
                        </div>
                        <div v-show="selectionId" class="gray-box">
                          <div class="gray-box-inner">
                            <ul class="items">
                              <li class="item">
                                <div class="title">選考名</div>
                                <div class="contents">
                                  {{ selectionTitle }}
                                </div>
                              </li>
                              <li class="item">
                                <div class="title">選考の管理者用メモ</div>
                                <div class="contents">
                                  {{ selectionDescription }}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="action-inner">
                    <div class="action-title">
                      <h3 class="title">実行する自動アクション</h3>
                      <p class="desc">
                        選択した選考の応募者に実行する自動アクションを選択してください。
                      </p>
                    </div>
                    <div class="action-contents">
                      <div>
                        <div class="select-action">
                          <div>選考の応募者に</div>
                          <div class="select-action-inner">
                            <base-select
                              :model-value="actionType"
                              :is-error="!!actionTypeError"
                              @change="handleChangeActionType($event)"
                            >
                              <option :value="0" selected hidden>
                                自動アクションを選択してください
                              </option>
                              <option
                                v-for="type in ACTION_TYPE_SELECT_OPTIONS"
                                :key="type.value"
                                :value="type.value"
                              >
                                {{ type.label }}
                              </option>
                            </base-select>
                            <div
                              v-show="!!actionTypeError"
                              class="error-message"
                            >
                              {{ actionTypeError }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <component
                    :is="FORM_COMPONENTS[actionType]"
                    :exclude-selection-ids="selectionId ? [selectionId] : []"
                  />
                </div>
              </section>
              <section
                :class="{ disabled: !actionType }"
                class="action-wrapper"
              >
                <highlight :h-level="2">自動アクションを実行する条件</highlight>
                <div class="action-outer">
                  <div class="action-inner">
                    <div class="action-title">
                      <h3 class="title">応募者のステータス</h3>
                      <p class="desc">
                        選考内の応募者がこのステータスになった時に自動アクションが実行されます
                      </p>
                    </div>
                    <div class="action-contents">
                      <div class="action-create-parts">
                        <base-select v-model="applicantStatus">
                          <option
                            v-for="progress in progressStatusOptions(
                              actionType,
                            )"
                            :key="progress.key"
                            :value="progress.key"
                          >
                            {{ progress.label }}
                          </option>
                        </base-select>
                        <small
                          v-if="actionType === ACTION_TYPES.results"
                          class="caption"
                        >
                          ※自動アクション「選考結果を通知」では選択できるステータスが「通過・内定・不採用」のみになります。
                        </small>
                        <small v-else class="caption">
                          ※自動アクションによっては選択できるステータスに制限がかかることがあります。
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="action-inner">
                    <div class="action-title">
                      <h3 class="title">応募者のフラグ</h3>
                      <p class="desc">
                        設定するとステータス・フラグのどちらにも合致する応募者にのみ実行されます。
                      </p>
                    </div>
                    <div class="action-contents">
                      <div>
                        <flag-group-select />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                :class="{ disabled: !actionType }"
                class="action-wrapper"
              >
                <highlight :h-level="2">
                  自動アクションを実行するタイミング
                </highlight>
                <div class="action-outer">
                  <div class="action-inner">
                    <div class="action-title">
                      <h3 class="title">自動アクションのタイミング</h3>
                      <p class="desc">
                        自動アクションを実行するタイミングを選択できます。
                        <br />
                        採用/不採用通知を面接の3日後に送りたい場合などにご利用ください。
                      </p>
                    </div>
                    <div class="action-contents">
                      <div class="action-time-trigger">
                        <div class="action-time-trigger-inner">
                          <base-select v-model.number="triggerDelayDay">
                            <option :value="0">すぐに</option>
                            <template
                              v-if="actionType !== ACTION_TYPES.assignProgress"
                            >
                              <option v-for="n in 7" :key="n" :value="n">
                                {{ `${n}日後` }}
                              </option>
                            </template>
                          </base-select>
                          {{ triggerDelayDay === 0 ? '実行' : 'の' }}
                        </div>
                        <template v-if="triggerDelayDay !== 0">
                          <div class="action-time-trigger-inner">
                            <base-select v-model="triggerHour">
                              <option
                                v-for="(n, index) of 24"
                                :key="n"
                                :value="$utils.zeroPadding(index)"
                              >
                                {{ $utils.zeroPadding(index) }}
                              </option>
                            </base-select>
                            時
                          </div>
                          <div class="action-time-trigger-inner">
                            <base-select v-model="triggerMin">
                              <option
                                v-for="(n, index) of 6"
                                :key="n"
                                :value="`${index}0`"
                              >
                                {{ `${index}0` }}
                              </option>
                            </base-select>
                            分に実行
                          </div>
                        </template>
                      </div>
                    </div>
                    <small
                      v-if="actionType === ACTION_TYPES.assignProgress"
                      class="caption"
                    >
                      ※選考の割り当てのタイミングは「すぐに実行」のみです
                    </small>
                  </div>
                </div>
              </section>
            </div>
          </template>
          <template #footer>
            <div class="page-footer">
              <button-base
                type="button"
                class="btn-cancel"
                button-type="secondary"
                button-text="キャンセル"
                @click="() => router.push({ name: 'AutoActions' })"
              />
              <button-base
                type="submit"
                class="btn-confirm"
                :button-text="'確認'"
                :is-loading="isSubmitting"
              />
            </div>
          </template>
        </auto-actions-template>
      </form>
    </div>
    <selections-search-modal
      :is-view="isViewSelectionSearchModal"
      :exclude-selection-ids="
        formValues.assignProgress?.id ? [formValues.assignProgress.id] : []
      "
      @close-modal="isViewSelectionSearchModal = false"
      @select-selection="handleSelectSelection({ ...$event })"
    />
    <modal-window
      :is-visible="isViewConfirmActionType"
      :modal-type="'danger'"
      :max-width="'700px'"
      title="自動アクションを変更します"
      @clickOuter="isViewConfirmActionType = false"
      @close="isViewConfirmActionType = false"
    >
      <template #content>
        <div class="modal-contents">
          <div class="modal-contents-text">
            <div>
              自動アクションの変更を行うと「実行する自動アクション」の編集内容が失われます。
              自動アクションを変更してもよろしいでしょうか？
            </div>
          </div>
          <div class="modal-contents-bottom">
            <button-base
              :button-text="'キャンセル'"
              button-type="secondary"
              @click="isViewConfirmActionType = false"
            />
            <button-base
              :button-text="'自動アクションを変更'"
              :button-type="'danger'"
              @click="changeActionTypeConfirm"
            />
          </div>
        </div>
      </template>
    </modal-window>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
.page-wrapper {
  margin: 20px 0;
  padding: 0 20px;
  .page-inner {
    padding: 0 30px 40px 30px;
    background-color: $white;
  }
}
.page-footer {
  display: flex;
  gap: 12px;
  margin: 20px 0 0;
  & > button {
    width: 50%;
    height: 56px;
  }
}
.section-body-inner {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.seletct-selection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.select-action {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  select {
    font-size: $font_14;
  }
}
.select-action-inner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.action-time-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: ($media_query_sp)) {
    flex-wrap: wrap;
  }
}
.action-time-trigger-inner {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  @media (max-width: ($media_query_sp)) {
    width: 100%;
  }
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.modal-contents {
  padding: 24px;
  &-text {
    white-space: pre-line;
  }
  &-bottom {
    display: flex;
    gap: 8px;
    margin-top: 40px;
    button {
      height: 52px;
      width: 100%;
    }
  }
}
</style>
