<template>
  <div class="radioGroup">
    <label
      v-for="item in options"
      :key="`${item.id}`"
      class="radioButton"
      @change="onChange(item.id)"
    >
      <input class="input" type="radio" :checked="checkedIdx === item.id" />
      <div class="radio" :class="{ checkedRadio: checkedIdx === item.id }" />
      <div class="label">{{ item.description }}</div>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RadioGroup',
  props: {
    onChange: {
      type: Function,
      required: true,
    },
    checkedIdx: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {},
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.radioButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 0px;
  margin-right: 8px;
  .input {
    display: none;
  }
  .radio {
    flex-shrink: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid $border_color;
    border-radius: 50%;
    margin-right: 8px;
  }
  .label {
    user-select: none;
  }
  .checkedRadio {
    border-color: $mochica_color;
  }
  .checkedRadio:after {
    content: ' ';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: $mochica_color;
    border-radius: 50%;
    margin-top: 4px;
    margin-left: 4px;
  }
}
</style>
