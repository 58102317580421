<script setup>
import { ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useField } from 'vee-validate';

import SEARCH_CONSTS from '@/components/features/searchApplicants/defines/search';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import DropDown from '@/components/ui/menus/components/DropDown';
import useSearchFlagGroups from '@/composables/useSearchFlagGroups';
import useSearchFlagGroupOptions from '@/composables/useSearchFlagGroupOptions';

const store = useStore();

const {
  searchFlagGroups,
  visibleAddFlags,
  visibleFlagGroupIds,
  visibleFlagIds,
  getFlagGroups,
  getFlags,
  getFlagNameById,
  updateFlagGroupId,
  updateFlagId,
  addFlagGroupRow,
  removeFlagGroupRow,
  openDropDownFlagGroupIds,
  openDropDownFlagId,
  closeAllFlagGroupsDropDown,
} = useSearchFlagGroups(store, [], () => {});

const {
  searchFlagGroupOptions,
  visibleFlagGroupOptions,
  addFlagGroupOption,
  removeFlagGroupOption,
  updateFlagGroupOption,
  openDropDownFlagGroupOption,
  closeAllFlagGroupOptionsDropDown,
} = useSearchFlagGroupOptions();

const { value: flagGroups } = useField('flagGroups');

const flagErrorMessage = ref('');

const updateFlag = () => {
  const mappedFlagGroups = searchFlagGroups.value.map(
    (selectedFlagGroups, i) => {
      const flagGroup = getFlagGroups.value.find(
        flagGroup => flagGroup.id === selectedFlagGroups.id,
      );
      const tags = flagGroup.tags.filter(tag =>
        selectedFlagGroups.flags.includes(tag.id),
      );
      return {
        id: flagGroup.id,
        name: flagGroup.name,
        flags: tags,
        isContain: searchFlagGroupOptions.value[i].eq,
      };
    },
  );
  flagGroups.value = mappedFlagGroups;
};

const onSelectedFlag = (event, index) => {
  updateFlagId(event, index);
  updateFlag();
};

const onSelectedOption = (payload, i) => {
  updateFlagGroupOption(payload.item.id, i);
  updateFlag();
};
const onSelectedFlagGroupId = (payload, i) => {
  updateFlagGroupId(payload, i, nextTick);
  updateFlag();
};
const addLocalFlagGroupRow = () => {
  if (getFlagGroups.value.length === 0) {
    flagErrorMessage.value = '※フラググループは登録されていません';
    return;
  }
  addFlagGroupRow(addFlagGroupOption);
};
const removeLocalFlagGroupRow = i => {
  removeFlagGroupRow(i, removeFlagGroupOption);
  updateFlag();
};

const selectableSearchFlagGroupOptionsDefault = [
  { id: 1, name: '含む' },
  { id: 0, name: '含まない' },
];
</script>

<template>
  <div>
    <div class="ar-flagGroup">
      <table v-if="searchFlagGroups.length !== 0" class="common_table">
        <thead class="common_thead">
          <tr class="common_thead_row align-left">
            <th class="common_thead_item"><span>フラググループ</span></th>
            <th class="common_thead_item"><span>条件</span></th>
            <th class="common_thead_item"><span>フラグ</span></th>
            <th class="common_thead_item"><span></span></th>
          </tr>
        </thead>
        <tbody
          v-for="(flagGroup, index) in searchFlagGroups"
          :key="`flagGroup_${flagGroup.id}`"
          class="common_tbody"
        >
          <tr>
            <td
              class="common_tbody_item"
              valign="top"
              aria-label="フラググループ名"
            >
              <dl class="item ar-inputElement">
                <dd class="group">
                  <drop-down
                    class="toSelect"
                    :placeholder="'フラググループ名'"
                    :selected-id="flagGroup.id || null"
                    :list="getFlagGroups"
                    :is-view="
                      visibleFlagGroupIds[index] &&
                      getFlagGroups.findIndex(tg => !tg.disabled) !== -1
                    "
                    @onOpen="openDropDownFlagGroupIds(index)"
                    @onClose="closeAllFlagGroupsDropDown"
                    @onSelected="onSelectedFlagGroupId($event, index)"
                  />
                </dd>
              </dl>
            </td>
            <td class="common_tbody_item" valign="top" aria-label="条件">
              <dl class="item ar-inputElement">
                <drop-down
                  :placeholder="'条件'"
                  :selected-id="searchFlagGroupOptions[index].selectOption"
                  :list="
                    selectableSearchFlagGroupOptionsDefault.map(option =>
                      Object.assign({}, option, {
                        selected:
                          searchFlagGroupOptions &&
                          searchFlagGroupOptions[index] &&
                          option.id ===
                            searchFlagGroupOptions[index].selectOption,
                      }),
                    )
                  "
                  :is-view="visibleFlagGroupOptions[index]"
                  @onOpen="openDropDownFlagGroupOption(index)"
                  @onClose="closeAllFlagGroupOptionsDropDown"
                  @onSelected="onSelectedOption($event, index, flagGroup)"
                />
              </dl>
            </td>
            <td class="common_tbody_item" aria-label="フラグ">
              <ul
                v-if="
                  !searchFlagGroupOptions[index] ||
                  searchFlagGroupOptions[index].selectOption === 0 ||
                  searchFlagGroupOptions[index].selectOption === 1
                "
                class="ar-flagLabels"
              >
                <li
                  v-for="flagId in flagGroup.flags"
                  :key="`flag_${flagId}`"
                  class="item"
                  :class="{
                    'is-deleted': getFlagNameById(flagId, flagGroup.id)
                      ? false
                      : true,
                  }"
                >
                  {{ getFlagNameById(flagId, flagGroup.id) }}
                </li>
              </ul>
              <div
                v-if="flagGroup.id !== null"
                v-show="
                  !searchFlagGroupOptions[index] ||
                  searchFlagGroupOptions[index].selectOption === 0 ||
                  searchFlagGroupOptions[index].selectOption === 1
                "
              >
                <div class="button_add_flag" @click="openDropDownFlagId(index)">
                  <p>フラグを追加</p>
                </div>
                <float-context-menu
                  class="float_context_menu_comp"
                  :list="getFlags(flagGroup.id, index)"
                  :is-view="visibleFlagIds[index]"
                  :is-multiple-select="true"
                  @onClose="closeAllFlagGroupsDropDown"
                  @onSelected="onSelectedFlag($event, index, flagGroup)"
                />
              </div>
            </td>
            <td align="center" valign="top" class="common_tbody_btn">
              <button-base
                button-text="削除"
                button-type="secondary"
                icon-file-name="delete_white"
                @click="removeLocalFlagGroupRow(index, flagGroup)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btn-wrapper">
      <div class="btn-inner" :class="{ error: !!flagErrorMessage }">
        <div
          v-if="visibleAddFlags"
          class="btn btn-availability is-active"
          @click="addLocalFlagGroupRow"
        >
          <button type="button">
            <img
              slot="icon"
              class="icon"
              src="@/assets/img/add_white.svg"
              alt
            />
            フラググループを追加
          </button>
        </div>
        <div v-else>
          <span>
            フラググループは{{
              SEARCH_CONSTS.MAX_FLAG_GROUPS_COUNT
            }}個まで検索可能です。
          </span>
        </div>
      </div>
      <div :class="{ error: !!flagErrorMessage }" class="error-message">
        {{ flagErrorMessage }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
@include common_table_style(
  $col_1: 35px,
  $col_1_ta: left,
  $col_2: 15px,
  $col_2_ta: left,
  $col_3: 42px,
  $col_3_ta: left,
  $col_4: 8px,
  $col_4_ta: left
);

.ar-flagGroup {
  &:has(.common_table) {
    margin: 0 0 16px;
    border-bottom: 1px solid #ededed;
  }
}
.btn-inner {
  &.error {
    width: fit-content;
    margin: 0 0 12px;
    padding: 2px;
    border: 2px solid $error-border;
    border-radius: 6px;
  }
}
.error-message {
  color: $error-text;
}

.common_table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0px;
  font-size: $font_14;
  .common_thead {
    .common_thead_row {
      .common_thead_item {
        padding: 12px 12px 12px 0px;
        font-size: $font_14;
      }
      :first-of-type {
        padding-left: 10px;
        border-radius: 4px 0 0 4px;
      }
      :last-of-type {
        border-radius: 0 4px 4px 0;
        padding-right: 0px;
        padding-left: 0px;
        width: 12px;
        min-width: 12px;
      }
    }
  }

  .common_tbody {
    td {
      padding: 16px 12px 0 0;
      font-size: $font_14;
      &.common_tbody_item {
        padding: 16px 12px 0 0;
      }

      &.common_tbody_btn {
        padding-right: 0;
        text-align: right;
      }
    }

    @media (max-width: ($media_query_sp)) {
      td {
        padding: 12px 0;

        &.common_tbody_item {
          padding: 12px 0;
        }

        &.common_tbody_btn {
          padding-right: 0;
          text-align: left;
        }
      }
    }
  }
  margin-bottom: 24px;
}

.btn-availability {
  > button {
    cursor: pointer;
  }
}

.ar-flagLabels {
  display: flex;
  flex-wrap: wrap;

  > .item {
    margin: 0 10px 0 0;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #ebebeb;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.is-deleted {
      display: none;
    }
  }
}

.button_add_flag {
  width: 20px;
  height: 20px;
  background-image: url('@/assets/img/add_circle_gray.svg');
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  p {
    height: 20px;
    padding: 2px 0 0 25px;
  }
}

.float_context_menu_comp {
  width: 200px;
  & :deep(.context-menu) {
    width: 100%;
    ul {
      li {
        .li-inner {
          p {
            width: 330px;
            white-space: normal;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  @include common_table_style(
    $col_1: 100%,
    $col_1_ta: left,
    $col_2: 100%,
    $col_2_ta: left,
    $col_3: 100%,
    $col_3_ta: left,
    $col_4: 100%,
    $col_4_ta: left
  );
  .common_tbody_item {
    width: 100%;
    padding: 1rem 1rem 1rem 0 !important;
  }
  .common_tbody_btn {
    padding-bottom: 12px !important;
  }
  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
    width: 100%;
  }
  .common_thead_row {
    display: none;
  }
  td::before {
    content: attr(aria-label);
    display: inline-block;
    padding: 10px 0;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
  }
}
</style>
