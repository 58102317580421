<template>
  <li
    class="plan_item"
    :class="{
      'is-light': plan.id === 1,
      'is-free': plan.id === 3,
      'is-standard': plan.id === 2,
    }"
    :style="{ borderColor: plan.color }"
  >
    <div class="title_block" :style="{ backgroundColor: plan.color }">
      <div class="title">{{ plan.name_ja }}</div>
    </div>
    <div class="content_wrapper">
      <div class="content_inner">
        <p class="count_title">最大応募者数</p>
        <p class="count_text" :style="{ color: plan.color }">
          <!-- スタンダードプランの場合 -->
          <span v-if="plan.id === 2" class="count_number">無制限</span>
          <!-- ライトプランの場合 -->
          <template v-else-if="plan.id === 1">
            <span class="count_number">300</span>
            <span class="count_wavy_line">〜</span>
            <span class="count_number">900</span>
            名まで
            <span class="is-annotation">※</span>
          </template>
          <template v-else>
            <span class="count_number">{{ plan.applicant_limit }}</span>
            名まで
          </template>
        </p>
        <div class="count_cation">
          <p v-if="plan.id === 1">※最大人数はオプションによります</p>
        </div>
        <div class="file_storage_block">
          <p class="content_subtitle">ファイルストレージ</p>
          <p
            class="content_value"
            :class="{
              'is-enabled': plan.file_storage > 0,
              'is-disabled': plan.file_storage <= 0,
            }"
          >
            {{ plan.file_storage > 0 ? '利用可能' : '利用不可' }}
          </p>
          <p class="content_caption">
            <span v-if="plan.file_storage > 0">
              （{{ plan.file_storage }}GBまで利用可能）
            </span>
          </p>
        </div>
        <div class="applicant_block">
          <p class="content_subtitle">応募者情報</p>
          <p
            class="content_value"
            :class="{
              'is-enabled': plan.delete_applicant,
              'is-disabled': !plan.delete_applicant,
            }"
          >
            {{ plan.delete_applicant ? '削除可能' : '削除不可' }}
          </p>
        </div>
        <!-- ライトプランの場合 -->
        <div v-if="plan.id === 1" class="contact_block_light">
          <p>最大応募者人数</p>
          <ul>
            <li
              v-for="lightPlanItem in lightPlanItems"
              :key="`light_${lightPlanItem.id}`"
            >
              <p :style="{ color: plan.color }">
                <span>{{ lightPlanItem.applicant_limit }}</span>
                名まで
              </p>
              <button
                type="button"
                class="btn"
                :style="{
                  backgroundColor:
                    currentPlanId === lightPlanItem.id ? '#ccc' : plan.color,
                }"
                :class="{ current: currentPlanId === lightPlanItem.id }"
                :disabled="currentPlanId === lightPlanItem.id"
                @click="
                  currentPlanId !== lightPlanItem.id
                    ? $emit('openModalChangePlan', lightPlanItem)
                    : () => {}
                "
              >
                {{
                  currentPlanId === lightPlanItem.id ? '現在利用中' : '申し込み'
                }}
              </button>
            </li>
          </ul>
          <p class="attention">※お申込み後、サポート担当から連絡差し上げます</p>
        </div>
        <template v-else>
          <div class="contact_block">
            <button
              type="button"
              class="btn"
              :style="{
                backgroundColor:
                  plan.id === currentPlanId ? '#ccc' : plan.color,
              }"
              :class="{ current: plan.id === currentPlanId }"
              :disabled="plan.id === currentPlanId"
              @click="
                plan.id !== currentPlanId
                  ? $emit('openModalChangePlan', plan)
                  : () => {}
              "
            >
              {{ plan.id === currentPlanId ? '現在利用中' : '申し込み' }}
            </button>
            <p class="attention">
              {{
                currentPlanId === 2
                  ? '※アップグレードできるプランはありません。'
                  : '※お申込み後、サポート担当から連絡差し上げます'
              }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </li>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PlanItem',
  props: {
    plan: {
      type: Object,
      required: true,
    },
    lightPlanItems: {
      type: Array,
      required: true,
    },
    currentPlanId: {
      type: Number,
      default: null,
    },
  },
  emits: ['openModalChangePlan'],
  setup(props) {
    const isEnabledLightPlan = id => {
      return props.lightPlanItems.map(plan => plan.id).includes(id);
    };
    return { isEnabledLightPlan };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.plan_item {
  line-height: 1.5;
  border: 4px solid #ccc;
  border-radius: 3px;
  background-color: #f4f4f4;
}

.title_block {
  width: 100%;
  padding: 20px 12px;
  line-height: 1;
  @media (max-width: ($media_query_sp)) {
    padding: 10px;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;
  }
}

.content_wrapper {
  height: 100%;
  font-size: 1.4rem;
  text-align: center;
  white-space: pre-line;
}

.count_title {
  padding: 30px 12px 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  background-color: #fff;
}

.count_text {
  padding: 0 12px 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  background-color: #fff;
  .count_number {
    display: inline-block;
    height: 42px;
    font-size: 42px;
    line-height: 42px;
    vertical-align: 0;
  }
  .count_wavy_line {
    display: inline-block;
    padding-bottom: 5px;
    height: 42px;
    font-size: 20px;
    line-height: 42px;
    vertical-align: 8px;
    font-weight: normal;
  }
  .is-annotation {
    font-size: 12px;
    vertical-align: 8px;
    margin-left: -4px;
  }
  .count_light {
    padding-bottom: 15px;
  }
}

.count_cation {
  height: 42px;
  padding: 0 12px;
  font-size: 12px;
  color: #777;
  background-color: #fff;
}

.content_subtitle {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
}

.content_value {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
}

.is-enabled {
  background-image: url('../../../../../assets/img/icon_enabled.svg');
  background-size: auto 16px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.is-disabled {
  background-image: url('../../../../../assets/img/icon_disabled.svg');
  background-size: auto 16px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.content_caption {
  font-size: 12px;
  height: 18px;
  color: #777;
}

.file_storage_block {
  padding: 15px 12px;
  background: rgb(217, 217, 217, 0.3);
  border-top: 1px solid #e0e0e0;
}

.applicant_block {
  padding: 15px 12px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
}

.contact_block_light {
  width: 100%;
  padding: 24px 12px;
  background-color: #fff;
  text-align: left;
  > p {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #777;
    &.attention {
      margin-bottom: 0;
      font-weight: normal;
      text-align: center;
    }
  }
  > ul {
    > li {
      display: flex;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &:last-child {
        border: none;
      }
      > p {
        margin-right: auto;
        line-height: 32px;
        font-weight: bold;
        > span {
          font-size: 28px;
          line-height: 28px;
        }
      }
      > .btn {
        width: 112px;
        padding: 0;
        line-height: 1;
        height: 32px;
        &:hover {
          opacity: 0.6;
          transition: all ease 0.3s;
        }
        &.current:hover {
          opacity: 1;
          transition: none;
          cursor: auto;
        }
      }
    }
  }
}

.contact_block {
  padding-top: 24px;
  background-color: #fff;
  .btn {
    width: 240px;
    max-width: 240px;
    line-height: 2;
    margin-bottom: 16px;
    @media (max-width: (1150px)) {
      width: 90%;
    }
    &:hover {
      opacity: 0.6;
      transition: all ease 0.3s;
    }
    &.current:hover {
      opacity: 1;
      transition: none;
      cursor: auto;
    }
  }
  .attention {
    padding-bottom: 25px;
  }
}

.attention {
  padding: 0 10px;
  font-size: 12px;
  color: #777;
}

.fix_plan {
  width: 100%;
  border-radius: 3px;
  text-align: center;
  .title {
    width: 100%;
    padding: 20px 10px;
    background-color: #43a048;
    color: #fff;
    font-size: 1.6rem;
    border-radius: 5px 5px 0 0;
  }
  .message {
    padding: 20px 30px;
    font-size: 1.6rem;
    font-weight: bold;
  }
}

.is-light {
  .contact_block_light {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
