<template>
  <section class="sec-register-csv">
    <p
      v-if="csvStatus === 'importCsv' && isSuccessUpload === true"
      class="normal-txt"
    >
      {{
        csvLabel
      }}からダウンロードしたCSVファイルに入っている応募者を「応募者」として登録します。
      <br />
      登録に使用したいCSVファイルを以下のエリアで選択してください。
    </p>
    <p
      v-if="
        csvStatus === 'confirmCsv' &&
        duplicatedRows.length === 0 &&
        errorRows.length === 0
      "
      class="normal-txt"
    >
      {{
        csvFileName
      }}に記述されている応募者を「応募者」として、MOCHICAへ登録します。
      <br />
      内容をご確認の上、ページ下部の登録を行うボタンをクリックしてください。
    </p>
    <p v-if="csvStatus === 'completeCsv'" class="normal-txt">
      {{
        csvFileName
      }}に記述されている以下の応募者を「応募者」として、MOCHICAへ登録が完了しました。
      <br />
      応募者一覧の「応募者」タブよりご確認いただけます。
    </p>
    <template v-if="csvType === 'mochica'">
      <p class="normal-txt">
        登録種別が、「アルバイト、中途、派遣・契約、その他」の場合は、卒年の欄に以下の数字を入力してください。
      </p>
      <table class="normal-txt">
        <tr>
          <td>アルバイト</td>
          <td>9901</td>
        </tr>
        <tr>
          <td>中途</td>
          <td>9902</td>
        </tr>
        <tr>
          <td>派遣・契約</td>
          <td>9903</td>
        </tr>
        <tr>
          <td>その他</td>
          <td>9999</td>
        </tr>
      </table>
      <p class="-mb20">
        ※MOCHICAフォーマットに限ります。マイナビ、リクナビのフォーマットは対応しておりません。
      </p>
    </template>
    <div v-if="csvStatus === 'importCsv'" class="file-select">
      <div class="area-file-drop">
        <div
          class="btn btn-availability -mb40"
          @click="isVisibleConfirmFormatModal = true"
        >
          アップロードするCSVフォーマットを確認する
          <img
            class="icon-rightArrow"
            src="@/assets/img/arrow_right_white.svg"
            alt=""
          />
        </div>
        <form enctype="multipart/form-data" novalidate>
          <div
            class="dropbox"
            @dragleave.prevent
            @dragover.prevent
            @drop.prevent="onDropImage($event)"
          >
            <input
              type="file"
              name="csv"
              accept="text/csv"
              class="input-file"
              @change="onSelectImage($event)"
            />
            <div class="dropbox_inner">
              <span class="dropbox_inner_txt">
                {{ csvLabel }}のCSVファイルをドラッグ＆ドロップしてください
              </span>
              <span class="dropbox_inner_or">または...</span>
              <div class="dropbox_inner_btn">
                パソコンからファイルを選択する
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="filename_wrapper">
        <span class="-fBold">アップロードするファイル名</span>
        <p class="filename_item">{{ csvFileName }}</p>
      </div>
      <div class="btn-col2">
        <div class="btn btn-availability" @click="confirmUploadCsv">
          確認へ進む
          <img
            class="icon-rightArrow"
            src="@/assets/img/arrow_right_white.svg"
            alt=""
          />
        </div>
      </div>
      <modal-about-csv-format
        :is-visible="isVisibleConfirmFormatModal"
        :csv-type-name="csvLabel"
        :csv-type="csvType"
        @onclickOuter="isVisibleConfirmFormatModal = false"
      />
    </div>
    <div v-if="csvStatus === 'confirmCsv'" class="file-check">
      <div class="filename_wrapper">
        <span class="-fBold">アップロードするファイル名</span>
        <p class="filename_item">{{ csvFileName }}</p>
      </div>
      <div v-if="errorRows && errorRows.length > 0">
        <span
          v-if="isSuccessUpload === false"
          class="area-file-registration_btn_error_message"
        >
          {{ resMessage }}
        </span>
        <div class="area-file-error">
          <p class="area-file-error_ttl">CSV登録エラー</p>
          <table class="table-normal">
            <thead class="area-file-error_list_header">
              <tr class="area-file-error_list_header_item">
                <th>エラー内容</th>
                <th>行番号</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="area-file-error_list_body">
              <tr
                v-for="(errorRow, index) in errorRows"
                :key="index"
                class="area-file-error_list_body_item"
              >
                <!-- <td>形式エラー</td> -->
                <td>{{ errorRow.error_type }}</td>
                <td>{{ errorRow.row_at + 1 }}行目</td>
                <td>{{ errorRow.reason.columns }}の形式が違います。</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="btn-col2">
          <div class="btn btn-unavailable" @click="resetModes">キャンセル</div>
        </div>
      </div>

      <div v-else-if="duplicatedRows && duplicatedRows.length > 0">
        <span
          v-if="isSuccessUpload === false"
          class="area-file-registration_btn_error_message"
        >
          {{ resMessage }}
        </span>
        <div class="area-file-error">
          <p class="area-file-error_ttl">CSV登録エラー</p>
          <table class="table-normal">
            <thead class="area-file-error_list_header">
              <tr class="area-file-error_list_header_item">
                <th>行番号</th>
                <th>email</th>
                <th>電話番号</th>
              </tr>
            </thead>
            <tbody class="area-file-error_list_body">
              <tr
                v-for="(duplicatedRow, index) in duplicatedRows"
                :key="index"
                class="area-file-error_list_body_item"
              >
                <td>{{ duplicatedRow.row_at + 1 }}行目</td>
                <td>
                  <span v-if="duplicatedRow.email !== ''">
                    {{ duplicatedRow.email }}
                  </span>
                </td>
                <td>
                  <span v-if="duplicatedRow.tel !== ''">
                    {{ duplicatedRow.tel }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="duplicatedRows.filter(obj => obj.email !== '').length">
          <div class="btn-col2 -mb10">
            <div
              class="btn btn-availability"
              @click="openModalUploadCsv(false)"
            >
              重複するメールアドレスの応募者をのぞいて登録
              <img
                class="icon-rightArrow"
                src="@/assets/img/arrow_right_white.svg"
                alt=""
              />
            </div>
            <div class="btn btn-availability" @click="openModalUploadCsv(true)">
              重複するメールアドレスの応募者を上書きして登録
              <img
                class="icon-rightArrow"
                src="@/assets/img/arrow_right_white.svg"
                alt=""
              />
            </div>
          </div>
          <div class="btn btn-unavailable btn-sizefull" @click="resetModes">
            キャンセル
          </div>
        </div>

        <div v-else>
          <div class="btn-col2">
            <div class="btn btn-unavailable" @click="resetModes">
              キャンセル
            </div>
            <div class="btn btn-availability" @click="openModalUploadCsv(true)">
              登録
              <img
                class="icon-rightArrow"
                src="@/assets/img/arrow_right_white.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <span
          :class="{
            active: isSuccessUpload,
            'area-file-registration_btn_success_message':
              isSuccessUpload === true,
            'area-file-registration_btn_error_message':
              isSuccessUpload === false,
          }"
        >
          {{ resMessage }}
        </span>
        <div class="btn-col2">
          <div class="btn btn-unavailable" @click="resetModes">キャンセル</div>
          <div
            v-if="isSuccessUpload === true"
            class="btn btn-availability"
            @click="openModalUploadCsv(true)"
          >
            登録
            <img
              class="icon-rightArrow"
              src="@/assets/img/arrow_right_white.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="csvStatus === 'completeCsv'" class="success-info">
      <div>
        <div class="filename_wrapper">
          選択ファイル名
          <p class="filename_item">{{ csvFileName }}</p>
          <p class="success-info_txt">
            上記のCSV内の応募者の登録が完了しました。
          </p>
        </div>
        <div v-if="registeredGraduates.length !== 0" class="new_tag_group">
          <span v-for="(graduate, index) in registeredGraduates" :key="index">
            『{{ graduate }}』
          </span>
          <span v-if="registeredGraduates.length !== 0">
            の年度の学生が生成されました
          </span>
        </div>
        <div v-if="registeredTagGroups.length !== 0" class="new_tag_group">
          <span v-for="(tagGroup, index) in tagGroups" :key="index">
            『{{ tagGroup }}』
          </span>
          <span v-if="registeredTagGroups.length !== 0">
            のフラググループが生成されました
          </span>
        </div>
      </div>
      <div class="success-follow">
        <p class="success-follow_txt">
          続けて登録を行いたい場合は下のボタンよりCSV登録ページへ移動してください。
        </p>
        <div class="btn-col2">
          <div
            v-if="registeredApplicantIds && registeredApplicantIds.length > 0"
            class="btn btn-availability"
            @click="
              $store.dispatch('modalApplicants/VISIBLE_MODAL_APPLICANTS', {
                title: `${csvFileName}で新たに登録した応募者です。`,
                applicantIds: registeredApplicantIds,
              })
            "
          >
            CSV登録を行った応募者の情報を確認する
            <img
              class="icon-rightArrow"
              src="@/assets/img/arrow_right_white.svg"
              alt=""
            />
          </div>
          <div class="btn btn-availability" @click="resetModes">
            続けてCSVで応募者を登録する
            <img
              class="icon-rightArrow"
              src="@/assets/img/arrow_right_white.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <modal-loader
      :is-view="isUpdating === true"
      title="CSVのインポート中"
      message="インポート処理中です。しばらくお待ちください。"
    />
  </section>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import applicantService from '@/services/applicant';
import { validateCsvFileSize } from '@/utils/file';
import ModalAboutCsvFormat from '@/components/page/applicants/registration/components/ModalAboutCsvFormat';
import ModalLoader from '@/components/ui/loaders/components/ModalLoader';

export default defineComponent({
  name: 'RegisterApplicantByCsv',
  components: { ModalAboutCsvFormat, ModalLoader },
  props: {
    csvType: {
      type: String,
      required: true,
    },
    csvLabel: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const csvStatus = ref('importCsv'); // 'importCsv'|'uploadingCsv'|'confirmCsv'|'completeCsv'
    const isUpdating = ref(false);
    const isSuccessUpload = ref(true);
    const isVisibleConfirmFormatModal = ref(false);
    const csvInfo = ref([]);
    const resMessage = ref('');
    const registeredApplicantIds = ref([]);
    const registeredTagGroups = ref([]);
    const registeredGraduates = ref([]);
    const duplicatedRows = ref([]);
    const errorRows = ref([]);

    // computed
    const csvFileName = computed(() =>
      csvInfo.value && csvInfo.value.length >= 2 ? csvInfo.value[2] : '',
    );

    // methods
    const importCsvFile = async override => {
      isUpdating.value = true;
      csvStatus.value = 'uploadingCsv';
      const formData = new FormData();
      formData.append(...csvInfo.value);
      formData.append('type', props.csvType);
      formData.append('override', override);
      const res = await applicantService.importCsv(formData);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isUpdating.value = false;
      if (res.success === true) {
        csvStatus.value = 'completeCsv';
        return;
      }
      // 失敗時は卒年の再読み込み
      store.dispatch('graduateds/FETCH');
      if (res.data !== null) csvStatus.value = 'confirmCsv';
      registeredApplicantIds.value = res.data.applicants.map(v => v.id);
      registeredTagGroups.value = res.data.tag_groups.map(v => v.name);
      registeredGraduates.value = res.data.graduates.map(v => v);
      csvStatus.value = 'completeCsv';
    };
    const confirmUploadCsv = async () => {
      if (csvInfo.value.length === 0) return;
      csvStatus.value = 'uploadingCsv';
      const formData = new FormData();
      formData.append(...csvInfo.value);
      const res = await applicantService.verifyCsv(formData, props.csvType);
      csvStatus.value = 'confirmCsv';
      isSuccessUpload.value = res.success;
      resMessage.value = res.message;
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
      if (res.hasErrorRows === true) {
        errorRows.value = res.errorRows;
        return;
      }
      if (res.hasDuplicatedRows === true) {
        duplicatedRows.value = res.duplicatedRows;
        return;
      }
    };
    const openModalUploadCsv = override => {
      if (duplicatedRows.value.filter(obj => obj.tel !== '').length === 0) {
        importCsvFile(override);
        return;
      }
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        title: '電話番号が空欄の応募者がいます',
        message: '電話番号が空欄の応募者がいますが登録してよろしいですか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: ({ choice }) => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          if (choice === 2) importCsvFile(override);
        },
        onclickOuter: () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    const resetModes = () => {
      csvStatus.value = 'importCsv';
      store.dispatch('modalApplicants/INVISIBLE_MODAL_APPLICANTS');
      isUpdating.value = false;
      isSuccessUpload.value = true;
      resMessage.value = '';
      csvInfo.value = [];
      duplicatedRows.value = [];
      errorRows.value = [];
      registeredApplicantIds.value = [];
    };
    const onDropImage = event => {
      const files = event.dataTransfer.files;
      if (
        files[0].type !== 'text/csv' &&
        files[0].type !== 'application/vnd.ms-excel'
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'CSVファイル以外はアップロードできません',
          type: false,
        });
        resetModes();
        return;
      }
      const validationResult = validateCsvFileSize(files[0].size);
      if (!validationResult.success) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: validationResult.message,
          type: false,
        });
        return;
      }
      csvFilesChange(event.target.name, files);
    };
    const onSelectImage = event => {
      const files = event.target.files;
      const validationResult = validateCsvFileSize(files[0].size);
      if (!validationResult.success) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: validationResult.message,
          type: false,
        });
        return;
      }
      csvFilesChange(event.target.name, files);
    };
    const csvFilesChange = (fieldName, fileList) => {
      if (!fileList.length) return;
      csvInfo.value = [fieldName, fileList[0], fileList[0].name];
    };

    return {
      csvStatus,
      isUpdating,
      isSuccessUpload,
      isVisibleConfirmFormatModal,
      resMessage,
      registeredApplicantIds,
      registeredTagGroups,
      registeredGraduates,
      duplicatedRows,
      errorRows,
      csvFileName,
      resetModes,
      importCsvFile,
      confirmUploadCsv,
      openModalUploadCsv,
      onDropImage,
      onSelectImage,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
.sec-registration-body-links {
  padding-bottom: 2rem;
  &_item {
    font-size: 1.4rem;
    height: 1.2rem;
    line-height: 1;
    color: $mochica_color_gray;
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: 1px solid $border_color;
    cursor: pointer;
    &:last-of-type {
      border-right: none;
    }
    &.active {
      color: $mochica_color;
      font-weight: normal;
    }
  }
}
.sec-register-csv {
  .filename_wrapper {
    margin: 2rem 0 3rem;
    border-bottom: 1px solid $border_color;
    .filename_item {
      display: flex;
      align-items: center;
      height: 3rem;
      margin-top: 0.6rem;
      padding: 0 0.6rem 0 0;
      border-radius: 0.2rem;
      border-bottom: 1px #e3e3e3 solid;
    }
  }

  .file-select {
    .area-file-drop {
      .dropbox {
        border: 2px dashed #d6d6d6;
        color: dimgray;
        padding: 10px 10px;
        min-height: 200px;
        position: relative;
        cursor: pointer;
        @include flex_center_center;
        min-width: 50rem;
        &_inner {
          font-size: 1.2rem;
          text-align: center;
          &_txt {
            font-weight: bold;
            display: block;
            margin-bottom: 1rem;
            color: #9d9d9d;
            font-size: 2rem;
          }
          &_or {
            font-weight: normal;
            display: block;
            margin-bottom: 1.2rem;
          }
          &_btn {
            display: inline-block;
            line-height: 3rem;
            height: 3rem;
            padding: 0 1.5rem;
            color: black;
            background: #efefef;
            border-radius: 0.3rem;
            text-align: center;
          }
        }
      }
      .input-file {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        cursor: pointer;
      }
      .dropbox:hover {
        opacity: 0.8;
      }
      .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
      }
      .confirm {
        text-align: right;
        margin-top: 1rem;
        &_inner {
          &_btn {
            cursor: pointer;
            background: #efefef;
            height: 2.6rem;
            line-height: 2.6rem;
            display: inline-block;
            padding: 0 1.5rem;
            border-radius: 0.3rem;
            margin-bottom: 1rem;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
    }
    .file_submit_btn {
      background: $mochica_color;
      height: 3.8rem;
      margin-top: 2rem;
      @include flex_center_center;
      color: #fff;
      font-weight: bold;
      border-radius: 0.3rem;
      letter-spacing: 0.2em;
      position: relative;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        transition: 0.3s;
      }
      &.cancell {
        background: #fff;
        border: 1px solid #f1f1f1;
        color: #333;
        margin-top: 1rem;
        font-weight: normal;
      }
      .arrow_right_img {
        width: 0.8rem;
        position: absolute;
        right: 13px;
      }
    }
  }

  .file-check {
    .filename_wrapper {
      margin: 2rem 0 3rem;
      padding: 0;
      border: none;
      border-bottom: 1px solid #e3e3e3;
    }
    .area-file-error {
      text-align: left;
      width: calc(100% - 3rem);
      margin: 3rem 0;
      &_ttl {
        color: $mochica_color;
        margin-bottom: 1.2rem;
      }
      &_list {
        min-width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        box-shadow: $box_shadow;
        &_header {
          height: 3.6rem;
          line-height: 3.6rem;
          background: #f7f7f7;
          border-top: 1px solid #e3e3e3;
          th {
            padding-left: 1.2rem;
          }
          th:nth-of-type(1) {
            width: 20%;
          }
          th:nth-of-type(2) {
            width: 40%;
          }
          th:nth-of-type(3) {
            width: 40%;
          }
        }
        &_body {
          background: #fff;
          &_item {
            height: 3rem;
            line-height: 3rem;
            border-bottom: 1px dashed $border_color;
            > td {
              padding-left: 1.2rem;
              border-right: 1px solid #e3e3e3;
              &:last-of-type {
                border: none;
              }
            }
          }
        }
      }
    }
    .area-file-registration_btn {
      width: calc(100% - 3rem);
    }
    .area-file-registration_btn_error_message {
      display: block;
      color: #c31e1e;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .area-file-registration_btn_success_message {
      display: block;
      color: #99be05;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .area-file-registration_btn_item {
      @include flex_center_center;
      height: 3.6rem;
      border-radius: 0.3rem;
      letter-spacing: 0.03em;
      background: #fff;
      transition: all ease 0.3s;
      margin-bottom: 0.8rem;
      &.back {
        background: #efefef;
        color: #333;
      }
      &.expect {
        border: 1px solid #1899d6;
        color: #1899d6;
        position: relative;
        .arrow_right_img {
          width: 0.8rem;
          position: absolute;
          right: 13px;
        }
      }
      &.with {
        background: #1899d6;
        color: #fff;
        font-weight: bold;
        position: relative;
        .arrow_right_img {
          width: 0.8rem;
          position: absolute;
          right: 13px;
        }
      }
      &.through {
        background: #1899d6;
        color: #fff;
        font-weight: bold;
        margin-top: 2.4rem;
      }
      &.cancel {
        background: #efefef;
        color: #333;
        margin-top: 1rem;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .success-info {
    width: 100%;
    margin: 2.4rem 0;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid $border_color;
    .filename_wrapper {
      margin-bottom: 20px;
      border-bottom: none;
    }
  }
  .success-info_txt {
    margin: 20px 0 0;
  }
  .success-follow_txt {
    margin: 20px 0 20px;
  }
}
.new_tag_group {
  margin-top: 1rem;
  overflow-y: scroll;
  height: 120px;
  padding: 1.3rem;
  border-radius: 0.2rem;
  line-height: 1.8;
  border: solid 1px #e3e3e3;
}
.btn {
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    opacity: 0.6;
  }
}
// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.sec-registration-body_ttl {
  line-height: 1.8rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: inline-block;
}
.normal-txt {
  margin-bottom: 20px;
}
.area-file-error_ttl {
  color: #333 !important;
  font-weight: bold;
}
.area-file-registration_btn_error_message {
  font-size: 1.4rem;
  font-weight: bold;
}
.area-file-registration_btn_success_message {
  font-size: 1.4rem;
  font-weight: bold;
}
table.normal-txt {
  text-align: center;
  border: solid 1px #9d9d9d;
  width: 200px;
  margin-bottom: 1rem;
  td {
    padding: 0.5rem;
    border: 1px solid #9d9d9d;
  }
}
.-mt40 {
  margin-top: 40px !important;
}
.table-normal {
  border-right: 1px solid #e3e3e3;
}
.table-section {
  overflow: auto;
  max-height: 500px;
}
</style>
