import cloneDeep from 'lodash.clonedeep';

const selectionListColumnsDefault = [
  {
    name: 'event',
    name_jp: '選考名',
    sort_by: 'event',
    relation: ['event.title'],
    activated: { disabled: false },
    notActivated: { disabled: true },
  },
  {
    name: 'countVenues',
    name_jp: '登録会場数',
    sort_by: 'countVenues',
    relation: ['countVenues'],
    activated: { disabled: false },
    notActivated: { disabled: true },
    filter: 'countVenues',
  },
  {
    name: 'reserve',
    name_jp: '選考予約画面に表示',
    sort_by: 'reserve',
    relation: ['reserve'],
    activated: { disabled: false },
    notActivated: { disabled: true },
  },
  {
    name: 'reminder_check',
    name_jp: '担当者へ通知',
    sort_by: 'reminder_check',
    relation: ['reminder_check'],
    activated: { disabled: true },
    notActivated: { disabled: true },
  },
  {
    name: 'ActialPerLimit',
    name_jp: '参加人数',
    sort_by: 'ActialPerLimit',
    relation: ['ActialPerLimit'],
    activated: { disabled: true },
    notActivated: { disabled: true },
  },
  {
    name: 'allDates',
    name_jp: '開催日時',
    sort_by: 'allDates',
    relation: ['allDates'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'description',
    name_jp: '管理用メモ',
    sort_by: 'description',
    relation: ['description'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
];

const getSelectionListColumns = () => {
  return cloneDeep(selectionListColumnsDefault);
};

const getSelectionListNotAnnouncedColumns = () => {
  const columns = cloneDeep(selectionListColumnsDefault);
  columns.splice(3, 0, {
    name: 'notAnnouncedCount',
    name_jp: '未案内件数',
    sort_by: 'notAnnouncedCount',
    relation: ['notAnnouncedCount'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  });
  return columns;
};

const getSelectionListNotResultedColumns = () => {
  const columns = cloneDeep(selectionListColumnsDefault);
  columns.splice(3, 0, {
    name: 'notResultedCount',
    name_jp: '未通知件数',
    sort_by: 'notResultedCount',
    relation: ['notResultedCount'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  });
  return columns;
};

const getSelectionFlowColumns = () => [
  {
    name: 'event',
    name_jp: '選考名',
    sort_by: 'event',
    relation: ['event.title'],
    activated: { disabled: false },
    notActivated: { disabled: true },
  },
  {
    name: 'countVenues',
    name_jp: '登録会場数',
    sort_by: 'countVenues',
    relation: ['countVenues'],
    activated: { disabled: false },
    notActivated: { disabled: true },
    filter: 'countVenues',
  },
  {
    name: 'ActialPerLimit',
    name_jp: '参加人数',
    sort_by: 'ActialPerLimit',
    relation: ['ActialPerLimit'],
    activated: { disabled: true },
    notActivated: { disabled: true },
  },
  {
    name: 'allDates',
    name_jp: '開催日時',
    sort_by: 'allDates',
    relation: ['allDates'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
  {
    name: 'description',
    name_jp: '管理用メモ',
    sort_by: 'description',
    relation: ['description'],
    activated: { disabled: false },
    notActivated: { disabled: false },
  },
];

export {
  getSelectionListColumns,
  getSelectionListNotAnnouncedColumns,
  getSelectionListNotResultedColumns,
  getSelectionFlowColumns,
};
