<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';

import ContactSwitchManager from '@/services/contact-switch';

const router = useRoute();

const isSuccess = ref<boolean | null>(null);

const init = async () => {
  const queryToken = router.query?.token;
  if (typeof queryToken !== 'string') {
    isSuccess.value = false;
    return;
  }

  const res = await ContactSwitchManager.contactSwitch({
    token: queryToken,
  });
  isSuccess.value = res.data.success;
};

onMounted(() => {
  init();
});
</script>

<template>
  <div class="wrapper">
    <div class="container">
      <div v-if="isSuccess === null" class="inner">
        <local-page-loader />
      </div>
      <div v-if="isSuccess" class="inner">
        <div class="circle">
          <img
            class="icon"
            src="@/assets/img/check_rounded.svg"
            alt="チェックマークのアイコン"
          />
        </div>
        <h1 class="container-title">
          今後はLINEに情報を
          <br />
          お届けいたします
        </h1>
        <p class="container-text">
          まだ友達追加をしていない場合は
          <br />
          LINEトーク画面にて友達追加を
          <br />
          お願いします
        </p>
        <div class="caption">このまま画面を閉じて下さい</div>
      </div>
      <div v-else class="inner">
        <div class="circle danger">
          <img
            class="icon"
            src="@/assets/img/cross.svg"
            alt="バツマークのアイコン"
          />
        </div>
        <h1 class="container-title danger">このページは表示できません</h1>
        <p class="container-text">
          <span class="container-text-inner">
            すでにこのページへアクセスしている、
            <br />
            またはページの有効期限が切れている
            <br />
            可能性があります。
          </span>
        </p>
        <div class="caption">このまま画面を閉じて下さい</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 390px;
  height: 100vh;
  padding: 18px;
  background: url('../../assets/img/bg-primary-mask.png') lightgray 0px 0px /
    100% 100% no-repeat;
  background-color: #3b9bcc;
  background-blend-mode: overlay;
}
.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $white;
  border-radius: 8px;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background: url('../../assets/img/bg_primary_148.png') center center / cover
    no-repeat;
  &.danger {
    background: url('../../assets/img/bg_danger_148.png') center center / cover
      no-repeat;
  }
}
.container-title {
  color: #2a8bbc;
  font-size: $font_20;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;

  &.danger {
    color: $mochica_color_red;
  }
}
.container-text {
  margin-top: 12px;
  font-size: $font_16;
  line-height: 1.8;
  text-align: center;
}
.container-text-inner {
  display: block;
  & + & {
    margin-top: 30px;
  }
}
.caption {
  margin-top: 32px;
  color: $system_color;
}
</style>
