/**
 * キャメルケースをスネークケースに変更
 * @param {String} name - キャメルケース文字列
 * @return {String} スネークケース文字列
 */
const camelToSnakeCase = name => {
  return name.replace(/([A-Z])/g, s => `_${s.charAt(0).toLowerCase()}`);
};
/**
 * スネークケースをキャメルケースに変更
 * @param {String} name - スネークケース文字列
 * @return {String} キャメルケース文字列
 */
const snakeToCamelCase = name => {
  return name.replace(/_./g, s => s.charAt(1).toUpperCase());
};
/**
 * key名がスネークケースのObjectをkey名がキャメルケースのObjectに変更
 * @param {Object} obj - スネークケースオブジェクト
 * @return {Object} キャメルケースオブジェクト
 */
const snakeToCamelCaseForObject = obj => {
  const resObject = {};
  Object.keys(obj).forEach(
    key => (resObject[snakeToCamelCase(key)] = obj[key]),
  );
  return resObject;
};

export { camelToSnakeCase, snakeToCamelCase, snakeToCamelCaseForObject };
