<template>
  <button
    class="btn-base"
    :class="generateBtnClass(buttonType, iconFileName, isOutlined, isLoading)"
    :style="generateBtnStyle(iconFileName)"
    :disabled="
      hasPermission === false || isLoading === true || isDisabled === true
    "
    @click="$emit('click', $event)"
  >
    <div class="loader">
      <span>{{ buttonText }}</span>
    </div>
  </button>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

const definedButtonTypes = [
  'primary',
  'secondary',
  'tertiary',
  'success',
  'danger',
];
const definedRoleIds = [1, 2, 3]; // 1,（管理者権限）, 2（人事担当者）, 3（担当者）
const definedAdminIds = [1, 2, null]; // 1（MOCHICA管理者）, 2（マスターアカウント）, null（admin権限無し）

export default defineComponent({
  name: 'ButtonBase',
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    buttonType: {
      type: String,
      default: 'primary',
    },
    iconFileName: {
      type: String,
      default: '',
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    rejectedRoleIds: {
      type: Array,
      default: () => [],
    },
    rejectedAdminIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click'],

  setup(props, context) {
    const store = useStore();
    const staff = store.getters['staff/staff'];

    // computed
    const hasPermission = computed(() =>
      judgeHasPermission(staff, props.rejectedRoleIds, props.rejectedAdminIds),
    );

    const filterCorrectPermissionIds = (
      definedPermissionIdsArray,
      rejectedPermissionIdsArray,
    ) => {
      const definedPermissionIds = definedPermissionIdsArray;
      const rejectedPermissionIds = rejectedPermissionIdsArray;
      if (
        Array.isArray(definedPermissionIds) === false ||
        Array.isArray(rejectedPermissionIds) === false ||
        rejectedPermissionIds.length === 0
      ) {
        return [];
      }

      const filteredPermissionIds = rejectedPermissionIds.filter(
        permissionId => definedPermissionIds.includes(permissionId) === true,
      );

      return filteredPermissionIds;
    };

    const judgeHasPermission = (
      staffObj,
      rejectedRoleIdsArray,
      rejectedAdminIdsArray,
    ) => {
      const staffObject = staffObj;
      if (typeof staffObject !== 'object') return false;

      const rejectedRoleIds = filterCorrectPermissionIds(
        definedRoleIds,
        rejectedRoleIdsArray,
      );
      const rejectedAdminIds = filterCorrectPermissionIds(
        definedAdminIds,
        rejectedAdminIdsArray,
      );
      if (rejectedRoleIds.length === 0 && rejectedAdminIds.length === 0) {
        return true;
      }

      return (
        rejectedRoleIds.includes(staffObject.role.id) === false &&
        rejectedAdminIds.includes(staffObject.admin) === false
      );
    };

    const generateBtnStyle = iconName => {
      if (typeof iconName === 'string' && iconName !== '') {
        try {
          const iconFilePath = require(`@/assets/img/${iconName}.svg`);
          return `--icon-src: url(${iconFilePath});`;
        } catch (e) {
          console.error(e);
          return '';
        }
      }
      return '';
    };

    const generateBtnClass = (btnType, iconName, isOutlined, isLoading) => {
      let classString = definedButtonTypes.includes(btnType)
        ? `btn-${btnType}`
        : 'btn-primary';

      const existsIcon = !!generateBtnStyle(iconName);
      if (existsIcon === true) {
        classString = classString.concat(` btn-icon btn-icon-${iconName}`);
      }

      if (isOutlined === true) {
        classString = classString.concat(` btn-outlined`);
      }

      if (isLoading === true) {
        classString = classString.concat(' btn-loading');
      }

      return classString;
    };

    return {
      hasPermission,
      generateBtnStyle,
      generateBtnClass,
    };
  },
});
</script>

<style scoped lang="scss">
$btnPrimaryColor: #16b2d9 !default;
$btnPrimaryHoverColor: #1698d9 !default;
$btnSecondaryColor: #999 !default;
$btnSecondaryHoverColor: #666 !default;
$btnTertiaryColor: #eee !default;
$btnTertiaryHoverColor: #d9d9d9 !default;
$btnSuccessColor: #12b312 !default;
$btnSuccessHoverColor: #007300 !default;
$btnDangerColor: #bf0000 !default;
$btnDangerHoverColor: #8c0707 !default;
$btnOutlinedColor: #fff !default;
$btnOutlinedHoverColor: #f5f5f5 !default;
$btnDisabledColor: #999 !default;

$standardFontColor: #fff !default;
$disabledFontColor: #333 !default;

$fontSize: 1.2rem !default;
$loadingIconSize: 1.4rem;

@mixin buttonDesign($btnColor, $btnHoverColor) {
  background-color: $btnColor;
  &:hover {
    background-color: $btnHoverColor;
  }

  &.btn-outlined {
    color: $btnColor;
    border: solid 1px $btnColor;
    span::before {
      background-color: $btnColor;
    }
    &:hover {
      color: $btnHoverColor;
      border-color: $btnHoverColor;
      span::before {
        background-color: $btnHoverColor;
      }
    }
  }
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button.btn-base {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 4px;
  text-align: center;
  color: $standardFontColor;
  font-size: $fontSize;
  font-weight: bold;
  transition: background-color 0.3s ease-out;
  cursor: pointer;

  &.btn-icon {
    span::before {
      content: '';
      mask-image: var(--icon-src);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      display: inline-block;
      position: relative;
      background-color: $standardFontColor;
      height: $fontSize;
      width: $fontSize;
      margin: -1px 5px -1px 0;
    }

    &.icon-blue span::before {
      background-color: $btnPrimaryColor;
    }

    &.icon-gray span::before {
      background-color: $btnSecondaryColor;
    }

    &.btn-icon-plus_circle span::before {
      margin-right: 6px;
      top: 0.8px;
    }

    &.btn-icon-download span::before {
      height: 1.1rem;
      width: 1.1rem;
      margin-right: 6px;
      top: 0.5px;
    }

    &.btn-icon-arrow_right span::before {
      height: 1.1rem;
      width: 1.1rem;
      top: 0.5px;
    }

    &.btn-icon-copy span::before {
      margin-right: 8px;
    }

    &.btn-icon-check span::before {
      top: 1px;
    }

    &.btn-icon-loading_arrow span::before {
      top: 1.5px;
      margin-right: 8px;
    }

    &.btn-icon-checkbox span::before {
      height: 1.15rem;
      width: 1.4rem;
    }

    &.btn-icon-icon_eye_white span::before {
      height: 1rem;
      width: 1.5rem;
      margin-right: 8px;
    }
  }

  &.btn-primary {
    @include buttonDesign($btnPrimaryColor, $btnPrimaryHoverColor);
  }

  &.btn-secondary {
    @include buttonDesign($btnSecondaryColor, $btnSecondaryHoverColor);
  }

  &.btn-tertiary {
    @include buttonDesign($btnTertiaryColor, $btnTertiaryHoverColor);
    color: #777;
  }

  &.btn-success {
    @include buttonDesign($btnSuccessColor, $btnSuccessHoverColor);
  }

  &.btn-danger {
    @include buttonDesign($btnDangerColor, $btnDangerHoverColor);
  }

  &.btn-outlined {
    background-color: $btnOutlinedColor;
    &:hover {
      background-color: $btnOutlinedHoverColor;
    }
  }

  &:disabled,
  &:disabled.btn-outlined {
    @include buttonDesign($btnDisabledColor, $btnDisabledColor);
    color: $disabledFontColor;
    border: none;
    opacity: 0.3;
    cursor: not-allowed;

    span::before {
      background-color: $disabledFontColor;
    }

    &.btn-loading {
      span {
        visibility: hidden;
      }

      .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &::before {
          content: '';
          mask-image: url('../../../../assets/img/loader.svg');
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: contain;
          display: inline-block;
          position: absolute;
          top: calc(50% - #{$loadingIconSize}/ 2);
          left: calc(50% - #{$loadingIconSize}/ 2);
          background-color: $standardFontColor;
          height: $loadingIconSize;
          width: $loadingIconSize;
          animation: rotate-animation 0.8s infinite linear;
        }
      }
    }
  }
}
</style>
