<template>
  <div class="google_link">
    <p class="setting_title">Googleカレンダー連携</p>
    <p class="setting_text">
      GoogleカレンダーとMOCHICAを連携することで、MOCHICA内でGoogleの予定を参照できるようになります。
      <span
        v-if="isConnectedGoogleCalendar === true"
        class="setting_text setting_caption"
      >
        ※認証キーファイル（JSON）・ワークスペースカスタマーIDを変更するには一度連携を解除する必要があります
      </span>
    </p>

    <div v-if="isConnectedGoogleCalendar !== true">
      <button
        class="btn btn-availability"
        @click="$emit('changeMode', 'linkGoogleCalendar')"
      >
        連携に進む
      </button>
    </div>
    <div v-else>
      <dl
        v-if="isConnectedGoogleWorkspaceCustomerId !== true"
        class="setting_list"
      >
        <dt>連携する会議室の設定</dt>
        <dd>
          <p>Googleの会議室とMOCHICAを連携することができます。</p>
          <p>連携を行うことで会議室の予定を確認できるようになります。</p>
          <button
            class="btn btn-availability btn-long"
            @click="$emit('changeMode', 'linkWorkspaceCustomerId')"
          >
            連携に進む
          </button>
        </dd>
      </dl>
      <dl
        v-if="
          isConnectedGoogleCalendar === true &&
          isConnectedGoogleWorkspaceCustomerId === true
        "
        class="setting_list"
      >
        <dt>連携する会議室の設定</dt>
        <dd>
          <div class="resource_box">
            <div class="resource_box_head">
              <p>連携する会議室</p>
              <button
                class="btn btn-availability"
                @click="$emit('changeMode', 'editResource')"
              >
                編集
              </button>
            </div>
            <div class="resource_box_content">
              <p v-if="!calendarResources || calendarResources.length === 0">
                連携している会議室はありません
              </p>
              <ul v-else>
                <li
                  v-for="calendarResource in calendarResources"
                  :key="calendarResource.id"
                >
                  {{ calendarResource.name }}
                </li>
              </ul>
            </div>
          </div>
        </dd>
      </dl>
      <dl class="setting_list">
        <dt>カレンダー連携の解除</dt>
        <dd>
          <button-base
            class="btn btn-unavailable"
            button-text="連携解除"
            button-type="secondary"
            :is-loading="isLoading"
            :rejected-role-ids="[2, 3]"
            :rejected-admin-ids="[2]"
            @click="openResetLinkGoogleCalendar"
          />
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import GoogleCalendarService from '@/services/google-calendar';

export default defineComponent({
  name: 'LinkGoogleCalendar',
  props: {
    isConnectedGoogleCalendar: {
      type: Boolean,
      default: false,
    },
    isConnectedGoogleWorkspaceCustomerId: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'changeMode',
    'changeIsConnectedGoogleCalendar',
    'changeIsConnectedGoogleWorkspaceCustomerId',
  ],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);
    const calendarResources = ref([]);

    // methods
    const checkLinkGoogleCalendar = async () => {
      const res = await GoogleCalendarService.checkLinkGoogleCalendar();
      if (res.success !== true) {
        if (res.code === 1001) {
          // role=1以外はリダイレクト
          router.push({ name: 'Configs' });
        }
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        context.emit('changeIsConnectedGoogleCalendar', false);
        context.emit('changeIsConnectedGoogleWorkspaceCustomerId', false);
      } else {
        context.emit(
          'changeIsConnectedGoogleCalendar',
          res.is_connected_google_calendar,
        );
        context.emit(
          'changeIsConnectedGoogleWorkspaceCustomerId',
          res.is_connected_google_workspace_customer_id,
        );
        calendarResources.value = res.calendar_resources;
      }
    };
    const resetLinkGoogleCalendar = async () => {
      isLoading.value = true;
      const res = await GoogleCalendarService.resetLinkGoogleCalendar();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        type: res.success,
        message: res.message,
      });
      isLoading.value = false;
      if (res.success === true) {
        context.emit('changeIsConnectedGoogleCalendar', false);
        context.emit('changeIsConnectedGoogleWorkspaceCustomerId', false);
      }
    };
    const openResetLinkGoogleCalendar = async () => {
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        alertFlag: true,
        accentColor: '#C31E1E',
        title: 'カレンダー連携の解除',
        message: '本当にGoogleカレンダー連携を解除しますか？',
        buttonText1: 'キャンセル',
        buttonText2: '解除する',
        onSelected: async payload => {
          if (payload.choice === 3 && isLoading.value === false) {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            await resetLinkGoogleCalendar();
          } else {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          }
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };

    return {
      isLoading,
      checkLinkGoogleCalendar,
      openResetLinkGoogleCalendar,
      calendarResources,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.google_link {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid #e5e5e5;
}
.setting_title {
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #5896cc;
  border-left: 6px solid #5896cc;
}

.setting_text {
  margin-bottom: 24px;
  line-height: 1.75;
  > span {
    display: block;
    margin-top: 5px;
  }
}

.setting_caption {
  color: #777;
  font-size: 12px;
}

.setting_list {
  border-top: 1px solid #e5e5e5;
  padding-top: 24px;
  margin-bottom: 24px;
  > dt {
    margin-bottom: 18px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: bold;
    > span {
      display: inline-block;
      width: 44px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      background-color: #bb1b1c;
      border-radius: 4px;
      text-align: center;
    }
  }
  > dd {
    margin-bottom: 18px;

    > p {
      margin-bottom: 10px;
    }
  }
}

.resource_box {
  width: 628px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  .resource_box_head {
    display: flex;
    padding: 8px 16px;
    border-bottom: 1px solid #c9c9c9;
    > p {
      font-weight: bold;
      line-height: 34px;
    }
    > .btn-availability {
      width: 81px;
      height: 34px;
      padding-left: 30px;
      margin-left: auto;
      background-image: url('../../../../../assets/img/pen_white.svg');
      background-size: auto 12px;
      background-position: 16px 10px;
      background-repeat: no-repeat;
    }
  }
  .resource_box_content {
    > p {
      color: #777;
      padding: 16px;
    }
    > ul {
      max-height: 180px;
      overflow: auto;
      > li {
        padding: 12px 16px;
        border-bottom: 1px solid #c9c9c9;
        &:nth-child(2n) {
          background-color: #fafafa;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.btn-availability {
  width: 189px;
  height: 40px;
  &.btn-long {
    margin-top: 15px;
    width: 260px;
  }
  &:disabled {
    color: #333 !important;
    background-color: #999;
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.btn-unavailable {
  width: 189px;
  height: 40px;
}

@media (max-width: ($media_query_sp)) {
  .resource_box {
    width: 100%;
  }
}
</style>
