import httpClient from '@/http-client';

const Login = function _Login() {
  const admin = Object.create(Login.prototype);
  return admin;
};

Login.prototype = {
  async getLoginContents() {
    const res = await httpClient.get('/login/contents');
    return res.data;
  },
};

export default Login();
