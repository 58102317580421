import httpClient from '@/http-client';

const SearchFilterPresets = function _SearchFilterPresets() {
  const company = Object.create(SearchFilterPresets.prototype);
  return company;
};

SearchFilterPresets.prototype = {
  async fetchSearchFilterPresets() {
    const res = await httpClient.get('/search_filter_presets');
    return res.data;
  },
  async createSearchFilterPresets(payload) {
    const res = await httpClient.post('/search_filter_presets', payload);
    return res.data;
  },
  async updateSearchFilterPresets(id, payload) {
    const res = await httpClient.put(`/search_filter_presets/${id}`, payload);
    return res.data;
  },
  async deleteSearchFilterPresets(id) {
    const res = await httpClient.delete(`/search_filter_presets/${id}`);
    return res.data;
  },
};
export default SearchFilterPresets();
