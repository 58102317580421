<template>
  <div class="form__wrapper">
    <div class="form__inner">
      <ext-page-error v-if="status === 'error'" />
      <transition name="fade">
        <div v-if="status !== 'error' && status !== ''">
          <div class="form__inner__title">
            <div class="company_name">{{ companyName }}</div>
            <div class="entry_title">エントリーフォーム</div>
          </div>
          <div ref="formContent" class="form__inner__contents">
            <input-email
              v-show="status === 'inputEmail'"
              :is-required-email="isRequiredEmail"
              :is-entered="isEntered"
              @verifyEmail="verifyEmail"
              @skipSendEmail="skipSendEmail"
              @registerEmail="registerEmail"
            />
            <input-otp
              v-if="status === 'inputOtp'"
              :email="email"
              :is-required-email="isRequiredEmail"
              @verifyOtp="verifyOtp"
              @resendOtp="resendOtp"
              @back="back"
            />
            <entry-form
              v-if="graduatedDefault !== null && isEntered !== true"
              v-show="status === 'entryForm'"
              :email="email"
              :is-fixed-graduated="isFixedGraduated"
              :graduateds="graduatedList"
              :initial-graduated-year="graduatedDefault"
              @preview="preview"
              @backPage="status = 'inputEmail'"
            />
            <preview
              v-if="status === 'preview' && isEntered !== true"
              :entry-form="entryForm.data"
              :questions="previewQuestions.data"
              @register="register"
              @backPage="status = 'entryForm'"
            />
            <ext-page-completed v-if="status === 'completedOtp'" />
            <ext-page-completed v-if="status === 'completedEntry'" />
            <ext-page-completed
              v-if="status === 'entered'"
              title="既に登録が完了しています"
              content="このまま画面を閉じてください。"
            />
            <notification />
          </div>
        </div>
      </transition>
    </div>
    <local-page-loader v-if="isLoading" :is-full-size="true" />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import qs from 'qs';
import cloneDeep from 'lodash.clonedeep';

import applicantRegisterService from '@/services/applicant-registration';
import Notification from '@/components/ui/notification/components/Notification';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import InputEmail from '@/components/page/line_entry/components/InputEmail';
import InputOtp from '@/components/page/line_entry/components/InputOtp';
import EntryForm from '@/components/page/line_entry/components/EntryForm';
import Preview from '@/components/page/line_entry/components/Preview';
import ExtPageCompleted from '@/components/features/extPage/components/ExtPageCompleted';
import ExtPageError from '@/components/features/extPage/components/ExtPageError';

export default defineComponent({
  name: 'LineEntry',
  components: {
    InputEmail,
    InputOtp,
    EntryForm,
    Preview,
    ExtPageCompleted,
    ExtPageError,
    Notification,
    LocalPageLoader,
  },
  setup(props, context) {
    const store = useStore();
    const status = ref('');
    const token = ref(null);
    const companyName = ref('');
    const email = ref('');
    const isFixedGraduated = ref(false);
    const graduatedDefault = ref(null);
    const isEntered = ref(false);
    const isRequiredEmail = ref(false);
    const isLoading = ref(false);
    const graduatedList = ref([]);
    const entryForm = reactive({ data: {} });
    const previewQuestions = reactive({ data: {} });

    // watch
    watch(status, val => {
      // status変更時スクロールリセット
      window.scrollTo(0, 0);
    });

    // methods
    const verifyToken = async () => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const res = await applicantRegisterService.verifyToken(token.value);
      if (res.status === 200) {
        companyName.value = res.data.company_name;
        if (res.data.completed === true) {
          // 既に登録済みの場合
          status.value = 'entered';
        } else {
          // Email登録画面
          status.value = 'inputEmail';
          // 初期登録種別が非表示の場合、卒年を自動で選択させない
          const existDefaultGraduated =
            res.data.graduated_default &&
            res.data.graduateds
              .map(graduated => graduated.year)
              .includes(res.data.graduated_default);
          if (existDefaultGraduated) {
            graduatedDefault.value = res.data.graduated_default;
          } else {
            graduatedDefault.value = '';
          }
          graduatedList.value = res.data.graduateds;
          isEntered.value = res.data.is_entered === true;
          isRequiredEmail.value = res.data.is_required_email === true;
          isFixedGraduated.value = res.data.has_progress === true;
        }
      } else {
        // エラー画面
        status.value = 'error';
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: false,
        });
      }
      isLoading.value = false;
    };
    const verifyEmail = async tmpEmail => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const result = await applicantRegisterService.verifyEmail(
        token.value,
        tmpEmail,
      );
      if (result.status === 200) {
        email.value = tmpEmail;
        if (result.data.completed === true) {
          // 既に登録済みの場合
          status.value = 'entered';
        } else if (result.data.continue === true) {
          // エントリーフォーム画面へ
          status.value = 'entryForm';
        } else {
          // 既にデータ登録済みなのでotpの確認
          status.value = 'inputOtp';
        }
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: false,
          message: result.data.message,
        });
      }
      isLoading.value = false;
    };
    const registerEmail = async tmpEmail => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const result = await applicantRegisterService.verifyEmail(
        token.value,
        tmpEmail,
      );
      if (result.status === 200) {
        email.value = tmpEmail;
        if (result.data.completed === true) {
          // 既に登録済みの場合
          status.value = 'entered';
        } else if (result.data.continue === true) {
          // エントリーフォーム入力済みの場合はEmailを登録して終了
          if (isEntered.value === true) {
            const resRegisterEmail =
              await applicantRegisterService.registerEntryForm({
                entry_form: { email: tmpEmail },
                token: token.value,
              });
            if (resRegisterEmail.data.success === true) {
              // 送信完了画面へ
              status.value = 'completedEntry';
            } else {
              store.dispatch('notification/VISIBLE_NOTIFICATION', {
                message: resRegisterEmail.data.message,
                type: false,
              });
            }
          } else {
            // エントリーフォーム画面へ
            status.value = 'entryForm';
          }
        } else {
          // 既にデータ登録済みなのでotpの確認
          status.value = 'inputOtp';
        }
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: false,
          message: result.data.message,
        });
      }
      isLoading.value = false;
    };
    const resendOtp = async () => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const result = await applicantRegisterService.resendOtp(
        token.value,
        email.value,
      );
      if (result.data.completed === true) {
        // 既に登録済みの場合
        status.value = 'entered';
      } else if (result.status === 200) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: result.data.message,
          type: true,
        });
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: result.data.message,
          type: false,
        });
      }
      isLoading.value = false;
    };
    const verifyOtp = async otp => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const res = await applicantRegisterService.verifyOtp(
        token.value,
        email.value,
        otp,
      );
      if (res.data.success === true) {
        if (res.data.completed === true) {
          // 既に登録済みの場合
          status.value = 'entered';
        } else {
          // Email紐付け完了画面へ
          status.value = 'completedOtp';
        }
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message
            ? res.data.message
            : '正しいワンタイムパスワードを入力してください',
          type: false,
        });
      }
      isLoading.value = false;
    };
    const preview = async payload => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const res = await applicantRegisterService.previewEntryForm({
        entry_form: parseEntryForm(cloneDeep(payload.entryForm)),
        token: token.value,
      });
      if (res.data.success === true) {
        if (res.data.completed === true) {
          // 既に登録済みの場合
          status.value = 'entered';
        } else {
          // プレビュー画面へ
          entryForm.data = cloneDeep(payload.entryForm);
          previewQuestions.data = cloneDeep(payload.questions);
          status.value = 'preview';
        }
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: false,
        });
      }
      isLoading.value = false;
    };
    const register = async () => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const res = await applicantRegisterService.registerEntryForm({
        entry_form: parseEntryForm(entryForm.data),
        token: token.value,
      });
      if (res.data.success === true) {
        // 送信完了画面へ
        status.value = 'completedEntry';
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: false,
        });
      }
      isLoading.value = false;
    };
    const parseEntryForm = obj => {
      // APIのリクエスト形式に合わせる
      const entryForm = cloneDeep(obj);
      entryForm.firstname_kana = entryForm.firstnameKana;
      entryForm.lastname_kana = entryForm.lastnameKana;
      entryForm.gender_id = entryForm.genderId;
      entryForm.department_category_id = entryForm.departmentCategoryId;
      entryForm.tag_ids = entryForm.tagIds;
      delete entryForm.firstnameKana;
      delete entryForm.lastnameKana;
      delete entryForm.genderId;
      delete entryForm.departmentCategoryId;
      delete entryForm.tagIds;
      delete entryForm.questions;
      return entryForm;
    };
    const skipSendEmail = () => {
      // エントリーフォーム画面へ
      status.value = 'entryForm';
      email.value = '';
    };
    const back = () => {
      // Email入力画面へ
      status.value = 'inputEmail';
    };

    // lifecycle
    onBeforeMount(async () => {
      token.value = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }).token;
      await verifyToken();
    });

    return {
      status,
      token,
      companyName,
      email,
      graduatedDefault,
      graduatedList,
      entryForm,
      previewQuestions,
      isEntered,
      isRequiredEmail,
      isFixedGraduated,
      isLoading,
      // methods
      verifyToken,
      verifyEmail,
      registerEmail,
      verifyOtp,
      resendOtp,
      preview,
      register,
      skipSendEmail,
      back,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.form__wrapper {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #e5e5e5;
}

.form__inner {
  width: 100%;
  max-width: 375px;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: 0.05em;
  background-color: #fff;
}

.form__inner__title {
  padding: 20px 15px 60px 15px;
  text-align: center;
  background-image: url('../../assets/img/entryform_bg.png');
  background-size: 375px auto;
  color: #fff;
  background-repeat: repeat-y;

  > .company_name {
    font-size: 28px;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 10px;
  }

  > .entry_title {
    line-height: 1.5;
    font-weight: bold;
  }
}

.form__inner__contents {
  margin-top: -35px;
  padding: 30px 20px 20px;
  font-size: 1.3rem;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
</style>
