<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <div class="form__wrapper">
      <div class="form__inner">
        <div class="form__inner__contents">
          <div class="smartphone">
            <div class="main-wrapper">
              <div class="mochica_desc">
                <div v-if="isVisibleInvalidForm" class="form-confirmed">
                  <div class="bg-bold">{{ message }}</div>
                </div>
                <div v-else class="form-confirmed">
                  <div class="bg-bold">{{ message }}</div>
                  <notification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import Notification from '@/components/ui/notification/components/Notification';
import qs from 'qs';

import lineTalkService from '@/services/linetalk';
import { onBeforeMount } from 'vue';

export default defineComponent({
  name: 'LineMessageExt',
  components: { Notification },
  setup(props, context) {
    const store = useStore();
    const isVisibleInvalidForm = ref(false);
    const message = ref('');

    // lifecycle
    onBeforeMount(async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      const res = await lineTalkService.lineMessageExt(query.token);
      message.value = res.message;
      if (res.success !== true) isVisibleInvalidForm.value = true;
      await store.dispatch('page/SET_LOADED_FOR_EXT');
    });

    return {
      isVisibleInvalidForm,
      message,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include form_wrapper_css();

.form__inner__contents {
  justify-content: center;

  .smartphone {
    max-width: 500px;
  }
}

.mochica_desc {
  justify-content: center;
  padding: 3rem 0 3rem;
  .reminder-password {
    width: 100%;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
  }
  .submission-header {
    margin: 3rem 0;
  }
  .form-btn {
    width: 100%;
    color: #fff;
    padding: 1rem;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1.4rem;
    background: #1ecd01;
    cursor: pointer;
  }

  .form-confirmed,
  .form-header {
    text-align: center;
  }
  .form-body {
    padding-bottom: 2rem;
    border-bottom: 1px solid $border_color;
  }
  .bg-bold {
    white-space: pre-line;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 0.5rem 0;
  }
  .sm-bold {
    padding: 0.5rem 0;
    font-size: 1.4rem;
    font-weight: bold;
  }
}
</style>
