<template>
  <transition name="fade">
    <div v-if="$store.getters['page/isPageLoaded']">
      <list-view-staffs
        :staffs="staffs"
        :loading-edit-email="isLoadingEditEmail"
        :loading-cancel="isLoadingCancel"
        :loading-delete="isLoadingDelete"
        @commitedEmailChange="commitEmailChange"
        @onSelectedCancelDialog="onSelectedCancelDialog"
        @onClickSettleDeleteStaff="onClickSettleDeleteStaff"
        @fetchStaffs="fetchStaffs"
      />

      <div class="staffs-invite">
        <div class="staffs-invite_ttl">スタッフの招待</div>
        <form class="staffs-invite_form" :model="inviteForm">
          <div class="staffs-invite_form_item">
            <label>招待したいメールアドレス</label>
            <input
              v-model="inviteForm.email"
              class="mail-form"
              name="email"
              type="text"
              :class="{ 'is-danger': errors.email.isValid !== true }"
              placeholder="example@mochica.jp"
              @blur="validateEmail('email', inviteForm.email, true)"
            />
            <p v-show="errors.email.isValid !== true" class="fa-warning">
              {{ errors.email.message }}
            </p>
          </div>
          <div class="staffs-invite_form_item">
            <label>招待するスタッフの権限</label>
            <select
              v-model="inviteForm.role_id"
              name="role_id"
              :class="{ 'is-danger': errors.roleId.isValid !== true }"
            >
              <option value="">-</option>
              <option
                v-for="role in rolesExceptOwner"
                :key="role.id"
                :value="role.id"
              >
                {{ role.description }}
              </option>
            </select>
            <p v-show="errors.roleId.isValid !== true" class="fa-warning">
              {{ errors.roleId.message }}
            </p>
          </div>
          <div class="staffs-invite_form_item item-btn">
            <button
              :disabled="isDisabled"
              class="btn btn-availability"
              type="text"
              @click.prevent="postInvite()"
            >
              <img class="icon" src="@/assets/img/send_mail_white.svg" alt="" />
              招待メールを送信する
            </button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import { isOwner } from '@/defines/staff';
import staffsService from '@/services/staffs';
import useValidation from '@/composables/useValidation';
import ListViewStaffs from '@/components/page/configs/staffs/components/ListViewStaffs';

export default defineComponent({
  name: 'Staffs',
  components: { ListViewStaffs },
  setup(props, context) {
    const { errors, resetErrors, validateEmail, validateRequire } =
      useValidation(['email', 'roleId']);
    const store = useStore();
    const staffs = ref([]);
    const inviteForm = reactive({ role_id: null, email: '' });
    const isLoadingEditEmail = ref(false);
    const isLoadingCancel = ref(false);
    const isLoadingDelete = ref(false);
    const isDisabled = ref(false);

    // computed
    const rolesExceptOwner = computed(() =>
      store.getters['company/roles'].filter(role => !isOwner(role.name)),
    );

    // methods
    const fetchAll = async () => {
      staffs.value = await staffsService.fetchStaffs(true);
      await store.dispatch('company/FETCH_COMPANY_ROLES');
    };
    const fetchStaffs = async () => {
      const res = await staffsService.fetchStaffs(true);
      staffs.value = res;
    };
    const postInvite = async () => {
      resetErrors();
      const isValidEmail = validateEmail('email', inviteForm.email, true);
      const isValidRoleId = validateRequire(
        'roleId',
        inviteForm.role_id,
        'select',
      );
      if (
        isValidEmail !== true ||
        isValidRoleId !== true ||
        inviteForm.role_id === null ||
        inviteForm.role_id === ''
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '項目を正しく入力して下さい',
          type: false,
        });
        return;
      }
      isDisabled.value = true;
      const res = await staffsService.inviteStaff(
        parseInt(inviteForm.role_id, 10),
        inviteForm.email,
      );
      isDisabled.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        inviteForm.email = '';
        inviteForm.role_id = null;
        resetErrors();
      }
    };
    const commitEmailChange = async payload => {
      isLoadingEditEmail.value = true;
      const res = await staffsService.updateEmailPending(
        payload.staff_id,
        payload.staff_email,
      );
      fetchStaffs();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoadingEditEmail.value = false;
    };
    const onSelectedCancelDialog = async payload => {
      isLoadingCancel.value = true;
      const res = await staffsService.cancelEmailPending(payload.staff_id);
      fetchStaffs();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoadingCancel.value = false;
    };
    const onClickSettleDeleteStaff = async payload => {
      isLoadingDelete.value = true;
      const res = await staffsService.deleteStaff(payload.staff_id);
      fetchStaffs();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoadingDelete.value = false;
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      errors,
      staffs,
      inviteForm,
      isLoadingEditEmail,
      isLoadingCancel,
      isLoadingDelete,
      isDisabled,
      rolesExceptOwner,
      validateEmail,
      fetchStaffs,
      postInvite,
      commitEmailChange,
      onSelectedCancelDialog,
      onClickSettleDeleteStaff,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.staffs-member-condition {
  .row-1 {
    @include band;
    padding: 0 3rem;
    font-size: 1.2rem;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.staffs-invite {
  margin-top: 40px;
  padding: 30px;
  background-color: #f2f2f2;
}
.staffs-invite_ttl {
  margin-bottom: 30px;
  font-size: 1.4rem;
  font-weight: bold;
}
.staffs-invite_form {
  width: auto;
  align-items: flex-end;
  display: flex;
  .btn {
    padding: 12px 15px;
  }
}
.staffs-invite_form_item {
  margin-right: 20px;
  position: relative;
  label {
    display: block;
    margin-bottom: 10px;
  }
  input {
    width: 350px;
    height: 40px;
    padding: 0.6rem;
    border-radius: 0.4rem;
    border: 1px solid $border_color;
    font-size: 1.4rem;
    background: $white;
  }
  select {
    width: 160px;
    height: 40px;
    padding: 0.6rem;
    border-radius: 0.4rem;
    border: 1px solid $border_color;
    font-size: 1.4rem;
    background: $white;
  }
  .fa-warning {
    color: #c31e1e;
    padding-top: 4px;
    font-size: 1.2rem;
    position: absolute;
    bottom: -1.4rem;
  }
  .fa {
    display: block;
  }
}

input:focus,
select:focus {
  border: 1px green solid;
}

@media (max-width: ($media_query_tablet)) {
  .staffs-invite_ttl {
    margin-bottom: 30px;
  }
  .staffs-invite_form {
    display: block;
  }
  .staffs-invite_form_item {
    margin: 0 0 20px 0;
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
    &.item-btn {
      margin-bottom: 0;
      text-align: center;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .staffs-member_list {
    overflow-x: visible;
    white-space: normal;
  }
  .staffs-invite {
    margin-top: 0;
    padding: 20px;
  }
  .staffs-invite_ttl {
    margin-bottom: 20px;
  }
}
</style>
