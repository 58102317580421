export default {
  namespaced: true,
  state: {
    createAnnounceTargetApplicants: [],
  },
  getters: {
    createAnnounceTargetApplicants: state =>
      state.createAnnounceTargetApplicants,
  },
  mutations: {
    SET_CREATE_ANNOUNCE_TARGET_APPLICANTS(
      state,
      createAnnounceTargetApplicants,
    ) {
      state.createAnnounceTargetApplicants = createAnnounceTargetApplicants;
    },
  },
  actions: {
    SET_CREATE_ANNOUNCE_TARGET_APPLICANTS(
      { commit },
      createAnnounceTargetApplicants,
    ) {
      commit(
        'SET_CREATE_ANNOUNCE_TARGET_APPLICANTS',
        createAnnounceTargetApplicants,
      );
    },
  },
};
