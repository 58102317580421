<template>
  <li
    class="row-inner reminder_password_col"
    :class="{
      'is-error_linked_google':
        isEditSelection === true &&
        isConnectedGoogleCalendar === true &&
        timetable.isGoogleSyncError === true,
    }"
  >
    <!-- 開催時間編集画面項目（Google登録済みを外部スタッフが編集時のみ、編集できないので表示画面を表示） -->
    <!-- Googleカレンダーステータス表示 -->
    <div
      v-if="
        isGoogleMember === true ||
        (isGoogleMember === false && timetable.hasGoogleEventId === true)
      "
      class="row-inner-upper"
    >
      <!-- Googleカレンダー登録した開催時間かつ、外部スタッフの場合は編集画面にならないので除外する -->
      <template v-if="isConnectedGoogleCalendar === true">
        <div class="check-google_calendar">
          <p
            v-if="timetable.hasGoogleEventId === true"
            class="google_edit_status"
          >
            ※すでにGoogleカレンダーに登録済みです
          </p>
          <p
            v-else-if="timetable.isGoogleSyncError === true"
            class="google_edit_status"
          >
            ※カレンダーの登録に失敗しています。
          </p>
          <label v-else>
            <input
              v-model="timetable.isCreateGoogleCalendar"
              class="setting_input active"
              type="checkbox"
              @input="
                updateTimetableDataByIndexInVenueData(
                  iVenue,
                  iTimetable,
                  'isCreateGoogleCalendar',
                  !timetable.isCreateGoogleCalendar,
                )
              "
            />
            <span>Googleカレンダーに登録</span>
          </label>
        </div>
      </template>
    </div>
    <!-- 開催時間・参加人数・出席確認 -->
    <div class="row-inner-upper">
      <div class="venue-block time-block is-edit">
        <p class="title is-required">開催時間</p>
        <!-- 参加予定の応募者がいる場合、開催時間は編集できない（Google登録済み、かつ外部スタッフの場合も編集不可） -->
        <div
          v-if="timetable.actual !== 0 && timetable.actual !== null"
          class="value"
        >
          <p>
            {{ $utils.parseTime(timetable.start) }}
            〜
            {{ $utils.parseTime(timetable.last) }}
          </p>
        </div>
        <div v-else class="value value-timetable">
          <input
            v-model="timetable.start"
            class="value-input time"
            type="time"
            step="300"
            :class="{ 'is-danger': errors.start.isValid !== true }"
            @input="validateOnInput('start', timetable.start, validateRequire)"
          />
          <span class="timetable_term">〜</span>
          <input
            v-model="timetable.last"
            class="value-input time"
            type="time"
            step="300"
            :class="{ 'is-danger': errors.last.isValid !== true }"
            @input="validateOnInput('last', timetable.last, validateRequire)"
          />
          <p
            v-if="errors.start.isValid !== true || errors.last.isValid !== true"
            class="fa-warning font-s"
          >
            開催時間は必須項目です
          </p>
        </div>
      </div>
      <div class="venue-block limit-block is-edit">
        <p class="title is-required">参加人数</p>
        <div class="value value-timetable input-limit">
          {{ timetable.actual }}/
          <input
            v-model="timetable.limit"
            class="value-input"
            type="number"
            min="1"
            max="30000"
            :class="{ 'is-danger': errors.limit.isValid !== true }"
            @input="
              validateOnInput('limit', timetable.limit, validateTimetableLimit)
            "
          />
          <p
            v-if="errors.limit.isValid !== true"
            class="fa-warning font-s limit_check_section-error"
          >
            {{ errors.limit.message }}
          </p>
        </div>
      </div>
      <div class="venue-block password-block is-edit">
        <p class="title">出席確認</p>
        <div class="value input_reminder_check">
          <div>
            <label>
              <input
                v-model="timetable.reminderCheck"
                class="setting_input active"
                type="checkbox"
                @input="updateReminderLocal"
              />
              <p>確認する</p>
            </label>
            <input
              v-model="timetable.reminderPassword"
              :disabled="timetable.reminderCheck !== true"
              type="text"
              placeholder="パスワード(英数字のみ)"
              maxlength="8"
              class="reminder-password"
              :class="[
                errors.reminderPassword.isValid !== true &&
                timetable.reminderCheck
                  ? 'is-danger'
                  : '',
                timetable.reminderCheck ? 'thin_color' : '',
              ]"
              @input="
                updateTimetableDataByIndexInVenueData(
                  iVenue,
                  iTimetable,
                  'reminderPassword',
                  $event.target.value,
                )
              "
            />
          </div>
          <p
            v-if="
              errors.reminderPassword.isValid !== true &&
              timetable.reminderCheck
            "
            class="fa-warning font-s input_reminder_check-error"
          >
            {{ errors.reminderPassword.message }}
          </p>
        </div>
      </div>
    </div>
    <!-- 選考担当者・社内施設割り当て -->
    <div class="row-inner-upper">
      <div
        class="venue-block select_staff"
        :class="{
          'has-googleResources':
            isGoogleMember === true && googleResources.length > 0,
        }"
      >
        <p class="title">選考担当割り当て</p>
        <div class="select_staff_inner">
          <div>
            <p>選考担当者（最大{{ ASSIGNMENT_LIMIT_COUNT }}人まで）</p>
            <div @click.stop>
              <div
                class="multiple_selector"
                @click="toggleOpenMultiStaffSelector"
              >
                {{
                  timetable.selectStaffs
                    ? timetable.selectStaffs
                        .filter(x => x.selected)
                        .map(x => x.name)
                        .join(', ')
                    : ''
                }}
              </div>
              <float-context-menu
                :is-view="timetable.isOpenMultiStaffSelector"
                :list="timetable.selectStaffs"
                :is-multiple-select="true"
                @onClose="toggleOpenMultiStaffSelector"
                @onSelected="updateSelectStaffsLocal($event)"
              />
            </div>
          </div>
          <div v-if="isGoogleMember === true && googleResources.length > 0">
            <p>社内施設（最大{{ RESOURCE_LIMIT_COUNT }}つまで）</p>
            <div @click.stop>
              <div
                class="multiple_selector"
                @click="toggleOpenMultiResourceSelector"
              >
                {{
                  timetable.selectResources
                    ? timetable.selectResources
                        .filter(x => x.selected)
                        .map(x => x.name)
                        .join(', ')
                    : ''
                }}
              </div>
              <float-context-menu
                :is-view="timetable.isOpenMultiResourceSelector"
                :list="timetable.selectResources"
                :is-multiple-select="true"
                @onClose="toggleOpenMultiResourceSelector"
                @onSelected="updateSelectedResourcesLocal($event)"
              />
            </div>
          </div>
          <button
            class="btn btn-availability"
            style="margin-top: 10px"
            @click="openCalendar"
          >
            予定参照
          </button>
        </div>
        <modal-calendar
          v-if="isOpenCalendarList[iTimetable] === true"
          :is-view="isOpenCalendarList[iTimetable] === true"
          :initial-date="venue.date"
          :initial-staff-ids="
            timetable.selectStaffs
              .filter(tmpStaff => tmpStaff.selected)
              .map(tmpStaff => tmpStaff.id)
          "
          :initial-resource-ids="
            timetable.selectResources
              .filter(resource => resource.selected)
              .map(resource => resource.id)
          "
          :highlight-start-time="timetable.start"
          :highlight-last-time="timetable.last"
          @onClickCancel="closeAllModalCalendar"
          @onClickSubmit="updateCalendarStaffsLocal($event)"
        />
      </div>
    </div>

    <!-- 参加予定の応募者名（開催時間表示・編集画面共通項目） -->
    <div class="row-inner-upper has-delete">
      <div class="venue-assignment_wrapper">
        <!-- 参加予定の応募者名は選考編集画面のみ表示 -->
        <div
          v-if="isEditSelection === true"
          class="venue-block venue-assignment"
        >
          <p class="title">参加予定の応募者名</p>
          <div v-if="timetable.applicantNames.length === 0" class="value_gray">
            <p>参加予定の応募者はいません</p>
          </div>
          <div v-else class="value_applicant">
            <div
              class="applicant_box"
              :class="{
                applicant_box: true,
                'is-open':
                  timetable.applicantNames.length <= APPLICANTS_LIMIT_COUNT ||
                  isOpenApplicantList === true,
              }"
            >
              <span
                v-for="(appName, iName) in timetable.applicantNames"
                :key="iName"
                class="applicant_name"
                @click="openApplicantDetail(appName.id)"
              >
                {{ appName.name }}
              </span>
            </div>
          </div>
          <div
            v-if="timetable.applicantNames.length > APPLICANTS_LIMIT_COUNT"
            class="btn_applicant_list"
          >
            <button
              v-if="isOpenApplicantList === false"
              class="btn_more"
              @click="isOpenApplicantList = true"
            >
              続きを見る
            </button>
            <button
              v-else
              class="btn_more"
              @click="isOpenApplicantList = false"
            >
              元に戻す
            </button>
          </div>
        </div>
        <!-- Zoom IDが既に存在するもしくはZoom連携済みの場合 -->
        <div
          v-if="enableZoom === true || timetable.zoomId"
          class="web_meeting_area"
        >
          <!-- Zoomの削除（Zoom IDが既に存在するもしくはZoom連携済みの表示） -->
          <div class="web_meeting_area-title">
            <p>Web会議室の設定</p>
            <div v-if="timetable.zoomId" class="btn_delete">
              <button @click="deleteWebMeeting">Web会議室を削除</button>
            </div>
          </div>
          <!-- Zoomのミーティング作成済みの場合 -->
          <div v-if="timetable.zoomId" class="zoom_status">
            <dl class="is-check">
              <dt>システム</dt>
              <dd>Zoomを使用</dd>
            </dl>
            <dl>
              <dt>ホスト</dt>
              <dd>
                {{ timetable.zoomHostName ? timetable.zoomHostName : '' }}
              </dd>
            </dl>
            <dl>
              <dt>ミーティングID</dt>
              <dd>{{ timetable.zoomId }}</dd>
            </dl>
          </div>
          <!-- Zoom連携済みかつZoomミーティング更新エラーの場合のエラー文言 -->
          <p
            v-else-if="
              enableZoom === true && timetable.isZoomSyncError === true
            "
            class="google_edit_status"
          >
            ※Zoomミーティング作成に失敗しています。
          </p>
          <!-- Zoom連携済みかつZoomミーティング未作成の場合のチェックボックスとホスト選択select -->
          <ul v-else-if="enableZoom === true">
            <li>
              <label>
                <input
                  v-model="timetable.isCreateZoomUrl"
                  class="setting_input active"
                  type="checkbox"
                  @input="
                    updateTimetableDataByIndexInVenueData(
                      iVenue,
                      iTimetable,
                      'isCreateZoomUrl',
                      !timetable.isCreateZoomUrl,
                    )
                  "
                />
                <span>Zoomを使用</span>
              </label>
              <dl v-if="timetable.isCreateZoomUrl === true">
                <dt class="web_meeting_area-head">ホスト</dt>
                <dd @click.stop>
                  <div
                    class="multiple_selector"
                    :class="{ 'is-danger': errors.zoomHost.isValid !== true }"
                    @click="toggleOpenMultiZoomHostSelector"
                  >
                    {{
                      timetable.selectZoomHosts &&
                      timetable.selectZoomHosts.find(host => host.selected)
                        ? timetable.selectZoomHosts.find(host => host.selected)
                            .name
                        : ''
                    }}
                  </div>
                  <float-context-menu
                    :is-view="timetable.isOpenMultiZoomHostSelector"
                    :list="timetable.selectZoomHosts"
                    :is-multiple-select="false"
                    @onClose="toggleOpenMultiZoomHostSelector"
                    @onSelected="updateSelectZoomHostsLocal($event)"
                  />
                  <p
                    v-if="
                      errors.zoomHost.isValid !== true ||
                      errors.zoomHost.isValid !== true
                    "
                    class="fa-warning font-s"
                  >
                    Zoomホストは必須項目です
                  </p>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
      <!-- 開催時間が2つ以上場合のみ表示（Google登録済み・登録エラーの場合に社外スタッフは非表示） -->
      <div v-if="venue.timetables.length > 1" class="btn-delete-timetable">
        <!-- 開催時間新規作成の場合、API問合せしない -->
        <div
          v-if="!timetable.id"
          class="btn btn-unavailable"
          @click="deleteTimetableLocal"
        >
          <img class="icon" src="@/assets/img/delete_white.svg" alt />
          削除
        </div>
        <!-- API問合せ（参加予定の応募者がいる場合は非表示） -->
        <div
          v-else-if="timetable.actual === 0"
          class="btn btn-unavailable"
          @click="openDeleteTimetableModal"
        >
          <img class="icon" src="@/assets/img/delete_white.svg" alt />
          削除
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { onBeforeMount, computed, defineComponent, ref, nextTick } from 'vue';
import { useStore } from 'vuex';

import assignment from '@/components/features/selectionDetailBody/defines/assignment';
import useValidation from '@/composables/useValidation';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import ModalCalendar from '@/components/features/selectionDetailBody/components/ModalCalendar';

export default defineComponent({
  name: 'SelectionsVenueTimetableEdit',
  components: { FloatContextMenu, ModalCalendar },
  props: {
    iVenue: {
      type: Number,
      required: true,
    },
    iTimetable: {
      type: Number,
      required: true,
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    googleResources: {
      type: Array,
      default: () => [],
    },
    isConnectedGoogleCalendar: {
      type: Boolean,
      default: false,
    },
    isEditSelection: {
      type: Boolean,
      default: false,
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    selectionVenues: {
      type: Array,
      default: () => [],
    },
    updateVenueDataByKey: {
      type: Function,
      default: () => {},
    },
    deleteTimetable: {
      type: Function,
      default: () => {},
    },
    updateTimetableDataByIndexInVenueData: {
      type: Function,
      default: () => {},
    },
    deleteZoomMeeting: { type: Function, default: () => {} },
  },
  emits: ['fetchAll'],
  setup(props, context) {
    const {
      errors,
      resetErrors,
      resetError,
      validateOnInput,
      validateRequire,
      validateTimetableLimit,
      validateTimetableReminderPassword,
    } = useValidation([
      'start',
      'last',
      'limit',
      'reminderPassword',
      'zoomHost',
    ]);
    const store = useStore();
    const isLoading = ref(false);
    const isOpenCalendarList = ref([]);
    const isOpenApplicantList = ref(false);

    // computed
    const isGoogleMember = computed(() => {
      return store.getters['staff/staff']
        ? store.getters['staff/staff'].is_google_member
        : false;
    });
    const venue = computed(() => props.selectionVenues[props.iVenue]);
    const timetable = computed(() => venue.value.timetables[props.iTimetable]);

    // methods
    // 開催時間の削除(APIリクエスト)
    const openDeleteTimetableModal = () => {
      if (venue.value.timetables.length === 1) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '開催時間は最低１つは必要です',
          type: false,
        });
        return;
      }
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: 'この開催時間を削除します',
        message:
          '削除された開催時間は復元できません。この開催時間を削除してよろしいですか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async ({ choice }) => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          if (choice !== 2) return;
          await props.deleteTimetable(props.iVenue, props.iTimetable, store);
        },
        onclickOuter: () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    // Web会議室を削除
    const deleteWebMeeting = () => {
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: 'Web会議室を削除します',
        message:
          '削除されたWeb会議室は復元できません。このWeb会議室を削除してよろしいですか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async ({ choice }) => {
          if (choice !== 2) {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            return;
          }
          if (isLoading.value === true) return;
          isLoading.value = true;
          const res = await props.deleteZoomMeeting(
            venue.value.id,
            timetable.value.id,
          );
          if (res.success === true) context.emit('fetchAll');
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message:
              res.zoom_error_messages && res.zoom_error_messages.length > 0
                ? res.zoom_error_messages[0]
                : res.message,
            type:
              res.zoom_error_messages && res.zoom_error_messages.length > 0
                ? false
                : res.success,
          });
          isLoading.value = false;
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        onclickOuter: () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    // 開催時間の削除（ローカル処理）
    const deleteTimetableLocal = () => {
      if (venue.value.timetables.length === 1) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '開催時間は最低１つは必要です',
          type: false,
        });
        return;
      }
      const timetables = Object.assign([], venue.value.timetables);
      timetables.splice(props.iTimetable, 1);
      props.updateVenueDataByKey(props.iVenue, 'timetables', timetables);
    };
    // 出席確認するチェック時処理
    const updateReminderLocal = () => {
      props.updateTimetableDataByIndexInVenueData(
        props.iVenue,
        props.iTimetable,
        'reminderCheck',
        !timetable.value.reminderCheck,
      );
      if (timetable.value.reminderCheck) timetable.value.reminderPassword = '';
    };
    // 開催時間へ担当者選択モーダルで、確定押下時の処理
    const updateSelectStaffsLocal = event => {
      if (
        timetable.value.selectStaffs.filter(x => x.selected).length >=
          assignment.ASSIGNMENT_LIMIT_COUNT &&
        event.item.selected === false
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `選択できるのは${assignment.ASSIGNMENT_LIMIT_COUNT}名までです`,
          type: false,
        });
        return;
      }
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((timetable, i) => {
          if (i === props.iTimetable) {
            timetable.selectStaffs = timetable.selectStaffs.map(tmpStaff => {
              if (tmpStaff.id === event.item.id) {
                tmpStaff.selected = !tmpStaff.selected;
              }
              return tmpStaff;
            });
          }
          return timetable;
        }),
      );
    };
    // 開催時間へ社内施設選択モーダルで、確定押下時の処理
    const updateSelectedResourcesLocal = event => {
      if (
        timetable.value.selectResources.filter(x => x.selected).length >=
          assignment.RESOURCE_LIMIT_COUNT &&
        event.item.selected === false
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `選択できる社内施設は${assignment.RESOURCE_LIMIT_COUNT}つまでです`,
          type: false,
        });
        return;
      }
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((tmpTimetable, i) => {
          if (i === props.iTimetable) {
            tmpTimetable.selectResources = tmpTimetable.selectResources.map(
              resource => {
                if (resource.id === event.item.id) {
                  resource.selected = !resource.selected;
                }
                return resource;
              },
            );
          }
          return tmpTimetable;
        }),
      );
    };
    // 開催時間へ担当者選択モーダルで、確定押下時の処理
    const updateSelectZoomHostsLocal = event => {
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((tmpTimetable, i) => {
          if (i === props.iTimetable) {
            tmpTimetable.selectZoomHosts = tmpTimetable.selectZoomHosts.map(
              host => {
                host.selected =
                  host.id === event.item.id ? !host.selected : false;
                return host;
              },
            );
          }
          return tmpTimetable;
        }),
      );
      nextTick(() => validateZoomHost());
    };
    // 選考担当の選択処理
    const updateCalendarStaffsLocal = ({
      selectedStaffIds,
      selectedResourceIds,
    }) => {
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((tmpTimetable, i) => {
          if (props.iTimetable === i) {
            tmpTimetable.selectStaffs = tmpTimetable.selectStaffs.map(
              tmpStaff => {
                tmpStaff.selected = selectedStaffIds.includes(tmpStaff.id);
                return tmpStaff;
              },
            );
            tmpTimetable.selectResources = tmpTimetable.selectResources.map(
              resource => {
                resource.selected = selectedResourceIds.includes(resource.id);
                return resource;
              },
            );
          }
          return tmpTimetable;
        }),
      );
      closeAllModalCalendar();
    };
    // 開催時間へ担当者割り当てモーダル処理
    const toggleOpenMultiStaffSelector = () => {
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((tmpTimetable, i) => {
          if (i === props.iTimetable) {
            tmpTimetable.isOpenMultiStaffSelector =
              !tmpTimetable.isOpenMultiStaffSelector;
          }
          return tmpTimetable;
        }),
      );
    };
    // 開催時間へ社内施設割り当てモーダル処理
    const toggleOpenMultiResourceSelector = () => {
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((tmpTimetable, i) => {
          if (i === props.iTimetable) {
            tmpTimetable.isOpenMultiResourceSelector =
              !tmpTimetable.isOpenMultiResourceSelector;
          }
          return tmpTimetable;
        }),
      );
    };
    // Zoomホスト選択モーダル処理
    const toggleOpenMultiZoomHostSelector = () => {
      props.updateVenueDataByKey(
        props.iVenue,
        'timetables',
        venue.value.timetables.map((tmpTimetable, i) => {
          if (i === props.iTimetable) {
            tmpTimetable.isOpenMultiZoomHostSelector =
              !tmpTimetable.isOpenMultiZoomHostSelector;
          }
          return tmpTimetable;
        }),
      );
    };
    // 予定参照モーダルを開く
    const openCalendar = () => {
      isOpenCalendarList.value = isOpenCalendarList.value.map(
        (x, i) => i === props.iTimetable,
      );
    };
    // 予定参照モーダルを全て閉じる
    const closeAllModalCalendar = () => {
      isOpenCalendarList.value = isOpenCalendarList.value.map(x => false);
    };
    // 応募者詳細画面へ遷移
    const openApplicantDetail = applicantId => {
      window.open(`/applicants/${applicantId}?menu=0`, '_blank');
    };
    const validateZoomHost = () => {
      // 親のコンポーネントから呼ばれるので注意
      resetError('zoomHost');
      return validateRequire(
        'zoomHost',
        timetable.value.selectZoomHosts.find(v => v.selected === true),
      );
    };
    const validateTimetable = () => {
      // 親のコンポーネントから呼ばれるので注意
      resetErrors();
      const isValidStart = validateRequire('start', timetable.value.start);
      const isValidLast = validateRequire('last', timetable.value.last);
      const isValidLimit = validateTimetableLimit(
        'limit',
        timetable.value.limit,
      );
      const isValidReminderCheck =
        !timetable.value.reminderCheck ||
        (timetable.value.reminderCheck &&
          validateTimetableReminderPassword(
            'reminderPassword',
            timetable.value.reminderPassword,
          ) === true);
      const isValidZoom =
        timetable.value.isCreateZoomUrl !== true ||
        (timetable.value.isCreateZoomUrl === true &&
          validateZoomHost() === true);
      return (
        isValidStart === true &&
        isValidLast === true &&
        isValidLimit === true &&
        isValidReminderCheck === true &&
        isValidZoom === true
      );
    };

    // lifecycle
    onBeforeMount(
      () => (isOpenCalendarList.value = venue.value.timetables.map(v => false)),
    );

    return {
      ASSIGNMENT_LIMIT_COUNT: assignment.ASSIGNMENT_LIMIT_COUNT,
      RESOURCE_LIMIT_COUNT: assignment.RESOURCE_LIMIT_COUNT,
      APPLICANTS_LIMIT_COUNT: 10,
      errors,
      isOpenCalendarList,
      isOpenApplicantList,
      isGoogleMember,
      venue,
      timetable,
      validateOnInput,
      validateRequire,
      validateTimetableLimit,
      validateTimetableReminderPassword,
      openDeleteTimetableModal,
      deleteWebMeeting,
      deleteTimetableLocal,
      updateReminderLocal,
      updateSelectStaffsLocal,
      updateSelectedResourcesLocal,
      updateSelectZoomHostsLocal,
      updateCalendarStaffsLocal,
      toggleOpenMultiStaffSelector,
      toggleOpenMultiResourceSelector,
      toggleOpenMultiZoomHostSelector,
      openCalendar,
      closeAllModalCalendar,
      openApplicantDetail,
      validateZoomHost,
      validateTimetable,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/components/features/selectionDetailBody/assets/selections-timetable.scss';
</style>
