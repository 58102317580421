<template>
  <div>
    <table class="table-typeA">
      <thead class="thead">
        <tr class="row">
          <th
            v-for="(col, iCol) in staffsListColumns"
            v-show="isAdmin"
            :key="iCol"
            class="item-th"
          >
            {{ col.name_jp }}
          </th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr v-for="staff in staffs" :key="staff.id" class="row">
          <td
            v-for="(col, iCol) in staffsListColumns"
            v-show="!staff.is_delete"
            :key="iCol"
            class="item-td"
            :data-label="['名前', 'メールアドレス', '権限', '削除'][iCol]"
          >
            <p>
              <span
                v-for="(relation, iRelation) in col.relation"
                :key="iRelation"
              >
                <admin-edit-staff-email
                  v-if="relation === 'email'"
                  :staff="staff"
                  :is-admin="isAdmin"
                  :company-id="companyId"
                  @commitedEmailChange="$emit('commitedEmailChange', $event)"
                />
                <admin-edit-staff-role
                  v-else-if="relation === 'role.description' && isAdmin"
                  :staff="staff"
                  :is-admin="isAdmin"
                  @commitedRoleChange="$emit('commitedRoleChange', $event)"
                />
                <admin-delete-staff
                  v-else-if="relation === 'delete' && isAdmin"
                  :staff="staff"
                  :is-admin="isAdmin"
                  @onClickSettleDeleteStaff="
                    $emit('onClickSettleDeleteStaff', $event)
                  "
                />
                <span v-else class="value">
                  {{ $utils.getValueFromNestedObject(staff, relation) }}
                </span>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import AdminEditStaffRole from '@/components/page/admin/components/AdminEditStaffRole.vue';
import AdminEditStaffEmail from '@/components/page/admin/components/AdminEditStaffEmail.vue';
import AdminDeleteStaff from '@/components/page/admin/components/AdminDeleteStaff.vue';
import { staffsListColumns } from '@/defines/staff';

export default defineComponent({
  name: 'AdminListViewStaffs',
  components: { AdminEditStaffEmail, AdminEditStaffRole, AdminDeleteStaff },
  props: {
    staffs: {
      type: Array,
      default: () => [],
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'commitedEmailChange',
    'commitedRoleChange',
    'onClickSettleDeleteStaff',
  ],
  setup(props, context) {
    return { staffsListColumns };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.table-typeA {
  font-size: 1.4rem;
}

@media (max-width: ($media_query_sp)) {
  .table-typeA {
    font-size: 1rem;
    .tbody {
      .row {
        position: relative;
        margin-bottom: 79px;
        border-bottom: 1px solid #d1d1d1;
      }
      .row:first-child {
        margin-bottom: 30px;
      }
      .item-td:not(.item-btns):before {
        min-width: 110px;
      }
      .edit-text {
        display: block;
      }
      .item-td:nth-child(4) {
        display: none;
      }
    }
  }
}
</style>
