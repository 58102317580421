import { ref, computed } from 'vue';
import cloneDeep from 'lodash.clonedeep';

export function useSearchApplicantTab() {
  /**
   * 最大タブ数
   */
  const MAX_SEARCH_TAB_COUNT = 3;
  /**
   * タブ切り替え中グフラグ
   */
  const isLoadingTab = ref(false);
  /**
   * プレビュー画面フラグ
   */
  const isOpenPreviewApplicantList = ref(false);
  /**
   * タブデータ配列（タブ毎に検索条件、応募者/LINE友達、選択応募者配列、選択LINE友達配列、選択不可応募者配列などを管理）
   */
  const searchTabs = ref([
    {
      id: 1,
      name: '検索',
      isSelected: true,
      searchFilter: {},
      activated: true,
      selectedRegisteredApplicants: [],
      selectedLineFriends: [],
      disabledApplicantIds: [],
    },
  ]);

  // computed
  /**
   * 選択中のタブ
   */
  const currentTab = computed(() => {
    return searchTabs.value.find(tab => tab.isSelected === true) || {};
  });
  /**
   * 全タブの全ての応募者配列
   */
  const selectedAllRegisteredApplicants = computed(() => {
    return searchTabs.value.map(tab => tab.selectedRegisteredApplicants).flat();
  });
  /**
   * 全タブの全ての選択済みLINE友達ID配列
   */
  const selectedAllLineFriends = computed(() => {
    return searchTabs.value.map(tab => tab.selectedLineFriends).flat();
  });
  /**
   * 全タブの全ての選択済み応募者・LINE友達ID配列
   */
  const allSelectedApplicantIds = computed(() => {
    return [
      ...new Set(
        selectedAllRegisteredApplicants.value
          .concat(selectedAllLineFriends.value)
          .map(a => a.id),
      ),
    ];
  });

  // methods
  /**
   * 現在のタブの選択応募者をsearchTabsに保存
   * @param {number} searchTabId searchTab ID
   * @param {{ selectedRegisteredApplicants: Object[], selectedLineFriends: Object[] }} payload 選択応募者配列オブジェクト
   */
  const updateSelectedApplicantsInSearchTab = (searchTabId, payload) => {
    searchTabs.value = searchTabs.value.map(searchTab => {
      if (searchTab.id === searchTabId) {
        const newSearchTab = { ...searchTab };
        if (currentTab.value.activated === true) {
          // 応募者の場合
          newSearchTab.selectedRegisteredApplicants =
            payload.selectedRegisteredApplicants;
        } else {
          // LINE友達の場合
          newSearchTab.selectedLineFriends = payload.selectedLineFriends;
        }
        return newSearchTab;
      }
      return searchTab;
    });
    updateAllDisabledApplicantIds();
  };
  /**
   * 全タブの選択不可応募者を更新
   * @param {number} tabId タブID
   */
  const updateAllDisabledApplicantIds = () => {
    searchTabs.value = searchTabs.value.map(tab => {
      return cloneDeep({
        ...tab,
        disabledApplicantIds: allSelectedApplicantIds.value.filter(id => {
          const allIdsInTab = tab.selectedRegisteredApplicants
            .concat(tab.selectedLineFriends)
            .map(a => a.id);
          return !allIdsInTab.includes(id);
        }),
      });
    });
  };
  /**
   * 対象タブの選択応募者のリセット
   * @param {number} tabId タブID
   */
  const resetSelectedApplicants = tabId => {
    // 対象タブの選択済み応募者を全てリセット
    searchTabs.value = searchTabs.value.map(tab => {
      if (tab.id === tabId) {
        return {
          ...tab,
          selectedRegisteredApplicants: [],
          selectedLineFriends: [],
        };
      }
      return tab;
    });
    updateAllDisabledApplicantIds();
  };
  /**
   * 現在のタブの追加
   */
  const addSearchTab = () => {
    if (searchTabs.value.length >= MAX_SEARCH_TAB_COUNT) return;
    const nextId = searchTabs.value[searchTabs.value.length - 1].id + 1;
    // タブ追加
    searchTabs.value = searchTabs.value.concat([
      {
        id: nextId,
        name: '検索',
        isSelected: false,
        activated: true,
        searchFilter: {},
        selectedRegisteredApplicants: [],
        selectedLineFriends: [],
        // 現在選択中の応募者は全て選択不能にする
        disabledApplicantIds: allSelectedApplicantIds.value,
      },
    ]);
    // 新規タブを選択状態に変更
    changeTab(nextId);
  };
  /**
   * 現在のタブの削除
   * @param {number|null} tabId タブID
   */
  const deleteSearchTab = tabId => {
    if (searchTabs.value.length < 1) return;
    // タブ削除
    searchTabs.value = searchTabs.value.filter(tab => tab.id !== tabId);
    updateAllDisabledApplicantIds();
    // 選択済みタブが存在すればそのまま、なければ先頭のタブを選択済みに変更
    changeTab(currentTab.value ? currentTab.value.id : null);
  };
  /**
   * 現在のタブの切り替え
   * @param {number|null} tabId タブID
   */
  const changeTab = (tabId = null) => {
    if (isLoadingTab.value === true) return;
    isLoadingTab.value = true;
    // タブ切り替え
    if (tabId === null) {
      // 選択タブがない場合、先頭タブを選択タブに変更
      searchTabs.value = searchTabs.value.map((tab, i) => ({
        ...tab,
        isSelected: i === 0,
      }));
    } else {
      searchTabs.value = searchTabs.value.map(tab => ({
        ...tab,
        isSelected: tab.id === tabId,
      }));
    }
    isLoadingTab.value = false;
  };
  /**
   * 検索条件の保存
   * @param {Object|null} args 検索条件パラメータオブジェクト
   */
  const saveSearchFilterObject = (args = null) => {
    searchTabs.value = searchTabs.value.map(tab => {
      if (tab.isSelected === true) {
        return {
          ...tab,
          searchFilter: args ? args : null,
        };
      }
      return tab;
    });
  };
  /**
   * 応募者/LINE友達の変更処理
   * @param {boolean} activated 応募者かどうかのフラグ
   */
  const onChangeActivated = activated => {
    // 応募者/LINE友達の変更をタブ毎に保持
    searchTabs.value = searchTabs.value.map(tab => {
      if (tab.isSelected === true) return { ...tab, activated };
      return tab;
    });
  };
  /**
   * プレビューモーダルを開く
   * @param {Object} store storeオブジェクト
   * @param {number} sendLimit 送信制限人数
   */
  const openPreviewApplicantListModal = (store, sendLimit) => {
    // 現在のタブで一時的に変更された選択応募者をsearchTabsに統合
    if (
      selectedAllRegisteredApplicants.value.length === 0 &&
      selectedAllLineFriends.value.length === 0
    ) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        type: false,
        message: '送信先応募者を選択してください',
      });
      return;
    }
    if (
      selectedAllRegisteredApplicants.value.length +
        selectedAllLineFriends.value.length >=
      sendLimit
    ) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: `通知する人数は${sendLimit}名以内に設定してください`,
        type: false,
      });
      return;
    }
    isOpenPreviewApplicantList.value = true;
  };
  /**
   * プレビューモーダルを閉じる
   */
  const closePreviewApplicantListModal = () => {
    isOpenPreviewApplicantList.value = false;
  };
  /**
   * 選択応募者リセット確認モーダルを開く
   */
  const openResetSelectedApplicantsConfirm = (
    { callback, args },
    searchTabId,
    store,
  ) => {
    const targetTab = searchTabs.value.find(tab => tab.id === searchTabId);
    if (
      targetTab.selectedRegisteredApplicants.length === 0 &&
      targetTab.selectedLineFriends.length === 0
    ) {
      // 対象タブの応募者を1人も選択していなかった場合、警告confirmを出さないでリセット
      callback(args);
      return;
    }
    store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
      accentColor: '#1899D6',
      title: '選択中の応募者を解除して検索',
      message:
        '選択した検索タブにチェックの入っている応募者の選択は全て解除して検索します。',
      buttonText1: 'キャンセル',
      buttonText2: '応募者を解除して検索',
      isDisabledShowModalDisplayed: true,
      onSelected: async payload => {
        store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        if (payload.choice === 2) {
          callback(args);
          resetSelectedApplicants(searchTabId);
        }
      },
      onclickOuter: () => {
        store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
      },
    });
  };

  return {
    MAX_SEARCH_TAB_COUNT,
    isLoadingTab,
    isOpenPreviewApplicantList,
    searchTabs,
    // computed
    currentTab,
    selectedAllRegisteredApplicants,
    selectedAllLineFriends,
    allSelectedApplicantIds,
    // methods
    addSearchTab,
    deleteSearchTab,
    changeTab,
    updateSelectedApplicantsInSearchTab,
    saveSearchFilterObject,
    onChangeActivated,
    openPreviewApplicantListModal,
    closePreviewApplicantListModal,
    openResetSelectedApplicantsConfirm,
  };
}
