<script setup>
import { ref, onMounted } from 'vue';
import BaseTextarea from '@/components/ui/textarea/BaseTextarea.vue';
import EmojiPicker from '@/components/ui/menus/components/EmojiPicker.vue';
import CopyTag from '@/components/ui/forms/CopyTag.vue';

defineProps({
  content: {
    type: Object,
    default: () => ({
      value: '',
      errorMessage: '',
    }),
  },
  copyTags: {
    type: Array,
    default: () => [],
  },
  isForceSendEmail: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['input-content', 'blur-content']);

const textareaRef = ref(null);

const appendEmoji = emoji => {
  const textArea = textareaRef.value.$el;
  if (!textArea) return;
  const beforeText = textArea.value.substring(0, textArea.selectionStart);
  const afterText = textArea.value.substring(
    textArea.selectionStart,
    textArea.value.length,
  );
  emit('input-content', `${beforeText}${emoji}${afterText}`);
  emit('blur-content', `${beforeText}${emoji}${afterText}`);
};

const adjustHeightTextarea = () => {
  const element = textareaRef.value.$el;
  if (!element) return;
  element.style.height = 'auto';
  element.style.height = `${element.scrollHeight}px`;
};

const handleInputContent = value => {
  emit('input-content', value);
  adjustHeightTextarea();
};
onMounted(() => {
  adjustHeightTextarea();
});
</script>

<template>
  <div class="contactForm-wrapper">
    <div v-show="isForceSendEmail" class="disable-overlay">
      <div class="disable-overlay-inner">
        「全員にメールで送信」にチェックが入っているため
        <br />
        LINEの応募者にもメールで送信されます
      </div>
    </div>
    <div class="title">
      <img
        class="line_icon_svg"
        src="@/assets/img/icon_line.svg"
        alt="LINEのアイコン"
      />
      LINE
    </div>
    <div>
      <div class="desc">
        連絡方法が
        <strong>LINE</strong>
        の応募者へ届きます。
      </div>
      <div v-show="copyTags.length > 0" class="variable">
        <div class="variable-tags">
          <div v-for="tag in copyTags" :key="tag.text">
            <copy-tag :tag="tag" />
          </div>
        </div>
        <span class="variable-desc">
          ※青文字をコピーして本文中の任意の位置に挿入することで、{{
            copyTags.map(tag => tag.description).join('・')
          }}を利用することができます。
        </span>
      </div>
      <div class="message">
        <div class="message-inner">
          <div class="textbox-wrapper">
            <div class="emoji-picker-wrapper">
              <emoji-picker @selected="appendEmoji" />
            </div>
            <div class="textbox">
              <div class="caption-name">{姓・名}さん※編集できません</div>
              <base-textarea
                ref="textareaRef"
                :model-value="content.value"
                :is-error="!!content.errorMessage"
                name="lineContent"
                class="line-content"
                @input="handleInputContent($event.target.value)"
                @blur="emit('blur-content', $event.target.value)"
              />
            </div>
          </div>
          <div v-show="!!content.errorMessage" class="error-text">
            {{ content.errorMessage }}
          </div>
        </div>
      </div>
      <ul class="message-caption-lists">
        <slot>
          <li class="message-caption-list">
            ※ 内容冒頭には宛先の｛姓・名｝さんが挿入されます。
          </li>
        </slot>
        <slot name="addCaptions" />
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include contactFormStyle(line);

.emoji-picker-wrapper {
  position: absolute;
  inset: 1px 1px auto;
  z-index: 2;
  padding: 16px 18px;
  background: #f9f9f9;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 4px 4px 0 0;
}
.disable-overlay {
  position: absolute;
  inset: -1px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 70%);
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  z-index: 3;
}
.disable-overlay-inner {
  padding: 32px 48px;
  background: #f9f9f9;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: $font_12;
  text-align: center;
  line-height: 1.8;
}
</style>
