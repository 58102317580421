// このファイルはglobalに呼び出せるようになるため、使用頻度が高いutilのみを定義すること。
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

moment.updateLocale('ja', {
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
});

/**
 * DB用日時データを指定したフォーマットの日時文字列に変換して返す
 * @param {string} value - 日時データ
 * @param {string} format - 日時フォーマット 'YYYY-MM-DD HH:mm'
 * @return {string} 変換後の日時文字列
 */
const parseDateTime = (value, format) => {
  if (!value) return value !== undefined ? value : '';
  return value === '****' ? value : moment(value).format(format);
};

/**
 * DB用時間データを時間文字列に変換して返す
 * @param {string} value - 日時データ
 * @param {string} format - 任意。デフォルトは 'HH:mm'
 * @return {string} 変換後の時間文字列
 */
const parseTime = (value, format = 'HH:mm') => {
  if (!value) return value !== undefined ? value : '';
  return moment(value, 'H:mm:ss').format(format);
};

/**
 * \n を <br> に変換してsanitizeして返す
 * @param {string} text - ベースとなる文字列
 * @param {Object} sanitize-htmlのオプション
 * @return {string} 変換後の文字列
 */
const sanitize = (text, options = null) => {
  if (!options) options = { allowedTags: ['br'] };
  return sanitizeHtml(text.replace(/\n/g, '<br/>'), options);
};

/**
 * 2桁数値の頭をゼロ埋めした文字列で返す
 * @param {number} num - 対象数値
 * @return {string} ゼロ埋めした文字列
 */
const zeroPadding = num => `0${num}`.slice(-2);

/**
 * 対象文字列が文字数オーバーした場合に…を挿入し省略する
 * @param {string} text - ベースとなる文字列
 * @param {number} length - 終端から表示する文字列
 * @return {string} 変換後の文字列
 */
const textEllipsis = (value, length = 20) => {
  return value && value.length > length
    ? `${value.slice(0, length)}...`
    : value;
};

/**
 * 文字の中間を省略して…を挿入する
 * @param {string} text - ベースとなる文字列
 * @param {number} startLength - 開始から表示する文字数
 * @param {number} endLength - 終端から表示する文字列
 * @return {string} 省略した文字列
 */
const textEllipsisMiddle = (text, startLength = 25, endLength = 10) => {
  if (!text || text.length < startLength + endLength + 1) return text;
  return `${text.slice(0, startLength)}…${text.slice(
    text.length - endLength,
    text.length,
  )}`;
};

/**
 * 西暦を年齢に変換
 * @param {string} text - ベースとなる文字列
 * @return {string} 変換後の文字列
 */
const getAgeFromDateTime = value => {
  if (!value) return '';
  return value === '****' ? value : moment().diff(moment(value), 'years');
};

/**
 * 入れ子になったObjectのKey文字列からObjectのvalueを取得
 * @param nestedObject - Object（{ staff: {lastname: 'lastname'} }）
 * @param objectKey - Objectのkey文字列（staff.lastname）
 * @return {any}
 */
const getValueFromNestedObject = (nestedObject, objectKey) => {
  try {
    return objectKey
      .split('.')
      .reduce((obj, property) => obj[property], nestedObject);
  } catch (err) {
    return undefined;
  }
};

export default {
  parseDateTime,
  parseTime,
  sanitize,
  zeroPadding,
  textEllipsis,
  textEllipsisMiddle,
  getAgeFromDateTime,
  getValueFromNestedObject,
};
