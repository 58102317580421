<script setup>
import { ref, watch } from 'vue';
import moment from 'moment';
import ModalFullSize from '@/components/ui/modals/ModalFullSize.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';

import richMessageService from '@/services/richmessage';

const props = defineProps({
  isView: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['select-rich-message', 'close-modal']);

const isLoading = ref(false);
const richMessages = ref([]);

const fetchRichMessages = async () => {
  isLoading.value = true;
  const res = await richMessageService.fetchRichMessages();
  if (!res.success) {
    isLoading.value = false;
    return;
  }
  richMessages.value = res.richmessage_list;
  isLoading.value = false;
};

const selectRichMessage = richMessage => {
  emit('select-rich-message', richMessage);
};

watch(
  () => props.isView,
  newIsView => {
    if (newIsView) {
      fetchRichMessages();
    }
  },
);
</script>

<template>
  <modal-full-size :is-view="isView" @close-modal="emit('close-modal')">
    <template #header>送信するリッチメッセージの選択</template>
    <template #body>
      <div class="body-wrapper">
        <div class="body-inner">
          <div v-if="isLoading" class="page-loading">
            <LocalPageLoader />
          </div>
          <div v-else-if="!isLoading && richMessages.length === 0">
            <EmptyContent>
              <p>
                リッチメッセージがありません
                <br />
                あらかじめ「応募者へ通知」からリッチメッセージを
                <br />
                作成している必要があります
              </p>
            </EmptyContent>
          </div>
          <div v-else>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                  <th>画像</th>
                  <th>タイトル</th>
                  <th class="fit-center">分割数</th>
                  <th>更新者</th>
                  <th>更新日</th>
                </thead>
                <tbody>
                  <tr
                    v-for="richMessage in richMessages"
                    :key="richMessage.id"
                    @click="selectRichMessage(richMessage)"
                  >
                    <td class="img">
                      <img :src="`${richMessage.image_url}/240`" alt="" />
                    </td>
                    <td class="text-ellipsis">{{ richMessage.name }}</td>
                    <td class="fit-center">{{ richMessage.image_type }}</td>
                    <td>{{ richMessage.staff.full_name }}</td>
                    <td>
                      {{
                        moment(richMessage.created_at).format(
                          'YYYY-MM-DD HH:mm',
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-col">
        <button-base
          type="button"
          class="btn-cancel"
          button-type="secondary"
          button-text="キャンセル"
          @click="emit('close-modal')"
        />
      </div>
    </template>
  </modal-full-size>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.body-wrapper {
  padding: 20px;
  background-color: $white;
}
//body
.body-inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.body-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

//テーブル
.table-wrapper {
  overflow-x: auto;
  min-height: 400px;
}
.table {
  width: 100%;
  white-space: nowrap;
  font-size: $font_14;
  text-align: left;
  thead {
    background: $mochica_color_gray2;
  }
  th,
  td {
    min-width: 220px;
    &.fit-center {
      width: 0;
      min-width: unset;
      white-space: nowrap;
      text-align: center;
    }
    &.text-ellipsis {
      max-width: 20em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.img {
      img {
        max-width: 50px;
      }
    }
  }
  th {
    padding: 12px 10px;
    &:first-of-type {
      padding: 12px 10px 12px 20px;
    }
    &:last-of-type {
      padding: 12px 20px 12px 10px;
    }
  }
  td {
    padding: 18px 10px;
    &:first-of-type {
      padding: 18px 10px 18px 20px;
    }
    &:last-of-type {
      padding: 18px 20px 18px 10px;
    }
  }
  tr {
    border-bottom: 1px solid #e8e8e8;
    &:hover {
      background: #f1f1f1;
    }
  }
}
.btn-cancel {
  width: 100%;
  height: 46px;
  margin-top: 20px;
}
</style>
