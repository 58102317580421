<template>
  <div>
    <div v-if="searchFlagGroups.length > 0" class="ar-flagGrowup">
      <div
        v-for="(flagGroup, index) in searchFlagGroups"
        :key="`flagGroup_${flagGroup.id}`"
        class="ar-flagOne"
      >
        <div class="ar-flagMain">
          <p class="headline">フラググループ</p>
          <div class="group">
            <drop-down
              class="toSelect"
              :placeholder="'フラググループ名'"
              :selected-id="flagGroup.id || null"
              :list="getFlagGroups"
              :is-view="
                visibleFlagGroupIds[index] &&
                getFlagGroups.findIndex(tg => !tg.disabled) !== -1
              "
              @onOpen="openDropDownFlagGroupIds(index)"
              @onClose="closeAllFlagGroupsDropDown"
              @onSelected="onSelectedFlagGroupId($event, index)"
            />
            <search-detail-filter-option
              v-if="isDetail === true"
              :radio-list="searchFlagGroupOptions"
              :index="index"
              :update-radio-list="updateFlagGroupOption"
              unique-id="searchFlagGroup"
            />
          </div>
          <div
            :class="{ toRelease: true, 'is-detail': isDetail }"
            @click="removeLocalFlagGroupRow(index)"
          >
            選択を解除
          </div>
        </div>
        <div
          v-if="
            !searchFlagGroupOptions[index] ||
            searchFlagGroupOptions[index].selectOption === 0 ||
            searchFlagGroupOptions[index].selectOption === 1
          "
          class="ar-flagSub"
        >
          <p class="headline">フラグ</p>
          <div>
            <ul class="ar-flagLabels">
              <li
                v-for="flagId in flagGroup.flags"
                :key="`flag_${flagId}`"
                class="item"
                :class="{
                  'is-deleted': getFlagNameById(flagId, flagGroup.id)
                    ? false
                    : true,
                }"
              >
                {{ getFlagNameById(flagId, flagGroup.id) }}
              </li>
            </ul>
            <div v-if="flagGroup.id !== null">
              <div class="button_add_flag" @click="openDropDownFlagId(index)" />
              <float-context-menu
                class="float_context_menu_comp"
                :list="getFlags(flagGroup.id, index)"
                :is-view="visibleFlagIds[index]"
                :is-multiple-select="true"
                @onClose="closeAllFlagGroupsDropDown"
                @onSelected="updateFlagId($event, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleAddFlags"
      :class="{
        subBtn: true,
        'is-disabled':
          getFlagGroups.filter(tg => !tg.disabled).length === 0 ||
          searchFlagGroups.filter(tg => tg.id === null).length > 0,
      }"
      @click="addLocalFlagGroupRow"
    >
      検索に複数のフラググループを使用する
    </div>
    <div v-else>
      <span>
        フラググループは{{ MAX_FLAG_GROUPS_COUNT }}個まで検索可能です。
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

import SEARCH_CONSTS from '@/components/features/searchApplicants/defines/search';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import DropDown from '@/components/ui/menus/components/DropDown';
import SearchDetailFilterOption from '@/components/features/searchApplicants/components/SearchDetailFilterOption';

export default defineComponent({
  name: 'SearchFlagGroup',
  components: { FloatContextMenu, DropDown, SearchDetailFilterOption },
  props: {
    searchFlagGroups: {
      type: Array,
      required: true,
    },
    visibleFlagGroupIds: {
      type: Array,
      required: true,
    },
    visibleFlagIds: {
      type: Array,
      required: true,
    },
    getFlagGroups: {
      type: Array,
      required: true,
    },
    visibleAddFlags: {
      type: Boolean,
      required: true,
    },
    getFlags: {
      type: Function,
      required: true,
    },
    getFlagNameById: {
      type: Function,
      required: true,
    },
    updateFlagGroupId: {
      type: Function,
      required: true,
    },
    updateFlagId: {
      type: Function,
      required: true,
    },
    addFlagGroupRow: {
      type: Function,
      required: true,
    },
    removeFlagGroupRow: {
      type: Function,
      required: true,
    },
    openDropDownFlagGroupIds: {
      type: Function,
      required: true,
    },
    openDropDownFlagId: {
      type: Function,
      required: true,
    },
    closeAllFlagGroupsDropDown: {
      type: Function,
      required: true,
    },
    // optional
    isDetail: {
      type: Boolean,
      default: false,
    },
    searchFlagGroupOptions: {
      type: Array,
      default: () => [],
    },
    addFlagGroupOption: {
      type: Function,
      default: () => {},
    },
    removeFlagGroupOption: {
      type: Function,
      default: () => {},
    },
    updateFlagGroupOption: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, context) {
    const onSelectedFlagGroupId = (payload, i) => {
      props.updateFlagGroupId(payload, i, nextTick);
    };
    const addLocalFlagGroupRow = () => {
      props.addFlagGroupRow(props.addFlagGroupOption);
    };
    const removeLocalFlagGroupRow = i => {
      props.removeFlagGroupRow(i, props.removeFlagGroupOption);
    };
    return {
      onSelectedFlagGroupId,
      addLocalFlagGroupRow,
      removeLocalFlagGroupRow,
      MAX_FLAG_GROUPS_COUNT: SEARCH_CONSTS.MAX_FLAG_GROUPS_COUNT,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.ar-flagComponent {
  padding: 20px 0;
  margin-bottom: 40px;
  border-bottom: 1px solid #ccc;

  > .subBtn {
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
    padding: 10px 15px 10px 25px;
    cursor: pointer;
    background-image: url('../../../../assets/img/add_blue.svg');
    background-size: 10px auto;
    background-position: left 10px center;
    background-repeat: no-repeat;

    &.is-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.ar-flagMain {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;

  > .headline {
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
  }

  > .group {
    width: 48.5%;
    margin-right: 15px;
  }

  > .toSelect {
    width: 48.5%;
  }

  > .toRelease {
    color: #1899d6;
    cursor: pointer;

    &.is-detail {
      margin-bottom: 23px;
    }
  }
}

.ar-flagSub {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;

  > .headline {
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.ar-flagLabels {
  display: flex;
  flex-wrap: wrap;

  > .item {
    margin: 0 10px 10px 0;
    padding: 10px;
    border-radius: 4px;
    background-color: #ebebeb;
    &.is-deleted {
      display: none;
    }
  }
}

.flag_select {
  margin-bottom: 40px;
  dt {
    margin-bottom: 10px;
    font-weight: bold;
  }
  dd .options_btn {
    margin-left: 5px;
  }
}

.button_add_flag {
  width: 20px;
  height: 20px;
  background-image: url('../../../../assets/img/add_circle_gray.svg');
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.float_context_menu_comp {
  width: 400px;
  & :deep(.context-menu) {
    width: 100%;
    ul {
      li {
        .li-inner {
          p {
            width: 330px;
            white-space: normal;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .ar-flagMain {
    display: block;
    margin-bottom: 20px;

    > .group {
      width: 100%;
      margin: 0 0 10px 0;
    }

    > .toSelect {
      width: 100%;
    }

    > .toRelease {
      &.is-detail {
        margin-bottom: 0;
      }
    }
  }

  .ar-flagComponent {
    > .subBtn {
      padding: 10px 10px 10px 21px;
      background-position: left 8px center;
    }
  }
}
</style>
