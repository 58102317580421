<template>
  <div v-if="$store.getters['page/hasApplicationError']" class="error-wrapper">
    <div class="error-inner">
      <div class="contents">
        <p class="title">
          {{ $store.getters['page/applicationErrorInfo'].title }}
        </p>
        <!-- eslint-disable vue/no-v-html -->
        <p
          class="message"
          v-html="
            $utils.sanitize($store.getters['page/applicationErrorInfo'].message)
          "
        />
        <!--eslint-enable-->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

// APIで500エラーが発生した場合の全画面白背景のエラー画面
export default defineComponent({ name: 'ApplicationError' });
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.error-wrapper {
  position: fixed;
  z-index: 40;
  top: 0;
  left: auto;
  right: auto;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.85;
  display: block;
  color: $mochica_color;
}
.error-inner {
  width: 100%;
  height: 100%;
  position: absolute;
}
.contents {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 100px;
  text-align: center;
}
.title {
  font-size: 1.6rem;
  font-weight: 600;
  padding-bottom: 20px;
}
.message {
  letter-spacing: 0.1rem;
  text-align: left;
  padding: 0 40px 0;
  line-height: 2rem;
}
</style>
