export default {
  copyToClipboard(text) {
    // For browsers like Chrome
    let res = true;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).catch(() => {
        res = false;
        alert(`テキスト${text}のクリップボードへのコピーに失敗しました。`);
      });
    } else {
      // For IE
      const area = document.createElement('textarea');
      area.value = text;
      document.body.appendChild(area);
      area.select();
      document.execCommand('copy');
      document.body.removeChild(area);
    }
    return res;
  },
};
