<template>
  <section class="as-block">
    <div class="headline">
      <img
        width="23"
        height="26"
        class="icon"
        src="@/assets/img/senkou_white.svg"
      />
      選考のタイムライン
    </div>
    <div class="inner">
      <div class="btns-area">
        <div
          v-if="$store.getters['staff/isOperator'] !== true"
          class="btn btn-availability"
          @click="onCreateSelection"
        >
          <img class="icon" src="@/assets/img/add_white.svg" />
          新規個別選考の追加
        </div>
        <div class="btn btn-availability" @click="onSelectSelection()">
          <img class="icon" src="@/assets/img/add_white.svg" />
          既存の選考の割り当て
        </div>
      </div>
      <applicant-flow-item
        v-for="progress in progresses.committed"
        ref="flowItem"
        :key="progress.id"
        :is-committed="true"
        :progress="progress"
        :is-current-progress="progresses.currentProgressId === progress.id"
        :loading="isLoadingDeleteSelection"
        :applicant-graduated-id="applicantGraduatedId"
        @announceSkip="announceSkip"
        @deleteSelection="deleteSelection"
        @toggleReminderSetting="$emit('toggleReminderSetting', $event)"
        @toggleReminderCancel="$emit('toggleReminderCancel', $event)"
        @toggleReminderCheckSetting="
          $emit('toggleReminderCheckSetting', $event)
        "
        @toggleReminderCheckCancel="$emit('toggleReminderCheckCancel', $event)"
        @toggleMypageVisibleSetting="
          $emit('toggleMypageVisibleSetting', $event)
        "
        @toggleMypageVisibleCancel="$emit('toggleMypageVisibleCancel', $event)"
        @determineOperatorEvaluation="
          $emit('determineOperatorEvaluation', $event)
        "
        @determineManagerEvaluation="
          $emit('determineManagerEvaluation', $event)
        "
        @undoManagerEvaluation="$emit('undoManagerEvaluation', $event)"
        @applicantFetchAllApi="$emit('applicantFetchAllApi', $event)"
      />
      <applicant-flow-item
        v-for="progress in progresses.notCommitted"
        :key="progress.id"
        :is-committed="false"
        :progress="progress"
        :is-current-progress="progresses.currentProgressId === progress.id"
        :loading="isLoadingDeleteSelection"
        :applicant-graduated-id="applicantGraduatedId"
        @deleteSelection="deleteSelection"
        @emitReloadPage="$emit('reloadPage', $event)"
        @determineOperatorEvaluation="
          $emit('determineOperatorEvaluation', $event)
        "
        @determineManagerEvaluation="
          $emit('determineManagerEvaluation', $event)
        "
        @undoManagerEvaluation="$emit('undoManagerEvaluation', $event)"
        @applicantFetchAllApi="$emit('applicantFetchAllApi', $event)"
      />
    </div>
    <modal-selection-search
      :is-visible="isOpenSelectionSearch"
      :init-graduated-id="applicantGraduatedId"
      :is-just-select="true"
      :disabled-ids="selectionDisableIds"
      @onSelected="onSelectedModalSelection"
      @onClickCancel="onClickCancelSelectionSearch"
    />
    <modal-selection-detail
      :id="selectionId"
      :is-visible="isOpenSelectionDetail"
      :selection-detail-mode="selectionDetailMode"
      :init-graduated-id="applicantGraduatedId"
      :is-loading="isLoading"
      @onClickSubmit="onClickAddSelectionDetail"
      @onClickBack="onClickCancelSelectionDetail"
    />
  </section>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import selectionService from '@/services/selection';
import selectionsService from '@/services/selections';
import applicantsService from '@/services/applicants';
import progressesService from '@/services/progresses';
import ModalSelectionSearch from '@/components/page/applicants/components/ModalSelectionSearch';
import ModalSelectionDetail from '@/components/page/applicants/components/ModalSelectionDetail';
import ApplicantFlowItem from '@/components/page/applicants/_id/components/ApplicantFlowItem';
import { postPottosEvent } from '@/utils/pottos';

export default defineComponent({
  name: 'ApplicantFlow',
  components: { ApplicantFlowItem, ModalSelectionSearch, ModalSelectionDetail },
  props: {
    progresses: {
      type: Object,
      default: null,
    },
    applicantId: {
      type: Number,
      default: null,
    },
    applicantGraduatedId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'reloadPage',
    'toggleReminderSetting',
    'toggleReminderCancel',
    'toggleReminderCheckSetting',
    'toggleReminderCheckCancel',
    'toggleMypageVisibleSetting',
    'toggleMypageVisibleCancel',
    'determineOperatorEvaluation',
    'determineManagerEvaluation',
    'undoManagerEvaluation',
    'applicantFetchAllApi',
  ],
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const isLoadingDeleteSelection = ref(false);
    const isOpenSelectionAdd = ref(false);
    const isOpenSelectionSearch = ref(false);
    const isOpenSelectionDetail = ref(false);
    const selectionDetailMode = ref(1); // view: 1 | registration: 2 | edit: 3
    const selectionId = ref(null);
    const selectionDisableIds = ref([]);
    const modalSelectionDetailPottosId = ref(null);

    // computed
    const isViewModalSelectionDetail = computed(
      () =>
        (selectionId.value &&
          isOpenSelectionDetail.value &&
          selectionDetailMode.value === 1) ||
        (selectionId.value === null &&
          isOpenSelectionDetail.value &&
          selectionDetailMode.value === 2),
    );

    // methods
    // 新規個別選考の追加
    const onCreateSelection = () => {
      selectionId.value = null;
      selectionDetailMode.value = 2;
      isOpenSelectionDetail.value = true;
      modalSelectionDetailPottosId.value = 66;
    };
    // 既存の選考の割り当て
    const onSelectSelection = async () => {
      const res = await selectionService.checkDisable();
      if (res.success === true) {
        selectionDisableIds.value = res.selections;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
      isOpenSelectionSearch.value = true;
      selectionDetailMode.value = 1;
      modalSelectionDetailPottosId.value = 60;
    };
    // 選考一覧モーダルから選択時のイベント
    const onSelectedModalSelection = payload => {
      isOpenSelectionSearch.value = false;
      selectionId.value = payload.id;
      selectionDetailMode.value = 1;
      isOpenSelectionDetail.value = true;
    };
    // 選考一覧モーダルから戻る
    const onClickCancelSelectionSearch = () => {
      isOpenSelectionSearch.value = false;
    };
    // 選考詳細モーダルから選考割当て
    const onClickAddSelectionDetail = async selection => {
      postPottosEvent(modalSelectionDetailPottosId.value);
      if (selectionDetailMode.value === 2) {
        isLoading.value = true;
        // venueとtimetable毎に選択したスタッフIDの配列を作成
        const selectedStaffIds = selection.venues.map(venue =>
          venue.timetables.map(timetable =>
            timetable.selectStaffs.filter(x => x.selected).map(x => x.id),
          ),
        );
        const selectedResourceIds = selection.venues.map(venue =>
          venue.timetables.map(timetable =>
            timetable.selectResources.filter(x => x.selected).map(x => x.id),
          ),
        );
        const zoomHostIds = selection.venues.map(venue =>
          venue.timetables.map(timetable =>
            timetable.selectZoomHosts.find(x => x.selected)
              ? timetable.selectZoomHosts.find(x => x.selected).id
              : null,
          ),
        );
        const result = await selectionsService.createSelection({
          selection: selection.jsonObjCreateSelection(
            selectedStaffIds,
            selectedResourceIds,
            zoomHostIds,
          ),
        });
        if (result.success === true) {
          assignApplicants(props.applicantId, result.selection.id);
          isOpenSelectionDetail.value = false;
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: result.message,
            type: result.success,
          });
        }
        isLoading.value = false;
      } else {
        assignApplicants(props.applicantId, selectionId.value);
      }
    };
    // 選考詳細モーダルから戻る
    const onClickCancelSelectionDetail = payload => {
      if (payload === true) {
        isOpenSelectionDetail.value = false;
      } else {
        isOpenSelectionDetail.value = false;
        isOpenSelectionSearch.value = true;
      }
    };
    // 選考割当て
    const assignApplicants = async (applicantId, selectionId) => {
      const result = await progressesService.createProgress(
        selectionId,
        [applicantId],
        false,
      );
      if (result.success) {
        resetModalMode();
        context.emit('reloadPage');
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: result.message,
        type: result.success,
      });
    };
    // モーダルのリセット
    const resetModalMode = () => {
      isOpenSelectionSearch.value = false;
      isOpenSelectionDetail.value = false;
      selectionDetailMode.value = 1;
      selectionId.value = null;
    };
    // 通知せずに進む
    const announceSkip = async payload => {
      const res = await applicantsService.announceSkipApplicant(payload);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      context.emit('reloadPage');
    };
    // 選考削除
    const deleteSelection = async payload => {
      isLoadingDeleteSelection.value = true;
      const result = await applicantsService.deleteSelection(
        props.applicantId,
        payload,
      );
      isLoadingDeleteSelection.value = false;
      if (result.success) context.emit('reloadPage');
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: result.message,
        type: result.success,
      });
    };

    return {
      isOpenSelectionAdd,
      isOpenSelectionSearch,
      isOpenSelectionDetail,
      selectionDetailMode,
      selectionId,
      selectionDisableIds,
      isLoadingDeleteSelection,
      isLoading,
      modalSelectionDetailPottosId,
      isViewModalSelectionDetail,
      onCreateSelection,
      onSelectSelection,
      onSelectedModalSelection,
      onClickCancelSelectionSearch,
      onClickAddSelectionDetail,
      onClickCancelSelectionDetail,
      assignApplicants,
      resetModalMode,
      announceSkip,
      deleteSelection,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.as-block {
  margin-bottom: 20px;
  background-color: #fff;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  .inner {
    padding: 30px 20px;
  }
}

.btns-area + .flow-item-wrapper {
  margin-top: 30px;
}

@media (max-width: ($media_query_sp)) {
  .btns-area {
    .btn {
      display: block;
      text-align: left;
    }
    .btn + .btn {
      margin: 10px 0 0 0;
    }
  }
}
</style>
