<script setup>
import { useRouter, useRoute } from 'vue-router';

import AutoActionsTemplate from '@/components/features/autoActions/templates/AutoActionsTemplate.vue';
import HighLight from '@/components/ui/text/Highlight.vue';

import { ACTION_TYPES, EDIT_CONFIRM_COMPONENTS } from '@/defines/autoActions';

const route = useRoute();
const router = useRouter();

const backPageHandler = () => {
  router.push({
    name: 'AutoActionsEdit',
    params: { id: route.params.id },
  });
};

defineProps({
  formValues: {
    type: Object,
    default: () => ({}),
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['submit']);
</script>
<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <auto-actions-template
        :header-title="'編集内容の確認'"
        :back-page-title="'自動アクションの編集に戻る'"
        :on-back-page="backPageHandler"
      >
        <template #titleDescription>
          自動アクションを以下の内容で登録します。
          <br />
          内容をご確認の上、決定ボタンを押してください。
        </template>
        <template #body>
          <div class="section-body-inner">
            <section class="confirm-wrapper">
              <div class="confirm-outer">
                <div class="confirm-inner">
                  <h3 class="confirm-title">自動アクション名</h3>
                  <div class="confirm-contents text">
                    <p>
                      {{ formValues.actionName }}
                    </p>
                  </div>
                </div>
                <div class="confirm-inner">
                  <h3 class="confirm-title">自動アクションの有効/無効</h3>
                  <div class="confirm-contents text">
                    <p>
                      自動アクションを{{
                        formValues.actionStatus ? '有効' : '無効'
                      }}にする
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section
              v-if="
                formValues.actionType === ACTION_TYPES.results ||
                formValues.actionType === ACTION_TYPES.notice
              "
              class="confirm-wrapper"
            >
              <high-light :h-level="2">メール・LINEの文面</high-light>
              <div class="confirm-outer">
                <div class="confirm-inner">
                  <div class="confirm-contents elements">
                    <component
                      :is="EDIT_CONFIRM_COMPONENTS[formValues.actionType]"
                      :results="{ ...formValues.results }"
                      :notice="{ ...formValues.notice }"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </template>
        <template #footer>
          <div class="page-footer">
            <button-base
              type="button"
              class="btn-cancel"
              button-type="secondary"
              button-text="キャンセル"
              @click="backPageHandler"
            />
            <button-base
              type="submit"
              class="btn-confirm"
              :button-text="'登 録'"
              :is-loading="isSubmitting"
              @click="emit('submit')"
            />
          </div>
        </template>
      </auto-actions-template>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
@include confirmTemplate;
.page-wrapper {
  margin: 20px 0;
  padding: 0 20px;
  .page-inner {
    padding: 0 30px 30px 40px;
    background-color: #fff;
  }
}
.confirm-wrapper {
  @extend .action-wrapper;
}
.confirm-outer {
  @extend .action-outer;
}
.confirm-inner {
  @extend .action-inner;
}
.page-footer {
  display: flex;
  gap: 12px;
  margin: 20px 0 0;
  & > button {
    width: 50%;
    height: 56px;
  }
}
</style>
