<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content">
        <div class="body">
          <div class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl"></div>
            </div>
            <div
              class="modal-header_cancell"
              @click="$emit('onClickCancel', $event)"
            >
              <img
                slot="icon"
                class="icon"
                src="@/assets/img/close_black.svg"
                alt
              />
            </div>
          </div>
          <div class="modal-body">
            <div class="sec-search-condition">
              <modal-selectable-applicants
                :target-applicant-ids="deleteToApplicantsIds"
                :is-selectable="false"
                :is-except-graduated="true"
                :csv-download="true"
                @onClickDownloadApplicantsCsv="
                  $emit('onClickDownloadApplicantsCsv', $event)
                "
              />
            </div>
          </div>
          <div class="btn-col2">
            <div
              class="btn btn-unavailable"
              @click="$emit('onClickCancel', $event)"
            >
              閉じる
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent } from 'vue';

import Modal from '@/components/ui/modals/components/Modal';
import ModalSelectableApplicants from '@/components/features/modalSelectableApplicants/components/ModalSelectableApplicants';

export default defineComponent({
  name: 'ModalDeleteToApplicantsList',
  components: { Modal, ModalSelectableApplicants },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    sendLimit: {
      type: Number,
      default: 100,
    },
    deleteToApplicantsIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCancel', 'onClickDownloadApplicantsCsv'],
  setup(props, context) {
    return {};
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
}
.modal-body {
  text-align: start;
}
img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}
.btn-col2 {
  width: 90%;
  margin: 30px auto;
  .btn-unavailable {
    width: 100%;
  }
}
.sec-search-condition {
  font-size: 1.2rem;
  background: #fff;
  padding: 2rem;
}
</style>
