<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">フラグ管理</span>
      </template>
    </bread-crumb-list>

    <modal-window
      :is-visible="isVisibleDeleteFlagGroupModal"
      :is-loading="isLoading"
      :title="
        'フラググループ「' +
        (selectedFlagGroupObj && selectedFlagGroupObj.text
          ? selectedFlagGroupObj.text
          : '') +
        '」を削除します'
      "
      modal-type="danger"
      message="全ての応募者のフラグからこのフラグ/フラググループが削除されます。この操作は取り消すことができません。"
      button-text="フラググループを削除"
      @close="closeDeleteFlagGroupModal"
      @click="commitDeleteFlagGroupModal"
    />

    <modal-window
      :is-visible="isVisibleDeleteFlagModal"
      :is-loading="isLoading"
      :title="
        'フラグ「' +
        (selectedFlagObj && selectedFlagObj.text ? selectedFlagObj.text : '') +
        '」を削除します'
      "
      modal-type="danger"
      message="全ての応募者のフラグからこのフラグが削除されます。この操作は取り消すことができません。"
      button-text="フラグを削除"
      @close="closeDeleteFlagModal"
      @click="commitDeleteFlagModal"
    />
    <edit-disabled-modal
      :is-view="isViewEditDisabledModal"
      :actions="actions"
      :target-name="'フラグ'"
      :action-type="'応募者のフラグ'"
      :operation-name="'削除'"
      @close="isViewEditDisabledModal = false"
    />

    <section class="content-warp">
      <section class="content sec-flag-group">
        <div class="top-add-flag-group">
          <div class="btn-add-flag-group pos-relative">
            <button-base
              button-text="フラググループを追加する"
              :icon-file-name="'plus_circle'"
              @click="isCreatedFlagGroupTop = true"
            />
          </div>
          <flag-item-create-input
            class="create-flag-group-input"
            :is-visible="isCreatedFlagGroupTop === true"
            placeholder="フラググループを新規登録"
            @close="isCreatedFlagGroupTop = false"
            @createItem="createFlagGroup"
          />
        </div>
        <div class="scroll-area">
          <table class="flag-table">
            <thead>
              <tr>
                <th>フラググループ</th>
                <th class="flag-table-title">フラグ</th>
              </tr>
            </thead>
            <draggable
              v-model="draggableFlagGroups"
              tag="tbody"
              item-key="id"
              handle=".handle"
              v-bind="{
                animation: 200,
              }"
              @sort="sortFlagGroups"
            >
              <template #item="{ element }">
                <tr
                  class="flag-table-row"
                  :class="{ active: element.highlight }"
                >
                  <td class="flag-group">
                    <div class="handle">
                      <div class="handleLine">
                        <span>MENU</span>
                      </div>
                    </div>
                    <div>
                      <flag-item-update-input
                        :tag-group-id="element.id"
                        :text="element.name"
                        :is-group="true"
                        @commit="updateFlagGroup"
                        @delete="showDeleteFlagGroupModal"
                      />
                      <label
                        :for="`checkbox_${element.id}`"
                        class="cover_select"
                        @click="
                          toggleHighlightFlagGroup(
                            element.id,
                            !element.highlight,
                          )
                        "
                      >
                        <span class="checkbox_ui">
                          <input
                            :id="`checkbox_${element.id}`"
                            v-model="element.highlight"
                            type="checkbox"
                            name="highlight"
                          />
                        </span>
                        このフラググループをハイライトする
                      </label>
                    </div>
                  </td>
                  <td class="flag-name">
                    <ul class="flag-list">
                      <li
                        v-for="tag in element.tags"
                        :key="tag.id"
                        class="flag-list_item"
                      >
                        <span class="flag">
                          <flag-item-update-input
                            :tag-id="tag.id"
                            :tag-group-id="element.id"
                            :text="tag.name"
                            :is-group="false"
                            @commit="updateFlag"
                            @delete="showDeleteFlagModal"
                          />
                        </span>
                      </li>
                      <li class="flag-list_item">
                        <div class="pos-relative">
                          <div
                            class="btn-click-container flexbox flexbox-middle"
                            @click="showNewFlagInput(element)"
                          >
                            <div class="btn-click">
                              <img
                                class="flag-add-btn-svg"
                                src="@/assets/img/add_circle_gray.svg"
                                alt
                              />
                            </div>
                          </div>
                          <flag-item-create-input
                            v-if="
                              selectedFlagItemObj &&
                              element.id === selectedFlagItemObj.id
                            "
                            class="input-add-flag"
                            :is-visible="isCreatedFlag === true"
                            placeholder="フラグを新規登録"
                            @close="isCreatedFlag = false"
                            @createItem="createFlag"
                          />
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>

        <div
          v-if="$store.getters['company/companyTagGroups'].length > 0"
          class="bottom-add-flag-group"
        >
          <div class="btn-add-flag-group pos-relative">
            <button-base
              button-text="フラググループを追加する"
              :icon-file-name="'plus_circle'"
              @click="isCreatedFlagGroupBottom = true"
            />
          </div>
          <flag-item-create-input
            class="create-flag-group-input"
            :is-visible="isCreatedFlagGroupBottom === true"
            placeholder="フラググループを新規登録"
            @close="isCreatedFlagGroupBottom = false"
            @createItem="createFlagGroup"
          />
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, onUnmounted } from 'vue';
import { useStore } from 'vuex';

import draggable from 'vuedraggable';

import tagsService from '@/services/tags';
import staffsService from '@/services/staffs';
import { postPottosEvent } from '@/utils/pottos';

import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import FlagItemCreateInput from '@/components/page/tagGroups/components/FlagItemCreateInput.vue';
import FlagItemUpdateInput from '@/components/page/tagGroups/components/FlagItemUpdateInput.vue';
import EditDisabledModal from '@/components/features/autoActions/modals/EditDisabledModal';

export default defineComponent({
  name: 'TagGroups',
  components: {
    draggable,
    BreadCrumbList,
    FlagItemCreateInput,
    FlagItemUpdateInput,
    EditDisabledModal,
  },

  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const isCreatedFlagGroupTop = ref(false);
    const isCreatedFlagGroupBottom = ref(false);
    const isCreatedFlag = ref(false);
    const isVisibleDeleteFlagGroupModal = ref(false);
    const isVisibleDeleteFlagModal = ref(false);
    const selectedFlagItemObj = ref(null);
    const selectedFlagGroupObj = ref(null);
    const selectedFlagObj = ref(null);
    const draggableFlagGroups = ref([]);

    const companyId = store.getters['company/company'].id;

    const isViewEditDisabledModal = ref(false);
    const actions = ref([]);

    const fetchAll = async () => {
      await store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
      draggableFlagGroups.value =
        await store.getters['company/companyTagGroups'];
      await store.dispatch('page/SET_LOADED');
    };

    onBeforeMount(async () => {
      await fetchAll();
    });

    onUnmounted(() => {
      store.dispatch('company/CLEAR_COMPANY_TAG_GROUPS');
    });

    const createFlagGroup = async payload => {
      postPottosEvent(33);
      const res = await tagsService.createTagGroup(companyId, payload);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        await fetchAll();
        isCreatedFlagGroupTop.value = false;
        isCreatedFlagGroupBottom.value = false;
      }
    };

    const updateFlagGroup = async payload => {
      const res = await tagsService.updateTagGroup(
        payload.tagGroupId,
        payload.text,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await fetchAll();
    };

    const showDeleteFlagGroupModal = payload => {
      selectedFlagGroupObj.value = payload;
      isVisibleDeleteFlagGroupModal.value = true;
    };

    const closeDeleteFlagGroupModal = () => {
      selectedFlagGroupObj.value = {};
      isVisibleDeleteFlagGroupModal.value = false;
    };

    const commitDeleteFlagGroupModal = async payload => {
      if (payload.selected === 'submit') {
        isLoading.value = true;
        const res = await tagsService.deleteTagGroup(
          selectedFlagGroupObj.value.tagGroupId,
        );
        if (!res.success && res?.actions?.length > 0) {
          actions.value = res.actions;
          isVisibleDeleteFlagGroupModal.value = false;
          isViewEditDisabledModal.value = true;
          isLoading.value = false;
          return;
        }
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        await fetchAll();
      }
      selectedFlagGroupObj.value = null;
      isVisibleDeleteFlagGroupModal.value = false;
      isLoading.value = false;
    };

    const createFlag = async payload => {
      postPottosEvent(34);
      const res = await tagsService.createTag(
        selectedFlagItemObj.value.id,
        payload,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await fetchAll();
      selectedFlagItemObj.value = null;
      isCreatedFlag.value = false;
    };

    const updateFlag = async payload => {
      const res = await tagsService.updateTag(
        payload.tagGroupId,
        payload.tagId,
        payload.text,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await fetchAll();
    };

    const showDeleteFlagModal = payload => {
      selectedFlagObj.value = payload;
      isVisibleDeleteFlagModal.value = true;
    };

    const closeDeleteFlagModal = () => {
      isVisibleDeleteFlagModal.value = false;
      selectedFlagObj.value = null;
    };

    const commitDeleteFlagModal = async payload => {
      if (payload.selected === 'submit') {
        isLoading.value = true;
        const res = await tagsService.deleteTag(selectedFlagObj.value.tagId);
        if (!res.success && res?.actions?.length > 0) {
          actions.value = res.actions;
          isVisibleDeleteFlagGroupModal.value = false;
          isViewEditDisabledModal.value = true;
          isLoading.value = false;
          return;
        }
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        await fetchAll();
      }
      selectedFlagObj.value = null;
      isVisibleDeleteFlagModal.value = false;
      isLoading.value = false;
    };

    const showNewFlagInput = payload => {
      selectedFlagItemObj.value = payload;
      isCreatedFlag.value = true;
    };

    const sortFlagGroups = async () => {
      const res = await staffsService.updateTagGroupsOrder({
        flag_group_ids: draggableFlagGroups.value.map(
          flagGroup => flagGroup.id,
        ),
      });
      await store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };

    const toggleHighlightFlagGroup = async (flagGroupId, value) => {
      const res = await tagsService.updateHighlightTagGroup(flagGroupId, value);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await fetchAll();
    };

    return {
      isLoading,
      isCreatedFlagGroupTop,
      isCreatedFlagGroupBottom,
      isCreatedFlag,
      isVisibleDeleteFlagGroupModal,
      isVisibleDeleteFlagModal,
      selectedFlagItemObj,
      selectedFlagGroupObj,
      selectedFlagObj,
      draggableFlagGroups,
      isViewEditDisabledModal,
      actions,
      fetchAll,
      createFlagGroup,
      updateFlagGroup,
      showDeleteFlagGroupModal,
      closeDeleteFlagGroupModal,
      commitDeleteFlagGroupModal,
      createFlag,
      updateFlag,
      showDeleteFlagModal,
      closeDeleteFlagModal,
      commitDeleteFlagModal,
      showNewFlagInput,
      sortFlagGroups,
      toggleHighlightFlagGroup,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  padding: 30px;
  background: #fff;
  &.sec-flag-group {
    .top-add-flag-group {
      margin-bottom: 30px;
    }
    .bottom-add-flag-group {
      margin-top: 30px;
    }
    .btn-add-flag-group {
      display: flex;
    }
  }
  .scroll-area {
    line-height: 1.5;
    font-size: 1.3rem;
    .flag-table {
      width: 100%;
      @media (max-width: ($media_query_tablet)) {
        white-space: nowrap;
      }
      tbody tr {
        border-bottom: 1px solid #e2e2e2;
      }
      th {
        padding: 10px 20px;
        text-align: left;
        background-color: #e6e6e6;
      }
      td {
        padding: 20px;
      }
      &-title {
        min-width: 500px;
      }
      .flag-table-row {
        .cover_select {
          cursor: pointer;
          height: 20px;
          width: 100px;
          font-size: 1.1rem;
        }
        .checkbox_ui {
          min-width: 6rem;
          cursor: pointer;
          input[name='highlight'] {
            position: relative;
            top: 2px;
            width: 1.4rem;
            height: 1.4rem;
            margin: 0 2px 0 0;
            border-radius: 0.4rem;
            border: 2px solid #9d9d9d;
            background: #fff;
            appearance: none;
            vertical-align: -2px;
            -webkit-appearance: none;
            -moz-appearance: none;
            cursor: pointer;
            &:checked {
              &::after {
                content: url(../../assets/img/check.svg);
                height: 1.1rem;
                width: 1.5rem;
                margin: -5px 0 0 1px;
                position: absolute;
                bottom: 3px;
                left: 0;
              }
            }
          }
          label {
            cursor: pointer;
          }
        }
      }
      .flag-table-row.active {
        background-color: #f9f2d9;
      }
      .flag-group {
        display: flex;
        align-items: center;
      }
      .flag-name {
        @media (min-width: ($media_query_tablet)) {
          max-width: 60vw;
        }
        .flag-list {
          display: flex;
          align-items: center;
          @media (min-width: ($media_query_tablet)) {
            margin-bottom: -15px;
            flex-wrap: wrap;
            justify-content: flex-start;
          }
          .flag-list_item {
            @media (min-width: ($media_query_tablet)) {
              margin-bottom: 15px;
            }
            &:not(:last-child) {
              margin-right: 10px;
            }
            .flag {
              display: block;
              padding: 4px;
              border-radius: 4px;
              background-color: #ebebeb;
            }
            .flag-add-btn-svg {
              width: 2rem;
              height: 2rem;
              cursor: pointer;
            }
          }
        }
      }
      .sortable-ghost {
        border: dashed 3px #ccc;
      }
      .handle {
        position: relative;
        z-index: 0;
        width: 25px;
        height: 25px;
        cursor: grab;
        margin-right: 1.5rem;
        .handleLine {
          position: relative;
          z-index: 0;
          width: 25px;
          height: 25px;
          &::before,
          &::after,
          span {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 16px;
            height: 2px;
            margin: auto;
            background: #ccc;
          }
          &::before {
            z-index: 2;
            transform: translate(0, -6px);
            content: '';
          }
          &::after {
            z-index: 2;
            transform: translate(0, 6px);
            content: '';
          }
          span {
            overflow: hidden;
            z-index: 1;
            color: #ccc;
          }
        }
      }
    }
  }
}

.input-add-flag {
  @media (min-width: ($media_query_tablet)) {
    right: 0px;
  }
}

@media (max-width: ($media_query_tablet)) {
  .scroll-area {
    overflow-x: auto;
    padding-bottom: 50px;
  }
  .content {
    padding: 40px;
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px math_size(20);
  }
  .flag-table {
    font-size: 1.2rem;
    th {
      padding: 10px;
    }
    td {
      padding: 15px 10px;
    }
  }
}

.input-add-flag {
  line-height: 1;
}
</style>
