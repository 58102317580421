<template>
  <div>
    <div v-if="searchFlagGroups.length > 0" class="ar-flagGrowup">
      <table class="common_table">
        <thead class="common_thead">
          <tr class="common_thead_row align-left">
            <th class="common_thead_item"><span>フラググループ</span></th>
            <th class="common_thead_item"><span>条件</span></th>
            <th class="common_thead_item"><span>フラグ</span></th>
            <th class="common_thead_item"><span></span></th>
          </tr>
        </thead>
        <tbody
          v-for="(flagGroup, index) in searchFlagGroups"
          :key="`flagGroup_${flagGroup.id}`"
          class="common_tbody"
        >
          <tr>
            <td
              class="common_tbody_item"
              valign="top"
              aria-label="フラググループ名"
            >
              <dl class="item ar-inputElement">
                <dd class="group">
                  <drop-down
                    class="toSelect"
                    :placeholder="'フラググループ名'"
                    :selected-id="flagGroup.id || null"
                    :list="getFlagGroups"
                    :is-view="
                      visibleFlagGroupIds[index] &&
                      getFlagGroups.findIndex(tg => !tg.disabled) !== -1
                    "
                    @onOpen="openDropDownFlagGroupIds(index)"
                    @onClose="closeAllFlagGroupsDropDown"
                    @onSelected="onSelectedFlagGroupId($event, index)"
                  />
                </dd>
              </dl>
            </td>
            <td class="common_tbody_item" valign="top" aria-label="条件">
              <dl class="item ar-inputElement">
                <drop-down
                  :placeholder="'条件'"
                  :selected-id="searchFlagGroupOptions[index].selectOption"
                  :list="
                    selectableSearchFlagGroupOptionsDefault.map(option =>
                      Object.assign({}, option, {
                        selected:
                          searchFlagGroupOptions &&
                          searchFlagGroupOptions[index] &&
                          option.id ===
                            searchFlagGroupOptions[index].selectOption,
                      }),
                    )
                  "
                  :is-view="visibleFlagGroupOptions[index]"
                  @onOpen="openDropDownFlagGroupOption(index)"
                  @onClose="closeAllFlagGroupOptionsDropDown"
                  @onSelected="onSelectedOption($event, index)"
                />
              </dl>
            </td>
            <td class="common_tbody_item" aria-label="フラグ">
              <ul
                v-if="
                  !searchFlagGroupOptions[index] ||
                  searchFlagGroupOptions[index].selectOption === 0 ||
                  searchFlagGroupOptions[index].selectOption === 1
                "
                class="ar-flagLabels"
              >
                <li
                  v-for="flagId in flagGroup.flags"
                  :key="`flag_${flagId}`"
                  class="item"
                  :class="{
                    'is-deleted': getFlagNameById(flagId, flagGroup.id)
                      ? false
                      : true,
                  }"
                >
                  {{ getFlagNameById(flagId, flagGroup.id) }}
                </li>
              </ul>
              <div
                v-if="flagGroup.id !== null"
                v-show="
                  !searchFlagGroupOptions[index] ||
                  searchFlagGroupOptions[index].selectOption === 0 ||
                  searchFlagGroupOptions[index].selectOption === 1
                "
              >
                <div class="button_add_flag" @click="openDropDownFlagId(index)">
                  <p>フラグを追加</p>
                </div>
                <float-context-menu
                  class="float_context_menu_comp"
                  :list="getFlags(flagGroup.id, index)"
                  :is-view="visibleFlagIds[index]"
                  :is-multiple-select="true"
                  @onClose="closeAllFlagGroupsDropDown"
                  @onSelected="updateFlagId($event, index)"
                />
              </div>
            </td>
            <td align="center" valign="top" class="common_tbody_btn">
              <button-base
                button-text="削除"
                button-type="secondary"
                icon-file-name="delete_white"
                @click="removeLocalFlagGroupRow(index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="visibleAddFlags"
      class="btn btn-availability is-active"
      @click="addLocalFlagGroupRow"
    >
      <button>
        <img slot="icon" class="icon" src="@/assets/img/add_white.svg" alt />
        フラググループを追加
      </button>
    </div>
    <div v-else>
      <span>
        フラググループは{{ MAX_FLAG_GROUPS_COUNT }}個まで検索可能です。
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

import SEARCH_CONSTS from '@/components/features/searchApplicants/defines/search';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import DropDown from '@/components/ui/menus/components/DropDown';

export default defineComponent({
  name: 'SearchFlagGroupDetail',
  components: { FloatContextMenu, DropDown },
  props: {
    searchFlagGroups: {
      type: Array,
      required: true,
    },
    visibleFlagGroupIds: {
      type: Array,
      required: true,
    },
    visibleFlagIds: {
      type: Array,
      required: true,
    },
    visibleFlagGroupOptions: {
      type: Array,
      required: true,
    },
    selectableSearchFlagGroupOptionsDefault: {
      type: Array,
      required: true,
    },
    getFlagGroups: {
      type: Array,
      required: true,
    },
    visibleAddFlags: {
      type: Boolean,
      required: true,
    },
    getFlags: {
      type: Function,
      required: true,
    },
    getFlagNameById: {
      type: Function,
      required: true,
    },
    updateFlagGroupId: {
      type: Function,
      required: true,
    },
    updateFlagId: {
      type: Function,
      required: true,
    },
    addFlagGroupRow: {
      type: Function,
      required: true,
    },
    removeFlagGroupRow: {
      type: Function,
      required: true,
    },
    openDropDownFlagGroupIds: {
      type: Function,
      required: true,
    },
    openDropDownFlagId: {
      type: Function,
      required: true,
    },
    openDropDownFlagGroupOption: {
      type: Function,
      required: true,
    },
    closeAllFlagGroupsDropDown: {
      type: Function,
      required: true,
    },
    closeAllFlagGroupOptionsDropDown: {
      type: Function,
      required: true,
    },
    // optional
    isDetail: {
      type: Boolean,
      default: false,
    },
    searchFlagGroupOptions: {
      type: Array,
      default: () => [],
    },
    addFlagGroupOption: {
      type: Function,
      default: () => {},
    },
    removeFlagGroupOption: {
      type: Function,
      default: () => {},
    },
    updateFlagGroupOption: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, context) {
    const onSelectedOption = (payload, i) => {
      props.updateFlagGroupOption(payload.item.id, i);
    };
    const onSelectedFlagGroupId = (payload, i) => {
      props.updateFlagGroupId(payload, i, nextTick);
    };
    const addLocalFlagGroupRow = () => {
      props.addFlagGroupRow(props.addFlagGroupOption);
    };
    const removeLocalFlagGroupRow = i => {
      props.removeFlagGroupRow(i, props.removeFlagGroupOption);
    };

    return {
      onSelectedOption,
      onSelectedFlagGroupId,
      addLocalFlagGroupRow,
      removeLocalFlagGroupRow,
      MAX_FLAG_GROUPS_COUNT: SEARCH_CONSTS.MAX_FLAG_GROUPS_COUNT,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
@include common_table_style(
  $col_1: 35px,
  $col_1_ta: left,
  $col_2: 15px,
  $col_2_ta: left,
  $col_3: 42px,
  $col_3_ta: left,
  $col_4: 8px,
  $col_4_ta: left
);

.common_table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0px;
  .common_thead {
    .common_thead_row {
      .common_thead_item {
        padding: 12px 12px 12px 0px;
      }
      :first-of-type {
        padding-left: 10px;
        border-radius: 4px 0 0 4px;
      }
      :last-of-type {
        border-radius: 0 4px 4px 0;
        padding-right: 0px;
        padding-left: 0px;
        width: 12px;
        min-width: 12px;
      }
    }
  }

  .common_tbody {
    td {
      padding: 2rem 1rem 0 0;

      &.common_tbody_item {
        padding: 2rem 1rem 0 0;
      }

      &.common_tbody_btn {
        padding-right: 0;
        text-align: right;
      }
    }

    @media (max-width: ($media_query_sp)) {
      td {
        padding: 1rem 0;

        &.common_tbody_item {
          padding: 1rem 0;
        }

        &.common_tbody_btn {
          padding-right: 0;
          text-align: left;
        }
      }
    }
  }
  margin-bottom: 2rem;
}

.ar-flagComponent {
  padding: 20px 0;
  margin-bottom: 40px;
  border-bottom: 1px solid #ccc;

  > .subBtn {
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
    padding: 10px 15px 10px 25px;
    cursor: pointer;
    background-image: url('../../../../assets/img/add_white.svg');
    background-size: 10px auto;
    background-position: left 10px center;
    background-repeat: no-repeat;

    &.is-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.ar-flagMain {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;

  > .headline {
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
  }

  > .group {
    width: 48.5%;
    margin-right: 15px;
  }

  > .toSelect {
    width: 48.5%;
  }

  > .toRelease {
    color: #1899d6;
    cursor: pointer;

    &.is-detail {
      margin-bottom: 23px;
    }
  }
}

.btn-availability {
  > button {
    cursor: pointer;
  }
}
.ar-flagSub {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;

  > .headline {
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.ar-flagLabels {
  display: flex;
  flex-wrap: wrap;

  > .item {
    margin: 0 10px 0 0;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #ebebeb;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.is-deleted {
      display: none;
    }
  }
}

.flag_select {
  margin-bottom: 40px;
  dt {
    margin-bottom: 10px;
    font-weight: bold;
  }
  dd .options_btn {
    margin-left: 5px;
  }
}

.button_add_flag {
  width: 20px;
  height: 20px;
  background-image: url('../../../../assets/img/add_circle_gray.svg');
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  p {
    height: 20px;
    padding: 2px 0 0 25px;
  }
}

.float_context_menu_comp {
  width: 200px;
  & :deep(.context-menu) {
    width: 100%;
    ul {
      li {
        .li-inner {
          p {
            width: 330px;
            white-space: normal;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  @include common_table_style(
    $col_1: 100%,
    $col_1_ta: left,
    $col_2: 100%,
    $col_2_ta: left,
    $col_3: 100%,
    $col_3_ta: left,
    $col_4: 100%,
    $col_4_ta: left
  );
  .common_tbody_item {
    width: 100%;
    padding: 1rem 1rem 1rem 0 !important;
  }
  .common_tbody_btn {
    padding-bottom: 12px !important;
  }
  .ar-flagMain {
    display: block;
    margin-bottom: 20px;

    > .group {
      width: 100%;
      margin: 0 0 10px 0;
    }

    > .toSelect {
      width: 100%;
    }

    > .toRelease {
      &.is-detail {
        margin-bottom: 0;
      }
    }
  }

  .ar-flagComponent {
    > .subBtn {
      padding: 10px 10px 10px 21px;
      background-position: left 8px center;
    }
  }
  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
    width: 100%;
  }
  .common_thead_row {
    display: none;
  }
  td::before {
    content: attr(aria-label);
    display: inline-block;
    padding: 10px 0;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
  }
}
</style>
