import httpClient from '@/http-client';

const DashboardManager = function _DashboardManager() {
  const manager = Object.create(DashboardManager.prototype);
  return manager;
};
DashboardManager.prototype = {
  funcPercent(total, part) {
    if (total === 0 || part === 0) return 0;
    return Math.round((part / total) * 100);
  },
  /**
   * 基本データ：連絡手段の取得
   * @param payload
   * @returns {Promise<{success: boolean, data: null}>}
   */
  async fetchBasicContactTypes(payload) {
    const response = await httpClient.post('/dashboards/contact_type_id', {
      company_id: payload.companyId,
      graduated: payload.graduatedYear,
      status: payload.status || 0,
    });
    const data = {
      total: { count: 0 },
      mail: { count: 0, percent: 0 },
      line: { count: 0, percent: 0 },
    };
    let total = 0;
    response.data.forEach(el => {
      total += el.count;
      switch (el.contact_type_id) {
        case 1:
          data.mail.count = el.count;
          break;
        case 2:
          data.line.count = el.count;
          break;
        default:
          break;
      }
    });
    data.total.count = total;
    data.mail.percent = this.funcPercent(total, data.mail.count);
    data.line.percent = this.funcPercent(total, data.line.count);
    if (response.status === 200) return { success: true, data };
    return { success: false, data: response.data ? response.data : null };
  },
  /**
   * 基本データ：登録者の取得
   * @param payload
   * @returns {Promise<{success: boolean, data: null}>}
   */
  async fetchBasicActivated(payload) {
    const response = await httpClient.post('/dashboards/activated_at', {
      company_id: payload.companyId,
      graduated: payload.graduatedYear,
      status: payload.status || 0,
    });
    const data = {
      total: { count: 0 },
      activated: { count: 0, percent: 0 },
      notActivated: { count: 0, percent: 0 },
    };
    // responseの1つ目が応募者数、2つ目がLINE友達の人数
    data.activated.count = response.data[0].count;
    data.notActivated.count = response.data[1].count;
    data.total.count += data.activated.count;
    data.total.count += data.notActivated.count;
    data.activated.percent = this.funcPercent(
      data.total.count,
      data.activated.count,
    );
    data.notActivated.percent = this.funcPercent(
      data.total.count,
      data.notActivated.count,
    );
    if (response.status === 200) return { success: true, data };
    return { success: false, data: response.data ? response.data : null };
  },
  /**
   * 基本データ：性別の取得
   * @param payload
   * @returns {Promise<{success: boolean, data: null}>}
   */
  async fetchBasicGender(payload) {
    const response = await httpClient.post('/dashboards/gender_id', {
      company_id: payload.companyId,
      graduated: payload.graduatedYear,
      status: payload.selectedGenderOption || 0,
    });
    if (response.status !== 200) {
      return { success: false, data: response.data ? response.data : null };
    }
    const data = {
      total: { count: 0 },
      male: { count: 0, percent: 0 },
      female: { count: 0, percent: 0 },
      other: { count: 0, percent: 0 },
      noanswer: { count: 0, percent: 0 },
    };
    let total = 0;
    response.data.forEach(el => {
      total += el.count;
      switch (el.gender_id) {
        case 1:
          data.male.count = el.count;
          break;
        case 2:
          data.female.count = el.count;
          break;
        case 3:
          data.other.count = el.count;
          break;
        case 4:
          data.noanswer.count = el.count;
          break;
        default:
          break;
      }
    });
    data.total.count = total;
    data.male.percent = this.funcPercent(total, data.male.count);
    data.female.percent = this.funcPercent(total, data.female.count);
    data.other.percent = this.funcPercent(total, data.other.count);
    data.noanswer.percent = this.funcPercent(total, data.noanswer.count);
    return { success: true, data };
  },
  /**
   * 基本データ：文理の取得
   * @param payload
   * @returns {Promise<{success: boolean, data: null}>}
   */
  async fetchBasicDepartmentCategory(payload) {
    const response = await httpClient.post(
      '/dashboards/department_category_id',
      {
        company_id: payload.companyId,
        graduated: payload.graduatedYear,
        status: payload.selectedDepartmentOption || 0,
      },
    );
    const data = {
      total: { count: 0 },
      humanities: { count: 0, percent: 0 },
      science: { count: 0, percent: 0 },
      other: { count: 0, percent: 0 },
    };
    let total = 0;
    response.data.forEach(el => {
      total += el.count;
      switch (el.department_category_id) {
        case 1:
          data.humanities.count = el.count;
          break;
        case 2:
          data.science.count = el.count;
          break;
        case 3:
          data.other.count = el.count;
          break;
        default:
          break;
      }
    });
    data.total.count = total;
    data.humanities.percent = this.funcPercent(total, data.humanities.count);
    data.science.percent = this.funcPercent(total, data.science.count);
    data.other.percent = this.funcPercent(total, data.other.count);
    if (response.status === 200) return { success: true, data };
    return { success: false, data: response.data ? response.data : null };
  },
  async fetchEntryTransitionEach(payload) {
    const response = await httpClient.post('/dashboards/statistics', payload);
    if (response.status === 200) {
      return {
        success: true,
        data: { status: payload.status, response: response.data },
      };
    }
    return { success: false, data: response.data ? response.data : null };
  },

  async getDashboardsAnalyticsHiringGraduatedId(graduatedId) {
    const res = await httpClient.get(
      `/api/v1/dashboards/analytics/hiring/${graduatedId}`,
    );
    return res.data;
  },

  async getDashboardsAnalyticsHiringGraduates() {
    const res = await httpClient.get(
      `/api/v1/dashboards/analytics/hiring/graduates`,
    );
    return res.data;
  },
};
export default DashboardManager();
