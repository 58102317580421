import qs from 'qs';

import httpClient from '@/http-client';

const ReserveManager = function _ReserveManager() {
  const manager = Object.create(ReserveManager.prototype);
  return manager;
};

ReserveManager.prototype = {
  /**
   * applicant ID と selection ID から選考通知された会場データを取得（メールからブラウザ表示）
   */
  async getSelectionFromApplicantIdSelectionId(token) {
    const query = qs.stringify({ token });
    const res = await httpClient.get(`/reserves?${query}`);
    if (res.status === 401) return null;
    return res.data;
  },
  /**
   * applicant_id と timetable_id をポスト「選考通知」された会場データを取得（メールからブラウザ表示）
   */
  async postSelectionTimeFromApplicantIdTimeId(payload) {
    const res = await httpClient.post('/reserves', {
      token: payload.token,
      timetable_id: payload.timetableId,
    });
    return res;
  },
  /**
   * 一括開催時間割り当て
   */
  async bulkReserveWithAssignProgress(payload) {
    const res = await httpClient.post(
      '/reserves/bulk_reserve_with_assign_progress',
      {
        token: payload.token,
        selection_id: payload.selectionId,
        timetable_id: payload.timetableId,
        applicant_ids: payload.applicantIds,
      },
    );
    return res.data;
  },
  /**
   * applicant ID と sellection ID から選考通知された会場データを取得（企業のオペレータが手動で面接を設定する）
   */
  async getSelectionInfo(payload) {
    const res = await httpClient.post('/reserves_operation', {
      applicant_id: payload.applicant_id,
      selection_id: payload.selection_id,
    });
    return res.data;
  },
  /**
   * timetable_id をポスト「選考通知」された会場の予約（企業のオペレータが手動で面接を設定する）
   */
  async createAttendanceOperation(payload) {
    const res = await httpClient.post('/reserves_operation/attendance', {
      timetable_id: payload.timetable_id,
      applicant_id: payload.applicant_id,
    });
    return res.data;
  },
  /**
   * 応募者詳細：リマインダー通知設定
   */
  async toggleReminderSetting(payload) {
    const res = await httpClient.post('/reserves_operation/reminder', {
      attendance: payload,
    });
    return res.data;
  },
  /**
   * 応募者詳細：参加確認設定
   */
  async toggleReminderCheckSetting(payload) {
    const res = await httpClient.post(
      '/reserves_operation/reminder_check',
      payload,
    );
    return res.data;
  },
  /**
   * 応募者詳細：選考のマイページ表示設定
   */
  async toggleMypageVisibleSetting(payload) {
    const res = await httpClient.patch(
      `/reserves_operation/${payload.applicant_id}/progresses/mypage`,
      {
        progress_id: payload.progress_id,
        mypage_visible: payload.mypage_visible,
      },
    );
    return res.data;
  },
};

export default ReserveManager();
