<template>
  <div class="breadcrumb_wrapper">
    <slot name="breadcrumb" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BreadCrumbList',
  setup(props, context) {},
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.breadcrumb_wrapper {
  background: #f2f9fc;
  padding-left: 20px;
  border-bottom: 1px solid #b6bfde;
  height: 50px;
  display: flex;
  align-items: center;
}
img {
  width: 0.8rem;
  margin-right: 0.8rem;
}
span {
  margin-right: 0.8rem;
}
@media (max-width: ($media_query_tablet)) {
  .breadcrumb_wrapper {
    padding-left: 15px;
  }
}
</style>
