import cloneDeep from 'lodash.clonedeep';
import { ref } from 'vue';

import { getInitSearchBody } from '@/models/applicant-search';
import applicantSearchDetailFiltersDefault from '@/components/features/searchApplicants/defines/applicant-search-detail-filters';
import { camelToSnakeCase } from '@/utils/change-case';

export default function useSearchDetailFilters() {
  /**
   * 選択したフィルターリスト
   * [{
   *   id: ?number,
   *   name: String,
   *   keyName: String,
   *   data: ?String,
   *   eq: Boolean,
   *   exist: ?Boolean,
   *   selectOption: Number,
   *   isView: Boolean,
   * }]
   */
  const searchDetailFilters = ref([]);
  // 選択可能なフィルター項目リスト
  const selectableSearchDetailFilters = ref(
    cloneDeep(applicantSearchDetailFiltersDefault),
  );
  const attendanceId = ref(null);

  // methods
  /**
   * searchDetailFiltersに新規フィルターを追加
   */
  const addSearchDetailFilter = () => {
    searchDetailFilters.value.push({
      id: null,
      name: '',
      keyName: '',
      data: null,
      eq: true,
      exist: null,
      selectOption: 1,
      isView: false,
    });
  };
  /**
   * searchDetailFiltersのフィルターを削除
   * @param {Number} index
   * @param {String} keyName
   */
  const removeSearchDetailFilter = (index, keyName) => {
    if (keyName === 'eventId') {
      resetEventId();
    } else if (keyName === 'attendanceDate') {
      resetAttendance();
    }
    searchDetailFilters.value.splice(index, 1);
  };
  /**
   * searchDetailFiltersのフィルターを更新
   * @param {Number} i
   * @param {{
   *   id: ?Straing,
   *   data: ?String,
   *   keyName: ?String,
   *   name: ?String,
   *   disabled: ?Boolean,
   *   isView: ?Boolean,
   * }} payload
   */
  const updateSearchDetailFilterData = (i, payload) => {
    if (payload.id !== undefined) searchDetailFilters.value[i].id = payload.id;
    if (payload.disabled !== undefined) {
      searchDetailFilters.value[i].disabled = payload.disabled;
    }
    if (payload.data !== undefined) {
      searchDetailFilters.value[i].data = payload.data;
    }
    if (payload.keyName !== undefined) {
      searchDetailFilters.value[i].keyName = payload.keyName;
    }
    if (payload.name !== undefined) {
      searchDetailFilters.value[i].name = payload.name;
    }
    if (payload.isView !== undefined) {
      searchDetailFilters.value[i].isView = payload.isView;
    }
  };
  /**
   * searchDetailFiltersのフィルターOptionを更新
   * @param {String} val
   * @param {Number} i
   */
  const updateSearchDetailFilterOption = (value, i) => {
    const valNum = parseInt(value, 10);
    if (valNum < 2) {
      searchDetailFilters.value[i].eq = valNum;
      searchDetailFilters.value[i].exist = null;
    } else {
      searchDetailFilters.value[i].eq = 1;
      searchDetailFilters.value[i].exist = valNum - 2;
    }
    searchDetailFilters.value[i].selectOption = valNum;
  };
  /**
   * データのリセット
   */
  const resetSearchDetailFilters = () => {
    searchDetailFilters.value = [];
    selectableSearchDetailFilters.value = cloneDeep(
      applicantSearchDetailFiltersDefault,
    );
    attendanceId.value = null;
  };
  /**
   * searchDetailFiltersのフィルターOptionによる選択肢のdisabledの判定
   * @param {Number} i
   */
  const getDisabledBySelectOption = i => {
    return (
      searchDetailFilters.value[i].selectOption === 2 ||
      searchDetailFilters.value[i].selectOption === 3
    );
  };
  /**
   * progressStatusの更新
   * @param {Number} i
   * @param {?Number} value
   */
  const updateProgressStatusIds = (i, value) => {
    const progressStatusIds = searchDetailFilters.value[i];
    if (value === null) {
      // nullの場合は配列をリセット
      progressStatusIds.data = [];
    } else if (progressStatusIds.data.includes(value)) {
      // 同じ項目選択時は選択解除
      progressStatusIds.data = progressStatusIds.data.filter(
        id => id !== value,
      );
    } else {
      progressStatusIds.data.push(value);
    }
  };
  /**
   * attendanceIdを更新
   * @param {String} value
   */
  const updateAttendanceId = value => {
    attendanceId.value = value;
  };
  /**
   * 選考日削除時には参加日・参加確認をリセット
   */
  const resetEventId = () => {
    attendanceId.value = null;
    searchDetailFilters.value.forEach((filter, index) => {
      if (
        filter.keyName === 'attendanceDate' ||
        filter.keyName === 'reminderCheck'
      ) {
        updateSearchDetailFilterData(index, { data: null });
      }
    });
  };
  /**
   * 参加日削除時はattendanceIdも削除が必要
   */
  const resetAttendance = () => {
    attendanceId.value = null;
    searchDetailFilters.value.forEach((filter, index) => {
      if (filter.keyName === 'attendanceDate') {
        updateSearchDetailFilterData(index, { data: null });
      }
    });
  };
  /**
   * Search Detail のObjectをAPIリクエストのフォーマットに整形
   * @param {Object} obj - オブジェクト
   * @returns {Object} オブジェクト
   */
  const parseDetailToRequest = obj => {
    const result = {};
    for (const [key, valueObject] of Object.entries(obj)) {
      // keyがapplicantSearchBodyDefaultに存在する場合のみリクエストに含める
      if (Object.keys(getInitSearchBody()).includes(key)) {
        let newKey = camelToSnakeCase(key);
        if (
          valueObject &&
          (valueObject.exist !== null ||
            (valueObject.data !== null &&
              valueObject.data !== undefined &&
              valueObject.data !== ''))
        ) {
          if (Array.isArray(valueObject.data)) {
            // dataが配列の場合、1以上要素が存在する場合はリクエストに含める
            if (key === 'flagGroups') {
              // アンケート検索の場合tagsに変換して整形
              if (valueObject.data.length > 0) {
                const tags = valueObject.data
                  .filter((x, i) => {
                    // フラググループが選択されていない場合は除外
                    if (x.id === null) return false;
                    if (!x.flags || x.flags.length === 0) {
                      // フラグが選択されていない場合、値がある/値がないの場合のみ含める
                      return valueObject.flagOptions[i].exist !== null;
                    }
                    // フラグが選択されている場合
                    return true;
                  })
                  .map((x, i) => {
                    x.flags;
                    return {
                      tagGroupId: x.id,
                      tagIds: x.flags,
                      eq: valueObject.flagOptions[i].eq,
                      exist: valueObject.flagOptions[i].exist,
                    };
                  });
                if (tags.length > 0) result['tags'] = tags;
              }
            } else if (key === 'surveyLists') {
              // Googleフォーム検索の場合
              if (valueObject.data.length > 0) {
                result[newKey] = {
                  data: valueObject.data
                    .map(x => ({
                      name: x.name,
                      text: x.text,
                    }))
                    .filter(x => x.text && x.text !== ''),
                };
              }
            } else {
              result[newKey] = cloneDeep(valueObject);
            }
          } else {
            // 配列以外の場合
            result[newKey] = cloneDeep(valueObject);
          }
        }
      }
    }
    return result;
  };
  /**
   * Search Detail のObjectをAPIリクエストのフォーマットに整形
   * @param {Object} obj - オブジェクト
   * @returns {Object} オブジェクト
   */
  const getSearchDetailRequest = obj => {
    const searchBody = parseDetailToRequest(obj);
    for (const [key, value] of Object.entries(searchBody)) {
      if (value.data === '' || value.data === null) {
        if (value.exist !== null) {
          searchBody[key] = { data: ' ', eq: true, exist: value.exist };
        } else {
          searchBody[key] = { data: null, eq: true };
        }
      } else {
        searchBody[key] = value;
      }
    }
    return searchBody;
  };
  /**
   * searchBodyの作成
   * @param {{
   *   searchFlagGroups: [{ id: ?Number, flags: [Number] }],
   *   searchFlagGroupOptions: [{eq: Number, exist: ?Number, selectOption: Number}],
   *   searchSurveys: [{ id: ?Number, text: [String] }],
   *   searchSurveyTexts: [String],
   *   newSearchDetailFilters: [Object] | null
   * }}
   * @returns {Object} オブジェクト
   */
  const createSearchBody = (
    searchFlagGroups = [],
    searchFlagGroupOptions = [],
    searchSurveys = [],
    searchSurveyTexts = [],
    newSearchDetailFilters = null,
  ) => {
    const searchBody = {};
    // searchDetailFilters からsearchBodyにデータを反映
    if (!newSearchDetailFilters) {
      searchDetailFilters.value
        .filter(filter => filter.id !== null)
        .forEach(filter => {
          searchBody[filter.keyName] = {
            data: filter.data,
            eq: filter.eq,
            exist: filter.exist,
          };
        });
    } else {
      newSearchDetailFilters
        .filter(filter => filter.id !== null)
        .forEach(filter => {
          searchBody[filter.keyName] = {
            data: filter.data,
            eq: filter.eq,
            exist: filter.exist,
          };
        });
    }
    if (attendanceId.value) {
      searchBody.attendanceId = attendanceId.value;
    }
    if (searchFlagGroups.length > 0 && searchFlagGroupOptions.length > 0) {
      searchBody.flagGroups = {
        data: cloneDeep(
          searchFlagGroups.map(flagGroup => ({
            id: flagGroup.id,
            flags: cloneDeep(flagGroup.flags),
          })),
        ),
        flagOptions: cloneDeep(searchFlagGroupOptions).map(x => ({
          eq: x.eq,
          exist: x.exist,
        })),
      };
    }
    if (searchSurveys.length > 0 && searchSurveyTexts.length > 0) {
      searchBody.surveyLists = {
        data: searchSurveys
          .filter(survey => survey.id !== null)
          .map((survey, index) => {
            const res = cloneDeep(survey);
            res.text = searchSurveyTexts[index];
            return res;
          }),
      };
    }
    return getSearchDetailRequest(searchBody);
  };
  /**
   * プリセットを読み込む
   * @param {Object[]} newSearchDetailFilters
   */
  const loadSearchDetailFiltersPreset = async (
    newSearchDetailFilters,
    setAttendances,
  ) => {
    searchDetailFilters.value = cloneDeep(newSearchDetailFilters);
    // 選考名を選択している場合、参加日一覧を取得
    const eventFilter = newSearchDetailFilters.find(
      filter => filter.keyName === 'eventId',
    );
    if (eventFilter) {
      const attendances = await setAttendances(eventFilter.data);
      // 参加日を選択している場合attendanceIdを更新
      const attendanceDateFilter = newSearchDetailFilters.find(
        filter => filter.keyName === 'attendanceDate',
      );
      if (attendanceDateFilter) {
        updateAttendanceId(attendances.indexOf(attendanceDateFilter.data));
      }
    }
  };

  return {
    searchDetailFilters,
    selectableSearchDetailFilters,
    attendanceId,
    addSearchDetailFilter,
    removeSearchDetailFilter,
    updateSearchDetailFilterOption,
    updateSearchDetailFilterData,
    updateProgressStatusIds,
    updateAttendanceId,
    resetSearchDetailFilters,
    getDisabledBySelectOption,
    createSearchBody,
    loadSearchDetailFiltersPreset,
  };
}
