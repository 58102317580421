<script setup>
import { ref, computed, watch } from 'vue';
import { useForm } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  BaseSchema,
  AssignProgressSchema,
  ResultsSchema,
  RichMessageSchema,
  FlagSurveySchema,
  NoticeSchema,
} from '@/schemas/autoActions';
import { toTypedSchema } from '@vee-validate/zod';

import CreatePage from '@/pages/autoActions/create';
import ConfirmPage from '@/pages/autoActions/create/confirm';
import ModalLeaveConfirm from '@/components/ui/modals/ModalLeaveConfirm.vue';

import autoActionsService from '@/services/autoActions.js';
import useBeforeRouteLeave from '@/composables/useBeforeRouteLeave';
import {
  ACTION_TYPES,
  ACTION_STATUSES,
  DEFAULT_RESULTS_TEXT,
} from '@/defines/autoActions';
import {
  PROGRESS_STATUSES,
  FINISHED_PROGRESS_STATUSES,
} from '@/defines/progresses';

const store = useStore();
const router = useRouter();

const localActionType = ref(0);

const leaveConfirmModalRef = ref(null);
const isViewLeaveConfirmModal = ref(false);
const isSkipLeaveConfirm = ref(false);

watch(
  () => router.currentRoute.value.path,
  () => {
    window.scrollTo(0, 0);
    store.dispatch('page/SET_LOADED');
  },
);
// 選択しているアクションタイプによってスキーマを動的に変更
const currentSchema = computed(() => {
  switch (localActionType.value) {
    case ACTION_TYPES.assignProgress:
      return toTypedSchema(BaseSchema.merge(AssignProgressSchema));
    case ACTION_TYPES.results:
      return toTypedSchema(BaseSchema.merge(ResultsSchema));
    case ACTION_TYPES.richMessage:
      return toTypedSchema(BaseSchema.merge(RichMessageSchema));
    case ACTION_TYPES.flagSurvey:
      return toTypedSchema(BaseSchema.merge(FlagSurveySchema));
    case ACTION_TYPES.notice:
      return toTypedSchema(BaseSchema.merge(NoticeSchema));
    default:
      return toTypedSchema(BaseSchema);
  }
});

const { handleSubmit, setFieldValue, isSubmitting, values, meta } = useForm({
  validationSchema: currentSchema,
  initialValues: {
    actionName: '',
    selection: {},
    actionType: 0,
    applicantStatus: 'notAnnounced',
    flagGroups: [],
    triggerDelayDay: 1,
    triggerHour: '12',
    triggerMin: '00',
    assignProgress: {},
    richMessage: {},
    flagSurvey: {},
    notice: {
      email: { subject: '', content: '' },
      line: { content: '' },
      files: [],
      isSendForceEmail: false,
      isConfirmMessage: false,
    },
    results: {
      passed: {
        email: {
          subject: DEFAULT_RESULTS_TEXT.passed.email.subject,
          content: DEFAULT_RESULTS_TEXT.passed.email.content,
        },
        line: { content: DEFAULT_RESULTS_TEXT.passed.line.content },
      },
      offered: {
        email: {
          subject: DEFAULT_RESULTS_TEXT.offered.email.subject,
          content: DEFAULT_RESULTS_TEXT.offered.email.content,
        },
        line: { content: DEFAULT_RESULTS_TEXT.offered.line.content },
      },
      dropped: {
        email: {
          subject: DEFAULT_RESULTS_TEXT.dropped.email.subject,
          content: DEFAULT_RESULTS_TEXT.dropped.email.content,
        },
        line: { content: DEFAULT_RESULTS_TEXT.dropped.line.content },
      },
    },
  },
});

const skipModalCondition = to => {
  const targetPaths = [
    '/configs/auto-actions/create',
    '/configs/auto-actions/create/confirm',
  ];
  return (
    targetPaths.includes(to.path) ||
    isSkipLeaveConfirm.value ||
    !meta.value.dirty
  );
};

useBeforeRouteLeave(
  () => leaveConfirmModalRef.value.openModal(),
  skipModalCondition,
);

const actionTypeConditions = () => {
  switch (values.actionType) {
    case ACTION_TYPES.assignProgress:
      return { selection_id: values.assignProgress?.id };
    case ACTION_TYPES.announce:
      return { selection_id: values.selection?.id };
    case ACTION_TYPES.richMessage:
      return { richmessage_id: values.richMessage?.id };
    case ACTION_TYPES.flagSurvey:
      return { flagsurvey_id: values.flagSurvey?.id };
    case ACTION_TYPES.notice:
      return {
        notification: {
          notice_check: values.notice.isConfirmMessage,
          force_email: values.notice.isSendForceEmail,
          mail_subject: values.notice.email.subject,
          mail_content: values.notice.email.content,
          line_content: values.notice.line.content,
        },
      };
    case ACTION_TYPES.results:
      return {
        result: {
          passed: {
            mail_subject: values.results.passed.email.subject,
            mail_content: values.results.passed.email.content,
            line_content: values.results.passed.line.content,
          },
          offered: {
            mail_subject: values.results.offered.email.subject,
            mail_content: values.results.offered.email.content,
            line_content: values.results.offered.line.content,
          },
          dropped: {
            mail_subject: values.results.dropped.email.subject,
            mail_content: values.results.dropped.email.content,
            line_content: values.results.dropped.line.content,
          },
        },
      };
    default:
      undefined;
      break;
  }
};

const progressStatusKeyToIds = key => {
  const progress_status_ids = [];
  const finished_progress_status_ids = [];
  key.split(' ').forEach(_key => {
    if (PROGRESS_STATUSES[_key]) {
      progress_status_ids.push(PROGRESS_STATUSES[_key]);
    } else {
      finished_progress_status_ids.push(FINISHED_PROGRESS_STATUSES[_key]);
    }
  });
  return { progress_status_ids, finished_progress_status_ids };
};

const mapFlagGroups = flagGroups => {
  const mappedFlags = flagGroups
    .filter(flagGroup => flagGroup.flags.length > 0)
    .map(flagGroup => {
      return {
        tag_group_id: flagGroup.id,
        tag_ids: flagGroup.flags.map(flag => flag.id),
        is_contain: flagGroup.isContain,
      };
    });
  return mappedFlags.length === 0 ? {} : { tag_groups: mappedFlags };
};

const requestPayload = computed(() => ({
  graduated_id: store.getters['graduateds/selectedGraduatedId'],
  selection_id: values.selection.id,
  name: values.actionName,
  status: ACTION_STATUSES.enabled,
  action_type: values.actionType,
  trigger_delay_days:
    values.triggerDelayDay === 0 ? null : values.triggerDelayDay,
  trigger_time: `${values.triggerHour}:${values.triggerMin}`,
  action_conditions: actionTypeConditions(),
  trigger_conditions: {
    tags: mapFlagGroups(values.flagGroups),
    ...progressStatusKeyToIds(values.applicantStatus),
  },
}));

const createFormData = () => {
  const formData = new FormData();
  formData.append('params', JSON.stringify(requestPayload.value));
  const files = values.notice.files;
  const isSendForceEmail =
    requestPayload.value.action_conditions.notification?.force_email;
  if (files && isSendForceEmail) {
    files.forEach(file => {
      formData.append('files[]', file.file);
    });
  }
  return formData;
};

const submitConfirm = handleSubmit(async () => {
  const { flagGroups } = values;
  if (flagGroups.some(flagGroup => flagGroup.flags.length === 0)) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: 'フラグが選択されていないフラググループがあります',
      type: false,
    });
    return;
  }

  const { status } = await autoActionsService.checkDuplicate(
    requestPayload.value,
  );
  if (status !== 204) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: 'アクションが重複しています',
      type: false,
    });
    return;
  }
  router.push({
    name: 'AutoActionsCreateConfirm',
  });
});
const submitCreate = handleSubmit(async () => {
  const payload =
    requestPayload.value.action_type === ACTION_TYPES.notice
      ? createFormData()
      : requestPayload.value;
  const { status, data } = await autoActionsService.createAutoAction(payload);
  if (status !== 201) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: data.message,
      type: false,
    });
    return;
  }
  store.dispatch('notification/VISIBLE_NOTIFICATION', {
    message: '自動アクションを作成しました',
    type: true,
  });
  isSkipLeaveConfirm.value = true;
  router.push({ name: 'AutoActions' });
});

const handleSelectActionType = actionType => {
  localActionType.value = actionType;
  setFieldValue('applicantStatus', 'notAnnounced');
  if (actionType === ACTION_TYPES.assignProgress) {
    setFieldValue('triggerDelayDay', 0);
  }
  if (actionType === ACTION_TYPES.results) {
    resetResultsFormValue();
  }
};
const resetResultsFormValue = () => {
  const resultsTypes = ['passed', 'offered', 'dropped'];
  for (const resultsType of resultsTypes) {
    setFieldValue(
      `results.${resultsType}.email.subject`,
      DEFAULT_RESULTS_TEXT[resultsType].email.subject,
    );
    setFieldValue(
      `results.${resultsType}.email.content`,
      DEFAULT_RESULTS_TEXT[resultsType].email.content,
    );
    setFieldValue(
      `results.${resultsType}.line.content`,
      DEFAULT_RESULTS_TEXT[resultsType].line.content,
    );
  }
};
</script>

<template>
  <div>
    <create-page
      v-show="router.currentRoute.value.name === 'AutoActionsCreate'"
      :form-values="values"
      :is-submitting="isSubmitting"
      @select-action-type="handleSelectActionType"
      @submit="submitConfirm"
    />
    <confirm-page
      v-show="router.currentRoute.value.name === 'AutoActionsCreateConfirm'"
      :form-values="values"
      :is-submitting="isSubmitting"
      @submit="submitCreate"
    />
    <modal-leave-confirm
      ref="leaveConfirmModalRef"
      :title="'自動アクションを登録中です'"
      :message="`このまま画面を閉じると自動アクションの登録内容が失われます。
      画面を閉じてもよろしいでしょうか？`"
      :is-view="isViewLeaveConfirmModal"
      @open="isViewLeaveConfirmModal = true"
      @close="isViewLeaveConfirmModal = false"
    />
  </div>
</template>
