<template>
  <div
    ref="refTagContextMenu"
    class="tag-context-menu basic-box-shadow"
    :class="{ 'position-top': isPositionTop }"
  >
    <div class="row-input flexbox flexbox-middle">
      <div class="text-wrapper">
        <input
          ref="refNewItem"
          v-model="textNewItem"
          type="text"
          :placeholder="placeholder"
        />
      </div>
      <div class="btn-wrapper">
        <button-base
          class="flag-add-btn-txt"
          button-text="新規登録"
          @click="addNewItem()"
        />
      </div>
    </div>
    <ul>
      <li v-for="item in list" :key="item.id" class="un-selectable">
        <div class="li-inner">
          <div class="btn-toggle" @click="onChangeSelected(item)">
            <p>{{ item.name }}</p>
            <img
              class="icon check"
              :src="
                item.selected
                  ? require('@/assets/img/check.svg')
                  : require('@/assets/img/check_gray.svg')
              "
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

// フラググループ及びフラグ選択ポップアップ
export default defineComponent({
  name: 'TagContextMenu',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    isPositionTop: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onChangeSelect', 'onClickNewItem', 'close'],
  setup(props, context) {
    const refNewItem = ref(null);
    const refTagContextMenu = ref(null);
    const textNewItem = ref('');

    // methods
    const onChangeSelected = item => {
      item.selected = !item.selected;
      context.emit('onChangeSelect', {
        id: item.id,
        selected: item.selected,
        name: item.name ? item.name : '',
      });
    };
    const addNewItem = async () => {
      if (textNewItem.value) {
        context.emit('onClickNewItem', { name: textNewItem.value });
        textNewItem.value = '';
      }
      focusNewItem();
    };
    const focusNewItem = () => {
      refNewItem.value.focus();
    };
    const onClickOuter = ev => {
      if (
        refTagContextMenu.value &&
        refTagContextMenu.value.contains(ev.target)
      ) {
        // 自分のコンポーネント内の要素をクリックした場合は閉じない
        return;
      }
      context.emit('close');
      textNewItem.value = '';
    };

    // lifecycle
    onMounted(() => {
      window.addEventListener('click', onClickOuter, true);
      focusNewItem();
    });
    onUnmounted(() => {
      window.removeEventListener('click', onClickOuter, true);
    });

    return {
      refNewItem,
      refTagContextMenu,
      textNewItem,
      onChangeSelected,
      addNewItem,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.tag-context-menu {
  max-width: 300px;
  border: solid 1px #f2f9fc;
  background: white;
  padding: 10px;
  z-index: 20;
  &.position-top {
    top: auto;
    bottom: 2rem;
    left: 0;
  }
  .row-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0 !important;
    border-bottom: 1px solid $border_color;
    .text-wrapper {
      // width: 76%;
      padding: 0.2rem;
      input {
        min-width: 180px;
        border: none;
        padding: 0;
        font-size: 1.2rem;
        background-color: transparent !important;
        height: 3rem;
        &::placeholder {
          font-style: 1.1rem;
        }
      }
    }
  }
  .flag-add-btn-txt {
    padding: 0.7rem 1rem;
    margin-top: -0.3rem;
    width: 7rem;
    margin-left: 6px;
  }
  .btn-toggle {
    word-break: break-all;
    display: flex;
    justify-content: space-between;
    text-align: left;
    .check {
      width: 15px;
      margin: 0px 12px;
    }
  }
  ul {
    overflow: auto;
    max-height: 300px;
    li {
      @include flex_center;
      padding: 15px 0;
      border-bottom: solid 1px $border_color;
      cursor: pointer;
      .li-inner {
        width: 100%;
        height: 100%;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
</style>
