import httpClient from '@/http-client';

const FlagSurveyNoticeManager = function _FlagSurveyNoticeManager() {
  const manager = Object.create(FlagSurveyNoticeManager.prototype);
  return manager;
};

FlagSurveyNoticeManager.prototype = {
  async fetchFlagSurveyNotices(payload) {
    const res = await httpClient.get(
      `/flagsurvey_notice?page=${payload.page}&limit=${payload.limit}`,
    );
    return res.data;
  },
  async fetchFlagSurveyNotice(payload) {
    const res = await httpClient.get(
      `/flagsurvey_notice/${payload.flagsurvey_id}?announced_id=${payload.announced_id}`,
    );
    return res.data;
  },
};

export default FlagSurveyNoticeManager();
