<template>
  <section class="as-block">
    <div class="headline -wrap">
      <img class="icon" height="20" src="@/assets/img/file_white.svg" />
      <p>
        マイページ操作履歴
        <br class="-spView" />
      </p>
    </div>

    <div class="inner">
      <div class="responsive-overflow">
        <table class="table-logs">
          <thead>
            <tr>
              <th>日時</th>
              <th>操作履歴</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="mypageLog in mypageLogs" :key="mypageLog.id">
              <td>
                <p class="area-files_createdat">
                  {{
                    $utils.parseDateTime(
                      mypageLog.created_at,
                      'YYYY年MM月DD日(ddd) HH:mm',
                    )
                  }}
                </p>
              </td>
              <td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="$utils.sanitize(mypageLog.content)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

import applicantService from '@/services/applicants';

export default defineComponent({
  name: 'MypageLog',
  setup(props, context) {
    const store = useStore();
    const mypageLogs = ref([]);

    // methods
    const fetchMypageLogs = async () => {
      const res = await applicantService.fetchMypageLogs({
        id: store.getters['applicant/applicant'].id,
      });
      if (res.success === true) mypageLogs.value = res.mypage_logs;
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchMypageLogs();
    });

    return { mypageLogs };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.as-block {
  margin-bottom: 20px;
  background-color: #fff;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  > .headline.-wrap {
    display: flex;
    align-items: center;
  }
  > .headline .small {
    font-size: 1.1rem;
  }
  .inner {
    padding: 30px 20px;
    .file_upload_btn {
      margin-bottom: 20px;
    }
  }
}

.responsive-overflow {
  max-height: 400px;
  overflow-y: scroll;
}

.table-logs {
  width: 100%;
  font-size: 1.2rem;
  thead {
    background-color: #e2e2e2;
    th {
      padding: 15px;
      text-align: left;
      &:nth-child(1) {
        width: 210px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e2e2e2;
      vertical-align: top;
    }
    td {
      padding: 10px;
      line-height: 1.5;
      vertical-align: top;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .table-logs {
    white-space: nowrap;
  }
}

@media (max-width: ($media_query_sp)) {
  .table-logs {
    thead {
      th {
        &:nth-child(1) {
          width: auto;
        }
      }
    }
  }
}
</style>
