<!-- eslint-disable vue/no-v-for-template-key -->
<!-- :key="`applicant_${i}`" の使用箇所で Vue2 ESLint エラーと Vue3 の ES Lint エラー が同時に警告されるため、Vue3 の記法に合わせて <template /> タグ内に :key を記述し、ESlint のエラーを無視する。 -->
<template>
  <div
    v-if="selection.extendedProps"
    ref="refCalendarDetail"
    class="calendar-detail"
    :class="{
      'is-show-detail': isShowDetail,
      'is-google-calendar': selection.extendedProps.isGoogleCalendar,
    }"
    :style="styles.data"
  >
    <div class="selection-header">
      <div
        class="selection-header_inner"
        :class="`is-${selection.extendedProps.color.slice(1, 7)}`"
      >
        <p class="selection-title">
          {{ selection.title }}
        </p>
        <button
          v-if="selection.extendedProps.isGoogleCalendar !== true"
          class="btn btn-availability"
          @click="openSelectionDetail(selection.id)"
        >
          <img
            slot="icon"
            class="icon"
            src="@/assets/img/search_white.svg"
            alt
          />
          詳細
        </button>
        <div class="button_close" @click="$emit('onClickClose')">
          <img
            slot="icon"
            class="icon"
            src="@/assets/img/close_black.svg"
            alt
          />
        </div>
      </div>
      <p class="selection-data">{{ selection.extendedProps.date }}</p>
    </div>
    <div
      v-if="
        selection.title && selection.extendedProps.isGoogleCalendar !== true
      "
      class="body"
    >
      <div class="message">
        <template v-if="selection.extendedProps.zoomId">
          <p class="selection-head">Zoomの情報</p>
          <div class="zoom_info">
            <div class="zoom_content zoom_id_area">
              <p class="zoom_display_area-text">
                ミーティングID: {{ selection.extendedProps.zoomId }}
              </p>
            </div>
            <div class="zoom_content zoom_url_area">
              <div>
                <p class="zoom_display_area-title">ホスト用URL</p>
                <p class="zoom_display_area-text">
                  ホスト: {{ selection.extendedProps.zoomHostName }}
                </p>
              </div>
              <div>
                <a
                  :href="selection.extendedProps.zoomStartUrl"
                  target="_blank"
                  rel="noopener"
                >
                  <button class="open_button btn btn-availability">
                    <span>開く</span>
                  </button>
                </a>
                <button-clipboard-copy
                  class="copy_button"
                  :text="selection.extendedProps.zoomStartUrl"
                  :is-blue-icon="true"
                  blue-button-text="コピー"
                />
              </div>
            </div>
            <div class="zoom_content zoom_url_area">
              <p class="zoom_display_area-title zoom_join_url">参加者用URL</p>
              <div>
                <a
                  :href="selection.extendedProps.zoomJoinUrl"
                  target="_blank"
                  rel="noopener"
                >
                  <button class="open_button btn btn-availability">
                    <span>開く</span>
                  </button>
                </a>
                <button-clipboard-copy
                  class="copy_button"
                  :text="selection.extendedProps.zoomJoinUrl"
                  :is-blue-icon="true"
                  blue-button-text="コピー"
                />
              </div>
            </div>
          </div>
        </template>
        <p class="selection-head">管理用メモ</p>
        <p class="selection-text">
          <template
            v-if="
              selection.extendedProps.description &&
              selection.extendedProps.description.length > 0
            "
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="selection-context"
              v-html="$utils.sanitize(selection.extendedProps.description)"
            />
            <!-- eslint-enable vue/no-v-html -->
          </template>
          <span v-else>管理用メモはありません</span>
        </p>
        <p class="selection-head">応募者向け連絡事項</p>
        <p class="selection-text">
          <template
            v-if="
              selection.extendedProps.notice &&
              selection.extendedProps.notice.length > 0
            "
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="selection-context"
              v-html="$utils.sanitize(selection.extendedProps.notice)"
            />
            <!-- eslint-enable vue/no-v-html -->
          </template>
          <span v-else>応募者向け連絡事項はありません</span>
        </p>
        <p class="selection-head">選考の担当者</p>
        <div class="selection-text">
          <template v-if="selection.extendedProps.staffs.length > 0">
            <template v-for="(staff, i) in selection.extendedProps.staffs">
              {{ `${staff.lastname} ${staff.firstname}`
              }}{{
                `${selection.extendedProps.staffs.length - 1 > i ? '・' : ''}`
              }}
            </template>
          </template>
          <span v-else>選考の担当者は割り当てられていません</span>
        </div>
        <p class="selection-head">参加予定の応募者名</p>
        <div class="selection-text">
          <template v-if="selection.extendedProps.applicants.length > 0">
            <template
              v-for="(applicant, i) in selection.extendedProps.applicants"
              :key="`applicant_${i}`"
            >
              <router-link
                :to="{
                  name: 'ApplicantDetail',
                  params: { id: applicant.id },
                  query: { menu: 0 },
                }"
                target="_blank"
              >
                {{ `${applicant.lastname} ${applicant.firstname}` }}
              </router-link>
              {{
                `${
                  selection.extendedProps.applicants.length - 1 > i ? '・' : ''
                }`
              }}
            </template>
          </template>
          <span v-else>参加予定の応募者はいません</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import ButtonClipboardCopy from '@/components/ui/buttons/components/ButtonClipboardCopy';

export default defineComponent({
  name: 'PopupCalendarDetail',
  components: { ButtonClipboardCopy },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Object,
      default: () => {},
    },
    positionLeft: {
      type: Number,
      required: true,
    },
    positionTop: {
      type: Number,
      required: true,
    },
    positionBottom: {
      type: Number,
      required: true,
    },
    calendarCellWidth: {
      type: Number,
      required: true,
    },
    calendarHeight: {
      type: Number,
      required: true,
    },
  },
  emits: ['onClickClose'],
  setup(props, context) {
    const router = useRouter();
    const refCalendarDetail = ref(null);
    const isShowDetail = ref(false);
    const styles = reactive({ data: {} });

    // methods
    const openSelectionDetail = selectionId => {
      context.emit('onClickClose');
      router.push({
        name: 'SelectionDetail',
        params: { id: selectionId },
        hash: `#venue_id_${props.selection.extendedProps.venueId}`,
      });
    };
    const getPositionLeft = () => {
      const bodyWidth = document.body.clientWidth;
      const popupWidth = refCalendarDetail.value.getBoundingClientRect().width;
      // サイドバーがある場合ない場合でのposition leftの調整
      const sidebarWidthAdjuster = bodyWidth > 1024 ? 220 : 50;
      // カレンダーをrelativeとしてのleft相対座標
      const leftRelative = props.positionLeft - sidebarWidthAdjuster;
      if (leftRelative - props.calendarCellWidth > popupWidth) {
        // 左付きでpopupがカレンダーからはみ出ない場合、左付き表示
        return `${leftRelative - props.calendarCellWidth - popupWidth + 15}px`;
      } else if (bodyWidth - props.positionLeft > popupWidth) {
        // 左付きでpopupがカレンダーからはみ出る、かつ右付きでカレンダーからはみ出ない場合、右付き表示
        return `${leftRelative}px`;
      }
      // 左付きでpopupがカレンダーからはみ出る、かつ右付きでもカレンダーからはみ出る場合、left:20px固定表示
      return '20px';
    };
    const getStylesPosition = (newStyles = {}) => {
      newStyles.left = getPositionLeft();
      const popupHeight =
        refCalendarDetail.value.getBoundingClientRect().height;
      if (props.calendarHeight - props.positionTop > popupHeight) {
        // 上付きでpopupがカレンダーからはみ出さない場合、上付き表示
        newStyles.top = `${props.positionTop}px`;
      } else if (props.positionTop > popupHeight) {
        // 上付きでpopupがカレンダーからはみ出る、かつ下付きでカレンダーからはみ出さない場合、下付き表示
        newStyles.bottom = `${props.positionBottom}px`;
      } else {
        // 上付きでpopupがカレンダーからはみ出るかつ、下付きでもカレンダーからはみ出る場合、bottom:20px固定表示
        // (popupのコンテンツ高さが大きすぎる場合の対応)
        newStyles.bottom = '20px';
      }
      return newStyles;
    };

    onMounted(() => {
      isShowDetail.value = false;
      styles.data = getStylesPosition();
      isShowDetail.value = true;
    });

    return { isShowDetail, styles, refCalendarDetail, openSelectionDetail };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/fullcalendar-colors.scss';

.calendar-detail {
  position: absolute;
  width: 470px;
  min-height: 240px;
  max-height: 860px;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.16);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.2s;
  &.is-show-detail {
    opacity: 1;
  }
  &.is-google-calendar {
    min-height: auto;
    padding-bottom: 10px;
  }
  .body {
    word-break: break-all;
    padding: 0 15px 15px;
    .message {
      max-height: calc(860px - 83px - 20px);
      padding-left: 25px;
      line-height: 1.75;
      overflow-y: auto;
    }
  }
}

.selection-header {
  padding: 15px;
}

.selection-header_inner {
  display: flex;
  &:before {
    content: '';
    display: block;
    max-width: 16px;
    min-width: 16px;
    height: 16px;
    margin-top: 9px;
    margin-right: 10px;
    border: 1px solid #cfcfcf;
    border-radius: 50%;
  }
  &.is-assignment {
    &:before {
      background-color: #3788d8;
      border: none;
    }
  }
}

.selection-title {
  margin: 2px auto 0 0;
  font-size: 22px;
  line-height: 1.4;
  font-weight: bold;
  word-break: break-all;
}
.selection-data {
  margin-top: 5px;
  padding-left: 25px;
  font-size: 14px;
}

.btn-availability {
  padding: 0;
  margin: 0 20px;
  min-width: 66px;
  height: 30px;
  font-size: 14px;
}

.button_close {
  cursor: pointer;
  margin-top: 6px;
  > img {
    width: 14px;
  }
}

.selection-head {
  padding: 5px 0 5px 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #f3f3f3;
}

.selection-subhead {
  font-weight: bold;
}

.selection-text {
  margin-bottom: 20px;
  font-size: 14px;
  > span {
    color: #999;
  }
  > a {
    color: #333;
    // text-decoration: underline;
    padding-bottom: 2px;
    border-bottom: 1px dotted;
    &:hover {
      color: #3788d8;
    }
  }
}

.selection-context {
  white-space: pre-wrap;
  word-break: break-word;
}

:deep(.zoom_info) {
  .zoom_content {
    margin-bottom: 10px;
    padding: 7px 0 11px;
    border-bottom: solid 1px #e6e6e6;

    &:last-child {
      border-bottom: none;
    }
  }

  .zoom_display_area-title {
    font-weight: bold;
    font-size: 15px;
  }

  .zoom_url_area {
    display: flex;
    justify-content: space-between;

    .zoom_display_area-text {
      max-width: 245px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .zoom_join_url {
      margin-top: 10.5px;
    }
  }

  button {
    margin: 7px 0 7px 7px;
    padding: 2.3px 8px;
    height: 30px;
    border-radius: 4px;

    &.open_button {
      path {
        fill: $white;
        stroke: $white;
      }

      span::before {
        content: '';
        mask-image: url(../../../../assets/img/icon_external_links2.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        display: inline-block;
        position: relative;
        background-color: #ffffff;
        height: 13px;
        width: 13px;
        top: 2px;
        margin-right: 3px;
      }
    }
    &.copy_button {
      background-color: $white;
      color: #0085fa !important;
      border: solid 1px $mochica_color;

      &:hover {
        background-color: $mochica_color_gray2;
      }
    }

    path {
      fill: $mochica_color;
      stroke: $mochica_color;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .calendar-detail {
    width: 100%;
    margin-left: -20px;
  }
  :deep(.modal-container) {
    width: auto;
    margin: 0 auto;
  }
}
</style>
