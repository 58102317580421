<template>
  <div class="template_content">
    <div class="left_content">
      <div>
        <img class="mail_icon_svg" src="@/assets/img/icon_email.svg" />
        <span class="mail_big_title">
          メール
          <template v-if="isTemplate">テンプレート</template>
        </span>
        <hr class="hr_mail_fixphrase" />
      </div>
      <div class="mail_temp">
        <p>
          連絡方法が
          <b>メール</b>
          の応募者へ届きます。
        </p>
        <p v-if="emailDescription.length">
          {{ emailDescription }}
        </p>
        <div class="mail_input_area">
          <input
            :value="emailTitle"
            :maxlength="emailTitleLimit"
            name="mail_title"
            type="text"
            data-vv-as="メールタイトル"
            placeholder="件名を入力してください"
            class="mail_title"
            :class="{ 'is-danger': errorEmailTitle.length > 0 }"
            :disabled="isDisabled"
            @input="$emit('updateEmailTitle', $event.target.value)"
          />
          <textarea
            :value="emailContent"
            :maxlength="emailContentLimit"
            name="mail_content"
            data-vv-as="メール内容"
            placeholder="本文を入力してください"
            class="mail_content"
            :class="{ 'is-danger': errorEmailContent.length > 0 }"
            :disabled="isDisabled"
            @input="$emit('updateEmailContent', $event.target.value)"
          ></textarea>
        </div>
        <span v-show="errorEmailTitle.length > 0" class="fa fa-warning">
          {{ errorEmailTitle }}
        </span>
        <span v-show="errorEmailContent.length > 0" class="fa fa-warning">
          {{ errorEmailContent }}
        </span>
        <ul class="footer_caution">
          <li v-for="(caption, i) in emailCaptions" :key="`emailCaption_${i}`">
            {{ caption }}
          </li>
          <li
            v-if="
              isDisabledLine === true &&
              (isDisabled === false || files.length > 0)
            "
          >
            添付ファイルは３つまで、かつ合計3MBまで添付できます。
          </li>
        </ul>
        <attachment-file
          v-if="
            isDisabledLine === true &&
            (isDisabled === false || files.length > 0)
          "
          ref="emailFile"
          :files="files"
          :is-disabled="isDisabled"
          :is-notice-history="isNoticeHistory"
          @setFile="setMailFile"
          @deleteFile="deleteMailFile"
        />
      </div>
    </div>
    <div class="right_content">
      <div>
        <img class="line_icon_svg" src="@/assets/img/icon_line.svg" />
        <span class="line_big_title">
          LINE
          <template v-if="isTemplate">テンプレート</template>
        </span>
        <hr class="hr_line_fixphrase" />
      </div>
      <div class="line_temp">
        <p>
          連絡方法が
          <b>LINE</b>
          の応募者へ届きます。
        </p>
        <p v-if="lineDescription.length">
          {{ lineDescription }}
        </p>
        <div class="line_input_area">
          <ul class="input_support_tools">
            <li v-if="!isDisabled" class="emoji-picker-wrapper">
              <emoji-picker @selected="appendEmoji" />
            </li>
          </ul>
          <textarea
            :value="lineContent"
            :maxlength="lineContentLimit"
            class="fix_phrase_line_textarea"
            name="line_content"
            data-vv-as="LINE 内容"
            :class="{ 'is-danger': errorLineContent.length > 0 }"
            placeholder="メッセージを入力してください"
            :disabled="isDisabled"
            @input="$emit('updateLineContent', $event.target.value)"
          ></textarea>
        </div>
        <span v-show="errorLineContent.length > 0" class="fa fa-warning">
          {{ errorLineContent }}
        </span>
        <ul class="footer_caution">
          <li v-for="(caption, i) in lineCaptions" :key="`lineCaption_${i}`">
            {{ caption }}
          </li>
        </ul>
      </div>
      <div v-if="isDisabledLine" class="disabled_line_area_wrapper">
        <div class="disabled_line_area">
          <p>
            「全員にメールで送信」にチェックが入っているため
            <br />
            LINEの応募者にもメールで送信されます
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { defineComponent, ref } from 'vue';

import EmojiPicker from '@/components/ui/menus/components/EmojiPicker';
import AttachmentFile from '@/components/ui/attachmentFile/components/AttachmentFile';

export default defineComponent({
  name: 'InputLineAndEmail',
  components: { EmojiPicker, AttachmentFile },
  props: {
    lineContent: {
      type: String,
      default: '',
    },
    lineContentLimit: {
      type: Number,
      default: 1000,
    },
    emailTitle: {
      type: String,
      default: '',
    },
    emailTitleLimit: {
      type: Number,
      default: 50,
    },
    emailContent: {
      type: String,
      default: '',
    },
    emailContentLimit: {
      type: Number,
      default: 1000,
    },
    lineDescription: {
      type: String,
      default: '',
    },
    emailDescription: {
      type: String,
      default: '',
    },
    lineCaptions: {
      type: Array,
      default: () => [],
    },
    emailCaptions: {
      type: Array,
      default: () => [],
    },
    files: {
      type: Array,
      default: () => [],
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDisabledLine: {
      type: Boolean,
      default: false,
    },
    isNoticeHistory: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'updateLineContent',
    'updateEmailTitle',
    'updateEmailContent',
    'updateFiles',
  ],
  setup(props, context) {
    const emailFiles = ref(cloneDeep(props.files));
    const errorLineContent = ref('');
    const errorEmailTitle = ref('');
    const errorEmailContent = ref('');

    const appendEmoji = emoji => {
      const textArea = document.querySelector('.fix_phrase_line_textarea');
      const beforeText = textArea.value.substring(0, textArea.selectionStart);
      const afterText = textArea.value.substring(
        textArea.selectionStart,
        textArea.value.length,
      );
      context.emit('updateLineContent', `${beforeText}${emoji}${afterText}`);
    };
    const setMailFile = file => {
      emailFiles.value.push(file);
      context.emit('updateFiles', emailFiles.value);
    };
    const deleteMailFile = fileId => {
      emailFiles.value = emailFiles.value.filter(file => file.id !== fileId);
      context.emit('updateFiles', emailFiles.value);
    };
    const validateAll = () => {
      let flag = true;
      errorLineContent.value = '';
      errorEmailTitle.value = '';
      errorEmailContent.value = '';
      if (props.isDisabledLine !== true) {
        if (props.lineContent.length === 0) {
          flag = false;
          errorLineContent.value = 'メッセージを入力してください';
        }
      }
      if (props.emailTitle.length === 0) {
        flag = false;
        errorEmailTitle.value = '件名を入力してください';
      }
      if (props.emailContent.length === 0) {
        flag = false;
        errorEmailContent.value = '本文を入力してください';
      }
      return flag;
    };
    return {
      errorLineContent,
      errorEmailTitle,
      errorEmailContent,
      appendEmoji,
      setMailFile,
      deleteMailFile,
      validateAll,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.hr_line_fixphrase {
  border: 1px solid #e2e2e2;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.hr_mail_fixphrase {
  border: 1px solid #e2e2e2;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.line_temp,
.mail_temp {
  > p {
    min-height: 1rem;
    margin-bottom: 0.5rem;
  }
}

.template_content {
  display: flex;
  justify-content: space-between;
  align-items:stretch input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #777777;
  }
  input:focus,
  select:focus {
    border: 1px green solid;
  }
  input:focus.is-danger,
  .is-danger {
    border: 1px $mochica_color_red solid !important;
  }
  .fa-warning {
    color: #c31e1e;
    padding-top: 4px;
  }
  .fa {
    display: block;
  }
}

.line_big_title {
  font-weight: bold;
  font-size: 1.3rem;
  color: #06c755;
}

.mail_big_title {
  font-weight: bold;
  font-size: 1.3rem;
  color: #d28300;
}

.line_icon_svg {
  margin-right: 10px;
  width: 24px;
  vertical-align: -6px;
}

.mail_icon_svg {
  margin: 4.5px 10px 4.5px 0;
  width: 20px;
  vertical-align: -7px;
}

.left_content {
  width: 49%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  border-top: 5px solid #d28300;
  border-radius: 4px;
  padding: 1.2rem 1.2rem 1.2rem 1.2rem;
}

.disabled_line_area_wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: -5px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 6px);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  z-index: 1;
}

.disabled_line_area {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 34px 40px;
  font-size: 1.1rem;
  line-height: 1.6;
  background: #f9f9f9;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.right_content {
  position: relative;
  width: 49%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  border-top: 5px solid #06c755;
  border-radius: 4px;
  padding: 1.2rem 1.2rem 1.2rem 1.2rem;
}

.line_input_area {
  margin-top: 1.5rem;
  border: 1px solid $tab_border_color;
  border-radius: 4px;
  background: #fff;
  textarea {
    display: block;
    width: 100%;
    height: 17rem;
    min-height: 17rem;
    max-height: 17rem;
    padding: 1rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6em;
    border: none;
  }
  textarea::placeholder,
  input::placeholder {
    font-family: 'Noto Sans JP', sans-serif;
    color: #777;
  }
}

.mail_input_area {
  margin-top: 1.5rem;
  background: #fff;
  border: 1px solid $tab_border_color;
  border-radius: 4px;
  .mail_title {
    width: 100%;
    padding: 2rem 1rem 2rem 1rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6em;
    border: 1px solid #b6b6b6;
    border-width: 0px 0px 1px 0px;
    border-radius: 4px 4px 0 0;
  }
  textarea {
    display: block;
    width: 100%;
    height: 17rem;
    min-height: 17rem;
    max-height: 17rem;
    padding: 1rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6em;
    border: none;
  }
  textarea::placeholder,
  input::placeholder {
    font-family: 'Noto Sans JP', sans-serif;
    color: #777;
  }
}

.input_support_tools {
  display: flex;
  min-height: 4.4rem;
  background-color: #f6f6f6b2;
  font-size: 1.1rem;
  padding: 16px;
  > li {
    height: 1.5rem;
    line-height: 1;
    margin-right: 1.4rem;
    cursor: pointer;
    img {
      height: 1.5rem;
      vertical-align: -0.4rem;
    }
    > span {
      margin-left: -0.6rem;
      font-size: 1rem;
      color: #777;
    }
  }
}

.footer_caution {
  margin-top: 10px;
  > li {
    margin-bottom: 10px;
    font-size: 1rem;
    &:before {
      content: '※';
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .template_content {
    display: block;
    margin-top: 10px;
    .left_content {
      width: 100%;
    }
    .right_content {
      width: 100%;
      margin-top: 20px;
    }
  }
  .disabled_line_area {
    padding: 20px 10px;
    font-size: 1rem;
  }
}
</style>
