<template>
  <main class="flexbox width-sp-limit">
    <div class="contents">
      <div class="form_desc_wrapper contents_wrapper">
        <div class="mochica_desc">
          <div class="company_name">{{ companyName }}</div>
          <div class="reserve_title">ご案内</div>
        </div>
        <p class="mail_desc">
          {{ companyName }}より「{{ selectionName }}」についてのお知らせです。
          <br />
          下のボタンより確認をして下さい。
        </p>
        <button
          class="one_time_btn"
          @click.prevent="$emit('openInputSelectionForm')"
        >
          お知らせを確認
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReserveTop',
  props: {
    companyName: {
      type: String,
      required: true,
    },
    selectionName: {
      type: String,
      required: true,
    },
  },
  emits: ['openInputSelectionForm'],
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.flexbox {
  justify-content: center;
}

.contents_wrapper {
  padding: 0 8%;
}

.mochica_desc {
  text-align: center;
  padding: 3rem 0 3rem;
  .company_name {
    font-size: 1.8rem;
    font-weight: bold;
  }
  .reserve_title {
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.one_time_btn {
  width: 100%;
  border: none;
  background-color: #656565;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  border-radius: 20px;
  cursor: pointer;
}

.contents {
  width: 100%;
  margin: 0 auto;
}

.mail_desc {
  text-align: left;
  padding-bottom: 10px;
}
.form_desc_wrapper.contents_wrapper {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 600px) {
  main > div.contents {
    padding-bottom: 0px;
  }
}
</style>
