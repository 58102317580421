import httpClient from '@/http-client';

const NotificationsManager = function _NotificationsManager() {
  const manager = Object.create(NotificationsManager.prototype);
  return manager;
};

const getFormData = payload => {
  const formData = new FormData();
  formData.append('graduated_year', payload.graduated_year);
  formData.append('staff_id', payload.staff_id);
  formData.append('schedule_time', payload.schedule_time);
  formData.append('notice_title', payload.notice_title);
  formData.append('title', payload.title);
  formData.append('content_email', payload.content_email);
  formData.append('notice_check', payload.notice_check);
  formData.append('force_email', payload.force_email);
  payload.applicant_ids.forEach(value => {
    formData.append('applicant_ids[]', value);
  });
  if (payload.force_email === true) {
    payload.files.forEach(file => {
      if (file.file) {
        formData.append('files', file.file, file.filename);
      } else {
        // アップロードファイルでない場合は削除しないファイルリストに追加する
        formData.append('not_delete_files', file.storage_filename);
      }
    });
  } else {
    formData.append('content_line', payload.content_line);
  }
  return formData;
};

NotificationsManager.prototype = {
  async getAnnounceList(year, tmpPage, searchBody) {
    const res = await httpClient.post('/announce/send_schedule/getlist', {
      year,
      page: tmpPage,
      name: searchBody.name ? searchBody.name : '',
      word: searchBody.word ? searchBody.word : '',
    });
    return res.data;
  },
  async getAnnounceErrorList(payload) {
    const res = await httpClient.post(
      '/announce/send_schedule/count_error',
      payload,
    );
    return res.data;
  },
  async getNoticeFromId(id) {
    const res = await httpClient.get(`/announce/send_schedule/getlist/${id}`);
    return res.data;
  },
  async getApplicantActivityNotice(applicantId, page = 1) {
    const res = await httpClient.post(
      `/notifications/activity_notice/${applicantId}`,
      { page },
    );
    return res.data;
  },
  async venuesCheck(selectionId, applicantIds) {
    const res = await httpClient.post('/notifications/venues_check', {
      notification: {
        selection_id: selectionId,
        applicant_ids: applicantIds,
      },
    });
    return res.data;
  },
  async sendVenues(selectionId, applicantIds) {
    const res = await httpClient.post('/notifications/venues', {
      notification: { selection_id: selectionId, applicant_ids: applicantIds },
    });
    return res.data;
  },
  async sendResults(selectionId, applicantIds, sendVenue = false) {
    const res = await httpClient.post('/notifications/results', {
      notification: {
        selection_id: selectionId,
        applicant_ids: applicantIds,
        send_venue: sendVenue,
      },
    });
    return res.data;
  },
  async noticeAnnounceCreate(payload) {
    const formData = getFormData(payload);
    const res = await httpClient.post(
      '/announce/send_schedule/create',
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
    return res.data;
  },
  async noticeAnnounceUpdate(payload) {
    const formData = getFormData(payload);
    formData.append('notice_id', payload.notice_id);
    formData.append('is_active', payload.is_active);
    const res = await httpClient.post(
      '/announce/send_schedule/update',
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
    return res.data;
  },
  async noticeAnnounceDelete(payload) {
    const res = await httpClient.post(
      '/announce/send_schedule/delete',
      payload,
    );
    return res.data;
  },
  async noticeAnnounceSendJust(payload) {
    let formData = new FormData();
    if (payload.new_create === true) {
      formData = getFormData(payload);
      formData.append('new_create', true);
    } else {
      // 新規作成時以外はstaff_id, notice_idのみリクエスト
      formData.append('staff_id', payload.staff_id);
      formData.append('notice_id', payload.notice_id);
      formData.append('new_create', false);
    }
    const res = await httpClient.post(
      '/announce/send_schedule/send_justnow',
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
    return res.data;
  },
  async notificationDuplicate(payload) {
    const res = await httpClient.post(
      '/announce/send_schedule/create_duplication',
      payload,
    );
    return res.data;
  },
  async noticeCheck(token) {
    const res = await httpClient.post('/notice_ext/notice_check', {
      token,
    });
    return res.data;
  },
};

export default NotificationsManager();
