<template>
  <div>
    <table class="table-typeA">
      <thead class="thead">
        <tr class="row">
          <th class="item-th">シート名</th>
          <th class="item-th">シートUID</th>
          <th class="item-th">シートGID</th>
          <th class="item-th">登録者名</th>
          <th class="item-th">登録日</th>
          <th class="item-th">更新日</th>
          <th class="item-th">&nbsp;</th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr v-for="survey in surveyList" :key="survey.id" class="row">
          <td data-label="シート名" class="item-td">
            <p>{{ survey.sheet_name }}</p>
          </td>
          <td data-label="シートUID" class="item-td">
            <!-- FIXME: 表示崩れ防止のため下記で修正すること -->
            <!-- <p>{{ $utils.textEllipsis(survey.sheet_uid) }}</p> -->
            <p>{{ survey.sheet_uid }}</p>
          </td>
          <td data-label="シートGID" class="item-td">
            <p>{{ survey.sheet_gid }}</p>
          </td>
          <td data-label="登録者名" class="item-td">
            <p>{{ survey.staff.lastname }}{{ survey.staff.firstname }}</p>
          </td>
          <td data-label="登録日" class="item-td">
            <p>{{ $utils.parseDateTime(survey.created_at, 'YYYY/MM/DD') }}</p>
          </td>
          <td data-label="更新日" class="item-td">
            <p>{{ $utils.parseDateTime(survey.updated_at, 'YYYY/MM/DD') }}</p>
          </td>
          <td class="item-td item-btns">
            <button
              v-if="isToken"
              class="btn btn-availability"
              @click="
                openModalRenewSurvey(
                  survey.id,
                  survey.sheet_uid,
                  survey.sheet_gid,
                  survey.sheet_name,
                )
              "
            >
              <img class="icon" src="@/assets/img/loading_white.svg" alt="" />
              更新
            </button>
            <button
              class="btn btn-unavailable"
              @click="
                openModalDeleteSurvey(
                  survey.id,
                  survey.sheet_uid,
                  survey.sheet_gid,
                )
              "
            >
              <img class="icon" src="@/assets/img/delete_white.svg" alt="" />
              削除
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ListViewSurvey',
  props: {
    surveyList: {
      type: Array,
      default: () => [],
    },
    isToken: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openModalDeleteSurvey', 'openModalRenewSurvey'],
  setup(props, context) {
    const store = useStore();
    const openModalDeleteSurvey = (sheetId, Uid, Gid) => {
      context.emit('openModalDeleteSurvey', {
        graduated_id: store.getters['graduateds/selectedGraduatedId'],
        sheet_id: sheetId,
        spreadsheet_uid: Uid,
        spreadsheet_gid: Gid,
      });
    };
    const openModalRenewSurvey = (sheetId, Uid, Gid, sheetName) => {
      context.emit('openModalRenewSurvey', {
        graduated_id: store.getters['graduateds/selectedGraduatedId'],
        sheet_id: sheetId,
        sheet_uid: Uid,
        sheet_gid: Gid,
        sheet_name: sheetName,
      });
    };
    return {
      openModalDeleteSurvey,
      openModalRenewSurvey,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.table-typeA {
  font-size: 1.4rem;
}
@media (max-width: ($media_query_sp)) {
  .table-typeA {
    font-size: 1rem;
    .tbody .item-td:not(.item-btns):before {
      width: 80px;
    }
  }
}
</style>
