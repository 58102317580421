<template>
  <transition name="fade">
    <div v-if="$store.getters['page/isPageLoaded']" class="personal_setting">
      <ul class="personal_setting_list area-edit">
        <li class="personal_setting_list_item">
          <edit-text
            :id="staff.id"
            class="title"
            :title="'社員名'"
            :type="'staff_name'"
            :l-text="staff.lastname"
            :f-text="staff.firstname"
            @commit="commitStaffInfo"
          />
        </li>
        <li class="personal_setting_list_item">
          <h2 class="personal_setting_list_item_ttl">メールアドレス</h2>
          <p :id="staff.id" class="personal_setting_list_item_value">
            {{ staff.email }}
          </p>
        </li>
        <li class="personal_setting_list_item area-edit">
          <edit-pass
            :id="staff.id"
            class="title"
            :title="'パスワード'"
            :type="'password'"
            @commit="commitChangePass"
          />
        </li>
        <li class="personal_setting_list_item area-edit">
          <h2 class="personal_setting_list_item_ttl">権限</h2>
          <p class="personal_setting_list_item_value">
            {{ description }}
          </p>
        </li>
        <li class="personal_setting_list_item area-edit">
          <edit-sign
            :id="staff.id"
            class="title"
            :title="'メールの署名'"
            :sign="staffInfo.data.sign"
            @commit="commitChangeSign"
          />
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import staffsService from '@/services/staffs';
import EditText from '@/components/page/configs/personal/components/EditText';
import EditPass from '@/components/page/configs/personal/components/EditPass';
import EditSign from '@/components/page/configs/personal/components/EditSign';

export default defineComponent({
  name: 'Personal',
  components: { EditText, EditPass, EditSign },
  setup(props, context) {
    const store = useStore();
    const staffInfo = reactive({ data: {} });
    const lastname = ref('');
    const firstname = ref('');
    const description = ref('');
    const sign = ref('');
    const tel = ref('');

    // computed
    const staff = computed(() => store.getters['staff/staff']);

    // methods
    const fetchAll = async () => {
      staffInfo.data = await staffsService.fetchStaff(staff.value.id);
      description.value = staffInfo.data.role.description;
      sign.value = staffInfo.data.sign;
      tel.value = staffInfo.data.tel;
      lastname.value = staffInfo.data.lastname;
      firstname.value = staffInfo.data.firstname;
    };
    const commitStaffInfo = async payload => {
      const res = await staffsService.updateStaff(
        payload.id,
        payload.lText,
        payload.fText,
        sign.value,
        tel.value,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? '名前を変更しました。'
            : '名前の変更に失敗しました。',
        type: res.success,
      });
      await fetchAll();
    };
    const commitChangePass = async payload => {
      const res = await staffsService.updatePassWord(
        payload.id,
        payload.current,
        payload.new,
        payload.confirm,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? 'パスワードを変更しました。'
            : 'パスワードの変更に失敗しました。',
        type: res.success,
      });
      await fetchAll();
    };
    const commitChangeSign = async payload => {
      const res = await staffsService.updateStaff(
        payload.id,
        lastname.value,
        firstname.value,
        payload.sign,
        tel.value,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true ? 'メールの署名を変更しました' : res.message,
        type: res.success,
      });
      await fetchAll();
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      staffInfo,
      description,
      sign,
      tel,
      staff,
      commitStaffInfo,
      commitChangePass,
      commitChangeSign,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@include validation_effects2();

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.personal_setting_list_item:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e3e3;
}

.personal_setting_list_item_value {
  font-size: 1.4rem;
}

:deep(.personal_setting_list_item_ttl) {
  position: relative;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}
:deep(.btn-s) {
  position: absolute;
  top: -5px;
  left: 500px;
}

@media (max-width: ($media_query_tablet)) {
  :deep(.btn-s) {
    left: auto;
    right: 0;
  }
}
</style>
