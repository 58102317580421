<template>
  <div class="form__wrapper">
    <div class="form__inner">
      <div class="form__inner__contents">
        <div class="smartphone">
          <div class="main-wrapper">
            <reserve-top
              v-if="mode === 'reserveTop'"
              :company-name="companyName"
              :selection-name="selectionName"
              @openInputSelectionForm="openInputSelectionForm"
            />
            <input-selection-form
              v-if="mode === 'inputSelectionForm'"
              :company-name="companyName"
              :selection-form="selectionForm"
              :is-loading="isLoading"
              @postSelectionTime="postSelectionTime"
            />
            <reserve-result
              v-if="mode === 'reserveResult'"
              :company-name="companyName"
              :reserve-result-data="reserveResultData"
            />
            <div v-if="mode === 'errorResult'" class="error_contents">
              {{ errorMessage }}
            </div>
          </div>
          <notification />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import qs from 'qs';
import reserveService from '@/services/reserves';

import Notification from '@/components/ui/notification/components/Notification';
import ReserveTop from '@/components/page/forms/mail_reserve/components/ReserveTop';
import ReserveResult from '@/components/page/forms/mail_reserve/components/ReserveResult';
import InputSelectionForm from '@/components/page/forms/mail_reserve/components/InputSelectionForm';

const ReserveMode = {
  generateOtp: 'generateOtp',
  associateMail: 'associateMail',
  askOtp: 'askOtp',
  inputForm: 'inputForm',
  errorResult: 'errorResult',
  inputSelectionForm: 'inputSelectionForm',
  reserveResult: 'reserveResult',
  reserveTop: 'reserveTop',
};

export default defineComponent({
  name: 'MailReserve',
  components: {
    Notification,
    ReserveTop,
    ReserveResult,
    InputSelectionForm,
  },
  setup(props, context) {
    const store = useStore();
    const token = ref(null);
    const errorMessage = ref(
      '通信に失敗したか、このURLの期限が切れている可能性があります。',
    );
    const mode = ref(ReserveMode.reserveTop);
    const selectionForm = ref(null);
    const reserveResultData = ref(null);
    const companyName = ref('');
    const selectionName = ref('');
    const isLoading = ref(false);

    // methods
    const init = async token => {
      const res =
        await reserveService.getSelectionFromApplicantIdSelectionId(token);
      if (res === null) {
        mode.value = ReserveMode.errorResult;
        return;
      }
      if (res.success === false) {
        errorMessage.value = res.message;
        mode.value = ReserveMode.errorResult;
        return;
      }
      selectionForm.value = res;
      companyName.value = res.company.name;
      selectionName.value = res.selection.event.title;
      mode.value = ReserveMode.reserveTop;
    };

    const openInputSelectionForm = () => {
      mode.value = ReserveMode.inputSelectionForm;
    };

    const postSelectionTime = async payload => {
      isLoading.value = true;
      const res = await reserveService.postSelectionTimeFromApplicantIdTimeId({
        token: token.value,
        timetableId: payload.timetableId,
      });
      isLoading.value = false;
      if (res.data.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `${res.data.reason}`,
          type: res.data.success,
        });
        return;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '登録が完了しました。',
        type: res.data.success,
      });
      reserveResultData.value = res.data;
      mode.value = ReserveMode.reserveResult;
    };

    // lifecycle
    onBeforeMount(async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      token.value = query.token;
      await init(token.value);
    });

    return {
      errorMessage,
      mode,
      selectionForm,
      reserveResultData,
      companyName,
      selectionName,
      isLoading,
      openInputSelectionForm,
      postSelectionTime,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@include form_wrapper_css();

/* ここから元の記述 */
.form__inner__contents {
  align-items: unset;
  padding: 2% 0;
}
.smartphone {
  width: 100%;
}
.error_contents {
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
}
footer {
  width: 90%;
  margin: 0 auto;
}
.logo-title {
  width: 15%;
}
.divider {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #efefef;
}

@media screen and (max-width: 600px) {
  .logo-title {
    width: 20%;
  }
}
</style>
