<template>
  <modal :is-view="isVisible === true">
    <template #content>
      <div class="content">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                {{ description }}
              </div>
            </div>
            <div class="modal-header_cancell" @click="$emit('close')">
              <img class="icon" src="@/assets/img/close_black.svg" alt />
            </div>
          </section>
          <section class="modal-body">
            <div class="area-search-condition">
              <div class="row-1 flexbox flexbox-middle">
                <div class="col-right margin-left-auto">
                  <div class="pos-relative applicant_column_block">
                    <div class="visible-applicant-inner">
                      <div
                        class="under-bar-hi-light btn visible-applicant"
                        @click="isVisiblePagerLoad = true"
                      >
                        表示する応募者の件数：{{ loadSetting }}件
                      </div>
                      <float-context-menu
                        :is-view="isVisiblePagerLoad"
                        :list="loadSection"
                        @onClose="isVisiblePagerLoad = false"
                        @onSelected="onSelectedPageLoad"
                      />
                    </div>
                  </div>
                  <div class="area-pager margin-left-auto">
                    <pager
                      :page="page"
                      :count="count"
                      :page-count="pageCount"
                      :load="loadSetting"
                      @move="movePager"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="area-search-list">
              <div class="area-table">
                <table class="area-table-list">
                  <thead class="area-table-list_header">
                    <tr>
                      <th>選考名</th>
                      <th>ステータス</th>
                      <th>名前</th>
                      <th>セイメイ</th>
                      <th>性別</th>
                      <th>学校名</th>
                      <th>学部</th>
                      <th>文理</th>
                      <th>電話番号</th>
                      <th>登録経路</th>
                    </tr>
                  </thead>
                  <tbody class="area-table-list_body">
                    <tr
                      v-for="applicant in applicants"
                      :key="applicant.id"
                      class="area-table-list_body_item"
                    >
                      <td>{{ applicant.status.eventTitle }}</td>
                      <td>{{ applicant.status.progress.description }}</td>
                      <td>{{ applicant.lastname }}{{ applicant.firstname }}</td>
                      <td>
                        {{ applicant.lastnameKana
                        }}{{ applicant.firstnameKana }}
                      </td>
                      <td>{{ applicant.gender.description }}</td>
                      <td>{{ applicant.school }}</td>
                      <td>{{ applicant.faculty }}</td>
                      <td>{{ applicant.departmentCategory.description }}</td>
                      <td>{{ applicant.tel }}</td>
                      <td>{{ applicant.channel }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-body_btn_item back_btn" @click="$emit('close')">
              閉じる
            </div>
          </section>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

import applicantsService from '@/services/applicants';
import { getInitSearchFlagForRequest } from '@/models/applicant-search';
import Modal from '@/components/ui/modals/components/Modal';
import Pager from '@/components/ui/pager/components/Pager';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';

export default defineComponent({
  name: 'ModalSelectionFailApplicants',
  components: { Modal, Pager, FloatContextMenu },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    applicantIds: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(props, context) {
    const loadSection = ref([
      { id: 5, name: '5', selected: false },
      { id: 25, name: '25', selected: true },
      { id: 50, name: '50', selected: false },
      { id: 100, name: '100', selected: false },
      { id: 200, name: '200', selected: false },
    ]);
    const store = useStore();
    const isVisiblePagerLoad = ref(false);
    const applicants = ref([]);
    const page = ref(0);
    const count = ref(0);
    const pageCount = ref(0);

    // computed
    const loadSetting = computed(() => {
      const selectedLoads = loadSection.value.filter(v => v.selected === true);
      if (selectedLoads.length > 0) return selectedLoads[0].id;
      return loadSection.value[0].id;
    });

    // watch
    watch(
      () => props.isVisible,
      async val => {
        if (val === true) await fetchApplicants();
      },
    );

    // methods
    const fetchApplicants = async (tmpLoad = 25, tmpPage = 1) => {
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      if (!graduatedYear) return;
      // ID指定応募者のみ検索
      const searchBody = { target_ids: props.applicantIds };
      const searchFlags = getInitSearchFlagForRequest();
      const res = await applicantsService.fetchApplicantsSearch(
        graduatedYear.year,
        tmpPage,
        tmpLoad,
        searchBody,
        searchFlags,
      );
      applicants.value = res.applicants;
      page.value = res.page;
      count.value = res.count;
      pageCount.value = res.pageCount;
    };
    const movePager = page => {
      fetchApplicants(loadSetting.value, page ? page.next : null);
    };
    const onSelectedPageLoad = ev => {
      loadSection.value = loadSection.value.map(v => ({
        ...v,
        selected: v.id === ev.item.id,
      }));
      isVisiblePagerLoad.value = false;
      fetchApplicants(loadSetting.value);
    };

    return {
      loadSection,
      isVisiblePagerLoad,
      applicants,
      page,
      count,
      pageCount,
      loadSetting,
      movePager,
      onSelectedPageLoad,
    };
  },
});
</script>

<style scoped>
.content {
  width: 100vw;
  height: 100vh;
}
</style>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.content {
  @include full_modal;
  .area-search-condition {
    .row-1 {
      padding: 0;
      margin-bottom: 2rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .col-right {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .applicant_column_block {
          margin-right: 20px;
          .btn {
            display: flex;
            padding-right: 2.4rem;
            font-size: 1.2rem;
            font-weight: normal;
            align-items: center;
            color: #333 !important;
            background-color: #fff;
            &::after {
              content: '';
              width: 6px;
              height: 6px;
              border: 0px;
              border-bottom: solid 2px #178fd0;
              border-right: solid 2px #178fd0;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 10px;
              margin-top: -4px;
            }
          }
        }
      }
    }
    .row-2 {
      margin: 1.2rem 3rem 1.2rem;
    }
  }
  .modal-body {
    .area-table-list {
      min-width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      box-shadow: $box_shadow;
      background: #fff;
      text-align: left;
      margin-bottom: 3rem;
      &_header {
        height: 3.6rem;
        line-height: 3.6rem;
        background: #f7f7f7;
        border-top: 1px solid #e3e3e3;
        th {
          padding-left: 1.2rem;
          text-align: left;
        }
      }
      &_body {
        background: #fff;
        margin-bottom: 3rem;
        &_item {
          height: 3rem;
          line-height: 3rem;
          border-bottom: 1px dashed $border_color;
          td {
            padding-left: 1.2rem;
            border-right: 1px solid #e3e3e3;
            &:last-of-type {
              border-right: none;
            }
          }
        }
      }
    }
    &_btn_item {
      width: 100%;
    }
  }
}
</style>
