<template>
  <modal-dialog
    :alert-flag="modalDialog.alertFlag"
    :is-view="modalDialog.isView"
    :accent-color="modalDialog.accentColor"
    :title="modalDialog.title"
    :message="modalDialog.message"
    :button-text1="modalDialog.buttonText1"
    :button-text2="modalDialog.buttonText2"
    :is-disabled-show-modal-displayed="modalDialog.isDisabledShowModalDisplayed"
    @onSelected="onSelected"
    @onclickOuter="modalDialog.onclickOuter($event)"
  />
</template>

<script>
// Vuexを使わないようにした後に削除対象
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import ModalDialog from '@/components/ui/modals/components/ModalDialog';

export default defineComponent({
  name: 'TopModalDialog',
  components: { ModalDialog },
  setup(props, context) {
    const store = useStore();
    const isSelected = ref(false);
    const modalDialog = computed(
      () => store.getters['ui/modalDialog/modalDialog'],
    );
    watch(
      () => modalDialog.value.isView,
      val => {
        if (val) isSelected.value = false;
      },
    );
    const onSelected = ev => {
      if (isSelected.value !== true) modalDialog.value.onSelected(ev);
      isSelected.value = true;
    };
    return { isSelected, modalDialog, onSelected };
  },
});
</script>

<style scoped></style>
