<template>
  <div v-if="$store.getters['graduateds/graduateds']">
    <navbar
      :is-global-menu="isGlobalMenu"
      @toggleGlobalMenu="toggleGlobalMenu"
    />
    <div class="main-wrapper">
      <main class="flexbox width-app-limit">
        <div class="sidebar" :class="{ '-open': isGlobalMenu }">
          <sidebar @toggleGlobalMenu="toggleGlobalMenu" />
        </div>
        <div class="contents">
          <router-view />
          <div v-if="!$store.getters['page/isPageLoaded']">
            <page-loader />
          </div>
        </div>
      </main>
    </div>
    <modal-applicants />
    <notification />
    <application-error />
    <top-modal-dialog />
  </div>
  <p v-else>卒年データロード中</p>
</template>

<script>
import { defineComponent, ref } from 'vue';

import Notification from '@/components/ui/notification/components/Notification';
import PageLoader from '@/components/ui/loaders/components/PageLoader';
import TopModalDialog from '@/components/ui/modals/components/TopModalDialog';
import Navbar from '@/components/page/top/components/Navbar';
import Sidebar from '@/components/page/top/components/Sidebar';
import ApplicationError from '@/components/page/top/components/ApplicationError';
import ModalApplicants from '@/components/page/top/components/ModalApplicants';

export default defineComponent({
  name: 'Top',
  components: {
    Notification,
    PageLoader,
    TopModalDialog,
    Navbar,
    Sidebar,
    ApplicationError,
    ModalApplicants,
  },
  setup(props, context) {
    const isGlobalMenu = ref(false);
    const toggleGlobalMenu = () => {
      isGlobalMenu.value = !isGlobalMenu.value;
    };
    return { isGlobalMenu, toggleGlobalMenu };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.main-wrapper {
  // height: 100vh;
  background-color: #e2e2e2;
}

.sidebar {
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  width: 180px;
  height: 100vh;
  padding-top: 51px;
  background-color: #fff;
  transition: left 0.2s ease-out;
}

.contents {
  width: 100%;
  padding: 50px 0 0 180px;
}

@media (max-width: ($media_query_tablet)) {
  .main-wrapper {
    height: 100%;
  }
  .contents {
    width: 100%;
    padding: 50px 0 0;
  }
  .sidebar {
    left: -180px;
  }
  .sidebar.-open {
    left: 0;
  }
}
</style>
