<template>
  <float-box :is-view="isVisible" @close="$emit('close')">
    <template #content>
      <div class="container">
        <div class="input-wrapper">
          <input
            v-model="inputText"
            type="text"
            maxlength="128"
            :placeholder="placeholder"
          />
        </div>
        <button-base
          class="btn-registration"
          button-text="新規登録"
          @click="createNewItem"
        />
      </div>
    </template>
  </float-box>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import FloatBox from '@/components/ui/menus/components/FloatBox';

export default defineComponent({
  name: 'FlagItemCreateInput',
  components: { FloatBox },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['createItem'],

  setup(props, context) {
    const store = useStore();
    const inputText = ref('');

    const createNewItem = () => {
      if (inputText.value === '') {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '空欄での登録はできません',
          type: false,
        });
        return;
      }
      context.emit('createItem', inputText.value);
      inputText.value = '';
    };

    return {
      inputText,
      createNewItem,
    };
  },
});
</script>

<style scoped lang="scss">
.container {
  min-width: 320px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input-wrapper {
    padding-right: 10px;

    input {
      min-width: 210px;
      padding: 0;
      font-size: 1.4rem;
      line-height: 1.4rem;
      background-color: transparent;
      border: none;
    }
  }

  .btn-registration.btn-base {
    min-width: 85px;
    padding: 10px;
  }
}
</style>
