<template>
  <div class="edit-company-element">
    <h2 class="company-member_list_item_ttl">
      <span class="list_title">会社名</span>
      <button-base
        class="btn-s"
        :rejected-admin-ids="[2]"
        :button-text="isEditMode === true ? '確定' : '編集'"
        :button-type="isEditMode === true ? 'success' : 'primary'"
        :icon-file-name="isEditMode === true ? 'check_white' : 'pen_white'"
        @click="isEditMode === true ? commit() : (isEditMode = true)"
      />
    </h2>
    <p v-if="isEditMode" class="name-kanji">
      <input
        v-model="localCompanyInfo.data.name"
        :class="{ 'is-danger': !errors.name.isValid }"
        type="text"
        placeholder=""
        maxlength="64"
        @input="
          validateOnInput('name', localCompanyInfo.data.name, validateRequire)
        "
      />
      <span v-show="!errors.name.isValid" class="fa fa-warning font-s">
        {{ errors.name.message }}
      </span>
    </p>
    <p v-else class="value">{{ localCompanyInfo.data.name }}</p>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'EditCompanyName',
  props: {
    companyInfo: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['commit'],
  setup(props, context) {
    const { errors, resetErrors, validateOnInput, validateRequire } =
      useValidation(['name']);
    const store = useStore();
    const localCompanyInfo = reactive({ data: {} });
    const isEditMode = ref(false);
    const commit = () => {
      resetErrors();
      if (validateRequire('name', localCompanyInfo.data.name) !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '会社名を正しく入力して下さい',
          type: false,
        });
        return;
      }
      context.emit('commit', { name: localCompanyInfo.data.name });
      isEditMode.value = false;
    };
    watch(
      () => props.companyInfo,
      () => (localCompanyInfo.data = props.companyInfo),
    );
    return {
      errors,
      localCompanyInfo,
      isEditMode,
      commit,
      validateOnInput,
      validateRequire,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

input:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px red solid;
}

.fa-warning {
  color: red;
  padding-top: 5px;
  font-size: 0.8rem;
}
.fa {
  display: block;
}

.btn {
  > .icon {
    margin-right: 6px;
  }
}
</style>
