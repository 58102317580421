<template>
  <form class="login-form">
    <div class="login-form-toissue">
      <p class="toissue_ttl">パスワードの再設定</p>
      <p class="toissue_desc">
        登録されているメールアドレスを入力してください。
      </p>
      <div class="form-item mail_toissue">
        <label class="el-form-item__label">
          <img
            class="form-item-svg mail-svg"
            src="@/assets/img/mail_blue.svg"
          />
          メールアドレス
        </label>
        <input v-model="email" class="form-item-input" type="mail" />
      </div>
      <div class="form-item login-btn">
        <button-base
          button-text="メールを送信"
          :is-loading="isLoading"
          @click="sendMail"
        />
      </div>
      <span
        class="changemode_span"
        @click.prevent="$emit('changeMode', 'loginLogin')"
      >
        ログイン画面に戻る
      </span>
    </div>
    <notification />
  </form>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import Notification from '@/components/ui/notification/components/Notification';
import issueService from '@/services/issue';

export default defineComponent({
  name: 'ToIssue',
  components: { Notification },
  emits: ['changeMode'],
  setup(props, context) {
    const store = useStore();
    const email = ref('');
    const isLoading = ref(false);

    // methods
    const sendMail = async () => {
      isLoading.value = true;
      const res = await issueService.sendIssueMail(email.value);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: 'メールを送信しました',
        type: true,
      });
      if (res.success === false) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `${res.message}`,
          type: res.success,
        });
      } else {
        context.emit('changeMode', 'loginLogin');
        email.value = '';
      }
      isLoading.value = false;
    };

    return {
      email,
      isLoading,
      sendMail,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.login-form {
  .toissue_ttl {
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
  }
  .toissue_desc {
    text-align: left;
    margin-bottom: 15px;
  }
  &-toissue {
    width: 35rem;
    padding: 3rem 3.6rem;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0.4rem;
    border: 1px solid #ccc;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    .form-item {
      margin-bottom: 1.2rem;
      &:nth-of-type(2) {
        margin-bottom: 2.4rem;
      }
    }
    label {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 2.4rem;
      margin-bottom: 0.6rem;
      line-height: unset;
      height: 2.2rem;
      font-size: 1.2rem;
      .form-item-svg {
        fill: $mochica_color;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        &.mail-svg {
          height: 1.4rem;
        }
        &.key-svg {
          height: 2rem;
          left: 0.6rem;
        }
      }
    }
    .form-item-input {
      width: 100%;
      height: 3rem;
      line-height: unset;
      border: 1px solid #e3e3e3;
      border-radius: 2px;
      padding: 1.7rem;
      background: #fff;
      font-size: 1.5rem;
      &:focus {
        border-color: $mochica-color;
        transition: 0.3s;
      }
    }
  }
  .login-btn {
    margin-bottom: 0;
    button {
      width: 100%;
      height: 3.6rem;
      background-color: $mochica_color;
      color: #fff;
      text-align: center;
      border: none;
      font-weight: bold;
      border-radius: 0.3rem;
      cursor: pointer;
    }
  }
}
.changemode_span {
  cursor: pointer;
  color: #1899d6;
}
</style>
