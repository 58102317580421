<template>
  <div class="list-view-staffs">
    <table class="table">
      <thead v-if="$store.getters['staff/isOwner']" class="thead">
        <tr class="tr">
          <th v-for="(col, iCol) in header" :key="iCol" class="th">
            {{ col.name_jp }}
          </th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr v-for="staff in staffs" :key="staff.id" class="tr">
          <td
            v-for="(col, iCol) in header"
            :key="iCol"
            class="td"
            :class="{
              owner: staff.role.id === 1,
            }"
            :data-label="tableTH[iCol]"
          >
            <p>
              <span
                v-for="(relation, iRelation) in col.relation"
                :key="iRelation"
              >
                <edit-email
                  v-if="relation === 'email'"
                  :staff="staff"
                  :is-owners-email="staff.role.id === 1"
                  :loading-edit-email="loadingEditEmail"
                  :loading-cancel="loadingCancel"
                  @commitedEmailChange="$emit('commitedEmailChange', $event)"
                  @onSelectedCancelDialog="
                    $emit('onSelectedCancelDialog', $event)
                  "
                />
                <delete-staff
                  v-else-if="
                    relation === 'delete' && $store.getters['staff/isOwner']
                  "
                  :staff="staff"
                  :loading="loadingDelete"
                  @onClickSettleDeleteStaff="
                    $emit('onClickSettleDeleteStaff', $event)
                  "
                />
                <edit-role
                  v-else-if="relation === 'role.description'"
                  :staff="staff"
                  :relation="relation"
                  @fetchStaffs="$emit('fetchStaffs')"
                />
                <span v-else class="value">
                  {{ $utils.getValueFromNestedObject(staff, relation) }}
                </span>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { staffsListColumns } from '@/defines/staff';
import EditRole from '@/components/page/configs/staffs/components/EditRole';
import EditEmail from '@/components/page/configs/staffs/components/EditEmail';
import DeleteStaff from '@/components/page/configs/staffs/components/DeleteStaff';

export default defineComponent({
  name: 'ListViewStaffs',
  components: { EditEmail, EditRole, DeleteStaff },
  props: {
    staffs: {
      type: Array,
      default: () => [],
    },
    loadingEditEmail: {
      type: Boolean,
      default: false,
    },
    loadingCancel: {
      type: Boolean,
      default: false,
    },
    loadingDelete: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'commitedEmailChange',
    'onSelectedCancelDialog',
    'onClickSettleDeleteStaff',
    'fetchStaffs',
  ],
  setup(props, context) {
    return {
      header: staffsListColumns,
      tableTH: ['名前', 'メールアドレス', '権限', '削除'],
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.list-view-staffs {
  overflow-x: auto;
  white-space: nowrap;
  font-size: 1.4rem;
  line-height: 1.5;
  @media (max-width: ($media_query_sp)) {
    overflow-x: hidden;
  }
  .table {
    text-align: left;
    .thead {
      @media (max-width: ($media_query_sp)) {
        display: none;
      }
      .tr {
        background-color: $mochica_color_gray2;
        .th {
          padding: 10px 20px;
        }
      }
    }
    .tbody {
      .tr {
        @media (max-width: ($media_query_sp)) {
          display: block;
          &:not(:last-child) {
            border-bottom: 1px solid $border_color;
            margin-bottom: 40px;
          }
        }
        .td {
          border-bottom: 1px solid $border_color;
          @media (max-width: ($media_query_sp)) {
            display: block;
            border: none;
            &:before {
              display: block;
              content: attr(data-label);
              padding: 10px;
              border-right: 1px solid $border_color;
              font-weight: bold;
              background-color: $mochica_color_gray2;
            }
            &:nth-child(4):before {
              display: none;
            }
            &.owner:nth-child(4) {
              p {
                padding: 0;
              }
            }
          }
          p {
            padding: 20px 10px;
            word-break: break-all;
          }
        }
      }
    }
  }
  @media (max-width: ($media_query_sp)) {
    font-size: 1rem;
    white-space: inherit;
  }
}
</style>
