<template>
  <modal :is-view="isView">
    <template #content>
      <div class="modal-window">
        <div class="header">
          <p class="title">Eメールアドレスの形式を確認してください</p>
        </div>
        <div class="body">
          <p class="description">
            Eメールアドレスの形式、使用できない文字、大文字などが使われていないか確認して下さい。
          </p>
          <div class="body-inner">
            <table class="table">
              <thead class="thead">
                <tr class="thead_row">
                  <th class="thead_item number">行番号</th>
                  <th class="thead_item">該当メールアドレス</th>
                </tr>
              </thead>
              <tbody class="tbody">
                <tr
                  v-for="(survey, iSurvey) in errorSurveyList"
                  :key="iSurvey"
                  class="tbody_row"
                >
                  <td class="tbody_item">{{ survey.record_num }}</td>
                  <td class="tbody_item">{{ survey.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="btn-col2">
          <button
            class="btn btn-unavailable"
            @click="$emit('onClickCancel', $event)"
          >
            キャンセル
          </button>
          <button
            class="btn btn-availability"
            @click="$emit('onClickForceSubmit', $event)"
          >
            不正なアドレス以外を登録する
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent } from 'vue';

import Modal from '@/components/ui/modals/components/Modal';

export default defineComponent({
  name: 'ModalSurveyErrorMailList',
  components: { Modal },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    errorSurveyList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCancel', 'onClickForceSubmit'],
  setup(props, context) {
    return {};
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.content {
  width: 80rem;
  border-radius: 1rem;
  .header {
    padding: 2rem;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    font-size: 1.2rem;
    padding: 2rem;
    .description {
      margin-bottom: 2rem;
    }
    .body-inner {
      max-height: 36rem;
      width: 100%;
      overflow: auto;
    }
    po .area_btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      .cancel_btn {
        width: 100%;
        height: 4rem;
        border: 1px solid $border_color;
        color: #333;
        cursor: pointer;
        border-radius: 0.4rem;
        background: #fff;
        border-color: #ccc;
        margin-right: 10px;
        &:hover {
          transition: all ease 0.3s;
          opacity: 0.6;
        }
      }
      .regist_btn {
        width: 100%;
        height: 4rem;
        border: 1px solid $border_color;
        cursor: pointer;
        border-radius: 0.4rem;
        background: #1899d6;
        color: #fff;
        font-weight: 600;
        &:hover {
          transition: all ease 0.3s;
          opacity: 0.6;
        }
      }
    }
  }
}
.pageloader {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 254px;
}
input:focus,
select:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid !important;
}
.table {
  width: 100%;
  text-align: left;
  .thead_item {
    padding: 10px;
    background-color: #e2e2e2;
  }
  .tbody_item {
    padding: 10px;
  }
  .number {
    width: 80px;
  }
}
.fa-warning {
  color: #c31e1e;
  padding-top: 4px;
}
.fa {
  display: block;
}
// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
