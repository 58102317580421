/**
 * questions[i].answers[j].isSelectedを操作する関数
 * @param {Object} Questions - FlagSurveyQuestions
 * @param {Number} questionIndex - QuestionIndex
 * @param {Number} answerIndex - AnswerIndex
 * @return {Object} Questions
 */
const setIsSelectedAnswer = (questions, questionIndex, answerIndex) =>
  questions.map((question, index) => {
    const answers = question.answers ? question.answers : question.tagGroups;
    if (index === questionIndex) {
      if (question.answers) {
        // line_entryで利用
        question.answers = answers.map((answer, childIndex) => {
          if (question.multi) {
            if (answerIndex === childIndex)
              answer.isSelected = !answer.isSelected;
          } else {
            answer.isSelected = answerIndex === childIndex;
          }
          return { ...answer };
        });
      } else if (question.tagGroups) {
        // entryformSettingで利用
        question.tagGroups = question.tagGroups.map(tagGroup => {
          if (tagGroup.isSelected) {
            tagGroup.tags.map((tag, childIndex) => {
              if (question.multi) {
                if (answerIndex === childIndex)
                  tag.isSelected = !tag.isSelected;
              } else {
                tag.isSelected = answerIndex === childIndex;
              }
              return { ...tag };
            });
          }
          return { ...tagGroup };
        });
      }
    }
    return { ...question };
  });

module.exports = { setIsSelectedAnswer };
