<template>
  <modal :is-view="isVisible === true">
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">選択した応募者の指定した選考を削除</p>
        </div>
        <div class="body">
          <div class="message">
            選択した応募者において、既に割り当てられている選考を削除します。
          </div>
          <div class="selected-selection-info">
            <p class="title">指定した選考名</p>
            <p v-if="selection.event">{{ selection.event.title }}</p>
          </div>
          <div class="selected-selection-info">
            <p v-if="selection.venues" class="title">開催日時</p>
            <span
              v-for="(venue, iVenue) in selection.venues"
              :key="iVenue"
              class="venue"
              :index="iVenue"
            >
              {{ $utils.parseDateTime(venue.date, 'M/D') }}
            </span>
          </div>
          <div class="area-applicant-list allow-announced">
            <p class="title">指定した選考が割り当てられている応募者</p>
            <selectable-applicant-table
              class="change-list"
              :applicants="
                applicants.filter(v => eligibleApplicants.includes(v.id))
              "
              :invisible-columns="invisibleColumns"
              :is-sortable="false"
            />
            <div
              v-if="
                applicants.filter(v => eligibleApplicants.includes(v.id))
                  .length === 0
              "
              class="area-err"
            >
              対象の応募者がいません。
            </div>
          </div>
          <div class="area-applicant-list deny-announced">
            <p class="title">
              以下の応募者は、指定した選考が割り当てられていないため
              <span class="alert_red">指定した選考の削除はできません</span>
            </p>
            <selectable-applicant-table
              class="change-list"
              :applicants="
                applicants.filter(v => !eligibleApplicants.includes(v.id))
              "
              :invisible-columns="invisibleColumns"
              :is-sortable="false"
            />
          </div>
        </div>
        <div class="btn-col2">
          <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
            キャンセル
          </div>
          <div
            class="btn btn-availability"
            @click="
              $emit('onClickSettle', {
                applicant_ids: eligibleApplicants.sort((a, b) => a > b),
              })
            "
          >
            指定した選考を削除する
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, watch, ref, nextTick } from 'vue';

import { onResizeModal } from '@/utils/modal-resize';
import Modal from '@/components/ui/modals/components/Modal';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';

export default defineComponent({
  name: 'ModalBulkSendSelectionDelete',
  components: { Modal, SelectableApplicantTable },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    applicants: {
      type: Array,
      default: () => [],
    },
    selection: {
      type: Object,
      default: () => {},
    },
    eligibleApplicants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCancel', 'onClickSettle'],
  setup(props, context) {
    const invisibleColumns = ref([]);
    watch(
      () => props.isVisible,
      v => {
        if (v === true) {
          nextTick(() => localResizeModal());
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );
    const localResizeModal = () =>
      onResizeModal(props.isVisible, {
        updateInvisibleColumns: v => (invisibleColumns.value = v),
      });
    return { invisibleColumns };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  overflow-y: auto;
  .header {
    padding: 2rem 0;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    font-size: 1.2rem;
    padding: 0rem 20px;
    margin-bottom: 20px;
    .message {
      padding: 2.4rem 0 1rem;
    }
    .selected-selection-info {
      text-align: left;
      &:not(:first-child) {
        margin-top: 20px;
      }
      .title {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .venue:not(:last-child)::after {
        content: ',';
        display: inline-block;
        margin-right: 4px;
      }
    }
    .area-finished-status {
      .area-operatosssr-body {
        margin-bottom: 1.2rem;
        .view-blank-text {
          min-height: 5rem;
          background: white;
          border: 1px solid $border_color;
          border-radius: 0.4rem;
          min-height: 10rem;
          padding: 1.2rem;
          font-size: 1.2rem;
          line-height: normal;
          word-wrap: break-word;
        }
        &-txt {
          letter-spacing: 0.05em;
          border: 1px solid $border_color;
          border-radius: 2px 2px 0 0;
          padding: 1.2rem;
          font-size: 1.2rem;
          min-height: 5rem;
          height: 5rem;
          background: #fff;
        }
      }
    }
    .area-applicant-list {
      .title {
        text-align: left;
        font-weight: bold;
        margin: 2em 0 0.5em;
      }
      span.alert_red {
        color: #c31e1e;
      }
    }
    .area-table {
      &.change-list {
        width: 100%;
        height: 18vh;
        margin: 0 0 0 0;
        border: 1px solid $border_color;
        border-top: none;
        text-align: left;

        tr {
          border-bottom: 1px solid $border_color;
        }
      }
    }
    .change-status_btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      &_item {
        width: 49.5%;
        height: 3.6rem;
        line-height: 3.6rem;
        background: $mochica_color;
        border-radius: 5rem;
        color: #fff;
        font-weight: bold;
        &.back_btn {
          background: #fff;
          border: 1px solid $border_color;
          color: #333;
          font-weight: normal;
        }
      }
    }
    .area-err {
      color: #c31e1e;
      margin: 10px 0;
      font-size: 1.2rem;
      text-align: left;
    }
    .area-available-comment {
      text-align: left;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    .body {
      text-align: left;
      .area-applicant-list {
        &.deny-announced {
          .title {
            line-height: 1.4;
          }
        }
      }
    }
  }
}
</style>
