<template>
  <section v-if="isLoaded" class="applicantInfo-section">
    <section class="ai-block">
      <div class="headline">応募者 基本情報</div>
      <div class="inner">
        <p class="inner-title">氏名</p>
        <div v-if="isEditingApplicantName === true">
          <p class="name-kanji edit-block">
            <input
              v-model="applicant.data.lastname"
              type="text"
              maxlength="14"
              :class="{ 'is-danger': errors.lastname.isValid !== true }"
            />
            <input
              v-model="applicant.data.firstname"
              type="text"
              maxlength="14"
              :class="{ 'is-danger': errors.firstname.isValid !== true }"
            />
          </p>
          <p
            v-if="errors.lastname.isValid !== true"
            class="fa-warning error-name"
          >
            {{ errors.lastname.message }}
          </p>
          <p
            v-if="errors.firstname.isValid !== true"
            class="fa-warning error-name"
          >
            {{ errors.firstname.message }}
          </p>
          <p class="name-kana edit-block">
            <input
              v-model="applicant.data.lastnameKana"
              type="text"
              maxlength="14"
              :class="{ 'is-danger': errors.lastnameKana.isValid !== true }"
            />
            <input
              v-model="applicant.data.firstnameKana"
              type="text"
              maxlength="14"
              :class="{ 'is-danger': errors.firstnameKana.isValid !== true }"
            />
          </p>
          <p
            v-if="errors.lastnameKana.isValid !== true"
            class="fa-warning error-kana"
          >
            {{ errors.lastnameKana.message }}
          </p>
          <p
            v-if="errors.firstnameKana.isValid !== true"
            class="fa-warning error-kana"
          >
            {{ errors.firstnameKana.message }}
          </p>
        </div>
        <div v-else>
          <p class="name-kanji">
            {{ applicant.data.lastname }} {{ applicant.data.firstname }}
          </p>
          <p class="name-kana">
            {{ applicant.data.lastnameKana }} {{ applicant.data.firstnameKana }}
          </p>
        </div>
        <dl class="mochicaID info-other">
          <dt class="title">MOCHICA ID</dt>
          <dd class="value">{{ applicant.data.id }}</dd>
        </dl>
        <dl class="flexbox flexbox-middle info-contact">
          <dt class="contact_title">連絡方法</dt>
          <dd class="contact_value" :class="{ line: isLine }">
            {{ applicant.data.contactType }}
          </dd>
          <dd v-if="applicant.data.lineBlock" class="contact_block">
            (ブロック中)
          </dd>
        </dl>
        <button-base
          class="btn btn-availability toggle-button"
          :button-text="isEditingApplicantName === true ? '確定' : '編集'"
          icon-file-name="pen_white"
          @click="
            isEditingApplicantName === true
              ? updateApplicantName()
              : (isEditingApplicantName = true)
          "
        />
      </div>
    </section>
    <section>
      <div class="print-btn">
        <button @click="movePrintView">印刷レイアウトを表示</button>
      </div>
    </section>

    <section class="ai-block">
      <div class="headline">採用担当者</div>
      <div v-if="$store.getters['staff/isOperator']" class="operator-text">
        <p class="staff">{{ staffName }}</p>
      </div>
      <div v-else class="inner">
        <div v-if="isEditingApplicantStaff === true" class="staff edit">
          <label for="selectStaffName">
            <select
              id="selectStaffName"
              v-model="applicant.data.staff.id"
              class="selectStaffName"
            >
              <option
                v-for="staff in staffs"
                v-show="staff.is_delete === false"
                :key="staff.id"
                :label="staff.lastname + ' ' + staff.firstname"
                :value="staff.id"
              >
                {{ staff.lastname + ' ' + staff.firstname }}
              </option>
            </select>
          </label>
        </div>
        <div v-else>
          <p class="staff">{{ staffName }}</p>
        </div>
        <button-base
          class="btn btn-availability toggle-button"
          :button-text="isEditingApplicantStaff === true ? '確定' : '編集'"
          icon-file-name="pen_white"
          @click="
            isEditingApplicantStaff === true
              ? updateStaff()
              : (isEditingApplicantStaff = true)
          "
        />
      </div>
    </section>

    <section class="ai-block">
      <div class="headline">応募者 詳細情報</div>
      <div class="inner">
        <div v-if="isEditingApplicantDetail === true">
          <ul class="applicantDetail-list edit">
            <li>
              <p class="title">登録種別</p>
              <div class="value">
                <drop-down
                  v-if="isReadyChangeGraduated"
                  :placeholder="graduatedName"
                  :selected-id="applicant.data.graduated"
                  :list="graduatedList"
                  :is-view="isVisibleGraduated"
                  @onOpen="isVisibleGraduated = true"
                  @onClose="isVisibleGraduated = false"
                  @onSelected="onSelectedGraduated"
                />
              </div>
              <p v-if="!isReadyChangeGraduated" class="value">
                {{ graduatedName }}
              </p>
            </li>
            <li>
              <p class="title">
                <label for="selectGender">性別</label>
              </p>
              <div class="value">
                <select id="selectGender" v-model="applicant.data.gender.id">
                  <option
                    v-for="gender in genderList"
                    :key="gender.id"
                    :label="gender.name"
                    :value="gender.id"
                  >
                    {{ gender.name }}
                  </option>
                </select>
              </div>
            </li>
            <li>
              <p class="title">学校名</p>
              <div class="value">
                <input
                  v-model="applicant.data.school"
                  type="text"
                  maxlength="50"
                />
              </div>
            </li>
            <li>
              <p class="title">学部</p>
              <div class="value">
                <input
                  v-model="applicant.data.faculty"
                  type="text"
                  maxlength="50"
                />
              </div>
            </li>
            <li>
              <p class="title">学科</p>
              <div class="value">
                <input
                  v-model="applicant.data.department"
                  type="text"
                  maxlength="50"
                />
              </div>
            </li>
            <li>
              <p class="title">
                <label for="selectDepartmentCategory">文理</label>
              </p>
              <div class="value">
                <select
                  id="selectDepartmentCategory"
                  v-model="applicant.data.departmentCategory.id"
                >
                  <option
                    v-for="departmentCategory in departmentCategoryList"
                    :key="departmentCategory.id"
                    :label="departmentCategory.name"
                    :value="departmentCategory.id"
                  >
                    {{ departmentCategory.name }}
                  </option>
                </select>
              </div>
            </li>
            <li>
              <p class="title">電話番号</p>
              <div class="value">
                <input
                  v-model="applicant.data.tel"
                  type="text"
                  maxlength="11"
                  :class="{ 'is-danger': errors.tel.isValid !== true }"
                />
              </div>
              <p v-if="errors.tel.isValid !== true" class="fa-warning">
                {{ errors.tel.message }}
              </p>
            </li>
            <li>
              <p class="title">郵便番号</p>
              <div class="value">
                <input
                  v-model="applicant.data.zipcode"
                  type="text"
                  maxlength="7"
                  :class="{ 'is-danger': errors.zipcode.isValid !== true }"
                />
              </div>
              <p v-if="errors.zipcode.isValid !== true" class="fa-warning">
                {{ errors.zipcode.message }}
              </p>
            </li>
            <li>
              <p class="title">住所</p>
              <div class="value">
                <input
                  v-model="applicant.data.address"
                  type="text"
                  maxlength="100"
                />
              </div>
            </li>
            <li>
              <p class="title">登録経路</p>
              <div class="value">{{ applicant.data.channel }}</div>
            </li>
            <li>
              <p class="title">生年月日</p>
              <div class="value">
                <el-date-picker
                  :model-value="applicant.data.birth"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  @update:model-value="applicant.data.birth = $event"
                />
              </div>
            </li>
            <li>
              <p class="title">年齢</p>
              <div class="value">
                {{ $utils.getAgeFromDateTime(applicant.data.birth) }}
              </div>
            </li>
            <li>
              <p class="title">メールアドレス</p>
              <div class="value">{{ applicant.data.email }}</div>
            </li>
            <li>
              <p class="title">受信用メールアドレス</p>
              <div class="value">
                <input
                  v-model="applicant.data.emailRecieve"
                  type="email"
                  maxlength="255"
                  :class="{ 'is-danger': errors.emailRecieve.isValid !== true }"
                />
              </div>
              <p v-if="errors.emailRecieve.isValid !== true" class="fa-warning">
                {{ errors.emailRecieve.message }}
              </p>
            </li>
            <li>
              <p class="title">登録日</p>
              <div class="value">
                {{
                  $utils.parseDateTime(applicant.data.createdAt, 'YYYY/MM/DD')
                }}
              </div>
            </li>
            <li>
              <p class="title">LINEエントリー日</p>
              <div class="value">
                {{
                  $utils.parseDateTime(applicant.data.activatedAt, 'YYYY/MM/DD')
                }}
              </div>
            </li>
            <li>
              <p class="title">メモ</p>
              <div class="value">
                <textarea
                  v-model="applicant.data.memo"
                  class="applicant_detail_memo_textarea"
                  maxlength="500"
                  placeholder="500文字まで"
                ></textarea>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <ul class="applicantDetail-list">
            <li>
              <p class="title">登録種別</p>
              <p class="value">{{ graduatedName }}</p>
            </li>
            <li>
              <p class="title">性別</p>
              <p class="value">{{ genderName }}</p>
            </li>
            <li>
              <p class="title">学校名</p>
              <p class="value">{{ applicant.data.school }}</p>
            </li>
            <li>
              <p class="title">学部</p>
              <p class="value">{{ applicant.data.faculty }}</p>
            </li>
            <li>
              <p class="title">学科</p>
              <p class="value">{{ applicant.data.department }}</p>
            </li>
            <li>
              <p class="title">文理</p>
              <p class="value">{{ departmentCategoryName }}</p>
            </li>
            <li>
              <p class="title">電話番号</p>
              <p class="value">{{ applicant.data.tel }}</p>
            </li>
            <li>
              <p class="title">郵便番号</p>
              <p class="value">〒{{ applicant.data.zipcode }}</p>
            </li>
            <li>
              <p class="title">住所</p>
              <p class="value">{{ applicant.data.address }}</p>
            </li>
            <li>
              <p class="title">登録経路</p>
              <p class="value">{{ applicant.data.channel }}</p>
            </li>
            <li>
              <p class="title">生年月日</p>
              <p class="value">
                {{ $utils.parseDateTime(applicant.data.birth, 'YYYY/MM/DD') }}
              </p>
            </li>
            <li>
              <p class="title">年齢</p>
              <p class="value">
                {{ $utils.getAgeFromDateTime(applicant.data.birth) }}
              </p>
            </li>
            <li>
              <p class="title">メールアドレス</p>
              <p class="value">{{ applicant.data.email }}</p>
            </li>
            <li>
              <p class="title">受信用メールアドレス</p>
              <div class="value">{{ applicant.data.emailRecieve }}</div>
            </li>
            <li>
              <p class="title">登録日</p>
              <p class="value">
                {{
                  $utils.parseDateTime(applicant.data.createdAt, 'YYYY/MM/DD')
                }}
              </p>
            </li>
            <li>
              <p class="title">LINEエントリー日</p>
              <p class="value">
                {{
                  $utils.parseDateTime(applicant.data.activatedAt, 'YYYY/MM/DD')
                }}
              </p>
            </li>
            <li>
              <p class="title">メモ</p>
              <!-- <p class="value">{{ applicant.data.memo }}</p> -->
              <textarea
                class="value textarea_memo"
                readonly
                :value="applicant.data.memo"
              />
            </li>
          </ul>
        </div>
        <button-base
          class="btn btn-availability toggle-button"
          :button-text="isEditingApplicantDetail === true ? '確定' : '編集'"
          icon-file-name="pen_white"
          @click="
            isEditingApplicantDetail === true
              ? updateApplicantDetail()
              : (isEditingApplicantDetail = true)
          "
        />
      </div>
    </section>
  </section>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import GraduatedDefines from '@/defines/graduated';
import { genders, departmentCategories } from '@/defines/applicant';
import applicantService from '@/services/applicant';
import staffsService from '@/services/staffs';
import useValidation from '@/composables/useValidation';
import DropDown from '@/components/ui/menus/components/DropDown';

export default defineComponent({
  name: 'ApplicantInfo',
  components: { DropDown },
  props: {
    defaultApplicant: {
      type: Object,
      default: null,
    },
    progresses: {
      type: Object,
      default: null,
    },
    flagSurveyNoticeId: {
      type: Number,
      default: null,
    },
  },
  setup(props, context) {
    const {
      errors,
      resetErrors,
      validateRequire,
      validateKana,
      validateZipcode,
      validateTel,
      validateEmail,
    } = useValidation([
      'lastname',
      'firstname',
      'lastnameKana',
      'firstnameKana',
      'tel',
      'zipcode',
      'emailRecieve',
    ]);
    const store = useStore();
    const isLoaded = ref(false);
    const isVisibleGraduated = ref(false);
    const staffs = ref([]);
    const genderList = ref([]);
    const departmentCategoryList = ref([]);
    const graduatedList = ref([]);
    const localGraduated = ref(null);
    const isEditingApplicantName = ref(false);
    const isEditingApplicantStaff = ref(false);
    const isEditingApplicantDetail = ref(false);
    const applicant = reactive({ data: {} });

    // computed
    const isLine = computed(() => applicant.data.contactType === 'LINE');
    const genderName = computed(() => {
      const filtered = genderList.value.filter(
        gender => gender.id === applicant.data.gender.id,
      );
      return filtered.length >= 1 ? filtered[0].name : '';
    });
    const staffName = computed(() => {
      const staff = staffs.value.find(s => s.id === applicant.data.staff.id);
      return staff.lastname + staff.firstname;
    });
    const departmentCategoryName = computed(() => {
      const filtered = departmentCategoryList.value.filter(
        departmentCategory =>
          departmentCategory.id === applicant.data.departmentCategory.id,
      );
      return filtered.length >= 1 ? filtered[0].name : '';
    });
    const graduatedName = computed(() => {
      const year = applicant.data.graduated;
      const res = GraduatedDefines.find(
        defineTitle => defineTitle.year === year,
      );
      if (res !== undefined) return res.name_jp;
      return `${year}年卒業`;
    });
    const isReadyChangeGraduated = computed(() => {
      if (
        props.progresses.committed.length !== 0 ||
        props.progresses.notCommitted.length !== 0
      ) {
        return false;
      }
      return true;
    });

    // methods
    const validateAll = () => {
      resetErrors();
      if (
        validateRequire('lastname', applicant.data.lastname) !== true ||
        validateRequire('firstname', applicant.data.firstname) !== true ||
        validateRequire('lastnameKana', applicant.data.lastnameKana) !== true ||
        validateRequire('firstnameKana', applicant.data.firstnameKana) !==
          true ||
        validateKana('lastnameKana', applicant.data.lastnameKana) !== true ||
        validateKana('firstnameKana', applicant.data.firstnameKana) !== true ||
        validateTel('tel', applicant.data.tel, false) !== true ||
        validateZipcode('zipcode', applicant.data.zipcode, false) !== true ||
        validateEmail('emailRecieve', applicant.data.emailRecieve, false) !==
          true
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容を確認して下さい',
          type: false,
        });
        return false;
      }
      if (
        localGraduated.value !== applicant.data.graduated &&
        isReadyChangeGraduated.value !== true
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '選考が割り当てられているため登録種別を変更できません',
          type: false,
        });
        return false;
      }
      return true;
    };
    // 応募者名更新
    const updateApplicantName = async () => {
      if (validateAll() !== true) return;
      const res = await store.dispatch(
        'applicant/UPDATE_APPLICANT',
        applicant.data.jsonObjCreateApplicant(),
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isEditingApplicantName.value = false;
    };
    // 応募者情報更新
    const updateApplicantDetail = async ev => {
      if (validateAll() !== true) return;
      const res = await store.dispatch(
        'applicant/UPDATE_APPLICANT',
        applicant.data.jsonObjCreateApplicant(),
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isEditingApplicantDetail.value = false;
    };
    // 担当スタッフ更新
    const updateStaff = async ev => {
      const response = await applicantService.bulkUpdateApplicantStaff(
        [applicant.data.id],
        applicant.data.staff.id,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: response.message,
        type: response.success,
      });
      isEditingApplicantStaff.value = false;
    };
    // 登録種別選択
    const onSelectedGraduated = payload => {
      const graduated = graduatedList.value.find(v => v.id === payload.item.id);
      applicant.data.graduated = graduated ? graduated.id : null;
    };
    // 印刷レイアウト表示
    const movePrintView = () => {
      window.open(
        `/applicant_print?applicant_id=${applicant.data.id}&flag_survey_notice_id=${props.flagSurveyNoticeId}`,
        '_blank',
        'width=800,height=1000',
      );
    };

    // lifecycle
    onBeforeMount(async () => {
      applicant.data = props.defaultApplicant;
      if (applicant.data.gender.id === 0) applicant.data.gender.id = 3;
      if (applicant.data.departmentCategory.id === 0) {
        applicant.data.departmentCategory.id = 3;
      }
      localGraduated.value = applicant.data.graduated;
      staffs.value = await staffsService.fetchStaffs();
      genderList.value = genders.map(gender => ({
        id: gender.id,
        name: gender.description,
        selected: false,
      }));
      departmentCategoryList.value = departmentCategories.map(v => ({
        id: v.id,
        name: v.description,
        selected: false,
      }));
      graduatedList.value = store.getters['graduateds/graduateds'].map(g => {
        const res = GraduatedDefines.find(v => v.year === g.year);
        return {
          id: g.year,
          name: res !== undefined ? res.name_jp : `${g.year}年卒業`,
          selected: false,
        };
      });
      isLoaded.value = true;
    });

    return {
      errors,
      isEditingApplicantName,
      isEditingApplicantStaff,
      isEditingApplicantDetail,
      isLoaded,
      isVisibleGraduated,
      applicant,
      staffs,
      genderList,
      departmentCategoryList,
      graduatedList,
      localGraduated,
      isLine,
      genderName,
      staffName,
      departmentCategoryName,
      graduatedName,
      isReadyChangeGraduated,
      updateApplicantName,
      updateApplicantDetail,
      updateStaff,
      onSelectedGraduated,
      movePrintView,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';
@include validation_effects();

.applicantInfo-section {
  width: 220px;
}

.ai-block {
  position: relative;
  background-color: #fff;
  + .ai-block {
    margin-top: 20px;
  }
  > .headline {
    padding: 15px 20px;
    font-weight: bold;
    background-color: #ebebeb;
  }
  > .operator-text {
    padding: 20px;
    .staff {
      margin-top: 0;
    }
  }
  > .inner {
    padding: 30px 20px 20px;

    .inner-title {
      display: block;
      margin-top: -10px;
      margin-bottom: 10px;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
}

.toggle-button {
  width: 100%;
  margin-top: 17px;
}

.name-kanji {
  margin-bottom: 5px;
  font-size: 2rem;
}
.name-kana {
  margin-bottom: 20px;
  font-size: 1.2rem;
}
.error-name {
  margin-top: -4px;
  margin-bottom: 7px;
}
.error-kana {
  margin-top: -20px;
  margin-bottom: 7px;
}
.edit-block {
  display: flex;
  justify-content: space-between;
  > input {
    width: 49%;
  }
}
.info-other {
  padding: 10px 0;
  border-top: solid 1px #e3e3e3;
  + .info-other {
    border-bottom: solid 1px #e3e3e3;
  }
  > .title {
    display: inline-block;
    padding-right: 15px;
  }
  > .value {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
.mochicaID {
  > .value {
    color: #1899d6;
  }
}

.line {
  display: inline-block;
  color: #1dcd00;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 0.2rem 0 0 1rem;
}
.contact_block {
  color: $mochica_color_red;
  padding: 0 0 0.2rem 0.5rem;
}

.staff {
  margin-top: -10px;
  padding-bottom: 5px;
  font-size: 1.3rem;
  line-height: 1.4;
  border-bottom: 1px solid #e3e3e3;
  overflow-wrap: break-word;
}
.staff.edit {
  padding-bottom: 0;
  border-bottom: none;
}
.selectStaffName {
  width: 100%;
  height: 32px;
}
.applicantDetail-list {
  > li {
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e3e3e3;
  }
  > li:first-child {
    margin-top: -10px;
  }
  > li:last-child {
    margin-bottom: 0;
  }
  > li > .title {
    margin-bottom: 5px;
    font-size: 1.1rem;
    font-weight: bold;
  }
  > li > .value {
    line-height: 1.4;
    font-size: 1.3rem;
    word-wrap: break-word;
  }
  > li > .textarea_memo {
    border: none;
    height: 300px;
    font-size: 1.3rem;
    font-family: 'Noto Sans JP', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  > li > .textarea_memo::-webkit-scrollbar {
    display: none;
  }
}
.applicantDetail-list.edit {
  > li > .value {
    font-size: 1.3rem;
    padding-bottom: 0;
    border-bottom: none;
  }
  input,
  select,
  .ui-select {
    width: 100%;
    height: 32px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #e3e3e3;
  }
  textarea {
    border-radius: 4px;
    border: 1px solid #e3e3e3;
  }
}
.applicant_detail_memo_textarea {
  padding: 10px;
  height: 300px;
  font-size: 1.3rem;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
}
:deep(.el-input__inner) {
  border: 1px solid #e3e3e3;
}
:deep(.el-date-editor.el-input) {
  width: 100% !important;
}
.txt-warning {
  margin-top: 5px;
  font-size: 1.1rem;
}
.print-btn {
  > button {
    background-color: $mochica_color;
    color: #fff;
    padding: 14px;
    font-size: 1.1rem;
    width: 100%;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease-out;
    &:hover {
      background-color: #16b2d9;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .applicantInfo-section {
    width: 100%;
    margin-bottom: 20px;
  }
  .applicantDetail-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > li {
      width: 48.5%;
    }
    > li:first-child {
      margin-top: 0;
    }
    > li:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .applicantDetail-list {
    display: block;
    > li {
      width: 100%;
    }
    > li:nth-last-child(2) {
      margin-bottom: 20px;
    }
  }
  .print-btn {
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
