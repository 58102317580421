import qs from 'qs';

import httpClient from '@/http-client';
import { snakeToCamelCaseForObject } from '@/utils/change-case';

const EventsManager = function _EventsManager() {
  const manager = Object.create(EventsManager.prototype);
  return manager;
};

EventsManager.prototype = {
  async fetchEvents(graduatedId) {
    const query = qs.stringify({ graduated_id: graduatedId });
    const res = await httpClient.get(`/events?${query}`);
    return res.data.events.map(v => snakeToCamelCaseForObject(v));
  },
  async fetchVisibleEvents(graduatedId, visible) {
    const query = qs.stringify({ graduated_id: graduatedId, visible });
    const res = await httpClient.get(`/events/select?${query}`);
    return res.data.events.map(v => snakeToCamelCaseForObject(v));
  },
  async createEvent(title, graduatedId) {
    const response = await httpClient.post('/events', {
      event: { title, graduated_id: graduatedId },
    });
    if (response.data.success) return response.data.event;
    return null;
  },
  async fetchAttendance(payload) {
    const { data } = await httpClient.post('/events/get_selection_date', {
      event_id: payload.event_id,
      graduated_id: payload.graduated_id,
    });
    return data;
  },
  async editEventTitle(payload) {
    const res = await httpClient.post('/events/edit_event_name', {
      event_id: payload.event_id,
      name: payload.name,
    });
    return res.data;
  },
  async toggleSelectionVisible(payload) {
    const res = await httpClient.post('/events/edit_event_visible', {
      event_id: payload.event_id,
      visible: payload.visible,
    });
    return res.data;
  },
};

export default EventsManager();
