<template>
  <div class="form__wrapper">
    <div class="form__inner">
      <div class="form__inner__contents">
        <div class="smartphone">
          <div class="main-wrapper">
            <div class="mochica_desc">
              <div class="bg-bold">{{ message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onBeforeMount } from 'vue';
import qs from 'qs';
import richMessageService from '@/services/richmessage';

export default defineComponent({
  name: 'RichMessageExt',
  setup(props, context) {
    const message = ref(null);

    // lifecycle
    onBeforeMount(async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      const res = await richMessageService.richMessageCheckRequest(query.token);
      message.value = res.message;
    });
    return {
      message,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include form_wrapper_css();

.form__inner__contents {
  .smartphone {
    max-width: 500px;
  }
  .survey-answer {
    padding: 3rem 0;
  }
  justify-content: center;
  .question_text {
    padding: 0.2rem;
    text-align: left;
  }
  .question_title {
    padding: 0.2rem 0 2.5rem 0;
    text-align: left;
  }
  .survey_header {
    padding: 0 1.5rem;
  }
  .option {
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border: 1px solid $border_color;
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: bold;
    border-radius: 0.3rem;
    transition: all ease 0.3s;
    &:hover {
      border: 1px solid #656565;
      color: #656565;
    }
  }
  .option_active {
    background: #1ecd01;
    color: #fff;
    border-color: #1ecd01;
  }
  .option-selected {
    margin-bottom: 1.2rem;
    padding: 1rem;
    border-color: #1ecd01;
    border: 1px solid #1ecd01;
    color: #656565;
    border-radius: 0.3rem;
    font-weight: bold;
  }
}
.submit_buttons {
  margin-top: 8rem;
}
.cancel_btn {
  width: 100%;
  border: solid 1px #656565;
  background-color: #fff;
  color: #656565;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  border-radius: 20px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.regist_btn {
  width: 100%;
  border: none;
  background-color: #656565;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  border-radius: 20px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.pad {
  padding: 3rem 0;
}

.flag_name {
  padding: 1rem;
}

.mochica_desc {
  text-align: center;
  padding: 3rem 0 3rem;
  .bg-bold {
    white-space: pre-line;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 0.5rem 0;
  }
  .sm-bold {
    padding: 0.5rem 0;
    font-size: 1.4rem;
    font-weight: bold;
  }
}
</style>
