<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useField } from 'vee-validate';
import FormEmail from '@/components/ui/forms/FormEmail.vue';
import FormLine from '@/components/ui/forms/FormLine.vue';
import FileList from '@/components/ui/files/FileList.vue';
import ModalFixedPhraseList from '@/components/features/fixedPhrase/components/ModalFixedPhraseList';
import fixphraseService from '@/services/fixphrase';
import uploadService from '@/services/upload';
import { NOTICE_COPY_TAGS } from '@/defines/autoActions';

defineProps({
  isSendForceEmail: {
    type: Boolean,
    default: false,
  },
  files: {
    type: Array,
    default: () => [],
  },
});

const store = useStore();

const {
  value: emailSubject,
  errorMessage: emailSubjectErrorMessage,
  handleChange: handleChangeEmailSubject,
} = useField('notice.email.subject');
const {
  value: emailContent,
  errorMessage: emailContentErrorMessage,
  handleChange: handleChangeEmailContent,
} = useField('notice.email.content');
const {
  value: lineContent,
  errorMessage: lineContentErrorMessage,
  handleChange: handleChangeLineContent,
} = useField('notice.line.content');

const isFixPhraseBtnDisabled = ref(false);
const fixPhrases = ref([]);
const isViewModalSelectFixPhraseList = ref(false);

const handleDownloadFile = async file => {
  const res = await uploadService.downloadFile({
    id: file.id,
    originalFilename: file.fileName,
  });
  if (!res.success) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: res.data.message,
      type: false,
    });
  }
};

const selectFixedPhrase = payload => {
  isViewModalSelectFixPhraseList.value = false;
  emailSubject.value = payload.mail_title;
  emailContent.value = payload.mail_content;
  lineContent.value = payload.line_content;
};

const openModalFixedPhraseList = async () => {
  isFixPhraseBtnDisabled.value = true;
  const res = await fixphraseService.postFixPhraseList(
    store.getters['graduateds/selectedGraduatedId'],
  );
  if (res.success) {
    fixPhrases.value = res.fixphrase_list;
  }
  isViewModalSelectFixPhraseList.value = true;
  isFixPhraseBtnDisabled.value = false;
};
</script>

<template>
  <div class="action-inner">
    <div class="action-title">
      <h3 class="title">連絡事項を通知</h3>
      <div class="desc">
        メールとLINEを使用して、応募者に連絡事項を通知します。
      </div>
    </div>
    <div class="action-contents auto-action">
      <div class="result-contents-inner">
        <div class="action-forms">
          <div class="action-form">
            <div class="action-form-title notice">
              連絡事項のメール・LINEの文面
            </div>
            <div>
              <div class="message-templete-button">
                <button-base
                  :disabled="isFixPhraseBtnDisabled"
                  type="button"
                  button-text="メッセージテンプレート"
                  icon-file-name="icon_template"
                  @click="openModalFixedPhraseList"
                />
              </div>
              <div class="action-form-contents">
                <div class="form-wrapper">
                  <form-email
                    :subject="{
                      value: emailSubject,
                      errorMessage: emailSubjectErrorMessage,
                    }"
                    :content="{
                      value: emailContent,
                      errorMessage: emailContentErrorMessage,
                    }"
                    :copy-tags="NOTICE_COPY_TAGS"
                    @blur-subject="handleChangeEmailSubject"
                    @blur-content="handleChangeEmailContent"
                  >
                    <template v-if="isSendForceEmail" #attachment>
                      <file-list
                        :files="files"
                        :delete-enabled="false"
                        @download-file="handleDownloadFile($event)"
                      />
                    </template>
                  </form-email>
                </div>
                <div class="form-wrapper">
                  <form-line
                    :content="{
                      value: lineContent,
                      errorMessage: lineContentErrorMessage,
                    }"
                    :copy-tags="NOTICE_COPY_TAGS"
                    :is-force-send-email="isSendForceEmail"
                    @blur-content="handleChangeLineContent"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-fixed-phrase-list
      :fixed-phrase-list="fixPhrases"
      :is-view="isViewModalSelectFixPhraseList"
      @openFixedPhrase="selectFixedPhrase"
      @onClickCloseModalSelectPhraseList="
        isViewModalSelectFixPhraseList = false
      "
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;

.result-contents-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.result-options {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 20px;
  background: #fafafa;
  border: 1px solid #ededed;
  border-radius: 4px;
  font-size: $font_14;
}

.result-option {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &:not(:last-of-type) {
    padding: 0 0 24px;
    border-bottom: 1px solid #ededed;
  }
  .title {
    color: $mochica_color;
    font-weight: bold;
  }
  .contents {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.result-option-check {
  display: flex;
  align-items: center;
  gap: 8px;
}

.result-option-capions {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 0 0 24px;
  color: #777;
  font-size: $font_12;
}

.message-templete-button {
  margin: 20px 0 0 28px;
}
.form-file-wrapper {
  margin-top: 16px;
}
.contactForm-wrapper .message-caption-list {
  line-height: 1.8;
}
</style>
