<template>
  <header class="header header-wrapper width-app-limit">
    <div class="header-menu">
      <div
        class="header-touchMenu"
        :class="{ '-open': isGlobalMenu }"
        @click="$emit('toggleGlobalMenu')"
      >
        <span class="item" />
        <span class="item" />
        <span class="item" />
        <span class="item" />
        <span class="item" />
        <span class="item" />
        <span class="item" />
        <span class="item" />
        <span class="item" />
      </div>
      <div class="header-logo">
        <img
          width="140"
          src="@/assets/img/mochica_white.svg"
          class="logo-img"
        />
      </div>
      <div class="header-plan">
        <div v-if="currentPlan.data.name !== 'standard'" class="plan_content">
          <span class="plan_name" @click="$router.replace('/configs/plan')">
            {{ currentPlan.data.name_short }}
          </span>
          <span class="plan_applicant_count">
            {{ applicantCount }}名 /
            {{
              currentPlan.data.applicant_limit === 99999999
                ? '上限なし'
                : `${currentPlan.data.applicant_limit}名`
            }}
          </span>
        </div>
      </div>
      <div
        v-if="$store.getters['plan/plan'].unread_all_count > 0"
        class="header-unread_count"
      >
        <router-link
          :to="{ name: 'ApplicantsUnreadMessages' }"
          @click="postPottosEvent(35)"
        >
          <img class="chat_icon" src="@/assets/img/chat.svg" alt />
          <span class="unread_count">
            {{
              $store.getters['plan/plan'].unread_all_count >= 100
                ? '99'
                : `${$store.getters['plan/plan'].unread_all_count}件`
            }}
          </span>
        </router-link>
      </div>
    </div>
    <div class="header-functions">
      <div class="header-function" :class="{ '-open': isFunctionMenu }">
        <div class="header-user">
          <div class="company">
            {{ $store.getters['company/company'].name }}
          </div>
          <div class="name">
            {{
              `${$store.getters['staff/staff'].lastname} ${$store.getters['staff/staff'].firstname}`
            }}
          </div>
          <div class="plan_name" @click="$router.replace('/configs/plan')">
            {{ currentPlan.data.name_ja }}
          </div>
        </div>
        <div class="header-selectGraduated">
          <label class="c-select">
            <select v-model="selectedGraduatedId" class="select">
              <option
                v-for="graduated in $store.getters['graduateds/graduateds']"
                :key="graduated.year"
                class="select-elements"
                :label="graduatedYearToLabelName(graduated.year)"
                :value="graduated.id"
              >
                {{ graduatedYearToLabelName(graduated.year) }}
              </option>
            </select>
          </label>
        </div>
        <div
          class="header-logout"
          @click.prevent="
            $store.dispatch('auth/LOGOUT');
            postPottosEvent(14);
          "
        >
          ログアウト
        </div>
      </div>
      <div>
        <figure class="header-touchFunctionMenu" @click="onClickFunctionMenu">
          <img src="@/assets/img/icon_header-function.svg" class="icon" />
        </figure>
      </div>
    </div>
  </header>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { postPottosEvent } from '@/utils/pottos';

import GraduatedDefines from '@/defines/graduated';
import { getPlanDataById } from '@/defines/plan';

export default defineComponent({
  name: 'Navbar',
  props: {
    isGlobalMenu: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggleGlobalMenu'],
  setup(props, context) {
    const store = useStore();
    const selectedYears = ref([]);
    const isFunctionMenu = ref(false);
    const currentPlan = reactive({ data: {} });

    // computed
    const applicantCount = computed(() => {
      const plan = store.getters['plan/plan'];
      if (plan.applicant_count >= 0) {
        return plan.applicant_count;
      }
      return 'N/A';
    });
    const selectedGraduatedId = computed({
      get: () => {
        return store.state.staff.staff.selected_graduated_id;
      },
      set: async newVal => {
        postPottosEvent(12);
        store.dispatch('page/RESET_PAGE');
        const selectedGraduatedYear = store.getters[
          'graduateds/graduateds'
        ].filter(year => year.id === newVal)[0].year;
        await Promise.all([
          store.dispatch(
            'applicant/FETCH_UNREAD_APPLICANT_COUNT',
            selectedGraduatedYear,
          ),
          store.dispatch(
            'applicant/FETCH_UNREAD_LINE_COUNT',
            selectedGraduatedYear,
          ),
          store.dispatch('staff/SET_SELECTED_GRADUATED_ID', newVal),
        ]);
        selectedYears.value = store.getters['graduateds/graduateds'].filter(
          year => year.id === newVal,
        );
        await store.dispatch('auth/INIT');
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `${graduatedYearToLabelName(
            selectedYears.value[0].year,
          )} の選考に変更しました。`,
          type: true,
        });
        await store.dispatch('page/SET_LOADED');
      },
    });

    // methods
    const graduatedYearToLabelName = year => {
      const res = GraduatedDefines.find(v => v.year === year);
      if (res !== undefined) return res.name_jp;
      return `${year}年卒業者`;
    };
    const onClickFunctionMenu = () => {
      isFunctionMenu.value = !isFunctionMenu.value;
    };

    // lifecycle
    onMounted(() => {
      currentPlan.data = getPlanDataById(store.getters['plan/plan'].id);
    });

    return {
      isFunctionMenu,
      currentPlan,
      applicantCount,
      selectedGraduatedId,
      graduatedYearToLabelName,
      onClickFunctionMenu,
      postPottosEvent,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 1.4rem;
  background: linear-gradient(90deg, #1899d6, #2186b5);
  .header-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 510px;
    .header-touchMenu {
      display: none;
    }
    .header-logo {
      display: flex;
      margin-left: 16px;
      @media (min-width: ($media_query_sp)) {
        padding: 0 20px;
        width: 180px;
      }
      .logo-img {
        @media (max-width: (365px)) {
          width: 94px;
        }
      }
    }
    .header-plan {
      font-size: 1.2rem;
      margin-right: 10px;
      .plan_content {
        text-align: center;
        .plan_name {
          background-color: #ffa439;
          padding: 4px 10px;
          border-radius: 4px;
          font-weight: bold;
          cursor: pointer;
          transition: all ease 0.3s;
          margin-right: 5px;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
    .header-unread_count {
      min-width: 90px;
      margin-right: 10px;
      .chat_icon {
        height: 18px;
        margin-right: 3px;
        fill: #fff;
      }
      .unread_count {
        display: inline-block;
        color: #fff;
        font-size: 1.2rem;
        background-color: #e83d86;
        padding: 4px 10px;
        margin-right: 4px;
        border-radius: 12px;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .header-functions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .header-touchFunctionMenu {
      display: none;
      padding-right: 16px;
      margin: 0;
    }
    .header-function {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .header-user {
        font-size: 1.4rem;
        .company {
          display: inline-block;
          padding-right: 10px;
        }
        .name {
          display: inline-block;
          margin-top: 5px;
          font-weight: bold;
          padding-right: 10px;
        }
        .plan_name {
          display: none;
        }
      }
      .header-selectGraduated {
        padding-right: 10px;
        > .c-select {
          display: inline-block;
          position: relative;
        }
        > .c-select::after {
          position: absolute;
          top: 50%;
          right: 7px;
          width: 6px;
          height: 6px;
          content: '';
          margin-top: -4px;
          border: 0;
          border-bottom: solid 2px #fff;
          border-right: solid 2px #fff;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        > .c-select > .select {
          padding: 7px 20px 7px 9px;
          border-radius: 4px;
          border: 1px solid #fff;
          font-weight: bold;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          &::-ms-expand {
            display: none;
          }
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
        > .c-select > .select > .select-elements {
          color: #333333;
        }
      }
      .header-logout {
        min-width: 125px;
        padding: 18px 20px;
        font-weight: bold;
        border-left: 1px solid #fff;
        cursor: pointer;
        transition: background-color 0.2s ease-out;
        &:hover {
          background-color: #0f658d;
        }
      }
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .header {
    .header-menu {
      min-width: inherit;
      .header-touchMenu {
        display: block;
        width: 50px;
        height: 50px;
        background-color: #73cdcf;
        cursor: pointer;
        > .item {
          position: absolute;
          width: 4px;
          height: 4px;
          background-color: #fff;
          transition: all 0.2s ease-out;
        }
        > .item:nth-child(1) {
          top: 15px;
          left: 15px;
        }
        > .item:nth-child(2) {
          top: 15px;
          left: 23px;
        }
        > .item:nth-child(3) {
          top: 15px;
          left: 31px;
        }
        > .item:nth-child(4) {
          top: 23px;
          left: 15px;
        }
        > .item:nth-child(5) {
          top: 23px;
          left: 23px;
        }
        > .item:nth-child(6) {
          top: 23px;
          left: 31px;
        }
        > .item:nth-child(7) {
          top: 31px;
          left: 15px;
        }
        > .item:nth-child(8) {
          top: 31px;
          left: 23px;
        }
        > .item:nth-child(9) {
          top: 31px;
          left: 31px;
        }
      }
      .header-touchMenu.-open {
        > .item:nth-child(1) {
          top: 23px;
          left: 14px;
          width: 24px;
          border-radius: 90px;
          transform: rotate(45deg);
        }
        > .item:nth-child(2) {
          display: none;
        }
        > .item:nth-child(3) {
          display: none;
        }
        > .item:nth-child(4) {
          display: none;
        }
        > .item:nth-child(5) {
          display: none;
        }
        > .item:nth-child(6) {
          display: none;
        }
        > .item:nth-child(7) {
          display: none;
        }
        > .item:nth-child(8) {
          display: none;
        }
        > .item:nth-child(9) {
          top: 23px;
          left: 14px;
          width: 24px;
          border-radius: 90px;
          transform: rotate(-45deg);
        }
      }
      .header-touchFunctionMenu {
        display: block;
        position: absolute;
        top: 8px;
        right: 10px;
        margin: 0;
        border-radius: 99px;
        box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .header-functions {
      .header-touchFunctionMenu {
        display: block !important;
      }
      .header-function {
        .header-user {
          display: none;
        }
        .header-selectGraduated {
          display: none;
        }
        .header-logout {
          display: none;
        }
        &::before {
          content: '';
          position: absolute;
          top: -19px;
          right: 10px;
          border: 7px solid transparent;
          border-bottom: 12px solid #2186b5;
        }
        &.-open {
          display: block;
          max-width: 300px;
          animation: fadeDownIn 0.3s;
          opacity: 1;
          position: absolute;
          top: 70px;
          right: 10px;
          text-align: center;
          font-size: 1.2rem;
          background-color: #2186b5;
          .header-user {
            display: block;
            margin: 20px;
            padding: 0;
            border: none;
            .company {
              width: 100%;
              line-height: 1.5;
            }
            .name {
              width: 100%;
              padding-top: 20px;
            }
          }
          .header-selectGraduated {
            display: block;
            .c-select {
              width: 100%;
              .select {
                width: 100%;
              }
            }
            margin: 20px;
            padding: 0;
          }
          .header-logout {
            display: block;
            border: none;
            background-color: #1c6f99;
          }
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .header {
    .header-menu {
      .plan_name {
        display: none !important;
      }
      .plan_applicant_count {
        display: none !important;
      }
    }
    .header-functions {
      .header-function {
        .header-user {
          .name {
            padding: 20px 0;
          }
          .plan_name {
            display: block;
            background-color: #ffa439;
            padding: 4px 10px;
            border-radius: 4px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
