<template>
  <div v-if="!isAdmin" class="value">{{ staff.email }}</div>
  <div v-else class="value">
    <div class="edit-text">
      <h2 class="staff_setting_list_item_ttl">
        <div v-if="editMode" class="staffs-email_form_item">
          <input
            v-model="emailForm"
            class="mail-form"
            type="text"
            :class="{ 'is-danger': errors.email.isValid !== true }"
            placeholder="example@mochica.jp"
            @input="validateOnInput('email', emailForm, validateEmail)"
          />
          <span
            v-if="errors.email.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.email.message }}
          </span>
        </div>
        <div v-else>
          {{ staff.email }}
        </div>
      </h2>

      <button-base
        class="btn"
        :rejected-admin-ids="[2]"
        :button-text="
          editMode === true ? 'メールアドレスを確定' : 'メールアドレスを変更'
        "
        :disabled="!validateEmail('email', emailForm)"
        :button-type="editMode === true ? 'success' : 'primary'"
        :icon-file-name="editMode === true ? 'check_white' : 'pen_white'"
        @click="editMode === true ? commit() : (editMode = true)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'AdminEditStaffEmail',
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['commitedEmailChange'],
  setup(props, context) {
    const { errors, validateOnInput, validateEmail } = useValidation(['email']);
    const editMode = ref(false);
    const emailForm = ref(props.staff.email);

    // watch
    watch(
      () => props.staff,
      () => {
        emailForm.value = props.staff.email;
      },
    );

    const commit = () => {
      editMode.value = false;
      context.emit('commitedEmailChange', {
        staff_id: props.staff.id,
        staff_email: emailForm.value,
      });
    };

    return {
      errors,
      validateOnInput,
      validateEmail,
      editMode,
      emailForm,
      commit,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.edit-text {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .staffs-email_form_item {
    white-space: initial;
    input {
      width: 300px;
      height: 40px;
      padding: 0.6rem;
      border-radius: 0.4rem;
      border: 1px solid $border_color;
      font-size: 1.4rem;
      background: #fff;
    }
    span {
      &.fa-warning {
        color: #c31e1e;
        padding-top: 4px;
        font-size: 1.2rem;
      }
      &.fa {
        display: block;
      }
    }
  }
}
.ui-select {
  height: 30px;
  border: 1px solid #e2e2e2;
}
.btn {
  margin-left: 20px;
}

@media (max-width: ($media_query_sp)) {
  .edit-text {
    font-size: 1rem;
  }
  .btn {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 150px;
    bottom: -50px;
  }
}
</style>
