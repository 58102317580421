<template>
  <div>
    <!-- 選考編集画面でローカルに会場を追加した状態 -->
    <div
      v-if="isEditSelection === true && !venue.id"
      class="venue-header new-venue"
    >
      <p class="venue-name">新規選考会場</p>
      <div>
        <button-base
          v-if="$store.getters['staff/isOperator'] !== true"
          :button-text="'選考会場の情報を確定'"
          :button-type="'success'"
          :icon-file-name="'check'"
          :is-loading="isLoading === true"
          :rejected-role-ids="rejectedRoleIds"
          :rejected-admin-ids="rejectedAdminIds"
          @click="onClickAddVenue"
        />
      </div>
    </div>
    <div v-else class="venue-header">
      <p class="venue-name">選考会場{{ iVenue + 1 }}</p>
      <!-- 権限がない または 選考通知 の場合は非活性ボタンとなる -->
      <button
        class="btn btn-applicant-wanted-status"
        :disabled="hasStaffPermission !== true || isAnnounceModal === true"
        :class="[venue.active === true ? 'btn-availability' : 'btn-caution']"
        @click="onClickUpdateActiveVenue"
      >
        <span>
          {{ venue.active === true ? '応募者の募集中' : '応募者の募集停止中' }}
        </span>
      </button>
    </div>
    <div :class="{ edit: isEditVenue }" class="row-inner no_underline">
      <div class="venue-block">
        <div class="title" :class="{ 'is-required': isEditVenue }">
          選考会場名
          <span v-if="isEditVenue === true">（20文字まで）</span>
        </div>
        <div v-if="isEditVenue === true" class="value">
          <input
            v-model="venue.name"
            class="value-input input-venue_name"
            type="text"
            placeholder="選考会場名を入力してください（20文字まで）"
            maxlength="20"
            :class="{ 'is-danger': errors.venueName.isValid !== true }"
            @input="validateOnInput('venueName', venue.name, validateRequire)"
          />
          <p v-if="errors.venueName.isValid !== true" class="fa-warning font-s">
            {{ errors.venueName.message }}
          </p>
        </div>
        <div v-else class="value">{{ venue.name }}</div>
      </div>
      <div class="venue-block">
        <div class="title" :class="{ 'is-required': isEditVenue }">
          会議室名
          <span v-if="isEditVenue === true">（20文字まで）</span>
        </div>
        <div v-if="isEditVenue === true" class="value">
          <input
            v-model="venue.roomName"
            class="value-input input-conference_room"
            type="text"
            placeholder="会議室名（20文字まで）"
            maxlength="20"
            :class="{ 'is-danger': errors.roomName.isValid !== true }"
            @input="
              validateOnInput('roomName', venue.roomName, validateRequire)
            "
          />
          <p v-if="errors.roomName.isValid !== true" class="fa-warning font-s">
            {{ errors.roomName.message }}
          </p>
        </div>
        <div v-else class="value">{{ venue.roomName }}</div>
      </div>
      <div class="venue-block">
        <div class="title" :class="{ 'is-required': isEditVenue }">
          選考開催日
        </div>
        <div v-if="isEditVenue === true" class="value">
          <el-date-picker
            id="el-input__inner"
            :model-value="venue.date"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder
            name="selectionDate"
            data-vv-as="開催日"
            :class="{ 'is-danger': errors.selectionDate.isValid !== true }"
            @update:model-value="venue.date = $event"
            @change="
              validateOnInput('selectionDate', venue.date, validateRequire)
            "
          />
          <p
            v-if="errors.selectionDate.isValid !== true"
            class="fa-warning font-s"
          >
            {{ errors.selectionDate.message }}
          </p>
        </div>
        <div v-else class="value">
          {{ $utils.parseDateTime(venue.date, 'YYYY/MM/DD') }}
        </div>
      </div>
      <div class="venue-block">
        <div class="title">キャンセル期限</div>
        <div
          v-if="isEditVenue === true || isEditLimitDate === true"
          class="value"
        >
          <el-date-picker
            id="el-input__inner"
            :model-value="venue.limitDate"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder
            @update:model-value="venue.limitDate = $event"
            @focus="isVisibleLimitDate = true"
            @blur="isVisibleLimitDate = false"
          />
        </div>
        <div v-else-if="venue.limitDate" class="value">
          {{
            venue.limitDate
              ? $utils.parseDateTime(venue.limitDate, 'YYYY/MM/DD')
              : 'なし'
          }}
        </div>
      </div>
    </div>
    <div
      v-if="isEditVenue === true || isEditLimitDate === true"
      class="row_inner_description"
    >
      ※キャンセル期限を入力すると、応募者がマイページからキャンセルできるようになります。
    </div>
    <div class="row-inner">
      <div class="venue-block">
        <div class="title" :class="{ 'is-required': isEditVenue === true }">
          住所
        </div>
        <div v-if="isEditVenue === true" class="value address-block">
          <div>
            <select
              v-model="venue.pref"
              class="value-input input-prefectures"
              type="text"
              placeholder="都道府県"
              :class="{ 'is-danger': errors.prefecture.isValid !== true }"
              @change="
                validateOnInput('prefecture', venue.pref, validateRequire)
              "
            >
              <option
                v-for="(v, i) in prefectures"
                :key="`prefecture_${i}`"
                :value="v"
              >
                {{ v }}
              </option>
              <option value="WEB">WEB</option>
            </select>
            <p
              v-if="errors.prefecture.isValid !== true"
              class="fa-warning font-s"
            >
              {{ errors.prefecture.message }}
            </p>
          </div>
          <div class="value-input-address">
            <input
              v-model="venue.address"
              class="value-input input-address"
              type="text"
              placeholder="住所（都道府県を除く、50文字まで）"
              maxlength="50"
              :class="{ 'is-danger': errors.address.isValid !== true }"
              @input="
                validateOnInput('address', venue.address, validateRequire)
              "
            />
            <p v-if="errors.address.isValid !== true" class="fa-warning font-s">
              {{ errors.address.message }}
            </p>
          </div>
        </div>
        <div v-else class="value">{{ venue.pref }} {{ venue.address }}</div>
      </div>
    </div>
    <!-- Google未連携|Google連携済みかつ社内スタッフ|Google連携済みかつ社外スタッフかつGoogle登録開催時間がない 場合のみ表示 -->
    <div
      v-if="
        isEditableVenue === true &&
        isEditSelection === true &&
        venue.id !== null &&
        $store.getters['staff/isOperator'] !== true
      "
      class="exist-venue-menu"
    >
      <button-base
        v-if="isEditVenue === true || isEditLimitDate === true"
        button-text="会場情報を確定"
        button-type="success"
        icon-file-name="check"
        :is-loading="isLoading"
        :rejected-role-ids="rejectedRoleIds"
        :rejected-admin-ids="rejectedAdminIds"
        @click="onClickUpdateVenue"
      />
      <template v-else>
        <button-base
          :button-text="
            venue.actual === 0 ? '会場情報を編集' : 'キャンセル期限を編集'
          "
          button-type="primary"
          icon-file-name="pen"
          :is-loading="isLoading"
          :rejected-role-ids="rejectedRoleIds"
          :rejected-admin-ids="rejectedAdminIds"
          @click="onClickEditVenue"
        />
        <p v-if="venue.actual !== 0" class="txt-warning">
          この選考会場の情報はすでに参加予定の応募者がいるため、キャンセル期限以外は編集できません。
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import prefectures from '@/components/features/selectionDetailBody/defines/prefectures';
import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'SelectionsVenueBasicInfo',
  props: {
    iVenue: {
      type: Number,
      default: null,
    },
    isAnnounceModal: {
      type: Boolean,
      default: false,
    },
    isConnectedGoogleCalendar: {
      type: Boolean,
      default: false,
    },
    isRegistrationSelection: {
      type: Boolean,
      default: false,
    },
    isEditSelection: {
      type: Boolean,
      default: false,
    },
    selectionVenues: {
      type: Array,
      default: () => [],
    },
    createVenue: {
      type: Function,
      default: () => {},
    },
    updateVenue: {
      type: Function,
      default: () => {},
    },
    updateTimetable: {
      type: Function,
      default: () => {},
    },
    updateVenueData: {
      type: Function,
      default: () => {},
    },
    updateVenueDataByKey: {
      type: Function,
      default: () => {},
    },
    updateActiveVenue: {
      type: Function,
      default: () => {},
    },
    validateVenueAndTimetables: {
      type: Function,
      default: () => {},
    },
    refSelectionsTimetables: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['onAddVenue', 'fetchAll'],
  setup(props, context) {
    const { errors, resetErrors, validateOnInput, validateRequire } =
      useValidation([
        'venueName',
        'roomName',
        'selectionDate',
        'prefecture',
        'address',
      ]);
    const store = useStore();
    const isLoading = ref(false);
    const isEditVenue = ref(
      props.isRegistrationSelection === true ||
        (props.isEditSelection === true &&
          !props.selectionVenues[props.iVenue].id),
    );
    // 選考新規作成及び選考編集時は編集状態が初期状態
    const isEditTimetable = ref(
      props.isRegistrationSelection === true ||
        (props.isEditSelection === true &&
          !props.selectionVenues[props.iVenue].id),
    );
    const isEditLimitDate = ref(false);
    const isVisibleLimitDate = ref(false);

    // computed
    // 会場編集条件
    // Google連携編集条件
    const isEditableVenue = computed(() => {
      // Google未連携であれば編集可能
      if (props.isConnectedGoogleCalendar !== true) return true;
      // Google連携時、社内メンバーであれば編集可能
      if (isGoogleMember.value === true) return true;
      // Google連携時、社外メンバーかつGoogle未登録開催時間のみであれば編集可能
      if (isIncludeGoogleEventId.value !== true) return true;
      return false;
    });
    // 開催時間に一つでもGoogleカレンダー登録済み・登録エラーが存在するかチェック
    const isIncludeGoogleEventId = computed(() => {
      let id = false;
      venue.value.timetables.forEach(timetable => {
        if (
          timetable.hasGoogleEventId === true ||
          timetable.isGoogleSyncError === true
        ) {
          id = true;
        }
      });
      return id;
    });
    const isGoogleMember = computed(() => {
      return store.getters['staff/staff']
        ? store.getters['staff/staff'].is_google_member
        : false;
    });
    const hasStaffPermission = computed(() => {
      return (
        store.getters['staff/staff'].admin !== 2 &&
        store.getters['staff/staff'].role.id !== 3
      );
    });
    const venue = computed(() => props.selectionVenues[props.iVenue]);

    // methods
    // 選考会場の追加
    const onClickAddVenue = async () => {
      if (props.validateVenueAndTimetables() !== true) return;
      isLoading.value = true;
      const res = await props.createVenue(venue.value, store);
      if (res.success !== true) {
        isLoading.value = false;
        return;
      }
      isEditLimitDate.value = false;
      isEditVenue.value = false;
      isEditTimetable.value = false;
      context.emit('onAddVenue');
      isLoading.value = false;
    };
    // 開催時間の更新
    const updateTimeTable = async venueId => {
      if (isEditTimetable.value !== true) return;
      if (
        props.refSelectionsTimetables[venueId] &&
        props.refSelectionsTimetables[venueId].length > 0 &&
        props.refSelectionsTimetables[venueId][0].validateTimetable
      ) {
        // 開催時間のバリデーション
        props.refSelectionsTimetables[venueId][0].validateTimetable();
      }
      isLoading.value = true;
      const res = await props.updateTimetable(
        props.iVenue,
        props.isGoogleMember,
        store,
      );
      isLoading.value = false;
      isEditTimetable.value = false;
      if (res.success === true) context.emit('fetchAll');
    };
    // 選考会場の更新
    const onClickUpdateVenue = async () => {
      if (
        (isEditVenue.value !== true && isEditLimitDate.value !== true) ||
        isLoading.value === true
      ) {
        return;
      }
      validateVenue();
      isLoading.value = true;
      const res = await props.updateVenue(
        venue.value,
        isEditLimitDate.value,
        store,
      );
      isLoading.value = false;
      if (res.success !== true) return;
      isEditVenue.value = false;
      isEditLimitDate.value = false;
      if (res.success === true) context.emit('fetchAll');
    };
    // 会場のバリデーション
    const validateVenue = () => {
      resetErrors();
      const isValidVenueName = validateRequire('venueName', venue.value.name);
      const isValidRoomName = validateRequire('roomName', venue.value.roomName);
      const isValidSelectionDate = validateRequire(
        'selectionDate',
        venue.value.date,
      );
      const isValidPrefecture = validateRequire('prefecture', venue.value.pref);
      const isValidAddress = validateRequire('address', venue.value.address);
      return (
        isValidVenueName === true &&
        isValidRoomName === true &&
        isValidSelectionDate === true &&
        isValidPrefecture === true &&
        isValidAddress === true
      );
    };
    // 応募者の募集の更新
    const onClickUpdateActiveVenue = async () => {
      if (store.getters['staff/staff'].role.name === 'user') {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '募集の切り替えは、管理者・人事担当の権限でのみ変更できます',
          type: false,
        });
        return;
      }
      if (venue.value.active === true) {
        props.updateVenueDataByKey(props.iVenue, 'active', false);
      } else {
        if (props.selectionVenues.filter(v => v.active).length >= 30) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: '応募者を募集できる選考会場の上限は30会場です。',
            type: false,
          });
          return;
        }
        props.updateVenueDataByKey(props.iVenue, 'active', true);
      }
      // 選考新規作成時はAPIリクエストを投げない
      if (props.isRegistrationSelection === true) return;
      const venueData = venue.value.jsonObjExceptTimetables();
      const res = await props.updateActiveVenue(
        venue.value.jsonObjExceptTimetables(),
      );
      if (res.success === true) {
        context.emit('fetchAll');
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message:
            venueData.active === true
              ? '募集中に変更しました'
              : '募集停止に変更しました',
          type: true,
        });
      }
    };
    // 会場の編集開始
    const onClickEditVenue = () => {
      if (venue.value.actual === 0) {
        isLoading.value = false;
        isEditVenue.value = true;
        return;
      }
      // キャンセル期限のみの編集
      isLoading.value = false;
      isEditLimitDate.value = true;
    };

    return {
      errors,
      prefectures,
      rejectedRoleIds: [3],
      rejectedAdminIds: [2],
      isLoading,
      isEditVenue,
      isEditTimetable,
      isEditLimitDate,
      isVisibleLimitDate,
      isEditableVenue,
      hasStaffPermission,
      venue,
      validateOnInput,
      validateRequire,
      onClickAddVenue,
      updateTimeTable,
      validateVenue,
      onClickUpdateActiveVenue,
      onClickEditVenue,
      onClickUpdateVenue,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';
@include validation_effects3();

.venue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 7px 7px 15px;
  color: #fff;
  background-color: #808080;
}

.row_inner_description {
  text-align: left;
  font-size: 12px;
  margin-left: 20px;
}

.row-inner {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 15px 0;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
  &.no_underline {
    border-bottom: none;
  }
  input {
    font-size: 1.4rem;
    background-color: #fff;
  }
}

.venue-block {
  margin: 0 15px 15px 0;
  &.venue-name {
    font-weight: bold;
  }
  .title {
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 14px;
    > span {
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: normal;
      color: #777;
    }
    &.is-required::after {
      display: inline-block;
      content: '*';
      line-height: 1;
      font-weight: 700;
      color: #c31e1e;
    }
  }
  .value {
    line-height: 1.3;
    font-size: 1.4rem;
    padding-bottom: 2px;
  }
}

.exist-venue-menu {
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #e2e2e2;
  .txt-warning {
    margin: 20px 0;
  }
}

.input-venue_name {
  width: 400px;
}

.input-conference_room {
  width: 300px;
}

.address-block {
  display: flex;
  align-items: flex-start;
}

.input-prefectures {
  width: 150px;
  height: 3rem;
  margin-right: 10px;
  border: solid 1px #adadad;
  font-size: 1.4rem;
}

.input-address {
  width: 500px;
}

.btn-applicant-wanted-status:disabled {
  cursor: not-allowed;
  &.btn-availability {
    background-position: calc(50% - 90px) 10px;
    background-color: #16b2d9;
  }
  &.btn-caution {
    background-color: #bf0000;
  }
}

@media (max-width: ($media_query_tablet)) {
  .value-input-address,
  .input-address {
    width: 100%;
  }
}

@media (max-width: (880px)) {
  .row-inner {
    display: block;
    margin: 15px 15px 0;
    .venue-block {
      margin-right: 0;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .venue-header {
    display: block;
    padding: 10px 10px;
    text-align: center;
    .venue-name {
      margin-bottom: 10px;
    }
  }

  .exist-venue-menu {
    line-height: 1.3;
  }

  .address-block {
    display: block;
  }

  .input-venue_name,
  .input-conference_room {
    width: 100%;
  }

  .input-address {
    margin-top: 10px;
  }

  .row-inner {
    margin: 15px 10px;
  }
}
</style>
