<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  min-height: 420px;
  border: 1px solid $mochica_color_gray2;
  border-radius: 4px;
  background: #f6f8fa;
  line-height: 1.8;
  font-size: $font_14;
  &.empty-flow {
    min-height: 120px;
    font-size: $font_12;
  }
}
</style>
