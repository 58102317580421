<script setup>
import ConfirmEmail from '@/components/ui/confirm/ConfirmEmail.vue';
import ConfirmLine from '@/components/ui/confirm/ConfirmLine.vue';
import ProgressStatusLabel from '@/components/features/progress/ProgressStatusLabel.vue';

import {
  FINISHED_PROGRESS_STATUSES,
  FINISHED_PROGRESS_STATUSES_LABEL,
} from '@/defines/progresses';

const props = defineProps({
  results: {
    type: Object,
    default: () => ({
      passed: {
        email: {
          subject: '',
          content: '',
        },
        line: {
          content: '',
        },
      },
      offered: {
        email: {
          subject: '',
          content: '',
        },
        line: {
          content: '',
        },
      },
      dropped: {
        email: {
          subject: '',
          content: '',
        },
        line: {
          content: '',
        },
      },
    }),
  },
});

const valuesArray = () => {
  return Object.entries(props.results).map(([key, value]) => ({
    key,
    ...value,
  }));
};
</script>

<template>
  <div class="action-inner">
    <p class="selected-action">選考の結果を通知</p>
    <div class="action-contents auto-action">
      <div class="action-forms">
        <div
          v-for="(statusValues, index) in valuesArray()"
          :key="index"
          class="action-form"
        >
          <div class="action-form-title" :class="statusValues.key">
            <ProgressStatusLabel
              :label-name="
                FINISHED_PROGRESS_STATUSES_LABEL[
                  FINISHED_PROGRESS_STATUSES[statusValues.key]
                ]
              "
            />
            の人に送信するメール・LINE
          </div>
          <div class="action-form-contents">
            <confirm-email :confirm-values="statusValues.email" />
            <confirm-line
              :confirm-values="{
                content: statusValues.line.content,
                isForceSendEmail: false,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
.action-forms {
  width: 100%;
}
</style>
