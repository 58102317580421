import { Applicant } from '@/types/applicant';

export const getApplicantFullName = (applicant: Applicant): string =>
  `${applicant.lastname ?? ''}${applicant.firstname ?? ''}`;

export const getApplicantFullNameKana = (applicant: Applicant): string =>
  `${applicant.lastnameKana ?? ''}${applicant.firstnameKana ?? ''}`;

export const getApplicantStaffFullName = (
  staff: Exclude<Applicant['staff'], null>,
): string => `${staff.lastname}${staff.firstname}`;
