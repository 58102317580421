<template>
  <section class="as-block">
    <div class="headline">
      <img class="icon" src="@/assets/img/message_white.svg" />
      メール・メッセージ
    </div>

    <div class="inner">
      <div class="btn btn-availability" @click="fetchLineAllOpenedMessage()">
        <img src="@/assets/img/icon_check.svg" />
        全てのメッセージを既読にする
      </div>

      <div class="linechat">
        <div class="linechat_block">
          <div class="notifier-new-talk">
            <p v-if="newMessageVisible" @click="onClickNewMessageNotifier">
              <img src="@/assets/img/icon_update_talk.svg" />
              新しいメッセージがあります。クリックすると最新のメッセージに移動します。
            </p>
          </div>
          <div ref="refMessageArea" class="linechat_block_message-area">
            <div
              ref="refMessageScrollArea"
              class="linechat_block_message-area_scroll-area"
            >
              <div v-if="oldMessageLoading" class="loading-message">
                <span>Loading...</span>
              </div>
              <ul ref="refMessageList" class="message-list">
                <div
                  v-for="talk in messages"
                  :id="'message_' + talk.id"
                  :key="talk.id"
                  class="message-list-item"
                >
                  <talk-item
                    :talk="talk"
                    @reloadMessages="reloadMessages"
                    @downloadFile="downloadFile"
                    @updateMessage="updateMessage"
                  />
                </div>
              </ul>
            </div>
          </div>
          <talk-input
            :applicant-graduated-id="applicantGraduatedId"
            @fetchNewMessage="fetchNewMessage"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import lineTalkService from '@/services/linetalk';
import TalkItem from '@/components/page/applicants/_id/components/TalkItem';
import TalkInput from '@/components/page/applicants/_id/components/TalkInput';
import { postPottosEvent } from '@/utils/pottos';

const intervalFetchNewMessage = 60 * 1000; // 1min

export default defineComponent({
  name: 'TalkIndex',
  components: { TalkItem, TalkInput },
  props: {
    applicantGraduatedId: {
      type: Number,
      required: true,
    },
  },
  emits: ['downloadFile'],
  setup(props, context) {
    const store = useStore();
    const refMessageScrollArea = ref(null);
    const refMessageArea = ref(null);
    const refMessageList = ref(null);
    const isLockScroll = ref(false);
    const isEnabledMessageScroll = ref(false);
    const messages = ref([]);
    const oldestTalkId = ref(null);
    const latestTalkId = ref(null);
    const fetchTimeoutId = ref(null);
    const oldMessageLoading = ref(false);
    const oldMessageLoadedCount = ref(0);
    const newMessageVisible = ref(false);
    const mousePosX = ref(0);
    const mousePosY = ref(0);

    const applicant = computed(() => {
      return store.getters['applicant/applicant'];
    });

    watch(isLockScroll, val => {
      if (val) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    });
    watch(isEnabledMessageScroll, async val => {
      if (val) {
        await refMessageScrollArea.value.addEventListener(
          'scroll',
          scrollMessageArea,
        );
      } else {
        await refMessageScrollArea.value.removeEventListener(
          'scroll',
          scrollMessageArea,
        );
      }
    });

    // 初回Talk取得
    const fetchAll = async () => {
      const res = await lineTalkService.fetchLineInitMessage(
        applicant.value.id,
      );
      if (res.success) {
        setNewMessages(res.line_messages || []);
        setOldestTalkId(res.line_messages || []);
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
    };
    // Talkの再取得
    const reloadMessages = async () => {
      const Limit = messages.value.length;
      const res = await lineTalkService.fetchLineInitMessage(
        applicant.value.id,
        Limit,
      );
      if (res.success) {
        messages.value = [];
        oldestTalkId.value = null;
        setNewMessages(res.line_messages || []);
        setOldestTalkId(res.line_messages || []);
      }
    };
    // 規定分毎に新規Talk確認処理
    const pollingNewMessage = async () => {
      // 既にポーリングが走っている場合は停止してから新規ポーリング開始
      if (fetchTimeoutId.value !== null) clearTimeout(fetchTimeoutId.value);
      const timeoutId = setTimeout(
        checkLineNewMessage,
        intervalFetchNewMessage,
      );
      fetchTimeoutId.value = timeoutId;
    };
    // ポーリング時に新規Messageが存在するかだけチェック
    const checkLineNewMessage = async () => {
      const res = await lineTalkService.checkLineNewMessage(
        applicant.value.id,
        latestTalkId.value,
      );
      if (res.message_created_at === true) {
        // 新規メッセージが存在する場合
        newMessageVisible.value = true;
        // 新規メッセージが見つかった場合ポーリング停止
        clearTimeout(fetchTimeoutId.value);
      } else if (res.success === false) {
        clearTimeout(fetchTimeoutId.value);
      } else {
        // noticeを消す
        newMessageVisible.value = false;
        // 新規メッセージがなければポーリング再開
        pollingNewMessage();
      }
    };
    // 最新Talkの取得
    const fetchNewMessage = async (args = { hasImage: false }) => {
      const res = await lineTalkService.fetchLineNewMessage(
        applicant.value.id,
        latestTalkId.value,
      );
      if (res.success) {
        // 最新メッセージを自動的に読み込む場合
        await setNewMessages(res.line_messages);
        scrollToLatestMessage();
        if (args.hasImage === true) context.emit('setFiles');
        // 新規メッセージを取得したのでnoticeを消す
        newMessageVisible.value = false;
        // メッセージ取得後にポーリング再開
        pollingNewMessage();
      }
    };
    const wait = (msec = 1000) => {
      return new Promise(resolve => setTimeout(resolve, msec));
    };
    // 過去Talkの取得
    const fetchOldMessage = async () => {
      const keepOldestTalkId = oldestTalkId.value;
      const promiseObj = await Promise.all([
        await lineTalkService.fetchLineOldMessage(
          applicant.value.id,
          oldestTalkId.value,
        ),
        await wait(150),
      ]);
      const res = promiseObj[0];
      if (res.success && res.line_messages.length > 0) {
        const tmpMessages = res.line_messages || [];
        if (messages.value.length > 0) {
          messages.value = messages.value.concat(tmpMessages);
        }
        setOldestTalkId(res.line_messages || []);
        scrollToMessageId(keepOldestTalkId);
      }
      oldMessageLoading.value = false;
    };
    // 新規Talkのセット
    const setNewMessages = tmpMessages => {
      if (tmpMessages && tmpMessages.length) {
        messages.value = tmpMessages.concat(messages.value);
        // APIから最新順にかえってくるので0番目を指定
        latestTalkId.value = tmpMessages[0].id;
      }
      return [];
    };
    // 過去Talkのセット
    const setOldestTalkId = messages => {
      if (messages.length > 0) {
        oldestTalkId.value = messages[messages.length - 1].id;
        oldMessageLoadedCount.value = messages.length;
      }
    };
    // 最新のTalkまでスクロール
    const scrollToLatestMessage = () => {
      refMessageScrollArea.value.scrollTop = refMessageList.value.clientHeight;
    };
    // 対象IDまでスクロール
    const scrollToMessageId = id => {
      const elmMessage = document.getElementById(`message_${id}`);
      if (elmMessage) {
        refMessageScrollArea.value.scrollTop = elmMessage.offsetTop;
      }
    };
    // 新規Talk通知押下時のイベント
    const onClickNewMessageNotifier = async () => {
      const res = await lineTalkService.fetchLineNewMessage(
        applicant.value.id,
        latestTalkId.value,
      );
      if (res.success) {
        await setNewMessages(res.line_messages);
        scrollToLatestMessage();
        // 新規メッセージを取得したのでnoticeを消す
        newMessageVisible.value = false;
        // メッセージ取得後にポーリング再開
        pollingNewMessage();
      }
    };
    // 全てのTalkを既読にする処理
    const fetchLineAllOpenedMessage = async () => {
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899d6',
        title: 'メッセージを全て既読にする',
        message: 'メッセージを全て既読に変更しますか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          if (payload.choice === 2) {
            postPottosEvent(30);
            const res = await lineTalkService.fetchLineAllOpenedMessage(
              applicant.value.id,
              null,
            );
            if (res.success) {
              await reloadMessages();
              scrollToLatestMessage();
              // 新規メッセージを取得したのでnoticeを消す
              newMessageVisible.value = false;
              // メッセージ取得後にポーリング再開
              pollingNewMessage();
              store.dispatch('notification/VISIBLE_NOTIFICATION', {
                message: '全てのメッセージを既読に変更しました',
                type: true,
              });
              store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            } else {
              store.dispatch('notification/VISIBLE_NOTIFICATION', {
                message:
                  '既読の変更に失敗しました。時間をおいてもう一度お試し下さい。',
                type: false,
              });
              store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            }
          } else {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          }
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    // Talkの既読更新
    const updateMessage = ({ messageId, isOpened }) => {
      const applicantMessages = messages.value.map(message => message.id);
      const getPosition = applicantMessages.indexOf(messageId);
      messages.value[getPosition].already = isOpened;
    };
    // Talkエリアにポインターが存在するかチェック
    const isPointerOnChatArea = () => {
      const pointer = {
        x: mousePosX.value,
        y: mousePosY.value,
      };
      const area = refMessageArea.value.getBoundingClientRect();
      return (
        area.x <= pointer.x &&
        pointer.x <= area.x + area.width &&
        area.y <= pointer.y &&
        pointer.y <= area.y + area.height
      );
    };
    // Talkエリアスクロールイベント
    const scrollMessageArea = () => {
      if (isPointerOnChatArea()) isLockScroll.value = true;
      if (oldMessageLoadedCount.value === 0 || oldMessageLoading.value) return;
      const currentScrollTop = refMessageScrollArea.value.scrollTop;
      if (currentScrollTop <= 0) {
        oldMessageLoading.value = true;
        fetchOldMessage();
      }
    };
    // マウスイベント取得
    const mouseMove = ev => {
      mousePosX.value = ev.clientX;
      mousePosY.value = ev.clientY;
      if (!isPointerOnChatArea()) {
        isLockScroll.value = false;
        isEnabledMessageScroll.value = false;
      } else {
        isEnabledMessageScroll.value = true;
      }
    };
    const downloadFile = file => {
      context.emit('downloadFile', file);
    };

    onMounted(async () => {
      await window.addEventListener('mousemove', mouseMove);
      await fetchAll();
      // ポーリング開始
      pollingNewMessage();
      scrollToLatestMessage();
    });
    onUnmounted(async () => {
      await window.removeEventListener('mousemove', mouseMove);
      isLockScroll.value = false;
      // ポーリング停止
      clearTimeout(fetchTimeoutId.value);
    });

    return {
      refMessageScrollArea,
      refMessageArea,
      refMessageList,
      messages,
      oldMessageLoading,
      newMessageVisible,
      onClickNewMessageNotifier,
      fetchLineAllOpenedMessage,
      fetchNewMessage,
      reloadMessages,
      downloadFile,
      updateMessage,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.as-block {
  margin-bottom: 20px;
  background-color: #fff;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  .inner {
    padding: 30px 20px;
  }
}

.linechat {
  margin-top: 30px;
  .linechat_block {
    position: relative;
    .notifier-new-talk {
      position: absolute;
      z-index: 1;
      width: calc(100% - 2rem);
      height: 3.4rem;
      margin: 1rem;
      p {
        height: 4rem;
        background: #06c755;
        color: white;
        font-weight: bold;
        line-height: 4rem;
        text-align: center;
        border-radius: 6px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        cursor: pointer;
        transition: all 0.3s ease;
        > img {
          margin-right: 0.1rem;
        }
      }
    }
    .linechat_block_message-area {
      height: 60rem;
      background-color: #f0f8fc;
      border-radius: 4px 4px 0 0;
      .linechat_block_message-area_scroll-area {
        padding-top: 20px;
        height: 100%;
        overflow-y: scroll;
        .loading-message {
          text-align: center;
        }
      }
    }
  }
}

.message-list {
  display: flex;
  flex-wrap: wrap-reverse;
  padding-bottom: 55px;
  .message-list-item {
    width: 100%;
  }
}

@media (max-width: ($media_query_sp)) {
  .as-block {
    .inner {
      padding: 30px 0;
      > .btn-availability {
        margin-left: 20px;
      }
    }
  }
}
</style>
