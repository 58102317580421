<template>
  <main class="entry_form">
    <div class="survey_header">
      <p class="entry_form_title">
        {{ isVisiblePreview !== true ? surveyTitle : '入力内容の確認' }}
      </p>
      <p class="entry_form_caption">
        {{
          isVisiblePreview !== true
            ? '下記の質問に選択肢の中から選んで答えを選択してください。'
            : '記入内容をご確認の上、問題なければ登録ボタンを押してください。'
        }}
      </p>
    </div>
    <form
      v-if="isVisiblePreview !== true"
      name="applicantForm"
      class="input_wrapper"
    >
      <ul>
        <li v-for="(question, qIdx) in questions" :key="qIdx">
          <div class="question_text">
            質問{{ qIdx + 1 }}
            <span class="required">*</span>
          </div>
          <p class="input_head">
            {{ question.question_title }}
            <template v-if="question.multi === true">
              <br />
              <b>（複数回答可）</b>
            </template>
          </p>

          <ul
            v-if="question.question_type === 1"
            :class="{
              checkbox_wrapper: question.multi === true,
              radio_wrapper: question.multi !== true,
            }"
          >
            <li
              v-for="(answer, answerIdx) in question.answers"
              :key="answerIdx"
            >
              <input
                :id="`q${qIdx}_a${answerIdx}`"
                v-model="answers[qIdx]"
                :type="question.multi === true ? 'checkbox' : 'radio'"
                :value="answer.flag_id"
              />
              <label
                :for="`q${qIdx}_a${answerIdx}`"
                @click.prevent="onSelectedFlag(qIdx, answerIdx, question.multi)"
              >
                {{ answer.flag_name }}
              </label>
            </li>
          </ul>

          <template v-if="question.question_type === 2">
            <textarea
              v-model="answers[qIdx]"
              class="textarea"
              maxlength="800"
            />
            <p class="textarea_caption">※800文字まで</p>
          </template>
        </li>
      </ul>
      <div class="input_footer">
        <button
          class="submit_button"
          :disabled="isValid !== true"
          @click.prevent="clickFlagSurveyCheck"
        >
          確認に進む
        </button>
      </div>
    </form>
    <div v-else>
      <ul>
        <li v-for="(question, idx) in localQuestions" :key="idx">
          <div class="question_text">
            質問{{ idx + 1 }}
            <span class="required">*</span>
          </div>
          <p class="input_head">
            {{ question.question_title }}
          </p>
          <template v-if="question.question_type === 1">
            <div v-if="question.multi === true" class="answer_text">
              <p class="flag">
                {{
                  answers[idx]
                    .map(
                      id =>
                        question.answers.find(v => v.flag_id === id).flag_name,
                    )
                    .join(' ・ ')
                }}
              </p>
            </div>
            <div v-else class="answer_text">
              <p class="option-selected">
                {{
                  question.answers.find(v => v.flag_id === answers[idx])
                    .flag_name
                }}
              </p>
            </div>
          </template>
          <p v-else class="option-selected">
            <textarea
              v-model="answers[idx]"
              class="textarea answer_text"
              maxlength="800"
              :disabled="true"
            />
          </p>
        </li>
      </ul>
      <div class="input_footer">
        <button
          class="submit_button"
          :disabled="isLoading"
          @click.prevent="submitAnswers"
        >
          上記の内容で登録
        </button>
        <button class="cancel_button" @click.prevent="clickFlagSurveyBack">
          前のページに戻る
        </button>
      </div>
    </div>
    <notification />
  </main>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import flagSurveyService from '@/services/flag-survey';
import Notification from '@/components/ui/notification/components/Notification';
export default defineComponent({
  name: 'FlagSurveyQuestions',
  components: { Notification },
  props: {
    companyName: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
    surveyTitle: {
      type: String,
      default: '',
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['completed'],
  setup(props, context) {
    const store = useStore();
    const isVisiblePreview = ref(false);
    const isLoading = ref(false);
    const localQuestions = ref([]);
    const answers = ref([]);

    // computed
    const isValid = computed(() => {
      return answers.value.every((answer, i) => {
        const question = localQuestions.value[i];
        if (question.question_type === 1) {
          // 複数選択可の場合、一つ以上の回答が存在するかチェック
          if (question.multi === true) return answer.length > 0;
          // 複数選択不可の場合、回答が存在するかチェック
          return answer;
        } else {
          // 自由記述の場合、800文字以下かチェック
          return answer && answer.length > 0 && answer.length <= 800;
        }
      });
    });

    // methods
    const clickFlagSurveyCheck = () => {
      isVisiblePreview.value = true;
      window.scrollTo(0, 0);
    };
    const clickFlagSurveyBack = () => {
      isVisiblePreview.value = false;
      window.scrollTo(0, 0);
    };
    const onSelectedFlag = (questionIdx, answerIdx, multi) => {
      answers.value = answers.value.map((answer, i) => {
        if (i === questionIdx) {
          const val =
            localQuestions.value[questionIdx].answers[answerIdx].flag_id;
          // 複数選択なしの場合 answer: Number|null
          if (multi !== true) return val;
          // 複数選択の場合 answer: [Number|null] (回答が選択済みであれば回答配列から削除、未選択であれば回答配列に追加)
          return answer.includes(val)
            ? answer.filter(v => v !== val)
            : answer.concat(val);
        }
        return answer;
      });
    };
    const submitAnswers = async () => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const reqAnswers = [];
      localQuestions.value.forEach((v, i) => {
        if (v.multi !== true) {
          reqAnswers.push({
            question_id: v.question_id,
            question_type: v.question_type,
            answer: answers.value[i],
          });
        } else {
          answers.value[i].forEach(a => {
            reqAnswers.push({
              question_id: v.question_id,
              question_type: v.question_type,
              answer: a,
            });
          });
        }
      });
      const res = await flagSurveyService.postFlagsurveyAnswers({
        token: props.token,
        answers: reqAnswers,
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoading.value = false;
      if (res.success === true) context.emit('completed', res.message);
    };

    onMounted(() => {
      localQuestions.value = props.questions;
      answers.value = Array(localQuestions.value.length)
        .fill(null)
        .map((answer, i) => {
          if (localQuestions.value[i].multi === true) return [];
          return answer;
        });
    });

    return {
      isVisiblePreview,
      isLoading,
      localQuestions,
      answers,
      isValid,
      clickFlagSurveyCheck,
      clickFlagSurveyBack,
      onSelectedFlag,
      submitAnswers,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.entry_form {
  font-size: 14px;
}

.entry_form_title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.entry_form_caption {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 4px solid #f0f0f0;
  letter-spacing: 0;
}

.radio_wrapper {
  margin-bottom: 30px;
  &.is-danger {
    border: none !important;
    > input[type='radio'] + label {
      border: 1px #c31e1e solid;
    }
  }
  input[type='radio'] {
    display: none;
    & + label {
      display: block;
      position: relative;
      padding: 18px 13px 18px 42px;
      margin-bottom: 5px;
      line-height: 1;
      background-color: #fafafa;
      border: 1px solid #bcbcbc;
      box-sizing: border-box;
      border-radius: 4px;
      transition: all ease 0.3s;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        left: 16px;
        width: 18px;
        height: 18px;
        border: 1px solid #bcbcbc;
        border-radius: 9px;
        background-color: #fff;
      }
    }
  }
  input[type='radio']:checked + label {
    font-weight: bold;
    border: 1px solid #20a8e9;
    color: #20a8e9;
    background-color: #e9f7fd;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 17px;
      left: 16px;
      width: 18px;
      height: 18px;
      border: 1px solid #20a8e9;
      border-radius: 9px;
      background-color: #fff;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      left: 19px;
      width: 12px;
      height: 12px;
      border: 1px solid #20a8e9;
      border-radius: 6px;
      background-color: #20a8e9;
    }
  }
  label {
    word-break: break-all;
    cursor: pointer;
  }
}
.checkbox_wrapper {
  margin-bottom: 30px;
  &.is-danger {
    border: none !important;
    > input[type='checkbox'] + label {
      border: 1px #c31e1e solid;
    }
  }
  input[type='checkbox'] {
    display: none;
    & + label {
      display: block;
      position: relative;
      padding: 18px 13px 18px 42px;
      margin-bottom: 5px;
      line-height: 1;
      background-color: #fafafa;
      border: 1px solid #bcbcbc;
      box-sizing: border-box;
      border-radius: 4px;
      transition: all ease 0.3s;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        left: 16px;
        width: 18px;
        height: 18px;
        border: 1px solid #bcbcbc;
        border-radius: 4px;
        background-color: #fff;
      }
    }
  }
  input[type='checkbox']:checked + label {
    font-weight: bold;
    border: 1px solid #20a8e9;
    color: #20a8e9;
    background-color: #e9f7fd;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 17px;
      left: 16px;
      width: 18px;
      height: 18px;
      border: 1px solid #20a8e9;
      border-radius: 4px;
      background-color: #fff;
    }
    &:after {
      content: url(../../../../assets/img/check.svg);
      display: block;
      position: absolute;
      top: 18px;
      left: 19px;
      width: 13px;
      height: 13px;
    }
  }
  label {
    word-break: break-all;
    cursor: pointer;
  }
}

.input_head {
  margin: 0 auto 3px 0;
  font-weight: bold;
  line-height: 1.3;
  word-break: break-all;
  white-space: pre-wrap;
  margin-bottom: 8px;
}

.required {
  color: #ee3030;
  font-weight: bold;
  font-size: 14px;
}

.textarea {
  width: 100%;
  min-height: 100px;
  font-size: 16px;
  padding: 10px;
  border-color: #c4c4c4;
  border-radius: 4px;
  resize: vertical;
  & + .fa-warning {
    display: block;
    margin-top: -30px;
    margin-bottom: 30px;
  }
  &:disabled {
    background: #f4f4f4;
  }
}

.textarea_caption {
  margin-bottom: 30px;
}

.answer_text {
  margin-bottom: 30px;
  color: #000 !important;
  background-color: #fff !important;
  opacity: 1;
  .flag {
    word-break: break-all;
  }
}

.input_footer {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #f0f0f0;
}

.submit_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  background: #545454;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.cancel_button {
  width: 100%;
  height: 65px;
  padding: 10px 0;
  margin-bottom: 10px;
  font-weight: normal;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 38px;
  cursor: pointer;
}
</style>
