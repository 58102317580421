import axios from 'axios';
import store from '@/stores';

const API_BASE = process.env.VUE_APP_API_BASE || 'http://api.grip-test.com/';
const httpClient = axios.create({
  baseURL: API_BASE,
  withCredentials: true,
  validateStatus: status => status >= 200 && status < 500,
});

// XXX: axiosはデフォルトでXSRF-TOKENというCookieを読み出し
// XXX: X-XSRF-TOKENヘッダに入れて送ろうとする
const setCSRFToken = async config => {
  const res = await httpClient.get('/csrf_token');
  config.headers['CSRF-Token'] = res.data.token;
};

httpClient.interceptors.request.use(async config => {
  switch (config.method) {
    case 'post':
    case 'put':
    case 'patch':
    case 'delete':
      await setCSRFToken(config);
      break;
    default:
      break;
  }
  return config;
});

httpClient.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 400) {
      Object.assign(response.data, {
        success: true,
      });
    } else {
      Object.assign(response.data, {
        success: false,
      });
      // XXX: エラーレスポンスボディ未定義のエラーが起きた時
      if (!response.data.message) {
        Object.assign(response.data, {
          message: '未定義のエラーが起きました',
        });
      }
    }
    return response;
  },
  error => {
    console.log(error.response.data.message);
    if (
      error.response.data.message ===
        '現在LINEが使用できません。LINEの設定を見直してください。' ||
      error.response.data.message === 'LINEの配信数状況取得に失敗しました。'
    ) {
      store.dispatch('page/SET_APPLICATION_ERROR', {
        error,
        title: 'LINE設定エラー',
        message:
          'LINEの設定情報が正しく設定されていないため、現在MOCHICAを利用できません。',
      });
      return Promise.reject(error);
    }
    store.dispatch('page/SET_APPLICATION_ERROR', {
      error,
      title: 'ネットワークエラー',
      message:
        '大変申し訳ございません。ネットワークエラーが発生いたしました。\nインターネットへの接続を確認しブラウザーをリロードしてください。',
    });
    return Promise.reject(error);
  },
);

export default httpClient;
