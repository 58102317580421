<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content">
        <div class="body">
          <div class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                {{
                  `選考名${selectionName}へ登録したい応募者を選択してください。`
                }}
              </div>
            </div>
            <div class="modal-header_cancell" @click="onClickCloseModal">
              <img slot="icon" src="@/assets/img/close_black.svg" alt />
            </div>
          </div>
          <div class="modal-body">
            <div class="sec-search-condition">
              <modal-selectable-applicants
                :is-all-applicants="true"
                :invisible-columns-pc="invisibleColumnsPc"
                :invisible-columns-sp="invisibleColumnsSp"
                :exclude-selection-id="selectionId"
                :selection-count="selectionCount"
                @onChangeCurrentSelect="onChangeCurrentSelect"
              />
            </div>
            <div class="btn-col2">
              <div class="btn btn-unavailable" @click="onClickCloseModal">
                選考の詳細へ戻る
              </div>
              <button-base
                class="btn submit-button"
                :is-loading="isLoading"
                button-text="応募者を登録"
                :rejected-role-ids="[3]"
                :rejected-admin-ids="[2]"
                @click="onClickRegister"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import cloneDeep from 'lodash/cloneDeep';

import { applicantListColumns } from '@/defines/applicant';
import Modal from '@/components/ui/modals/components/Modal';
import ModalSelectableApplicants from '@/components/features/modalSelectableApplicants/components/ModalSelectableApplicants';

export default defineComponent({
  name: 'ModalSelectionsApplicant',
  components: { Modal, ModalSelectableApplicants },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    selectionId: {
      type: Number,
      required: true,
    },
    selectionName: {
      type: String,
      default: '',
    },
    selectionCount: {
      type: Number,
      default: null,
    },
  },
  emits: ['onClickRegisterApplicant', 'onClickCloseModal'],
  setup(props, context) {
    const selectedApplicants = ref([]);
    const isLoading = ref(false);
    const invisibleColumnsPc = computed(() => {
      return applicantListColumns
        .map(col => ({
          id: col.name,
          selected: true,
          activated: col.activated,
          notActivated: col.notActivated,
        }))
        .filter(
          col =>
            col.id !== 'isSelect' &&
            (col.selected === false || col.activated.disabled),
        )
        .map(item => item.id);
    });

    const onChangeCurrentSelect = payload => {
      selectedApplicants.value = payload.selectedApplicantIds;
    };
    const onClickRegister = () => {
      isLoading.value = true;
      context.emit('onClickRegisterApplicant', {
        selectedApplicants: cloneDeep(selectedApplicants.value),
      });
    };
    const onClickCloseModal = () => {
      context.emit('onClickCloseModal');
      selectedApplicants.value = [];
    };
    const onRegisterCallback = () => {
      // 親コンポーネントで呼び出されているので注意
      isLoading.value = false;
    };
    const clearSelectedApplicants = () => {
      // 親コンポーネントで呼び出されているので注意
      selectedApplicants.value = [];
    };

    return {
      invisibleColumnsSp: [
        'event_title',
        'attendanceDate',
        'not_announe_lamp',
        'name_kana',
        'gender',
        'school',
        'faculty',
        'department',
        'department_category',
        'email',
        'tel',
        'channel',
        'invited_status',
        'invited_at',
        'created_at',
        'activated_at',
        'staff',
      ],
      isLoading,
      invisibleColumnsPc,
      onChangeCurrentSelect,
      onClickRegister,
      onClickCloseModal,
      onRegisterCallback,
      clearSelectedApplicants,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  text-align: left;
  @include full_modal;
  .modal-body {
    padding: 90px 0 0;
    width: 90%;
    margin: 0 auto;
    .area_search_block {
      padding: 20px;
      background: #fff;
      .area-search-condition {
        .row-1 {
          @include band;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 25px;
          font-size: 1.2rem;
          & :deep(.float-box) {
            right: 0;
            top: 40px;
            left: unset;
            .context-menu {
              position: static;
            }
          }
          .column-block {
            position: relative;
            + .column-block {
              margin-left: 10px;
            }
          }
          .btn {
            font-size: 1.2rem;
            padding-right: 2.4rem;
            &::after {
              content: '';
              width: 6px;
              height: 6px;
              border: 0px;
              border-bottom: solid 2px #fff;
              border-right: solid 2px #fff;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 10px;
              margin-top: -4px;
            }
          }
        }
        .row-2 {
          background-color: #fff;
          padding: 20px 0;
        }
      }
      & :deep(.area-table) {
        width: 100%;
        margin: 0 0 2.4rem;
        text-align: left;
      }
    }

    .btn-col2 {
      margin: 20px auto 0;
      .submit-button {
        padding: 15px 10px;
      }
    }
    & :deep(.modal-body_btn:not(.separate)) {
      .modal-body_btn_item {
        width: 100%;
      }
    }
  }
  .sec-search-condition {
    font-size: 1.2rem;
    background: #fff;
    padding: 2rem;
  }
}

@media (max-width: ($media_query_sp)) {
  .flexbox {
    flex-wrap: wrap;
  }
  .btn-availability {
    margin-bottom: 5px;
  }
  .area-search-condition {
    padding-top: 80px;
  }
  .btn-col2 {
    width: 100%;
    min-width: initial;
  }
}
</style>
