import Breakpoints from '@/defines/breakpoints';

/**
 * PC画面幅のデフォルト非表示カラムキー配列
 */
const BASE_INVISIBLE_COLUMNS = [
  'isSelect',
  'gender',
  'school',
  'department',
  'department_category',
  'tel',
  'contact_type',
];

/**
 * モバイル画面幅のデフォルト非表示カラムキー配列
 */
const BASE_INVISIBLE_COLUMNS_SP = [
  'isSelect',
  'event_title',
  'attendanceDate',
  'not_announe_lamp',
  'name_kana',
  'gender',
  'school',
  'faculty',
  'department',
  'department_category',
  'email',
  'tel',
  'contact_type',
  'channel',
  'invited_status',
  'invited_at',
  'created_at',
  'activated_at',
  'staff',
];

/**
 * 画面サイズ毎のデフォルト非表示カラムキー配列の取得
 * @return {string[]} デフォルト非表示カラムキー配列
 */
const getBaseInvisibleColumns = () => {
  return window.innerWidth <= Breakpoints.MAX_SP_SIZE
    ? BASE_INVISIBLE_COLUMNS_SP
    : BASE_INVISIBLE_COLUMNS;
};

/**
 * 画面サイズによるモーダル幅の切り替え
 * @param {string} modalWidthClass 対象モーダルのClass
 * @param {number} pcWidthMarginPercentage 対象モーダルのPC画面の横のmargin
 * @param {number} mobileWidthMarginPixel 対象モーダルのモバイル画面の横のmargin
 */
const resizeModalWidth = (
  modalWidthClass = '.modal-window',
  pcWidthMarginPercentage = 0.9,
  mobileWidthMarginPixel = 10,
) => {
  if (!document.querySelector(modalWidthClass)) return;
  document.querySelector(modalWidthClass).style.width = `${
    window.innerWidth > Breakpoints.MIN_PC_SIZE
      ? window.innerWidth * pcWidthMarginPercentage
      : window.innerWidth - mobileWidthMarginPixel * 2
  }px`;
};

/**
 * 画面サイズによるモーダル高さの切り替え
 * @param {string} modalHeight 対象モーダルのClass
 * @param {string} modalHeightInner 対象モーダルのClass
 * @param {number} mobileHeightMarginPixel 対象モーダルのモバイル画面の縦のmargin
 * @param {number} setTimeOutDelay リサイズの実行ディレイ
 */
const resizeModalHeight = (
  modalHeightClass = '.modal-wrapper',
  modalHeightInnerClass = '.modal-window .body',
  mobileHeightMarginPixel = 100,
  setTimeOutDelay = 300,
) => {
  if (
    !document.querySelector(modalHeightClass) ||
    !document.querySelector(modalHeightInnerClass)
  ) {
    return;
  }
  setTimeout(() => {
    document.querySelector(modalHeightClass).style.height =
      window.innerWidth > Breakpoints.MIN_PC_SIZE
        ? null
        : `${window.innerHeight}px`;
    document.querySelector(modalHeightInnerClass).style.maxHeight =
      window.innerWidth > Breakpoints.MIN_PC_SIZE
        ? null
        : `${window.innerHeight - mobileHeightMarginPixel * 2}px`;
  }, setTimeOutDelay);
};

/**
 * 画面サイズによるモーダル表示切り替え処理
 * @param {boolean} isVisible モーダル表示フラグ
 * @param {{
 *   updateInvisibleColumns?: Function
 *   shouldResizeModalWidth?: boolean
 * }} options {
 *   updateInvisibleColumns: 画面幅によって非表示カラムを更新する場合のコールバック関数(ex: v => (invisibleColumns.value = v)))
 *   shouldResizeModalWidth: 画面サイズによるモーダル幅切り替えフラグ
 * }
 */
const onResizeModal = (
  isVisible = false,
  { updateInvisibleColumns = null, shouldResizeModalWidth = true } = {},
) => {
  if (isVisible !== true) return;
  if (updateInvisibleColumns !== null) {
    updateInvisibleColumns(getBaseInvisibleColumns());
  }
  if (shouldResizeModalWidth === true) resizeModalWidth();
  resizeModalHeight();
};

export { BASE_INVISIBLE_COLUMNS, BASE_INVISIBLE_COLUMNS_SP, onResizeModal };
