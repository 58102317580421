<template>
  <div class="check_policy">
    <input
      id="policy"
      v-model="localCheckedPolicy"
      type="checkbox"
      class="setting_input"
    />
    <span>
      <label for="policy">MOCHICAの</label>
      <a @click="$emit('toPolicy')">利用規約</a>
      <span>に同意する</span>
    </span>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckPolicy',
  props: {
    checkedPolicy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeCheckPolicy', 'toPolicy'],
  setup(props, context) {
    const localCheckedPolicy = computed({
      get() {
        return props.checkedPolicy;
      },
      set(newValue) {
        context.emit('changeCheckPolicy', newValue);
      },
    });

    return { localCheckedPolicy };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.check_policy {
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #fafafa;

  > input {
    margin-right: 6px;
  }

  > span {
    margin-top: 1.5px;
  }
}
.setting_input {
  appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  position: relative;
  cursor: pointer;
  &:checked {
    border: 1px solid #20a8e9;
    &::after {
      content: url(../../../../assets/img/check.svg);
      height: 1.1rem;
      width: 1.5rem;
      margin: -5px 0 0 1px;
      position: absolute;
      bottom: 6px;
      left: 0;
    }
  }
}
</style>
