<template>
  <div v-if="$store.getters['page/isPageLoaded']" id="wrapper">
    <div class="btn-wrapper">
      <button-base
        class="btn-layout"
        button-text="基本情報・アンケートのみ"
        :is-outlined="isVisibleAll === true"
        @click="isVisibleAll = !isVisibleAll"
      />
      <button-base
        class="btn-top"
        button-text="印刷する"
        @click="printPreview"
      />
    </div>
    <div id="basic-info">
      <div class="basic-title">基本情報</div>
      <table>
        <tbody>
          <tr>
            <th>MOCHICA ID</th>
            <td colspan="3">{{ applicant.data.id }}</td>
          </tr>
          <tr>
            <th>フリガナ</th>
            <td colspan="3">
              {{ applicant.data.lastnameKana
              }}{{ applicant.data.firstnameKana }}
            </td>
          </tr>
          <tr>
            <th>名前</th>
            <td colspan="3">
              {{ applicant.data.lastname }}{{ applicant.data.firstname }}
            </td>
          </tr>
          <tr>
            <th>住所</th>
            <td colspan="3">{{ applicant.data.address }}</td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td colspan="3">{{ applicant.data.email }}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{{ applicant.data.tel }}</td>
            <th>連絡方法</th>
            <td>{{ applicant.data.contactType }}</td>
          </tr>
          <tr>
            <th>採用担当</th>
            <td>
              {{ applicant.data.staff ? applicant.data.staff.lastname : ''
              }}{{ applicant.data.staff ? applicant.data.staff.firstname : '' }}
            </td>
            <th>登録経路</th>
            <td>{{ applicant.data.channel }}</td>
          </tr>
          <tr>
            <th>登録種別</th>
            <td>{{ getGraduatedName }}</td>
            <th>年齢</th>
            <td>{{ $utils.getAgeFromDateTime(applicant.data.birth) }}</td>
          </tr>
          <tr>
            <th>生年月日</th>
            <td>
              {{ $utils.parseDateTime(applicant.data.birth, 'YYYY/MM/DD') }}
            </td>
            <th>性別</th>
            <td>
              {{
                applicant.data.gender ? applicant.data.gender.description : ''
              }}
            </td>
          </tr>
          <tr>
            <th>学校名</th>
            <td>{{ applicant.data.school }}</td>
            <th>学部名</th>
            <td>{{ applicant.data.faculty }}</td>
          </tr>
          <tr>
            <th>学科名</th>
            <td>{{ applicant.data.department }}</td>
            <th>文理</th>
            <td>
              {{
                applicant.data.departmentCategory
                  ? applicant.data.departmentCategory.description
                  : ''
              }}
            </td>
          </tr>
          <tr>
            <th>メモ</th>
            <td colspan="3">{{ applicant.data.memo }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="selection-flow">
      <template v-if="isVisibleAll === true">
        <div class="basic-title is-timeline">選考タイムライン</div>
        <selection-flow-preview
          v-for="prg in progress.data.committed"
          :key="prg.id"
          :is-committed="true"
          :progress="prg"
          :is-current-progress="isCurrentProgress(prg.id)"
          :is-line-friend="isLineFriend"
        />
        <selection-flow-preview
          v-for="prg in progress.data.notCommitted"
          :key="prg.id"
          :is-committed="false"
          :progress="prg"
          :is-current-progress="isCurrentProgress(prg.id)"
          :is-line-friend="isLineFriend"
        />
      </template>
      <applicant-flag-surveys
        v-if="flagsurveyNotice.data.title"
        :flagsurvey-notices="flagsurveyNotices"
        :flagsurvey-notice="flagsurveyNotice.data"
        :is-loading="isLoadingFlagSurveyNotice"
        :is-preview="true"
      />
    </div>
    <flag-group-preview v-if="isVisibleAll === true" />
  </div>
</template>

<script>
import qs from 'qs';
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import applicantsService from '@/services/applicants';
import progressesService from '@/services/progresses';
import GraduatedDefines from '@/defines/graduated';
import SelectionFlowPreview from '@/components/page/applicant_print/components/SelectionFlowPreview';
import FlagGroupPreview from '@/components/page/applicant_print/components/FlagGroupPreview';
import ApplicantFlagSurveys from '@/components/page/applicants/_id/components/ApplicantFlagSurveys';

export default defineComponent({
  name: 'ApplicantPrint',
  components: {
    SelectionFlowPreview,
    FlagGroupPreview,
    ApplicantFlagSurveys,
  },
  setup(props, context) {
    const store = useStore();
    const applicant = reactive({ data: {} });
    const progress = reactive({ data: {} });
    const flagsurveyNotices = ref([]);
    const flagsurveyNotice = reactive({ data: {} });
    const isLoadingFlagSurveyNotice = ref(false);
    const isVisibleAll = ref(true);

    // computed
    const getGraduatedName = computed(() => {
      const target = GraduatedDefines.find(
        g => g.year === applicant.data.graduated,
      );
      if (target !== undefined) return target.name_jp;
      return `${applicant.data.graduated}年卒業`;
    });
    const isLineFriend = computed(
      () =>
        applicant.data.contact_type_id === 2 && applicant.data.email === null,
    );

    // methods
    const fetchAll = async (applicantId, flagSurveyNoticeId) => {
      const applicantData = await store.dispatch(
        'applicant/FETCH_APPLICANT',
        applicantId,
      );
      applicant.data = applicantData;
      await progressesService.fetchProgress(applicant.data.id).then(p => {
        progress.data = p;
      });
      if (flagSurveyNoticeId) {
        await fetchFlagSurveyNotice(flagSurveyNoticeId, applicantId);
      }
    };
    const fetchFlagSurveyNotice = async (flagSurveyNoticeId, applicantId) => {
      isLoadingFlagSurveyNotice.value = true;
      const resFlagSurvey =
        await applicantsService.fetchApplicantFlagsurveyNotice(
          applicantId,
          flagSurveyNoticeId,
        );
      if (resFlagSurvey.success !== true) {
        flagsurveyNotice.data = {};
        return;
      }
      flagsurveyNotice.data = resFlagSurvey.flagsurvey_notice;
      isLoadingFlagSurveyNotice.value = false;
    };
    const isCurrentProgress = progressId => {
      return progress.data.currentProgressId === progressId;
    };
    const printPreview = () => {
      window.print();
    };

    // lifecycle
    onBeforeMount(async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      await fetchAll(query.applicant_id, query.flag_survey_notice_id);
      await store.dispatch('page/SET_LOADED');
    });

    return {
      applicant,
      progress,
      getGraduatedName,
      isLineFriend,
      flagsurveyNotices,
      flagsurveyNotice,
      isLoadingFlagSurveyNotice,
      isVisibleAll,
      isCurrentProgress,
      printPreview,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

#wrapper {
  background-color: #fff;
  padding: 15px 20px;
  width: 100%;
}
.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .btn-layout {
    padding: 14px;
    margin: 0 0 10px 0;
    &.btn-primary {
      border: 1px solid $mochica_color !important;
    }
  }
  .btn-top {
    background-color: $mochica_color;
    color: #fff;
    padding: 14px;
    margin: 0 0 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease-out;
    font-weight: bold;
  }
}

.basic-title {
  text-align: center;
  background-color: $mochica_color;
  color: #fff;
  padding: 14px;
  font-size: 1.3rem;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  font-weight: bold;
  &.is-timeline {
    margin-bottom: 20px;
  }
}

.selection-title {
  text-align: center;
  background-color: $mochica_color;
  color: #fff;
  padding: 14px;
  font-size: 1.3rem;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  font-weight: bold;
}
#selection-timeline {
  text-align: center;
}
.left-justified {
  text-align: left;
}

th {
  background-color: lightgray;
  width: 20%;
}

th,
td {
  border: solid 1px;
  padding: 10px;
  line-height: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.selection-flow {
  margin-bottom: 30px;
}
@media print {
  .btn-wrapper {
    display: none;
  }
}
</style>
