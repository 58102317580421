export const MESSAGE_CATEGORIES = {
  manual: 1,
  system: 2,
  log: 3,
} as const;

export const MESSAGE_TYPES = {
  mail: 1,
  line: 2,
} as const;
