<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <div class="form__wrapper">
      <div class="form__inner">
        <div class="form__inner__contents">
          <div class="smartphone">
            <div class="main-wrapper">
              <div class="mochica_desc">
                <div v-if="isSubmitted" class="form-confirmed">
                  <div class="bg-bold">パスワードを確認しました。</div>
                  <div class="bg-bold">出席確認が完了いたしました。</div>
                  <notification />
                </div>
                <div v-else-if="isVisibleInvalidForm" class="form-confirmed">
                  <div class="bg-bold">{{ message }}</div>
                </div>
                <div v-else>
                  <div class="form-header">
                    <div class="bg-bold">{{ reminder.data.company_name }}</div>
                    <div class="bg-bold">出席確認</div>
                  </div>
                  <div class="form-body">
                    <div class="sm-bold">開催日時：</div>
                    <div>
                      {{
                        $utils.parseDateTime(
                          reminder.data.date,
                          'YYYY年M月D日(ddd)',
                        )
                      }}
                      {{ reminder.data.start.slice(0, -3) }} ~
                      {{ reminder.data.last.slice(0, -3) }}
                    </div>
                    <div class="sm-bold">イベント名：</div>
                    <div>{{ reminder.data.selection_name }}</div>
                    <div class="sm-bold">会場名：</div>
                    <div>{{ reminder.data.venue_name }}</div>
                    <div class="sm-bold">会議室名：</div>
                    <div>{{ reminder.data.room_name }}</div>
                  </div>
                  <div class="form-submission">
                    <div class="submission-header">
                      お知らせされるパスワードを入力してください。
                    </div>
                    <input
                      v-model="password"
                      class="reminder-password"
                      type="text"
                      placeholder="出席確認パスワード"
                    />
                    <button class="form-btn" @click.prevent="onSubmit">
                      送信する
                    </button>
                  </div>
                  <notification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import Notification from '@/components/ui/notification/components/Notification';
import qs from 'qs';

import remindersService from '@/services/reminders';

export default defineComponent({
  name: 'RemindersExt',
  components: { Notification },
  setup(props, context) {
    const store = useStore();
    const reminder = reactive({ data: {} });
    const isSubmitted = ref(false);
    const isVisibleInvalidForm = ref(false);
    const message = ref('');
    const password = ref('');

    // methods
    const onSubmit = async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      const res = await remindersService.reminderCheck(
        query.token,
        password.value,
      );
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        return;
      }
      isSubmitted.value = true;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };

    // lifecycle
    onBeforeMount(async () => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      const res = await remindersService.reminderGet(query.token);
      if (res.success !== true) {
        isVisibleInvalidForm.value = true;
        message.value = res.message;
      }
      reminder.data = res.reminders;
      await store.dispatch('page/SET_LOADED_FOR_EXT');
    });

    return {
      reminder,
      isSubmitted,
      isVisibleInvalidForm,
      message,
      password,
      onSubmit,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include form_wrapper_css();

.form__inner__contents {
  justify-content: center;

  .smartphone {
    max-width: 500px;
  }
}

.mochica_desc {
  justify-content: center;
  /* text-align: center; */
  padding: 3rem 0 3rem;
  .reminder-password {
    width: 100%;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
  }
  .submission-header {
    margin: 3rem 0;
  }
  .form-btn {
    width: 100%;
    color: #fff;
    padding: 1rem;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1.4rem;
    background: #1ecd01;
    cursor: pointer;
  }

  .form-confirmed,
  .form-header {
    text-align: center;
  }
  .form-body {
    padding-bottom: 2rem;
    /* border-bottom: 1px solid #999999; */
    border-bottom: 1px solid $border_color;
  }
  .bg-bold {
    white-space: pre-line;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 0.5rem 0;
  }
  .sm-bold {
    padding: 0.5rem 0;
    font-size: 1.4rem;
    font-weight: bold;
  }
}
</style>
