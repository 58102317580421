<template>
  <transition name="fade">
    <div>
      <template v-if="mode === 'home'">
        <link-google-calendar
          ref="refLinkGoogle"
          :is-connected-google-calendar="isConnectedGoogleCalendar"
          :is-connected-google-workspace-customer-id="
            isConnectedGoogleWorkspaceCustomerId
          "
          @changeMode="changeMode"
          @changeIsConnectedGoogleCalendar="changeIsConnectedGoogleCalendar"
          @changeIsConnectedGoogleWorkspaceCustomerId="
            changeIsConnectedGoogleWorkspaceCustomerId
          "
        />
        <link-zoom
          ref="refLinkZoom"
          :is-connected-zoom="isConnectedZoom"
          :has-zoom-access-token="hasZoomAccessToken"
          @changeMode="changeMode"
          @changeIsConnectedZoom="changeIsConnectedZoom"
          @changeHasZoomAccessToken="changeHasZoomAccessToken"
        />
      </template>
      <link-google-calendar-link-service-account-key
        v-if="mode === 'linkGoogleCalendar'"
        :is-connected-google-calendar="isConnectedGoogleCalendar"
        @changeMode="changeMode"
        @changeIsConnectedGoogleCalendar="changeIsConnectedGoogleCalendar"
      />
      <link-google-calendar-link-workspace-customer-id
        v-else-if="mode === 'linkWorkspaceCustomerId'"
        @changeMode="changeMode"
        @changeIsConnectedGoogleWorkspaceCustomerId="
          changeIsConnectedGoogleWorkspaceCustomerId
        "
      />
      <link-google-calendar-edit-resource
        v-else-if="mode === 'editResource'"
        @changeMode="changeMode"
      />
      <link-zoom-link-client
        v-else-if="mode === 'editZoomClient'"
        :is-connected-zoom="isConnectedZoom"
        @changeMode="changeMode"
      />
    </div>
  </transition>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import LinkGoogleCalendar from '@/components/page/configs/linkSystem/components/LinkGoogleCalendar';
import LinkGoogleCalendarLinkServiceAccountKey from '@/components/page/configs/linkSystem/components/LinkGoogleCalendarLinkServiceAccountKey';
import LinkGoogleCalendarLinkWorkspaceCustomerId from '@/components/page/configs/linkSystem/components/LinkGoogleCalendarLinkWorkspaceCustomerId';
import LinkGoogleCalendarEditResource from '@/components/page/configs/linkSystem/components/LinkGoogleCalendarEditResource';
import LinkZoom from '@/components/page/configs/linkSystem/components/LinkZoom';
import LinkZoomLinkClient from '@/components/page/configs/linkSystem/components/LinkZoomLinkClient';

export default defineComponent({
  name: 'LinkSystem',
  components: {
    LinkGoogleCalendar,
    LinkGoogleCalendarLinkServiceAccountKey,
    LinkGoogleCalendarLinkWorkspaceCustomerId,
    LinkGoogleCalendarEditResource,
    LinkZoom,
    LinkZoomLinkClient,
  },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const refLinkGoogle = ref(null);
    const refLinkZoom = ref(null);
    const mode = ref('home'); // 'home' | 'linkGoogleCalendar' | 'linkWorkspaceCustomerId'| 'editResource' | 'editZoomClient'
    const isConnectedGoogleCalendar = ref(false);
    const isConnectedGoogleWorkspaceCustomerId = ref(false);
    const isConnectedZoom = ref(false);
    const hasZoomAccessToken = ref(false);

    // methods
    const changeMode = key => {
      mode.value = key;
    };
    const changeIsConnectedGoogleCalendar = val => {
      isConnectedGoogleCalendar.value = val;
    };
    const changeIsConnectedGoogleWorkspaceCustomerId = val => {
      isConnectedGoogleWorkspaceCustomerId.value = val;
    };
    const changeIsConnectedZoom = key => {
      isConnectedZoom.value = key;
    };
    const changeHasZoomAccessToken = key => {
      hasZoomAccessToken.value = key;
    };

    // lifecycle
    onBeforeMount(async () => {
      await store.dispatch('page/SET_LOADED');
    });
    onMounted(async () => {
      await refLinkGoogle.value.checkLinkGoogleCalendar();
      // クエリにcodeが存在する場合、Zoom連携後のリダイレクトなので、Zoomコード登録APIを叩く
      if (route.query.code) {
        await refLinkZoom.value.createZoomAccessToken(route.query.code);
      }
      await refLinkZoom.value.checkLinkZoom();
    });

    return {
      refLinkGoogle,
      refLinkZoom,
      mode,
      isConnectedGoogleCalendar,
      isConnectedGoogleWorkspaceCustomerId,
      isConnectedZoom,
      hasZoomAccessToken,
      changeMode,
      changeIsConnectedGoogleCalendar,
      changeIsConnectedGoogleWorkspaceCustomerId,
      changeIsConnectedZoom,
      changeHasZoomAccessToken,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
