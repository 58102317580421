<template>
  <div class="zoom_link">
    <p class="setting_title">Zoom連携</p>
    <p class="setting_text">
      Zoomと連携することで、選考会場の開催時間ごとにWeb面接を発行できます。
    </p>
    <dl
      v-if="hasZoomAccessToken !== true && isConnectedZoom === true"
      class="setting_list has-error"
    >
      <dt>Zoom連携の解除</dt>
      <dd>
        <p>正常に処理できませんでした。連携解除後にもう一度お試しください。</p>
        <button-base
          class="btn btn-unavailable"
          button-text="連携解除"
          button-type="secondary"
          :is-loading="isLoading"
          :rejected-role-ids="[2, 3]"
          :rejected-admin-ids="[2]"
          @click="openResetLinkZoom"
        />
      </dd>
    </dl>
    <template v-else-if="isConnectedZoom !== true">
      <!-- 未連携かつシステム連携トップ画面 -->
      <button
        class="btn btn-availability"
        @click="$emit('changeMode', 'editZoomClient')"
      >
        連携に進む
      </button>
    </template>
    <dl v-else class="setting_list">
      <dt>Zoom連携の解除</dt>
      <dd>
        <button-base
          class="btn btn-unavailable"
          button-text="連携解除"
          button-type="secondary"
          :is-loading="isLoading"
          :rejected-role-ids="[2, 3]"
          :rejected-admin-ids="[2]"
          @click="openResetLinkZoom"
        />
      </dd>
    </dl>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ZoomService from '@/services/zoom';

export default defineComponent({
  name: 'LinkZoom',
  props: {
    isConnectedZoom: {
      type: Boolean,
      default: false,
    },
    hasZoomAccessToken: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeMode', 'changeIsConnectedZoom', 'changeHasZoomAccessToken'],
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);

    // methods
    const checkLinkZoom = async () => {
      isLoading.value = true;
      const res = await ZoomService.checkLinkZoom();
      if (res.success !== true) {
        // role=1以外はリダイレクト
        if (res.code === 1001) router.push({ name: 'Configs' });
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
      context.emit('changeIsConnectedZoom', res.has_google_secret_zoom_client);
      context.emit('changeHasZoomAccessToken', res.has_zoom_access_token);
      isLoading.value = false;
    };
    const createZoomAccessToken = async () => {
      const resCreate = await ZoomService.createZoomAccessToken({
        code: route.query.code,
      });
      if (resCreate.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: resCreate.message,
          type: false,
        });
        context.emit('changeIsConnectedZoom', false);
        context.emit('changeHasZoomAccessToken', false);
      } else {
        context.emit('changeIsConnectedZoom', true);
        context.emit('changeHasZoomAccessToken', true);
      }
      router.replace({ name: 'LinkSystem', query: {} });
    };
    const resetLinkZoom = async () => {
      isLoading.value = true;
      const res = await ZoomService.resetLinkZoom();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        type: res.success,
        message: res.message,
      });
      isLoading.value = false;
      if (res.success === true) {
        context.emit('changeIsConnectedZoom', false);
        context.emit('changeHasZoomAccessToken', false);
      }
    };
    const openResetLinkZoom = async () => {
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        alertFlag: true,
        accentColor: '#C31E1E',
        title: 'Zoom連携の解除',
        message: '本当にZoom連携を解除しますか？',
        buttonText1: 'キャンセル',
        buttonText2: '解除する',
        onSelected: async payload => {
          if (payload.choice === 3 && isLoading.value === false) {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            await resetLinkZoom();
          } else {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          }
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };

    return {
      isLoading,
      resetLinkZoom,
      openResetLinkZoom,
      checkLinkZoom,
      createZoomAccessToken,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.setting_title {
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #5896cc;
  border-left: 6px solid #5896cc;
}

.setting_text {
  margin-bottom: 24px;
  line-height: 1.75;
  > span {
    display: block;
    margin-top: 5px;
  }
}

.setting_caption {
  color: #777;
  font-size: 12px;
}

.setting_list {
  border-top: 1px solid #e5e5e5;
  padding-top: 24px;
  margin-bottom: 24px;
  > dt {
    margin-bottom: 18px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: bold;
    > span {
      display: inline-block;
      width: 44px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      background-color: #bb1b1c;
      border-radius: 4px;
      text-align: center;
    }
  }
  > dd {
    margin-bottom: 18px;

    > p {
      margin-bottom: 10px;
    }
  }
  &.has-error {
    > dd {
      > p {
        margin-bottom: 20px;
        color: #e4003d;
      }
    }
  }
}

.zoom_input {
  width: 100%;
  max-width: 380px;
}

.btn-availability {
  width: 189px;
  height: 40px;
  &.btn-long {
    margin-top: 15px;
    width: 260px;
  }
  &:disabled {
    color: #333 !important;
    background-color: #999;
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.btn-unavailable {
  width: 189px;
  height: 40px;
}

@media (max-width: ($media_query_sp)) {
  .resource_box {
    width: 100%;
  }
}
</style>
