<script setup>
import { useStore } from 'vuex';
import BrowserUtil from '@/utils/browser';

defineProps({
  tag: {
    type: Object,
    default: () => ({
      text: '',
      description: '',
    }),
  },
});

const store = useStore();

const copyText = text => {
  const res = BrowserUtil.copyToClipboard(text);
  if (res) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: ` ${text} をコピーしました`,
      type: true,
    });
  }
};
</script>

<template>
  <button type="button" class="variable-tag" @click="copyText(tag.text)">
    <span class="variable-name">{{ tag.text }}</span>
    <span class="variable-content">（{{ tag.description }}）</span>
  </button>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.variable-tag {
  display: flex;
  width: fit-content;
  padding: 10px 14px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: $font_14;
  cursor: pointer;
  &::after {
    content: '';
    width: 14px;
    height: 14px;
    background-image: url(@/assets/img/icon_copy.svg);
    background-size: contain;
  }
  &:hover {
    background: #f0f3f3;
  }
  &:active {
    background: #e4e9ed;
  }
}
.variable-name {
  color: $mochica_color;
}
.variable-content {
  font-weight: normal;
}
</style>
