import { z } from 'zod';
import { excludeFileExtensions } from '@/components/ui/attachmentFile/defines/exclude-file-extensions';

const nonEmptyStringValidation = {
  validate: value => value.trim().length > 0,
  message: '※1文字以上入力してください',
};

const emailSchema = z.object({
  subject: z
    .string({ required_error: '※1文字以上入力してください' })
    .min(1, { message: '※1文字以上入力してください' })
    .max(50, { message: '※50文字以内で入力してください' })
    .refine(nonEmptyStringValidation.validate, {
      message: nonEmptyStringValidation.message,
    }),
  content: z
    .string({ required_error: '※1文字以上入力してください' })
    .min(1, { message: '※1文字以上入力してください' })
    .max(2000, '※2000文字以内で入力してください')
    .refine(nonEmptyStringValidation.validate, {
      message: nonEmptyStringValidation.message,
    }),
});
const lineSchema = z.object({
  content: z
    .string({ required_error: '※1文字以上入力してください' })
    .min(1, { message: '※1文字以上入力してください' })
    .max(1000, '※1000文字以内で入力してください')
    .refine(nonEmptyStringValidation.validate, {
      message: nonEmptyStringValidation.message,
    }),
});

export const BaseSchema = z.object({
  actionName: z
    .string({ required_error: '※1文字以上入力してください' })
    .min(1, { message: '※1文字以上入力してください' })
    .max(50, { message: '※50文字以内で入力してください' })
    .refine(nonEmptyStringValidation.validate, {
      message: nonEmptyStringValidation.message,
    }),
  selection: z.object({
    id: z
      .number({ required_error: '※選考を選択してください' })
      .min(1, { message: '※選考を選択してください' }),
    title: z.string().optional(),
    description: z.string().optional(),
  }),
  actionType: z
    .number({ required_error: '※自動アクションを選択してください' })
    .min(1, { message: '※自動アクションを選択してください' }),
  applicantStatus: z.string(),
  flagGroups: z.array(z.any()).optional(),
  triggerDelayDay: z.number(),
  triggerHour: z.string(),
  triggerMin: z.string(),
});

export const AssignProgressSchema = z.object({
  assignProgress: z.object({
    id: z
      .number({ required_error: '※選考を選択してください' })
      .min(1, { message: '※選考を選択してください' }),
    title: z.string().optional(),
    description: z.string().optional(),
  }),
});

export const ResultsSchema = z.object({
  results: z.object({
    passed: z.object({
      email: emailSchema,
      line: lineSchema,
    }),
    offered: z.object({
      email: emailSchema,
      line: lineSchema,
    }),
    dropped: z.object({
      email: emailSchema,
      line: lineSchema,
    }),
  }),
});

export const RichMessageSchema = z.object({
  richMessage: z.object({
    id: z
      .number({ required_error: '※リッチメッセージを選択してください' })
      .min(1, { message: '※リッチメッセージを選択してください' }),
    name: z.string().optional(),
    img: z.string().optional(),
  }),
});

export const FlagSurveySchema = z.object({
  flagSurvey: z.object({
    id: z
      .number({ required_error: '※アンケートを選択してください' })
      .min(1, { message: '※アンケートを選択してください' }),
    title: z.string().optional(),
  }),
});

const NoticeFileSchema = z
  .object({
    file: z.instanceof(File),
    fileName: z.string(),
    fileSize: z.number(),
    blob: z.instanceof(Blob),
  })
  .refine(
    data => {
      const extension = data.fileName.split('.').pop();
      return !excludeFileExtensions.includes(extension);
    },
    {
      message: '添付できないファイル形式が選択されています',
      path: ['notice', 'files'],
    },
  );

export const NoticeSchema = z.object({
  notice: z
    .object({
      email: emailSchema,
      line: z.object({
        content: z.string().max(1000, '※1000文字以内で入力してください'),
      }),
      isSendForceEmail: z.boolean().optional(),
      isConfirmMessage: z.boolean().optional(),
      files: z.array(NoticeFileSchema).optional(),
    })
    .refine(
      data => {
        if (data.isSendForceEmail) return true;
        return data.line && data.line.content.trim().length > 0;
      },
      { path: ['line', 'content'], message: '※1文字以上入力してください' },
    ),
});

export const EditAutoActionSchema = z.object({
  actionName: z
    .string({ required_error: '※1文字以上入力してください' })
    .min(1, { message: '※1文字以上入力してください' })
    .max(50, { message: '※50文字以内で入力してください' })
    .refine(nonEmptyStringValidation.validate, {
      message: nonEmptyStringValidation.message,
    }),
  actionStatus: z.number(),
});

export const EditNoticeSchema = z.object({
  notice: z
    .object({
      email: emailSchema,
      line: z.object({
        content: z.string().max(1000, '※1000文字以内で入力してください'),
      }),
      isSendForceEmail: z.boolean().optional(),
    })
    .refine(
      data => {
        if (data.isSendForceEmail) return true;
        return data.line && data.line.content.trim().length > 0;
      },
      { path: ['line', 'content'], message: '※1文字以上入力してください' },
    ),
});
