<template>
  <div>
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">応募者一覧</span>
      </template>
    </bread-crumb-list>

    <div class="content-warp">
      <div class="applicants_sub_menu">
        <div class="applicants_sub_menu-inner">
          <div
            class="btn btn-availability -tabNone"
            @click="$router.push({ name: 'ApplicantsRegistration' })"
          >
            <img class="icon" src="@/assets/img/add_white.svg" alt="" />
            CSVから応募者を登録
          </div>
          <div
            class="btn btn-availability"
            @click="$router.push({ name: 'ApplicantsEmailRegistration' })"
          >
            <img class="icon" src="@/assets/img/add_white.svg" alt="" />
            メールアドレスで応募者を登録
          </div>
          <div
            class="btn btn-availability -tabNone"
            @click="$router.push({ name: 'ApplicantsCsvDownload' })"
          >
            <img class="icon" src="@/assets/img/download_white.svg" alt="" />
            応募者一覧のCSVダウンロードを予約
          </div>
          <div
            class="btn btn-availability double-check -tabNone"
            @click="$router.push({ name: 'ApplicantsDoubleCheck' })"
          >
            <img
              slot="icon"
              class="search_icon"
              src="@/assets/img/checkbox.svg"
              alt=""
            />
            応募者の重複をチェック
          </div>
        </div>
      </div>

      <div class="local-nav">
        <div
          class="item"
          :class="{ active: isActivated }"
          @click="switchActivatedLocal(true)"
        >
          応募者
          <span v-if="unreadApplicantCountInGraduatedYear > 0" class="badge">
            {{
              unreadApplicantCountInGraduatedYear >= 100
                ? '99+'
                : unreadApplicantCountInGraduatedYear
            }}
          </span>
        </div>
        <div
          class="item"
          :class="{ active: !isActivated }"
          @click="switchActivatedLocal(false)"
        >
          LINE友達
          <span v-if="unreadLineCountInGraduatedYear > 0" class="badge">
            {{
              unreadLineCountInGraduatedYear >= 100
                ? '99+'
                : unreadLineCountInGraduatedYear
            }}
          </span>
        </div>
      </div>
      <section class="content">
        <local-page-loader
          v-if="
            !isShowSelectableApplicantTable ||
            !isShowSelectableApplicantTableSort
          "
        />
        <div
          v-if="isShowSelectableApplicantTable"
          v-show="isShowSelectableApplicantTableSort"
        >
          <div class="function-area">
            <p class="headline-typeD">
              条件検索
              <span class="headline-caption">
                （プリセットを選択すると「絞り込み検索」の検索条件が上書きされます）
              </span>
            </p>
            <div class="btnArea-refine">
              <button class="button_search" @click="openFloatApplicantsForm">
                <img
                  slot="icon"
                  class="search_icon"
                  src="@/assets/img/search_white.svg"
                  alt
                />
                <span>絞り込み検索</span>
              </button>
              <div class="button_preset_select">
                <div
                  v-if="viewSearchPresets.length === 0"
                  class="button-regist is-disabled"
                >
                  プリセットがありません
                  <img
                    slot="arrow"
                    class="arrow"
                    src="@/assets/img/arrow_down_blue.svg"
                    alt
                  />
                </div>

                <div
                  v-else
                  class="button-regist"
                  @click="isOpenSearchPresetSelection = true"
                >
                  <span slot="text">
                    {{
                      viewSearchPresets.find(v => v.selected)
                        ? viewSearchPresets.find(v => v.selected).name
                        : 'プリセットを選択'
                    }}
                  </span>
                  <img
                    slot="arrow"
                    class="arrow"
                    src="@/assets/img/arrow_down_blue.svg"
                    alt
                  />
                </div>
                <float-context-menu
                  :is-view="isOpenSearchPresetSelection"
                  :list="viewSearchPresets"
                  @onClose="isOpenSearchPresetSelection = false"
                  @onSelected="onSelectedPreset"
                />
              </div>
            </div>
            <p class="headline-typeD">表示オプション</p>
            <div class="btnArea-option">
              <div class="btn-menu">
                <div class="button-regist" @click="updateIsOpenPagerLoad(true)">
                  <span slot="text">
                    表示する応募者の件数：{{ pageLoad }}件
                  </span>
                  <img
                    slot="arrow"
                    class="arrow"
                    src="@/assets/img/arrow_down_blue.svg"
                    alt
                  />
                </div>
                <float-context-menu
                  :is-view="isOpenPagerLoad"
                  :list="loadSelection"
                  @onClose="updateIsOpenPagerLoad(false)"
                  @onSelected="onSelectedPageLoadLocal"
                />
              </div>
              <div class="btn-menu -spNone">
                <div
                  class="button-regist"
                  @click="isOpenColumnSelection = true"
                >
                  <span slot="text">表示項目</span>
                  <img
                    slot="arrow"
                    class="arrow"
                    src="@/assets/img/arrow_down_blue.svg"
                    alt
                  />
                </div>
                <float-context-menu
                  :is-view="isOpenColumnSelection"
                  :list="viewApplicantListColumns"
                  :is-multiple-select="true"
                  @onClose="isOpenColumnSelection = false"
                  @onSelected="onSelectedColumnSelection"
                />
              </div>
              <div class="btn-menu -spNone">
                <div class="button-regist" @click="onClickedDispFlagGroupsBtn">
                  <span slot="text">表示フラググループ</span>
                  <img
                    slot="arrow"
                    class="arrow"
                    src="@/assets/img/arrow_down_blue.svg"
                    alt
                  />
                </div>
                <float-context-menu
                  :is-view="isOpenColumnFlagSelection"
                  :list="tagGroups"
                  :is-multiple-select="true"
                  @onClose="isOpenColumnFlagSelection = false"
                  @onSelected="onSelectedColumnFlagSelection"
                />
              </div>
              <div v-if="isActivated" class="btn-menu -spNone">
                <div class="button-regist" @click="isOpenSurveyMenu = true">
                  <span slot="text">{{ surveySetting }}</span>
                  <img
                    slot="arrow"
                    class="arrow"
                    src="@/assets/img/arrow_down_blue.svg"
                    alt
                  />
                </div>
                <float-context-menu
                  :is-view="isOpenSurveyMenu"
                  :list="surveyList"
                  @onClose="isOpenSurveyMenu = false"
                  @onSelected="onSelectedSurvey"
                />
              </div>
            </div>
          </div>
          <search-filter-indicator
            :search-filter-detail="searchFilterObject.data"
            :events="events"
            :tag-groups="tagGroups"
            :survey-titles="surveyTitles"
            :staffs="staffs"
            @onReset="onSearchResetLocal"
          />
          <div class="button_selected_applicant_options">
            <button-base
              class="btn-bulk-edit"
              button-text="選択した応募者の一括編集"
              icon-file-name="checkbox"
              @click="isOpenApplicantsEditForm = !isOpenApplicantsEditForm"
            />
            <bulk-edit-applicants
              :staffs="staffs"
              :applicants-edit-form="isOpenApplicantsEditForm"
              :selected-applicants="selectedApplicants"
              :is-activated="isActivated"
              :is-free-plan="isFreePlan"
              :page="page"
              :get-selected-applicants="getSelectedApplicants"
              :get-selected-applicants-ids="getSelectedApplicantsIds"
              :reset-state="resetState"
              :validate-selected-applicants="validateSelectedApplicants"
              :fetch-and-move-page1="fetchAndMovePage1"
              :move-page="movePage"
              :switch-activated-local="switchActivatedLocal"
              @updateSelectedApplicants="updateSelectedApplicants"
              @close="isOpenApplicantsEditForm = false"
            />
          </div>
          <div
            class="btn btn-availability unreadMessageApplicantView"
            :class="{ active: isSortedApplicantsByUnreadCountActive }"
            @click="sortApplicantsByUnreadCountBtn()"
          >
            未読数で並べ替える
          </div>
          <div class="tableList-head">
            <div class="applicant-count">
              対象件数：
              <span class="num">{{ count }}</span>
              名
            </div>
            <pager
              :page="localPage"
              :count="count"
              :page-count="pageCount"
              :load="pageLoad"
              @move="
                nextPage =>
                  movePager(
                    nextPage,
                    nextPage.next !== parseInt($route.params.page, 10)
                      ? true
                      : false,
                  )
              "
            />
          </div>
          <div class="area-search-list">
            <selectable-applicant-table
              ref="refSelectableApplicantTable"
              :is-activated-list="isActivated"
              :is-selectable="true"
              :applicants="applicants"
              :applicants-tags="applicantsTags"
              :can-move-detail="true"
              :invisible-columns="invisibleColumnsStaffSetting"
              :header-additional="headerAdditional"
              :flag-mode="isFlagMode"
              :is-fixed-columns="true"
              :highlight-applicant-ids="highlightApplicantIds"
              @onChangeCurrentSelect="updateSelectedApplicants"
              @onChangeSort="onChangeSort"
            />
          </div>
          <div class="tableList-footer">
            <pager
              :page="localPage"
              :count="count"
              :page-count="pageCount"
              :load="pageLoad"
              @move="
                nextPage =>
                  movePager(
                    nextPage,
                    nextPage.next !== parseInt($route.params.page, 10)
                      ? true
                      : false,
                  )
              "
            />
          </div>
        </div>

        <template v-if="isOpenSearchApplicantFilters">
          <search-applicants-wrapper
            ref="refSearchApplicantsWrapper"
            :search-form-is-visible="searchFormIsVisible"
            :search-form-detail-is-visible="searchFormDetailIsVisible"
            :is-flag-mode="isFlagMode"
            :staffs="staffs"
            :events="events"
            :tag-groups="tagGroups"
            :survey-sheet-id="surveySheetId"
            :survey-titles="surveyTitles"
            :is-activated="isActivated"
            :search="search"
            :change-flag-mode="changeFlagMode"
            @searchFormDetailSwitch="searchFormDetailSwitch"
            @updateSearchFormIsVisible="updateSearchFormIsVisible"
            @updateSearchFormDetailIsVisible="updateSearchFormDetailIsVisible"
            @updateSurveySheetId="updateSurveySheetId"
            @updateSurveyTitles="updateSurveyTitles"
            @updateIsShowSelectableApplicantTable="
              updateIsShowSelectableApplicantTable
            "
            @onSearch="onSearchLocal"
            @onSearchReset="onSearchResetLocal"
            @fetchSearchFilterPresets="fetchSearchFilterPresets"
          />
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  reactive,
  watch,
  ref,
  nextTick,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

import Breakpoints from '@/defines/breakpoints';
import staffsService from '@/services/staffs';
import SearchFilterPresetsService from '@/services/search_filter_presets';
import useSearchApplicant from '@/composables/useSearchApplicant';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import Pager from '@/components/ui/pager/components/Pager';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';
import SearchApplicantsWrapper from '@/components/features/searchApplicants/components/SearchApplicantsWrapper';
import SearchFilterIndicator from '@/components/features/searchApplicants/components/SearchFilterIndicator.vue';
import BulkEditApplicants from '@/components/page/applicants/index/components/BulkEditApplicants';

export default defineComponent({
  name: 'Applicants',
  components: {
    BreadCrumbList,
    Pager,
    LocalPageLoader,
    FloatContextMenu,
    BulkEditApplicants,
    SelectableApplicantTable,
    SearchApplicantsWrapper,
    SearchFilterIndicator,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const refSearchApplicantsWrapper = ref(null);
    const refSelectableApplicantTable = ref(null);
    const isSortedApplicantsByUnreadCount = ref(false);
    const isSortedApplicantsByNewMessage = ref(false);
    const isOpenSearchPresetSelection = ref(false);
    const isOpenColumnSelection = ref(false);
    const isOpenColumnFlagSelection = ref(false);
    const isOpenSurveyMenu = ref(false);
    const isOpenApplicantsEditForm = ref(false);
    const viewSearchPresets = ref([]);
    const staffSetting = reactive({});
    const setStaffSetting = tmpApplicantListColumns => {
      const DEFAULT_ACTIVATED_DISPLAY = '0100001000000000000000000';
      // setApplicantColumnsでスタッフ設定を読み込ませるためのメソッド
      const staffSetting = store.getters['staff/staff'].setting;
      let staffInfoActivate = staffSetting.activatedDisplay.split('');
      let staffInfoProvisional = staffSetting.provisionalDisplay.split('');
      if (window.innerWidth <= Breakpoints.MAX_SP_SIZE) {
        staffInfoActivate = DEFAULT_ACTIVATED_DISPLAY.split('');
        staffInfoProvisional = DEFAULT_ACTIVATED_DISPLAY.split('');
      }
      const applicantListColumns = tmpApplicantListColumns.map((col, i) => {
        const activated = staffInfoActivate[i]
          ? staffInfoActivate[i] === '1'
          : false;
        const provisional = staffInfoProvisional[i]
          ? staffInfoProvisional[i] === '1'
          : false;
        const selected = isActivated.value ? activated : provisional;
        return Object.assign({}, col, { selected });
      });

      const pageLoad = isActivated.value
        ? staffSetting.activatedCount
        : staffSetting.provisionalCount;
      return { applicantListColumns, pageLoad };
    };

    const {
      search,
      searchFilterObject,
      page,
      count,
      pageCount,
      events,
      staffs,
      applicants,
      selectedApplicants,
      applicantListColumns,
      applicantsTags,
      highlightApplicantIds,
      viewApplicantListColumns,
      tagGroups,
      surveySheetId,
      headerAdditional,
      loadSelection,
      searchFormIsVisible,
      searchFormDetailIsVisible,
      isActivated,
      invisibleColumnsStaffSetting,
      surveySetting,
      getSelectedApplicants,
      getSelectedApplicantsIds,
      surveyList,
      surveyTitles,
      isFlagMode,
      isShowSelectableApplicantTable,
      isShowSelectableApplicantTableSort,
      isOpenPagerLoad,
      isOpenSearchApplicantFilters,
      isSortedApplicantsByUnreadCountCheck,
      // methods
      onSearch,
      onSearchReset,
      movePage,
      movePager,
      fetchApplicants,
      openFloatApplicantsForm,
      searchFormDetailSwitch,
      searchDirectPresetSwitch,
      fetchEvents,
      fetchStaffs,
      fetchTagGroups,
      fetchSurveyList,
      setApplicantColumns,
      setViewApplicantListColumns,
      setHeaderAdditional,
      resetState,
      switchActivated,
      validateSelectedApplicants,
      onResize,
      onChangeSort,
      onSelectedPageLoad,
      onSelectedColumnFlagSelection,
      updateIsOpenPagerLoad,
      updatePageLoad,
      updateSearch,
      updateSearchFormDetailSwitch,
      updateSelectedApplicants,
      updateSearchFormIsVisible,
      updateSearchFormDetailIsVisible,
      updateApplicantListColumns,
      updateSurveySheetId,
      updateLoadSelection,
      updateSurveyTitles,
      updateIsShowSelectableApplicantTable,
      changeFlagMode,
    } = useSearchApplicant(store, store.getters['searchFilter/searchFilter'], {
      router,
      setStaffSetting,
      isRequiredApplicantTags: true,
    });

    // computed
    const unreadApplicantCountInGraduatedYear = computed(() => {
      return store.getters['applicant/unreadApplicantCountInGraduatedYear'];
    });
    const unreadLineCountInGraduatedYear = computed(() => {
      return store.getters['applicant/unreadLineCountInGraduatedYear'];
    });
    const isFreePlan = computed(() => {
      return store.getters['plan/isFreePlan'];
    });
    const pageLoad = computed(() => {
      const selectedLoads = loadSelection.value.filter(
        load => load.selected === true,
      );
      if (selectedLoads && selectedLoads.length >= 1) {
        updatePageLoad(selectedLoads[0].id);
        return selectedLoads[0].id;
      }
      updatePageLoad(loadSelection.value[0].id);
      return loadSelection.value[0].id;
    });
    const isSortedApplicantsByUnreadCountActive = computed(() => {
      if (isSortedApplicantsByUnreadCountCheck.value === false) {
        isSortedApplicantsByUnreadCount.value = false;
        return false;
      } else if (isSortedApplicantsByUnreadCount.value === true) {
        return true;
      }
    });

    // watch
    watch(route, async () => {
      const nextPage = route.params.page;
      if (nextPage) {
        await fetchApplicants(parseInt(nextPage, 10));
        await store.dispatch('page/SET_LOADED');
      }
    });
    // 卒年を監視して卒年変更時に自動的に初期ページで再検索させる
    watch(
      () => store.getters['graduateds/selectedGraduatedId'],
      () => movePage(1),
    );

    // methods
    const fetchAll = async (flagReload = false) => {
      // スタッフ設定を取得
      await activeProvisionalCountChange(isActivated.value);
      // フラググループ・Googleフォーム・イベント・スタッフ情報取得、スタッフ設定を適用
      await Promise.all([
        fetchTagGroupsLocal(),
        fetchSurveyList(),
        fetchEvents(),
        fetchStaffs(true),
        fetchSearchFilterPresets(),
      ]);
      // 表示する応募者の件数・表示項目の取得
      setApplicantColumns();
      // 表示する応募者一覧カラム、応募者一覧を取得
      setViewApplicantListColumns();
      await fetchApplicants(props.page, true, flagReload);
    };
    const fetchAndMovePage1 = async (flag = false) => {
      await fetchAll(flag);
      await movePage(1, parseInt(route.params.page, 10) !== 1 ? true : false);
      await store.dispatch('page/SET_LOADED');
    };
    /**
     * フラググループの読み込み
     */
    const fetchTagGroupsLocal = async () => {
      await fetchTagGroups(staffSetting.applicant_activated_flaggroup_display);
      setHeaderAdditional();
    };
    /**
     * スタッフ設定の切り替え
     * @param {boolean} tmpIsActivated 応募者フラグ
     */
    const activeProvisionalCountChange = async tmpIsActivated => {
      const staff = await staffsService.fetchStaff(
        store.getters['staff/staff'].setting.id,
      );
      const newLoadSelection = loadSelection.value.map(load => {
        const newLoadSelection = cloneDeep(load);
        if (tmpIsActivated) {
          // 応募者の場合
          newLoadSelection.selected =
            load.id === staff.setting.applicant_activated_count;
        } else {
          // LINE友達の場合
          newLoadSelection.selected =
            load.id === staff.setting.applicant_provisional_count;
        }
        return newLoadSelection;
      });
      updateLoadSelection(newLoadSelection);
      Object.keys(staff.setting).forEach(key => {
        staffSetting[key] = cloneDeep(staff.setting[key]);
      });
    };
    /**
     * 応募者・LINE友達切り替え
     * @param {boolean} tmpIsActivated 応募者フラグ
     */
    const switchActivatedLocal = async tmpIsActivated => {
      await activeProvisionalCountChange(tmpIsActivated);
      await switchActivated(tmpIsActivated);
    };
    /**
     * 検索条件プリセット取得
     */
    const fetchSearchFilterPresets = async () => {
      const res = await SearchFilterPresetsService.fetchSearchFilterPresets();
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      viewSearchPresets.value = res.search_filter_presets.map(v => ({
        ...v,
        selected: false,
      }));
    };
    /**
     * 検索条件プリセット選択時イベント
     * @param {{ item: { id: number, name: string, search_filter_obj: Object }}} payload 選択したデータ
     */
    const onSelectedPreset = async payload => {
      // 簡易プリセット検索
      isOpenSearchPresetSelection.value = false;
      const searchFilterObj = payload.item.search_filter_obj;
      // 通常検索をリセットして詳細検索にプリセット読み込み
      refSearchApplicantsWrapper.value.refSearchApplicants.reset();
      searchDirectPresetSwitch(true);
      await refSearchApplicantsWrapper.value.loadPresetSearchDetail({
        searchFilterObj,
        preset: { id: payload.item.id, name: payload.item.name },
      });
      // attendancesが取得できないのでnextTickで待つ
      nextTick(async () => {
        // setTimeoutがないとスクロールができない
        await setTimeout(async () => {
          // プリセット条件で検索
          await refSearchApplicantsWrapper.value.refSearchApplicantsDetail.onSearch(
            null,
            {
              searchDetailFilters: searchFilterObj.search_detail_filters,
              searchFlagGroups: searchFilterObj.search_flag_groups,
              searchFlagGroupOptions: searchFilterObj.search_flag_group_options,
              searchSurveys: searchFilterObj.search_surveys,
              searchSurveyTexts: searchFilterObj.search_survey_texts,
              surveySheetId: searchFilterObj.sheet_id,
              orFlag: searchFilterObj.or_flag,
            },
          );
          // 最後にプリセットを選択済みに変更
          viewSearchPresets.value = viewSearchPresets.value.map(v => ({
            ...v,
            selected: v.id === payload.item.id,
          }));
        }, 0);
      });
    };
    /**
     * スタッフ別表示設定の保存
     */
    const updateStaffSetting = async () => {
      const columnName = isActivated.value
        ? 'applicant_activated'
        : 'applicant_provisional';
      await staffsService.displaySetting(
        columnName,
        applicantListColumns.value,
        loadSelection.value,
      );
    };
    /**
     * 表示する応募者の件数選択時イベント
     * @param {{ item: { id: number }}} payload 選択したデータ
     */
    const onSelectedPageLoadLocal = async payload => {
      await onSelectedPageLoad(payload);
      await updateStaffSetting();
    };
    /**
     * 表示項目選択時イベント
     * @param {{ item: { id: number, selected: boolean }}} payload 選択したデータ
     */
    const onSelectedColumnSelection = async payload => {
      const tmpApplicantListColumns = cloneDeep(applicantListColumns.value);
      const index = await tmpApplicantListColumns.findIndex(
        col => col.id === payload.item.id,
      );
      tmpApplicantListColumns[index].selected = !payload.item.selected;
      updateApplicantListColumns(tmpApplicantListColumns);
      setViewApplicantListColumns();
      await updateStaffSetting();
    };
    /**
     * プリセットの選択をリセット
     */
    const resetViewSearchPresets = () => {
      viewSearchPresets.value = viewSearchPresets.value.map(v => ({
        ...v,
        selected: false,
      }));
    };
    /**
     * フラグモード・Googleフォーム切り替えボタン押下時のイベント
     * @param {{ item: { id: number }}} payload 選択したデータ
     */
    const onSelectedSurvey = async payload => {
      refSearchApplicantsWrapper.value.reset();
      await changeFlagMode(payload.item.id);
      setHeaderAdditional();
      resetViewSearchPresets();
      await onSearchReset();
    };
    /**
     * 検索のハンドリングイベント
     * @param {{ searchBody: Object, orFlag: boolean, searchFilterObject: Object }} payload 検索条件
     */
    const onSearchLocal = async payload => {
      resetViewSearchPresets();
      const res = await onSearch(payload);
      return res;
    };
    /**
     * リセット検索のハンドリングイベント
     */
    const onSearchResetLocal = async () => {
      refSearchApplicantsWrapper.value.reset();
      resetViewSearchPresets();
      const res = await onSearchReset();
      return res;
    };
    /**
     * 表示フラググループモーダル開閉イベント
     */
    const onClickedDispFlagGroupsBtn = () => {
      if (tagGroups.value.length !== 0) {
        isOpenColumnFlagSelection.value = true;
      }
    };
    // 「未読数で並べ替える」のボタンで並び替える
    const sortApplicantsByUnreadCountBtn = () => {
      const args =
        isSortedApplicantsByUnreadCount.value === false
          ? { sort_by: 'unread_count', sort_order: true }
          : { sort_by: null, sort_order: null };
      refSelectableApplicantTable.value.onClickShowUnreadCount(args);
      isSortedApplicantsByUnreadCount.value =
        !isSortedApplicantsByUnreadCount.value;
      isSortedApplicantsByNewMessage.value = false;
    };
    // 未読数で並び替える
    const sortApplicantsByUnreadCount = () => {
      const args =
        isSortedApplicantsByUnreadCount.value === true
          ? { sort_by: null, sort_order: null }
          : { sort_by: 'unread_count', sort_order: false };
      refSelectableApplicantTable.value.onClickShowUnreadCount(args);
      isSortedApplicantsByUnreadCount.value =
        !isSortedApplicantsByUnreadCount.value;
      isSortedApplicantsByNewMessage.value = false;
    };
    // 新着メッセージ順で並び替える
    const sortApplicantsByNewMessage = () => {
      const args =
        isSortedApplicantsByNewMessage.value === true
          ? { sort_by: null, sort_order: null }
          : { sort_by: 'message_created_at', sort_order: false };
      refSelectableApplicantTable.value.onClickShowUnreadCount(args);
      isSortedApplicantsByNewMessage.value =
        !isSortedApplicantsByNewMessage.value;
      isSortedApplicantsByUnreadCount.value = false;
    };
    // searchFilter/START_SEARCHを監視する
    const unsubscribeStore = store.subscribe(async mutation => {
      if (mutation.type === 'searchFilter/START_SEARCH') {
        updateSearch(store.getters['searchFilter/searchFilter']);
        store.dispatch('searchFilter/RESET_SEARCH_FILTER');
        updateSearchFormDetailSwitch(false);
        await fetchApplicants();
      }
    });

    // lifecycle
    onBeforeMount(async () => {
      updateSearch(store.getters['searchFilter/searchFilter']);
      store.dispatch('searchFilter/RESET_SEARCH_FILTER');
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      await store.dispatch(
        'applicant/FETCH_UNREAD_APPLICANT_COUNT',
        graduatedYear.year,
      );
      await store.dispatch(
        'applicant/FETCH_UNREAD_LINE_COUNT',
        graduatedYear.year,
      );
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });
    onMounted(() => {
      window.addEventListener('resize', onResize);
    });

    onUnmounted(() => {
      unsubscribeStore();
      window.removeEventListener('resize', onResize);
    });

    return {
      refSearchApplicantsWrapper,
      refSelectableApplicantTable,
      isSortedApplicantsByUnreadCount,
      isSortedApplicantsByNewMessage,
      isOpenSearchPresetSelection,
      isOpenColumnSelection,
      isOpenColumnFlagSelection,
      isOpenApplicantsEditForm,
      isOpenSurveyMenu,
      unreadApplicantCountInGraduatedYear,
      unreadLineCountInGraduatedYear,
      isFreePlan,
      isSortedApplicantsByUnreadCountActive,
      // composable state
      search,
      searchFilterObject,
      localPage: page,
      count,
      pageCount,
      events,
      staffs,
      applicants,
      selectedApplicants,
      applicantListColumns,
      applicantsTags,
      highlightApplicantIds,
      viewSearchPresets,
      viewApplicantListColumns,
      tagGroups,
      surveySheetId,
      headerAdditional,
      loadSelection,
      searchFormIsVisible,
      searchFormDetailIsVisible,
      pageLoad,
      isActivated,
      invisibleColumnsStaffSetting,
      surveySetting,
      getSelectedApplicants,
      getSelectedApplicantsIds,
      surveyList,
      surveyTitles,
      isFlagMode,
      isShowSelectableApplicantTable,
      isShowSelectableApplicantTableSort,
      isOpenPagerLoad,
      isOpenSearchApplicantFilters,
      isSortedApplicantsByUnreadCountCheck,
      // methods
      fetchAndMovePage1,
      fetchSearchFilterPresets,
      sortApplicantsByUnreadCountBtn,
      sortApplicantsByUnreadCount,
      sortApplicantsByNewMessage,
      onClickedDispFlagGroupsBtn,
      changeFlagMode,
      // composable methods
      movePager,
      openFloatApplicantsForm,
      searchFormDetailSwitch,
      switchActivatedLocal,
      onSearchLocal,
      onSearchResetLocal,
      onSelectedPreset,
      onSelectedColumnSelection,
      onSelectedSurvey,
      onChangeSort,
      onSelectedPageLoadLocal,
      onSelectedColumnFlagSelection,
      updateIsOpenPagerLoad,
      updateSelectedApplicants,
      updateSearchFormIsVisible,
      updateSearchFormDetailIsVisible,
      updateSurveySheetId,
      updateSurveyTitles,
      updateIsShowSelectableApplicantTable,
      resetState,
      validateSelectedApplicants,
      fetchAll,
      movePage,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.local-nav {
  display: flex;
  font-size: 1.4rem;
  .item {
    padding: 20px 20px;
    white-space: nowrap;
    cursor: pointer;
    &.active,
    &:hover {
      color: #1899d6;
      font-weight: bold;
      border-bottom: 3px solid #1899d6;
    }
  }
  .badge {
    font-weight: bold;
    font-size: 1rem;
    border-radius: 12px;
    background: #e83d86;
    color: #ffffff;
    text-align: center;
    padding: 2px 10px;
    margin-left: 2px;
  }
}
.content {
  padding: 30px;
  background-color: #fff;
}
.function-area {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ebebeb;
  .btn-menu + .btn-menu {
    margin-left: 10px;
  }
}
.btnArea-refine {
  display: flex;
  margin-bottom: 20px;
  .button_regist {
    width: 240px;
    padding: 10px 15px;
  }
}
.btnArea-option {
  display: flex;
}
.tableList-head {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ($media_query_sp)) {
    flex-direction: column;
  }
}
.applicant-count {
  font-size: 1.4rem;
  @media (max-width: ($media_query_sp)) {
    margin-bottom: 20px;
  }
  > .num {
    font-size: 2rem;
    font-weight: bold;
  }
}
.tableList-footer {
  margin-top: 20px;
  @media (max-width: ($media_query_sp)) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
}
.headline-typeC {
  display: flex;
  > p {
    padding: 8.5px 0;
    line-height: 1;
  }
}
.btn-availability {
  margin: 0 20px;
}
.headline-caption {
  font-weight: normal;
  font-size: 12px;
}
.button_search {
  position: relative;
  padding: 10px 15px 10px 11px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background-color: #16b2d9;
  cursor: pointer;
  &:hover {
    background-color: #1698d9;
  }
  > .search_icon {
    height: 14px;
    margin-right: 4px;
    vertical-align: -2px;
  }
}
.button-select_search_preset {
  width: 480px;
  margin-left: 10px;
}

.button_selected_applicant_options {
  display: inline-block;
  position: relative;
  width: 235px;
  margin-bottom: 10px;
  .btn-bulk-edit {
    padding: 10px 30px 10px 10px;
    background-image: url('../../assets/img/rect_arrow_down_white.svg');
    background-position: right 10px center;
    background-size: 10px;
    background-repeat: no-repeat;
  }
}
:deep(.btn-icon-checkbox span::before) {
  vertical-align: -1px;
}

.button-sort {
  margin: 0 0 0 10px;
}
.unreadMessageApplicantView {
  display: inline-block;
  margin: 0 0 0 10px;
  height: 36px;
  border: solid 1px $mochica_color;
  background-color: #fff;
  color: $mochica_color !important;
  &.active {
    color: #fff !important;
    background-color: $mochica_color;
  }
}
.button_preset_select {
  position: relative;
  max-width: 530px;
  width: 100%;
  margin: 0 0 0 10px;
  > .button_regist {
    width: 170px;
    padding-left: 12px;
    padding-right: 25px;
    > img {
      margin-right: 5px;
    }
  }
  .balloon-box {
    left: -50px;
    &:before,
    &:after {
      margin-left: 50px;
    }
  }
}

.button-regist {
  &.is-disabled {
    color: #aaa;
  }
}

.applicants_sub_menu {
  padding: 30px 30px 20px;
  background-color: #fff;
}
.applicants_sub_menu-inner {
  display: flex;
  flex-wrap: wrap;
  > .btn {
    margin: 0 5px 10px 0;
    > img {
      width: 14px;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .button_preset_select {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
  .btnArea-refine {
    display: block;
    .button_regist {
      width: auto;
      padding: 10px 30px 10px 15px;
    }
    .button-select_search_preset {
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
    }
  }
  .btns-area {
    .double-check {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px math_size(20);
  }
  .function-area {
    .btn-menu + .btn-menu {
      margin: 10px 0 0;
    }
  }
  .btnArea-option {
    display: block;
  }
  .headline-typeC {
    display: block;
    > p {
      padding: 0;
    }
  }
  .btn-availability {
    width: 100%;
    margin: 20px 0 0;
  }
  .btn-area {
    > .btn {
      width: 100%;
    }
  }
}
</style>
