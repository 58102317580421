<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <div class="row-2">
      <div class="applicants_menu">
        <button-base
          class="btn btn-applicants-search"
          button-text="絞り込み検索"
          icon-file-name="search_white"
          @click="openFloatApplicantsForm"
        />

        <template v-if="isOpenSearchApplicantFilters">
          <search-applicants-wrapper
            ref="refSearchApplicantsWrapper"
            :search-form-is-visible="searchFormIsVisible"
            :search-form-detail-is-visible="searchFormDetailIsVisible"
            :is-flag-mode="isFlagMode"
            :staffs="staffs"
            :events="events"
            :tag-groups="tagGroups"
            :survey-sheet-id="surveySheetId"
            :survey-titles="surveyTitles"
            :is-activated="isActivated"
            :search="search"
            :change-flag-mode="changeFlagMode"
            @searchFormDetailSwitch="searchFormDetailSwitch"
            @updateSearchFormIsVisible="updateSearchFormIsVisible"
            @updateSearchFormDetailIsVisible="updateSearchFormDetailIsVisible"
            @updateSurveySheetId="updateSurveySheetId"
            @updateSurveyTitles="updateSurveyTitles"
            @updateIsShowSelectableApplicantTable="
              updateIsShowSelectableApplicantTable
            "
            @onSearch="onSearch"
            @onSearchReset="onSearchResetLocal"
          />
        </template>
      </div>

      <div class="applicant_count">
        <div class="button_regist" @click="updateIsOpenPagerLoad(true)">
          表示する応募者の件数：{{ pageLoad }}件
          <img
            slot="arrow"
            class="arrow"
            src="@/assets/img/arrow_down_white.svg"
          />
        </div>
        <float-context-menu
          :is-view="isOpenPagerLoad"
          :list="loadSelection"
          @onClose="updateIsOpenPagerLoad(false)"
          @onSelected="onSelectedPageLoad"
        />
      </div>

      <div class="applicant_survey">
        <div class="button_regist" @click="updateIsOpenSurveyMenu(true)">
          <span slot="text">{{ surveySetting }}</span>
          <img
            slot="arrow"
            class="arrow"
            src="@/assets/img/arrow_down_white.svg"
          />
        </div>
        <float-context-menu
          :is-view="isOpenSurveyMenu"
          :list="surveyList"
          @onClose="updateIsOpenSurveyMenu(false)"
          @onSelected="onSelectedSurvey"
        />
      </div>

      <div v-if="csvDownload" class="applicant_survey btn_download">
        <div class="btn btn-availability" @click="onClickDownloadApplicantsCsv">
          <span slot="text">
            <img class="icon" src="@/assets/img/download_white.svg" />
            削除予定の応募者のCSVをダウンロード
          </span>
        </div>
      </div>
    </div>
    <search-filter-indicator
      v-if="isShowSearchFilterIndicator"
      :search-filter-detail="searchFilterObject.data"
      :events="events"
      :tag-groups="tagGroups"
      :survey-titles="surveyTitles"
      :staffs="staffs"
      @onReset="onSearchResetLocal"
    />
    <div class="pager_wrapper">
      <div v-if="isAllApplicants === false" class="pager_wrapper_switch_area">
        <switch-tab-applicant
          :disp-applicants="dispApplicants"
          :is-disp-line-friends="isDispLineFriend"
          :is-disp-applicant="true"
          @input="onChangeActivated"
        />
      </div>
      <div
        v-if="isShowSelectableApplicantTable"
        class="pager_wrapper_pager col-right margin-left-auto flexbox valingn-middle"
      >
        <div class="area-pager margin-left-auto">
          <pager
            :page="page"
            :count="count"
            :page-count="pageCount"
            :load="pageLoad"
            @move="movePager"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isSelectable && isShowSelectableApplicantTable"
      class="applicant_counter"
    >
      <div>
        <span class="title_text">選択されている人数：</span>
        <span class="count_text">
          {{ selectedAllApplicants.length }}
          <template v-if="sendLimit > 0">/ {{ sendLimit }}</template>
          名
        </span>
      </div>
    </div>
    <local-page-loader v-if="!isShowSelectableApplicantTable" />
    <div v-if="isShowSelectableApplicantTable" class="area-search-list">
      <!-- 応募者一覧 -->
      <selectable-applicant-table
        v-show="isActivated === true"
        :is-selectable="isSelectable"
        :init-selected-applicants="selectedRegistApplicants"
        :applicants="registedApplicants"
        :applicants-tags="applicantsTags"
        :can-move-detail="true"
        :invisible-columns="invisibleColumns"
        :header-additional="headerAdditional"
        :flag-mode="isFlagMode"
        @onChangeCurrentSelect="onChangeCurrentSelectRegistApplicants"
        @onChangeSort="onChangeSort"
      />
      <!-- LINE友達一覧 -->
      <selectable-applicant-table
        v-show="isActivated === false"
        :is-selectable="isSelectable"
        :init-selected-applicants="selectedLineFriendApplicants"
        :applicants="lineFriends"
        :applicants-tags="applicantsTags"
        :can-move-detail="true"
        :invisible-columns="invisibleColumns"
        :header-additional="headerAdditional"
        :flag-mode="isFlagMode"
        @onChangeCurrentSelect="onChangeCurrentSelectLineFriends"
        @onChangeSort="onChangeSort"
      />
    </div>
    <p v-if="selectionCount" class="list-view-applicant_value">
      現在この選考には
      <span class="value">{{ selectionCount }}名</span>
      が登録されています。
    </p>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import { useStore } from 'vuex';

import useSearchApplicant from '@/composables/useSearchApplicant';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import Pager from '@/components/ui/pager/components/Pager';
import SwitchTabApplicant from '@/components/ui/switchTabApplicant/components/SwitchTabApplicant';
import SearchApplicantsWrapper from '@/components/features/searchApplicants/components/SearchApplicantsWrapper';
import SearchFilterIndicator from '@/components/features/searchApplicants/components/SearchFilterIndicator';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';

export default defineComponent({
  name: 'ModalSelectableApplicants',
  components: {
    SelectableApplicantTable,
    Pager,
    FloatContextMenu,
    SearchApplicantsWrapper,
    SearchFilterIndicator,
    SwitchTabApplicant,
    LocalPageLoader,
  },
  props: {
    disabledApplicantIds: {
      type: Array,
      default: () => [],
    },
    targetApplicantIds: {
      type: Array,
      default: () => [],
    },
    invisibleColumnsPc: {
      type: Array,
      default: () => [],
    },
    invisibleColumnsSp: {
      type: Array,
      default: () => null,
    },
    initiSelectedRegistApplicants: {
      type: Array,
      default: () => [],
    },
    initiSelectedLineFriends: {
      type: Array,
      default: () => [],
    },
    sendLimit: {
      type: Number,
      default: 0,
    },
    excludeSelectionId: {
      type: Number,
      default: null,
    },
    selectionCount: {
      type: Number,
      default: null,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    isAllApplicants: {
      type: Boolean,
      default: false,
    },
    isOnlyApplicantsOfLine: {
      type: Boolean,
      default: false,
    },
    isExceptGraduated: {
      type: Boolean,
      default: false,
    },
    csvDownload: {
      type: Boolean,
      default: false,
    },
    isDispLineFriend: {
      type: Boolean,
      default: true,
    },
    isShowSearchFilterIndicator: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onChangeCurrentSelect',
    'onClickDownloadApplicantsCsv',
    'onClickSettle',
  ],
  setup(props, context) {
    const store = useStore();
    const refSearchApplicantsWrapper = ref(null);
    const selectedRegistApplicants = ref(
      cloneDeep(props.initiSelectedRegistApplicants),
    );
    const selectedLineFriendApplicants = ref(
      cloneDeep(props.initiSelectedLineFriends),
    );

    // composable
    const {
      search,
      searchFilterObject,
      page,
      count,
      pageCount,
      pageLoad,
      events,
      staffs,
      applicants,
      headerAdditional,
      loadSelection,
      invisibleColumns,
      applicantsTags,
      searchFormIsVisible,
      searchFormDetailIsVisible,
      isActivated,
      surveySetting,
      surveyList,
      surveyTitles,
      tagGroups,
      surveySheetId,
      isFlagMode,
      isShowSelectableApplicantTable,
      isOpenPagerLoad,
      isOpenSearchApplicantFilters,
      isOpenSurveyMenu,
      // methods
      onSearch,
      onSearchReset,
      movePager,
      fetchApplicants,
      openFloatApplicantsForm,
      searchFormDetailSwitch,
      fetchEvents,
      fetchStaffs,
      fetchSurveyList,
      fetchTagGroups,
      setApplicantColumns,
      switchActivated,
      onResize,
      onChangeSort,
      onSelectedPageLoad,
      updateIsOpenPagerLoad,
      updateIsOpenSurveyMenu,
      updateSearchFormIsVisible,
      updateSearchFormDetailIsVisible,
      updateTargetIds,
      updateIsFlagMode,
      updateSurveySheetId,
      updateSurveyTitles,
      updateIsShowSelectableApplicantTable,
      changeFlagMode,
    } = useSearchApplicant(store, store.getters['searchFilter/searchFilter'], {
      invisibleColumnsPc: props.invisibleColumnsPc,
      invisibleColumnsSp: props.invisibleColumnsSp,
      excludeSelectionId: props.excludeSelectionId,
      isAllApplicants: props.isAllApplicants,
      isOnlyApplicantsOfLine: props.isOnlyApplicantsOfLine,
      isExceptGraduated: props.isExceptGraduated,
      isRequiredApplicantTags: true,
    });

    // computed
    const dispApplicants = computed(() => {
      if (isActivated.value === true) return 'regist_applicants';
      return 'line_friends';
    });
    const registedApplicants = computed(() => {
      return applicants.value.map(x => {
        x.isDisabled = props.disabledApplicantIds.includes(x.id);
        return x;
      });
    });
    const lineFriends = computed(() => {
      return applicants.value.map(x => {
        x.isDisabled = props.disabledApplicantIds.includes(x.id);
        return x;
      });
    });
    const selectedAllApplicants = computed(() => [
      ...selectedRegistApplicants.value,
      ...selectedLineFriendApplicants.value,
    ]);

    // methods
    const fetchAll = async () => {
      if (props.targetApplicantIds.length > 0) {
        updateTargetIds(props.targetApplicantIds);
      }
      // フラググループ・Googleフォーム・イベント・スタッフを取得
      onResize();
      await Promise.all([
        fetchTagGroups(),
        fetchSurveyList(),
        fetchEvents(),
        fetchStaffs(true),
      ]);
      // 応募者一覧を取得
      await fetchApplicants(1, true);
    };
    const onChangeCurrentSelectRegistApplicants = payload => {
      if (payload !== undefined) {
        selectedRegistApplicants.value = payload.selectedApplicants;
        context.emit('onChangeCurrentSelect', {
          selectedRegistApplicantIds: selectedRegistApplicants.value,
          selectedLineFriendIds: selectedLineFriendApplicants.value,
          selectedApplicantIds: selectedAllApplicants.value,
        });
      }
    };
    const onChangeCurrentSelectLineFriends = payload => {
      if (payload !== undefined) {
        selectedLineFriendApplicants.value = payload.selectedApplicants;
        context.emit('onChangeCurrentSelect', {
          selectedRegistApplicantIds: selectedRegistApplicants.value,
          selectedLineFriendIds: selectedLineFriendApplicants.value,
          selectedApplicantIds: selectedAllApplicants.value,
        });
      }
    };
    const onChangeActivated = key => {
      switchActivated(key === 'regist_applicants');
    };
    const onClickDownloadApplicantsCsv = () => {
      context.emit('onClickDownloadApplicantsCsv');
    };
    // フラグモード・Googleフォーム切り替えボタン押下時のイベント
    const onSelectedSurvey = async payload => {
      refSearchApplicantsWrapper.value.reset();
      await changeFlagMode(payload.item.id);
      await onSearchReset();
    };
    // リセット検索のハンドリングイベント
    const onSearchResetLocal = async () => {
      refSearchApplicantsWrapper.value.reset();
      const res = await onSearchReset();
      return res;
    };

    onBeforeMount(async () => {
      setApplicantColumns();
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });
    onMounted(async () => {
      window.addEventListener('resize', onResize);
    });
    onUnmounted(async () => {
      window.removeEventListener('resize', onResize);
    });

    return {
      // computed
      dispApplicants,
      registedApplicants,
      lineFriends,
      selectedAllApplicants,
      selectedRegistApplicants,
      selectedLineFriendApplicants,
      // composables
      search,
      searchFilterObject,
      page,
      count,
      pageCount,
      pageLoad,
      events,
      staffs,
      loadSelection,
      surveySetting,
      searchFormIsVisible,
      searchFormDetailIsVisible,
      applicantsTags,
      surveyList,
      surveyTitles,
      tagGroups,
      surveySheetId,
      invisibleColumns,
      headerAdditional,
      isActivated,
      isFlagMode,
      isShowSelectableApplicantTable,
      isOpenPagerLoad,
      isOpenSearchApplicantFilters,
      isOpenSurveyMenu,
      refSearchApplicantsWrapper,
      onSearch,
      onSearchResetLocal,
      // methods
      onChangeActivated,
      movePager,
      searchFormDetailSwitch,
      switchActivated,
      onChangeSort,
      onSelectedPageLoad,
      onSelectedSurvey,
      onChangeCurrentSelectRegistApplicants,
      onChangeCurrentSelectLineFriends,
      onClickDownloadApplicantsCsv,
      openFloatApplicantsForm,
      updateIsOpenPagerLoad,
      updateIsOpenSurveyMenu,
      updateSearchFormIsVisible,
      updateSearchFormDetailIsVisible,
      updateIsFlagMode,
      updateSurveySheetId,
      updateSurveyTitles,
      updateIsShowSelectableApplicantTable,
      changeFlagMode,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.row-2 {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.applicants_menu {
  position: relative;
  margin-right: 1rem;
  .btn-applicants-search {
    padding: 10px 30px 10px 15px;
    background-image: url('../../../../assets/img/rect_arrow_down_white.svg');
    background-position: right 10px center;
    background-size: 10px;
    background-repeat: no-repeat;
  }
}

.applicant_counter {
  .title_text {
    font-weight: bold;
  }
  .count_text {
    font-size: 1.8rem;
    margin-right: 20px;
  }
}

.pager_wrapper {
  display: flex;
  > .pager_wrapper_pager {
    margin-top: -10px;
    height: 32px;
    margin-bottom: calc(2rem - 2px);
  }
}

.applicant_count {
  position: relative;
  margin-right: 1rem;
}

.applicant_survey {
  position: relative;
}

.btn-applicants-search {
  transition: all ease 0.3s;
  &:hover {
    opacity: 0.6;
  }
}

.area-search-list {
  margin-top: 1rem;
}

.area-search-after {
  padding: 1rem;
}

.valingn-middle {
  align-items: center;
}

.button_regist {
  position: relative;
  padding: 10px 30px 10px 15px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background-color: #16b2d9;
  cursor: pointer;
  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 10px;
    margin-top: -2px;
  }
}

.btn_download {
  margin-left: 10px;
}

.list-view-applicant_value {
  margin-top: 1rem;
  text-align: left;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  > .value {
    color: $mochica_color;
  }
}

@media (max-width: ($media_query_sp)) {
  .row-2 {
    display: block;
  }

  .applicants_menu {
    margin: 0 0 10px 0;
    .btn {
      width: 100%;
      text-align: left;
    }
  }

  .pager_wrapper {
    display: block;
    > .pager_wrapper_pager {
      margin-bottom: calc(2rem);
    }
  }

  .applicant_count {
    margin: 0 0 10px 0;
  }

  .applicant_survey {
    margin: 0 0 10px 0;
  }

  .btn_download {
    margin-left: 0;
  }
}
</style>
