import { reactive, ref } from 'vue';

export default function useSearchApplicantPreset() {
  // 'searchFilter' | 'presetList' | 'presetEdit' | 'presetDeleteDialog' | 'presetEditDialog'
  const pageMode = ref('searchFilter');
  const currentPreset = reactive({ data: {} });
  const editPresetId = ref(null);
  const editPresetName = ref('');
  const editPresetSearchFilterObject = reactive({ data: {} });

  //methods
  /**
   * 検索条件が空かどうかチェック
   * @param {{
   *   search_detail_filters: [],
   *   search_flag_groups: [],
   *   search_flag_group_options: [],
   *   search_surveys: []
   *   search_survey_texts: [],
   * }} presetSearchFilterObject
   * @return {{ success: boolean, message: text }}
   */
  const validateSearchFiltersEmpty = presetSearchFilterObject => {
    // 値が空の行を除外して検索条件が入力されているかチェック
    // 検索条件が 文字列・数値・配列の値が入力されているか、もしくは「存在する/存在しない」が選択されているか確認
    const searchDetailFilters =
      presetSearchFilterObject.search_detail_filters.filter(
        v =>
          v.exist !== null ||
          (v.data !== null &&
            v.data !== undefined &&
            (((typeof v.data === 'string' || v.data instanceof String) &&
              v.data !== '') ||
              Number.isInteger(v.data) ||
              (Array.isArray(v.data) && v.data.length > 0))),
      );
    // フラググループとフラグが選択されているか、もしくはフラググループと「存在する/存在しない」が選択されているか確認
    const searchFlagGroups = presetSearchFilterObject.search_flag_groups.filter(
      (v, i) =>
        (v.id !== null &&
          v.id !== undefined &&
          v.id !== '' &&
          presetSearchFilterObject.search_flag_group_options[i].exist !==
            null) ||
        (v.id !== null &&
          v.id !== undefined &&
          v.id !== '' &&
          v.flags.length > 0),
    );
    // アンケート・アンケートタイトルが選択されているか確認
    const searchSurveys = presetSearchFilterObject.search_surveys.filter(
      (v, i) =>
        v.id !== null &&
        v.id !== undefined &&
        v.id !== '' &&
        presetSearchFilterObject.search_survey_texts[i],
    );
    if (
      searchDetailFilters.length > 0 ||
      searchFlagGroups.length > 0 ||
      searchSurveys.length > 0
    ) {
      return { success: true, message: '' };
    }
    // 検索条件が入力されていない場合は上書きできない
    return { success: false, message: '検索条件が入力されていません' };
  };
  /**
   * 検索条件入力画面を開く
   */
  const openSearchFilter = () => {
    pageMode.value = 'searchFilter';
  };
  /**
   * プリセット一覧画面を開く
   */
  const openPresetListModal = () => {
    pageMode.value = 'presetList';
  };
  /**
   * プリセット削除ダイアログ画面を開く
   * @param {string} name プリセット名
   */
  const openPresetDeleteDialogModal = ({ presetId, presetName }) => {
    editPresetId.value = presetId;
    editPresetName.value = presetName;
    pageMode.value = 'presetDeleteDialog';
  };
  /**
   * プリセット作成・編集画面を開く
   * @param {{
   *   presetName: string,
   *   presetSearchFilterObject: {
   *     search_detail_filters: [],
   *     search_flag_groups: [],
   *     search_flag_group_options: [],
   *     search_surveys: []
   *     search_survey_texts: [],
   *   },
   * }} プリセットデータ
   * @return {{ success: boolean, message: text }}
   */
  const openPresetEditModal = ({
    presetId,
    presetName,
    presetSearchFilterObject,
  }) => {
    const valid = validateSearchFiltersEmpty(presetSearchFilterObject);
    if (valid.success === true) {
      editPresetId.value = presetId;
      editPresetName.value = presetName;
      editPresetSearchFilterObject.data = presetSearchFilterObject;
      pageMode.value = 'presetEdit';
      return { success: true, message: valid.message };
    } else {
      return { success: false, message: valid.message };
    }
  };
  /**
   * プリセット上書きダイアログ画面を開く
   * @param {{
   *   presetName: string,
   *   presetSearchFilterObject: {
   *     search_detail_filters: [],
   *     search_flag_groups: [],
   *     search_flag_group_options: [],
   *     search_surveys: []
   *     search_survey_texts: [],
   *   },
   * }} プリセットデータ
   * @return {{ success: boolean, message: text }}
   */
  const openPresetEditDialogModal = ({
    presetId,
    presetName,
    presetSearchFilterObject,
  }) => {
    // プリセット上書き確認ダイアログ表示
    const valid = validateSearchFiltersEmpty(presetSearchFilterObject);
    if (valid.success === true) {
      editPresetId.value = presetId;
      editPresetName.value = presetName;
      pageMode.value = 'presetEditDialog';
      return { success: true, message: valid.message };
    } else {
      return { success: false, message: valid.message };
    }
  };
  /**
   * 通常検索・詳細検索切り替えダイアログを開く
   */
  const openSwitchSearchFilterDialogModal = () => {
    pageMode.value = 'switchSearchFilter';
  };
  /**
   * 通常検索・詳細検索切り替え処理
   * @param {{ choice: number, callbackFunction: Function,}} payload
   */
  const switchSearchFilter = payload => {
    // キャンセルでない場合
    if (payload.choice !== 1) payload.callbackFunction();
    pageMode.value = 'searchFilter';
  };
  /**
   * editPresetSearchFilterObjectの更新
   * @param {Object} newEditPresetSearchFilterObject
   */
  const updateEditPresetSearchFilterObject =
    newEditPresetSearchFilterObject => {
      editPresetSearchFilterObject.data = newEditPresetSearchFilterObject;
    };
  /**
   * currentPresetの更新
   * @param {Object} newEditPresetSearchFilterObject
   */
  const updateCurrentPreset = value => {
    currentPreset.data = value;
  };

  return {
    pageMode,
    currentPreset,
    editPresetId,
    editPresetName,
    editPresetSearchFilterObject,
    openSearchFilter,
    openPresetListModal,
    openPresetEditModal,
    openPresetDeleteDialogModal,
    openPresetEditDialogModal,
    openSwitchSearchFilterDialogModal,
    switchSearchFilter,
    updateEditPresetSearchFilterObject,
    updateCurrentPreset,
  };
}
