<template>
  <div>
    <modal-window
      :is-visible="isView"
      title="削除したい卒業年数を選択してください"
      modal-type="danger"
      @close="onSelectedModalInput({ selected: 'cancel' })"
    >
      <template #content>
        <div class="modal-window">
          <div class="body">
            <div class="message">
              <div v-if="localGraduateds.length > 0" class="select-graduated">
                <label class="c-select">
                  <select v-model="selectedGraduatedId" class="select">
                    <option
                      v-for="graduated in localGraduateds"
                      :key="graduated.year"
                      class="select-elements"
                      :label="graduatedYearToLabelName(graduated.year)"
                      :value="graduated.id"
                    >
                      {{ graduatedYearToLabelName(graduated.year) }}
                    </option>
                  </select>
                </label>
              </div>
              <div v-else class="err-txt">削除候補の卒年がありません。</div>
            </div>
          </div>
          <div class="btn-col2">
            <div
              class="cancelBtn btn"
              @click="onSelectedModalInput({ selected: 'cancel' })"
            >
              キャンセル
            </div>
            <button
              class="deleteBtn btn"
              :disabled="!selectedGraduatedId"
              @click="onSelectedModalInput({ selected: 'submit' })"
            >
              卒業年数を確認
            </button>
          </div>
        </div>
      </template>
    </modal-window>
    <modal-window
      :is-visible="isVisibleEditModal"
      :title="'卒業年数' + localYear + '年を削除します'"
      message="この操作は取り消すことができません。"
      modal-type="danger"
      button-text="卒業年数を削除"
      :is-disabled="isDisabled"
      :is-loading="isLoading"
      @click="onSelectedModalConfirm"
      @close="isVisibleEditModal = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import GraduatedsService from '@/services/graduateds';
import GRAD_SET from '@/defines/graduated';
import GraduatedDefines from '@/defines/graduated';

export default defineComponent({
  name: 'ModalDeleteGraduated',
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onCanceled'],
  setup(props, context) {
    const store = useStore();
    const localYear = ref(null);
    const localGraduatedId = ref(null);
    const selectedGraduatedId = ref('');
    const isVisibleEditModal = ref(false);
    const isDisabled = ref(false);
    const isLoading = ref(false);

    // computed
    const localGraduateds = computed(() => {
      const graduatedYears = GRAD_SET.map(v => v.year);
      return store.getters['graduateds/graduateds'].filter(
        v => !graduatedYears.includes(v.year),
      );
    });

    // methods
    const graduatedYearToLabelName = year => {
      const res = GraduatedDefines.find(v => v.year === year);
      if (res !== undefined) return res.name_jp;
      return `${year}年度`;
    };
    const onSelectedModalInput = ({ selected }) => {
      const selectedGraduatedYear = selectedGraduatedId.value
        ? localGraduateds.value.filter(
            v => v.id === selectedGraduatedId.value,
          )[0].year
        : null;
      if (selected === 'submit') {
        localYear.value = selectedGraduatedYear;
        localGraduatedId.value = selectedGraduatedId.value;
        context.emit('onCanceled');
        isVisibleEditModal.value = true;
      } else {
        context.emit('onCanceled');
        localYear.value = null;
        localGraduatedId.value = null;
      }
      selectedGraduatedId.value =
        localGraduateds.value.length > 0 ? localGraduateds.value[0].id : '';
    };
    const onSelectedModalConfirm = async ({ selected }) => {
      if (selected === 'submit') {
        isDisabled.value = true;
        isLoading.value = true;
        const res = await GraduatedsService.deleteGraduated(localYear.value);
        isDisabled.value = false;
        isLoading.value = false;
        if (res.success) {
          store.dispatch('graduateds/FETCH');
          context.emit('onCanceled');
        }
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        if (localGraduatedId.value === selectedGraduatedId.value) {
          selectedGraduatedId.value = '';
        }
      }
      isVisibleEditModal.value = false;
      localYear.value = null;
      localGraduatedId.value = null;
    };

    // lifecycle
    onMounted(() => {
      selectedGraduatedId.value =
        localGraduateds.value.length > 0 ? localGraduateds.value[0].id : '';
    });

    return {
      localYear,
      selectedGraduatedId,
      isVisibleEditModal,
      isDisabled,
      isLoading,
      localGraduateds,
      onSelectedModalInput,
      graduatedYearToLabelName,
      onSelectedModalConfirm,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.modal-window {
  margin: 0;
  width: 100%;
  .message {
    text-align: center;
    .select-graduated {
      > .c-select {
        display: inline-block;
        position: relative;
      }
      > .c-select::after {
        position: absolute;
        top: 50%;
        right: 7px;
        width: 6px;
        height: 6px;
        content: '';
        margin-top: -4px;
        border: 0;
        border-bottom: solid 2px #16b2d9;
        border-right: solid 2px #16b2d9;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      > .c-select > .select {
        padding: 7px 20px 7px 9px;
        border-radius: 4px;
        border: 1px solid #9d9d9d;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &::-ms-expand {
          display: none;
        }
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
      > .c-select > .select > .select-elements {
        color: #333333;
      }
    }
    .err-txt {
      color: $mochica_color_red;
    }
  }
}
</style>
