<template>
  <div class="home_content">
    <p class="home_sub_title">未読</p>
    <p class="home_caption">未読のメッセージを確認してください。</p>
    <div class="home_counter">
      <dl
        class="is-unread_messages"
        @click="$router.push({ name: 'ApplicantsUnreadMessages' })"
      >
        <dt>未読メッセージ</dt>
        <dd>{{ unreadMessageCount }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import applicantService from '@/services/applicant';

export default defineComponent({
  name: 'HomeUnreadMessages',
  setup(props, context) {
    const store = useStore();
    const unreadMessageCount = ref(null);

    const fetchAll = async () => {
      const res = await applicantService.getUnreadMessagesSummary();
      if (res.success === true) {
        unreadMessageCount.value = res.count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
      }
    };

    return { unreadMessageCount, fetchAll };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.home_sub_title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
}

.home_caption {
  margin-bottom: 24px;
  font-size: 14px;
  color: #777777;
}

.home_content {
  padding: 30px 30px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
}

.home_counter {
  min-height: 94px;
  padding: 17px 20px;
  margin-bottom: 12px;
  border: 1px solid #d7ebf5;
  border-radius: 4px;
  background: #f3f9fc;
  cursor: pointer;
  > dl {
    line-height: 1;
    > dt {
      position: relative;
      padding-left: 22px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 16px;
        height: 15px;
        background-position: 0 0;
        background-size: auto 15px;
        background-repeat: no-repeat;
        pointer-events: none;
      }
      &:after {
        content: '';
        position: absolute;
        top: 3px;
        right: 0;
        width: 8px;
        height: 13px;
        background-image: url('../../../../assets/img/arrow_right_blue.svg');
        background-size: auto 12px;
        background-position: 0 0;
        background-repeat: no-repeat;
        pointer-events: none;
      }
    }
    > dd {
      padding-left: 22px;
      font-size: 32px;
      font-weight: 700;
      color: #1c91c8;
    }
    &.is-unread_messages {
      > dt:before {
        width: 17px;
        background-image: url('../../../../assets/img/icon_message.svg');
      }
    }
  }
}
</style>
