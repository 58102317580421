<template>
  <transition name="fade">
    <div v-if="$store.getters['page/isPageLoaded']">
      <div class="survey_block">
        <section class="menu">
          <div class="add_survey">
            <button
              class="btn btn-availability"
              :class="{ disabled: !authToken }"
              @click="openModalAddSurvey"
            >
              <img class="icon" src="@/assets/img/add_white.svg" alt="" />
              Googleフォームを新規登録する
            </button>
            <transition name="fade">
              <p v-if="isVisibleAlert" class="alert_message">
                Googleフォーム（スプレッドシート）を追加する場合は、Google連携が必要です。
              </p>
            </transition>
            <modal-add-survey
              v-if="isVisibleAddSurvey"
              ref="refModalAddSurvey"
              :is-view="isVisibleAddSurvey"
              :size="addSurveySize"
              :forced="forced"
              @surveyCreate="surveyCreate"
              @onClickCancel="isVisibleAddSurvey = false"
            />
          </div>
          <div class="survey_google">
            <button
              v-if="!authToken"
              class="google_login_btn"
              @click="googleLogin"
            />
            <p
              v-if="authToken"
              class="dis_connected_google"
              @click="isVisibleDisConnectGoogle = true"
            >
              Google連携を解除する
            </p>
            <modal-window
              :is-visible="isVisibleDisConnectGoogle"
              :is-loading="isLoading"
              title="Google連携を解除"
              message="Googleとの連携を解除します。"
              button-text="Google連携を解除"
              modal-type="danger"
              @click="disConnectGoogle"
              @close="isVisibleDisConnectGoogle = false"
            />
          </div>
        </section>
        <section class="list">
          <div class="survey_table">
            <list-view-survey
              :survey-list="surveyList"
              :is-token="authToken"
              class="survey_list"
              @openModalRenewSurvey="openModalRenewSurvey"
              @openModalDeleteSurvey="openModalDeleteSurvey"
            />
            <modal-renew-survey
              v-if="isVisibleRenewSurvey"
              ref="refModalRenewSurvey"
              :is-view="isVisibleRenewSurvey"
              :size="renewSurveySize"
              :renew-survey-data="renewSurveyData"
              :forced="forced"
              @surveyRenew="surveyRenew"
              @onClickCancel="isVisibleRenewSurvey = false"
            />
          </div>
        </section>
      </div>
      <modal-window
        :is-visible="isVisibleDeleteSurvey"
        :is-loading="isLoading"
        title="Googleフォームを削除"
        message="削除されたGoogleフォームは復元できません。このGoogleフォームを削除してよろしいですか？"
        button-text="Googleフォームを削除"
        modal-type="danger"
        :data="deleteSurveyData"
        @click="surveyDelete"
        @close="isVisibleDeleteSurvey = false"
      />
      <modal-survey-error-mail-list
        v-if="isVisibleErrorSurvey"
        :is-view="isVisibleErrorSurvey"
        :error-survey-list="errorSurveyList"
        @onClickCancel="isVisibleErrorSurvey = false"
        @onClickForceSubmit="onClickForceSubmit"
      />
    </div>
  </transition>
</template>

<script>
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import surveyService from '@/services/survey';
import ListViewSurvey from '@/components/page/configs/survey/components/ListViewSurvey';
import ModalAddSurvey from '@/components/page/configs/survey/components/ModalAddSurvey';
import ModalRenewSurvey from '@/components/page/configs/survey/components/ModalRenewSurvey';
import ModalSurveyErrorMailList from '@/components/page/configs/survey/components/ModalSurveyErrorMailList';

export default defineComponent({
  name: 'Survey',
  components: {
    ListViewSurvey,
    ModalAddSurvey,
    ModalRenewSurvey,
    ModalSurveyErrorMailList,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const refModalAddSurvey = ref(null);
    const refModalRenewSurvey = ref(null);
    const page = ref(0);
    const lastPage = ref(null);
    const surveyList = ref([]);
    const googleUrl = ref(null);
    const addSurveySize = ref(null);
    const renewSurveySize = ref(null);
    const renewSurveyData = ref(null);
    const deleteSurveyData = ref(null);
    const errorSurveyList = ref(null);
    const payloadCache = ref(null);
    const createNewFlag = ref(null);
    const forced = ref(false);
    const authCode = ref(null);
    const authToken = ref(false);
    const isLoading = ref(false);
    const isVisibleAlert = ref(false);
    const isVisibleAddSurvey = ref(false);
    const isVisibleRenewSurvey = ref(false);
    const isVisibleErrorSurvey = ref(false);
    const isVisibleDeleteSurvey = ref(false);
    const isVisibleDisConnectGoogle = ref(false);

    // watch
    watch(
      () => store.getters['graduateds/selectedGraduatedId'],
      () => fetchAll(),
    );

    // methods
    const fetchAll = async () => {
      const res = await surveyService.getGoogleLoginUrl();
      if (res.success === true) googleUrl.value = res.google_url;
      if (route.query.code) {
        // Google連携直後処理
        authCode.value = route.query.code;
        const resGoogleConnect = await surveyService.postGoogleConnect(
          authCode.value,
        );
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: resGoogleConnect.message,
          type: resGoogleConnect.success,
        });
        router.push('/configs/survey');
      }
      const resGoogleTokenCheck = await surveyService.getGoogleTokenCheck();
      authToken.value = resGoogleTokenCheck.success;
      const resSurveyList = await surveyService.getSurveyList(
        store.getters['graduateds/selectedGraduatedId'],
      );
      if (resSurveyList.success) surveyList.value = resSurveyList.sheet_data;
      await store.dispatch('page/SET_LOADED');
    };
    const disConnectGoogle = async payload => {
      if (payload.selected !== 'submit') {
        isVisibleDisConnectGoogle.value = false;
        return;
      }
      isLoading.value = true;
      const res = await surveyService.resetGoogle();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoading.value = false;
      isVisibleDisConnectGoogle.value = false;
      if (res.success) await fetchAll();
    };
    const surveyCreate = async (payload, tmpForced = false) => {
      if (payload.sheet_uid === null) return;
      payloadCache.value = payload;
      createNewFlag.value = true;
      const response = await surveyService.oauth2callback(
        store.getters['graduateds/selectedGraduatedId'],
        payload.sheet_uid,
        payload.sheet_gid,
        payload.sheet_name,
        tmpForced,
        onProgress,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: response.message,
        type: response.success,
      });
      forced.value = false;
      isVisibleAddSurvey.value = false;
      if (response.success === true) {
        payloadCache.value = null;
        createNewFlag.value = null;
        await fetchAll();
        refModalAddSurvey.value.surveyCreateResponse();
        return;
      }
      if (response.error_list) {
        errorSurveyList.value = response.error_list;
        isVisibleErrorSurvey.value = true;
      }
      isVisibleRenewSurvey.value = false;
      refModalAddSurvey.value.surveyCreateResponse();
    };
    const surveyRenew = async (payload, tmpForced = false) => {
      if (payload.sheet_uid === null) return;
      payloadCache.value = payload;
      createNewFlag.value = false;
      const response = await surveyService.oauth2callback(
        payload.graduated_id,
        payload.sheet_uid,
        payload.sheet_gid,
        payload.sheet_name,
        tmpForced,
        onProgress,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: response.message,
        type: response.success,
      });
      forced.value = false;
      isVisibleRenewSurvey.value = false;
      refModalRenewSurvey.value.surveyRenewResponse();
      if (response.success === true) {
        payloadCache.value = null;
        createNewFlag.value = null;
        await fetchAll();
        return;
      }
      if (response.error_list) {
        errorSurveyList.value = response.error_list;
        isVisibleErrorSurvey.value = true;
      }
      isVisibleAddSurvey.value = false;
    };
    const onClickForceSubmit = async () => {
      if (createNewFlag.value === null || payloadCache.value === null) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'もう一度初めからやり直してください',
          type: false,
        });
        payloadCache.value = null;
        createNewFlag.value = null;
        isVisibleErrorSurvey.value = false;
        return;
      }
      forced.value = true;
      isVisibleErrorSurvey.value = false;
      if (createNewFlag.value === true) {
        isVisibleAddSurvey.value = true;
        await surveyCreate(payloadCache.value, true);
      } else if (createNewFlag.value === false) {
        isVisibleRenewSurvey.value = true;
        await surveyRenew(payloadCache.value, true);
      }
      isVisibleErrorSurvey.value = false;
    };
    const surveyDelete = async payload => {
      if (payload.selected !== 'submit') {
        isVisibleDeleteSurvey.value = false;
        deleteSurveyData.value = null;
        return;
      }
      isLoading.value = true;
      const res = await surveyService.deleteSurveySheet(payload.data);
      if (res.success === true) {
        await fetchAll();
        isVisibleDeleteSurvey.value = false;
        deleteSurveyData.value = null;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoading.value = false;
    };
    const onProgress = val => {
      if (val) addSurveySize.value = val;
    };
    const openModalAddSurvey = () => {
      if (authToken.value) {
        isVisibleAddSurvey.value = true;
        return;
      }
      isVisibleAlert.value = true;
      setTimeout(() => (isVisibleAlert.value = false), 3000);
    };
    const openModalRenewSurvey = payload => {
      isVisibleRenewSurvey.value = true;
      renewSurveyData.value = payload;
    };
    const openModalDeleteSurvey = payload => {
      isVisibleDeleteSurvey.value = true;
      deleteSurveyData.value = payload;
    };
    const googleLogin = () => {
      location.href = googleUrl.value;
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      refModalRenewSurvey,
      refModalAddSurvey,
      page,
      lastPage,
      surveyList,
      googleUrl,
      addSurveySize,
      renewSurveySize,
      renewSurveyData,
      deleteSurveyData,
      errorSurveyList,
      payloadCache,
      createNewFlag,
      forced,
      authCode,
      authToken,
      isLoading,
      isVisibleAlert,
      isVisibleAddSurvey,
      isVisibleRenewSurvey,
      isVisibleErrorSurvey,
      isVisibleDeleteSurvey,
      isVisibleDisConnectGoogle,
      disConnectGoogle,
      openModalAddSurvey,
      surveyCreate,
      surveyRenew,
      surveyDelete,
      onClickForceSubmit,
      openModalRenewSurvey,
      openModalDeleteSurvey,
      googleLogin,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.survey_block {
  section {
    &.menu {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .add_survey {
        position: relative;
        .add_survey_btn {
          display: inline-block;
          padding: 0 1rem;
          height: 3.2rem;
          line-height: 30px;
          border-radius: 0.4rem;
          border-color: #027db7;
          background: #1899d6;
          text-align: center;
          font-size: 1.2rem;
          font-weight: bold;
          color: white;
          cursor: pointer;
          &.disabled {
            border-color: #ccc;
            background-color: #efefef;
            color: #333;
            font-weight: 100;
          }
          img {
            width: 1.7rem;
            height: auto;
            margin-right: 0.7rem;
          }
        }
        .alert_message {
          position: absolute;
          width: 50rem;
          margin-top: 10px;
          color: $mochica_color_red;
        }
      }
      .survey_google {
        margin-left: 20px;
        .dis_connected_google {
          color: #bf0000;
          cursor: pointer;
          font-size: 1.2rem;
          &:hover {
            color: #8c0000;
            text-decoration: underline;
          }
        }
        .google_login_btn {
          border: 0;
          cursor: pointer;
          width: 133px;
          height: 3.2rem;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url('../../../components/page/configs/survey/assets/img/btn_google_signin_dark_normal_web.png');
        }
        .google_login_btn:hover {
          background-image: url('../../../components/page/configs/survey/assets/img/btn_google_signin_dark_focus_web.png');
        }
        .google_connect_btn {
          border: 0;
          cursor: pointer;
          width: 191px;
          height: 46px;
          background-color: $mochica_color;
        }
        .google_connect_btn:hover {
          background-color: $mochica_color;
        }
      }
    }
    &.list {
      .title {
        height: 4rem;
        line-height: 4rem;
        background: #1699d6;
        margin-bottom: 2rem;
        p {
          color: #fff;
          font-weight: bold;
          padding-left: 2rem;
        }
      }
      .survey_table {
        overflow-x: auto;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .add_survey {
    .btn {
      width: 100%;
    }
  }
  .survey_block {
    section.menu {
      display: block;
      margin-bottom: 20px;
    }
    section.menu .survey_google {
      margin: 20px 0 0;
      text-align: center;
      .dis_connected_google {
        display: inline-block;
      }
    }
    section.list .survey_table {
      white-space: normal;
    }
  }
}
</style>
