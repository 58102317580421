<template>
  <div class="edit-text">
    <button-base
      v-if="staff.role.id !== 1"
      :rejected-role-ids="[2, 3]"
      :rejected-admin-ids="[2]"
      class="delete-button"
      button-text="スタッフを削除"
      button-type="danger"
      icon-file-name="delete_white"
      @click="isDeleteMode = true"
    />
    <modal-window
      class="delete-staff-modal"
      :is-visible="isDeleteMode"
      :is-loading="loading"
      title="スタッフを削除します"
      :message="`※この操作は取り消すことができません。\n\n名前：${staff.firstname} ${staff.lastname} \nメールアドレス：${staff.email}`"
      button-text="スタッフを削除"
      modal-type="danger"
      @click="onClickSettleDeleteStaff"
      @close="isDeleteMode = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'DeleteStaff',
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClickSettleDeleteStaff'],
  setup(props, context) {
    const isDeleteMode = ref(false);
    const onClickSettleDeleteStaff = payload => {
      if (payload.selected === 'submit') {
        context.emit('onClickSettleDeleteStaff', {
          staff_id: props.staff.id,
        });
      }
      isDeleteMode.value = false;
    };
    return { isDeleteMode, onClickSettleDeleteStaff };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.edit-text {
  display: flex;
  align-items: center;
  .delete-staff-modal {
    white-space: break-spaces;
  }
  .staffs-email_form_item {
    white-space: initial;
    input {
      width: 300px;
      height: 40px;
      padding: 0.6rem;
      border-radius: 0.4rem;
      border: 1px solid $border_color;
      font-size: 1.4rem;
      background: #fff;
    }
    span {
      &.fa-warning {
        color: #c31e1e;
        padding-top: 4px;
        font-size: 1.2rem;
      }
      &.fa {
        display: block;
      }
    }
  }
}
.ui-select {
  height: 30px;
  border: 1px solid #e2e2e2;
}
.delete-button {
  @media (max-width: ($media_query_sp)) {
    width: 100%;
    font-size: 1rem;
  }
}
</style>
