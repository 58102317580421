<template>
  <div v-if="applicant.tagGroups.length" class="falg-groups">
    <div class="basic-title">フラググループ</div>
    <section class="as-block">
      <div class="inner">
        <table class="table-flag">
          <thead>
            <tr>
              <th>フラググループ名</th>
              <th>フラグ名</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in applicant.tagGroups" :key="group.id">
              <td class="flag-group">{{ group.name }}</td>
              <td class="flag-name">
                <div class="pos-relative">
                  <div v-if="group.tags.length > 0">
                    <ul class="list-flag_name">
                      <li v-for="tag in group.tags" :key="tag.id">
                        <span class="flag">{{ tag.name }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onUnmounted } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'FlagGroupPreview',
  setup(props, context) {
    const store = useStore();
    const applicant = computed(() => {
      return store.getters['applicant/applicant'];
    });

    // lifecycle
    onBeforeMount(() => {
      store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
    });
    onUnmounted(() => {
      store.dispatch('company/CLEAR_COMPANY_TAG_GROUPS');
    });

    return { applicant };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.as-block {
  margin-bottom: 20px;
  background-color: #fff;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  .inner {
    padding: 0px;
  }
}

.list-flag_name {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-bottom: 15px;
  }
  li:not(:last-child) {
    margin-right: 10px;
  }
}

.flagNoneSet {
  margin-bottom: 15px;
}

.table-flag {
  width: 100%;
  margin-bottom: 20px;
  font-size: 1.3rem;
  thead {
    background-color: #e2e2e2;
    th {
      padding: 15px;
      text-align: left;
      &:nth-child(1) {
        width: 200px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e2e2e2;
      td {
        word-break: break-all;
      }
    }
  }
  .flag-group {
    padding: 15px;
  }
  .flag-name {
    padding: 20px 0 0;
  }
}
.basic-title {
  text-align: center;
  background-color: $mochica_color;
  color: #fff;
  padding: 14px;
  font-size: 1.3rem;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  font-weight: bold;
}
@media (max-width: ($media_query_tablet)) {
  .table-flag {
    thead {
      th {
        &:nth-child(1) {
          width: 280px;
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .table-flag {
    thead {
      th {
        font-size: 1.1rem;
      }
    }
  }
  .flag-name .pos-relative .tag-context-menu.basic-box-shadow {
    top: initial;
    left: -80px;
  }
}
</style>
