<template>
  <div class="selection-info_header">
    <template v-if="isEdit !== true">
      <div class="select-date-area">
        <div class="select-title">
          <input
            id="send_reservation"
            v-model="sendType"
            type="radio"
            value="send_reservation"
            @change="$emit('updateSendType', sendType)"
          />
          <label for="send_reservation">
            予約送信する（7日後まで設定することができます）
          </label>
        </div>
      </div>
      <div v-show="!isSendNow" class="timeSetting">
        <div class="select-date-area_column date-column">
          <el-date-picker
            v-show="!isSendNow"
            id="el-input__inner"
            :model-value="selectedDate"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder="選択して下さい"
            @update:model-value="selectedDate = $event"
            @focus="isVisibleDate = true"
            @blur="isVisibleDate = false"
            @change="$emit('updateScheduleTime', scheduleTime)"
          />
        </div>
        <div class="select-date-area_column">
          <select
            v-show="!isSendNow"
            id="selectedate-h"
            v-model="selectedHour"
            class="select-h"
            name="selectedate-h"
            @change="$emit('updateScheduleTime', scheduleTime)"
          >
            <option v-for="(n, index) of 24" :key="`hour_${n}`" :value="index">
              {{ $utils.zeroPadding(index) }}
            </option>
          </select>
          <p v-show="isSendNow" class="disabled-date">
            {{ selectedHour }}
          </p>
          <span>時</span>
        </div>
        <div class="select-date-area_column">
          <select
            v-show="!isSendNow"
            id="selectedate-m"
            v-model="selectedMin"
            class="select-m"
            name="selectedate-m"
            @change="$emit('updateScheduleTime', scheduleTime)"
          >
            <option
              v-for="(n, index) of 6"
              :key="`min_${index}`"
              :value="`${index}0`"
            >
              {{ `${index}0` }}
            </option>
          </select>
          <span>分</span>
        </div>
      </div>
      <div v-show="!isSendNow" class="error-message-1">
        <p v-show="isSelectedDateError" class="has-error">
          現在時刻以降、7日以内で日付を選択して下さい
        </p>
        <p
          v-show="!selectedDate || selectedDate.length === 0"
          class="has-error"
        >
          予約送信日時は必須項目です
        </p>
      </div>
      <div class="select-date-area nowSend">
        <input
          id="send_now"
          v-model="sendType"
          type="radio"
          name="q1"
          value="send_now"
          @change="$emit('updateSendType', sendType)"
        />
        <label for="send_now">すぐに送信する</label>
      </div>
    </template>
    <template v-else>
      <div class="select-date-area-edit">
        <div class="select-title">配信</div>
        <div class="select-date-area_column">
          <select
            v-model="selectedIsActive"
            class="select-is-active"
            @change="$emit('updateIsActive', selectedIsActive)"
          >
            <option :value="false">しない</option>
            <option :value="true">する</option>
          </select>
        </div>
      </div>
      <div class="select-date-area-edit">
        <div class="select-title has-margin_top">予約送信日時</div>
        <div class="select-date-area_column -date">
          <el-date-picker
            id="el-input__inner"
            :model-value="selectedDate"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder="選択して下さい"
            @update:model-value="selectedDate = $event"
            @focus="isVisibleDate = true"
            @blur="isVisibleDate = false"
            @change="$emit('updateScheduleTime', scheduleTime)"
          />
        </div>
        <div class="select-date-area_column is-vertical_align_top">
          <select
            id="selectedate-h"
            v-model="selectedHour"
            class="select-h"
            name="selectedate-h"
            @change="$emit('updateScheduleTime', scheduleTime)"
          >
            <option v-for="(n, index) of 24" :key="n" :value="index">
              {{ $utils.zeroPadding(index) }}
            </option>
          </select>
          <p v-show="isSendNow" class="disabled-date">
            {{ selectedHour }}
          </p>
          <span>時</span>
        </div>
        <div class="select-date-area_column is-vertical_align_top">
          <select
            id="selectedate-m"
            v-model="selectedMin"
            class="select-m"
            name="selectedate-m"
            @change="$emit('updateScheduleTime', scheduleTime)"
          >
            <option
              v-for="(n, index) of 6"
              :key="`min_${index}`"
              :value="`${index}0`"
            >
              {{ `${index}0` }}
            </option>
          </select>
          <span>分</span>
        </div>
      </div>
      <div class="error-message-2">
        <p v-show="isSelectedDateError" class="has-error">
          現在時刻以降、7日以内で日付を選択して下さい
        </p>
        <p
          v-show="!selectedDate || selectedDate.length === 0"
          class="has-error"
        >
          予約送信日時は必須項目です
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default defineComponent({
  name: 'SelectScheduleTime',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    initialScheduleTime: {
      type: String,
      default: null,
    },
    initialIsActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'updateScheduleTime',
    'updateSendType',
    'updateIsActive',
    'updateIsSendNow',
  ],
  setup(props, context) {
    const store = useStore();

    /**
     * 10分単位の日時を生成
     * @returns {object} Moment Object
     */
    const roundAndTenMinLaterTime = () => {
      const currentMin = moment().format('mm');
      if (currentMin >= 55) return moment().add(1, 'hours').set('minutes', 10);
      if (50 <= currentMin && currentMin <= 54)
        return moment().add(1, 'hours').set('minutes', 0);
      const roundedMin =
        Math.round(moment().add(10, 'minutes').format('mm') / 10) * 10;
      if (roundedMin === 60) return moment().add(1, 'hours').set('minutes', 0);
      return moment().set('minutes', roundedMin);
    };

    /**
     * 日selectの文字列
     */
    const selectedDate = ref(
      props.initialScheduleTime !== null
        ? moment(props.initialScheduleTime).format('YYYY-MM-DD')
        : roundAndTenMinLaterTime().format('YYYY-MM-DD'),
    );
    /**
     * 時間selectの文字列
     */
    const selectedHour = ref(
      props.initialScheduleTime !== null
        ? moment(props.initialScheduleTime).format('H')
        : roundAndTenMinLaterTime().format('H'),
    );
    /**
     * 分selectの文字列
     */
    const selectedMin = ref(
      props.initialScheduleTime !== null
        ? moment(props.initialScheduleTime).format('mm')
        : roundAndTenMinLaterTime().format('mm'),
    );
    /**
     * 予約送信・すぐに送信の選択タイプ ('send_reservation' | 'send_now')
     */
    const sendType = ref('send_reservation');
    const isVisibleDate = ref(false);
    /**
     * 連絡事項編集時の送信フラグ
     */

    const selectedIsActive = ref(props.initialIsActive);

    /**
     * 予約送信日時の整形した文字列
     */
    const scheduleTime = computed(() => {
      const date = moment(
        `${selectedDate.value} ${selectedHour.value}:${selectedMin.value}:00`,
      );
      return date.format('YYYY-MM-DD HH:mm:ss');
    });
    /**
     * すぐに送信する選択フラグ
     */
    const isSendNow = computed(() => {
      return sendType.value === 'send_now';
    });
    /**
     * 予約送信日時エラーフラグ
     */
    const isSelectedDateError = computed(() => {
      const after7day = moment().add(7, 'days');
      if (moment(scheduleTime.value).isAfter(after7day)) return true;
      const before15min = moment().add(10, 'min');
      if (moment(scheduleTime.value).isBefore(before15min)) return true;
      return false;
    });

    // methods
    /**
     * 予約送信日時バリデーション
     * @returns {boolean}
     */
    const validateSendScheduleTime = () => {
      if (
        !selectedDate.value ||
        selectedDate.value.length === 0 ||
        !selectedHour.value ||
        selectedHour.value.length === 0 ||
        !selectedMin.value ||
        selectedMin.value.length === 0
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '予約送信日時を入力してください',
          type: false,
        });
        return false;
      }
      const nowAfter5min = moment().add(5, 'minutes');
      const nowAfter7day = moment().add(7, 'days');
      if (
        moment(scheduleTime.value).isBefore(moment(), 'day') ||
        moment(scheduleTime.value).isAfter(nowAfter7day, 'day')
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '予約送信日時は、本日より7日以内の日付で設定して下さい',
          type: false,
        });
        return false;
      }
      if (moment(scheduleTime.value).isSameOrBefore(nowAfter5min)) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '予約送信日時は、送信時刻より5分以上空けて設定して下さい',
          type: false,
        });
        return false;
      }
      if (isSelectedDateError.value === true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '予約送信日時に問題があります',
          type: false,
        });
      }
      return true;
    };

    // 初期値を親コンポーネントに渡す
    context.emit('updateScheduleTime', scheduleTime.value);
    context.emit('updateSendType', sendType.value);
    context.emit('updateIsActive', selectedIsActive.value);

    return {
      selectedDate,
      selectedHour,
      selectedMin,
      sendType,
      selectedIsActive,
      isVisibleDate,
      // computed
      scheduleTime,
      isSendNow,
      isSelectedDateError,
      // methods
      validateSendScheduleTime,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';

.selection-info_header {
  padding: 0 20px;
  .select-date-area {
    display: flex;
    align-items: center;
    .select-title {
      display: flex;
    }
    label[for='send_now'],
    label[for='send_reservation'] {
      padding-left: 10px;
      cursor: pointer;
    }
  }
  .select-date-area_column {
    margin-right: 10px;
    .error-area {
      margin-top: 5px;
    }
  }
  .timeSetting {
    display: flex;
    margin: 10px 0 20px 25px;

    :deep(.el-input__inner) {
      height: 35px;
    }
    .select-h {
      border-radius: 2px;
      padding: 0.7rem;
      margin-right: 0.3rem;
      font-size: 1.2rem;
      transition: 0.3s ease;
      border-radius: 0.3rem;
      height: 35px;
    }
    .select-m {
      border-radius: 2px;
      padding: 0.7rem;
      margin-right: 0.3rem;
      font-size: 1.2rem;
      transition: 0.3s ease;
      border-radius: 0.3rem;
      height: 35px;
    }
    .value-input {
      border-radius: 0.3rem;
      padding: 0.8rem;
      font-size: 1.2rem;
      transition: 0.3s ease;
      margin-right: 1rem;
    }
  }
  .nowSend {
    margin-top: 20px;
  }
  .has-error {
    margin-top: -10px;
    color: $mochica_color_red;
  }
}

.select-date-area-edit {
  display: flex;
  align-items: center;

  + .select-date-area-edit {
    margin-top: 20px;
  }
  & :deep(.el-input__inner) {
    height: 35px;
  }
  .select-is-active {
    border-radius: 2px;
    padding: 0.8rem;
    font-size: 1.2rem;
    transition: 0.3s ease;
    border-radius: 0.3rem;
    height: 35px;
  }
  .select-h {
    border-radius: 2px;
    padding: 0.8rem;
    font-size: 1.2rem;
    transition: 0.3s ease;
    border-radius: 0.3rem;
    height: 35px;
  }
  .select-m {
    border-radius: 2px;
    padding: 0.8rem;
    font-size: 1.2rem;
    transition: 0.3s ease;
    border-radius: 0.3rem;
    height: 35px;
  }
  .value-input {
    border-radius: 0.3rem;
    padding: 0.8rem;
    font-size: 1.2rem;
    transition: 0.3s ease;
    margin-right: 1rem;
  }
  .select-title {
    width: 100px;
    &.has-margin_top {
      margin-top: 9px;
      margin-bottom: auto;
    }
  }
  &_column {
    margin-right: 10px;
  }
}

.error-message-1 {
  padding-left: 25px;
}

.error-message-2 {
  padding: 15px 0 0 100px;
}

:deep(.el-input) {
  min-width: 200px;
}
</style>
