<template>
  <main class="issue_result">
    <div class="regist_success">
      パスワードの再設定が完了いたしました。
      <br />
      ご利用ありがとうございました。
    </div>
    <button
      class="to_login_btn"
      type="button"
      @click.prevent="$router.replace('/login')"
    >
      ログイン画面へ戻る
    </button>
  </main>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IssueResult',
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include form_wrapper_css();

/* 元のの記述 */
.regist_success {
  text-align: center;
}
.issue_result {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.to_login_btn {
  margin-top: 20px;
  width: 100%;
  border: none;
  background-color: $mochica_color;
  border: 1px solid $mochica_border_color;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  border-radius: 0.3rem;
  cursor: pointer;
}
</style>
