import cloneDeep from 'lodash.clonedeep';
import { computed, ref } from 'vue';
import SEARCH_CONSTS from '@/components/features/searchApplicants/defines/search';

export default function useSearchFlagGroups(
  store,
  flagGroupsDefault = [],
  closeAllDropDown = () => {},
  maxFlagGroupsCount = SEARCH_CONSTS.MAX_FLAG_GROUPS_COUNT,
) {
  /**
   * searchFlagGroups
   * [{ id: ?Number, flags: [Number] }]
   */
  const searchFlagGroups = ref(cloneDeep(flagGroupsDefault));
  const visibleFlagGroupIds = ref(searchFlagGroups.value.map(() => false));
  const visibleFlagIds = ref([]);

  // computed
  const visibleAddFlags = computed(
    () => searchFlagGroups.value.length < maxFlagGroupsCount,
  );
  const companyTagGroups = computed(() => {
    return store.getters['company/companyTagGroups'];
  });
  const getFlagGroups = computed(() => {
    const flagGroupIds = searchFlagGroups.value.map(g => g.id);
    return companyTagGroups.value.map(flagGroup => {
      const list = Object.assign({}, flagGroup, {
        name: flagGroup.name,
        disabled: flagGroupIds.filter(id => id === flagGroup.id).length > 0,
      });
      return list;
    });
  });
  const hasError = computed(() => {
    return searchFlagGroups.value.some(flagGroup => {
      // フラググループが存在しない場合はエラー
      if (hasErrorFlagGroup(flagGroup.id)) return true;
      // フラグが存在しない場合はエラー
      return flagGroup.flags && hasErrorFlag(flagGroup.id, flagGroup.flags);
    });
  });

  // methods
  /**
   * searchFlagGroupsのリセット
   * @param {Function} resetSearchFlagGroups
   */
  const resetSearchFlagGroups = (resetFlagGroupOptions = () => {}) => {
    searchFlagGroups.value = [];
    visibleFlagGroupIds.value = [];
    visibleFlagIds.value = [];
    resetFlagGroupOptions();
  };
  /**
   * 選択可能なFlagリストを取得
   * @param {Number} flagGroupId
   * @param {Number|null} index
   * @param {Number|null} flags
   * @return {[{ id: Number, selected: Boolean, }]} flags
   */
  const getFlags = (flagGroupId, index, flags = null) => {
    const flagGroup = companyTagGroups.value.find(g => g.id === flagGroupId);
    if (!flagGroup) return [];
    return flagGroup.tags.map(flag => {
      const targetFlags =
        index !== null ? searchFlagGroups.value[index].flags : flags;
      return {
        ...flag,
        selected:
          flag.id === targetFlags.find(id => id === flag.id) ? true : false,
      };
    });
  };
  /**
   * Flag名を取得
   * @param {Number} flagId
   * @param {Number} flagGroupId
   * @return {String} item
   */
  const getFlagNameById = (flagId, flagGroupId) => {
    const companyFlagGroup = companyTagGroups.value.find(
      flagGroup => flagGroup.id === flagGroupId,
    );
    const item =
      companyFlagGroup && companyFlagGroup.tags
        ? companyFlagGroup.tags.find(flag => flag.id === flagId)
        : [];
    return item ? item.name : '';
  };
  /**
   * FlagGroupを選択
   * @param {{ item: { id: Number } }} payload
   * @param {Number} i
   * @param {Function} nextTick
   */
  const updateFlagGroupId = (payload, i, nextTick) => {
    searchFlagGroups.value = searchFlagGroups.value.map((x, tmpIndex) =>
      tmpIndex === i ? { id: payload.item.id, flags: [] } : x,
    );
    // 選択後にはdropdownを閉じる
    nextTick(() => closeAllFlagGroupsDropDown());
  };
  /**
   * Flagを選択
   * @param {{ item: { id: Number } }} payload
   * @param {Number} i
   */
  const updateFlagId = (payload, i) => {
    const targetIndex = searchFlagGroups.value[i].flags.findIndex(
      id => id === payload.item.id,
    );
    searchFlagGroups.value = searchFlagGroups.value.map(
      (searchFlagGroup, index) => {
        if (i === index) {
          if (targetIndex >= 0) {
            // フラグを選択解除
            searchFlagGroup.flags.splice(targetIndex, 1);
          } else {
            // フラグを選択
            searchFlagGroup.flags.push(payload.item.id);
          }
          searchFlagGroup.flags.sort();
        }
        return searchFlagGroup;
      },
    );
  };
  /**
   * FlagGroupを追加
   * @param {Function} addFlagGroupOption
   */
  const addFlagGroupRow = (addFlagGroupOption = () => {}) => {
    closeAllFlagGroupsDropDown();
    if (
      visibleAddFlags.value &&
      getFlagGroups.value.filter(tg => tg.disabled === false).length > 0 &&
      searchFlagGroups.value.filter(tg => tg.id === null).length === 0
    ) {
      searchFlagGroups.value.push({ id: null, flags: [] });
      addFlagGroupOption();
    }
  };
  /**
   * flagGroupを削除
   * @param {Number} i
   * @param {Function} removeFlagGroupOption
   */
  const removeFlagGroupRow = (i, removeFlagGroupOption = () => {}) => {
    closeAllFlagGroupsDropDown();
    searchFlagGroups.value.splice(i, 1);
    removeFlagGroupOption(i);
  };
  /**
   * FlagGroupのDropDownを開く
   * @param {Number} i
   */
  const openDropDownFlagGroupIds = i => {
    closeAllFlagGroupsDropDown();
    visibleFlagGroupIds.value[i] = true;
  };
  /**
   * FlagGroupのDropDownを閉じる
   * @param {Number} i
   */
  const openDropDownFlagId = i => {
    closeAllFlagGroupsDropDown();
    visibleFlagIds.value[i] = true;
  };
  /**
   * flagGroup/flagのDropDownを全て閉じる
   */
  const closeAllFlagGroupsDropDown = () => {
    visibleFlagGroupIds.value = visibleFlagGroupIds.value.map(x => false);
    visibleFlagIds.value = visibleFlagIds.value.map(x => false);
    closeAllDropDown();
  };
  /**
   * プリセットを読み込む
   * @param {Object[]} newSearchFlagGroups
   */
  const loadFlagGroupsPreset = newSearchFlagGroups => {
    searchFlagGroups.value = cloneDeep(newSearchFlagGroups);
    visibleFlagGroupIds.value = searchFlagGroups.value.map(x => false);
    visibleFlagIds.value = searchFlagGroups.value.map(x => false);
  };
  /**
   * FlagGroupのIDが存在するかチェック
   * @return {Boolean} hasError
   */
  const hasErrorFlagGroup = flagGroupId => {
    // フラググループ未選択はエラーではない
    if (!flagGroupId) return false;
    // フラググループIDが存在しない場合はエラー
    return getFlagGroups.value.find(v => v.id === flagGroupId) === undefined;
  };
  /**
   * FlagのIDが存在するかチェック
   * @return {Boolean} hasError
   */
  const hasErrorFlag = (flagGroupId, flagGroupFlags) => {
    // フラググIDが存在しない場合はエラー
    return flagGroupFlags.some(
      flagId =>
        !getFlagNameById(flagId, flagGroupId) ||
        getFlagNameById(flagId, flagGroupId).length === 0,
    );
  };

  return {
    searchFlagGroups,
    visibleAddFlags,
    visibleFlagGroupIds,
    visibleFlagIds,
    getFlagGroups,
    hasError,
    resetSearchFlagGroups,
    getFlags,
    getFlagNameById,
    updateFlagGroupId,
    updateFlagId,
    addFlagGroupRow,
    removeFlagGroupRow,
    openDropDownFlagGroupIds,
    openDropDownFlagId,
    closeAllFlagGroupsDropDown,
    loadFlagGroupsPreset,
    hasErrorFlagGroup,
    hasErrorFlag,
  };
}
