import { onBeforeRouteLeave } from 'vue-router';

export default async function useBeforeRouteLeave(
  openModal,
  skipModalCondition,
) {
  onBeforeRouteLeave(async (to, from, next) => {
    if (skipModalCondition(to)) {
      next();
      return;
    }
    const confirmed = await openModal();
    if (confirmed) {
      next();
    } else {
      next(false);
    }
  });
  return {};
}
