import {
  getInitSearchBody,
  getInitSearchFlagForRequest,
} from '@/models/applicant-search';

export default {
  namespaced: true,
  state: {
    searchFilter: {
      body: getInitSearchBody(),
      sort: {},
      searchFlags: getInitSearchFlagForRequest(),
      init: true,
    },
  },
  getters: {
    searchFilter: state => {
      return state.searchFilter;
    },
  },
  mutations: {
    SET_SEARCH_FILTER(state, searchFilter) {
      state.searchFilter = searchFilter;
    },
    START_SEARCH() {
      // componentsで監視するトリガーのため中身は空
    },
  },
  actions: {
    async SET_SEARCH_FILTER({ commit }, searchFilter) {
      commit('SET_SEARCH_FILTER', searchFilter);
    },
    RESET_SEARCH_FILTER({ commit }) {
      commit('SET_SEARCH_FILTER', {
        body: getInitSearchBody(),
        sort: {},
        searchFlags: getInitSearchFlagForRequest(),
      });
    },
    START_SEARCH({ commit }) {
      commit('START_SEARCH');
    },
  },
};
