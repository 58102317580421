<template>
  <modal :is-view="isVisible">
    <template #rightTop>
      <div class="right-top" @click="$emit('onClickCancel')">
        <img class="icon" src="@/assets/img/close_black.svg" />
      </div>
    </template>
    <template #content>
      <div class="content">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                <span>応募者へ通知</span>
                <img
                  class="breadcrumb"
                  src="@/assets/img/arrow_right_blue.svg"
                  alt
                />
                <span>アンケートを応募者に送信する</span>
                <img
                  class="breadcrumb"
                  src="@/assets/img/arrow_right_blue.svg"
                  alt
                />
                <span>応募者選択</span>
              </div>
            </div>

            <div class="modal-header_cancell" @click="$emit('onClickCancel')">
              <img class="icon" src="@/assets/img/close_black.svg" />
            </div>
          </section>

          <section class="modal-body">
            <div class="sec-search-condition">
              <modal-selectable-applicants
                :disabled-applicant-ids="flagSurveyApplicantIds"
                :send-limit="sendLimit"
                @onChangeCurrentSelect="onChangeCurrentSelect"
              />
            </div>
          </section>

          <div class="btn-col2">
            <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
              <img
                class="icon-leftArrow"
                src="@/assets/img/arrow_left_white.svg"
              />
              キャンセル
            </div>
            <div class="btn btn-availability" @click="onClickOpenDialog">
              選択した応募者にアンケートを送信する
              <img
                src="@/assets/img/arrow_right_white.svg"
                class="icon-rightArrow"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { postPottosEvent } from '@/utils/pottos';

import flagsurveyService from '@/services/flag-survey';
import Modal from '@/components/ui/modals/components/Modal';
import ModalSelectableApplicants from '@/components/features/modalSelectableApplicants/components/ModalSelectableApplicants';

export default defineComponent({
  name: 'FlagSurveyDestination',
  components: { Modal, ModalSelectableApplicants },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    flagSurveyId: {
      type: Number,
      default: null,
    },
    sendLimit: {
      type: Number,
      default: 100,
    },
  },
  emits: ['onClickCancel', 'onChangeAppSelect', 'onClickSettle'],
  setup(props, context) {
    const store = useStore();
    const flagSurveyApplicantIds = ref([]);
    const selectedApplicants = ref([]);

    // watch
    watch(
      () => props.flagSurveyId,
      async flagSurveyId => {
        if (!flagSurveyId) return;
        const res = await flagsurveyService.getFlagSurvey({
          flagsurvey_id: flagSurveyId,
        });
        flagSurveyApplicantIds.value = res.applicant_ids;
      },
    );

    // methods
    const onChangeCurrentSelect = payload => {
      selectedApplicants.value = payload.selectedApplicantIds.map(id => id);
      context.emit('onChangeAppSelect', payload.selectedApplicantIds);
    };
    const onClickOpenDialog = async () => {
      if (!selectedApplicants.value.length) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者を選択してください',
          type: false,
        });
        return;
      }
      if (selectedApplicants.value.length > props.sendLimit) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `送信する人数は${props.sendLimit}名以内に設定してください`,
          type: false,
        });
        return;
      }
      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: 'アンケートの送信',
        message: '応募者にアンケートを送信しますか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        isDisabledShowModalDisplayed: true,
        onSelected: payload => {
          if (payload.choice === 2) {
            postPottosEvent(31);
            context.emit('onClickSettle', props.flagSurveyId);
          }
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        onclickOuter: () => store.dispatch('ui/modalDialog/INVISIBLE_DIALOG'),
      });
    };

    return {
      flagSurveyApplicantIds,
      onChangeCurrentSelect,
      onClickOpenDialog,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
}
.modal-body {
  text-align: start;
}
img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}
.sec-search-condition {
  font-size: 1.2rem;
  background: #fff;
  padding: 2rem;
  .balloon_btn {
    display: flex;
    width: 19rem;
    height: 3rem;
    position: relative;
    padding: 0 1rem 0.3rem 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid #9d9d9d;
    border-radius: 0.3rem;
    .balloon_btn_title {
      display: flex;
      font-size: 1.1rem;
      padding: 0 1.4rem 0 0.1rem;
      align-items: center;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 11px;
        width: 6px;
        height: 6px;
        margin-top: -6px;
        border: 0px;
        border-bottom: solid 2px #178fd0;
        border-right: solid 2px #178fd0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .balloon_btn:last-child {
    margin-bottom: 0;
  }
  .applicant_column_block {
    margin-right: 20px;
  }
  .search_count_block {
    margin-right: 20px;
    .btn {
      font-size: 1.2rem;
      padding-right: 2.4rem;
      display: flex;
      align-items: center;
      &::after {
        content: '';
        width: 6px;
        height: 6px;
        border: 0px;
        border-bottom: solid 2px #178fd0;
        border-right: solid 2px #178fd0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -4px;
      }
    }
  }
  .search_taggroups_block {
    .btn {
      font-size: 1.2rem;
      padding-right: 2.4rem;
      display: flex;
      align-items: center;
      &::after {
        content: '';
        width: 6px;
        height: 6px;
        border: 0px;
        border-bottom: solid 2px #178fd0;
        border-right: solid 2px #178fd0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -4px;
      }
    }
  }
  .row-2 {
    margin-bottom: 1rem;
    .selection_menu {
      position: relative;
      display: flex;
      margin-right: 1rem;
      .balloon_content {
        margin: 0.4rem;
        .balloon_btn {
          cursor: pointer;
        }
      }
    }
    .search_btn_title {
      height: 3.6rem;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      letter-spacing: 0.03em;
      padding: 0 2rem;
      &-svg {
        fill: #fff;
        width: 2.2rem;
        margin-right: 0.4rem;
      }
    }
  }
  .table-scrollable {
    text-align: left;
    thead {
      tr {
        th {
          table-layout: fixed;
          height: 3.6rem;
          padding-left: 1rem;
        }
      }
    }
    tbody {
      overflow-y: auto;
      height: 400px;
      tr {
        td {
          table-layout: fixed;
          height: 3.6rem;
          padding-left: 1rem;
        }
      }
    }
  }
}
.btn-col2 {
  width: 90%;
  margin: 20px auto;
}
</style>
