<template>
  <div class="area-button area-manager-body-btn" :class="{ edit: isEditing }">
    <p v-if="isEditing" class="description">
      人事の方は評価を入力した後、この選考の結果を選択してください。
    </p>
    <div
      class="btn_block"
      :class="[isSelectedBtn === true ? 'selected_btn' : '']"
    >
      <div
        class="btn-passed btn"
        :class="[progressStateName === 'passed' ? 'selected' : '']"
        @click="onclickProgressState('passed')"
      >
        通過
      </div>
      <div
        class="btn-offered btn"
        :class="[progressStateName === 'offered' ? 'selected' : '']"
        @click="onclickProgressState('offered')"
      >
        内定
      </div>
      <div
        class="btn-canceled btn"
        :class="[progressStateName === 'canceled' ? 'selected' : '']"
        @click="onclickProgressState('canceled')"
      >
        辞退
      </div>
      <div
        class="btn-dropped btn"
        :class="[progressStateName === 'dropped' ? 'selected' : '']"
        @click="onclickProgressState('dropped')"
      >
        不採用
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'ButtonProgressStatus',
  props: {
    progressStateName: {
      type: String,
      default: '',
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onChangeProgressState'],
  setup(props, context) {
    const isSelectedBtn = ref(false);
    const onclickProgressState = stateName => {
      isSelectedBtn.value = true;
      context.emit('onChangeProgressState', { progressStateName: stateName });
    };
    onMounted(() => {
      onclickProgressState();
    });
    return {
      isSelectedBtn,
      onclickProgressState,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects();

.area-manager-body-btn {
  background-color: #f4f4f4;
  padding: 1.2rem;
  position: relative;
  .description {
    margin-bottom: 1rem;
  }
  .btn_block {
    display: flex;
    justify-content: space-around;
    .btn {
      opacity: 0.1;
      @include flex_center_center();
      height: 3rem;
      border: 1px solid #e3e3e3;
      width: 24.5%;
      font-size: 1.2rem;
      border-radius: 0.6rem;
      color: #fff;
      transition: all ease 0.3s;
      cursor: auto;
    }
  }
}

.edit {
  .btn_block {
    .btn {
      cursor: pointer;
      opacity: 1;
    }
    .btn:hover {
      opacity: 0.6;
    }
  }
}
.selected_btn {
  .btn {
    opacity: 0.1 !important;
  }
  .selected {
    opacity: 1 !important;
  }
}

@media (max-width: ($media_query_tablet)) {
  .area-manager-body-btn {
    padding: 20px;
    .btn_block {
      flex-wrap: wrap;
      .btn {
        width: 48.5%;
        margin-bottom: 0;
      }
      .btn:nth-last-child(n + 3) {
        margin-bottom: 1.5%;
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .area-manager-body-btn {
    .btn_block {
      display: block;
      .btn {
        width: 100%;
        margin-bottom: 5px !important;
      }
      .btn:nth-last-child(1) {
        margin-bottom: 0 !important;
      }
    }
  }
}

.btn-passed {
  background-color: $passed_bg;
  color: #fff;
  font-weight: bold;
}
.btn-canceled {
  background-color: $canceled_bg;
  color: #fff;
  font-weight: bold;
}
.btn-dropped {
  background-color: $dropped_bg;
  color: #fff;
  font-weight: bold;
}
.btn-offered {
  background-color: $offered_bg;
  color: #fff;
  font-weight: bold;
}
.btn-offer_canceled {
  background-color: $offer_canceled_bg;
  color: #fff;
  font-weight: bold;
}
.btn-hired {
  background-color: $hired_bg;
  color: #fff;
  font-weight: bold;
}
</style>
