<template>
  <div>
    <!-- <div style="line-height: 1.2">
      <p>---------- DEBUG ----------</p>
      <p>
        page:
        {{ page }}
        /
        {{ pageCount }} ({{ pageLoad }}件)
        <br />
      </p>
      <br />
    </div> -->
    <div class="row-2">
      <div class="applicants_menu">
        <button-base
          class="btn btn-applicants-search"
          button-text="絞り込み検索"
          icon-file-name="search_white"
          @click="openFloatApplicantsForm"
        />
        <template v-if="isOpenSearchApplicantFilters">
          <search-applicants-wrapper
            ref="refSearchApplicantsWrapper"
            :search-form-is-visible="searchFormIsVisible"
            :search-form-detail-is-visible="searchFormDetailIsVisible"
            :is-flag-mode="isFlagMode"
            :staffs="staffs"
            :events="events"
            :tag-groups="tagGroups"
            :survey-sheet-id="surveySheetId"
            :survey-titles="surveyTitles"
            :is-activated="isActivated"
            :search="search"
            :change-flag-mode="changeFlagMode"
            @searchFormDetailSwitch="searchFormDetailSwitch"
            @updateSearchFormIsVisible="updateSearchFormIsVisible"
            @updateSearchFormDetailIsVisible="updateSearchFormDetailIsVisible"
            @updateSurveySheetId="updateSurveySheetId"
            @updateSurveyTitles="updateSurveyTitles"
            @updateIsShowSelectableApplicantTable="
              updateIsShowSelectableApplicantTable
            "
            @onSearch="onSearchLocal"
            @onSearchReset="onSearchResetLocal"
          />
        </template>
      </div>

      <div class="applicant_count">
        <div class="button_regist" @click="updateIsOpenPagerLoad(true)">
          表示する応募者の件数：{{ pageLoad }}件
          <img
            slot="arrow"
            class="arrow"
            src="@/assets/img/arrow_down_white.svg"
          />
        </div>
        <float-context-menu
          :is-view="isOpenPagerLoad"
          :list="loadSelection"
          @onClose="updateIsOpenPagerLoad(false)"
          @onSelected="onSelectedPageLoad"
        />
      </div>

      <div class="applicant_survey">
        <div class="button_regist" @click="updateIsOpenSurveyMenu(true)">
          <span slot="text">{{ surveySetting }}</span>
          <img
            slot="arrow"
            class="arrow"
            src="@/assets/img/arrow_down_white.svg"
          />
        </div>
        <float-context-menu
          :is-view="isOpenSurveyMenu"
          :list="surveyList"
          @onClose="updateIsOpenSurveyMenu(false)"
          @onSelected="onSelectedSurvey"
        />
      </div>
    </div>
    <search-filter-indicator
      :search-filter-detail="searchFilterObject.data"
      :events="events"
      :tag-groups="tagGroups"
      :survey-titles="surveyTitles"
      :staffs="staffs"
      @onReset="onSearchResetLocal"
    />
    <div class="pager_wrapper">
      <div v-if="isAllApplicants === false" class="pager_wrapper_switch_area">
        <switch-tab-applicant
          :disp-applicants="dispApplicants"
          :is-disp-line-friends="true"
          :is-disp-applicant="true"
          :is-tab="true"
          @input="switchActivatedLocal($event === 'regist_applicants')"
        />
      </div>
    </div>
    <div
      v-if="isSelectable && isShowSelectableApplicantTable"
      class="applicant_counter"
    >
      <div class="table_head">
        <div>
          <span class="title_text">対象人数：</span>
          <span class="count_text">{{ count }}</span>
          名
        </div>
        <div
          v-if="isShowSelectableApplicantTable"
          class="pager_wrapper_pager col-right margin-left-auto flexbox valingn-middle"
        >
          <div class="area-pager margin-left-auto">
            <pager
              :page="page"
              :count="count"
              :page-count="pageCount"
              :load="pageLoad"
              @move="movePager"
            />
          </div>
        </div>
      </div>
    </div>
    <local-page-loader v-if="!isShowSelectableApplicantTable" />
    <div v-if="isShowSelectableApplicantTable" class="area-search-list">
      <!-- 応募者一覧 -->
      <search-applicant-tab-list
        v-if="isActivated === true"
        ref="refRegisteredApplicantList"
        :search-tab-id="searchTabId"
        :applicants="parsedApplicants"
        :applicants-tags="applicantsTags"
        :invisible-columns="invisibleColumns"
        :is-flag-mode="isFlagMode"
        :is-line-friend="false"
        :is-sortable="isSortable"
        :is-scrollable="isScrollable"
        :is-selectable="isSelectable"
        :can-move-detail="canMoveDetail"
        :header-additional="headerAdditional"
        :header-pre-additional="headerPreAdditional"
        :highlight-applicant-ids="highlightApplicantIds"
        @onChangeSort="onChangeSort"
      />
      <!-- LINE友達一覧 -->
      <search-applicant-tab-list
        v-if="isActivated === false"
        ref="refLineFriendList"
        :search-tab-id="searchTabId"
        :applicants="parsedApplicants"
        :is-flag-mode="isFlagMode"
        :applicants-tags="applicantsTags"
        :invisible-columns="invisibleColumns"
        :is-line-friend="true"
        :is-sortable="isSortable"
        :is-scrollable="isScrollable"
        :is-selectable="isSelectable"
        :can-move-detail="canMoveDetail"
        :header-additional="headerAdditional"
        :header-pre-additional="headerPreAdditional"
        :highlight-applicant-ids="highlightApplicantIds"
        @onChangeSort="onChangeSort"
      />
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  nextTick,
  inject,
} from 'vue';
import { useStore } from 'vuex';

import useSearchApplicant from '@/composables/useSearchApplicant';
import SearchApplicantTabList from '@/components/features/searchApplicantTab/components/SearchApplicantTabList';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import Pager from '@/components/ui/pager/components/Pager';
import SwitchTabApplicant from '@/components/ui/switchTabApplicant/components/SwitchTabApplicant';
import SearchApplicantsWrapper from '@/components/features/searchApplicants/components/SearchApplicantsWrapper';
import SearchFilterIndicator from '@/components/features/searchApplicants/components/SearchFilterIndicator';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';

export default defineComponent({
  name: 'SearchApplicantTab',
  components: {
    Pager,
    FloatContextMenu,
    SearchApplicantsWrapper,
    SearchFilterIndicator,
    SwitchTabApplicant,
    LocalPageLoader,
    SearchApplicantTabList,
  },
  props: {
    searchTabId: {
      type: Number,
      default: null,
    },
    isSortable: {
      type: Boolean,
      default: true,
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    isAllApplicants: {
      type: Boolean,
      default: false,
    },
    isOnlyApplicantsOfLine: {
      type: Boolean,
      default: false,
    },
    canMoveDetail: {
      type: Boolean,
      default: true,
    },
    headerPreAdditional: {
      type: Array,
      default: () => [],
    },
    highlightApplicantIds: {
      type: Array,
      default: () => [],
    },
    invisibleColumnsPc: {
      type: Array,
      default: () => null,
    },
    invisibleColumnsSp: {
      type: Array,
      default: () => null,
    },
  },
  setup(props, context) {
    const store = useStore();
    const refSearchApplicantsWrapper = ref(null);
    const refRegisteredApplicantList = ref(null);
    const refLineFriendList = ref(null);
    // composable
    const {
      searchTabs,
      saveSearchFilterObject,
      openResetSelectedApplicantsConfirm,
      onChangeActivated,
    } = inject('useSearchApplicantTabKey');
    const {
      search,
      searchFilterObject,
      page,
      count,
      pageCount,
      pageLoad,
      events,
      staffs,
      applicants,
      headerAdditional,
      loadSelection,
      searchFormIsVisible,
      searchFormDetailIsVisible,
      isActivated,
      surveySetting,
      surveyList,
      surveyTitles,
      tagGroups,
      surveySheetId,
      applicantsTags,
      invisibleColumns,
      isFlagMode,
      isShowSelectableApplicantTable,
      isOpenPagerLoad,
      isOpenSearchApplicantFilters,
      isOpenSurveyMenu,
      // methods
      onSearch,
      onSearchReset,
      onChangeSort,
      movePager,
      fetchApplicants,
      openFloatApplicantsForm,
      switchActivated,
      searchFormDetailSwitch,
      onResize,
      onSelectedPageLoad,
      fetchEvents,
      fetchStaffs,
      fetchSurveyList,
      fetchTagGroups,
      setApplicantColumns,
      updateIsOpenPagerLoad,
      updateIsOpenSurveyMenu,
      updateSearchFormIsVisible,
      updateSearchFormDetailIsVisible,
      updateIsShowSelectableApplicantTable,
      updateIsFlagMode,
      updateSurveySheetId,
      updateSurveyTitles,
      changeFlagMode,
    } = useSearchApplicant(store, store.getters['searchFilter/searchFilter'], {
      invisibleColumnsPc: props.invisibleColumnsPc,
      invisibleColumnsSp: props.invisibleColumnsSp,
      isAllApplicants: props.isAllApplicants,
      isOnlyApplicantsOfLine: true,
      isExceptGraduated: false,
      isRequiredApplicantTags: true,
      excludeSelectionId: null,
    });

    // computed
    // 現在のタブ
    const currentSearchTab = computed(() => {
      return searchTabs.value.find(
        searchTab => searchTab.id === props.searchTabId,
      );
    });
    // 応募者・LINE友達Key
    const dispApplicants = computed(() => {
      if (isActivated.value === true) return 'regist_applicants';
      return 'line_friends';
    });
    // isSelected, isDisabled を最新の状態に更新した1ページ分の全応募者
    const parsedApplicants = computed(() => {
      if (!currentSearchTab.value) return [];
      // isSelected, isDisabled を最新の状態に更新
      const allSelectedApplicantIds =
        currentSearchTab.value.selectedRegisteredApplicants
          .map(v => v.id)
          .concat(currentSearchTab.value.selectedLineFriends.map(v => v.id));
      return applicants.value.map(applicant => {
        return {
          ...applicant,
          isSelected: allSelectedApplicantIds.includes(applicant.id),
          isDisabled: currentSearchTab.value.disabledApplicantIds.includes(
            applicant.id,
          ),
        };
      });
    });

    // methods
    // 初回データ読み込み
    const init = async () => {
      // フラググループ・Googleフォーム・イベント・スタッフを取得
      onResize();
      await Promise.all([
        fetchTagGroups(),
        fetchSurveyList(),
        fetchEvents(),
        fetchStaffs(true),
      ]);
      // 応募者一覧を取得
      await fetchApplicants(1, true);
    };
    // 応募者・LINE友達変更時のハンドリングイベント
    const switchActivatedLocal = async val => {
      onChangeActivated(val);
      const res = await switchActivated(val);
      return res;
    };
    // 選択応募者のリセット
    const resetSelectedApplicantsCallback = () => {
      updateIsShowSelectableApplicantTable(false);
      if (refRegisteredApplicantList.value) {
        refRegisteredApplicantList.value.resetAllSelectedApplicants();
      }
      if (refLineFriendList.value) {
        refLineFriendList.value.resetAllSelectedApplicants();
      }
    };
    // フラグモード・Googleフォーム切り替えボタン押下時のイベント
    const onSelectedSurvey = payload => {
      openResetSelectedApplicantsConfirm(
        {
          callback: async () => {
            resetSelectedApplicantsCallback();
            refSearchApplicantsWrapper.value.reset();
            nextTick(async () => {
              await changeFlagMode(payload.item.id);
              await onSearchReset();
              saveSearchFilterObject();
            });
          },
        },
        props.searchTabId,
        store,
      );
    };
    // 検索のハンドリングイベント
    const onSearchLocal = payload => {
      openResetSelectedApplicantsConfirm(
        {
          callback: async () => {
            resetSelectedApplicantsCallback();
            nextTick(async () => {
              await onSearch(payload);
              saveSearchFilterObject(payload.searchFilterObject);
            });
          },
        },
        props.searchTabId,
        store,
      );
    };
    // リセット検索のハンドリングイベント
    const onSearchResetLocal = () => {
      openResetSelectedApplicantsConfirm(
        {
          callback: async () => {
            resetSelectedApplicantsCallback();
            refSearchApplicantsWrapper.value.reset();
            nextTick(async () => {
              await onSearchReset();
              saveSearchFilterObject();
            });
          },
        },
        props.searchTabId,
        store,
      );
    };

    onBeforeMount(async () => {
      setApplicantColumns();
      await init();
      await store.dispatch('page/SET_LOADED');
    });
    onMounted(async () => {
      window.addEventListener('resize', onResize);
    });
    onUnmounted(async () => {
      window.removeEventListener('resize', onResize);
    });

    return {
      // computed
      currentSearchTab,
      dispApplicants,
      // composables
      refSearchApplicantsWrapper,
      refRegisteredApplicantList,
      refLineFriendList,
      search,
      searchFilterObject,
      page,
      count,
      pageCount,
      pageLoad,
      parsedApplicants,
      events,
      staffs,
      loadSelection,
      surveySetting,
      searchFormIsVisible,
      searchFormDetailIsVisible,
      surveyList,
      surveyTitles,
      tagGroups,
      surveySheetId,
      applicantsTags,
      invisibleColumns,
      headerAdditional,
      isFlagMode,
      isActivated,
      isShowSelectableApplicantTable,
      isOpenPagerLoad,
      isOpenSearchApplicantFilters,
      isOpenSurveyMenu,
      // methods
      onSearch,
      onSearchReset,
      onSearchLocal,
      onSearchResetLocal,
      onChangeSort,
      movePager,
      searchFormDetailSwitch,
      switchActivatedLocal,
      onSelectedPageLoad,
      onSelectedSurvey,
      openFloatApplicantsForm,
      updateIsOpenPagerLoad,
      updateIsOpenSurveyMenu,
      updateSearchFormIsVisible,
      updateSearchFormDetailIsVisible,
      updateIsFlagMode,
      updateSurveySheetId,
      updateSurveyTitles,
      updateIsShowSelectableApplicantTable,
      changeFlagMode,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.row-2 {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.applicants_menu {
  position: relative;
  margin-right: 1rem;
  .btn-applicants-search {
    padding: 10px 30px 10px 15px;
    background-image: url('../../../../assets/img/rect_arrow_down_white.svg');
    background-position: right 10px center;
    background-size: 10px;
    background-repeat: no-repeat;
  }
}

.title_text {
  font-weight: bold;
}
.count_text {
  font-size: 1.8rem;
  font-weight: bold;
}

.pager_wrapper {
  display: flex;
  > .pager_wrapper_pager {
    margin-top: -10px;
    height: 32px;
    margin-bottom: calc(2rem - 2px);
  }
}

.pager_wrapper_switch_area {
  width: 100%;
}

.applicant_count {
  position: relative;
  margin-right: 1rem;
}

.applicant_survey {
  position: relative;
}

.btn-applicants-search {
  transition: all ease 0.3s;
  &:hover {
    opacity: 0.6;
  }
}

.area-search-list {
  margin-top: 1rem;
}

.area-search-after {
  padding: 1rem;
}

.valingn-middle {
  align-items: center;
}

.table_head {
  display: flex;
  align-items: center;
}

.button_regist {
  position: relative;
  padding: 10px 30px 10px 15px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background-color: #16b2d9;
  cursor: pointer;
  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 10px;
    margin-top: -2px;
  }
}

.btn_download {
  margin-left: 10px;
}

.list-view-applicant_value {
  margin-top: 1rem;
  text-align: left;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  > .value {
    color: $mochica_color;
  }
}

@media (max-width: ($media_query_sp)) {
  .row-2 {
    display: block;
  }

  .applicants_menu {
    margin: 0 0 10px 0;
    .btn {
      width: 100%;
      text-align: left;
    }
  }

  .pager_wrapper {
    display: block;
    > .pager_wrapper_pager {
      margin-bottom: calc(2rem);
    }
  }

  .applicant_count {
    margin: 0 0 10px 0;
  }

  .applicant_survey {
    margin: 0 0 10px 0;
  }

  .btn_download {
    margin-left: 0;
  }
}
</style>
