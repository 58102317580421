<template>
  <div class="action-inner">
    <div class="action-title">
      <h4 class="title">選考の案内を通知</h4>
    </div>
    <div class="action-contents auto-action">
      <div class="gray-box">
        「自動アクションさせる選考」の案内を応募者に対して自動で通知します
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
.gray-box {
  font-size: $font_14;
}
</style>
