<script setup>
import { computed, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useForm } from 'vee-validate';

import {
  EditAutoActionSchema,
  EditNoticeSchema,
  ResultsSchema,
} from '@/schemas/autoActions';
import { toTypedSchema } from '@vee-validate/zod';
import { ACTION_TYPES } from '@/defines/autoActions';
import autoActionsService from '@/services/autoActions';
import useFetchAutoActionDetail from '@/composables/autoActions/useFetchAutoActionDetail';
import useBeforeRouteLeave from '@/composables/useBeforeRouteLeave';

import EditPage from '@/pages/autoActions/edit';
import ConfirmPage from '@/pages/autoActions/edit/confirm';
import ModalLeaveConfirm from '@/components/ui/modals/ModalLeaveConfirm.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();

const leaveConfirmModalRef = ref(null);
const isViewLeaveConfirmModal = ref(false);
const isSkipLeaveConfirm = ref(false);

if (!route.params.id) {
  router.push({ name: 'AutoActions' });
}

const { fetchAutoAction, action } = useFetchAutoActionDetail();

watch(
  () => router.currentRoute.value.path,
  () => {
    window.scrollTo(0, 0);
    store.dispatch('page/SET_LOADED');
  },
);

const currentSchema = computed(() => {
  switch (action.value.actionType) {
    case ACTION_TYPES.results:
      return toTypedSchema(EditAutoActionSchema.merge(ResultsSchema));
    case ACTION_TYPES.notice:
      return toTypedSchema(EditAutoActionSchema.merge(EditNoticeSchema));
    default:
      return toTypedSchema(EditAutoActionSchema);
  }
});

const { handleSubmit, isSubmitting, values, meta, resetForm } = useForm({
  validationSchema: currentSchema,
  initialValues: action.value,
});

const skipModalCondition = to => {
  const targetPaths = [
    `/configs/auto-actions/edit/${route.params.id}`,
    `/configs/auto-actions/edit/${route.params.id}/confirm`,
  ];
  return (
    targetPaths.includes(to.path) ||
    isSkipLeaveConfirm.value ||
    !meta.value.dirty
  );
};

useBeforeRouteLeave(
  () => leaveConfirmModalRef.value.openModal(),
  skipModalCondition,
);

onMounted(async () => {
  store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
  await fetchAutoAction(route.params.id);
  resetForm({ values: action.value });
  store.dispatch('page/SET_LOADED');
});

const requestPayload = computed(() => ({
  name: values.actionName,
  status: values.actionStatus,
  action_type: values.actionType,
  action_conditions: {
    result: values.results
      ? {
          passed: {
            mail_subject: values.results.passed.email.subject,
            mail_content: values.results.passed.email.content,
            line_content: values.results.passed.line.content,
          },
          offered: {
            mail_subject: values.results.offered.email.subject,
            mail_content: values.results.offered.email.content,
            line_content: values.results.offered.line.content,
          },
          dropped: {
            mail_subject: values.results.dropped.email.subject,
            mail_content: values.results.dropped.email.content,
            line_content: values.results.dropped.line.content,
          },
        }
      : undefined,
    notification: values.notice
      ? {
          mail_subject: values.notice.email.subject,
          mail_content: values.notice.email.content,
          line_content: values.notice.line.content,
          force_email: values.notice.isSendForceEmail,
          notice_check: values.notice.isConfirmMessage,
          upload_files: values.notice.files,
        }
      : undefined,
  },
}));

const submitConfirm = handleSubmit(() => {
  router.push({ name: 'AutoActionsEditConfirm' });
});

const submitEdit = handleSubmit(async () => {
  const { data, status } = await autoActionsService.editAutoAction(
    requestPayload.value,
    route.params.id,
  );
  if (status !== 204) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: data.message,
      type: false,
    });
    return;
  }
  store.dispatch('notification/VISIBLE_NOTIFICATION', {
    message: '自動アクションを更新しました',
    type: true,
  });
  isSkipLeaveConfirm.value = true;
  router.push({ name: 'AutoActionsDetail' });
});
</script>
<template>
  <div>
    <edit-page
      v-show="router.currentRoute.value.name === 'AutoActionsEdit'"
      :form-values="{ ...values }"
      @submit="submitConfirm"
    />
    <confirm-page
      v-show="router.currentRoute.value.name === 'AutoActionsEditConfirm'"
      :form-values="{ ...values }"
      :is-submitting="isSubmitting"
      @submit="submitEdit"
    />
    <modal-leave-confirm
      ref="leaveConfirmModalRef"
      :title="'自動アクションを編集中です'"
      :message="`このまま画面を閉じると自動アクションの編集内容が失われます。
      画面を閉じてもよろしいでしょうか？`"
      :is-view="isViewLeaveConfirmModal"
      @open="isViewLeaveConfirmModal = true"
      @close="isViewLeaveConfirmModal = false"
    />
  </div>
</template>
