import moment from 'moment';

import Staff from '@/models/staff';

const TimeTable = function _TimeTable(
  id = null,
  start = null,
  last = null,
  limit = 0,
  actual = 0,
  reminderCheck = false,
  reminderPassword = '',
  applicantNames = [],
  attendances = [],
  staffs = [],
  hasGoogleEventId = '',
  isGoogleSyncError = false,
  selectedCalendarResources = [],
  isCreateGoogleCalendar = false,
  createStaffName = null,
  updateStaffName = null,
  googleHostStaffName = null,
  createdAt = null,
  updatedAt = null,
  googleHostStaffUpdatedAt = null,
  zoomId = '',
  isZoomSyncError = '',
  isCreateZoomUrl = false,
  zoomHostName = '',
  zoomStartUrl = '',
  zoomJoinUrl = '',
) {
  const row = Object.create(TimeTable.prototype);

  row.id = id;
  row.start = start;
  row.last = last;
  row.limit = limit;
  row.actual = actual;
  row.reminderCheck = reminderCheck;
  row.reminderPassword = reminderPassword;
  row.applicantNames = applicantNames;
  row.attendances = attendances ? attendances : [];
  row.staffs = staffs ? staffs.map(Staff.createFromJSON) : [];
  row.hasGoogleEventId = hasGoogleEventId;
  row.isGoogleSyncError = isGoogleSyncError;
  row.selectedCalendarResources = selectedCalendarResources
    ? selectedCalendarResources
    : [];
  row.isCreateGoogleCalendar = isCreateGoogleCalendar;
  row.createStaffName = createStaffName;
  row.updateStaffName = updateStaffName;
  row.googleHostStaffName = googleHostStaffName;
  row.createdAt = createdAt;
  row.updatedAt = updatedAt;
  row.googleHostStaffUpdatedAt = googleHostStaffUpdatedAt;
  row.zoomId = zoomId;
  row.isZoomSyncError = isZoomSyncError;
  row.isCreateZoomUrl = isCreateZoomUrl;
  row.zoomHostName = zoomHostName;
  row.zoomStartUrl = zoomStartUrl;
  row.zoomJoinUrl = zoomJoinUrl;
  return row;
};

TimeTable.prototype = {
  /**
   * JSON形式Object
   * @returns {{name: *, room_name: *, address: *, pref: *, date: *, notice: *}}
   */
  jsonObjCreateTimetable(
    selectedStaffIds = [],
    selectedResourceIds = [],
    zoomHostId = null,
  ) {
    let obj = {
      start: this.start ? moment(this.start, 'HH:mm').format('HH:mm:ss') : null,
      last: this.last ? moment(this.last, 'HH:mm').format('HH:mm:ss') : null,
      limit: this.limit,
      reminder_check: this.reminderCheck,
      reminder_password: this.reminderPassword,
      is_create_google_calendar: this.isCreateGoogleCalendar,
      web_meeting_id: this.isCreateZoomUrl === true ? 1 : 0,
    };
    if (this.id) obj.id = this.id;
    if (selectedStaffIds.length > 0) obj.staffs = selectedStaffIds;
    if (selectedResourceIds.length > 0) {
      obj.selected_calendar_resources = selectedResourceIds;
    }
    // Zoomを使用する場合のみzoom_host_idを送信
    if (this.isCreateZoomUrl === true && zoomHostId !== null) {
      obj.zoom_host_id = zoomHostId;
    }
    return obj;
  },
};

TimeTable.createFromJSON = json =>
  TimeTable(
    json.id,
    json.start,
    json.last,
    json.limit,
    json.actual,
    json.reminder_check,
    json.reminder_password,
    json.applicant_names,
    json.attendances,
    json.staffs,
    json.has_google_event_id,
    json.is_google_sync_error,
    json.selected_calendar_resources,
    json.is_create_google_calendar,
    json.create_staff_name,
    json.update_staff_name,
    json.google_host_staff_name,
    json.created_at,
    json.updated_at,
    json.google_host_staff_updated_at,
    json.zoom_id,
    json.is_zoom_sync_error,
    json.is_create_zoom_url,
    json.zoom_host_name,
    json.zoom_start_url,
    json.zoom_join_url,
  );

export default TimeTable;
