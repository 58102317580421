<template>
  <div>
    <!-- <div style="line-height: 1.2">
      <p>---------- DEBUG ----------</p>
      <p>applicantIds: {{ applicants.map(v => v.id) }}</p>
      <p>localSelectedApplicantIds: {{ localSelectedApplicantIds }}</p>
      <br />
    </div> -->
    <div ref="areaTable" class="area-table">
      <table :class="{ 'table-scrollable': isScrollable }">
        <thead>
          <tr>
            <th
              v-for="(col, iCol) in filteredHeader()"
              :key="iCol"
              :ref="col.name"
              :class="[col.name, { sortable: col.sort_by && isSortable }]"
            >
              <div
                v-if="col.sort_by && isSortable"
                @click="onClickHeader(col, iCol)"
              >
                <span>{{ col.name_jp }}</span>
                <div class="area-icon-sortable">
                  <span
                    v-if="headerSortBy === col.sort_by"
                    :class="{ selected: headerSortBy === col.sort_by }"
                    class="icon-sortable"
                  >
                    <span v-if="headerSortOrder === true">
                      <img
                        class="icon-sortable-svg-up"
                        width="12"
                        height="7"
                        src="@/assets/img/rect_arrow_down_blue.svg"
                        style="transform: rotate(180deg)"
                        alt
                      />
                    </span>
                    <span v-else>
                      <img
                        class="icon-sortable-svg-down"
                        width="12"
                        height="7"
                        src="@/assets/img/rect_arrow_down_blue.svg"
                        alt
                      />
                    </span>
                  </span>
                  <span v-else class="icon-sortable">
                    <img
                      class="icon-sortable-svg-up"
                      width="12"
                      height="7"
                      src="@/assets/img/rect_arrow_down_gray.svg"
                      alt
                    />
                  </span>
                </div>
              </div>
              <div v-else>
                <div v-if="col.name === 'isSelect'">
                  <input
                    ref="refSelectAll"
                    type="checkbox"
                    name="selecthead"
                    value="1"
                    @change="selectAllChange"
                  />
                </div>
                <div v-else>
                  <span>{{ col.name_jp }}</span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="applicant in applicants"
            :key="applicant.id"
            :class="{
              'can-move-detail': canMoveDetail,
              'is-disabled': applicant.isDisabled,
              highlight: highlightApplicantIds.includes(applicant.id),
            }"
          >
            <td
              v-for="(col, iCol) in getHeader()"
              v-show="!isInvisible(col)"
              :key="iCol"
              :class="[col.name]"
              @click="moveApplicantDetailNewTab(applicant, col)"
            >
              <div v-if="col.filter">
                <template v-if="col.filter === 'unreadLineCount'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                    class="has-unread-line"
                  >
                    {{ applicant.unreadLineCount }}件
                  </span>
                </template>
                <template v-if="col.filter === 'unreadMailCount'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                    class="has-unread-mail"
                  >
                    {{ applicant.unreadMailCount }}件
                  </span>
                </template>
                <template v-if="col.filter === 'currentStatus'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                    :class="
                      applicant.status.progress.name === null
                        ? 'not_set_selection'
                        : applicant.status.progress.name
                    "
                    class="status_default"
                  >
                    {{
                      $utils.getValueFromNestedObject(applicant, relation) ===
                      null
                        ? '選考未割当て'
                        : $utils.getValueFromNestedObject(applicant, relation)
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'invitedStatus'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{
                      $utils.getValueFromNestedObject(applicant, relation)
                        ? '済'
                        : '未'
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'momentYmdNoSpace'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{
                      $utils.parseDateTime(
                        $utils.getValueFromNestedObject(applicant, relation),
                        'YYYY/MM/DD',
                      )
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'count'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                    :class="{
                      'has-unread': $utils.getValueFromNestedObject(
                        applicant,
                        relation,
                      ),
                    }"
                  >
                    {{
                      $utils.getValueFromNestedObject(applicant, relation) !==
                      null
                        ? `${$utils.getValueFromNestedObject(
                            applicant,
                            relation,
                          )}件`
                        : '-'
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'contactTypeStatus'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                    class="contact_type_status"
                  >
                    <span
                      v-if="
                        $utils.getValueFromNestedObject(applicant, relation) ===
                        1
                      "
                      class="contact_mail"
                    >
                      {{ 'メール' }}
                    </span>
                    <span
                      v-if="
                        $utils.getValueFromNestedObject(applicant, relation) ===
                        2
                      "
                      class="contact_line"
                    >
                      {{ 'LINE' }}
                    </span>
                    <span
                      v-if="
                        $utils.getValueFromNestedObject(applicant, relation) ===
                        3
                      "
                      class="contact_block"
                    >
                      {{ 'ブロック中' }}
                    </span>
                  </span>
                </template>
                <template v-if="col.filter === 'attendanceAt'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{
                      $utils.parseDateTime(
                        $utils.getValueFromNestedObject(applicant, relation),
                        'YYYY年M月D日',
                      )
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'reminderCheck'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{ applicant.status.progress.reminder_check ? '済み' : '' }}
                  </span>
                </template>
                <template v-if="col.filter === 'sendStatusDescription'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{
                      applicant.status.progress.name === 'announced'
                        ? '送信済み'
                        : applicant.status.progress.send_status_description
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'sendedAt'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{
                      $utils.parseDateTime(
                        applicant.status.progress.sended_at,
                        'YYYY年M月D日',
                      )
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'finishedSendStatusDescription'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{
                      applicant.status.progress.finished_send_status_description
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'finishedSendedAt'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{
                      $utils.parseDateTime(
                        applicant.status.progress.finished_sended_at,
                        'YYYY年M月D日',
                      )
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'announceCheck'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{
                      applicant.status.progress.announce_check ? '済み' : '未'
                    }}
                  </span>
                </template>
                <template v-if="col.filter === 'resultCheck'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{ applicant.status.progress.result_check ? '済み' : '未' }}
                  </span>
                </template>
                <template v-if="col.filter === 'richmessageCheck'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{ applicant.richmessageCheck ? '済み' : '未' }}
                  </span>
                </template>
                <template v-if="col.filter === 'flagsurveyCheck'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                  >
                    {{ applicant.flagsurveyCheck ? '済み' : '未' }}
                  </span>
                </template>
                <template v-if="col.filter === 'applicantName'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                    :class="{
                      name_color_red: applicant.nameColorRed
                        ? applicant.nameColorRed
                        : false,
                    }"
                  >
                    {{ $utils.getValueFromNestedObject(applicant, relation) }}
                  </span>
                </template>
                <template v-if="col.filter === 'applicantTel'">
                  <span
                    v-for="(relation, iRelation) in col.relation"
                    :key="iRelation"
                    :class="{
                      tel_color_red: applicant.telColorRed
                        ? applicant.telColorRed
                        : false,
                    }"
                  >
                    {{ $utils.getValueFromNestedObject(applicant, relation) }}
                  </span>
                </template>
              </div>

              <template v-else>
                <span
                  v-for="(relation, iRelation) in col.relation"
                  :key="iRelation"
                >
                  <template v-if="col.name === 'not_announe_lamp'">
                    {{ announceLamp(applicant) }}
                  </template>
                  <template v-else>
                    {{ $utils.getValueFromNestedObject(applicant, relation) }}
                  </template>
                </span>
              </template>

              <template v-if="col.isAdditional">
                <template v-if="isFlagMode">
                  <span
                    v-for="(tag, iTag) in getTagsList(applicant.id, col.name)"
                    :key="iTag"
                  >
                    {{ tag.name }}
                  </span>
                </template>
                <span v-else>
                  {{
                    applicant.surveyData &&
                    applicant.surveyData.columns[iCol - 23]
                  }}
                </span>
              </template>

              <span v-if="isColumnSelectable(col)">
                <img
                  v-if="applicant.isDisabled"
                  src="@/assets/img/icon_warning_gray.svg"
                  class="icon-warning_gray"
                  alt
                  @mouseover="showDisablePopup"
                  @mouseleave="hideDisablePopup"
                />
                <input
                  v-else
                  :ref="'select_' + applicant.id"
                  type="checkbox"
                  name="select"
                  value="1"
                  :checked="isCheckedApplicant(applicant.id)"
                  :disabled="applicant.isDisabled"
                  @change="selectChange(applicant, $event)"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="isShowDisablePopup === true"
      class="popup_wrapper"
      :style="{
        top: disablePopupY + 'px',
        left: disablePopupX + 'px',
      }"
    >
      <div class="popup">
        <p>他のタブで選択済み</p>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { computed, defineComponent, onMounted, ref, inject } from 'vue';

import Breakpoints from '@/defines/breakpoints';
import { applicantListColumns } from '@/defines/applicant';

export default defineComponent({
  name: 'SearchApplicantTabList',
  props: {
    applicantsTags: {
      type: Object,
      default: () => {},
    },
    searchTabId: {
      type: Number,
      default: null,
    },
    applicants: {
      type: Array,
      default: () => [],
    },
    isFlagMode: {
      type: Boolean,
      default: false,
    },
    invisibleColumns: {
      type: Array,
      default: () => [],
    },
    isLineFriend: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: true,
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    canMoveDetail: {
      type: Boolean,
      default: true,
    },
    headerAdditional: {
      type: Array,
      default: () => [],
    },
    headerPreAdditional: {
      type: Array,
      default: () => [],
    },
    highlightApplicantIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onChangeSort'],
  setup(props, context) {
    const refSelectAll = ref(null);
    // ヘッダーのソート項目
    const headerSortBy = ref('');
    // ヘッダーのソート順
    const headerSortOrder = ref(null);
    // 選択不可応募者のポップアップ表示フラグ
    const isShowDisablePopup = ref(false);
    // 選択不可応募者のポップアップX座標
    const disablePopupX = ref(0);
    // 選択不可応募者のポップアップY座標
    const disablePopupY = ref(0);
    // コンポーネント内選択応募者ID配列
    const localSelectedApplicantIds = ref([]);

    // composable
    const { searchTabs, updateSelectedApplicantsInSearchTab } = inject(
      'useSearchApplicantTabKey',
    );

    // computed
    const currentSearchTab = computed(() => {
      return searchTabs.value.find(
        searchTab => searchTab.id === props.searchTabId,
      );
    });

    // methods
    // 非表示カラムかチェック
    const isInvisible = col => {
      return props.invisibleColumns.findIndex(el => el === col.name) >= 0;
    };
    // 応募者を選択可能かチェック
    const isColumnSelectable = col => {
      return col.name === 'isSelect' && props.isSelectable;
    };
    // 応募者が選択中かチェック
    const isCheckedApplicant = id => {
      return (
        localSelectedApplicantIds.value.find(
          applicantId => applicantId === id,
        ) !== undefined
      );
    };
    // フラグ配列を取得
    const getTagsList = (applicantId, tagGroupId) => {
      return props.applicantsTags &&
        props.applicantsTags[applicantId] &&
        props.applicantsTags[applicantId][tagGroupId]
        ? props.applicantsTags[applicantId][tagGroupId]
        : [];
    };
    // ヘッダーを取得
    const getHeader = () => {
      return cloneDeep(props.headerPreAdditional)
        .concat(
          cloneDeep(
            applicantListColumns.filter(col => {
              if (!props.isSelectable) {
                if (col.name === 'isSelect') {
                  return false;
                }
                if (window.innerWidth < Breakpoints.MAX_SP_SIZE) {
                  if (
                    col.name === 'event_title' ||
                    col.name === 'attendanceAt' ||
                    col.name === 'reminderCheck' ||
                    col.name === 'not_announe_lamp' ||
                    col.name === 'name_kana' ||
                    col.name === 'contact_type_status' ||
                    col.name === 'gender' ||
                    col.name === 'school' ||
                    col.name === 'faculty' ||
                    col.name === 'department' ||
                    col.name === 'department_category' ||
                    col.name === 'email' ||
                    col.name === 'tel' ||
                    col.name === 'channel' ||
                    col.name === 'invited_status' ||
                    col.name === 'invited_at' ||
                    col.name === 'created_at' ||
                    col.name === 'activated_at' ||
                    col.name === 'staff'
                  ) {
                    return false;
                  }
                }
              }
              return true;
            }),
          ),
        )
        .concat(cloneDeep(props.headerAdditional));
    };
    // 非表示カラムを消したヘッダーを取得
    const filteredHeader = () => {
      return getHeader().filter(col => !isInvisible(col));
    };
    // 結果未通知の表示テキスト処理
    const announceLamp = applicant => {
      if (
        applicant.status.progress.name === 'passed' ||
        applicant.status.progress.name === 'offered' ||
        applicant.status.progress.name === 'dropped' ||
        applicant.status.progress.name === 'hired' ||
        applicant.status.progress.name === 'canceled' ||
        applicant.status.progress.name === 'offer_canceled'
      ) {
        return applicant.not_announe_lamp === true ? '未' : '';
      }
      return '';
    };
    // ヘッダー押下時のイベント
    const onClickHeader = col => {
      if (
        headerSortOrder.value === null ||
        headerSortBy.value !== col.sort_by
      ) {
        headerSortOrder.value = true;
      } else {
        headerSortOrder.value = !headerSortOrder.value;
      }
      headerSortBy.value = col.sort_by;
      context.emit('onChangeSort', {
        sort_order: headerSortOrder.value,
        sort_by: headerSortBy.value,
      });
    };
    // 応募者詳細画面への移動
    const moveApplicantDetailNewTab = (_applicant, col) => {
      if (!isColumnSelectable(col) && col.name !== 'createNewTab') {
        window.open(`/applicants/${_applicant.id}?menu=0`, '_blank');
      }
    };
    // 選択応募者の更新
    const updateSelectApplicants = () => {
      const localSelectedApplicants = props.applicants.filter(v =>
        localSelectedApplicantIds.value.includes(v.id),
      );
      const currentPageApplicantIds = props.applicants.map(v => v.id);
      const args = {};
      // 重複した選択応募者idを削除
      if (props.isLineFriend === true) {
        // 他のタブの選択応募者と別ページの選択応募者を結合して全応募者を算出
        const allSelectedApplicants = localSelectedApplicants.concat(
          currentSearchTab.value.selectedLineFriends.filter(
            v => !currentPageApplicantIds.includes(v.id),
          ),
        );
        args.selectedLineFriends = [
          ...new Set(allSelectedApplicants.map(tab => tab.id)),
        ].map(id =>
          allSelectedApplicants.find(applicant => applicant.id === id),
        );
      } else {
        // 他のタブの選択応募者と別ページの選択応募者を結合して全応募者を算出
        const allSelectedApplicants = localSelectedApplicants.concat(
          currentSearchTab.value.selectedRegisteredApplicants.filter(
            v => !currentPageApplicantIds.includes(v.id),
          ),
        );

        args.selectedRegisteredApplicants = [
          ...new Set(allSelectedApplicants.map(v => v.id)),
        ].map(id =>
          allSelectedApplicants.find(applicant => applicant.id === id),
        );
      }
      updateSelectedApplicantsInSearchTab(props.searchTabId, args);
    };
    // 応募者選択押下時のイベント
    const selectChange = (applicant, ev, checked) => {
      const index = localSelectedApplicantIds.value.findIndex(
        applicantId => applicantId === applicant.id,
      );
      if ((ev && ev.target.checked) || (ev === null && checked)) {
        localSelectedApplicantIds.value.push(applicant.id);
      } else if (index !== undefined) {
        localSelectedApplicantIds.value.splice(index, 1);
        if (refSelectAll.value && refSelectAll.value[0]) {
          refSelectAll.value[0].checked = false;
        }
      }
      updateSelectApplicants();
    };
    // 応募者全選択・解除押下時のイベント
    const selectAllChange = ev => {
      let selectedAllApplicantsIds = props.applicants.map(
        applicant => applicant.id,
      );
      const disabledApplicantIds = props.applicants
        .filter(x => x.isDisabled)
        .map(x => x.id);
      if (disabledApplicantIds.length) {
        selectedAllApplicantsIds = selectedAllApplicantsIds.filter(
          x => !disabledApplicantIds.includes(x),
        );
      }
      if (ev.target.checked) {
        // 全選択
        localSelectedApplicantIds.value = localSelectedApplicantIds.value
          .map(v => v)
          .concat(selectedAllApplicantsIds)
          .filter((x, i, self) => self.indexOf(x) === i);
      } else {
        // 全選択解除
        localSelectedApplicantIds.value =
          localSelectedApplicantIds.value.filter(
            appId => selectedAllApplicantsIds.indexOf(appId) === -1,
          );
      }
      updateSelectApplicants();
    };
    // 応募者全リセット時のイベント
    const resetAllSelectedApplicants = () => {
      // 親コンポーネントで呼び出されているので注意
      localSelectedApplicantIds.value = [];
      updateSelectApplicants();
    };
    // 選択不可応募者のポップアップを表示
    const showDisablePopup = e => {
      const rect = e.target.getBoundingClientRect();
      isShowDisablePopup.value = true;
      disablePopupX.value = rect.left - 10;
      disablePopupY.value = rect.top - 44;
    };
    // 選択不可応募者のポップアップを非表示
    const hideDisablePopup = () => {
      isShowDisablePopup.value = false;
      disablePopupX.value = 0;
      disablePopupY.value = 0;
    };

    onMounted(() => {
      // applicants[i].isSelected をsearchTabの選択応募者に反映させる
      localSelectedApplicantIds.value = props.applicants
        .filter(v => v.isSelected)
        .map(v => v.id);
    });

    return {
      searchTabs,
      headerSortBy,
      headerSortOrder,
      isShowDisablePopup,
      disablePopupX,
      disablePopupY,
      // computed
      currentSearchTab,
      localSelectedApplicantIds,
      // methods
      isCheckedApplicant,
      isColumnSelectable,
      isInvisible,
      getHeader,
      getTagsList,
      filteredHeader,
      announceLamp,
      onClickHeader,
      moveApplicantDetailNewTab,
      selectChange,
      selectAllChange,
      showDisablePopup,
      hideDisablePopup,
      resetAllSelectedApplicants,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.area-table {
  max-height: 800px;
  overflow-x: auto;
  table {
    width: 100%;
    thead {
      tr {
        background-color: #e2e2e2;
        th {
          position: sticky;
          z-index: 12;
          top: 0;
          background-color: #e2e2e2;
          padding: 0 1.5rem;
          height: 3.6rem;
          text-align: left;
          border-collapse: collapse;
          white-space: nowrap;
          input[type='checkbox'] {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 0.4rem;
            border: 2px solid #9d9d9d;
            background: #fff;
            position: relative;
            transform: translate3d(0px, 0px, 0px);
            cursor: pointer;
            &:checked {
              &::after {
                content: url(../../../../assets/img/check.svg);
                height: 1.1rem;
                width: 1.5rem;
                margin: -5px 0 0 1px;
                position: absolute;
                bottom: 3px;
                left: 0;
              }
            }
          }
          &:first-of-type {
            min-width: 3rem;
          }
          > div {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .area-icon-sortable {
            display: inline;
            margin-left: 4px;
            .icon-sortable {
              &.selected {
                * {
                  color: $mochica_color;
                  stroke: $mochica_color;
                }
                svg {
                  transform: rotate(180deg);
                  height: 0.8rem;
                  &.icon-sortable-svg-down {
                    transform: rotate(0deg);
                  }
                }
              }
            }
            .icon-sortable-svg-up {
              width: 1.2rem;
              fill: none;
              stroke-width: 2;
              stroke: $mochica_color_gray;
            }
            .icon-sortable-svg-down {
              @extend .icon-sortable-svg-up;
            }
          }
        }
      }
    }
    tbody {
      background: white;
      white-space: nowrap;
      tr {
        height: 4.4rem;
        border: none;
        border-bottom: 1px solid #e2e2e2;
        transition: all ease 0.1s;
        cursor: pointer;
        &:hover {
          background-color: #f1f1f1;
        }
        td {
          padding: 0 1.5rem;
          border-collapse: collapse;
          background: #fff;
          z-index: 10;
          .name_color_red {
            color: $mochica_color_red;
            font-weight: bold;
          }
          .tel_color_red {
            color: $mochica_color_red;
            font-weight: bold;
          }
          .has-unread {
            color: #e83d86;
            position: sticky;
            left: 0;
          }
          .has-unread-line,
          .has-unread-mail {
            display: block;
            text-align: center;
            color: #e83d86;
          }
          .status_default {
            display: inline-block;
            border-radius: 83px;
            width: 9rem;
            text-align: center;
            height: 28px;
            line-height: 27px;
          }
          .not_set_selection {
            background-color: $not_set_selection_bg;
            color: $white;
            font-weight: bold;
          }
          .announced {
            color: $announced_color;
            font-weight: bold;
          }
          .finished {
            border-color: $finished_border;
            background-color: $finished_bg;
            color: #fff;
            font-weight: bold;
          }
          .hired {
            color: $hired_color;
            font-weight: bold;
          }
          .offered {
            color: $offered_color;
            font-weight: bold;
          }
          .dropped {
            color: $dropped_color;
            font-weight: bold;
          }
          .canceled {
            color: $canceled_color;
            font-weight: bold;
          }
          .offer_canceled {
            color: $offer_canceled_color;
            font-weight: bold;
          }
          .passed {
            background-color: $passed_bg;
            color: $white;
            font-weight: bold;
          }
        }
        td.not_announe_lamp {
          color: #e83d86;
          text-align: center;
          padding: 0;
          padding-right: 1.5rem;
        }
      }
      tr.can-move-detail {
        cursor: pointer;
        td {
          &:first-of-type {
            min-width: 3rem;
            // @include flex_center_center;
          }
        }
        input[type='checkbox'] {
          appearance: none;
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 0.4rem;
          border: 2px solid #9d9d9d;
          background: #fff;
          position: relative;
          transform: translate3d(0px, 0px, 0px);
          cursor: pointer;
          &:checked {
            &::after {
              content: url(../../../../assets/img/check.svg);
              height: 1.1rem;
              width: 1.5rem;
              margin: -5px 0 0 1px;
              position: absolute;
              bottom: 3px;
              left: 0;
            }
          }
        }
      }
      tr.is-disabled {
        opacity: 0.6;
        > td {
          background-color: #ededed;
        }
      }
      tr.unread_count_color {
        > td {
          background-color: #ededed;
        }
      }
      tr.highlight {
        > td {
          background-color: #f9f2d9;
        }
      }
    }
  }
  .table-scrollable {
    text-align: left;
    thead {
      tr {
        th {
          table-layout: fixed;
          height: 3.6rem;
          padding-left: 1rem;
        }
      }
    }
    tbody {
      overflow-y: scroll;
      // height: 400px;
      tr {
        td {
          table-layout: fixed;
          height: 3.6rem;
          padding-left: 1rem;
        }
      }
    }
  }
  .contact_line {
    color: #1dcd00;
    font-weight: bold;
  }
  .contact_block {
    color: $mochica_color_red;
  }
  .icon-warning_gray {
    width: 17px;
  }
}

.popup_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  .popup {
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    background-color: #808080;
    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 20px;
      margin-left: -6px;
      border: 6px solid transparent;
      border-top: 6px solid #808080;
    }
    > p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 1;
      font-weight: bold;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .area-table table {
    font-size: 1.1rem;
    thead tr th {
      padding: 0 5px;
      &:first-of-type {
        padding: 0 5px 0 10px;
      }
    }
    tbody tr td {
      padding: 0 5px;
      &:first-of-type {
        padding: 0 5px 0 10px;
      }
    }
    tbody tr td .status_default {
      width: 100%;
      height: auto;
      padding: 0 5px;
      font-size: 0.9rem;
    }
  }
}
</style>
