<script setup>
import { ref, onMounted } from 'vue';
import BaseInput from '@/components/ui/input/BaseInput.vue';
import BaseTextarea from '@/components/ui/textarea/BaseTextarea.vue';
import CopyTag from '@/components/ui/forms/CopyTag.vue';

defineProps({
  subject: {
    type: Object,
    default: () => ({
      value: '',
      errorMessage: '',
    }),
  },
  content: {
    type: Object,
    default: () => ({
      value: '',
      errorMessage: '',
    }),
  },
  copyTags: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits([
  'input-subject',
  'input-content',
  'blur-subject',
  'blur-content',
]);

const textareaRef = ref(null);

const adjustHeightTextarea = () => {
  const element = textareaRef.value.$el;
  if (!element) return;
  element.style.height = 'auto';
  element.style.height = `${element.scrollHeight}px`;
};

const handleInputContent = value => {
  adjustHeightTextarea();
  emit('input-content', value);
};
onMounted(() => {
  adjustHeightTextarea();
});
</script>

<template>
  <div class="contactForm-wrapper">
    <div class="title">
      <img
        class="mail_icon_svg"
        src="@/assets/img/icon_email.svg"
        alt="メールのアイコン"
      />
      メール
    </div>
    <div>
      <div class="desc">
        連絡方法が
        <strong>メール</strong>
        の応募者へ届きます。
      </div>
      <div v-show="copyTags.length > 0" class="variable">
        <div class="variable-tags">
          <div v-for="tag in copyTags" :key="tag.text">
            <copy-tag :tag="tag" />
          </div>
        </div>
        <span class="variable-desc">
          ※青文字をコピーして本文中の任意の位置に挿入することで、{{
            copyTags.map(tag => tag.description).join('・')
          }}を利用することができます。
        </span>
      </div>
      <div class="subject">
        <strong class="subject-title">件名</strong>
        <div class="subject-inner">
          <base-input
            :model-value="subject.value"
            :is-error="!!subject.errorMessage"
            type="text"
            name="subject"
            @input="emit('input-subject', $event.target.value)"
            @blur="emit('blur-subject', $event.target.value)"
          />
          <div v-show="!!subject.errorMessage" class="error-text">
            {{ subject.errorMessage }}
          </div>
        </div>
      </div>
      <div class="message">
        <strong class="message-title">本文</strong>
        <div class="message-inner">
          <div class="textbox-wrapper">
            <div class="textbox">
              <div class="caption-name">
                {姓・名}様
                <small>※編集できません</small>
              </div>
              <base-textarea
                ref="textareaRef"
                :model-value="content.value"
                :is-error="!!content.errorMessage"
                name="content"
                @input="handleInputContent($event.target.value)"
                @blur="emit('blur-content', $event.target.value)"
              />
              <div class="caption-signature">
                {各種設定の署名}
                <small>※編集できません</small>
              </div>
            </div>
          </div>
          <div v-show="!!content.errorMessage" class="error-text">
            {{ content.errorMessage }}
          </div>
        </div>
        <ul class="message-caption-lists">
          <slot>
            <li class="message-caption-list">
              ※ 内容冒頭には宛先の｛姓・名｝様が挿入されます。
            </li>
            <li class="message-caption-list">
              ※ 末尾には設定している署名が挿入されます。
            </li>
          </slot>
          <slot name="addCaptions" />
        </ul>
      </div>
      <slot name="attachment" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include contactFormStyle(mail);
</style>
