<template>
  <transition name="fade">
    <div v-if="$store.getters['page/isPageLoaded']">
      <section class="menu">
        <button
          :class="{ disabled: fixPhraseList.length >= 100 }"
          :disabled="fixPhraseList.length >= 100"
          class="btn btn-availability"
          @click="isVisibleAddFixPhrase = true"
        >
          <img class="icon" src="@/assets/img/add_white.svg" alt="" />
          テンプレート新規作成
        </button>
        <p class="templates-count">
          テンプレート数
          <span class="num">{{ fixPhraseList.length }} / 100</span>
        </p>
      </section>
      <modal-select-fixed-phrase
        v-if="isVisibleAddFixPhrase"
        :is-view="isVisibleAddFixPhrase"
        :is-create="true"
        :fixed-phrase="fixedPhrase"
        :is-loading="isLoading"
        @onClickSubmitModalSelectPhrase="onClickAddFixPhrase"
        @onClickCloseModalSelectPhrase="onClickCancel"
      />
      <list-view-fix-phrase
        :fix-phrase-list="fixPhraseList"
        @openModalUpdateFixPhrase="openModalUpdateFixPhrase"
        @openModalDeleteFixPhrase="openModalDeleteFixPhrase"
      />
      <modal-select-fixed-phrase
        v-if="isVisibleUpdateFixPhrase"
        :is-view="isVisibleUpdateFixPhrase"
        :is-edit="true"
        :fixed-phrase="updateFixPhrase"
        :is-loading="isLoading"
        @onClickSubmitModalSelectPhrase="onClickUpdateFixPhrase"
        @onClickCloseModalSelectPhrase="isVisibleUpdateFixPhrase = false"
      />
      <modal-window
        :is-visible="isVisibleDeleteFixPhrase"
        title="選択されたテンプレートを削除"
        message="削除されたテンプレートは復元できません。削除しますか？"
        modal-type="danger"
        button-text="テンプレートを削除"
        :data="deleteFixPhraseData.data"
        :is-loading="isLoading"
        @click="onClickDeleteFixPhrase"
        @close="isVisibleDeleteFixPhrase = false"
      />
    </div>
  </transition>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

import fixPhraseService from '@/services/fixphrase';
import ListViewFixPhrase from '@/components/page/configs/fixphrase/components/ListViewFixPhrase';
import ModalSelectFixedPhrase from '@/components/features/fixedPhrase/components/ModalSelectFixedPhrase';

export default defineComponent({
  name: 'Survey',
  components: { ListViewFixPhrase, ModalSelectFixedPhrase },
  setup(props, context) {
    const store = useStore();
    const fixPhraseList = ref([]);
    const updateFixPhrase = reactive({
      id: null,
      graduated_id: null,
      name: null,
      line_content: null,
      mail_title: null,
      mail_content: null,
    });
    const fixedPhrase = reactive({
      name: null,
      line_content: null,
      mail_title: null,
      mail_content: null,
    });
    const deleteFixPhraseData = reactive({ data: null });
    const isLoading = ref(false);
    const isVisibleAddFixPhrase = ref(false);
    const isVisibleUpdateFixPhrase = ref(false);
    const isVisibleDeleteFixPhrase = ref(false);

    // watch
    watch(
      () => store.getters['graduateds/selectedGraduatedId'],
      () => fetchAll(),
    );

    // methods
    const fetchAll = async () => {
      const res = await fixPhraseService.postFixPhraseList(
        store.getters['graduateds/selectedGraduatedId'],
      );
      if (res.success === true) fixPhraseList.value = res.fixphrase_list;
      await store.dispatch('page/SET_LOADED');
    };
    const onClickUpdateFixPhrase = async payload => {
      if (
        payload.name === null ||
        payload.name === '' ||
        payload.line_content === null ||
        payload.mail_title === null ||
        payload.mail_content === null
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '全ての項目を入力して下さい',
          type: false,
        });
        return;
      }
      isLoading.value = true;
      const res = await fixPhraseService.postUpdateFixPhrase(payload);
      if (res.success) isVisibleUpdateFixPhrase.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await fetchAll();
      isLoading.value = false;
    };
    const onClickAddFixPhrase = async payload => {
      Object.assign(payload, {
        graduated_id: store.getters['graduateds/selectedGraduatedId'],
      });
      if (
        payload.name === null ||
        payload.name === '' ||
        payload.line_content === null ||
        payload.mail_title === null ||
        payload.mail_content === null
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '全ての項目を入力して下さい',
          type: false,
        });
        return;
      }
      isLoading.value = true;
      const res = await fixPhraseService.postCreateFixPhrase(payload);
      if (res.success) {
        resetFixedPhrase();
        await fetchAll();
        isVisibleAddFixPhrase.value = false;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoading.value = false;
    };
    const onClickDeleteFixPhrase = async payload => {
      if (payload.selected === 'submit') {
        isLoading.value = true;
        const res = await fixPhraseService.postDeleteFixPhrase(payload.data);
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        if (res.success) await fetchAll();
        isLoading.value = false;
      }
      isVisibleDeleteFixPhrase.value = false;
      deleteFixPhraseData.data = null;
    };
    const openModalUpdateFixPhrase = payload => {
      updateFixPhrase.id = payload.id;
      updateFixPhrase.graduated_id = payload.graduated_id;
      updateFixPhrase.name = payload.name;
      updateFixPhrase.line_content = payload.line_content;
      updateFixPhrase.mail_title = payload.mail_title;
      updateFixPhrase.mail_content = payload.mail_content;
      if (updateFixPhrase.id) isVisibleUpdateFixPhrase.value = true;
    };
    const openModalDeleteFixPhrase = payload => {
      deleteFixPhraseData.data = payload;
      if (deleteFixPhraseData.data.id) isVisibleDeleteFixPhrase.value = true;
    };
    const resetFixedPhrase = () => {
      fixedPhrase.name = null;
      fixedPhrase.line_content = null;
      fixedPhrase.mail_title = null;
      fixedPhrase.mail_content = null;
    };
    const onClickCancel = () => {
      resetFixedPhrase();
      isVisibleAddFixPhrase.value = false;
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      isLoading,
      isVisibleAddFixPhrase,
      isVisibleUpdateFixPhrase,
      isVisibleDeleteFixPhrase,
      fixPhraseList,
      updateFixPhrase,
      fixedPhrase,
      deleteFixPhraseData,
      openModalUpdateFixPhrase,
      onClickUpdateFixPhrase,
      onClickAddFixPhrase,
      openModalDeleteFixPhrase,
      onClickDeleteFixPhrase,
      resetFixedPhrase,
      onClickCancel,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.menu {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.templates-count {
  margin-left: 20px;
  > .num {
    margin-left: 20px;
    font-size: 1.6rem;
  }
}

@media (max-width: ($media_query_sp)) {
  .menu {
    display: block;
    margin-bottom: 20px;
    .btn {
      width: 100%;
    }
    .templates-count {
      margin: 20px 0 0;
      text-align: center;
    }
  }
}
</style>
