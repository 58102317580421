<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link :to="{ name: 'ApplicantsOverviewDefault' }">
            応募者一覧
          </router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">応募者登録</span>
      </template>
    </bread-crumb-list>
    <div class="content-warp">
      <div class="wrap-btns">
        <button-base
          button-text="CSVから応募者を登録"
          icon-file-name="plus_circle"
          @click="$router.push({ name: 'ApplicantsRegistration' })"
        />
        <button-base
          button-text="メールアドレスで応募者を登録"
          icon-file-name="plus_circle"
          @click="$router.push({ name: 'ApplicantsEmailRegistration' })"
        />
        <button-base
          button-text="応募者一覧のCSVダウンロードを予約"
          icon-file-name="download"
          @click="$router.push({ name: 'ApplicantsCsvDownload' })"
        />
        <button-base
          button-text="応募者の重複をチェック"
          icon-file-name="checkbox"
          @click="$router.push({ name: 'ApplicantsDoubleCheck' })"
        />
      </div>
      <div class="wrap">
        <ul :options="swiperOption" class="local-nav">
          <li
            v-for="(csvType, i) in CSV_TYPES"
            :key="csvType.route"
            :class="{ active: i === mode }"
            class="swiper-slide"
            @click="
              $route.name !== csvType.route
                ? $router.push({ name: csvType.route })
                : () => {}
            "
          >
            <span class="item">{{ csvType.label }}のCSVを使用する</span>
          </li>
        </ul>
        <div class="content">
          <register-applicant-by-csv
            :csv-type="CSV_TYPES[mode].id"
            :csv-label="CSV_TYPES[mode].label"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import '@/assets/swiper.min.css';

import Breakpoints from '@/defines/breakpoints';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import RegisterApplicantByCsv from '@/components/page/applicants/registration/components/RegisterApplicantByCsv';

export default defineComponent({
  name: 'ApplicantsRegistration',
  components: { BreadCrumbList, RegisterApplicantByCsv },
  setup(props, context) {
    const CSV_TYPES = [
      {
        id: 'mynavi',
        label: 'マイナビ',
        route: 'ApplicantsRegistrationCsvMynavi',
      },
      {
        id: 'recnavi',
        label: 'リクナビ',
        route: 'ApplicantsRegistrationCsvRecnavi',
      },
      {
        id: 'mochica',
        label: 'MOCHICA',
        route: 'ApplicantsRegistrationCsvMochica',
      },
    ];
    const route = useRoute();
    const store = useStore();
    const mode = ref(0);
    watch(
      () => route,
      async () => await initialize(),
    );

    const initialize = async () => {
      mode.value = route.meta.mode;
      await store.dispatch('page/SET_LOADED');
    };

    watch(route, async () => await initialize());
    onBeforeMount(async () => await initialize());
    return {
      CSV_TYPES,
      mode,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: false,
        SimulateTouch: false,
        noSwiping: true,
        noSwipingClass: 'local-nav',
        on: {
          init() {
            const winWid = window.innerWidth;
            if (winWid <= Breakpoints.MIN_PC_SIZE) {
              this.params.noSwiping = false;
              this.update();
            }
          },
        },
      },
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.content {
  padding: 40px 30px;
  background-color: $white;
}
.wrap {
  background: $white;
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}

.wrap-btns {
  background-color: $white;
  padding: 30px;
  button {
    margin: 0 7px 7px 0;
  }
}
</style>
