<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">選択した応募者へのフラグ一括追加</p>
        </div>
        <transition name="fade">
          <div v-if="isEdit">
            <div class="body">
              <div class="message">
                <p>
                  選択した応募者へフラグを一括で追加します。
                  <br />
                  ここで追加したフラグは応募者詳細ページで確認・削除することができます。
                </p>
                <p class="caption">
                  ※一括操作により、自動アクションが適用される可能性があるのでご注意ください
                </p>
              </div>
              <section class="area-table area-applicant-list">
                <p class="title">選択中の応募者</p>
                <selectable-applicant-table
                  class="change-list"
                  :applicants="applicants"
                  :init-selected-applicants="applicants.map(v => v.id)"
                  :is-selectable="true"
                  :is-sortable="false"
                  :is-initial-select-all="true"
                  :invisible-columns="invisibleColumns"
                  @onChangeCurrentSelect="
                    setSelectedApplicants($event.selectedApplicants)
                  "
                />
                <div v-if="errorMessages.applicants" class="flag-error-message">
                  {{ errorMessages.applicants }}
                </div>
              </section>
              <section class="area-select-flag">
                <p class="title">一括追加するフラグ</p>
                <update-applicant-flag
                  :is-edit="true"
                  :on-changed="onChangedFlag"
                  :default-tag-groups="selectedTagGroups.data"
                />
                <div v-if="errorMessages.tagGroups" class="flag-error-message">
                  {{ errorMessages.tagGroups }}
                </div>
              </section>
            </div>
            <div class="btn-col2">
              <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
                キャンセル
              </div>
              <div
                class="btn btn-availability"
                @click="
                  onClickToConfirm($event);
                  postPottosEvent(27);
                "
              >
                フラグの一括追加の確認をする
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade" appear>
          <div v-if="isConfirm">
            <div class="body">
              <div class="message">
                <p>選択した応募者へ一括で追加するフラグを確認してください。</p>
                <p>
                  確認した内容でよろしければ「選択した応募者へフラグの一括追加を行う」をクリックしてください。
                </p>
              </div>
              <section class="area-table area-applicant-list">
                <p class="title">選択中の応募者</p>
                <selectable-applicant-table
                  class="change-list"
                  :applicants="selectedApplicants"
                  :is-selectable="false"
                  :invisible-columns="invisibleColumns"
                />
              </section>
              <section class="area-select-flag">
                <p class="title">一括追加するフラグ</p>
                <update-applicant-flag
                  :is-edit="false"
                  :on-changed="onChangedFlag"
                  :default-tag-groups="confirmSelectedTagGroups.data"
                />
              </section>
            </div>
            <div class="btn-col2">
              <div class="btn btn-unavailable" @click="onClickBack">
                編集に戻る
              </div>
              <div class="btn btn-availability" @click="onClickSettle">
                選択した応募者へフラグの一括追加を行う
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, reactive, ref, watch, nextTick } from 'vue';

import { postPottosEvent } from '@/utils/pottos';
import Breakpoints from '@/defines/breakpoints';
import { onResizeModal } from '@/utils/modal-resize';
import Modal from '@/components/ui/modals/components/Modal';
import UpdateApplicantFlag from '@/components/page/applicants/index/components/UpdateApplicantFlag';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';

export default defineComponent({
  name: 'ModalBulkUpdateFlag',
  components: { Modal, SelectableApplicantTable, UpdateApplicantFlag },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    applicants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickSettle', 'onClickCancel'],
  setup(props, context) {
    const invisibleColumns = ref([]);
    const isEdit = ref(true);
    const isConfirm = ref(false);
    const selectedApplicants = ref([]);
    const selectedTagGroups = reactive({ data: {} });
    const confirmSelectedTagGroups = reactive({ data: {} });
    const errorMessages = reactive({ data: {} });

    // watch
    watch(
      () => props.isVisible,
      v => {
        if (v === true) {
          setSelectedApplicants(props.applicants.map(v => v.id));
          nextTick(() => localResizeModal());
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );

    // methods
    const validate = () => {
      const errs = {};
      if (selectedApplicants.value.length === 0) {
        errs.applicants = '対象の応募者を選択して下さい。';
      }
      if (getAllTagIds().length === 0) {
        errs.tagGroups = 'フラグを選択して下さい。';
      }
      errorMessages.data = errs;
      return errs;
    };
    const getAllTagIds = () => {
      let tags = Object.values(selectedTagGroups.data);
      tags = tags.reduce(
        (accumulator, curr) => accumulator.concat(curr.tags),
        [],
      );
      return tags.map(el => el.id);
    };
    const onClickBack = () => {
      isConfirm.value = false;
      setTimeout(() => (isEdit.value = true), 300);
    };
    const onChangedFlag = tagGroups => {
      selectedTagGroups.data = Object.assign({}, tagGroups);
    };
    const onClickToConfirm = () => {
      if (Object.keys(validate()).length > 0) return;
      const newSelectedTagGroups = Object.values(selectedTagGroups.data).reduce(
        (accumulator, curr) => {
          if (curr.tags.length !== 0) {
            accumulator = Object.assign({}, accumulator, { [curr.id]: curr });
          }
          return accumulator;
        },
        {},
      );
      confirmSelectedTagGroups.data = newSelectedTagGroups;
      isEdit.value = false;
      setTimeout(() => (isConfirm.value = true), 300);
    };
    const onClickSettle = () => {
      context.emit('onClickSettle', {
        applicantIds: selectedApplicants.value.map(el => el.id),
        tagIds: getAllTagIds(),
      });
    };
    const setSelectedApplicants = selectedApplicantIds => {
      selectedApplicants.value = Object.assign(
        [],
        selectedApplicantIds.reduce((accumulator, curr) => {
          const found = props.applicants.find(v => v.id === curr);
          if (found) accumulator.push(Object.assign({}, found));
          return accumulator;
        }, []),
      );
    };
    const getInvisibleColumns = () => {
      return window.innerWidth <= Breakpoints.MAX_SP_SIZE
        ? [
            'event_title',
            'attendanceDate',
            'not_announe_lamp',
            'name_kana',
            'gender',
            'school',
            'faculty',
            'department',
            'department_category',
            'email',
            'tel',
            'contact_type_status',
            'channel',
            'invited_status',
            'invited_at',
            'created_at',
            'activated_at',
            'staff',
          ]
        : [
            'attendanceDate',
            'not_announe_lamp',
            'gender',
            'school',
            'department',
            'department_category',
            'tel',
            'contact_type_status',
          ];
    };
    const resetAll = () => {
      // 親コンポーネントで呼び出されているので注意
      isEdit.value = true;
      isConfirm.value = false;
      selectedTagGroups.data = {};
      confirmSelectedTagGroups.data = {};
    };
    const localResizeModal = () =>
      onResizeModal(props.isVisible, {
        updateInvisibleColumns: () =>
          (invisibleColumns.value = getInvisibleColumns()),
      });

    return {
      isEdit,
      isConfirm,
      selectedApplicants,
      selectedTagGroups,
      confirmSelectedTagGroups,
      errorMessages,
      invisibleColumns,
      validate,
      getAllTagIds,
      onClickBack,
      onChangedFlag,
      onClickToConfirm,
      onClickSettle,
      setSelectedApplicants,
      resetAll,
      postPottosEvent,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.content {
  border-radius: 1rem;
  // height: 80rem;
  .header {
    padding: 2rem;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    font-size: 1.2rem;
    padding: 20px;
    .body-inner {
      overflow-y: auto;
      // height: 650px;
    }
    .message {
      padding: 20px 0 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      p {
        line-height: 1.8;
      }
      .caption {
        color: $mochica_color_red;
        font-size: $font_12;
        font-weight: bold;
      }
    }
    section {
      margin-bottom: 20px;
      .title {
        text-align: left;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .area-table {
        &.change-list {
          margin-bottom: 10px;
          width: 100%;
          height: 30vh;
          border: 1px solid $border_color;
          border-top: none;
          text-align: left;
          tr {
            border-bottom: 1px solid $border_color;
          }
        }
      }
      &.area-select-flag {
        min-height: 120px;
      }
    }
    .flag-error-message {
      text-align: left;
      font-size: 1.3rem;
      color: $mochica_color_red;
    }
    .area_btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      .item {
        width: 49.5%;
        height: 3.6rem;
        line-height: 3.6rem;
        background: $mochica_color;
        border-radius: 5rem;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        &.back_btn {
          background: #fff;
          border: 1px solid $border_color;
          color: #333;
          font-weight: normal;
        }
      }
    }
  }
}
.btn-col2 {
  padding: 10px;
}
@media (max-width: ($media_query_sp)) {
  .content .body {
    height: calc(100vh - 200px);
  }
}
</style>
