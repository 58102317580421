<template>
  <div ref="refSelectEventPopup" class="tag-context-menu basic-box-shadow">
    <div class="row-input flexbox flexbox-middle">
      <div v-if="isAddable" class="text-wrapper">
        <input
          ref="refNewItem"
          v-model="textNewItem"
          type="text"
          :placeholder="'選考名を新規登録（20文字まで）'"
          maxlength="20"
        />
      </div>
      <button
        :disabled="isDisabled === true"
        class="btn-wrapper"
        @click="onClickNewItem()"
      >
        <div class="btn-add">
          <img
            class="flag-add-btn-svg"
            src="@/assets/img/add_circle_gray.svg"
          />
        </div>
      </button>
    </div>
    <ul>
      <li
        v-for="item in list"
        :key="item.id"
        :class="{ selected: selectId === item.id }"
        class="un-selectable"
        @click="$emit('onClickItem', item)"
      >
        <div class="li-inner">{{ item.name }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

export default defineComponent({
  name: 'PopupSelectEvent',
  props: {
    isAddable: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    selectId: {
      type: Number,
      default: null,
    },
  },
  emits: ['onClickItem', 'onClickNewItem', 'close'],
  setup(props, context) {
    const refNewItem = ref(null);
    const refSelectEventPopup = ref(null);
    const isDisabled = ref(false);
    const textNewItem = ref('');

    // methods
    const onClickNewItem = async () => {
      isDisabled.value = true;
      if (textNewItem.value) {
        context.emit('onClickNewItem', { name: textNewItem.value });
      }
      focusNewItem();
    };
    const focusNewItem = () => {
      refNewItem.value.focus();
    };
    const onClickOuter = ev => {
      if (
        refSelectEventPopup.value &&
        refSelectEventPopup.value.contains(ev.target)
      ) {
        // 自分のコンポーネント内の要素をクリックした場合は閉じない
        return;
      }
      context.emit('close');
    };
    const reset = () => {
      isDisabled.value = false;
    };

    // lifecycle
    onMounted(() => {
      window.addEventListener('click', onClickOuter, true);
      focusNewItem();
    });
    onUnmounted(() => {
      window.removeEventListener('click', onClickOuter, true);
    });

    return {
      refNewItem,
      refSelectEventPopup,
      isDisabled,
      textNewItem,
      onClickNewItem,
      reset,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.btn-add {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
}
.tag-context-menu {
  position: absolute;
  border: solid 1px #f2f9fc;
  background: white;
  min-width: 24rem;
  top: 2rem;
  left: 0;
  z-index: 5;
  .row-input {
    padding: 1rem;
    .text-wrapper {
      width: 100%;
      input {
        width: 100%;
        border: none;
        padding: 0;
        font-size: 1.2rem;
      }
    }
    .btn-wrapper {
      margin-left: auto;
    }
  }
  ul {
    max-height: 30rem;
    overflow: auto;
    li {
      padding: 0 1rem;
      height: 3.6rem;
      line-height: 3.6rem;
      cursor: pointer;
      font-size: 1.2rem;
      transition: all ease 0.3s;
      &:not(:last-child) {
        border-bottom: dashed 1px $border_color;
      }
      &:hover {
        background: #fafafa;
      }
    }
    li.selected {
      color: $mochica_color;
      font-weight: bold;
      background: #fafafa;
    }
  }
}
</style>
