<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link to="/announce">応募者への通知</router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">
          連絡事項を通知する
        </span>
      </template>
    </bread-crumb-list>
    <div class="content-warp">
      <div class="content">
        <div class="headline-typeC">連絡事項を通知する</div>
        <div class="btn-notice-create-search-wrapper">
          <button class="btn btn-availability" @click="openCreateModal">
            <img class="icon" src="@/assets/img/add_white.svg" alt="" />
            <span slot="text">連絡事項の新規作成</span>
          </button>
          <button-base
            class="btn btn-notice-history-search"
            button-text="絞り込み検索"
            icon-file-name="search_white"
            @click="isVisibleFloatNoticeHistorySearch = true"
          />
          <float-notice-history-search
            :is-visible="isVisibleFloatNoticeHistorySearch"
            @onClose="isVisibleFloatNoticeHistorySearch = false"
            @onSearch="onSearch"
            @onReset="() => {}"
          />
          <pager
            class="pager"
            :page="page"
            :count="count"
            :page-count="pageCount"
            @move="movePager"
          />
        </div>
        <div class="notice_table">
          <table class="massage-table">
            <thead class="thead">
              <tr class="thead-row">
                <th class="thead-th"><span>連絡事項タイトル</span></th>
                <th class="thead-th"><span>添付ファイル</span></th>
                <th class="thead-th"><span>送信先</span></th>
                <th class="thead-th"><span>担当者</span></th>
                <th class="thead-th"><span>配信</span></th>
                <th class="thead-th"><span>配信予約日時</span></th>
                <th class="thead-th"><span>送信エラー</span></th>
                <th class="thead-th"><span>作成日</span></th>
                <th class="thead-th"><span>機能</span></th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr v-for="item in noticeLists" :key="item.id" class="tbody-row">
                <td class="tbody-td" @click="openViewModal(item)">
                  <span>{{ $utils.textEllipsis(item.notice_title) }}</span>
                </td>
                <td class="tbody-td" @click="openViewModal(item)">
                  <span>
                    {{ item.files_length > 0 ? 'あり' : 'なし' }}
                  </span>
                </td>
                <td class="tbody-td" @click="openViewModal(item)">
                  <span>{{ item.applicant_notices.length }} 名</span>
                </td>
                <td class="tbody-td" @click="openViewModal(item)">
                  <span>
                    {{ item.staff === null ? '' : item.staff.lastname
                    }}{{ item.staff === null ? '' : item.staff.firstname }}
                  </span>
                </td>
                <td class="tbody-td" @click="openViewModal(item)">
                  <span
                    :class="{
                      red: sendStatus(item) === '済み',
                      fail: sendStatus(item) === '失敗',
                      green: sendStatus(item) === 'する',
                    }"
                  >
                    {{ sendStatus(item) }}
                  </span>
                </td>
                <td class="tbody-td" @click="openViewModal(item)">
                  <span>
                    {{
                      $utils.parseDateTime(
                        item.schedule_time,
                        'YYYY年MM月DD日 HH:mm',
                      )
                    }}
                  </span>
                </td>
                <td
                  v-if="item.announced_at !== null"
                  class="tbody-td"
                  @click="openViewModal(item)"
                >
                  <span>{{ item.error_count }} 名</span>
                </td>
                <td v-else class="tbody-td" @click="openViewModal(item)">
                  <span></span>
                </td>
                <td class="tbody-td" @click="openViewModal(item)">
                  <span>
                    {{
                      $utils.parseDateTime(
                        item.created_at,
                        'YYYY年MM月DD日 HH:mm',
                      )
                    }}
                  </span>
                </td>
                <td class="tbody-td" @click="openModalDuplication(item)">
                  <button class="btn btn-availability">
                    <img
                      slot="icon"
                      height="16"
                      src="@/assets/img/duplicate_white.svg"
                      alt=""
                    />
                    <span slot="text">複製</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pager
          class="pager"
          :page="page"
          :count="count"
          :page-count="pageCount"
          @move="movePager"
        />
      </div>
    </div>
    <modal-notice-detail
      v-if="clickedItem.data.applicant_notices"
      :is-visible="isVisibleModalNoticeView"
      :item="clickedItem.data"
      @onClickOpenEditModal="onClickOpenEditModal"
      @onClickUpdateSubmit="onClickUpdateSubmit"
      @onClickSendNoticeSubmit="onClickSendNoticeSubmit"
      @onClickCancel="onClickCancel"
      @openCreateModalAndSetApplicants="openCreateModalAndSetApplicants"
    />
    <modal-edit-notice
      ref="refModalEditNotice"
      :notice-items="clickedItem.data"
      :send-limit="sendLimit"
      :is-visible="isVisibleModalEditNotice"
      :selected-applicants="selectedApplicants"
      :is-copy-create="isCopyCreate"
      @onClickCreateSubmit="onClickCreateSubmit"
      @onClickUpdateSubmit="onClickUpdateSubmit"
      @onClickCloseEditModal="onClickCloseCreateModal"
      @onClickCancel="onClickCancel"
    />
    <modal-window
      :is-visible="isVisibleModalDuplication"
      :is-loading="isLoading"
      title="連絡事項の複製"
      message="連絡事項の複製を行います。よろしいですか？"
      button-text="連絡事項を複製"
      @click="notificationDuplicate"
      @close="isVisibleModalDuplication = false"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  nextTick,
} from 'vue';
import { useStore } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import NotificationService from '@/services/notifications';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import Pager from '@/components/ui/pager/components/Pager';
import ModalNoticeDetail from '@/components/features/modalNoticeDetail/components/ModalNoticeDetail';
import ModalEditNotice from '@/components/features/modalNoticeDetail/components/ModalEditNotice';
import FloatNoticeHistorySearch from '@/components/page/announce/noticeannounce/components/FloatNoticeHistorySearch';

export default defineComponent({
  name: 'NoticeAnnounce',
  components: {
    BreadCrumbList,
    Pager,
    ModalNoticeDetail,
    ModalEditNotice,
    FloatNoticeHistorySearch,
  },
  setup(props, context) {
    const INITIAL_NOTICE = {
      applicant_notices: [
        {
          announced_at: '',
          applicant: { id: null, lastname: '', firstname: '' },
        },
      ],
      staff: { lastname: '', firstname: '' },
      notice_check: false,
    };
    const store = useStore();
    const refModalEditNotice = ref(null);
    const isLoading = ref(false);
    const isVisibleModalDuplication = ref(false);
    const isVisibleModalNoticeView = ref(false);
    const isVisibleModalEditNotice = ref(false);
    const isVisibleFloatNoticeHistorySearch = ref(false);
    const isCopyCreate = ref(false);
    const duplicationNoticeId = ref(null);
    const page = ref(1);
    const count = ref(0);
    const pageCount = ref(0);
    const sendLimit = ref(1000);
    const searchBody = reactive({ data: {} });
    const noticeLists = ref([]);
    const noticeListsIds = ref([]);
    const selectedApplicants = ref([]);
    const clickedItem = reactive({ data: {} });

    // methods
    const onSearch = async tmpSearchBody => {
      searchBody.data = tmpSearchBody;
      movePage(1);
      await getAnnounceList(page.value, tmpSearchBody);
      isVisibleFloatNoticeHistorySearch.value = false;
    };
    const getAnnounceList = async (tmpPage = 1, tmpSearchBody = {}) => {
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      const res = await NotificationService.getAnnounceList(
        graduatedYear.year,
        tmpPage,
        tmpSearchBody,
      );
      noticeListsIds.value = res.list.map(v => v.id);
      noticeLists.value = res.list;
      count.value = res.count;
      pageCount.value = res.page_count;
      page.value = tmpPage;
      await getAnnounceErrorList();
    };
    const getAnnounceErrorList = async () => {
      const res = await NotificationService.getAnnounceErrorList({
        notice_ids: noticeListsIds.value,
      });
      if (res.success === true) {
        const listObject = noticeLists.value;
        Object.assign(listObject, res.list);
        noticeLists.value = res.list;
      }
    };
    const notificationDuplicate = async ({ selected }) => {
      if (selected !== 'submit') {
        isVisibleModalDuplication.value = false;
        return;
      }
      isLoading.value = true;
      const res = await NotificationService.notificationDuplicate({
        notice_id: duplicationNoticeId.value,
        applicant_ids: [],
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) await getAnnounceList();
      isVisibleModalDuplication.value = false;
      isLoading.value = false;
    };
    const onClickUpdateSubmit = async () => {
      await getAnnounceList();
      isVisibleModalNoticeView.value = false;
      isVisibleModalEditNotice.value = false;
    };
    const onClickSendNoticeSubmit = async () => {
      await getAnnounceList();
      isVisibleModalNoticeView.value = false;
    };
    const onClickCreateSubmit = async () => {
      await getAnnounceList();
      isCopyCreate.value = false;
      isVisibleModalEditNotice.value = false;
      refModalEditNotice.value.resetLocal();
    };
    const openViewModal = async item => {
      const noticeItem = await NotificationService.getNoticeFromId(item.id);
      clickedItem.data = noticeItem.notice_data;
      nextTick(() => {
        isVisibleModalNoticeView.value = true;
      });
    };
    const movePage = page => {
      getAnnounceList(page, searchBody.data);
    };
    const movePager = page => {
      movePage(page.next);
    };
    const sendStatus = item => {
      if (item.announced_at !== null) return '済み';
      if (
        moment(item.schedule_time).format('YYYY-MM-DD HH:mm:ss') <
          moment().format('YYYY-MM-DD HH:mm:ss') &&
        item.is_active
      )
        return '失敗';
      if (item.is_active) return 'する';
      if (!item.is_active) return 'しない';
      return '';
    };
    const openModalDuplication = item => {
      isVisibleModalDuplication.value = true;
      duplicationNoticeId.value = item.id;
    };
    // 連絡事項新規作成
    const openCreateModal = () => {
      clickedItem.data = cloneDeep(INITIAL_NOTICE);
      isVisibleModalEditNotice.value = true;
    };
    const openCreateModalAndSetApplicants = tmpSelectedApplicants => {
      onClickCancel();
      isCopyCreate.value = true;
      selectedApplicants.value = tmpSelectedApplicants;
      isVisibleModalEditNotice.value = true;
    };
    const onClickCloseCreateModal = () => {
      isCopyCreate.value = false;
      isVisibleModalEditNotice.value = false;
    };
    // 連絡事項編集
    const onClickOpenEditModal = () => {
      isVisibleModalNoticeView.value = false;
      isVisibleModalEditNotice.value = true;
    };
    const onClickCancel = () => {
      isVisibleModalNoticeView.value = false;
      isVisibleModalEditNotice.value = false;
      isCopyCreate.value = false;
      selectedApplicants.value = [];
    };

    // lifecycle
    onBeforeMount(async () => {
      await getAnnounceList();
      await store.dispatch('page/SET_LOADED');
      clickedItem.data.value = cloneDeep(INITIAL_NOTICE);
    });
    onMounted(() => {
      page.value = 1;
      // storeに新規連絡事項作成対象応募者が保持されている場合、画面表示後すぐに新規作成モーダルを開く。
      if (store.getters['announce/createAnnounceTargetApplicants'].length > 0) {
        openCreateModalAndSetApplicants(
          store.getters['announce/createAnnounceTargetApplicants'],
        );
        // storeの対象応募者をリセット
        store.dispatch('announce/SET_CREATE_ANNOUNCE_TARGET_APPLICANTS', []);
      }
    });

    return {
      refModalEditNotice,
      isLoading,
      isVisibleModalDuplication,
      isVisibleModalNoticeView,
      isVisibleModalEditNotice,
      isVisibleFloatNoticeHistorySearch,
      isCopyCreate,
      duplicationNoticeId,
      page,
      count,
      pageCount,
      sendLimit,
      noticeLists,
      selectedApplicants,
      clickedItem,
      onSearch,
      notificationDuplicate,
      onClickUpdateSubmit,
      onClickSendNoticeSubmit,
      onClickCreateSubmit,
      openViewModal,
      sendStatus,
      movePager,
      openModalDuplication,
      onClickOpenEditModal,
      onClickCancel,
      openCreateModalAndSetApplicants,
      openCreateModal,
      onClickCloseCreateModal,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.content {
  padding: 40px 30px;
  background: #fff;
}

.massage-table {
  width: 100%;
  white-space: nowrap;
  margin-bottom: 20px;
  .thead-th {
    padding: 15px 10px;
    text-align: left;
    background-color: #e2e2e2;
  }
  .tbody-row {
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  }
  .tbody-td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
}

.sec_notice_create {
  background: #fff;
  border-radius: 0.3rem;
  margin: 2rem 2rem 10rem 2rem;
  padding: 2rem;
}

.sec_notice_create_title {
  margin-bottom: 2rem;
}

.btn-notice-create-search-wrapper {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  .pager {
    margin-left: auto;
  }
  .btn-notice-history-search {
    margin-left: 10px;
    padding: 10px 55px 10px 15px;
    background-image: url('../../../assets/img/rect_arrow_down_white.svg');
    background-position: right 10px center;
    background-size: 10px;
    background-repeat: no-repeat;
  }
}

.notice_table {
  overflow-x: auto;
  .red {
    color: $mochica_color_red;
  }
  .green {
    color: #1dcd00;
  }
  .fail {
    color: $mochica_color_red;
    font-weight: bold;
  }
}

.notice_create {
  background: $mochica_color;
  padding: 0 2rem;
  margin-right: 1rem;
  border-radius: 0.4rem;
  font-weight: bold;
  border: 0.1rem solid $mochica_border_color;
  height: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  span {
    color: $white;
  }
}

.balloon-box {
  left: 182px !important;
}

.btn-availability {
  > img {
    margin-right: 7px;
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px;
  }
  .btn-notice-create-search-wrapper {
    display: block;
    .pager {
      display: none;
    }
    .btn {
      width: 100%;
      text-align: left;
    }
    .btn-notice-history-search {
      margin: 10px 0 0 0;
    }
  }
  .balloon-box {
    top: 85px !important;
    left: 0 !important;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
