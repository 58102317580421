import httpClient from '@/http-client';

const ZoomManager = function _ZoomManager() {
  const manager = Object.create(ZoomManager.prototype);
  return manager;
};

ZoomManager.prototype = {
  async checkLinkZoom() {
    const res = await httpClient.get('/zoom/link');
    return res.data;
  },
  async linkZoom(payload) {
    const res = await httpClient.post('/zoom/link', payload);
    return res.data;
  },
  async resetLinkZoom() {
    const res = await httpClient.delete('/zoom/link');
    return res.data;
  },
  async createZoomAccessToken(payload) {
    const res = await httpClient.post('/zoom/access_token', payload);
    return res.data;
  },
};

export default ZoomManager();
