import { getPlanDataById } from '@/defines/plan';

export default {
  namespaced: true,
  state: {
    plan: null,
  },
  getters: {
    plan: currentState => currentState.plan,
    isFreePlan: currentState =>
      getPlanDataById(currentState.plan.id).name === 'free',
  },
  mutations: {
    SET_PLAN(state, plan) {
      Object.assign(state, { plan });
    },
    CLEAR_PLAN(state) {
      Object.assign(state, { plan: null });
    },
  },
  actions: {
    async SET_PLAN({ commit }, plan) {
      commit('SET_PLAN', plan);
    },
    async CLEAR_COMPANY({ commit }) {
      commit('CLEAR_PLAN');
    },
  },
};
