<template>
  <div class="area-selection">
    <bread-crumb-list>
      <template #breadcrumb>
        <span>カレンダー</span>
      </template>
    </bread-crumb-list>

    <section class="content-warp">
      <div class="content">
        <div class="selections-content">
          <venue-calendar />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import VenueCalendar from '@/components/features/venueCalendar/components/VenueCalendar';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';

export default defineComponent({
  name: 'Calendar',
  components: {
    BreadCrumbList,
    VenueCalendar,
  },
  setup(props, context) {
    const store = useStore();
    onBeforeMount(async () => {
      await store.dispatch('page/SET_LOADED');
    });
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content-warp {
  padding-bottom: 20px;
}

.content {
  background: #fff;
  border-radius: 4px 4px 0 0;
}
</style>
