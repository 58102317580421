import cloneDeep from 'lodash.clonedeep';
import { ref } from 'vue';

export default function useSearchFlagGroupOptions(flagGroups = []) {
  /**
   * FlagGroupOptions
   * [{ id: number, eq: Boolean, exist: ?Boolean, selectOption: Number }]
   */
  const searchFlagGroupOptions = ref(cloneDeep(flagGroups));
  const visibleFlagGroupOptions = ref([]);
  // 詳細検索条件のデフォルト値
  const selectableSearchFlagGroupOptionsDefault = [
    { id: 1, name: '含む' },
    { id: 0, name: '含まない' },
    { id: 3, name: '存在する' },
    { id: 2, name: '存在しない' },
  ];

  // methods
  /**
   * FlagGroupOptionを追加
   */
  const addFlagGroupOption = () => {
    searchFlagGroupOptions.value.push({
      id: 1,
      eq: true,
      exist: null,
      selectOption: 1,
    });
  };
  /**
   * FlagGroupOptionを削除
   * @param {Number} i
   */
  const removeFlagGroupOption = i => {
    searchFlagGroupOptions.value.splice(i, 1);
  };
  /**
   * FlagGroupOptionを更新
   * @param {Number} value
   * @param {Number} i
   */
  const updateFlagGroupOption = (value, i) => {
    const valNum = parseInt(value, 10);
    if (valNum < 2) {
      searchFlagGroupOptions.value[i].eq = valNum;
      searchFlagGroupOptions.value[i].exist = null;
    } else {
      searchFlagGroupOptions.value[i].eq = 1;
      searchFlagGroupOptions.value[i].exist = valNum - 2;
    }
    searchFlagGroupOptions.value[i].selectOption = valNum;
  };
  /**
   * FlagGroupOptionをリセット
   */
  const resetFlagGroupOptions = () => {
    searchFlagGroupOptions.value = [];
    visibleFlagGroupOptions.value = [];
  };
  /**
   * FlagGroupOptionのDropDownを開く
   * @param {number} optionId 検索オプションのID
   */
  const openDropDownFlagGroupOption = optionId => {
    closeAllFlagGroupOptionsDropDown();
    visibleFlagGroupOptions.value[optionId] = true;
  };
  /**
   * flagGroupOptionのDropDownを閉じる
   */
  const closeAllFlagGroupOptionsDropDown = () => {
    visibleFlagGroupOptions.value = visibleFlagGroupOptions.value.map(
      x => false,
    );
  };
  /**
   * プリセットを読み込む
   * @param {Object[]} newSearchFlagGroupOptions
   */
  const loadFlagGroupOptionPreset = newSearchFlagGroupOptions => {
    searchFlagGroupOptions.value = cloneDeep(newSearchFlagGroupOptions);
  };

  return {
    searchFlagGroupOptions,
    visibleFlagGroupOptions,
    selectableSearchFlagGroupOptionsDefault,
    addFlagGroupOption,
    removeFlagGroupOption,
    updateFlagGroupOption,
    resetFlagGroupOptions,
    openDropDownFlagGroupOption,
    closeAllFlagGroupOptionsDropDown,
    loadFlagGroupOptionPreset,
  };
}
