<script setup>
defineProps({
  isView: {
    type: Boolean,
    default: false,
  },
  actions: {
    type: Array,
    default: () => [],
  },
  actionType: {
    type: String,
    default: '',
  },
  targetName: {
    type: String,
    default: '',
  },
  operationName: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['close']);
</script>
<template>
  <modal-window
    :is-visible="isView"
    :max-width="'700px'"
    :title="`この${targetName}は${operationName}できません`"
    modal-type="danger"
    @close="emit('close')"
    @clickOuter="emit('close')"
  >
    <template #content>
      <div class="modal-contents">
        <div class="modal-contents-text">
          <div>
            <div class="top-text-block">
              この{{ targetName }}は以下の自動アクションの「{{
                actionType
              }}」で選択されているため、{{ operationName }}できません。
            </div>
            <ul class="action-list">
              <li
                v-for="action in actions"
                :key="action.id"
                class="action-list-item"
              >
                {{ action.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-contents-bottom">
          <button-base
            :button-text="'キャンセル'"
            button-type="secondary"
            @click="emit('close')"
          />
        </div>
      </div>
    </template>
  </modal-window>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.modal-contents {
  padding: 24px;
  &-text {
    white-space: pre-line;
  }
  &-bottom {
    display: flex;
    gap: 8px;
    margin-top: 40px;
    button {
      height: 52px;
      width: 100%;
    }
  }
  .top-text-block {
    line-height: 1.8;
    margin-bottom: 18px;
  }
  .action-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 0 24px;
    padding: 16px 20px 18px;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
  }
  .action-list-item {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    line-height: 1.5;
    font-size: $font_14;
    &::before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      margin: 8px 0 0;
      border-radius: 50%;
      background: #777;
    }
  }
}
</style>
