<template>
  <section v-if="$store.getters['page/isPageLoaded']" class="content-warp">
    <ul :options="swiperOption" class="local-nav">
      <li :class="{ active: mode === 0 }">
        <span class="item" @click="changeTab('PersonalPage')">個人設定</span>
      </li>
      <li :class="{ active: mode === 1 }">
        <span class="item" @click="changeTab('CompanyPage')">会社設定</span>
      </li>
      <li :class="{ active: mode === 2 }">
        <span class="item" @click="changeTab('StaffsPage')">社員管理</span>
      </li>
      <li :class="{ active: mode === 3 }">
        <span class="item" @click="changeTab('AutoActions')">
          自動アクション
        </span>
      </li>
      <li :class="{ active: mode === 4 }">
        <span class="item" @click="changeTab('EntryformSettingPage')">
          エントリーフォーム
        </span>
      </li>

      <li :class="{ active: mode === 5 }">
        <span class="item" @click="changeTab('Survey')">Googleフォーム</span>
      </li>
      <li :class="{ active: mode === 6 }">
        <span class="item" @click="changeTab('FixPhrase')">テンプレート</span>
      </li>
      <li :class="{ active: mode === 7 }">
        <span class="item" @click="changeTab('Plan')">料金プラン</span>
      </li>
      <li
        v-if="$store.getters['staff/staff'].role.id === 1"
        :class="{ active: mode === 8 }"
      >
        <span class="item" @click="changeTab('LinkSystem')">システム連携</span>
      </li>
    </ul>

    <div slot="content" class="content">
      <router-view />
    </div>
  </section>
</template>

<script>
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import '@/assets/swiper.min.css';

import Breakpoints from '@/defines/breakpoints';

export default defineComponent({
  name: 'Configs',
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const mode = ref(0);
    watch(
      () => route,
      () => (mode.value = route.meta.mode),
    );

    const init = async () => {
      mode.value = route.meta.mode;
      await store.dispatch('page/SET_LOADED');
    };

    watch(route, async () => await init());

    const changeTab = key => {
      if (route.name !== key) {
        router.push({ name: key });
      }
    };

    onBeforeMount(async () => await init());

    return {
      mode,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: false,
        SimulateTouch: false,
        noSwiping: true,
        noSwipingClass: 'local-nav',
        on: {
          init() {
            const winWid = window.innerWidth;
            if (winWid <= Breakpoints.MIN_PC_SIZE) {
              this.params.noSwiping = false;
              this.update();
            }
          },
        },
      },
      changeTab,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content-warp {
  min-height: calc(100vh - 50px);
  .local-nav {
    overflow-x: auto;
  }
}
.content {
  padding: 40px 30px;
  background: #fff;
}

@media (max-width: ($media_query_tablet)) {
  .content {
    padding: 40px;
  }
}
@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px math_size(20);
  }
}
</style>
