<template>
  <div class="form__wrapper">
    <div class="form__inner">
      <div class="form__inner__contents">
        <div class="flexbox width-sp-limit">
          <div v-if="!isVisiblePolicy" class="contents">
            <div class="form-wrapper contents_wrapper">
              <p class="form_desc">
                <img
                  class="logo-title"
                  src="@/assets/img/mochica_join.svg"
                  alt
                />
                あなたはMOCHICAに招待されています。以下の項目に入力の上、登録ボタンを押してください。
              </p>
              <form>
                <div class="form_row">
                  <label>姓名</label>
                  <input
                    v-model="lastname"
                    type="text"
                    :class="{
                      input: true,
                      'is-danger': !errors.lastname.isValid,
                    }"
                    :maxlength="NAME_LENGTH_MAX"
                    placeholder="姓"
                    @blur="validateName('lastname', lastname)"
                  />
                  <p v-if="!errors.lastname.isValid" class="fa-warning">
                    {{ errors.lastname.message }}
                  </p>
                  <input
                    v-model="firstname"
                    type="text"
                    :class="{
                      input: true,
                      'is-danger': !errors.firstname.isValid,
                    }"
                    :maxlength="NAME_LENGTH_MAX"
                    placeholder="名"
                    @blur="validateName('firstname', firstname)"
                  />
                  <p v-if="!errors.firstname.isValid" class="fa-warning">
                    {{ errors.firstname.message }}
                  </p>
                </div>
                <div class="form_row">
                  <label>パスワード</label>
                  <input
                    v-model="password"
                    type="password"
                    auto-complete="off"
                    :class="{
                      input: true,
                      pass_input: true,
                      'is-danger': !errors.password.isValid,
                    }"
                    placeholder="パスワードを入力"
                    @blur="validatePassword('password', password)"
                  />
                  <p class="password_caption">
                    半角英数字・8〜64文字・大文字を1文字以上含んだ形式で入力してください
                  </p>
                  <span v-show="!errors.password.isValid" class="fa fa-warning">
                    {{ errors.password.message }}
                  </span>
                </div>
                <div class="form_row">
                  <label>パスワード（確認用）</label>
                  <input
                    v-model="confirmPassword"
                    type="password"
                    auto-complete="off"
                    :class="{
                      input: true,
                      pass_input: true,
                      'is-danger': !errors.confirmPassword.isValid,
                    }"
                    placeholder="パスワード（確認用）を入力"
                  />
                  <span
                    v-show="!errors.confirmPassword.isValid"
                    class="fa fa-warning"
                  >
                    {{ errors.confirmPassword.message }}
                  </span>
                </div>
                <div class="check_kiyaku">
                  <div class="check_kiyaku_block">
                    <input
                      id="form_kiyaku"
                      v-model="checked"
                      class="input_kiyaku"
                      type="checkbox"
                      name="kiyaku"
                    />
                    <label for="form_kiyaku">MOCHICAの</label>
                    <a @click="toPolicy">利用規約</a>
                    に同意する
                  </div>
                </div>
                <button class="register_btn" @click.prevent="postSignup">
                  登録
                </button>
              </form>
            </div>
          </div>
          <notification />
          <form-policy
            v-if="isVisiblePolicy"
            @backFromPolicy="backFromPolicy"
          />
        </div>
      </div>
      <!-- form__inner__contents -->
    </div>
    <!-- form__inner -->
  </div>
  <!-- form__wrapper -->
</template>

<script>
import qs from 'qs';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import staffService from '@/services/staffs';
import useValidation from '@/composables/useValidation';
import Notification from '@/components/ui/notification/components/Notification';
import FormPolicy from '@/components/features/policy/components/FormPolicy';

export default defineComponent({
  name: 'Join',
  components: { Notification, FormPolicy },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const {
      errors,
      NAME_LENGTH_MAX,
      resetErrors,
      validateName,
      validatePassword,
      validateComparisonPassword,
    } = useValidation(['firstname', 'lastname', 'password', 'confirmPassword']);
    const lastname = ref('');
    const firstname = ref('');
    const division = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const checked = ref(false);
    const isVisiblePolicy = ref(false);

    // methods
    const postSignup = async () => {
      const validRes = validateAll();
      if (validRes.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: validRes.message,
          type: false,
        });
        return;
      }
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      const res = await staffService.registerStaffs({
        staff: {
          firstname: firstname.value,
          lastname: lastname.value,
          password: password.value,
          confirmation: confirmPassword.value,
          token: query.token,
        },
      });
      if (res.data.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: res.data.success,
        });
        return false;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '登録が完了しました。',
        type: res.data.success,
      });
      reset();
      return false;
    };
    const reset = () => {
      firstname.value = '';
      lastname.value = '';
      password.value = '';
      confirmPassword.value = '';
      resetErrors();
      router.replace({ path: '/' });
    };
    const validateAll = () => {
      if (checked.value !== true) {
        return {
          success: false,
          message: '利用規約に同意してください',
        };
      }
      resetErrors();
      const isValidLastname = validateName('lastname', lastname.value);
      const isValidFirstname = validateName('firstname', firstname.value);
      const isValidPassword = validatePassword('password', password.value);
      if (
        !isValidLastname ||
        !isValidFirstname ||
        !isValidPassword ||
        !validateComparisonPassword(password.value, confirmPassword.value)
      ) {
        return {
          success: false,
          message: '入力内容を確認して下さい',
        };
      }
      return { success: true, message: '' };
    };
    const toPolicy = () => {
      isVisiblePolicy.value = true;
    };
    const backFromPolicy = () => {
      isVisiblePolicy.value = false;
    };

    return {
      lastname,
      firstname,
      division,
      password,
      confirmPassword,
      checked,
      isVisiblePolicy,
      errors,
      NAME_LENGTH_MAX,
      validateName,
      validatePassword,
      postSignup,
      toPolicy,
      backFromPolicy,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include form_wrapper_css();
@include validation_effects();

.logo-title {
  width: 100%;
  fill: #88c6ed;
  display: block;
  margin: 0 auto 0px;
}

.contents_wrapper {
  padding: 0 8%;
}

.register_btn {
  width: 100%;
  border: none;
  background-color: #88c6ed;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  border-radius: 20px;
  cursor: pointer;
}

/*form*/
.form-wrapper {
  padding-bottom: 40px;
}
.form_desc {
  border-bottom: 1px solid #88c6ed;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.form_desc .logo-title {
  margin-bottom: 30px;
}

.form_row label {
  margin-bottom: 6px;
  padding: 0 !important;
  border: none !important;
  display: block;
  height: auto;
  color: #88c6ed;
  font-weight: bold;
}
.form_row input {
  height: 40px;
  border: 1px solid #efefef;
  box-shadow: none;
}
.form_row input + input {
  margin-top: 10px;
}
.form_row input[type='radio'] {
  height: auto;
}

.check_kiyaku {
  padding-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  .check_kiyaku_block {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      margin-right: 10px;
    }
    a {
      color: #88c6ed;
      text-decoration: underline;
      display: inline;
      cursor: pointer;
    }
  }
}

/* 元の記述 */
.flexbox {
  width: 100%;
  .contents {
    width: 100%;
    .contents_wrapper {
      padding: 0 20%;
    }
    .form-wrapper {
      width: 100%;
      .form_row {
        border-bottom: none;
        margin-bottom: 3rem;
        padding-bottom: 0;
        input {
          padding: 6px 13px;
          font-size: 1.4rem;
          width: 100%;
        }
        input::placeholder {
          color: #e4e4e4;
        }
      }
      .form_desc {
        text-align: center;
        img {
          max-width: 30rem;
          margin: 0 auto 2rem;
        }
      }
    }
  }
}
.form__wrapper {
  background: #88c6ed;
}

.password_caption {
  font-size: 12px;
}
</style>
