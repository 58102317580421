import { createApp } from 'vue';

import 'sanitize.css';

import ElementPlus from 'element-plus';
import ja from 'element-plus/dist/locale/ja';
import 'element-plus/theme-chalk/index.css';

import '@/assets/common.css';
import App from '@/App.vue';
import router from '@/router';
import store from '@/stores';
import gtmOption from '@/plugins/gtm';
import VueGtm from '@gtm-support/vue-gtm';

import globalUtils from '@/utils/global-utils';
import ButtonBase from '@/components/ui/buttons/components/ButtonBase.vue';
import ModalWindow from '@/components/ui/modals/components/ModalWindow.vue';

export const app = createApp(App);

app.component('ButtonBase', ButtonBase);
app.component('ModalWindow', ModalWindow);
app.use(router);
app.use(store);
app.use(ElementPlus, { locale: ja });
app.use(VueGtm, gtmOption(router));
app.config.globalProperties.$utils = globalUtils;

const APPLICANT_PAGES_PATHS = [
  '/line_entry',
  '/mypage',
  '/reserves',
  '/results',
  '/flagsurveyext',
  '/selection_reserve_ext',
  '/richmessage_ext',
  '/reminders_ext',
  '/notice_ext',
  '/line_message_ext',
];

if (APPLICANT_PAGES_PATHS.includes(location.pathname)) {
  // 応募者用ページのみinitさせない
  app.mount('#app');
} else {
  // load時のみログイン済みの場合、storeにStaff/Companyなどを保持させる（非ログインページでもlogin/checkを叩いてしまうので検討必要）
  store.dispatch('auth/INIT').then(() => {
    app.mount('#app');
  });
}

window.addEventListener('popstate', () => {
  document.querySelector('body')?.classList.remove('-noscroll');
});
