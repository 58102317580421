<template>
  <modal :is-view="modalApplicantsIsView">
    <template #content>
      <div class="content">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">{{ modalApplicantsTitle }}</div>
            </div>
            <div class="modal-header_cancell" @click="close">
              <img class="icon" src="@/assets/img/close_black.svg" />
            </div>
          </section>
          <!-- modal-header -->

          <section class="modal-body">
            <div class="row-1">
              <div class="refine-block">
                <div
                  class="btn btn-availability"
                  @click="openFloatApplicantsForm"
                >
                  <img class="icon" src="@/assets/img/search_white.svg" />
                  絞り込み検索
                </div>
                <search-applicants
                  :is-view="searchFormIsVisible"
                  :events="events"
                  :staffs="staffs"
                  @searchFormDetailSwitch="searchFormDetailSwitch"
                  @onClose="updateSearchFormIsVisible(false)"
                  @onSearch="onSearch"
                  @onReset="onSearchReset"
                />
                <search-applicants-detail
                  :is-view="searchFormDetailIsVisible"
                  :surveys-props="surveyTitles"
                  :events="events"
                  :staffs="staffs"
                  @searchFormDetailSwitch="searchFormDetailSwitch"
                  @onClose="updateSearchFormDetailIsVisible(false)"
                  @onSearch="onSearch"
                  @onReset="onSearchReset"
                />
              </div>

              <div class="col-right">
                <div class="btn-menu">
                  <div
                    class="button-regist"
                    @click="updateIsOpenPagerLoad(true)"
                  >
                    表示する応募者の件数：{{ pageLoad }}件
                    <img
                      class="icon arrow"
                      src="@/assets/img/rect_arrow_down_blue.svg"
                      alt
                    />
                  </div>
                  <float-context-menu
                    :is-view="isOpenPagerLoad"
                    :list="loadSelection"
                    @onClose="updateIsOpenPagerLoad(false)"
                    @onSelected="onSelectedPageLoad"
                  />
                </div>
                <div
                  v-if="isShowSelectableApplicantTable"
                  class="area-pager margin-left-auto"
                >
                  <pager
                    :page="page"
                    :count="count"
                    :page-count="pageCount"
                    :load="pageLoad"
                    @move="movePager"
                  />
                </div>
              </div>
            </div>

            <div class="area-search-list">
              <local-page-loader v-if="!isShowSelectableApplicantTable" />
              <table
                v-if="isShowSelectableApplicantTable"
                class="area-table-list"
              >
                <thead class="area-table-list_header">
                  <tr>
                    <th>応募者ID</th>
                    <th>名前</th>
                    <th>セイメイ</th>
                    <th>性別</th>
                    <th>学校名</th>
                    <th>学部</th>
                    <th>文理</th>
                    <th>電話番号</th>
                    <th>登録経路</th>
                  </tr>
                </thead>
                <tbody class="area-table-list_body">
                  <tr
                    v-for="applicant in applicants"
                    :key="applicant.id"
                    class="area-table-list_body_item"
                  >
                    <td>{{ applicant.id }}</td>
                    <td>{{ applicant.lastname }}{{ applicant.firstname }}</td>
                    <td>
                      {{ applicant.lastnameKana }}{{ applicant.firstnameKana }}
                    </td>
                    <td>{{ applicant.gender.description }}</td>
                    <td>{{ applicant.school }}</td>
                    <td>{{ applicant.faculty }}</td>
                    <td>{{ applicant.departmentCategory.description }}</td>
                    <td>{{ applicant.tel }}</td>
                    <td>{{ applicant.channel }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="btn-col2">
              <div class="btn btn-unavailable" @click="close">閉じる</div>
            </div>
          </section>
          <!-- modal-body -->
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

import useSearchApplicant from '@/composables/useSearchApplicant';
import Pager from '@/components/ui/pager/components/Pager';
import Modal from '@/components/ui/modals/components/Modal';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';
import SearchApplicants from '@/components/features/searchApplicants/components/SearchApplicants';
import SearchApplicantsDetail from '@/components/features/searchApplicants/components/SearchApplicantsDetail';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';

// TODO: Vuex削除時にリファクタリングする（最終的には削除したい）
export default defineComponent({
  name: 'ModalApplicants',
  components: {
    Modal,
    Pager,
    FloatContextMenu,
    SearchApplicants,
    SearchApplicantsDetail,
    LocalPageLoader,
  },
  setup(props, context) {
    const store = useStore();
    const searchFilter = store.getters['searchFilter/searchFilter'];

    const {
      page,
      count,
      pageCount,
      events,
      staffs,
      applicants,
      surveyTitles,
      loadSelection,
      pageLoad,
      searchFormIsVisible,
      searchFormDetailIsVisible,
      isOpenPagerLoad,
      isShowSelectableApplicantTable,
      // methods
      fetchApplicants,
      fetchEvents,
      fetchStaffs,
      resetState,
      searchFormDetailSwitch,
      movePager,
      openFloatApplicantsForm,
      onSearch,
      onSearchReset,
      onSelectedPageLoad,
      updateSearchFormIsVisible,
      updateSearchFormDetailIsVisible,
      updateIsOpenPagerLoad,
      updateTargetIds,
    } = useSearchApplicant(store, searchFilter, {
      isAllApplicants: true,
      isExceptGraduated: true,
    });

    // computed
    const modalApplicantsIsView = computed(() => {
      return store.getters['modalApplicants/modalApplicantsIsView'];
    });
    const modalApplicantsTitle = computed(() => {
      return store.getters['modalApplicants/modalApplicantsTitle'];
    });
    const modalApplicantsApplicantIds = computed(() => {
      return store.getters['modalApplicants/modalApplicantsApplicantIds'];
    });

    // watch
    store.watch(
      (state, getters) =>
        getters['modalApplicants/modalApplicantsApplicantIds'],
      async ids => {
        if (ids && Array.isArray(ids) && ids.length >= 1) {
          fetchAll();
        }
      },
    );

    // methods
    const fetchAll = async () => {
      await resetState();
      updateTargetIds(modalApplicantsApplicantIds.value);
      await Promise.all([fetchEvents(), fetchStaffs(true), fetchApplicants(1)]);
    };
    const close = () => {
      resetState();
      store.dispatch('modalApplicants/INVISIBLE_MODAL_APPLICANTS');
    };

    return {
      modalApplicantsIsView,
      modalApplicantsTitle,
      // composables
      page,
      count,
      pageCount,
      events,
      staffs,
      applicants,
      surveyTitles,
      loadSelection,
      pageLoad,
      searchFormIsVisible,
      searchFormDetailIsVisible,
      isOpenPagerLoad,
      isShowSelectableApplicantTable,
      searchFormDetailSwitch,
      movePager,
      openFloatApplicantsForm,
      onSearch,
      onSearchReset,
      onSelectedPageLoad,
      updateSearchFormIsVisible,
      updateSearchFormDetailIsVisible,
      updateIsOpenPagerLoad,
      // methods
      close,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
  width: 100vw;
  height: 100vh;

  .row-1 {
    padding: 0;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .col-right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .applicant_column_block {
        margin-right: 20px;
        .btn {
          font-size: 1.2rem;
          padding-right: 2.4rem;
          display: flex;
          align-items: center;
          &::after {
            content: '';
            width: 6px;
            height: 6px;
            border: 0px;
            border-bottom: solid 2px #178fd0;
            border-right: solid 2px #178fd0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: -4px;
          }
        }
      }
    }
  }
  .row-2 {
    margin: 1.2rem 3rem 1.2rem;
  }

  .modal-body {
    .area-search-list {
      overflow-x: auto;
      margin-bottom: 30px;
    }
    .area-table-list {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      box-shadow: $box_shadow;
      background: #fff;
      text-align: left;
      white-space: nowrap;
    }
    .area-table-list_header {
      height: 3.6rem;
      line-height: 3.6rem;
      background: #f7f7f7;
      border-top: 1px solid #e3e3e3;
      th {
        padding-left: 1.2rem;
        text-align: left;
      }
    }
    .area-table-list_body {
      background: #fff;
      margin-bottom: 3rem;
      .area-table-list_body_item {
        height: 3rem;
        line-height: 3rem;
        border-bottom: 1px dashed $border_color;
        td {
          padding: 0 15px;
          border-right: 1px solid #e3e3e3;
          &:last-of-type {
            border-right: none;
          }
        }
      }
    }
  }
  .modal-body_btn_item {
    width: 100%;
  }
}

.refine-block {
  position: relative;
}

.btn-menu {
  margin-right: 20px;
}

.btn-col2 {
  justify-content: center;
}

@media (max-width: ($media_query_sp)) {
  .content {
    .modal-body {
      padding-top: 80px;
    }
    .row-1 {
      display: block;
    }
    .modal-header_ttl {
      margin-top: 10px;
    }
  }
  .refine-block {
    text-align: left;
    margin-bottom: 15px;
  }
}
</style>
