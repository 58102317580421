<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                この応募者に登録されている選考の詳細情報です。
              </div>
            </div>
            <div class="modal-header_cancell" @click="$emit('close')">
              <img class="icon" src="@/assets/img/close_black.svg" />
            </div>
          </section>
          <div class="modal-body">
            <selection-detail-body :id="id" :is-modal-selection-mode="true" />
            <div class="btn-col2">
              <div class="btn btn-unavailable" @click="$emit('close')">
                閉じる
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent } from 'vue';

import Modal from '@/components/ui/modals/components/Modal';
import SelectionDetailBody from '@/components/features/selectionDetailBody/components/SelectionDetailBody';

export default defineComponent({
  name: 'ModalShowSelectionDetail',
  components: { Modal, SelectionDetailBody },
  props: {
    id: {
      type: Number,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, context) {},
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
  overflow-y: auto;
  padding-bottom: 6rem;
  text-align: left;
  .modal-body {
    & :deep(.sec-basic-info) {
      margin: 0 0 3rem;
    }
    & :deep(.sec-venues) {
      margin: 0;
    }
  }
  .btn-col2 {
    justify-content: center;
  }
}
</style>
