<template>
  <div>
    <div v-if="isPreview && base64ImgData" class="preview">
      <p>プレビュー</p>
      <img :src="base64ImgData" alt="test" />
    </div>
    <div class="image_upload_box">
      <div
        ref="dropbox"
        class="dropbox"
        @dragleave.prevent
        @dragover.prevent
        @drop.prevent="onDropFile($event)"
      >
        <input
          v-if="isOnlyJpg === true"
          id="file_upload-box"
          ref="refFile"
          type="file"
          accept="image/jpeg, image/jpg"
          @change="setFile($event.target.files)"
          @click="e => (e.target.value = '')"
        />
        <input
          v-else
          id="file_upload-box"
          ref="refFile"
          type="file"
          @change="setFile($event.target.files)"
          @click="e => (e.target.value = '')"
        />
        <template v-if="isOnlyJpg === true">
          <label
            v-show="dataObject.file"
            ref="refPreview"
            for="file_upload-box"
            class="image_preview"
          />
        </template>
        <div
          v-if="isOnlyJpg === false || (isOnlyJpg === true && !dataObject.file)"
          class="dropbox_inner"
        >
          <div
            v-if="isDisabled !== true"
            class="placeholder_message"
            :class="{ 'is-only_jpg': isOnlyJpg }"
          >
            <p>
              <span class="description">
                {{ description }}
              </span>
              <span>または…</span>
              <label for="file_upload-box" class="upload_button">
                ファイルを選択
              </label>
            </p>
          </div>
          <div
            v-if="isOnlyJpg === false && localFiles.length > 0"
            class="mail_uploaded_file_area"
          >
            <p>
              <img src="@/assets/img/icon_file.svg" />
              {{ fileTitle }}
            </p>
            <ul>
              <li v-for="tmpFile in localFiles" :key="`file_${tmpFile.id}`">
                <p
                  v-if="isNoticeHistory"
                  class="filename download-link"
                  @click="downloadFile(tmpFile)"
                >
                  {{ tmpFile.filename || tmpFile.original_filename }} ({{
                    conversionToMegaByte(tmpFile.filesize, 100)
                  }}MB)
                </p>
                <p v-else class="filename">
                  {{ tmpFile.filename || tmpFile.original_filename }} ({{
                    conversionToMegaByte(tmpFile.filesize, 100)
                  }}MB)
                </p>
                <button
                  v-if="isDisabled !== true"
                  @click="deleteFile(tmpFile.id)"
                >
                  削除
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import uploadService from '@/services/upload';
import { excludeFileExtensions } from '@/components/ui/attachmentFile/defines/exclude-file-extensions';
import { conversionToMegaByte } from '@/utils/file';
import { generateUniqueId } from '@/utils/unique-id';

export default defineComponent({
  name: 'AttachmentFile',
  props: {
    isOnlyJpg: {
      type: Boolean,
      default: false,
    },
    limitSize: {
      type: Number,
      default: 3,
    },
    files: {
      type: Array,
      default: () => [],
    },
    limitFileCount: {
      type: Number,
      default: 3,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isNoticeHistory: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: 'ファイルを添付する場合は\nここにドロップしてください',
    },
    fileTitle: {
      type: String,
      default: '添付ファイル',
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setFile', 'deleteFile'],
  setup(props, context) {
    const store = useStore();
    const refFile = ref(null);
    const refPreview = ref(null);
    const dataObject = reactive({ file: null });
    const localFiles = ref(cloneDeep(props.files));
    const resetFiles = () => {
      localFiles.value = [];
    };
    const base64ImgData = ref('');
    const generateBase64ImgData = file => {
      if (!file) return false;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        base64ImgData.value = reader.result;
      };
      reader.onerror = () => {
        base64ImgData.value = '';
        return false;
      };
      return true;
    };
    const setFile = files => {
      if (props.files.length >= props.limitFileCount) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `${props.limitFileCount}つ以上のファイルはアップロードできません`,
          type: false,
        });
        return;
      }
      if (files === null || !files[0]) {
        resetFile();
        return;
      }
      if (props.isPreview) {
        const success = generateBase64ImgData(files[0]);
        if (!success) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: `プレビューが表示できませんでした`,
            type: false,
          });
          return;
        }
      }
      if (props.isOnlyJpg === true) {
        // 画像のみの場合
        if (files[0].size >= props.limitSize * 1024 * 1024) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: `${props.limitSize}MB以上のファイルはアップロードできません`,
            type: false,
          });
          return;
        }
        if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg') {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: 'JPGファイル以外はアップロードできません',
            type: false,
          });
          return;
        }
        dataObject.file = files[0];
        previewImage(files[0]);
        context.emit('setFile', files[0]);
      } else {
        // ファイル許可の場合
        const sumSize =
          localFiles.value.length > 0
            ? localFiles.value
                .map(f => f.filesize)
                .reduce((prev, current) => prev + current) + files[0].size
            : files[0].size;
        if (files[0].name.length > 128) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: `ファイル名は${props.limitSize}文字までです`,
            type: false,
          });
          return;
        }
        if (sumSize >= props.limitSize * 1024 * 1024) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: `合計${props.limitSize}MB以上のファイルはアップロードできません`,
            type: false,
          });
          return;
        }
        if (localFiles.value.length >= 3) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: '添付できるファイルは3つまでです',
            type: false,
          });
          return;
        }
        const splitedFileName = files[0].name.split('.');
        const fileExtension = splitedFileName[splitedFileName.length - 1];
        if (excludeFileExtensions.includes(fileExtension)) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: 'このファイル形式はアップロードできません',
            type: false,
          });
          return;
        }
        const tmpFile = {
          id: generateUniqueId(),
          file: files[0],
          filename: files[0].name,
          filesize: files[0].size,
        };
        localFiles.value.push(tmpFile);
        context.emit('setFile', tmpFile);
        resetFile();
      }
    };
    const resetFile = () => {
      refFile.value = '';
      dataObject.file = null;
      if (props.isOnlyJpg === true) previewImage(null);
    };
    const previewImage = tmpFile => {
      const preview = refPreview.value;
      const previewImage = document.getElementById('previewImage');
      if (!preview) return;
      if (tmpFile === null) {
        if (!previewImage) return;
        preview.removeChild(previewImage);
        return;
      }
      const reader = new FileReader();
      if (previewImage) preview.removeChild(previewImage);
      reader.onload = () => {
        const img = document.createElement('img');
        img.setAttribute('src', reader.result);
        img.setAttribute('id', 'previewImage');
        img.setAttribute('alt', tmpFile.name);
        img.setAttribute(
          'style',
          'width: auto; height: auto; max-width:100%; max-height:100%;',
        );
        preview.appendChild(img);
      };
      reader.readAsDataURL(tmpFile);
    };
    const onDropFile = event => {
      resetFile();
      setFile(event.dataTransfer.files);
    };
    const deleteFile = fileId => {
      base64ImgData.value = '';
      localFiles.value = localFiles.value.filter(
        tmpFile => tmpFile.id !== fileId,
      );
      context.emit('deleteFile', fileId);
    };
    const downloadFile = async fileObj => {
      Object.assign(fileObj, { originalFilename: fileObj.original_filename });
      const res = await uploadService.downloadFile(fileObj);
      if (!res.success) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: false,
        });
      }
    };
    return {
      refFile,
      refPreview,
      dataObject,
      localFiles,
      base64ImgData,
      resetFile,
      resetFiles,
      generateBase64ImgData,
      setFile,
      onDropFile,
      conversionToMegaByte,
      deleteFile,
      downloadFile,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.image_upload_box {
  display: block;
  width: 100%;
  text-align: center;
  background-color: #fff;
}

.dropbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > input {
    display: none;
  }
}

.dropbox_inner {
  width: 100%;
  height: 100%;
  border: 1px dashed $tab_border_color;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.image_preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(17rem - 3px);
}

.placeholder_message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1.4;
  width: 100%;
  height: 13rem;
  &.is-only_jpg {
    height: calc(17rem - 3px);
  }
  > p {
    color: #9e9e9e;
    > span {
      display: block;
      &:nth-child(1) {
        font-weight: bold;
      }
      &:nth-child(2) {
        margin: 1rem;
        font-size: 1rem;
      }
    }
  }
}

.upload_button {
  display: block;
  width: 160px;
  line-height: 1;
  padding: 10px;
  margin: 0 auto;
  font-size: 1rem;
  color: #333;
  background-color: #efefef;
  border-radius: 4px;
  cursor: pointer;
}

.mail_uploaded_file_area {
  padding: 1.5rem;
  margin: 1rem;
  border: 1px solid #ededed;
  background-color: #fff;
  border-radius: 3px;
  text-align: left;
  > p {
    font-weight: bold;
    color: $mochica_color;
    > img {
      vertical-align: 0;
    }
  }
  > ul {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 4px solid #ededed;
    > li {
      display: flex;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #ededed;
      &:last-child {
        margin-bottom: 0;
      }
      p.download-link {
        color: #16b2d9;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        max-width: 90%;
        overflow-wrap: break-word;
      }
      > button {
        margin-left: auto;
        font-weight: normal;
        color: #de3131;
        cursor: pointer;
      }
    }
  }
}

.description {
  white-space: pre-line;
}

.preview {
  p {
    color: #696969;
  }

  img {
    width: 50%;

    margin-bottom: 1rem;
  }
}
</style>
