<template>
  <ul class="pager">
    <li
      v-if="page !== 1 && pageCount >= 2"
      class="item-arrow prev"
      @click="move(1)"
    >
      <img class="icon" src="@/assets/img/arrow_double_left_blue.svg" />
    </li>
    <li
      v-for="(el, iel) in viewPages"
      :key="iel"
      class="item"
      :class="{ selected: el === page }"
      @click="move(el)"
    >
      {{ el }}
    </li>
    <li
      v-if="page !== pageCount && pageCount >= 2"
      class="item-arrow next"
      @click="moveLast()"
    >
      <img class="icon" src="@/assets/img/arrow_double_right_blue.svg" />
    </li>
  </ul>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Pager',
  props: {
    load: {
      type: Number,
      default: 20,
    },
    page: {
      type: Number,
      default: 1,
    },
    count: {
      type: Number,
      default: 0,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    viewPageRange: {
      type: Number,
      default: 5,
    },
    isFullOmission: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['move'],
  setup(props, context) {
    const halfRangePage = computed(() => Math.floor(props.viewPageRange / 2));
    const viewPages = computed(() => {
      let first = 1;
      let range = props.viewPageRange;
      if (props.pageCount <= props.viewPageRange) {
        range = props.pageCount;
      } else if (
        halfRangePage.value < props.page &&
        props.page < props.pageCount - halfRangePage.value
      ) {
        first = props.page - halfRangePage.value;
      } else if (props.page <= halfRangePage.value) {
        first = 1;
      } else if (props.page >= props.pageCount - halfRangePage.value) {
        first = props.pageCount - props.viewPageRange + 1;
      }
      return Array.from(Array(range), (v, k) => k + first);
    });
    const move = next => {
      context.emit('move', { current: props.page, next });
    };
    const moveLast = () => {
      context.emit('move', { current: props.page, next: props.pageCount });
    };
    return { viewPages, move, moveLast };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.pager {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > .item {
    margin-right: 5px;
    padding: 8px 10px;
    border: 1px solid #ccc;
    font-weight: bold;
    background-color: #fff;
    cursor: pointer;
    &.selected {
      color: #fff;
      border-color: #16b2d9;
      background-color: #16b2d9;
    }
  }
  > .item-arrow {
    &.prev {
      margin-right: 10px;
    }
    &.next {
      margin-left: 5px;
    }
    > .icon {
      width: 15px;
      color: #16b2d9;
      cursor: pointer;
    }
  }
}
</style>
