<template>
  <div class="login-form-wrapper">
    <form class="login-form">
      <div class="login-form-toissue">
        <img
          class="login-form_logo-title"
          viewBox="0 0 394.2 41"
          src="@/assets/img/mochica_login.svg"
          alt
        />
        <div class="container">
          <privacy-policy v-if="privacyPolicy" />
          <user-policy v-if="!privacyPolicy" />
        </div>
        <button
          v-if="privacyPolicy"
          class="to_login_btn"
          type="button"
          @click.prevent="$router.replace('/login')"
        >
          ログイン画面へ戻る
        </button>
      </div>
    </form>
    <notification />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import qs from 'qs';
import PrivacyPolicy from '@/components/page/policy/components/PrivacyPolicy';
import UserPolicy from '@/components/features/policy/components/UserPolicy';
import Notification from '@/components/ui/notification/components/Notification';

export default defineComponent({
  name: 'Policy',
  components: { PrivacyPolicy, UserPolicy, Notification },
  setup(props, context) {
    const privacyPolicy = ref(false);

    // lifecycle
    onBeforeMount(() => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (query.privacy_policy !== undefined) {
        privacyPolicy.value = true;
      }
    });
    return {
      privacyPolicy,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.login-form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  background: url(../../assets/img/login_bg.jpg) no-repeat;
  background-size: cover;
}
.login-form {
  display: flex;
  align-items: center;
  flex-flow: column;
  max-width: 1200px;
  width: 93.75%;
  height: inherit;
  &-toissue {
    width: 100%;
    height: calc(100% - 40px);
    margin: 20px 0 0;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}
.login-form_logo-title {
  max-width: 300px;
  fill: #88c6ed;
  margin: 30px 0 60px;
}
.container {
  overflow-y: scroll;
  max-width: 900px;
  height: calc(100% - 213px);
  margin: 0 auto;
  padding: 0 20px 0 0;
  font-weight: normal;
  font-size: 1.4rem;
  color: #888;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  word-break: break-all;
}
.policy__text-link {
  display: inline-block;
  color: $mochica-color;
  &:hover {
    transition: 0.3s;
    opacity: 0.8;
  }
}
.to_login_btn {
  @include btn_hover();
  max-width: 300px;
  width: 100%;
  margin: 40px auto;
  padding: 15px 0;
  border: none;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: $mochica_color;
}

@media (max-width: ($media_query_tablet)) {
  .container {
    padding: 0;
  }
}

@media (max-width: ($media_query_sp)) {
  .login-form_logo-title {
    max-width: 260px;
    margin: 20px 0 40px;
  }
  .container {
    height: calc(100% - 169px);
  }
  .to_login_btn {
    margin: 30px auto 0;
  }
}
</style>
