<template>
  <modal
    :is-view="isView"
    :is-disabled-show-modal-displayed="true"
    @onclickOuter="onClickOuter"
  >
    <template #rightTop>
      <div class="right-top" @click="close">
        <img class="icon" src="@/assets/img/close_white.svg" />
      </div>
    </template>

    <template #content>
      <div class="modal-window -typeA">
        <div class="header">
          <p class="title">送信メッセージ</p>
        </div>
        <div class="body">
          <p class="text">
            下の内容で{{
              lineContent.length > 0 ? 'LINE' : 'メール'
            }}を送信します。よろしいですか？
          </p>
          <div v-if="lineContent.length > 0" class="line-content">
            <p>{{ lineContent }}</p>
          </div>
          <div v-else-if="mailContent.length > 0" class="mail-wrapper">
            <p class="mail-title">{{ mailTitle }}</p>
            <div class="mail-content">
              <p>{{ mailContent }}</p>
              <div v-if="mailFiles.length > 0" class="mail_uploaded_file_area">
                <p>添付ファイル</p>
                <ul>
                  <li v-for="upload in mailFiles" :key="`upload_${upload.id}`">
                    <span>
                      {{ upload.filename }} ({{
                        conversionToMegaByte(upload.filesize, 100)
                      }}MB)
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="dialog-checkbox">
            <p class="dialog-checkbox-title">メッセージの確認</p>
            <label class="cursor_pointer">
              <input v-model="checkbox" type="checkbox" @click="toggleCheck" />
              送信したメッセージを応募者が確認したかチェックする
            </label>
            <p class="dialog-checkbox-caption">
              ※LINEの場合は応募者に確認ボタンが送信され、メールの場合は文面内に確認リンクが挿入されます。
            </p>
          </div>
        </div>
        <div class="btn-col2">
          <button class="btn btn-unavailable" @click="onClick(1, $event)">
            キャンセル
          </button>
          <button
            class="btn btn-availability"
            :disabled="isBtnDisabled"
            @click="onClick(2, $event)"
          >
            はい
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref } from 'vue';

import { conversionToMegaByte } from '@/utils/file';
import Modal from '@/components/ui/modals/components/Modal';

export default defineComponent({
  name: 'ModalSendConfirm',
  components: { Modal },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    lineContent: {
      type: String,
      default: '',
    },
    mailTitle: {
      type: String,
      default: '',
    },
    mailContent: {
      type: String,
      default: '',
    },
    mailFiles: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onSelected', 'onClickOuter', 'onClickUnreadNeed'],
  setup(props, context) {
    const checkbox = ref(false);
    const isBtnDisabled = ref(false);

    const onClick = choice => {
      if (choice === 1) {
        isBtnDisabled.value = false;
        context.emit('onSelected', { choice });
      }
      if (choice === 2 && !isBtnDisabled.value) {
        isBtnDisabled.value = true;
        context.emit('onSelected', { choice });
      }
    };
    const onClickOuter = payload => {
      context.emit('onClickOuter', payload);
    };
    const close = () => {
      context.emit('onClickOuter', false);
    };
    const toggleCheck = () => {
      checkbox.value = !checkbox.value;
      context.emit('onClickUnreadNeed', checkbox.value);
    };
    const resetData = () => {
      checkbox.value = false;
      isBtnDisabled.value = false;
    };

    return {
      checkbox,
      isBtnDisabled,
      onClick,
      onClickOuter,
      close,
      toggleCheck,
      resetData,
      conversionToMegaByte,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.modal-window.-typeA {
  max-width: 690px;
}

.header {
  color: $mochica_color;
  border-color: $mochica_color;
}

.text {
  font-size: 14px;
  text-align: left;
}

.line-content {
  margin-top: 20px;
  padding: 20px;
  font-size: 14px;
  line-height: 1.75;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-all;
}

.mail-wrapper {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.75;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-all;
}

.mail-title {
  font-weight: bold;
  font-size: 16px;
  padding: 20px;
  border-bottom: 1px solid #e2e2e2;
}
.mail-content {
  padding: 20px;
}

.dialog-checkbox {
  border-top: 1px solid #e2e2e2;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  input[type='checkbox'] {
    vertical-align: -2px;
    cursor: pointer;
  }
}
.dialog-checkbox-title {
  margin-bottom: 15px;
  font-size: 16px;
  color: $mochica_color;
  font-weight: bold;
}
.dialog-checkbox-caption {
  margin-top: 10px;
  margin-left: 16px;
  font-size: 1rem;
  line-height: 1;
  color: #777;
}

.mail_uploaded_file_area {
  padding: 1.5rem;
  margin-top: 1rem;
  border: 1px solid #ededed;
  background-color: #fff;
  border-radius: 3px;
  > p {
    font-weight: bold;
    color: #888;
    &::before {
      content: '';
      mask-image: url(../../../../../assets/img/icon_file.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      display: inline-block;
      position: relative;
      background-color: #888888;
      height: 14px;
      width: 14px;
      top: 2px;
      margin-right: 4px;
    }
  }
  > ul {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 4px solid #ededed;
    > li {
      margin-bottom: 0.3rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.cursor_pointer {
  cursor: pointer;
}
</style>
