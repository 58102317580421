import httpClient from '@/http-client';

const TagsManager = function _TagsManager() {
  const manager = Object.create(TagsManager.prototype);
  return manager;
};

TagsManager.prototype = {
  async fetchTagGroups() {
    const result = await httpClient.get('/tag_groups');
    if (result.status === 200) return result.data.tag_groups;
    // TODO:エラー時の処理をどうするか
    return null;
  },
  async createTagGroup(companyId, name) {
    const res = await httpClient.post('/tag_groups', {
      tag_group: { company_id: companyId, name },
    });
    return res.data;
  },
  async updateTagGroup(tagGroupId, name) {
    const res = await httpClient.patch(`/tag_groups/${tagGroupId}`, {
      tag_group: { name },
    });
    return res.data;
  },
  async deleteTagGroup(tagGroupId) {
    const res = await httpClient.delete(`/tag_groups/${tagGroupId}`);
    return res.data;
  },
  async createTag(tagGroupId, name) {
    const result = await httpClient.post('/tags', {
      tag: { name, tag_group_id: tagGroupId },
    });
    return result.data;
  },
  async updateTag(tagGroupId, tagId, name) {
    const result = await httpClient.patch(`/tags/${tagId}`, {
      tag: { name, tag_group_id: tagGroupId },
    });
    return result.data;
  },
  async deleteTag(tagId) {
    const res = await httpClient.delete(`/tags/${tagId}`);
    return res.data;
  },
  /**
   * 応募者にフラグを紐付け
   * @param applicantId
   * @param tagId
   * @returns {Promise.<null>}
   */
  async addApplicantTags(applicantId, tagId) {
    const result = await httpClient.put(`/applicants/${applicantId}/tags`, {
      tag: { id: tagId },
    });
    return result.status === 200 ? result : null;
  },
  /**
   * 応募者に対するフラグを削除
   * @param applicantId
   * @param tagId
   * @returns {Promise.<null>}
   */
  async deleteApplicantTags(applicantId, tagId) {
    const result = await httpClient.delete(
      `/applicants/${applicantId}/tags/${tagId}`,
    );
    return result.status === 200 ? result : null;
  },
  async updateHighlightTagGroup(tagGroupId, value) {
    const result = await httpClient.patch(
      `/tag_groups/highlight/${tagGroupId}`,
      { tag_group_id: tagGroupId, highlight: value },
    );
    return result.data;
  },
};

export default TagsManager();
