<script setup>
import {
  FINISHED_PROGRESS_STATUSES,
  FINISHED_PROGRESS_STATUSES_LABEL,
  PROGRESS_STATUSES,
  PROGRESS_STATUSES_LABEL,
} from '@/defines/progresses';

const props = defineProps({
  labelName: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md',
  },
});

const labelClass = () => {
  switch (props.labelName) {
    case PROGRESS_STATUSES_LABEL[PROGRESS_STATUSES.notSetSelection]:
      return 'not-set-selection';
    case PROGRESS_STATUSES_LABEL[PROGRESS_STATUSES.announced]:
      return 'announced';
    case PROGRESS_STATUSES_LABEL[PROGRESS_STATUSES.notAnnounced]:
      return 'not-announced';
    case PROGRESS_STATUSES_LABEL[PROGRESS_STATUSES.notEvaluated]:
      return 'not-evaluated';
    case PROGRESS_STATUSES_LABEL[PROGRESS_STATUSES.pending]:
      return 'pending';
    case FINISHED_PROGRESS_STATUSES_LABEL[FINISHED_PROGRESS_STATUSES.hired]:
      return 'hired';
    case FINISHED_PROGRESS_STATUSES_LABEL[FINISHED_PROGRESS_STATUSES.offered]:
      return 'offered';
    case FINISHED_PROGRESS_STATUSES_LABEL[FINISHED_PROGRESS_STATUSES.dropped]:
      return 'dropped';
    case FINISHED_PROGRESS_STATUSES_LABEL[FINISHED_PROGRESS_STATUSES.canceled]:
      return 'canceled';
    case FINISHED_PROGRESS_STATUSES_LABEL[
      FINISHED_PROGRESS_STATUSES.offerCanceled
    ]:
      return 'offer-canceled';
    case FINISHED_PROGRESS_STATUSES_LABEL[FINISHED_PROGRESS_STATUSES.passed]:
      return 'passed';
    default:
      return 'no-display';
  }
};
</script>

<template>
  <div class="progress-status-label" :class="[labelClass(), size]">
    {{ labelName }}
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.progress-status-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 110px;
  padding: 8px 10px;
  border-radius: 32px;
  color: $white;
  font-weight: bold;
  &.md {
    font-size: $font_14;
  }
  &.sm {
    min-width: unset;
    font-size: $font_12;
  }
  &.not-set-selection {
    background: $not_set_selection_bg;
  }
  &.not-announced {
    background: $not_announced_bg;
  }
  &.announced {
    background: $announced_color;
  }
  &.not-evaluated {
    background: $not_evaluated_bg;
  }
  &.pending {
    background: $pending_bg;
  }
  &.hired {
    background: $hired_bg;
  }
  &.offered {
    background: $offered_bg;
  }
  &.dropped {
    background: $dropped_bg;
  }
  &.canceled {
    background: $canceled_bg;
  }
  &.offer-canceled {
    background: $offer_canceled_bg;
  }
  &.passed {
    background: $passed_bg;
  }
  &.no-display {
    display: none;
  }
}
</style>
