import httpClient from '@/http-client';

const ReserveSelectionManager = function _ReserveSelectionManager() {
  const manager = Object.create(ReserveSelectionManager.prototype);
  return manager;
};

ReserveSelectionManager.prototype = {
  async selectionListRequest(payload) {
    const response = await httpClient.post(
      '/selection_reserve_ext/get_list',
      payload,
    );
    return response.data;
  },
  async selectionReserveRequest(payload) {
    const response = await httpClient.post(
      '/selection_reserve_ext/reserve',
      payload,
    );
    return response.data;
  },
  async checkReserveSelection(payload) {
    const result = await httpClient.post('/selection_reserve/check', payload);
    return result.data;
  },
  async sendReserveSelection(payload) {
    const result = await httpClient.post('/selection_reserve/send', payload);
    return result.data;
  },
};

export default ReserveSelectionManager();
