<template>
  <div class="entryform_setting_items">
    <label
      v-for="key in formKeys"
      :key="`checkbox_${key}`"
      class="entryform_setting_label"
      :for="key"
      :class="{ checked: formSetting[key] }"
    >
      <input
        :id="key"
        v-model="formSetting[key]"
        class="entryform_setting_input"
        type="checkbox"
        @click="$emit('setPreviewData', key)"
      />
      <span class="entryform_setting_text">{{ getFormLabel(key) }}</span>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EntryFormSettingCheckList',
  props: {
    formKeys: {
      type: Array,
      required: true,
    },
    formSetting: {
      type: Object,
      required: true,
    },
    getFormLabel: {
      type: Function,
      required: true,
    },
  },
  emits: ['setPreviewData'],
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@include form_wrapper_css();

.entryform_setting_items {
  border-radius: 4px;
  border: 1px solid #c9c9c9;

  .entryform_setting_label {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    &:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
    cursor: pointer;
    &.checked {
      background: #def2ff;
    }
    .entryform_setting_input {
      appearance: none;
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 5px;
      border-radius: 4px;
      border: 2px solid #9d9d9d;
      background: #fff;
      position: relative;
      cursor: pointer;
      &:checked {
        &::after {
          content: url('../../../../assets/img/check.svg');
          height: 1.1rem;
          width: 1.5rem;
          margin: -5px 0 0 1px;
          position: absolute;
          bottom: 3px;
          left: 0;
        }
      }
    }
    .entryform_setting_text {
      display: inline-block;
      margin-left: 0.5rem;
      font-size: $font_14;
    }
  }
}
</style>
