<template>
  <transition name="modal">
    <div v-if="isView" id="modal-pos" ref="refModal" class="modal-mask">
      <div class="modal-wrapper" @click="onClickOuter">
        <div class="area-top-right">
          <slot name="rightTop" />
        </div>
        <div class="modal-container">
          <slot name="content" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// ModalWindowに移行したら削除すること
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    flowItem: {
      type: Boolean,
      default: false,
    },
    isDisabledShowModalDisplayed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onclickOuter'],
  setup(props, context) {
    const refModal = ref(null);
    const storedScrollTop = ref(0);
    watch(
      () => props.isView,
      async val => {
        const bodyEl = document.querySelector('body');
        if (val) {
          if (!props.isDisabledShowModalDisplayed) {
            // ちからわざ!
            const flowContent = document.getElementById('flow-content');
            if (flowContent && props.flowItem)
              document.getElementById('modal-pos').style.position = 'fixed';
          }
          // positionを保持
          storedScrollTop.value = window.pageYOffset;
          bodyEl.classList.add('-noscroll');
          bodyEl.style.top = `-${storedScrollTop.value}px`;
        } else {
          bodyEl.classList.remove('-noscroll');
          bodyEl.style.removeProperty('top');
          window.scrollTo(0, storedScrollTop.value);
        }
      },
    );
    const onClickOuter = ev => {
      if (
        props.isView === true &&
        refModal.value &&
        refModal.value.children &&
        refModal.value.children[0] === ev.target
      ) {
        // モーダル外側クリック時のイベント
        context.emit('onclickOuter', false);
      }
    };
    return { refModal, storedScrollTop, onClickOuter };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(#707070, 0.95);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100vw;
  height: 100vh;
}
.area-top-right {
  position: fixed;
  top: 26px;
  right: 34px;
}
@media (max-width: ($media_query_tablet)) {
  .area-top-right {
    top: 15px;
    right: 15px;
  }
}
@media (max-width: ($media_query_sp)) {
  .area-top-right {
    top: 10px;
    right: 10px;
  }
}
/*
 * Transition:modal
 */
.modal-enter-from {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
</style>
