<template>
  <div>
    <div class="admin-input">
      <div v-for="menu in menus" :key="menu.id" class="admin-input_list">
        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">タイトル</label>
          <textarea
            v-model="menu.title"
            maxlength="100"
            class="admin-input_list-row-input"
          />
        </div>

        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">コンテンツ</label>
          <textarea
            v-model="menu.content"
            maxlength="500"
            class="admin-input_list-row-input"
          />
        </div>

        <div class="admin-input_list-row">
          <label class="admin-input_list-row-item">ボタン名</label>
          <input
            v-model="menu.btn_title"
            maxlength="10"
            class="admin-input_list-row-input"
          />
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
      <button-base
        class="admin-input_list-submit-btn"
        button-text="問い合わせ内容を更新する"
        :is-loading="isLoading"
        @click="updateInquiry"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

import adminService from '@/services/admin';

export default defineComponent({
  name: 'AdminEditPlanInquiry',
  components: {},
  setup(props, context) {
    const store = useStore();
    const menus = ref([]);
    const isLoading = ref(false);

    // methods
    const getInquiryList = async () => {
      const res = await adminService.getInquiryList();
      menus.value = res.menus;
    };
    const updateInquiry = async () => {
      isLoading.value = true;
      const res = await adminService.updateInquiryContets(menus.value);
      isLoading.value = false;
      if (res.success === true) getInquiryList();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };

    // lifecycle
    onBeforeMount(async () => await getInquiryList());

    return { menus, updateInquiry, isLoading };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.admin-input {
  @include flex_center();
  align-items: flex-start;
  padding: 2rem;
  line-height: 1.5;
}
.admin-input_list {
  background: #fff;
  padding: 20px;
  width: 50rem;
  margin-right: 2rem;
}
.admin-input_list-row {
  margin-bottom: 1.2rem;
  padding-bottom: 1.2rem;
  &:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
  }
}
.admin-input_list-row-item {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}
.admin-input_list-row-input {
  display: block;
  border: 1px solid $border_color;
  padding: 0.4rem;
  width: 100%;
}

.btn-wrapper {
  padding: 20px;
}
.admin-input_list-submit-btn {
  width: 100%;
  height: 48px;
  border-radius: 24px !important;
}

.admin-input_regist-view {
  @extend .admin-input_list;
  background: #eaeaea;
  margin-right: 0;
}

.regist-block__title {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0.6rem;

  &:nth-of-type(2) {
    margin-top: 2.4rem;
  }
}
.regist-block__list {
  padding: 2rem;
  background: #fff;
  border-radius: 0.4rem;
}
.regist-block__list-item {
  margin-bottom: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #f1f1f1;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

input:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid;
}

.fa-warning {
  color: $mochica_color_red;
  padding-top: 4px;
  font-size: 1.2rem;
}
span.red {
  color: #f00;
}
</style>
