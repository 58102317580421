import httpClient from '@/http-client';

const PlanManager = function _PlanManager() {
  const manager = Object.create(PlanManager.prototype);
  return manager;
};

PlanManager.prototype = {
  async sendInquiryRequest(payload) {
    const response = await httpClient.post('/plan/inquiry', payload);
    return response.data;
  },
  async sendChangeRequest(payload) {
    const response = await httpClient.post('/plan/change_request', payload);
    return response.data;
  },
  async getInquiryList(payload) {
    const response = await httpClient.get('/plan/inquiry', payload);
    return response.data;
  },
};

export default PlanManager();
