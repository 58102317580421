<template>
  <div class="form__wrapper">
    <local-page-loader v-if="isLoading === true" :is-full-size="true" />
    <div v-else class="form__inner">
      <ext-page-error v-if="statusCode !== 0 && statusCode !== 2" />
      <transition name="fade">
        <div v-if="statusCode === 0 || statusCode === 2">
          <div class="form__inner__title">
            <div class="company_name">{{ companyName }}</div>
            <div class="entry_title">アンケートフォーム</div>
          </div>
          <div
            v-if="statusCode === 0"
            ref="formContent"
            class="form__inner__contents"
          >
            <flag-survey-questions
              :token="token"
              :company-name="companyName"
              :survey-title="surveyTitle"
              :questions="questions"
              @completed="openCompleted"
            />
          </div>
          <div
            v-else-if="statusCode === 2"
            ref="formContent"
            class="form__inner__contents"
          >
            <ext-page-completed
              :title="message"
              content="このまま画面を閉じてください。"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import qs from 'qs';

import flagSurveyService from '@/services/flag-survey';
import FlagSurveyQuestions from '@/components/page/flagsurvey_ext/components/FlagSurveyQuestions';
import ExtPageCompleted from '@/components/features/extPage/components/ExtPageCompleted';
import ExtPageError from '@/components/features/extPage/components/ExtPageError';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';

export default defineComponent({
  name: 'FlagSurveyExt',
  components: {
    FlagSurveyQuestions,
    ExtPageCompleted,
    ExtPageError,
    LocalPageLoader,
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const statusCode = ref(null);
    const companyName = ref('');
    const message = ref('');
    const token = ref('');
    const surveyTitle = ref('');
    const questions = ref([]);

    // methods
    const fetchFlagsurveyQuestions = async () => {
      isLoading.value = true;
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      token.value = query.token;
      const res = await flagSurveyService.fetchFlagsurveyQuestions(query.token);
      companyName.value = res.company_name;
      if (res.status_code === 0) {
        surveyTitle.value = res.survey.title;
        questions.value = res.questions;
      } else {
        message.value = res.message;
      }
      statusCode.value = res.status_code;
      await store.dispatch('page/SET_LOADED_FOR_EXT');
      isLoading.value = false;
    };
    const openCompleted = text => {
      message.value = text;
      statusCode.value = 2;
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchFlagsurveyQuestions();
    });

    return {
      isLoading,
      statusCode,
      companyName,
      message,
      token,
      surveyTitle,
      questions,
      openCompleted,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.form__wrapper {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #e5e5e5;
}

.form__inner {
  width: 100%;
  max-width: 375px;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: 0.05em;
  background-color: #fff;
}

.form__inner__title {
  padding: 20px 15px 60px 15px;
  text-align: center;
  background-image: url('../../assets/img/entryform_bg.png');
  background-size: 375px auto;
  color: #fff;
  background-repeat: repeat-y;

  > .company_name {
    font-size: 28px;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 10px;
  }

  > .entry_title {
    line-height: 1.5;
    font-weight: bold;
  }
}

.form__inner__contents {
  margin-top: -35px;
  padding: 30px 20px 20px;
  font-size: 1.3rem;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
</style>
