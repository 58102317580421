<template>
  <div v-if="staff.role.id === 1" class="value">
    {{ staff.role.description }}
  </div>
  <div v-else class="value">
    <div class="edit-text">
      <h2 v-if="editMode" class="staff_setting_list_item_ttl">
        <select v-model="staff.role.id" name="role_id" class="ui-select">
          <option value="1">管理者</option>
          <option value="2">人事担当</option>
          <option value="3">担当者</option>
        </select>
      </h2>
      <div v-else>
        {{ staff.role.description }}
      </div>

      <button-base
        v-show="staff.role.id !== 1"
        class="btn"
        :rejected-admin-ids="[2]"
        :button-text="editMode === true ? '権限を確定' : '権限を変更'"
        :button-type="editMode === true ? 'success' : 'primary'"
        :icon-file-name="editMode === true ? 'check_white' : 'pen_white'"
        @click="editMode === true ? commit() : (editMode = true)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'AdminEditStaffRole',
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['commitedRoleChange'],
  setup(props, context) {
    const editMode = ref(false);

    const commit = () => {
      context.emit('commitedRoleChange', {
        staff_id: props.staff.id,
        role: props.staff.role.id,
      });
      editMode.value = false;
    };

    return { editMode, commit };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.edit-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ui-select {
  height: 30px;
  border: 1px solid #e2e2e2;
}
.btn {
  margin-left: 20px;
}

@media (max-width: ($media_query_sp)) {
  .edit-text {
    font-size: 1rem;
  }
  .btn {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 150px;
    bottom: -50px;
  }
}
</style>
