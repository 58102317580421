<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import AutoActionsTemplate from '@/components/features/autoActions/templates/AutoActionsTemplate.vue';
import Highlight from '@/components/ui/text/Highlight.vue';
import ProgressStatusLabel from '@/components/features/progress/ProgressStatusLabel.vue';

import FlagGroupConfirm from '@/components/features/flagGroup/FlagGroupConfirm.vue';

import { download } from '@/utils/file';
import {
  PROGRESS_STATUSES,
  FINISHED_PROGRESS_STATUSES,
  PROGRESS_STATUSES_LABEL,
  FINISHED_PROGRESS_STATUSES_LABEL,
} from '@/defines/progresses';
import { CONFIRM_COMPONENTS } from '@/defines/autoActions';

defineProps({
  formValues: {
    type: Object,
    default: () => ({}),
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['submit']);

const store = useStore();
const router = useRouter();

const handleDownloadFile = file => {
  download(file.blob, file.fileName);
};

onMounted(() => {
  store.dispatch('page/SET_LOADED');
});
</script>

<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <form @submit.prevent="emit('submit')">
        <auto-actions-template
          :header-title="'登録内容の確認'"
          :back-page-title="'自動アクションの登録に戻る'"
          @back-page="() => router.push({ name: 'AutoActionsCreate' })"
        >
          <template #titleDescription>
            自動アクションを以下の内容で登録します。
            <br />
            内容をご確認の上、決定ボタンを押してください。
            <br />
            <small class="section-caption">
              ※登録後は自動アクション名、メール・LINEの文面の変更しかできません。ご注意ください。
            </small>
          </template>
          <template #body>
            <div class="section-body-inner">
              <section class="confirm-wrapper action-name">
                <div class="confirm-outer">
                  <div class="confirm-inner">
                    <h3 class="confirm-title">自動アクション名</h3>
                    <div class="confirm-contents text">
                      <p>
                        {{ formValues.actionName }}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section class="confirm-wrapper">
                <highlight :h-level="2">アクションの詳細内容</highlight>
                <div class="confirm-outer">
                  <div class="confirm-inner">
                    <h3 class="confirm-title">自動アクションさせる選考</h3>
                    <div class="confirm-contents elements">
                      <div class="gray-box">
                        <div class="gray-box-inner">
                          <ul class="items">
                            <li class="item">
                              <div class="title">選考名</div>
                              <div class="contents">
                                {{ formValues.selection?.title }}
                              </div>
                            </li>
                            <li class="item">
                              <div class="title">選考の管理者用メモ</div>
                              <div class="contents">
                                {{ formValues.selection?.description }}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="confirm-inner">
                    <h3 class="confirm-title">実行する自動アクション</h3>
                    <div class="confirm-contents text">
                      <component
                        :is="CONFIRM_COMPONENTS[formValues.actionType]"
                        :results="{ ...formValues.results }"
                        :selection="{ ...formValues.assignProgress }"
                        :rich-message="{ ...formValues.richMessage }"
                        :flag-survey="{ ...formValues.flagSurvey }"
                        :notice="{ ...formValues.notice }"
                        @download-file="handleDownloadFile($event)"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section class="confirm-wrapper">
                <highlight :h-level="2">自動アクションを実行する条件</highlight>
                <div class="confirm-outer">
                  <div class="confirm-inner">
                    <h3 class="confirm-title">応募者のステータス</h3>
                    <div class="confirm-contents elements">
                      <div class="progress-statuses">
                        <progress-status-label
                          v-for="statusKey in formValues.applicantStatus.split(
                            ' ',
                          )"
                          :key="statusKey"
                          :label-name="
                            PROGRESS_STATUSES_LABEL[
                              PROGRESS_STATUSES[statusKey]
                            ] ??
                            FINISHED_PROGRESS_STATUSES_LABEL[
                              FINISHED_PROGRESS_STATUSES[statusKey]
                            ]
                          "
                          class="w110"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="confirm-inner">
                    <h3 class="confirm-title">応募者のフラグ</h3>
                    <div class="confirm-contents elements">
                      <flag-group-confirm
                        :flag-groups="formValues.flagGroups"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section class="confirm-wrapper">
                <highlight :h-level="2">
                  自動アクションを実行するタイミング
                </highlight>
                <div class="confirm-outer">
                  <div class="confirm-inner">
                    <h3 class="confirm-title">自動アクションのタイミング</h3>
                    <div class="confirm-contents text">
                      <template v-if="formValues.triggerDelayDay === 0">
                        <p>すぐに実行</p>
                      </template>
                      <template v-else>
                        <p>
                          {{ formValues.triggerDelayDay }}日後の{{
                            formValues.triggerHour
                          }}:{{ formValues.triggerMin }}に実行
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </template>
          <template #footer>
            <div class="page-footer">
              <button-base
                type="button"
                class="btn-cancel"
                button-type="secondary"
                button-text="入力に戻る"
                @click="() => router.push({ name: 'AutoActionsCreate' })"
              />
              <button-base
                type="submit"
                class="btn-confirm"
                :button-text="'登 録'"
                :is-loading="isSubmitting"
              />
            </div>
          </template>
        </auto-actions-template>
      </form>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
.page-wrapper {
  margin: 20px 0;
  padding: 0 20px;
  .page-inner {
    padding: 0 30px 30px 40px;
    background-color: #fff;
  }
}

.section-caption {
  color: #777;
  font-size: $font_12;
}

.confirm-inner {
  display: flex;
  flex-direction: column;
  &:has(.text) {
    gap: 8px;
  }
  &:has(.elements) {
    gap: 12px;
  }
}
.confirm-wrapper {
  @extend .action-wrapper;
}
.confirm-outer {
  @extend .action-outer;
}
.confirm-inner {
  @extend .action-inner;
}

.confirm-title {
  font-weight: bold;
  font-size: $font_14;
  line-height: 1.5;
}
.confirm-contents {
  > p {
    font-size: $font_16;
    line-height: 1.8;
  }
}
.progress-statuses {
  .w110 {
    flex-basis: 110px;
  }
  @media (max-width: ($media_query_sp)) {
    .w110 {
      flex-basis: calc(50% - 4px);
    }
  }
}
.page-footer {
  display: flex;
  gap: 12px;
  margin: 20px 0 0;
  & > button {
    width: 50%;
    height: 56px;
  }
}
</style>
