const roles = {
  OWNER: 'owner', // 管理者
  MANAGER: 'manager', // 人事担当
  USER: 'user', // 担当者
};
const staffsListColumns = [
  {
    name: 'name',
    name_jp: '名前',
    sort_by: 'name',
    relation: ['lastname', 'firstname'],
  },
  {
    name: 'email',
    name_jp: 'メールアドレス',
    sort_by: 'email',
    relation: ['email'],
  },
  {
    name: 'role',
    name_jp: '権限',
    sort_by: 'role',
    relation: ['role.description'],
  },
  {
    name: 'delete',
    name_jp: '削除',
    sort_by: 'delete',
    relation: ['delete'],
  },
];

const isOwner = roleName => roleName === roles.OWNER;
const isManager = roleName =>
  roleName === roles.OWNER || roleName === roles.MANAGER;
const isOperator = roleName => roleName === roles.USER;

export { staffsListColumns, isOwner, isManager, isOperator };
