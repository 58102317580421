<template>
  <div>
    <div class="btn_back">
      <p @click="onClickCancelLinkGoogleCalendar">システム連携に戻る</p>
    </div>
    <div>
      <dl class="setting_list">
        <dd class="setting_text">
          <p class="setting_title">Googleカレンダー連携</p>
          <p>
            Googleのサービスアカウントの認証キーファイル（JSON）をご用意いただき、アップロード後連携ボタンをクリックしてください。
          </p>
          <!-- <p class="link_box">
              サービスアカウント 認証キーは
              <a href="" target="_blank">こちらの記事</a>
              を参考にしてご用意ください。
            </p> -->
        </dd>
      </dl>
      <dl class="setting_list">
        <dt>
          サービスアカウント認証キー（JSON）
          <span v-if="isConnectedGoogleCalendar !== true">必須</span>
        </dt>
        <dd v-if="isConnectedGoogleCalendar === true"><p>連携済み</p></dd>
        <dd v-else>
          <p>JSON形式で作成した認証キーファイルをアップロードしてください。</p>
          <div class="input-file_wrapper">
            <label for="file_upload">
              <input
                id="file_upload"
                type="file"
                accept="application/json"
                class="input-file"
                @change="onSelectFile($event)"
              />
              ファイル選択
            </label>
            <span class="file_name">
              {{ file && file.data ? file.data.name : '' }}
            </span>
          </div>
        </dd>
      </dl>
      <dl class="setting_list">
        <dd>
          <button-base
            class="btn btn-send"
            button-text="連携"
            button-type="success"
            :is-loading="isLoading"
            :is-disabled="!file.data"
            :rejected-role-ids="[2, 3]"
            :rejected-admin-ids="[2]"
            @click="sendServiceAccount"
          />
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import GoogleCalendarService from '@/services/google-calendar';

export default defineComponent({
  name: 'LinkGoogleCalendarLinkServiceAccount',
  props: {
    isConnectedGoogleCalendar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeMode', 'changeIsConnectedGoogleCalendar'],
  setup(props, context) {
    const store = useStore();
    const file = reactive({ data: null });
    const isLoading = ref(false);

    // methods
    const sendServiceAccount = async () => {
      isLoading.value = true;
      if (!file.data) return;
      const res = await GoogleCalendarService.linkGoogleCalendar(file.data);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        type: res.success,
        message: res.message,
      });
      isLoading.value = false;
      if (res.success === true) {
        resetFile();
        context.emit('changeMode', 'home');
        context.emit('changeIsConnectedGoogleCalendar', true);
      }
    };
    const onClickLinkGoogleCalendar = () => {
      context.emit('changeMode', 'linkGoogleCalendar');
    };
    const onClickCancelLinkGoogleCalendar = () => {
      resetFile();
      context.emit('changeMode', 'home');
    };

    const onSelectFile = event => {
      const { files } = event.target;
      if (files.length > 1) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '複数のファイルはアップロードできません',
          type: false,
        });
        return;
      }
      if (files === null || !files[0]) {
        resetFile();
        return;
      }
      file.data = files[0];
    };
    const resetFile = () => {
      file.data = null;
    };

    return {
      file,
      isLoading,
      onClickLinkGoogleCalendar,
      onClickCancelLinkGoogleCalendar,
      onSelectFile,
      sendServiceAccount,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.setting_title {
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #5896cc;
  border-left: 6px solid #5896cc;
}

.setting_text {
  margin-bottom: 24px;
  line-height: 1.75;
  > span {
    display: block;
    margin-top: 5px;
  }
}

.setting_list {
  border-top: 1px solid #e5e5e5;
  padding-top: 24px;
  margin-bottom: 24px;
  > dt {
    margin-bottom: 18px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: bold;
    > span {
      display: inline-block;
      width: 44px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      background-color: #bb1b1c;
      border-radius: 4px;
      text-align: center;
    }
  }
  > dd {
    margin-bottom: 18px;

    > p {
      margin-bottom: 10px;
    }

    > .input-file_wrapper {
      margin-bottom: 20px;
      > label {
        display: inline-block;
        width: 126px;
        height: 40px;
        margin-top: 18px;
        margin-right: 10px;
        line-height: 40px;
        text-align: center;
        background-color: #efefef;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        cursor: pointer;
      }
      .file_name {
        color: #777;
        vertical-align: 2px;
        line-height: 1;
      }
    }
  }
}

.btn_back {
  margin-bottom: 27px;
  > p {
    padding-left: 15px;
    line-height: 1;
    background-image: url('../../../../../assets/img/icon-back.svg');
    background-size: auto 12px;
    background-position: 0 1.5px;
    background-repeat: no-repeat;
    color: #5896cc;
    cursor: pointer;
  }
}

.link_box {
  padding: 10px 16px;
  margin-top: 16px;
  border: 1px solid #d7e5ee;
  line-height: 20px;
  border-radius: 4px;
  background: #f3f9fd;
  > a {
    font-weight: bold;
    padding: 0 17px 2px 0;
    color: #1899d6;
    text-decoration: underline;
    background-image: url('../../../../../assets/img/icon-external-link.svg');
    background-size: 14px 14px;
    background-position: right 1px;
    background-repeat: no-repeat;
  }
}

.input-file {
  display: none; /* アップロードボタンのスタイルを無効にする */
}

.btn-send {
  width: 100%;
  height: 54px;
  background-color: #12b312;
  &.btn-long {
    margin-top: 15px;
    width: 260px;
  }
  &:disabled {
    color: #333 !important;
    background-color: #999;
    opacity: 0.3;
    cursor: not-allowed;
  }
}
</style>
