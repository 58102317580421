<template>
  <main class="completed_otp">
    <img
      class="icon_entryform_complete"
      src="@/assets/img/icon_error_page.svg"
      alt=""
    />
    <div class="error_title">{{ title }}</div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p v-html="$utils.sanitize(content)" />
  </main>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExtPageError',
  props: {
    title: {
      type: String,
      default: 'ページは開けませんでした',
    },
    content: {
      type: String,
      default:
        'URLが間違っている可能性があります。\nURLをご確認いただき、\n再度アクセスしてください。',
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.completed_otp {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 15px - 130px);
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
}

.icon_entryform_complete {
  width: 96px;
  margin: 0 auto 20px;
}

.error_title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 22px;
  color: #df1616;
}
</style>
