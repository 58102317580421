<template>
  <div class="login-form-wrapper">
    <login-form
      v-if="mode === 'loginLogin'"
      :is-master="true"
      @submitLogin="login"
    />

    <notification />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import Notification from '@/components/ui/notification/components/Notification';
import LoginForm from '@/components/features/loginForm/components/LoginForm';

export default defineComponent({
  name: 'MasterLogin',
  components: {
    Notification,
    LoginForm,
  },
  setup(props, context) {
    const store = useStore();
    const mode = ref('loginLogin'); // loginLogin, loginToIssue

    // methods
    const login = async payload => {
      const res = await store.dispatch('auth/MASTER_LOGIN', {
        email: payload.email,
        password: payload.password,
        company_id: payload.company_id,
      });
      if (res.success === false) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `${res.message}`,
          type: res.success,
        });
      }
    };

    return {
      mode,
      login,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.support-message {
  width: 40rem;
  letter-spacing: 1px;
  background: #eee;
  border: 1px solid #808080;
  padding: 2rem;
  margin: 0 0 2rem;
  line-height: 1.3;
}
.br-block {
  display: block;
  text-align: left;
}
.upper-title {
  text-align: left;
  margin-bottom: 1rem;
}
.under-contents {
  text-align: left;
  .contact_line {
    border-bottom: solid 1px;
    padding: 1rem 0 0 0;
    margin-bottom: 1rem;
  }
  .contact_text {
    margin-bottom: 0.5rem;
  }
}
.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.login-form__logo-title {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  fill: #88c6ed;
}
.login-form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
  background: url(../../assets/img/login_bg.jpg) no-repeat;
  background-size: cover;
  .logo-title {
    width: 38rem;
    fill: #88c6ed;
    margin: 0 auto 3.6rem;
  }
}

@media (max-width: ($media_query_tablet)) {
}

@media (max-width: ($media_query_sp)) {
  .login-form-wrapper {
    padding: 0 math_size(10);
  }
}
</style>
