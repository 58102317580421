<template>
  <main class="flexbox width-sp-limit">
    <div class="contents">
      <div class="mochica_desc">
        <div class="bg-bold">{{ companyName }}</div>
        <p class="bg-bold-desc">イベント予約フォーム</p>
        <p>
          参加を希望する日時、会場、時間を選択し、送信ボタンを押してください。
        </p>
      </div>
      <div class="form_body">
        <form class="form-wrapper">
          <div class="selections">
            <div class="selections_desc">
              <span>
                次の中から
                <span class="bold">参加をご希望のイベント</span>
                を1つ選択してください。
              </span>
            </div>
            <ul class="selections_wrapper">
              <li
                v-for="selection in selections"
                :key="selection.id"
                :class="{
                  selections_active: selectedSelection.data.id === selection.id,
                }"
                class="selections_item"
                @click="selectSelection(selection)"
              >
                {{ selection.event.title }}
              </li>
            </ul>
          </div>
          <div v-if="isVisibleVenue" id="id_venue">
            <div class="selection_venue_desc">
              <span>
                <span class="bold">
                  {{ selectedSelection.data.event.title }}
                </span>
                での参加を希望する、日時・会場を選択してください。
              </span>
            </div>
            <div class="selection_notice">
              <b>{{ companyName }}からの連絡事項</b>
              <p class="selection_notice_content">
                {{ selectedSelection.data.notice }}
              </p>
            </div>
            <ul class="selection_venue">
              <li
                v-for="venue in selectedSelection.data.venues"
                :key="venue.id"
                :class="{ venue_active: selectedVenue.data.id === venue.id }"
                class="selection_venue_item"
                @click="selectVenue(venue)"
              >
                <div class="selection_venue_item-date">
                  <b>
                    {{ $utils.parseDateTime(venue.date, 'YYYY年M月D日(ddd)') }}
                  </b>
                </div>
                <div class="selection_venue_item-name">
                  <div>{{ venue.name }}</div>
                  <span v-if="selectedVenue.data.id === venue.id">選択中</span>
                </div>
                <div class="selection_venue_item-address">
                  {{ venue.address }}
                </div>
              </li>
            </ul>
          </div>
          <div v-if="isVisibleTime" id="id_time" class="selection_time">
            <h2 class="selection_time_desc">
              選択した会場で参加を希望する時間をお選びください。
            </h2>
            <ul class="selection_time_item_wrapper">
              <li
                v-for="time in selectedVenueTimetableFormatted"
                :key="time.id"
                :class="{ time_active: selectedTime.data.id === time.id }"
                class="selection_time_item"
                @click="selectTime(time)"
              >
                {{ time.start }}~{{ time.last }}
              </li>
            </ul>
          </div>
          <div v-if="isVisibleCheck" id="id_all" class="check_selection">
            <h2 class="check_selection_desc">
              以下の内容で日程を決定します。よろしければ送信ボタンをクリックしてください。
            </h2>
            <ul class="check_selection_wrapper">
              <li class="check_selection_venue check_selection_item">
                <dl>
                  <dt class="check_selection_venue_company"><b>会社名：</b></dt>
                  <dd class="dt check_selection_venue_company">
                    {{ companyName }}
                  </dd>
                </dl>
                <dl>
                  <dt class="check_selection_venue_selection">
                    <b>イベント名：</b>
                  </dt>
                  <dd class="dt check_selection_venue_selection">
                    {{ selectedSelection.data.event.title }}
                  </dd>
                </dl>
                <dl>
                  <dt class="check_selection_venue_date"><b>日付：</b></dt>
                  <dd class="dt check_selection_venue_date">
                    {{
                      $utils.parseDateTime(
                        selectedVenue.data.date,
                        'YYYY年M月D日(ddd)',
                      )
                    }}
                  </dd>
                </dl>
                <dl>
                  <dt class="check_selection_time_ttl"><b>時間：</b></dt>
                  <dd class="dt check_selection_time_value">
                    {{ $utils.parseTime(selectedTime.data.start) }}〜{{
                      $utils.parseTime(selectedTime.data.last)
                    }}
                  </dd>
                </dl>
                <dl>
                  <dt class="check_selection_venue_name"><b>会場名：</b></dt>
                  <dd class="dt check_selection_venue_name">
                    {{ selectedVenue.data.name }}
                  </dd>
                </dl>
                <dl>
                  <dt class="check_selection_venue_address"><b>住所：</b></dt>
                  <dd class="dt check_selection_venue_address">
                    {{ selectedVenue.data.address }}
                  </dd>
                </dl>
                <dl>
                  <dt class="check_selection_venue_room"><b>部屋名：</b></dt>
                  <dd class="dt check_selection_venue_room">
                    {{ selectedVenue.data.room_name }}
                  </dd>
                </dl>
              </li>
            </ul>
            <button-base
              class="regist_btn"
              button-text="上記の内容で送信"
              :is-loading="isLoading"
              @click="postSelectionTime"
            />
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, reactive, computed } from 'vue';

export default defineComponent({
  name: 'InputSelectionForm',
  props: {
    companyName: {
      type: String,
      required: true,
    },
    selections: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['postSelectionTime'],
  setup(props, context) {
    const isVisibleVenue = ref(false);
    const isVisibleTime = ref(false);
    const isVisibleCheck = ref(false);
    const selectedSelection = reactive({ data: {} });
    const selectedVenue = reactive({ data: {} });
    const selectedTime = reactive({ data: {} });

    // computed
    const selectedVenueTimetableFormatted = computed(() => {
      if (selectedVenue.data) {
        return selectedVenue.data.timetables.map(timetable => ({
          id: timetable.id,
          limit: timetable.limit,
          start: timetable.start.slice(0, -3),
          last: timetable.last.slice(0, -3),
        }));
      }
      return null;
    });

    // methods
    const postSelectionTime = () => {
      if (selectedTime.data.id) {
        context.emit('postSelectionTime', {
          timetableId: selectedTime.data.id,
        });
      }
    };

    const scrollDown = divId => {
      setTimeout(() => {
        document.getElementById(divId).scrollIntoView({
          behavior: 'smooth',
        });
      }, 100);
    };

    const selectSelection = selection => {
      if (selection) {
        isVisibleVenue.value = true;
        isVisibleTime.value = false;
        isVisibleCheck.value = false;
        selectedVenue.data = {};
        selectedTime.data = {};
        selectedSelection.data = selection;
        scrollDown('id_venue');
      }
    };

    const selectVenue = venue => {
      if (venue) {
        selectedVenue.data = venue;
        isVisibleTime.value = true;
        isVisibleCheck.value = false;
        selectedTime.data = {};
        scrollDown('id_time');
      }
    };

    const selectTime = time => {
      if (time) {
        selectedTime.data = time;
        isVisibleCheck.value = true;
        scrollDown('id_all');
      }
    };

    return {
      isVisibleVenue,
      isVisibleTime,
      isVisibleCheck,
      selectedSelection,
      selectedVenue,
      selectedTime,
      selectedVenueTimetableFormatted,
      postSelectionTime,
      selectSelection,
      selectVenue,
      selectTime,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

$order-number-fill: #1e880d;
$order-number-border: #329222;
@mixin pseudo_number($diameter: 3.5rem) {
  @include flex_center_center;
  height: $diameter;
  width: $diameter;
  border-radius: calc(#{$diameter} / 2);
  margin: calc(#{$diameter} / 2);
  padding: calc(#{$diameter} / 2);
  border: 1px solid $order-number-fill;
  color: $order-number-border;
  font-weight: bold;
  font-size: $diameter * 0.625;
}
.mochica_desc {
  text-align: center;
  margin: 0;
  p {
    font-size: 1.4rem;
  }
  .bg-bold {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
  }
  .bg-bold-desc {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
}
.selections_active {
  border-color: #1ecd01 !important;
  color: #1ecd01 !important;
  background: #fff !important;
}
.regist_btn {
  width: 100%;
  border: none;
  background-color: #000 !important;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem !important;
  padding: 10px 0 !important;
  border-radius: 20px !important;
  cursor: pointer;
}

.contents {
  width: 100%;
}
.form-wrapper {
  width: 90%;
  max-width: 96rem;
  margin: 0 auto;
}
.form_body {
  .selections {
    .selections_desc {
      display: flex;
      align-items: center;
      padding-top: 5rem;
      padding-bottom: 2rem;
      margin: 3rem 0 1rem;
      border-top: 1px solid #d0d0d0;
      border-bottom: 1px solid $border_color;
      font-size: 1.4rem;
      .bold {
        font-weight: bold;
      }
      &::before {
        content: '1';
        @include pseudo_number;
      }
    }
    .selections_wrapper {
      padding-top: 2rem;
      padding-bottom: 3rem;
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 1.2rem;
    }
    .selections_item {
      cursor: pointer;
      border: 1px solid $border_color;
      margin-bottom: 1rem;
      padding: 1.2rem 0;
      margin-left: 1%;
      margin-right: 1%;
      border-radius: 0.2rem;
      width: 48%;
      background: #f7f7f7;
      font-size: 1.4rem;
      transition: all ease 0.2s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 1px solid #656565;
        color: #656565;
      }
      &.active {
        background: #1ecd01;
        color: #fff;
        border-color: #1ecd01;
      }
    }
  }
  .selection_notice {
    display: block;
    font-size: 1.2rem;
    margin: 2rem 0;
    text-align: left;
    .selection_notice_content {
      background: #f2f2f2;
      font-size: 1.2rem;
      border: 1px solid #e3e3e3;
      border-radius: 0.3rem;
      padding: 0.5rem 1rem;
      white-space: pre-wrap;
      word-wrap: break-word;
      line-height: 1.2;
    }
  }
  .selection_venue_desc {
    margin: 3rem 0 1rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    padding-top: 5rem;
    padding-bottom: 2rem;
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid $border_color;
    .bold {
      font-weight: bold;
    }
    &::before {
      content: '2';
      @include pseudo_number;
    }
  }
  .form-wrapper {
    .selection_venue {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 1.4rem;
      .selection_venue_item {
        width: 48%;
        padding: 1%;
        cursor: pointer;
        transition: all ease 0.2s;
        &::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          background: $border_color;
          position: absolute;
          bottom: -9%;
          left: 0;
          opacity: 0.4;
        }
        position: relative;
        .selection_venue_item-date {
          color: #000;
          font-size: 1.4rem;
          padding: 1rem 0;
          text-align: left;
        }
        .selection_venue_item-name {
          background: #656565;
          color: #fff;
          font-weight: bold;
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0.5rem;
          span {
            font-weight: lighter;
          }
        }
        .selection_venue_item-address {
          text-align: left;
          margin-top: -0.1rem;
          border: 1px solid #656565;
          padding: 1rem;
        }

        &:nth-of-type(2n) {
          margin-left: 2%;
          &::after {
            content: '';
            display: inline-block;
            height: 100%;
            width: 1px;
            background: $border_color;
            position: absolute;
            left: -4%;
            top: 0;
            opacity: 0.4;
          }
        }
        &:last-of-type,
        &:nth-last-of-type(2) {
          margin-bottom: 0;
          &::before {
            display: none;
          }
        }

        &:hover {
          opacity: 0.6;
        }
        &.active {
        }
      }
    }
    .venue_active {
      .selection_venue_item-name {
        background-color: #1ecd01 !important;
      }
      .selection_venue_item-address {
        border-color: #1ecd01 !important;
      }
    }
    .time_active {
      border-color: #1ecd01 !important;
      color: #1ecd01 !important;
    }
    .selection_time {
      .selection_time_desc {
        display: flex;
        margin: 3rem 0 1rem;
        font-size: 1.4rem;
        padding-top: 5rem;
        align-items: center;
        padding-bottom: 2rem;
        border-top: 1px solid $border_color;
        border-bottom: 1px solid #d0d0d0;
        &::before {
          content: '3';
          @include pseudo_number;
        }
      }
      .selection_time_item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $border_color;
        width: 24%;
        text-align: center;
        margin-left: 1%;
        margin-bottom: 1%;
        font-size: 1.4rem;
        font-weight: lighter;
        padding: 1.5rem;
        border-radius: 0.2rem;
        transition: all ease 0.3s;
        &:hover {
          border: 1px solid #656565;
          color: #656565;
        }
        &.active {
          background: #1ecd01;
          color: #fff;
          border-color: #1ecd01;
        }
      }
      .selection_time_item_wrapper {
        padding: 2rem 2rem 1rem;
        border-radius: 0.6rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 1.2rem;
      }
    }
    .check_selection {
      .check_selection_desc {
        display: flex;
        align-items: center;
        padding-top: 5rem;
        padding-bottom: 2rem;
        margin: 3rem 0 1rem;
        border-top: 1px solid #d0d0d0;
        border-bottom: 1px solid $border_color;
        font-size: 1.4rem;
        &::before {
          content: '4';
          @include pseudo_number;
        }
      }

      .check_selection_item {
        dl {
          display: flex;
          margin-bottom: 1rem;
          text-align: left;
          font-size: 1.4rem;
          dt {
            font-weight: bold;
            min-width: 110px;
            text-align: right;
          }
        }
      }
      .regist_btn {
        margin-top: 3rem;
        transition: all ease 0.3s;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .contents {
    width: 100%;
  }
  .form-wrapper {
    width: 100%;
    max-width: unset;
    margin: 1rem auto;
  }
  .form_body {
    padding: 1rem 0;
    .form-wrapper {
      .selections_desc,
      .selection_venue_desc {
        margin-bottom: 1rem;
        display: block;
        &::before {
          text-align: center;
          margin: 0 auto 1rem;
        }
      }
      .selections_wrapper {
        border: none;
        padding: 0;
      }
      .selections_item {
        width: 100%;
        justify-content: center;
        text-align: center;
        margin-bottom: 3%;
      }
      .selection_venue {
        border: none;
        padding: 0;
        .selection_venue_item {
          width: 100%;
          margin-bottom: 1%;
          padding-top: 2%;
          padding-bottom: 2%;
          justify-content: center;
          text-align: center;
          &::before {
            display: none;
          }
          .selection_venue_item-name {
            border-radius: 0.2rem;
            background: #656565;
            text-align: left;
            color: #fff;
            font-weight: bold;
            padding: 1rem;
          }
          .selection_venue_item-address {
            border-radius: 0.2rem;
            text-align: left;
            margin-top: -0.1rem;
            border: 1px solid $border_color;
            padding: 1rem;
          }

          &:nth-of-type(2n) {
            margin-left: 0;
            &::after {
              display: none;
            }
          }
        }
      }
      .venue_active {
        .selection_venue_item-name {
          background-color: #1ecd01 !important;
        }
        .selection_venue_item-address {
          border-color: #1ecd01 !important;
        }
      }
      .time_active {
        .selection_time_item {
          border-color: #1ecd01 !important;
          color: #1ecd01 !important;
        }
      }
      .selection_time {
        .selection_time_desc {
          display: block;
          &::before {
            text-align: center;
            margin: 0 auto 1rem;
          }
        }
        .selection_time_item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 0 0 0.9rem;
          padding: 1.5rem 0;
          border-radius: 0.3rem;
        }
        .selection_time_item_wrapper {
          border: none;
          padding: 0;
        }
      }
      .check_selection {
        .check_selection_desc {
          margin-bottom: 1rem;
          display: block;
          &::before {
            text-align: center;
            margin: 0 auto 1rem;
          }
        }
        .check_selection_item {
          dl {
            font-size: 1.2rem;
          }
        }
        .regist_btn {
          margin-top: 3rem;
        }
      }
    }
  }
}
</style>
