<template>
  <modal-base
    :is-view="isVisible"
    :title="'エントリーフォームの一括送信'"
    :is-closable-click-outside="false"
    :is-view-close-button="false"
    class="modal-bulk-send-entry-form"
  >
    <template #main>
      <div class="main">
        <div class="body">
          <div class="message">
            一覧画面にて選択した応募者に一括でエントリーフォームを送信します。
          </div>
          <div class="area-applicant-list allow-announced">
            <p class="title">
              <span class="target-number">対象件数：</span>
              <span class="applicantNum">
                <span class="num">{{ allowApplicants.length }}名</span>
              </span>
            </p>
            <selectable-applicant-table
              class="change-list"
              :class="{ 'long-list': allowApplicants.length >= 3 }"
              :applicants="allowApplicants"
              :invisible-columns="invisibleColumns"
              :is-sortable="false"
            />
            <div v-if="allowApplicants.length === 0" class="area-err">
              対象の応募者がいません。
            </div>
          </div>
          <div
            v-if="denyApplicants.length > 0"
            class="area-applicant-list deny-announced"
          >
            <p class="title">
              以下の応募者は【エントリー済み】のため
              <span class="alert_red">
                エントリーフォームを送信できません。
              </span>
            </p>
            <selectable-applicant-table
              class="change-list"
              :class="{ 'long-list': allowApplicants.length >= 3 }"
              :applicants="denyApplicants"
              :invisible-columns="invisibleColumns"
              :is-sortable="false"
            />
          </div>
        </div>
        <div class="btn-col2">
          <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
            キャンセル
          </div>
          <div
            class="btn btn-availability"
            @click="
              $emit(
                'onClickSettle',
                allowApplicants.map(v => v.id),
              )
            "
          >
            エントリーフォームを送信する
          </div>
        </div>
      </div>
    </template>
  </modal-base>
</template>

<script>
import { computed, defineComponent } from 'vue';

import { BASE_INVISIBLE_COLUMNS } from '@/utils/modal-resize';
import ModalBase from '@/components/ui/modals/components/ModalBase';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';

export default defineComponent({
  name: 'ModalBulkSendEntryForm',
  components: { ModalBase, SelectableApplicantTable },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    applicants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCancel', 'onClickSettle'],
  setup(props, context) {
    const allowApplicants = computed(() =>
      props.applicants.filter(v => v.email === null && v.contactTypeId === 2),
    );
    const denyApplicants = computed(() =>
      props.applicants.filter(v => v.email !== null || v.contactTypeId !== 2),
    );
    return {
      invisibleColumns: BASE_INVISIBLE_COLUMNS,
      allowApplicants,
      denyApplicants,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.modal-bulk-send-entry-form {
  overflow-y: auto;
  .main {
    .area-finished-status {
      .area-operatosssr-body {
        margin-bottom: 1.2rem;
        .view-blank-text {
          min-height: 5rem;
          background: white;
          border: 1px solid $border_color;
          border-radius: 0.4rem;
          min-height: 10rem;
          padding: 1.2rem;
          font-size: 1.2rem;
          line-height: normal;
          word-wrap: break-word;
        }
        &-txt {
          letter-spacing: 0.05em;
          border: 1px solid $border_color;
          border-radius: 2px 2px 0 0;
          padding: 1.2rem;
          font-size: 1.2rem;
          min-height: 5rem;
          height: 5rem;
          background: #fff;
        }
      }
    }
    .area-applicant-list {
      .title {
        text-align: left;
        font-weight: bold;
        margin: 10px 0;
        .target-number {
          margin-left: 5px;
        }
        .applicantNum {
          text-align: left;
          font-weight: normal;
          .num {
            font-size: 2rem;
            font-weight: bold;
          }
        }
      }
      span.alert_red {
        color: #c31e1e;
      }
    }
    .area-table {
      &.change-list {
        width: 100%;
        height: 18vh;
        margin: 0;
        border: 1px solid $border_color;
        border-top: none;
        text-align: left;
        margin-bottom: 20px;
        tr {
          border-bottom: 1px solid $border_color;
        }
      }
      @media (min-width: ($media_query_sp)) {
        &.long-list {
          height: 30vh;
        }
      }
    }
    .change-status_btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      cursor: pointer;
      &_item {
        width: 49.5%;
        height: 3.6rem;
        line-height: 3.6rem;
        background: $mochica_color;
        border-radius: 0.4rem;
        color: #fff;
        font-weight: bold;
        &.back_btn {
          background: #fff;
          border: 1px solid $border_color;
          color: #333;
          font-weight: normal;
        }
      }
    }
    .area-err {
      color: #c31e1e;
      padding-top: 4px;
      font-size: 1.2rem;
      text-align: left;
    }
    .area-available-comment {
      text-align: left;
    }
  }
}
</style>
