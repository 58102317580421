<template>
  <button
    type="button"
    class="action-button btn"
    :class="`btn-${buttonType}`"
    :style="{ fontSize: fontSize }"
    :disabled="loading || isDisabled"
    @click="$emit('click', $event)"
  >
    <div
      v-if="loading"
      class="loading-circle"
      :style="{ width: fontSize, height: fontSize }"
    >
      Loading...
    </div>
    <span v-else>
      <img v-if="imgSrcLeft !== ''" class="icon" :src="imgSrcLeft" />
      <span>{{ text }}</span>
      <img
        v-if="hasImgRightArrow === true"
        src="@/assets/img/arrow_right_white.svg"
        class="icon-rightArrow"
      />
    </span>
  </button>
</template>

<script>
// ModalDialog削除時に同時に削除
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ActionButton',
  props: {
    text: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '1.2rem',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'availability',
    },
    imgSrcLeft: {
      type: String,
      default: '',
    },
    hasImgRightArrow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    color: #333 !important;
    background-color: #999 !important;
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.loading-circle {
  margin: auto;
  font-size: 10px;
  text-indent: -9999em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(
    left,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: linear-gradient(
    to right,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  -webkit-animation: loading-circle-keyframe 0.8s infinite linear;
  animation: loading-circle-keyframe 0.8s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loading-circle:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loading-circle:after {
  background: #999;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.icon {
  margin-right: 6px;
}

.btn .icon-rightArrow {
  vertical-align: 0;
}

@-webkit-keyframes loading-circle-keyframe {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-circle-keyframe {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
