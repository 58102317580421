<template>
  <div>
    <div class="breadcrumb">
      <span class="chip" @click="$emit('onHomeClick')">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">トップ</span>
      </span>
      <span class="chip" @click="changeMode('default')">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">ファイル管理</span>
      </span>
      <span v-if="mode === 'upload' || mode === 'confirming'" class="chip">
        <img
          class="arrow-icon"
          src="@/assets/img/arrow_mypage_left_black.svg"
          alt
        />
        <span class="chip_title link">ファイルのアップロード</span>
      </span>
    </div>

    <div v-if="!isLoading">
      <div class="title-header">
        <div class="bg-bold">{{ generateTitleText(mode) }}</div>
      </div>
      <div class="file-body">
        <div v-if="mode === 'default'">
          <div class="txt-block">
            <button-round
              class="btn-round"
              button-text="ファイルをアップロード"
              :has-arrow="true"
              @click="changeMode('upload')"
            />
          </div>
          <div class="gray-bg" />
          <div class="txt-block">
            <p class="bold-thin-black top-space1">アップロード済みのファイル</p>
            <p class="sm-spacer" />
            <ul v-if="files.length > 0" class="file-list">
              <li v-for="(file, i) in files" :key="`${file}+${i}`">
                <p class="sm-blue">
                  <span @click="downloadFile(file)">
                    {{ file.original_filename }}
                  </span>
                </p>
                <p class="pad1">
                  アップロード日：{{
                    $utils.parseDateTime(file.created_at, 'YYYY年M月D日(ddd)')
                  }}
                </p>
                <p class="pad1">
                  アップロード者：{{
                    file.applicant_upload
                      ? `${applicantName}さん`
                      : `${companyName}`
                  }}
                </p>
                <button-base
                  v-if="file.applicant_upload"
                  button-text="削除"
                  button-type="secondary"
                  icon-file-name="delete_white"
                  @click="openCautionModal(file)"
                />
              </li>
            </ul>
            <div v-else>
              <div class="no-data">ファイルはありません</div>
            </div>
          </div>
        </div>
        <div v-else-if="mode === 'upload'">
          <div class="gray-bg" />
          <div class="txt-block">
            <p class="sm-bold-blue">
              アップロードするファイルを選択してください。
            </p>
            <div class="file-select">
              <div class="area-file-drop">
                <form enctype="multipart/form-data" novalidate>
                  <div
                    class="dropbox"
                    @dragleave.prevent
                    @dragover.prevent
                    @drop.prevent="dropDownImage($event)"
                  >
                    <input
                      type="file"
                      name="file"
                      class="input-file"
                      @change="selectImage($event)"
                    />
                    <div class="dropbox_inner">
                      <span class="dropbox_inner_txt">
                        ファイルをドラッグ＆ドロップ
                      </span>
                      <span class="dropbox_inner_or">または</span>
                      <button-round
                        class="btn-round btn-upload"
                        button-text="ファイルを選択"
                        :is-outlined="true"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <p class="sm-spacer" />
            <p class="bold-thin-black top-space1 bottom-space1">
              選択ファイル名
            </p>
            <p class="filename_item">
              {{ uploadedFile ? uploadedFile.name : null }}
            </p>
            <button-round
              class="btn-round btn-file-confirm"
              button-text="ファイルを確認"
              :has-arrow="true"
              @click="confirmFile"
            />
            <button-round
              class="btn-round btn-file-confirm"
              button-text="キャンセル"
              button-type="secondary"
              @click="cancelUploadMode"
            />
          </div>
        </div>
        <div v-else-if="mode === 'confirming'">
          <div class="gray-bg" />
          <div class="txt-block">
            <p class="sm-bold-blue">ファイルをアップロードします。</p>
            <p v-if="uploadedFile" class="desc">
              ファイル「{{ uploadedFile.name }}」をアップロードします。
            </p>
            <p class="mid-spacer" />
            <button-round
              class="btn-round btn-file-upload"
              button-text="ファイルをアップロード"
              :has-arrow="true"
              @click="uploadFile"
            />
            <button-round
              class="btn-round btn-file-upload"
              button-text="キャンセル"
              button-type="secondary"
              @click="changeMode('upload')"
            />
          </div>
        </div>
        <div v-else-if="mode === 'completed'">
          <div class="gray-bg" />
          <div class="txt-block">
            <p class="sm-bold-blue">アップロードが完了しました。</p>
            <p class="desc">
              ファイル管理ページにてアップロードしたファイルを確認・ダウンロードすることができます。
            </p>
            <p class="mid-spacer" />
            <button-round
              class="btn-round"
              button-text="ファイル管理に戻る"
              :is-outlined="true"
              @click="changeMode('default')"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <local-page-loader v-if="isLoading" class="loader" />
    </div>
    <notification />
    <modal-loader :is-view="isUploading" />
    <modal-window
      :is-visible="isVisibleCautionModal"
      title="ファイルの削除"
      :message="`${
        deleteTargetFile ? deleteTargetFile.original_filename : ''
      }のファイルを本当に削除しますか？`"
      button-text="削除"
      modal-type="danger"
      @click="deleteFile"
      @close="closeCautionModal"
    />
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import { validateFile } from '@/utils/file';
import MyPageService from '@/services/mypage';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import Notification from '@/components/ui/notification/components/Notification';
import ModalLoader from '@/components/ui/loaders/components/ModalLoader';
import ButtonRound from '@/components/page/mypage/components/ButtonRound.vue';

export default defineComponent({
  name: 'MypageFileUpload',
  components: {
    Notification,
    LocalPageLoader,
    ModalLoader,
    ButtonRound,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    applicantName: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
  },
  emits: ['onHomeClick'],
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const isUploading = ref(false);
    const isVisibleCautionModal = ref(false);
    const mode = ref('default'); // 'default'|'upload' | 'confirming' | 'completed'
    const uploadedFile = ref(null);
    const files = ref([]);
    const deleteTargetFile = ref({});

    onBeforeMount(async () => {
      await fetchFiles();
    });

    const generateTitleText = currentDisplayMode => {
      switch (currentDisplayMode) {
        case 'default':
          return 'ファイル管理';
        case 'upload':
          return 'ファイルのアップロード';
        case 'confirming':
          return 'ファイルのアップロード';
        case 'completed':
          return 'ファイルのアップロード';
        default:
          return 'ファイル管理';
      }
    };

    const cancelUploadMode = () => {
      uploadedFile.value = null;
      changeMode('default');
    };

    const confirmFile = () => {
      if (uploadedFile.value === null) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'ファイルが選択されていません',
          type: false,
        });
        return;
      }
      changeMode('confirming');
    };

    const updateCurrentFile = files => {
      if (files.length === 0) return;
      const file = files[0];
      const newFileInfo = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModifiedDate: file.lastModifiedDate,
        file,
      };
      const validationResult = validateFile(newFileInfo);
      if (validationResult.success === false) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: validationResult.message,
          type: false,
        });
        uploadedFile.value = null;
        return;
      }
      uploadedFile.value = newFileInfo;
    };

    const changeMode = modeName => {
      mode.value = modeName;
      window.scrollTo(0, 0);
    };

    const dropDownImage = event => {
      const files = event.dataTransfer.files;
      updateCurrentFile(files);
    };

    const selectImage = event => {
      const files = event.target.files;
      updateCurrentFile(files);
    };

    const openCautionModal = file => {
      deleteTargetFile.value = file;
      isVisibleCautionModal.value = true;
    };

    const closeCautionModal = () => {
      isVisibleCautionModal.value = false;
      deleteTargetFile.value = {};
    };

    const uploadFile = async () => {
      isUploading.value = true;
      const res = await MyPageService.uploadFile({
        token: props.token,
        file: uploadedFile.value.file,
      });
      isUploading.value = false;
      uploadedFile.value = null;
      if (res.success === false) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      fetchFiles();
      changeMode('completed');
    };

    const downloadFile = async payload => {
      const res = await MyPageService.downloadFile({
        token: props.token,
        uploadId: payload.id,
        originalFilename: payload.original_filename,
      });
      if (res.success === false) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      fetchFiles();
    };

    const deleteFile = async ({ selected }) => {
      if (selected === 'submit') {
        const res = await MyPageService.deleteFile({
          token: props.token,
          data: { id: deleteTargetFile.value.id },
        });
        if (res.success === false) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
          closeCautionModal();
          return;
        }
      }
      fetchFiles();
      closeCautionModal();
    };

    const fetchFiles = async () => {
      isLoading.value = true;
      const res = await MyPageService.getFilelist({ token: props.token });
      isLoading.value = false;
      if (res.success === false) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      files.value = res.filelist;
    };

    return {
      mode,
      uploadedFile,
      files,
      isLoading,
      isVisibleCautionModal,
      deleteTargetFile,
      isUploading,
      generateTitleText,
      cancelUploadMode,
      confirmFile,
      updateCurrentFile,
      changeMode,
      dropDownImage,
      selectImage,
      openCautionModal,
      closeCautionModal,
      uploadFile,
      downloadFile,
      deleteFile,
      fetchFiles,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.breadcrumb {
  padding: 1.5rem 0 1.2rem;
  margin: 0 1.5rem;
  border-bottom: 1px solid $border_color;
  .chip {
    padding: 0.5rem 0;
    cursor: pointer;
    > span {
      padding: 0 0.32rem;
    }
    .chip_title {
      font-size: 1rem;
    }
    .link {
      color: $mochica_color;
      font-weight: bold;
    }
    .arrow-icon {
      width: 15px;
      height: 15px;
      padding-right: 0.5rem;
    }
  }
}

:deep(.btn-round.btn-upload) {
  width: 90%;
}

.btn-round.btn-file-confirm,
.btn-round.btn-file-upload {
  margin-bottom: 1.5rem;
}
.filename_item {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  background: #eceff1;
  font-size: 1.4rem;
  border: solid 1px $border_color;
  margin-bottom: 2rem;
  border-radius: 0.4rem;
}

.file-select {
  margin: 10px 10px 0 10px;

  .area-file-drop {
    .dropbox {
      border: 2px dashed #c5e5f4;
      background: $mochica_light_color;
      color: dimgray;
      /* padding: 10px; */
      min-height: 250px;
      position: relative;
      cursor: pointer;
      @media (max-width: 600px) {
        min-height: 70px;
        padding: 0;
        border: none;
        background: none;
      }
    }

    .dropbox_inner {
      font-size: 1.4rem;
      width: 100%;
      @include flex_center_center;
      flex-direction: column;
    }

    .dropbox_inner_txt {
      font-weight: bold;
      display: block;
      color: #333;
      margin-bottom: 2rem;
      font-weight: bold;
    }
    .dropbox_inner_or {
      font-weight: normal;
      display: block;
      margin-bottom: 1.5rem;
    }
    .input-file {
      opacity: 0;
      width: 100%;
      height: 200px;
      position: absolute;
      cursor: pointer;
      @media (max-width: 600px) {
        height: 70px;
      }
    }
    .dropbox:hover {
      opacity: 0.8;
    }
    .dropbox p {
      font-size: 1.2em;
      text-align: center;
      padding: 50px 0;
    }
  }
}
.dropbox {
  border: 2px dashed #c5e5f4;
  background: $mochica_light_color;
  color: dimgray;
  padding: 10px 10px;
  min-height: 250px;
  position: relative;
  cursor: pointer;
  @include flex_center_center;
}

.dropbox_inner {
  font-size: 1.2rem;
  text-align: center;
}

.dropbox_inner_txt {
  font-weight: bold;
  display: block;
  color: #333;
  margin-bottom: 1rem;
  @media (max-width: 600px) {
    display: none !important;
  }
}
.dropbox_inner_or {
  font-weight: normal;
  display: block;
  margin-bottom: 1.2rem;
  @media (max-width: 600px) {
    display: none !important;
  }
}
.dropbox_inner_btn {
  display: inline-block;
  line-height: 2.6rem;
  width: 90%;
  height: 3rem;
  border: 1px solid $mochica_color;
  color: $mochica_color;
  background: $white;
  border-radius: 10rem;
}

.mid-spacer {
  padding-top: 3rem;
  border-bottom: 1px solid $border_color;
  margin-bottom: 3rem;
}
.sm-spacer {
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid $border_color;
}
.thin-black {
  color: $dropped_color;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 1rem 0;
}
.sm-bold-blue {
  padding: 1rem 0;
  font-size: 1.6rem;
  color: $mochica_color;
  font-weight: bold;
}

.pad1 {
  padding: 0.5rem 0;
}
.sm-blue {
  padding: 1rem 0;
  font-size: 1.6rem;
  color: $mochica_color;
  text-decoration: underline;
  word-break: break-all;
}
.txt-block {
  padding: 1.5rem;
}
.border-bottom-space-sm {
  padding-bottom: 1rem;
  border-top: 1px solid $border_color;
}
.border-bottom-space-md {
  padding-bottom: 1.5rem;
  border-top: 1px solid $border_color;
}

.no-data {
  text-align: center;
  border-radius: 0.5rem;
  background-color: $mochica_light_gray_bg;
  padding: 4rem 5rem;
  font-size: 1.4rem;
  color: $dropped_color;
  border: 1px solid $border_color;
}
.gray-bg {
  height: 15px;
  background-color: $mochica_light_gray_bg;
  border-top: 1px solid $border_color;
  border-bottom: 1px solid $border_color;
}
.top-space1 {
  padding-top: 1rem;
}
.bottom-space1 {
  padding-bottom: 1rem;
}
.bold-thin-black {
  color: $dropped_color;
  font-weight: bold;
}
.bg-bold {
  white-space: pre-line;
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 1rem;
}
.title-header {
  padding: 1.5rem;
}
.desc {
  padding: 0.5rem 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.file-list {
  > li {
    padding-bottom: 15px;
    border-bottom: 1px solid $border_color;

    > :deep(.btn-unavailable) {
      margin-top: 10px;
    }
  }
}
</style>
