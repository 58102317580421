import httpClient from '@/http-client';

const ReminderManager = function _ReminderManager() {
  const manager = Object.create(ReminderManager.prototype);
  return manager;
};

ReminderManager.prototype = {
  async reminderCheck(token, reminderPassword) {
    const response = await httpClient.post('/reminders_ext/reminder_check', {
      token,
      reminderPassword,
    });
    return response.data;
  },
  async reminderGet(token) {
    const response = await httpClient.post('/reminders_ext/reminder_get', {
      token,
    });
    return response.data;
  },
};

export default ReminderManager();
