<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">選択した応募者の選考を結果を通知せずに進める</p>
        </div>

        <transition name="fade">
          <div v-if="isEdit">
            <div class="body">
              <div class="message">
                <p>応募者の選考を結果を通知せずに進めます。</p>
                <p>選考を進める応募者を選択してください。</p>
              </div>
              <p class="title">選択中の応募者</p>
              <selectable-applicant-table
                class="change-list"
                :applicants="applicants"
                :init-selected-applicants="applicants.map(v => v.id)"
                :is-selectable="true"
                :is-sortable="false"
                :invisible-columns="invisibleColumns"
                :is-initial-select-all="true"
                @onChangeCurrentSelect="
                  setSelectedApplicants($event.selectedApplicants)
                "
              />
              <div v-if="errorMessages.data.applicants">
                {{ errorMessages.data.applicants }}
              </div>
            </div>
            <div class="btn-col2">
              <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
                キャンセル
              </div>
              <div class="btn btn-availability" @click="onClickToConfirm">
                確認画面に進む
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade" appear>
          <div v-if="isConfirm">
            <div class="body">
              <div class="message">
                <p>応募者の選考を結果を通知せずに進めます。</p>
                <p>選考を進める応募者を選択してください。</p>
              </div>
              <p class="title">選択中の応募者</p>
              <selectable-applicant-table
                class="change-list"
                :applicants="selectedApplicants"
                :is-selectable="false"
                :is-sortable="false"
                :invisible-columns="invisibleColumns"
              />
            </div>
            <div class="btn-col2">
              <button
                :disabled="isDisabled"
                class="btn btn-unavailable"
                @click="onClickBack"
              >
                編集に戻る
              </button>
              <button
                :disabled="isDisabled"
                class="btn btn-availability"
                @click="onClickSettle"
              >
                選択した応募者の選考を結果を通知せずに進める
              </button>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, reactive, ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';

import { onResizeModal } from '@/utils/modal-resize';
import Modal from '@/components/ui/modals/components/Modal';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';

export default defineComponent({
  name: 'ModalBulkAnnounceSkip',
  components: { Modal, SelectableApplicantTable },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    applicants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCancel', 'onClickSettle'],
  setup(props, context) {
    const store = useStore();
    const isEdit = ref(true);
    const isConfirm = ref(false);
    const isDisabled = ref(false);
    const selectedApplicants = ref([]);
    const errorMessages = reactive({ data: {} });

    // watch
    watch(
      () => props.isView,
      async val => {
        setSelectedApplicants(props.applicants.map(el => el.id));
        if (val === true) {
          nextTick(() => localResizeModal());
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );

    // methods
    const validate = () => {
      if (selectedApplicants.value.length > 200) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '200名以上の応募者は選択できません',
          type: false,
        });
        return false;
      }
      if (selectedApplicants.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者が選択されていません',
          type: false,
        });
        return false;
      }
      return true;
    };
    const onClickBack = () => {
      isConfirm.value = false;
      setTimeout(() => (isEdit.value = true), 300);
    };
    const onClickToConfirm = () => {
      if (validate() !== true) return;
      isEdit.value = false;
      setTimeout(() => (isConfirm.value = true), 300);
    };
    const onClickSettle = () => {
      if (validate() !== true) return;
      isDisabled.value = true;
      isConfirm.value = false;
      isEdit.value = true;
      context.emit('onClickSettle', {
        applicant_ids: selectedApplicants.value.map(v => v.id),
      });
    };
    const setSelectedApplicants = selectedApplicantIds => {
      const newSelectedApplicants = selectedApplicantIds.reduce(
        (accumulator, curr) => {
          const found = props.applicants.find(el => el.id === curr);
          if (found) accumulator.push(Object.assign({}, found));
          return accumulator;
        },
        [],
      );
      selectedApplicants.value = Object.assign([], newSelectedApplicants);
    };
    const localResizeModal = () => onResizeModal(props.isView);

    return {
      invisibleColumns: [
        'attendanceDate',
        'gender',
        'school',
        'department',
        'department_category',
        'tel',
        'contact_type',
        'invited_status',
        'invited_at',
      ],
      isEdit,
      isConfirm,
      isDisabled,
      selectedApplicants,
      errorMessages,
      validate,
      onClickBack,
      onClickSettle,
      onClickToConfirm,
      setSelectedApplicants,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.content {
  border-radius: 1rem;
  width: 80%;
  // height: 80rem;
  .header {
    padding: 2rem;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    font-size: 1.2rem;
    padding: 2rem;
    .message {
      p {
        margin-bottom: 1rem;
      }
    }
    .title {
      text-align: left;
      font-weight: bold;
      margin: 2em 0 0.5em;
    }
    .change-list {
      overflow: auto;
      width: 100%;
      height: 30vh;
      margin: 0;
      border: 1px solid $border_color;
      border-top: none;
      text-align: left;
      tr {
        border-bottom: 1px solid $border_color;
      }
    }
  }
}
.modal-window .btn-col2 {
  padding: 0 10px 10px;
}
</style>
