<template>
  <div>
    <div class="btn_back">
      <p @click="onClickCancelLinkGoogleCalendar">システム連携に戻る</p>
    </div>
    <div>
      <dl class="setting_list">
        <dd class="setting_text">
          <p class="setting_title">連携する会議室の設定</p>
          <p>
            カレンダーと連携した会議室を選択し、決定ボタンをクリックしてください。
            <br />
            連携できる会議室は最大{{ MAX_CALENDAR_RESOURCE_LENGTH }}件までです。
          </p>
        </dd>
      </dl>
      <dl class="setting_list">
        <dd v-if="isLoading === true" class="is-loading">読み込み中...</dd>
        <dd v-else>
          <ul v-if="editCalendarResources" class="resource_list">
            <li
              v-for="editCalendarResource in editCalendarResources"
              :key="`edit_${editCalendarResource.id}`"
            >
              <label>
                <input
                  v-model="editCalendarResource.isSelected"
                  type="checkbox"
                  :disabled="isLoadingSend || isLoading"
                  @click="
                    selectResource($event, editCalendarResource.isSelected)
                  "
                />
                {{ editCalendarResource.name }}
              </label>
            </li>
          </ul>
          <div v-else class="no_resource">連携できる会議室がありません</div>
        </dd>
      </dl>
      <dl class="setting_list">
        <dd>
          <button-base
            class="btn btn-send"
            button-text="決定"
            button-type="success"
            :is-loading="isLoadingSend || isLoading"
            :rejected-role-ids="[2, 3]"
            :rejected-admin-ids="[2]"
            @click="updateCalendarResources"
          />
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import GoogleCalendarService from '@/services/google-calendar';
import CompanyService from '@/services/company';

export default defineComponent({
  name: 'LinkGoogleCalendarEditResource',
  emits: ['changeMode'],
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const isLoadingSend = ref(false);
    const MAX_CALENDAR_RESOURCE_LENGTH = 20;
    const editCalendarResources = ref([]);

    // computed
    const selectedResources = computed(() =>
      editCalendarResources.value.filter(
        editCalendarResource => editCalendarResource.isSelected,
      ),
    );

    // methods
    const fetchGoogleWorkspaceResources = async () => {
      isLoading.value = true;
      const res = await GoogleCalendarService.fetchGoogleWorkspaceResources();
      isLoading.value = false;
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        context.emit('changeMode', 'home');
      } else {
        const calendarResources = res.calendar_resources;
        editCalendarResources.value =
          calendarResources.length !== 0 ? calendarResources : null;
      }
    };
    const updateCalendarResources = async () => {
      isLoadingSend.value = true;
      const res = await CompanyService.updateCalendarResources({
        calendar_resources: selectedResources.value.map(v => ({
          id: v.id,
          name: v.name,
        })),
      });
      isLoadingSend.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        context.emit('changeMode', 'home');
      }
    };
    const onClickCancelLinkGoogleCalendar = () => {
      context.emit('changeMode', 'home');
    };

    const selectResource = (e, val) => {
      let validateMaxStaffLength = null;
      validateMaxStaffLength =
        selectedResources.value.length >= MAX_CALENDAR_RESOURCE_LENGTH;
      // スタッフ選択時のバリデーション
      if (val !== true && validateMaxStaffLength) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `選択できるのは${MAX_CALENDAR_RESOURCE_LENGTH}個までです`,
          type: false,
        });
        e.preventDefault();
        return;
      }
    };

    onMounted(async () => {
      await fetchGoogleWorkspaceResources();
    });

    return {
      isLoading,
      isLoadingSend,
      editCalendarResources,
      MAX_CALENDAR_RESOURCE_LENGTH,
      fetchGoogleWorkspaceResources,
      onClickCancelLinkGoogleCalendar,
      updateCalendarResources,
      selectResource,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.setting_title {
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #5896cc;
  border-left: 6px solid #5896cc;
}

.setting_text {
  margin-bottom: 24px;
  line-height: 1.75;
  > span {
    display: block;
    margin-top: 5px;
  }
}

.setting_list {
  border-top: 1px solid #e5e5e5;
  padding-top: 24px;
  margin-bottom: 24px;
  > dt {
    margin-bottom: 18px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: bold;
    > span {
      display: inline-block;
      width: 44px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      background-color: #bb1b1c;
      border-radius: 4px;
      text-align: center;
    }
  }
  > dd {
    margin-bottom: 18px;

    > p {
      margin-bottom: 10px;
    }
  }

  .is-loading {
    text-align: center;
  }

  .resource_list {
    display: flex;
    min-height: 120px;
    flex-wrap: wrap;
    margin: 0 -10px;
    > li {
      display: block;
      width: 50%;
      margin-bottom: 12px;
      padding: 0 10px;
      > label {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        > input {
          display: inline-block;
          position: relative;
          width: 18px;
          min-width: 18px;
          height: 18px;
          margin-right: 2px;
          border-radius: 4px;
          border: 2px solid #9d9d9d;
          vertical-align: -4px;
          background-color: #fff;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          cursor: pointer;
          &:checked {
            &::after {
              content: url('../../../../../assets/img/check.svg');
              position: absolute;
              bottom: 3px;
              left: 0;
              height: 13px;
              width: 18px;
            }
          }
        }
      }
    }
  }

  .btn-send {
    width: 100%;
    height: 54px;
    background-color: #12b312;
    &:disabled {
      color: #333 !important;
      background-color: #999;
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  @media (max-width: ($media_query_sp)) {
    .resource_list {
      max-height: 100%;
    }
  }
}

.btn_back {
  margin-bottom: 27px;
  > p {
    padding-left: 15px;
    line-height: 1;
    background-image: url('../../../../../assets/img/icon-back.svg');
    background-size: auto 12px;
    background-position: 0 1.5px;
    background-repeat: no-repeat;
    color: #5896cc;
    cursor: pointer;
  }
}

.no_resource {
  color: #777777;
  text-align: center;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
