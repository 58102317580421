<template>
  <div>
    <div v-if="searchSurveys.length > 0" class="ar-flagOne">
      <div class="ar-questionnaireMain">
        <div
          v-for="(searchSurvey, index) in searchSurveys"
          :key="`survey_${searchSurvey.id}`"
          class="set-box"
        >
          <div class="item">
            <p class="headline">Googleフォーム</p>
            <div class="select-set">
              <drop-down
                :placeholder="'Googleフォーム名'"
                :selected-id="searchSurvey.id || null"
                :list="getSurveys"
                :is-view="
                  visibleSurveyIds[index] &&
                  getSurveys.findIndex(sv => !sv.disabled) !== -1
                "
                class="select-box"
                @onOpen="openDropDownSurveyIds(index)"
                @onClose="closeSearchSurveyDropDown"
                @onSelected="onSelectedSurveyId($event, index)"
              />
              <div class="toRelease" @click="removeSurveysRow(index)">
                選択を解除
              </div>
            </div>
          </div>
          <div class="item">
            <p class="headline">検索ワード</p>
            <input
              class="search_input"
              type="text"
              placeholder="検索ワード"
              :value="searchSurveyTexts[index]"
              @input="updateSearchSurveyTexts(index, $event.target.value)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="visibleAddText"
      class="btn btn-availability"
      @click="addSurveysRaw"
    >
      <button>
        <img slot="icon" class="icon" src="@/assets/img/add_white.svg" alt />
        検索に複数のGoogleフォーム項目を使用する
      </button>
    </div>
    <div v-else>
      <span>
        Googleフォーム項目は{{ MAX_SEARCH_SURVEY_COUNT }}個まで検索可能です。
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

import DropDown from '@/components/ui/menus/components/DropDown';
import SEARCH_CONSTS from '@/components/features/searchApplicants/defines/search';

export default defineComponent({
  name: 'SearchSurvey',
  components: { DropDown },
  props: {
    searchSurveys: {
      type: Array,
      default: () => [],
    },
    searchSurveyTexts: {
      type: Array,
      default: () => [],
    },
    visibleSurveyIds: {
      type: Array,
      default: () => [],
    },
    getSurveys: {
      type: Array,
      default: () => [],
    },
    visibleAddText: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    updateSearchSurvey: {
      type: Function,
      required: true,
    },
    addSurveysRaw: {
      type: Function,
      required: true,
    },
    removeSurveysRow: {
      type: Function,
      required: true,
    },
    openDropDownSurveyIds: {
      type: Function,
      required: true,
    },
    closeSearchSurveyDropDown: {
      type: Function,
      required: true,
    },
    updateSearchSurveyTexts: {
      type: Function,
      required: true,
    },
  },
  setup(props, context) {
    const onSelectedSurveyId = (payload, i) => {
      props.updateSearchSurvey(payload, i, nextTick);
    };
    return {
      onSelectedSurveyId,
      MAX_SEARCH_SURVEY_COUNT: SEARCH_CONSTS.MAX_SEARCH_SURVEY_COUNT,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.ar-flagComponent {
  padding: 20px 0;
  margin-bottom: 40px;
  border-bottom: 1px solid #ccc;

  > .subBtn {
    display: inline-block;
    padding: 10px 15px 10px 25px;
    cursor: pointer;
    background-image: url('../../../../assets/img/add_blue.svg');
    background-size: 10px auto;
    background-position: left 10px center;
    background-repeat: no-repeat;

    &.is-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.ar-flagSub {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;

  > .headline {
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.flag_select {
  margin-bottom: 40px;
  dt {
    margin-bottom: 10px;
    font-weight: bold;
  }
  dd .options_btn {
    margin-left: 5px;
  }
}

.ar-questionnaireMain {
  margin-bottom: 20px;
  .headline {
    margin-bottom: 10px;
    font-weight: bold;
  }
  .select-set {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .select-box {
    width: calc(100% - 6em);
  }
  .item + .item {
    margin-top: 10px;
  }
  .set-box + .set-box {
    margin-top: 20px;
  }

  .toRelease {
    color: #1899d6;

    &.is-detail {
      margin-bottom: 23px;
    }
  }
}

.btn-availability {
  > button {
    cursor: pointer;
  }
}

@media (max-width: ($media_query_sp)) {
  .ar-questionnaireMain {
    .select-set {
      display: block;
    }
    .select-box {
      margin-bottom: 10px;
    }
    .toRelease {
      &.is-detail {
        margin-bottom: 0;
      }
    }
  }

  .ar-flagComponent {
    > .subBtn {
      padding: 10px 10px 10px 21px;
      background-position: left 8px center;
    }
  }
}
</style>
