<template>
  <div class="edit-text">
    <div class="item-title">
      <h2 class="personal_setting_list_item_ttl">
        <span class="list_title">{{ title }}</span>
      </h2>
      <button-base
        :button-text="isEditMode === true ? '確定' : '編集'"
        :button-type="isEditMode === true ? 'success' : 'primary'"
        :rejected-admin-ids="[2]"
        :icon-file-name="isEditMode === true ? 'check_white' : 'pen_white'"
        @click="isEditMode === true ? commit() : (isEditMode = true)"
      />
    </div>
    <textarea
      v-model="localSign"
      class="sign"
      maxlength="500"
      placeholder="500文字まで"
      :readonly="isEditMode !== true"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'EditSign',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    id: {
      type: Number,
      default: null,
    },
    sign: {
      type: String,
      default: '',
    },
  },
  emits: ['commit', 'onDelete'],
  setup(props, context) {
    const isEditMode = ref(false);
    const localSign = ref('');
    watch(
      () => props.sign,
      () => (localSign.value = props.sign),
    );

    const commit = () => {
      context.emit('commit', { id: props.id, sign: localSign.value });
      isEditMode.value = false;
    };
    const onDelete = () => {
      context.emit('onDelete', { id: props.id, text: null });
      isEditMode.value = false;
    };
    return { localSign, isEditMode, commit, onDelete };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects2();

.sign {
  padding: 7px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
}
.btn-availability {
  > .icon {
    margin-right: 3px;
  }
}

.item-title {
  display: flex;
  justify-content: space-between;
  width: 580px;
  margin-bottom: 20px;

  h2 {
    padding-top: 10px;
    margin-bottom: 0;
  }
}

@media (max-width: ($media_query_tablet)) {
  .item-title {
    width: 100%;
  }
}
</style>
