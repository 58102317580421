<template>
  <div
    :class="{
      'not-announced': flowItemCssClass,
      selection_done: isCommitted,
      selection_current: isCurrentProgress,
    }"
    class="flow-item-wrapper"
  >
    <template v-if="isCommitted">
      <modal-window
        v-if="applicant.firstname === null"
        :is-visible="isOpenModal"
        :title="`${applicant.lastname}の${progress.status.description} を通知せずに選考を進める`"
        message="変更をすると担当者評価・人事評価を再度行うことが出来なくなります。"
        button-text="設定を変更"
        @click="announceSkip"
        @close="onclickOuter"
      />
      <modal-window
        v-else
        :is-visible="isOpenModal"
        :title="`${applicant.lastname}${applicant.firstname} の${progress.status.description}を通知せずに選考を進める`"
        message="変更をすると担当者評価・人事評価を再度行うことが出来なくなります。"
        button-text="設定を変更"
        @click="announceSkip"
        @close="onclickOuter"
      />
      <modal-window
        :is-visible="isOpenSelectionDelete"
        :message="`この応募者から選考を削除します。\n予約日、評価内容は全て消去されます。\n 削除された選考は、再度戻すことはできませんがよろしいですか？`"
        class="delete-selection-modal"
        title="応募者から選考を削除する"
        modal-type="danger"
        button-text="選考を削除"
        @click="deleteSelection"
        @close="onclickOuterDel"
      />
      <section
        class="item-flow committed committed-item"
        :class="progressCssClass"
      >
        <aside v-if="isNotAnnounced" class="not-announce">
          <p
            v-if="!$store.getters['applicant/isLineFriend']"
            class="notice-left"
          >
            <span>評価後、この選考の通知を行っていません。結果通知は</span>
            <span class="replace-announce dashed" @click="replaceAnnounce">
              こちら
            </span>
          </p>
          <p
            class="notice-right"
            @click="(isOpenModal = true), (isFlowItem = true)"
          >
            <span class="dashed">
              <img class="commit_svg" src="@/assets/img/close_white.svg" />
              <span>通知をせず選考を進める</span>
            </span>
          </p>
        </aside>
        <div
          :class="{ 'pointer-default': isCurrentProgress }"
          class="header header-committed"
          @click="toggleBodyExpand()"
        >
          <span class="title">{{ progress.title }}</span>
          <span class="prgress-message">{{ progress.status.description }}</span>

          <div class="selection-delete-block">
            <div
              v-if="
                progress.status.name === 'not_set_selection' ||
                progress.status.name === 'not_announced' ||
                progress.status.name === 'announced' ||
                progress.status.name === 'not_evaluated' ||
                progress.status.name === 'pending' ||
                progress.status.name === 'passed' ||
                progress.status.name === 'offered' ||
                progress.status.name === 'canceled' ||
                progress.status.name === 'dropped'
              "
              class="btn btn-unavailable"
              @click="(isOpenSelectionDelete = true), (isFlowItem = true)"
            >
              <img class="icon" src="@/assets/img/delete_white.svg" />
              この選考を削除する
            </div>
          </div>

          <div
            v-if="!isCurrentProgress"
            class="btn-toggle"
            :class="{ on: isExpandedBody, off: !isExpandedBody }"
          >
            <img
              class="icon accordion"
              src="@/assets/img/arrow_down_white.svg"
              alt
            />
          </div>
        </div>
        <transition name="ease">
          <div
            v-show="isExpandedBody || isCurrentProgress"
            :class="expandCssClass"
          >
            <div class="body body-committed">
              <div class="area-selection-info">
                <div v-if="progress.venue" class="area-section-info-detail">
                  <div class="detail-row">
                    <div class="area-selection-item venue-name">
                      <p class="title">会場名</p>
                      <p class="value">
                        <span>{{ progress.venue.name }}</span>
                      </p>
                    </div>
                    <div class="area-selection-item selection-date">
                      <p class="title">開催日</p>
                      <p class="value">
                        <span>
                          {{
                            $utils.parseDateTime(
                              progress.venue.date,
                              'YYYY/MM/DD',
                            )
                          }}
                        </span>
                      </p>
                    </div>
                    <div class="area-selection-item selection-time">
                      <p class="title">開催時間</p>
                      <p class="value">
                        <span
                          v-if="
                            progress.venue.timetables &&
                            progress.venue.timetables.length
                          "
                        >
                          {{
                            $utils.parseTime(progress.venue.timetables[0].start)
                          }}
                          〜
                          {{
                            $utils.parseTime(progress.venue.timetables[0].last)
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="detail-row">
                    <div class="area-selection-item venue-address">
                      <p class="title">住所</p>
                      <p class="value">
                        <span>
                          {{ progress.venue.pref }}{{ progress.venue.address }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="progress.venue.timetables[0].zoom_id"
                    class="detail-row"
                  >
                    <div class="area-selection-item venue-zoom-info">
                      <div class="zoom_info_area">
                        <p class="title">Zoomの情報</p>
                        <p class="value">
                          <span>
                            ミーティングID:
                            {{ progress.venue.timetables[0].zoom_id }}
                          </span>
                        </p>
                      </div>
                      <div class="zoom_url_area">
                        <p class="zoom_display_area-title">参加者用URL</p>
                        <button-clipboard-copy
                          :text="progress.venue.timetables[0].zoom_join_url"
                          blue-button-text="URLコピー"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="detail-row">
                    <div
                      v-if="
                        progress.venue.timetables &&
                        progress.venue.timetables.length
                      "
                      class="area-selection-item"
                    >
                      <p
                        class="cover_reminder"
                        @click="isOpenSendReminder = true"
                      ></p>
                      <p class="value checkbox_ui">
                        <input
                          v-model="isReminder"
                          type="checkbox"
                          name="is_reminder"
                          disabled
                        />
                        <label class="title">前日にリマインド通知する</label>
                      </p>
                    </div>
                    <div
                      v-if="
                        progress.venue.timetables &&
                        progress.venue.timetables.length
                      "
                      class="area-selection-item"
                    >
                      <p
                        class="cover_mypage"
                        @click="isOpenSendMypageVisible = true"
                      ></p>
                      <p class="value checkbox_ui">
                        <input
                          v-model="progress.mypageVisible"
                          type="checkbox"
                          name="is_mypage_visible"
                          disabled
                        />
                        <label class="title">マイページに表示する</label>
                      </p>
                    </div>
                    <div
                      v-if="
                        progress.venue.timetables &&
                        progress.venue.timetables.length
                      "
                      class="area-selection-item"
                    >
                      <p
                        class="cover_reminder_check"
                        @click="isOpenSendReminderCheck = true"
                      ></p>
                      <p class="value checkbox_ui">
                        <input
                          v-model="progress.reminderCheck"
                          type="checkbox"
                          name="is_reminder_check"
                          disabled
                        />
                        <label class="title">参加確認済み</label>
                      </p>
                    </div>
                    <modal-window
                      :is-visible="isOpenSendReminder"
                      title="リマインダーの通知設定を変更します"
                      message="リマインダー通知設定を変更しますか？"
                      button-text="設定を変更"
                      @click="toggleSendReminder"
                      @close="isOpenSendReminder = false"
                    />
                    <modal-window
                      :is-visible="isOpenSendReminderCheck"
                      title="参加確認の設定を変更します"
                      message="参加確認通設定を変更しますか？"
                      button-text="設定を変更"
                      @click="toggleSendReminderCheck"
                      @close="isOpenSendReminderCheck = false"
                    />
                    <modal-window
                      :is-visible="isOpenSendMypageVisible"
                      title="マイページに表示の設定を変更します"
                      message="「マイページに表示」の設定を変更します"
                      button-text="設定を変更"
                      @click="toggleSendMypageVisible"
                      @close="isOpenSendMypageVisible = false"
                    />
                  </div>
                </div>
              </div>
              <div class="area-form">
                <applicant-evaluation
                  ref="evaluation"
                  :is-committed="isCommitted"
                  :is-current-progress="isCurrentProgress"
                  :is-not-announced="isNotAnnounced"
                  :is-operator="isApplicantOperator"
                  :is-manager="isManager"
                  :comment="progress.comment"
                  :evaluation="progress.evaluation"
                  :progress-id="progress.id"
                  :progress="progress"
                  :progress-status-name="progress.status.name"
                  :applicant-graduated-id="applicantGraduatedId"
                  @determineOperatorEvaluation="
                    $emit('determineOperatorEvaluation', $event)
                  "
                  @determineManagerEvaluation="
                    $emit('determineManagerEvaluation', $event)
                  "
                  @undoManagerEvaluation="
                    $emit('undoManagerEvaluation', $event)
                  "
                  @applicantFetchAllApi="$emit('applicantFetchAllApi', $event)"
                />
              </div>
            </div>
          </div>
        </transition>
      </section>
    </template>
    <template v-else>
      <modal-window
        :is-visible="isOpenSelectionDelete"
        :message="`この応募者から選考を削除します。\n予約日、評価内容は全て消去されます。\n 削除された選考は、再度戻すことはできませんがよろしいですか？`"
        class="delete-selection-modal"
        title="応募者から選考を削除する"
        modal-type="danger"
        button-text="選考を削除"
        @click="deleteSelection"
        @close="onclickOuterDel"
      />
      <modal-assign-time-table
        :is-visible="isOpenAssignTimetable"
        :selection="selectionForm.selection"
        :has-close-button="true"
        @onClickSettle="onClickSettleOperation"
        @onClickCancel="onclickOuterOperation"
      />
      <section :class="progressCssClass" class="item-flow not-committed">
        <div class="header header-not-committed" @click="toggleBodyExpand()">
          <span class="title">{{ progress.title }}</span>
          <span class="prgress-message">{{ progress.status.description }}</span>

          <div class="selection-delete-block">
            <div
              v-if="
                progress.status.name === 'not_set_selection' ||
                progress.status.name === 'not_announced' ||
                progress.status.name === 'announced' ||
                progress.status.name === 'not_evaluated' ||
                progress.status.name === 'pending'
              "
              class="btn btn-unavailable"
              @click="(isOpenSelectionDelete = true), (isFlowItem = true)"
            >
              <img class="icon" src="@/assets/img/delete_white.svg" />
              この選考を削除する
            </div>
          </div>
          <div
            class="btn-toggle"
            :class="{ on: isExpandedBody, off: !isExpandedBody }"
          >
            <img
              class="icon accordion"
              src="@/assets/img/arrow_down_white.svg"
              alt
            />
          </div>
        </div>
        <transition name="ease">
          <div v-show="isExpandedBody" :class="expandCssClass">
            <div class="body body-not-committed">
              <div class="btn-box">
                <p
                  class="btn btn-availability"
                  @click="showModalSelection(progress)"
                >
                  <span>この選考の詳細を確認する</span>
                </p>
                <p
                  v-if="
                    isCurrentProgress &&
                    !$store.getters['applicant/isLineFriend']
                  "
                  class="btn btn-availability btn-set_date"
                  @click="showModalOperation(progress)"
                >
                  <span>手動で面接日を設定する</span>
                </p>
              </div>
              <div v-if="isCurrentProgress" class="link-selection-next-box">
                <applicant-evaluation
                  ref="evaluation"
                  :is-commited="isCommitted"
                  :is-operator="isApplicantOperator"
                  :is-manager="isManager"
                  :operator-evaluation="progress.operatorEvaluation"
                  :manager-evaluation="progress.managerEvaluation"
                  :progress-id="progress.id"
                  :progress="progress"
                  :progress-status-name="progress.status.name"
                  :applicant-graduated-id="applicantGraduatedId"
                  @determineOperatorEvaluation="
                    $emit('determineOperatorEvaluation', $event)
                  "
                  @determineManagerEvaluation="
                    $emit('determineManagerEvaluation', $event)
                  "
                  @undoManagerEvaluation="
                    $emit('undoManagerEvaluation', $event)
                  "
                />
              </div>
            </div>
          </div>
        </transition>
      </section>
    </template>
    <modal-show-selection-detail
      v-if="isVisibleModalSelectionDetail"
      :id="selectionDetailId"
      :is-visible="isVisibleModalSelectionDetail"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import reserveService from '@/services/reserves';
import ButtonClipboardCopy from '@/components/ui/buttons/components/ButtonClipboardCopy';
import ModalAssignTimeTable from '@/components/page/applicants/components/ModalAssignTimeTable';
import ApplicantEvaluation from '@/components/page/applicants/components/ApplicantEvaluation';
import ModalShowSelectionDetail from '@/components/page/applicants/_id/components/ModalShowSelectionDetail';

export default defineComponent({
  name: 'ApplicantFlowItem',
  components: {
    ButtonClipboardCopy,
    ModalShowSelectionDetail,
    ApplicantEvaluation,
    ModalAssignTimeTable,
  },
  props: {
    isCommitted: {
      type: Boolean,
      default: false,
    },
    isCurrentProgress: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    applicantGraduatedId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'determineOperatorEvaluation',
    'determineManagerEvaluation',
    'undoManagerEvaluation',
    'announceSkip',
    'deleteSelection',
    'emitReloadPage',
    'toggleReminderSetting',
    'toggleReminderCheckSetting',
    'toggleMypageVisibleSetting',
    'applicantFetchAllApi',
  ],
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const isFlowItem = ref(false);
    const isExpandedBody = ref(true);
    const isNotAnnounced = ref(false);
    const isOpenModal = ref(false);
    const isOpenSelectionDelete = ref(false);
    const isOpenAssignTimetable = ref(false);
    const isOpenSendReminder = ref(false);
    const isOpenSendReminderCheck = ref(false);
    const isOpenSendMypageVisible = ref(false);
    const selectionForm = reactive({
      selection: { id: null, event: { title: null }, venues: [] },
    });
    const isVisibleModalSelectionDetail = ref(false);
    const selectionDetailId = ref(null);

    // computed
    const applicant = computed(() => store.getters['applicant/applicant']);
    const isManager = computed(() => store.getters['staff/isManager']);
    const isReminder = computed(() => {
      const check = props.progress.venue.timetables[0].attendances.filter(
        v => v.applicant_id === applicant.value.id,
      );
      if (check[0].disabled === false) return true;
      if (check[0].disabled === true) return false;
      return false;
    });
    const flowItemCssClass = computed(() => {
      let cssClass = false;
      if (
        props.progress.status.announced !== undefined &&
        props.progress.status.announced === false &&
        props.progress.status.name !== 'committed' &&
        props.progress.status.name !== 'hired' &&
        props.progress.status.name !== 'offer_canceled'
      ) {
        isNotAnnounced.value = true;
        cssClass = true;
      } else {
        isNotAnnounced.value = false;
        cssClass = false;
      }
      return cssClass;
    });
    const progressCssClass = computed(() => {
      const cssClass = [];
      cssClass.push(`progress-status-${props.progress.status.id}`);
      if (props.isCurrentProgress) cssClass.push('current');
      return cssClass;
    });
    const isApplicantOperator = computed(
      () => applicant.value.staff.id === store.getters['staff/staff'].id,
    );

    // methods
    const toggleBodyExpand = () => {
      isExpandedBody.value = !isExpandedBody.value;
    };
    const expandCssClass = () => {
      const classes = [];
      classes.push(isExpandedBody.value ? 'open' : 'close');
      return classes;
    };
    // 手動で面接日を設定する
    const showModalOperation = async tmpProgress => {
      const res = await reserveService.getSelectionInfo({
        applicant_id: applicant.value.id,
        selection_id: tmpProgress.selectionId,
      });
      if (res.success === true) {
        selectionForm.selection = {
          id: res.selection.id,
          event: { title: res.selection.event.title },
          venues: res.selection.venues.map(v => ({
            id: v.id,
            date: v.date,
            name: v.name,
            pref: v.pref,
            address: v.address,
            roomName: v.room_name,
            notice: v.notice,
            timetables: v.timetables.map(t => ({
              id: t.id,
              limit: t.limit,
              start: t.start,
              last: t.last,
            })),
          })),
        };
        isOpenAssignTimetable.value = true;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
      }
    };
    // 通知画面へ遷移
    const replaceAnnounce = () => {
      const headerWrapper = document.getElementsByClassName('header-wrapper');
      const sidebar = document.getElementsByClassName('sidebar');
      const contents = document.getElementsByClassName('contents');
      headerWrapper[0].style.display = '';
      sidebar[0].style.display = '';
      contents[0].style.padding = '';
      router.push({ name: 'Announce' });
    };
    // 通知せずに進む
    const announceSkip = payload => {
      if (payload.selected === 'submit') {
        context.emit('announceSkip', {
          applicant_id: applicant.value.id,
          progress_id: props.progress.id,
        });
      }
      isOpenModal.value = false;
    };
    // 選考削除
    const deleteSelection = payload => {
      if (payload.selected === 'submit') {
        context.emit('deleteSelection', props.progress.id);
      }
      isOpenSelectionDelete.value = false;
    };
    // 応募者に開催時間の割当て
    const onClickSettleOperation = async payload => {
      const res = await reserveService.createAttendanceOperation({
        timetable_id: payload.timetable_id,
        applicant_id: applicant.value.id,
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === false) return;
      isOpenAssignTimetable.value = false;
      isFlowItem.value = false;
      context.emit('emitReloadPage');
    };
    // 前日にリマインダーの通知するトグル
    const toggleSendReminder = payload => {
      if (payload.selected === 'submit') {
        const attend = props.progress.venue.timetables[0].attendances.filter(
          obj => obj.applicant_id === applicant.value.id,
        );
        context.emit('toggleReminderSetting', attend[0]);
      }
      isOpenSendReminder.value = false;
    };
    // 参加確認済みトグル
    const toggleSendReminderCheck = payload => {
      if (payload.selected === 'submit') {
        context.emit('toggleReminderCheckSetting', {
          reminder_check: !props.progress.reminderCheck,
          applicant_id: applicant.value.id,
          progress_id: props.progress.id,
        });
      }
      isOpenSendReminderCheck.value = false;
    };
    // マイページに表示するトグル
    const toggleSendMypageVisible = payload => {
      if (payload.selected === 'submit') {
        context.emit('toggleMypageVisibleSetting', {
          mypage_visible: !props.progress.mypageVisible,
          applicant_id: applicant.value.id,
          progress_id: props.progress.id,
        });
      }
      isOpenSendMypageVisible.value = false;
    };
    // この選考の詳細を確認する
    const showModalSelection = tmpProgress => {
      selectionDetailId.value = tmpProgress.selectionId;
      isVisibleModalSelectionDetail.value = true;
    };
    const onclickOuter = () => {
      isOpenModal.value = false;
    };
    const onclickOuterDel = () => {
      isOpenSelectionDelete.value = false;
    };
    const onclickOuterOperation = payload => {
      isOpenAssignTimetable.value = payload;
      isFlowItem.value = payload;
    };
    const closeModal = () => {
      isVisibleModalSelectionDetail.value = false;
      selectionDetailId.value = null;
    };

    // lifecycle
    onBeforeMount(() => {
      isExpandedBody.value = props.isCurrentProgress;
    });

    return {
      isFlowItem,
      isExpandedBody,
      isNotAnnounced,
      isOpenModal,
      isOpenSelectionDelete,
      isVisibleModalSelectionDetail,
      isOpenAssignTimetable,
      isOpenSendReminder,
      isOpenSendReminderCheck,
      isOpenSendMypageVisible,
      selectionForm,
      text: { message: '' },
      applicant,
      isManager,
      selectionDetailId,
      isReminder,
      flowItemCssClass,
      progressCssClass,
      isApplicantOperator,
      toggleBodyExpand,
      expandCssClass,
      showModalOperation,
      replaceAnnounce,
      announceSkip,
      deleteSelection,
      onclickOuter,
      onclickOuterDel,
      onclickOuterOperation,
      onClickSettleOperation,
      toggleSendReminder,
      toggleSendReminderCheck,
      toggleSendMypageVisible,
      showModalSelection,
      closeModal,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.btns-area + .flow-item-wrapper {
  &.not-announced .item-flow .not-announce::before {
    display: none;
  }
}

.flow-item-wrapper {
  position: relative;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 2px;
    height: calc(100% + 0.3rem);
    background-color: #e2e2e2;
    left: 1.8rem;
    top: 3.8rem;
  }
  .header {
    &::before {
      width: 24px;
      height: 24px;
      border: 2px solid #e2e2e2;
      background-size: 1.3rem 1.3rem;
      top: 1.4rem;
      left: -2.8rem;
      border-radius: 2.4rem;
      z-index: 0;
    }
  }
  &.selection_done {
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 2px;
      height: calc(100% + 0.3rem);
      background-color: #1899d6;
      left: 1.9rem;
      top: 3.8rem;
    }
    .header {
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        border: 2px solid #1899d6;
        top: 1.4rem;
        left: -2.8rem;
        border-radius: 2.4rem;
        z-index: 0;
      }
    }
  }
  &.selection_current {
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 0.2rem;
      background-color: #e2e2e2;
      left: 1.8rem;
    }
    .header {
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        background: #fff;
        border: 2px solid #1899d6 !important;
        top: 1.4rem;
        left: -2.8rem;
        border-radius: 2.4rem;
        z-index: 1 !important;
      }
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        background: #1899d6 !important;
        top: 1.8rem;
        left: -2.4rem;
        border-radius: 1.8rem;
        z-index: 1 !important;
      }
    }
  }
  &.not-announced {
    .item-flow {
      position: relative;
      top: -10px;
      .header {
        display: flex;
      }
      .header-committed {
        &::before {
          width: 2.4rem;
          height: 2.4rem;
          border: 2px solid #e2e2e2;
          background-size: 1.3rem 1.3rem;
          top: 1.4rem;
          left: -2.8rem;
          border-radius: 2.4rem;
          z-index: 0;
        }
      }
      .not-announce {
        display: flex;
        justify-content: space-between;
        background: #c31e1e;
        padding: 1rem;
        color: #fff;
        font-weight: bold;
        border-radius: 4px 4px 0 0;
        .notice-right {
          margin-left: auto;
        }
        & + .header {
          border-radius: 0 0 0 0;
        }
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 2px;
          height: calc(8% + 1rem);
          background-color: #1899d6;
          left: -1.7rem;
          top: 0;
          z-index: 1;
        }
        .dashed {
          padding-bottom: 0.2rem;
          border-bottom: 1px dashed #fff;
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
          .commit_svg {
            display: inline-block;
            width: 1rem;
            fill: #ffffff;
            stroke: #ffffff;
            margin-top: -1px;
            margin-right: 0.4rem;
          }
        }
      }
    }
  }
  &:first-of-type.not-announced {
    .item-flow {
      .not-announce {
        &::before {
          display: none;
        }
      }
    }
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
  .delete-selection-modal {
    white-space: break-spaces;
  }
}

.item-flow {
  border-radius: 4px;
  background: #fff;
  &.not-committed {
    margin-left: 3.6rem;
    .header {
      background: #e3e3e3;
      border-radius: 0.5rem;
      .title {
        position: static;
        padding-right: 1rem;
        &::before {
          display: none;
        }
      }
      .prgress-message {
        background: #fff;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  &.committed-item {
    margin-left: 3.6rem;
    .header {
      background: #808080;
      border-radius: 0.5rem;
      .title {
        font-weight: bold;
        color: #fff;
        padding-right: 1rem;
      }
      .prgress-message {
        background: #fff;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  &.current {
    .header {
      background: #1899d6;
      border-radius: 0.5rem 0.5rem 0 0;
      .title {
        color: #fff;
        font-weight: bold;
        padding-right: 1rem;
      }
    }
    .body {
      border: 2px solid #1899d6;
      border-radius: 0 0 0.5rem 0.5rem;
      background: #fff;
    }
  }
  > * {
    &::before {
      position: absolute;
      content: '';
      display: inline-block;
    }
    &::after {
      position: absolute;
      content: '';
      display: inline-block;
    }
  }
  .btn-toggle {
    .accordion {
      width: 12px;
      height: 7.5px;
      transition: all ease 0.3s;
      margin: 0 1rem;
    }
  }
  .btn-toggle.on {
    .accordion {
      transform: rotate(180deg);
    }
  }

  .header.pointer-default {
    cursor: default;
  }

  .header {
    display: flex;
    padding: 1rem;
    cursor: pointer;
    align-items: center;
    font-size: 1.2rem;
  }
  .header-not-committed {
    position: relative;
  }
  .header-committed {
    position: relative;
    padding: 1rem;
    .title {
      font-size: 1.2rem;
    }
    .prgress-message {
      font-size: 1.2rem;
    }
    .link-delete {
      position: absolute;
      right: 20px;
      .btn {
        color: #c31e1e;
        border-bottom: 1px dashed #c31e1e;
        padding-bottom: 0.2rem;
        transition: all ease 0.3s;
        .delete_svg {
          width: 10px;
          margin-right: 0.2rem;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .body {
    border: 2px solid #808080;
    border-radius: 0 0 0.3rem 0.3rem;
    margin: -0.4rem 0 0;
    .area-selection-info {
      position: relative;
      background-color: #e9f3f8;
      &::before {
        background: #1899d6;
        position: absolute;
        display: block;
        width: 1px;
        height: 100%;
        top: 0;
        left: -2.4rem;
      }
      .area-section-info-detail {
        padding: 1.6rem;
        .detail-row {
          display: flex;
          padding-bottom: 1.2rem;
          margin-bottom: 1.2rem;
          border-bottom: solid 1px #e3e3e3;
          .area-selection-item {
            margin-right: 2rem;
            padding-right: 1.2rem;
            .title {
              min-width: 13rem;
            }

            p.value {
              font-size: 16px;
            }
            p.title {
              font-size: 15px;
              font-weight: bold;
              margin-bottom: 1rem;
            }
            .cover_reminder {
              cursor: pointer;
              position: absolute;
              height: 20px;
              width: 170px;
              z-index: 1;
            }
            .cover_mypage {
              cursor: pointer;
              position: absolute;
              height: 20px;
              width: 140px;
              z-index: 1;
            }
            .cover_reminder_check {
              cursor: pointer;
              position: absolute;
              height: 20px;
              width: 120px;
              z-index: 1;
            }
            .value {
              min-width: 13rem;
              &.checkbox_ui {
                cursor: pointer;
                input[name='is_reminder'],
                input[name='is_reminder_check'],
                input[name='is_mypage_visible'] {
                  position: relative;
                  top: 2px;
                  width: 1.4rem;
                  height: 1.4rem;
                  margin: 0 8px 0 0;
                  vertical-align: -1px;
                  border-radius: 0.4rem;
                  border: 2px solid #9d9d9d;
                  background: #fff;
                  appearance: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  cursor: pointer;
                  &:checked {
                    &::after {
                      content: url(../../../../../assets/img/check.svg);
                      height: 1.1rem;
                      width: 1.5rem;
                      margin: -5px 0 0 1px;
                      position: absolute;
                      bottom: 3px;
                      left: 0;
                    }
                  }
                }
                label {
                  cursor: pointer;
                }
              }
            }
          }
          &:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
            border: none;
          }
        }
      }
      .venue-address {
        .value {
          font-size: 1.2rem;
          min-width: 13rem;
        }
      }

      .venue-zoom-info {
        display: flex;

        .zoom_info_area {
          margin-top: 4.5px;
          margin-right: 20px;
        }
        .zoom_url_area {
          display: flex;
          align-items: center;
          max-width: 334px;
          padding: 6px 6px 6px 12px;
          margin-right: 8px;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          white-space: nowrap;

          .zoom_display_area-title {
            font-weight: bold;
            margin-top: 3px;
            margin-right: 25px;
          }
          .zoom_display_area-text {
            max-width: 135px;
            margin-top: 8px;
            margin-right: 8px;
            font-size: 12px;
            line-height: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        @media (max-width: ($media_query_sp)) {
          display: block;

          .zoom_url_area {
            width: 100%;
            margin-top: 15px;
            justify-content: space-between;
          }
        }
      }
    }
    .area-form {
      textarea {
        width: 100%;
        height: 15rem;
      }
    }
  }
  .body-not-committed {
    background-color: #fff;
    border: 2px solid #e2e2e2;
    margin-bottom: 2rem;
    .link-selection {
      margin: 1.6rem;
      height: 3.2rem;
      line-height: 3rem;
      border-radius: 5px;
      text-align: center;
      background-color: #1899d6;
      border: 1px solid #027db7;
      color: #fff;
      font-weight: bold;
      transition: all ease 0.3s;
      &:hover {
        opacity: 0.6;
      }
      a {
        color: #5f5f5f;
        font-weight: 500;
        font-size: 1.2rem;
      }
      & + .link-selection-next-box {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: 1px solid $border_color;
      }
    }
    .operate-attendance {
      margin: 1.6rem;
      height: 3.2rem;
      line-height: 3rem;
      border-radius: 5px;
      text-align: center;
      background-color: #e3e3e3;
      border: 1px solid #cfcfcf;
      color: #5f5f5f;
      transition: all ease 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .body-committed {
    position: relative;
  }

  .selection-delete-block {
    display: flex;
    justify-content: center;
    margin-left: auto;
    &:empty {
      margin-top: 0;
    }
    .link-delete {
      padding: 0.8rem 6rem;
      border: 1px solid red;
      border-radius: 3px;
      .btn {
        color: #c31e1e;
        transition: all ease 0.3s;
        .delete_svg {
          width: 10px;
          margin-right: 0.2rem;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .body-not-committed {
    padding: 20px 0 20px;
    text-align: center;
  }
}

.progress-status-7,
.progress-status-8,
.progress-status-9,
.progress-status-10,
.progress-status-11,
.progress-status-12 {
  .body {
    .body-committed {
      background-color: #ffffff;
      & :deep(.area-operatosssr-body) {
        margin-bottom: 0;
      }
      .link-selection {
        width: 100%;
        height: 3.2rem;
        line-height: 3rem;
        border-radius: 5px;
        text-align: center;
        background-color: #1899d6;
        border: 1px solid #027db7;
        color: #fff;
        font-weight: bold;
        transition: all ease 0.3s;
        &:hover {
          opacity: 0.6;
        }
        a {
          color: #5f5f5f;
          font-weight: 500;
          font-size: 1.2rem;
        }
        & + .link-selection-next-box {
          padding-top: 2rem;
          margin-top: 2rem;
          border-top: 1px solid $border_color;
        }
      }
      &::before {
        display: none;
      }
    }
  }
}

.btn-set_date {
  margin-left: 8px;
}

@media (max-width: ($media_query_tablet)) {
  .item-flow {
    .body {
      .area-selection-info {
        .area-section-info-detail {
          .detail-row {
            .area-selection-item {
              margin-right: 0;
              padding-right: 15px;
              .title {
                min-width: initial;
              }
              .value {
                min-width: initial;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .flow-item-wrapper {
    margin-bottom: 20px;
    &::after {
      display: none;
    }
    .header {
      &::before {
        display: none;
      }
    }
    &.selection_done {
      &::after {
        display: none;
      }
      .header {
        &::before {
          display: none;
        }
      }
    }
    &.selection_current {
      &::after {
        display: none;
      }
      .header {
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
      &.not-announced {
        .item-flow {
          .header {
            display: block;
            padding: 10px;
          }
          .not-announce {
            display: block;
            padding: 12px 15px 15px;
            &::before {
              display: none;
            }
            .notice-left {
              margin-bottom: 15px;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }

  .item-flow {
    &.committed-item {
      margin-left: 0;
      .header {
        display: block;
        text-align: center;
        .title {
          display: block;
          margin-bottom: 10px;
        }
        .prgress-message {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    &.not-committed {
      margin-left: 0;
      .header {
        display: block;
        text-align: center;
        .title {
          display: block;
          margin-bottom: 10px;
        }
        .prgress-message {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    .header {
      padding: 10px;
      text-align: center;
      .title {
        display: block;
        margin-bottom: 10px;
      }
      .prgress-message {
        display: block;
      }
    }
    .body {
      .area-selection-info {
        .area-section-info-detail {
          .detail-row {
            display: block;
            .area-selection-item {
              padding-right: 0;
            }
            .area-selection-item + .area-selection-item {
              margin-top: 20px;
            }
          }
        }
      }
      .btn-box {
        padding: 0 20px;
        .btn {
          width: 100%;
        }
        .btn + .btn {
          margin-top: 10px;
        }
      }
    }
  }

  .btn-set_date {
    margin-left: 0;
  }
}
</style>
