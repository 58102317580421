<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content">
        <div class="content-inner">
          <section class="modal-body">
            <div class="calendar-wrapper">
              <div class="selections-calendar">
                <local-page-loader v-if="isLoading" />
                <div
                  class="selections-calendar-inner"
                  :class="{ 'is-init': isInitialized }"
                >
                  <div
                    ref="selectStaffsWrapper"
                    :class="{
                      'select-staff-wrapper': true,
                      'is-open': true,
                    }"
                  >
                    <select-staffs
                      v-if="isInitialized === true"
                      ref="selectStaffs"
                      :settings="{
                        isShowExceptMySelection: false,
                        isShowMySelection,
                        isShowGoogleEvent: true,
                        staffs,
                        googleResourceCalendars,
                      }"
                      :calendar-colors="calendarColors"
                      @updateOptions="updateOptions($event, refCalendar)"
                    />
                  </div>
                  <div class="selections-calendar-relative modal-calendar">
                    <full-calendar
                      ref="refCalendar"
                      :options="calendarOptions"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons">
              <button class="btn btn-unavailable" @click="onClickCancel">
                キャンセル
              </button>
              <button class="btn btn-availability" @click="onClickSubmit">
                選択した担当者を割り当て
              </button>
            </div>
          </section>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import '@fullcalendar/core';
import FullCalendar from '@fullcalendar/vue3';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

import venueCalendarService from '@/services/venues';
import useGoogleCalendar from '@/composables/useGoogleCalendar';
import Modal from '@/components/ui/modals/components/Modal';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import SelectStaffs from '@/components/features/venueCalendar/components/SelectStaffs';

export default defineComponent({
  name: 'ModalCalendar',
  components: {
    FullCalendar,
    Modal,
    LocalPageLoader,
    SelectStaffs,
  },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    initialDate: {
      type: String,
      default: '',
    },
    initialStaffIds: {
      type: Array,
      default: () => [],
    },
    initialResourceIds: {
      type: Array,
      default: () => [],
    },
    highlightStartTime: {
      type: String,
      default: '',
    },
    highlightLastTime: {
      type: String,
      default: '',
    },
  },
  emits: ['onClickCancel', 'onClickSubmit'],
  setup(props, context) {
    const store = useStore();
    const staff = store.getters['staff/staff'];
    const {
      baseCalendarOptions,
      isShowMySelection,
      staffs,
      googleResourceCalendars,
      parseVenues,
      updateOptions,
      resetSelectedStaff,
      highlightTargetTimeRage,
    } = useGoogleCalendar(
      'resourceTimeGridDay',
      props.initialDate ? props.initialDate : null,
      '100%',
    );
    const refCalendar = ref(null);
    /**
     * 初回のカレンダー情報読み込み後までfalse、その後はずっとtrue（カレンダーが初期化されたか判別するのに利用）
     */
    const isInitialized = ref(false);
    /**
     * カレンダー読み込み時に毎回trueになる
     */
    const isLoading = ref(false);
    /**
     * スタッフに使う全Hex色コード配列（DBで保存・管理） ex: ['#000000']
     */
    const calendarColors = ref([]);
    /**
     * カレンダーに流し込むイベント配列
     */
    const calendarOptions = reactive({
      ...baseCalendarOptions,
      ...{
        plugins: [resourceTimeGridPlugin],
        schedulerLicenseKey: process.env.VUE_APP_FULLCALENDAR_KEY,
        headerToolbar: { start: 'prev,next title', end: '' },
        initialDate: props.initialDate ? props.initialDate : null,
        resourceOrder: '', // 日表示での担当者カラムの並び順
        allDaySlot: true, // 終日表示
        eventMaxStack: 5, // 5個以上イベントが重なる場合、ポップアップ表示させる
        titleFormat: date => {
          // タイトルに曜日を表示させるためカスタマイズ
          return `${date.date.year}年${date.date.month + 1}月${
            date.date.day
          }日(${
            ['日', '月', '火', '水', '木', '金', '土'][
              date.date.marker.getDay()
            ]
          })`;
        },
        events: async (info, successCallback, failureCallback) => {
          let events = [];
          // Googleカレンダーから取得
          events = await fetchVenues(
            store.getters['graduateds/selectedGraduatedId'],
            moment(info.startStr).format('YYYY-MM-DD'),
            moment(info.endStr).format('YYYY-MM-DD'),
          );
          // resourcesと取得したeventsのマージ
          let selectedStaffs = staffs.value.filter(
            tmpStaff => tmpStaff.isSelected,
          );
          // 自分を含む場合
          if (isShowMySelection.value === true) selectedStaffs.unshift(staff);
          let resources = selectedStaffs.map(tmpStaff => ({
            id: tmpStaff.id,
            title: `${tmpStaff.lastname}${tmpStaff.firstname}`,
            className: tmpStaff.color
              ? `is-${tmpStaff.color.slice(1, 9)}`
              : 'is-4698d1', // 自分の色
          }));
          resources.sort((a, b) => (a.id > b.id ? 1 : -1));
          // Googleカレンダー会議室のカラムを追加
          const selectedGoogleResources = googleResourceCalendars.value.filter(
            resourceCalendar => resourceCalendar.isSelected,
          );
          const googleResourceResources = selectedGoogleResources.map(
            resourceCalendar => ({
              id: resourceCalendar.id,
              title: resourceCalendar.summary,
              className: `is-${resourceCalendar.color.slice(1, 9)}`,
            }),
          );
          resources = resources.concat(googleResourceResources);
          refCalendar.value.getApi().setOption('resources', resources);
          // resource列ラベルに色付け
          const els = refCalendar.value.$el.getElementsByClassName(
            'fc-scrollgrid-sync-inner',
          );
          els.forEach((el, i) => {
            // 終日ヘッダーを避けるためクラスの個数で峻別
            if (el.classList.length === 1) {
              el.classList.add(resources[i].className);
            }
          });
          successCallback(events);
        },
        datesSet(event) {
          highlightTargetTimeRage(props, refCalendar.value, event);
        },
      },
    });

    /**
     * 選考担当データとGoogleカレンダーデータ取得
     */
    const fetchVenues = async (graduatedId, startDate, endDate) => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      let selectedStaffIds = [];
      let selectedResourceIds = [];
      if (isInitialized.value === false) {
        // 初回のみ、自分を選択しているかチェックしてチェックボックスを初期表示を操作
        updateOptions({
          isShowMySelection:
            props.initialStaffIds.length > 0 &&
            props.initialStaffIds.find(
              staffId => parseInt(staffId, 10) === parseInt(staff.id, 10),
            ) !== undefined,
        });
        selectedStaffIds = props.initialStaffIds;
        selectedResourceIds = props.initialResourceIds;
      } else {
        selectedStaffIds = staffs.value
          .filter(staff => staff.isSelected)
          .map(staff => staff.id);
        if (isShowMySelection.value === true) {
          selectedStaffIds.unshift(staff.id);
        }
        selectedResourceIds = googleResourceCalendars.value
          .filter(v => v.isSelected)
          .map(v => v.id);
      }
      // googleカレンダー取得
      const res = await venueCalendarService.fetchVenuesDay(
        graduatedId,
        startDate,
        endDate,
        selectedStaffIds,
        selectedResourceIds,
      );
      isLoading.value = false;
      if (res.success !== true) {
        // Googleカレンダー以外でエラーの場合、何も表示できない
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        isInitialized.value = true;
        return [];
      } else if (
        res.code !== '400' &&
        res.code !== '401' &&
        res.error_message.length > 0
      ) {
        // Googleカレンダーのエラーかつ400.401以外のコードが返ってくる場合、エラーメッセージを表示
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.error_message,
          type: false,
        });
      }
      calendarColors.value = res.calendar_colors;
      updateOptions({
        staffs: res.staffs.map(staff => ({
          id: staff.id,
          lastname: staff.lastname,
          firstname: staff.firstname,
          color: staff.color,
          isSelected: staff.isSelected,
          isShowTooltip: false,
        })),
        googleResourceCalendars: res.google_resource_calendars.map(
          tmpResource => ({
            id: tmpResource.id,
            summary: tmpResource.summary,
            color: tmpResource.color,
            isSelected: tmpResource.isSelected,
            isShowTooltip: false,
          }),
        ),
      });
      isInitialized.value = true;
      return parseVenues(res.venues, staff.id, true);
    };
    /**
     * キャンセルボタン押下時選択スタッフをリセット
     */
    const onClickCancel = () => {
      resetSelectedStaff();
      context.emit('onClickCancel');
    };
    /**
     * 保存ボタン押下時選択スタッフを渡す
     */
    const onClickSubmit = () => {
      const selectedStaffIds = staffs.value
        ? staffs.value.filter(s => s.isSelected).map(s => s.id)
        : [];
      if (isShowMySelection.value === true) {
        selectedStaffIds.push(store.getters['staff/staff'].id);
      }
      selectedStaffIds.sort();

      const selectedResourceIds = googleResourceCalendars.value
        ? googleResourceCalendars.value.filter(r => r.isSelected).map(r => r.id)
        : [];
      selectedResourceIds.sort();
      context.emit('onClickSubmit', { selectedStaffIds, selectedResourceIds });
    };

    // 担当者を選択せずにモーダルを開くとメッセージ表示
    if (props.isView === true && props.initialStaffIds.length === 0) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '担当者を選択してください',
        type: true,
      });
    }

    onMounted(() => {
      document.body.style.overflow = 'hidden';
    });

    onUnmounted(() => {
      document.body.style.overflow = 'auto';
    });

    return {
      refCalendar,
      isInitialized,
      isLoading,
      calendarOptions,
      isShowMySelection,
      staffs,
      googleResourceCalendars,
      calendarColors,
      // methods
      updateOptions,
      onClickCancel,
      onClickSubmit,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/venue-calendar.scss';

.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.3);
}

.content-inner {
  position: relative;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  margin: 30px;
  background-color: #fff;
  border-radius: 4px;
}

.calendar-wrapper {
  height: calc(100vh - 60px - 72px);
  overflow: hidden;
}

.selections-calendar-relative {
  width: 100%;
}

.buttons {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  margin: 30px;
  width: calc(100% - 60px);
  height: 72px;
  padding: 8px 12px;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.08);
  z-index: 1;
  > button {
    display: block;
    width: calc(50% - 5.5px);
    height: 56px;
    border-radius: 4px;
    &:last-child {
      margin-left: auto;
    }
  }
}

// メニュー左上の角丸対応
:deep() {
  border-radius: 4px 0 0 0;
}

// メニューの縦スクロール高さ調整
:deep(.select-staffs-list) {
  height: calc(100vh - 60px - 73px - 40px - 83px - 18px);
}

// 当日の背景色削除
:deep(td.fc-day-today) {
  background-color: #fff !important;
}

:deep(.fc-view-harness .fc-col-header-cell) {
  background-color: #fff;
}

:deep(.fc-daygrid-day-frame.fc-scrollgrid-sync-inner) {
  > .fc-daygrid-day-events {
    width: 100%;
  }
}

// カレンダーセル
:deep(.fc-view-harness .fc-daygrid-day) {
  height: 40px;
}

// 終日多数の場合にscrollにする場合
// ::v-deep .fc-daygrid-day-events {
//   max-height: 80px;
//   overflow-y: scroll;
// }

// 名前行
:deep(.fc .fc-scrollgrid-section table) {
  min-height: 40px;
}

// 終日行
:deep(.fc-timegrid-axis.fc-scrollgrid-shrink) {
  .fc-timegrid-axis-frame.fc-scrollgrid-shrink-frame.fc-timegrid-axis-frame-liquid
    > span {
    min-height: 40px;
    padding: 12px 10px;
    margin: 0 auto 0 0;
  }
}
:deep(.fc .fc-daygrid-body-natural .fc-daygrid-day-events) {
  padding: 0;
  margin-bottom: 0;
}

// 名前・終日行の調整
:deep(.fc-scrollgrid-sync-inner) {
  display: flex;
  font-size: 16px;
  text-align: left;
  &:before {
    content: '';
    display: block;
    max-width: 14px;
    min-width: 14px;
    height: 14px;
    margin-top: 3px;
    margin-right: 0;
    border: 1px solid #cfcfcf;
    border-radius: 50%;
  }
  &.fc-daygrid-day-frame,
  &.fc-scrollgrid-shrink-cushion {
    // 終日の場合は○が出ないようにする
    &:before {
      display: none;
    }
  }
}

// イベントはクリック不可のためカーソルを出さない
:deep(.fc-daygrid-block-event),
:deep(.fc-event-title-container),
:deep(.fc-timegrid-event) {
  cursor: default;
}

:deep(.fc-event-title) {
  font-weight: bold;
}
</style>
