<template>
  <modal-window
    :is-visible="isView"
    :title="title"
    :is-visible-close-button="false"
  >
    <template #content>
      <div>
        <div class="message">{{ message }}</div>
        <div class="pageloader flexbox flexbox-middle">
          <div class="jumper">
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </template>
  </modal-window>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalLoader',
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'ファイルアップロード',
    },
    message: {
      type: String,
      default: 'ファイルをアップロード中です。',
    },
  },
  setup(props, context) {},
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.message {
  padding: 30px 0;
  font-size: 1.2rem;
  text-align: center;
}

.pageloader {
  width: 70px;
  margin: 0 auto;
  padding: 10px 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
}
.jumper {
  display: block;
  margin: 0 auto 10px;
  width: 50px;
  height: 50px;
}
.jumper > div {
  background-color: #88c6ed;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  -webkit-animation: jumper 1s 0s linear infinite;
  animation: jumper 1s 0s linear infinite;
}
.jumper > div:nth-child(2) {
  -webkit-animation-delay: 0.33333s;
  animation-delay: 0.33333s;
}
.jumper > div:nth-child(3) {
  -webkit-animation-delay: 0.66666s;
  animation-delay: 0.66666s;
}
@-webkit-keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}
</style>
