<template>
  <dd class="options">
    <template v-for="(option, optionIndex) in options">
      <template
        v-if="
          showExistSelector === true || (optionIndex !== 2 && optionIndex !== 3)
        "
      >
        <input
          :id="`${uniqueId}${option.id}${optionIndex}_${index}`"
          :key="`${uniqueId}${option.id}${optionIndex}_${index}`"
          v-model="radioList[index].selectOption"
          type="radio"
          :name="`${uniqueId}${option.id}_${index}`"
          :value="option.value"
          @change="updateRadioList($event.target.value, index)"
        />
        <label
          :key="`${uniqueId}${option.id}${optionIndex}Label_${index}`"
          :for="`${uniqueId}${option.id}${optionIndex}_${index}`"
          class="options_btn"
        >
          {{ option.label }}
        </label>
      </template>
    </template>
  </dd>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchDetailFilterOption',
  props: {
    radioList: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    updateRadioList: {
      type: Function,
      required: true,
    },
    options: {
      type: Array,
      default: () => [
        { id: 'filterOption', value: 1, label: '含む' },
        { id: 'filterOption', value: 0, label: '含まない' },
        { id: 'filterOption', value: 3, label: '存在する' },
        { id: 'filterOption', value: 2, label: '存在しない' },
      ],
    },
    uniqueId: { type: String, default: '' },
    showExistSelector: { type: Boolean, default: true },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.options {
  margin-top: 10px;

  & > input[type='radio'] {
    margin-right: 3px;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  & > label {
    cursor: pointer;
  }
}

@media (max-width: ($media_query_sp)) {
  .options {
    font-size: 1rem;

    & > .options_btn {
      vertical-align: 3px;
    }

    & > input[type='radio'] {
      width: 10px;
    }
  }
}
</style>
