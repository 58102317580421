export default [
  {
    eq: 0,
    exist: null,
    text: 'を含まない',
  },
  {
    eq: 1,
    exist: null,
    text: 'を含む',
  },
  {
    eq: 1,
    exist: 0,
    text: 'が存在しない',
  },
  {
    eq: 1,
    exist: 1,
    text: 'が存在する',
  },
];
