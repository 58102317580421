<template>
  <div class="applicant_wrap">
    <bread-crumb-list>
      <template #breadcrumb>
        <div>
          <span class="crumb_title">応募者一覧</span>
          <span class="next_arrow">></span>
          <span class="crumb_title">応募者詳細</span>
        </div>
      </template>
    </bread-crumb-list>

    <div v-if="$store.getters['page/isPageLoaded']" class="page-contents">
      <applicant-info
        :default-applicant="applicant"
        :progresses="progresses.data"
        :flag-survey-notice-id="flagSurveyNoticeId"
      />

      <div class="applicantSetting-section">
        <applicant-flow
          :progresses="progresses.data"
          :applicant-id="id"
          :applicant-graduated-id="applicantGraduatedId"
          @reloadPage="fetchAll"
          @toggleReminderSetting="toggleReminderSetting"
          @toggleReminderCancel="toggleReminderCancel"
          @toggleReminderCheckSetting="toggleReminderCheckSetting"
          @toggleReminderCheckCancel="toggleReminderCheckCancel"
          @toggleMypageVisibleSetting="toggleMypageVisibleSetting"
          @toggleMypageVisibleCancel="toggleMypageVisibleCancel"
          @determineOperatorEvaluation="determineOperatorEvaluation"
          @determineManagerEvaluation="determineManagerEvaluation"
          @undoManagerEvaluation="undoManagerEvaluation"
          @applicantFetchAllApi="fetchAll"
        />
        <file-upload
          :files="uploadFiles"
          :upload-status="uploadStatus.data"
          :downloading-file-id-map="downloadingFileIdMap"
          @visibleModalUpload="isOpenModalFileUpload = true"
          @upload="uploadFile"
          @delete="deleteFile"
          @download="downloadFile"
        />
        <applicant-flag-group
          :applicant-graduated-id="applicantGraduatedId"
          @toModalDialog="toModalDialog"
        />
        <applicant-flag-surveys
          :flagsurvey-notices="flagsurveyNotices"
          :flagsurvey-notice="flagsurveyNotice.data"
          :is-loading="isLoadingFlagSurveyNotice"
          @updateFlagSurvey="fetchFlagSurveyNotice"
        />
        <talk-index
          v-if="applicantGraduatedId"
          ref="talkIndex"
          :applicant-graduated-id="applicantGraduatedId"
          @setFiles="setFiles"
          @downloadFile="downloadFile"
        />
        <notice-history :applicant-obj="applicant" :applicant-id="id" />
        <mypage-log v-if="currentPlan.data.name !== 'entry'" />
        <div
          v-if="!$store.getters['plan/isFreePlan']"
          class="sec-applicant-delete"
        >
          <div
            class="btn btn-unavailable"
            @click="isVisibleModalDeleteApplicant = true"
          >
            <img class="icon" src="@/assets/img/delete_white.svg" />
            この応募者を削除する
          </div>
        </div>
      </div>

      <modal-dialog
        v-if="isOpenModalDialog"
        :is-view="true"
        :accent-color="'#C31E1E'"
        :title="'フラググループをはずします。'"
        :message="'応募者のフラグからこのフラグ/フラググループがはずされます。この操作は取り消すことができません。'"
        :button-text1="'キャンセル'"
        :button-text2="'フラググループをはずす'"
        @onSelected="onSelectedModalDeleteFlagGroup"
        @onclickOuter="onclickOuterTagGroup"
      />
      <modal-loader :is-view="isOpenModalUploadProgress" />
      <modal-dialog
        :is-view="isOpenModalFileDelete"
        :accent-color="'#C31E1E'"
        :title="
          '「' +
          (deleteTargetFile.data
            ? deleteTargetFile.data.originalFilename
            : '') +
          '」を削除します。'
        "
        :message="'上記のファイルを削除します。一度削除すると取り消すことができません。'"
        :button-text1="'キャンセル'"
        :button-text2="'ファイルを削除する'"
        :aleart-flag="true"
        @onSelected="onSelectedModalFileDelete"
        @onclickOuter="isOpenModalFileDelete = false"
      />
    </div>
    <modal-window
      :is-visible="isVisibleModalDeleteApplicant"
      :is-visible-close-button="true"
      :is-new-design="true"
      max-width="550px"
      modal-type="danger"
      title="応募者を削除する"
      message="この応募者を削除します。一度削除すると戻すことはできません。"
      caution-note="※選考に割り当てられていた場合、削除後も目標対比の集計対象に含まれます。"
      button-text="応募者を削除する"
      @clickOuter="isVisibleModalDeleteApplicant = false"
      @close="isVisibleModalDeleteApplicant = false"
      @click="deleteApplicantMethod"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';
import qs from 'qs';

import { getPlanDataById } from '@/defines/plan';
import progressesService from '@/services/progresses';
import uploadService from '@/services/upload';
import applicantsService from '@/services/applicants';
import reserveService from '@/services/reserves';
import ModalDialog from '@/components/ui/modals/components/ModalDialog';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import ModalLoader from '@/components/ui/loaders/components/ModalLoader';
import ApplicantInfo from '@/components/page/applicants/_id/components/ApplicantInfo';
import ApplicantFlow from '@/components/page/applicants/_id/components/ApplicantFlow';
import FileUpload from '@/components/page/applicants/_id/components/FileUpload';
import ApplicantFlagGroup from '@/components/page/applicants/_id/components/ApplicantFlagGroup';
import TalkIndex from '@/components/page/applicants/_id/components/TalkIndex';
import NoticeHistory from '@/components/page/applicants/_id/components/NoticeHistory';
import MypageLog from '@/components/page/applicants/_id/components/MypageLog';
import ApplicantFlagSurveys from '@/components/page/applicants/_id/components/ApplicantFlagSurveys';

export default defineComponent({
  name: 'ApplicantDetail',
  components: {
    BreadCrumbList,
    ModalDialog,
    ModalLoader,
    ApplicantInfo,
    ApplicantFlow,
    FileUpload,
    ApplicantFlagGroup,
    TalkIndex,
    ApplicantFlagSurveys,
    NoticeHistory,
    MypageLog,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const isVisibleModalDeleteApplicant = ref(false);
    const isOpenModalDialog = ref(false);
    const isOpenModalUploadProgress = ref(false);
    const isOpenModalFileDelete = ref(false);
    const isLoadingFlagSurveyNotice = ref(false);
    const modeMenu = ref(null);
    const messages = ref([]);
    const applicantGraduatedId = ref(null);
    const flagsurveyNotices = ref([]);
    const applicantId = ref(null);
    const tagGroupId = ref(null);
    const uploadFiles = ref([]);
    const flagSurveyNoticeId = ref(null);
    const flagsurveyNotice = reactive({ data: {} });
    const deleteTargetFile = reactive({ data: {} });
    const uploadStatus = reactive({ data: {} });
    const downloadingFileIdMap = reactive({ data: {} });
    const currentPlan = reactive({ data: {} });
    const progresses = reactive({ data: {} });

    // computed
    const applicant = computed(() => store.getters['applicant/applicant']);

    // methods
    const setPlan = () => {
      currentPlan.data = getPlanDataById(store.getters['plan/plan'].id);
    };
    const setProgresses = async () => {
      await progressesService.fetchProgress(props.id).then(v => {
        progresses.data = v;
      });
    };
    const setFiles = async () => {
      const res = await uploadService.fetchApplicantFiles(props.id);
      if (res.success) uploadFiles.value = res.data;
    };
    const setUploadStatus = async () => {
      const resUploadStatus = await uploadService.fetchUploadStatus();
      if (resUploadStatus.success) {
        uploadStatus.data = resUploadStatus.data;
      }
    };
    const fetchFlagSurveyNotice = async id => {
      flagSurveyNoticeId.value = id;
      if (id === 0) return;
      isLoadingFlagSurveyNotice.value = true;
      const resFlagSurvey =
        await applicantsService.fetchApplicantFlagsurveyNotice(props.id, id);
      if (resFlagSurvey.success !== true) {
        flagsurveyNotice.data = {};
        return;
      }
      flagsurveyNotice.data = resFlagSurvey.flagsurvey_notice;
      isLoadingFlagSurveyNotice.value = false;
    };
    const fetchFlagSurveyNotices = async () => {
      isLoadingFlagSurveyNotice.value = true;
      const resFlagSurveys =
        await applicantsService.fetchApplicantFlagsurveyNotices(props.id);
      if (resFlagSurveys.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: resFlagSurveys.message,
          type: false,
        });
        return;
      }
      if (
        resFlagSurveys.flagsurvey_notices &&
        resFlagSurveys.flagsurvey_notices.length > 0
      ) {
        flagsurveyNotices.value = [
          { id: 0, name: '未選択', suffix: '' },
        ].concat(
          resFlagSurveys.flagsurvey_notices.map(v => ({
            id: v.id,
            name: v.title,
            suffix: ` - ${moment(v.announced_at).format('YYYY/MM/DD HH:mm')}`,
          })),
        );
      } else {
        flagsurveyNotices.value = [{ id: 0, name: '未選択', suffix: '' }];
      }
      isLoadingFlagSurveyNotice.value = false;
    };
    const fetchAll = async () => {
      const app = await store.dispatch('applicant/FETCH_APPLICANT', props.id);
      if (app) {
        await setProgresses();
      } else {
        router.push({ name: 'ApplicantsOverviewDefault' });
      }
      const applicantGraduateds = store.getters['graduateds/graduateds'].find(
        graduated => graduated.year === applicant.value.graduated,
      );
      applicantGraduatedId.value = applicantGraduateds
        ? applicantGraduateds.id
        : null;
      setPlan();
      await setFiles();
      await setUploadStatus();
      await fetchFlagSurveyNotices();
      await store.dispatch('page/SET_LOADED');
    };
    const toModalDialog = payload => {
      applicantId.value = payload.applicantId;
      tagGroupId.value = payload.tagGroupId;
      isOpenModalDialog.value = true;
    };
    const onSelectedModalDeleteFlagGroup = async payload => {
      if (payload.choice === 2) {
        store.dispatch('applicant/DELETE_APPLICANT_TAG_GROUP', {
          applicantId: applicantId.value,
          tagGroupId: tagGroupId.value,
        });
      }
      isOpenModalDialog.value = false;
    };
    const onclickOuterTagGroup = payload => {
      isOpenModalDialog.value = payload;
    };
    const uploadFile = async payload => {
      isOpenModalUploadProgress.value = true;
      payload.applicantId = applicant.value.id;
      const res = await uploadService.uploadFile(payload);
      if (!res.success) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: false,
        });
      }
      isOpenModalUploadProgress.value = false;
      await setFiles();
      await setUploadStatus();
    };
    const deleteFile = payload => {
      isOpenModalFileDelete.value = true;
      deleteTargetFile.data = payload;
    };
    const onSelectedModalFileDelete = async payload => {
      if (payload.choice === 2) {
        const res = await uploadService.deleteFile(deleteTargetFile.data);
        if (!res.success) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.data.message,
            type: false,
          });
        }
      }
      deleteTargetFile.data = null;
      isOpenModalFileDelete.value = false;
      await setFiles();
      await setUploadStatus();
    };
    const downloadFile = async payload => {
      downloadingFileIdMap.data[`${payload.id}`] = true;
      const res = await uploadService.downloadFile(payload);
      if (downloadingFileIdMap.data[payload.id]) {
        const newDownloadingFileIdMap = Object.assign(
          {},
          downloadingFileIdMap.data,
        );
        delete newDownloadingFileIdMap[payload.id];
        downloadingFileIdMap.data = newDownloadingFileIdMap;
      }
      if (!res.success) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: false,
        });
      }
    };
    const cssDeleteMenu = async () => {
      if (modeMenu.value === 0) {
        const headerWrapper = document.getElementsByClassName('header-wrapper');
        const sidebar = document.getElementsByClassName('sidebar');
        const contents = document.getElementsByClassName('contents');
        headerWrapper[0].style.display = 'none';
        sidebar[0].style.display = 'none';
        contents[0].style.padding = 0;
      }
    };
    const toggleReminderSetting = async payload => {
      const res = await reserveService.toggleReminderSetting(payload);
      if (res) {
        store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        await fetchAll();
      }
    };
    const toggleReminderCancel = async () => {
      store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
      await fetchAll();
    };
    const toggleReminderCheckSetting = async payload => {
      const res = await reserveService.toggleReminderCheckSetting(payload);
      if (res) {
        store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        await fetchAll();
      }
    };
    const toggleReminderCheckCancel = async () => {
      store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
      await fetchAll();
    };
    const toggleMypageVisibleSetting = async payload => {
      const res = await reserveService.toggleMypageVisibleSetting(payload);
      if (res) {
        store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        await fetchAll();
      }
    };
    const toggleMypageVisibleCancel = async () => {
      store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
      await fetchAll();
    };
    const determineOperatorEvaluation = async payload => {
      const res = await progressesService.updateCurrentProgressComment(
        props.id,
        payload.evaluation,
        payload.progressId,
      );

      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      payload.finishLoading();
      if (res.success === true) {
        payload.closeOperatorEvaluationForm();
        await fetchAll();
      }
    };
    const determineManagerEvaluation = async payload => {
      let res = null;
      if (payload.progressStatus === payload.statusType.hired) {
        res = await progressesService.updateFinishedProgressStatus(
          props.id,
          'yes',
        );
      } else if (payload.progressStatus === payload.statusType.offer_canceled) {
        res = await progressesService.updateFinishedProgressStatus(
          props.id,
          'no',
        );
      } else {
        res = await progressesService.updateCurrentProgressStatus(
          props.id,
          payload.evaluation,
          payload.progressStatus,
          payload.progressId,
        );
      }
      if (res === null) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '不正な値が入力されました',
          type: false,
        });
        return;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      payload.finishLoading();
      if (res.success === true) {
        payload.closeManagerEvaluationForm();
        await fetchAll();
      }
    };
    const undoManagerEvaluation = async payload => {
      let res;
      if (payload.progressStatus === payload.statusType.hired) {
        res = await progressesService.updateFinishedProgressStatus(
          props.id,
          'yes',
        );
      } else if (payload.progressStatus === payload.statusType.offer_canceled) {
        res = await progressesService.updateFinishedProgressStatus(
          props.id,
          'no',
        );
      } else {
        res = await progressesService.updateCurrentProgressStatus(
          props.id,
          payload.evaluation,
          payload.progressStatus,
          payload.progressId,
        );
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message ? res.message : 'エラーが発生しました',
        type: res.success,
      });
      payload.finishLoading();
      if (res.success === true) {
        payload.closeManagerEvaluationForm();
        await fetchAll();
      }
    };
    const deleteApplicant = async payload => {
      const res = await applicantsService.deleteApplicant(payload);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        router.push({ name: 'ApplicantsOverviewDefault' });
      }
    };
    const deleteApplicantMethod = async ({ selected }) => {
      if (selected === 'submit') {
        await deleteApplicant(applicant.value.id);
        const headerWrapper = document.getElementsByClassName('header-wrapper');
        const sidebar = document.getElementsByClassName('sidebar');
        const contents = document.getElementsByClassName('contents');
        headerWrapper[0].style.display = 'flex';
        sidebar[0].style.display = 'block';
        contents[0].style.padding = '5rem 0 10rem 17rem';
        document.querySelector('body').classList.remove('-noscroll');
        router.push({ path: '/applicants/page/1' });
      }
      isVisibleModalDeleteApplicant.value = false;
    };
    const onclickOuter = () => {
      isVisibleModalDeleteApplicant.value = false;
    };

    // lifecycle
    onBeforeMount(async () => {
      // menu=0 のqueryがついていたらメニューを消す
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      modeMenu.value = parseInt(query.menu, 10);
      await fetchAll();
    });
    onMounted(async () => {
      await cssDeleteMenu();
    });
    onUnmounted(() => {
      store.dispatch('applicant/CLEAR_APPLICANT');
    });

    return {
      isVisibleModalDeleteApplicant,
      isOpenModalDialog,
      isOpenModalUploadProgress,
      isOpenModalFileDelete,
      isLoadingFlagSurveyNotice,
      modeMenu,
      messages,
      applicantGraduatedId,
      flagsurveyNotices,
      flagsurveyNotice,
      flagSurveyNoticeId,
      deleteTargetFile,
      uploadFiles,
      uploadStatus,
      downloadingFileIdMap,
      currentPlan,
      progresses,
      applicant,
      fetchAll,
      fetchFlagSurveyNotice,
      setFiles,
      toModalDialog,
      onSelectedModalDeleteFlagGroup,
      onclickOuterTagGroup,
      uploadFile,
      deleteFile,
      onSelectedModalFileDelete,
      downloadFile,
      cssDeleteMenu,
      toggleReminderSetting,
      toggleReminderCancel,
      toggleReminderCheckSetting,
      toggleReminderCheckCancel,
      toggleMypageVisibleSetting,
      toggleMypageVisibleCancel,
      determineOperatorEvaluation,
      determineManagerEvaluation,
      undoManagerEvaluation,
      deleteApplicantMethod,
      onclickOuter,
      deleteApplicant,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.applicant_wrap {
  height: 100vh;
}
.page-contents {
  display: flex;
  width: 100%;
  padding: 20px;
  max-width: 1300px;
  margin: 0 auto;
}
.applicantSetting-section {
  margin-left: 20px;
  flex: 1;
  width: calc(100% - 240px);

  .area-title {
    .title {
      display: flex;
      align-items: center;
      padding: 1.2rem 0 0 0;
      margin-bottom: 1.2rem;

      .area-title-svg {
        width: 22px;
        fill: $mochica_color;
        margin-right: 12px;
      }
    }
  }
  .sec-activity {
    background: white;
    table {
      padding-bottom: 1rem;
      thead {
        th {
          height: 3rem;
          line-height: 3rem;
          color: $mochica_color;
          padding-left: 1.2rem;
        }
      }
      tbody {
        tr {
          td {
            height: 3rem;
            line-height: 3rem;
            padding-left: 1.2rem;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  //フラグの調整
  & :deep(.table-normal) {
    th:first-of-type {
      min-width: 20rem;
    }
    td {
      line-height: unset;
      white-space: nowrap;
      flex-wrap: wrap;

      .flexbox-middle {
        align-items: center;
        flex-wrap: wrap;
        padding-top: 1rem;
        li {
          padding: 0 1rem 0 0;
          margin-bottom: 1rem;
        }
        &.btn-click-container {
          padding-top: 0;
        }
      }
    }
  }
  & :deep(.modal-wrapper) {
    .modal-header_btn {
      display: none;
    }
    .modal-body {
      height: auto;
    }
  }
}
.sec-applicant-delete {
  text-align: center;
}
@media (max-width: ($media_query_tablet)) {
  .page-contents {
    display: block;
    padding: 20px 0;
  }
  .applicantSetting-section {
    margin-left: 0;
    width: 100%;
  }
}

.next_arrow {
  margin: 0 8px;
}
</style>
