<script setup>
import { useField } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';

import AutoActionsTemplate from '@/components/features/autoActions/templates/AutoActionsTemplate.vue';
import BaseCheckbox from '@/components/ui/checkbox/BaseCheckbox.vue';
import BaseInput from '@/components/ui/input/BaseInput.vue';
import HighLight from '@/components/ui/text/Highlight.vue';

import {
  ACTION_TYPES,
  ACTION_STATUSES,
  EDIT_FORM_COMPONENTS,
} from '@/defines/autoActions';

defineProps({
  formValues: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['submit']);

const route = useRoute();
const router = useRouter();

const {
  value: actionName,
  handleChange: handleChangeActionName,
  errorMessage: actionNameError,
} = useField('actionName');
const { value: actionStatus } = useField('actionStatus');

const handleChangeActionStatus = value => {
  actionStatus.value = value.target.checked
    ? ACTION_STATUSES.enabled
    : ACTION_STATUSES.disabled;
};
const backPageHandler = () => {
  router.push({
    name: 'AutoActionsDetail',
    params: { id: route.params.id },
  });
};
</script>
<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <auto-actions-template
        :header-title="'自動アクションの編集'"
        :back-page-title="'自動アクションの詳細に戻る'"
        :on-back-page="backPageHandler"
      >
        <template #titleDescription>
          作成したい自動アクションの内容を入力し、保存を押してください。
          <br />
          <small class="caption">
            ※自動アクションの編集は「アクション名」「アクションの有効/無効」「メール・LINEの文面の変更」のみ行えます。
          </small>
        </template>
        <template #body>
          <div class="section-body-inner">
            <section class="action-wrapper">
              <div class="action-outer">
                <div class="action-inner">
                  <div class="action-title">
                    <h3 class="title">自動アクション名</h3>
                    <p class="desc">
                      作成する自動アクションの名前を入力してください。
                    </p>
                  </div>
                  <div class="action-contents">
                    <div class="action-create-parts">
                      <base-input
                        :model-value="actionName"
                        :is-error="!!actionNameError"
                        @blur="handleChangeActionName"
                      />
                      <div v-show="!!actionNameError" class="error-message">
                        {{ actionNameError }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="action-inner">
                  <div class="action-title">
                    <h3 class="title">アクションの有効/無効</h3>
                  </div>
                  <div class="action-contents">
                    <div class="action-checkbox">
                      <base-checkbox
                        :model-value="actionStatus === ACTION_STATUSES.enabled"
                        @change="handleChangeActionStatus($event)"
                      />
                      <div>自動アクションを有効にする</div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              v-if="
                formValues.actionType === ACTION_TYPES.results ||
                formValues.actionType === ACTION_TYPES.notice
              "
              class="action-wrapper"
            >
              <high-light :h-level="2">メール・LINEの文面</high-light>
              <div class="action-outer">
                <component
                  :is="EDIT_FORM_COMPONENTS[formValues.actionType]"
                  :files="formValues.notice?.files ?? []"
                  :is-send-force-email="!!formValues.notice?.isSendForceEmail"
                />
              </div>
            </section>
          </div>
        </template>
        <template #footer>
          <div class="page-footer">
            <button-base
              type="button"
              class="btn-cancel"
              button-type="secondary"
              button-text="キャンセル"
              @click="backPageHandler"
            />
            <button-base
              type="button"
              class="btn-confirm"
              :button-text="'確 認'"
              @click="emit('submit')"
            />
          </div>
        </template>
      </auto-actions-template>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionTemplate;
.page-wrapper {
  margin: 20px 0;
  padding: 0 20px;
  .page-inner {
    padding: 0 30px 30px 40px;
    background-color: #fff;
  }
}
.page-footer {
  display: flex;
  gap: 12px;
  padding: 20px 0 0;
  & > button {
    width: 50%;
    height: 56px;
  }
}
</style>
