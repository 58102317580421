<template>
  <main class="issue_password">
    <p class="pass_desc">
      以下のフォームに新しいパスワードを入力してください。
    </p>
    <form class="issue_form">
      <label for="">パスワード</label>
      <input
        v-model="password"
        type="password"
        class="pass_input"
        :class="{ input: true, 'is-danger': !errors.password.isValid }"
        placeholder="パスワードを入力"
        @blur="validatePassword('password', password)"
      />
      <p class="password_caption">
        半角英数字・8〜64文字・大文字を1文字以上含んだ形式で入力してください
      </p>
      <span v-show="!errors.password.isValid" class="fa fa-warning">
        {{ errors.password.message }}
      </span>
      <label for="">パスワード（確認用）</label>
      <input
        v-model="confirmPassword"
        type="password"
        class="pass_input"
        :class="{ input: true, 'is-danger': !errors.confirmPassword.isValid }"
        placeholder="パスワード（確認用）を入力"
      />
      <span v-show="!errors.confirmPassword.isValid" class="fa fa-warning">
        {{ errors.confirmPassword.message }}
      </span>
    </form>
    <button class="pass_btn" @click.prevent="sendPassword">送信する</button>
  </main>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'IssuePassWord',
  props: {},
  emits: ['changePassword'],
  setup(props, context) {
    const store = useStore();
    const {
      errors,
      resetErrors,
      validatePassword,
      validateComparisonPassword,
    } = useValidation(['password', 'confirmPassword']);
    const password = ref('');
    const confirmPassword = ref('');

    // methods
    const sendPassword = () => {
      const validRes = validateAll();
      if (validRes.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: validRes.message,
          type: false,
        });
        return;
      }
      context.emit('changePassword', {
        new_password: password.value,
        confirmation: confirmPassword.value,
      });
    };
    const validateAll = () => {
      resetErrors();
      const isValidPassword = validatePassword('password', password.value);
      if (
        !isValidPassword ||
        !validateComparisonPassword(password.value, confirmPassword.value)
      ) {
        return {
          success: false,
          message: '入力内容を確認して下さい',
        };
      }
      return { success: true, message: '' };
    };

    return {
      errors,
      password,
      confirmPassword,
      validatePassword,
      sendPassword,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include form_wrapper_css();
@include validation_effects2();

main.issue_password {
  display: flex;
  flex-flow: column;
  .issue_form {
    display: flex;
    flex-flow: column;
    margin-top: 25px;
    label {
      margin-top: 20px;
    }
  }
  button.pass_btn {
    margin-top: 20px;
    width: 100%;
    border: none;
    background-color: $mochica_color;
    border: $mochica_border_color;
    color: #fff;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 10px 0;
    border-radius: 0.3rem;
    cursor: pointer;
  }
}

.password_caption {
  font-size: 12px;
}
</style>
