import moment from 'moment';

import Venue from '@/models/venue';
import { snakeToCamelCaseForObject } from '@/utils/change-case';

const Selection = function _Selection(
  assigndCount = null,
  id = null,
  count = null,
  event = null,
  notice = null,
  venues = null,
  dates = null,
  description = null,
  updatedAt = null,
  reserve = false,
  reminderCheck = true,
) {
  const selection = Object.create(Selection.prototype);
  selection.count = assigndCount;
  selection.id = id;
  selection.applicant_count = count;
  selection.event = event
    ? snakeToCamelCaseForObject(event)
    : { id: null, title: null, visible: null, createdAt: null };
  selection.notice = notice;
  selection.venues = venues ? venues.map(Venue.createFromJSON) : [];
  selection.dates = dates;
  selection.description = description;
  selection.updated_at = updatedAt;
  selection.reserve = reserve;
  selection.reminder_check = reminderCheck;
  return selection;
};

Selection.createFromJSON = json => {
  if (json) {
    return Selection(
      json.assigned_count,
      json.id,
      json.applicant_count,
      json.event,
      json.notice,
      json.venues,
      json.dates,
      json.description,
      json.updated_at,
      json.reserve,
      json.reminder_check,
    );
  }
  return Selection();
};

Selection.prototype = {
  get venueDates() {
    return this.venues ? this.venues.map(venue => venue.date) : [];
  },
  get countVenues() {
    return this.venues ? this.venues.length : 0;
  },
  get countSelectionLimit() {
    let count = 0;
    if (this.venues) {
      this.venues.forEach(venue => {
        count += venue.limit;
      });
      return count;
    }
    return 0;
  },
  get ActialPerLimit() {
    let limCount = 0;
    let actCount = 0;
    if (this.venues) {
      this.venues.forEach(venue => {
        actCount += venue.actual;
        limCount += venue.limit;
      });
      return `${actCount} / ${limCount}`;
    }
    return '0 / 0';
  },
  get allDates() {
    let dateCorrect = '';
    if (this.venues) {
      this.venues.forEach(venue => {
        dateCorrect += moment(venue.date).format('M/D');
        dateCorrect += ', ';
      });
      return dateCorrect;
    }
    return '';
  },
  addVenue(args = {}) {
    const venue = new Venue();
    venue.addTimeTable(args);
    this.venues.push(venue);
  },
  jsonObjCreateSelection(
    selectedStaffIds = [],
    selectedResourceIds = [],
    zoomHostIds = [],
  ) {
    return {
      event_id: this.event.id,
      notice: this.notice,
      description: this.description,
      update_at: this.update_at,
      reserve: this.reserve,
      reminder_check: this.reminder_check,
      venues: this.venues.map((venue, i) =>
        venue.jsonObjCreateVenue(
          selectedStaffIds[i] ? selectedStaffIds[i] : [],
          selectedResourceIds[i] ? selectedResourceIds[i] : [],
          zoomHostIds[i] ? zoomHostIds[i] : venue.timetables.map(t => null),
        ),
      ),
    };
  },
};

export default Selection;
