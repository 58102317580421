<template>
  <div>
    <modal :is-view="isVisible">
      <template #content>
        <div class="content">
          <div class="body">
            <section class="modal-header">
              <div class="modal-header_inner">
                <div class="modal-header_ttl">連絡事項確認</div>
              </div>
              <div class="modal-header_cancell" @click="onClickCancel">
                <img src="@/assets/img/close_black.svg" alt />
              </div>
            </section>
            <section class="modal-body">
              <div class="selection-info">
                <div v-if="item.announced_at === null" class="button_block">
                  <button
                    class="btn btn-availability"
                    @click="onClickOpenEditModal"
                  >
                    <img class="icon" src="@/assets/img/pen_white.svg" alt />
                    <span slot="text">メッセージの編集</span>
                  </button>
                  <button
                    class="btn btn-unavailable"
                    @click="onClickOpenDeleteDialog"
                  >
                    <img class="icon" src="@/assets/img/delete_white.svg" alt />
                    <span slot="text">メッセージの削除</span>
                  </button>
                </div>
                <div
                  v-if="item.announced_at === null"
                  class="selection-info_header_title"
                >
                  配信状況
                </div>
                <div v-else class="selection-info_header_title">
                  送信完了しました。
                </div>

                <div class="announce_information">
                  <dl>
                    <dt>配信</dt>
                    <dd>{{ sendStatus(item) }}</dd>
                  </dl>
                  <dl>
                    <dt>配信予約日時</dt>
                    <dd>
                      {{
                        $utils.parseDateTime(
                          item.schedule_time,
                          'YYYY年MM月DD日 HH:mm',
                        )
                      }}
                    </dd>
                  </dl>
                  <dl
                    v-if="
                      item.applicant_notices.length > 0 &&
                      item.announced_at !== null
                    "
                  >
                    <dt>配信完了日</dt>
                    <dd>
                      {{
                        $utils.parseDateTime(
                          item.announced_at,
                          'YYYY年MM月DD日 HH:mm',
                        )
                      }}
                    </dd>
                  </dl>
                  <dl>
                    <dt>担当者</dt>
                    <dd>
                      {{ item.staff === null ? '' : item.staff.lastname
                      }}{{ item.staff === null ? '' : item.staff.firstname }}
                    </dd>
                  </dl>
                </div>
                <div class="selection-info_header_title">メッセージ</div>
                <div class="url_read">
                  <p class="rich_heading">連絡事項タイトル</p>
                  <div class="input_tr">
                    <span>{{ item.notice_title }}</span>
                  </div>
                  <p class="rich_heading">全員にメールで送信</p>
                  <div class="input_tr">
                    <span>
                      {{
                        item.force_email
                          ? '全員にメールで送信する'
                          : '全員にメールで送信しない'
                      }}
                    </span>
                  </div>
                  <p class="rich_heading">メッセージ確認</p>
                  <div class="input_tr">
                    <span>{{ item.notice_check ? 'あり' : 'なし' }}</span>
                  </div>
                </div>
                <div class="input_line_and_email">
                  <input-line-and-email
                    :line-content="item.content_line"
                    :email-title="item.title"
                    :email-content="item.content_email"
                    line-description="LINEで送信できなかった場合は、メールで送信されます。"
                    email-description=" "
                    :line-captions="[
                      '内容冒頭には宛先の｛姓・名｝さんが挿入されます。',
                    ]"
                    :email-captions="[
                      '内容冒頭には宛先の｛姓・名｝様が挿入されます。',
                      '末尾には設定している署名が挿入されます。',
                    ]"
                    :files="item.files"
                    :is-disabled-line="item.force_email"
                    :is-disabled="true"
                    :is-notice-history="true"
                  />
                </div>
                <div
                  v-if="!isApplicantDetailView"
                  class="send_applicant_list_section"
                >
                  <div class="selection-info_header_title">送信先応募者</div>
                  <div
                    v-if="applicantList.length === 0"
                    class="send_applicant_list_none"
                  >
                    <span>
                      ※送信先の応募者が設定されていないか、既に削除された応募者です。
                    </span>
                  </div>
                  <template v-else-if="item.notice_check">
                    <div class="send_applicant_list">
                      <p class="send_applicant_list_title">
                        メッセージ確認済応募者：
                        <span class="inner">
                          {{ checkedApplicants.length }}名
                        </span>
                      </p>
                      <notice-applicant-preview
                        :is-view-add-button="false"
                        :applicant-list="checkedApplicants"
                        :is-notice-view="needToResendNotice"
                        @changeSelectedApplicantsIds="
                          changeSelectedCheckedApplicantsIds
                        "
                      />
                    </div>
                    <div class="send_applicant_list">
                      <p class="send_applicant_list_title">
                        メッセージ未確認応募者：
                        <span class="inner">
                          {{ uncheckedApplicants.length }}名
                        </span>
                      </p>
                      <notice-applicant-preview
                        :is-view-add-button="false"
                        :applicant-list="uncheckedApplicants"
                        :is-notice-view="needToResendNotice"
                        @changeSelectedApplicantsIds="
                          changeSelectedUncheckedApplicantsIds
                        "
                      />
                    </div>
                  </template>
                  <div v-else class="send_applicant_list">
                    <p class="send_applicant_list_title">
                      送信先：
                      <span class="inner">{{ applicantList.length }}名</span>
                    </p>
                    <notice-applicant-preview
                      :is-view-add-button="false"
                      :applicant-list="applicantList"
                    />
                  </div>
                </div>
              </div>
            </section>
            <div v-if="!isApplicantDetailView" class="btn-col2">
              <button
                :class="{ 'full-size-btn': !isExistAnnouncedAt }"
                class="btn btn-unavailable"
                @click="onClickCancel"
              >
                <span>キャンセル</span>
              </button>
              <button
                v-if="isExistAnnouncedAt"
                class="btn btn-availability"
                @click="onClickSendNoticeSubmit"
              >
                <span>今すぐ送信する</span>
              </button>
              <button
                v-else-if="needToResendNotice"
                class="btn btn-availability"
                @click="onClickCreateNewNotice"
              >
                <span>選択された応募者に新規連絡事項を作成する</span>
              </button>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import NotificationService from '@/services/notifications';
import Modal from '@/components/ui/modals/components/Modal';
import InputLineAndEmail from '@/components/features/inputLineAndEmail/components/InputLineAndEmail';
import NoticeApplicantPreview from '@/components/features/modalNoticeDetail/components/NoticeApplicantPreview';

export default defineComponent({
  name: 'ModalNoticeDetail',
  components: { Modal, NoticeApplicantPreview, InputLineAndEmail },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    isApplicantDetailView: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onClickCancel',
    'onClickOpenEditModal',
    'onClickOpenDeleteDialog',
    'onClickSendNoticeSubmit',
    'onClickCreateNewNotice',
    'openCreateModalAndSetApplicants',
    'onClickUpdateSubmit',
  ],
  setup(props, context) {
    const store = useStore();
    const applicantList = ref([]);
    const selectedCheckedApplicantIds = ref([]);
    const selectedUncheckedApplicantIds = ref([]);

    // computed
    const isExistAnnouncedAt = computed(() => props.item.announced_at === null);
    const needToResendNotice = computed(
      () =>
        isExistAnnouncedAt.value !== true &&
        applicantList.value.filter(v => !v.noticeCheck).length > 0 &&
        props.item.notice_check,
    );
    const checkedApplicants = computed(() => {
      return applicantList.value.filter(x => x.noticeCheck);
    });
    const uncheckedApplicants = computed(() => {
      return applicantList.value.filter(x => !x.noticeCheck);
    });

    // watch
    watch(
      () => props.isVisible,
      () => {
        resetLocal();
      },
    );

    // methods
    const resetLocal = () => {
      applicantList.value = props.item.applicant_notices.map(notice => ({
        id: notice.applicant.id,
        lastname: notice.applicant.lastname,
        firstname: notice.applicant.firstname,
        lastnameKana: notice.applicant.lastname_kana,
        firstnameKana: notice.applicant.firstname_kana,
        gender: notice.applicant.gender,
        school: notice.applicant.school,
        faculty: notice.applicant.faculty,
        department: notice.applicant.department,
        departmentCategory: notice.applicant.department_category,
        tel: notice.applicant.tel,
        email: notice.applicant.email,
        contactType: notice.applicant.contact_type
          ? notice.applicant.contact_type.description
          : null,
        isError: notice.is_error,
        announcedAt: notice.announced_at
          ? moment(notice.announced_at).format('YYYY-MM-DD HH:mm:ss')
          : null,
        noticeCheck: notice.notice_check,
      }));
    };
    const onClickCancel = () => {
      context.emit('onClickCancel');
    };
    const onClickOpenEditModal = () => {
      context.emit('onClickOpenEditModal');
    };
    const onClickOpenDeleteDialog = async () => {
      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: '配信予約の削除',
        message: '現在選択している配信予約を削除します。よろしいですか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          if (payload.choice === 2) await deleteNotice();
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    const deleteNotice = async () => {
      const res = await NotificationService.noticeAnnounceDelete({
        notice_id: props.item.id,
      });
      if (res.success) context.emit('onClickUpdateSubmit');
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const onClickSendNoticeSubmit = async () => {
      if (props.item.applicant_notices.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '送信先応募者を設定してください',
          type: false,
        });
        return;
      }
      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: '配信予約の送信',
        message: '現在選択している配信予約を送信します。よろしいですか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          if (payload.choice === 2) {
            const res = await NotificationService.noticeAnnounceSendJust({
              staff_id: props.item.staff_id,
              notice_id: props.item.id,
            });
            if (res.success) context.emit('onClickSendNoticeSubmit');
            store.dispatch('notification/VISIBLE_NOTIFICATION', {
              message: res.message,
              type: res.success,
            });
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            selectedCheckedApplicantIds.value = [];
            selectedUncheckedApplicantIds.value = [];
          } else if (payload.choice === 1) {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          }
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    const onClickCreateNewNotice = () => {
      const selectedApplicantIds = selectedCheckedApplicantIds.value.concat(
        selectedUncheckedApplicantIds.value,
      );
      if (selectedApplicantIds.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者を選択してください',
          type: false,
        });
        return;
      }
      const selectedApplicants = applicantList.value.filter(applicant =>
        selectedApplicantIds.includes(applicant.id),
      );
      context.emit('onClickCancel');
      context.emit('openCreateModalAndSetApplicants', selectedApplicants);
      selectedCheckedApplicantIds.value = [];
      selectedUncheckedApplicantIds.value = [];
    };
    const changeSelectedCheckedApplicantsIds = selectedApplicantIds => {
      selectedCheckedApplicantIds.value = [...selectedApplicantIds];
    };
    const changeSelectedUncheckedApplicantsIds = selectedApplicantIds => {
      selectedUncheckedApplicantIds.value = [...selectedApplicantIds];
    };
    const sendStatus = item => {
      if (item.announced_at !== null) return '済み';
      if (item.is_active) return 'する';
      if (!item.is_active) return 'しない';
      return '';
    };

    return {
      applicantList,
      selectedCheckedApplicantIds,
      selectedUncheckedApplicantIds,
      // computed
      isExistAnnouncedAt,
      needToResendNotice,
      checkedApplicants,
      uncheckedApplicants,
      // methods
      onClickCancel,
      onClickOpenEditModal,
      onClickOpenDeleteDialog,
      onClickSendNoticeSubmit,
      onClickCreateNewNotice,
      changeSelectedCheckedApplicantsIds,
      changeSelectedUncheckedApplicantsIds,
      sendStatus,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
@include common_table_style(
  $col_1: 180px,
  $col_1_ta: left,
  $col_2: 20%,
  $col_2_ta: left,
  $col_3: 20%,
  $col_3_ta: left,
  $col_4: 20%,
  $col_4_ta: left,
  $col_5: 5%,
  $col_5_ta: left,
  $col_6: 5%,
  $col_6_ta: left
);

.rich_heading {
  font-size: 1.3rem;
  font-weight: bold;
  color: #1899d6;
  padding-bottom: 1rem;
  text-align: left;
}

.input_tr {
  margin-bottom: 30px;
  .input_block {
    display: block;
    margin-bottom: 5px;
  }
}

.setting_input {
  appearance: none;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.4rem;
  border: 2px solid #9d9d9d;
  background: #fff;
  position: relative;
  /* cursor: pointer; */
  &:checked {
    &::after {
      content: url('../../../../assets/img/check.svg');
      height: 1.1rem;
      width: 1.5rem;
      margin: -5px 0 0 1px;
      position: absolute;
      bottom: 3px;
      left: 0;
    }
  }
}

.url_read {
  padding: 15px 20px;
}

.button_block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  .btn + .btn {
    margin-left: 10px;
  }
}

@media (max-width: ($media_query_sp)) {
  .button_block {
    display: block;
    .btn {
      width: 100%;
    }
    .btn + .btn {
      margin: 10px 0 0;
    }
  }
}

.content {
  @include full_modal;

  .modal-body {
    .selection-info {
      text-align: left;
      background: #fff;
      padding: 10px;
      position: relative;
      border-radius: 0.3rem;
      & :deep(.area-table) {
        margin: 3rem 0 0;
        width: 100%;
        border: 1px solid $border_color;
        border-top: none;
        box-shadow: $box_shadow;
      }
    }
    .selection-info_header_title {
      background-color: #f2f9fc;
      padding: 15px 20px;
      font-size: 1.4rem;
      font-weight: bold;
      color: #1899d6;
      margin-bottom: 20px;
    }
  }
  .modal-body_btn_item.disabled {
    cursor: default;
  }
}

@media (max-width: ($media_query_tablet)) {
  .content .modal-body .selection-info_notice {
    margin: 20px 0 40px;
  }
}

@media (max-width: ($media_query_sp)) {
  .content .modal-body .selection-info_notice {
    display: block;
  }
  .content .modal-body .selection-info_notice .notice__content {
    width: 100%;
  }
  .content .modal-body .selection-info_notice .notice__content:nth-child(2) {
    border-top: 3px solid #f0f0f0;
    border-left: none;
  }
}

.is_mail {
}
.is_line {
}

.mail_icon_svg {
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.mail_icon_svg_path {
  fill: #d28300;
}

.line_icon_svg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.line_icon_svg_path {
  fill: #1dcd00;
}

.notice__header {
  width: 100%;
}

.notice__title {
  margin: 0 0 10px;
}

.notice__title.is_mail {
  color: #d28300;
  font-weight: bold;
  margin-bottom: 35px;
  font-size: 1.3rem;
  margin-bottom: 40px;
}

@media (max-width: ($media_query_sp)) {
  .notice__title.is_mail {
    margin-bottom: 10px;
  }
}

.notice__title.is_line {
  color: #43a047;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice_title {
  font-size: 1.3rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.notice__description {
  margin-bottom: 10px;
}

.notice_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.notice_block + .notice_block {
  margin-top: 20px;
}

.notice_form-input {
  background: #fff;
  padding: 10px;
  border: 1px solid #90a4ae;
  border-radius: 3px;
  color: #333;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
}

.notice_form-textarea {
  background: $white;
  padding: 10px;
  border: 1px solid #90a4ae;
  border-radius: 3px;
  color: #333;
  line-height: 1.8;
  min-height: 150px;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
}

.notice_form-textarea.line {
  min-height: 220px;
}

.notice_caution {
  margin-top: 10px;
}

.notice_caution-text {
  margin-bottom: 10px;
  &:before {
    content: '※';
    padding-right: 5px;
  }
}

.notice_content_confirm-atena {
  margin-bottom: 10px;
}

.notice_content_confirm-sign {
  margin-top: 10px;
}

.linechat_block_input-area {
  border-radius: 0px 0px 4px 4px;
  .linechat_block_input-area_header {
    .emoji-picker-wrapper {
      position: relative;
    }
  }
  .linechat_block_input-area_body {
    textarea {
      border-radius: 0.4rem;
      border: 1px solid;
      border-color: #808080;
      padding: 1em;
      font-size: 1.3rem;
      line-height: 1.6em;
      height: 10rem;
      background-color: #fff;
      margin-bottom: 1rem;
    }
    .btn-send-confirm {
      display: inline-block;
      width: 100%;
      height: 3.2rem;
      line-height: 30px;
      border-radius: 0.4rem;
      border-color: #027db7;
      background: #1899d6;
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
      color: white;
      cursor: pointer;
    }
  }
}

img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}

.sec_notice_history {
  background: #fff;
  border-radius: 0.3rem;
  margin: 2rem;
  padding: 2rem;
}

.send_applicant_list_none {
  padding: 20px;
}

.send_applicant_list {
  .send_applicant_list_title {
    font-weight: bold;
    font-size: 1.3rem;
    padding-left: 20px;
    margin-bottom: 10px;
    span {
      font-weight: normal;
    }
  }
  .send_applicant_list_content {
    border: 1px solid #90a4ae;
    border-radius: 0.3rem;
    padding: 1rem;
    overflow-y: auto;
    height: 16rem;
  }
  .applicants {
    line-height: 1.8rem;
  }
  .applicants:not(:last-child):after {
    content: '、';
  }
}

.announce_information {
  display: flex;
  justify-content: flex-start;
  font-size: 1.3rem;
  padding: 20px 20px 40px;
  dl {
    margin-right: 40px;
  }
  dl:last-of-type {
    margin-right: 0;
  }
  dt {
    display: inline-block;
    font-weight: bold;
    margin-right: 16px;
    margin-bottom: 5px;
  }
  dd {
    display: inline-block;
  }
}

@media (max-width: ($media_query_tablet)) {
  .announce_information {
    dl {
      margin-right: 20px;
    }
    dt {
      display: block;
    }
    dd {
      display: block;
    }
  }
}

.btn-col2 {
  width: 90%;
  margin: 20px auto 0;
}

@media (max-width: ($media_query_sp)) {
  .announce_information {
    display: block;
    padding-bottom: 30px;
    dl {
      margin: 0 0 10px 0;
    }
  }
}

.notice_create {
  background: $mochica_color;
  padding: 0 2rem;
  margin-right: 1rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $mochica_border_color;
  height: 3.5rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  span {
    color: $white;
  }
}

.notice_table {
  margin-bottom: 50px;
}

.notice_create_bottom {
  display: flex;
  justify-content: center;
}

.notice_create_bottom_btn {
  &.full-size-btn {
    width: 100%;
    margin: 0;
  }
  background-color: #efefef;
  width: 50%;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.4rem;
  letter-spacing: 0.08em;
  margin-right: 2rem;
}

.notice_create_bottom_btn_sent_btn {
  background: #1899d6;
  color: #fff;
  font-weight: bold;
  width: 50%;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.4rem;
  letter-spacing: 0.08em;
}

.mochica_button_blue {
  background: $mochica_color;
  padding: 0 2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $mochica_border_color;
  height: 3.5rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $white;
  }
}

.mochica_button_red {
  background: $mochica_color_red;
  padding: 0 2rem;
  margin-right: 1rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $error-border;
  height: 3.5rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $white;
  }
}

.send_applicant_list_title.-error {
  margin-bottom: 10px;
}

.input_line_and_email {
  margin: 0 20px 40px;
}

@media (max-width: ($media_query_tablet)) {
  .send_applicant_list_title.-error {
    padding-left: 20px;
  }

  .input_line_and_email {
    margin: 0 0 40px;
  }
}
</style>
