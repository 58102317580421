<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                リッチメッセージ送信履歴
                <img
                  class="breadcrumb"
                  src="@/assets/img/arrow_right_blue.svg"
                  alt
                />
                リッチメッセージ詳細
              </div>
            </div>
            <div class="modal-header_cancell" @click="$emit('onClickCancel')">
              <img slot="icon" src="@/assets/img/close_black.svg" alt />
            </div>
          </section>
          <section class="rich_area">
            <div v-if="!item.announced_at" class="delete_btn_area">
              <span>
                内容を編集するには一度取り消して
                <br />
                再予約する必要があります。
              </span>
              <button
                class="btn btn-unavailable"
                @click="isVisibleModalDeleteRichMessageReminder = true"
              >
                <span>予約送信の取消</span>
              </button>
              <modal-window
                :is-visible="isVisibleModalDeleteRichMessageReminder"
                :is-loading="isLoading"
                class="delete-rich-message-reminder-modal"
                title="リッチメッセージの予約送信の取消"
                message="リッチメッセージの予約送信を取り消しますか？"
                button-text="予約送信を取消"
                @click="deleteRichMessageRecord"
                @close="isVisibleModalDeleteRichMessageReminder = false"
              />
            </div>
            <div class="selection-info_header_title">送信状況</div>
            <div class="announce_information">
              <dl v-if="item.announced_at">
                <dt>送信日時</dt>
                <dd>
                  {{
                    $utils.parseDateTime(
                      item.announced_at,
                      'YYYY年MM月DD日 HH:mm',
                    )
                  }}
                </dd>
              </dl>
              <dl v-else>
                <dt>予約送信日時</dt>
                <dd>
                  {{
                    $utils.parseDateTime(
                      item.schedule_time,
                      'YYYY年MM月DD日 HH:mm',
                    )
                  }}
                </dd>
              </dl>
              <dl>
                <dt>送信者</dt>
                <dd>{{ `${item.staff.lastname} ${item.staff.firstname}` }}</dd>
              </dl>
            </div>
            <div class="selection-info_header_title">送信内容</div>
            <div class="rich_main flexbox">
              <div class="rich_image">
                <div
                  v-show="richMessageObj.image_type == 1"
                  class="rich_image-inner"
                >
                  <a
                    class="rich_share_item share1_a"
                    :href="richMessageObj.link_a"
                    target="_blank"
                  >
                    <span>A</span>
                  </a>
                </div>
                <div
                  v-show="richMessageObj.image_type == 2"
                  class="rich_image-inner"
                >
                  <a
                    class="rich_share_item share2_a"
                    :href="richMessageObj.link_a"
                    target="_blank"
                  >
                    <span>A</span>
                  </a>
                  <a
                    class="rich_share_item share2_b"
                    :href="richMessageObj.link_b"
                    target="_blank"
                  >
                    <span>B</span>
                  </a>
                </div>
                <div
                  v-show="richMessageObj.image_type == 3"
                  class="rich_image-inner"
                >
                  <a
                    class="rich_share_item share3_a"
                    :href="richMessageObj.link_a"
                    target="_blank"
                  >
                    <span>A</span>
                  </a>
                  <a
                    class="rich_share_item share3_b"
                    :href="richMessageObj.link_b"
                    target="_blank"
                  >
                    <span>B</span>
                  </a>
                  <a
                    class="rich_share_item share3_c"
                    :href="richMessageObj.link_c"
                    target="_blank"
                  >
                    <span>C</span>
                  </a>
                </div>
                <div
                  v-show="richMessageObj.image_type == 4"
                  class="rich_image-inner"
                >
                  <a
                    class="rich_share_item share4_a"
                    :href="richMessageObj.link_a"
                    target="_blank"
                  >
                    <span>A</span>
                  </a>
                  <a
                    class="rich_share_item share4_b"
                    :href="richMessageObj.link_b"
                    target="_blank"
                  >
                    <span>B</span>
                  </a>
                  <a
                    class="rich_share_item share4_c"
                    :href="richMessageObj.link_c"
                    target="_blank"
                  >
                    <span>C</span>
                  </a>
                  <a
                    class="rich_share_item share4_d"
                    :href="richMessageObj.link_d"
                    target="_blank"
                  >
                    <span>D</span>
                  </a>
                </div>
                <img
                  class="rich_image_bind"
                  :src="`${richMessageObj.image_url}/700`"
                  width="478"
                  height="478"
                  alt
                />
              </div>
              <div class="rich_share">
                <p class="rich_heading">リッチメッセージのリンク分割方法</p>
                <ul>
                  <li :class="richMessageObj.image_type === 1 ? 'checked' : ''">
                    <label for="share01">
                      <input
                        id="share01"
                        v-model="richMessageObj.image_type"
                        disabled
                        type="radio"
                        name="share"
                        value="1"
                      />
                      <img
                        src="@/components/page/richmessage/assets/img/rich_share_01.png"
                        width="60"
                        height="60"
                        alt
                      />
                      リンクを分割しない
                    </label>
                  </li>
                  <li :class="richMessageObj.image_type === 2 ? 'checked' : ''">
                    <label for="share02">
                      <input
                        id="share02"
                        v-model="richMessageObj.image_type"
                        disabled
                        type="radio"
                        name="share"
                        value="2"
                      />
                      <img
                        src="@/components/page/richmessage/assets/img/rich_share_02.png"
                        width="60"
                        height="60"
                        alt
                      />
                      リンクを2つに分割する
                    </label>
                  </li>
                  <li :class="richMessageObj.image_type === 3 ? 'checked' : ''">
                    <label for="share03">
                      <input
                        id="share03"
                        v-model="richMessageObj.image_type"
                        disabled
                        type="radio"
                        name="share"
                        value="3"
                      />
                      <img
                        src="@/components/page/richmessage/assets/img/rich_share_03.png"
                        width="60"
                        height="60"
                        alt
                      />
                      リンクを3つに分割する
                    </label>
                  </li>
                  <li :class="richMessageObj.image_type === 4 ? 'checked' : ''">
                    <label for="share04">
                      <input
                        id="share04"
                        v-model="richMessageObj.image_type"
                        disabled
                        type="radio"
                        name="share"
                        value="4"
                      />
                      <img
                        src="@/components/page/richmessage/assets/img/rich_share_04.png"
                        width="60"
                        height="60"
                        alt
                      />
                      リンクを4つに分割する
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="rich_bottom">
              <p class="rich_heading">タイトル</p>
              <div class="input_tr">
                <input
                  v-model="richMessageObj.name"
                  readonly
                  type="text"
                  name="richmessage_name"
                  class="richmessage_name input_block"
                />
              </div>

              <p class="rich_heading">スマートフォンロック画面メッセージ</p>
              <div class="input_tr">
                <div class="line_alt_text-inputarea">
                  <input
                    v-model="richMessageObj.line_alt_text"
                    readonly
                    type="text"
                    name="richmessage_alt_text"
                    class="richmessage_alt_text input_block"
                  />
                </div>
              </div>

              <p class="rich_heading">メッセージ確認</p>
              <div class="input_tr">
                <!-- <input
                disabled
                type="checkbox"
                v-model="richMessageObj.richmessage_check"
              class="setting_input">-->
                <span>
                  {{ richMessageObj.richmessage_check ? 'あり' : 'なし' }}
                </span>
              </div>

              <p class="rich_heading">メッセージ</p>
              <div class="input_tr">
                <textarea
                  v-model="richMessageObj.push_message"
                  readonly
                  name="richmessage_push"
                  class="richmessage_push_textarea"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <ul class="link-list">
                <li class="item">
                  <div class="row">
                    <p class="link-label -linkA">リンクA</p>
                    <input
                      v-model="richMessageObj.link_a"
                      readonly
                      type="text"
                      name="input_link_a"
                      class="input_block"
                    />
                  </div>
                  <p v-if="richMessageObj.link_a" class="url">
                    <a target="_blank" :href="richMessageObj.link_a">
                      {{ richMessageObj.link_a }}
                    </a>
                  </p>
                </li>
                <li v-show="richMessageObj.image_type != 1" class="item">
                  <div class="row">
                    <p class="link-label -linkB">リンクB</p>
                    <input
                      v-model="richMessageObj.link_b"
                      readonly
                      type="text"
                      name="input_link_b"
                      class="input_block"
                    />
                  </div>
                  <p v-if="richMessageObj.link_b" class="url">
                    <a target="_blank" :href="richMessageObj.link_b">
                      {{ richMessageObj.link_b }}
                    </a>
                  </p>
                </li>
                <li
                  v-show="
                    richMessageObj.image_type != 1 &&
                    richMessageObj.image_type != 2
                  "
                  class="item"
                >
                  <div class="row">
                    <p class="link-label -linkC">リンクC</p>
                    <input
                      v-model="richMessageObj.link_c"
                      type="text"
                      name="input_link_c"
                      class="input_block"
                    />
                  </div>
                  <p v-if="richMessageObj.link_c" class="url">
                    <a target="_blank" :href="richMessageObj.link_c">
                      {{ richMessageObj.link_c }}
                    </a>
                  </p>
                </li>
                <li v-show="richMessageObj.image_type == 4" class="item">
                  <div class="row">
                    <p class="link-label -linkD">リンクD</p>
                    <input
                      v-model="richMessageObj.link_d"
                      type="text"
                      name="input_link_d"
                      class="input_block"
                    />
                  </div>
                  <p v-if="richMessageObj.link_d" class="url">
                    <a target="_blank" :href="richMessageObj.link_d">
                      {{ richMessageObj.link_d }}
                    </a>
                  </p>
                </li>
              </ul>
            </div>
            <template v-if="richMessageObj.richmessage_check">
              <div class="send_applicant_list">
                <p class="send_applicant_list_title">メッセージ確認済応募者</p>
                <selectable-applicant-table
                  v-if="applicantIds.length"
                  :invisible-columns="invisibleColumns"
                  :applicants="checkedApplicants"
                  :is-selectable="true"
                  @onChangeSort="onChangeSort"
                  @onChangeCurrentSelect="
                    selectedCheckedApplicantIds = [...$event.selectedApplicants]
                  "
                />
              </div>
              <div class="send_applicant_list">
                <p class="send_applicant_list_title">メッセージ未確認応募者</p>
                <selectable-applicant-table
                  v-if="applicantIds.length"
                  :invisible-columns="invisibleColumns"
                  :applicants="uncheckedApplicants"
                  :is-selectable="true"
                  @onChangeSort="onChangeSort"
                  @onChangeCurrentSelect="
                    selectedUncheckedApplicantIds = [
                      ...$event.selectedApplicants,
                    ]
                  "
                />
              </div>
            </template>
            <div v-else class="send_applicant_list">
              <p class="send_applicant_list_title">送信先応募者</p>
              <selectable-applicant-table
                v-if="applicantIds.length"
                :invisible-columns="invisibleColumns"
                :applicants="applicants"
                :is-selectable="true"
                @onChangeSort="onChangeSort"
                @onChangeCurrentSelect="
                  selectedUncheckedApplicantIds = [...$event.selectedApplicants]
                "
              />
            </div>
          </section>
          <div class="btn-box">
            <button class="btn btn-unavailable" @click="$emit('onClickCancel')">
              <span>閉じる</span>
            </button>
            <button class="btn btn-availability" @click="onClickCreateAnnounce">
              <span>選択された応募者に新規連絡事項を作成する</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  nextTick,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import applicantService from '@/services/applicants';
import richMessageService from '@/services/richmessage';

import Breakpoints from '@/defines/breakpoints';
import {
  getInitSearchSurveyForRequest,
  getInitSearchFlagForRequest,
} from '@/models/applicant-search';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';
import Modal from '@/components/ui/modals/components/Modal';

export default defineComponent({
  name: 'ModalRichMessageView',
  components: { SelectableApplicantTable, Modal },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    richMessageObj: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['onClickCancel'],
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const invisibleColumns = ref([]);
    const applicants = ref([]);
    const applicantIds = ref([]);
    const initSelectedApplicantIds = ref([]);
    const richmessageChecks = ref([]);
    const selectedCheckedApplicantIds = ref([]);
    const selectedUncheckedApplicantIds = ref([]);
    const isVisibleModalDeleteRichMessageReminder = ref(false);
    const isLoading = ref(false);
    const timeoutId = ref(null);

    // computed
    const checkedApplicants = computed(() => {
      return applicants.value.filter(x => x.richmessageCheck);
    });
    const uncheckedApplicants = computed(() => {
      return applicants.value.filter(x => !x.richmessageCheck);
    });

    // methods
    const fetchDetail = async () => {
      const result = await richMessageService.fetchRichMessageRecord(
        props.item.id,
      );
      richmessageChecks.value = result.applicants.map(e => ({
        applicant_id: e.applicant_id,
        richmessage_check: e.richmessage_check,
      }));
      applicantIds.value = result.applicants.map(e => e.applicant_id);
    };
    const fetchApplicants = async sort => {
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      if (!graduatedYear) return;
      const page = 1;
      const load = 5000;
      // ID指定応募者のみ検索
      const searchBody = { target_ids: applicantIds.value };
      // 応募者(LINE+メール) + LINE友達対象
      const searchFlags = {
        ...getInitSearchFlagForRequest(),
        is_all_applicants: true,
      };
      const searchSurvey = getInitSearchSurveyForRequest();
      const res = await applicantService.fetchApplicantsSearch(
        graduatedYear.year,
        page,
        load,
        searchBody,
        searchFlags,
        sort,
        searchSurvey,
        true, // isExceptGraduated（卒年を無視）
      );
      applicants.value = res.applicants.map(applicant => {
        const target = richmessageChecks.value.find(
          v => applicant.id === v.applicant_id,
        );
        return {
          ...applicant,
          richmessageCheck: target ? target.richmessage_check : false,
        };
      });
    };
    const deleteRichMessageRecord = async ({ selected }) => {
      if (selected !== 'submit') {
        isVisibleModalDeleteRichMessageReminder.value = false;
        return;
      }
      isLoading.value = true;
      const res = await richMessageService.deleteRichMessageRecord(
        props.item.id,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoading.value = false;
      isVisibleModalDeleteRichMessageReminder.value = false;
      if (res.success === true) {
        context.emit('reset');
        nextTick(() => context.emit('onClickCancel'));
      }
    };
    const onChangeSort = async sort => {
      if (sort) await fetchApplicants(sort);
    };
    const onClickCreateAnnounce = () => {
      const selectedApplicantIds = selectedCheckedApplicantIds.value.concat(
        selectedUncheckedApplicantIds.value,
      );
      if (selectedApplicantIds.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者が選択されていません',
          type: false,
        });
        return;
      }
      // 選択済み応募者をページを跨ぎを渡すためstoreに一時的に保持（遷移後すぐにリセットさせる）
      store.dispatch(
        'announce/SET_CREATE_ANNOUNCE_TARGET_APPLICANTS',
        applicants.value.filter(x => selectedApplicantIds.includes(x.id)),
      );
      router.push({ name: 'NoticeAnnounce' });
      selectedCheckedApplicantIds.value = [];
      selectedUncheckedApplicantIds.value = [];
    };
    const getInvisibleColumns = () => {
      const baseInvisibleColumns = [
        'attendanceAt',
        'reminderCheck',
        'not_announe_lamp',
        'attendanceDate',
        'finishedSendedAt',
        'sendStatusDescription',
        'sendedAt',
        'finishedSendStatusDescription',
        'currentStatus',
        'faculty',
        'department',
        'department_category',
        'email',
        'tel',
        'channel',
        'invited_status',
        'invited_at',
        'created_at',
        'activated_at',
        'finished_send_status_description',
        'finished_sended_at',
        'send_status_description',
        'sended_at',
        'unread_count',
      ];
      invisibleColumns.value =
        window.innerWidth <= Breakpoints.MAX_SP_SIZE
          ? baseInvisibleColumns.concat([
              'event_title',
              'name_kana',
              'gender',
              'school',
              'staff',
            ])
          : baseInvisibleColumns;
    };
    const onResize = () => {
      if (timeoutId.value) return;
      timeoutId.value = setTimeout(() => {
        timeoutId.value = 0;
        getInvisibleColumns();
      }, 500);
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchDetail();
      await fetchApplicants();
      getInvisibleColumns();
    });
    onMounted(() => {
      window.addEventListener('resize', onResize);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', onResize);
    });

    return {
      invisibleColumns,
      applicants,
      applicantIds,
      initSelectedApplicantIds,
      richmessageChecks,
      selectedCheckedApplicantIds,
      selectedUncheckedApplicantIds,
      isVisibleModalDeleteRichMessageReminder,
      isLoading,
      checkedApplicants,
      uncheckedApplicants,
      deleteRichMessageRecord,
      onChangeSort,
      onClickCreateAnnounce,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.selection-info_header_title {
  background-color: #f2f9fc;
  padding: 15px 20px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #1899d6;
  margin-bottom: 20px;
}

.announce_information {
  display: flex;
  justify-content: flex-start;
  font-size: 1.3rem;
  padding: 20px 20px 40px;
  dl {
    margin-right: 40px;
  }
  dl:last-of-type {
    margin-right: 0;
  }
  dt {
    display: inline-block;
    font-weight: bold;
    margin-right: 16px;
    margin-bottom: 5px;
  }
  dd {
    display: inline-block;
  }
}

.applicant_error_message {
  color: #bf0000;
}

@media (max-width: ($media_query_tablet)) {
  .announce_information {
    dl {
      margin-right: 20px;
    }
    dt {
      display: block;
    }
    dd {
      display: block;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .announce_information {
    display: block;
    padding-bottom: 30px;
    dl {
      margin: 0 0 10px 0;
    }
  }
}

.delete_btn_area {
  margin-bottom: 20px;
  display: flex;
  > span {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: 16px;
    padding-left: 1em;
    font-size: 12px;
    line-height: 140%;
    opacity: 0.5;
    &:before {
      content: '※';
      position: absolute;
      top: 0;
      left: 0;
      width: 12px;
      height: 12px;
    }
  }
  > .btn-unavailable {
    padding: 10px 12px 10px 22px;
    width: 142px;
    background-image: url('../../../../../assets/img/icon_delete.svg');
    background-size: auto 14px;
    background-position: 12px 10px;
    background-repeat: no-repeat;
  }
}

.setting_input {
  appearance: none;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.3rem;
  border-radius: 0.4rem;
  border: 2px solid #9d9d9d;
  background: #fff;
  position: relative;
  /* cursor: pointer; */
  &:checked {
    &::after {
      content: url('../../../../../assets/img/check.svg');
      height: 1.1rem;
      width: 1.5rem;
      margin: -5px 0 0 1px;
      position: absolute;
      bottom: 3px;
      left: 0;
    }
  }
}

.content {
  @include full_modal;
  overflow-y: auto;
  .modal-body {
    padding-bottom: 40px;
    // text-align: start;
    & :deep(.area-table) {
      margin: 2rem 0 0;
    }
  }
  .modal-body_btn_item.disabled {
    cursor: default;
  }
}

.body {
  padding: 0 20px;
}

img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}

.rich_area {
  max-width: 1200px;
  padding: 30px;
  margin: 80px auto 0;
  text-align: left;
  background-color: #fff;
  .delete-rich-message-reminder-modal {
    :deep(.content) {
      width: 100%;
    }
  }
}
@media (max-width: ($media_query_sp)) {
  .rich_area {
    padding: 20px;
  }
}

.rich_main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0 30px;
}
@media (max-width: ($media_query_sp)) {
  .rich_main {
    display: block;
  }
}

.rich_heading {
  font-size: 1.3rem;
  font-weight: bold;
  color: #1899d6;
  padding-bottom: 1rem;
  text-align: left;
}
.input_tr {
  margin-bottom: 30px;
  .input_block {
    display: block;
    margin-bottom: 5px;
  }
}
.input-file {
  display: none;
}

.rich_image {
  position: relative;
  width: 40%;
  height: 100%;
}
@media (max-width: ($media_query_sp)) {
  .rich_image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}
.rich_image-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rich_image img {
  width: 100%;
  height: 100%;
}

.rich_share {
  width: 58%;
  ul {
    & > li {
      text-align: left;
    }
  }
}
@media (max-width: ($media_query_sp)) {
  .rich_share {
    width: 100%;
  }
}
.rich_share_item {
  position: absolute;
  display: table;
}
.rich_share_item span {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 5rem;
  color: #757575;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: 'alpha(opacity=100)';
}
.rich_image .share1_a {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.rich_image .share2_a {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
}
.rich_image .share2_b {
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
}
.rich_image .share3_a {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
}
.rich_image .share3_b {
  width: 50%;
  height: 50%;
  bottom: 0;
  left: 0;
  border-right: 1px solid #ccc;
}
.rich_image .share3_c {
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
}
.rich_image .share4_a {
  width: 50%;
  height: 50%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.rich_image .share4_b {
  width: 50%;
  height: 50%;
  top: 0;
  right: 0;
}
.rich_image .share4_c {
  width: 50%;
  height: 50%;
  bottom: 0;
}
.rich_image .share4_d {
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.rich_share {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}
.rich_share .rich_heading {
  padding: 25px;
}
.rich_share li {
  border-top: 1px solid #f0f0f0;
}
.rich_share li.checked {
  background: #efefef;
}
.rich_share li label {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
}
.rich_share li input,
.rich_share li img {
  margin-right: 15px;
}
@media (max-width: ($media_query_sp)) {
  .rich_share li img {
    width: 30px;
    height: auto;
  }
}

.richmessage_name {
  width: 100%;
  max-width: 650px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.richmessage_alt_text {
  width: 100%;
  max-width: 650px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

.richmessage_push_textarea {
  background: $white;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  line-height: 1.8;
  min-height: 150px;
}

.link-list {
  margin-bottom: 40px;
  .item + .item {
    margin-top: 20px;
  }
  .row {
    display: flex;
  }
  .input_block {
    width: 550px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
  }
  .fa-warning {
    padding-left: 80px;
  }
  .url {
    padding: 10px 0 0 80px;
    word-break: break-all;
  }
}
@media (max-width: ($media_query_sp)) {
  .link-list {
    .row {
      display: block;
    }
    .input_block {
      width: 100%;
      margin-top: 10px;
    }
    .fa-warning {
      padding-left: 0;
    }
    .url {
      padding: 10px 0 0 0;
    }
  }
}

.link-list {
  .item + .item {
    margin-top: 20px;
  }
  .row {
    display: flex;
  }
  .input_block {
    width: 550px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
  }
  .fa-warning {
    padding-left: 80px;
  }
  .url {
    padding: 10px 0 0 80px;
    word-break: break-all;
  }
}
@media (max-width: ($media_query_sp)) {
  .link-list {
    .row {
      display: block;
    }
    .input_block {
      width: 100%;
      margin-top: 10px;
    }
    .fa-warning {
      padding-left: 0;
    }
    .url {
      padding: 10px 0 0 0;
    }
  }
}

.link-label {
  white-space: nowrap;
  margin-right: 20px;
  padding: 8px;
  font-weight: bold;
}
@media (max-width: ($media_query_sp)) {
  .link-label {
    margin-right: 0;
  }
}
.link-label.-linkA {
  background: #f7f7f7;
  border: 1px solid #d8d8d8;
}
.link-label.-linkB {
  background: #e1f3fa;
  border: 1px solid #90cfef;
}
.link-label.-linkC {
  background: #faf5e1;
  border: 1px solid #edc98e;
}
.link-label.-linkD {
  background: #edfae0;
  border: 1px solid #90ee94;
}

.btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 20px auto;

  > .btn {
    display: flex;
    padding: 15px 10px;
    width: calc(50% - 5px);
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: ($media_query_sp)) {
  .btn-box {
    display: block;

    > .btn {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

.send_applicant_list {
  text-align: left;
  margin-top: 2rem;
  .send_applicant_list_title {
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom: 1rem;
  }
  .send_applicant_list_content {
    border: 1px solid #90a4ae;
    border-radius: 0.3rem;
    padding: 1rem;
    overflow-y: auto;
    height: 16rem;
  }
  .applicants {
    line-height: 1.8rem;
  }
  .applicants:not(:last-child):after {
    content: '、';
  }
}

@include validation_effects;
</style>
