<template>
  <modal :is-view="isVisible">
    <template #rightTop>
      <div class="right-top" @click="$emit('onClickCancel')">
        <img class="icon" src="@/assets/img/close_black.svg" alt />
      </div>
    </template>

    <template #content>
      <div class="content">
        <div class="body">
          <!-- breadcrumb -->
          <section v-if="isRichMessage" class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                <span>リッチメッセージ</span>
                <img
                  class="breadcrumb"
                  src="@/assets/img/arrow_right_blue.svg"
                  alt
                />
                <span>リッチメッセージを応募者に送信する</span>
                <img
                  class="breadcrumb"
                  src="@/assets/img/arrow_right_blue.svg"
                  alt
                />
                <span>応募者選択</span>
              </div>
            </div>
            <div class="modal-header_cancell" @click="$emit('onClickCancel')">
              <img class="icon" src="@/assets/img/close_black.svg" alt />
            </div>
          </section>

          <div v-if="$store.getters['page/isPageLoaded']" class="modal-body">
            <!-- 応募者選択画面 -->
            <div v-show="isOpenPreviewApplicantList !== true">
              <div v-if="isRichMessage" class="content-wrapper">
                <div class="selection-info_header_title">送信方法</div>
                <select-schedule-time
                  ref="refSelectScheduleTime"
                  @updateScheduleTime="updateScheduleTime"
                  @updateSendType="updateSendType"
                />
              </div>
              <div class="content-wrapper">
                <template v-if="isRichMessage">
                  <div class="selection-info_header_title">送信先応募者</div>
                  <p class="caption_text">
                    リッチメッセージを送信したい応募者を選択し、送信先応募者を確認を押して下さい。
                  </p>
                </template>
                <div class="selected_applicants_count">
                  <span class="title_text">選択中の人数：</span>
                  <span class="count_text">
                    {{
                      selectedAllRegisteredApplicants.length +
                      selectedAllLineFriends.length
                    }}
                    <template v-if="sendLimit > 0">/ {{ sendLimit }}</template>
                  </span>
                  名
                </div>
                <!-- <div style="line-height: 1.2">
                <p>---------- DEBUG ----------</p>
                <p>
                  allSelectedApplicantIds: {{ allSelectedApplicantIds }},
                  <br />
                </p>
                <br />
                <div v-for="(searchTab, i) in searchTabs" :key="`debug_${i}`">
                  <p>
                    [Tab{{ searchTab.id }}] selectedRegisteredApplicants:
                    {{ searchTab.selectedRegisteredApplicants.map(a => a.id) }},
                    <br />
                    selectedLineFriends:
                    {{ searchTab.selectedLineFriends.map(a => a.id) }},
                    <br />
                    disabledApplicantIds:
                    {{ searchTab.disabledApplicantIds }}
                    <br />
                  </p>
                  <br />
                </div>
              </div> -->
                <div class="search_tabs">
                  <ul>
                    <li
                      v-for="searchTab in searchTabs"
                      :key="searchTab.id"
                      :class="{ 'is-current': searchTab.isSelected }"
                      @click="changeTab(searchTab.id)"
                    >
                      <div class="search_tab">
                        <span>{{ searchTab.name }}</span>
                        <div v-if="searchTabs.length > 1">
                          <button
                            @click.stop="
                              openResetSelectedApplicantsConfirm(
                                {
                                  callback: deleteSearchTab,
                                  args: searchTab.id,
                                },
                                searchTab.id,
                                $store,
                              )
                            "
                          >
                            <img
                              v-if="searchTab.isSelected === true"
                              src="@/assets/img/icon_close_search_tab_is_current.svg"
                              alt=""
                            />
                            <img
                              v-else
                              src="@/assets/img/icon_close_search_tab.svg"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </li>
                    <li
                      v-if="searchTabs.length < MAX_SEARCH_TAB_COUNT"
                      class="is-add_button"
                      @click="addSearchTab"
                    >
                      タブを追加
                    </li>
                  </ul>
                </div>
                <div
                  v-for="searchTab in searchTabs"
                  :key="`applicant_list_${searchTab.id}`"
                >
                  <search-applicant-tab
                    v-show="
                      isLoadingTab !== true && searchTab.isSelected === true
                    "
                    :search-tab-id="searchTab.id"
                    :is-sortable="isSortable"
                    :is-scrollable="isScrollable"
                    :is-selectable="isSelectable"
                    :can-move-detail="canMoveDetail"
                    :header-additional="headerAdditional"
                    :header-pre-additional="headerPreAdditional"
                    :highlight-applicant-ids="highlightApplicantIds"
                    :invisible-columns-pc="invisibleColumnsPc"
                    :invisible-columns-sp="invisibleColumnsSp"
                  />
                </div>
              </div>
              <div class="btn-col2">
                <button
                  class="btn btn-unavailable"
                  @click="$emit('onClickCancel')"
                >
                  キャンセル
                </button>
                <button-base
                  class="btn submit-button"
                  button-text="確認"
                  @click="
                    openPreviewApplicantListModalLocal(
                      $store,
                      isSendNow,
                      sendLimit,
                    )
                  "
                />
              </div>
            </div>
            <!-- 送信プレビュー画面 -->
            <section v-if="isOpenPreviewApplicantList === true">
              <div class="content-wrapper">
                <div class="selection-info_header_title">
                  送信先応募者の確認
                </div>
                <p v-if="isRichMessage" class="title_caption">
                  以下の応募者にリッチメッセージを送信します。
                  <br />
                  内容を確認して、送信ボタンを押してください。
                </p>
                <template v-if="isRichMessage">
                  <p class="selection-info_head"><span>送信方法</span></p>
                  <p v-if="isSendNow === true">すぐに送信する</p>
                  <p v-else>
                    予約送信する（{{
                      $utils.parseDateTime(scheduleTime, 'YYYY/MM/DD HH:mm')
                    }}予定）
                  </p>
                </template>
                <p class="selection-info_head"><span>送信先応募者</span></p>
                <div class="selected_applicants_count">
                  <span class="title_text">選択中の人数：</span>
                  <span class="count_text">
                    {{
                      selectedAllRegisteredApplicants.length +
                      selectedAllLineFriends.length
                    }}
                    <template v-if="sendLimit > 0">/ {{ sendLimit }}</template>
                  </span>
                  名
                </div>
                <search-applicant-tab-preview
                  :send-limit="sendLimit"
                  :registered-applicants="selectedAllRegisteredApplicants"
                  :line-friends="selectedAllLineFriends"
                />
              </div>
              <div class="btn-col2">
                <button
                  class="btn btn-unavailable"
                  @click="closePreviewApplicantListModal()"
                >
                  キャンセル
                </button>
                <button-base
                  class="btn submit-button"
                  :button-text="isSendNow === true ? '今すぐ送信' : '予約送信'"
                  :is-loading="isLoadingSend"
                  @click="
                    isSendNow === true ? onClickSend() : onClickCreateReminder()
                  "
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, onBeforeMount, ref, provide } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useSearchApplicantTab } from '@/components/features/searchApplicantTab/composables/useSearchApplicantTab';
import RichMessageService from '@/services/richmessage';
import Modal from '@/components/ui/modals/components/Modal';
import SelectScheduleTime from '@/components/features/searchApplicantTab/components/SelectScheduleTime';
import SearchApplicantTab from '@/components/features/searchApplicantTab/components/SearchApplicantTab';
import SearchApplicantTabPreview from '@/components/features/searchApplicantTab/components/SearchApplicantTabPreview';
import { postPottosEvent } from '@/utils/pottos';

export default defineComponent({
  name: 'SearchApplicantTabs',
  components: {
    Modal,
    SelectScheduleTime,
    SearchApplicantTab,
    SearchApplicantTabPreview,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    sendLimit: {
      type: Number,
      default: 0,
    },
    isRichMessage: {
      type: Boolean,
      default: false,
    },
    richMessageId: {
      type: Number,
      default: null,
    },
    isSortable: {
      type: Boolean,
      default: true,
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    isAllApplicants: {
      type: Boolean,
      default: false,
    },
    isOnlyApplicantsOfLine: {
      type: Boolean,
      default: false,
    },
    canMoveDetail: {
      type: Boolean,
      default: true,
    },
    headerAdditional: {
      type: Array,
      default: () => [],
    },
    headerPreAdditional: {
      type: Array,
      default: () => [],
    },
    highlightApplicantIds: {
      type: Array,
      default: () => [],
    },
    invisibleColumnsPc: {
      type: Array,
      default: () => null,
    },
    invisibleColumnsSp: {
      type: Array,
      default: () => null,
    },
  },
  emits: ['resetModalStates'],
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const refSelectScheduleTime = ref(null);
    // 送信中フラグ
    const isLoadingSend = ref(false);
    // 即時送信フラグ
    const isSendNow = ref(false);
    // 予約送信日時
    const scheduleTime = ref('');

    // composable
    const {
      MAX_SEARCH_TAB_COUNT,
      isLoadingTab,
      isOpenPreviewApplicantList,
      searchTabs,
      selectedAllRegisteredApplicants,
      selectedAllLineFriends,
      allSelectedApplicantIds,
      saveSearchFilterObject,
      // methods
      addSearchTab,
      deleteSearchTab,
      changeTab,
      openPreviewApplicantListModal,
      closePreviewApplicantListModal,
      openResetSelectedApplicantsConfirm,
      onChangeActivated,
      updateSelectedApplicantsInSearchTab,
    } = useSearchApplicantTab();
    provide('useSearchApplicantTabKey', {
      searchTabs,
      saveSearchFilterObject,
      openResetSelectedApplicantsConfirm,
      onChangeActivated,
      updateSelectedApplicantsInSearchTab,
    });

    // methods
    // 予約送信・即時送信切替
    const updateSendType = val => {
      isSendNow.value = val === 'send_now' ? true : false;
    };
    // 予約送信日時更新
    const updateScheduleTime = val => {
      scheduleTime.value = val;
    };
    // 即時送信
    const sendRichMessage = async () => {
      // リッチメッセージ即時送信
      if (isLoadingSend.value === true) return;
      isLoadingSend.value = true;
      postPottosEvent(29);
      const res = await RichMessageService.sendRichMessage(
        props.richMessageId,
        {
          search_filters: searchTabs.value
            // 応募者が選択されたタブのみ送信
            .filter(
              tab =>
                tab.selectedRegisteredApplicants.length > 0 ||
                tab.selectedLineFriends.length > 0,
            )
            .map(tab => ({
              // 検索条件を送信してDBに保存する
              search_filter: tab.searchFilter,
              applicant_ids: tab.selectedRegisteredApplicants
                .concat(tab.selectedLineFriends)
                .map(a => a.id),
            })),
        },
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success) {
        context.emit('resetModalStates');
        router.push({ name: 'RichmessageHistory' });
      }
      isLoadingSend.value = false;
    };
    // 予約送信
    const createReminder = async () => {
      // リッチメッセージ予約送信
      if (isLoadingSend.value === true) return;
      isLoadingSend.value = true;
      postPottosEvent(29);
      const res = await RichMessageService.createRichMessageRecord({
        rich_message_id: props.richMessageId,
        schedule_time: scheduleTime.value,
        search_filters: searchTabs.value
          .filter(
            tab =>
              tab.selectedRegisteredApplicants.length > 0 ||
              tab.selectedLineFriends.length > 0,
          )
          .map(tab => ({
            search_filter: tab.searchFilter,
            applicant_ids: tab.selectedRegisteredApplicants
              .concat(tab.selectedLineFriends)
              .map(a => a.id),
          })),
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoadingSend.value = false;
      if (res.success) router.push({ name: 'RichmessageHistory' });
    };
    // 即時送信クリック時のイベント
    const onClickSend = async () => {
      if (isLoadingSend.value === true) return;
      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: 'リッチメッセージの送信',
        message: '応募者にリッチメッセージを送信しますか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          if (payload.choice === 2) await sendRichMessage();
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    // 予約送信クリック時のイベント
    const onClickCreateReminder = async () => {
      if (isLoadingSend.value === true) return;
      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: 'リッチメッセージの予約送信',
        message: 'リッチメッセージ予約送信を設定しますか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          if (payload.choice === 2) await createReminder();
        },
        onclickOuter: async () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    // 送信予約日時のバリデーション
    const openPreviewApplicantListModalLocal = args => {
      // 即時送信の場合はバリデーションなし
      if (isSendNow.value === true) return openPreviewApplicantListModal(args);
      if (refSelectScheduleTime.value.validateSendScheduleTime() !== true) {
        return;
      }
      return openPreviewApplicantListModal(args);
    };

    onBeforeMount(async () => {
      await store.dispatch('page/SET_LOADED');
    });

    return {
      MAX_SEARCH_TAB_COUNT,
      refSelectScheduleTime,
      isLoadingTab,
      isLoadingSend,
      isOpenPreviewApplicantList,
      isSendNow,
      scheduleTime,
      searchTabs,
      selectedAllRegisteredApplicants,
      selectedAllLineFriends,
      allSelectedApplicantIds,
      // methods
      addSearchTab,
      deleteSearchTab,
      changeTab,
      openPreviewApplicantListModalLocal,
      closePreviewApplicantListModal,
      updateSendType,
      updateScheduleTime,
      onClickSend,
      onClickCreateReminder,
      openResetSelectedApplicantsConfirm,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
}

.modal-body {
  text-align: start;
}

img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}

.content-wrapper {
  font-size: 1.2rem;
  background: #fff;
  padding: 30px 30px 40px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.content-wrapper {
  font-size: 1.2rem;
  background: #fff;
  padding: 30px 30px 40px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.selection-info_header_title {
  background-color: #f2f9fc;
  padding: 15px 20px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #1899d6;
  margin-bottom: 20px;
}

.caption_text {
  margin-bottom: 20px;
}

.selected_applicants_count {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 20px;
  .title_text {
    font-weight: bold;
  }
  .count_text {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.search_tabs {
  width: calc(100% + 60px);
  margin: 0 -30px 20px;
  > ul {
    display: flex;
    padding: 0 30px;
    border-bottom: 4px solid #dedede;
    > li {
      width: 100px;
      padding: 12px 10px;
      margin-bottom: -4px;
      color: #777;
      background-color: #f0f0f0;
      border-bottom: 4px solid #dedede;
      cursor: pointer;
      &.is-current {
        border-bottom: 4px solid $mochica_color;
        font-weight: bold;
        color: $mochica_color;
        background-color: #e8f5fb;
        .search_tab {
          button {
            > img {
              padding-right: 0;
            }
          }
        }
      }
      &.is-add_button {
        width: 200px;
        padding: 14px 0 0 32px;
        font-weight: bold;
        border-bottom: 4px solid #dedede;
        background-image: url('../../../../assets/img/icon_add_search_tab.svg');
        background-size: auto 16px;
        background-position: 10px 13px;
        background-repeat: no-repeat;
        color: $mochica_color;
        background-color: #fff;
      }
    }
  }
}

.search_tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  border-right: solid 1px #dedede;
  > span {
    padding: 3px 0;
    margin-right: auto;
  }
  button {
    height: 20px;
    padding: 1px 6px;
    margin-left: auto;
    cursor: pointer;
    > img {
      padding-right: 6px;
    }
  }
}

.selection-info_header_title {
  background-color: #f2f9fc;
  padding: 15px 20px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #1899d6;
  margin-bottom: 20px;
}

.selection-info_head {
  padding: 30px 0 10px;
  margin-bottom: 20px;
  border-bottom: 4px solid rgba(87, 149, 203, 0.16);
  > span {
    padding: 0 10px;
    display: inline-block;
    height: 18px;
    border-left: 6px solid #1899d6;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: bold;
    color: #1899d6;
  }
}

.title_caption {
  line-height: 1.7;
}

.caption_text {
  margin-bottom: 20px;
}

.selected_applicants_count {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 20px;
  .title_text {
    font-weight: bold;
  }
  .count_text {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.btn-col2 {
  margin: 25px auto;
  width: 100%;
  .submit-button {
    padding: 15px 10px;
  }
}
</style>
