<template>
  <div class="select-staffs" :class="{ 'is-calendar-page': isCalendarPage }">
    <div class="select-staffs-head">
      <div class="select-staffs-head-inner">
        <span>メニュー</span>
        <button
          v-if="isCalendarPage === true"
          class="btn-close"
          @click="$emit('closeMenu')"
        />
      </div>
    </div>
    <div class="select-staffs-options">
      <dl v-if="isCalendarPage === true">
        <dt>表示オプション</dt>
        <dd>
          <label>
            <input
              v-model="isShowExceptMySelection"
              type="checkbox"
              class="default-check"
              @change="updateOptions"
            />
            自分以外の選考を表示
          </label>
        </dd>
        <dd
          v-if="isConnectedGoogleCalendar === true && isGoogleMember === true"
        >
          <label>
            <input
              v-model="isShowGoogleEvent"
              type="checkbox"
              class="default-check"
              @change="updateOptions"
            />
            Googleイベント
          </label>
        </dd>
      </dl>
      <dl>
        <dt>表示する担当者</dt>
        <dd>
          <label>
            <input
              v-model="isShowMySelection"
              type="checkbox"
              @change="updateOptions"
            />
            {{ me.data.lastname }}{{ me.data.firstname }}
          </label>
        </dd>
      </dl>
    </div>
    <div class="select-staffs-list">
      <ul
        :class="{
          'has-calendar_resource':
            googleResourceCalendars && googleResourceCalendars.length > 0,
        }"
      >
        <li v-for="staff in staffs" :key="`staff-${staff.id}`">
          <label>
            <input
              v-model="staff.isSelected"
              type="checkbox"
              :style="{
                'border-color': staff.color,
                'background-color': staff.isSelected ? staff.color : '#fff',
              }"
              @click="selectStaff($event, staff.isSelected)"
              @change="updateOptions"
            />
            <span :class="{ 'has-edit_color': isCalendarPage === true }">
              {{ staff.lastname }}{{ staff.firstname }}
            </span>
          </label>
          <button
            v-if="isCalendarPage === true"
            @click.stop="openTooltip($event, staff.id)"
          >
            <img src="@/assets/img/icon-show_options.svg" alt />
          </button>
        </li>
      </ul>
      <template
        v-if="googleResourceCalendars && googleResourceCalendars.length > 0"
      >
        <p class="resource-head">会議室</p>
        <ul class="has-calendar_resource">
          <li
            v-for="googleResourceCalendar in googleResourceCalendars"
            :key="`calendar_resource-${googleResourceCalendar.id}`"
          >
            <label>
              <input
                v-model="googleResourceCalendar.isSelected"
                type="checkbox"
                :style="{
                  'border-color': googleResourceCalendar.color,
                  'background-color': googleResourceCalendar.isSelected
                    ? googleResourceCalendar.color
                    : '#fff',
                }"
                @click="
                  selectResource($event, googleResourceCalendar.isSelected)
                "
                @change="updateOptions"
              />
              <span :class="{ 'has-edit_color': isCalendarPage === true }">
                {{ googleResourceCalendar.summary }}
              </span>
            </label>
            <button
              v-if="isCalendarPage === true"
              @click.stop="
                openTooltipResource($event, googleResourceCalendar.id)
              "
            >
              <img src="@/assets/img/icon-show_options.svg" alt />
            </button>
          </li>
        </ul>
      </template>
    </div>
    <calendar-color-tooltip
      v-if="showedTooltipStaff"
      :selected-color="showedTooltipStaff.color"
      :calendar-colors="calendarColors"
      :tooltip-y="tooltipY"
      label="担当者"
      @selectColor="selectColor(showedTooltipStaff.id, $event)"
    />
    <calendar-color-tooltip
      v-if="showedTooltipGoogleResourceCalendar"
      :selected-color="showedTooltipGoogleResourceCalendar.color"
      :calendar-colors="calendarColors"
      :tooltip-y="tooltipY"
      label="会議室"
      @selectColor="
        selectColorResource(showedTooltipGoogleResourceCalendar.id, $event)
      "
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  nextTick,
} from 'vue';
import { useStore } from 'vuex';

import CalendarColorTooltip from '@/components/features/venueCalendar/components/CalendarColorTooltip.vue';

export default defineComponent({
  name: 'SelectStaffs',
  components: { CalendarColorTooltip },
  props: {
    isCalendarPage: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => {},
    },
    calendarColors: {
      type: Array,
      default: () => [],
    },
    isConnectedGoogleCalendar: {
      type: Boolean,
      default: false,
    },
    isGoogleMember: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateOptions', 'closeMenu'],
  setup(props, context) {
    const STAFF_LIMIT_COUNT = 3; // 表示カレンダーのスタッフ指定最大3人まで
    const store = useStore();
    const me = reactive({ data: {} });
    const staffs = ref([]);
    const googleResourceCalendars = ref([]);
    const tooltipY = ref(0);
    const isShowExceptMySelection = ref(true);
    const isShowGoogleEvent = ref(true);
    const isShowMySelection = ref(true);
    const MAX_SELECT_STAFF_LENGTH = 3;
    const MAX_SELECT_RESOURCE_LENGTH = 3;

    // computed
    const selectedStaffs = computed(() =>
      staffs.value.filter(staff => staff.isSelected),
    );
    const selectedGoogleResourceCalendars = computed(() =>
      googleResourceCalendars.value.filter(
        googleResourceCalendar => googleResourceCalendar.isSelected,
      ),
    );
    const showedTooltipStaff = computed(() =>
      staffs.value.find(v => v.isShowTooltip),
    );
    const showedTooltipGoogleResourceCalendar = computed(() =>
      googleResourceCalendars.value.find(v => v.isShowTooltip),
    );
    // watch
    watch(
      () => props.settings.staffs,
      () => {
        staffs.value = cloneDeep(props.settings.staffs);
        googleResourceCalendars.value = cloneDeep(
          props.settings.googleResourceCalendars,
        );
      },
    );

    // methods
    const openTooltip = (e, id) => {
      closeTooltips();
      closeTooltipsResource();
      nextTick(() => {
        staffs.value = staffs.value.map(staff => {
          staff.isShowTooltip = staff.id === id ? !staff.isShowTooltip : false;
          return staff;
        });
        tooltipY.value = e.pageY - 125;
      });
    };
    const closeTooltips = () => {
      staffs.value = staffs.value.map(staff => {
        staff.isShowTooltip = false;
        return staff;
      });
    };
    const openTooltipResource = (e, id) => {
      closeTooltips();
      closeTooltipsResource();
      nextTick(() => {
        googleResourceCalendars.value = googleResourceCalendars.value.map(
          resource => {
            if (resource.id === id) {
              resource.isShowTooltip =
                resource.id === id ? !resource.isShowTooltip : false;
            }
            return resource;
          },
        );
        tooltipY.value = e.pageY - 125;
      });
    };
    const closeTooltipsResource = () => {
      googleResourceCalendars.value = googleResourceCalendars.value.map(
        resource => {
          resource.isShowTooltip = false;
          return resource;
        },
      );
    };
    const selectColor = (id, color) => {
      // tooltipでの色選択時のイベント
      staffs.value = staffs.value.map(staff => {
        if (staff.id === id) staff.color = color;
        return staff;
      });
      updateOptions();
      closeTooltips();
      closeTooltipsResource();
    };
    const selectColorResource = (id, color) => {
      // tooltipでの色選択時のイベント
      googleResourceCalendars.value = googleResourceCalendars.value.map(
        resource => {
          if (resource.id === id) resource.color = color;
          return resource;
        },
      );
      updateOptions();
      closeTooltips();
      closeTooltipsResource();
    };
    const selectStaff = (e, val) => {
      let validateMaxStaffLength = null;
      if (props.isCalendarPage === true) {
        // カレンダーページの場合、自分を除くスタッフ3名のみ指定可能
        validateMaxStaffLength =
          selectedStaffs.value.length >= MAX_SELECT_STAFF_LENGTH;
      } else {
        // それ以外の画面の場合、自分を含むスタッフ3名のみ指定可能
        validateMaxStaffLength =
          selectedStaffs.value.length +
            (isShowMySelection.value === true ? 1 : 0) >=
          3;
      }
      // スタッフ選択時のバリデーション
      if (val !== true && validateMaxStaffLength) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `選択できるのは${MAX_SELECT_STAFF_LENGTH}名までです`,
          type: false,
        });
        e.preventDefault();
        return;
      }
    };
    const selectResource = (e, val) => {
      // 会議室選択時のバリデーション
      if (
        val !== true &&
        selectedGoogleResourceCalendars.value.length >=
          MAX_SELECT_RESOURCE_LENGTH
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `選択できる会議室は${MAX_SELECT_RESOURCE_LENGTH}つまでです`,
          type: false,
        });
        e.preventDefault();
        return;
      }
    };
    const updateOptions = e => {
      if (props.isCalendarPage === false) {
        // カレンダー以外の画面の場合、自分を含むスタッフ3名のみ指定可能
        if (
          isShowMySelection.value === true &&
          selectedStaffs.value.length >= STAFF_LIMIT_COUNT
        ) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: `選択できるのは${MAX_SELECT_STAFF_LENGTH}名までです`,
            type: false,
          });
          nextTick(() => (isShowMySelection.value = false));
          e.preventDefault();
          return;
        }
      }
      context.emit('updateOptions', {
        isShowExceptMySelection: isShowExceptMySelection.value,
        isShowMySelection: isShowMySelection.value,
        isShowGoogleEvent: isShowGoogleEvent.value,
        staffs: staffs.value,
        googleResourceCalendars: googleResourceCalendars.value,
      });
    };
    const setStoredOptions = async () => {
      // 保存した設定を反映
      isShowExceptMySelection.value = props.settings.isShowExceptMySelection;
      isShowMySelection.value = props.settings.isShowMySelection;
      isShowGoogleEvent.value = props.settings.isShowGoogleEvent;
      // 自分を除くスタッフの設定読み込み
      staffs.value = cloneDeep(props.settings.staffs);
      // 会議室の読み込み
      googleResourceCalendars.value = cloneDeep(
        props.settings.googleResourceCalendars,
      );
    };

    onMounted(async () => {
      // 自分のデータを取得
      me.data = cloneDeep(store.getters['staff/staff']);
      await setStoredOptions();
    });

    return {
      me,
      staffs,
      googleResourceCalendars,
      tooltipY,
      isShowExceptMySelection,
      isShowGoogleEvent,
      isShowMySelection,
      showedTooltipStaff,
      showedTooltipGoogleResourceCalendar,
      openTooltip,
      closeTooltips,
      openTooltipResource,
      closeTooltipsResource,
      selectColor,
      selectColorResource,
      selectStaff,
      selectResource,
      setStoredOptions,
      updateOptions,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.select-staffs {
  width: 208px;
  min-width: 208px;
  max-width: 208px;
  height: 100%;
  margin-right: -1px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.is-calendar-page {
  .select-staffs {
    border-bottom: none;
  }
  .select-staffs-list {
    display: flex;
    position: relative;
    flex-flow: column;
    // 高さは月表示カレンダーの高さに合わせているの変更時は注意
    height: 696.2px;
    min-height: 696.2px;
    max-height: 696.2px;
  }
}
.resource-head {
  margin: 0 12px 12px;
  padding-top: 22px;
  font-size: 12px;
  font-weight: bold;
  color: #777;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.select-staffs-head {
  height: 40px;
  line-height: 1;
  padding: 0 12px;
  color: #777;
  font-weight: bold;
  background-color: #f5f5f5;
}

.select-staffs-head-inner {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  > span {
    margin-right: auto;
  }
  > button {
    width: 13px;
    cursor: pointer;
    > svg {
      path {
        fill: #777;
      }
    }
  }

  .btn-close::before {
    content: '';
    mask-image: url(../../../../assets/img/close_black.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    display: inline-block;
    position: relative;
    background-color: #777777;
    height: 13px;
    width: 13px;
  }
}

label {
  display: flex;
  cursor: pointer;
  > input {
    position: relative;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #4698d1;
    vertical-align: -4px;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    &:checked {
      background-color: #4698d1;
      &::after {
        content: url(../../../../assets/img/check_white.svg);
        position: absolute;
        top: -1px;
        left: 2px;
        height: 7px;
        width: 11px;
      }
    }
  }
}

.default-check {
  background-color: #fff;
  border: 2px solid #9d9d9d;
  &:checked {
    background-color: #fff;
    &::after {
      content: url(../../../../assets/img/check.svg);
      position: absolute;
      bottom: 3px;
      left: 0;
      height: 13px;
      width: 18px;
    }
  }
}

.select-staffs-options {
  padding-top: 18px;
  margin: 0 12px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  > dl {
    line-height: 1.2;
    margin-bottom: 20px;
    > dt {
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 12px;
      color: #777;
    }
    > dd {
      margin-bottom: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.select-staffs-list {
  > ul {
    margin: 0 0 18px 12px;
    overflow-y: scroll;
    position: relative;
    &.has-calendar_resource {
      max-height: calc(50% - 35px);
    }
    > li {
      display: flex;
      position: relative;
      margin-bottom: 14px;
      line-height: 1.2;
      > label {
        display: flex;
        > span {
          display: block;
          width: 100%;
          max-width: 156px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.has-edit_color {
            max-width: 140px;
          }
        }
      }
      > button {
        display: none;
        width: 20px;
        margin-left: auto;
        margin-right: 12px;
      }
      &:hover {
        > button {
          display: block;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
