<template>
  <section v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link :to="{ name: 'ApplicantsOverviewDefault' }">
            応募者一覧
          </router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">応募者登録</span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <div v-if="registrationMode === 'input'" class="content inline-warp">
        <p class="page-back">
          <router-link :to="{ name: 'ApplicantsOverviewDefault' }">
            &lt; 応募者管理一覧に戻る
          </router-link>
        </p>
        <div class="headline-area">
          応募者のメールアドレス
          <span class="required">必須</span>
        </div>
        <div class="input-area">
          <input
            v-model="form.data.email"
            class="input-sizefull input"
            type="email"
            maxlength="255"
            placeholder="example@mochica.jp"
            :class="{ 'is-danger': errors.email.isValid !== true }"
            @input="validateOnInput('email', form.data.email, validateEmail)"
          />
          <p v-if="errors.email.isValid !== true" class="fa-warning">
            {{ errors.email.message }}
          </p>
        </div>
        <div class="headline-area">
          応募者の氏名
          <span class="required">必須</span>
        </div>
        <div class="input-area">
          <table class="table-row">
            <tr>
              <td>
                <div class="flex">
                  <label class="name-label">姓</label>
                  <input
                    v-model="form.data.lastname"
                    class="input-sizehalf"
                    type="text"
                    maxlength="14"
                    :class="{ 'is-danger': errors.lastname.isValid !== true }"
                    @input="
                      validateOnInput(
                        'lastname',
                        form.data.lastname,
                        validateRequire,
                      )
                    "
                  />
                </div>
                <p
                  v-if="errors.lastname.isValid !== true"
                  class="fa-warning warning-name"
                >
                  {{ errors.lastname.message }}
                </p>
              </td>
              <td>
                <div class="flex">
                  <label class="name-label">名</label>
                  <input
                    v-model="form.data.firstname"
                    class="input-sizehalf"
                    type="text"
                    maxlength="14"
                    :class="{ 'is-danger': errors.firstname.isValid !== true }"
                    @input="
                      validateOnInput(
                        'firstname',
                        form.data.firstname,
                        validateRequire,
                      )
                    "
                  />
                </div>
                <p
                  v-if="errors.firstname.isValid !== true"
                  class="fa-warning warning-name"
                >
                  {{ errors.firstname.message }}
                </p>
              </td>
            </tr>
          </table>
          <table class="table-row">
            <tr>
              <td>
                <div class="flex">
                  <label class="name-label">セイ</label>
                  <input
                    v-model="form.data.lastnameKana"
                    class="input-sizehalf"
                    type="text"
                    maxlength="63"
                    :class="{
                      'is-danger': errors.lastnameKana.isValid !== true,
                    }"
                    @input="
                      validateOnInput(
                        'lastnameKana',
                        form.data.lastnameKana,
                        validateKana,
                      )
                    "
                  />
                </div>
                <p
                  v-if="errors.lastnameKana.isValid !== true"
                  class="fa-warning warning-name"
                >
                  {{ errors.lastnameKana.message }}
                </p>
              </td>
              <td>
                <div class="flex">
                  <label class="name-label">メイ</label>
                  <input
                    v-model="form.data.firstnameKana"
                    class="input-sizehalf"
                    type="text"
                    maxlength="63"
                    :class="{
                      'is-danger': errors.firstnameKana.isValid !== true,
                    }"
                    @input="
                      validateOnInput(
                        'firstnameKana',
                        form.data.firstnameKana,
                        validateKana,
                      )
                    "
                  />
                </div>
                <p
                  v-if="errors.firstnameKana.isValid !== true"
                  class="fa-warning warning-name"
                >
                  {{ errors.firstnameKana.message }}
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div class="headline-area">
          登録種別
          <span class="required">必須</span>
        </div>
        <div class="input-area">
          <select
            v-model="form.data.graduated"
            type="text"
            class="input select-sotsunen"
            :class="{
              'is-danger': errors.graduated.isValid !== true,
            }"
            @input="
              validateOnInput('graduated', form.data.graduated, validateRequire)
            "
          >
            <option
              v-for="val in $store.getters['graduateds/graduateds']"
              :key="val.id"
              :label="graduatedYearToLabelName(val.year)"
              :value="val.year"
            >
              {{ val.year }}
            </option>
          </select>
          <p v-if="errors.graduated.isValid !== true" class="fa-warning">
            {{ errors.graduated.message }}
          </p>
        </div>
        <div class="headline-area">性別</div>
        <div class="input-area">
          <span class="radio-wrapper">
            <input
              id="sex-man"
              v-model="form.data.genderId"
              type="radio"
              name="q1"
              value="1"
            />
            <label for="sex-man">男</label>
          </span>
          <span class="radio-wrapper">
            <input
              id="sex-female"
              v-model="form.data.genderId"
              type="radio"
              name="q1"
              value="2"
            />
            <label for="sex-female">女</label>
          </span>
          <span class="radio-wrapper">
            <input
              id="sex-noanswer"
              v-model="form.data.genderId"
              type="radio"
              name="q1"
              value="4"
            />
            <label for="sex-noanswer">回答しない</label>
          </span>
        </div>
        <div class="headline-area">郵便番号</div>
        <div class="input-area">
          <input
            v-model="form.data.zipcode"
            type="text"
            class="input-sizehalf"
            placeholder="郵便番号（ハイフンなし）"
            maxlength="7"
            :class="{
              'is-danger': errors.zipcode.isValid !== true,
            }"
            @input="
              validateOnInput(
                'zipcode',
                form.data.zipcode,
                validateZipcode,
                false,
              )
            "
            @keyup="postalCodeJp"
          />
          <p v-if="errors.zipcode.isValid !== true" class="fa-warning">
            {{ errors.zipcode.message }}
          </p>
        </div>
        <div class="headline-area">住所</div>
        <div class="input-area">
          <input
            v-model="form.data.address"
            type="text"
            maxlength="99"
            class="input-sizefull"
            placeholder="住所（都道府県から入力）"
          />
        </div>
        <div class="headline-area">学校名</div>
        <div class="input-area">
          <input
            v-model="form.data.school"
            type="text"
            maxlength="50"
            placeholder="学校名"
          />
        </div>
        <div class="headline-area">学部</div>
        <div class="input-area">
          <input
            v-model="form.data.faculty"
            type="text"
            maxlength="50"
            class="input-sizefull"
            placeholder="学部"
          />
        </div>
        <div class="headline-area">学科</div>
        <div class="input-area">
          <input
            v-model="form.data.department"
            type="text"
            maxlength="50"
            class="input-sizefull"
            placeholder="学科"
          />
        </div>
        <div class="headline-area">文理</div>
        <div class="input-area">
          <span class="radio-wrapper">
            <input
              id="departmentCategory1"
              v-model="form.data.departmentCategoryId"
              type="radio"
              name="q2"
              value="1"
            />
            <label for="departmentCategory1">文</label>
          </span>
          <span class="radio-wrapper">
            <input
              id="departmentCategory2"
              v-model="form.data.departmentCategoryId"
              type="radio"
              name="q2"
              value="2"
            />
            <label for="departmentCategory2">理</label>
          </span>
          <span class="radio-wrapper">
            <input
              id="departmentCategory3"
              v-model="form.data.departmentCategoryId"
              type="radio"
              name="q2"
              value="3"
            />
            <label for="departmentCategory3">その他</label>
          </span>
        </div>
        <div class="headline-area">電話番号</div>
        <div class="input-area">
          <input
            v-model="form.data.tel"
            type="tel"
            maxlength="11"
            placeholder="電話番号（ハイフンなし）"
            class="input input-sizehalf"
            :class="{
              'is-danger': errors.tel.isValid !== true,
            }"
            @input="validateOnInput('tel', form.data.tel, validateTel, false)"
          />
          <p v-if="errors.tel.isValid !== true" class="fa-warning">
            {{ errors.tel.message }}
          </p>
        </div>
        <div class="headline-area">生年月日</div>
        <div class="input-area last">
          <table class="table-row">
            <tr>
              <td>
                <div class="flex">
                  <select v-model="birthDayYear" class="select-birthday">
                    <option></option>
                    <option
                      v-for="y in Array.from(Array(69), (v, k) => k).map(
                        y => y + 1950,
                      )"
                      :key="`year_${y}`"
                    >
                      {{ y }}
                    </option>
                  </select>
                  年
                </div>
              </td>
              <td>
                <div class="flex">
                  <select v-model="birthDayMonth" class="select-birthday">
                    <option></option>
                    <option
                      v-for="m in Array.from(Array(12), (v, k) => k).map(
                        m => m + 1,
                      )"
                      :key="`month_${m}`"
                    >
                      {{ m }}
                    </option>
                  </select>
                  月
                </div>
              </td>
              <td>
                <div class="flex">
                  <select v-model="birthDayDay" class="select-birthday">
                    <option></option>
                    <option
                      v-for="d in Array.from(Array(31), (v, k) => k).map(
                        d => d + 1,
                      )"
                      :key="`day_${d}`"
                    >
                      {{ d }}
                    </option>
                  </select>
                  日
                </div>
              </td>
            </tr>
          </table>
        </div>
        <button
          type="button"
          class="confirm-button btn btn-availability"
          @click="toConfirm"
        >
          確認へ進む
        </button>
      </div>
      <div
        v-else-if="registrationMode === 'confirm'"
        class="content inline-warp minWid600"
      >
        <h3 class="headline-typeC">入力内容の確認</h3>
        <div class="headline-area">メールアドレス</div>
        <div class="input-area">
          <p>{{ form.data.email }}</p>
        </div>
        <div class="headline-area">氏名</div>
        <div class="input-area">
          <p>{{ form.data.lastname }} {{ form.data.firstname }}</p>
        </div>
        <div class="headline-area">フリガナ</div>
        <div class="input-area">
          <p>{{ form.data.lastnameKana }} {{ form.data.firstnameKana }}</p>
        </div>
        <div class="headline-area">登録種別</div>
        <div class="input-area">
          <p>{{ graduatedYearToLabelName(form.data.graduated) }}</p>
        </div>
        <div class="headline-area">性別</div>
        <div class="input-area">
          <p>
            {{
              form.data.genderId === '1'
                ? '男'
                : form.data.genderId === '2'
                  ? '女'
                  : form.data.genderId === '4'
                    ? '回答しない'
                    : ''
            }}
          </p>
        </div>
        <div class="headline-area">郵便番号</div>
        <div class="input-area">
          <p>{{ form.data.zipcode }}</p>
        </div>
        <div class="headline-area">住所</div>
        <div class="input-area">
          <p>{{ form.data.address }}</p>
        </div>
        <div class="headline-area">学校名</div>
        <div class="input-area">
          <p>{{ form.data.school }}</p>
        </div>
        <div class="headline-area">学部</div>
        <div class="input-area">
          <p>{{ form.data.faculty }}</p>
        </div>
        <div class="headline-area">学科</div>
        <div class="input-area">
          <p>{{ form.data.department }}</p>
        </div>
        <div class="headline-area">文理</div>
        <div class="input-area">
          <p>
            {{
              form.data.departmentCategoryId === '1'
                ? '文系'
                : form.data.departmentCategoryId === '2'
                  ? '理系'
                  : form.data.departmentCategoryId === '3'
                    ? 'その他'
                    : ''
            }}
          </p>
        </div>
        <div class="headline-area">電話番号</div>
        <div class="input-area">
          <p>{{ form.data.tel }}</p>
        </div>
        <div class="headline-area">生年月日</div>
        <div class="input-area last">
          <p>{{ formDataBirth !== '0000-00-00' ? formDataBirth : '' }}</p>
        </div>
        <div class="btn-col2">
          <div class="btn btn-unavailable" @click="registrationMode = 'input'">
            戻る
          </div>
          <button-base
            class="btn register-button"
            button-text="応募者を登録"
            :is-loading="isLoading"
            @click="registerApplicant"
          />
        </div>
      </div>
      <div
        v-else-if="registrationMode === 'complete'"
        class="content inline-warp minWid600"
      >
        <h3 class="headline-typeC">応募者リストに追加しました。</h3>

        <div class="inline-warp minWid600">
          <div class="btn-col2">
            <div class="btn btn-availability" @click="resetModes">
              続けて応募者を登録する
            </div>
            <div class="btn btn-availability" @click="pathChange">
              応募者一覧に行く
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import zipcode from 'japan-postal-code';

import GraduatedDefines from '@/defines/graduated';
import applicantsService from '@/services/applicants';
import { camelToSnakeCase } from '@/utils/change-case';
import useValidation from '@/composables/useValidation';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';

export default defineComponent({
  name: 'ApplicantsEmailRegistration',
  components: { BreadCrumbList },
  setup(props, context) {
    const INITIAL_FORM = {
      email: '',
      lastname: '',
      firstname: '',
      lastnameKana: '',
      firstnameKana: '',
      graduated: '',
      genderId: '',
      zipcode: '',
      address: '',
      school: '',
      faculty: '',
      department: '',
      departmentCategoryId: '',
      tel: '',
      birth: '',
    };
    const {
      errors,
      resetErrors,
      updateErrors,
      validateRequire,
      validateEmail,
      validateKana,
      validateZipcode,
      validateTel,
      validateOnInput,
    } = useValidation([
      'email',
      'lastname',
      'firstname',
      'lastnameKana',
      'firstnameKana',
      'graduated',
      'zipcode',
      'tel',
    ]);
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);
    const registrationMode = ref('input'); // 'input'|'confirm'|'complete'
    const form = reactive({ data: { ...INITIAL_FORM } });
    const birthDayYear = ref('');
    const birthDayMonth = ref('');
    const birthDayDay = ref('');

    // computed
    const formDataBirth = computed(() => {
      if (
        birthDayYear.value === '' ||
        birthDayMonth.value === '' ||
        birthDayDay.value === ''
      ) {
        // 年月日を全て選択済みでない場合は空文字
        return '';
      }
      return `${`0000${birthDayYear.value}`.slice(
        -4,
      )}-${`00${birthDayMonth.value}`.slice(
        -2,
      )}-${`00${birthDayDay.value}`.slice(-2)}`;
    });

    // methods
    const registerApplicant = async () => {
      isLoading.value = true;
      let applicant = {};
      if (formDataBirth.value !== '') form.data.birth = formDataBirth.value;
      const keys = Object.keys(form.data);
      for (const key of keys) {
        const newKey = camelToSnakeCase(key);
        applicant = Object.assign({}, applicant, {
          [newKey]: form.data[key],
        });
      }
      const res = await applicantsService.createApplicantProvision({
        applicant,
      });
      isLoading.value = false;
      if (res.isAlreadyRegistered === true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'このメールアドレスは既に登録済みです。',
          type: res.data.success,
        });
        return;
      }
      if (res.isSuccess === true) {
        registrationMode.value = 'complete';
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者リストに追加しました。',
          type: res.isSuccess,
        });
        return;
      }
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      return;
    };
    const validateAll = () => {
      resetErrors();
      const isValidEmail = validateEmail('email', form.data.email);
      const isValidLastname = validateRequire('lastname', form.data.lastname);
      const isValidFirstname = validateRequire(
        'firstname',
        form.data.firstname,
      );
      const isValidLastnameKana = validateKana(
        'lastnameKana',
        form.data.lastnameKana,
      );
      const isValidFirstnameKana = validateKana(
        'firstnameKana',
        form.data.firstnameKana,
      );
      const isValidGraduated = validateRequire(
        'graduated',
        form.data.graduated,
      );
      const isValidZipcode = validateZipcode(
        'zipcode',
        form.data.zipcode,
        false,
      );
      const isValidTel = validateTel('tel', form.data.tel, false);
      return (
        isValidEmail === true &&
        isValidLastname === true &&
        isValidFirstname === true &&
        isValidLastnameKana === true &&
        isValidFirstnameKana === true &&
        isValidGraduated === true &&
        isValidZipcode === true &&
        isValidTel === true
      );
    };
    const toConfirm = () => {
      if (validateAll() !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容を確認して下さい',
          type: false,
        });
        return;
      }
      registrationMode.value = 'confirm';
    };
    const resetModes = () => {
      registrationMode.value = 'input';
      form.data = { ...INITIAL_FORM };
      // 初期選択卒年に現在の卒年をセット
      let year = 0;
      store.getters['graduateds/graduateds'].forEach(v => {
        if (v.id === store.getters['staff/staff'].selected_graduated_id) {
          year = v.year;
        }
      });
      form.data.graduated = year;
    };
    const pathChange = () => {
      resetModes();
      router.replace('/applicants');
    };
    const postalCodeJp = () => {
      zipcode.get(form.data.zipcode, addr => {
        form.data.address = `${addr.prefecture}${addr.city}${addr.area}`;
      });
    };
    const graduatedYearToLabelName = year => {
      const res = GraduatedDefines.find(v => v.year === year);
      if (res !== undefined) return res.name_jp;
      return `${year}年卒業`;
    };

    // lifecycle
    onBeforeMount(async () => {
      await store.dispatch('page/SET_LOADED');
      resetModes();
    });

    return {
      errors,
      updateErrors,
      validateRequire,
      validateEmail,
      validateKana,
      validateZipcode,
      validateTel,
      validateOnInput,
      isLoading,
      registrationMode,
      form,
      birthDayYear,
      birthDayMonth,
      birthDayDay,
      formDataBirth,
      registerApplicant,
      toConfirm,
      postalCodeJp,
      resetModes,
      pathChange,
      graduatedYearToLabelName,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects2();

.content {
  padding: 40px 30px;
  background-color: #fff;
}
.inline-warp {
  display: inline-block;
}
.headline-area {
  padding: 20px 0 15px;
  border-top: 1px solid #e5e5e5;
  font-size: 1.4rem;
  font-weight: bold;
  &:first-child {
    border: none;
  }
}
.input-area {
  padding-bottom: 20px;
  font-size: 1.4rem;
  &.last {
    margin-bottom: 40px;
    border-bottom: 1px solid #e5e5e5;
  }
}
.required {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  vertical-align: 1px;
  color: #fff;
  font-size: 1rem;
  background-color: #b30000;
}

.warning-name {
  margin-left: 50px;
}
.table-row {
  + .table-row {
    margin-top: 15px;
  }
  td {
    vertical-align: top;
  }
  td:nth-child(n + 2) {
    padding-left: 15px;
  }
  .txt-warning {
    padding-left: 50px;
  }
}
.name-label {
  width: 50px;
  text-align: center;
}
.radio-wrapper {
  margin-right: 3px;
  > input[type='radio'] {
    margin-right: 4px;
  }
  + .radio-wrapper {
    margin-left: 10px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
input,
select {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #adadad;
  vertical-align: middle;
}
.input-sizefull {
  width: 100%;
}
.input-sizehalf {
  width: 260px;
}
.select-sotsunen {
  width: 260px;
}
.select-birthday {
  width: 140px;
  margin-right: 5px;
}
.fa-warning {
  font-size: 12px;
}
.red {
  color: #c31e1e;
}

.confirm-button {
  width: 100%;
  padding: 15px 10px;
}

.btn-col2 {
  .register-button {
    padding: 15px 10px;
  }
}

@media (max-width: ($media_query_tablet)) {
  .content {
    padding: 40px;
  }
  .inline-warp {
    display: block;
  }
  .input-sizehalf {
    width: 100%;
    max-width: 260px;
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px math_size(20);
  }
  .table-row {
    width: 100%;
    td {
      display: block;
    }
    td:nth-child(n + 2) {
      padding-top: 10px;
      padding-left: 0;
    }
  }
}

@media (min-width: ($media_query_sp)) {
  .minWid600 {
    min-width: 600px;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
