<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">担当者の一括変更</p>
        </div>
        <div class="body">
          <div class="message">
            一覧画面にて選択した応募者の担当者を一括で変更します。
          </div>
          <div class="change-staff">
            担当者
            <drop-down
              class="change-staff-drop-down"
              :placeholder="'担当者'"
              :selected-id="staffId"
              :list="
                staffs.map(v => ({
                  ...v,
                  selected: v.id === staffId,
                  name: v.lastname + v.firstname,
                }))
              "
              :is-view="isVisibleStaffId"
              @onOpen="isVisibleStaffId = true"
              @onClose="isVisibleStaffId = false"
              @onSelected="onSelectedStaffId"
            />
          </div>
          <selectable-applicant-table
            class="change-list"
            :applicants="applicants"
            :invisible-columns="invisibleColumns"
            :is-sortable="false"
          />
        </div>
        <div class="btn-col2">
          <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
            キャンセル
          </div>
          <div
            class="btn btn-availability"
            @click="staffId ? $emit('onClickSettle', { staffId }) : () => {}"
          >
            担当者を変更する
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref, watch, nextTick } from 'vue';

import { BASE_INVISIBLE_COLUMNS, onResizeModal } from '@/utils/modal-resize';
import Modal from '@/components/ui/modals/components/Modal';
import DropDown from '@/components/ui/menus/components/DropDown';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';

export default defineComponent({
  name: 'ModalBulkUpdateStaff',
  components: { Modal, DropDown, SelectableApplicantTable },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    applicants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCancel', 'onClickSettle'],
  setup(props, context) {
    const staffId = ref(null);
    const isVisibleStaffId = ref(false);
    watch(
      () => props.isVisible,
      v => {
        if (v === true) {
          nextTick(() => localResizeModal());
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );
    const onSelectedStaffId = payload => {
      staffId.value = payload.item.id;
      isVisibleStaffId.value = false;
    };
    const localResizeModal = () => onResizeModal(props.isVisible);

    return {
      invisibleColumns: BASE_INVISIBLE_COLUMNS,
      staffId,
      isVisibleStaffId,
      onSelectedStaffId,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.content {
  .header {
    padding: 2rem 0;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    font-size: 1.2rem;
    padding: 0rem 2rem 2rem;
    .message {
      padding: 2.4rem 0 1rem;
    }
    .change-staff {
      text-align: left;
      &-drop-down {
        width: 100%;
        margin: 0.6rem 0 2rem;
        position: relative;
      }
    }
    .area-table {
      &.change-list {
        width: 100%;
        height: 30vh;
        margin: 0;
        border: 1px solid $border_color;
        border-top: none;
        text-align: left;

        tr {
          border-bottom: 1px solid $border_color;
        }
      }
    }
    .change-staff_btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      &_item {
        width: 49.5%;
        height: 3.6rem;
        line-height: 3.6rem;
        background: $mochica_color;
        border-radius: 5rem;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        &.back_btn {
          background: #fff;
          border: 1px solid $border_color;
          color: #333;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
