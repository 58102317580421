import httpClient from '@/http-client';
import { download, getFileExtension } from '@/utils/file';

const LineTalkManager = function _LineTalkManager() {
  const manager = Object.create(LineTalkManager.prototype);
  return manager;
};

LineTalkManager.prototype = {
  async sendLineMassage(applicantId, content, unreadNeed) {
    const res = await httpClient.post('/line_talks/send', {
      content,
      unread_need: unreadNeed,
      applicant_id: applicantId,
    });
    return { success: res.status === 200, data: res.data ? res.data : null };
  },
  async unreadCheckMessage(payload) {
    const res = await httpClient.post('/line_talks/unread_check', payload);
    return res.data;
  },
  async fetchLineInitMessage(applicantId, limit = 10) {
    const res = await httpClient.post('/line_talks/get_init', {
      applicant_id: applicantId,
      limit,
    });
    return res.data;
  },
  async checkLineNewMessage(applicantId, latestId) {
    const res = await httpClient.get(
      `/line_talks/check_newtalk?applicant_id=${applicantId}&latest_id=${latestId}`,
    );
    return res.data;
  },
  async fetchLineNewMessage(applicantId, latestId) {
    const res = await httpClient.post('/line_talks/get_newtalk', {
      applicant_id: applicantId,
      latest_id: latestId,
    });
    return res.data;
  },
  async fetchLineOldMessage(applicantId, oldestId, limit = 10) {
    const res = await httpClient.post('/line_talks/get_oldtalk', {
      applicant_id: applicantId,
      oldest_id: oldestId,
      limit,
    });
    return res.data;
  },
  async fetchLineAllOpenedMessage(applicantId, latestId) {
    const res = await httpClient.post('/line_talks/all_opened', {
      applicant_id: applicantId,
      latest_id: latestId,
    });
    return { success: res.status === 200, data: res.data ? res.data : [] };
  },
  async updateMessageOpened(applicantId, messageId, isOpened) {
    const res = await httpClient.post('/line_talks/update_opened', {
      applicant_id: applicantId,
      message_id: messageId,
      is_opened: isOpened,
    });
    return { success: res.status === 200, data: res.data ? res.data : [] };
  },
  async postLineContentDownload(messageId, content) {
    const res = await httpClient.get(`/line_talks/content/${messageId}`, {
      responseType: 'blob',
    });
    if (res.status === 200) {
      if (res.data) {
        let filePrefix = '';
        // ここを変える場合はLineTalk.vueも変更すること
        filePrefix = getFileExtension(content);
        download(res.data, `download_file${filePrefix}`);
        return { success: true, data: null };
      }
    }
    return { success: false, data: res.data ? res.data : null };
  },
  async sendLineImage(formData) {
    const res = await httpClient.post('/line_talks/send_img', formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    return { success: res.status === 200, data: res.data ? res.data : null };
  },
  async lineMessageExt(token) {
    const response = await httpClient.post('/line_message_ext', { token });
    return response.data;
  },
};
export default LineTalkManager();
