<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link to="/announce">応募者への通知</router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">
          アンケート送信履歴
        </span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <div class="content">
        <div class="btn-notice-history-search-wrapper pos-relative">
          <pager
            class="pager"
            :page="page"
            :count="count"
            :page-count="pageCount"
            @move="movePage"
          />
        </div>
        <div class="scroll-table">
          <table class="table-typeB">
            <thead class="thead">
              <tr class="row">
                <th class="item-th"><span>アンケート名</span></th>
                <th class="item-th"><span>質問数</span></th>
                <th class="item-th"><span>送信先</span></th>
                <th class="item-th"><span>担当者</span></th>
                <th class="item-th"><span>送信日</span></th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr
                v-for="(item, i) in flagSurveyHistory"
                :key="i"
                class="row"
                @click="openModal(item)"
              >
                <td class="item-td">
                  <span>{{ $utils.textEllipsis(item.title) }}</span>
                </td>
                <td class="item-td">
                  <span>{{ item.count }}</span>
                </td>
                <td class="item-td">
                  <span>{{ item.applicant_count }}名</span>
                </td>
                <td class="item-td">
                  <span>{{ !item.staff ? '' : item.staff.name }}</span>
                </td>
                <td class="item-td">
                  <span>
                    {{
                      $utils.parseDateTime(
                        item.announced_at,
                        'YYYY/MM/DD HH:mm',
                      )
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <modal-flag-survey-view
      v-if="isShowModalFlagSurveyView"
      :is-visible="isShowModalFlagSurveyView"
      :flag-survey-id="selectedFlagSurveyId"
      :announced-id="selectedAnnouncedId"
      @onClickCancel="onClickCancel"
    />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import flagsurveyNoticeService from '@/services/flag-survey-notice';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import Pager from '@/components/ui/pager/components/Pager';
import ModalFlagSurveyView from '@/components/page/flagsurvey/flagsurveyhistory/components/ModalFlagSurveyView';

export default defineComponent({
  name: 'FlagSurveyHistory',
  components: { BreadCrumbList, Pager, ModalFlagSurveyView },
  setup(props, context) {
    const store = useStore();
    const page = ref(1);
    const count = ref(0);
    const limit = ref(25);
    const pageCount = ref(0);
    const flagSurveyHistory = ref(null);
    const selectedFlagSurveyId = ref(null);
    const selectedAnnouncedId = ref(null);
    const isShowModalFlagSurveyView = ref(false);

    const fetchFlagSurveyHistory = async (tmpPage = 1) => {
      const res = await flagsurveyNoticeService.fetchFlagSurveyNotices({
        page: tmpPage,
        limit: limit.value,
      });
      flagSurveyHistory.value = res.flagsurveys;
      count.value = res.count;
      pageCount.value = res.page_count;
      page.value = tmpPage;
    };
    const openModal = item => {
      selectedFlagSurveyId.value = item.id;
      selectedAnnouncedId.value = item.announced_id;
      isShowModalFlagSurveyView.value = true;
    };
    const onClickCancel = () => {
      isShowModalFlagSurveyView.value = false;
    };
    const movePage = tmpPage => {
      fetchFlagSurveyHistory(tmpPage.next);
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchFlagSurveyHistory();
      await store.dispatch('page/SET_LOADED');
    });
    onMounted(() => {
      page.value = 1;
    });

    return {
      page,
      count,
      pageCount,
      flagSurveyHistory,
      selectedAnnouncedId,
      limit,
      isShowModalFlagSurveyView,
      selectedFlagSurveyId,
      openModal,
      onClickCancel,
      movePage,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.content {
  padding: 40px;
  background: #fff;
}

.btn-notice-history-search-wrapper {
  display: flex;
  margin-bottom: 1rem;
  .pager {
    margin-left: auto;
  }
}

.scroll-table {
  overflow-x: auto;
  margin-top: 40px;
}

.table-typeB {
  table-layout: fixed;
  .row {
    cursor: pointer;
  }
  .row:hover {
    background-color: #f1f1f1;
  }
  .item-td {
    img {
      width: 50px;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .table-typeB {
    table-layout: auto;
  }
  .scroll-table {
    white-space: nowrap;
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px;
  }
  .scroll-table {
    margin-top: 20px;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
