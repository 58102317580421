<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment';

import Pager from '@/components/ui/pager/components/Pager.vue';
import AutoActionsTemplate from '@/components/features/autoActions/templates/AutoActionsTemplate.vue';
import Highlight from '@/components/ui/text/Highlight.vue';
import ProgressStatusLabel from '@/components/features/progress/ProgressStatusLabel.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import FlagGroupConfirm from '@/components/features/flagGroup/FlagGroupConfirm.vue';

import { formatAutoActionsLogsDetailToState } from '@/utils/autoActions/formatAutoActionData';

import autoActionsService from '@/services/autoActions';

import { ACTION_LOG_STATUSES, CONFIRM_COMPONENTS } from '@/defines/autoActions';

const route = useRoute();
const router = useRouter();
const action = ref({});
const applicants = ref([]);
const pageMeta = ref({});

const store = useStore();

const isViewDeleteModal = ref(false);
const isLoading = ref(false);

const initialPage = 1;

if (!route.query?.action_logs_id && !route.query?.reservation_date) {
  router.push({ name: 'AutoActionsLogs' });
}

const backPageHandler = () => {
  router.push({ name: 'AutoActionsLogs' });
};

const fetchLogsApplicants = async page => {
  const { action_logs_id, reservation_date } = route.query;
  const { data } = await autoActionsService.fetchAutoActionsApplicants(
    {
      graduated_id: store.getters['graduateds/selectedGraduatedId'],
      action_logs_id,
      reservation_date,
      page,
      status: ACTION_LOG_STATUSES.done,
    },
    route.params.id,
  );
  applicants.value = data.applicants;
  pageMeta.value = data.meta;
};

const fetchLogsDetail = async page => {
  const { action_logs_id, reservation_date } = route.query;
  const { data } = await autoActionsService.fetchAutoActionsLogsDetail(
    {
      graduated_id: store.getters['graduateds/selectedGraduatedId'],
      action_logs_id,
      reservation_date,
    },
    route.params.id,
  );
  action.value = formatAutoActionsLogsDetailToState(data);
};

const movePage = async ({ next }) => {
  isLoading.value = true;
  await fetchLogsApplicants(next);
  isLoading.value = false;
};

const handleApplicantClick = (applicantId, isDeleted) => {
  if (isDeleted) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: '応募者が削除されているため遷移できません',
      type: false,
    });
    return;
  }
  window.open(`/applicants/${applicantId}`, '_blank');
};

onMounted(async () => {
  store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
  await fetchLogsApplicants(initialPage);
  await fetchLogsDetail();
  store.dispatch('page/SET_LOADED');
});
</script>
<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <auto-actions-template
        :header-title="action.historyTitle"
        :back-page-title="'自動アクションの履歴一覧に戻る'"
        :on-back-page="backPageHandler"
      >
        <template #body>
          <div class="section-body-inner">
            <section class="confirm-wrapper action-name">
              <div class="confirm-outer">
                <div class="confirm-inner">
                  <h3 class="confirm-title">自動アクションの実行日時</h3>
                  <div class="confirm-contents text">
                    <p>
                      {{ action.executedAt }}
                    </p>
                  </div>
                </div>
                <div class="confirm-inner">
                  <h3 class="confirm-title">実行人数</h3>
                  <div class="confirm-contents text">
                    <p>{{ action.totalApplicants }}</p>
                  </div>
                </div>
                <div class="confirm-inner">
                  <h3 class="confirm-title">自動アクション名</h3>
                  <div class="confirm-contents text">
                    <p>
                      {{ action.actionName }}
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="confirm-wrapper">
              <highlight :h-level="2">自動アクションを実行した応募者</highlight>
              <div v-if="isLoading">
                <local-page-loader />
              </div>
              <div v-else class="applicant-list">
                <pager
                  :page="pageMeta.current_page"
                  :count="pageMeta.total_count"
                  :page-count="pageMeta.total_pages"
                  @move="movePage"
                />
                <div class="table-wrapper">
                  <table class="table">
                    <thead>
                      <th class="fit-left">
                        <span>実行日時</span>
                      </th>
                      <th><span>名前</span></th>
                      <th><span>セイメイ</span></th>
                      <th><span>連絡方法</span></th>
                      <th><span>性別</span></th>
                      <th class="fit-left">
                        <span>学校名</span>
                      </th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="applicant in applicants"
                        :key="applicant.id"
                        @click="
                          handleApplicantClick(
                            applicant.id,
                            applicant.is_deleted,
                          )
                        "
                      >
                        <td>
                          {{
                            applicant.executed_at
                              ? moment(applicant.executed_at).format(
                                  'YYYY年MM月DD日 HH:mm',
                                )
                              : '-'
                          }}
                        </td>
                        <td class="text-ellipsis">{{ applicant.name }}</td>
                        <td class="text-ellipsis">{{ applicant.name_kana }}</td>
                        <td>{{ applicant.contact_type }}</td>
                        <td>{{ applicant.gender }}</td>
                        <td class="text-ellipsis">{{ applicant.school }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pager
                  :page="pageMeta.current_page"
                  :count="pageMeta.total_count"
                  :page-count="pageMeta.total_pages"
                  @move="movePage"
                />
              </div>
            </section>
            <section class="confirm-wrapper">
              <highlight :h-level="2">アクションの詳細内容</highlight>
              <div class="confirm-outer">
                <div class="confirm-inner">
                  <h3 class="confirm-title">自動アクションさせる選考</h3>
                  <div class="confirm-contents elements">
                    <div class="gray-box">
                      <div class="gray-box-inner">
                        <ul class="items">
                          <li class="item">
                            <div class="title">選考名</div>
                            <div class="contents">
                              {{ action.selection?.title }}
                            </div>
                          </li>
                          <li class="item">
                            <div class="title">選考の管理者用メモ</div>
                            <div class="contents">
                              {{ action.selection?.description }}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="confirm-inner">
                  <h3 class="confirm-title">実行する自動アクション</h3>
                  <div class="confirm-contents text">
                    <component
                      :is="CONFIRM_COMPONENTS[action.actionType]"
                      :results="{ ...action.results }"
                      :selection="{ ...action.selection }"
                      :rich-message="{
                        id: action.richMessage?.id,
                        name: action.richMessage?.title,
                        img: `${action.richMessage?.image_url}/240`,
                      }"
                      :flag-survey="{ ...action.flagSurvey }"
                      :notice="{ ...action.notice }"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section class="confirm-wrapper">
              <highlight :h-level="2">自動アクションを実行する条件</highlight>
              <div class="confirm-outer">
                <div class="confirm-inner">
                  <h3 class="confirm-title">応募者のステータス</h3>
                  <div class="confirm-contents elements">
                    <div class="progress-statuses">
                      <progress-status-label
                        v-for="progress in action.applicantStatus"
                        :key="progress"
                        :label-name="progress"
                        class="w110"
                      />
                    </div>
                  </div>
                </div>
                <div class="confirm-inner">
                  <h3 class="confirm-title">応募者のフラグ</h3>
                  <div class="confirm-contents elements">
                    <flag-group-confirm :flag-groups="action.flagGroups" />
                  </div>
                </div>
              </div>
            </section>
            <section class="confirm-wrapper">
              <highlight :h-level="2">
                自動アクションを実行するタイミング
              </highlight>
              <div class="confirm-outer">
                <div class="confirm-inner">
                  <h3 class="confirm-title">自動アクションのタイミング</h3>
                  <div class="confirm-contents text">
                    <template v-if="action.triggerDelayDay === null">
                      <p>すぐに実行</p>
                    </template>
                    <template v-else>
                      <p>
                        {{ action.triggerDelayDay }}日後の{{
                          action.triggerDelayTime
                        }}
                        に実行
                      </p>
                    </template>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </template>
        <template #footer>
          <div class="page-footer">
            <button-base
              type="button"
              class="btn-cancel"
              button-type="secondary"
              button-text="自動アクション一覧に戻る"
              @click="backPageHandler"
            />
          </div>
        </template>
      </auto-actions-template>
    </div>
    <modal-window
      :is-visible="isViewDeleteModal"
      :modal-type="'danger'"
      :max-width="'700px'"
      title="自動アクションを削除します"
      @close="isViewDeleteModal = false"
      @clickOuter="isViewDeleteModal = false"
    >
      <template #content>
        <div class="modal-contents">
          <div class="modal-contents-text">
            <div>本当に自動アクションを削除してもよろしいでしょうか？</div>
          </div>
          <div class="modal-contents-bottom">
            <button-base
              :button-text="'キャンセル'"
              button-type="secondary"
              @click="isViewDeleteModal = false"
            />
            <button-base
              :button-text="'自動アクションを削除'"
              :button-type="'danger'"
              :is-loading="isLoading"
              @click="handleDeleteAction"
            />
          </div>
        </div>
      </template>
    </modal-window>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
@include confirmTemplate;
@include tableStyle(0);
.page-wrapper {
  margin: 20px 0;
  padding: 0 20px;
  .page-inner {
    padding: 0 30px 30px 40px;
    background-color: #fff;
  }
}
.confirm-wrapper {
  @extend .action-wrapper;
}
.confirm-outer {
  @extend .action-outer;
}
.confirm-inner {
  @extend .action-inner;
}
.applicant-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.pager {
  justify-content: flex-start;
}
.progress-statuses {
  .w110 {
    flex-basis: 110px;
    @media (max-width: ($media_query_sp)) {
      flex-basis: calc(50% - 4px);
    }
  }
}
.page-footer {
  display: flex;
  gap: 12px;
  margin: 20px 0 0;
  & > button {
    width: 100%;
    height: 56px;
  }
}
.modal-contents {
  padding: 24px;
  &-text {
    white-space: pre-line;
  }
  &-bottom {
    display: flex;
    gap: 8px;
    margin-top: 40px;
    button {
      height: 52px;
      width: 100%;
    }
  }
}
</style>
