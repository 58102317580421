<template>
  <div class="wrapper">
    <div class>
      <div class="edit-company-element">
        <h2 class="company-member_list_item_ttl">
          <span class="list_title">会社名</span>
        </h2>
        <p class="value">{{ companyName }}</p>
      </div>
      <div class="edit-company-element">
        <h2 class="company-member_list_item_ttl">
          <span class="list_title">現在のプラン名</span>
        </h2>
        <p class="value">{{ companyPlanData.name_ja }}</p>
      </div>
      <div class="edit-company-element">
        <h2 class="company-member_list_item_ttl">
          <span class="list_title">応募者数</span>
        </h2>
        <p class="value">{{ applicantsCount }}名</p>
      </div>
    </div>
    <div class="edit-plan">
      <div class="title">プランの変更</div>
      <label class="select-plan-label" for>プランの選択</label>
      <select v-model="selectedPlanId" class="select-plan" type="text" required>
        <option :value="null" selected>▼選択してください</option>
        <option
          v-for="plan in planData"
          v-show="plan.id !== companyPlanData.id"
          :key="plan.id"
          :value="plan.id"
        >
          {{ plan.name_ja }}
        </option>
      </select>
      <div
        v-if="selectedPlanId !== null"
        class="edit-company-element__applicant"
      >
        <div class="row-column">
          <h2 class="company-member_list_item_ttl">
            <span class="list_title">応募者数</span>
          </h2>
          <p class="value">
            {{ applicantsCount }} /
            {{ selectedPlanData.applicant_limit }}
          </p>
        </div>
        <div v-show="selectedPlanData.applicant_limit < applicantsCount">
          <button-base
            class="btn-s"
            button-text="削除予定の応募者を表示"
            icon-file-name="search_white"
            :rejected-admin-ids="[2]"
            @click="$emit('onClickOpenDeleToApplicantsModal', $event)"
          />
          <button-base
            class="btn-s btn-download_csv"
            button-text="削除予定の応募者のCSVをダウンロード"
            icon-file-name="download_white"
            :rejected-admin-ids="[2]"
            @click="$emit('onClickDownloadApplicantsCsv', $event)"
          />
        </div>
      </div>
      <p
        v-show="selectedPlanData.applicant_limit < applicantsCount"
        class="attention"
      >
        ※
        選択しているプランは、登録可能な応募者数の上限を超えているため、応募者の削除が必要になります
      </p>
    </div>
    <button-base
      class="btn btn-availability apply-plan"
      button-text="プランを設定"
      @click="$emit('onClickOpenModalSettingPlan', selectedPlanId)"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from 'vue';

import { mochicaPlans, getPlanDataById } from '@/defines/plan';

export default defineComponent({
  name: 'AdminEditPlan',
  props: {
    planId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
    companyName: {
      type: String,
      default: '',
    },
    applicantsCount: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'onClickOpenModalSettingPlan',
    'onClickOpenDeleToApplicantsModal',
    'onClickDownloadApplicantsCsv',
    'onSelectedPlanId',
  ],
  setup(props, context) {
    const selectedPlanId = ref(null);

    // computed
    const planData = computed(() => [
      ...mochicaPlans.filter(plan => plan.is_show === true),
    ]);
    const selectedPlanData = computed(() => {
      const plan = getPlanDataById(selectedPlanId.value);
      return plan || {};
    });
    const companyPlanData = computed(() => {
      const plan = getPlanDataById(props.planId);
      return plan || {};
    });

    // watch
    watch(selectedPlanId, val => {
      const planId = getPlanDataById(val);
      context.emit('onSelectedPlanId', planId !== undefined ? planId : null);
    });

    return {
      planData,
      selectedPlanId,
      selectedPlanData,
      companyPlanData,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.wrapper {
  margin-top: 20px;
}
.edit-company-element {
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  .company-member_list_item_ttl {
    display: flex;
    justify-content: space-between;
  }
  .btn-availability {
    line-height: 0px;
  }
  .list_title {
    font-weight: bold;
  }
  .value {
    padding-top: 10px;
  }
}
.edit-company-element__applicant {
  display: flex;
  flex-direction: column;
  .row-column {
    margin-bottom: 20px;
  }
}

.edit-plan {
  .title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  margin-top: 50px;
}

.apply-plan {
  width: 100%;
  margin-top: 30px;
}
.select-plan-label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.select-plan {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid #ccc;
}
.entryform_setting_ttl {
  padding: 12px 20px;
  background: #e2e2e2;
  font-weight: bold;
}
.attention {
  color: $mochica_color_red;
  margin-top: 20px;
}
.btn-download_csv {
  margin-left: 10px;
}
</style>
