<script setup>
import { ref } from 'vue';
import { useField } from 'vee-validate';

import SelectionsSearchModal from '@/components/features/autoActions/modals/SelectionsSearchModal.vue';

defineProps({
  excludeSelectionIds: {
    type: Array,
    default: () => [],
  },
});

defineEmits(['open-selection-modal']);

const { value: id, errorMessage } = useField('assignProgress.id');
const { value: title } = useField('assignProgress.title');
const { value: description } = useField('assignProgress.description');

const isViewAssignProgressModal = ref(false);

const handleSelectAssignProgress = payload => {
  id.value = payload.id;
  title.value = payload.title;
  description.value = payload.description;
  isViewAssignProgressModal.value = false;
};
</script>

<template>
  <div class="action-inner">
    <div class="action-title">
      <h3 class="title">新しく選考を割り当てる</h3>
      <div class="desc">
        自動アクションさせる選考の応募者に対して新しく選考を割り当てます。
      </div>
    </div>
    <div class="action-contents auto-action">
      <div class="action-assign-progress gray-box">
        <div class="button-wrapper" :class="{ error: !!errorMessage }">
          <div class="button-inner">
            <button-base
              type="button"
              :button-text="'選考を選択'"
              :icon-file-name="'plus_circle'"
              @click="isViewAssignProgressModal = true"
            />
          </div>
          <div v-show="!!errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
        <div v-if="title" class="gray-box-inner">
          <ul class="items">
            <li class="item">
              <div class="title">選考名</div>
              <div class="contents">{{ title }}</div>
            </li>
            <li class="item">
              <div class="title">選考の管理者用メモ</div>
              <div class="contents">{{ description }}</div>
            </li>
          </ul>
        </div>
        <div v-else class="empty">割り当てる選考を選択してください</div>
      </div>
    </div>
    <selections-search-modal
      :is-view="isViewAssignProgressModal"
      :exclude-selection-ids="excludeSelectionIds"
      @close-modal="isViewAssignProgressModal = false"
      @select-selection="handleSelectAssignProgress($event)"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
</style>
