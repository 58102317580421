import httpClient from '@/http-client';

const SurveyManager = function _SurveyManager() {
  const manager = Object.create(SurveyManager.prototype);
  return manager;
};

SurveyManager.prototype = {
  async getSurveyList(graduatedId) {
    const res = await httpClient.post('/survey', {
      graduated_id: graduatedId,
    });
    return res.data;
  },
  async fetchSurvey(id) {
    const res = await httpClient.get(`/survey/${id}`);
    return res.data;
  },
  async getGoogleTokenCheck() {
    const res = await httpClient.get('/survey/google/check');
    return res.data;
  },
  async getGoogleLoginUrl() {
    const res = await httpClient.get('/survey/google/login');
    return res.data;
  },
  async resetGoogle() {
    const res = await httpClient.post('/survey/google/reset');
    return res.data;
  },
  async postGoogleConnect(code) {
    const res = await httpClient.post('/survey/google/connect', {
      code,
    });
    return res.data;
  },
  async oauth2callback(
    graduatedId,
    spreadsheetUid,
    spreadsheetGid,
    spreadsheetName,
    forced = false,
    onProgress,
  ) {
    const config = {
      onDownloadProgress(progressEvent) {
        // const total = progressEvent.srcElement.getResponseHeader('Content-Range');
        const percentCompleted = Math.round(progressEvent.loaded);
        if (onProgress) onProgress(percentCompleted);
        return percentCompleted;
      },
    };
    const res = await httpClient.post(
      '/survey/oauth2callback',
      {
        graduated_id: graduatedId,
        sheet_uid: spreadsheetUid,
        sheet_gid: spreadsheetGid,
        sheet_name: spreadsheetName,
        forced,
      },
      config,
    );
    return res.data;
  },
  async deleteSurveySheet(payload) {
    const res = await httpClient.post('/survey/delete', {
      graduated_id: payload.graduated_id,
      sheet_id: payload.sheet_id,
      spreadsheet_uid: payload.spreadsheet_uid,
      spreadsheet_gid: payload.spreadsheet_gid,
    });
    return res.data;
  },
};

export default SurveyManager();
