<template>
  <div
    class="selections-calendar"
    @click="
      closeCalendarDetail();
      closeTooltips();
    "
  >
    <local-page-loader v-if="isLoading" />
    <div
      class="selections-calendar-inner"
      :class="{ 'is-init': isInitialized }"
    >
      <div
        ref="refSelectStaffsWrapper"
        :class="{ 'select-staff-wrapper': true, 'is-open': isOpenCalendarNav }"
      >
        <select-staffs
          v-if="isInitialized === true"
          ref="refSelectStaffs"
          :is-calendar-page="true"
          :is-google-member="staff.is_google_member"
          :is-connected-google-calendar="isConnectedGoogleCalendar"
          :settings="{
            isShowExceptMySelection,
            isShowMySelection,
            isShowGoogleEvent,
            staffs,
            googleResourceCalendars,
          }"
          :calendar-colors="calendarColors"
          @closeMenu="toggleMenu(false)"
          @updateOptions="updateOptions($event, refCalendar)"
        />
      </div>
      <div class="selections-calendar-relative">
        <div class="open-menu-button">
          <button @click="toggleMenu"></button>
        </div>
        <div class="toggle-calendar-button">
          <button
            :class="{ 'is-current': calendarView === 'timeGridWeek' }"
            @click="toggleCalendar('timeGridWeek')"
          >
            週
          </button>
          <button
            :class="{ 'is-current': calendarView === 'dayGridMonth' }"
            @click="toggleCalendar('dayGridMonth')"
          >
            月
          </button>
        </div>
        <full-calendar ref="refCalendar" :options="calendarOptions" />
      </div>
      <div @click="$event.stopPropagation()">
        <popup-calendar-detail
          v-if="isOpenDetail"
          :selection="selection.data"
          :position-left="detailLeft"
          :position-top="detailTop"
          :position-bottom="detailBottom"
          :calendar-cell-width="calendarCellWidth"
          :calendar-height="calendarHeight"
          @onClickClose="closeCalendarDetail"
        />
      </div>
      <div @click="$event.stopPropagation()">
        <popup-create-selection
          v-if="isOpenCreateSelection"
          :date="clickedDate"
          :position-left="detailLeft"
          :position-top="detailTop"
          :calendar-cell-width="calendarCellWidth"
          @onClickClose="closeCalendarDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import '@fullcalendar/core';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import venueService from '@/services/venues';
import staffSettingsService from '@/services/staff_settings';
import useGoogleCalendar from '@/composables/useGoogleCalendar';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import SelectStaffs from '@/components/features/venueCalendar/components/SelectStaffs';
import PopupCreateSelection from '@/components/features/venueCalendar/components/PopupCreateSelection';
import PopupCalendarDetail from '@/components/features/popupCalendarDetail/components/PopupCalendarDetail';

export default defineComponent({
  name: 'VenueCalendar',
  components: {
    FullCalendar,
    PopupCalendarDetail,
    PopupCreateSelection,
    LocalPageLoader,
    SelectStaffs,
  },
  setup(props, context) {
    const store = useStore();
    const refCalendar = ref(null);
    const refSelectStaffsWrapper = ref(null);
    const refSelectStaffs = ref(null);
    const staff = store.getters['staff/staff'];
    const {
      baseCalendarOptions,
      isShowExceptMySelection,
      isShowMySelection,
      isShowGoogleEvent,
      staffs,
      googleResourceCalendars,
      parseVenues,
      updateOptions,
    } = useGoogleCalendar('timeGridWeek');
    /**
     * 企業のGoogleカレンダー連携状態
     */
    const isConnectedGoogleCalendar = ref(false);
    /**
     * 選考詳細ポップアップ表示フラグ
     */
    const isOpenDetail = ref(false);
    /**
     * 選考作成ポップアップ表示フラグ
     */
    const isOpenCreateSelection = ref(false);
    /**
     * 初回のカレンダー情報読み込み後までfalse、その後はずっとtrue（カレンダーが初期化されたか判別するのに利用）
     */
    const isInitialized = ref(false);
    /**
     * カレンダー読み込み時に毎回trueになる
     */
    const isLoading = ref(false);
    /**
     * カレンダーメニュー保存中フラグ
     */
    const isUpdateIsOpenMenu = ref(false);
    /**
     * カレンダーメニュー開閉状態フラグ（カレンダーページではDBで保存・管理）
     */
    const isOpenCalendarNav = ref(true);
    /**
     * 選択された選考詳細情報（選考詳細ポップアップで利用）
     */
    const selection = reactive({ data: {} });
    /**
     * 選考詳細ポップアップ表示するleft座標
     */
    const detailLeft = ref(0);
    /**
     * 選考詳細ポップアップ表示するtop座標
     */
    const detailTop = ref(0);
    /**
     * 選考詳細ポップアップ表示するbottom座標
     */
    const detailBottom = ref(0);
    /**
     * 現在のカレンダーのセルの幅
     */
    const calendarCellWidth = ref(0);
    /**
     * 現在のカレンダーのセルの高さ
     */
    const calendarHeight = ref(0);
    /**
     * 現在のカレンダーのセルのView
     */
    const calendarView = ref('timeGridWeek');
    /**
     * スタッフに使う全Hex色コード配列（DBで保存・管理） ex: ['#000000']
     */
    const calendarColors = ref([]);
    const clickedDate = ref('');
    /**
     * fullcalendarのオプション設定
     */
    const calendarOptions = reactive({
      ...baseCalendarOptions,
      ...{
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        headerToolbar: { start: 'prev,next today title', end: '' },
        dayMaxEventRows: true,
        // 高さは月表示カレンダーの高さに合わせているの変更時は注意
        height: 903,
        scrollTime: '07:00',
        nowIndicator: true,
        views: { dayGrid: { dayMaxEventRows: 5 } },
        eventOrder: 'order',
        eventTimeFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
        events: async (info, successCallback, failureCallback) => {
          isOpenDetail.value = false;
          // カレンダー画面ではvenuesから取得
          const events = await fetchVenues(
            store.getters['graduateds/selectedGraduatedId'],
            moment(info.startStr).format('YYYY-MM-DD'),
            moment(info.endStr).format('YYYY-MM-DD'),
          );
          successCallback(events);
        },
        // eventColor: '#378006',
        dayPopoverFormat: date => dayPopoverFormat(date),
        eventClick: info => handleEventClick(info),
        dateClick: info => handleDateClick(info),
      },
    });
    // watch
    watch(
      () => store.getters['graduateds/selectedGraduatedId'],
      () => {
        refCalendar.value.getApi().refetchEvents();
      },
    );

    /**
     * 選考担当データのみ取得（カレンダーページのみで利用）
     */
    const fetchVenues = async (graduatedId, startDate, endDate) => {
      // カレンダーデータAPI問合せ
      if (isLoading.value === true) return;
      isLoading.value = true;
      const res = await venueService.fetchVenues(
        graduatedId,
        startDate,
        endDate,
        isShowExceptMySelection.value,
        isShowMySelection.value,
        isShowGoogleEvent.value,
        isOpenCalendarNav.value,
        isInitialized.value === true,
        staffs.value,
        googleResourceCalendars.value,
      );
      isLoading.value = false;
      if (res.success !== true) {
        // Googleカレンダー以外でエラーの場合、何も表示できない
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        isInitialized.value = true;
        isOpenCalendarNav.value = true;
        return [];
      } else if (
        res.code !== '400' &&
        res.code !== '401' &&
        res.error_message &&
        res.error_message.length > 0
      ) {
        // Googleカレンダーのエラーかつ400.401以外のコードが返ってくる場合、エラーメッセージを表示
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.error_message,
          type: false,
        });
      }
      isOpenCalendarNav.value = res.is_open_calendar_nav;
      calendarColors.value = res.calendar_colors;
      isConnectedGoogleCalendar.value = res.is_connected_google_calendar;
      updateOptions({
        isShowExceptMySelection: res.is_show_except_my_selection,
        isShowMySelection: res.is_show_my_selection,
        isShowGoogleEvent: res.is_show_google_event,
        staffs: res.staffs.map(staff => ({
          id: staff.id,
          lastname: staff.lastname,
          firstname: staff.firstname,
          color: staff.color,
          isSelected: staff.isSelected,
          isShowTooltip: false,
        })),
        googleResourceCalendars: res.google_resource_calendars.map(
          tmpResource => ({
            id: tmpResource.id,
            summary: tmpResource.summary,
            color: tmpResource.color,
            isSelected: tmpResource.isSelected,
            isShowTooltip: false,
          }),
        ),
      });
      isInitialized.value = true;
      return parseVenues(res.venues, staff.id);
    };

    /**
     * 選考が多い場合の全選考表示ポップオーバーのフォーマット
     */
    const dayPopoverFormat = date => {
      isOpenDetail.value = false;
      const weekday = moment(
        `${date.date.year}-${date.date.month + 1}-${date.date.day}`,
      ).format('ddd');
      return `${date.date.month + 1}月${date.date.day}日(${weekday}曜日)の予定`;
    };
    /**
     * 選考クリック時の処理
     */
    const handleEventClick = info => {
      // popupが閉じないようイベント伝播を止める
      info.jsEvent.stopPropagation();
      // 表示中のpopup componentがあれば削除
      closeCalendarDetail();
      nextTick(() => {
        // popupのcomponent削除後
        openCalendarDetail(info);
      });
    };
    /**
     * 選考クリック後、選考詳細ポップアップ表示処理
     */
    const openCalendarDetail = info => {
      // 押下したイベント要素情報
      const elRect = info.el.getBoundingClientRect();
      // 年月タイトル要素の高さ(80px)+曜日欄の高さ(32px)+セルの日付タイトル要素の高さ(22px)+セルのmargin-top(9px)
      const CALENDAR_TOP_MARGIN = 80 + 32 + 22 + 9;
      const calendarRect = refCalendar.value.$el.getBoundingClientRect();
      // カレンダーセル1つの幅(responsive)
      calendarCellWidth.value = calendarRect.width / 7;
      // カレンダーの高さ(1015px)
      calendarHeight.value = calendarRect.height;
      // カレンダー上部からクリック対象までの高さ。
      // popupのx軸座標(クリック対象の右端座標+xスクロール量)
      detailLeft.value =
        elRect.left + calendarCellWidth.value + window.pageXOffset;
      // popupのy軸上付き座標(クリック対象の上端座標-表示位置調整50px+yスクロール量)
      detailTop.value =
        elRect.top - CALENDAR_TOP_MARGIN - 50 + window.pageYOffset;
      // popupのy軸下付き座標(クリック対象の下端座標+表示位置調整110px+yスクロール量)
      detailBottom.value =
        calendarHeight.value - (elRect.bottom + window.pageYOffset) + 110;
      // 対象selectionをセット
      selection.data = info.event;
      // popupのcomponentをmount
      isOpenDetail.value = true;
    };
    /**
     * 日付セルクリック後、選考作成ポップアップ表示処理
     */
    const openCreateSelection = info => {
      // 押下したイベント要素情報
      clickedDate.value = info.date;
      const dayEl = {
        top: info.jsEvent.screenY,
        left: info.dayEl.offsetLeft,
        width: info.dayEl.scrollWidth,
      };
      // 年月タイトル要素の高さ(80px)+曜日欄の高さ(32px)+セルの日付タイトル要素の高さ(22px)+セルのmargin-top(9px)
      const CALENDAR_TOP_MARGIN = 80 + 32 + 22 + 9;
      // カレンダーセル1つの幅(responsive)
      calendarCellWidth.value = dayEl.width;
      // サイドナビ表示時の位置調整
      const extraX = isOpenCalendarNav.value === true ? 240 : 32;
      // popupのx軸座標(クリック対象の右端座標+xスクロール量+サイドナビ表示時の位置調整)
      detailLeft.value =
        dayEl.left + calendarCellWidth.value + window.pageXOffset + extraX;
      // popupのy軸上付き座標(クリック対象の上端座標-表示位置調整50px+yスクロール量)
      detailTop.value =
        dayEl.top - CALENDAR_TOP_MARGIN - 50 + window.pageYOffset - 100;
      selection.data = info.event;
      // popupのcomponentをmount
      isOpenCreateSelection.value = true;
    };
    /**
     * 選考詳細ポップアップ非表示処理
     */
    const closeCalendarDetail = () => {
      isOpenDetail.value = false;
      isOpenCreateSelection.value = false;
    };
    /**
     * スタッフカラー選択ツールチップ非表示処理
     */
    const closeTooltips = () => {
      refSelectStaffs.value.closeTooltips();
      refSelectStaffs.value.closeTooltipsResource();
    };
    /**
     * カレンダー設定メニューの開閉
     */
    const toggleMenu = async val => {
      isUpdateIsOpenMenu.value = true;
      isOpenCalendarNav.value =
        val === true ? true : val === false ? false : !isOpenCalendarNav.value;
      // 初回読み込みを避け、ここでtransitionを付与させる
      refSelectStaffsWrapper.value.style.transition = '0.2s ease-in-out';
      // transitionが終わってから再レンダリング
      setTimeout(() => {
        nextTick(() => refCalendar.value.getApi().render());
      }, 200);
      // カレンダー画面のみ開閉状態保存
      const res = await staffSettingsService.patchIsOpenCalendarNav(
        isOpenCalendarNav.value,
      );
      if (res.data.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return [];
      }
      isUpdateIsOpenMenu.value = false;
    };
    /**
     * カレンダータイプの変更
     */
    const toggleCalendar = key => {
      refCalendar.value.getApi().changeView(key);
      calendarView.value = key;
    };
    /**
     * 日付セルクリック時の処理
     */
    const handleDateClick = info => {
      // popupが閉じないようイベント伝播を止める
      info.jsEvent.stopPropagation();
      // 表示中のpopup componentがあれば削除
      closeCalendarDetail();
      nextTick(() => {
        // popupのcomponent削除後
        openCreateSelection(info);
      });
    };

    return {
      refCalendar,
      refSelectStaffsWrapper,
      refSelectStaffs,
      isOpenDetail,
      isOpenCreateSelection,
      isInitialized,
      isLoading,
      isUpdateIsOpenMenu,
      isOpenCalendarNav,
      isConnectedGoogleCalendar,
      selection,
      calendarOptions,
      detailLeft,
      detailTop,
      detailBottom,
      calendarCellWidth,
      calendarHeight,
      isShowExceptMySelection,
      isShowMySelection,
      isShowGoogleEvent,
      staffs,
      staff,
      googleResourceCalendars,
      calendarColors,
      clickedDate,
      calendarView,
      updateOptions,
      closeCalendarDetail,
      toggleMenu,
      toggleCalendar,
      closeTooltips,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/venue-calendar.scss';

.selections-calendar {
  // ローディング後の高さズレをなくす（月表示カレンダーの高さに合わせているの変更時は注意）
  min-height: 903px;
}

.open-menu-button {
  position: absolute;
  top: 15px;
  left: 20px;
  margin-top: 10px;
  padding: 0px 12px 0 0;
  height: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  > button {
    width: 40px;
    height: 40px;
    margin-top: -5px;
    background-color: #88cbce;
    background-image: url('../../../../assets/img/icon_menu.svg');
    background-size: auto 16px;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 4px;
    cursor: pointer;
  }
}

.toggle-calendar-button {
  position: absolute;
  top: 15px;
  right: 20px;
  margin-top: 10px;
  height: 30px;

  > button {
    width: 40px;
    height: 40px;
    margin-top: -5px;
    color: #333;
    font-weight: normal;
    background-color: #f5f5f5;
    background-size: auto 16px;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    &.is-current {
      font-weight: bold;
      color: #fff;
      background-color: #4698d1;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

// メニュー開閉ボタン設置のためfc-headerをずらす
:deep(.fc-header-toolbar) {
  margin-left: 64px;
}

// ヘッダー文字サイズ
:deep(.fc-col-header-cell-cushion) {
  font-size: 14px;
}

// 終日高さ
:deep(.fc .fc-daygrid-day-frame) {
  min-height: 0;
  .fc-daygrid-day-events {
    margin-bottom: 0;
  }
}

// 時間セル高さ
:deep(.fc-timegrid-slot) {
  height: 24px;
}

// セルスタイル
:deep(.fc-view-harness .fc-event) {
  border-radius: 4px;
}

// イベントレイアウト
:deep(.fc-event-main-frame) {
  .fc-event-time {
    order: 2;
    flex-grow: 1;
    &::after {
      display: none;
    }
  }
  .fc-event-title-container {
    margin-right: 2px;
    order: 1;
    flex-grow: 0;
  }
}

:deep(.fc-view-harness .fc-event.is-google-calendar) {
  .fc-event-main-frame {
    // 月表示終日の文字色
    color: #fff;
  }
}

// セル上ではポインター表示
:deep(.fc-daygrid-da)y,
:deep(.fc-timegrid-slot) {
  cursor: pointer;
}

// 終日イベントスタイル
:deep(.fc-h-event) {
  border: none;
  padding: 0 2px;
}

:deep(.fc-daygrid-block-event) {
  padding-bottom: 2px;
  font-weight: bold;
  &:before {
    display: none !important;
  }
}

// イベントホバー時の色変更
:deep(.fc-view-harness) {
  .fc-event-selected,
  .fc-event:hover {
    background-color: #dcf4ff;
  }
}

// 月表示の終日の●
:deep(.fc-daygrid-block-event) {
  &:before {
    content: '';
    display: inline-block;
    float: left;
    width: 10px;
    height: 10px;
    min-width: 10px;
    max-width: 10px;
    min-height: 10px;
    max-height: 10px;
    padding: 0;
    margin: 2.5px 3px 0 0;
    border-width: 1px !important;
    border-radius: 10px;
    border-color: #000;
  }
}

// 月表示のカレンダーセルスタイル
:deep(.fc-view-harness .fc-daygrid-day) {
  height: 130px;
  padding: 8px;
}

// 週表示終日セル
:deep(.fc-view-harness .fc-daygrid-day) {
  height: 40px;
}

// 週表示限定
:deep(.fc-timegrid) {
  // 文字色
  .fc-event-time {
    color: #fff;
  }
  .fc-event-title {
    font-weight: bold;
    color: #fff;
  }
  .is-ffffff {
    .fc-event-time {
      color: #333;
    }
    .fc-event-title {
      color: #333;
    }
  }
  // 終日●削除
  .fc-daygrid-block-event::before {
    display: none;
  }
  // 終日行
  .fc-timegrid-axis.fc-scrollgrid-shrink {
    .fc-timegrid-axis-frame.fc-scrollgrid-shrink-frame.fc-timegrid-axis-frame-liquid
      > span {
      min-height: 40px;
      padding: 12px 10px;
      margin: 0 auto 0 0;
    }
  }
}
</style>
