import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import esLocale from '@fullcalendar/core/locales/es';
import jaLocale from '@fullcalendar/core/locales/ja';

import { ref } from 'vue';

export default function useGoogleCalendar(
  initialView = '',
  initialDate = null,
  height = 'auto',
) {
  /**
   * カレンダーに流し込むイベント配列
   */
  const baseCalendarOptions = {
    initialView,
    timeZone: 'local',
    locales: [esLocale, jaLocale],
    locale: 'ja',
    height,
    headerToolbar: { start: 'prev,next title', end: '' },
    initialDate,
  };
  /**
   * 「自分以外の選考を表示」フラグ（カレンダーページのみで利用。DBで保存・管理）
   */
  const isShowExceptMySelection = ref(true);
  /**
   * 自分の選考表示フラグ（カレンダーページではDBで保存・管理）
   */
  const isShowMySelection = ref(true);
  /**
   * 自分の選考表示フラグ（カレンダーページではDBで保存・管理）
   */
  const isShowGoogleEvent = ref(true);
  /**
   * スタッフ情報（カレンダーページではDBで保存・管理）
   */
  const staffs = ref([]);
  /**
   * 会議室情報
   */
  const googleResourceCalendars = ref([]);
  // methods
  /**
   * APIから取得した選考データ・Googleカレンダーイベントをカレンダー表示用にパース
   * @param {Object[]} venues 会場情報配列
   * @param {boolean} myStaffId 自分のスタッフID
   * @param {boolean} hasGoogleCalendarPrefix タイトルに(G)を付与する場合true
   * @return {Object[]} venues カレンダー用の選考・会場配列
   */
  const parseVenues = (venues, myStaffId, hasGoogleCalendarPrefix = true) => {
    // APIから取得したvenueデータのパース
    let order = 0;
    return (
      venues
        .map(venue => {
          order += 1;
          const startDateTime =
            venue.is_all_day === true
              ? venue.date
              : `${venue.date} ${venue.start_time}`;
          const endDateTime =
            venue.is_all_day === true
              ? venue.end_date
              : `${venue.end_date ? venue.end_date : venue.date} ${
                  venue.end_time
                }`;
          const startDate = moment(startDateTime, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY年MM月DD日 (ddd曜日)',
          );
          const endDate = moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY年MM月DD日 (ddd曜日)',
          );
          let duration = '';
          if (venue.is_all_day === true) {
            // 終日イベントの場合、終了日が実際の日付と1日多く取得されるので調整する
            const fixedEndDate = moment(endDateTime, 'YYYY-MM-DD HH:mm:ss')
              .subtract(1, 'day')
              .format('YYYY年MM月DD日 (ddd曜日)');
            duration =
              startDate === fixedEndDate
                ? startDate
                : `${startDate} 〜 ${fixedEndDate}`;
          } else {
            // 同日・日またぎイベントの調整
            duration = `${
              startDate !== endDate
                ? `${startDate.slice(0, 11)} `
                : `${startDate} `
            }${moment(startDateTime, 'YYYY-MM-DD HH:mm:ss').format(
              'HH:mm',
            )} 〜 ${
              startDate !== endDate ? `${endDate.slice(0, 11)} ` : ''
            }${moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}`;
          }

          let className = [];
          let color = '#ffffff';
          // 選考のスタッフデータ（Googleカレンダーはvenue。staff_idがない）
          let id = null;
          let resourceId = venue.staff_id;
          let isGoogleCalendar = false;
          let title = null;
          let applicants = [];
          let venueStaffs = [];
          let notice = null;
          let description = null;
          let zoomId = null;
          let zoomHostName = null;
          let zoomStartUrl = null;
          let zoomJoinUrl = null;
          if (venue.selection.id) {
            // 選考が存在する場合はMOCHICAのイベント
            id = venue.selection.id;
            title = venue.selection.title;
            applicants = venue.selection.applicants;
            venueStaffs = venue.selection.staffs;
            notice = venue.selection.notice;
            description = venue.selection.description;
            zoomId = venue.selection.zoom_id;
            zoomHostName = venue.selection.zoom_host_name;
            zoomStartUrl = venue.selection.zoom_start_url;
            zoomJoinUrl = venue.selection.zoom_join_url;
            // 応募者がいない場合は文字はグレーに変更
            if (applicants.length === 0) className.push('is-no-applicant');
            if (venue.staff_id === myStaffId) {
              // 自分の色は固定
              color = '#4698d1';
            } else if (venue.staff_id !== null) {
              // 担当者が存在する場合、選択されていれば色を取得
              let targetStaff = staffs.value.find(
                tmpStaff => tmpStaff.id === venue.staff_id,
              );
              if (targetStaff) color = targetStaff.color;
            }
          } else {
            isGoogleCalendar = true;
            id = venue.id;
            const eventSummary = venue.google_calendar_event_summary
              ? venue.google_calendar_event_summary
              : '予定あり';
            title =
              hasGoogleCalendarPrefix === true
                ? `(G) ${eventSummary}`
                : eventSummary;
            // 選考が存在しなければGoogleカレンダーイベント
            if (applicants.length === 0) className.push('is-google-calendar');
            if (!venue.staff_id) {
              // 会議室の場合
              resourceId = venue.resource_id;
              color = googleResourceCalendars.value.find(
                v => v.id === resourceId,
              ).color;
            } else if (venue.staff_id === myStaffId) {
              // 自分の色は固定
              color = '#4698d1';
            } else if (venue.staff_id !== null) {
              // 担当者が存在する場合、選択されていれば色を取得
              let targetStaff = staffs.value.find(
                tmpStaff => tmpStaff.id === venue.staff_id,
              );
              if (targetStaff) color = targetStaff.color;
            }
          }
          // カラーのクラス情報を渡す
          className.push(`is-${color.slice(1, 7)}`);
          return {
            id,
            resourceId,
            title,
            start: startDateTime,
            end: endDateTime,
            order,
            className,
            extendedProps: {
              venueId: venue.id,
              isGoogleCalendar,
              date: duration,
              notice,
              description,
              zoomId,
              zoomHostName,
              zoomStartUrl,
              zoomJoinUrl,
              applicants,
              staffs: venueStaffs,
              color,
            },
          };
        })
        // Googleイベント未選択時のGoogleイベントのフィルタリング
        .filter(
          venue =>
            isShowGoogleEvent.value === true ||
            (isShowGoogleEvent.value === false &&
              venue.extendedProps.isGoogleCalendar !== true),
        )
    );
  };
  /**
   * カレンダー設定を更新後、DB保存してカレンダーを更新
   * @param {{
   *   isShowExceptMySelection: boolean | undefined,
   *   isShowMySelection: boolean,
   *   isShowGoogleEvent: boolean,
   *   staffs: Object[],
   * }} args 引数
   * @param {Object} refsCalendar イベントを再取得する場合はカレンダーのrefデータを渡す
   */
  const updateOptions = (args, refsCalendar = null) => {
    if (
      args.isShowExceptMySelection === true ||
      args.isShowExceptMySelection === false
    ) {
      isShowExceptMySelection.value = args.isShowExceptMySelection;
    }
    if (args.isShowMySelection === true || args.isShowMySelection === false) {
      isShowMySelection.value = args.isShowMySelection;
    }
    if (args.isShowGoogleEvent === true || args.isShowGoogleEvent === false) {
      isShowGoogleEvent.value = args.isShowGoogleEvent;
    }
    if (args.staffs) staffs.value = cloneDeep(args.staffs);
    if (args.googleResourceCalendars) {
      googleResourceCalendars.value = cloneDeep(args.googleResourceCalendars);
    }
    if (refsCalendar !== null) refsCalendar.getApi().refetchEvents();
  };
  /**
   * スタッフ選択をリセット
   */
  const resetSelectedStaff = () => {
    staffs.value = staffs.value.map(staff => {
      staff.isSelected = false;
      return staff;
    });
  };
  /**
   * 対象時刻をハイライト処理（日別カレンダーのみ）
   */
  const highlightTargetTimeRage = (props, refCalendar, event) => {
    if (!props.highlightStartTime || !props.highlightLastTime) return;
    // ハイライト対象の全セル要素を取得
    const labelEls = refCalendar.$el.getElementsByClassName(
      'fc-timegrid-slot-label',
    );
    const els = refCalendar.$el.getElementsByClassName('fc-timegrid-slot-lane');
    const startIndex =
      parseInt(props.highlightStartTime.split(':')[0], 10) * 2 +
      parseInt(props.highlightStartTime.split(':')[1], 10) / 30;
    const endIndex =
      parseInt(props.highlightLastTime.split(':')[0], 10) * 2 +
      parseInt(props.highlightLastTime.split(':')[1], 10) / 30 -
      1 / 60;
    if (moment(event.startStr).format('YYYY-MM-DD') === props.initialDate) {
      // 対象日付の場合
      els.forEach((el, i) => {
        // 対象時間帯にのみhighlightクラスを付与
        if (i >= Math.floor(startIndex) && i <= Math.floor(endIndex)) {
          el.classList.add('is-highlight');
          labelEls[i].classList.add('is-highlight');
        } else {
          el.classList.remove('is-highlight');
          labelEls[i].classList.remove('is-highlight');
        }
      });
    } else {
      // 対象日付以外の場合、全てのhighlightクラスを削除
      els.forEach(el => el.classList.remove('is-highlight'));
      labelEls.forEach(el => el.classList.remove('is-highlight'));
    }
  };

  return {
    baseCalendarOptions,
    isShowExceptMySelection,
    isShowMySelection,
    isShowGoogleEvent,
    staffs,
    googleResourceCalendars,
    // methods
    parseVenues,
    updateOptions,
    resetSelectedStaff,
    highlightTargetTimeRage,
  };
}
