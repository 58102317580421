<template>
  <section>
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">新着情報</span>
      </template>
    </bread-crumb-list>

    <ul :options="swiperOption" class="tabs_wrapper">
      <li
        v-for="(tab, i) in tabs"
        :key="`tab_${tab.id}`"
        :class="{ active: tabMode === i }"
      >
        <dl :class="`tab${i}`">
          <dt>
            {{ i === 0 ? '未読' : i === 1 ? '直近3日間の選考状況' : '' }}
          </dt>
          <dd
            :class="{ 'is-current': tabMode === tab.id }"
            @click="
              $route.name !== tab.routeName
                ? $router.push({ name: tab.routeName })
                : () => {}
            "
          >
            <img :src="tab.icon" alt="" />
            {{ tab.label }}
          </dd>
        </dl>
      </li>
    </ul>
    <div v-for="tab in tabs" :key="`applicants_${tab.id}`">
      <applicants-unread-page-content
        v-if="tabMode === tab.id"
        :title="tab.label"
        :columns="tab.columns"
        :init-method="tab.initMethod"
        :fetch-method="tab.fetchMethod"
      />
    </div>
  </section>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';

import iconMessageSvg from '@/assets/img/icon_message.svg';
import twoMenBlueSvg from '@/assets/img/two_men_blue.svg';
import manBlueSvg from '@/assets/img/man_blue.svg';
import iconFileWhiteSvg from '@/assets/img/icon_file_white.svg';
import Breakpoints from '@/defines/breakpoints';
import applicantService from '@/services/applicant';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import ApplicantsUnreadPageContent from '@/components/page/applicants/unreadpage/components/ApplicantsUnreadPageContent';

export default defineComponent({
  name: 'ApplicantsUnreadPage',
  components: {
    BreadCrumbList,
    ApplicantsUnreadPageContent,
  },
  setup(props, context) {
    const route = useRoute();
    const store = useStore();
    // computed
    const tabs = computed(() => [
      {
        id: 0,
        routeName: 'ApplicantsUnreadMessages',
        label: '未読メッセージ',
        icon: iconMessageSvg,
        columns: [
          { id: 'unread_line_count', label: 'LINE未読' },
          { id: 'unread_mail_count', label: 'メール未読' },
          { id: 'name', label: '名前' },
          { id: 'name_kana', label: 'セイメイ' },
          { id: 'message_created_at', label: '新着メッセージ日時' },
          { id: 'contact_type_status', label: '連絡方法' },
          { id: 'gender_description', label: '性別' },
          { id: 'school', label: '学校名' },
          { id: 'faculty', label: '学部' },
          { id: 'department', label: '学科' },
          { id: 'department_category_description', label: '文理' },
          { id: 'email', label: 'メールアドレス' },
          { id: 'tel', label: '電話番号' },
          { id: 'channel', label: '登録経路' },
          { id: 'created_at', label: '登録日' },
          { id: 'staff_name', label: '担当者' },
        ],
        initMethod: async () => {
          const res = await applicantService.getUnreadMessagesSummary();
          return res;
        },
        fetchMethod: async graduatedYear => {
          const res =
            await applicantService.getUnreadMessagesGraduatedYearsId(
              graduatedYear,
            );
          return {
            success: res.success,
            message: res.message,
            list: res.list.map(v => ({
              ...v,
              name: `${v.lastname ? v.lastname : ''}${
                v.firstname ? v.firstname : ''
              }`,
              name_kana: `${v.lastname_kana ? v.lastname_kana : ''}${
                v.firstname_kana ? v.firstname_kana : ''
              }`,
              contact_type_status:
                v.contact_type_id === 1
                  ? 'メール'
                  : v.contact_type_id === 2 && v.line_block === true
                    ? 'ブロック中'
                    : 'LINE',
              gender_description: v.gender.description,
              department_category_description:
                v.department_category === null
                  ? 'その他'
                  : v.department_category.description,
              staff_name: `${v.staff.lastname ? v.staff.lastname : ''}${
                v.staff.firstname ? v.staff.firstname : ''
              }`,
              message_created_at: v.message_created_at
                ? moment(v.message_created_at).format('YYYY年MM月DD日 HH:mm')
                : '',
              created_at: v.created_at
                ? moment(v.created_at).format('YYYY年MM月DD日 HH:mm')
                : '',
            })),
          };
        },
      },
      {
        id: 1,
        routeName: 'ApplicantsSelectionReserves',
        label: '直近の選考予約',
        icon: twoMenBlueSvg,
        columns: [
          { id: 'created_at', label: '予約日' },
          { id: 'name', label: '応募者名' },
          { id: 'selection_name', label: '選考' },
          { id: 'venue_name', label: '会場' },
          { id: 'venue_date', label: '日付' },
          { id: 'start_time', label: '開催時間' },
        ],
        initMethod: async () => {
          const res = await applicantService.getSelectionReservesSummary();
          return res;
        },
        fetchMethod: async graduatedYear => {
          const res =
            await applicantService.getSelectionReservesGraduatedYearsId(
              graduatedYear,
            );
          return {
            success: res.success,
            message: res.message,
            list: res.list.map(v => ({
              ...v,
              name: `${v.lastname ? v.lastname : ''}${
                v.firstname ? v.firstname : ''
              }`,
              created_at: v.created_at
                ? moment(v.created_at).format('YYYY年MM月DD日 HH:mm')
                : '',
              venue_date: v.venue_date
                ? moment(v.venue_date).format('MM月DD日')
                : '',
              start_time: `${v.start_time.split(':')[0]}:${
                v.start_time.split(':')[1]
              }`,
            })),
          };
        },
      },
      {
        id: 2,
        routeName: 'ApplicantsEnteredApplicants',
        label: '直近の応募者',
        icon: manBlueSvg,
        columns: [
          { id: 'entered_at', label: 'LINEエントリー日時' },
          { id: 'name', label: '名前' },
          { id: 'name_kana', label: '姓名' },
          { id: 'contact_type_status', label: '連絡方法' },
          { id: 'gender_description', label: '性別' },
          { id: 'school', label: '学校名' },
          { id: 'faculty', label: '学部' },
          { id: 'department', label: '学科' },
          { id: 'department_category_description', label: '文理' },
          { id: 'email', label: 'メールアドレス' },
          { id: 'tel', label: '電話番号' },
          { id: 'channel', label: '登録経路' },
          { id: 'created_at', label: '登録日' },
          { id: 'staff_name', label: '担当者' },
        ],
        initMethod: async () => {
          const res = await applicantService.getEnteredApplicantsSummary();
          return res;
        },
        fetchMethod: async graduatedYear => {
          const res =
            await applicantService.getEnteredApplicantsGraduatedYearsId(
              graduatedYear,
            );
          return {
            success: res.success,
            message: res.message,
            list: res.list.map(v => ({
              ...v,
              name: `${v.lastname ? v.lastname : ''}${
                v.firstname ? v.firstname : ''
              }`,
              name_kana: `${v.lastname_kana ? v.lastname_kana : ''}${
                v.firstname_kana ? v.firstname_kana : ''
              }`,
              contact_type_status:
                v.contact_type_id === 1
                  ? 'メール'
                  : v.contact_type_id === 2
                    ? 'LINE'
                    : 'ブロック中',
              gender_description: v.gender.description,
              department_category_description:
                v.department_category.description,
              staff_name: `${v.staff.lastname}${v.staff.firstname}`,
              created_at: v.created_at
                ? moment(v.created_at).format('YYYY年MM月DD日 HH:mm')
                : '',
              entered_at: v.entered_at
                ? moment(v.entered_at).format('YYYY年MM月DD日 HH:mm')
                : '',
            })),
          };
        },
      },
      {
        id: 3,
        routeName: 'ApplicantsUploads',
        label: '直近のファイル',
        icon: iconFileWhiteSvg,
        columns: [
          { id: 'created_at', label: 'アップロード日' },
          { id: 'name', label: '名前' },
          { id: 'original_filename', label: 'ファイル名' },
        ],
        initMethod: async () => {
          const res = await applicantService.getEnteredUploadsSummary();
          return res;
        },
        fetchMethod: async graduatedYear => {
          const res =
            await applicantService.getUploadsGraduatedYearsId(graduatedYear);
          return {
            success: res.success,
            message: res.message,
            list: res.list.map(v => ({
              ...v,
              name: `${v.lastname ? v.lastname : ''}${
                v.firstname ? v.firstname : ''
              }`,
              created_at: v.created_at
                ? moment(v.created_at).format('YYYY年MM月DD日 HH:mm')
                : '',
            })),
          };
        },
      },
    ]);
    const swiperOption = computed(() => ({
      slidesPerView: 'auto',
      centeredSlides: false,
      SimulateTouch: false,
      noSwiping: true,
      noSwipingClass: 'local-nav',
      on: {
        init() {
          const winWid = window.innerWidth;
          if (winWid <= Breakpoints.MIN_PC_SIZE) {
            this.params.noSwiping = false;
            this.update();
          }
        },
      },
    }));

    // ref
    const tabMode = ref(0);

    // watch
    watch(
      () => route,
      async () => {
        tabMode.value = route.meta.mode;
        store.dispatch('page/SET_LOADED');
      },
    );

    const init = async () => {
      tabMode.value = route.meta.mode;
      await store.dispatch('page/SET_LOADED');
    };

    watch(route, async () => await init());

    // lifecycle
    onBeforeMount(async () => await init());

    return { tabMode, tabs, swiperOption };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.tabs_wrapper {
  display: flex;
  dl {
    margin: 20px 0 0 0;
    &.tab0 {
      margin-left: 20px;
      margin-right: 20px;
    }
    &.tab3 {
      margin-right: 20px;
    }
    > dt {
      height: 12px;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 12px;
      color: #777777;
    }
    > dd {
      position: relative;
      width: 170px;
      height: 56px;
      padding: 18px 15px 18px 15px;
      border-radius: 4px 4px 0px 0px;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      color: #000;
      background: #fff;
      border: 1px solid #e2e2e2;
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #e2e2e2;
        z-index: 1;
        pointer-events: none;
      }
      &.is-current {
        color: #1c91c8;
        background: #f3f9fc;
        border: 1px solid #d7ebf5;
        &::after {
          background-color: #1c91c8;
        }
      }
      > img {
        width: auto;
        height: 14px;
        vertical-align: -2px;
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .tabs_wrapper {
    display: flex;
    dl {
      &.tab0 {
        margin-left: 30px;
        margin-right: 30px;
      }
      &.tab3 {
        margin-right: 30px;
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .tabs_wrapper {
    dl {
      &.tab0 {
        margin-left: 5vw;
        margin-right: 5vw;
      }
      &.tab3 {
        margin-right: 5vw;
      }
    }
  }
}
</style>
