<template>
  <modal :is-view="isView" @close="close">
    <template #rightTop>
      <div class="right-top" @click="close">
        <img slot="icon" class="icon" src="@/assets/img/close_white.svg" alt />
      </div>
    </template>

    <template #content>
      <div>
        <search-applicant-preset
          v-show="pageMode && pageMode !== 'searchFilter'"
          :page-mode="pageMode"
          :edit-preset-id="editPresetId"
          :edit-preset-name="editPresetName"
          :edit-preset-search-filter-object="editPresetSearchFilterObject.data"
          :staffs="staffs"
          :events="events"
          :tag-groups="tagGroups"
          :survey-sheet-id="surveySheetId"
          :surveys-titles="surveysProps"
          :or-flag="orFlag"
          :search-detail-filters="searchDetailFilters"
          :search-flag-groups="searchFlagGroups"
          :search-flag-group-options="searchFlagGroupOptions"
          :search-surveys="searchSurveys"
          :search-survey-texts="searchSurveyTexts"
          :update-current-preset="updateCurrentPreset"
          :current-preset-id="currentPreset.data.id"
          @openPresetEditModal="openPresetEditModalLocal"
          @openPresetDeleteDialogModal="openPresetDeleteDialogModal"
          @openPresetEditDialogModal="openPresetEditDialogModalLocal"
          @openPresetListModal="openPresetListModal"
          @openSearchFilter="openSearchFilter"
          @loadPreset="loadPreset"
          @close="openSearchFilter"
          @switchSearchFilter="switchSearchFilterLocal"
          @fetchSearchFilterPresets="$emit('fetchSearchFilterPresets', $event)"
          @updateCurrentPreset="updateCurrentPreset"
        />
        <div
          v-show="!pageMode || pageMode === 'searchFilter'"
          class="modal-window -typeB"
        >
          <div class="header">
            <p class="title">応募者詳細検索</p>
            <button class="btn-searchSwitch" @click="searchFormDetailSwitch">
              <img
                slot="icon"
                class="icon"
                src="@/assets/img/arrow_wind_double_white.svg"
                alt
              />
              通常検索に切り替え
            </button>
          </div>
          <div class="body">
            <div class="search_filter_controllers">
              <p v-if="currentPreset.data.id">
                <b>選択中のプリセット：</b>
                {{ currentPreset.data.name }}
              </p>
              <div>
                <button class="btn btn-unavailable" @click="reset">
                  検索条件をクリア
                </button>
                <button
                  v-if="hasPreset === true"
                  class="btn btn-availability"
                  @click="openPresetListModal"
                >
                  プリセット一覧
                </button>
              </div>
            </div>
            <div class="ar-option">
              <label>
                <input
                  id="el-input__inner-checkbox"
                  v-model="orFlag"
                  class="or_flag_checkbox"
                  type="checkbox"
                  name="or_flag"
                />
                OR 検索を有効にする
              </label>
            </div>

            <search-applicants-detail-filters
              ref="refFormDetailFilters"
              :search-detail-filters="searchDetailFilters"
              :selectable-search-detail-filters="selectableSearchDetailFilters"
              :events="events"
              :staffs="staffs"
              :display-activated-at="displayActivatedAt"
              :attendance-id="attendanceId"
              :add-search-detail-filter="addSearchDetailFilter"
              :remove-search-detail-filter="removeSearchDetailFilter"
              :update-search-detail-filter-data="updateSearchDetailFilterData"
              :update-search-detail-filter-option="
                updateSearchDetailFilterOption
              "
              :update-progress-status-ids="updateProgressStatusIds"
              :update-attendance-id="updateAttendanceId"
              :get-disabled-by-select-option="getDisabledBySelectOption"
            />

            <search-flag-group-detail
              v-if="flagMode"
              ref="refSearchFlagGroupDetail"
              class="ar-flagComponent"
              :is-detail="true"
              :search-flag-groups="searchFlagGroups"
              :visible-add-flags="visibleAddFlags"
              :visible-flag-group-ids="visibleFlagGroupIds"
              :visible-flag-ids="visibleFlagIds"
              :visible-flag-group-options="visibleFlagGroupOptions"
              :selectable-search-flag-group-options-default="
                selectableSearchFlagGroupOptionsDefault
              "
              :get-flag-groups="getFlagGroups"
              :get-flags="getFlags"
              :get-flag-name-by-id="getFlagNameById"
              :update-flag-group-id="updateFlagGroupId"
              :update-flag-id="updateFlagId"
              :add-flag-group-row="addFlagGroupRow"
              :remove-flag-group-row="removeFlagGroupRow"
              :open-drop-down-flag-group-ids="openDropDownFlagGroupIds"
              :open-drop-down-flag-id="openDropDownFlagId"
              :open-drop-down-flag-group-option="openDropDownFlagGroupOption"
              :close-all-flag-groups-drop-down="closeAllFlagGroupsDropDown"
              :close-all-flag-group-options-drop-down="
                closeAllFlagGroupOptionsDropDown
              "
              :search-flag-group-options="searchFlagGroupOptions"
              :add-flag-group-option="addFlagGroupOption"
              :remove-flag-group-option="removeFlagGroupOption"
              :update-flag-group-option="updateFlagGroupOption"
            />
            <search-survey
              v-else
              class="ar-flagComponent"
              :search-surveys="searchSurveys"
              :search-survey-texts="searchSurveyTexts"
              :visible-add-text="visibleAddText"
              :visible-survey-ids="visibleSurveyIds"
              :get-surveys="getSurveys"
              :update-search-survey="updateSearchSurvey"
              :update-search-survey-texts="updateSearchSurveyTexts"
              :add-surveys-raw="addSurveysRaw"
              :remove-surveys-row="removeSurveysRow"
              :open-drop-down-survey-ids="openDropDownSurveyIds"
              :close-search-survey-drop-down="closeSearchSurveyDropDown"
            />

            <div class="ar-actionBtns">
              <div class="btn btn-availability" @click="onSearch">
                <img class="icon" src="@/assets/img/search_white.svg" alt />
                上記の条件で検索
              </div>
              <div class="two_buttons">
                <button-base
                  class="btn btn-border"
                  button-text="プリセットを上書きして検索"
                  :is-loading="isLoading"
                  :is-disabled="!currentPreset.data.id"
                  :is-outlined="true"
                  :rejected-role-ids="[3]"
                  :rejected-admin-ids="[2]"
                  @click="updatePresetAndSearch"
                />

                <div class="btn btn-unavailable" @click="resetAndSearch">
                  検索をリセット
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';

import { onResizeModal } from '@/utils/modal-resize';
import surveyService from '@/services/survey';
import SearchFilterPresetsService from '@/services/search_filter_presets';
import useSearchFlagGroups from '@/composables/useSearchFlagGroups';
import useSearchFlagGroupOptions from '@/composables/useSearchFlagGroupOptions';
import useSearchDetailFilters from '@/components/features/searchApplicants/composables/useSearchDetailFilters';
import useSearchSurveys from '@/components/features/searchApplicants/composables/useSearchSurveys';
import useSearchApplicantPreset from '@/components/features/searchApplicants/composables/useSearchApplicantPreset';
import Modal from '@/components/ui/modals/components/Modal';
import SearchApplicantsDetailFilters from '@/components/features/searchApplicants/components/SearchApplicantsDetailFilters';
import SearchFlagGroupDetail from '@/components/features/searchApplicants/components/SearchFlagGroupDetail';
import SearchSurvey from '@/components/features/searchApplicants/components/SearchSurvey';
import SearchApplicantPreset from '@/components/features/searchApplicants/components/SearchApplicantPreset';

export default defineComponent({
  name: 'SearchApplicantsDetail',
  components: {
    Modal,
    SearchApplicantsDetailFilters,
    SearchFlagGroupDetail,
    SearchSurvey,
    SearchApplicantPreset,
  },
  props: {
    isView: {
      type: Boolean,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    staffs: {
      type: Array,
      required: true,
    },
    flagMode: {
      type: Boolean,
      default: true,
    },
    displayActivatedAt: {
      type: Boolean,
      default: true,
    },
    tagGroups: {
      type: Array,
      default: () => [],
    },
    surveySheetId: {
      type: Number,
      default: null,
    },
    surveysProps: {
      type: Array,
      default: () => [],
    },
    formDefault: {
      type: Object,
      default: () => {},
    },
    hasPreset: {
      type: Boolean,
      default: false,
    },
    changeFlagMode: {
      type: Function,
      default: () => () => {},
    },
  },
  emits: [
    'onClose',
    'onSearch',
    'onReset',
    'updateIsShowSelectableApplicantTable',
    'updateSurveyTitles',
    'fetchSearchFilterPresets',
  ],
  setup(props, context) {
    const store = useStore();
    const refFormDetailFilters = ref(null);
    const refSearchFlagGroupDetail = ref(null);
    const orFlag = ref(false);
    const isLoading = ref(false);
    const closeAllDropDown = () => {
      refFormDetailFilters.value.closeAllDropDown();
    };
    const {
      searchDetailFilters,
      selectableSearchDetailFilters,
      attendanceId,
      addSearchDetailFilter,
      removeSearchDetailFilter,
      updateSearchDetailFilterOption,
      updateSearchDetailFilterData,
      updateProgressStatusIds,
      updateAttendanceId,
      resetSearchDetailFilters,
      getDisabledBySelectOption,
      createSearchBody,
      loadSearchDetailFiltersPreset,
    } = useSearchDetailFilters();
    const {
      searchFlagGroups,
      visibleAddFlags,
      visibleFlagGroupIds,
      visibleFlagIds,
      getFlagGroups,
      resetSearchFlagGroups,
      getFlags,
      getFlagNameById,
      updateFlagGroupId,
      updateFlagId,
      addFlagGroupRow,
      removeFlagGroupRow,
      openDropDownFlagGroupIds,
      openDropDownFlagId,
      closeAllFlagGroupsDropDown,
      loadFlagGroupsPreset,
    } = useSearchFlagGroups(store, [], closeAllDropDown);
    const {
      searchFlagGroupOptions,
      visibleFlagGroupOptions,
      selectableSearchFlagGroupOptionsDefault,
      addFlagGroupOption,
      removeFlagGroupOption,
      updateFlagGroupOption,
      resetFlagGroupOptions,
      openDropDownFlagGroupOption,
      closeAllFlagGroupOptionsDropDown,
      loadFlagGroupOptionPreset,
    } = useSearchFlagGroupOptions();
    const {
      searchSurveys,
      searchSurveyTexts,
      visibleAddText,
      visibleSurveyIds,
      getSurveys,
      resetSearchSurveys,
      resetSelectableSurveys,
      updateSearchSurvey,
      updateSearchSurveyTexts,
      addSurveysRaw,
      removeSurveysRow,
      openDropDownSurveyIds,
      closeSearchSurveyDropDown,
      loadSurveyPreset,
    } = useSearchSurveys(props.surveysProps, closeAllDropDown);
    const {
      pageMode,
      presets,
      currentPreset,
      editPresetId,
      editPresetName,
      editPresetSearchFilterObject,
      openPresetListModal,
      openPresetEditModal,
      openPresetDeleteDialogModal,
      openPresetEditDialogModal,
      openSearchFilter,
      openSwitchSearchFilterDialogModal,
      switchSearchFilter,
      updateEditPresetSearchFilterObject,
      updateCurrentPreset,
    } = useSearchApplicantPreset();

    // watch
    watch(
      () => props.isView,
      async val => {
        if (val === true) {
          store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
          nextTick(() => localResizeModal());
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );
    watch(
      () => props.surveysProps,
      val => {
        resetSelectableSurveys(val);
      },
    );

    //methods
    // ...mapActions('company', ['FETCH_COMPANY_TAG_GROUP']),
    const searchFormDetailSwitch = () => {
      openSwitchSearchFilterDialogModal();
    };
    const switchSearchFilterLocal = args => {
      switchSearchFilter({
        choice: args.choice,
        callbackFunction: () => {
          reset();
          context.emit('searchFormDetailSwitch', false);
        },
      });
    };
    const close = () => {
      openSearchFilter();
      context.emit('onClose');
    };
    const onSearch = (e, req = null) => {
      // ステータス複数選択時or検索は不可
      const searchMultiStatusIdsFilter = searchDetailFilters.value.find(
        filter =>
          filter.keyName === 'progressStatusIds' && filter.data.length >= 2,
      );
      if (orFlag.value === true && searchMultiStatusIdsFilter) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: false,
          message: 'OR検索を使用するには、ステータスは1つにしてください。',
        });
      } else {
        const args =
          req === null
            ? {
                searchBody: createSearchBody(
                  searchFlagGroups.value,
                  searchFlagGroupOptions.value,
                  searchSurveys.value,
                  searchSurveyTexts.value,
                ),
                orFlag: orFlag.value,
                searchFilterObject: {
                  search_detail_filters: searchDetailFilters.value,
                  search_flag_groups: searchFlagGroups.value,
                  search_flag_group_options: searchFlagGroupOptions.value,
                  search_surveys: searchSurveys.value,
                  search_survey_texts: searchSurveyTexts.value,
                  sheet_id: props.surveySheetId,
                  or_flag: orFlag.value,
                },
              }
            : {
                searchBody: createSearchBody(
                  req.searchFlagGroups,
                  req.searchFlagGroupOptions,
                  req.searchSurveys,
                  req.searchSurveyTexts,
                  req.searchDetailFilters,
                ),
                orFlag: req.orFlag,
                searchFilterObject: {
                  search_detail_filters: req.searchDetailFilters,
                  search_flag_groups: req.searchFlagGroups,
                  search_flag_group_options: req.searchFlagGroupOptions,
                  search_surveys: req.searchSurveys,
                  search_survey_texts: req.searchSurveyTexts,
                  sheet_id: req.surveySheetId,
                  or_flag: req.orFlag,
                },
              };
        context.emit('onSearch', args);
      }
    };
    const updatePresetAndSearch = async () => {
      // プリセット検索条件上書き
      isLoading.value = true;
      const res = await SearchFilterPresetsService.updateSearchFilterPresets(
        currentPreset.data.id,
        {
          search_filter_obj: {
            or_flag: orFlag.value,
            search_detail_filters: searchDetailFilters.value,
            search_flag_groups: searchFlagGroups.value,
            search_flag_group_options: searchFlagGroupOptions.value,
            search_surveys: searchSurveys.value,
            search_survey_texts: searchSurveyTexts.value,
            sheet_id: props.surveySheetId,
          },
        },
      );
      isLoading.value = false;
      if (res.success === true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'プリセット条件が上書きされました',
          type: res.success,
        });
        context.emit('fetchSearchFilterPresets');
        await onSearch();
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
      }
    };
    const reset = () => {
      orFlag.value = false;
      resetSearchDetailFilters();
      resetSearchFlagGroups();
      resetFlagGroupOptions();
      resetSearchSurveys();
      updateCurrentPreset({});
    };
    const resetAndSearch = () => {
      reset();
      context.emit('onReset');
      // -noscrollが残ってしまうので除去する
      const bodyEl = document.querySelector('body');
      bodyEl.classList.remove('-noscroll');
      bodyEl.style.removeProperty('top');
    };
    const openPresetEditModalLocal = ({
      presetId,
      presetName,
      presetSearchFilterObject,
    }) => {
      const resValidate = openPresetEditModal({
        presetId,
        presetName,
        presetSearchFilterObject:
          // プリセット新規作成時は現在の絞り込み条件、プリセット編集時は保存された検索条件を渡す
          presetSearchFilterObject !== null
            ? presetSearchFilterObject
            : {
                search_detail_filters: searchDetailFilters.value,
                search_flag_groups: searchFlagGroups.value,
                search_flag_group_options: searchFlagGroupOptions.value,
                search_surveys: searchSurveys.value,
                search_survey_texts: searchSurveyTexts.value,
                sheet_id: props.surveySheetId,
              },
      });
      if (resValidate.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: resValidate.message,
          type: false,
        });
      }
    };
    const openPresetEditDialogModalLocal = async ({ presetId, presetName }) => {
      // プリセット上書き確認ダイアログ表示イベント
      const resValidate = openPresetEditDialogModal({
        presetId,
        presetName,
        presetSearchFilterObject: {
          search_detail_filters: searchDetailFilters.value,
          search_flag_groups: searchFlagGroups.value,
          search_flag_group_options: searchFlagGroupOptions.value,
          search_surveys: searchSurveys.value,
          search_survey_texts: searchSurveyTexts.value,
        },
      });
      if (resValidate.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: resValidate.message,
          type: false,
        });
      }
    };
    const loadPreset = async ({
      searchFilterObj: {
        or_flag = false,
        search_detail_filters = [],
        search_flag_groups = [],
        search_flag_group_options = [],
        search_surveys = [],
        search_survey_texts = [],
        sheet_id = null,
      },
      preset = {},
    }) => {
      reset();
      if (props.flagMode === true && search_surveys.length > 0) {
        // フラグモード時にGoogleフォームモードのpresetを読み込んだ場合、強制Googleフォームモード切り替え
        await props.changeFlagMode(sheet_id);
      } else if (props.flagMode === false && search_flag_groups.length > 0) {
        // Googleフォームモード時にフラグモードのpresetを読み込んだ場合、強制フラグモード切り替え（強制的にGoogleフォームの一番上の項目を選択）
        await props.changeFlagMode(0);
      } else if (props.surveySheetId !== sheet_id) {
        // 利用するGoogleフォームIDが切り替わった場合、フラグモード・Googleフォーム表示も切り替える
        await props.changeFlagMode(sheet_id);
      }
      if (search_surveys.length > 0) {
        const result = await surveyService.fetchSurvey(sheet_id);
        context.emit('updateSurveyTitles', result.survey_title);
      }
      orFlag.value = or_flag;
      await loadSearchDetailFiltersPreset(
        search_detail_filters,
        refFormDetailFilters.value
          ? refFormDetailFilters.value.setAttendances
          : () => [],
      );
      loadFlagGroupsPreset(search_flag_groups);
      loadFlagGroupOptionPreset(search_flag_group_options);
      loadSurveyPreset(search_surveys, search_survey_texts);
      updateEditPresetSearchFilterObject({
        or_flag,
        search_detail_filters,
        search_flag_groups,
        search_flag_group_options,
        search_surveys,
        search_survey_texts,
        sheet_id,
      });
      openSearchFilter();
      context.emit('updateIsShowSelectableApplicantTable', true);
      updateCurrentPreset(preset);
    };
    const localResizeModal = () =>
      onResizeModal(props.isView, { shouldResizeModalWidth: false });

    return {
      refFormDetailFilters,
      refSearchFlagGroupDetail,
      orFlag,
      isLoading,
      pageMode,
      presets,
      currentPreset,
      searchDetailFilters,
      selectableSearchDetailFilters,
      attendanceId,
      addSearchDetailFilter,
      removeSearchDetailFilter,
      updateSearchDetailFilterData,
      updateSearchDetailFilterOption,
      updateProgressStatusIds,
      updateAttendanceId,
      getDisabledBySelectOption,
      searchFlagGroups,
      editPresetId,
      editPresetName,
      editPresetSearchFilterObject,
      visibleAddFlags,
      visibleFlagGroupIds,
      visibleFlagIds,
      getFlagGroups,
      getFlags,
      getFlagNameById,
      updateFlagGroupId,
      updateFlagId,
      addFlagGroupRow,
      removeFlagGroupRow,
      openDropDownFlagGroupIds,
      openDropDownFlagId,
      closeAllFlagGroupsDropDown,
      searchFlagGroupOptions,
      visibleFlagGroupOptions,
      selectableSearchFlagGroupOptionsDefault,
      addFlagGroupOption,
      removeFlagGroupOption,
      updateFlagGroupOption,
      openDropDownFlagGroupOption,
      closeAllFlagGroupOptionsDropDown,
      searchFormDetailSwitch,
      searchSurveys,
      searchSurveyTexts,
      visibleAddText,
      visibleSurveyIds,
      getSurveys,
      updateSearchSurvey,
      updateSearchSurveyTexts,
      addSurveysRaw,
      removeSurveysRow,
      openDropDownSurveyIds,
      closeSearchSurveyDropDown,
      close,
      onSearch,
      updatePresetAndSearch,
      reset,
      resetAndSearch,
      openPresetListModal,
      openPresetDeleteDialogModal,
      openPresetEditModalLocal,
      openPresetEditDialogModalLocal,
      openSearchFilter,
      switchSearchFilterLocal,
      loadPreset,
      updateCurrentPreset,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.header,
.body {
  max-width: 1070px;
  width: calc(100vw - 40px);
}

.search_filter_controllers {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-align: right;
  > p {
    text-align: left;
    line-height: 1.4;
  }
  > div {
    margin-left: auto;
  }
  button:last-child {
    margin-left: 10px;
  }
}

.btn-searchSwitch {
  position: absolute;
  top: 50%;
  right: 10px;
  border: none;
  border-radius: 126px;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  background-color: #16b2d9;
  transform: translateY(-50%);
  cursor: pointer;
  > .icon {
    height: 12px;
    vertical-align: -2px;
  }
  &:hover {
    background-color: #1698d9;
  }
}

.ar-flagComponent {
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  > .subBtn {
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
  }
}

.ar-option {
  min-width: 700px;
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 4px;
  background-color: #f0f6fa;
}

.ar-actionBtns {
  text-align: center;
  .btn-availability {
    display: block;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
  }
  .btn-unavailable {
    padding: 20px 30px;
  }
  .btn-border {
    margin-bottom: 0;
    height: 55px;
  }
}

.btn-availability.is-active > button {
  cursor: pointer;
}

.two_buttons {
  display: flex;
  .btn {
    width: calc(50% - 7.5px);
    &:last-child {
      margin-left: auto;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .search_filter_controllers {
    display: block;
    > p {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .btn-searchSwitch {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    margin-top: 20px;
    transform: none;
  }
  .two_buttons {
    display: block;
    .btn {
      width: 100%;
      &:last-child {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}
</style>
