import { isOwner, isManager, isOperator } from '@/defines/staff';

const Staff = function _Staff(
  id,
  firstname,
  lastname,
  email,
  birthday,
  tel,
  selectedGraduatedId,
  sign,
  role,
  setting,
  admin,
  isDelete,
  emailUpdatePending,
  superAdmin,
  isGoogleMember,
  isMaster,
) {
  const staff = Object.create(Staff.prototype);
  staff.id = id;
  staff.firstname = firstname;
  staff.lastname = lastname;
  staff.email = email;
  staff.birthday = birthday;
  staff.tel = tel;
  staff.selected_graduated_id = selectedGraduatedId;
  staff.sign = sign;
  staff.role = role
    ? {
        ...role,
        isOwner: () => isOwner(role.name),
        isManager: () => isManager(role.name),
        isOperator: () => isOperator(role.name),
      }
    : null;
  // TODO: APIからのレスポンスをフロント都合でkey名を変更しているので修正したい
  staff.setting = setting
    ? {
        id: setting.staff_id,
        activatedCount: setting.applicant_activated_count,
        activatedDisplay: setting.applicant_activated_display,
        provisionalCount: setting.applicant_provisional_count,
        provisionalDisplay: setting.applicant_provisional_display,
        activatedFlaggroupDisplay:
          setting.applicant_activated_flaggroup_display,
        provisionalFlaggroupDisplay:
          setting.applicant_provisional_flaggroup_display,
        selectionCount: setting.selection_count,
        selectionDisplay: setting.selection_display,
      }
    : null;
  staff.admin = admin;
  staff.is_delete = isDelete;
  staff.email_update_pending = emailUpdatePending;
  staff.super_admin = superAdmin;
  staff.is_google_member = isGoogleMember;
  staff.is_master = isMaster;
  return staff;
};

Staff.createFromJSON = json =>
  Staff(
    json.id ? json.id : null,
    json.firstname,
    json.lastname,
    json.email,
    json.birthday,
    json.tel,
    json.selected_graduated_id,
    json.sign,
    json.role ? json.role : null,
    json.setting ? json.setting : null,
    json.admin,
    json.is_delete,
    json.email_update_pending,
    json.super_admin,
    json.is_google_member,
    json.is_master,
  );

Staff.prototype = {};

export default Staff;
