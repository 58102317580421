<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import BaseCheckbox from '@/components/ui/checkbox/BaseCheckbox.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import Pager from '@/components/ui/pager/components/Pager.vue';

import autoActionsService from '@/services/autoActions.js';
import { ACTION_LOG_STATUSES } from '@/defines/autoActions';

const props = defineProps({
  isView: {
    type: Boolean,
    required: false,
  },
  actionId: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(['open', 'close', 'update-auto-actions']);

const store = useStore();
const allowUpdateApplicants = !store.getters['staff/staff'].is_master;

const isLoading = ref(false);
const applicants = ref([]);
const pageMeta = ref({});
const selectedApplicantLogsIds = ref([]);
const initialPage = 1;

const closeModal = () => {
  emit('close');
  selectedApplicantLogsIds.value = [];
};

const fetchFailedApplicants = async page => {
  isLoading.value = true;
  const { data } = await autoActionsService.fetchAutoActionsApplicants(
    { page, status: ACTION_LOG_STATUSES.failed },
    props.actionId,
  );
  applicants.value = data.applicants;
  pageMeta.value = data.meta;
  isLoading.value = false;
  if (applicants.value.length === 0) {
    closeModal();
    emit('update-auto-actions');
  }
};

const updateFailedApplicants = async () => {
  isLoading.value = true;
  const payload = {
    action_logs: selectedApplicantLogsIds.value.map(id => {
      return { id, status: ACTION_LOG_STATUSES.confirmed };
    }),
  };
  const { status, data } =
    await autoActionsService.updateAutoActionsApplicants(payload);
  if (status === 204) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: '応募者を更新しました',
      type: true,
    });
  } else {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: data.message,
      type: false,
    });
    return;
  }
  await fetchFailedApplicants(initialPage);
  selectedApplicantLogsIds.value = [];
  isLoading.value = false;
};

const handleSelectApplicant = (checked, actionLogsId) => {
  if (checked) {
    selectedApplicantLogsIds.value.push(actionLogsId);
  } else {
    selectedApplicantLogsIds.value = selectedApplicantLogsIds.value.filter(
      app => app !== actionLogsId,
    );
  }
};
const handleChangeAllSelect = checked => {
  if (checked) {
    selectedApplicantLogsIds.value = applicants.value.map(
      applicant => applicant.action_logs_id,
    );
  } else {
    selectedApplicantLogsIds.value = [];
  }
};
const movePage = ({ next }) => {
  fetchFailedApplicants(next);
};

const handleApplicantClick = (applicantId, isDeleted) => {
  if (isDeleted) return;
  window.open(`/applicants/${applicantId}`, '_blank');
};

watch(
  () => props.isView,
  newIsView => {
    if (newIsView) {
      fetchFailedApplicants(initialPage);
    }
  },
);
</script>
<template>
  <modal-window
    :is-visible="isView"
    title="自動アクションに失敗した応募者"
    :max-width="'1070px'"
    :max-height="'90vh'"
    @close="closeModal"
    @clickOuter="closeModal"
  >
    <template #content>
      <div class="modal-contents">
        <div class="modal-contents-text">
          アクション失敗した応募者の確認ができたら完了しましょう。
          <br />
          確認後、チェックを入れて更新すると本リストから削除できます。
        </div>
        <div v-if="isLoading">
          <local-page-loader />
        </div>
        <div v-else class="modal-contents-table">
          <pager
            :page="pageMeta.page"
            :count="pageMeta.total_count"
            :page-count="pageMeta.total_pages"
            @move="movePage"
          />
          <div class="table-wrapper">
            <table class="table">
              <thead>
                <th class="fit-left">
                  <base-checkbox
                    :model-value="
                      applicants.length === selectedApplicantLogsIds.length
                    "
                    @change="handleChangeAllSelect($event.target.checked)"
                  />
                </th>
                <th class="fit-left">
                  <span>失敗日時</span>
                </th>
                <th><span>名前</span></th>
                <th><span>セイメイ</span></th>
                <th><span>連絡方法</span></th>
                <th><span>性別</span></th>
                <th class="fit-left">
                  <span>学校名</span>
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="applicant in applicants"
                  :key="applicant.action_logs_id"
                  :class="{ 'in-active': applicant.is_deleted }"
                  @click="
                    handleApplicantClick(applicant.id, applicant.is_deleted)
                  "
                >
                  <td class="fit-left">
                    <base-checkbox
                      :model-value="
                        selectedApplicantLogsIds.includes(
                          applicant.action_logs_id,
                        )
                      "
                      @click.stop
                      @change="
                        handleSelectApplicant(
                          $event.target.checked,
                          applicant.action_logs_id,
                        )
                      "
                    />
                  </td>
                  <td>
                    {{
                      moment(applicant.executed_at).format(
                        'YYYY年MM月DD日 HH:mm',
                      )
                    }}
                  </td>
                  <td class="text-ellipsis">
                    {{ applicant.name }}
                  </td>
                  <td class="text-ellipsis">{{ applicant.name_kana }}</td>
                  <td>{{ applicant.contact_type }}</td>
                  <td>
                    {{ applicant.gender }}
                  </td>
                  <td class="text-ellipsis">{{ applicant.school }}</td>
                  <span class="reason_code">
                    {{ applicant.reason_code }}
                  </span>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-contents-bottom">
          <button-base
            type="button"
            :button-text="'キャンセル'"
            button-type="secondary"
            @click="closeModal"
          />
          <button-base
            type="button"
            :button-text="'更新'"
            :is-loading="isLoading"
            class="btn-update-applicants"
            :class="{
              'is-disabled':
                selectedApplicantLogsIds.length === 0 || !allowUpdateApplicants,
            }"
            @click="updateFailedApplicants"
          />
        </div>
      </div>
    </template>
  </modal-window>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include tabsWrapper;
@include tableStyle;

.btn-update-applicants {
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.modal-contents {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  &-title {
    margin-bottom: 6px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  &-bottom {
    display: flex;
    gap: 8px;
    margin-top: 30px;
    button {
      height: 52px;
      width: 100%;
    }
  }
}
.modal-contents-text {
  padding: 0 0 24px;
  border-bottom: 1px solid $mochica_color_gray2;
  font-size: $font_14;
  line-height: 1.8;
}
.modal-contents-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .pager {
    justify-content: flex-start;
  }
}
.table-wrapper {
  overflow-y: auto;
  max-height: 50vh;
  min-height: auto;
}
.table {
  tr {
    cursor: pointer;
    &.in-active {
      background: #f5f5f5;
      color: #bbb;
      .reason_code {
        color: #333;
      }
    }
    position: relative;
  }
  &:has(.base-checkbox) {
    th,
    td {
      &:first-of-type {
        padding-right: 0;
        vertical-align: baseline;
      }
    }
  }
  td {
    &,
    &:last-of-type {
      padding-bottom: 72px;
    }
    &:has(.base-checkbox) {
      vertical-align: baseline;
    }
  }
  .reason_code {
    position: absolute;
    inset: auto 0 20px 48px;
    padding: 10px 16px;
    background: #fafafa;
    border: 1px solid #ededed;
    border-radius: 4px;
    font-size: $font_12;
  }
}
</style>
