<template>
  <div class="admin-input">
    <div class="admin-input_list">
      <div class="admin-input_list-row">
        <label class="admin-input_list-row-item">タイトル</label>
        <textarea
          v-model="info.data.title"
          maxlength="200"
          class="admin-input_list-row-input"
        />
      </div>
      <div class="admin-input_list-row">
        <label class="admin-input_list-row-item">サブタイトル</label>
        <textarea
          v-model="info.data.sub_title"
          maxlength="400"
          class="admin-input_list-row-input"
        />
      </div>
      <div class="admin-input_list-row">
        <label class="admin-input_list-row-item">コンテンツ</label>
        <textarea
          v-model="info.data.content"
          maxlength="1000"
          class="admin-input_list-row-input"
        />
      </div>
      <div class="admin-input_list-row">
        <label class="admin-input_list-row-item">リンク</label>
        <input
          v-model="info.data.link"
          maxlength="400"
          class="admin-input_list-row-input"
          :class="{ 'is-danger': errors.link.isValid !== true }"
          @input="validateOnInput('link', info.data.link, validateUrl, false)"
        />
        <span
          v-if="errors.link.isValid !== true"
          class="fa-warning font-s error_message"
        >
          {{ errors.link.message }}
        </span>
      </div>
      <div class="admin-input_list-row">
        <label class="admin-input_list-row-item">ボタン名</label>
        <input
          v-model="info.data.btn_title"
          maxlength="20"
          class="admin-input_list-row-input"
        />
      </div>
      <div class="admin-input_list-row">
        <label class="admin-input_list-row-item">背景画像</label>
        <p class="caption">
          ※ ファイルの形式は jpg, jpeg, png のいずれかを使用してください
        </p>
        <p class="caption">
          ※ 推奨される画像のサイズは 1777 px × 1414 px で画像の容量は 1 MB
          までです
        </p>
        <attachment-file
          :limit-size="1"
          :files="imgFiles"
          :limit-file-count="1"
          :is-disabled="isLoading"
          :description="'背景画像を変更する場合は\nここにドロップしてください'"
          file-title="画像ファイル"
          :is-preview="true"
          @setFile="setImgFile"
          @deleteFile="deleteImgFile"
        />
      </div>
      <button-base
        class="admin-input_list-submit-btn"
        button-text="ログイン画面の情報を更新する"
        :is-loading="isLoading"
        @click="updateLoginInfo"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import loginService from '@/services/login';
import adminService from '@/services/admin';

import useValidation from '@/composables/useValidation';
import AttachmentFile from '@/components/ui/attachmentFile/components/AttachmentFile';

export default defineComponent({
  name: 'AdminEditLoginPage',
  components: { AttachmentFile },
  setup(props, context) {
    const { errors, resetErrors, validateOnInput, validateUrl } = useValidation(
      ['link'],
    );
    const store = useStore();
    const isLoading = ref(false);
    const info = reactive({ data: {} });
    const imgFiles = ref([]);

    const getLoginContents = async () => {
      const res = await loginService.getLoginContents();
      info.data = res.info;
    };
    const setImgFile = async file => {
      await deleteImgFile();
      imgFiles.value = [file];
    };
    const deleteImgFile = () => {
      imgFiles.value = [];
    };
    const updateLoginInfo = async () => {
      resetErrors();
      if (info.data.link && validateUrl('link', info.data.link) !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容を確認してください',
          type: false,
        });
        return;
      }
      isLoading.value = true;
      const formData = new FormData();
      formData.append('title', info.data.title === null ? '' : info.data.title);
      formData.append(
        'sub_title',
        info.data.sub_title === null ? '' : info.data.sub_title,
      );
      formData.append(
        'content',
        info.data.content === null ? '' : info.data.content,
      );
      formData.append('link', info.data.link === null ? '' : info.data.link);
      formData.append(
        'btn_title',
        info.data.btn_title === null ? '' : info.data.btn_title,
      );
      if (imgFiles.value.length === 1) {
        formData.append('file', imgFiles.value[0].file);
      }
      const res = await adminService.updateLoginContents(formData);
      if (res.success === true) getLoginContents();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoading.value = false;
    };

    // methods
    onBeforeMount(async () => await getLoginContents());

    return {
      errors,
      isLoading,
      info,
      imgFiles,
      validateOnInput,
      validateUrl,
      setImgFile,
      deleteImgFile,
      updateLoginInfo,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.admin-input {
  @include flex_center();
  align-items: flex-start;
  padding: 2rem;
  line-height: 1.5;
}
.admin-input_list {
  background: #fff;
  padding: 20px;
  width: 50rem;
  margin-right: 2rem;
}
.admin-input_list-row {
  margin-bottom: 1.2rem;
  padding-bottom: 1.2rem;
  &:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
  }
}
.admin-input_list-row-item {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}
.admin-input_list-row-input {
  display: block;
  border: 1px solid $border_color;
  padding: 0.4rem;
  width: 100%;
}
.admin-input_list-submit-btn {
  width: 100%;
  height: 48px;
  margin-top: 24px;
  border-radius: 36px;
  border-radius: 24px !important;
}

.admin-input_regist-view {
  @extend .admin-input_list;
  background: #eaeaea;
  margin-right: 0;
}

.regist-block__title {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0.6rem;

  &:nth-of-type(2) {
    margin-top: 2.4rem;
  }
}
.regist-block__list {
  padding: 2rem;
  background: #fff;
  border-radius: 0.4rem;
}
.regist-block__list-item {
  margin-bottom: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #f1f1f1;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

input:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid;
}
.fa-warning {
  color: $mochica_color_red;
  padding-top: 4px;
  font-size: 1.2rem;
}
span.red {
  color: #f00;
}

.caption {
  font-size: 1.2rem;
  margin: 1rem 0;
}
</style>
