<template>
  <div class="form__wrapper">
    <div class="form__inner">
      <div class="form__inner__contents">
        <div class="issue">
          <div v-if="!isLoading" class="issue_desc">
            {{ updateEmailMessage }}
          </div>
          <footer class="issue">
            <div class="divider">
              <img class="logo-title" src="@/assets/img/mochica_logo.svg" />
            </div>
          </footer>
          <notification />
        </div>
      </div>
      <!-- form__inner__contents -->
    </div>
    <!-- form__inner -->
  </div>
  <!-- form__wrapper -->
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import qs from 'qs';
import Notification from '@/components/ui/notification/components/Notification';
import updateEmailExtService from '@/services/updateEmailExt';

export default defineComponent({
  name: 'UpdateEmailExt',
  components: { Notification },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const token = ref(null);
    const updateEmailMessage = ref('Eメールアドレスの認証中です');

    // methods
    const updateEmailExt = async () => {
      isLoading.value = true;
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      token.value = query.token;
      if (token.value) {
        const res = await updateEmailExtService.updateEmailExt({
          token: token.value,
        });
        updateEmailMessage.value = res.message;
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: res.success,
          message: res.message,
        });
      } else {
        updateEmailMessage.value = 'URLが正しくありません';
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          type: false,
          message: 'URLが正しくありません',
        });
      }
      isLoading.value = false;
    };

    // lifecycle
    onBeforeMount(async () => {
      await updateEmailExt();
    });

    return {
      isLoading,
      token,
      updateEmailMessage,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include form_wrapper_css();
.form__wrapper {
  background: #88c6ed;
}
.divider {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #efefef;
}

div.issue {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 800px;
  footer.issue {
    width: 100%;
    padding: 0px;
  }
}
.main-wrapper {
  width: 100%;
}

.logo-title {
  fill: #88c6ed;
  display: block;
  margin: 0 auto 0px;
  width: 20%;
}
.issue_desc {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  font-weight: bold;
  &-title {
    margin-top: 1rem;
  }
}
</style>
