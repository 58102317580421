<template>
  <div class="contents-wrapper">
    <div class="notes">
      <div class="description">
        【ご注意】ログインを制限するIPアドレスを間違えるとログインできなくなります。
        <br />
        すべて空欄の場合は、IPアドレスの制限はかかりません。
        <br />
        ログインできるIPアドレスは最大30件まで設定できます。
      </div>
    </div>
    <div class="block">
      <div class="block-header">
        <h3 class="block-header-title">
          下記のIPアドレスからのみログインできます
        </h3>
      </div>
      <div v-if="isEditMode" class="block-contents">
        <div class="block-contents-top">
          <div v-if="ipAddresses.length === 0" class="no-list-item">
            登録しているIPアドレスはありません
          </div>
          <ul v-else class="list">
            <li
              v-for="(ipAddress, index) in ipAddresses"
              :key="ipAddress.id"
              class="list-item"
            >
              <div class="flex">
                <input
                  :value="ipAddress.value"
                  :name="`ip_address_${index}`"
                  :class="{
                    'is-error': !errors[ipAddress.id].isValid,
                  }"
                  class="list-item-input"
                  type="text"
                  maxlength="15"
                  @input="e => onChangeIpAddress(e, index, ipAddress.id)"
                />
                <div class="list-item-btn">
                  <button-base
                    type="button"
                    :button-text="'削除'"
                    :button-type="'secondary'"
                    :icon-file-name="'trash'"
                    @click="onClickDeleteInput(ipAddress.id)"
                  />
                </div>
              </div>
              <span
                v-show="!errors[ipAddress.id].isValid"
                class="fa fa-warning"
              >
                {{ errors[ipAddress.id].message }}
              </span>
            </li>
          </ul>
          <div class="block-contents-bottom">
            <div class="add-input">
              <button-base
                type="button"
                class="add-input-btn"
                :class="{ 'max-registration': isMaxReg }"
                :button-text="
                  isMaxReg
                    ? 'これ以上はIPアドレスを追加できません'
                    : 'IPアドレスを追加'
                "
                :button-type="'primary'"
                :icon-file-name="isMaxReg ? '' : 'add_white'"
                :disabled="isMaxReg"
                @click="onClickAddInput"
              />
              <small class="caption">※最大30件まで登録できます</small>
            </div>
            <div class="submit">
              <button-base
                type="button"
                class="submit-btn"
                :button-text="'IPアドレスを確定'"
                :button-type="'success'"
                :icon-file-name="'check_white'"
                @click="onClickOpenConfirmModal"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="block-contents">
        <div
          v-if="companyInfo.ip_addresses.length === 0"
          class="block-contents-top"
        >
          <div class="no-list-item">登録しているIPアドレスはありません</div>
        </div>
        <ul v-else class="list read-only">
          <li
            v-for="ip in companyInfo.ip_addresses"
            :key="ip.id"
            class="list-item"
          >
            {{ ip.ip_address }}
          </li>
        </ul>
        <div v-if="isOwner" class="block-contents-bottom edit">
          <button-base
            type="button"
            class="edit-btn"
            :button-text="'IPアドレスを編集'"
            :rejected-admin-ids="[2]"
            :button-type="'primary'"
            :icon-file-name="'pen_white'"
            @click="isEditMode = true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import useValidation from '@/composables/useValidation';
import { generateUniqueId } from '@/utils/unique-id';

const MAX_IP_REGISTRATIONS = 30;

export default defineComponent({
  name: 'EditCompanyIpAddress',
  components: {},
  props: {
    companyInfo: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['commit'],
  setup({ companyInfo }, { emit, root }) {
    const store = useStore();
    const isOwner = store.getters['staff/isOwner'];
    const initialIpAddressValues = companyInfo.ip_addresses.map(ip => ({
      id: ip.id,
      value: ip.ip_address,
    }));
    const ipAddresses = ref(initialIpAddressValues);
    const isEditMode = ref(false);

    const { errors, validateIpAddress } = useValidation(
      initialIpAddressValues.map(ip => ip.id),
    );

    const onChangeIpAddress = (e, index, id) => {
      ipAddresses.value[index].value = e.target.value;
      validateIpAddress(id, e.target.value);
    };
    const onClickAddInput = () => {
      const id = generateUniqueId();
      ipAddresses.value.push({ id, value: '' });
      errors[id] = { isValid: true, message: '' };
    };
    const onClickDeleteInput = id => {
      delete errors[id];
      ipAddresses.value = ipAddresses.value.filter(ip => ip.id !== id);
    };
    const onClickOpenConfirmModal = async () => {
      const isValid = Object.values(errors).every(error => error.isValid);
      if (!isValid) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容を確認して下さい',
          type: false,
        });
        return;
      }
      emit(
        'commit',
        ipAddresses.value.map(ip => ip.value).filter(ip => ip !== ''),
      );
    };
    const reset = () => {
      isEditMode.value = false;
      ipAddresses.value = ipAddresses.value.filter(ip => ip.value !== '');
    };
    const isMaxReg = computed(
      () => ipAddresses.value.length === MAX_IP_REGISTRATIONS,
    );

    return {
      isOwner,
      errors,
      isEditMode,
      ipAddresses,
      isMaxReg,
      validateIpAddress,
      onChangeIpAddress,
      onClickAddInput,
      onClickDeleteInput,
      onClickOpenConfirmModal,
      reset,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.flex {
  display: flex;
  align-items: center;
}
.notes {
  margin-top: 14px;

  .description {
    margin: 0 0 1rem 0;
    line-height: 1.5;
    font-size: 1.2rem;
    color: $system_color;
  }
}
.block {
  border: 1px solid $border_color;
  border-radius: 4px;
  max-width: 568px;
}
.block-header {
  position: relative;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;

  &-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    height: 1px;
    width: calc(100% - 40px);
    background-color: $border_color;
  }
}
.block-contents {
  padding: 16px;

  .no-list-item {
    height: 44px;
    padding: 16px 0;
    font-size: 1.2rem;
    color: $system_color;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 12px 0;

    &-item-input {
      height: 44px;
      width: 100%;
      max-width: 464px;
      margin-right: 12px;
    }
    &-item-btn {
      flex-shrink: 0;
    }
    .fa-warning {
      color: $error-text;
      padding-top: 4px;
      font-size: 0.8rem;
    }
    .fa {
      display: block;
      line-height: 26px;
      align-self: flex-start;
    }
    .is-error {
      border: 1px $error-border solid;
    }
  }
  .list.read-only {
    gap: 20px 0;

    .list-item {
      border-bottom: 1px solid $border_color;
      padding-bottom: 20px;
    }
    .list-item:last-of-type {
      padding-bottom: 0px;
      border: none;
    }
  }
}
.block-contents-bottom {
  .add-input {
    padding-bottom: 16px;
    border-bottom: 1px solid $border_color;
    margin-top: 16px;
    .caption {
      padding-left: 8px;
      color: $system_color;
    }
  }
  .submit {
    padding-top: 16px;
  }
  &.edit {
    margin-top: 16px;
    padding-top: 20px;
    border-top: 1px solid $border_color;
  }
}
:deep(button.btn-base.add-input-btn) {
  &:disabled,
  &:disabled.btn-outlined {
    opacity: 1;
    color: $white;
  }
}

@media (max-width: ($media_query_sp)) {
  .block-header {
    padding: 14px 20px;
    line-height: 1.5;
  }
  .block-contents-bottom {
    .submit > .submit-btn,
    .add-input > .add-input-btn,
    .edit-btn {
      width: 100%;
      height: 40px;
    }
    .add-input .caption {
      display: block;
      margin-top: 8px;
      padding-left: 0;
    }
    .add-input-btn.max-registration {
      line-height: 1.4;
      height: auto;
    }
  }
}
</style>
