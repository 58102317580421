import { reactive, ref, watch } from 'vue';

export default function useEntryFormSetting() {
  /**
   * フォーム項目順番定義配列
   */
  const formKeys = [
    'birth',
    'gender',
    'tel',
    'zipcode',
    'address',
    'school',
    'faculty',
    'department',
    'department_category',
  ];
  /**
   * フォーム項目ラベル名定義Object
   */
  const FORM_LABELS = Object.freeze({
    birth: '生年月日',
    gender: '性別',
    tel: '電話番号',
    zipcode: '郵便番号',
    address: '住所',
    school: '学校名',
    faculty: '学部',
    department: '学科',
    department_category: '文理',
  });
  const formSettingDefault = {};
  formKeys.forEach(key => {
    formSettingDefault[key] = false;
  });
  /**
   * フォーム項目チェックしたかどうかのObject
   */
  const formSetting = reactive(formSettingDefault);
  /**
   * チェック済のフォーム項目のみのラベル配列
   */
  const isCheckedLabels = ref([]);

  // watch
  watch(
    () => formSetting,
    () => {
      isCheckedLabels.value = Object.keys(formSetting)
        .map(key => ({ key, val: formSetting[key] }))
        .filter(obj => obj.val === true)
        .map(obj => getFormLabel(obj.key));
    },
    { deep: true },
  );

  // methods
  /**
   * formSettingの値を全て書き換える
   * @param {string} key
   */
  const setFormSettingAll = newFormSetting => {
    Object.keys(formSetting).forEach(key => {
      formSetting[key] = newFormSetting[key];
    });
  };
  /**
   * formSettingのbooleanを反転させる
   * @param {string} key
   */
  const toggleSwitch = key => {
    formSetting[key] = !formSetting[key];
  };
  /**
   * フォーム項目のKeyからラベル名の取得
   * @param {string} key
   * @return {string} ラベル名
   */
  const getFormLabel = key => {
    return FORM_LABELS[key];
  };

  return {
    formKeys,
    formSetting,
    isCheckedLabels,
    setFormSettingAll,
    toggleSwitch,
    getFormLabel,
  };
}
