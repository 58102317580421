<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';

import TabButton from '@/components/ui/tabs/TabButton.vue';
import ProgressStatusLabel from '@/components/features/progress/ProgressStatusLabel.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import Pager from '@/components/ui/pager/components/Pager.vue';
import autoActionsService from '@/services/autoActions.js';

import { ACTION_TYPE_LABELS } from '@/defines/autoActions';

const router = useRouter();
const store = useStore();

const autoActionsLogs = ref([]);
const meta = ref({});
const isLoading = ref(false);
const initialPage = 1;

const movePage = ({ next }) => {
  fetchAutoActionsLogs(next);
};

const fetchAutoActionsLogs = async page => {
  isLoading.value = true;
  const { data } = await autoActionsService.fetchAutoActionsLogs({
    graduated_id: store.getters['graduateds/selectedGraduatedId'],
    page,
  });
  autoActionsLogs.value = data.action_logs;
  meta.value = data.meta;
  isLoading.value = false;
};
onMounted(async () => {
  await fetchAutoActionsLogs(initialPage);
});
</script>

<template>
  <div class="page-wrapper">
    <section class="page-header">
      <div class="tabs-wrapper">
        <tab-button @click="router.push({ name: 'AutoActions' })">
          自動アクション一覧
        </tab-button>
        <tab-button :is-active="true">自動アクションの履歴</tab-button>
      </div>
    </section>
    <section class="page-body">
      <div class="page-body-header">
        <div class="page-body-header-inner">
          <h1 class="page-body-title">自動アクションの履歴</h1>
          <small class="page-body-header-caption">
            ※「選考の割り当て」は履歴に表示されません
          </small>
        </div>
        <pager
          v-show="!isLoading"
          :page="meta.current_page"
          :count="meta.total_count"
          :page-count="meta.total_pages"
          @move="movePage"
        />
      </div>
      <div v-if="isLoading">
        <local-page-loader />
      </div>
      <div v-else-if="autoActionsLogs.length === 0">
        <empty-content>
          自動アクションはまだ実行されていません
          <br />
          ※「選考の割り当て」の履歴に表示されません
        </empty-content>
      </div>
      <div v-else class="table-wrapper">
        <table class="table">
          <thead>
            <th class="fit-left">
              <span>最終実行日時</span>
            </th>
            <th class="fit-left">
              <span>実行人数</span>
            </th>
            <th><span>自動アクション名</span></th>
            <th><span>対象の選考</span></th>
            <th><span>対象のステータス</span></th>
            <th><span>アクション</span></th>
            <th class="fit-left">
              <span>フラグ指定</span>
            </th>
          </thead>
          <tbody>
            <tr
              v-for="log in autoActionsLogs"
              :key="log.action.id"
              @click="
                router.push({
                  name: 'AutoActionsLogsDetail',
                  query: {
                    action_logs_id: log.action_logs_id,
                    reservation_date: log.reservation_date,
                  },
                  params: { id: log.action.id },
                })
              "
            >
              <td class="fit-left">
                {{
                  log.last_executed_at
                    ? moment(log.last_executed_at).format(
                        'YYYY年MM月DD日 HH:mm',
                      )
                    : '-'
                }}
              </td>
              <td class="fit-left">{{ log.matched_applicant_count }}名</td>
              <td class="text-ellipsis">
                {{ log.action.name }}
              </td>
              <td class="text-ellipsis">
                {{ log.action.event_name }}
              </td>
              <td>
                <div class="progress-statuses">
                  <progress-status-label
                    v-for="progress in [
                      ...log.action.trigger_conditions.progress_status_ids,
                      ...log.action.trigger_conditions
                        .finished_progress_status_ids,
                    ]"
                    :key="progress.name"
                    :label-name="progress.name"
                    class="w-fit sm"
                  />
                </div>
              </td>
              <td>
                {{ ACTION_TYPE_LABELS[log.action.action_type] }}
              </td>
              <td class="fit-left">
                {{ log.action.trigger_conditions.has_flag ? 'あり' : 'なし' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pager
        v-show="!isLoading"
        :page="meta.current_page"
        :count="meta.total_count"
        :page-count="meta.total_pages"
        @move="movePage"
      />
    </section>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include tabsWrapper;
@include tableStyle;

.page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.page-header {
  padding: 0 0 32px;
  border-bottom: 1px solid $mochica_color_gray2;
}
.page-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.page-body-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.page-body-header-inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .btn-base {
    width: fit-content;
  }
}
.page-body-title {
  margin: 0;
  font-size: $font_20;
  font-weight: bold;
}
.page-body-header-caption {
  font-size: $font_12;
  color: #777;
  line-height: 1.5;
}
.progress-statuses {
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  .progress-status-label {
    flex-basis: 100%;
  }
  .w-fit {
    width: fit-content;
    padding: 8px 16px;
  }
}
</style>
