<template>
  <section class="as-block">
    <div class="headline -wrap">
      <img class="icon" height="20" src="@/assets/img/file_white.svg" />
      <p>
        ファイル
        <br class="-spView" />
        <span class="small">（※１ファイルあたり上限8MBまで）</span>
      </p>
    </div>

    <div class="inner">
      <div
        v-if="allowFileManagement && uploadStatus && uploadStatus.status"
        class="btn btn-availability file_upload_btn"
        @click="isOpenModalFileUpload = true"
      >
        <img class="icon" height="20" src="@/assets/img/upload_white.svg" />
        ファイルをアップロード
      </div>

      <div class="responsive-overflow">
        <table class="table-file_list">
          <thead>
            <tr>
              <th>アップロード日</th>
              <th>ファイル名</th>
              <th v-if="allowFileManagement">マイページに表示</th>
              <th>アップロード方法</th>
              <th v-if="allowFileManagement" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in files" :key="file.id">
              <td>
                <p>{{ file.createdAt }}</p>
              </td>
              <td>
                <span
                  class="file-name"
                  :class="{ available: !isDownloading(file.id) }"
                  @click="downloadFile(file)"
                >
                  {{ $utils.textEllipsisMiddle(file.originalFilename) }}
                </span>
              </td>
              <td v-if="allowFileManagement" class="has-checkbox">
                <input
                  v-if="!file.applicantUpload && !file.noticeId"
                  v-model="file.mypageVisible"
                  type="checkbox"
                  name="is_mypage_visible"
                  @click.prevent="openConfirmMypageVisible(file)"
                />
              </td>
              <td>
                <span>{{ getUploadMethod(file) }}</span>
              </td>
              <td v-if="allowFileManagement">
                <button-base
                  v-if="!file.applicantUpload && !file.noticeId"
                  button-text="削除"
                  button-type="secondary"
                  icon-file-name="delete_white"
                  class="button-delete"
                  @click="$emit('delete', file)"
                />
                <span v-else class="spacer" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <modal-file-select
      :is-view="isOpenModalFileUpload === true"
      @onClose="isOpenModalFileUpload = false"
      @onSelected="selectModalFileUpload"
    />
    <modal-window
      :is-visible="isOpenDialogUploadConfirm"
      :message="'ファイル「' + uploadFileName + '」をアップロードします。'"
      title="ファイルをアップロードします"
      button-text="ファイルをアップロード"
      @click="selectedModalConfirm"
      @close="cancelUpload"
    />
    <modal-window
      :is-visible="isOpenConfirmMypageVisible"
      :is-loading="isLoading"
      :data="mypageVisible.data"
      title="マイページ表示設定の変更"
      message="マイページに表示させる設定を変更しますか？"
      button-text="設定を変更"
      @click="uploadMypageVisible"
      @close="isOpenConfirmMypageVisible = false"
    />
  </section>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import uploadService from '@/services/upload';
import ModalFileSelect from '@/components/page/applicants/_id/components/ModalFileSelect';

export default defineComponent({
  name: 'FileUpload',
  components: { ModalFileSelect },
  props: {
    files: {
      required: false,
      type: Array,
      default: () => [],
    },
    uploadStatus: {
      required: false,
      type: Object,
      default: () => {},
    },
    downloadingFileIdMap: {
      required: true,
      type: Object,
    },
  },
  emits: ['upload', 'download', 'delete'],
  setup(props, context) {
    const store = useStore();
    const uploadFile = reactive({ data: {} });
    const isOpenModalFileUpload = ref(false);
    const isOpenDialogUploadConfirm = ref(false);
    const isOpenConfirmMypageVisible = ref(false);
    const mypageVisible = reactive({ data: {} });
    const isLoading = ref(false);

    // computed
    const uploadFileName = computed(() => uploadFile.data.name || '');
    const allowFileManagement = computed(
      () => store.getters['company/allowFileManagiment'],
    );

    const isDownloading = id => {
      return props.downloadingFileIdMap[id];
    };
    const resetCurrentUpload = () => {
      uploadFile.data = {};
    };
    // ファイルの選択
    const selectModalFileUpload = payload => {
      if (payload.choice === 2) {
        uploadFile.data = payload.fileInfo;
        isOpenModalFileUpload.value = false;
        isOpenDialogUploadConfirm.value = true;
      } else {
        uploadFile.data = null;
        isOpenModalFileUpload.value = false;
      }
    };
    // ファイルアップロード
    const selectedModalConfirm = payload => {
      if (payload.selected === 'submit')
        context.emit('upload', uploadFile.data);
      isOpenDialogUploadConfirm.value = false;
      resetCurrentUpload();
    };
    const cancelUpload = () => {
      isOpenDialogUploadConfirm.value = false;
      resetCurrentUpload();
    };
    // ファイルダウンロード
    const downloadFile = file => {
      if (window.innerWidth >= 1025) {
        if (!props.downloadingFileIdMap[file.id]) {
          context.emit('download', file);
        }
      }
    };
    const openConfirmMypageVisible = file => {
      isOpenConfirmMypageVisible.value = true;
      mypageVisible.data = file;
    };
    const uploadMypageVisible = async payload => {
      if (payload.selected === 'submit') {
        isLoading.value = true;
        const res = await uploadService.uploadMypageVisible({
          uploadId: payload.data.id,
          request: {
            mypage_visible: !payload.data.mypageVisible,
            applicant_id: store.getters['applicant/applicant'].id,
          },
        });
        isLoading.value = false;
        if (res.success !== true) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.data.message,
            type: false,
          });
        } else {
          payload.data.mypageVisible = !payload.data.mypageVisible;
        }
      }
      isOpenConfirmMypageVisible.value = false;
      mypageVisible.data = {};
    };
    const getUploadMethod = file => {
      if (file.line) {
        return 'トーク画面からLINEで送信';
      } else if (file.messageId) {
        if (file.applicantUpload === true) return '応募者よりメールで送信';
        return 'トーク画面からメールで送信';
      } else if (file.applicantUpload) {
        return '応募者よりアップロード';
      } else if (file.noticeId) {
        return '連絡事項からメールで送信';
      }
      return '管理画面上に格納';
    };

    return {
      uploadFile,
      isOpenModalFileUpload,
      isOpenDialogUploadConfirm,
      isOpenConfirmMypageVisible,
      mypageVisible,
      isLoading,
      uploadFileName,
      allowFileManagement,
      isDownloading,
      selectModalFileUpload,
      selectedModalConfirm,
      cancelUpload,
      downloadFile,
      openConfirmMypageVisible,
      uploadMypageVisible,
      getUploadMethod,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.responsive-overflow {
  max-height: 400px;
  overflow-y: scroll;
}
.as-block {
  margin-bottom: 20px;
  background-color: #fff;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  > .headline.-wrap {
    display: flex;
    align-items: center;
  }
  > .headline .small {
    font-size: 1.1rem;
  }
  .inner {
    padding: 30px 20px;
    .file_upload_btn {
      margin-bottom: 20px;
    }
  }
}

.table-file_list {
  width: 100%;
  font-size: 1.2rem;
  thead {
    background-color: #e2e2e2;
    th {
      padding: 15px;
      text-align: left;
      &:nth-child(1) {
        min-width: 131px;
        width: 210px;
      }
      &:nth-child(2) {
        min-width: 10px;
        width: 100%;
      }
      &:nth-child(3) {
        min-width: 143px;
        width: 190px;
      }
      &:nth-child(4) {
        min-width: 146px;
        width: 190px;
      }
      &:nth-child(5) {
        min-width: 98px;
        width: 98px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e2e2e2;
    }
    td {
      padding: 10px;
      height: 55px;
      word-break: break-all;
      > .spacer {
        display: block;
        height: 35px;
      }
      &.has-checkbox {
        text-align: center;
      }
      input[name='is_mypage_visible'] {
        position: relative;
        top: 2px;
        width: 1.4rem;
        height: 1.4rem;
        margin: 0 5px 0 0;
        border-radius: 0.4rem;
        border: 2px solid #9d9d9d;
        background: #fff;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        &:checked {
          &::after {
            content: url(../../../../../assets/img/check.svg);
            height: 1.1rem;
            width: 1.5rem;
            margin: -5px 0 0 1px;
            position: absolute;
            bottom: 3px;
            left: 0;
          }
        }
      }
    }
    .available {
      color: #16b2d9;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .btn {
      font-size: 1.2rem;
    }
  }
}

.button-delete {
  width: 80px;
}

@media (max-width: ($media_query_tablet)) {
  .table-file_list {
    white-space: nowrap;
    tbody {
      .available {
        color: #333333;
        cursor: unset;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .table-file_list {
    thead {
      th {
        &:nth-child(1) {
          width: auto;
        }
        &:nth-child(2) {
          width: auto;
        }
        &:nth-child(3) {
          width: auto;
        }
        &:nth-child(4) {
          width: auto;
        }
        &:nth-child(5) {
          width: auto;
        }
      }
    }
  }
}
</style>
