import httpClient from '@/http-client';

const RichMessageManager = function _RichMessageManager() {
  const manager = Object.create(RichMessageManager.prototype);
  return manager;
};

RichMessageManager.prototype = {
  async fetchRichMessages() {
    const res = await httpClient.get('/richmessage');
    return res.data;
  },
  // richmessage
  async createRichMessage(payload) {
    const res = await httpClient.post('/richmessage', payload);
    return res.data;
  },
  async updateRichMessage(payload) {
    const res = await httpClient.patch(
      `/richmessage/${payload.richmessage_id}`,
      payload,
    );
    return res.data;
  },
  async deleteRichMessage(id) {
    const res = await httpClient.delete(`/richmessage/${id}`);
    return res.data;
  },
  async sendRichMessage(richmessage_id, payload) {
    const res = await httpClient.post(
      `/richmessage/${richmessage_id}/send/`,
      payload,
    );
    return res.data;
  },
  // richmessage_record
  async fetchRichMessageRecords(tmpPage) {
    const res = await httpClient.get(`/richmessage_record/?page=${tmpPage}`);
    return res.data;
  },
  async createRichMessageRecord(payload) {
    const res = await httpClient.post('/richmessage_record', payload);
    return res.data;
  },
  async fetchRichMessageRecord(richmessageRecordId) {
    const res = await httpClient.get(
      `/richmessage_record/${richmessageRecordId}`,
    );
    return res.data;
  },
  async deleteRichMessageRecord(id) {
    const res = await httpClient.delete(`/richmessage_record/${id}`);
    return res.data;
  },
  // richmessage_ext
  async richMessageCheckRequest(token) {
    const res = await httpClient.post('/richmessage_ext/check', { token });
    return res.data;
  },
};

export default RichMessageManager();
