<script setup>
import { ref } from 'vue';
import { useField } from 'vee-validate';

import FlagSurveyListModal from '@/components/features/flagSurvey/FlagSurveyListModal.vue';
import FlagSurveyDetailModal from '@/components/features/flagSurvey/FlagSurveyDetailModal.vue';

const FLAG_SURVEY_MODE = Object.freeze({
  REGIST: Symbol('flagSurveyRegist'),
  EDIT: Symbol('flagSurveyEdit'),
  DETAIL: Symbol('flagSurveyDetail'),
});

const isViewFlagSurveyListModal = ref(false);
const isViewFlagSurveyDetailModal = ref(false);

const { value: id, errorMessage } = useField('flagSurvey.id');
const { value: title } = useField('flagSurvey.title');

defineEmits(['open-flag-survey-list-modal', 'open-flag-survey-detail-modal']);

const handleSelectFlagSurvey = payload => {
  id.value = payload.id;
  title.value = payload.title;
  isViewFlagSurveyListModal.value = false;
};
</script>

<template>
  <div class="action-inner">
    <div class="action-title">
      <h3 class="title">アンケートを通知</h3>
      <div class="desc">応募者にアンケートを通知します。</div>
      <div class="desc">
        アンケートの回答内容によって、応募者に自動でフラグが付与されます。
      </div>
    </div>
    <div class="action-contents auto-action">
      <div class="gray-box">
        <div class="button-wrapper" :class="{ error: !!errorMessage }">
          <div class="button-inner">
            <button-base
              type="button"
              :button-text="'アンケートを選択'"
              :icon-file-name="'plus_circle'"
              @click="isViewFlagSurveyListModal = true"
            />
          </div>
          <div v-show="!!errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
        <div v-if="title" class="gray-box-inner">
          <ul class="items">
            <li class="item">
              <div class="title">選択中のアンケート</div>
              <div class="contents">
                <div class="side-contents">
                  <div class="side-contents-name">{{ title }}</div>
                  <div class="side-contents-check">
                    <button-base
                      type="button"
                      :button-text="'確認'"
                      @click="isViewFlagSurveyDetailModal = true"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="empty">アンケートを選択してください</div>
      </div>
    </div>
    <flag-survey-list-modal
      :is-view="isViewFlagSurveyListModal"
      @close-modal="isViewFlagSurveyListModal = false"
      @select-flag-survey="handleSelectFlagSurvey($event)"
    />
    <flag-survey-detail-modal
      :is-view="isViewFlagSurveyDetailModal"
      :flag-survey-id="id"
      :flag-survey-detail-mode="FLAG_SURVEY_MODE.DETAIL"
      :flag-survey-mode="FLAG_SURVEY_MODE"
      @close-modal="isViewFlagSurveyDetailModal = false"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
</style>
