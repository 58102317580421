<script setup>
import { ref } from 'vue';
import FileList from '@/components/ui/files/FileList.vue';

const props = defineProps({
  files: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Object,
    default: () => ({
      isError: false,
      message: '',
    }),
  },
});
const emit = defineEmits(['add-file', 'update-files', 'download-file']);

const fileInputRef = ref(null);
const isDragOver = ref(false);

const dropHandler = event => {
  isDragOver.value = false;
  emit('add-file', event.dataTransfer.files);
};
const triggerFileInput = () => {
  if (fileInputRef.value) {
    fileInputRef.value.click();
  }
};
</script>

<template>
  <div>
    <div
      class="drop-area"
      :class="{ 'drag-over': isDragOver }"
      @dragover.prevent="isDragOver = true"
      @dragleave.prevent="isDragOver = false"
      @drop.prevent="dropHandler"
    >
      <div class="drop-area-inner">
        <div class="drop-text">
          ファイルを添付する場合は
          <br />
          ここにドロップしてください
        </div>
        <span class="drop-text-sub">または...</span>
        <input
          ref="fileInputRef"
          type="file"
          style="display: none"
          @change="$emit('add-file', $event.target.files)"
          @click="resetError"
        />
        <button
          id="fileSelect"
          class="select-file-button"
          type="button"
          @click="triggerFileInput"
        >
          パソコンからファイルを選択
        </button>
      </div>
      <div class="file-wrapper">
        <file-list
          :files="props.files"
          @update-files="$emit('update-files', $event)"
          @download-file="$emit('download-file', $event)"
        />
        <div v-show="error.isError" class="failed-files-wrapper">
          <div class="failed-files-title">ファイルの添付に失敗しました</div>
          <ul class="failed-files">
            <li class="failed-file">{{ error.message }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.drop-area {
  border: 1px dashed #ccc;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 32px 16px 0;
  font-size: $font_14;
  text-align: center;
  cursor: pointer;
}

.drop-area.drag-over {
  background-color: #e9e9e9;
}

.drop-area-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 0 28px;
}
.drop-text {
  color: #999;
  font-size: $font_14;
  font-weight: bold;
  line-height: 1.5;
}
.drop-text-sub {
  color: #777;
  font-size: $font_12;
}
.select-file-button {
  width: 210px;
  height: 32px;
  background: #efefef;
  border-radius: 4px;
  font-weight: normal;
  font-size: $font_12;
  cursor: pointer;
}

.file-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-bottom: 16px;
}

.failed-files-wrapper {
  padding: 20px;
  background: #fff6f6;
  border: 1px solid #f8dada;
  border-radius: 4px;
  color: $error-text;
}

.failed-files-title {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 0 14px;
  border-bottom: 4px solid #ededed;
  color: $mochica_color;
  font-weight: bold;
  img {
    display: block;
    width: 14px;
    height: auto;
  }
  margin: 0 0 16px;
  border-bottom-color: #fceaea;
  color: $error-text;
}

.failed-file {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.5;
  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #ee9d9d;
  }
}
</style>
