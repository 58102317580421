// import qs from 'qs';
import httpClient from '@/http-client';

const SendgridSend = function _SendgridSend() {
  const manager = Object.create(SendgridSend.prototype);
  return manager;
};

SendgridSend.prototype = {
  async sendEmail(payload) {
    const formData = new FormData();
    formData.append('applicant_id', payload.applicantId);
    formData.append('title', payload.title);
    formData.append('content', payload.content);
    formData.append('unread_need', payload.unreadNeed);
    payload.files.forEach(file => {
      formData.append('files', file.file, file.filename);
    });
    const res = await httpClient.post('/sendgrid_send', formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    return { success: res.status === 200, data: res.data ? res.data : null };
  },
};
export default SendgridSend();
