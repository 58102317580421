<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">ダッシュボード</span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <ul :options="swiperOption" class="local-nav">
        <li :class="{ active: mode === 0 }">
          <span
            class="item"
            @click="
              $route.name !== 'DashboardBasic'
                ? $router.push({ name: 'DashboardBasic' })
                : () => {};
              postPottosEvent(8);
            "
          >
            基本データ集計
          </span>
        </li>
        <li :class="{ active: mode === 1 }">
          <span
            class="item"
            @click="
              $route.name !== 'DashboardEntry'
                ? $router.push({ name: 'DashboardEntry' })
                : () => {};
              postPottosEvent(9);
            "
          >
            エントリー推移
          </span>
        </li>
        <li :class="{ active: mode === 2 }">
          <span
            class="item"
            @click="
              $route.name !== 'DashboardRecruitment'
                ? $router.push({ name: 'DashboardRecruitment' })
                : () => {};
              postPottosEvent(10);
            "
          >
            採用進捗
          </span>
        </li>
        <li :class="{ active: mode === 3 }">
          <span
            class="item"
            @click="
              $route.name !== 'DashboardKind'
                ? $router.push({ name: 'DashboardKind' })
                : () => {};
              postPottosEvent(11);
            "
          >
            登録種別応募者数
          </span>
        </li>
      </ul>
      <router-view />
    </section>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { postPottosEvent } from '@/utils/pottos';
import '@/assets/swiper.min.css';
import Breakpoints from '@/defines/breakpoints';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';

export default defineComponent({
  name: 'Dashboard',
  components: { BreadCrumbList },
  setup(props, context) {
    const route = useRoute();
    const store = useStore();
    const mode = ref(0);
    const selectionFlowId = ref(
      route.params.selectionFlowId
        ? parseInt(route.params.selectionFlowId, 10)
        : null,
    );

    // watch
    watch(
      () => route,
      async () => {
        selectionFlowId.value = route.params.selectionFlowId
          ? parseInt(route.params.selectionFlowId, 10)
          : null;
        await init();
      },
    );

    watch(route, async () => {
      await init();
    });

    const init = async () => {
      mode.value = route.meta.mode;
      await store.dispatch('page/SET_LOADED');
    };

    onBeforeMount(async () => await init());

    return {
      mode,
      selectionFlowId,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: false,
        SimulateTouch: false,
        noSwiping: true,
        noSwipingClass: 'local-nav',
        on: {
          init() {
            const winWid = window.innerWidth;
            if (winWid <= Breakpoints.MIN_PC_SIZE) {
              this.params.noSwiping = false;
              this.update();
            }
          },
        },
      },
      postPottosEvent,
    };
  },
});
</script>
