<template>
  <modal
    v-if="
      (isVisible && selectionDetailMode === 1 && id) ||
      (isVisible && selectionDetailMode === 2 && id === null)
    "
    :is-view="isVisible"
  >
    <template #content>
      <div class="content add-selection-modal">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl"></div>
            </div>
            <div
              class="modal-header_cancell"
              @click="$emit('onClickBack', true)"
            >
              <img class="icon" src="@/assets/img/close_black.svg" alt />
            </div>
          </section>
          <div class="modal-body">
            <selection-detail-body
              :id="id"
              ref="refSelectionDetailBody"
              :selection-detail-mode="selectionDetailMode"
              :is-modal-selection-mode="true"
              :init-graduated-id="initGraduatedId"
              @updateSelection="selection.data = $event"
            />
          </div>
          <div
            :class="{
              button_area: true,
              'has-three_button':
                middleButton.length > 0 && isLineFriend === false,
            }"
          >
            <button-base
              v-if="selectionDetailMode === 1"
              :button-text="backButtonText"
              button-type="secondary"
              @click="$emit('onClickBack', false)"
            />
            <button-base
              v-if="middleButton.length > 0 && isLineFriend === false"
              :button-text="middleButton"
              :is-loading="isLoading"
              @click="$emit('onClickMiddle', selection.data)"
            />
            <button-base
              :button-text="
                selectionDetailMode === 1
                  ? okButtonText
                  : 'この内容で応募者に選考を追加する'
              "
              :is-loading="isLoading"
              @click="onClickSubmit"
            />
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';

import Modal from '@/components/ui/modals/components/Modal';
import SelectionDetailBody from '@/components/features/selectionDetailBody/components/SelectionDetailBody';

export default defineComponent({
  name: 'ModalSelectionDetail',
  components: { Modal, SelectionDetailBody },
  props: {
    id: {
      type: Number,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    okButtonText: {
      type: String,
      default: 'この内容で応募者に選考を追加する',
    },
    backButtonText: {
      type: String,
      default: '選考の選択に戻る',
    },
    middleButton: {
      type: String,
      default: '',
    },
    selectionDetailMode: {
      type: Number,
      default: 1,
    }, // view: 1 | registration: 2 | edit: 3
    isLineFriend: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    initGraduatedId: {
      type: Number,
      default: null,
    },
  },
  emits: ['onClickBack', 'onClickMiddle', 'onClickSubmit'],
  setup(props, context) {
    const refSelectionDetailBody = ref(null);
    const selection = reactive({ data: null });
    const onClickSubmit = () => {
      const isValidVenues = refSelectionDetailBody.value.validateVenues();
      const isValidSelection =
        refSelectionDetailBody.value.validationSelectionBasicInfo();
      if (isValidVenues === true && isValidSelection === true) {
        context.emit('onClickSubmit', selection.data);
      }
    };
    return { refSelectionDetailBody, selection, onClickSubmit };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
  text-align: left;
  overflow-y: auto;
  .modal-body {
    & :deep(.sec-basic-info) {
      margin: 0 0 3rem;
    }
    & :deep(.sec-venues) {
      margin: 0;
    }
  }
}

img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}

.button_area {
  display: flex;
  width: 92%;
  margin: 30px auto;
  > button {
    width: 48%;
    margin: 1%;
    height: 44px;
  }
  &.has-three_button {
    width: 92%;
    > button {
      width: 32%;
      margin: 1%;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .button_area {
    display: block;
    width: 100%;
    > button {
      width: 100%;
    }
    &.has-three_button {
      > button {
        width: 100%;
      }
    }
  }
}
</style>
