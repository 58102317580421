import httpClient from '@/http-client';

const IssueManager = function _IssueManager() {
  const manager = Object.create(IssueManager.prototype);
  return manager;
};

IssueManager.prototype = {
  /**
   * パスワード再発行のためメールアドレスを送り、再発行URLをメールで受け取る
   */
  async sendIssueMail(email) {
    const response = await httpClient.post('/issue', {
      staff: {
        email,
      },
    });
    return response.data;
  },
  /**
   * パスワードを再発行する
   */
  async IssuePass(payload) {
    const response = await httpClient.post('/issue/password', {
      password: {
        token: payload.token,
        new_password: payload.new_password,
        confirmation: payload.confirmation,
      },
    });
    return response.data;
  },
};
export default IssueManager();
