<script setup>
import { ref } from 'vue';

import RichMessageDetailModal from '@/components/features/richMessage/RichMessageDetailModal.vue';

defineProps({
  richMessage: {
    type: Object,
    default: () => ({
      id: null,
      name: '',
      img: '',
    }),
  },
});

const isViewRichMessageDetailModal = ref(false);
</script>

<template>
  <div class="action-inner">
    <p class="selected-action">リッチメッセージを送信</p>
    <div class="action-contents auto-action">
      <div class="gray-box">
        <div class="gray-box-inner">
          <ul class="items">
            <li class="item">
              <div class="title">選択したリッチメッセージ</div>
              <div class="contents">
                <div class="side-contents">
                  <img
                    class="side-contents-img"
                    :src="richMessage.img"
                    :alt="richMessage.name"
                  />
                  <div class="side-contents-name">{{ richMessage.name }}</div>
                  <div class="side-contents-check">
                    <button-base
                      type="button"
                      :button-text="'確認'"
                      @click="isViewRichMessageDetailModal = true"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <rich-message-detail-modal
      :is-view="isViewRichMessageDetailModal"
      :rich-message-id="richMessage.id"
      @close-modal="isViewRichMessageDetailModal = false"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
.gray-box {
  padding: 20px 20px 16px;
}
</style>
