<template>
  <section v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link :to="{ name: 'ApplicantsOverviewDefault' }">
            応募者一覧
          </router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">
          CSVのダウンロード予約
        </span>
      </template>
    </bread-crumb-list>
    <div class="content-warp">
      <div class="content">
        <div class="content-buttons">
          <div
            class="btn btn-availability -tabNone btn-reserve"
            @click="isVisibleModalReserveCsvDownload = true"
          >
            <img class="icon" src="@/assets/img/add_white.svg" alt="" />
            CSVのダウンロード予約
          </div>
          <div
            class="btn btn-availability -tabNone btn-reserve"
            @click="reloadList"
          >
            <img class="icon" src="@/assets/img/loading_white.svg" alt="" />
            最新の情報に更新
          </div>
        </div>
        <div class="table_setting">
          <div class="notice_table">
            <table class="massage-table">
              <thead class="thead">
                <tr class="thead-row">
                  <th class="thead-th"><span>予約日</span></th>
                  <th class="thead-th"><span>担当者</span></th>
                  <th class="thead-th"><span>権限</span></th>
                  <th class="thead-th"><span>対象応募者</span></th>
                  <th class="thead-th"><span>選択項目</span></th>
                  <th class="thead-th"><span>ステータス</span></th>
                  <th class="thead-th"><span>ダウンロード</span></th>
                  <th class="thead-th"><span>削除</span></th>
                </tr>
              </thead>
              <tbody class="tbody">
                <tr
                  v-for="dlItem in reservedCsvList"
                  :key="dlItem.id"
                  class="tbody-row"
                >
                  <td class="tbody-td">
                    <span>
                      {{
                        $utils.parseDateTime(
                          dlItem.created_at,
                          'YYYY年MM月DD日 HH:mm',
                        )
                      }}
                    </span>
                  </td>
                  <td class="tbody-td">
                    <span v-if="dlItem.staff !== null">
                      {{ dlItem.staff.full_name }}
                    </span>
                  </td>
                  <td class="tbody-td">
                    <span v-if="dlItem.role_id === 1">管理者</span>
                    <span v-if="dlItem.role_id === 2">人事担当者</span>
                    <span v-if="dlItem.role_id === 3">担当者</span>
                  </td>
                  <td class="tbody-td">
                    <span>
                      {{
                        dlItem.applicant_type === 0
                          ? '応募者'
                          : dlItem.applicant_type === 1
                            ? 'LINE友達'
                            : dlItem.applicant_type === 2
                              ? '全員'
                              : dlItem.applicant_type
                      }}
                    </span>
                  </td>
                  <td class="tbody-td">
                    <span>
                      {{
                        dlItem.column_type === 0
                          ? 'フラグ'
                          : dlItem.column_type === 1
                            ? '選考ステータス'
                            : dlItem.column_type === 2
                              ? 'フラグ／選考ステータス'
                              : dlItem.column_type
                      }}
                    </span>
                  </td>
                  <td class="tbody-td">
                    <span>
                      {{
                        dlItem.status === 0
                          ? '処理中'
                          : dlItem.status === 1
                            ? '完了'
                            : dlItem.status === 2
                              ? '失敗'
                              : dlItem.status
                      }}
                    </span>
                  </td>
                  <td class="tbody-td">
                    <span>
                      <button-base
                        class="btn btn-availability btn-download-csv"
                        :class="{
                          disabled:
                            !dlItem.downloadable ||
                            $store.getters['staff/staff'].admin === 2,
                        }"
                        button-text="ダウンロード"
                        :disabled="
                          !dlItem.downloadable ||
                          $store.getters['staff/staff'].admin === 2
                        "
                        @click="downloadCsv(dlItem)"
                      />
                    </span>
                  </td>
                  <td class="tbody-td">
                    <span>
                      <button
                        class="btn btn-caution"
                        @click="openModalDeleteDialog(dlItem)"
                      >
                        削除
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal-dialog
      :is-view="isVisibleModalDelete"
      :accent-color="'#C31E1E'"
      :title="'この応募者CSVを削除します。'"
      :message="'この操作は取り消すことができません。'"
      :button-text1="'キャンセル'"
      :button-text2="'削除する'"
      :alert-flag="true"
      @onSelected="onClickDeleteReserveItem"
      @onclickOuter="isVisibleModalDelete = false"
    />
    <modal-reserve-csv-download
      :is-view="isVisibleModalReserveCsvDownload"
      @onclickOuter="isVisibleModalReserveCsvDownload = false"
      @reserveDownloadCsv="reserveDownloadCsv"
    />
  </section>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import adminService from '@/services/admin';
import applicantService from '@/services/applicants';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import ModalDialog from '@/components/ui/modals/components/ModalDialog';
import ModalReserveCsvDownload from '@/components/page/applicants/csv/components/ModalReserveCsvDownload';

export default defineComponent({
  name: 'ApplicantsCsvDownload',
  components: { BreadCrumbList, ModalReserveCsvDownload, ModalDialog },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const isVisibleModalDelete = ref(false);
    const isVisibleModalReserveCsvDownload = ref(false);
    const reservedCsvList = ref([]);
    const selectedItem = reactive({ data: {} });

    // methods
    const init = async () => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      const res = await applicantService.fetchDownloadReserveList();
      if (res.success) {
        reservedCsvList.value = res.download_list;
      }
      isLoading.value = false;
    };
    const reserveDownloadCsv = async dlItem => {
      const applicantType = dlItem.contactType - 1;
      let columnType = null;
      if (dlItem.downloadCsvType === 'flag') columnType = 0;
      if (dlItem.downloadCsvType === 'status') columnType = 1;
      if (dlItem.downloadCsvType === 'all') columnType = 2;
      if (store.getters['staff/staff'].admin === 2) {
        // 会社マスター管理者の場合
        await adminDownloadCsv(
          applicantType,
          columnType,
          dlItem.graduated_years,
        );
        await init();
        isVisibleModalReserveCsvDownload.value = false;
        return;
      }
      // csvダウンロード予約
      const res = await applicantService.reserveUploadCsv({
        applicant_type: applicantType,
        column_type: columnType,
        graduated_years: dlItem.graduated_years,
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
        timeout: 5000,
      });
      if (res.success === true) await init();
      isVisibleModalReserveCsvDownload.value = false;
    };
    const downloadCsv = async dlItem => {
      // 会社マスター管理者の場合ダウンロード不可
      if (store.getters['staff/staff'].admin === 2) return;
      if (dlItem.downloadable) {
        // csvダウンロード
        const res = await applicantService.downloadUploadCsv(dlItem);
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message:
            res.success === true ? 'ダウンロードを開始します' : res.message,
          type: res.success,
        });
      }
    };
    const adminDownloadCsv = async (
      applicantType,
      columnType,
      graduatedYears,
    ) => {
      const res = await adminService.postAdminApplicantsCsvDownload({
        applicant_type: applicantType,
        column_type: columnType,
        graduated_years: graduatedYears,
      });
      let blob;
      const csvName = 'mochica.csv';
      const csv = [res];
      const type = { type: 'text/csv; charset=Shift-JIS' };
      const link = document.createElement('a');
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      if (window.navigator.msSaveOrOpenBlob) {
        // for ie
        blob = new Blob([bom, csv], type);
        window.navigator.msSaveOrOpenBlob(blob, csvName);
      } else if (window.webkitURL && window.webkitURL.createObjectURL) {
        // for chrome (and safari)
        blob = new Blob([bom, csv], type);
        link.setAttribute('download', csvName);
        link.setAttribute('href', window.webkitURL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else if (window.URL && window.URL.createObjectURL) {
        // for firefox
        blob = new Blob([bom, csv], type);
        link.setAttribute('download', csvName);
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    };
    const deleteReservedData = async () => {
      if (!selectedItem.data.id) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '削除するCSVが選択されていません',
          type: false,
        });
        selectedItem.data = {};
        isVisibleModalDelete.value = false;
        return;
      }
      const res = await applicantService.deleteCsvReservedData({
        reserve_id: selectedItem.data.id,
      });
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await init();
      selectedItem.data = {};
      isVisibleModalDelete.value = false;
    };
    const reloadList = async () => {
      await init();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: 'CSVダウンロードリストを再読み込みしました',
        type: true,
      });
    };
    const openModalDeleteDialog = item => {
      isVisibleModalDelete.value = true;
      selectedItem.data = item;
    };
    const onClickDeleteReserveItem = ({ choice }) => {
      if (choice !== 1) {
        deleteReservedData();
        return;
      }
      // キャンセル
      isVisibleModalDelete.value = false;
      selectedItem.data = {};
    };

    // lifecycle
    onBeforeMount(async () => {
      await init();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      reservedCsvList,
      selectedItem,
      isLoading,
      isVisibleModalDelete,
      isVisibleModalReserveCsvDownload,
      reserveDownloadCsv,
      downloadCsv,
      openModalDeleteDialog,
      onClickDeleteReserveItem,
      deleteReservedData,
      reloadList,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

:deep(.area-table) {
  width: 100%;
}
.content-warp {
  min-height: calc(100vh - 100px);

  padding: 20px 20px 100px;
  background-color: #e2e2e2;
}
.content {
  height: 90vh;
  padding: 40px 30px;
  overflow-y: auto;
  background-color: #fff;
  &-buttons {
    display: flex;
  }
}
.btn-reserve {
  margin-bottom: 30px;
  margin-right: 10px;
}
.table_setting {
  height: 100%;
  overflow: auto;
}
.btn-download-csv {
  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
}
.massage-table {
  width: 100%;
  white-space: nowrap;
  margin-bottom: 20px;
  .thead-th {
    padding: 15px 10px;
    text-align: left;
    background-color: #e2e2e2;
  }
  .tbody-row {
    &:hover {
      background-color: #f1f1f1;
    }
  }
  .tbody-td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
}
.content-warp {
  .list-wrapper {
    display: flex;
    padding: 20px;
    margin-bottom: 10px;
    background-color: #fff;
  }
}
.header-content {
  margin-bottom: 30px;
  padding: 20px 40px;
  background: #fff;
  font-size: 1.4rem;
  font-weight: bold;
}
.no-lists-contents {
  height: 150px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline-warp {
  display: inline-block;
}
.headline-area {
  padding: 20px 0 15px;
  border-top: 1px solid #e5e5e5;
  font-size: 1.4rem;
  font-weight: bold;
}
.btn-menu {
  background: #fff;
  width: 95px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.input-area {
  padding-bottom: 20px;
  font-size: 1.4rem;
  &.last {
    margin-bottom: 40px;
    border-bottom: 1px solid #e5e5e5;
  }
}
.required {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  vertical-align: 1px;
  color: #fff;
  font-size: 1rem;
  background-color: #b30000;
}
.table-row {
  + .table-row {
    margin-top: 15px;
  }
  td {
    vertical-align: top;
  }
  td:nth-child(n + 2) {
    padding-left: 15px;
  }
  .txt-warning {
    padding-left: 50px;
  }
}
.name-label {
  width: 50px;
  text-align: center;
}
.radio-wrapper {
  + .radio-wrapper {
    margin-left: 10px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
input,
select {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #adadad;
  vertical-align: middle;
}
.input-sizefull {
  width: 100%;
}
.input-sizehalf {
  width: 260px;
}
.select-sotsunen {
  width: 260px;
}
.select-birthday {
  width: 140px;
  margin-right: 5px;
}
.txt-warning {
  margin-top: 5px;
}
.red {
  color: #c31e1e;
}
.delete-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  padding: 15px 10px;
  background-color: $mochica_color_red;
  margin: 0 auto;
}

@media (max-width: ($media_query_tablet)) {
  .content {
    padding: 40px;
  }
  .inline-warp {
    display: block;
  }
  .input-sizehalf {
    width: 100%;
    max-width: 260px;
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px math_size(20);
  }
  .table-row {
    width: 100%;
    td {
      display: block;
    }
    td:nth-child(n + 2) {
      padding-top: 10px;
      padding-left: 0;
    }
  }
}

@media (min-width: ($media_query_sp)) {
  .minWid600 {
    min-width: 600px;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
