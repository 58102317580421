<template>
  <li
    class="row-inner reminder_password_col"
    :class="{
      'is-not_linked_google':
        isEditSelection === true &&
        isConnectedGoogleCalendar === true &&
        timetable.hasGoogleEventId !== true,
      'is-linked_google':
        isEditSelection === true &&
        isConnectedGoogleCalendar === true &&
        timetable.hasGoogleEventId === true,
      'is-error_linked_google':
        isEditSelection === true &&
        isConnectedGoogleCalendar === true &&
        timetable.isGoogleSyncError === true,
    }"
  >
    <!-- 開催時間表示画面項目 -->
    <!-- Googleカレンダーステータス, 更新者・作成者・主催者表示 -->
    <div class="row-inner-upper">
      <div
        v-if="
          timetable.updateStaffName ||
          timetable.googleHostStaffName ||
          timetable.createStaffName ||
          isConnectedGoogleCalendar === true
        "
        class="check-google_calendar"
      >
        <template v-if="isConnectedGoogleCalendar === true">
          <!-- 社外担当者かつ開催時間編集時は編集できない旨のテキスト表示 -->
          <template
            v-if="
              isEditTimetable === true &&
              isGoogleMember === false &&
              (timetable.hasGoogleEventId === true ||
                timetable.isGoogleSyncError === true)
            "
          >
            <p class="google_edit_status">
              ※すでにGoogleカレンダーに登録済みのため、編集できません
            </p>
          </template>
          <!-- 社内担当者 -->
          <template v-else>
            <div
              v-if="timetable.hasGoogleEventId === true"
              class="google_status"
            >
              Googleカレンダー登録済み
            </div>
            <template v-else-if="timetable.isGoogleSyncError === true">
              <div class="google_status">Googleカレンダー登録失敗</div>
            </template>
            <div v-else class="google_status">Googleカレンダー未登録</div>
          </template>
        </template>
        <!-- 更新者・作成者・主催者表示（開催時間編集時は非表示） -->
        <div v-if="isEditTimetable !== true" class="staff_data">
          <p v-if="timetable.updateStaffName">
            <span>更新者</span>
            - {{ timetable.updateStaffName }}
            {{ $utils.parseDateTime(timetable.updatedAt, 'YYYY/MM/DD HH:mm') }}
          </p>
          <p
            v-if="
              isConnectedGoogleCalendar === true &&
              timetable.googleHostStaffName
            "
          >
            <span>主催者</span>
            - {{ timetable.googleHostStaffName }}
            {{
              $utils.parseDateTime(
                timetable.googleHostStaffUpdatedAt,
                'YYYY/MM/DD HH:mm',
              )
            }}
          </p>
          <p v-else-if="timetable.createStaffName">
            <span>作成者</span>
            - {{ timetable.createStaffName }}
            {{ $utils.parseDateTime(timetable.createdAt, 'YYYY/MM/DD HH:mm') }}
          </p>
        </div>
      </div>
    </div>
    <div class="error_message">
      <!-- Googleカレンダー登録エラーの場合 -->
      <div
        v-if="
          isEditTimetable !== true &&
          timetable.isGoogleSyncError === true &&
          isConnectedGoogleCalendar === true &&
          isGoogleMember === true
        "
        class="error_message_inner"
      >
        <p class="error_message_title">Googleカレンダー登録に失敗しました</p>
        <p class="error_message_caption">
          再登録を行なってください。何度も失敗する場合は
          <a href="/" target="_blank">ヘルプページ</a>
          をご参照ください。
        </p>
        <button-base
          :is-loading="isLoading"
          icon-file-name="loading_arrow"
          button-text="Googleカレンダーへ再登録"
          :rejected-role-ids="[3]"
          :rejected-admin-ids="[2]"
          @click="updateGoogleEventLocal"
        />
      </div>
      <!-- Zoom登録エラーの場合 -->
      <div
        v-if="
          isEditTimetable !== true &&
          timetable.isZoomSyncError === true &&
          enableZoom === true
        "
        class="error_message_inner"
      >
        <p class="error_message_title">Zoom登録に失敗しました</p>
        <p class="error_message_caption">
          再登録を行なってください。何度も失敗する場合は
          <a href="/" target="_blank">ヘルプページ</a>
          をご参照ください。
        </p>
        <button-base
          :is-loading="isLoading"
          icon-file-name="loading_arrow"
          button-text="Zoomへ再登録"
          :rejected-role-ids="[3]"
          :rejected-admin-ids="[2]"
          @click="updateZoomMeetingLocal"
        />
      </div>
    </div>
    <!-- 開催時間・参加人数・出席確認 -->
    <div class="row-inner-upper">
      <div class="venue-block time-block">
        <p class="title">開催時間</p>
        <div class="value">
          <p>
            {{ $utils.parseTime(timetable.start) }}
            〜
            {{ $utils.parseTime(timetable.last) }}
          </p>
        </div>
      </div>
      <div class="venue-block limit-block">
        <p class="title">参加人数</p>
        <div class="value">
          <p>{{ timetable.actual }} / {{ timetable.limit }}名</p>
        </div>
      </div>
      <div class="venue-block password-block">
        <p class="title">出席確認</p>
        <div
          class="reminder-check"
          :class="{ 'is-checked': timetable.reminderCheck }"
        >
          {{
            timetable.reminderCheck
              ? `確認する（パスワード：${timetable.reminderPassword}）`
              : '確認しない'
          }}
        </div>
      </div>
    </div>
    <!-- 選考担当者・社内施設割り当て -->
    <div class="row-inner-upper">
      <div
        class="venue-block select_staff"
        :class="{ 'has-googleResources': googleResources.length > 0 }"
      >
        <p class="title">選考担当割り当て</p>
        <div class="select_staff_inner">
          <div class="is-display">
            <p>選考担当者</p>
            <div
              v-if="
                Array.isArray(timetable.staffs) && timetable.staffs.length > 0
              "
              class="value"
            >
              {{
                timetable.staffs
                  .map(x => `${x.lastname}${x.firstname}`)
                  .join(', ')
              }}
            </div>
            <div v-else class="value_gray">担当者なし</div>
          </div>
          <div v-if="googleResources.length > 0">
            <p>社内施設</p>
            <div
              v-if="
                Array.isArray(timetable.selectedCalendarResources) &&
                timetable.selectedCalendarResources.length > 0
              "
              class="value"
            >
              {{
                timetable.selectedCalendarResources
                  .map(resource => resource.name)
                  .join(', ')
              }}
            </div>
            <div v-else class="value_gray">社内施設なし</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-inner-upper btn-send_assignment_reminder">
      <button-base
        :button-text="'選考担当者にリマインドメールを今すぐ送信'"
        :button-type="'primary'"
        :is-disabled="isValidSendAssignmentReminder === true"
        :is-loading="isLoading"
        :rejected-role-ids="[3]"
        :rejected-admin-ids="[2]"
        @click="openSendAssignmentReminderModal(timetable)"
      />
    </div>

    <!-- 参加予定の応募者名（開催時間表示・編集画面共通項目） -->
    <div class="row-inner-upper has-delete">
      <div class="venue-assignment_wrapper">
        <!-- 参加予定の応募者名は選考編集画面のみ表示 -->
        <div
          v-if="isEditSelection === true"
          class="venue-block venue-assignment"
        >
          <p class="title">参加予定の応募者名</p>
          <div v-if="timetable.applicantNames.length === 0" class="value_gray">
            <p>参加予定の応募者はいません</p>
          </div>
          <div v-else class="value_applicant">
            <div
              class="applicant_box"
              :class="{
                applicant_box: true,
                'is-open':
                  timetable.applicantNames.length <= APPLICANTS_LIMIT_COUNT ||
                  isOpenApplicantList === true,
              }"
            >
              <span
                v-for="(appName, iName) in timetable.applicantNames"
                :key="iName"
                class="applicant_name"
                @click="openApplicantDetail(appName.id)"
              >
                {{ appName.name }}
              </span>
            </div>
          </div>
          <div
            v-if="timetable.applicantNames.length > APPLICANTS_LIMIT_COUNT"
            class="btn_applicant_list"
          >
            <button
              v-if="isOpenApplicantList === false"
              class="btn_more"
              @click="isOpenApplicantList = true"
            >
              続きを見る
            </button>
            <button
              v-else
              class="btn_more"
              @click="isOpenApplicantList = false"
            >
              元に戻す
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Zoom情報表示 -->
    <div v-if="timetable.zoomId" class="zoom_display_area">
      <div class="zoom_id_area">
        <p class="zoom_display_area-title">Zoomの情報</p>
        <p class="zoom_display_area-text">
          ミーティングID: {{ timetable.zoomId }}
        </p>
      </div>
      <div class="zoom_url_area">
        <div>
          <p class="zoom_display_area-title">ホスト用URL</p>
          <p class="zoom_display_area-text">
            ホスト:
            {{ timetable.zoomHostName ? timetable.zoomHostName : '削除済み' }}
          </p>
        </div>
        <button-clipboard-copy
          :text="timetable.zoomStartUrl"
          blue-button-text="URLコピー"
        />
      </div>
      <div class="zoom_url_area">
        <p class="zoom_display_area-title">参加者用URL</p>
        <button-clipboard-copy
          :text="timetable.zoomJoinUrl"
          blue-button-text="URLコピー"
        />
      </div>
    </div>
  </li>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import ButtonClipboardCopy from '@/components/ui/buttons/components/ButtonClipboardCopy';

export default defineComponent({
  name: 'SelectionsVenueTimetable',
  components: { ButtonClipboardCopy },
  props: {
    iVenue: {
      type: Number,
      required: true,
    },
    iTimetable: {
      type: Number,
      required: true,
    },
    isEditTimetable: {
      type: Boolean,
      required: true,
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    googleResources: {
      type: Array,
      default: () => [],
    },
    isConnectedGoogleCalendar: {
      type: Boolean,
      default: false,
    },
    isEditSelection: {
      type: Boolean,
      default: false,
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    selectionVenues: {
      type: Array,
      default: () => [],
    },
    sendAssignmentReminder: {
      type: Function,
      default: () => {},
    },
    updateGoogleEvent: {
      type: Function,
      default: () => {},
    },
    updateZoomMeeting: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['fetchAll'],
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const isOpenApplicantList = ref(false);

    // computed
    const isGoogleMember = computed(() => {
      return store.getters['staff/staff']
        ? store.getters['staff/staff'].is_google_member
        : false;
    });
    const venue = computed(() => props.selectionVenues[props.iVenue]);
    const timetable = computed(() => venue.value.timetables[props.iTimetable]);
    // リマインダーメール送信条件
    const isValidSendAssignmentReminder = computed(
      () =>
        // 応募者・担当者が0、もしくは開催日が当日より過去の場合はリマインダーメールは送れない
        timetable.value.applicantNames.length === 0 ||
        timetable.value.staffs.length === 0 ||
        moment(venue.value.date).isBefore(moment()),
    );

    // methods
    // 選考担当者にリマインドメールを通知
    const openSendAssignmentReminderModal = () => {
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        title: '選考担当者にリマインドメールを通知',
        message:
          '選考に割り当てられている担当者に\n今すぐリマインドメールを通知します。',
        buttonText1: 'キャンセル',
        buttonText2: '通知',
        isDisabledShowModalDisplayed: true,
        onSelected: async payload => {
          if (isLoading.value === true) return;
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          if (payload.choice !== 2) return;
          isLoading.value = true;
          await props.sendAssignmentReminder(
            props.iVenue,
            props.iTimetable,
            store,
          );
          isLoading.value = false;
        },
        onclickOuter: () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
      });
    };
    // Googleカレンダーへ再登録
    const updateGoogleEventLocal = async () => {
      isLoading.value = true;
      const res = await props.updateGoogleEvent(
        venue.value.id,
        timetable.value.id,
      );
      if (res.success === true) context.emit('fetchAll');
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      isLoading.value = false;
    };
    // Zoomへ再登録
    const updateZoomMeetingLocal = async () => {
      isLoading.value = true;
      const res = await props.updateZoomMeeting(
        venue.value.id,
        timetable.value.id,
      );
      if (res.success === true) context.emit('fetchAll');
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.zoom_error_messages && res.zoom_error_messages.length > 0
            ? res.zoom_error_messages[0]
            : res.message,
        type:
          res.zoom_error_messages && res.zoom_error_messages.length > 0
            ? false
            : res.success,
      });
      isLoading.value = false;
    };
    // 応募者詳細画面表示
    const openApplicantDetail = applicantId => {
      window.open(`/applicants/${applicantId}?menu=0`, '_blank');
    };
    const validateTimetable = () => {
      // 親のコンポーネントから呼ばれるので注意（編集画面ではないのでvalidationは必ずtrue）
      return true;
    };

    return {
      APPLICANTS_LIMIT_COUNT: 10,
      venue,
      isLoading,
      isOpenApplicantList,
      isGoogleMember,
      timetable,
      openSendAssignmentReminderModal,
      updateGoogleEventLocal,
      updateZoomMeetingLocal,
      isValidSendAssignmentReminder,
      openApplicantDetail,
      validateTimetable,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/components/features/selectionDetailBody/assets/selections-timetable.scss';

.is-not_linked_google {
  .google_status {
    padding-left: 22px;
    background-color: #d6971d;
    background-image: url('../../../../assets/img/icon_google_not_linked.svg');
    background-size: 17px auto;
    background-position: 20px 6px;
  }
}

.is-linked_google {
  .google_status {
    padding-left: 34px;
    background-color: #12b312;
    background-image: url('../../../../assets/img/icon_google_linked.svg');
    background-size: 19px auto;
    background-position: 20px 8px;
  }
}

.is-error_linked_google {
  .google_status {
    padding: 8px 10px 8px 26px;
    color: #fff;
    background-color: #c31e1e;
    background-image: url('../../../../assets/img/icon_google_error.svg');
    background-position: 15px 7px;
  }
}
</style>
