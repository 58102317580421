import cloneDeep from 'lodash.clonedeep';
import { computed, ref } from 'vue';

import SEARCH_CONSTS from '@/components/features/searchApplicants/defines/search';

export default function useSearchSurveys(
  surveys = [],
  closeAllDropDown = () => {},
) {
  /**
   * searchSurveys
   *   [{ item: { id: ?Number, name: String, name_jp: String, isAdditional: Boolean }]
   */
  const searchSurveys = ref([]);
  /**
   * searchSurveyTexts
   *   [String]
   */
  const searchSurveyTexts = ref([]);
  const visibleSurveyIds = ref(searchSurveys.value.map(() => false));
  const selectableSurveys = ref(cloneDeep(surveys));

  // computed
  const visibleAddText = computed(
    () => searchSurveys.value.length < SEARCH_CONSTS.MAX_SEARCH_SURVEY_COUNT,
  );
  const getSurveys = computed(() => {
    const surveyIds = searchSurveys.value.map(x => x.id);
    return selectableSurveys.value.map(survey => {
      const list = Object.assign({}, survey, {
        name: survey.name_jp,
        name_jp: survey.name,
        isAdditional: true,
        disabled: surveyIds.filter(id => id === survey.id).length > 0,
      });
      return list;
    });
  });

  // methods
  /**
   * SearchSurveysのリセット
   */
  const resetSearchSurveys = () => {
    searchSurveys.value = [];
    searchSurveyTexts.value = [];
    visibleSurveyIds.value = [];
  };
  /**
   * SelectableSearchSurveysのリセット
   */
  const resetSelectableSurveys = newSearchSurveys => {
    selectableSurveys.value = cloneDeep(newSearchSurveys);
  };
  /**
   * SearchSurveysの更新
   * @param {
   *   item: {
   *     id: Number,
   *     name: String,
   *     name_jp: String,
   *     isAdditional: Boolean,
   *   },
   * } payload
   * @param {Number} i
   * @param {Function} nextTick
   */
  const updateSearchSurvey = (payload, i, nextTick) => {
    const surveys = cloneDeep(searchSurveys.value);
    surveys[i] = {
      id: payload.item.id,
      name: payload.item.name_jp,
      disabled: surveys.filter(x => x.id === payload.item.id).length > 0,
      name_jp: payload.item.name,
      isAdditional: true,
    };
    searchSurveys.value = surveys;
    // 選択後にはdropdownを閉じる
    nextTick(() => closeSearchSurveyDropDown());
  };
  /**
   * searchSurveyTextの更新
   * @param {Number} index
   * @param {String} value
   */
  const updateSearchSurveyTexts = (i, value) => {
    searchSurveyTexts.value[i] = value;
  };
  /**
   * searchSurveyの追加
   */
  const addSurveysRaw = () => {
    closeSearchSurveyDropDown();
    if (
      visibleAddText.value &&
      getSurveys.value.filter(x => x.disabled === false).length > 0 &&
      searchSurveys.value.filter(x => x.id === null).length === 0
    ) {
      // 制限数以下、かつ選択可能なGoogleフォームが存在し、かつ他のGoogleフォーム名を全て選択している場合
      searchSurveys.value.push({ id: null });
    }
  };
  /**
   * searchSurveyの削除
   * @param {Number} index
   */
  const removeSurveysRow = index => {
    searchSurveys.value.splice(index, 1);
    searchSurveyTexts.value.splice(index, 1);
    closeSearchSurveyDropDown();
  };
  /**
   * DropDownを開く
   * @param {Number} index
   */
  const openDropDownSurveyIds = index => {
    closeSearchSurveyDropDown();
    visibleSurveyIds.value[index] = true;
  };
  /**
   * DropDownを閉じる
   * @param {Number} index
   */
  const closeSearchSurveyDropDown = () => {
    visibleSurveyIds.value = visibleSurveyIds.value.map(() => false);
    closeAllDropDown();
  };
  /**
   * プリセットを読み込む
   * @param {Object[]} newSearchSurveys
   * @param {Object[]} newSearchSurveyTexts
   */
  const loadSurveyPreset = (newSearchSurveys, newSearchSurveyTexts) => {
    searchSurveys.value = cloneDeep(newSearchSurveys);
    searchSurveyTexts.value = cloneDeep(newSearchSurveyTexts);
    visibleSurveyIds.value = searchSurveys.value.map(v => false);
  };

  return {
    searchSurveys,
    searchSurveyTexts,
    visibleAddText,
    visibleSurveyIds,
    getSurveys,
    resetSearchSurveys,
    resetSelectableSurveys,
    updateSearchSurvey,
    updateSearchSurveyTexts,
    addSurveysRaw,
    removeSurveysRow,
    openDropDownSurveyIds,
    closeSearchSurveyDropDown,
    loadSurveyPreset,
  };
}
