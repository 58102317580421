export default [
  { id: 0, name: '選考名', keyName: 'eventId', disabled: false },
  { id: 1, name: 'ステータス', keyName: 'progressStatusIds', disabled: false },
  { id: 2, name: '登録経路', keyName: 'channelId', disabled: false },
  { id: 3, name: '担当者', keyName: 'staffId', disabled: false },
  { id: 4, name: '応募者の名前', keyName: 'name', disabled: false },
  { id: 5, name: 'フリガナ', keyName: 'nameKana', disabled: false },
  { id: 6, name: '性別', keyName: 'genderId', disabled: false },
  { id: 7, name: 'メール', keyName: 'email', disabled: false },
  { id: 8, name: '電話番号', keyName: 'tel', disabled: false },
  { id: 9, name: '住所', keyName: 'address', disabled: false },
  { id: 10, disabled: false, name: '学校名', keyName: 'school' },
  { id: 11, name: '学部', keyName: 'faculty', disabled: false },
  { id: 12, name: '学科', keyName: 'department', disabled: false },
  { id: 13, name: '文理', keyName: 'departmentCategoryId', disabled: false },
  { id: 14, name: '登録日', keyName: 'createdAt', disabled: false },
  { id: 15, name: 'LINEエントリー日', keyName: 'activatedAt', disabled: false },
  { id: 16, name: 'ブロック状況', keyName: 'lineBlock', disabled: false },
  { id: 17, name: '応募者ID', keyName: 'appId', disabled: false },
  { id: 18, name: '参加日', keyName: 'attendanceDate', disabled: false },
  { id: 19, name: '参加確認', keyName: 'reminderCheck', disabled: false },
  { id: 20, name: '連絡方法', keyName: 'contactTypeId', disabled: false },
];
