<template>
  <div
    class="wrapper-emoji-picker"
    :class="[isOutlined ? 'is-outlined' : 'not-outlined']"
  >
    <vue-emoji-picker
      v-if="isVisibleEmojiPicker"
      class="emoji-picker"
      :native="true"
      :hide-search="true"
      :group-names="{
        recent: 'よく使う物',
        smileys_people: 'ピープル',
        animals_nature: '自然',
        food_drink: '食べ物',
        activities: 'アクティビティ',
        travel_places: '場所',
        objects: '物',
        symbols: 'シンボル',
        flags: '国旗',
      }"
      :static-texts="{ skinTone: '肌の色を選択' }"
      :display-recent="true"
      @select="selectEmoji"
    />
    <button
      class="btn-emoji-invoker"
      @click="isVisibleEmojiPicker = !isVisibleEmojiPicker"
    >
      <span>絵文字</span>
    </button>
  </div>
</template>

<script>
import VueEmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';

import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'EmojiPicker',
  components: { VueEmojiPicker },
  props: {
    isOutlined: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selected'],
  setup(props, context) {
    const isVisibleEmojiPicker = ref(false);
    const selectEmoji = emojiObj => {
      context.emit('selected', emojiObj.i);
      isVisibleEmojiPicker.value = false;
    };
    return { isVisibleEmojiPicker, selectEmoji };
  },
});
</script>

<style scoped lang="scss">
.wrapper-emoji-picker {
  position: relative;

  &.not-outlined {
    .emoji-picker {
      position: absolute;
      z-index: 3;
      left: -20px;
      bottom: 35px;
    }
    .btn-emoji-invoker {
      font-weight: 400;
      font-size: 1.1rem;
      display: inline-block;
      cursor: pointer;
      position: relative;

      span {
        padding-left: 20px;
      }

      span::before {
        content: '';
        mask-image: url('../../../../assets/img/icon_emoji.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        display: inline-block;
        background-color: #616061;
        position: relative;
        height: 18px;
        width: 18px;
        position: absolute;
        top: -3px;
        left: -2px;
      }
    }
  }

  &.is-outlined {
    .emoji-picker {
      position: absolute;
      z-index: 3;
      bottom: 50px;
    }

    .btn-emoji-invoker {
      font-weight: 400;
      font-size: 1.3rem;
      padding: 6px 10px 10px;
      display: inline-block;
      border: solid 1px #cccccc;
      border-radius: 6px;
      cursor: pointer;

      span::before {
        content: '';
        mask-image: url('../../../../assets/img/emoji_black.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        display: inline-block;
        background-color: #000000;
        position: relative;
        height: 20px;
        width: 20px;
        top: 3.5px;
        margin-right: 4px;
      }
    }
  }
}
</style>
