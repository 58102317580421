<template>
  <main class="flexbox width-sp-limit">
    <div class="contents">
      <div class="form_intro">
        <p class="mochica_desc">
          <span class="company_name">{{ companyName }}</span>
        </p>
      </div>
      <div class="reserve_result">
        <h2 class="reserve_result_desc">
          以下の内容で登録が完了いたしました。
        </h2>
        <ul class="reserve_result_wrapper">
          <li class="reserve_result_venue reserve_result_item">
            <dl>
              <dt class="reserve_result_venue_company">会社名：</dt>
              <dd class="dt reserve_result_venue_company">
                {{ companyName }}
              </dd>
            </dl>
            <dl>
              <dt class="reserve_result_venue_date">イベント名：</dt>
              <dd class="dt reserve_result_venue_date">
                {{ reserveResultData.event.event_title }}
              </dd>
            </dl>
            <dl>
              <dt class="reserve_result_venue_date">日付：</dt>
              <dd class="dt reserve_result_venue_date">
                {{
                  $utils.parseDateTime(
                    reserveResultData.venue.date,
                    'YYYY年M月D日(ddd)',
                  )
                }}
              </dd>
            </dl>
            <dl>
              <dt class="reserve_result_venue_name">時間：</dt>
              <dd class="dt reserve_result_venue_name">
                {{ $utils.parseTime(reserveResultData.timetable.start) }}〜{{
                  $utils.parseTime(reserveResultData.timetable.last)
                }}
              </dd>
            </dl>
            <dl>
              <dt class="reserve_result_venue_pref">会場名：</dt>
              <dd class="dt reserve_result_venue_pref">
                {{ reserveResultData.venue.name }}
              </dd>
            </dl>
            <dl>
              <dt class="reserve_result_venue_address">住所：</dt>
              <dd class="dt reserve_result_venue_address">
                {{ reserveResultData.venue.pref
                }}{{ reserveResultData.venue.address }}
              </dd>
            </dl>
            <dl>
              <dt class="reserve_result_venue_room">部屋名：</dt>
              <dd class="dt reserve_result_venue_room">
                {{ reserveResultData.venue.room_name }}
              </dd>
            </dl>
            <dl>
              <dt class="reserve_result_venue_notice">連絡事項：</dt>
              <dd class="dt reserve_result_venue_notice">
                {{ reserveResultData.notice }}
              </dd>
            </dl>
          </li>
        </ul>
      </div>
      <p class="reserve_result_txt">
        ご登録いただいたメールアドレスに上記の登録内容を送信いたしました。
        <br />
        ご利用ありがとうございました。
      </p>
    </div>
  </main>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReserveResult',
  props: {
    reserveResultData: {
      type: Object,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.contents {
  width: 100%;
}

.reserve_result {
  padding: 3rem 0 0;
  .reserve_result_desc {
    font-size: 1.6rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 1.6rem;
    padding-bottom: 1.2em;
  }
  .reserve_result_item {
    dl {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      text-align: left;
      display: flex;
      dt {
        font-weight: bold;
        min-width: 110px;
        text-align: right;
      }
    }
  }
}
.reserve_result_txt {
  margin-top: 1.6rem;
  padding-top: 1.2em;
  border-top: 1px solid $border_color;
  font-size: 1.4rem;
}
.mochica_desc {
  text-align: center;
  font-size: 2.6rem;
  margin-bottom: 3rem;
  font-weight: bold;
}
</style>
