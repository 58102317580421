import applicantService from '@/services/applicant';
import applicantsService from '@/services/applicants';
import tagsService from '@/services/tags';
import store from '@/stores';

export default {
  namespaced: true,
  state: {
    applicant: null,
    unreadApplicantCountInGraduatedYear: 0,
    unreadLineCountInGraduatedYear: 0,
  },
  getters: {
    applicant: currentState => currentState.applicant,
    applicantFullName: currentState => {
      return currentState.applicant.firstname === null
        ? currentState.applicant.lastname
        : currentState.applicant.lastname +
            ' ' +
            currentState.applicant.firstname;
    },
    unreadApplicantCountInGraduatedYear: currentState =>
      currentState.unreadApplicantCountInGraduatedYear,
    unreadLineCountInGraduatedYear: currentState =>
      currentState.unreadLineCountInGraduatedYear,
    applicantTagGroups: currentState => {
      const tagGroups = {};
      if (currentState.applicant && currentState.applicant.tagGroups) {
        currentState.applicant.tagGroups.forEach(tagGroup => {
          Object.assign(tagGroups, {
            [tagGroup.id]: tagGroup.name,
          });
        });
      }
      return tagGroups;
    },
    applicantTagsKv: currentState => {
      const tags = {};
      if (currentState.applicant && currentState.applicant.tagGroups) {
        currentState.applicant.tagGroups.forEach(tagGroup => {
          tagGroup.tags.forEach(tag => {
            Object.assign(tags, {
              [tag.id]: tag.name,
            });
          });
        });
      }
      return tags;
    },
    isLineFriend: currentState =>
      currentState.applicant.contactTypeId === 2 &&
      currentState.applicant.email === null,
  },
  mutations: {
    SET_APPLICANT(state, applicant) {
      Object.assign(state, { applicant });
    },
    CLEAR_APPLICANT(state) {
      Object.assign(state, { applicant: null });
    },
    ADD_APPLICANT_TAG_GROUP(state, payload) {
      const tagGroups = state.applicant.tagGroups;
      if (!tagGroups[payload.id]) {
        state.applicant.tagGroups.push({
          id: payload.id,
          name: payload.name,
          tags: [],
        });
      }
    },
    ADD_APPLICANT_TAG(state, tagGroup) {
      state.tagGroups.push(tagGroup);
    },
    SET_UNREAD_APPLICANT_COUNT(state, unreadApplicantCountInGraduatedYear) {
      state.unreadApplicantCountInGraduatedYear =
        unreadApplicantCountInGraduatedYear;
    },
    SET_UNREAD_LINE_COUNT(state, unreadLineCountInGraduatedYear) {
      state.unreadLineCountInGraduatedYear = unreadLineCountInGraduatedYear;
    },
  },
  actions: {
    async FETCH_APPLICANT({ commit }, id) {
      const applicant = await applicantsService.fetchApplicant(id);
      if (applicant) {
        commit('SET_APPLICANT', applicant);
        return applicant;
      }
      return null;
    },
    async FETCH_UNREAD_APPLICANT_COUNT({ commit }, selectedGraduatedYear) {
      const response =
        await applicantService.getUnreadMessagesSummaryGraduatedYearsIdApplicant(
          selectedGraduatedYear,
        );
      if (response.success !== true) return null;
      const unreadApplicantCountInGraduatedYear = response.count;
      commit('SET_UNREAD_APPLICANT_COUNT', unreadApplicantCountInGraduatedYear);
      return unreadApplicantCountInGraduatedYear;
    },
    async FETCH_UNREAD_LINE_COUNT({ commit }, selectedGraduatedYear) {
      const response =
        await applicantService.getUnreadMessagesSummaryGraduatedYearsIdLineCount(
          selectedGraduatedYear,
        );
      if (response.success) {
        const unreadLineCountInGraduatedYear = response.count;
        commit('SET_UNREAD_LINE_COUNT', unreadLineCountInGraduatedYear);
        return unreadLineCountInGraduatedYear;
      }
      return null;
    },
    async UPDATE_APPLICANT({ commit }, applicant) {
      const response = await applicantsService.updateApplicant(applicant);
      await store.dispatch('applicant/FETCH_APPLICANT', applicant.id);
      return response;
    },
    async CLEAR_APPLICANT({ commit }) {
      commit('CLEAR_APPLICANT');
    },
    async ADD_APPLICANT_TAG({ commit }, payload) {
      await tagsService.addApplicantTags(payload.applicantId, payload.tagId);
      store.dispatch('applicant/FETCH_APPLICANT', payload.applicantId);
    },
    async DELETE_APPLICANT_TAG_GROUP({ commit }, payload) {
      await applicantsService.deleteApplicantTagGroup(
        payload.applicantId,
        payload.tagGroupId,
      );
      store.dispatch('applicant/FETCH_APPLICANT', payload.applicantId);
    },
    async ADD_APPLICANT_TAG_GROUP({ commit }, payload) {
      if (payload.selected === undefined || payload.selected === true) {
        commit('ADD_APPLICANT_TAG_GROUP', payload);
      } else {
        // TODO:tagの一括削除とか必要になっってくる？
      }
    },
    async DELETE_APPLICANT_TAG({ commit }, payload) {
      await tagsService.deleteApplicantTags(
        payload.applicantId,
        payload.event.id,
      );
      store.dispatch('applicant/FETCH_APPLICANT', payload.applicantId);
    },
  },
};
