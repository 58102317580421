<template>
  <div class="home_content">
    <p class="home_sub_title">本日の予定</p>
    <p class="event_title">
      {{
        $utils.parseDateTime(new Date().toISOString(), 'YYYY年M月D日(ddd)')
      }}の予定
    </p>
    <full-calendar ref="calendar" :options="calendarOptions" />
    <div @click="$event.stopPropagation()">
      <popup-calendar-detail
        v-if="isOpenDetail"
        :selection="selection.data"
        :position-left="0"
        :position-top="0"
        :position-bottom="0"
        :calendar-cell-width="0"
        :calendar-height="0"
        @onClickClose="closeCalendarDetail"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import '@fullcalendar/core';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import venueCalendarService from '@/services/venues';
import useGoogleCalendar from '@/composables/useGoogleCalendar';
import PopupCalendarDetail from '@/components/features/popupCalendarDetail/components/PopupCalendarDetail';

export default defineComponent({
  name: 'HomeEvent',
  components: { FullCalendar, PopupCalendarDetail },
  setup(props, context) {
    const store = useStore();
    const staff = store.getters['staff/staff'];
    const { baseCalendarOptions, parseVenues } =
      useGoogleCalendar('timeGridFourDay');
    /**
     * 選考詳細ポップアップ表示フラグ
     */
    const isOpenDetail = ref(false);
    /**
     * 選択された選考詳細情報（選考詳細ポップアップで利用）
     */
    const selection = reactive({ data: {} });
    const calendarOptions = reactive({
      ...baseCalendarOptions,
      ...{
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        headerToolbar: { start: 'prev,next today title', end: '' },
        dayMaxEventRows: true,
        height: 550,
        scrollTime: '07:00',
        nowIndicator: true,
        views: {
          dayGrid: { dayMaxEventRows: 5 },
          timeGridFourDay: { type: 'timeGrid', duration: { days: 1 } },
        },
        eventOrder: 'order',
        eventTimeFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
        events: async (info, successCallback, failureCallback) => {
          const events = await fetchVenues(
            store.getters['graduateds/selectedGraduatedId'],
            moment(info.startStr).format('YYYY-MM-DD'),
            moment(info.endStr).format('YYYY-MM-DD'),
          );
          successCallback(events);
        },
        eventColor: '#378006',
        dayPopoverFormat: date => dayPopoverFormat(date),
        eventClick: info => handleEventClick(info),
      },
    });
    const isLoading = ref(false);

    /**
     * 選考担当データとGoogleカレンダーデータ取得
     */
    const fetchVenues = async (graduatedId, startDate, endDate) => {
      if (isLoading.value === true) return;
      isLoading.value = true;
      // カレンダーデータAPI問合せ
      const res = await venueCalendarService.fetchVenuesDay(
        graduatedId,
        startDate,
        endDate,
        // 自分のスタッフIDのみを送る
        staff ? [staff.id] : [],
        [],
      );
      isLoading.value = false;
      if (res.success !== true) {
        // Googleカレンダー以外でエラーの場合、何も表示できない
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return [];
      } else if (
        res.code !== '400' &&
        res.code !== '401' &&
        res.error_message.length > 0
      ) {
        // Googleカレンダーのエラーかつ400.401以外のコードが返ってくる場合、エラーメッセージを表示
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.error_message,
          type: false,
        });
      }
      return parseVenues(res.venues, staff.id);
    };
    /**
     * 選考が多い場合の全選考表示ポップオーバーのフォーマット
     */
    const dayPopoverFormat = date => {
      isOpenDetail.value = false;
      const weekday = moment(
        `${date.date.year}-${date.date.month + 1}-${date.date.day}`,
      ).format('ddd');
      return `${date.date.month + 1}月${date.date.day}日(${weekday}曜日)の予定`;
    };
    /**
     * 選考クリック時の処理
     */
    const handleEventClick = info => {
      // popupが閉じないようイベント伝播を止める
      info.jsEvent.stopPropagation();
      // 表示中のpopup componentがあれば削除
      closeCalendarDetail();
      nextTick(() => {
        // popupのcomponent削除後
        openCalendarDetail(info);
      });
    };
    /**
     * 選考クリック後、選考詳細ポップアップ表示処理
     */
    const openCalendarDetail = info => {
      // 対象selectionをセット
      selection.data = info.event;
      // popupのcomponentをmount
      isOpenDetail.value = true;
    };

    /**
     * 選考詳細ポップアップ非表示処理
     */
    const closeCalendarDetail = () => {
      isOpenDetail.value = false;
    };

    return {
      calendarOptions,
      selection,
      isOpenDetail,
      closeCalendarDetail,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/fullcalendar-colors.scss';

.home_sub_title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
}

.home_content {
  position: relative;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
}

.event_title {
  padding: 15px 10px;
  margin-bottom: -21.6px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border: 1px solid #dcdcdc;
  border-bottom: none;
}

// 今日ボタンを消す
:deep(.fc-today-button) {
  display: none;
}
// 月移動ボタンを消す
:deep(.fc-button-group) {
  display: none;
}
// 日付タイトルを消す
:deep(.fc-toolbar-title) {
  display: none;
}

// 当日の黄色背景色を消す
:deep(.fc-scrollgrid tbody),
:deep(.fc-scrollgrid tbody .fc-daygrid-day) {
  background-color: #fff !important;
}

// 曜日セルの高さ調整
:deep(.fc-col-header-cell .fc-scrollgrid-sync-inner) {
  height: 30px !important;
  background-color: #f5f5f5;
  > a {
    padding: 8px;
    font-size: 14px;
    color: #333;
  }
}

:deep(.calendar-detail) {
  left: auto !important;
  right: 35px !important;
  bottom: 25px !important;
  @media (max-width: ($media_query_sp)) {
    left: 20px !important;
    right: auto !important;
    bottom: 0 !important;
  }
}
</style>
