export default {
  getEnv() {
    let env = null;
    switch (window.location.host) {
      case 'attempt-app.mochica.jp':
        env = 'attempt';
        break;
      case 'test-app.mochica.jp':
        env = 'test';
        break;
      case 'stg-app.mochica.jp':
        env = 'stg';
        break;
      case 'demo-app.mochica.jp':
        env = 'demo';
        break;
      case 'app.mochica.jp':
        env = 'production';
        break;
      default:
        env = 'develop';
        break;
    }
    return env;
  },
};
