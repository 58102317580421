<template>
  <div
    class="calendar-color-tooltip"
    :style="{ top: `${tooltipY}px` }"
    @click.stop
  >
    <p>{{ label }}の色</p>
    <ul>
      <li v-for="item in localCalendarColors" :key="item.color">
        <input
          v-model="item.isSelected"
          type="checkbox"
          :style="{ 'background-color': item.color }"
          @change="selectColor(item)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'CalendarColorTooltip',
  props: {
    label: {
      type: String,
      required: true,
    },
    tooltipY: {
      type: Number,
      required: true,
    },
    selectedColor: {
      type: String,
      default: '',
    },
    calendarColors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['selectColor'],
  setup(props, context) {
    const localCalendarColors = ref([]);

    // methods
    const selectColor = item => {
      // 色の選択時のイベント
      localCalendarColors.value = localCalendarColors.value.map(tmpItem => {
        tmpItem.isSelected = item.color === tmpItem.color;
        return tmpItem;
      });
      context.emit('selectColor', item.color);
    };

    // lifecycle
    onMounted(() => {
      // ローカルで色情報のパース
      localCalendarColors.value = props.calendarColors.map(color => ({
        color,
        isSelected: props.selectedColor === color,
      }));
    });

    return {
      localCalendarColors,
      selectColor,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.calendar-color-tooltip {
  position: absolute;
  left: 34px;
  top: 0;
  width: 160px;
  height: 157px;
  margin-top: 24px;
  padding: 13px 7px 7px 13px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #777;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;

  > p {
    margin-bottom: 10px;
  }

  > ul {
    > li {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin: 0 6px 6px 0;
      background: #fff;
      border-radius: 2px;
      vertical-align: bottom;
      cursor: pointer;
      > input {
        position: relative;
        width: 22px;
        min-width: 22px;
        height: 22px;
        margin-right: 5px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        vertical-align: -4px;
        background-color: #fff;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        &:checked {
          background-color: #4698d1;
          &::after {
            content: url(../../../../assets/img/check_white.svg);
            position: absolute;
            top: 4px;
            left: 3px;
            height: 9px;
            width: 14px;
          }
        }
      }
    }
  }
}
</style>
