<template>
  <modal-window
    :is-visible="isView"
    title="アップロード対象のファイルを選択して下さい。"
    max-width="100%"
    max-height="100%"
    @close="$emit('onClose', $event)"
  >
    <template #content>
      <div>
        <section class="sec-file-upload">
          <div class="file-select">
            <div class="area-file-drop">
              <form enctype="multipart/form-data" novalidate>
                <div
                  class="dropbox"
                  @dragleave.prevent
                  @dragover.prevent
                  @drop.prevent="filesChange($event.dataTransfer.files)"
                >
                  <input
                    type="file"
                    name="file"
                    class="input-file"
                    @change="filesChange($event.target.files)"
                  />
                  <div class="dropbox_inner">
                    <span class="dropbox_inner_txt">
                      ファイルをドラッグ＆ドロップしてください
                    </span>
                    <span class="dropbox_inner_or">または</span>
                    <div class="dropbox_inner_btn">
                      ファイルを選択してください
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="filename_wrapper">
              選択ファイル名
              <p class="filename_item">
                {{
                  fileInfo.data && fileInfo.data.name ? fileInfo.data.name : ''
                }}
              </p>
              <p v-if="!validateFile(fileInfo.data).success" class="txt-err">
                ファイルを選択して下さい。
              </p>
            </div>
          </div>
        </section>
        <div class="btn-col2">
          <button-base
            class="btn cancelBtn"
            button-type="secondary"
            button-text="キャンセル"
            @click="$emit('onClose', $event)"
          />
          <button-base
            class="btn validityBtn"
            button-text="ファイルをアップロード"
            :disabled="validateFile(fileInfo.data).success !== true"
            @click="onSubmit"
          />
        </div>
      </div>
    </template>
  </modal-window>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
import { useStore } from 'vuex';

import { validateFile } from '@/utils/file';

export default defineComponent({
  name: 'ModalFileSelect',
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClose', 'onSelected'],
  setup(props, context) {
    const store = useStore();
    const fileInfo = reactive({ data: {} });

    // watch
    watch(
      () => props.isView,
      () => (fileInfo.data = null),
    );

    // methods
    const filesChange = fileList => {
      if (!fileList.length) return;
      const newFileInfo = {
        name: fileList[0].name,
        size: fileList[0].size,
        type: fileList[0].type,
        lastModifiedDate: fileList[0].lastModifiedDate,
        file: fileList[0],
      };
      const validationResult = validateFile(newFileInfo);
      if (validationResult.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: validationResult.message,
          type: false,
        });
        fileInfo.data = null;
        return;
      }
      fileInfo.data = newFileInfo;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: 'ファイルを選択しました。',
        type: true,
      });
    };
    const onSubmit = () => {
      if (validateFile(fileInfo.data).success === false) return;
      context.emit('onSelected', {
        choice: 2,
        fileInfo: fileInfo.data || null,
      });
    };

    return { fileInfo, filesChange, onSubmit, validateFile };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.sec-file-upload {
  margin: 40px 20px 20px;
  .filename_wrapper {
    text-align: left;
    width: 100%;
    margin: 30px 0;
    padding-top: 30px;
    border-top: 1px solid $border_color;
    .filename_item {
      display: flex;
      align-items: center;
      background: #eceff1;
      height: 3rem;
      border: solid 1px $border_color;
      margin-top: 0.6rem;
      padding: 0 0.6rem;
      border-radius: 0.2rem;
    }
    .txt-err {
      color: #c31e1e;
      padding-top: 4px;
      font-size: 1.2rem;
      text-align: left;
    }
  }
  .file-select {
    margin: 20px 20px 0 20px;

    .area-file-drop {
      .dropbox {
        border: 2px dashed #c5e5f4;
        background: $mochica_light_color;
        color: dimgray;
        padding: 10px 10px;
        min-height: 250px;
        position: relative;
        cursor: pointer;
        @include flex_center_center;
        @media (max-width: 600px) {
          min-height: 180px;
        }
      }
      .dropbox_inner {
        font-size: 1.2rem;
        text-align: center;
      }
      .dropbox_inner_txt {
        font-weight: bold;
        display: block;
        color: #333;
        margin-bottom: 1rem;
      }
      .dropbox_inner_or {
        font-weight: normal;
        display: block;
        margin-bottom: 1.2rem;
      }
      .dropbox_inner_btn {
        display: inline-block;
        line-height: 2.6rem;
        width: 90%;
        height: 3rem;
        border: 1px solid $mochica_color;
        color: $mochica_color;
        background: $white;
        border-radius: 10rem;
      }
      .input-file {
        opacity: 0;
        width: 100%;
        height: 200px;
        position: absolute;
        cursor: pointer;
      }
      .dropbox:hover {
        opacity: 0.8;
      }
      .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
      }
    }
    .file_submit_btn {
      background: $mochica_color;
      border: solid 1px $mochica_border_color;
      height: 3.8rem;
      width: 50rem;
      margin-top: 2rem;
      @include flex_center_center;
      color: $white;
      font-weight: bold;
      border-radius: 5rem;
      letter-spacing: 0.2em;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        transition: 0.3s;
      }
      &.cancell {
        background: $white;
        border: 1px solid #f1f1f1;
        color: #333;
        margin-top: 1rem;
        font-weight: normal;
      }
    }
  }
}

.btn-col2 {
  margin: 20px;
}

@media (max-width: ($media_query_sp)) {
  .sec-file-upload {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
