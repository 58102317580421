import httpClient from '@/http-client';
import Selection from '@/models/selection';

const SelectionManager = function _SelectionManager() {
  const manager = Object.create(SelectionManager.prototype);
  return manager;
};

SelectionManager.prototype = {
  async fetchSelectionSearch(graduated, page = 1, load = 1, searchBody = {}) {
    const path = `/selection/search?graduated_id=${graduated}&load=${load}&page=${page}`;
    const response = await httpClient.post(path, {
      search: searchBody,
    });
    if (response.status === 200) {
      const data = response.data;
      return Object.assign({}, data, {
        selections: data.selections.map(Selection.createFromJSON),
      });
    }
    return null;
  },
  async checkDisable() {
    const result = await httpClient.post('/selection/check_disable', {});
    return result.data;
  },
  async fetchSelectionNotAnnounced(payload) {
    const response = await httpClient.post('/selection/not_announced', payload);
    return response.data;
  },
  async fetchSelectionNotResulted(payload) {
    const response = await httpClient.post('/selection/not_resulted', payload);
    return response.data;
  },
};

export default SelectionManager();
