<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">応募者への通知</span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <div class="content">
        <p class="announce-desc">
          選考に登録した応募者や、人事の評価が完了し選考結果（通過・内定・不採用など）が決まった応募者に対して、その内容を通知します。
          <br />
          以下の5つから応募者へ通知したい内容をお選び下さい。
        </p>
        <div class="announce-btn-wrapepr">
          <div
            class="item announce-btn toselection"
            @click="
              $router.push({ name: 'SelectionAnnounce' });
              postPottosEvent(55);
            "
          >
            <p class="announce-btn-ttl">
              <span>応募者へ</span>
              <span class="is-strong">選考の案内</span>
              <span>を通知する</span>
            </p>
            <p class="announce-btn-txt">
              選考に登録されている応募者へ選考の案内を行います。
            </p>
            <h3 class="announce-btn-bottom">応募者へ選考案内を通知する</h3>
          </div>
          <div
            class="item announce-btn toresult"
            @click="
              $router.push({ name: 'ResultAnnounce' });
              postPottosEvent(56);
            "
          >
            <p class="announce-btn-ttl">
              <span>応募者へ</span>
              <span class="is-strong">選考の結果</span>
              <span>を通知する</span>
            </p>
            <p class="announce-btn-txt">
              人事評価で選考結果を入力した応募者へ選考結果を通知します。
            </p>
            <h3 class="announce-btn-bottom">応募者へ選考結果を通知する</h3>
          </div>
          <div
            class="item announce-btn tonotice"
            @click="
              $router.push({ name: 'NoticeAnnounce' });
              postPottosEvent(57);
            "
          >
            <p class="announce-btn-ttl">
              <span>応募者へ</span>
              <span class="is-strong">連絡事項</span>
              <span>を通知する</span>
            </p>
            <p class="announce-btn-txt">応募者へ連絡事項を通知します。</p>
            <h3 class="announce-btn-bottom">応募者へ連絡事項を通知する</h3>
          </div>
        </div>
        <div class="announce-btn-wrapepr">
          <div class="item">
            <div
              class="announce-btn torichmessage"
              @click="
                $router.push({ name: 'Richmessage' });
                postPottosEvent(58);
              "
            >
              <p class="announce-btn-ttl">
                <span class="is-strong">リッチメッセージ</span>
                <span>を通知する</span>
              </p>
              <p class="announce-btn-txt">
                選択した応募者へLINEでリッチメッセージを通知します。
              </p>
              <h3 class="announce-btn-bottom">リッチメッセージを通知する</h3>
            </div>
            <div
              class="torichmessage tohistory"
              @click="$router.push({ name: 'RichmessageHistory' })"
            >
              送信履歴を表示する
            </div>
          </div>
          <div class="item">
            <div
              class="announce-btn toflagsurvey"
              @click="
                $router.push({ name: 'Flagsurvey' });
                postPottosEvent(59);
              "
            >
              <p class="announce-btn-ttl">
                <span class="is-strong">アンケート</span>
                <span>を通知する</span>
              </p>
              <p class="announce-btn-txt">
                選択した応募者へLINEやメールでアンケートを通知します。
              </p>
              <h3 class="announce-btn-bottom">アンケートを通知する</h3>
            </div>
            <div
              class="toflagsurvey tohistory"
              @click="$router.push({ name: 'FlagsurveyHistory' })"
            >
              送信履歴を表示する
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { postPottosEvent } from '@/utils/pottos';

import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';

export default defineComponent({
  name: 'Announce',
  components: { BreadCrumbList },
  setup(props, context) {
    const store = useStore();
    onBeforeMount(async () => {
      await store.dispatch('page/SET_LOADED');
    });
    return { postPottosEvent };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.content {
  padding: 40px 40px 20px;
  background: #fff;
}
.announce-desc {
  margin-bottom: 1.8rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: 0.05em;
}

.alert_block {
  padding: 10px;
  background: #edf9e8;
  border: 2px solid #afd2b6;
  margin-bottom: 30px;
  .alert_text {
    line-height: 1.4;
    font-weight: bold;
    // color: $mochica_color_red;
  }
}

.modal {
  @include full_modal;
}

//どの通知か選ぶ画面。トップ
.announce-ttl {
  margin: 3rem 0 2.4rem 3.6rem;
  color: $mochica_color;
  display: flex;
  align-items: center;
  &-svg {
    width: 27px;
    fill: $mochica_color;
    path:nth-of-type(1) {
      fill: none;
      stroke: $mochica_color;
    }
    margin-right: 1.2rem;
  }
  &-txt {
    border-bottom: 2px solid;
    padding-bottom: 4px;
  }
}
.band {
  @include band;
  margin-bottom: 3.6rem;
}

h2 {
  font-size: 1.4rem;
  color: $mochica_color;
  text-align: center;
  padding-bottom: 0.6rem;
  margin-bottom: 3.6rem;
  span {
    border-bottom: 1px solid $mochica_color;
  }
}
.announce-btn-wrapepr {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  margin-bottom: calc(10px + 2%);
  border-bottom: 1px solid #909090;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  .item {
    width: calc((100% - 2% * 2) / 3);
    margin: 0 2% 2% 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.announce-btn {
  position: relative;
  transition: all ease 0.3s;
  background-color: #fff;
  border: 4px solid $mochica_color;
  border-radius: 0.4rem;
  padding-bottom: 40px;
  cursor: pointer;
  &-ttl {
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 0.05em;
    text-align: center;
    margin: 0 2rem;
    padding: 2rem 0 1.8rem;
    align-items: baseline;
    .is-strong {
      color: $mochica_color;
      font-weight: bold;
      font-size: 1.8rem;
    }
  }
  &-bottom {
    position: absolute;
    bottom: 0;
    height: 36px;
    width: 100%;
    font-size: 1.4rem;
    background-color: $mochica_color;
    color: $white;
    text-align: center;
    padding-top: 12px;
    &::after {
      content: '';
      background: url(../../assets/img/arrow_white_r.svg) right no-repeat;
      background-size: 1rem;
      width: 12px;
      height: 3rem;
      display: inline-block;
      position: absolute;
      bottom: -2px;
      right: 6px;
    }
  }
  &-img {
    height: 5rem;
    margin: 0 auto 1.2rem;
  }
  &-txt {
    font-size: 1.3rem;
    line-height: 1.8rem;
    letter-spacing: 0.05em;
    text-align: center;
    margin: 0 2rem;
    padding: 2rem 0 1.8rem;
    border-top: 1px solid $mochica_color;
  }
  &.toresult {
    border-color: #ea6f25;
    .announce-btn-ttl .is-strong {
      color: #ea6f25;
    }
    .announce-btn-bottom {
      background-color: #ea6f25;
    }
    .announce-btn-txt {
      border-color: #ea6f25;
    }
    &::after {
      background-color: #ea6f25;
    }
  }

  &.tonotice {
    // opacity: 0.4;
    // pointer-events: none;
    border-color: #43a047;
    .announce-btn-ttl .is-strong {
      color: #43a047;
    }
    .announce-btn-bottom {
      background-color: #43a047;
    }
    .announce-btn-txt {
      border-color: #43a047;
    }
    &::after {
      background-color: #43a047;
    }
  }

  &.torichmessage {
    border-color: #a341eb;
    .announce-btn-ttl .is-strong {
      color: #a341eb;
    }
    .announce-btn-bottom {
      background-color: #a341eb;
    }
    .announce-btn-txt {
      border-top: 1px solid #a341eb;
    }
    &::after {
      background-color: #a341eb;
    }
  }

  &.toflagsurvey {
    border-color: #ff659a;
    .announce-btn-ttl .is-strong {
      color: #ff659a;
    }
    .announce-btn-bottom {
      background-color: #ff659a;
    }
    .announce-btn-txt {
      border-top: 1px solid #ff659a;
    }
    &::after {
      background-color: #ff659a;
    }
  }

  &:hover {
    opacity: 0.6;
  }
}
.tohistory {
  position: relative;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  height: 36px;
  padding-top: 6px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &::after {
    content: '';
    background: url(../../assets/img/arrow_white_r.svg) right no-repeat;
    background-size: 1rem;
    width: 12px;
    height: 3rem;
    display: inline-block;
    position: absolute;
    bottom: -3px;
    right: 6px;
  }
}
.tonotice.tohistory {
  border: 4px solid #43a047;
  background-color: #43a047;
}
.torichmessage.tohistory {
  border: 4px solid #a341eb;
  background-color: #a341eb;
}
.toflagsurvey.tohistory {
  border: 4px solid #ff659a;
  background-color: #ff659a;
}

//モーダル 選考通知
.modal {
  @include full_modal;
}

@media (max-width: 1260px) {
  .announce-btn-wrapepr {
    margin-bottom: calc(10px + 2%);
    .item {
      width: calc((100% - 2%) / 2);
      margin: 0 2% 2% 0;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .content {
    padding: 20px;
  }
  .announce-btn-wrapepr {
    display: block;
    margin-bottom: 30px;
    .item {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
}
</style>
