import qs from 'qs';

import httpClient from '@/http-client';

const ApplicantEntryManager = function _ApplicantEntryManager() {
  const manager = Object.create(ApplicantEntryManager.prototype);
  return manager;
};

ApplicantEntryManager.prototype = {
  async verifyToken(token) {
    const query = qs.stringify({ token });
    const res = await httpClient.get(`/entries/verify_token?${query}`);
    return res;
  },

  async verifyEmail(token, email) {
    const res = await httpClient.post('/entries/verify_email', {
      token,
      email,
    });
    return res;
  },

  async resendOtp(token, email) {
    const res = await httpClient.post('/entries/resend_otp', {
      token,
      email,
    });
    return res;
  },

  async verifyOtp(token, email, otp) {
    const res = await httpClient.post('/entries/verify_otp', {
      token,
      email,
      otp,
    });
    return res;
  },

  async previewEntryForm(payload) {
    const res = await httpClient.post('/entries/preview', payload);
    return res;
  },

  async registerEntryForm(payload) {
    const res = await httpClient.post('/entries/register', payload);
    return res;
  },

  /**
   * エントリーフォームの送信
   */
  async sendEntryFormToLine(payload) {
    const res = await httpClient.post(
      '/applicants/provision/lineform',
      payload,
    );
    return res.data;
  },
  /**
   * LINE切り替え案内の送信
   */
  async sendSwitchLineAnnounce(payload) {
    const res = await httpClient.post(
      '/applicants/provision/mail_to_line_form',
      payload,
    );
    return res.data;
  },
};

export default ApplicantEntryManager();
