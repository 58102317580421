<template>
  <div class="content">
    <div class="graph-setting">
      <div class="viewTerm">
        {{ $utils.parseDateTime(termStart, 'YYYY年M月D日') }}
        〜 {{ $utils.parseDateTime(termEnd, 'YYYY年M月D日') }}
        までの推移
      </div>
      <div class="viewSetting">
        <label for="termSetting" class="termSetting">
          <span class="label">期間</span>
          <el-date-picker
            :model-value="termStart"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder=""
            name="date"
            class="data_form"
            @update:model-value="termStart = $event"
          />
          〜
          <el-date-picker
            :model-value="termEnd"
            type="date"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            placeholder=""
            name="date"
            class="data_form"
            @update:model-value="termEnd = $event"
          />
        </label>
        <label for="termUnitSelected" class="termUnitSelected">
          <span class="label">
            <span>表示</span>
            <br class="-spView" />
            <span>単位</span>
          </span>
          <select id="termUnitSelected" v-model="termUnit" class="unitSelect">
            <option
              v-for="unit in TERM_UNITS"
              :key="unit.id"
              :label="unit.name"
              :value="unit.id"
            >
              {{ unit.name }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <div class="area-chart" @touchstart="isSlideIcon = true">
      <p class="side-icon" :class="{ none: isSlideIcon }">
        <img
          src="@/components/page/dashboard/entry/assets/img/side-scroll.png"
          class="img"
        />
      </p>
      <div class="graph">
        <div class="bar-chart">
          <Bar
            v-if="
              barChartData.data.datasets[0].data.length > 0 &&
              barChartData.data.datasets[1].data.length > 0 &&
              barChartData.data.datasets[2].data.length > 0
            "
            :data="barChartData.data"
            :options="chartOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

import DashboardsService from '@/services/dashboards';

export default defineComponent({
  name: 'DashboardEntry',
  components: { Bar },
  setup(props, context) {
    const TERM_UNITS = [
      { id: 0, name: '1日' },
      { id: 1, name: '1週間' },
      { id: 2, name: '1ヶ月' },
      { id: 3, name: '1年間' },
    ];
    const store = useStore();
    const isSlideIcon = ref(false);
    const termStart = ref(moment().add(-3, 'month').format('YYYY-MM-DD'));
    const termEnd = ref(moment().format('YYYY-MM-DD'));
    const termUnit = ref(0);
    const barChartData = reactive({
      data: {
        labels: [],
        datasets: [
          {
            type: 'line',
            lineTension: 0,
            label: '応募者数',
            borderColor: '#ED6747',
            pointBackgroundColor: '#ED6747',
            pointBorderWidth: 3,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            data: [],
            datalabels: {
              formatter: () => '',
            },
          },
          {
            type: 'line',
            lineTension: 0,
            label: 'LINE友達数',
            borderColor: '#EDC747',
            pointBackgroundColor: '#EDC747',
            pointBorderWidth: 3,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            data: [],
            datalabels: {
              formatter: () => '',
            },
          },
          {
            type: 'bar',
            label: 'エントリー数',
            borderColor: '#33B3EF',
            backgroundColor: '#33B3EF',
            data: [],
            datalabels: {
              formatter: () => '',
            },
          },
        ],
      },
    });

    // watch
    watch(
      () => termUnit.value,
      async val => {
        await fetchAllData();
      },
    );
    watch(
      () => termStart.value,
      async (val, old) => {
        if (val === null) {
          notificationAlert();
          termStart.value = old;
          return;
        }
        if (!moment(termEnd.value).isAfter(val)) {
          notificationAlert();
          termStart.value = old;
          return;
        }
        await fetchAllData();
      },
    );
    watch(
      () => termEnd.value,
      async (val, old) => {
        if (val === null) {
          notificationAlert();
          termEnd.value = old;
          return;
        }
        if (!moment(val).isAfter(termStart.value)) {
          notificationAlert();
          termStart.value = old;
          return;
        }
        await fetchAllData();
      },
    );

    // methods
    const fetchAllData = async () => {
      // ** データ取得
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      const payload = {
        graduated: graduatedYear.year,
        kind: termUnit.value,
        start: termStart.value,
        end: termEnd.value,
      };
      // 登録・エントリー・エントリー数
      const res = { data: { nonActivated: [], activated: [], entry: [] } };
      const statusKeyList = Object.keys(res.data);
      const resAll = await Promise.all(
        statusKeyList.map((v, i) =>
          DashboardsService.fetchEntryTransitionEach({
            ...payload,
            status: i,
          }),
        ),
      );
      if (resAll.some(v => v.success === false)) return;
      resAll.forEach((v, i) => (res.data[statusKeyList[i]] = v.data.response));
      await setBarChartData(res.data);
    };
    const resetBarChartData = activated => {
      const tmpData = Object.assign({}, barChartData.data);
      tmpData.labels = activated.map(v => v.format_date || '');
      tmpData.datasets[0].data = activated.map(() => 0);
      tmpData.datasets[1].data = activated.map(() => 0);
      tmpData.datasets[2].data = [];
      barChartData.data = tmpData;
    };
    const setBarChartData = async ({ activated, entry, nonActivated }) => {
      // ** 棒グラフを下から伸ばしたいので、一度データをリセット
      resetBarChartData(activated);
      await setTimeout(async () => {
        const tmpDataActive = Object.assign({}, barChartData.data);
        if (activated) {
          tmpDataActive.datasets[0].data = activated.map(v => v.count || 0);
        }
        if (nonActivated) {
          tmpDataActive.datasets[1].data = nonActivated.map(v => v.count || 0);
        }
        barChartData.data = tmpDataActive;
        await setTimeout(() => {
          const tmpDataEntry = Object.assign({}, barChartData.data);
          if (entry) {
            tmpDataEntry.datasets[2].data = entry.map(v => v.count || 0);
          }
          barChartData.data = tmpDataEntry;
        }, 500);
      }, 400);
    };
    const notificationAlert = () => {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '指定期間が正しくありません',
        type: false,
      });
    };

    ChartJS.register(
      Tooltip,
      Legend,
      BarElement,
      LineElement,
      PointElement,
      CategoryScale,
      LinearScale,
      LineController,
    );

    // lifecycle
    onMounted(async () => await fetchAllData());

    return {
      TERM_UNITS,
      isSlideIcon,
      barChartData,
      termStart,
      termEnd,
      termUnit,
      chartOptions: {
        maintainAspectRatio: false,
        scales: {
          y: { ticks: { min: 0, unitStepSize: 20, beginAtZero: true } },
        },
        animation: { tension: { duration: 1500 } },
        plugins: { legend: { position: 'bottom', reverse: true } },
      },
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/datepicker.scss';

.content {
  padding: 40px 30px;
  background: #fff;
}
.graph-setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .viewTerm {
    padding-right: 20px;
    font-size: 1.4rem;
    font-weight: bold;
  }
  .termSetting {
    margin-right: 20px;
  }
  .viewSetting {
    font-size: 1.4rem;
  }
  .label {
    margin-right: 10px;
  }
  .data_form {
    width: 146px;
  }
  .termUnitSelected {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    &:after {
      position: absolute;
      top: 50%;
      right: 10px;
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 2px #178fd0;
      border-right: solid 2px #178fd0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg) translateY(-4px);
    }
    .unitSelect {
      width: 70px;
      height: 36px;
      padding-left: 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 3.6px;
      &:-ms-expand {
        display: none;
      }
    }
  }
}
.side-icon {
  display: none;
}

.bar-chart {
  height: 400px;
  margin: 30px 0 0 0;
}

@media (max-width: ($media_query_tablet)) {
  .content {
    padding: 40px;
  }
  .graph-setting {
    display: block;
    .viewTerm {
      margin-bottom: 20px;
    }
  }
  .area-chart {
    position: relative;
    overflow-x: scroll;
    .side-icon {
      display: block;
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      .img {
        width: 100px;
      }
    }
    .graph {
      min-width: 1000px;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px;
  }
  .graph-setting {
    .viewTerm {
      margin: 0 0 20px 0;
      font-size: 1.2rem;
    }
    .viewSetting {
      font-size: 1rem;
    }
    .termUnitSelected {
      margin-top: 10px;
      .label {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.2;
      }
    }
  }
  .el-input--prefix .el-input__inner {
    padding-left: 20px;
  }
  .el-input__icon {
    width: 14px;
  }
}

:deep(.el-input) {
  width: 13rem;
}
</style>
