<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link to="/announce">応募者への通知</router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">
          リッチメッセージを通知する
        </span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <div class="content">
        <div class="headline-typeC">リッチメッセージを通知する</div>
        <div class="header-block">
          <button-base
            class="btn"
            button-text="リッチメッセージの新規作成"
            icon-file-name="add_circle_white"
            @click="isVisibleModalRegisterRichMessage = true"
          />
        </div>
        <div class="scroll-table">
          <table class="table-typeB">
            <thead class="thead">
              <tr class="row">
                <th class="item-th">画像</th>
                <th class="item-th">タイトル</th>
                <th class="item-th">分割数</th>
                <th class="item-th">更新者</th>
                <th class="item-th">更新日</th>
                <th class="item-th">機能</th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr
                v-for="(list, iList) in richMessageList"
                :key="iList"
                class="row"
              >
                <td class="item-td" @click="openModalSendRichMessage(list)">
                  <img
                    class="rich-message-img"
                    :src="`${list.image_url}/240`"
                  />
                </td>
                <td class="item-td" @click="openModalSendRichMessage(list)">
                  {{ list.name }}
                </td>
                <td class="item-td" @click="openModalSendRichMessage(list)">
                  {{ list.image_type }}
                </td>
                <td class="item-td" @click="openModalSendRichMessage(list)">
                  {{ list.staff.lastname }}{{ list.staff.firstname }}
                </td>
                <td class="item-td" @click="openModalSendRichMessage(list)">
                  {{
                    $utils.parseDateTime(
                      list.updated_at,
                      'YYYY年MM月DD日 HH:mm',
                    )
                  }}
                </td>
                <td class="item-td">
                  <button-base
                    class="btn btn-availability"
                    button-text="編集"
                    icon-file-name="pen_white"
                    :is-loading="isLoading"
                    @click="openModalEditRichMessage(list)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <modal-register-rich-message
        ref="refModalRegisterRichMessage"
        :is-visible="isVisibleModalRegisterRichMessage"
        @onClickCancel="resetModalStates"
        @visibleNotificationAfterOnReloadRichMessageRegister="
          visibleNotificationAfterOnReloadRichMessageRegister
        "
      />
      <modal-edit-rich-message
        ref="refModalEditRichMessage"
        :is-visible="isVisibleModalEditRichMessage"
        :rich-message-obj="editRichMessageObj.data"
        @onClickCancel="resetModalStates"
        @onReload="onReload"
        @visibleNotificationAfterOnReloadRichMessageEdit="
          visibleNotificationAfterOnReloadRichMessageEdit
        "
      />
      <modal-send-rich-message
        :is-visible="isVisibleModalSendRichMessage"
        :rich-message-obj="sendRichMessageObj.data"
        @onClickCancel="resetModalStates"
        @onClickSubmitSendModal="openModalSelectApplicant"
      />
      <search-applicant-tabs
        v-if="isVisibleModalSearchApplicants"
        :is-visible="isVisibleModalSearchApplicants"
        :send-limit="sendLimit"
        :is-rich-message="true"
        :rich-message-id="sendRichMessageObj.data.id"
        @onClickCancel="onClickCancelSelectApplicant"
        @resetModalStates="resetModalStates"
      />
      <edit-disabled-modal
        :is-view="isViewEditDisabledModal"
        :actions="actions"
        :target-name="'リッチメッセージ'"
        :action-type="ACTION_TYPE_LABELS[ACTION_TYPES.richMessage]"
        :operation-name="'編集'"
        @close="isViewEditDisabledModal = false"
      />
    </section>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import RichMessageService from '@/services/richmessage';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import SearchApplicantTabs from '@/components/features/searchApplicantTab/components/SearchApplicantTabs';
import ModalRegisterRichMessage from '@/components/page/richmessage/components/ModalRegisterRichMessage';
import ModalEditRichMessage from '@/components/page/richmessage/components/ModalEditRichMessage';
import ModalSendRichMessage from '@/components/page/richmessage/components/ModalSendRichMessage';
import EditDisabledModal from '@/components/features/autoActions/modals/EditDisabledModal';
import autoActionsService from '@/services/autoActions.js';
import { ACTION_TYPES, ACTION_TYPE_LABELS } from '@/defines/autoActions';

export default defineComponent({
  name: 'RichMessage',
  components: {
    ModalRegisterRichMessage,
    ModalEditRichMessage,
    ModalSendRichMessage,
    SearchApplicantTabs,
    BreadCrumbList,
    EditDisabledModal,
  },
  setup(props, context) {
    const SEND_LIMIT = 1000;
    const store = useStore();
    const refModalRegisterRichMessage = ref(null);
    const refModalEditRichMessage = ref(null);
    const richMessageList = ref([]);
    const editRichMessageObj = reactive({ data: {} });
    const sendRichMessageObj = reactive({ data: {} });
    const isVisibleModalEditRichMessage = ref(false);
    const isVisibleModalSendRichMessage = ref(false);
    const isVisibleModalSearchApplicants = ref(false);
    const isVisibleModalRegisterRichMessage = ref(false);

    const isLoading = ref(false);
    const actions = ref([]);
    const isViewEditDisabledModal = ref(false);

    // methods
    const fetchAll = async () => {
      const res = await RichMessageService.fetchRichMessages();
      if (res.success === true) {
        richMessageList.value = res.richmessage_list.filter(
          v => v.disable === false,
        );
      }
    };
    const onReload = async () => {
      await fetchAll();
      resetModalStates();
    };
    const visibleNotificationAfterOnReloadRichMessageRegister =
      async payload => {
        await fetchAll();
        resetModalStates();
        store.dispatch('notification/VISIBLE_NOTIFICATION', payload);
        refModalRegisterRichMessage.value.resetSubmitButton();
      };
    const visibleNotificationAfterOnReloadRichMessageEdit = async payload => {
      await fetchAll();
      resetModalStates();
      store.dispatch('notification/VISIBLE_NOTIFICATION', payload);
      refModalEditRichMessage.value.resetSubmitButton();
    };
    const openModalEditRichMessage = async richMessageObj => {
      isLoading.value = true;
      await checkUsedAutoAction(richMessageObj.id);
      if (actions.value.length > 0) {
        isViewEditDisabledModal.value = true;
        isLoading.value = false;
        return;
      }
      isVisibleModalEditRichMessage.value = true;
      editRichMessageObj.data = richMessageObj;
      isLoading.value = false;
    };
    const openModalSendRichMessage = richMessageObj => {
      isVisibleModalSendRichMessage.value = true;
      sendRichMessageObj.data = richMessageObj;
    };
    const openModalSelectApplicant = () => {
      isVisibleModalSendRichMessage.value = false;
      isVisibleModalSearchApplicants.value = true;
    };
    const resetModalStates = () => {
      isVisibleModalRegisterRichMessage.value = false;
      isVisibleModalEditRichMessage.value = false;
      isVisibleModalSendRichMessage.value = false;
      isVisibleModalSearchApplicants.value = false;
    };
    const onClickCancelSelectApplicant = () => {
      resetModalStates();
      openModalSendRichMessage(sendRichMessageObj.data);
    };
    const checkUsedAutoAction = async richMessageId => {
      const { data } = await autoActionsService.inUseAutoActions({
        richmessage_id: richMessageId,
      });
      actions.value = data.actions;
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      refModalRegisterRichMessage,
      refModalEditRichMessage,
      richMessageList,
      editRichMessageObj,
      sendRichMessageObj,
      isVisibleModalEditRichMessage,
      isVisibleModalSendRichMessage,
      isVisibleModalRegisterRichMessage,
      isVisibleModalSearchApplicants,
      sendLimit: SEND_LIMIT,
      isViewEditDisabledModal,
      actions,
      isLoading,
      onReload,
      visibleNotificationAfterOnReloadRichMessageRegister,
      visibleNotificationAfterOnReloadRichMessageEdit,
      openModalEditRichMessage,
      openModalSendRichMessage,
      openModalSelectApplicant,
      resetModalStates,
      onClickCancelSelectApplicant,
      checkUsedAutoAction,
      ACTION_TYPES,
      ACTION_TYPE_LABELS,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.content {
  padding: 40px;
  background: #fff;
  .header-block {
    margin-bottom: 20px;
  }
}

.item-td {
  word-break: break-all;
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px;
  }
}

.scroll-table {
  overflow-x: auto;
}
@media (max-width: ($media_query_tablet)) {
  .scroll-table {
    white-space: nowrap;
  }
}
@media (max-width: ($media_query_sp)) {
  .scroll-table {
  }
}

.table-typeB {
  table-layout: fixed;
  .row {
    cursor: pointer;
  }
  .row:hover {
    background-color: #f1f1f1;
  }
  .item-td {
    .rich-message-img {
      width: 50px;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .table-typeB {
    table-layout: auto;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
