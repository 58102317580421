<template>
  <modal
    :is-view="isView"
    :flow-item="flowItem"
    :is-disabled-show-modal-displayed="isDisabledShowModalDisplayed"
    @onclickOuter="$emit('onclickOuter', $event)"
  >
    <template #rightTop>
      <div class="right-top" @click="$emit('onclickOuter', false)">
        <img class="icon" src="@/assets/img/close_white.svg" />
      </div>
    </template>

    <template #content>
      <div class="modal-window -typeA">
        <div class="header" :style="attentionHeader">
          <p class="title text-style">{{ title }}</p>
        </div>
        <div class="body">
          <div class="message">{{ message }}</div>
          <div v-if="checkboxLabel.length > 0" class="dialog-checkbox">
            <label>
              <input v-model="checkbox" type="checkbox" @click="toggleCheck" />
              {{ checkboxLabel }}
            </label>
          </div>
        </div>
        <div class="btn-col2">
          <action-button
            :text="buttonText1"
            :button-type="'unavailable'"
            @click="$emit('onSelected', { choice: 1 })"
          />
          <div v-if="buttonOptText1" class="txt-btn-option">
            {{ buttonOptText1 }}
          </div>
          <action-button
            v-if="alertFlag === false"
            :text="buttonText2"
            :loading="loading"
            @click="$emit('onSelected', { choice: 2 })"
          />
          <action-button
            v-if="alertFlag === true"
            :text="buttonText2"
            :button-type="'caution'"
            :loading="loading"
            @click="$emit('onSelected', { choice: 3 })"
          />
          <div v-if="buttonOptText2" class="txt-btn-option">
            {{ buttonOptText2 }}
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
// Vuexを使わないようにした後に削除対象
import { defineComponent, computed, ref, watch } from 'vue';

import Modal from '@/components/ui/modals/components/Modal';
import ActionButton from '@/components/ui/buttons/components/ActionButton';

export default defineComponent({
  name: 'ModalDialog',
  components: { Modal, ActionButton },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    isDisabledShowModalDisplayed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    buttonText1: {
      type: String,
      default: 'いいえ',
    },
    buttonOptText1: {
      type: String,
      default: null,
    },
    buttonText2: {
      type: String,
      default: 'はい',
    },
    buttonOptText2: {
      type: String,
      default: null,
    },
    alertFlag: {
      type: Boolean,
      default: false,
    },
    accentColor: {
      type: String,
      default: '#1899D6',
    },
    flowItem: {
      type: Boolean,
      default: false,
    },
    checkboxLabel: {
      type: String,
      default: '',
    },
  },
  emits: ['onclickOuter', 'onSelected', 'onclickCheckbox'],
  setup(props, context) {
    const attentionHeader = computed(() => ({
      color: props.accentColor,
      borderColor: props.accentColor,
    }));
    const checkbox = ref(false);
    watch(
      () => props.alertFlag,
      () => {
        checkbox.value = false;
      },
    );
    const toggleCheck = () => {
      checkbox.value = !checkbox.value;
      context.emit('onclickCheckbox', checkbox.value);
    };
    return { attentionHeader, checkbox, toggleCheck };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.message {
  white-space: break-spaces;
}

.text-style {
  white-space: pre-wrap;
  word-break: break-word;
}

.dialog-checkbox {
  margin-top: 25px;
  input[type='checkbox'] {
    cursor: pointer;
  }
}
</style>
