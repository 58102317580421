<template>
  <float-box :is-view="isView" @close="$emit('onClose')">
    <template #content>
      <div class="menu">
        <div class="context-menu">
          <ul>
            <li
              v-for="item in list.filter(item => !item.disabled)"
              :key="item.id"
              class="un-selectable"
              :class="{ selected: item.selected }"
              @click="selected(item)"
            >
              <div class="li-inner flexbox flexbox-middle">
                <p>{{ item.name }}</p>
                <div
                  v-if="isMultipleSelect && item.selected"
                  class="margin-left-auto"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </float-box>
</template>

<script>
import { defineComponent } from 'vue';

import FloatBox from '@/components/ui/menus/components/FloatBox';

// 表示する選考の種類選択ポップアップ
export default defineComponent({
  name: 'FloatContextMenuForSelection',
  components: { FloatBox },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    isMultipleSelect: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onSelected'],
  setup(props, context) {
    const selected = _item => {
      context.emit('onSelected', {
        item: _item,
        isMultipleSelect: props.isMultipleSelect,
      });
    };

    return { selected };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.context-menu {
  // position: absolute;
  background: white;
  min-width: 16rem;
  top: 0;
  left: 185px;
  z-index: 5;
  max-height: 25rem;
  overflow: auto;
  ul {
    border-radius: 0.2rem;
    li {
      border-bottom: dashed 1px #d9d9d94d;
      cursor: pointer;
      font-size: 1.2rem;
      padding: 0 1rem;
      &:last-child {
        border-bottom: none;
      }
      .li-inner {
        height: 3.6rem;
        p {
          white-space: nowrap;
          margin-right: 1.2rem;
        }
        div {
          display: none;
        }
      }
    }
    li.selected {
      color: $mochica_color;
      font-weight: bold;
      background: #fafafa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      &::after {
        content: '';
        display: inline-block;
        background: url(../../../../assets/img/check.svg) no-repeat;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
}
</style>
