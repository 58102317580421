<template>
  <div
    class="localpageloader flexbox flexbox-middle"
    :class="{ 'is-full_size': isFullSize }"
  >
    <div class="local_jumper">
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocalPageLoader',
  props: {
    isFullSize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {},
});
</script>

<style scoped>
.is-full_size {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.localpageloader {
  min-width: 30vw;
  width: 100%;
  min-height: 30vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.local_jumper {
  width: 50px;
  height: 50px;
}
.local_jumper > div {
  background-color: #88c6ed;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  -webkit-animation: local_jumper 1s 0s linear infinite;
  animation: local_jumper 1s 0s linear infinite;
}
.local_jumper > div:nth-child(2) {
  -webkit-animation-delay: 0.33333s;
  animation-delay: 0.33333s;
}
.local_jumper > div:nth-child(3) {
  -webkit-animation-delay: 0.66666s;
  animation-delay: 0.66666s;
}
@-webkit-keyframes local_jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes local_jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
