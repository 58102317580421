<template>
  <div class="row">
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１条（総則）</h5>
        <p>
          １．この「MOCHICA」利用規約（以下「本規約」といいます）は、株式会社ネオキャリア（以下「当社」といいます）が権利を有し、提供するシステム「MOCHICA」（以下、単に「MOCHICA」といいます）に関する条件を定めるものであり、全てのMOCHICAを利用する企業（以下「ユーザー企業」といいます）は本規約に従いMOCHICAを利用するものとします。
        </p>
        <br />
        <p>
          ２．ユーザー企業は、MOCHICAの利用を開始することで本規約に同意したものとし、これをもってユーザー企業と当社との間でMOCHICAに関する契約（以下「MOCHICA利用契約」といいます）が成立したものとみなします。なお、MOCHICA利用契約はMOCHICAが提供する機能毎に成立するものとします。
        </p>
        <br />
        <p>
          ３．当社は、本規約を事前の予告なく変更、追加又は削除することがあります。この場合において、当社は次に定めるURL(https://app.mochica.jp/policy?privacy_policy=1)で変更後の規約を掲示するものとし、ユーザー企業は、当該変更後にMOCHICAを利用した場合、変更後の本規約に同意したとみなされるものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第２条（MOCHICAの機能）</h5>
        <p class="p_2">
          １．MOCHICAが提供する機能は、ユーザー企業の人事・採用業務を支援するものであって、当社が別に定める機能とします。
        </p>
        <br />
        <p class="p_2">
          ２．ユーザー企業は、MOCHICAが提供する機能のうち、自らが利用する機能（オプション機能を含みます）を、当社所定の申込書（以下「申込書」といいます）によって特定するものとします。
        </p>
        <br />
        <p class="p_2">
          ３．当社が提供するMOCHICAには、フリープラン、ライトプランおよびスタンダードプランの３種類があります。なお、サービスの詳細および内容は変更となる可能性があります。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第３条（利用許諾）</h5>
        <p class="p_2">
          １．当社は、ユーザー企業に対して、本規約の条件に従い、MOCHICAを利用する譲渡不能の非独占的利用権を許諾するものとします。
        </p>
        <br />
        <p class="p_2">
          ２．ユーザー企業は、自らの役員、従業員、及び従業員に準ずる者（以下、総称して「ユーザー企業等」といいます）をして、MOCHICAを操作せしめることができるものとします。なお、従業員に準ずる者とは、ユーザー企業の事業所内に勤務し、ユーザー企業の事業の補助的な業務を行う者に限定するものとします。
        </p>
        <br />
        <p class="p_2">
          ３．ユーザー企業は、前項で許諾された利用権について、有償無償を問わず、第三者への譲渡・販売・貸与、及びこれに類似する行為を行ってはなりません。
        </p>
        <br />
        <p class="p_2">
          ４．ユーザー企業は、本サービスを構成するソフトウェアの複製・改変・解析、及び当該ソフトウェアのデータを改変するプログラムの開発を行ってはなりません。
        </p>
        <br />
        <p class="p_2">
          ５．ユーザー企業が当社の直接の競合者である場合には、当社は、当社が承諾する場合を除き、本サービスの利用を許諾しないものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第４条（ID・パスワード）</h5>
        <p class="p_2">
          １．当社は、ユーザー企業に対して、MOCHICAを操作するために必要なID及びパスワード（以下「ID等」といいます）を一式付与するものとします。なお、ID等はMOCHICAの機能毎に付与されるものとします。
        </p>
        <br />
        <p class="p_2">
          ２．当社がユーザー企業に付与するID等は、ユーザー企業の役員、従業員、その他ユーザー企業において業務に従事する者だけが取り扱うものとし、ID等を利用する者の氏名、所属、連絡先等当社が必要と定める事項を届け出ることとします。なお、届け出た内容を変更する場合も同様とします。
        </p>
        <br />
        <p class="p_2">
          ３．当社は、ユーザー企業に付与したID等を、ユーザー企業に対して事前に通知した上で変更することができるものとします。
        </p>
        <br />
        <p class="p_2">
          ４．ユーザー企業は、付与されたID等を厳正に管理する責任を負うものとします。また、ID等を第三者に譲渡、貸与し、又はこれを用いてMOCHICAにログインさせてはなりません。
        </p>
        <br />
        <p class="p_2">
          ５．当社は、ユーザー企業からのMOCHICAへの接続が、当社がユーザー企業に対して設定した正当なID等による操作・接続である限り、正当なユーザー企業の利用とみなします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第５条（個人情報の取扱い）</h5>
        <p class="p_2">
          １．当社は、ユーザー企業から提供を受けた個人情報（生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）をいい、ユーザー企業がMOCHICAに格納した情報を含みます。以下同様とします）を、当社のWebサイトに掲示するプライバシーポリシーに従い適切に取り扱うものとします。
        </p>
        <br />
        <p class="p_2">
          ２．ユーザー企業及び当社は、MOCHICAを利用する際に取り扱う個人情報については、個人情報管理に関する責任者を特定し、個人情報の漏えい・滅失・改ざん・棄損等の事故を未然に防止するため必要な措置を講じるものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第６条（情報の利用）</h5>
        <p class="p_2">
          １．当社は、ユーザー企業がMOCHICAを通じて格納した個人情報及びユーザー企業の企業情報のうち、個人又はユーザー企業を特定できない部分を統計情報として利用できるものとし、ユーザー企業はこれに同意します。
        </p>
        <br />
        <p class="p_2">
          ２．ユーザー企業は、MOCHICAに個人情報を格納するに際して、予め当該個人情報の保有当事者に前項の統計情報の利用方法について提示し、同意を得るものとします。
        </p>
        <br />
        <p class="p_2">
          ３．当社が本条第１項に定める範囲で統計情報を利用しているにもかかわらず、ユーザー企業又は当社が個人情報の保有当事者からクレーム等を受けた場合、ユーザー企業は、自らの費用と責任でこれを解決するものとし、当社に迷惑をかけないものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第７条（サービス・利用料）</h5>
        <p class="p_2">
          １．ユーザー企業は、別途当社に対して提出する申込書、料金表に従い、MOCHICAに関する対価（以下「利用料」といいます）を当社に支払うものとします。
        </p>
        <br />
        <p class="p_2">
          ２．当社は、本サービスの開始日の月末に全ての商材に関して一括で請求を行い、ユーザー企業は翌月末までに、当社が指定する方法に従いこれを支払うものとします。
          <br />
          ただし、ユーザー企業と当社との間で別途支払い方法について協議のうえ決定された場合には、合意された支払方法に従うものとします。なお、料金の振込手数料はユーザー企業の負担とします。
        </p>
        <br />
        <p class="p_2">
          ３．前項にかかわらず、当社は、当社に故意又は重過失ある場合を除き、一度受領した対価を返金しないものとします。
        </p>
        <br />
        <p class="p_2">
          ４．ユーザー企業が、料金表で定めるオプションを利用した場合、当該オプションの対価については、申込書に従い請求・支払いが行われるものとします。
        </p>
        <br />
        <p class="p_2">
          ５．MOCHICAは、申込書記載のご利用プランで示されるご利用者数に関して下記のように定めるものとします。
          <br />
          （１）本申込書に記載されたご利用者数は、ユーザー企業によるご登録可能人数を意味し（以下、「従業員」といい、そのアカウントを「従業員アカウント」といいます）その数を超える従業員アカウントを作成し、MOCHICAにアクセスすることはできないものとします。
          <br />
          （２）従業員のパスワードは、その他の個人と共有することはできないものとします。
          <br />
        </p>
        <br />
        <p class="p_2">
          ６．本条にて規定された料金の支払いがなされなかった場合、当社はユーザー企業が利用できる機能の全部又は一部、及びサービスに利用制限を設けることができるものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第８条（禁止事項）</h5>
        <p class="p_2">
          ユーザー企業は、MOCHICAに関して下記の行為を行ってはならないものとします。
        </p>
        <br />
        <p class="p_2">
          １．ユーザー企業自身の評価以外の目的で本サービスを利用すること
        </p>
        <br />
        <p class="p_2">
          ２．本サービスを構成するプログラム等の複製および解析を行うこと
        </p>
        <br />
        <p class="p_2">
          ３．本サービスを受ける権利を譲渡若しくは貸与（有償・無償を問いません）すること
        </p>
        <br />
        <p class="p_2">４．本サービスを第三者に再利用させること</p>
        <br />
        <p class="p_2">
          ５．本サービスの利用により入手した情報を第三者に漏洩すること
        </p>
        <br />
        <p class="p_2">
          ６．本サービスと同等或いは類似するサービスを第三者に提供すること
        </p>
        <br />
        <p class="p_2">
          ７．本サービスの可用性、性能、機能、インターフェイスの測定、比較、調査、その他これらに類する目的で本サービスを利用すること
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第９条（MOCHICA初期設定サービス）</h5>
        <p class="p_2">
          １．MOCHICAアカウント初期設定サービスに申し込んだユーザー企業（以下「初期設定ユーザー企業」といいます）には、本規約の他の規定に加え、本条の規定が適用されるものとします。
        </p>
        <br />
        <p class="p_2">
          ２．初期設定ユーザー企業は、当社に対し、当社がMOCHICAアカウント初期設定サービスを提供するうえで必要となる初期設定ユーザー企業のアカウント（MOCHICAアカウント、LINEアカウントを含みますが、これに限りません）へアクセスすることにつき、無制限に許諾するものとします。
          <br />
        </p>
        <br />
        <p class="p_2">
          ３．初期設定ユーザー企業は、当社に対し、MOCHICAアカウント初期設定サービスの提供に必要な素材を提供する義務を負います。初期設定ユーザー企業は、当該素材の提供に関し、第三者の知的財産権その他のいかなる権利も侵害しておらず、また、侵害するおそれもないことを、当社に対して表明し保証するものとします。なお、初期設定ユーザー企業が提供した素材に関連して第三者から当社に訴えの提起、申立て、その他の請求がなされた場合、初期設定ユーザーは、自己の費用と責任において対応するものとし、当社に迷惑をかけないものとします。
        </p>
        <br />
        <p class="p_2">
          ４．初期設定ユーザー企業は、当社からMOCHICAアカウント初期設定サービスに係る成果物の引渡しを受けた場合、３営業日以内に、当社に対し、成果物の合格又は不合格の通知を行うものとします。ただし、初期設定ユーザー企業は、合理的な根拠を示さずに不合格の通知をすることができないものとします。なお、３営業日以内に本項の通知がなされない場合、当該成果物は初期設定ユーザーの検査に合格したものとみなします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１０条（MOCHICAの停止・中断）</h5>
        <p class="p_2">
          １．当社は、毎月第2月曜日もしくは第3月曜日の22:00～翌06:00までMOCHICAのシステム保守として定期メンテナンスを行うためにMOCHICAを停止・中断することがあります。その他、別途当社の定める日程において緊急メンテナンスを行う事もございますが、この場合におけるメンテナンスの日時及び期間は、予めMOCHICA上で通知するものとします。
        </p>
        <br />
        <p class="p_2">
          ２．当社は、以下の各号の場合において、ユーザー企業に事前通知することなく、MOCHICAの全部又は一部の機能を停止・中断することがあります。
          <br />
          （１）緊急にMOCHICAの保守・整備・改修、及び機器交換等が必要となった場合
          <br />
          （２）天災地変・騒乱・戦争・ストライキ等、不測の事態が発生した場合
          <br />
          （３）電力会社の電力供給の中断、又は電気通信事業者が提供するインターネット等の通信障害、及び連携する外部システム（電子政府の総合窓口「e-Gov」を含むが、これに限らない）の不具合等が発生した場合
          <br />
          （４）その他、当社が停止又は中断を必要と判断した場合
        </p>
        <br />
        <p class="p_2">
          ３．前２項の事由によりMOCHICAの利用ができない日時があったとしても、ユーザー企業は当社に対して、利用料の減額を求めることはできません。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１１条（損害賠償）</h5>
        <p class="p_2">
          １．MOCHICAの障害及び停止・中断等によるユーザー企業の損害について、当社の故意又は重過失により生じた損害である場合を除き、当社は一切の責任を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          ２．ユーザー企業が、本規約又はMOCHICAに関連する他社の規約・ルール等に規定されていることに違反した結果、ユーザー企業に損害が生じた場合については、当社は一切の責任を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          ３．ユーザー企業又は当社は、本規約に規定されていることに違反し、相手方又は第三者に損害を与えた場合、その損害を補償しなければならないものとします。仮に、当社が何らかの理由により責任を負う場合であっても、当社の賠償額は、直近１年間に当該ユーザー企業から当社が受領した料金の総額を上限とします。
        </p>
        <br />
        <p class="p_2">
          ４．前条第１項又は第２項によりMOCHICAが利用できず、それによってユーザー企業に損害が発生した場合であっても、当社は一切の責任を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          ５．ユーザー企業は、自己の責任で必要なデータのバックアップを確保するものとし、MOCHICA上に格納したユーザー企業のデータの喪失・破損について、当社は、故意又は重過失の場合を除き、責任を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          ６．当社は、MOCHICAがユーザー企業の特定の目的に適合すること、期待する機能、商品的価値、正確性、有用性を有すること、ユーザー企業による「MOCHICA」の利用がユーザー企業に適用ある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。
        </p>
        <br />
        <p class="p_2">
          ７．MOCHICAに関連してユーザー企業と第三者の間において生じた取引、連絡、紛争等について、当社は一切の責任を負いません。
        </p>
        <br />
        <p class="p_2">
          ８．ユーザー企業がMOCHICAに関連して外部サービスを利用する場合（LINE等のSNSサービスを含み、これに限定されるものではない）、当該外部サービスに起因して、利用者を含む第三者との間で紛争等が生じたときは、ユーザー企業の責任と費用において対応するものとし、当社は一切の責任を負いません。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１２条（有効期間）</h5>
        <p class="p_2">
          １．MOCHICA利用契約の有効期間は、利用する機能毎に設定されるものとし、それぞれの有効期間は、フリープランは半年、ライトプランおよびスタンダードプランは1年とします。ただし、フリープランの有効期間中にライトプランまたはスタンダードプランに切替えた場合、切替えた時点からライトプランまたはスタンダードプランの有効期間が開始されるものとします。その他、イレギュラーな有効期間については、申込み後、当社からユーザー企業に送信する納品メール記載の期間とします。
        </p>
        <br />
        <p class="p_2">
          ２．ユーザー企業は、MOCHICA利用契約の契約内容の変更、または契約を更新せず終了させる場合には、有効期間満了日の１ヶ月前までに、別途当社が定める方法によりその旨を申し出ることとします。申し出のない場合には、同一条件にて自動的に有効期間が1年間延長され続けるものとします。なお、MOCHICA利用契約の終了日は月末日とし、ユーザー企業が希望した終了日が月の末日以外の日である場合であっても解約月の対価は返金されないものとします。
        </p>
        <br />
        <p class="p_2">
          ３．有効期間内にユーザー企業がMOCHICA利用契約を解約しようとする場合には、残余の期間に対応する利用料を支払う義務を負うものとします。なお、当社が既に残余の期間に対応する利用料を受領している場合において、当社は、理由の如何を問わず、一切の返金義務を負わないものとします。
        </p>
        <br />
        <p class="p_2">
          ４．ユーザー企業は、MOCHICAにおいて使用するプランを、申込書に記載して選択するものとします。
        </p>
        <br />
        <p class="p_2">
          ５．MOCHICA利用契約の有効期間の始期は、全ての機能及び全てのプランにおいて毎月１日とし、ID等の発行日が発行月の１日以外の日に行われた場合には、当該月の末日までの利用期間はMOCHICA利用契約の有効期間に参入せず、その場合の有効期間は翌月の１日から起算されるものとします。ただし、利用開始日からMOCHICA利用契約の有効期間の始期までの間におけるMOCHICAの利用に関しては、本規約が適用されるものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１３条（利用契約終了時の措置）</h5>
        <p class="p_2">
          １．MOCHICA利用契約が終了した場合、当社は、ユーザー企業に付与していたID等を無効にし、サーバに残存するユーザー企業のうち、個人又はユーザーを特定できるデータを消去します。
        </p>
        <br />
        <p class="p_2">
          ２．ユーザー企業は、自己の責任で必要なデータのバックアップを確保するものとし、当社は、前項によるユーザー企業のデータを削除したことによるデータ消失の責任は負わないものとします。
        </p>
        <br />
        <p class="p_2">
          ３．ユーザー企業がMOCHICAの利用に伴い取得したMOCHICAに関するマニュアル及びそれらの複製物については、MOCHICA利用契約の終了後、直ちに当社に返却するか、当社の承認の下に破棄するものとします。
        </p>
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１４条（データの保存期間）</h5>
        <p class="p_2">
          １．MOCHICAを利用する場合、MOCHICAに入力した各利用者のデータは、入力日から３年間、当社のサーバに保持されるものとします。
        </p>
        <br />
        <p class="p_2">
          ２．前項のデータ保持期間は、入力日から３年経過した時点でMOCHICAを継続使用している場合であっても適用されるものとします。
        </p>
        <br />
        <p class="p_2">
          ３．前２項にかかわらず、ユーザー企業がMOCHICAの利用を終了した場合には、３年間の経過を待たず、入力されたデータは、当該利用の終了日から以下の各号の区分に従った期間の経過後、サーバから消去されるものとします。
          <br />
          （１）利用終了時に適用されたプランがフリープランの場合：6ヶ月後
          <br />
          （２）利用終了時に適用されたプランがライトプラン又はスタンダードプランの場合：1年後
          <br />
        </p>
        <br />
        <p class="p_2">
          ４．データの保存期間にかかわらず、当社は第６条に従い個人が特定できない状態とした情報の利用を継続実施できるものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１５条（解除）</h5>
        <p class="p_2">
          １．当社は、ユーザー企業が本規約の条項の一にでも違反した場合には相当期間を定めて違反の是正を催告し、当該期間中にかかる違反が是正されない場合には、MOCHICA利用契約を解除することができるものとします。
        </p>
        <br />
        <p class="p_2">
          ２．当社は、ユーザー企業が次の各号の一にでも該当する場合には、何等催告なくしてMOCHICA利用契約を直ちに解除することができるものとします。
          <br />
          （１）自ら振り出し又は引き受けた手形若しくは小切手が不渡りとなったとき、又は支払停止状態に至ったとき
          <br />
          （２）公租公課の滞納処分を受けたとき
          <br />
          （３）差押、仮差押、仮処分、競売その他公権力の処分を受け若しくは破産、民事再生手続開始、会社更生手続開始の申立のあったとき、又は清算に入る等事実上営業を停止したとき
          <br />
          （４）上記各号の他、財産状態・会社経営の状態が悪化又はそのおそれがあると客観的・明白に認められる事情が発生したとき
          <br />
          （５）監督官庁から営業停止、許可取消等の指示を受けたとき
          <br />
          （６）合併によらない解散の決議をしたとき
          <br />
          （７）その他、社会的信用を喪失し、又は喪失することが明らかなとき
        </p>
        <br />
        <p class="p_2">
          ３．本条による解除は、ユーザー企業に対する損害賠償請求（弁護士費用を含む）を妨げません。
        </p>
        <br />
        <p class="p_2">
          ４．ユーザー企業は、第１項又は第２項各号のいずれかの事由が生じた場合、当然に期限の利益を喪失し、直ちに当社に対する残債務全額を一括して支払わなければならない。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１６条（反社会的勢力の排除）</h5>
        <p class="p_2">
          １．ユーザー企業及び当社は、現在又は過去５年以内において、自己又は自己の役員が、暴力団、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下、「反社会的勢力」）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、且つ将来にわたっても該当しないことを確約します。
          <br />
          （１）反社会的勢力が経営を支配していると認められる関係を有すること
          <br />
          （２）反社会的勢力が経営に実質的に関与していると認められる関係を有すること
          <br />
          （３）自己若しくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもって反社会的勢力を利用していると認められる関係を有すること
          <br />
          （４）反社会的勢力に対して資金等を提供し、または便宜を供与するなどの反社会的勢力の維持、運営に協力し、または関与をしていると認められる関係を有すること
          <br />
          （５）自己の役員が反社会的勢力と社会的に非難されるべき関係を有すること
          <br />
        </p>
        <br />
        <p class="p_2">
          ２．甲および乙は、自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを保証する。
          <br />
          （１）暴力的な要求行為
          <br />
          （２）法的な責任を超えた不当な要求行為
          <br />
          （３）取引に関して、脅迫的な言動をし、または暴力を用いる行為
          <br />
          （４）風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為
          <br />
          （５）その他前各号に準ずる行為
          <br />
        </p>
        <br />
        <p class="p_2">
          ３．当社は、ユーザー企業が前２項に違反した場合には、何等催告なくしてMOCHICA利用契約を直ちに解除することができるものとします。なお、この場合において、当社は、何らの損害賠償、補償その他の支払義務も負わないものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１７条（契約上の地位の譲渡等）</h5>
        <p class="p_2">
          １．ユーザー企業は、当社の書面による承諾なく、MOCHICA利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
        </p>
        <br />
        <p class="p_2">
          ２．当社は、MOCHICAに係る事業を他社に譲渡した場合には、当該事業譲渡に伴いMOCHICA利用契約上の地位、本規約に基づく権利及び義務並びにユーザー企業の登録事項その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザー企業は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１８条（準拠法及び管轄裁判所）</h5>
        <p class="p_2">
          １．本規約又はMOCHICAの利用については、日本国法に準拠するものとします。
        </p>
        <br />
        <p class="p_2">
          ２．本規約又はMOCHICAの利用について紛争が生じた場合、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">第１９条（協議）</h5>
        <p class="p_2">
          本規約に関して疑義あるいは、本規約に取り決めのない事項が生じた場合、信義誠実の原則に従って協議し、解決を図ることとします。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">プライバシーポリシー</h5>
        <p class="p_2">
          株式会社ネオキャリアは一般財団法人日本情報経済社会推進協会（JIPDEC）より個人情報保護に取り組む企業を示す「プライバシーマーク」を取得しています。
        </p>
        <br />
        <p class="p_2">
          当社は、転職・就職支援、人材派遣、及びそれらに関するコンサルティング、コンテンツ制作・ソフトウェア開発企業としての責務を認識し、誠実にお客様のニーズに応え、広く社会に貢献したいという基本理念の下、利用者様からお預かりする個人情報保護の重要性を全従業者が認識し、日本工業規格「個人情報保護に関する個人情報保護マネジメントシステムの要求事項」（JISQ15001）に準拠した以下の取組みを実施し、維持します。
        </p>
        <br />
        <p class="p_2">
          1.
          求職者（利用者）、お取引先企業様、当社従業者の個人情報について、「個人情報保護管理責任者」を設置し、適切な保護を行います。
        </p>
        <br />
        <p class="p_2">
          2.
          自社採用、当社の事業である転職支援、就職支援、人材派遣、関連する各種コンテンツ・ソフトウェアの企画・制作・維持管理、就職情報誌の発行、就職イベントの企画・運営、採用コンサルティング上必要な範囲に限定し、適切な手段で、個人情報を取得、利用、提供いたします。個人情報の取得時には、利用目的、
          及び、問い合せに対する窓口を明確にします。
        </p>
        <br />
        <p class="p_2">
          3.
          個人情報は、原則として法律に基づく命令等を除いて、特定された利用目的の達成に必要な範囲を超えた個人情報の取り扱いは行いません。また、目的外の利用を行わないよう、適切かつ必要な措置を講じます。
        </p>
        <br />
        <p class="p_2">
          4.
          個人情報への不正アクセス、個人情報の紛失、破壊、改竄、滅失、毀損及び漏洩などのリスクに対しては、合理的な安全対策を講じ、予防並びに是正を行います。
        </p>
        <br />
        <p class="p_2">
          5.
          個人情報を取り扱う業務をほかの会社に委託する場合、個人情報を取得する時の承諾に基づく利用、提供、安全管理を守るように、委託先に対する適切な契約や指導・管理をします。
        </p>
        <br />
        <p class="p_2">
          6.
          当社が保有する個人情報に関する苦情及び相談の適切な対応のみならず、開示、訂正、提供範囲の変更や削除を本人から依頼された場合には、適切な本人確認をさせていただいた上で、合理的な範囲ですみやかに対応します。
        </p>
        <br />
        <p class="p_2">
          7.
          当社が取り扱う個人情報に関して適用される法令、国が定める指針その他規範を遵守するとともに、適切な運用が実施されるよう管理と必要な是正を行い、個人情報保護のマネジメントシステムを継続的に見直し、改善していきます。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">個人情報とは</h5>
        <p class="p_2">
          個人情報とはお預かりした、氏名、住所、電話番号、電子メールアドレス、社歴&nbsp;等をいい、その他の記述などによって特定の個人を識別できるものをいいます。
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">個人情報の利用目的</h5>
        <p class="p_2">
          利用者（求職者、派遣登録希望者、各種Webサイト利用者等）より取得する個人情報
          <br />
          ・お問合せに対し、回答するため
          <br />
          ・当社サービス（就職・転職支援、派遣、各種Webサイト）を提供するため
          <br />
          ・当社サービス（就職・転職支援、派遣、各種Webサイト）情報を案内するため
          <br />
          ・その他、付随する業務につき本人と連絡を取るため
          <br />
          <br />
          当社のお取引先企業様より取得する個人情報
          <br />
          ・当社の採用アウトソーシングサービスのご利用につき、お取引先企業様（委託元）の応募者の採用業務を代行するため
          <br />
          ・当社が提供するコンテンツ・ソフトウェアサービスの保守・維持管理につき、お取引先企業様（委託元）のユーザー情報の一部を預かるため
          <br />
          ・連絡、協力、交渉、契約の履行、履行請求などを行うため
          <br />
          ・商法にもとづく権利の行使、義務の履行のため
          <br />
          <br />
          当社の採用に応募いただく方の個人情報
          <br />
          ・採用検討、面接、業務連絡などを行うため
          <br />
          <br />
          当社従業者の個人情報・人事・給与、福利厚生、業務連絡などを行うため
          <br />
          <br />
          その他、当社へのお問合せにより取得する個人情報
          <br />
          ・お問合せに対応するため
          <br />
          <br />
          取得する個人情報の種類
          <br />
          利用者（求職者、派遣登録希望者、各種Webサイト利用者等）より取得する個人情報
          <br />
          ・氏名、性別、生年月日、電話番号、メールアドレス、住所、職歴等
          <br />
          <br />
          当社のお取引先企業様より取得する個人情報
          <br />
          ・お取引先企業様（委託元）の採用における応募者の個人情報（氏名、性別、生年月日、電話番号、メールアドレス、住所、職歴等）
          <br />
          ・氏名、電話番号、メールアドレス等
          <br />
          ・お取引先企業様（委託元）より当社提供コンテンツ・ソフトウェアを通して預かるユーザー情報（氏名、性別、生年月日、電話番号、メールアドレス、住所、職歴等）
          <br />
          ・氏名、電話番号、メールアドレス等
          <br />
          <br />
          当社の採用に応募いただく方の個人情報
          <br />
          ・氏名、性別、生年月日、電話番号、メールアドレス、住所、学歴・職歴等
          <br />
          <br />
          当社従業者の個人情報
          <br />
          ・氏名、性別、生年月日、電話番号、メールアドレス、住所、学歴・職歴等
          <br />
          <br />
          その他、当社へのお問合せにより取得する個人情報
          <br />
          ・氏名、電話番号、メールアドレス、住所等
          <br />
          <br />
          個人情報の第三者提供について
          <br />
          本人の同意がある場合または法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。
          <br />
          個人情報の委託
          <br />
          取得した個人情報は、上記「個人情報の利用目的」の範囲内で委託することがあります。
          <br />
          いずれの委託先も、当社と秘密保持契約を締結しています。
          <br />
          個人情報の共同利用
          <br />
          取得した個人情報は、以下内容に基づき共同利用することがあります。
          <br />
          共同利用する個人情報の項目
          <br />
          上記「取得する個人情報の種類」に同じ
          <br />
          共同して利用する者の範囲
          ネオキャリアグループ各社：（http://www.neo-career.co.jp/profile/group.html）
          <br />
          <br />
          共同利用の目的
          <br />
          ①求職者（利用者）より取得する個人情報
          <br />
          ａ．当社サービス（利用者向け）が、ネオキャリアグループ全体の連携した提供であるため
          <br />
          ｂ．取得した個人情報を管理するシステムが、ネオキャリアグループでの管理であるため
          <br />
          ②当社のお取引先企業様より取得する個人情報
          <br />
          ａ．当社サービス（法人向け）が、ネオキャリアグループ全体の連携した提供であるため
          <br />
          ｂ．取得した個人情報を管理するシステムが、ネオキャリアグループでの管理であるため
          <br />
          ③当社の採用に応募いただく方の個人情報
          <br />
          ａ．ネオキャリアグループ採用とし、内定後に所属グループ会社を決めるため
          <br />
          ｂ．取得した個人情報を管理するシステムが、ネオキャリアグループでの管理であるため
          <br />
          ④当社従業者の個人情報
          <br />
          ａ．従業者の人事労務をネオキャリアグループにて管理するため
          <br />
          ｂ．他グループ会社（従業者）との業務連携が必須であるため
          <br />
          ⑤その他、当社へのお問合せにより取得する個人情報
          <br />
          ａ．当社サービスが、ネオキャリアグループ全体の連携した提供であるため
          <br />
          ｂ．取得した個人情報を管理するシステムが、ネオキャリアグループでの管理であるため
        </p>
        <br />
      </div>
    </div>
    <div class="col-md-12 col-xs-12 privacy_list">
      <div class="p_1">
        <h5 class="article_ttl">共同利用における管理責任者</h5>
        <p class="p_2">
          株式会社ネオキャリア
          <br />
          <br />
          取得方法
          <br />
          書面または電子データ
          <br />
          <br />
          開示対象個人情報の開示等及び問合わせ窓口について
          <br />
          本人からの求めにより、次の窓口で当社が保有する開示対象個人情報の利用目的の通知・開示・内容の訂正・追加又は削除・利用停止・消去等に応じます。
          <br />
          【個人情報に関する苦情・相談窓口】
          <br />
          TEL（共通）：0120-550-150
          <br />
          (土日祝・弊社休業日を除く平日午前9時～午後8時)
          <br />
          FAX：03-5908-8046
          <br />
          Mail：neo-customer@neo-career.co.jp
          <br />
          <br />
          <br />
          任意性
          <br />
          個人情報を提供されることは随意ですが、十分な個人情報が提供されない場合、当社サービスの提供やお問合せの対応に支障が生ずることがあります。
          <br />
          <br />
          本人が容易に識別できない方法による個人情報の取得
          <br />
          当社が運営する一部のWebサイトにおいて、ユーザーのプライバシーの保護、利便性の向上、広告の配信および統計データの取得のため、Cookie（クッキー）を使用しております。
          <br />
          また、CookieやJavaScript等の技術を利用して取得した情報のうち、年齢や性別、職業、居住地域など個人が特定できない属性情報（組み合わせることによっても個人が特定できないものに限られます）や端末情報、サービスサイト内におけるユーザーの行動履歴（アクセスしたURL、コンテンツ、参照順等）、及びスマートフォン等利用時のユーザーの承諾・申込みに基づく位置情報を取得することがあります。
          Cookie及び行動履歴 等には個人情報は一切含まれません。
          <br />
          Cookieを無効化させる設定をされていると、一部サービスをご利用いただけない場合がございますので、あらかじめご了承ください。
          <br />
          <br />
          個人情報の安全管理措置について
          <br />
          取得した個人情報については、漏洩、滅失または棄損の防止と是正、その他個人情報の安全管理のため必要且つ適切な措置を講じます。
        </p>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrivacyPolicy',
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.privacypolicy h4 {
  font-size: 1.6rem;
  color: #1b93d8;
  margin-bottom: 50px;
  .row {
    height: 100%;
  }
}
.article_ttl {
  font-size: 1.6rem;
  color: #202020;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media (max-width: ($media_query_tablet)) {
}

@media (max-width: ($media_query_sp)) {
}
</style>
