<template>
  <modal :is-view="isView">
    <template #rightTop>
      <div class="right-top" @click="onClickCancel">
        <img class="icon" src="@/assets/img/close_white.svg" alt />
      </div>
    </template>
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">出席確認パスワードの一括割当て</p>
        </div>

        <transition name="fade">
          <div class="modal-window-inner">
            <div class="message_area">
              <p>
                開催時間ごとの出席確認パスワードを一括で割当てます。
                <br />
                一括割当てを行うと、パスワードが未登録の開催時間だけにパスワードが割当てられます。
              </p>
            </div>
            <dl class="select_staff_area">
              <dt>
                <b>パスワード</b>
                （英数字のみ・最大8文字まで）
              </dt>
              <dd @click.stop>
                <input
                  v-model="password"
                  type="text"
                  placeholder="パスワード"
                  maxlength="8"
                  class="input-password"
                />
              </dd>
            </dl>
            <div class="btn-full_width">
              <div class="btn btn-unavailable" @click="onClickCancel">
                キャンセル
              </div>
              <button-base
                class="btn"
                button-text="パスワードを割当て"
                :rejected-role-ids="[3]"
                :rejected-admin-ids="[2]"
                @click="onClickToConfirm"
              />
            </div>
          </div>
        </transition>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import Modal from '@/components/ui/modals/components/Modal';

export default defineComponent({
  name: 'ModalBulkAssignPassword',
  components: { Modal },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClickCancel', 'setPassword'],
  setup(props, context) {
    const store = useStore();
    const password = ref('');

    // methods
    const onClickCancel = () => {
      context.emit('onClickCancel');
    };
    const onClickToConfirm = async () => {
      if (password.value.length > 8) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'パスワードが8文字を超えています',
          type: false,
        });
        return;
      }
      context.emit('setPassword', password.value);
      password.value = '';
    };

    return { password, onClickCancel, onClickToConfirm };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  max-width: 640px;
}

.message_area {
  margin: 0;
  padding-bottom: 25px;
  margin: 30px 40px 25px;
  font-size: 16px;
  line-height: 175%;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 10%);
  > p {
    margin-bottom: 6px;
  }
}

.select_staff_area {
  padding: 0 40px;
  margin-bottom: 27px;
  text-align: left;
  > dt {
    margin-bottom: 10px;
    font-size: 14px;
    color: #777;
    > b {
      color: #333;
    }
  }
}

.input-password {
  width: 100%;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #9d9d9d;
  background: white;
  &:disabled {
    color: #d6d6d6;
    border-color: #e6e6e6;
    &::placeholder {
      color: #d6d6d6;
    }
    &::-ms-input-placeholder {
      color: #d6d6d6;
    }
  }
}

.btn-full_width {
  display: flex;
  width: 100%;
  padding: 0 40px 50px;
  > button {
    margin-left: 10px;
  }
  > div,
  button {
    width: 275px;
    height: 50px;
    margin-bottom: 10px;
    padding: 18px 10px;
    line-height: 1;
  }
}

@media (max-width: ($media_query_sp)) {
  .message_area {
    padding-bottom: 10px;
    margin: 20px 15px 20px;
  }
  .select_staff_area {
    padding: 0 15px;
  }
  .btn-full_width {
    padding: 0 15px 10px;
  }
}
</style>
