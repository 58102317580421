<template>
  <div class="applicant_list">
    <div class="applicant_warp">
      <button
        v-if="isViewAddButton"
        class="btn btn-availability -mb20"
        @click="$emit('openApplicantsSelectModal')"
      >
        応募者の追加・変更をする
      </button>
      <div v-if="hasErrorLineFriend" class="line_friend_warning">
        <img src="@/assets/img/icon_warning.svg" />
        <p>
          LINE友達の場合はメールアドレスが登録されていないため、メールを送信できません
        </p>
        <button class="btn btn-caution" @click="$emit('unselectLineFriend')">
          LINE友達の応募者を一括解除
        </button>
      </div>
      <div class="notice_table">
        <table class="common_table" :class="{ 'is-notice_view': isNoticeView }">
          <thead class="common_thead">
            <tr class="common_thead_row align-left">
              <th v-if="isNoticeView" class="common_thead_item">
                <input
                  ref="selectAll"
                  type="checkbox"
                  name="selecthead"
                  value="1"
                  @change="selectAllChange"
                />
              </th>
              <th class="common_thead_item">
                <span>名前</span>
              </th>
              <th class="common_thead_item">
                <span>セイメイ</span>
              </th>
              <th class="common_thead_item">
                <span>性別</span>
              </th>
              <th class="common_thead_item">
                <span>学校名</span>
              </th>
              <th class="common_thead_item">
                <span>学部</span>
              </th>
              <th class="common_thead_item">
                <span>学科</span>
              </th>
              <th class="common_thead_item">
                <span>文理</span>
              </th>
              <th class="common_thead_item">
                <span>電話番号</span>
              </th>
              <th class="common_thead_item">
                <span>連絡方法</span>
              </th>
              <th class="common_thead_item">
                <span>送信ステータス</span>
              </th>
              <th class="common_thead_item">
                <span>送信日</span>
              </th>
            </tr>
          </thead>
          <tbody v-if="applicantList.length > 0" class="common_tbody">
            <tr
              v-for="item in applicantList"
              :key="item.id"
              class="common_tbody_row align-left"
            >
              <td v-if="isNoticeView" class="common_tbody_item">
                <span>
                  <input
                    :ref="'select_' + item.id"
                    type="checkbox"
                    name="select"
                    value="1"
                    :checked="selectedApplicantIds.includes(item.id)"
                    @change="onSelectApplicant(item.id)"
                  />
                </span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.lastname }} {{ item.firstname }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.lastnameKana }}{{ item.firstnameKana }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.gender.description }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.school }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.faculty }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.department }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.departmentCategory.description }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.tel }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span>{{ item.contactType }}</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span v-if="item.isError">エラー</span>
                <span v-else-if="!item.isError && !item.announcedAt">
                  未送信
                </span>
                <span v-else>送信済み</span>
              </td>
              <td
                class="common_tbody_item"
                @click="openApplicantDetail(item.id)"
              >
                <span v-if="item.announcedAt">{{ item.announcedAt }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-else class="common_tbody">
            <tr class="common_tbody_row align-left">
              <td v-if="isNoticeView" class="common_tbody_item"><span /></td>
              <td class="common_tbody_item" colspan="12"><span /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'NoticeApplicantPreview',
  props: {
    applicantList: {
      type: Array,
      default: () => [],
    },
    isViewAddButton: {
      type: Boolean,
      default: true,
    },
    isNoticeView: {
      type: Boolean,
      default: false,
    },
    hasErrorLineFriend: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openApplicantsSelectModal', 'unselectLineFriend'],
  setup(props, context) {
    const selectedApplicantIds = ref([]);
    const selectAllChange = ev => {
      selectedApplicantIds.value = ev.target.checked
        ? props.applicantList.map(applicant => applicant.id)
        : [];
      context.emit('changeSelectedApplicantsIds', selectedApplicantIds.value);
    };
    const openApplicantDetail = applicantId => {
      window.open(`/applicants/${applicantId}?menu=0`, '_blank');
    };
    const onSelectApplicant = applicantId => {
      if (selectedApplicantIds.value.includes(applicantId)) {
        selectedApplicantIds.value = selectedApplicantIds.value.filter(
          id => id !== applicantId,
        );
      } else {
        selectedApplicantIds.value.push(applicantId);
      }
      context.emit('changeSelectedApplicantsIds', selectedApplicantIds.value);
    };
    return {
      selectedApplicantIds,
      selectAllChange,
      openApplicantDetail,
      onSelectApplicant,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/table-normal.scss';
@include common_table_style(
  $col_1: 180px,
  $col_1_ta: left,
  $col_2: 20%,
  $col_2_ta: left,
  $col_3: 20%,
  $col_3_ta: left,
  $col_4: 20%,
  $col_4_ta: left,
  $col_5: 5%,
  $col_5_ta: left,
  $col_6: 5%,
  $col_6_ta: left
);

.line_friend_warning {
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #de3131;
  border-radius: 4px;
  color: #de3131;
  background-color: #fdf0f0;

  > img {
    padding-right: 0.5rem;
  }

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    line-height: 1.6;
  }

  > .btn-caution {
    min-width: 225px;
    margin-left: auto;
  }
}

.is-notice_view {
  .common_thead_item:nth-of-type(1),
  .common_tbody_item:nth-of-type(1) {
    min-width: 3rem;
    width: 3rem;
  }
  .common_thead_item:nth-of-type(2),
  .common_tbody_item:nth-of-type(2) {
    min-width: 180px;
    width: 180px;
  }
}

.headlineA {
  margin-bottom: 20px;
  padding: 15px 20px;
  color: #1899d6;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #f2f9fc;
}

.applicant_warp {
  margin: 0 20px 35px;
}

.applicant_list {
  padding: 0;
  margin-bottom: 1rem;
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: #f2f9fc;
    padding: 0 20px;
    height: 40px;
    &_title {
      font-size: 1.3rem;
      font-weight: bold;
      color: #1899d6;
    }
    button {
      cursor: pointer;
      padding: 8px 10px;
      border-radius: 0.4rem;
      border: 1px solid #027db7;
      background: #1899d6;
      color: #fff;
      font-weight: bold;
      letter-spacing: 0.08em;
      transition: all ease 0.3s;
      position: relative;
    }
  }
  .notice_table {
    overflow: auto;
    max-height: 400px;
  }
  &_title {
    font-size: 1.3rem;
  }
  &_content {
    border: 1px solid #90a4ae;
    border-radius: 0.3rem;
    padding: 1rem;
    overflow-y: auto;
    height: 16rem;
  }
  .applicants {
    line-height: 1.8rem;
  }
  .applicants:not(:last-child):after {
    content: '、';
  }
}

@media (max-width: ($media_query_tablet)) {
  .applicant_warp {
    margin: 0 0 35px;
  }
  .line_friend_warning {
    flex-wrap: wrap;

    > img {
      width: calc(18px + 0.5rem);
    }
    > p {
      width: calc(100% - 18px - 0.5rem);
    }
    > .btn-caution {
      margin-top: 20px;
      width: 100%;
    }
  }
}
</style>
