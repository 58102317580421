<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link to="/announce">応募者への通知</router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">
          リッチメッセージ送信履歴
        </span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <div class="content">
        <div class="btn-notice-history-search-wrapper pos-relative">
          <pager
            class="pager"
            :page="page"
            :count="count"
            :page-count="pageCount"
            @move="movePager"
          />
        </div>
        <div class="scroll-table">
          <table class="table-typeB">
            <thead class="thead">
              <tr class="row">
                <th class="item-th"><span>送信日</span></th>
                <th class="item-th"><span>画像</span></th>
                <th class="item-th"><span>タイトル</span></th>
                <th class="item-th"><span>分割数</span></th>
                <th class="item-th"><span>送信人数</span></th>
                <th class="item-th"><span>送信者</span></th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr
                v-for="itemTmp in richMessageHistory"
                :key="itemTmp.id"
                class="row"
                @click="openModal(itemTmp)"
              >
                <td class="item-td">
                  <span v-if="itemTmp.announced_at">
                    <span class="label-announced">送信済</span>
                    {{
                      $utils.parseDateTime(
                        itemTmp.announced_at,
                        'YYYY/MM/DD HH:mm',
                      )
                    }}
                  </span>
                  <span v-else>
                    <span class="label-schedule">予約送信</span>
                    {{
                      $utils.parseDateTime(
                        itemTmp.schedule_time,
                        'YYYY/MM/DD HH:mm',
                      )
                    }}
                  </span>
                </td>
                <td class="item-td">
                  <span>
                    <img
                      :src="`${itemTmp.richmessage.image_url}/240`"
                      style="width: 50px; height: 50px"
                    />
                  </span>
                </td>
                <td class="item-td">
                  <span>
                    {{ $utils.textEllipsis(itemTmp.richmessage.name) }}
                  </span>
                </td>
                <td class="item-td">
                  <span>{{ itemTmp.richmessage.image_type }}</span>
                </td>
                <td class="item-td">
                  <span>{{ itemTmp.applicant_count }}名</span>
                </td>
                <td class="item-td">
                  <span>
                    {{ itemTmp.staff === null ? '' : itemTmp.staff.lastname
                    }}{{
                      itemTmp.staff === null ? '' : itemTmp.staff.firstname
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <modal-rich-message-view
      v-if="isVisibleModalRichMessageView === true"
      :is-visible="isVisibleModalRichMessageView"
      :rich-message-obj="richMessageObj.data"
      :item="item.data"
      @onClickCancel="isVisibleModalRichMessageView = false"
      @reset="fetchRichMessageHistory"
    />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import RichMessageService from '@/services/richmessage';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import Pager from '@/components/ui/pager/components/Pager';
import ModalRichMessageView from '@/components/page/richmessage/richmessagehistory/components/ModalRichMessageView';

export default defineComponent({
  name: 'RichMessageHistory',
  components: { BreadCrumbList, Pager, ModalRichMessageView },
  setup(props, context) {
    const store = useStore();
    const richMessageHistory = ref([]);
    const richMessageObj = reactive({ data: {} });
    const item = reactive({ data: {} });
    const page = ref(1);
    const count = ref(0);
    const pageCount = ref(0);
    const isVisibleModalRichMessageView = ref(false);

    // methods
    const fetchRichMessageHistory = async (tmpPage = 1) => {
      const res = await RichMessageService.fetchRichMessageRecords(tmpPage);
      richMessageHistory.value = res.list;
      count.value = res.count;
      pageCount.value = res.page_count;
      page.value = tmpPage;
    };
    const openModal = tmpData => {
      richMessageObj.data = tmpData.richmessage;
      item.data = tmpData;
      isVisibleModalRichMessageView.value = true;
    };
    const movePage = tmpPage => {
      fetchRichMessageHistory(tmpPage);
    };
    const movePager = tmpPage => {
      movePage(tmpPage.next);
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchRichMessageHistory();
      await store.dispatch('page/SET_LOADED');
    });
    onMounted(() => (page.value = 1));

    return {
      page,
      count,
      pageCount,
      richMessageHistory,
      richMessageObj,
      isVisibleModalRichMessageView,
      item,
      fetchRichMessageHistory,
      openModal,
      movePage,
      movePager,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.label-announced {
  display: block;
  padding: 4px 9px 5px 20px;
  margin-bottom: 8px;
  width: 65px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  background-color: #dcdcdc;
  border-radius: 2px;
  background-image: url('../../../assets/img/icon_schedule.svg');
  background-size: auto 10px;
  background-position: 6px 6px;
  background-repeat: no-repeat;
}

.label-schedule {
  display: block;
  padding: 4px 6px 5px 26px;
  margin-bottom: 8px;
  width: 80px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  background-color: #d64d85;
  border-radius: 2px;
  background-image: url('../../../assets/img/icon_reminder.svg');
  background-size: auto 15px;
  background-position: 6px 4px;
  background-repeat: no-repeat;
}

.content {
  padding: 40px;
  background: #fff;
}
@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px;
  }
}

.btn-notice-history-search-wrapper {
  display: flex;
  margin-bottom: 1rem;
  .pager {
    margin-left: auto;
  }
}

.scroll-table {
  overflow-x: auto;
  margin-top: 40px;
}
@media (max-width: ($media_query_tablet)) {
  .scroll-table {
    white-space: nowrap;
  }
}
@media (max-width: ($media_query_sp)) {
  .scroll-table {
    margin-top: 20px;
  }
}

.table-typeB {
  table-layout: fixed;
  .row {
    cursor: pointer;
  }
  .row:hover {
    background-color: #f1f1f1;
  }
  .item-td {
    img {
      width: 50px;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .table-typeB {
    table-layout: auto;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
