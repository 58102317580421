<template>
  <div class="form__wrapper">
    <div class="form__inner">
      <div class="form__inner__contents">
        <div class="issue">
          <div class="issue_desc">
            <div class="issue_desc-title">パスワードの再設定</div>
          </div>
          <issue-password
            v-if="mode === 'issuePassword'"
            @changePassword="changePassword"
          />
          <issue-result v-if="mode === 'issueResult'" />
          <footer class="issue">
            <div class="divider">
              <img class="logo-title" src="@/assets/img/mochica_blue.svg" alt />
            </div>
          </footer>
          <notification />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import Notification from '@/components/ui/notification/components/Notification';
import qs from 'qs';
import issueService from '@/services/issue';
import IssuePassword from '@/components/page/issue/components/IssuePassword';
import IssueResult from '@/components/page/issue/components/IssueResult';

export default defineComponent({
  name: 'Issue',
  components: { IssuePassword, IssueResult, Notification },
  setup(props, context) {
    const store = useStore();
    const token = ref(null);
    const mode = ref('issuePassword');

    // methods
    const changePassword = async payload => {
      const res = await issueService.IssuePass({
        token: token.value,
        new_password: payload.new_password,
        confirmation: payload.confirmation,
      });
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        return;
      }
      mode.value = 'issueResult';
    };

    // lifecycle
    onBeforeMount(() => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      token.value = query.token;
      mode.value = 'issuePassword';
    });

    return {
      token,
      mode,
      changePassword,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

@include form_wrapper_css();
.form__wrapper {
  background: #88c6ed;
}
.divider {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #efefef;
}

div.issue {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 800px;
  footer.issue {
    width: 100%;
    padding: 0px;
  }
}
.main-wrapper {
  width: 100%;
}

.logo-title {
  fill: #88c6ed;
  display: block;
  margin: 0 auto 0px;
  width: 20%;
}
.issue_desc {
  text-align: center;
  font-size: 2.6rem;
  margin-bottom: 3rem;
  font-weight: bold;
  &-title {
    margin-top: 1rem;
  }
}
</style>
