import staffsService from '@/services/staffs';

export default {
  components: {
    staffsService,
  },
  namespaced: true,
  state: {
    staff: null,
  },
  getters: {
    staff: currentState => currentState.staff,
    selectedGraduatedId: currentState =>
      currentState.staff.selected_graduated_id,
    staffRole: currentState => currentState.staff.role,
    isAdmin: currentState => currentState.staff.admin,
    isOwner: currentState => currentState.staff.role.isOwner(),
    isManager: currentState => currentState.staff.role.isManager(),
    isOperator: currentState => currentState.staff.role.isOperator(),
  },
  mutations: {
    SET_STAFF(state, staff) {
      Object.assign(state, { staff });
    },
    SET_SELECTED_GRADUATED_ID(state, graduatedId) {
      Object.assign(
        state,
        Object.assign(state.staff, { selected_graduated_id: graduatedId }),
      );
    },
    CLEAR_STAFF(state) {
      Object.assign(state, { staff: null });
    },
  },
  actions: {
    async SET_STAFF({ commit }, staff) {
      commit('SET_STAFF', staff);
    },
    async SET_SELECTED_GRADUATED_ID({ commit }, graduatedId) {
      commit('SET_SELECTED_GRADUATED_ID', graduatedId);
      await staffsService.patchStaffSelectedGraduated(graduatedId);
    },
    async CLEAR_STAFF({ commit }) {
      commit('CLEAR_STAFF');
    },
  },
};
