import httpClient from '@/http-client';
import { download } from '@/utils/file';
import { snakeToCamelCaseForObject } from '@/utils/change-case';

const UploadManager = function _UploadManager() {
  const manager = Object.create(UploadManager.prototype);
  return manager;
};

UploadManager.prototype = {
  async fetchApplicantFiles(applicantId) {
    const response = await httpClient.get(`/upload/filelist/${applicantId}`);
    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data.map(v => snakeToCamelCaseForObject(v)),
      };
    }
    return { success: false, data: response.data ? response.data : null };
  },
  async fetchUploadStatus() {
    const response = await httpClient.get('/upload/status');
    if (response.status === 200) {
      return {
        success: true,
        data: response.data.storage ? response.data.storage : null,
      };
    }
    return { success: false, data: response.data ? response.data : null };
  },
  async uploadFile(payload) {
    const formData = new FormData();
    formData.append('file', payload.file, payload.name);
    const result = await httpClient.post(
      `/upload/${payload.applicantId}`,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
    if (result.status === 200) return { success: true };
    // Error || Duplicate
    return { success: false, data: result.data };
  },
  async deleteFile(payload) {
    const response = await httpClient.delete(`/upload/${payload.id}`);
    if (response.status === 200) return { success: true, data: null };
    return { success: false, data: response.data ? response.data : null };
  },
  async downloadFile(payload) {
    const response = await httpClient.get(`/upload/download/${payload.id}`, {
      responseType: 'blob',
    });
    if (response.status === 200) {
      if (response.data) {
        const fileName = payload.originalFilename
          ? payload.originalFilename
          : payload.filename;
        download(response.data, fileName);
        return { success: true, data: null };
      }
    }
    return { success: false, data: response.data ? response.data : null };
  },
  async uploadMypageVisible(payload) {
    const result = await httpClient.post(
      `/upload/mypage_visible/${payload.uploadId}`,
      payload.request,
    );
    if (result.status === 200) return { success: true };
    return { success: false, data: result.data };
  },
};

export default UploadManager();
