<template>
  <modal :is-view="isVisible">
    <template #rightTop>
      <div class="right-top" @click="$emit('onclickOuter', false)">
        <img class="icon" src="@/assets/img/close_white.svg" alt />
      </div>
    </template>

    <template #content>
      <div class="modal-window">
        <div class="header">
          <p class="title">
            {{ csvTypeName }}のCSVをアップロードをする際のフォーマットについて
          </p>
        </div>
        <div class="body">
          <p class="message -mb20">
            {{
              csvTypeName
            }}からダウンロードしたCSVファイルを使用して応募者の登録を行う際には、
            <br />
            {{
              csvTypeName
            }}で設定されているデフォルトのフォーマット形式に一致している必要がございます。
          </p>
          <p class="message -mb30">
            通常、ダウンロードしたCSVファイルはデフォルトのフォーマットになっていますが、
            <br />
            もしアップロードに失敗した際には、以下よりダウンロードすることができる
            <br />
            CSVファイルと形式が間違っていないかご確認の上、再度お試しください。
          </p>
          <div class="img_area">
            <img
              v-if="csvType === 'mynavi'"
              class="example_img"
              src="@/components/page/applicants/registration/assets/img/mynaviSample.png"
              alt
            />
            <img
              v-else-if="csvType === 'recnavi'"
              class="example_img"
              src="@/components/page/applicants/registration/assets/img/recnaviSample.png"
              alt
            />
            <img
              v-else-if="csvType === 'mochica'"
              class="example_img"
              src="@/components/page/applicants/registration/assets/img/mochicaSample.png"
              alt
            />
            <p class="img_desc">{{ csvTypeName }}のCSVフォーマットの例(一部)</p>
          </div>
        </div>
        <div class="btn-col2">
          <div
            class="btn btn-availability"
            @click="confirmImportFormat(csvType)"
          >
            CSVフォーマットをダウンロードする
          </div>
          <div
            class="btn btn-availability"
            @click="confirmImportSample(csvType)"
          >
            入力例を記載したCSVフォーマットをダウンロードする
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent } from 'vue';
import Modal from '@/components/ui/modals/components/Modal';

export default defineComponent({
  name: 'ModalAboutCsvFormat',
  components: { Modal },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    csvTypeName: {
      type: String,
      default: '',
    },
    csvType: {
      type: String,
      default: '',
    },
  },
  emits: ['onclickOuter'],
  setup(props, context) {
    const staticDirectory = '../../../../static';
    return {
      confirmImportFormat: csvType => {
        location.href = `${staticDirectory}/${csvType}.csv`;
      },
      confirmImportSample: csvType => {
        location.href = `${staticDirectory}/${csvType}_sample.csv`;
      },
      confirmImportReplaceSheet: () => {
        // currently, mynavi only
        location.href = `${staticDirectory}/mynavi2023変換フォーマット.xlsx`;
      },
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.modal-window {
  width: 800px;
}

.img_area {
  .example_img {
    width: 100%;
    max-width: 700px;
    margin-bottom: 10px;
  }
}

.arrow_right {
  position: absolute;
  right: 13px;
  width: 10px;
}
</style>
