<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content modal-window -typeA">
        <div class="header">
          <p class="title">Googleフォーム更新</p>
        </div>
        <transition name="fade">
          <div class="body">
            <div class="body-inner">
              <section>
                <div v-if="!isConnected" class="survey_form">
                  <dl>
                    <dt>
                      <label>Googleフォーム名</label>
                    </dt>
                    <dd>
                      <input
                        v-model="renewSurveyData.sheet_name"
                        type="text"
                        maxlength="30"
                        :class="{ 'is-danger': !errors.sheetName.isValid }"
                        placeholder="Googleフォーム名を入力してください"
                        @input="
                          validateOnInput(
                            'sheetName',
                            renewSurveyData.sheet_name,
                            validateRequire,
                          )
                        "
                      />
                      <span
                        v-show="!errors.sheetName.isValid"
                        class="fa fa-warning font-s"
                      >
                        {{ errors.sheetName.message }}
                      </span>
                    </dd>
                    <dt>
                      <label>スプレッドシートUID</label>
                    </dt>
                    <dd>
                      <span>{{ renewSurveyData.sheet_uid }}</span>
                    </dd>
                    <dt>
                      <label>スプレッドシートGID</label>
                    </dt>
                    <dd>
                      <span>{{ renewSurveyData.sheet_gid }}</span>
                    </dd>
                  </dl>
                </div>
                <div v-else class="survey_conected">
                  <page-loader />
                  <p v-if="!isReceived">
                    Google にリクエストを送信しています。
                  </p>
                  <p v-else>
                    スプレッドシートから {{ size }} kb のデータを受信中です。
                  </p>
                </div>
              </section>
            </div>
          </div>
        </transition>
        <div class="btn-col2">
          <button
            class="btn btn-unavailable"
            :class="{ disabled: isDisabled }"
            :disabled="isDisabled"
            @click="$emit('onClickCancel')"
          >
            キャンセル
          </button>
          <button
            class="btn btn-availability"
            :class="{ disabled: isDisabled }"
            :disabled="isDisabled"
            @click="onClickToConfirm($event, postPottosEvent(32))"
          >
            Googleフォームを更新する
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';
import { useStore } from 'vuex';

import { onResizeModal } from '@/utils/modal-resize';
import useValidation from '@/composables/useValidation';
import Modal from '@/components/ui/modals/components/Modal';
import PageLoader from '@/components/ui/loaders/components/PageLoader';
import { postPottosEvent } from '@/utils/pottos';

export default defineComponent({
  name: 'ModalRenewSurvey',
  components: { Modal, PageLoader },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: null,
    },
    renewSurveyData: {
      type: Object,
      default: () => {},
    },
    forced: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClickCancel', 'surveyRenew'],
  setup(props, context) {
    const { errors, resetErrors, validateOnInput, validateRequire } =
      useValidation(['sheetName']);
    const store = useStore();
    const isDisabled = ref(false);
    const isConnected = ref(false);
    const isReceived = ref(false);

    // watch
    watch(
      () => props.size,
      val => {
        props.size = val;
        if (val) isReceived.value = true;
      },
    );

    // methods
    const onClickToConfirm = async () => {
      resetErrors();
      if (
        validateRequire('sheetName', props.renewSurveyData.sheet_name) !== true
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容をご確認ください',
          type: false,
        });
        return;
      }
      isDisabled.value = true;
      isConnected.value = true;
      context.emit('surveyRenew', {
        graduated_id: props.renewSurveyData.graduated_id,
        sheet_name: props.renewSurveyData.sheet_name,
        sheet_uid: props.renewSurveyData.sheet_uid,
        sheet_gid: props.renewSurveyData.sheet_gid,
      });
    };
    const surveyRenewResponse = () => {
      isConnected.value = false;
      isDisabled.value = false;
      isReceived.value = false;
    };
    const localResizeModal = () =>
      onResizeModal(props.isView, { shouldResizeModalWidth: false });

    // lifecycle
    onBeforeMount(() => {
      if (props.forced === true) {
        isDisabled.value = true;
        isConnected.value = true;
      }
    });
    onMounted(() => {
      window.addEventListener('resize', localResizeModal);
      localResizeModal();
    });
    onUnmounted(() => window.removeEventListener('resize', localResizeModal));

    return {
      errors,
      isDisabled,
      isConnected,
      isReceived,
      onClickToConfirm,
      surveyRenewResponse,
      validateOnInput,
      validateRequire,
      postPottosEvent,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.content {
  border-radius: 1rem;
  .header {
    padding: 2rem;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    font-size: 1.2rem;
    padding: 2rem;
    .body-inner {
      overflow-y: auto;
    }
    section {
      text-align: left;
      .survey_conected {
        p {
          text-align: right;
        }
      }
      .survey_form {
        dl {
          dt {
            margin-bottom: 1rem;
            label {
              font-weight: bold;
            }
          }
          dd {
            margin-bottom: 30px;
            span {
              display: block;
              word-wrap: break-word;
              word-break: break-all;
              white-space: normal;
            }
            input[type='text'] {
              width: 100%;
              border: solid 1px #808080;
              border-radius: 2px;
              padding: 0.8rem;
              font-size: 1.4rem;
              transition: 0.3s ease;
              border-radius: 0.3rem;
              height: 3em;
            }
            input[name='survey_url'] {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.pageloader {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 254px;
}
input:focus,
select:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid !important;
}
.fa-warning {
  color: #c31e1e;
  padding-top: 4px;
}
.fa {
  display: block;
}
// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
