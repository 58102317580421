<template>
  <div>
    <modal-window
      :is-visible="isView"
      title="追加したい卒業年数を入力してください"
      @close="onSelectedModalInput({ selected: 'cancel' })"
    >
      <template #content>
        <div class="modal-window">
          <div class="body">
            <div class="message">
              <input
                v-model.number="year"
                type="number"
                class="year-input"
                :class="{
                  input: true,
                  'is-danger': !errors.graduatedYear.isValid,
                }"
              />
              年卒業の選考を追加する
              <p v-show="!errors.graduatedYear.isValid" class="err-txt">
                {{ errors.graduatedYear.message }}
              </p>
            </div>
          </div>
          <div class="btn-col2">
            <button-base
              class="cancelBtn btn"
              button-type="secondary"
              button-text="キャンセル"
              @click="onSelectedModalInput({ selected: 'cancel' })"
            />
            <button-base
              class="validityBtn btn"
              :disabled="!isValidGraduatedYear"
              button-text="卒業年数を確認"
              @click="onSelectedModalInput({ selected: 'submit' })"
            />
          </div>
        </div>
      </template>
    </modal-window>
    <modal-window
      :is-visible="isVisibleEditModal"
      :title="'卒業年数' + year + '年を追加します'"
      message="この操作は取り消すことができません。"
      button-text="卒業年数を追加"
      :is-disabled="isDisabled"
      :is-loading="isLoading"
      @click="onSelectedModalConfirm"
      @close="onSelectedModalConfirm({ selected: 'cancel' })"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import GraduatedsService from '@/services/graduateds';
import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'ModalCreateGraduated',
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onCanceled'],
  setup(props, context) {
    const { errors, resetErrors, validateGraduatedYear } = useValidation([
      'graduatedYear',
    ]);
    const store = useStore();
    const year = ref(new Date().getFullYear());
    const currentYear = ref(new Date().getFullYear());
    const isVisibleEditModal = ref(false);
    const isDisabled = ref(false);
    const isLoading = ref(false);

    // computed
    const isValidGraduatedYear = computed(() => {
      return validateGraduatedYear(
        'graduatedYear',
        year.value,
        currentYear.value + 32,
        currentYear.value - 5,
        store.getters['graduateds/graduateds'].map(v => v.year),
      );
    });

    // methods
    const onSelectedModalInput = payload => {
      // 卒年選択モーダル押下時
      resetErrors();
      if (payload.selected !== 'submit') {
        // キャンセル時
        context.emit('onCanceled');
        year.value = currentYear.value;
        return;
      }
      if (isValidGraduatedYear.value === true) {
        context.emit('onCanceled');
        isVisibleEditModal.value = true;
      }
    };
    const onSelectedModalConfirm = async payload => {
      // 卒年追加決定モーダル押下時
      if (payload.selected === 'submit') {
        isDisabled.value = true;
        isLoading.value = true;
        const res = await GraduatedsService.createGraduated(year.value);
        isDisabled.value = false;
        isLoading.value = false;
        if (res.success) store.dispatch('graduateds/FETCH');
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: res.success,
        });
        context.emit('onCanceled');
      }
      isVisibleEditModal.value = false;
      year.value = currentYear.value;
    };

    return {
      errors,
      year,
      currentYear,
      isVisibleEditModal,
      isDisabled,
      isLoading,
      isValidGraduatedYear,
      onSelectedModalInput,
      onSelectedModalConfirm,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.modal-window {
  margin: 0;
  width: 100%;
  .message {
    height: 82px;
    text-align: center;
  }
  .year-input {
    width: 100px;
    padding: 10px;
    border-radius: 4px;
    margin-right: 15px;
    border: 1px solid $border_color;
  }
  .err-txt {
    color: $mochica_color_red;
    margin-top: 10px;
  }
  .cancelBtn {
    height: 51px;
  }
  .validityBtn {
    height: 51px;
  }
}
</style>
