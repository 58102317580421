<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link to="/announce">応募者への通知</router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">
          選考の結果を通知する
        </span>
      </template>
    </bread-crumb-list>
    <div class="content-warp">
      <div class="content">
        <div class="headline-typeC">選考の結果を通知する</div>
        <div class="selection-search-wrapper">
          <selections-search
            table-type="notResulted"
            @onSelected="onSelectedSelectionSearch"
          />
        </div>
        <modal-selectable-applicant-with-selection
          :is-visible="isVisibleModalSelectableApplicantWithSelection"
          :modal-type="'result-announce'"
          :selection="selection.data"
          :is-confirm="isApplicantSelectConfirm"
          :applicants="getApplicantList()"
          :init-selected-applicants="selectedApplicantIds"
          :is-disp-line-friends="false"
          :loading-send="isLoadingSendResult"
          :additional-header="[
            {
              name: 'finished_send_status_description',
              name_jp: '送信ステータス',
              sort_by: 'finished_send_status_description',
              relation: ['finishedSendStatusDescription'],
              activated: { disabled: true },
              notActivated: { disabled: true },
              filter: 'finishedSendStatusDescription',
            },
            {
              name: 'finished_sended_at',
              name_jp: '送信日',
              sort_by: 'finished_sended_at',
              relation: ['finishedSendedAt'],
              activated: { disabled: true },
              notActivated: { disabled: true },
              filter: 'finishedSendedAt',
            },
            {
              name: 'result_check',
              name_jp: 'メッセージ確認',
              sort_by: 'result_check',
              relation: ['resultCheck'],
              activated: { disabled: true },
              notActivated: { disabled: true },
              filter: 'resultCheck',
            },
          ]"
          :invisible-columns="[
            'unread_count',
            'reminderCheck',
            'gender',
            'faculty',
            'department',
            'department_category',
            'email',
            'tel',
            'channel',
            'attendanceDate',
            'invited_status',
            'created_at',
            'activated_at',
            'invited_at',
            'staff',
          ]"
          :announce-basic-info-title="`応募者へ選考の結果の通知を行う選考「${
            selection.data && selection.data.event
              ? selection.data.event.title
              : ''
          }」の管理用メモ、連絡事項です。お間違えがないか確認してください。`"
          :announce-venue-title="`応募者へ選考の結果の通知を行う選考「${
            selection.data && selection.data.event
              ? selection.data.event.title
              : ''
          }」の選考会場です。お間違えがないか確認してください。`"
          :announce-applicant-list-title="`応募者へ選考の結果の通知を行う選考「${
            selection.data && selection.data.event
              ? selection.data.event.title
              : ''
          }」に登録されている応募者です。案内して問題ないかご確認してください。`"
          ok-title="応募者に選考結果を通知"
          @onClickAdd="onClickSend"
          @onClickSendWithAnnounce="sendResult(true)"
          @onClickCancel="resetModalStates"
          @onClickBack="onClickBack"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import applicantService from '@/services/applicants';
import NotificationService from '@/services/notifications';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import SelectionsSearch from '@/components/features/selectionsSearch/components/SelectionsSearch';
import ModalSelectableApplicantWithSelection from '@/components/features/modalSelectableApplicants/components/ModalSelectableApplicantWithSelection';

export default defineComponent({
  name: 'ResultAnnounce',
  components: {
    BreadCrumbList,
    SelectionsSearch,
    ModalSelectableApplicantWithSelection,
  },
  setup(props, context) {
    const store = useStore();
    const page = ref(1);
    const recipientApplicants = ref([]);
    const selectedApplicantIds = ref([]);
    const selection = reactive({ data: {} });
    const isLoadingSendResult = ref(false);
    const isApplicantSelectConfirm = ref(false);
    const isVisibleModalSelectableApplicantWithSelection = ref(false);

    // methods
    const onSelectedSelectionSearch = async selectionObj => {
      selection.data = selectionObj;
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      if (graduatedYear) {
        const result = await fetchNotificationTargetApplicants(
          graduatedYear.year,
        );
        recipientApplicants.value = result.applicants;
        selectedApplicantIds.value = result.applicants.map(a => a.id);
      }
      isVisibleModalSelectableApplicantWithSelection.value = true;
    };
    const fetchNotificationTargetApplicants = async year => {
      const res = await applicantService.fetchApplicantsSearch(
        year,
        1,
        10000,
        {},
        {
          or_flag: false,
          // 応募者(LINE+メール) + LINE友達対象
          is_all_applicants: true,
          selection_to_announce: selection.data.id,
        },
      );
      return res;
    };
    const sendResult = async (sendVenue = false) => {
      isLoadingSendResult.value = true;
      const res = await NotificationService.sendResults(
        selection.data.id,
        selectedApplicantIds.value,
        sendVenue,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) resetModalStates();
      isLoadingSendResult.value = false;
    };
    const onClickSend = async applicantIds => {
      if (isApplicantSelectConfirm.value !== true) {
        selectedApplicantIds.value = applicantIds;
        isApplicantSelectConfirm.value = true;
        return;
      }
      await sendResult();
    };
    const getApplicantList = () => {
      return isApplicantSelectConfirm.value === true
        ? recipientApplicants.value.filter(
            a => selectedApplicantIds.value.findIndex(b => b === a.id) !== -1,
          )
        : recipientApplicants.value;
    };
    const onClickBack = () => {
      if (isApplicantSelectConfirm.value === true) {
        isApplicantSelectConfirm.value = false;
        return;
      }
      resetModalStates();
    };
    const resetModalStates = () => {
      isVisibleModalSelectableApplicantWithSelection.value = false;
      isApplicantSelectConfirm.value = false;
      recipientApplicants.value = [];
      selectedApplicantIds.value = [];
      selection.data = {};
    };

    // lifecycle
    onBeforeMount(async () => {
      await store.dispatch('page/SET_LOADED');
    });
    onMounted(() => {
      page.value = 1;
    });

    return {
      page,
      recipientApplicants,
      selectedApplicantIds,
      selection,
      isLoadingSendResult,
      isApplicantSelectConfirm,
      isVisibleModalSelectableApplicantWithSelection,
      onSelectedSelectionSearch,
      sendResult,
      onClickSend,
      getApplicantList,
      onClickBack,
      resetModalStates,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.content {
  padding: 40px 30px;
  background: #fff;
}

.btn + .button_regist {
  margin-left: 10px;
}

.massage-table {
  width: 100%;
  white-space: nowrap;
  margin-bottom: 20px;
  .thead-th {
    padding: 15px 10px;
    text-align: left;
    background-color: #e2e2e2;
  }
  .tbody-row {
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  }
  .tbody-td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
}

.sec_notice_create {
  background: #fff;
  border-radius: 0.3rem;
  margin: 2rem 2rem 10rem 2rem;
  padding: 2rem;
}
.sec_notice_create_title {
  margin-bottom: 2rem;
}
.selection-search-wrapper {
  // display: flex;
  // position: relative;
  // margin-bottom: 20px;
  .pager {
    margin-left: auto;
  }
}
.notice_table {
  overflow-x: auto;
  .red {
    color: $mochica_color_red;
  }
  .green {
    color: #1dcd00;
  }
  .fail {
    color: $mochica_color_red;
    font-weight: bold;
  }
}
.notice_create {
  background: $mochica_color;
  padding: 0 2rem;
  margin-right: 1rem;
  border-radius: 0.4rem;
  font-weight: bold;
  border: 0.1rem solid $mochica_border_color;
  height: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  span {
    color: $white;
  }
}
.btn-notice-history-search-inner {
  font-weight: bold;
}

.balloon-box {
  left: 182px !important;
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px;
  }
  .selection-search-wrapper {
    display: block;
    .pager {
      display: none;
    }
    .btn {
      width: 100%;
      text-align: left;
    }
    .btn + .button_regist {
      margin: 10px 0 0 0;
    }
  }
  .balloon-box {
    top: 85px !important;
    left: 0 !important;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
