<template>
  <div class="area-selection-detail">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link to="/selections">選考一覧</router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">選考の詳細情報</span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <selection-detail-body
        :id="id"
        :selection-detail-mode="$route.meta.isRegistration ? 2 : 3"
      />
    </section>
  </div>
</template>

<script>
import { defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import SelectionDetailBody from '@/components/features/selectionDetailBody/components/SelectionDetailBody';

export default defineComponent({
  name: 'SelectionDetail',
  components: { BreadCrumbList, SelectionDetailBody },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    watch(
      () => store.getters['graduateds/selectedGraduatedId'],
      () => router.replace('/selections'),
    );
    return {};
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
