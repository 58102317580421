import httpClient from '@/http-client';

const GoogleCalendarManager = function _GoogleCalendarManager() {
  const manager = Object.create(GoogleCalendarManager.prototype);
  return manager;
};

GoogleCalendarManager.prototype = {
  async checkLinkGoogleCalendar() {
    const res = await httpClient.get('/google_calendar/link_service_account');
    return res.data;
  },
  async linkGoogleCalendar(file) {
    const formData = new FormData();
    formData.append('file', file, 'file');
    const res = await httpClient.post(
      '/google_calendar/link_service_account',
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
    return res.data;
  },
  async resetLinkGoogleCalendar() {
    const res = await httpClient.post('/google_calendar/reset_link');
    return res.data;
  },
  async fetchGoogleWorkspaceResources() {
    const res = await httpClient.get(
      '/google_calendar/link_workspace_customer_id',
    );
    return res.data;
  },
  async linkGoogleWorkspaceCustomerIdCalendar(payload) {
    const res = await httpClient.post(
      '/google_calendar/link_workspace_customer_id',
      payload,
    );
    return res.data;
  },
};

export default GoogleCalendarManager();
