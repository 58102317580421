<template>
  <modal
    :is-view="isView"
    :flow-item="flowItem"
    :is-disabled-show-modal-displayed="isDisabledShowModalDisplayed"
  >
    <template #rightTop>
      <div class="right-top" @click="close">
        <img class="icon" src="@/assets/img/close_white.svg" alt />
      </div>
    </template>
    <template #content>
      <div class="modal-window">
        <div class="header">
          <p class="title">応募者一覧のCSVダウンロードの予約</p>
        </div>
        <div class="select-graduated">
          <div class="multi_selector" @click="isOpenGraduatedList = true">
            {{
              selectedGraduated.length === 0
                ? '選択してください'
                : selectedGraduated.map(v => v.name).join(', ')
            }}
          </div>
          <float-context-menu
            :is-view="isOpenGraduatedList"
            :list="graduatedList"
            :is-multiple-select="true"
            @onClose="isOpenGraduatedList = false"
            @onSelected="onSelectedGraduated"
          />
        </div>
        <div class="contact-type">
          <div class="applicant-choice">
            <input
              id="1"
              v-model="contactType"
              type="radio"
              name="contacts"
              value="1"
            />
            <label for="1">応募者のみ</label>
          </div>
          <div class="applicant-choice">
            <input
              id="2"
              v-model="contactType"
              type="radio"
              name="contacts"
              value="2"
            />
            <label for="2">LINE友達のみ</label>
          </div>
          <div class="applicant-choice">
            <input
              id="3"
              v-model="contactType"
              type="radio"
              name="contacts"
              value="3"
            />
            <label for="3">全員</label>
          </div>
        </div>
        <div class="body">
          <p class="message -mb20">
            CSVに選考状況を含めるかを選択してください。
          </p>
          <div class="btn-area">
            <div
              :class="{ active: downloadCsvType === 'flag' }"
              class="btn btn-swicher"
              @click="downloadCsvType = 'flag'"
            >
              <span>「フラグ」の項目を含める</span>
            </div>
            <div
              :class="{ active: downloadCsvType === 'status' }"
              class="btn btn-swicher"
              @click="downloadCsvType = 'status'"
            >
              <span>「選考名・選考ステータス」の項目を含める</span>
            </div>
            <div
              :class="{ active: downloadCsvType === 'all' }"
              class="btn btn-swicher"
              @click="downloadCsvType = 'all'"
            >
              <span>「フラグ」と「選考名・選考ステータス」の項目を含める</span>
            </div>
          </div>
        </div>
        <div class="btn-col2">
          <div class="btn cancelBtn" @click="close">
            <span>キャンセル</span>
          </div>
          <div
            class="btn validityBtn active"
            :class="{ 'is-disabled': selectedGraduated.length === 0 }"
            @click="reserveDownloadCsv"
          >
            <img class="icon" src="@/assets/img/download_white.svg" alt />
            <span>ダウンロードの予約をする</span>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import GraduatedDefines from '@/defines/graduated';
import Modal from '@/components/ui/modals/components/Modal';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';

export default defineComponent({
  name: 'ModalReserveCsvDownload',
  components: { Modal, FloatContextMenu },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    flowItem: {
      type: Boolean,
      default: false,
    },
    isDisabledShowModalDisplayed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onclickOuter', 'reserveDownloadCsv'],
  setup(props, context) {
    const store = useStore();
    const contactType = ref(1); // 1:応募者のみ|2:LINE友達のみ|3:全員
    const downloadCsvType = ref('flag'); // 'flag'|'status'|'all'
    const graduatedList = ref([]);
    const isOpenGraduatedList = ref(false);

    // computed
    const selectedGraduated = computed(() =>
      graduatedList.value.filter(graduated => graduated.selected),
    );

    onMounted(() => {
      const graduatedYearToLabelName = year => {
        const res = GraduatedDefines.find(v => v.year === year);
        if (res !== undefined) return res.name_jp;
        return `${year}年卒業者`;
      };
      graduatedList.value = store.getters['graduateds/graduateds'].map(
        graduated => ({
          id: graduated.year,
          selected: false,
          name: graduatedYearToLabelName(graduated.year),
        }),
      );
    });

    // methods
    const reserveDownloadCsv = () => {
      if (selectedGraduated.value.length === 0) return;
      context.emit('reserveDownloadCsv', {
        contactType: parseInt(contactType.value, 10),
        downloadCsvType: downloadCsvType.value,
        graduated_years: selectedGraduated.value.map(graduated => graduated.id),
      });
      resetGraduatedList();
    };
    const close = () => {
      resetGraduatedList();
      context.emit('onclickOuter', false);
    };
    const onSelectedGraduated = ({ item }) => {
      graduatedList.value = graduatedList.value.map(v => {
        if (v.id === item.id) return { ...v, ...{ selected: !item.selected } };
        return v;
      });
    };
    const resetGraduatedList = () => {
      graduatedList.value = graduatedList.value.map(graduated => {
        return { ...graduated, ...{ selected: false } };
      });
    };

    return {
      contactType,
      downloadCsvType,
      isOpenGraduatedList,
      graduatedList,
      selectedGraduated,
      reserveDownloadCsv,
      close,
      onSelectedGraduated,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.disable_color {
  opacity: 0.3;
}
.contact-type {
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  .applicant-choice {
    display: flex;
    padding: 30px 5px 0;
    input {
      margin-right: 2px;
      cursor: pointer;
    }
    label[for='1'],
    label[for='2'],
    label[for='3'] {
      cursor: pointer;
      margin-right: 3px;
    }
  }
}
.btn-area {
  width: 470px;
}

.select-graduated {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 470px;
  margin: 20px 20px 0;
}

.multi_selector {
  width: 100%;
  height: 50px;
  display: inline-block;
  position: relative;
  margin-top: 10px;
  padding: 16px 20px 16px 10px;
  font-size: 16px;
  line-height: 1;
  border-radius: 4px;
  border: 1px solid #ccc;
  appearance: none;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    opacity: 0.7;
  }
  &::after {
    position: absolute;
    top: 50%;
    right: 7px;
    width: 6px;
    height: 6px;
    content: '';
    margin-top: -4px;
    border: 0;
    border-bottom: solid 2px #ccc;
    border-right: solid 2px #ccc;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.btn.is-disabled {
  color: #333 !important;
  background-color: #999;
  opacity: 0.3;
  cursor: not-allowed;
  > img {
    display: none;
  }
}
</style>
