<template>
  <div
    v-if="isView"
    ref="refFloatBox"
    class="float-box basic-box-shadow"
    :style="styles"
  >
    <div class="box-container">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

// FloatContextMenuもしくはAddTextで利用
export default defineComponent({
  name: 'FloatBox',
  props: {
    zIndex: {
      type: Number,
      default: 10,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: null,
    },
    left: {
      type: String,
      default: null,
    },
    right: {
      type: String,
      default: null,
    },
    bottom: {
      type: String,
      default: null,
    },
    suspendCloseEvent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, context) {
    const refFloatBox = ref(null);

    const styles = () => {
      let style = {};
      if (props.top) style = Object.assign({}, style, { top: props.top });
      if (props.left) style = Object.assign({}, style, { left: props.left });
      if (props.right) style = Object.assign({}, style, { right: props.right });
      if (props.bottom) {
        style = Object.assign({}, style, { bottom: props.bottom });
      }
      if (props.zIndex) {
        style = Object.assign({}, style, { 'z-index': props.zIndex });
      }
      return style;
    };
    const onClickOuter = ev => {
      if (
        props.suspendCloseEvent === true ||
        props.isView !== true ||
        (refFloatBox.value && refFloatBox.value.contains(ev.target))
      ) {
        // 自分のコンポーネント内の要素をクリックした場合は閉じない
        return;
      }
      context.emit('close');
    };

    onMounted(() => {
      window.addEventListener('click', onClickOuter, true);
    });
    onUnmounted(() => {
      window.removeEventListener('click', onClickOuter, true);
    });

    return { refFloatBox, styles };
  },
});
</script>

<style lang="scss" scoped>
.float-box {
  position: absolute;
  z-index: 20;
  & :deep(.flag-add-btn-svg) {
    width: 2rem;
    height: 2rem;
  }
}
</style>
