<template>
  <section class="as-block">
    <div class="headline">
      <img class="icon" src="@/assets/img/flag_white.svg" />
      フラググループ
    </div>
    <div class="inner">
      <div class="responsive-overflow">
        <table class="table-flag">
          <thead>
            <tr>
              <th>フラググループ名</th>
              <th>フラグ名</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in applicant.tagGroups" :key="group.id">
              <td class="flag-group">{{ group.name }}</td>
              <td class="flag-name">
                <div class="pos-relative">
                  <div v-if="group.tags.length > 0">
                    <ul class="list-flag_name">
                      <li
                        v-for="tag in group.tags"
                        :key="tag.id"
                        class="flag_name"
                      >
                        <span class="flag">{{ tag.name }}</span>
                      </li>
                      <li>
                        <img
                          class="flag-add-btn-svg"
                          width="20"
                          height="20"
                          src="@/assets/img/add_circle_gray.svg"
                          @click="onClickAddFlg({ group })"
                        />
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    <ul class="flexbox flexbox-middle flagNoneSet">
                      <li>
                        <button
                          class="flag-select-button"
                          type="button"
                          @click="onClickAddFlg({ group })"
                        >
                          <span>フラグを選択</span>
                          <img
                            class="flag-add-btn-svg"
                            width="20"
                            height="20"
                            src="@/assets/img/add_circle_gray.svg"
                          />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <tag-context-menu
                    v-if="addFlag && group.id === selectedTagGroupId"
                    :placeholder="'フラグを新規登録'"
                    :list="getTagsForContext"
                    @close="addFlag = false"
                    @onChangeSelect="onChangeFlag"
                    @onClickNewItem="onClickNewItemTag($event, group.id)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pos-relative">
        <div class="btn btn-availability" @click="addFlagGroup = true">
          <img class="icon" src="@/assets/img/add_white.svg" />
          フラググループを追加する
        </div>
        <tag-context-menu
          v-if="addFlagGroup"
          :placeholder="'フラググループを新規登録'"
          :list="getTagGroupsForContext"
          @close="addFlagGroup = false"
          @onChangeSelect="onChangeSelectFlagGroup"
          @onClickNewItem="onAddNewFlagGroup"
        />
      </div>
    </div>
    <matched-actions-modal
      :is-view="isViewMatchAutoActionsModal"
      :actions="matchActions"
      :target-item="{
        type: 'flag',
        target: targetFlag.name,
      }"
      @submit="execChangeFlag"
      @close="cancelChangeFlag"
    />
    <flag-group-matched-actions-modal
      :is-view="isViewUnSelectFlagGroupModal"
      :actions="matchActions"
      :target-flag-names="targetFlagNames"
      @submit="execChangeFlagGroup"
      @close="isViewUnSelectFlagGroupModal = false"
    />
  </section>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  onUnmounted,
  ref,
} from 'vue';
import { useStore } from 'vuex';

import tagsService from '@/services/tags';
import TagContextMenu from '@/components/ui/menus/components/TagContextMenu';
import { postPottosEvent } from '@/utils/pottos';
import MatchedActionsModal from '@/components/features/autoActions/modals/MatchedActionsModal';
import FlagGroupMatchedActionsModal from '@/components/features/autoActions/modals/flagGroup/MatchedActionsModal';

import autoActionsService from '@/services/autoActions.js';

export default defineComponent({
  name: 'ApplicantFlagGroup',
  components: {
    TagContextMenu,
    MatchedActionsModal,
    FlagGroupMatchedActionsModal,
  },
  props: {
    applicantGraduatedId: {
      type: Number,
      default: null,
    },
  },
  emits: ['toModalDialog'],
  setup(props, context) {
    const store = useStore();
    const addFlag = ref(false);
    const addFlagGroup = ref(false);
    const selectedTagGroupId = ref(-1);

    const targetFlag = ref({});
    const targetFlagGroup = ref({});
    const isViewMatchAutoActionsModal = ref(false);
    const isViewUnSelectFlagGroupModal = ref(false);
    const matchActions = ref([]);

    // computed
    const applicant = computed(() => store.getters['applicant/applicant']);
    const getTagGroupsForContext = computed(() => {
      const result = [];
      for (const group of store.getters['company/companyTagGroups']) {
        result.push({
          id: group.id,
          name: group.name,
          selected:
            store.getters['applicant/applicantTagGroups'][group.id] !==
            undefined,
        });
      }
      return result;
    });
    const getTagsForContext = computed(() => {
      const filteredCompanyTagGroups = store.getters[
        'company/companyTagGroups'
      ].filter(group => {
        const result = group.id === parseInt(selectedTagGroupId.value, 10);
        return result;
      });
      if (
        filteredCompanyTagGroups &&
        filteredCompanyTagGroups.length > 0 &&
        filteredCompanyTagGroups[0].tags
      ) {
        const applicantTags = store.getters['applicant/applicantTagsKv'];
        return filteredCompanyTagGroups[0].tags.map(tag =>
          Object.assign({}, tag, {
            selected: applicantTags[tag.id] !== undefined,
          }),
        );
      }
      return [];
    });

    const targetFlagNames = computed(() => {
      if (!targetFlagGroup.value?.tags) return [];
      return targetFlagGroup.value.tags.map(tag => {
        return tag.name;
      });
    });

    // methods
    const onClickAddFlg = event => {
      addFlag.value = true;
      selectedTagGroupId.value = event.group.id;
    };
    const onChangeSelectFlagGroup = async event => {
      // フラググループ追加
      postPottosEvent(28);
      if (event.selected) {
        await store.dispatch('applicant/ADD_APPLICANT_TAG_GROUP', event);
      } else {
        targetFlagGroup.value = applicant.value.tagGroups.find(
          tagGroup => tagGroup.id === event.id,
        );
        await checkMatchActions({
          action: 'remove',
          tag_group_id: targetFlagGroup.value.id,
        });
        if (matchActions.value.length > 0) {
          isViewUnSelectFlagGroupModal.value = true;
        } else {
          context.emit('toModalDialog', {
            applicantId: applicant.value.id,
            tagGroupId: event.id,
          });
        }
      }
    };
    const onAddNewFlagGroup = async ev => {
      // 新規フラググループ作成
      const tagGroup = await store.dispatch('company/ADD_COMPANY_TAG_GROUP', {
        id: store.getters['company/company'].id,
        name: ev.name,
      });
      await store.dispatch('applicant/ADD_APPLICANT_TAG_GROUP', tagGroup);
    };
    const addApplicantTag = async (applicantId, tagId) => {
      // フラグ追加
      postPottosEvent(64);
      await store.dispatch('applicant/ADD_APPLICANT_TAG', {
        applicantId,
        tagId,
      });
      await store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
    };
    const onChangeSelectTag = async event => {
      if (event.selected) {
        await addApplicantTag(applicant.value.id, event.id);
      } else {
        // フラグ削除
        postPottosEvent(64);
        await store.dispatch('applicant/DELETE_APPLICANT_TAG', {
          applicantId: applicant.value.id,
          event,
        });
      }
    };
    const onClickNewItemTag = async (event, tagGroupId) => {
      const res = await tagsService.createTag(tagGroupId, event.name);
      await store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.messages,
        type: res.success,
      });
      if (res.success) addApplicantTag(applicant.value.id, res.tag.id);
    };

    const onChangeFlag = async event => {
      targetFlag.value = event;
      await checkMatchActions({
        action: targetFlag.value.selected ? 'append' : 'remove',
        tag_id: targetFlag.value.id,
      });
      if (matchActions.value.length > 0) {
        isViewMatchAutoActionsModal.value = true;
      } else {
        onChangeSelectTag(targetFlag.value);
      }
    };

    const checkMatchActions = async payloadTag => {
      const { data } = await autoActionsService.checkMatchAction(
        applicant.value.id,
        {
          graduated_id: props.applicantGraduatedId,
          selection_id: applicant.value.status.selectionId,
          flag: payloadTag,
        },
      );
      matchActions.value = data.actions;
    };
    const execChangeFlag = async () => {
      onChangeSelectTag(targetFlag.value);
      isViewMatchAutoActionsModal.value = false;
    };
    const cancelChangeFlag = () => {
      getTagsForContext.value = getTagsForContext.value.map(tag => {
        if (tag.id === targetFlag.value.id) {
          tag.selected = !tag.selected;
        }
        return tag;
      });
      isViewMatchAutoActionsModal.value = false;
    };

    const execChangeFlagGroup = async () => {
      await store.dispatch('applicant/DELETE_APPLICANT_TAG_GROUP', {
        applicantId: applicant.value.id,
        tagGroupId: targetFlagGroup.value.id,
      });
      isViewUnSelectFlagGroupModal.value = false;
    };

    // lifecycle
    onBeforeMount(() => {
      store.dispatch('company/FETCH_COMPANY_TAG_GROUP');
    });
    onUnmounted(() => {
      store.dispatch('company/CLEAR_COMPANY_TAG_GROUPS');
    });

    return {
      applicant,
      addFlag,
      addFlagGroup,
      selectedTagGroupId,
      getTagGroupsForContext,
      getTagsForContext,
      isViewMatchAutoActionsModal,
      isViewUnSelectFlagGroupModal,
      matchActions,
      targetFlag,
      targetFlagGroup,
      targetFlagNames,
      onClickAddFlg,
      onChangeSelectFlagGroup,
      onAddNewFlagGroup,
      onChangeSelectTag,
      onClickNewItemTag,
      checkMatchActions,
      execChangeFlag,
      cancelChangeFlag,
      onChangeFlag,
      execChangeFlagGroup,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.as-block {
  margin-bottom: 20px;
  background-color: #fff;
  line-height: 1.5;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  .inner {
    padding: 30px 20px;
  }
}

.list-flag_name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .flag_name {
    padding: 10px;
    border-radius: 4px;
    background-color: #ebebeb;
    word-break: break-all;
  }
  li {
    margin-bottom: 15px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.flagNoneSet {
  margin-bottom: 15px;
  .flag-select-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    .flag-add-btn-svg {
      margin-left: 5px;
    }
  }
}

.responsive-overflow {
}

.table-flag {
  width: 100%;
  font-size: 1.3rem;
  thead {
    background-color: #e2e2e2;
    th {
      padding: 15px;
      text-align: left;
      &:nth-child(1) {
        width: 200px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e2e2e2;
    }
  }
  .flag-group {
    padding: 0 15px;
    word-break: break-all;
  }
  .flag-name {
    padding: 0;
  }
}

.pos-relative {
  margin-top: 20px;
}
.flag-add-btn-svg {
  margin: -3px;
  cursor: pointer;
}

@media (max-width: ($media_query_tablet)) {
  .table-flag {
    thead {
      th {
        &:nth-child(1) {
          width: auto;
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .table-flag {
    thead {
      th {
        font-size: 1.1rem;
      }
    }
  }
  .flag-name .pos-relative .tag-context-menu.basic-box-shadow {
    top: initial;
    left: -80px;
  }
}
</style>
