<template>
  <div>
    <search-applicants
      ref="refSearchApplicants"
      :is-view="searchFormIsVisible"
      :flag-mode="isFlagMode"
      :staffs="staffs"
      :events="events"
      :tag-groups="tagGroups"
      :surveys-props="surveyTitles"
      :display-activated-at="isActivated"
      :form-default="search"
      :has-preset="true"
      @loadPresetSearchDetail="loadPresetSearchDetail"
      @searchFormDetailSwitch="$emit('searchFormDetailSwitch', $event)"
      @onClose="$emit('updateSearchFormIsVisible', false)"
      @onSearch="$emit('onSearch', $event)"
      @onReset="$emit('onSearchReset', $event)"
      @fetchSearchFilterPresets="$emit('fetchSearchFilterPresets', $event)"
    />
    <search-applicants-detail
      ref="refSearchApplicantsDetail"
      :is-view="searchFormDetailIsVisible"
      :flag-mode="isFlagMode"
      :staffs="staffs"
      :events="events"
      :tag-groups="tagGroups"
      :survey-sheet-id="surveySheetId"
      :surveys-props="surveyTitles"
      :display-activated-at="isActivated"
      :form-default="search"
      :change-flag-mode="changeFlagMode"
      :has-preset="true"
      @searchFormDetailSwitch="$emit('searchFormDetailSwitch', $event)"
      @onClose="$emit('updateSearchFormDetailIsVisible', false)"
      @onSearch="$emit('onSearch', $event)"
      @onReset="$emit('onSearchReset', $event)"
      @updateSurveySheetId="$emit('updateSurveySheetId', $event)"
      @updateSurveyTitles="$emit('updateSurveyTitles', $event)"
      @updateIsShowSelectableApplicantTable="
        $emit('updateIsShowSelectableApplicantTable', $event)
      "
      @fetchSearchFilterPresets="$emit('fetchSearchFilterPresets', $event)"
    />
  </div>
</template>

<script>
import { defineComponent, ref, nextTick } from 'vue';

import SearchApplicants from '@/components/features/searchApplicants/components/SearchApplicants';
import SearchApplicantsDetail from '@/components/features/searchApplicants/components/SearchApplicantsDetail';

export default defineComponent({
  name: 'SearchApplicantsWrapper',
  components: {
    SearchApplicants,
    SearchApplicantsDetail,
  },
  props: {
    searchFormIsVisible: {
      type: Boolean,
      required: true,
    },
    searchFormDetailIsVisible: {
      type: Boolean,
      required: true,
    },
    isFlagMode: {
      type: Boolean,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    staffs: {
      type: Array,
      required: true,
    },
    tagGroups: {
      type: Array,
      default: () => [],
    },
    surveySheetId: {
      type: Number,
      default: null,
    },
    surveyTitles: {
      type: Array,
      default: () => [],
    },
    isActivated: {
      type: Boolean,
      required: true,
    },
    search: {
      type: Object,
      default: () => {},
    },
    changeFlagMode: {
      type: Function,
      default: () => () => {},
    },
  },
  emits: [
    'updateSearchFormIsVisible',
    'updateSearchFormDetailIsVisible',
    'searchFormDetailSwitch',
    'onSearch',
    'onSearchReset',
    'updateSurveySheetId',
    'updateSurveyTitles',
    'updateIsShowSelectableApplicantTable',
    'fetchSearchFilterPresets',
  ],
  setup(props, context) {
    const refSearchApplicants = ref(null);
    const refSearchApplicantsDetail = ref(null);
    const loadPresetSearchDetail = args => {
      nextTick(() => {
        refSearchApplicantsDetail.value.loadPreset(args);
      });
    };
    const reset = () => {
      refSearchApplicants.value.reset();
      refSearchApplicantsDetail.value.reset();
    };

    return {
      refSearchApplicants,
      refSearchApplicantsDetail,
      loadPresetSearchDetail,
      reset,
    };
  },
});
</script>

<style scoped lang="scss"></style>
