import cloneDeep from 'lodash.clonedeep';
import { computed, ref, reactive, toRefs } from 'vue';

import Breakpoints from '@/defines/breakpoints';
import { applicantListColumns } from '@/defines/applicant';
import applicantService from '@/services/applicant';
import applicantsService from '@/services/applicants';
import tagsService from '@/services/tags';
import staffsService from '@/services/staffs';
import eventsService from '@/services/events';
import surveyService from '@/services/survey';
import {
  getInitSearchBodyForRequest,
  getInitSearchFlagForRequest,
} from '@/models/applicant-search';

const definesApplicantListColumns = cloneDeep(applicantListColumns);

/**
 * @param {Object} store Vuex Store Object
 * @param {body: Object, sort: Object, searchFlags: { or_flag: boolean }} searchFilter 検索フィルタ
 * @param {{
 *   setStaffSetting: () => { 'applicantListColumns': [string], 'pageLoad': number },
 *   invisibleColumnsPc: [string],
 *   invisibleColumnsSp: [string],
 *   excludeSelectionId: [number],
 *   isAllApplicants: boolean,
 *   isOnlyApplicantsOfLine: boolean,
 *   isExceptGraduated: boolean,
 *   isRequiredApplicantTags: boolean,
 *   router: Object?
 * }} options setStaffSetting: スタッフ設定を反映させる場合の関数,
 * invisibleColumnsPc: PCでの非表示カラム,
 * invisibleColumnsSp: SPでの非表示カラム,
 * excludeSelectionId: 任意の選考を除外するフラグ,
 * isAllApplicants: 応募者・LINE友達全て対象,
 * isOnlyApplicantsOfLine: LINE登録応募者のみ対象,
 * isExceptGraduated: 卒年を無視して検索する場合のフラグ,
 * isRequiredApplicantTags: アンケート表示が必要な場合のフラグ
 * router: Vue-Router Object(応募者一覧のみ指定)
 */
export default function useSearchApplicant(
  store = {},
  searchFilter = {
    body: getInitSearchBodyForRequest(),
    sort: {},
    searchFlags: getInitSearchFlagForRequest(),
    init: true,
  },
  options = {
    setStaffSetting: null,
    invisibleColumnsPc: null,
    invisibleColumnsSp: null,
    excludeSelectionId: null,
    isAllApplicants: false,
    isOnlyApplicantsOfLine: false,
    isExceptGraduated: false,
    isRequiredApplicantTags: false,
    router: null,
  },
) {
  /**
   * loadSelectionの初期化メソッド
   * @return {[{ id: number, name: string, selected: boolean }]}
   */
  const initLoadSelection = () => {
    const company = store.getters['company/company'];
    const baseLoadSelection = [
      { id: 5, name: '5', selected: false },
      { id: 25, name: '25', selected: true },
      { id: 50, name: '50', selected: false },
      { id: 100, name: '100', selected: false },
      { id: 200, name: '200', selected: false },
    ];
    if (
      (company.id === 557 && company.name === '大東建託株式会社') ||
      company.id === 1
    ) {
      return baseLoadSelection.concat([
        { id: 500, name: '500', selected: false },
      ]);
    } else {
      return baseLoadSelection;
    }
  };
  const search = reactive(cloneDeep(searchFilter));
  const page = ref(1);
  const count = ref(0);
  const pageCount = ref(0);
  const pageLoad = ref(25);
  const applicants = ref([]);
  const resApplicantIds = ref([]);
  const storedResApplicantIds = ref([]);
  const events = ref([]);
  const staffs = ref([]);
  const applicantListColumns = ref([]);
  const viewApplicantListColumns = ref([]);
  const loadSelection = ref(initLoadSelection());
  const surveyList = ref([{ id: 0, name: 'フラグモード', selected: true }]);
  const selectedApplicants = ref([]);
  const headerAdditional = ref([]);
  const tagGroups = ref([]);
  const surveyTitles = ref([]);
  const targetIds = ref([]);
  const invisibleColumns = ref([]);
  const timeoutId = ref(null);
  const surveySheetId = ref(null);
  const searchFormIsVisible = ref(false);
  const searchFormDetailIsVisible = ref(false);
  const searchFormDetailSwitchIsVisible = ref(false);
  const isActivated = ref(true);
  const isFlagMode = ref(true);
  const isShowSelectableApplicantTable = ref(false);
  const isShowSelectableApplicantTableSort = ref(true);
  const isOpenPagerLoad = ref(false);
  const isOpenSurveyMenu = ref(false);
  const isOpenSearchApplicantFilters = ref(true);
  const applicantsTags = reactive({});
  const highlightApplicantIds = ref([]);
  const searchFilterObject = reactive({ data: {} });
  const isSortedApplicantsByUnreadCountCheck = ref(false);

  // computed
  /**
   * スタッフ設定利用時の応募者一覧非表示ヘッダーのID配列
   * @return {[number]}
   */
  const invisibleColumnsStaffSetting = computed(() => {
    return applicantListColumns.value
      .filter(
        column =>
          column.selected === false ||
          (isActivated.value
            ? column.activated.disabled
            : column.notActivated.disabled),
      )
      .map(item => item.id);
  });
  /**
   * @return {[number]}
   */
  const surveySetting = computed(() => {
    const surveySelected = surveyList.value.filter(
      survey => survey.selected === true,
    );
    if (surveySelected && surveySelected.length >= 1) {
      return surveySelected[0].name;
    }
    return surveyList.value[0].name;
  });
  /**
   * 選択中の応募者
   * @return {[Object]}
   */
  const getSelectedApplicants = computed(() => {
    return applicants.value.filter(
      applicant =>
        selectedApplicants.value.findIndex(id => applicant.id === id) >= 0,
    );
  });
  /**
   * 選択中の応募者のID配列
   * @return {[number]}
   */
  const getSelectedApplicantsIds = computed(() => {
    return getSelectedApplicants.value.map(applicant => applicant.id);
  });

  // methods
  /**
   * 検索イベント
   * @param {{ searchBody: Object, orFlag: boolean, searchFilterObject: Object }} payload 検索条件
   */
  const onSearch = async payload => {
    searchFormIsVisible.value = false;
    searchFormDetailIsVisible.value = false;
    const body = cloneDeep(payload.searchBody);
    search.body = body;
    search.searchFlags.or_flag = payload.orFlag;
    search.init = false;
    searchFilterObject.data = payload.searchFilterObject;
    await movePage();
  };
  /**
   * 検索リセットイベント
   */
  const onSearchReset = async () => {
    isOpenSearchApplicantFilters.value = false;
    searchFormIsVisible.value = false;
    searchFormDetailIsVisible.value = false;
    search.body = getInitSearchBodyForRequest();
    search.searchFlags.or_flag = false;
    search.sort = {};
    search.init = true;
    searchFilterObject.data = {};
    isOpenSearchApplicantFilters.value = true;
    await movePage();
  };
  /**
   * ページ移動イベント
   * @param {number} nextPage 次のページ数
   */
  const movePage = async (nextPage = 1, isNotFetchApplicants = false) => {
    isShowSelectableApplicantTable.value = false;
    selectedApplicants.value = [];
    if (options.router && nextPage !== page.value) {
      options.router.push({ path: `/applicants/page/${nextPage}` });
    }
    if (isNotFetchApplicants) return;
    await fetchApplicants(nextPage);
  };
  /**
   * ページャー押下イベント
   * @param {{ next: number }} nextPage 次のページ数
   */
  const movePager = async (
    nextPage = { next: 1 },
    isNotFetchApplicants = false,
  ) => {
    isOpenSearchApplicantFilters.value = true;
    await movePage(nextPage.next, isNotFetchApplicants);
  };
  /**
   * ページ移動イベント
   * @param {{ data: {
   *   page: number,
   *   count: number,
   *   pageCount: number,
   *   applicants: [Object],
   *   resApplicantIds: [number],
   *   surveyTitles: [string],
   * }}} result 次のページ数
   */
  const setApplicants = result => {
    // 応募者情報のセット
    if (result.success === true) {
      applicants.value = cloneDeep(result.data.applicants);
      resApplicantIds.value = cloneDeep(result.data.resApplicantIds);
      page.value = result.data.page;
      count.value = result.data.count;
      pageCount.value = result.data.pageCount;
      surveyTitles.value = cloneDeep(result.data.surveyTitles);
      setHeaderAdditional();
    } else {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: result.error.message,
        type: result.error.type,
        nonVisible: result.error.nonVisible,
      });
    }
    isShowSelectableApplicantTable.value = true;
  };
  /**
   * 応募者一覧のリセット
   */
  const resetState = async () => {
    applicants.value = [];
    selectedApplicants.value = [];
    page.value = 1;
    count.value = 0;
    pageCount.value = 0;
    targetIds.value = [];
    if (!isActivated.value) {
      await switchActivated(false);
    } else {
      await movePage(1);
    }
  };
  /**
   * 応募者検索
   * @param {number} page ページ数
   * @param {boolean} resetSort ソートをリセットするフラグ
   */
  const fetchApplicants = async (
    page = 1,
    resetSort = false,
    flagReload = false,
  ) => {
    const graduatedYear =
      await store.getters['graduateds/selectedGraduatedYear'];
    if (!graduatedYear) return;
    if (resetSort) search.sort = {};
    const searchBody = cloneDeep(search.body);
    const searchFlags = cloneDeep(search.searchFlags);
    // activatedはここで指定（LINE応募者に限定する場合はfalse）
    searchBody.activated = { data: isActivated.value };
    // 応募者ID指定する場合
    if (targetIds.value.length > 0) {
      searchBody.target_ids = cloneDeep(targetIds.value);
    }
    // 応募者・LINE友達全て対象にする場合
    if (options.isAllApplicants === true) searchFlags.is_all_applicants = true;
    // LINE登録済み応募者のみ対象にする場合
    if (options.isOnlyApplicantsOfLine === true) {
      if (isActivated.value === true) {
        searchFlags.is_only_applicants_of_line = true;
        searchFlags.is_only_line_friends = false;
      } else {
        searchFlags.is_only_applicants_of_line = false;
        searchFlags.is_only_line_friends = true;
      }
    }
    const isExceptGraduated = options.isExceptGraduated === true;
    // 任意の選考IDに紐付く応募者を除外する場合
    if (options.excludeSelectionId !== null) {
      searchFlags.exclude_selection_id = options.excludeSelectionId;
    }
    const result = await applicantsService.fetchApplicantsSearch(
      graduatedYear.year,
      page,
      pageLoad.value,
      searchBody,
      searchFlags,
      search.sort,
      { sheet_id: surveySheetId.value },
      isExceptGraduated,
      searchFilterObject.data,
    );
    let parsedResult = {};
    if (result.error) {
      parsedResult = {
        success: false,
        error: {
          message:
            'アクセスが集中したため、情報を取得できませんでした。ページを再度更新してください。',
          type: false,
          nonVisible: true,
        },
      };
    } else {
      const resultApplicantIds = result.applicants.map(
        applicant => applicant.id,
      );
      parsedResult = {
        success: true,
        data: {
          applicants: result.applicants,
          resApplicantIds: resultApplicantIds,
          page: result.page,
          count: result.count,
          pageCount: result.pageCount,
          surveyTitles: result.sheet_title,
        },
      };
      if (options.isRequiredApplicantTags === true) {
        // ApplicantTagsの更新
        await fetchApplicantTags(resultApplicantIds, flagReload);
      }
    }
    setApplicants(parsedResult);
  };
  /**
   * ApplicantTagsを取得して更新
   */
  const fetchApplicantTags = async (resultApplicantIds, flagReload = false) => {
    if (
      resultApplicantIds.toString() !==
        storedResApplicantIds.value.toString() ||
      flagReload === true
    ) {
      storedResApplicantIds.value = cloneDeep(resultApplicantIds);
      const resFetchApplicantsTags =
        await applicantService.fetchApplicantSearchTags(
          resultApplicantIds,
          tagGroups.value.map(g => g.id),
        );
      // applicantsTagsの更新
      Object.keys(resFetchApplicantsTags.applicantsTags).forEach(key => {
        applicantsTags[key] = cloneDeep(
          resFetchApplicantsTags.applicantsTags[key],
        );
      });
      // highlightApplicantIdsの更新
      highlightApplicantIds.value = Object.keys(applicantsTags)
        .map(applicantId => {
          if (
            Object.keys(applicantsTags[applicantId]).filter(tagGroupId => {
              return resFetchApplicantsTags.highlightTagGroupIds.includes(
                parseInt(tagGroupId, 10),
              );
            }).length > 0
          ) {
            return parseInt(applicantId, 10);
          } else {
            return null;
          }
        })
        .filter(x => x !== null);
    }
  };
  /**
   * 応募者検索モーダル表示
   */
  const openFloatApplicantsForm = () => {
    if (searchFormDetailSwitchIsVisible.value) {
      searchFormIsVisible.value = false;
      searchFormDetailIsVisible.value = true;
    } else {
      searchFormIsVisible.value = true;
      searchFormDetailIsVisible.value = false;
    }
  };
  /**
   * 応募者検索モーダルの詳細画面切り替え
   * @param {boolean} bool 切り替えフラグ
   */
  const searchFormDetailSwitch = bool => {
    searchFormDetailSwitchIsVisible.value = bool;
    searchFormIsVisible.value = !bool;
    searchFormDetailIsVisible.value = bool;
  };
  /**
   * 応募者一覧画面から応募者詳細検索モーダルを表示せずに検索する
   * @param {boolean} bool 切り替えフラグ
   */
  const searchDirectPresetSwitch = bool => {
    searchFormDetailSwitchIsVisible.value = bool;
    searchFormIsVisible.value = !bool;
  };
  /**
   * イベント取得
   */
  const fetchEvents = async () => {
    events.value = await eventsService.fetchEvents(
      store.getters['graduateds/selectedGraduatedId'],
    );
  };
  /**
   * スタッフ取得
   */
  const fetchStaffs = async excludeDeletedStaffs => {
    staffs.value = await staffsService.fetchStaffs(excludeDeletedStaffs);
  };
  /**
   * フラググループ取得
   * @param {array|null} staffSettingApplicantActivatedFlagGroupDisplay 表示フラググループのスタッフ設定
   */
  const fetchTagGroups = async (
    staffSettingApplicantActivatedFlagGroupDisplay = null,
  ) => {
    // フラググループの読み込み
    const newTagGroups = await tagsService.fetchTagGroups();
    tagGroups.value = newTagGroups.map(group => {
      const newTagGroup = {
        id: group.id,
        name: group.name,
        tags: group.tags,
        isAdditional: true,
        selected: true,
      };
      if (staffSettingApplicantActivatedFlagGroupDisplay !== null) {
        // スタッフ設定を適用する場合
        const settingTagGroup =
          staffSettingApplicantActivatedFlagGroupDisplay.find(
            set => set.id === group.id,
          );
        if (settingTagGroup) {
          newTagGroup.isAdditional = settingTagGroup.isAdditional;
          newTagGroup.selected = settingTagGroup.selected;
        }
      }
      return newTagGroup;
    });
  };
  /**
   * Googleフォーム取得
   */
  const fetchSurveyList = async () => {
    const surveyLists = await surveyService.getSurveyList(
      store.getters['graduateds/selectedGraduatedId'],
    );
    const surveys = surveyLists.sheet_data.map(list => ({
      id: list.id,
      name: list.sheet_name,
      selected: false,
    }));
    surveys.unshift({ id: 0, name: 'フラグモード', selected: true });
    surveyList.value = surveys;
  };
  /**
   * 応募者一覧ヘッダーへのフラググループorGoogleフォームタイトルの追加
   */
  const setHeaderAdditional = () => {
    let viewFlag = true;
    if (isFlagMode.value === true) {
      // 応募者の場合フラグを表示する
      headerAdditional.value = cloneDeep(tagGroups.value)
        .filter(item => item.selected === viewFlag)
        .map(item => ({
          name: item.id,
          name_jp: item.name,
          isAdditional: item.isAdditional,
        }));
    } else {
      headerAdditional.value = cloneDeep(surveyTitles.value);
    }
  };
  /**
   * 表示する応募者の件数・表示項目の取得
   */
  const setApplicantColumns = () => {
    let tmpApplicantListColumns = definesApplicantListColumns
      .map(col => ({
        id: col.name,
        name: col.name_jp,
        selected: true,
        activated: col.activated,
        notActivated: col.notActivated,
      }))
      .filter(item => item.id !== 'isSelect');
    // スタッフ設定を読み込む場合
    if (options.setStaffSetting) {
      const result = options.setStaffSetting(tmpApplicantListColumns);
      tmpApplicantListColumns = result.applicantListColumns;
      pageLoad.value = result.pageLoad;
    }
    applicantListColumns.value = cloneDeep(tmpApplicantListColumns);
  };
  /**
   * 応募者一覧で表示するカラムの取得
   */
  const setViewApplicantListColumns = () => {
    const tmpViewApplicantListColumns = applicantListColumns.value.filter(
      col => {
        const disabled = isActivated.value
          ? col.activated.disabled === true
          : col.notActivated.disabled === true;
        return !disabled;
      },
    );
    viewApplicantListColumns.value = cloneDeep(tmpViewApplicantListColumns);
  };
  /**
   * 応募者・LINE友達切り替え
   * @param {boolean} tmpIsActivated 切り替えフラグ
   */
  const switchActivated = async tmpIsActivated => {
    isShowSelectableApplicantTable.value = false;
    const graduatedYear =
      await store.getters['graduateds/selectedGraduatedYear'];
    await store.dispatch(
      'applicant/FETCH_UNREAD_APPLICANT_COUNT',
      graduatedYear.year,
    );
    await store.dispatch(
      'applicant/FETCH_UNREAD_LINE_COUNT',
      graduatedYear.year,
    );
    isActivated.value = tmpIsActivated;
    await setApplicantColumns();
    await setViewApplicantListColumns();
    setHeaderAdditional();
    await movePage(1);
    isShowSelectableApplicantTable.value = true;
  };
  const validateSelectedApplicants = () => {
    if (selectedApplicants.value.length === 0) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '応募者を選択してください。',
        type: false,
      });
      return false;
    } else if (selectedApplicants.value.length > 200) {
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '200名以上の応募者は選択できません',
        type: false,
      });
      return false;
    }
    return true;
  };

  /**
   * 画面サイズによって応募者一覧のカラム数を変更
   */
  const onResize = () => {
    // 画面サイズによって応募者一覧のカラム数を変更
    if (timeoutId.value) return;
    timeoutId.value = setTimeout(() => {
      timeoutId.value = 0;
      setApplicantColumns();
      if (options.setStaffSetting) {
        // スタッフ設定を読み込む場合
        invisibleColumns.value = JSON.parse(
          JSON.stringify(invisibleColumnsStaffSetting.value),
        );
      } else {
        // スタッフ設定を読み込まない場合
        if (options.invisibleColumnsPc && options.invisibleColumnsSp) {
          invisibleColumns.value =
            window.innerWidth <= Breakpoints.MAX_SP_SIZE
              ? JSON.parse(JSON.stringify(options.invisibleColumnsSp))
              : JSON.parse(JSON.stringify(options.invisibleColumnsPc));
        } else {
          // 非表示カラムのdefault設定
          if (window.innerWidth <= Breakpoints.MAX_SP_SIZE) {
            invisibleColumns.value = [
              'faculty',
              'department',
              'department_category',
              'email',
              'tel',
              'channel',
              'attendanceDate',
              'invited_status',
              'created_at',
              'activated_at',
              'invited_at',
              'name_kana',
              'gender',
              'school',
              'staff',
              'event_title',
              'not_announe_lamp',
            ];
          } else {
            invisibleColumns.value = [
              'faculty',
              'department',
              'department_category',
              'email',
              'tel',
              'channel',
              'attendanceDate',
              'invited_status',
              'created_at',
              'activated_at',
              'invited_at',
              'unread_count',
            ];
          }
        }
      }
    }, 500);
  };
  /**
   * 表示する応募者の件数選択時イベント
   * @param {{ item: { id: number }}} payload 選択したデータ
   */
  const onSelectedPageLoad = async payload => {
    loadSelection.value = loadSelection.value.map(load => {
      const newLoadSelection = cloneDeep(load);
      newLoadSelection.selected = load.id === payload.item.id;
      return newLoadSelection;
    });
    isOpenPagerLoad.value = false;
    isShowSelectableApplicantTable.value = false;
    const selectedLoad = loadSelection.value.find(
      load => load.selected === true,
    );
    pageLoad.value = selectedLoad.id;
    await movePage(1);
  };
  /**
   * 応募者一覧並び替え
   * @param {Object} payload ソートデータ
   */
  const onChangeSort = async payload => {
    if (payload !== undefined) {
      isShowSelectableApplicantTableSort.value = false;
      updateSearch({ sort: payload });
      payload.sort_by === 'unread_count' // 「未読数で並べ替える」ボタンのためのステータス
        ? (isSortedApplicantsByUnreadCountCheck.value = true)
        : (isSortedApplicantsByUnreadCountCheck.value = false);
      await fetchApplicants(page.value);
      isShowSelectableApplicantTableSort.value = true;
    }
  };
  /**
   * 表示フラググループ選択時イベント
   * @param {{ item: { id: number, selected: boolean }}} payload 選択したデータ
   */
  const onSelectedColumnFlagSelection = async payload => {
    tagGroups.value = tagGroups.value.map(tagGroup => {
      if (tagGroup.id === payload.item.id) {
        tagGroup.selected = !payload.item.selected;
      }
      return cloneDeep(tagGroup);
    });
    await staffsService.flagGroupSetting(
      'applicant_activated',
      tagGroups.value,
    );
    setHeaderAdditional();
  };
  /**
   * searchの更新
   * @param {Object} searchObject 更新するデータ
   */
  const updateSearch = searchObject => {
    Object.keys(searchObject).forEach(key => {
      if (key in search) search[key] = cloneDeep(searchObject[key]);
    });
  };
  /**
   * pageの更新
   * @param {number} value 更新するデータ
   */
  const updatePage = value => {
    page.value = value;
  };
  /**
   * pageCountの更新
   * @param {number} value 更新するデータ
   */
  const updatePageCount = value => {
    pageCount.value = value;
  };
  /**
   * countの更新
   * @param {number} value 更新するデータ
   */
  const updateCount = value => {
    count.value = value;
  };
  const updatePageLoad = value => {
    pageLoad.value = value;
  };
  /**
   * searchFormDetailSwitchIsVisibleの更新
   * @param {boolean} value 更新するデータ
   */
  const updateSearchFormDetailSwitch = value => {
    searchFormDetailSwitchIsVisible.value = value;
  };
  /**
   * selectedApplicantsの更新
   * @param {[Object]} value 更新するデータ
   */
  const updateSelectedApplicants = async payload => {
    // 応募者選択時イベント
    if (payload) {
      selectedApplicants.value = cloneDeep(payload.selectedApplicants);
    }
  };
  /**
   * isShowSelectableApplicantTableの更新
   * @param {boolean} value 更新するデータ
   */
  const updateIsShowSelectableApplicantTable = value => {
    isShowSelectableApplicantTable.value = value;
  };
  /**
   * isOpenPagerLoadの更新
   * @param {boolean} value 更新するデータ
   */
  const updateIsOpenPagerLoad = value => {
    isOpenPagerLoad.value = value;
  };
  /**
   * isOpenSurveyMenuの更新
   * @param {boolean} value 更新するデータ
   */
  const updateIsOpenSurveyMenu = value => {
    isOpenSurveyMenu.value = value;
  };
  /**
   * searchFormIsVisibleの更新
   * @param {boolean} value 更新するデータ
   */
  const updateSearchFormIsVisible = value => {
    searchFormIsVisible.value = value;
  };
  /**
   * searchFormDetailIsVisibleの更新
   * @param {boolean} value 更新するデータ
   */
  const updateSearchFormDetailIsVisible = value => {
    searchFormDetailIsVisible.value = value;
  };
  /**
   * surveyListの更新
   * @param {[Object]} value 更新するデータ
   */
  const updateSurveyList = value => {
    surveyList.value = cloneDeep(value);
  };
  /**
   * applicantListColumnsの更新
   * @param {[Object]} value 更新するデータ
   */
  const updateApplicantListColumns = value => {
    applicantListColumns.value = cloneDeep(value);
  };
  /**
   * isFlagModeの更新
   * @param {boolean} value 更新するデータ
   */
  const updateIsFlagMode = value => {
    isFlagMode.value = value;
  };
  /**
   * surveySheetIdの更新
   * @param {number} value 更新するデータ
   */
  const updateSurveySheetId = value => {
    surveySheetId.value = value;
  };
  /**
   * targetIdsの更新
   * @param {[string]} value 更新するデータ
   */
  const updateTargetIds = value => {
    targetIds.value = cloneDeep(value);
  };
  /**
   * loadSelectionの更新
   * @param {[string]} value 更新するデータ
   */
  const updateLoadSelection = value => {
    loadSelection.value = cloneDeep(value);
  };
  /**
   * surveyTitlesの更新
   * @param {[string]} value 更新するデータ
   */
  const updateSurveyTitles = value => {
    surveyTitles.value = value;
  };
  /**
   * フラグモード・Googleフォーム切り替え処理
   * @param {number} id 選択したデータ
   */
  const changeFlagMode = async id => {
    updateSurveyList(
      surveyList.value.map(survey => ({
        ...survey,
        selected: survey.id === id,
      })),
    );
    updateIsShowSelectableApplicantTable(false);
    if (id === 0) {
      // フラグモード時
      updateIsFlagMode(true);
      updateSurveySheetId(null);
    } else {
      // Googleフォームモード時
      updateIsFlagMode(false);
      updateSurveySheetId(id);
    }
  };

  return {
    search: toRefs(search),
    searchFilterObject,
    page,
    count,
    pageCount,
    pageLoad,
    events,
    staffs,
    applicants,
    resApplicantIds,
    applicantsTags,
    highlightApplicantIds,
    applicantListColumns,
    viewApplicantListColumns,
    selectedApplicants,
    surveyList,
    surveyTitles,
    targetIds,
    tagGroups,
    surveySheetId,
    invisibleColumns,
    loadSelection,
    searchFormIsVisible,
    searchFormDetailIsVisible,
    searchFormDetailSwitchIsVisible,
    isFlagMode,
    isShowSelectableApplicantTable,
    isShowSelectableApplicantTableSort,
    isOpenPagerLoad,
    isOpenSurveyMenu,
    isOpenSearchApplicantFilters,
    isSortedApplicantsByUnreadCountCheck,
    // computed
    invisibleColumnsStaffSetting,
    isActivated,
    surveySetting,
    getSelectedApplicants,
    getSelectedApplicantsIds,
    headerAdditional,
    // methods
    onSearch,
    onSearchReset,
    movePage,
    movePager,
    fetchApplicants,
    fetchEvents,
    fetchStaffs,
    fetchSurveyList,
    fetchTagGroups,
    openFloatApplicantsForm,
    searchFormDetailSwitch,
    searchDirectPresetSwitch,
    setApplicantColumns,
    setViewApplicantListColumns,
    setHeaderAdditional,
    resetState,
    switchActivated,
    validateSelectedApplicants,
    onChangeSort,
    onSelectedPageLoad,
    onSelectedColumnFlagSelection,
    onResize,
    updateSearch,
    updatePage,
    updateCount,
    updatePageCount,
    updatePageLoad,
    updateSearchFormDetailSwitch,
    updateSelectedApplicants,
    updateIsShowSelectableApplicantTable,
    updateIsOpenPagerLoad,
    updateIsOpenSurveyMenu,
    updateSearchFormIsVisible,
    updateSearchFormDetailIsVisible,
    updateSurveyList,
    updateApplicantListColumns,
    updateIsFlagMode,
    updateSurveySheetId,
    updateTargetIds,
    updateLoadSelection,
    updateSurveyTitles,
    changeFlagMode,
  };
}
