import cloneDeep from 'lodash/cloneDeep';

import { snakeToCamelCaseForObject } from '@/utils/change-case';

const applicantSearchBodyDefault = {
  appId: null,
  eventId: null,
  staffId: null,
  channelId: null,
  genderId: null,
  contactTypeId: null,
  progressStatusId: null,
  attendanceId: null,
  name: '',
  nameKana: '',
  email: '',
  tel: '',
  address: '',
  school: '',
  faculty: '',
  department: '',
  departmentCategoryId: null,
  lineBlock: null,
  attendanceDate: null,
  reminderCheck: null,
  createdAt: null,
  activatedAt: null,
  progressStatusIds: [],
  flagGroups: [],
  surveyLists: [],
  // finishedStatusId: null,
  // attendanceAt: null,
  // targetIds: [],
  // tags: [],
};
const applicantSearchFlagsDefault = { orFlag: false };
const applicantSearchSurvey = { sheetId: null };

/**
 * ApplicantSearchBodyのデフォルトObjectの取得
 * @returns {Object}
 */
const getInitSearchBody = () => cloneDeep(applicantSearchBodyDefault);

const getInitSearchBodyForRequest = () =>
  snakeToCamelCaseForObject(cloneDeep(applicantSearchBodyDefault));

/**
 * ApplicantSearchFlagのデフォルトObjectの取得
 * @returns {Object}
 */
const getInitSearchFlagForRequest = () =>
  snakeToCamelCaseForObject(cloneDeep(applicantSearchFlagsDefault));

/**
 * ApplicantSearchSurveyのデフォルトObjectの取得
 * @returns {Object}
 */
const getInitSearchSurveyForRequest = () =>
  snakeToCamelCaseForObject(cloneDeep(applicantSearchSurvey));

export {
  getInitSearchBody,
  getInitSearchBodyForRequest,
  getInitSearchFlagForRequest,
  getInitSearchSurveyForRequest,
};
