import TimeTable from '@/models/timetable';

const Venue = function _Venue(
  id = null,
  name = '',
  roomName = '',
  pref = '',
  address = '',
  date,
  limit = 0,
  limitDate,
  actual = 0,
  notice = '',
  active = true,
  timetables = [],
) {
  const venue = Object.create(Venue.prototype);
  venue.id = id;
  venue.name = name;
  venue.roomName = roomName;
  venue.pref = pref;
  venue.address = address;
  venue.date = date;
  venue.limit = limit;
  venue.limitDate = limitDate;
  venue.actual = actual;
  venue.notice = notice;
  venue.active = active;
  venue.timetables = timetables ? timetables.map(TimeTable.createFromJSON) : [];

  return venue;
};

Venue.prototype = {
  async copy() {
    const newTimetables = this.timetables.map(tt => {
      const newData = Object.assign({}, tt, {
        id: null,
        actual: 0,
        applicantNames: [],
      });
      return newData;
    });
    const newVenue = Object.assign({}, this, {
      id: null,
      actual: 0,
      timetables: newTimetables,
    });
    return Venue.createFromJSON(newVenue);
  },
  isDeletable() {
    if (!this.timetables || this.timetables.length === 0) {
      return true;
    }
    return !this.timetables.some(
      tt => tt.applicantNames !== undefined && tt.applicantNames.length >= 1,
    );
  },
  addTimeTable(args = {}) {
    if (Object.keys(args).length > 0) {
      // 新規作成の場合のみisCreateGoogleCalendarはtrueにする
      this.timetables.push(
        new TimeTable(
          null,
          null,
          null,
          0,
          0,
          false,
          '',
          [],
          [],
          [],
          '',
          false,
          [],
          args.isCreateGoogleCalendar,
        ),
      );
    } else {
      this.timetables.push(new TimeTable());
    }
  },
  /**
   * JSON形式Object
   * @returns {{name: *, room_name: *, address: *, pref: *, date: *, notice: *}}
   */
  jsonObjCreateVenue(
    selectedStaffIds = [],
    selectedResourceIds = [],
    zoomHostIds = [],
  ) {
    let obj = {
      name: this.name,
      room_name: this.roomName,
      address: this.address,
      pref: this.pref,
      date: this.date,
      notice: this.notice,
      active: this.active,
      timetables: this.timetables.map((timetable, i) =>
        timetable.jsonObjCreateTimetable(
          selectedStaffIds[i] ? selectedStaffIds[i] : [],
          selectedResourceIds[i] ? selectedResourceIds[i] : [],
          zoomHostIds[i] ? zoomHostIds[i] : null,
        ),
      ),
      limit_date: this.limitDate,
    };
    if (this.id) obj = Object.assign({}, obj, { id: this.id });
    return obj;
  },
  jsonObjExceptTimetables() {
    const obj = this.jsonObjCreateVenue();
    delete obj.timetables;
    return obj;
  },
};

Venue.createFromJSON = json => {
  if (json) {
    return Venue(
      json.id,
      json.name,
      json.room_name || json.roomName,
      json.pref,
      json.address,
      json.date,
      json.limit,
      json.limit_date || json.limitDate,
      json.actual,
      json.notice,
      json.active,
      json.timetables || [],
    );
  }
  return Venue();
};

export default Venue;
