<template>
  <div>
    <div class="ui-select" :class="{ 'is-disabled': disabled }" @click="onOpen">
      <div class="button_content">
        <div v-if="selectedId === null" class="placeholder">
          {{ placeholder }}
        </div>
        <div v-else class="wrap_selected_content">
          <span class="selected_content">{{ selectedText }}</span>
          <span class="selected_content_suffix">{{ selectedTextSuffix }}</span>
        </div>
        <img
          v-if="!disabled"
          class="arrow_icon"
          src="@/assets/img/arrow_down_blue.svg"
          alt
        />
      </div>
    </div>
    <float-context-menu
      :list="list"
      :is-view="isView"
      @onClose="$emit('onClose', $event)"
      @onSelected="$emit('onSelected', $event)"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';

export default defineComponent({
  name: 'DropDown',
  components: { FloatContextMenu },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    selectedId: {
      type: Number,
      default: null,
    },
    list: {
      type: Array,
      default: () => [],
    },
    isView: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onOpen', 'onClose', 'onSelected'],
  setup(props, context) {
    const selectedText = computed(() => {
      const filterItems = props.list.filter(v => props.selectedId === v.id);
      return filterItems && filterItems.length >= 1 ? filterItems[0].name : '';
    });
    const selectedTextSuffix = computed(() => {
      const filterItems = props.list.filter(v => props.selectedId === v.id);
      return filterItems && filterItems.length >= 1 && filterItems[0].suffix
        ? filterItems[0].suffix
        : '';
    });
    const onOpen = () => {
      if (props.list.length === 0 || props.disabled === true) return;
      context.emit('onOpen');
    };
    return { selectedText, selectedTextSuffix, onOpen };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.placeholder {
  color: #aaa;
}
.ui-select {
  border: solid 1px #adadad;
  border-radius: 4px;
  cursor: pointer;

  &.is-disabled {
    opacity: 0.6;
    background-color: #ededed;
  }
}
.button_content {
  padding: 10px;
}
.arrow_icon {
  float: right;
  top: 50%;
  right: 10px;
  width: 10px;
  margin-top: -10px;
}
.wrap_selected_content {
  width: 95%;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
}
.selected_content_suffix {
  color: $mochica_color_gray;
  font-size: 12px;
}
.selected_content {
  word-break: break-word;
}
</style>
