<template>
  <div>
    <search-applicant-preset-list
      v-if="pageMode === 'presetList'"
      :staffs="staffs"
      :events="events"
      :tag-groups="tagGroups"
      :survey-titles="surveyTitles"
      @close="$emit('openSearchFilter', $event)"
      @loadPreset="$emit('loadPreset', $event)"
      @openPresetEditModal="$emit('openPresetEditModal', $event)"
      @openPresetDeleteDialogModal="
        $emit('openPresetDeleteDialogModal', $event)
      "
      @openPresetEditDialogModal="$emit('openPresetEditDialogModal', $event)"
    />
    <search-applicant-preset-edit
      v-else-if="pageMode === 'presetEdit'"
      :preset-id="editPresetId"
      :preset-name="editPresetName"
      :preset-search-filter-object="editPresetSearchFilterObject"
      :staffs="staffs"
      :events="events"
      :tag-groups="tagGroups"
      :survey-titles="surveyTitles"
      :current-preset-id="currentPresetId"
      @close="$emit('openPresetListModal', $event)"
      @fetchSearchFilterPresets="$emit('fetchSearchFilterPresets', $event)"
      @updateCurrentPreset="$emit('updateCurrentPreset', $event)"
    />
    <modal-dialog
      v-else-if="pageMode === 'presetDeleteDialog'"
      :is-view="true"
      :accent-color="'#C31E1E'"
      :title="'検索条件を削除します'"
      :message="`この操作は取り消すことができません \n 検索条件『${editPresetName}』を削除します`"
      :button-text1="'キャンセル'"
      :button-text2="'削除'"
      :alert-flag="true"
      @onSelected="deletePreset"
      @onclickOuter="$emit('close', $event)"
    />
    <modal-dialog
      v-else-if="pageMode === 'presetEditDialog'"
      :is-view="true"
      title="検索条件を上書きします"
      :message="`この操作は取り消すことができません \n 検索条件『${editPresetName}』を上書きします`"
      button-text1="キャンセル"
      button-text2="上書き"
      @onSelected="updatePreset"
      @onclickOuter="$emit('close', $event)"
    />
    <modal-dialog
      v-else-if="pageMode === 'switchSearchFilter'"
      :is-view="true"
      title="通常検索・詳細検索の切り替え"
      message="通常検索・詳細検索を切り替えると現在の検索条件がリセットされます。"
      button-text1="キャンセル"
      button-text2="切り替え"
      @onSelected="$emit('switchSearchFilter', $event)"
      @onclickOuter="$emit('close', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

import SearchFilterPresetsService from '@/services/search_filter_presets';
import SearchApplicantPresetList from '@/components/features/searchApplicants/components/SearchApplicantPresetList';
import SearchApplicantPresetEdit from '@/components/features/searchApplicants/components/SearchApplicantPresetEdit';
import ModalDialog from '@/components/ui/modals/components/ModalDialog';

export default defineComponent({
  name: 'SearchApplicantPreset',
  components: {
    SearchApplicantPresetList,
    SearchApplicantPresetEdit,
    ModalDialog,
  },
  props: {
    pageMode: {
      type: String,
      required: true,
    },
    editPresetId: {
      type: Number,
      default: null,
    },
    editPresetName: {
      type: String,
      default: '',
    },
    editPresetSearchFilterObject: {
      type: Object,
      default: () => {},
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    tagGroups: {
      type: Array,
      default: () => [],
    },
    surveySheetId: {
      type: Number,
      default: null,
    },
    surveyTitles: {
      type: Array,
      default: () => [],
    },
    orFlag: {
      type: Boolean,
      default: false,
    },
    searchDetailFilters: {
      type: Array,
      default: () => [],
    },
    searchFlagGroups: {
      type: Array,
      required: true,
    },
    searchFlagGroupOptions: {
      type: Array,
      default: () => [],
    },
    searchSurveys: {
      type: Array,
      default: () => [],
    },
    searchSurveyTexts: {
      type: Array,
      default: () => [],
    },
    currentPresetId: {
      type: Number,
      default: null,
    },
    parseToSearchFilterObject: {
      type: Function,
      default: null,
    },
  },
  emits: [
    'openSearchFilter',
    'loadPreset',
    'openPresetEditModal',
    'openPresetDeleteDialogModal',
    'openPresetEditDialogModal',
    'openPresetListModal',
    'fetchSearchFilterPresets',
    'updateCurrentPreset',
    'switchSearchFilter',
  ],
  setup(props, context) {
    const store = useStore();
    const updatePreset = async payload => {
      if (payload.choice === 1) {
        // キャンセルの場合
      } else {
        // プリセット検索条件上書き
        const res = await SearchFilterPresetsService.updateSearchFilterPresets(
          props.editPresetId,
          {
            search_filter_obj: {
              or_flag: props.orFlag,
              search_detail_filters:
                props.parseToSearchFilterObject === null
                  ? props.searchDetailFilters
                  : props.parseToSearchFilterObject(),
              search_flag_groups: props.searchFlagGroups,
              search_flag_group_options: props.searchFlagGroupOptions,
              search_surveys: props.searchSurveys,
              search_survey_texts: props.searchSurveyTexts,
              sheet_id: props.surveySheetId,
            },
          },
        );
        if (res.success === true) {
          context.emit('fetchSearchFilterPresets');
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
        }
      }
      context.emit('openPresetListModal');
    };
    const deletePreset = async payload => {
      if (payload.choice === 1) {
        // キャンセルの場合
      } else {
        // 削除実行
        const res = await SearchFilterPresetsService.deleteSearchFilterPresets(
          props.editPresetId,
        );
        if (res.success === true) {
          context.emit('fetchSearchFilterPresets');
        } else {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.message,
            type: false,
          });
          return;
        }
      }
      // 選択中のプリセットが削除された場合
      if (props.currentPresetId === props.editPresetId) {
        context.emit('updateCurrentPreset', {});
      }
      context.emit('openPresetListModal');
    };

    return {
      updatePreset,
      deletePreset,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
</style>
