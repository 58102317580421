<template>
  <modal :is-view="isView">
    <template #rightTop>
      <div class="right-top" @click="$emit('onClickCancel')">
        <img class="icon" src="@/assets/img/close_white.svg" alt />
      </div>
    </template>
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">担当者の一括割当て</p>
        </div>

        <transition name="fade">
          <div
            class="modal-window-inner"
            @click="isOpenMultiStaffSelector = false"
          >
            <div class="message_area">
              <p>
                全ての開催時間に対して、一括で担当者を割当てることができます。
              </p>
            </div>
            <dl class="select_staff_area">
              <dt>
                <b>選考の担当者</b>
                （最大{{ limitCount }}人まで）
              </dt>
              <dd @click.stop>
                <div
                  class="multiple_selector"
                  @click="isOpenMultiStaffSelector = true"
                >
                  {{ selectedStaffs.map(x => x.name).join(', ') }}
                </div>
                <float-context-menu
                  :is-view="isOpenMultiStaffSelector"
                  :list="staffs"
                  :is-multiple-select="true"
                  @onClose="isOpenMultiStaffSelector = false"
                  @onSelected="onSelectedStaffIds($event)"
                />
              </dd>
            </dl>
            <div class="btn-full_width">
              <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
                キャンセル
              </div>
              <button-base
                class="btn"
                button-text="担当者を割当て"
                :rejected-role-ids="[3]"
                :rejected-admin-ids="[2]"
                @click="onClickToConfirm"
              />
            </div>
          </div>
        </transition>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import Modal from '@/components/ui/modals/components/Modal';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';

export default defineComponent({
  name: 'ModalBulkAssignStaff',
  components: { Modal, FloatContextMenu },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    selectedStaffs: {
      type: Array,
      default: () => [],
    },
    limitCount: {
      type: Number,
      default: 3,
    },
    validateStaffs: {
      type: Function,
      required: true,
    },
  },
  emits: ['onClickCancel', 'setStaffs', 'selectStaff'],
  setup(props, context) {
    const store = useStore();
    const isOpenMultiStaffSelector = ref(false);

    // methods
    const onClickToConfirm = async () => {
      if (props.selectedStaffs.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '担当者が選択されていません',
          type: false,
        });
        return;
      }
      context.emit('setStaffs', props.selectedStaffs.length);
    };
    const onSelectedStaffIds = event => {
      if (event.item.selected === false) {
        const resultValidateStaffs = props.validateStaffs(
          props.selectedStaffs.length,
        );
        if (resultValidateStaffs.success !== true) return;
      }
      context.emit('selectStaff', event.item.id);
    };

    return { isOpenMultiStaffSelector, onClickToConfirm, onSelectedStaffIds };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  max-width: 640px;
}

.message_area {
  margin: 0;
  padding-bottom: 25px;
  margin: 30px 40px 25px;
  font-size: 16px;
  line-height: 175%;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 10%);
  > p {
    margin-bottom: 6px;
  }
}

.select_staff_area {
  padding: 0 40px;
  margin-bottom: 27px;
  text-align: left;
  > dt {
    margin-bottom: 10px;
    font-size: 14px;
    color: #777;
    > b {
      color: #333;
    }
  }
}

.multiple_selector {
  height: 44px;
  padding: 14px 25px 14px 14px;
  line-height: 1;
  border: solid 1px #adadad;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-image: url('../../../../assets/img/arrow_down_blue.svg');
  background-size: 10px auto;
  background-position: right 10px center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.btn-full_width {
  display: flex;
  width: 100%;
  padding: 0 40px 50px;
  > button {
    margin-left: 10px;
  }
  > div,
  button {
    width: 275px;
    height: 50px;
    margin-bottom: 10px;
    padding: 18px 10px;
    line-height: 1;
  }
}

@media (max-width: ($media_query_sp)) {
  .message_area {
    padding-bottom: 10px;
    margin: 20px 15px 20px;
  }
  .select_staff_area {
    padding: 0 15px;
  }
  .btn-full_width {
    padding: 0 15px 10px;
  }
}
</style>
