import cloneDeep from 'lodash.clonedeep';
import { reactive, toRefs } from 'vue';

import { camelToSnakeCase } from '@/utils/change-case';
import { getInitSearchBody } from '@/models/applicant-search';
import applicantSearchDetailFiltersDefault from '@/components/features/searchApplicants/defines/applicant-search-detail-filters';

export default function useSearchFilters() {
  /**
   * 選択したフィルターリスト
   * [{
   *   name: String,
   *   nameKana: String,
   *   school: String,
   *   faculty: String,
   *   department: String,
   *   address: String,
   *   tel: String,
   *   email: String,
   *   appId: ?Number,
   *   eventId: ?Number,
   *   progressStatusIds: [Number],
   *   genderId: ?Number,
   *   departmentCategoryId: ?Number,
   *   attendanceId: ?Number,
   *   staffId: ?Number,
   *   channelId: ?Number,
   *   contactTypeId: ?Number,
   *   lineBlock: ?Boolean,
   *   reminderCheck: ?Boolean,
   *   attendanceDate: ?Boolean,
   *   activatedAt: ?Boolean,
   *   createdAt: ?Boolean,
   *   flagGroups: [{ id: Number, flags: [Number] }],
   *   surveyLists: [{ id: Number, text: String }],
   * }]
   */
  const searchFilters = reactive(getInitSearchBody());

  // methods
  /**
   * searchFiltersのリセット
   */
  const resetSearchFilters = () => {
    for (const [key, value] of Object.entries(getInitSearchBody())) {
      searchFilters[key] = cloneDeep(value);
    }
  };
  /**
   * searchFiltersを更新
   * @param {String} key
   * @param {String} value
   */
  const setSearchFilters = (key, value) => {
    searchFilters[key] = value;
  };
  /**
   * progressStatusIdsを更新
   * @param {String} value
   */
  const setProgressStatusIds = value => {
    if (searchFilters.progressStatusIds.includes(value)) {
      // 同じ項目選択時は選択解除
      searchFilters.progressStatusIds = searchFilters.progressStatusIds.filter(
        id => id !== value,
      );
    } else {
      searchFilters.progressStatusIds.push(value);
    }
  };
  /**
   * ObjectをAPIリクエストのフォーマットに整形
   * @param {Object} obj - オブジェクト
   * @returns {Object} オブジェクト
   */
  const parseToRequest = obj => {
    const result = {};
    for (const [key, value] of Object.entries(obj)) {
      // keyがapplicantSearchBodyDefaultに存在する場合のみリクエストに含める
      if (Object.keys(getInitSearchBody()).includes(key)) {
        let newKey = camelToSnakeCase(key);
        if (Array.isArray(value)) {
          // 配列の場合、1以上要素が存在する場合はリクエストに含める
          if (key === 'flagGroups') {
            // アンケート検索の場合tagsに変換して整形
            const tags = value
              .filter(x => x.flags.length > 0)
              .map(x => {
                return {
                  tagGroupId: x.id,
                  tagIds: x.flags,
                  eq: true,
                  exist: null,
                };
              });
            if (tags.length > 0) result['tags'] = tags;
          } else if (key === 'surveyLists') {
            // Googleフォーム検索の場合
            const surveyLists = value
              .map(x => ({ name: x.name, text: x.text }))
              .filter(x => x.text && x.text !== '');
            if (surveyLists.length > 0) result[newKey] = surveyLists;
          } else if (value.length > 0) {
            result[newKey] = [...value];
          }
        } else if (value !== null && value !== undefined && value !== '') {
          // 配列以外の場合、null, undefined, 空文字列以外はリクエストに含める
          result[newKey] = value;
        }
      }
    }
    return result;
  };
  /**
   * SearchのObjectをAPIリクエストのフォーマットに整形
   * @param {Object} obj - オブジェクト
   * @returns {Object} オブジェクト
   */
  const getSearchRequest = obj => {
    const searchBody = parseToRequest(obj);
    for (const [key, value] of Object.entries(searchBody)) {
      if (key === 'target_ids' || key === 'tags') {
        searchBody[key] = value;
      } else {
        searchBody[key] = {
          data: value,
          eq: true,
        };
      }
    }
    return searchBody;
  };
  /**
   * searchBody作成
   * @param {[{ id: ?Number, flags: [Number] }]} searchFlagGroups
   * @param {[{ id: ? Number, text: [String] }]} searchSurveys
   * @param {[String]} searchSurveyTexts
   * @return {Object} searchBody
   */
  const createSearchBody = (
    searchFlagGroups,
    searchSurveys,
    searchSurveyTexts,
  ) => {
    const searchBody = cloneDeep(searchFilters);
    if (searchFlagGroups.length > 0) {
      searchBody.flagGroups = searchFlagGroups.map(flagGroup => ({
        id: flagGroup.id,
        flags: cloneDeep(flagGroup.flags),
      }));
    } else if (searchSurveys.length > 0) {
      // surveys, surveyTexts を surveyLists へ統合
      searchBody.surveyLists = searchSurveys
        .filter(survey => survey.id !== null)
        .map((survey, i) => {
          const res = { ...survey };
          res.text = searchSurveyTexts[i];
          return res;
        });
    }
    return getSearchRequest(searchBody);
  };
  /**
   * 通常検索条件を詳細検索のsearchDetailFiltersに変換
   * @return {[{
   *   id: ?number,
   *   name: String,
   *   keyName: String,
   *   data: ?String|Array,
   *   disabled: Boolean,
   *   isView: Boolean,
   *   eq: Number,
   *   exist: ?Number,
   *   selectOption: Number,
   * }]} searchDetailFilters
   */
  const parseToSearchFilterObject = () => {
    const searchDetailFilterOption = { eq: 1, exist: null, selectOption: 1 };
    const searchDetailFilters = Object.keys(searchFilters)
      .filter(key => {
        if (
          key === 'progressStatusIds' ||
          key === 'flagGroups' ||
          key === 'surveyLists'
        ) {
          // 値が配列の場合、0個の場合除外
          return searchFilters[key].length > 0;
        } else if (key === 'attendanceId') {
          // attendanceIdは除外
          return false;
        } else {
          // 文字列・数値の場合空の要素を除外
          return searchFilters[key] || searchFilters[key] === 0;
        }
      })
      .map(key => {
        const targetFilter = applicantSearchDetailFiltersDefault.find(
          filter => key === filter.keyName,
        );
        return {
          id: targetFilter.id,
          name: targetFilter.name,
          data: searchFilters[key],
          keyName: key,
          disabled: targetFilter.disabled,
          isView: false,
          ...searchDetailFilterOption,
        };
      });
    return searchDetailFilters;
  };

  return {
    searchFilters: toRefs(searchFilters),
    resetSearchFilters,
    setSearchFilters,
    setProgressStatusIds,
    createSearchBody,
    parseToSearchFilterObject,
  };
}
