<template>
  <div class="modal-window -typeB">
    <div class="header">
      <p class="title">応募者検索プリセット一覧</p>
    </div>
    <div class="body">
      <div v-if="presetList.length === 0" class="no_preset">
        <p>プリセットが登録されていません</p>
      </div>
      <ul v-else class="preset_list">
        <li
          v-for="(preset, i) in presetList"
          :key="`preset${i}`"
          :class="{ 'is-open': preset.isOpenedSearchFilter === true }"
        >
          <div class="preset_head">
            <div class="preset_title" @click="togglePreset(preset.id)">
              <img
                slot="arrow"
                class="arrow"
                src="@/assets/img/arrow_down_blue.svg"
                alt=""
              />
              <p>
                {{ preset.name }}
              </p>
            </div>
            <div class="preset_buttons">
              <button-base
                :button-text="'呼び出し'"
                @click="
                  $emit('loadPreset', {
                    searchFilterObj: preset.search_filter_obj,
                    preset,
                  })
                "
              />
              <button-base
                :button-text="'上書き'"
                :rejected-role-ids="rejectedRoleIds"
                :rejected-admin-ids="rejectedAdminIds"
                @click="
                  $emit('openPresetEditDialogModal', {
                    presetId: preset.id,
                    presetName: preset.name,
                  })
                "
              />
              <button-base
                :button-text="'名前の編集'"
                :rejected-role-ids="rejectedRoleIds"
                :rejected-admin-ids="rejectedAdminIds"
                @click="
                  $emit('openPresetEditModal', {
                    presetId: preset.id,
                    presetName: preset.name,
                    presetSearchFilterObject: preset.search_filter_obj,
                    presetSheetId: preset.sheet_id,
                  })
                "
              />
              <button-base
                :button-text="'削除'"
                :button-type="'secondary'"
                :rejected-role-ids="rejectedRoleIds"
                :rejected-admin-ids="rejectedAdminIds"
                @click="
                  $emit('openPresetDeleteDialogModal', {
                    presetId: preset.id,
                    presetName: preset.name,
                  })
                "
              />
            </div>
          </div>
          <div
            v-if="preset.isOpenedSearchFilter === true"
            class="preset_detail"
          >
            <p>
              最終更新 -
              {{ $utils.parseDateTime(preset.updated_at, 'YYYY/MM/DD HH:mm') }}
              {{ preset.staff_name }}
            </p>
            <search-filter-indicator
              :search-filter-detail="preset.search_filter_obj"
              :events="events"
              :tag-groups="tagGroups"
              :survey-titles="surveyTitles"
              :staffs="staffs"
              :has-reset="false"
              :has-border="false"
              :is-show-no-filter-message="true"
            />
          </div>
        </li>
      </ul>
      <button-base
        :button-text="'新しいプリセットとして保存'"
        :icon-file-name="'plus_circle'"
        :rejected-role-ids="rejectedRoleIds"
        :rejected-admin-ids="rejectedAdminIds"
        @click="openPresetEditModal"
      />
      <div class="ar-actionBtns">
        <button class="btn btn-unavailable" @click="$emit('close')">
          キャンセル
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import SearchFilterPresetsService from '@/services/search_filter_presets';
import SearchFilterIndicator from '@/components/features/searchApplicants/components/SearchFilterIndicator.vue';

export default defineComponent({
  name: 'SearchApplicantPresetList',
  components: { SearchFilterIndicator },
  props: {
    staffs: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    tagGroups: {
      type: Array,
      default: () => [],
    },
    surveyTitles: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'loadPreset',
    'openPresetEditModal',
    'openPresetEditDialogModal',
    'openPresetDeleteDialogModal',
    'close',
  ],
  setup(props, context) {
    const store = useStore();
    const staff = store.getters['staff/staff'];
    const presetList = ref([]);
    const MAX_PRESET_LENGTH = 10;
    const rejectedRoleIds = [3];
    const rejectedAdminIds = [2];

    // 担当者の場合は編集権限なし
    const editingPermission = computed(() => staff.role.id !== 3);

    const togglePreset = id => {
      presetList.value = presetList.value.map(preset => {
        if (preset.id === id) {
          return {
            ...preset,
            isOpenedSearchFilter: !preset.isOpenedSearchFilter,
          };
        }
        return preset;
      });
    };
    const openPresetEditModal = () => {
      if (presetList.value.length >= MAX_PRESET_LENGTH) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `プリセットは${MAX_PRESET_LENGTH}個までしか作成できません`,
          type: false,
        });
      } else {
        context.emit('openPresetEditModal', {
          presetId: null,
          presetName: '',
          presetSearchFilterObject: null,
          presetSheetId: null,
        });
      }
    };
    const fetchSearchFilterPresets = async () => {
      const res = await SearchFilterPresetsService.fetchSearchFilterPresets();
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      presetList.value = res.search_filter_presets.map(preset => ({
        ...preset,
        isOpenedSearchFilter: false,
      }));
    };

    onMounted(async () => {
      await fetchSearchFilterPresets();
    });

    return {
      presetList,
      editingPermission,
      staff,
      rejectedRoleIds,
      rejectedAdminIds,
      togglePreset,
      openPresetEditModal,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.modal-window.-typeB {
  width: calc(100vw - 20px);
  max-width: 1070px;
}
.header,
.body {
  width: 100%;
}

.no_preset {
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.preset_list {
  margin-bottom: 15px;
  > li {
    padding: 15px 0 15px;
    border-bottom: 1px solid #ccc;
    &.is-open img {
      transform: rotate(0);
    }
  }
}

.preset_head {
  display: flex;
}

.preset_title {
  display: flex;
  align-items: flex-start;
  width: 100%;
  line-height: 1.6;
  margin-right: auto;
  padding-right: 15px;
  > img {
    display: inline-block;
    width: 12px;
    height: 34px;
    margin-top: -1px;
    margin-right: 4px;
    transform: rotate(-90deg);
    transition: 0.1s;
    cursor: pointer;
  }
  > p {
    display: inline-block;
    padding: 4px 0;
    cursor: pointer;
  }
}

.preset_buttons {
  width: 352px;
  min-width: 352px;
  > button {
    margin-right: 10px;
    word-break: keep-all;
    &:last-child {
      margin-right: 0;
    }
  }
}

.preset_detail {
  margin-left: 15px;
  > p {
    margin-bottom: 10px;
    color: #777;
  }
}

.ar-actionBtns {
  text-align: center;
  > .btn-unavailable {
    width: 100%;
    max-width: 320px;
    margin: 20px 0 0;
    padding: 20px 30px;
  }
}

@media (max-width: ($media_query_sp)) {
  .preset_head {
    display: block;
  }
  .preset_list {
    > li {
      padding-bottom: 5px;
    }
  }
  .preset_buttons {
    width: 100%;
    min-width: 100%;
    margin-top: 5px;
    margin-left: 15px;
    > button {
      margin-bottom: 10px;
    }
  }
  .preset_detail {
    margin-top: 10px;
  }
}
</style>
