<template>
  <div class="area-selection">
    <bread-crumb-list>
      <template #breadcrumb>
        <span>選考一覧</span>
      </template>
    </bread-crumb-list>

    <section class="content-warp">
      <div v-if="$store.getters['staff/isOperator'] !== true" class="content">
        <p class="headline-typeC">選考データの登録</p>
        <button-base
          :button-text="'新しい選考を登録'"
          :icon-file-name="'plus_circle'"
          :rejected-role-ids="[3]"
          :rejected-admin-ids="[2]"
          @click="
            $router.push('/selections/registration');
            postPottosEvent(15);
          "
        />
      </div>
      <div class="content">
        <p class="headline-typeC">選考一覧</p>
        <selections-search />
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { postPottosEvent } from '@/utils/pottos';

import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import SelectionsSearch from '@/components/features/selectionsSearch/components/SelectionsSearch';

export default defineComponent({
  name: 'Selections',
  components: { BreadCrumbList, SelectionsSearch },
  setup(props, context) {
    return { postPottosEvent };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.display_none {
  display: none;
}
.content {
  padding: 40px 30px;
  background: #fff;
  + .content {
    margin-top: 20px;
  }
}
.edit-selection {
  margin-left: 7px;
}

@media (max-width: ($media_query_tablet)) {
  .content {
    padding: 40px;
  }
}
@media (max-width: ($media_query_sp)) {
  .content {
    padding: 10px;
  }
}
</style>
