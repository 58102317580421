<script setup>
import { watch, ref } from 'vue';
import ModalFullSize from '@/components/ui/modals/ModalFullSize.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';

import flagsurveyService from '@/services/flag-survey';
import tagsService from '@/services/tags';
import { generateUniqueId } from '@/utils/unique-id';
import useValidation from '@/composables/useValidation';
import FlagSurveyQuestion from '@/components/features/flagSurveyQuestion/components/FlagSurveyQuestion';

const props = defineProps({
  isView: {
    type: Boolean,
    default: false,
  },
  flagSurveyMode: {
    type: Object,
    required: true,
  },
  flagSurveyDetailMode: {
    type: Symbol,
    required: true,
    default: Symbol(''),
  },
  flagSurveyId: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(['close-modal']);

const { errors } = useValidation([
  'questionTitle',
  'mailTitle',
  'mailContent',
  'lineContent',
]);

const isLoading = ref(false);

const refQuestion = ref(null);
const tagGroups = ref([]);
const isShowDeleteModal = ref(false);
const questionTitle = ref('');
const mailTitle = ref('');
const questions = ref([]);
const mailContent = ref('');
const lineContent = ref('');

const init = async () => {
  const res = await tagsService.fetchTagGroups();
  tagGroups.value = res.reduce((result, group) => {
    if (group.tags.length > 0) {
      result.push({ id: group.id, name: group.name, selected: false });
    }
    return result;
  }, []);
  questionTitle.value = '';
  questions.value = [];
  mailTitle.value = '';
  mailContent.value = '';
  lineContent.value = '';
  isShowDeleteModal.value = false;
};

watch(
  () => props.isView,
  async val => {
    if (!val) return;
    isLoading.value = true;
    await init();
    const res = await flagsurveyService.getFlagSurvey({
      flagsurvey_id: props.flagSurveyId,
    });
    if (Object.keys(res.survey).length === 0) {
      isLoading.value = false;
      return;
    }
    questionTitle.value = res.survey.title;
    mailTitle.value = res.survey.mail_title;
    mailContent.value = res.survey.mail_content;
    lineContent.value = res.survey.line_content;
    questions.value = res.survey.flagsurvey_questions.map(x => ({
      id: `local_${generateUniqueId()}`,
      questionTitle: x.question_title,
      questionType: x.question_type ? x.question_type : 1,
      multi: x.multi,
      flagGroupId: x.flag_group_id,
      tagGroups: tagGroups.value.filter(y => !y.selected),
    }));
    isLoading.value = false;
  },
);
</script>

<template>
  <modal-full-size :is-view="isView" @close-modal="emit('close-modal')">
    <template #header>
      <div class="header">
        <div>送信するアンケートの選択</div>
        <img src="@/assets/img/arrow_right_blue.svg" />
        <div>アンケートの内容</div>
      </div>
    </template>
    <template #body>
      <div class="body-wrapper">
        <div class="body-inner">
          <div v-if="isLoading" class="page-loading">
            <LocalPageLoader />
          </div>
          <div v-else-if="!isLoading && questions.length === 0">
            <EmptyContent>
              <p>
                アンケートがありません
                <br />
                あらかじめ「応募者へ通知」からアンケートを
                <br />
                作成している必要があります
              </p>
            </EmptyContent>
          </div>
          <div v-else>
            <section class="flag_survey_area">
              <div
                v-if="flagSurveyDetailMode === flagSurveyMode.EDIT"
                class="btn btn-caution btn-delete"
                @click="isShowDeleteModal = true"
              >
                アンケートを削除
              </div>
              <div class="flag_survey_form flag_survey_flex">
                <p class="flag_survey_heading">アンケート名</p>
                <div class="input_tr">
                  <input
                    v-model="questionTitle"
                    type="text"
                    maxlength="50"
                    placeholder="アンケート名を入力してください（50文字まで）"
                    class="flag_survey_input input_block"
                    :disabled="flagSurveyDetailMode === flagSurveyMode.DETAIL"
                  />
                  <span
                    v-show="
                      flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                      !errors.questionTitle.isValid
                    "
                    class="fa fa-warning font-s"
                  >
                    {{ errors.questionTitle.message }}
                  </span>
                </div>
                <div class="option_tr" />
              </div>
              <p class="headlineA">アンケート内容</p>
              <ul class="question_list">
                <flag-survey-question
                  v-for="(question, i) in questions"
                  ref="refQuestion"
                  :key="question.id"
                  :index="i + 1"
                  :question-id="question.id"
                  :question-title="question.questionTitle"
                  :question-type="question.questionType"
                  :question-multi="question.multi"
                  :question-flag-group-id="question.flagGroupId"
                  :question-tag-groups="question.tagGroups"
                  :is-editable="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                  :questions="questions"
                />
              </ul>
              <ul class="footer_caption">
                <li>
                  ※アンケート送信時点で各フラググループに設定されているフラグ情報が選択肢になります。
                </li>
                <li>※自由記述の質問では応募者は800文字まで回答できます。</li>
              </ul>
              <p class="headlineA">メッセージ内容</p>
              <div>
                <div class="notice__content__btn">
                  <button
                    v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                    class="btn btn-availability"
                    @click="openModalSelectFixPhraseList"
                  >
                    テンプレートを使用
                  </button>
                </div>
                <div class="notice__warp">
                  <section class="notice__content">
                    <div class="notice__content-inner is_mail">
                      <header class="notice__header">
                        <h1 class="notice__title is_mail">
                          <img
                            class="mail_icon_svg"
                            src="@/assets/img/mail_gold.svg"
                          />
                          メールの応募者向け
                        </h1>
                        <div
                          v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                          class="notice__description"
                        >
                          <p class="notice__description-text">
                            こちらに入力した内容は、連絡方法が
                            <span>メール</span>
                            の応募者へ届きます。
                          </p>
                        </div>
                      </header>
                      <div class="notice_block">
                        <label
                          class="notice_title"
                          :class="{
                            is_required:
                              flagSurveyDetailMode !== flagSurveyMode.DETAIL,
                          }"
                        >
                          メールタイトル
                        </label>
                        <input
                          v-model="mailTitle"
                          type="text"
                          maxlength="200"
                          placeholder="メールタイトル（200文字まで）"
                          class="notice_form-input mail-title"
                          :class="{
                            'is-danger':
                              flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                              !errors.mailTitle.isValid,
                          }"
                          :disabled="
                            flagSurveyDetailMode === flagSurveyMode.DETAIL
                          "
                        />
                        <span
                          v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                          v-show="!errors.mailTitle.isValid"
                          class="fa fa-warning font-s"
                        >
                          {{ errors.mailTitle.message }}
                        </span>
                      </div>
                      <div class="notice_block">
                        <label
                          class="notice_title"
                          :class="{
                            is_required:
                              flagSurveyDetailMode !== flagSurveyMode.DETAIL,
                          }"
                        >
                          メール内容{{
                            flagSurveyDetailMode === flagSurveyMode.DETAIL
                              ? ''
                              : '(最大1000文字)'
                          }}
                        </label>
                        <textarea
                          v-model="mailContent"
                          maxlength="1000"
                          placeholder="内容（1000文字まで）"
                          class="notice_form-textarea"
                          :disabled="
                            flagSurveyDetailMode === flagSurveyMode.DETAIL
                          "
                          :class="{
                            'is-danger':
                              flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                              !errors.mailContent.isValid,
                          }"
                        />
                        <span
                          v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                          v-show="!errors.mailContent.isValid"
                          class="fa fa-warning font-s"
                        >
                          {{ errors.mailContent.message }}
                        </span>
                        <div class="notice_caution">
                          <p class="notice_caution-text">
                            内容冒頭には宛先の｛姓・名｝様が挿入されます。
                          </p>
                          <p class="notice_caution-text">
                            末尾には、設定している署名と、アンケートのURLが挿入されます。
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section class="notice__content">
                    <div class="notice__content-inner is_line">
                      <header class="notice__header">
                        <h1 class="notice__title is_line">
                          <img
                            class="line_icon_svg"
                            src="@/assets/img/line_green.svg"
                          />
                          LINEの応募者向け
                        </h1>
                        <div class="notice__description">
                          <p
                            v-if="
                              flagSurveyDetailMode !== flagSurveyMode.DETAIL
                            "
                            class="notice__description-text"
                          >
                            こちらに入力した内容は、連絡方法が
                            <span>LINE</span>
                            の応募者へ届きます。
                          </p>
                          <p class="notice__description-text">
                            LINEの場合はタイトルは含まれません。
                          </p>
                        </div>
                      </header>
                      <div class="notice_block linechat_block_input-area">
                        <label
                          class="notice_title"
                          :class="{
                            is_required:
                              flagSurveyDetailMode !== flagSurveyMode.DETAIL,
                          }"
                        >
                          LINEメッセージ{{
                            flagSurveyDetailMode === flagSurveyMode.DETAIL
                              ? ''
                              : '(最大800文字)'
                          }}
                        </label>
                        <textarea
                          v-model="lineContent"
                          maxlength="800"
                          placeholder="LINEメッセージ（800文字まで）"
                          class="notice_form-textarea form-line"
                          :class="{
                            'is-danger':
                              flagSurveyDetailMode !== flagSurveyMode.DETAIL &&
                              !errors.lineContent.isValid,
                          }"
                          :disabled="
                            flagSurveyDetailMode === flagSurveyMode.DETAIL
                          "
                        />
                        <span
                          v-if="flagSurveyDetailMode !== flagSurveyMode.DETAIL"
                          v-show="!errors.lineContent.isValid"
                          class="fa fa-warning font-s"
                        >
                          {{ errors.lineContent.message }}
                        </span>
                        <div class="notice_caution">
                          <p class="notice_caution-text">
                            内容冒頭には宛先の｛姓・名｝さんが挿入されます。
                          </p>
                          <p class="notice_caution-text">
                            末尾には、アンケートのURLが挿入されます。
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="btn-col">
        <button-base
          type="button"
          class="btn-cancel"
          button-type="secondary"
          button-text="キャンセル"
          @click="emit('close-modal')"
        />
      </div>
    </template>
  </modal-full-size>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.header {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: 12px;
    height: 12px;
  }
}
.body-wrapper {
  padding: 20px;
  background-color: $white;
}
//body
.body-inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}
.flag_survey_area {
  text-align: left;
}

.btn-delete {
  margin-bottom: 40px;
}

.question_list {
  margin-bottom: 30px;
  border-bottom: 1px solid #f0f0f0;
}

.flag_survey_form {
  text-align: left;
}

.flag_survey_flex {
  display: flex;
  padding-bottom: 30px;
}

.flag_survey_heading {
  min-width: 152px;
  max-width: 152px;
  font-size: 1.3rem;
  font-weight: bold;
  color: #1899d6;
  padding: 0.8rem 0 0 0;
  margin-right: 10px;
  text-align: left;
}

.input_tr {
  width: 100%;
  max-width: 750px;
  margin-right: auto;
  margin-bottom: -5px;
  .input_block {
    display: block;
    margin-bottom: 5px;
  }
}

.option_tr {
  min-width: 110px;
  max-width: 110px;
  height: 36px;
  margin-left: 20px;
}

.flag_survey_input {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  color: #333;
  font-family: 'Noto Sans JP', sans-serif;
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.footer_caption {
  margin: 20px 0 40px;
  > li {
    margin-bottom: 7px;
  }
}

.btn-col2 {
  max-width: 1200px;
  margin: 0 auto;
}

.btn-add-question {
  width: auto;
  &.is-disabled {
    opacity: 0.6;
    cursor: default;
    &:hover {
      background-color: #16b2d9;
    }
  }
}

.headlineA {
  margin-bottom: 20px;
  padding: 15px 20px;
  color: #1899d6;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #f2f9fc;
}

.notice__content__btn {
  min-height: 3px;
  margin: 0 20px;
  background-color: #f0f0f0;
  > button {
    margin: 10px;
  }
}

.notice__warp {
  display: flex;
  margin: 0 20px 40px;
  border: 3px solid #f0f0f0;
  border-top: none;
  .notice__content:nth-child(2) {
    border-left: 3px solid #f0f0f0;
  }
}

.notice__content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notice__content-inner {
  width: 100%;
  padding: 20px 20px 0;
}

.mail_icon_svg {
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.mail_icon_svg_path {
  fill: #d28300;
}

.line_icon_svg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
.line_icon_svg_path {
  fill: #1dcd00;
}

.notice__header {
  width: 100%;
}

.notice_title {
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.notice__title.is_mail {
  margin: 0 0 15px;
  color: #d28300;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice__title.is_line {
  margin: 0 0 15px;
  color: #43a047;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice__description {
  margin-bottom: 20px;
}

.notice__description-text {
  line-height: 1.4;
  span {
    font-weight: bold;
    padding: 3px 5px;
  }
}

.notice_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.notice_form-input {
  background: #fff;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  color: #333;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-appearance: none;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.notice_form-textarea {
  background: $white;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  color: #333;
  font-size: 1.4rem;
  line-height: 1.4;
  padding: 0.8rem;
  min-height: 150px;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-appearance: none;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.notice_caution {
  margin-top: 10px;
}

.notice_caution-text {
  margin-bottom: 10px;
  &:before {
    content: '※';
    padding-right: 5px;
  }
}

.is_required {
  &:after {
    content: '必須';
    font-weight: 100;
    color: #f4511e;
    font-size: 1.2rem;
    padding-left: 5px;
  }
}

.linechat_block_input-area {
  border-radius: 0px 0px 4px 4px;
  .emoji-picker-wrapper {
    margin-bottom: 7px;
  }
  .linechat_block_input-area_body {
    textarea {
      border-radius: 0.4rem;
      border: 1px solid;
      border-color: #808080;
      font-size: 1.4rem;
      line-height: 1.4;
      padding: 0.8rem;
      height: 10rem;
      background-color: #fff;
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: ($media_query_tablet)) {
  .notice__content__btn {
    margin: 0;
  }
  .notice__warp {
    margin: 0 0 40px;
  }
  .notice__content-inner {
    padding: 15px 15px 0;
  }
}

@media (max-width: ($media_query_sp)) {
  .flag_survey_area {
    padding: 20px;
  }
  .notice__warp {
    display: block;
  }
  .notice__warp .notice__content:nth-child(2) {
    border-top: 3px solid #f0f0f0;
    border-left: none;
  }
}

@media (max-width: 830px) {
  .flag_survey_flex {
    display: block;
    padding-bottom: 20px;
  }

  .flag_survey_form {
    margin-bottom: 20px;
  }

  .input_tr {
    margin-bottom: 10px;
  }

  .option_tr {
    min-height: 0;
    height: 100%;
    margin: 0;
  }

  .flag_survey_heading {
    padding: 0 0 1rem 0;
    margin-right: 0;
  }

  .btn-add-question {
    width: 100%;
  }
}
.warning-bottom {
  margin-bottom: 10px;
}
@include validation_effects3;

.btn-cancel {
  width: 100%;
  height: 46px;
  margin: 20px auto 0 auto;
}
</style>
