/**
 * 通知module
 * <div @click="VISIBLE_NOTIFICATION({ message: 'jisdjfisdjfidsj' })">VISIBLE_NOTIFICATION</div>
 * <div @click="INVISIBLE_NOTIFICATION">INVISIBLE_NOTIFICATION</div>
 * ...mapActions('notification', ['VISIBLE_NOTIFICATION', 'INVISIBLE_NOTIFICATION']),
 */
export default {
  namespaced: true,
  state: {
    isVisible: false,
    message: '',
    timeoutId: null,
    type: false,
  },
  getters: {
    isNotificationVisible: currentState => currentState.isVisible,
    notificationMessage: currentState => currentState.message,
    notificationType: currentState => currentState.type,
  },
  mutations: {
    VISIBLE_NOTIFICATION(state, payload) {
      Object.assign(state, {
        isVisible: true,
        message: payload.message,
        timeoutId: payload.timeoutId,
        type: payload.type,
      });
    },
    INVISIBLE_NOTIFICATION(state) {
      Object.assign(state, {
        isVisible: false,
        message: '',
        timeoutId: null,
        type: false,
      });
    },
  },
  actions: {
    VISIBLE_NOTIFICATION({ commit }, payload) {
      let timeoutId = null;
      let timeout = 3500;
      if (payload.timeout) {
        timeout = payload.timeout;
      }
      if (payload.nonVisible) {
        timeoutId = null;
      } else {
        timeoutId = setTimeout(() => {
          this.dispatch('notification/INVISIBLE_NOTIFICATION');
        }, timeout);
      }

      commit('VISIBLE_NOTIFICATION', {
        message: payload.message,
        timeoutId,
        type: payload.type,
      });
    },
    INVISIBLE_NOTIFICATION({ commit }) {
      if (this.state.notification.timeoutId) {
        clearTimeout(this.state.notification.timeoutId);
      }
      commit('INVISIBLE_NOTIFICATION');
    },
  },
};
