<template>
  <transition name="fade" appear>
    <div v-if="$store.getters['page/isPageLoaded']">
      <ul class="company-member_list">
        <li class="company-member_list_item">
          <edit-company-name
            v-if="$store.getters['staff/isOwner']"
            :company-info="localCompanyInfo.data"
            class="title"
            @commit="commitCompanyName"
          />
          <span v-else>
            <h2 class="company-member_list_item_ttl">会社名</h2>
            <p class="company-member_list_item_value">{{ companyInfo.name }}</p>
          </span>
        </li>

        <li class="company-member_list_item">
          <edit-company-zipcode
            v-if="$store.getters['staff/isOwner']"
            :company-info="localCompanyInfo.data"
            class="title"
            @commit="commitCompanyZipcode"
          />
          <span v-else>
            <h2 class="company-member_list_item_ttl">郵便番号</h2>
            <p class="company-member_list_item_value">
              {{ companyInfo.zipcode }}
            </p>
          </span>
        </li>

        <li class="company-member_list_item">
          <edit-company-address
            v-if="$store.getters['staff/isOwner']"
            :company-info="localCompanyInfo.data"
            class="title"
            @commit="commitCompanyAddress"
          />
          <span v-else>
            <h2 class="company-member_list_item_ttl">住所</h2>
            <p class="company-member_list_item_value">
              {{ companyInfo.address }}
            </p>
          </span>
        </li>

        <li class="company-member_list_item">
          <edit-company-tel
            v-if="$store.getters['staff/isOwner']"
            :company-info="localCompanyInfo.data"
            class="title"
            @commit="commitCompanyTel"
          />
          <span v-else>
            <h2 class="company-member_list_item_ttl">電話番号</h2>
            <p class="company-member_list_item_value">{{ companyInfo.tel }}</p>
          </span>
        </li>

        <li class="company-member_list_item">
          <span>
            <h2 class="company-member_list_item_ttl">送信用メールアドレス</h2>
            <p class="company-member_list_item_value mb_20">
              {{ companyInfo.email }}
            </p>
            <p class="mb_30">
              ※変更をご希望の場合は右下のチャットよりご連絡ください。
            </p>
            <div class="sub_contents">
              <div class="sub_contents_item">
                <span class="sub_contents_item_title">
                  MOCHICAでのメール受信機能：
                </span>
                <span class="sub_contents_item_value">
                  {{ companyInfo.email_flag ? 'ON' : 'OFF' }}
                </span>
              </div>
              <div class="sub_contents_item">
                <p class="mb_20">
                  メール受信機能が「ON」になっている場合、下記の機能は
                  「@mail.recruiting-system.co.jp」から送信されます。
                </p>
                <p class="mb_10">・応募者詳細画面からのメール送信</p>
                <p class="mb_20">・連絡事項の通知</p>
                <p class="mb_10">
                  ※送信元アドレスの@以前は応募者ごとに異なります。
                </p>
                <p class="mb_20 description_attention">
                  ※メール受信機能の詳細及び機能の切り替えについては
                  <a
                    href="https://help.mochica.jp/use/update20220208/"
                    target="_blank"
                    rel="noopener"
                    class="a_link"
                  >
                    コチラ
                  </a>
                  から
                </p>
              </div>
            </div>
          </span>
        </li>

        <li class="company-member_list_item">
          <edit-company-qr
            :company-info="localCompanyInfo.data"
            class="title"
          />
        </li>

        <li class="company-member_list_item">
          <h2 class="company-member_list_item_ttl">
            ログインできるIPアドレスの制限
          </h2>
          <p
            v-if="$store.getters['staff/isOwner']"
            class="company-member_list_item_value"
          >
            <input
              id="ip_enable"
              v-model="localCompanyInfo.data.ip_enable"
              class="setting_input"
              type="checkbox"
              name="ip_enable"
              @click="isVisibleChangeIpAddressSetting = true"
            />
            <label for="ip_enable">IPアドレス制限を有効にする</label>
          </p>
          <p v-else class="company-member_list_item_value">
            <input
              id="ip_enable"
              v-model="localCompanyInfo.data.ip_enable"
              onclick="return false;"
              class="setting_input"
              type="checkbox"
              name="ip_enable"
              @click="changeIpAddressSettingIsNotOwner"
            />
            <label for="ip_enable">IPアドレス制限を有効にする</label>
          </p>
          <edit-company-ip-address
            v-if="localCompanyInfo.data.ip_enable"
            ref="refEditCompanyIpAddress"
            :company-info="localCompanyInfo.data"
            class="title"
            @commit="openModalCommitCompanyIpAddress"
          />
        </li>

        <li
          v-if="
            $store.getters['staff/isOwner'] || $store.getters['staff/isManager']
          "
          class="company-member_list_item"
        >
          <h2 class="company-member_list_item_ttl">卒業年数の追加と削除</h2>
          <div class="company-member_list_item_value graduated_area">
            <button-base
              :button-text="'卒業年数を追加'"
              :rejected-role-ids="rejectedRoleIds"
              :rejected-admin-ids="rejectedAdminIds"
              @click="
                isVisibleInputGraduated = true;
                postPottosEvent(13);
              "
            />
            <button-base
              :button-text="'卒業年数を削除'"
              :button-type="'danger'"
              :rejected-role-ids="rejectedRoleIds"
              :rejected-admin-ids="rejectedAdminIds"
              @click="isVisibleDeleteGraduated = true"
            />
          </div>
        </li>

        <li class="company-member_list_item">
          <h2 class="company-member_list_item_ttl">
            エントリーフォーム自動送信
          </h2>
          <p class="company-member_list_item_value">
            <input
              id="send_enable_form"
              v-model="localCompanyInfo.data.entryform_enable"
              type="checkbox"
              class="setting_input"
              @click="isVisibleCommitCompanyEnableForm = true"
            />
            <label for="send_enable_form">
              LINE登録時に自動的にエントリーフォームを送信する
            </label>
          </p>
        </li>

        <li class="company-member_list_item">
          <h2 class="company-member_list_item_ttl">
            メールアドレススキップ機能
          </h2>
          <p class="company-member_list_item_value">
            <input
              id="is_required_email"
              v-model="localCompanyInfo.data.is_required_email"
              type="checkbox"
              class="setting_input"
              @click="isVisibleCommitCompanyIsRequiredEmail = true"
            />
            <label for="is_required_email">
              メールアドレスの入力を必須にする
            </label>
            <span class="text_caption">※全卒年共通の設定になります</span>
          </p>
        </li>

        <li class="company-member_list_item">
          <edit-company-graduated
            v-if="
              $store.getters['staff/isOwner'] ||
              $store.getters['staff/isManager']
            "
            :company-info="localCompanyInfo.data"
            class="title"
            @commit="handleClickChangeGraduated"
          />
          <div v-else>
            <h2 class="company-member_list_item_ttl">LINE初期登録種別</h2>
            <div>
              <span class="company-member_list_item_value">
                {{
                  graduatedToDescription(
                    localCompanyInfo.data.graduated_default,
                  )
                }}
              </span>
            </div>
          </div>
        </li>

        <li class="company-member_list_item">
          <h2 class="company-member_list_item_ttl">
            未読メッセージ件数通知
            <br />
            （毎日9時/13時/16時通知）
          </h2>
          <p class="company-member_list_item_value">
            <input
              id="line_unread_alert"
              v-model="localCompanyInfo.data.linealert"
              type="checkbox"
              class="setting_input"
              @click="isVisibleCommitCompanyLineAlert = true"
            />
            <label for="line_unread_alert">
              未読メッセージ件数通知をメールで受け取る。
            </label>
          </p>
        </li>

        <li
          v-if="
            $store.getters['company/allowFileManagiment'] && uploadStatus.data
          "
          class="company-member_list_item"
        >
          <span>
            <h2 class="company-member_list_item_ttl">
              ファイルアップロード機能の現在の使用容量
            </h2>
            <div class="progress-bar">
              <span class="progress-min">0GB</span>
              <span class="progress-bar-text">
                {{ byteToGB(uploadStatus.data.used) }}GB&nbsp;/&nbsp;{{
                  byteToGB(uploadStatus.data.size)
                }}GB &nbsp;{{ Math.floor(uploadStatus.data.ratio * 10) / 10 }}%
              </span>
              <div :style="progressStyles" class="progress-bar-blue"></div>
              <span class="progress-max">
                {{ byteToGB(uploadStatus.data.size) }}GB
              </span>
            </div>
          </span>
        </li>
      </ul>
      <modal-create-graduated
        :is-view="isVisibleInputGraduated"
        @onCanceled="isVisibleInputGraduated = false"
      />
      <modal-delete-graduated
        :is-view="isVisibleDeleteGraduated"
        @onCanceled="isVisibleDeleteGraduated = false"
      />
      <modal-window
        :is-visible="isVisibleChangeIpAddressSetting"
        :is-loading="isLoading"
        title="ログインできるIPアドレスの制限"
        max-width="600px"
        max-height="80vh"
        @close="closeModal('isVisibleChangeIpAddressSetting')"
      >
        <template #content>
          <div class="modal-contents ip-setting">
            <div class="modal-contents-text">
              <h3 class="modal-contents-title">【ご確認ください】</h3>
              <div>
                IPアドレスの制限を変更します。
                <br />
                制限を有効にする場合は、必ず設定されたIPアドレスを全てご確認ください。
              </div>
              <small class="caption">
                ※編集中の場合は、編集内容が全て破棄されます。
              </small>
            </div>
            <div class="modal-contents-bottom">
              <button-base
                :button-text="'キャンセル'"
                button-type="secondary"
                @click="closeModal('isVisibleChangeIpAddressSetting')"
              />
              <button-base
                :button-text="'登録'"
                :button-type="'primary'"
                :is-loading="isLoading"
                @click="changeIpAddressSetting({ selected: 'submit' })"
              />
            </div>
          </div>
        </template>
      </modal-window>
      <modal-window
        :is-visible="isVisibleCommitCompanyIpAddress"
        :is-loading="isLoading"
        title="IPアドレスの設定変更"
        max-width="600px"
        max-height="80vh"
        @close="closeModal('isVisibleCommitCompanyIpAddress')"
      >
        <template #content>
          <div class="modal-contents ip-commit">
            <div class="modal-contents-text caution">
              <h3 class="modal-contents-title">【ご確認ください】</h3>
              <div>
                ログインできるIPアドレスの設定を変更します。
                <br />
                ログインを制限するIPアドレスを間違えるとログインできなくなります。
              </div>
            </div>
            <div class="modal-contents-text list">
              <h3 class="modal-contents-title">許可するIPアドレス:</h3>
              <div v-if="ipAddressesArray.length === 0" class="no-list-item">
                登録なし
              </div>
              <ul v-else class="ip-list">
                <li
                  v-for="(ip, i) in ipAddressesArray"
                  :key="i"
                  class="ip-list-item"
                >
                  {{ ip }}
                </li>
              </ul>
            </div>
            <div v-if="ipAddressesArray.length === 0" class="caption">
              ※登録しない場合は、IPアドレスの制限はかかりません。
            </div>
            <div class="modal-contents-bottom">
              <button-base
                :button-text="'キャンセル'"
                button-type="secondary"
                @click="closeModal('isVisibleCommitCompanyIpAddress')"
              />
              <button-base
                :button-text="'設定を変更'"
                :button-type="'primary'"
                :is-loading="isLoading"
                @click="commitCompanyIpAddress({ selected: 'submit' })"
              />
            </div>
          </div>
        </template>
      </modal-window>
      <modal-window
        :is-visible="isVisibleCommitCompanyEnableForm"
        title="エントリーフォーム自動送信設定の変更"
        message="エントリーフォーム自動送信設定を変更しますか？"
        button-text="設定を変更"
        :is-loading="isLoading"
        @click="commitCompanyEnableForm"
        @close="closeModal('isVisibleCommitCompanyEnableForm')"
      />
      <modal-window
        :is-visible="isVisibleCommitCompanyIsRequiredEmail"
        title="メールアドレススキップ機能の変更"
        message="メールアドレス入力のスキップ設定を変更しますか？"
        button-text="設定を変更"
        :is-loading="isLoading"
        @click="commitCompanyIsRequiredEmail"
        @close="closeModal('isVisibleCommitCompanyIsRequiredEmail')"
      />
      <modal-window
        :is-visible="isVisibleCommitCompanyLineAlert"
        title="未読通知設定の変更"
        message="未読通知設定を変更しますか？"
        button-text="設定を変更"
        :is-loading="isLoading"
        @click="commitCompanyLineAlert"
        @close="closeModal('isVisibleCommitCompanyLineAlert')"
      />
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { postPottosEvent } from '@/utils/pottos';

import companyService from '@/services/company';
import uploadService from '@/services/upload';
import ModalCreateGraduated from '@/components/page/configs/company/components/ModalCreateGraduated';
import ModalDeleteGraduated from '@/components/page/configs/company/components/ModalDeleteGraduated';
import EditCompanyName from '@/components/page/configs/company/components/EditCompanyName';
import EditCompanyZipcode from '@/components/page/configs/company/components/EditCompanyZipcode';
import EditCompanyAddress from '@/components/page/configs/company/components/EditCompanyAddress';
import EditCompanyTel from '@/components/page/configs/company/components/EditCompanyTel';
import EditCompanyQr from '@/components/page/configs/company/components/EditCompanyQr';
import EditCompanyGraduated from '@/components/page/configs/company/components/EditCompanyGraduated';
import EditCompanyIpAddress from '@/components/page/configs/company/components/EditCompanyIpaddress';

export default defineComponent({
  name: 'Company',
  components: {
    EditCompanyName,
    EditCompanyZipcode,
    EditCompanyAddress,
    EditCompanyTel,
    EditCompanyQr,
    EditCompanyIpAddress,
    EditCompanyGraduated,
    ModalCreateGraduated,
    ModalDeleteGraduated,
  },
  setup(props, context) {
    const store = useStore();
    const refEditCompanyIpAddress = ref(null);
    const localCompanyInfo = reactive({ data: {} });
    const uploadStatus = reactive({ data: {} });
    const companyName = ref('');
    const companyZipcode = ref('');
    const companyAddress = ref('');
    const companyTel = ref('');
    const companyQr = ref('');
    const graduatedOptions = ref([]);
    const ipAddressesArray = ref([]);
    const progressStyles = reactive({ width: '' });
    const rejectedRoleIds = ref([3]);
    const rejectedAdminIds = ref([2]);
    const isLoading = ref(false);
    const isVisibleInputGraduated = ref(false);
    const isVisibleDeleteGraduated = ref(false);
    const isVisibleChangeIpAddressSetting = ref(false);
    const isVisibleCommitCompanyIpAddress = ref(false);
    const isVisibleCommitCompanyEnableForm = ref(false);
    const isVisibleCommitCompanyIsRequiredEmail = ref(false);
    const isVisibleCommitCompanyLineAlert = ref(false);

    // computed
    const companyInfo = computed(() => store.getters['company/companyInfo']);

    // methods
    const fetchAll = async () => {
      await store.dispatch('company/FETCH_COMPANY_INFO');
      await setUploadStatus();
      localCompanyInfo.data = companyInfo.value;
    };
    const setUploadStatus = async () => {
      const res = await uploadService.fetchUploadStatus();
      if (res.success) uploadStatus.data = res.data;
    };
    const commitCompanyName = async payload => {
      const res = await companyService.updateCompany(
        payload.name,
        companyInfo.value.zipcode,
        companyInfo.value.address,
        companyInfo.value.tel,
      );
      const key = '会社名';
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? `${key}を変更しました。`
            : `${key}の変更に失敗しました。`,
        type: res.success === true,
      });
      await fetchAll();
    };
    const commitCompanyZipcode = async payload => {
      const res = await companyService.updateCompany(
        companyInfo.value.name,
        payload.zipcode,
        companyInfo.value.address,
        companyInfo.value.tel,
      );
      const key = '郵便番号';
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? `${key}を変更しました。`
            : `${key}の変更に失敗しました。`,
        type: res.success === true,
      });
      await fetchAll();
    };
    const commitCompanyAddress = async payload => {
      const res = await companyService.updateCompany(
        companyInfo.value.name,
        companyInfo.value.zipcode,
        payload.address,
        companyInfo.value.tel,
      );
      const key = '住所';
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? `${key}を変更しました。`
            : `${key}の変更に失敗しました。`,
        type: res.success === true,
      });
      await fetchAll();
    };
    const commitCompanyTel = async payload => {
      const res = await companyService.updateCompany(
        companyInfo.value.name,
        companyInfo.value.zipcode,
        companyInfo.value.address,
        payload.tel,
      );
      const key = '電話番号';
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? `${key}を変更しました。`
            : `${key}の変更に失敗しました。`,
        type: res.success === true,
      });
      await fetchAll();
    };
    const changeIpAddressSetting = async payload => {
      if (payload.selected !== 'submit') {
        await fetchAll();
        isVisibleChangeIpAddressSetting.value = false;
        return;
      }
      isLoading.value = true;
      const res = await companyService.setIpAddress(
        localCompanyInfo.data.ip_enable,
      );
      await fetchAll();
      isVisibleChangeIpAddressSetting.value = false;
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const changeIpAddressSettingIsNotOwner = async () => {
      await fetchAll();
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '管理者のみが変更できます',
        type: false,
      });
    };
    const commitCompanyIpAddress = async payload => {
      if (payload.selected !== 'submit') {
        await fetchAll();
        isVisibleCommitCompanyIpAddress.value = false;
        ipAddressesArray.value = [];
        return;
      }
      isLoading.value = true;
      localCompanyInfo.data.ip_addresses = ipAddressesArray.value;
      const res = await companyService.updateIpAddress(ipAddressesArray.value);
      await fetchAll();
      await refEditCompanyIpAddress.value.reset();
      isVisibleCommitCompanyIpAddress.value = false;
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      ipAddressesArray.value = [];
    };
    const commitCompanyEnableForm = async payload => {
      if (payload.selected !== 'submit') {
        await fetchAll();
        isVisibleCommitCompanyEnableForm.value = false;
        return;
      }
      isLoading.value = true;
      const res = await companyService.updateCompanyEntryFormEnable(
        localCompanyInfo.data.entryform_enable,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await fetchAll();
      isVisibleCommitCompanyEnableForm.value = false;
      isLoading.value = false;
    };
    const commitCompanyIsRequiredEmail = async payload => {
      if (payload.selected !== 'submit') {
        await fetchAll();
        isVisibleCommitCompanyIsRequiredEmail.value = false;
      }
      isLoading.value = true;
      const res = await companyService.updateCompanyIsRequiredEmail(
        localCompanyInfo.data.is_required_email,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      await fetchAll();
      isVisibleCommitCompanyIsRequiredEmail.value = false;
      isLoading.value = false;
    };
    const commitCompanyLineAlert = async payload => {
      if (payload.selected !== 'submit') {
        await fetchAll();
        isVisibleCommitCompanyLineAlert.value = false;
        return;
      }
      isLoading.value = true;
      const res = await companyService.updateLineAlert(
        companyInfo.value.linealert,
      );
      isVisibleCommitCompanyLineAlert.value = false;
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? '未読通知設定を変更しました。'
            : '未読通知設定の変更に失敗しました。',
        type: res.success,
      });
      await fetchAll();
    };
    const commitCompanyFormDetail = async payload => {
      const res = await companyService.updateCompany(
        companyInfo.value.name,
        companyInfo.value.zipcode,
        companyInfo.value.address,
        companyInfo.value.tel,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message:
          res.success === true
            ? '詳細項目を変更しました。'
            : '詳細項目の変更ができませんでした。',
        type: res.success,
      });
      await fetchAll();
    };
    const handleClickChangeGraduated = async year => {
      localCompanyInfo.data.graduated_default = year;
      const res = await companyService.updateDefaultGraduated(year);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const closeModal = async key => {
      await fetchAll();
      switch (key) {
        case 'isVisibleChangeIpAddressSetting':
          isVisibleChangeIpAddressSetting.value = false;
          break;
        case 'isVisibleCommitCompanyIpAddress':
          isVisibleCommitCompanyIpAddress.value = false;
          break;
        case 'isVisibleCommitCompanyEnableForm':
          isVisibleCommitCompanyEnableForm.value = false;
          break;
        case 'isVisibleCommitCompanyIsRequiredEmail':
          isVisibleCommitCompanyIsRequiredEmail.value = false;
          break;
        case 'isVisibleCommitCompanyLineAlert':
          isVisibleCommitCompanyLineAlert.value = false;
          break;
        default:
          break;
      }
    };
    const openModalCommitCompanyIpAddress = tmpIpAddresses => {
      ipAddressesArray.value = tmpIpAddresses;
      isVisibleCommitCompanyIpAddress.value = true;
    };
    const byteToGB = value => {
      // バイトをギガバイトに変換
      return value > 0
        ? Math.floor((value / (1000 * 1000 * 1000)) * 10) / 10
        : value;
    };
    const graduatedToDescription = year => {
      switch (year) {
        case 9901:
          return 'アルバイト';
        case 9902:
          return '中途採用';
        case 9903:
          return '派遣・契約';
        case 9999:
          return 'その他';
        default:
          return `${year}年３月卒業`;
      }
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
      // ステータスバーの幅の計算
      progressStyles.width = `${
        Math.floor(uploadStatus.data.ratio * 10) / 10
      }%`;
    });

    return {
      refEditCompanyIpAddress,
      localCompanyInfo,
      uploadStatus,
      companyName,
      companyZipcode,
      companyAddress,
      companyTel,
      companyQr,
      graduatedOptions,
      ipAddressesArray,
      progressStyles,
      rejectedRoleIds,
      rejectedAdminIds,
      isLoading,
      isVisibleInputGraduated,
      isVisibleDeleteGraduated,
      isVisibleChangeIpAddressSetting,
      isVisibleCommitCompanyIpAddress,
      isVisibleCommitCompanyEnableForm,
      isVisibleCommitCompanyIsRequiredEmail,
      isVisibleCommitCompanyLineAlert,
      companyInfo,
      commitCompanyName,
      commitCompanyAddress,
      commitCompanyTel,
      changeIpAddressSetting,
      changeIpAddressSettingIsNotOwner,
      commitCompanyIpAddress,
      commitCompanyEnableForm,
      commitCompanyIsRequiredEmail,
      commitCompanyLineAlert,
      commitCompanyFormDetail,
      closeModal,
      commitCompanyZipcode,
      handleClickChangeGraduated,
      openModalCommitCompanyIpAddress,
      byteToGB,
      graduatedToDescription,
      postPottosEvent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.mb_10 {
  margin-bottom: 10px;
}
.mb_20 {
  margin-bottom: 20px;
}
.mb_30 {
  margin-bottom: 30px;
}
.company-member_list_item {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid $mochica_color_gray2;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.company-member_list_item_ttl {
  font-size: 1.2rem;
  line-height: 1;
  color: $black;
  margin-bottom: 0.6rem;
  font-weight: bold;
}
.company-member_list_item_value {
  font-size: 1.2rem;
  line-height: 1;
}
.sub_contents_item {
  margin-bottom: 20px;
  .sub_contents_item_title {
    font-weight: bold;
  }
  .sub_contents_item_value {
    font-size: 1.4rem;
  }
}
.company-member_list_item_ttl {
  margin-bottom: 20px;
  br {
    display: none;
  }
}
.company-member_list_item_value {
  position: relative;
  display: flex;
  font-size: 1.4rem;
  word-break: break-word;
  .setting_input {
    appearance: none;
    min-width: 1.4rem;
    height: 1.4rem;
    border-radius: 0.4rem;
    border: 2px solid $mochica_color_gray;
    background: $white;
    position: relative;
    cursor: pointer;
    &:checked {
      &::after {
        content: url('../../../assets/img/check.svg');
        height: 1.1rem;
        width: 1.5rem;
        margin: -5px 0 0 1px;
        position: absolute;
        bottom: 3px;
        left: 0;
      }
    }
  }
  label {
    display: inline-block;
    padding: 0 0 0 10px;
    font-size: 1.4rem;
    cursor: pointer;
  }
}
.select_graduateds_wrapper {
  display: flex;
  align-items: center;
  width: 45rem;
  .toggle-edit-check_inner {
    margin-left: auto;
  }
}
.company-member-condition {
  @include band();
  margin-bottom: 3.6rem;
}
.company-p {
  width: calc(100% - 6rem);
  margin: 0 3rem;
  margin-bottom: 1rem;
  color: $mochica_color_gray;
}
.company-member_ttl {
  display: flex;
  align-items: center;
  height: 5rem;
  padding-left: 2rem;
  border-bottom: 1px dashed $border_color;
}
.company-member_ttl_svg {
  height: 3rem;
  margin-right: 1rem;
  fill: $mochica_color;
}
.progress-bar {
  border: 1px solid #707070;
  border-radius: 0.3rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 2rem;
  .progress-bar-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .progress-bar-blue {
    height: 100%;
    background: $mochica_color;
    display: flex;
    justify-content: flex-start;
  }
  .progress-min {
    position: absolute;
    left: -2px;
    bottom: -2rem;
  }
  .progress-max {
    position: absolute;
    right: -2px;
    bottom: -2rem;
  }
}
.company-member_list_item_content {
  .btn-s {
    position: relative;
  }
}
.company-member_list_item_image {
  width: 100%;
}
label,
progress {
  display: table-cell;
}
label {
  padding-right: 10px;
  text-align: right;
}

:deep(.btn-s) {
  position: absolute;
  top: -6px;
  right: 0;
}
:deep(.edit-company-element) {
  .company-member_list_item_ttl {
    position: relative;
    width: 500px;
    margin-bottom: 20px;
    font-size: 1.2rem;
    line-height: 1;
    color: $black;
    .list_title {
      font-weight: bold;
    }
  }
  @media (max-width: ($media_query_tablet)) {
    .company-member_list_item_ttl {
      width: 100%;
    }
    .name-kanji {
      input {
        width: 45rem;
        padding: 7px;
        border-radius: 6px;
        border: 1px solid $border_color;
      }
    }
  }
}

.text_caption {
  margin: 1px 0 0 10px;
  font-size: 14px;
  color: #bf0000;
}

.description_attention {
  .a_link {
    font-weight: bold;
    text-decoration: underline;
  }
}

.graduated_area {
  button {
    margin: 10px 6px 0 0;
  }
}

@media (max-width: ($media_query_tablet)) {
  :deep(.btn-s) {
    left: auto;
    right: 0;
  }
  :deep(.edit-company-element) {
    .name-kanji {
      input {
        width: 100%;
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .company-member_list_item_value {
    &.graduated_area {
      display: block;
    }

    label {
      line-height: 1.4;
      font-size: 1.4rem;
      text-align: left;
    }
  }
  .company-member_list_item_ttl {
    br {
      display: inline !important;
    }
  }
}

.modal-contents {
  padding: 24px;

  &-title {
    margin-bottom: 6px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  &-bottom {
    display: flex;
    gap: 8px;
    margin-top: 30px;
    button {
      height: 52px;
      width: 100%;
    }
  }
}
.modal-contents.ip-setting {
  .modal-contents-title {
    padding-top: 14px;
  }
  .caption {
    padding-top: 6px;
    font-size: 1rem;
    color: $system_color;
  }
}
.modal-contents.ip-commit {
  .modal-contents-text.caution {
    padding-bottom: 20px;
    border-bottom: 1px solid $border_color;

    .modal-contents-title {
      padding: 14px 8px 0 0;
    }
  }
  .modal-contents-text.list {
    display: flex;
    padding-top: 20px;

    .modal-contents-title {
      padding-right: 8px;
    }
    .no-list-item {
      color: $system_color;
    }
    .ip-list {
      margin-right: 8px;
    }
  }
  .caption {
    color: $system_color;
  }
}
</style>
