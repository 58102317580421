<template>
  <div
    ref="refCalendarDetail"
    class="create-selection"
    :class="{ 'is-show-detail': isShowDetail }"
    :style="styles.data"
  >
    <div class="selection-header">
      <div class="selection-header_inner">
        <p class="selection-title">
          {{ parseDate(date) }}
        </p>
        <div class="button_close" @click="$emit('onClickClose')">
          <img
            slot="icon"
            class="icon"
            src="@/assets/img/close_black.svg"
            alt
          />
        </div>
      </div>
    </div>
    <button class="btn btn-availability" @click="openCreateSelection(date)">
      <img
        slot="icon"
        class="icon"
        src="@/assets/img/icon_plus_white.svg"
        alt
      />
      新しい選考を登録する
    </button>
  </div>
</template>

<script>
import moment from 'moment';

import { defineComponent, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PopupCreateSelection',
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Date,
      required: true,
    },
    positionLeft: {
      type: Number,
      required: true,
    },
    positionTop: {
      type: Number,
      required: true,
    },
    calendarCellWidth: {
      type: Number,
      required: true,
    },
  },
  emits: ['onClickClose'],
  setup(props, context) {
    const router = useRouter();
    const refCalendarDetail = ref(null);
    const isShowDetail = ref(false);
    const styles = reactive({ data: {} });

    // methods
    const openCreateSelection = date => {
      context.emit('onClickClose');
      router.push({
        name: 'SelectionRegistration',
        query: { date: moment(date).format('YYYY-MM-DD') },
      });
    };
    const parseDate = dt => {
      return moment(dt).format('YYYY年MM月DD日(ddd曜日)');
    };
    const getPositionLeft = () => {
      const bodyWidth = document.body.clientWidth;
      const popupWidth = refCalendarDetail.value.getBoundingClientRect().width;
      // サイドバーがある場合ない場合でのposition leftの調整
      const sidebarWidthAdjuster = bodyWidth > 1024 ? 30 : 30;
      // カレンダーをrelativeとしてのleft相対座標
      const leftRelative = props.positionLeft - sidebarWidthAdjuster;
      if (leftRelative - props.calendarCellWidth > popupWidth) {
        // 左付きでpopupがカレンダーからはみ出ない場合、左付き表示
        return `${leftRelative - props.calendarCellWidth - popupWidth - 3}px`;
      }
      return `${leftRelative}px`;
    };
    const getStylesPosition = (newStyles = { top: 0, bottom: 0, left: 0 }) => {
      newStyles.left = getPositionLeft();
      // 上付きでpopupがカレンダーからはみ出さない場合、上付き表示
      newStyles.top = `${props.positionTop}px`;
      return newStyles;
    };

    onMounted(() => {
      isShowDetail.value = false;
      styles.data = getStylesPosition();
      isShowDetail.value = true;
    });

    return {
      refCalendarDetail,
      isShowDetail,
      styles,
      openCreateSelection,
      parseDate,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/fullcalendar-colors.scss';

.create-selection {
  position: absolute;
  width: 310px;
  height: 128px;
  border-radius: 4px;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.16);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.2s;
  &.is-show-detail {
    opacity: 1;
  }
}

.selection-header {
  margin: 21px 15px 14px 38px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9e9e9;
}

.selection-header_inner {
  display: flex;
}

.selection-title {
  margin: 0 auto 0 -25px;
  padding-left: 25px;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  word-break: break-all;
  background-image: url('../../../../assets/img/icon_time.svg');
  background-size: auto 16px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.btn-availability {
  padding: 0;
  margin: 0 15px 21px 38px;
  width: 258px;
  height: 40px;
  font-size: 14px;
}

.button_close {
  cursor: pointer;
  > img {
    width: 14px;
  }
}

@media (max-width: ($media_query_sp)) {
  :deep(.modal-container) {
    width: auto;
    margin: 0 auto;
  }
}
</style>
