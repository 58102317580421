export default [
  {
    id: 0,
    name: '選考未割当て',
    isFinished: false,
  },
  {
    id: 1,
    name: '未案内',
    isFinished: false,
  },
  {
    id: 2,
    name: '案内済',
    isFinished: false,
  },
  // {
  //   id: 3,
  //   name: '日程確定',
  // isFinished: false,
  // },
  {
    id: 4,
    name: '未評価',
    isFinished: false,
  },
  {
    id: 5,
    name: '人事待',
    isFinished: false,
  },
  // {
  //   id: 6,
  //   name: '終了',
  //   isFinished: false,
  // },
  {
    id: 7,
    name: '採用',
    isFinished: true,
    finished_status_id: 1,
  },
  {
    id: 8,
    name: '内定',
    isFinished: true,
    finished_status_id: 2,
  },
  {
    id: 9,
    name: '不採用',
    isFinished: true,
    finished_status_id: 3,
  },
  {
    id: 10,
    name: '辞退',
    isFinished: true,
    finished_status_id: 4,
  },
  {
    id: 11,
    name: '内定辞退',
    isFinished: true,
    finished_status_id: 5,
  },
  {
    id: 12,
    name: '通過',
    isFinished: true,
    finished_status_id: 6,
  },
];
