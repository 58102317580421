<template>
  <div class="wrapper">
    <div class>
      <div class="edit-company-element">
        <h2 class="company-member_list_item_ttl">
          <span class="list_title">応募者の一括削除</span>
        </h2>
        <p class="value">
          フリープランの場合のみ、登録されている応募者を一括削除できます。
        </p>
      </div>
    </div>
    <div
      class="btn btn-caution delete-applicants"
      @click="$emit('onClickOpenModalDeleteApplicantsConfirm', $event)"
    >
      <img slot="icon" class="icon" src="@/assets/img/delete_white.svg" alt />
      応募者を一括削除
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdminDeleteAllApplicants',
  props: {
    companyId: {
      type: Number,
      default: null,
    },
  },
  emits: ['onClickOpenModalDeleteApplicantsConfirm'],
  setup(props, context) {
    return {};
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.wrapper {
  margin-top: 20px;
}
.edit-company-element {
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  .company-member_list_item_ttl {
    display: flex;
    justify-content: space-between;
  }
  .btn-availability {
    line-height: 0px;
  }
  .list_title {
    font-weight: bold;
  }
  .value {
    padding-top: 10px;
  }
}
.delete-applicants {
  width: 100%;
  margin-top: 30px;
}
</style>
