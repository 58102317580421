import httpClient from '@/http-client';

const NewsManager = function _NewsManager() {
  const manager = Object.create(NewsManager.prototype);
  return manager;
};

NewsManager.prototype = {
  async getNews() {
    const res = await httpClient.get('/news');
    return res.data;
  },
};

export default NewsManager();
