<template>
  <div class="edit-company-element">
    <h2 class="company-member_list_item_ttl">
      <p class="list_title">LINE初期登録種別</p>
      <button-base
        class="btn-s btn-mobile"
        :rejected-admin-ids="[2]"
        :button-text="
          isEditMode === true
            ? 'LINE初期登録種別を確定'
            : 'LINE初期登録種別を変更'
        "
        :button-type="isEditMode === true ? 'success' : 'primary'"
        :icon-file-name="isEditMode === true ? 'check_white' : 'pen_white'"
        @click="isEditMode === true ? commit() : (isEditMode = true)"
      />
    </h2>
    <div class="company-member_list_item_value">
      <div v-if="isEditMode">
        <select
          class="entryform_setting_select_id"
          type="text"
          @change="onChangeGraduated"
        >
          <option :value="companyInfo.graduated_default" selected>
            ▼ 選択してください
          </option>
          <option
            v-for="graduated in graduatedOptions"
            :key="graduated.year"
            :label="graduatedYearToLabelName(graduated.year)"
            :value="graduated.year"
          >
            {{ graduatedYearToLabelName(graduated.year) }}
          </option>
        </select>
      </div>
      <div v-else>
        <span>
          {{
            graduatedYearToLabelName(
              Number(localCompanyInfo.data.graduated_default),
            )
          }}{{ isVisibleDescription ? '年3月卒業予定' : '' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';

import GraduatedDefines from '@/defines/graduated';

export default defineComponent({
  name: 'EditCompanyGraduated',
  props: {
    companyInfo: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['commit'],
  setup(props, context) {
    const store = useStore();
    const localCompanyInfo = reactive({ data: {} });
    const graduatedOptions = ref([]);
    const isEditMode = ref(false);
    const isVisibleDescription = computed(() => {
      const year = Number(localCompanyInfo.data.graduated_default);
      if (
        year === 9000 ||
        year === 9901 ||
        year === 9902 ||
        year === 9903 ||
        year === 9999
      ) {
        return false;
      }
      return true;
    });
    const graduatedYearToLabelName = year => {
      if (year === 9000) return '卒業年度を選択してください';
      const res = [...GraduatedDefines].find(v => v.year === year);
      if (res !== undefined) return res.name_jp;
      return `${year}`;
    };
    const commit = () => {
      context.emit('commit', localCompanyInfo.data.graduated_default);
      isEditMode.value = false;
    };
    const onChangeGraduated = event => {
      localCompanyInfo.data.graduated_default = event.currentTarget.value;
    };
    onBeforeMount(() => {
      graduatedOptions.value = [...store.getters['graduateds/graduateds']];
    });

    watch(
      () => props.companyInfo,
      () => (localCompanyInfo.data = props.companyInfo),
    );

    return {
      localCompanyInfo,
      graduatedOptions,
      isEditMode,
      isVisibleDescription,
      graduatedYearToLabelName,
      commit,
      onChangeGraduated,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.entryform_setting_select_id {
  height: 30px;
}

.company-member_list_item_value {
  font-size: 1.4rem;
}

.btn {
  > .icon {
    margin-right: 6px;
  }
}

@media (max-width: ($media_query_sp)) {
  .btn-mobile {
    width: 100%;
    position: relative !important;
    margin-top: 20px;
  }
}
</style>
