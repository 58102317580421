<template>
  <modal :is-view="isVisible">
    <template #content>
      <div class="content">
        <div class="body">
          <section class="modal-header">
            <div class="modal-header_inner">
              <div class="modal-header_ttl">
                応募者へ通知
                <img
                  class="breadcrumb"
                  src="@/assets/img/arrow_right_blue.svg"
                  alt
                />
                アンケート送信
              </div>
            </div>
            <div class="modal-header_cancell" @click="$emit('onClickCancel')">
              <img
                slot="icon"
                class="icon"
                src="@/assets/img/close_black.svg"
                alt
              />
            </div>
          </section>
          <section class="flag_survey_area">
            <p v-if="disable" class="delete_message">
              このアンケートはすでに削除されています。送信履歴のみの表示となります。
            </p>
            <div class="flag_survey_form flag_survey_flex">
              <p class="flag_survey_heading">アンケート名</p>
              <div class="input_tr">
                <input
                  v-model="title"
                  type="text"
                  name="title"
                  class="flag_survey_input input_block"
                  :disabled="true"
                />
              </div>
              <div class="option_tr" />
            </div>
            <ul>
              <flag-survey-question
                v-for="(question, i) in questions"
                :key="question.id"
                :index="i + 1"
                :question-id="String(question.id)"
                :question-title="question.question_title"
                :question-type="question.question_type"
                :question-multi="question.multi"
                :question-flag-group-id="question.flag_group_id"
                :question-tag-groups="tagGroups"
                :is-editable="false"
                :questions="questions"
              />
            </ul>
            <ul class="footer_caption">
              <li>
                ※アンケート送信時点で各フラググループに設定されているフラグ情報が選択肢になります。
              </li>
              <li>※自由記述の質問では応募者は800文字まで回答できます。</li>
            </ul>
            <p class="headlineA">メッセージ内容</p>
            <div>
              <div class="notice__content__btn" />
              <div class="notice__warp">
                <section class="notice__content">
                  <div class="notice__content-inner is_mail">
                    <header class="notice__header">
                      <h1 class="notice__title is_mail">
                        <img
                          class="mail_icon_svg"
                          src="@/assets/img/mail_gold.svg"
                          alt="alt"
                        />
                        メールの応募者向け
                      </h1>
                    </header>
                    <div class="notice_block">
                      <label class="notice_title">タイトル</label>
                      <input
                        v-model="mailTitle"
                        type="text"
                        class="notice_form-input mail-title"
                        :disabled="true"
                      />
                    </div>
                    <div class="notice_block">
                      <label class="notice_title">内容</label>
                      <textarea
                        v-model="mailContent"
                        class="notice_form-textarea"
                        :disabled="true"
                      />
                      <div class="notice_caution">
                        <p class="notice_caution-text">
                          内容冒頭には宛先の｛姓・名｝様が挿入されます。
                        </p>
                        <p class="notice_caution-text">
                          末尾には、設定している署名と、アンケートのURLが挿入されます。
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="notice__content">
                  <div class="notice__content-inner is_line">
                    <header class="notice__header">
                      <h1 class="notice__title is_line">
                        <img
                          class="line_icon_svg"
                          src="@/assets/img/line_green.svg"
                        />
                        LINEの応募者向け
                      </h1>
                      <div class="notice__description">
                        <p class="notice__description-text">
                          LINEの場合はタイトルは含まれません。
                        </p>
                      </div>
                    </header>
                    <div class="notice_block linechat_block_input-area">
                      <label class="notice_title">LINEメッセージ</label>
                      <textarea
                        v-model="lineContent"
                        class="notice_form-textarea form-line"
                        :disabled="true"
                      ></textarea>
                      <div class="notice_caution">
                        <p class="notice_caution-text">
                          内容冒頭には宛先の｛姓・名｝さんが挿入されます。
                        </p>
                        <p class="notice_caution-text">
                          末尾には、アンケートのURLが挿入されます。
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <p class="flag_survey_subtitle">送信済み応募者</p>
            <dl class="flag_survey_announced_at">
              <dt>送信日時</dt>
              <dd>
                {{ $utils.parseDateTime(announcedAt, 'YYYY/MM/DD HH:mm') }}
              </dd>
            </dl>
            <p class="flag_survey_sub_title">アンケート未回答応募者</p>
            <div class="area-search-list">
              <selectable-applicant-table
                :is-selectable="true"
                :applicants="notAnsweredApplicants"
                :applicants-tags="applicantsTags.data"
                :can-move-detail="true"
                :invisible-columns="getInvisibleColumn"
                :header-additional="additionalHeader"
                :flag-mode="true"
                @onChangeCurrentSelect="
                  onChangeCurrentSelectUnansweredApplicants
                "
                @onChangeSort="onChangeSort"
              />
            </div>
            <div class="btn-right">
              <button
                class="btn btn-availability"
                :class="{ disable: disable }"
                :disabled="disable"
                @click="onClickSubmitSendDialog"
              >
                選択した未回答の応募者にアンケートを再送信
              </button>
            </div>
            <p class="flag_survey_sub_title">アンケート回答済み応募者</p>
            <div class="area-search-list">
              <selectable-applicant-table
                :is-selectable="true"
                :applicants="answeredApplicants"
                :applicants-tags="applicantsTags.data"
                :can-move-detail="true"
                :invisible-columns="getInvisibleColumn"
                :header-additional="additionalHeader"
                :flag-mode="true"
                @onChangeCurrentSelect="onChangeCurrentSelectAnsweredApplicants"
                @onChangeSort="onChangeSort"
              />
            </div>
          </section>
          <div class="btn-col2">
            <button class="btn btn-unavailable" @click="$emit('onClickCancel')">
              キャンセル
            </button>
            <button class="btn btn-availability" @click="onClickCreateAnnounce">
              選択した応募者に新規連絡事項を送信
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Breakpoints from '@/defines/breakpoints';
import applicantService from '@/services/applicant';
import applicantsService from '@/services/applicants';
import tagsService from '@/services/tags';
import flagsurveyService from '@/services/flag-survey';
import flagsurveyNoticeService from '@/services/flag-survey-notice';
import {
  getInitSearchFlagForRequest,
  getInitSearchSurveyForRequest,
} from '@/models/applicant-search';
import Modal from '@/components/ui/modals/components/Modal';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';
import FlagSurveyQuestion from '@/components/features/flagSurveyQuestion/components/FlagSurveyQuestion';

export default defineComponent({
  name: 'ModalFlagSurveyView',
  components: { Modal, FlagSurveyQuestion, SelectableApplicantTable },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    flagSurveyId: {
      type: Number,
      default: null,
    },
    announcedId: {
      type: Number,
      default: null,
    },
  },
  emits: ['onClickCancel'],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const title = ref('');
    const disable = ref(false);
    const questions = ref([]);
    const mailTitle = ref('');
    const mailContent = ref('');
    const lineContent = ref('');
    const applicants = ref([]);
    const applicantsIds = ref([]);
    const selectedAnsweredApplicantIds = ref([]);
    const selectedUnansweredApplicantIds = ref([]);
    const flagsurveyUncheckApplicantIds = ref([]);
    const flagsurveyAnsweredApplicantIds = ref([]);
    const applicantsTags = reactive({ data: {} });
    const announcedAt = ref(null);
    const tagGroups = ref([]);
    const timeoutId = ref(null);
    const getInvisibleColumn = ref([]);

    // computed
    const additionalHeader = computed(() =>
      tagGroups.value
        .filter(item => item.selected === true)
        .map(item => ({
          name: item.id,
          name_jp: item.name,
          isAdditional: item.isAdditional,
        }))
        .concat([
          {
            name: 'flagsurvey_check',
            name_jp: 'メッセージ確認',
            sort_by: 'flagsurvey_check',
            relation: ['flagsurvey_check'],
            activated: { disabled: true },
            notActivated: { disabled: true },
            filter: 'flagsurveyCheck',
          },
        ]),
    );
    const answeredApplicants = computed(() =>
      applicants.value.filter(applicant =>
        flagsurveyAnsweredApplicantIds.value.includes(applicant.id),
      ),
    );
    const notAnsweredApplicants = computed(() =>
      applicants.value.filter(
        applicant =>
          !flagsurveyAnsweredApplicantIds.value.includes(applicant.id),
      ),
    );

    // methods
    const fetchDetail = async () => {
      const res = await flagsurveyNoticeService.fetchFlagSurveyNotice({
        flagsurvey_id: props.flagSurveyId,
        announced_id: props.announcedId,
      });
      if (res.success === false) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.message,
          type: false,
        });
        return;
      }
      title.value = res.survey.title;
      disable.value = res.survey.disable;
      questions.value = res.survey.flagsurvey_questions.map(v => ({
        ...v,
        // question_type がない場合は強制的に1
        question_type: v.question_type ? v.question_type : 1,
      }));
      mailTitle.value = res.survey.mail_title;
      mailContent.value = res.survey.mail_content;
      lineContent.value = res.survey.line_content;
      applicantsIds.value = res.survey.applicants_ids;
      announcedAt.value = res.survey.announced_at;
      flagsurveyUncheckApplicantIds.value = res.survey.flagsurvey_checks
        .filter(x => x.flagsurvey_check === false)
        .map(x => x.applicant_id);
      flagsurveyAnsweredApplicantIds.value = res.survey.flagsurvey_answereds
        .filter(x => x.answered_check === true)
        .map(x => x.applicant_id);
    };
    const fetchApplicants = async sort => {
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedId'];
      if (!graduatedYear) return;
      const page = 1;
      const load = 5000;
      // ID指定応募者のみ検索
      const searchBody = { target_ids: applicantsIds.value };
      // 応募者(LINE+メール) + LINE友達対象
      const searchFlags = {
        ...getInitSearchFlagForRequest(),
        is_all_applicants: true,
      };
      const searchSurvey = getInitSearchSurveyForRequest();
      const res = await applicantsService.fetchApplicantsSearch(
        graduatedYear.year,
        page,
        load,
        searchBody,
        searchFlags,
        sort,
        searchSurvey,
        true, // isExceptGraduated（卒年を無視）
      );
      // メッセージ確認の項目を付与
      applicants.value = res.applicants.map(v => ({
        ...v,
        flagsurveyCheck: !flagsurveyUncheckApplicantIds.value.includes(v.id),
      }));
      const tags = await applicantService.fetchApplicantSearchTags(
        applicants.value.map(a => a.id),
        tagGroups.value.map(g => g.id),
      );
      applicantsTags.data = tags;
    };
    const getTagGroups = async () => {
      const tagGroups = await tagsService.fetchTagGroups();
      return tagGroups.map(group => ({
        id: group.id,
        name: group.name,
        isAdditional: true,
        selected: true,
      }));
    };
    const onChangeSort = async sort => {
      if (sort) await fetchApplicants(sort);
    };
    const onClickCreateAnnounce = () => {
      const selectedApplicantIds = selectedAnsweredApplicantIds.value.concat(
        selectedUnansweredApplicantIds.value,
      );
      if (selectedApplicantIds.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者が選択されていません',
          type: false,
        });
        return;
      }
      // 選択済み応募者をページを跨ぎを渡すためstoreに一時的に保持（遷移後すぐにリセットさせる）
      store.dispatch(
        'announce/SET_CREATE_ANNOUNCE_TARGET_APPLICANTS',
        applicants.value.filter(x => selectedApplicantIds.includes(x.id)),
      );
      router.push({ name: 'NoticeAnnounce' });
    };
    const onClickSubmitSendDialog = async () => {
      if (selectedUnansweredApplicantIds.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'アンケート未回答応募者が選択されていません',
          type: false,
        });
        return;
      }
      await store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        title: 'アンケートの再送信',
        message: '選択された応募者にアンケートを再送信しますか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async payload => {
          if (payload.choice === 2) {
            const res = await flagsurveyService.sendFlagSurvey({
              flagsurvey_id: props.flagSurveyId,
              applicant_ids: selectedUnansweredApplicantIds.value,
              announced_id: props.announcedId,
            });
            if (res.success) context.emit('onClickCancel');
            store.dispatch('notification/VISIBLE_NOTIFICATION', {
              message: res.success ? 'アンケートを再送しました' : res.message,
              type: res.success,
            });
          }
          await store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        onclickOuter: async () => {
          await store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    const onChangeCurrentSelectAnsweredApplicants = payload => {
      if (payload === undefined) return;
      selectedAnsweredApplicantIds.value = [...payload.selectedApplicants];
    };
    const onChangeCurrentSelectUnansweredApplicants = payload => {
      if (payload === undefined) return;
      selectedUnansweredApplicantIds.value = [...payload.selectedApplicants];
    };
    const getInvisibleColumns = () => {
      const baseInvisibleColumns = [
        'attendanceDate',
        'attendanceAt',
        'reminderCheck',
        'not_announe_lamp',
        'faculty',
        'department',
        'department_category',
        'email',
        'tel',
        'channel',
        'invited_status',
        'created_at',
        'activated_at',
        'invited_at',
        'staff',
        'unread_count',
      ];
      getInvisibleColumn.value =
        window.innerWidth <= Breakpoints.MAX_SP_SIZE
          ? baseInvisibleColumns.concat(['name_kana', 'gender'])
          : baseInvisibleColumns;
    };
    const onResize = () => {
      if (timeoutId.value) return;
      timeoutId.value = setTimeout(() => {
        timeoutId.value = 0;
        getInvisibleColumns();
      }, 500);
    };

    // lifecycle
    onBeforeMount(async () => {
      tagGroups.value = await getTagGroups();
      await fetchDetail();
      await fetchApplicants();
      await getInvisibleColumns();
    });
    onMounted(() => {
      window.addEventListener('resize', onResize);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', onResize);
    });

    return {
      title,
      disable,
      questions,
      mailTitle,
      mailContent,
      lineContent,
      applicants,
      applicantsTags,
      announcedAt,
      tagGroups,
      getInvisibleColumn,
      additionalHeader,
      answeredApplicants,
      notAnsweredApplicants,
      onChangeSort,
      onClickCreateAnnounce,
      onClickSubmitSendDialog,
      onChangeCurrentSelectAnsweredApplicants,
      onChangeCurrentSelectUnansweredApplicants,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  @include full_modal;
  overflow-y: auto;
  .modal-body {
    padding-bottom: 40px;
    // text-align: start;
    & :deep(.area-table) {
      margin: 2rem 0 0;
    }
  }
  .modal-body_btn_item.disabled {
    cursor: default;
  }
}

.body {
  padding: 0 20px;
}

img.breadcrumb {
  width: 0.6rem;
  margin: 0 0.8rem;
}

.flag_survey_area {
  .delete_message {
    border: solid 1px $mochica_color_red;
    color: $white;
    background-color: $mochica_color_red;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  max-width: 1200px;
  padding: 30px;
  margin: 80px auto 0;
  text-align: left;
  background-color: $white;
}

.btn-right {
  display: flex;
  max-width: 1200px;
  margin: 30px auto 0;
  .btn-availability {
    max-width: 333px;
    margin-left: auto;
    &.disable {
      background-color: #ccc;
      opacity: 0.4;
      cursor: default;
    }
  }
}

.btn-col2 {
  max-width: 1200px;
  margin: 30px auto 0;
  .btn-availability {
    &.disable {
      background-color: #ccc;
      opacity: 0.4;
      cursor: default;
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .flag_survey_area {
    padding: 20px;
  }
}

.flag_survey_form {
  text-align: left;
  margin-bottom: 30px;
  border-bottom: 1px solid $border_color;
}

.flag_survey_flex {
  display: flex;
  padding-bottom: 30px;
}

.flag_survey_heading {
  min-width: 152px;
  max-width: 152px;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1;
  color: $mochica_color;
  padding: 0.85rem 0 0 0;
  margin-right: 10px;
  text-align: left;
}

.input_tr {
  width: 100%;
  max-width: 750px;
  margin-right: auto;
  margin-bottom: -5px;
  .input_block {
    display: block;
    margin-bottom: 5px;
  }
}

.option_tr {
  min-width: 110px;
  max-width: 110px;
  height: 36px;
  margin-left: 20px;
}

.flag_survey_input {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  color: #333;
  font-family: 'Noto Sans JP', sans-serif;
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.flag_survey_subtitle {
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 30px;
  font-size: 1.4rem;
  font-weight: bold;
  color: $white;
  background-color: #52b0d5;
}

.flag_survey_announced_at {
  display: flex;
  > dt {
    margin-right: 30px;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.flag_survey_sub_title {
  margin: 30px 0 15px;
  font-size: 1.2rem;
  font-weight: bold;
}
.footer_caption {
  margin: 20px 0 40px;
  > li {
    margin-bottom: 7px;
  }
}

.headlineA {
  margin-bottom: 20px;
  padding: 15px 20px;
  color: $mochica_color;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #f2f9fc;
}

.notice__content__btn {
  min-height: 3px;
  margin: 0 20px;
  background-color: #f0f0f0;
  > button {
    margin: 10px;
  }
}

.notice__warp {
  display: flex;
  margin: 0 20px 40px;
  border: 3px solid #f0f0f0;
  border-top: none;
  .notice__content:nth-child(2) {
    border-left: 3px solid #f0f0f0;
  }
}

.notice__content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notice__content-inner {
  width: 100%;
  padding: 20px 20px 0;
}

.mail_icon_svg {
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.mail_icon_svg_path {
  fill: #d28300;
}

.line_icon_svg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
.line_icon_svg_path {
  fill: #1dcd00;
}

.notice__header {
  width: 100%;
}

.notice_title {
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.notice__title.is_mail {
  margin: 0 0 15px;
  color: #d28300;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice__title.is_line {
  margin: 0 0 15px;
  color: #43a047;
  font-weight: bold;
  font-size: 1.3rem;
}

.notice__description {
  margin-bottom: 20px;
}

.notice__description-text {
  line-height: 1.5;
  span {
    font-weight: bold;
    padding: 3px 5px;
  }
}

.notice_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.notice_form-input {
  background: $white;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  color: #333;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.notice_form-textarea {
  background: $white;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  color: #333;
  line-height: 1.8;
  min-height: 150px;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    transition: 0.3s;
    border-color: $mochica-color;
  }
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.notice_caution {
  margin-top: 10px;
}

.notice_caution-text {
  margin-bottom: 10px;
  &:before {
    content: '※';
    padding-right: 5px;
  }
}

.linechat_block_input-area {
  border-radius: 0px 0px 4px 4px;
  .linechat_block_input-area_header {
    .emoji-picker-wrapper {
      cursor: pointer;
      position: relative;
    }
  }
  .linechat_block_input-area_body {
    textarea {
      border-radius: 0.4rem;
      border: 1px solid;
      border-color: #808080;
      padding: 1em;
      font-size: 1.2rem;
      line-height: 1.6em;
      height: 10rem;
      background-color: $white;
      margin-bottom: 1rem;
    }
  }
}

.btn-col2 {
  max-width: 1200px;
  margin: 30px auto 0;
}

@media (max-width: ($media_query_tablet)) {
  .notice__content__btn {
    margin: 0;
  }
  .notice__warp {
    margin: 0 0 40px;
  }
  .notice__content-inner {
    padding: 15px 15px 0;
  }
}

@media (max-width: ($media_query_sp)) {
  .notice__warp {
    display: block;
  }
  .notice__warp .notice__content:nth-child(2) {
    border-top: 3px solid #f0f0f0;
    border-left: none;
  }

  .btn-col2 {
    flex-direction: column-reverse;
    > .btn-availability {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 830px) {
  .flag_survey_flex {
    display: block;
    padding-bottom: 20px;
  }

  .flag_survey_form {
    margin-bottom: 20px;
  }

  .input_tr {
    margin-bottom: 10px;
  }

  .option_tr {
    min-height: 0;
    height: 100%;
    margin: 0;
  }

  .flag_survey_heading {
    padding: 0 0 1rem 0;
    margin-right: 0;
  }
}
</style>
