<template>
  <modal :is-view="isView">
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">挿入するテンプレート名を選択してください</p>
        </div>
        <transition name="fade">
          <div class="body">
            <div class="body-inner">
              <div class="fixPharase_area">
                <table class="table-typeB">
                  <thead class="thead">
                    <tr class="row">
                      <th class="item-th">テンプレート名</th>
                      <th class="item-th">作成者</th>
                      <th class="item-th">設定内容</th>
                      <th class="item-th">作成日</th>
                    </tr>
                  </thead>
                  <tbody class="tbody">
                    <tr
                      v-for="fixphrase in fixedPhraseList"
                      :key="fixphrase.id"
                      class="row selectable-template"
                      @click="openFixedPhrase(fixphrase)"
                    >
                      <td class="item-td">
                        <!-- FIXME: 表示崩れ防止のため下記で修正すること -->
                        <!-- {{ $utils.textEllipsis(fixphrase.name) }} -->
                        {{ fixphrase.name }}
                      </td>
                      <td class="item-td">
                        {{ fixphrase.staff.lastname
                        }}{{ fixphrase.staff.firstname }}
                      </td>
                      <td class="item-td">
                        <span class="line_cont">
                          {{ fixphrase.line_content !== '' ? 'LINE' : '' }}
                        </span>
                        <span class="mail_cont">
                          {{ fixphrase.mail_content !== '' ? 'メール' : '' }}
                        </span>
                      </td>
                      <td class="item-td">
                        {{
                          $utils.parseDateTime(
                            fixphrase.created_at,
                            'YYYY/MM/DD HH:mm',
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </transition>
        <div class="btn-col2">
          <button
            class="btn btn-availability"
            @click="onClickCloseModalSelectPhraseList"
          >
            キャンセル
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, watch, nextTick } from 'vue';

import { onResizeModal } from '@/utils/modal-resize';
import Modal from '@/components/ui/modals/components/Modal';

export default defineComponent({
  name: 'ModalFixedPhraseList',
  components: { Modal },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    fixedPhraseList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCloseModalSelectPhraseList', 'openFixedPhrase'],
  setup(props, context) {
    watch(
      () => props.isView,
      async val => {
        if (val === true) {
          nextTick(() => localResizeModal());
          window.addEventListener('resize', localResizeModal);
        } else {
          window.removeEventListener('resize', localResizeModal);
        }
      },
    );
    const onClickCloseModalSelectPhraseList = () => {
      context.emit('onClickCloseModalSelectPhraseList');
    };
    const openFixedPhrase = payload => {
      context.emit('openFixedPhrase', payload);
    };
    const localResizeModal = () => onResizeModal(props.isView);
    return {
      onClickCloseModalSelectPhraseList,
      openFixedPhrase,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.fixPharase_area {
  overflow: auto;
  white-space: nowrap;
  height: 400px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal-window {
  width: 600px;
}

.content {
  border-radius: 1rem;
  .header {
    padding: 2rem;
    border-bottom: solid 1px;
    color: #1899d6;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    .title {
      font-size: 1.4rem;
    }
  }
  .body {
    padding: 2rem;
    .body-inner {
      overflow-y: auto;
    }
  }
}
.pageloader {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 254px;
}
input:focus,
select:focus {
  border: 1px green solid;
}
input:focus.is-danger,
.is-danger {
  border: 1px $mochica_color_red solid !important;
}
.fa-warning {
  color: #c31e1e;
  padding-top: 4px;
}
.fa {
  display: block;
}
// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.btn-col2 {
  justify-content: center;
}

.selectable-template {
  cursor: pointer;
}
</style>
