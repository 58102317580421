<template>
  <modal :is-view="isView">
    <template #rightTop>
      <div class="right-top" @click="$emit('onClickCancel')">
        <img class="icon" src="@/assets/img/close_white.svg" alt />
      </div>
    </template>
    <template #content>
      <div class="content modal-window">
        <div class="header">
          <p class="title">Zoomのホストの一括割当て</p>
        </div>

        <transition name="fade">
          <div
            class="modal-window-inner"
            @click="isOpenMultiZoomHostSelector = false"
          >
            <div class="message_area">
              <p>
                開催時間ごとのZoomのホストを一括で割当てます。
                <br />
                ホストとなる担当者を選択して、Zoomのホストを割当てるを押して下さい。
              </p>
            </div>
            <dl class="select_staff_area">
              <dt>
                <b>Zoomのホスト</b>
              </dt>
              <dd @click.stop>
                <div
                  class="multiple_selector"
                  @click="isOpenMultiZoomHostSelector = true"
                >
                  {{
                    selectZoomHosts &&
                    selectZoomHosts.find(host => host.selected)
                      ? selectZoomHosts.find(host => host.selected).name
                      : ''
                  }}
                </div>
                <float-context-menu
                  :is-view="isOpenMultiZoomHostSelector"
                  :list="selectZoomHosts"
                  :is-multiple-select="false"
                  @onClose="isOpenMultiZoomHostSelector = false"
                  @onSelected="onSelectedZoomHosts"
                />
              </dd>
            </dl>
            <div class="btn-full_width">
              <div class="btn btn-unavailable" @click="$emit('onClickCancel')">
                キャンセル
              </div>
              <button-base
                class="btn"
                button-text="Zoomのホストを割当て"
                :rejected-role-ids="[3]"
                :rejected-admin-ids="[2]"
                @click="onClickToConfirm"
              />
            </div>
          </div>
        </transition>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import Modal from '@/components/ui/modals/components/Modal';
import FloatContextMenu from '@/components/ui/menus/components/FloatContextMenu';

export default defineComponent({
  name: 'ModalBulkAssignZoomHost',
  components: { Modal, FloatContextMenu },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    zoomUsers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClickCancel', 'setZoomHost'],
  setup(props, context) {
    const store = useStore();
    const isOpenMultiZoomHostSelector = ref(false);
    const selectZoomHosts = ref(
      props.zoomUsers.map(user => ({
        id: user.id,
        name: user.name,
        selected: false,
      })),
    );

    // computed
    const selectedZoomHost = computed(() => {
      return selectZoomHosts.value.find(host => host.selected === true);
    });

    // methods
    // 開催時間へ担当者選択モーダルで、確定押下時の処理
    const onSelectedZoomHosts = event => {
      selectZoomHosts.value = selectZoomHosts.value.map(host => {
        host.selected = host.id === event.item.id ? !host.selected : false;
        return host;
      });
    };
    const onClickToConfirm = async () => {
      if (!selectedZoomHost.value) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'Zoomホストが選択されていません',
          type: false,
        });
        return;
      }
      context.emit('setZoomHost', selectZoomHosts.value);
      selectZoomHosts.value = selectZoomHosts.value.map(host => {
        host.selected = false;
        return host;
      });
    };

    return {
      isOpenMultiZoomHostSelector,
      selectZoomHosts,
      onClickToConfirm,
      onSelectedZoomHosts,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content {
  max-width: 640px;
}

.message_area {
  margin: 0;
  padding-bottom: 25px;
  margin: 30px 40px 25px;
  font-size: 16px;
  line-height: 175%;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 10%);
  > p {
    margin-bottom: 6px;
  }
}

.select_staff_area {
  padding: 0 40px;
  margin-bottom: 27px;
  text-align: left;
  > dt {
    margin-bottom: 10px;
    font-size: 14px;
    color: #777;
    > b {
      color: #333;
    }
  }
}

.multiple_selector {
  height: 44px;
  padding: 14px 25px 14px 14px;
  line-height: 1;
  border: solid 1px #adadad;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-image: url('../../../../assets/img/arrow_down_blue.svg');
  background-size: 10px auto;
  background-position: right 10px center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.btn-full_width {
  display: flex;
  width: 100%;
  padding: 0 40px 50px;
  > button {
    margin-left: 10px;
  }
  > div,
  button {
    width: 275px;
    height: 50px;
    margin-bottom: 10px;
    padding: 18px 10px;
    line-height: 1;
  }
}

@media (max-width: ($media_query_sp)) {
  .message_area {
    padding-bottom: 10px;
    margin: 20px 15px 20px;
  }
  .select_staff_area {
    padding: 0 15px;
  }
  .btn-full_width {
    padding: 0 15px 10px;
  }
}
</style>
