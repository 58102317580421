import httpClient from '@/http-client';

const FixPhraseManager = function _FixPhraseManager() {
  const manager = Object.create(FixPhraseManager.prototype);
  return manager;
};

FixPhraseManager.prototype = {
  async postFixPhraseList(graduatedId) {
    const res = await httpClient.post('/fixphrase/list', {
      graduated_id: graduatedId,
    });
    return res.data;
  },
  async postCreateFixPhrase(payload) {
    const res = await httpClient.post('/fixphrase/create', {
      graduated_id: payload.graduated_id,
      name: payload.name,
      mail_title: payload.mail_title,
      mail_content: payload.mail_content,
      line_content: payload.line_content,
    });
    return res.data;
  },
  async postUpdateFixPhrase(payload) {
    const res = await httpClient.post('/fixphrase/update', {
      fixphrase_id: payload.id,
      graduated_id: payload.graduated_id,
      name: payload.name,
      mail_title: payload.mail_title,
      mail_content: payload.mail_content,
      line_content: payload.line_content,
    });
    return res.data;
  },
  async postDeleteFixPhrase(payload) {
    const res = await httpClient.post('/fixphrase/delete', {
      fixphrase_id: payload.id,
      graduated_id: payload.graduated_id,
    });
    return res.data;
  },
};

export default FixPhraseManager();
