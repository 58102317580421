<template>
  <div>
    <div class="pager_wrapper">
      <div v-if="isAllApplicants === false" class="pager_wrapper_switch_area">
        <switch-tab-applicant
          :disp-applicants="displayApplicants"
          :is-disp-line-friends="isDispLineFriend"
          :is-disp-applicant="true"
          :is-tab="true"
          @input="updateDisplayApplicants"
        />
      </div>
    </div>
    <div v-if="isShowSelectableApplicantTable" class="applicant_counter">
      <div class="table_head">
        <div>
          <span class="title_text">対象人数：</span>
          <span class="count_text">
            {{
              isActivated === true
                ? registeredApplicants.length
                : lineFriends.length
            }}
          </span>
          名
        </div>
      </div>
    </div>
    <local-page-loader v-if="!isShowSelectableApplicantTable" />
    <div v-if="isShowSelectableApplicantTable" class="area-search-list">
      <!-- 応募者一覧 -->
      <template v-if="isActivated === true">
        <selectable-applicant-table
          v-if="registeredApplicants.length > 0"
          :is-selectable="false"
          :applicants="registeredApplicants"
          :applicants-tags="applicantsTags"
          :can-move-detail="true"
          :invisible-columns="invisibleColumns"
          :header-additional="headerAdditional"
          :flag-mode="isFlagMode"
        />
        <div v-else class="no_applicants">
          <p>応募者は選択していません</p>
        </div>
      </template>
      <template v-else>
        <!-- LINE友達一覧 -->
        <selectable-applicant-table
          v-if="lineFriends.length > 0"
          v-show="isActivated === false"
          :is-selectable="false"
          :applicants="lineFriends"
          :applicants-tags="applicantsTags"
          :can-move-detail="true"
          :invisible-columns="invisibleColumns"
          :header-additional="headerAdditional"
          :flag-mode="isFlagMode"
        />
        <div v-else class="no_applicants">
          <p>LINE友達の応募者は選択していません</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import { useStore } from 'vuex';

import useSearchApplicant from '@/composables/useSearchApplicant';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';
import SwitchTabApplicant from '@/components/ui/switchTabApplicant/components/SwitchTabApplicant';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';

export default defineComponent({
  name: 'SearchApplicantTabPreview',
  components: { SelectableApplicantTable, SwitchTabApplicant, LocalPageLoader },
  props: {
    registeredApplicants: {
      type: Array,
      default: () => null,
    },
    lineFriends: {
      type: Array,
      default: () => null,
    },
    invisibleColumnsPc: {
      type: Array,
      default: () => null,
    },
    invisibleColumnsSp: {
      type: Array,
      default: () => null,
    },
    excludeSelectionId: {
      type: Number,
      default: null,
    },
    isAllApplicants: {
      type: Boolean,
      default: false,
    },
    isOnlyApplicantsOfLine: {
      type: Boolean,
      default: false,
    },
    isExceptGraduated: {
      type: Boolean,
      default: false,
    },
    isDispLineFriend: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    // composable
    const {
      onResize,
      headerAdditional,
      invisibleColumns,
      applicantsTags,
      isFlagMode,
    } = useSearchApplicant(store, store.getters['searchFilter/searchFilter'], {
      invisibleColumnsPc: props.invisibleColumnsPc,
      invisibleColumnsSp: props.invisibleColumnsSp,
      excludeSelectionId: props.excludeSelectionId,
      isAllApplicants: props.isAllApplicants,
      isOnlyApplicantsOfLine: props.isOnlyApplicantsOfLine,
      isExceptGraduated: props.isExceptGraduated,
      isRequiredApplicantTags: true,
    });
    const displayApplicants = ref('regist_applicants');
    const isShowSelectableApplicantTable = ref(true);

    // computed
    const isActivated = computed(() => {
      return displayApplicants.value === 'regist_applicants';
    });

    // methods
    onBeforeMount(async () => {
      onResize();
      await store.dispatch('page/SET_LOADED');
    });
    onMounted(async () => {
      window.addEventListener('resize', onResize);
    });
    onUnmounted(async () => {
      window.removeEventListener('resize', onResize);
    });
    const updateDisplayApplicants = val => {
      displayApplicants.value = val;
    };

    return {
      applicantsTags,
      invisibleColumns,
      headerAdditional,
      isFlagMode,
      displayApplicants,
      isShowSelectableApplicantTable,
      isActivated,
      updateDisplayApplicants,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.title_text {
  font-weight: bold;
}

.count_text {
  font-size: 1.8rem;
  font-weight: bold;
}

.pager_wrapper {
  display: flex;
  > .pager_wrapper_pager {
    martin-top: -10px;
    height: 32px;
    margin-bottom: calc(2rem - 2px);
  }
}

.pager_wrapper_switch_area {
  width: 100%;
}

.applicant_counter {
  margin-bottom: 12px;
}

.table_head {
  display: flex;
  align-items: center;
}

.no_applicants {
  display: flex;
  width: 100%;
  height: 252px;
  align-items: center;
  margin: 0 auto;
  color: #777;
  background-color: rgba(217, 217, 217, 0.23);
  > p {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: ($media_query_sp)) {
  .pager_wrapper {
    display: block;
    > .pager_wrapper_pager {
      margin-bottom: calc(2rem);
    }
  }
}
</style>
