<template>
  <div class="edit-text">
    <div class="item-title">
      <h2 class="personal_setting_list_item_ttl">
        <span class="list_title">{{ title }}</span>
      </h2>
      <button-base
        :button-text="isEditMode === true ? '確定' : '編集'"
        :button-type="isEditMode === true ? 'success' : 'primary'"
        :rejected-admin-ids="[2]"
        :icon-file-name="isEditMode === true ? 'check_white' : 'pen_white'"
        @click="isEditMode === true ? commit() : (isEditMode = true)"
      />
    </div>
    <p v-if="isEditMode === true" class="name-kanji">
      <input v-model="localLText" type="text" placeholder="" maxlength="16" />
      <input v-model="localFText" type="text" placeholder="" maxlength="16" />
    </p>
    <p v-else class="value">{{ localLText + localFText }}</p>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'EditText',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    id: {
      type: Number,
      default: null,
    },
    lText: {
      type: String,
      default: '',
    },
    fText: {
      type: String,
      default: '',
    },
  },
  emits: ['commit'],
  setup(props, context) {
    const store = useStore();
    const isEditMode = ref(false);
    const localLText = ref(props.lText);
    const localFText = ref(props.fText);
    const commit = () => {
      if (localLText.value === '' || localFText.value === '') {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '名前を入力して下さい',
          type: false,
        });
        return;
      }
      context.emit('commit', {
        id: props.id,
        lText: localLText.value,
        fText: localFText.value,
      });
      isEditMode.value = false;
    };
    return {
      localLText,
      localFText,
      isEditMode,
      commit,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects2();

.value {
  font-size: 1.4rem;
}

.name-kanji {
  input {
    padding: 7px;
    border-radius: 6px;
    border: 1px solid #e3e3e3;
  }
  input + input {
    margin-left: 10px;
  }
}

.btn-availability {
  > .icon {
    margin-right: 3px;
  }
}

.item-title {
  display: flex;
  justify-content: space-between;
  width: 580px;
  margin-bottom: 10px;
  margin-bottom: 20px;

  h2 {
    padding-top: 10px;
    margin-bottom: 0;
  }
}

@media (max-width: ($media_query_tablet)) {
  .item-title {
    width: 100%;
  }
}

@media (max-width: ($media_query_sp)) {
  .name-kanji {
    input {
      display: block;
      width: 100%;
    }
    input + input {
      margin: 10px 0 0;
    }
  }
}
</style>
